import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IconsService } from '../../../services/icons.service';
import { NGXLogger } from 'ngx-logger';
import { Gt2ApiService } from '../../../api/gt2-api.service';
import { PrintService } from '../../../../services/print.service';
import { FileService } from '../../../../services/file.service';
import { locale as english } from '../i18n/en-CA';
import { locale as french } from '../i18n/fr-CA';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { ApiRoutes } from '../../../api/ApiRoutes';
import { GT2PageAbstract } from '../../abstract/GT2PageAbstract';
import { MatDialog } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-download-pdf',
  templateUrl: './download-pdf.component.html',
  styleUrls: ['./download-pdf.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatTooltipModule,
    CommonModule,
    TranslateModule,
  ],
})
export class DownloadPdfComponent extends GT2PageAbstract implements OnInit {
  @Input() dateRange: any;
  @Input() urlSection!: string; //  "mngt/report-mngt-prod-list-by-prod-zone"

  constructor(
    public translate: TranslateService,
    public iconsService: IconsService,
    public logger: NGXLogger,
    private api: Gt2ApiService,
    public print: PrintService,
    private dialog: MatDialog,
    public fileService: FileService,
    private translationLoader: FuseTranslationLoaderService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
  }

  ngOnInit(): void {}

  public onDownloadPDF(format: string = 'a4'): void {
    //this.logger.info("DownloadPdfComponent.onPrintProductionZoneList() -> format: " + format);
    //this.logger.info("DownloadPdfComponent.onPrintProductionZoneList() -> dateRange: " + JSON.stringify(this.dateRange));
    //this.logger.info("DownloadPdfComponent.onPrintProductionZoneList() -> urlSection: " + this.urlSection);

    // new
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PDF,
      this.urlSection,
      format + '/fr-CA',
    );

    // OLD
    // const url: string = this.api.createReportURL(ApiRoutes.REPORT_PDF, "mngt/report-mngt-prod-list-by-prod-zone", "fr-CA");
    //this.logger.info("DownloadPdfComponent.onPrintProductionZoneList() -> url: " + url);
    this.downloadPost(url);
  }

  public downloadPost(url: string): void {
    // //this.logger.info("DownloadPdfComponent.downloadPost() -> url: " + url);
    this.fileService.postDownloadFile(url, this.dateRange).subscribe({
      next: (response) => {
        // //this.logger.info("DownloadPdfComponent.downloadPost() -> SUCCESS: " + JSON.stringify(response));
      },
      error: (error) => {
        this.logger.error(
          'downloadPost.downloadPost() -> ERROR: ' + JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }
}
