import { GT2ConstantsEnum } from './../../../../const/GT2ConstantsEnum';
import { ModulesService } from './../../../../services/modules.service';
import { PrintService } from '../../../../../services/print.service';
import { FileService } from '../../../../../services/file.service';
import { GT2FirebaseService } from '../../../../../services/gt2-firebase.service';
import { ApiRoutes } from '../../../../api/ApiRoutes';
import { ItemUrlFinder } from './../../../../utils/item-url-finder';
import { GroupState } from './assignment-group/assignment-group.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreateGroupModalComponent } from './create-group-modal/create-group-modal.component';
import { LocaleHelperService } from './../../../../services/locale-helper.service';
import { FuseSidebarService } from '../../../../../../@fuse/components/sidebar/sidebar.service';
import { fuseAnimations } from '../../../../../../@fuse/animations';
import { GT2FormPageAbstract } from '../../../../content/abstract/GT2FormPageAbstract';
import { EventsService } from '../../../../api/events.service';
import { Title } from '@angular/platform-browser';
import { Gt2ApiService } from '../../../../api/gt2-api.service';
import { RolesService } from '../../../../services/roles.service';
import { MatDialog } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '../../../../../../@fuse/services/translation-loader.service';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Location } from '@angular/common';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { AssignementsService } from './assignements.service';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-assignments',
  templateUrl: './assignments.component.html',
  styleUrls: ['./assignments.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
})
export class AssignmentsComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  // event: any;
  editable: boolean = true;
  isSmallScreen!: boolean;
  eventUUDID!: string;
  groupUUDID!: string;
  assignments: any;
  loading: boolean = false;
  declare dialogRef: any;
  groupsOpened: string[] = [];
  @ViewChild('assignmentsPage')
  assignmentsPage!: ElementRef;
  // @ViewChild('newGroupButton', {static: false}) newGroupButton;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public logger: NGXLogger,
    public location: Location,
    private dialog: MatDialog,
    private titleService: Title,
    public rolesService: RolesService,
    private eventsService: EventsService,
    private api: Gt2ApiService,
    public localeHelperService: LocaleHelperService,
    public moduleService: ModulesService,
    private assignmentsService: AssignementsService,
    private _fuseSidebarService: FuseSidebarService,
    private spinner: NgxSpinnerService,
    public print: PrintService,
    public fileService: FileService,
    public router: Router,
    private breakpointObserver: BreakpointObserver,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
  }

  ngOnInit(): void {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe({
      next: (result) => {
        this.isSmallScreen = result.matches;
      },
    });
    //this.titleService.setTitle(this.translate.instant("ASSIGNMENTS.ASSIGNMENTS"));

    this.route.params.subscribe({
      next: (params: any) => {
        this.eventUUDID = params.id;
        this.groupUUDID = params.group;
        if (this.groupUUDID) {
          this.groupsOpened.push(this.groupUUDID);
        }
        this.loadAssignments();
      },
    });
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  public trackByUUID(index: any, item: any) {
    return item.uuid;
  }

  public loadAssignments(): void {
    // this.assignments = null;
    this.spinner.show('assignmentGroupSpinner');
    this.assignmentsService.getAssigments(this.eventUUDID).subscribe({
      next: (response: any) => {
        //this.logger.info("AssignmentsComponent.loadData().getAssigments() -> SUCCESS: " + JSON.stringify(response));
        this.assignments = response;
        this.validateEventStatus();
        this.spinner.hide('assignmentGroupSpinner');
        // //this.logger.info("AssignmentsComponent.loadData().getAssigments() -> SUCCESS: " + JSON.stringify(this.assignments));
      },
      error: (error: any) => {
        this.logger.error(
          'EventComponent.loadData().getAssigments() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
        this.spinner.hide('assignmentGroupSpinner');
      },
    });
  }

  public validateEventStatus(): void {
    this.editable =
      this.assignments.event.status !== GT2ConstantsEnum.EVENT_ABORT &&
      this.assignments.event.status !== GT2ConstantsEnum.EVENT_CANCEL &&
      this.assignments.event.status !== GT2ConstantsEnum.EVENT_COMPLETE;
  }

  public onOpenInNewTab(): void {
    window.open(this.router.url, '_blank');
  }

  public toggleHistoricSidebarOpen(key: string): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  public onAddNewGroup(): void {
    this.dialogRef = this.dialog.open(CreateGroupModalComponent, {
      width: '580px',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: {
        name:
          this.translate.instant('ASSIGNMENTS.GROUP') +
          ' ' +
          (this.assignments.group_assignments.length + 1),
        eventUUDID: this.eventUUDID,
        startDate: this.assignments.event.event_date,
        startTime: this.assignments.event.event_time,
        endDate: this.assignments.event.event_date_end,
        endTime: this.assignments.event.event_time_end,
      },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          // this.loadAssignments();
          this.onNewAssigmentModel(result);
        } else {
          // this.spinner.hide('assignmentSpiner');
        }
      },
    });
  }

  public onGroupStateChange(state: GroupState): void {
    this.groupsOpened = this.groupsOpened.filter((uuid) => {
      return uuid !== state.uuid;
    });
    if (state.state) {
      this.groupsOpened.push(state.uuid);
    }
  }

  public onVisitEvent(): void {
    this.router.navigateByUrl(ItemUrlFinder.getItemURL(this.assignments.event));
  }

  public onVisitInvoice(): void {
    //this.logger.info("AssignmentsComponent.onVisitInvoice(): " + JSON.stringify(this.assignments.invoice));
    this.router.navigateByUrl(
      ItemUrlFinder.getItemURL(this.assignments.invoice),
    );
  }

  public onVisitOrder(): void {
    //this.logger.info("AssignmentsComponent.onVisitOrder(): " + JSON.stringify(this.assignments.order));
    this.router.navigateByUrl(ItemUrlFinder.getItemURL(this.assignments.order));
  }

  public onNewAssigmentModel(assignment: any): void {
    // //this.logger.info(
    //     "AssignmentsComponent.onNewAssigmentModel() ***** : " +
    //         JSON.stringify(assignment)
    // );
    //this.logger.info("AssignmentsComponent.onNewAssigmentModel() ***** ");

    this.assignments = assignment;
    this.assignments.group_assignments = [
      ...this.assignments.group_assignments,
    ];
  }

  public onAssignmentGroupDelete(): void {
    setTimeout(() => {
      this.assignmentsPage.nativeElement.scrollTop = 0;
    }, 300);
  }

  // public onNewAssigmentJobModel(event): void {
  //     const assignment = this.assignments.group_assignments.find( item => {
  //         return item.uuid = event.group;
  //     });
  //     //this.logger.info("onNewAssigmentJobModel(): " + JSON.stringify(assignment));

  //     if (assignment) {
  //         const index = assignment.jobs.findIndex( item => {
  //             return item.uuid === event.job.uuid
  //         });
  //         //this.logger.info("onNewAssigmentJobModel().afterClosed -> index: " + index);

  //         if (index && index !== -1) {
  //             //this.logger.info("onNewAssigmentJobModel().afterClosed -> SET AT index: " + index);
  //             assignment.jobs[index] = event.job;
  //         }
  //     }
  // }

  //
  public printPost(url: string, dateRange: any): void {
    // //this.logger.info("AssignmentsComponent.printPost() -> url: " + url);
    this.print.postPrintHTML(url, dateRange).subscribe({
      next: (response: any) => {
        // //this.logger.info("AssignmentsComponent.printPost() -> SUCCESS: " + JSON.stringify(response));
      },
      error: (error: any) => {
        this.logger.error(
          'AssignmentsComponent.printPost() -> ERROR: ' + JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public downloadPost(url: string, dateRange: any = null): void {
    // //this.logger.info("AssignmentsComponent.downloadPost() -> url: " + url);
    this.fileService.postDownloadFile(url, dateRange).subscribe({
      next: (response) => {
        // //this.logger.info("AssignmentsComponent.downloadPost() -> SUCCESS: " + JSON.stringify(response));
      },
      error: (error) => {
        this.logger.error(
          'AssignmentsComponent.downloadPost() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public onPrintEmployeesAssigments(
    section: string,
    paperFormat: string,
  ): void {
    //this.logger.info("AssignmentsComponent.onPrintEmployeesAssigments()");
    const url: string =
      this.api.createReportURL(
        ApiRoutes.REPORT_PRINT,
        section,
        paperFormat + '/fr-CA',
      ) +
      '/' +
      GT2FirebaseService.CLIENT_KEY;
    //this.logger.info("AssignmentsComponent.onPrintEmployeesAssigments() -> url: " + url);
    this.printPost(url, { uuid_event: this.eventUUDID });
  }

  public onDownloadEmployeesAssigments(
    section: string,
    paperFormat: string,
  ): void {
    //this.logger.info("AssignmentsComponent.onDownloadEmployeesAssigments()");
    const url: string =
      this.api.createReportURL(
        ApiRoutes.REPORT_PDF,
        section,
        paperFormat + '/fr-CA',
      ) +
      '/' +
      GT2FirebaseService.CLIENT_KEY;
    //this.logger.info("AssignmentsComponent.onDownloadEmployeesAssigments() -> url: " + url);
    this.downloadPost(url, { uuid_event: this.eventUUDID });
  }
}
