import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DocumentsService } from '../../../api/documents.service';
import { ItemUrlFinder } from '../../../utils/item-url-finder';
import { NGXLogger } from 'ngx-logger';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import { Settings } from '../../../settings/settings';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-create-proposal',
  templateUrl: './create-proposal.component.html',
  styleUrls: ['./create-proposal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatButtonModule,
  ],
})
export class CreateProposalComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  form: UntypedFormGroup;
  proposal: any;
  eventUUID!: string;
  loading: boolean = false;
  autoRedirect: boolean = false;

  constructor(
    public override dialogRef: MatDialogRef<CreateProposalComponent>,
    private translationLoader: FuseTranslationLoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private documentsService: DocumentsService,
    public dialog: MatDialog,
    public router: Router,
    private logger: NGXLogger,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
    });

    this.proposal = {
      name: null,
    };

    if (data && data.autoRedirect !== null) {
      this.autoRedirect = data.autoRedirect;
    }

    //this.logger.info("CreateProposalComponent.onCreateProposal() -> data.name: " + data.name);
    if (data && data.name !== null) {
      this.proposal = {
        name: data.name,
      };
    }

    if (data && data.eventUUID !== null) {
      this.eventUUID = data.eventUUID;
    }
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public onCreateProposal() {
    this.loading = true;
    //this.logger.info("CreateProposalComponent.onCreateProposal()");
    if (this.eventUUID) {
      this.onCreateProposalForEvent();
    } else {
      this.onDoCreateProposal();
    }
  }

  public onCreateProposalForEvent(): void {
    //this.logger.info("CreateProposalComponent.onCreateProposalForEvent()");
    this.documentsService
      .createProposal(this.proposal, this.eventUUID)
      .subscribe({
        next: (response) => {
          // //this.logger.info("CreateProposalComponent.onCreateProposal() -> SUCCESS: " + JSON.stringify(response));
          this.loading = false;
          if (this.autoRedirect) {
            // TODO:
            this.router.navigateByUrl(ItemUrlFinder.getItemURL(response.data));
            this.dialogRef.close(null);
          } else {
            this.dialogRef.close(response);
          }
        },
        error: (error) => {
          this.logger.error(
            'CreateProposalComponent.onCreateProposal() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;

          // TODO: should have a toastr ????
          this.handleAPIError(error, this.dialog, null, null);
        },
      });
  }

  public onDoCreateProposal(): void {
    //this.logger.info("CreateProposalComponent.onDoCreateProposal()");
  }
}
