import { ValidatorHelper } from '../../../../../validators/ValidatorHelper';
import { GT2PageAbstract } from './../../../../abstract/GT2PageAbstract';
import { GT2DateAdapter } from './../../../../../utils/GT2DateAdapter';
import { NGXLogger } from 'ngx-logger';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import * as _ from 'lodash';
import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { AssignementsService } from '../assignements.service';
import { StaffProviderService } from '../staff-provider.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { RichTextLocaleComponent } from '../../../../../components/rich-text-locale/rich-text-locale.component';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { CalendarDateFormatter } from 'angular-calendar';
import { CustomDateFormatter } from '../../../../calendar/CustomDateFormatter';

@Component({
  selector: 'app-edit-job-assignment-modal',
  templateUrl: './edit-job-assignment-modal.component.html',
  styleUrls: ['./edit-job-assignment-modal.component.scss'],
  standalone: true,
  imports: [
    NgxSpinnerModule,
    MatDatepickerModule,
    FlexLayoutModule,
    FormsModule,
    CommonModule,
    MatButtonModule,
    RichTextLocaleComponent,
    MatInputModule,
    NgxMaterialTimepickerModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatCheckboxModule,
    TranslateModule,
  ],
  providers: [
    { provide: DateAdapter, useClass: GT2DateAdapter },
    { provide: CalendarDateFormatter, useClass: CustomDateFormatter },
    AssignementsService,
    StaffProviderService,
  ],
})
export class EditJobAssignmentModalComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  job: any;
  form!: UntypedFormGroup;

  constructor(
    public override dialogRef: MatDialogRef<EditJobAssignmentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    private formBuilder: UntypedFormBuilder,
    public router: Router,
    private dialog: MatDialog,
    // private jobsFunctionService: JobsFunctionService,
    public assignmentsService: AssignementsService,
    private adapter: DateAdapter<any>,
    private spinner: NgxSpinnerService,
    private logger: NGXLogger,
  ) {
    super();
    this.adapter.setLocale(this.translate.currentLang);
    // //this.logger.info("CreateGroupModalComponent.constructor() -> data.group: " + JSON.stringify(data.group));
    if (data && data.job) {
      this.job = _.cloneDeep(data.job); // data.job;
    }
  }

  ngOnInit() {
    //this.logger.info("EditJobAssignmentModalComponent.ngOnInit() -> this.job: " + JSON.stringify(this.job));
    this.form = this.formBuilder.group({
      // name: ['', [Validators.minLength(Settings.inputMinChar), Validators.maxLength(Settings.inputMaxCharMid), Validators.required]],
      real_date_from: ['', [ValidatorHelper.dateRangeDefault]],
      real_date_to: ['', [ValidatorHelper.dateRangeDefault]],
      meal_included: [
        '',
        [
          Validators.required,
          ValidatorHelper.isInteger,
          ValidatorHelper.isNumber0To1000,
        ],
      ],
      tips_amount: ['', [ValidatorHelper.isNumber0To1000000]],
      hourly_wage: [
        '',
        [Validators.required, ValidatorHelper.isNumber0To1000000],
      ],
    });
  }

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public onSaveJob(): void {
    //this.logger.info("EditJobAssignmentModalComponent.onSaveJob() -> this.job: " + JSON.stringify(this.job));
    this.spinner.show('proposalSpiner');
    this.assignmentsService
      .editJobAssigment(this.job.uuid, this.job)
      .subscribe({
        next: (response: any) => {
          //this.logger.info("EditJobAssignmentModalComponent.onSaveJob() -> SUCCESS: " + JSON.stringify(response));
          this.dialogRef.close(response);
          this.spinner.hide('proposalSpiner');
        },
        error: (error: any) => {
          this.logger.error(
            'EditJobAssignmentModalComponent.onSaveJob() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.handleAPIError(error, this.dialog, null, null);
          this.spinner.hide('proposalSpiner');
        },
      });
  }
}
