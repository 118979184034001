import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { GT2PageAbstract } from '../../content/abstract/GT2PageAbstract';
import { Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { MatSelectModule } from '@angular/material/select';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { MatOptionModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-gt2-select',
  templateUrl: './gt2-select.component.html',
  styleUrls: ['./gt2-select.component.scss'],
  standalone: true,
  imports: [
    MatSelectModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    MatButtonModule,
    MatInputModule,
    TranslateModule,
    ReactiveFormsModule,
    CommonModule,
    MatFormFieldModule,
    FormsModule,
    Gt2PrimengModule,
    MatProgressSpinnerModule,
    MatOptionModule,
  ],
})
export class Gt2SelectComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
  @Input() service?: IGetDataService;
  @Input() label: string = '';
  @Input() placeHolder?: string;
  @Input() noResultsLabel?: string;
  @Input() disabled = false;
  @Input() labelKey: string = 'name';
  @Input() nullableUseNone = true;
  @Input() selectedModel?: ISelectModel;

  @Input() isRequired = false;
  @Input() isSimpleValue = false;
  @Input() useFloatValue: 'always' | 'auto' = 'auto';
  @Output() changeEvent = new EventEmitter<any>();
  @Output() selectedModelChange = new EventEmitter<any>();
  @Output() modelSetEvent = new EventEmitter<any>();

  data?: any[];
  selected: any;
  isInit: boolean = false;
  loading: boolean = false;

  constructor(private logger: NGXLogger) {
    super();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.service) {
      this.loading = true;
      this.service.getData().subscribe({
        next: (response) => {
          // //this.logger.info("Gt2SelectComponent.getData() -> SUCCESS: " + JSON.stringify(response));
          this.data = response.data;
          this.loading = false;
          this.setSelected();
          //
          this.modelSetEvent.next({
            isEmpty: this.data?.length === 0,
          });
          this.selectedModelChange.emit();
        },
        error: (error) => {
          //this.logger.info("Gt2SelectComponent.getData() -> ERROR: " + JSON.stringify(error));
          this.loading = false;
        },
      });
    }

    this.isInit = true;
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges) {
    // //this.logger.info("Gt2SelectComponent.ngOnChanges() -> : " + JSON.stringify(changes));
    // //this.logger.info("Gt2SelectComponent.ngOnChanges() -> : " + changes);

    const chng = changes['selectedModel'];
    if (chng) {
      this.setSelected();
    }
  }

  public setSelected(): void {
    if (this.selectedModel && this.data) {
      this.selected = this.data.find((item) => {
        return this.selectedModel?.uuid === item.uuid;
      });
      if (this.selected) {
        this.changeEvent.next(this.selected);
      }
    } else {
      this.selected = null;
    }
  }

  public onSelectionChange(event: any): void {
    // //this.logger.info("Gt2SelectComponent.onSelectionChange() -> event" + JSON.stringify(event.value));
    // //this.logger.info("Gt2SelectComponent.onSelectionChange() -> this.selected" + JSON.stringify(this.selected));
    this.changeEvent.next(event.value);
  }
}

export interface IGetDataService {
  getData(): Observable<any>;
}

export interface ISelectModel {
  uuid: string;
}
