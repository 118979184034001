export const locale = {
  lang: 'fr-CA',
  data: {
    CONGRESSES: {
      CONGRESSES: 'Congrès',
      CONGRESS: 'Congrès',
      CONGRESS_NUMBER: 'Numéro de congrès',
      SPCM_NUMBER: '# SPCM',
      PROJECT_NUMBER: '# projet',
      CREATE_CONGRESS_TITLE: 'Créer un congrès',
      CREATE_PROJECT_TITLE: 'Créer un projet',
      NO_PROJECTS: 'Aucun projet pour ce congrès',
      NO_INVOICES: 'Aucune facture pour ce congrès',
      CONGRES_CANCELED: 'CONGRÈS ANNULÉ',
      PROJECT_CANCELED: 'PROJET ANNULÉ',
      GROUP_ACTION_TOOLTIP:
        'Action de groupe. Au moins 1 item doit être sélectionné',
      CUSTOM_RANGE: 'Personnalisé',
    },
  },
};
