import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FuseConfigService } from '../../../../@fuse/services/config.service';
import { fuseAnimations } from '../../../../@fuse/animations';
import { GT2FormPageAbstract } from '../../../gt2/content/abstract/GT2FormPageAbstract';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { SettingsModel } from '../../../gt2/settings/SettingsModel';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { SettingsService } from '../../../gt2/settings/settings.service';
import { AppRoutesService } from '../../../gt2/services/app-routes.service';
import { SupportContactService } from '../../../gt2/content/contact/support-contact.service';
import { Settings } from '../../../gt2/settings/settings';
import { ResponsesStatus } from '../../../gt2/api/ResponsesStatus';
import { GenericAlertComponent } from '../../../gt2/components/generic-alert/generic-alert.component';
import { AppRoutes } from '../../../gt2/const/AppRoutes';
import { environment } from '../../../../environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class MaintenanceComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  form: UntypedFormGroup | any;
  subject?: string;
  message?: string;
  setting?: SettingsModel;

  /*
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private router: Router,
    public location: Location,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    public dialog: MatDialog,
    private logger: NGXLogger,
    private formBuilder: UntypedFormBuilder,
    private settings: SettingsService,
    private titleService: Title,
    public appRoutesService: AppRoutesService,
    private supportContactService: SupportContactService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };

    //
    this.form = this.formBuilder.group({
      subject: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      message: [
        '',
        [Validators.minLength(Settings.inputMinChar), Validators.required],
      ],
    });

    //
    this.settings.loadSettings().subscribe({
      next: (response) => {
        // this.logger.info('Error500Component.constructor().settings() -> SUCCESS: ' + JSON.stringify(response));
        if (response.response_status === ResponsesStatus.OK) {
          this.setting = response.response;
        }
      },
      error: (error) => {
        this.logger.error(
          'Error500Component.constructor().settings() -> ERRORS: ' +
            JSON.stringify(error),
        );
      },
    });
  }

  ngOnInit() {
    //this.titleService.setTitle(this.translate.instant("ERROR_503.MAINTENANCE"));
  }

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public onSendContactForm(): void {
    // this.logger.info("Error500Component.onSendContactForm()");
    const email: any = {
      subject: '(Error 503 maintenance page) ' + this.subject,
      message: this.message,
    };
    this.supportContactService.postEmailError503(email).subscribe({
      next: (response) => {
        // this.logger.info("Error500Component.onSendContactForm() -> SUCCESS: " + JSON.stringify(response));
        this.showConfirmationDialog();
      },
      error: (error) => {
        this.logger.error(
          'Error500Component.onSendContactForm() -> ERROR: ' +
            JSON.stringify(error),
        );
      },
    });
  }

  public override onPageBack(): void {
    // this.logger.info(">>>>>>>>> ==> " + window.history.length);
    if (window.history.length > 1) {
      this.location.back();
    } else {
      this.router.navigateByUrl(AppRoutes.DASHBOARD);
    }
  }

  public onVisitGt2(): void {
    window.open(environment.gt2Website, '_blank');
    // window.open("https://www.gestiontraiteur.com", '_blank');
  }

  public showConfirmationDialog(): void {
    this.subject = '';
    this.message = '';
    this.form.markAsUntouched();
    this.form.markAsPristine();

    this.dialogRef = this.dialog.open(GenericAlertComponent, {
      // width: '45%',
      disableClose: false,
    });
    this.dialogRef.componentInstance.alertTitle = '';
    this.dialogRef.componentInstance.alertMessage = this.translate.instant(
      'ERROR_429.SUCCESS_MESSAGE',
    );
    // this.dialogRef.componentInstance.showConfirmButton = false;

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        this.dialogRef = null;
      },
    });
  }
}
