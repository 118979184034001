import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Editor, EditorModule } from 'primeng/editor';
import { Subscription } from 'rxjs';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import {
  GeneralConditionModel,
  GeneralConditionsService,
} from '../../../api/general-conditions.service';
import { Settings } from '../../../settings/settings';
import { GT2FormPageAbstract } from '../../abstract/GT2FormPageAbstract';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-general-conditions',
  templateUrl: './general-conditions.component.html',
  styleUrls: ['./general-conditions.component.scss'],
  standalone: true,
  imports: [
    EditorModule,
    TranslateModule,
    MatSelectModule,
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
  ],
})
export class GeneralConditionsComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  editMode: boolean = true;
  model!: GeneralConditionModel;
  form: UntypedFormGroup;
  @ViewChild('proposalEditor') proposalEditor!: Editor;
  @ViewChild('proposalEditorEn') proposalEditorEn!: Editor;

  @ViewChild('depositInvoiceEditor') depositInvoiceEditor!: Editor;
  @ViewChild('depositInvoiceEditorEn') depositInvoiceEditorEn!: Editor;

  @ViewChild('eventInvoiceEditor') eventInvoiceEditor!: Editor;
  @ViewChild('eventInvoiceEditorEn') eventInvoiceEditorEn!: Editor;

  @ViewChild('adjusmentInvoiceEditor') adjusmentInvoiceEditor!: Editor;
  @ViewChild('adjusmentInvoiceEditorEn') adjusmentInvoiceEditorEn!: Editor;

  @ViewChild('creditEditor') creditEditor!: Editor;
  @ViewChild('creditEditorEn') creditEditorEn!: Editor;

  proposalEditorSubscription!: Subscription;
  proposalEditorSubscriptionEn!: Subscription;

  depositInvoiceEditorSubscription!: Subscription;
  depositInvoiceEditorSubscriptionEn!: Subscription;

  eventInvoiceEditorSubscription!: Subscription;
  eventInvoiceEditorSubscriptionEn!: Subscription;

  adjusmentInvoiceEditorSubscription!: Subscription;
  adjusmentInvoiceEditorSubscriptionEn!: Subscription;

  creditEditorSubscription!: Subscription;
  creditEditorSubscriptionEn!: Subscription;

  noteEditordebounceTimeValue: number = 1500;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private translate: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private logger: NGXLogger,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private generalConditionsService: GeneralConditionsService,
    private cdr: ChangeDetectorRef,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    this.form = this.formBuilder.group({
      proposal_condition: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharLong),
          Validators.required,
        ],
      ],
      deposite_invoice_condition: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharLong),
          Validators.required,
        ],
      ],
      event_invoice_condition: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharLong),
          Validators.required,
        ],
      ],
      adjusment_invoice_condition: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharLong),
          Validators.required,
        ],
      ],
      credit_note_condition: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharLong),
          Validators.required,
        ],
      ],
    });

    this.loadData();
  }

  ngOnInit() {
    // this.logger.info("this.proposalEditor: " + this.proposalEditor);
  }

  ngAfterViewInit() {
    // this.logger.info("this.proposalEditor: " + this.proposalEditor);
    // this.setControlsChanges(this.form);
  }

  ngOnDestroy() {}

  setFocus(event: any) {
    // this.logger.info("------__> ,,,,,,,,,,, tester");
    event.editor.focus();
  }

  public initEditors(): void {
    // this.logger.info("this.proposalEditor: " + this.proposalEditor);
    // this.logger.info("this.proposalEditorEn: " + this.proposalEditorEn);
    // this.logger.info("this.proposalEditorEn: " + this.proposalEditorEn);
    // this.logger.info("this.depositInvoiceEditorEn: " + this.depositInvoiceEditorEn);
    // if (this.proposalEditor)
    // {
    //     this.setNoteAutoSave(this.proposalEditor, this.proposalEditorSubscription, "proposal_condition");
    // }
    // if (this.proposalEditorEn)
    // {
    //     this.setNoteAutoSave(this.proposalEditorEn, this.proposalEditorSubscription, "proposal_condition");
    // }
    //
    // if (this.depositInvoiceEditor)
    // {
    //     this.setNoteAutoSave(this.depositInvoiceEditor, this.depositInvoiceEditorSubscription, "deposite_invoice_condition");
    // }
    // if (this.depositInvoiceEditorEn)
    // {
    //     this.setNoteAutoSave(this.depositInvoiceEditorEn, this.depositInvoiceEditorSubscriptionEn, "deposite_invoice_condition");
    // }
    //
    // if (this.eventInvoiceEditor)
    // {
    //     this.setNoteAutoSave(this.eventInvoiceEditor, this.eventInvoiceEditorSubscription, "event_invoice_condition");
    // }
    // if (this.eventInvoiceEditorEn)
    // {
    //     this.setNoteAutoSave(this.eventInvoiceEditorEn, this.eventInvoiceEditorSubscriptionEn, "event_invoice_condition");
    // }
    //
    // if (this.adjusmentInvoiceEditor)
    // {
    //     this.setNoteAutoSave(this.adjusmentInvoiceEditor, this.adjusmentInvoiceEditorSubscription, "adjusment_invoice_condition");
    // }
    // if (this.adjusmentInvoiceEditorEn)
    // {
    //     this.setNoteAutoSave(this.adjusmentInvoiceEditorEn, this.adjusmentInvoiceEditorSubscriptionEn, "adjusment_invoice_condition");
    // }
    //
    // if (this.creditEditor)
    // {
    //     this.setNoteAutoSave(this.creditEditor, this.creditEditorSubscription, "credit_note_condition");
    // }
    // if (this.creditEditorEn)
    // {
    //     this.setNoteAutoSave(this.creditEditorEn, this.creditEditorSubscriptionEn, "credit_note_condition");
    // }
  }

  public loadData(): void {
    this.generalConditionsService.getConditions().subscribe({
      next: (response: any) => {
        // this.logger.info("GeneralConditionsComponent -> getConditions()  SUCCESS: " + JSON.stringify(response));
        this.model = response.data;
        this.cdr.detectChanges();
        setTimeout(() => {
          // this.initEditors();
          this.proposalEditor?.getQuill().focus();
        }, 300);
      },
      error: (error: any) => {
        this.logger.error(
          'GeneralConditionsComponent -> getConditions()  ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public onLanguageChange(lang: string): void {
    // this.logger.info("GeneralConditionsComponent.onLanguageChange() -> " + lang);
    this.loadData();
  }

  public override autosaveDataChange(data: any): void {
    // this.logger.info("GeneralConditionsComponent.autosaveDataChange() -> data: " + JSON.stringify(data));
    this.generalConditionsService
      .updateCondition(data, this.model.uuid)
      .subscribe({
        next: (response) => {
          // this.logger.info("GeneralConditionsComponent.autosaveDataChange() -> SUCCESS: " + JSON.stringify(response));
          this.form.markAsPristine();
          this.toastr.success(
            '',
            this.translate.instant('GENERIC.API_CALL_SUCCESS.SAVE_SUCCESS'),
          );
          this.loadData();
        },
        error: (error) => {
          this.logger.error(
            'GeneralConditionsComponent.autosaveDataChange() -> ERROR: ' +
              JSON.stringify(error),
          );
          // this.toastr.error("", this.translate.instant("GENERIC.ERRORS.GENERIC"));
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.GENERIC'),
          );
        },
      });
  }

  // public setNoteAutoSave(note: Editor, subscription: Subscription, key: string): void
  // {
  //     subscription = note.onTextChange.debounceTime(this.noteEditordebounceTimeValue)
  //         .distinctUntilChanged().subscribe(data => {
  //             const obj: any = {};
  //             obj[key] = data.htmlValue;
  //             this.generalConditionsService.updateCondition(obj, this.model.uuid).subscribe(response => {
  //                     this.logger.info("GeneralConditionsComponent.setNoteAutoSave() -> SUCCESS: " + JSON.stringify(response));
  //                     this.form.markAsPristine();
  //                     this.loadData();
  //                 },
  //                 error => {
  //                     this.logger.info("GeneralConditionsComponent.setNoteAutoSave() -> ERROR: " + JSON.stringify(error));
  //                     this.handleAPIError(error, this.dialog);
  //                 }
  //             );

  //             // this.productsService.updateProduct(obj, this.productID)
  //             //     .subscribe(response => {
  //             //             this.logger.info("ProductComponent.noteEditor.onTextChange() -> SUCCESS: " + JSON.stringify(response));
  //             //         },
  //             //         error => {
  //             //             this.logger.info("ProductComponent.noteEditor.onTextChange() -> ERROR: " + JSON.stringify(error));
  //             //             this.handleAPIError(error, this.dialog);
  //             //         }
  //             //     );
  //             });
  //         // }

  // }

  // public onSaveEditor(local: string, key: string, text: string)
  // {
  //     // this.logger.info("text : " + this.proposalEditor.value);
  //     // const currentLanguage = this.translate.currentLang;
  //     // localStorage.setItem(LocalizationConfig.LOCAL_STORED_LANG_ID, local);
  //     const obj: any = {};
  //     obj[key] = text;
  //     this.logger.info("--> " + JSON.stringify(obj));

  //     this.generalConditionsService.updateCondition(obj, this.model.uuid).subscribe(response => {
  //             this.logger.info("GeneralConditionsComponent.setNoteAutoSave() -> SUCCESS: " + JSON.stringify(response));
  //             localStorage.setItem(LocalizationConfig.LOCAL_STORED_LANG_ID, currentLanguage);
  //             this.form.markAsPristine();
  //             this.loadData();
  //         },
  //         error => {
  //             this.logger.info("GeneralConditionsComponent.setNoteAutoSave() -> ERROR: " + JSON.stringify(error));
  //             localStorage.setItem(LocalizationConfig.LOCAL_STORED_LANG_ID, currentLanguage);
  //             this.handleAPIError(error, this.dialog);
  //         }
  //     );
  // }

  public onSaveEditor(data: any) {
    // this.logger.info("text : " + this.proposalEditor.value);
    // const currentLanguage = this.translate.currentLang;
    // localStorage.setItem(LocalizationConfig.LOCAL_STORED_LANG_ID, local);
    // const obj: any = {};
    // obj[key] = text;
    // this.logger.info("--> " + JSON.stringify(data));

    this.generalConditionsService
      .updateCondition(data, this.model.uuid)
      .subscribe({
        next: (response) => {
          // this.logger.info("GeneralConditionsComponent.onSaveEditor() -> SUCCESS: " + JSON.stringify(response));
          // localStorage.setItem(LocalizationConfig.LOCAL_STORED_LANG_ID, currentLanguage);
          this.form.markAsPristine();
          this.toastr.success(
            '',
            this.translate.instant('GENERIC.API_CALL_SUCCESS.SAVE_SUCCESS'),
          );
          this.loadData();
        },
        error: (error) => {
          this.logger.error(
            'GeneralConditionsComponent.onSaveEditor() -> ERROR: ' +
              JSON.stringify(error),
          );
          // localStorage.setItem(LocalizationConfig.LOCAL_STORED_LANG_ID, currentLanguage);
          // this.toastr.error("", this.translate.instant("GENERIC.ERRORS.GENERIC"));
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.GENERIC'),
          );
        },
      });
  }
}
