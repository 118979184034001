import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { OrdersService } from '../../../api/orders.service';
import { SpecificConditionService } from '../../../api/specific-condision.service';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { WrappingService } from '../../../api/wrapping.service';
import { ProposalComposeService } from '../../../api/proposal-compose.service';
import { ProposalComposeCTService } from '../../../api/proposal-compose-ct.service';
import { OrderComposeService } from '../../../api/order-compose.service';
import { InvoiceComposeService } from '../../../api/invoice-compose.service';
import { InvoiceComposeCTService } from '../../../api/invoice-compose-ct.service';
import { InvoiceComposeGlobalPaymentsService } from '../../../api/invoice-compose-global-payments.service';
import { CatererService } from '../../../content/caterer/caterer.service';
import { ConseillersService } from '../../../api/conseillers.service';
import { ReportsService } from '../../../reports/reports.service';
import { ReportFactoryService } from '../../../reports/report-factory.service';
import { MenuReportsService } from '../../../reports/menu-reports.service';
import { ReceiptNoticeComposeService } from '../../../api/receipt-notice-compose.service';
import { ReceiptNoticeSellOnlyComposeService } from '../../../api/receipt-notice-sell-only-compose.service';
import { StorageNoticeComposeService } from '../../../api/storage-notice-compose.service';
import { Gt2SelectComponent } from '../../gt2-select/gt2-select.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-add-specific-condition',
  templateUrl: './add-specific-condition.component.html',
  standalone: true,
  imports: [
    Gt2SelectComponent,
    MatProgressSpinnerModule,
    MatInputModule,
    FlexLayoutModule,
    MatButtonModule,
    TranslateModule,
    CommonModule,
  ],
  providers: [
    WrappingService,
    SpecificConditionService,
    ProposalComposeService,
    ProposalComposeCTService,
    OrderComposeService,
    InvoiceComposeService,
    InvoiceComposeCTService,
    InvoiceComposeGlobalPaymentsService,
    CatererService,
    ConseillersService,
    ReportsService,
    ReportFactoryService,
    MenuReportsService,
    ReceiptNoticeComposeService,
    ReceiptNoticeSellOnlyComposeService,
    StorageNoticeComposeService,
  ],
  styleUrls: ['./add-specific-condition.component.scss'],
})
export class AddSpecificConditionComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  static CREATE: string = 'CREATE';
  static EDIT: string = 'EDIT';

  //
  mode: string = AddSpecificConditionComponent.CREATE;
  // form: FormGroup;
  // specificCondition: any;
  orderUUID!: string;
  // order: any;
  loading: boolean = false;
  autoRedirect: boolean = false;
  specificConditions?: any[];
  selectedSpecificCondition: any;
  // specificConditionToUpdate: any;
  // specificConditionData: any;

  constructor(
    public override dialogRef: MatDialogRef<AddSpecificConditionComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    // private formBuilder: FormBuilder,
    // public deliveryRequestTypeService: DeliveryRequestTypeService,
    // public deliveryRequestContentService: DeliveryRequestContentService,
    // public specificConditionService: ServiceChargeService,
    public specificConditionService: SpecificConditionService,
    public orderService: OrdersService,
    public dialog: MatDialog,
    public router: Router,
    private adapter: DateAdapter<any>,
    private toastr: ToastrService,
    private logger: NGXLogger,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    this.adapter.setLocale(this.translate.currentLang);

    // this.form = this.formBuilder.group({
    //   name: ['', [ValidatorHelper.isNumber0To1000000, Validators.required]],
    // });

    // this.logger.info("CreateDeliveryRequestComponent.constructor() -> data: " + JSON.stringify(data));
    // this.specificCondition = {
    //   name: "",
    // };

    if (data && data.mode !== null) {
      this.mode = data.mode;
    }

    if (data && data.eventUUID !== null) {
      this.orderUUID = data.orderUUID;

      //
      // this.deliveryRequestService.getNewDeliveryRequestData(this.eventUUID).subscribe( response =>
      //   {
      //     this.logger.info("CreateDeliveryRequestComponent.constructor() -> SUCCESS: " + JSON.stringify(response));
      //     this.deliveryRequestData = response.data;
      //     this.deliveryRequest = this.deliveryRequestData;
      //     // this.deliveryRequest.price = this.deliveryRequestData.price;
      //     // this.deliveryRequest.name = this.deliveryRequestData.name;
      //   }, error =>
      //   {
      //     this.logger.error("CreateDeliveryRequestComponent.constructor() -> ERROR: " + JSON.stringify(error));
      //   });
    }

    if (data && data.autoRedirect !== null) {
      this.autoRedirect = data.autoRedirect;
    }

    // if (data && data.specificConditionToUpdate) {
    //   this.specificCondition = data.specificConditionToUpdate;
    //   this.logger.info("AddSpecificConditionComponent.constructor() -> specificConditionToUpdate TO data.specificConditionToUpdate: " + JSON.stringify(data.specificConditionToUpdate));
    //   this.logger.info("AddSpecificConditionComponent.constructor() -> specificConditionToUpdate TO this.specificCondition: " + JSON.stringify(this.specificCondition));
    // }
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public onCreateSpecificCondition() {
    this.loading = true;
    // this.logger.info("AddSpecificConditionComponent.onCreateSpecificCondition() -> flow: " + JSON.stringify(this.selectedSpecificCondition));

    if (this.mode === AddSpecificConditionComponent.CREATE) {
      this.doCreateSpecificCondition();
    } else if (this.mode === AddSpecificConditionComponent.EDIT) {
      this.updateSpecificCondition();
    }
  }

  public doCreateSpecificCondition() {
    //this.logger.info("AddSpecificConditionComponent.doCreateSpecificCondition() -> selectedSpecificCondition: " + JSON.stringify({ service_charge: [this.selectedSpecificCondition] }));
    this.orderService
      .addSpecificCondition(this.orderUUID, {
        specific_condition: [this.selectedSpecificCondition],
      })
      .subscribe({
        next: (response: any) => {
          // this.logger.info("AddSpecificConditionComponent.doCreateDeliveryRequest() -> SUCCESS: " + JSON.stringify(response));
          this.loading = false;
          this.dialogRef.close(response.data);
        },
        error: (error: any) => {
          this.logger.error(
            'AddSpecificConditionComponent.doCreateSpecificCondition() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.GENERIC'),
          );
          // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
        },
      });
  }

  public updateSpecificCondition() {
    //this.logger.info("AddSpecificConditionComponent.updateSpecificCondition() -> ***** UPDATE this.selectedSpecificCondition: " + JSON.stringify(this.selectedSpecificCondition));
    //   this.orderService.updateServiceCharge(this.deliveryRequest, this.eventUUID).subscribe(response => {
    //     this.logger.info("AddServiceChargeComponent.onCreateFlow() -> SUCCESS: " + JSON.stringify(response));
    //     this.loading = false;
    //     this.dialogRef.close(response);
    //   }, error => {
    //       this.logger.error("AddServiceChargeComponent.onCreateFlow() -> ERROR: " + JSON.stringify(error));
    //       this.loading = false;
    //       this.handleAPIError( error, this.dialog);
    //       this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
    //     });
    // }
  }

  public onSpecificConditioneChange(event: any): void {
    this.selectedSpecificCondition = event;
    // this.selectedServiceCharge.unit_price = this.specificCondition.unit_price;
    // this.selectedServiceCharge.qty = this.specificCondition.qty;
    // this.specificCondition = this.selectedServiceCharge;
  }
}
