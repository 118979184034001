<div fxLayout="row">
  <button
    *ngxPermissionsOnly="rolesService.SALE_ROLES"
    mat-button
    (click)="onNewProposal()"
    class="icon-mat"
  >
    <mat-icon class="icon-mat s-24" style="margin-right: 8px !important">{{
      getIcon("PROPOSAL")
    }}</mat-icon>
    {{ "GENERIC.CREATE_PROPOSAL" | translate }}
  </button>

  <button
    *ngxPermissionsOnly="rolesService.SALE_ROLES"
    mat-button
    (click)="onCreateOrder()"
    class="icon-mat"
  >
    <mat-icon class="icon-mat s-24" style="margin-right: 8px !important">{{
      getIcon("ORDER")
    }}</mat-icon>
    {{ "GENERIC.CREATE_ORDER" | translate }}
  </button>
</div>
