import { ItemUrlFinder } from '../../../utils/item-url-finder';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { AppRoutesService } from './../../../services/app-routes.service';
import { of, Subscription, merge } from 'rxjs';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import { Gt2ApiService } from '../../../api/gt2-api.service';
import { NGXLogger } from 'ngx-logger';
import { startWith, switchMap, catchError, map } from 'rxjs/operators';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  CongressesService,
  CongressModel,
} from './../../../api/congresses.service';
import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import {
  MatTable,
  MatTableDataSource,
  MatTableModule,
} from '@angular/material/table';
import { DatatableHelperService } from '../../../../services/datatable-helper.service';
import { Router, RouterLink } from '@angular/router';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CommonModule } from '@angular/common';
import { CdkTableModule } from '@angular/cdk/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TableSizePipeModule } from '../../../modules/table-size.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-project-box',
  templateUrl: './project-box.component.html',
  styleUrls: ['./project-box.component.scss'],
  standalone: true,
  imports: [
    MatProgressBarModule,
    CommonModule,
    CdkTableModule,
    MatSortModule,
    RouterLink,
    MatTabsModule,
    MatButtonModule,
    FlexLayoutModule,
    TableSizePipeModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatIconModule,
    TranslateModule,
    MatTableModule,
  ],
  animations: fuseAnimations,
})
export class ProjectBoxComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  //
  @Input() congress!: CongressModel;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('table') table!: MatTable<any>;
  //
  loading: boolean = false;
  projects: any[] = [];
  //
  displayedColumns = [
    'full_name',
    'date_start',
    'ordering_client',
    'enterprise',
    'actions',
  ];
  filterValue: string = '';
  dataSource = new MatTableDataSource();
  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;
  fromEventSubscription!: Subscription;
  isFirstLoad: boolean = true;
  meta: any;
  @Output() onMeta = new EventEmitter<any>();
  //
  constructor(
    private congressesService: CongressesService,
    private translate: TranslateService,
    private logger: NGXLogger,
    private api: Gt2ApiService,
    public dialog: MatDialog,
    public datatableHelperService: DatatableHelperService,
    public appRoutesService: AppRoutesService,
    private router: Router,
  ) {
    super();
  }

  ngOnInit() {
    //this.logger.info("ProjectBoxComponent.ngOnInit()");
  }

  ngAfterViewInit() {
    //this.logger.info("ProjectBoxComponent.ngAfterViewInit()");
    this.sort.sortChange.subscribe({
      next: () => (this.paginator.pageIndex = 0),
    });

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(startWith(null))
      .pipe(
        switchMap(() => {
          setTimeout(() => {
            this.isLoadingResults = true;
          });
          //
          return this.congressesService.getCongressProjectsWithPagination(
            this.congress.uuid,
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize,
          );
        }),
      )
      .pipe(
        map((data) => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.meta.pagination.total;
          //this.logger.info("ProjectBoxComponent.ngAfterViewInit().getCongressesWithPagination().map() -> data : " + JSON.stringify(data.data));
          this.meta = data.meta;
          //this.logger.info("ProjectBoxComponent.ngAfterViewInit().getCongressesWithPagination().map() -> meta : " + JSON.stringify(data.meta));
          this.onMeta.next(this.meta);
          return data.data;
        }),
      )
      .pipe(
        catchError((error) => {
          setTimeout(() => {
            this.isLoadingResults = false;
            this.isRateLimitReached = true;
            //
            this.handleAPIError(error, this.dialog, null, null);
          });
          return of([]);
        }),
      )
      .subscribe({ next: (data) => this.onTableData(data) });
  }

  ngOnDestroy() {
    //this.logger.info("ProjectBoxComponent.ngOnDestroy()");
  }

  public onTableData(data: any): void {
    this.dataSource.data = data;
    // this.reloadData();
  }

  public reloadData(): void {
    // Hack to refresh the Table
    this.paginator?._changePageSize(this.paginator.pageSize);
  }

  public loadProjects(): void {
    this.loading = true;
    this.congressesService.getCongressProjects(this.congress?.uuid).subscribe({
      next: (response: any) => {
        // //this.logger.info("ProjectBoxComponent.loadCongress() -> SUCCESS: ");
        //this.logger.info("ProjectBoxComponent.loadProjects() -> SUCCESS: " + JSON.stringify(response));
        this.loading = false;
        this.projects = response.data;
      },
      error: (error: any) => {
        this.logger.error(
          'ProjectBoxComponent.loadCongress() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public onPageProjet(): void {}

  public syncPrimaryPaginator(event: PageEvent) {
    //this.logger.info("CongresComponent.syncPrimaryPaginator()");
    this.paginator.pageIndex = event.pageIndex;
    this.paginator.pageSize = event.pageSize;
    this.paginator.page.emit(event);
  }

  public onOpenProjectInNewTab(project: any): void {
    window.open(ItemUrlFinder.getItemURL(project), '_blank');
  }

  public onOpenPage(item: any): void {
    // this.router.navigateByUrl(ItemUrlFinder.getItemURL(item));
  }
}
