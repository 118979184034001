import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ItemUrlFinder } from '../../utils/item-url-finder';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { merge, of, Subscription, fromEvent } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  startWith,
  switchMap,
  map,
  catchError,
} from 'rxjs/operators';
import { fuseAnimations } from '../../../../@fuse/animations/index';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { DatatableHelperService } from '../../../services/datatable-helper.service';
import { FileService } from '../../../services/file.service';
import { PrintService } from '../../../services/print.service';
import { Gt2ApiService } from '../../api/gt2-api.service';
import { CreatePlaceComponent } from '../../components/dialogs/create-place/create-place.component';
import { GenericAlertComponent } from '../../components/generic-alert/generic-alert.component';
import { AppRoutesService } from '../../services/app-routes.service';
import { GT2PageAbstract } from '../abstract/GT2PageAbstract';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { VenuesService } from './venues.service';
import { ApiRoutes } from '../../api/ApiRoutes';
import { Title } from '@angular/platform-browser';
import { ModulesService } from '../../services/modules.service';
import { CalendarDateFormatter, DateAdapter } from 'angular-calendar';
import { CustomDateFormatter } from '../calendar/CustomDateFormatter';
import { GT2DateAdapter } from '../../utils/GT2DateAdapter';

@Component({
  selector: 'app-venues',
  templateUrl: './venues.component.html',
  styleUrls: ['./venues.component.scss'],
  animations: fuseAnimations,
  providers: [
    { provide: DateAdapter, useClass: GT2DateAdapter },
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})
export class VenuesComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  visible: boolean = true;
  confirmDialogRef: any;
  displayedColumns = ['name', 'email', 'phone', 'internal'];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('table') table!: MatTable<any>;
  @ViewChild('mainContainer') mainContainer!: ElementRef;
  @ViewChild('filter') filter!: ElementRef;
  filterValue: string = '';
  dataSource = new MatTableDataSource();
  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;
  isFirstLoad: boolean = true;

  // --o Subscription
  fromEventSubscription!: Subscription;
  confirmDialogRefSubscription!: Subscription;
  resendInvitationSubscription!: Subscription;

  constructor(
    private venuesService: VenuesService,
    private translationLoader: FuseTranslationLoaderService,
    public dialog: MatDialog,
    public datatableHelperService: DatatableHelperService,
    public router: Router,
    private toastr: ToastrService,
    public print: PrintService,

    public fileService: FileService,
    public translate: TranslateService,
    public appRoutesService: AppRoutesService,
    public modulesService: ModulesService,
    private api: Gt2ApiService,
    private titleService: Title,
    private logger: NGXLogger,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
  }

  ngOnInit() {
    //this.titleService.setTitle(this.translate.instant("VENUES.VENUES"));
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe({
      next: () => (this.paginator.pageIndex = 0),
    });

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(startWith(null))
      .pipe(
        switchMap(() => {
          setTimeout(() => {
            this.isLoadingResults = true;
          });

          return this.venuesService.getPlacesWithPagination(
            this.sort.active || 'name',
            this.sort.direction || 'asc',
            this.paginator.pageIndex,
            this.paginator.pageSize,
            this.filterValue,
          );
        }),
      )
      .pipe(
        map((data) => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.meta.pagination.total;
          this.isFirstLoad = false;
          this.mainContainer.nativeElement.scrollTop = 0;

          return data.data;
        }),
      )
      .pipe(
        catchError(() => {
          setTimeout(() => {
            this.isLoadingResults = false;
            this.isRateLimitReached = true;
          });
          return of([]);
        }),
      )
      .subscribe({ next: (data) => (this.dataSource.data = data) });

    this.fromEventSubscription = fromEvent(this.filter?.nativeElement, 'keyup')
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => {
          if (
            this.filter?.nativeElement.value.length > 1 ||
            this.filter?.nativeElement.value === ''
          ) {
            this.paginator.pageIndex = 0;
            this.paginator?._changePageSize(this.paginator.pageSize);
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    if (this.fromEventSubscription) {
      this.fromEventSubscription.unsubscribe();
    }

    if (this.confirmDialogRefSubscription) {
      this.confirmDialogRefSubscription.unsubscribe();
    }

    if (this.resendInvitationSubscription) {
      this.resendInvitationSubscription.unsubscribe();
    }
  }

  public clearSearch(): void {
    this.filterValue = '';
    this.paginator.pageIndex = 0;
    this.paginator?._changePageSize(this.paginator.pageSize);
  }

  public syncPrimaryPaginator(event: PageEvent) {
    //this.logger.info("syncPrimaryPaginator()");
    this.paginator.pageIndex = event.pageIndex;
    this.paginator.pageSize = event.pageSize;
    this.paginator?.page.emit(event);
  }

  public onCreateNewVenue(): void {
    this.logger.debug('VenuesComponent.onCreateNewVenue()');

    // this.dialogRef = this.dialog.open(CreateRoomComponent, {
    this.dialogRef = this.dialog.open(CreatePlaceComponent, {
      width: '62%',
      minWidth: 350,
      disableClose: false,
      data: { autoRedirect: true },
      panelClass: 'scrollable-dialog',
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
        }
        this.dialogRef = null;
      },
    });
  }

  public onDeleteVenue(uuid: string): void {
    // this.logger.info("VenuesComponent.onDeleteVenue()");

    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');

    this.confirmDialogRefSubscription = this.confirmDialogRef
      .afterClosed()
      .subscribe({
        next: (result: any) => {
          if (result) {
            this.venuesService.deleteVenue({ uuid: uuid }).subscribe({
              next: (response) => {
                // this.logger.info("VenuesComponent.onDeleteVenue() -> SUCCESS: " + JSON.stringify(response));
                this.toastr.success(
                  '',
                  this.translate.instant('GENERIC.DELETE_SUCCESS'),
                );
                this.reloadData();
              },
              error: (error) => {
                this.logger.error(
                  'VenuesComponent.onDeleteVenue() -> ERROR: ' +
                    JSON.stringify(error),
                );
                // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
                this.handleAPIError(
                  error,
                  this.dialog,
                  this.toastr,
                  this.translate.instant('GENERIC.ERRORS.GENERIC'),
                );
              },
            });
          }
          this.confirmDialogRef = null;
        },
      });
  }

  public reloadData(): void {
    this.paginator?._changePageSize(this.paginator.pageSize);
  }

  public doPrint(url: string): void {
    this.print.printHTML(url).subscribe({
      next: (response: any) => {
        // this.logger.info("VenuesComponent.doPrint() -> SUCCESS: " + JSON.stringify(response));
      },
      error: (error: any) => {
        this.logger.error(
          'VenuesComponent.doPrint() -> ERROR: ' + JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public doDownload(url: string): void {
    this.fileService.downloadFile(url).subscribe({
      next: (response: any) => {
        // this.logger.info("VenuesComponent.doDownload() -> SUCCESS: " + JSON.stringify(response));
      },
      error: (error: any) => {
        this.logger.error(
          'VenuesComponent.doDownload() -> ERROR: ' + JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public onPrint(): void {
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PRINT,
      'places',
      'fr-CA',
    );
    // this.print.printHTML(url);
    this.doPrint(url);
  }

  public onOpenInNewTab(): void {
    window.open(this.router.url, '_blank');
  }

  public onDownloadPDF(): void {
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PDF,
      'places',
      'fr-CA',
    );
    // this.fileService.downloadFile(url);
    this.doDownload(url);
  }

  public onDownloadVCard(): void {
    // this.logger.info("onDownloadVCard() ");
    // const url: string = this.api.createReportURL(ApiRoutes.REPORT_VCARD, "places", "fr-CA");
    // window.location.href = url;
  }

  public onDownloadXLS(): void {
    // this.logger.info("onDownloadXLS() ");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_XLS,
      'places',
      'fr-CA',
    );
    // this.fileService.downloadFile(url);
    this.doDownload(url);
  }
}
