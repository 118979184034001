import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DeliveryRequestContentService } from '../../../api/delivery-request-content.service';
import { DeliveryRequestTypeService } from '../../../api/delivery-request-type.service';
import { WrappingService } from '../../../api/wrapping.service';
import { CrudEmployeeService } from '../../../content/crud/crud-employee/crud-employee.service';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-convert-employee-to-user',
  templateUrl: './convert-employee-to-user.component.html',
  styleUrls: ['./convert-employee-to-user.component.scss'],
  standalone: true,
  imports: [
    MatInputModule,
    MatFormFieldModule,
    CommonModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    MatButtonModule,
    FlexLayoutModule,
    TranslateModule,
    FormsModule,
  ],
})
export class ConvertEmployeeToUserComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  form: UntypedFormGroup;
  user_email?: string;
  employee_uuid?: string;
  loading: boolean = false;

  constructor(
    public override dialogRef: MatDialogRef<ConvertEmployeeToUserComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    public deliveryRequestTypeService: DeliveryRequestTypeService,
    public deliveryRequestContentService: DeliveryRequestContentService,
    public wrappingService: WrappingService,
    public employeeService: CrudEmployeeService,
    public dialog: MatDialog,
    public router: Router,
    private adapter: DateAdapter<any>,
    private toastr: ToastrService,
    private logger: NGXLogger,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    this.adapter.setLocale(this.translate.currentLang);

    this.form = this.formBuilder.group({
      email: ['', [Validators.email]],
    });

    //
    if (data && data.user_email !== null) {
      this.user_email = data.user_email;
      // this.logger.info("ConvertEmployeeToUserComponent.constructor() -> this.user_email: " + this.user_email);
    }

    if (data && data.employee_uuid !== null) {
      this.employee_uuid = data.employee_uuid;
      // this.logger.info("ConvertEmployeeToUserComponent.constructor() -> this.employee_uuid: " + this.employee_uuid);
    }
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public onConvertToUser(): void {
    // this.logger.info("ConvertEmployeeToUserComponent.onConvertToUser() ");
    this.loading = true;
    this.employeeService
      .convertToUser({
        employee_uuid: this.employee_uuid,
        email: this.user_email,
      })
      .subscribe({
        next: (response) => {
          // this.logger.info("CrudEmployeeComponent.onConvertToUser() -> SUCCESS: " + JSON.stringify(response));
          this.toastr.success(
            '',
            this.translate.instant('GENERIC.API_CALL_SUCCESS.INVITE'),
          );
          this.loading = false;
          this.dialogRef.close(response);
        },
        error: (error) => {
          this.logger.error(
            'CrudEmployeeComponent.onConvertToUser() -> ERROR: ' +
              JSON.stringify(error),
          );
          // this.toastr.error('', this.translate.instant("GENERIC.ERRORS.INVITE") );
          this.loading = false;
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.INVITE'),
          );
        },
      });
  }
}
