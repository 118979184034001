import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { FuseTranslationLoaderService } from '../../../../../../@fuse/services/translation-loader.service';
import { CrudDatatableGenericService } from '../../../../api/crud-datatable-generic.service';
import { ProductCategoryService } from '../../../../api/product-category.service';
import { Settings } from '../../../../settings/settings';
import { GT2PageAbstract } from '../../../abstract/GT2PageAbstract';
import { locale as english } from '../i18n/en-CA';
import { locale as french } from '../i18n/fr-CA';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-product-categories-create',
  templateUrl: './product-categories-create.component.html',
  styleUrls: ['./product-categories-create.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    TranslateModule,
    MatSelectModule,
  ],
  providers: [CrudDatatableGenericService],
})
export class ProductCategoriesCreateComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  // @Input() serviceURL: string;
  // @Input() createPlaceholder: string;
  serviceURL?: string;
  createPlaceholder?: string;
  ledgerAccounts: any;
  ledger_account: any;
  dialogTitle?: string;
  useEnglish: boolean = true;
  form: UntypedFormGroup;
  model: any;
  loading: boolean = false;
  newCategory_fr_CA!: string | any;
  newCategory_en_CA!: string | any;
  accountNumber?: string;

  constructor(
    public override dialogRef: MatDialogRef<ProductCategoriesCreateComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private crudDatatableGenericService: CrudDatatableGenericService,
    public productCategoriesService: ProductCategoryService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public router: Router,
    private toastr: ToastrService,
    private logger: NGXLogger,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    if (data) {
      this.dialogTitle = data.dialogTitle;
      this.serviceURL = data.serviceURL;
      this.createPlaceholder = data.createPlaceholder;
      this.ledgerAccounts = data.ledgerAccounts;
      if (data.useEnglish !== null) {
        this.useEnglish = data.useEnglish;
      }
    }

    if (this.useEnglish) {
      this.form = this.formBuilder.group({
        name_fr_CA: [
          '',
          [
            Validators.minLength(Settings.inputMinChar),
            Validators.maxLength(Settings.inputMaxCharMid),
            Validators.required,
          ],
        ],
        name_en_CA: [
          '',
          [
            Validators.minLength(Settings.inputMinChar),
            Validators.maxLength(Settings.inputMaxCharMid),
            Validators.required,
          ],
        ],
        ledger_account: '',
      });
    } else {
      this.form = this.formBuilder.group({
        name: [
          '',
          [
            Validators.minLength(Settings.inputMinChar),
            Validators.maxLength(Settings.inputMaxCharMid),
            Validators.required,
          ],
        ],
        ledger_account: '',
      });
    }
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public onCreateNew() {
    // this.logger.info("GenericCreateComponent.onCreateNew()");
    // this.logger.info("ProductCategoriesCreateComponent.onCreateNew() - this.newCategory_fr_CA: " + this.newCategory_fr_CA);
    // this.logger.info("ProductCategoriesCreateComponent.onCreateNew() - this.newCategory_en_CA: " + this.newCategory_en_CA);
    // this.logger.info("ProductCategoriesCreateComponent.onCreateNew() - this.serviceURL: " + this.serviceURL);

    this.productCategoriesService
      .create(
        this.newCategory_fr_CA,
        this.newCategory_en_CA,
        this.ledger_account,
      )
      .subscribe({
        next: (response: any) => {
          // this.logger.info("ProductCategoriesCreateComponent.onCreateNew() -> SUCCESS:" + JSON.stringify(response));
          // this.toastr.success(this.translate.instant('GENERIC_CREATE.CREATE_SUCCESS_TOAST'));
          this.toastr.success(
            this.translate.instant('PRODUCTS_CATEGORIES.CREATE_SUCCESS_TOAST'),
          );
          this.newCategory_fr_CA = null;
          this.newCategory_en_CA = null;
          this.dialogRef.close(response.data);
          // this.form.markAsUntouched();
          // this.form.markAsPristine();
          // this.reloadData();
        },
        error: (error: any) => {
          this.logger.error(
            'ProductCategoriesCreateComponent.onCreateNew() -> ERROR' +
              JSON.stringify(error),
          );
          // this.toastr.error(this.translate.instant('GENERIC_CREATE.CREATE_ERROR_TOAST'));
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC_CREATE.CREATE_ERROR_TOAST'),
          );
        },
      });
  }
}
