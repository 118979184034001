export const locale = {
  lang: 'fr-CA',
  data: {
    CLIENTS: {
      CLIENT_NUMBER: 'Numéro de Client',
      ENTERPRISE_NUMBER: "Numéro d'enterprise",
      ACTIVE: 'Actif',
      GENERAL: 'Général',
      PROFILE: 'Profil',
      SALUTATION: 'Salutation',
      SELECT_TITLE: 'Sélectionner une salutation',
      COMM_LANGUAGE: 'Langue de communication',
      DELETE_CLIENT: 'Effacer ce client',
      DELETE_ENTERPRISE: 'Effacer cette entreprise',
      EDIT_CLIENT: 'Modifier ce client',
      EDIT_ENTERPRISE: 'Modifier cette entreprise',
      CLOSE_EDIT: 'Fermer modification',
      ADD_ORDER: 'Ajouter une commande',
      ADD_CLIENT: 'Ajouter un client',
      ADD_BRANCH: 'Ajouter une succursale',
      ADD_ENTERPRISE: 'Ajouter une entreprise',
      ATTACH_ENTERPRISE: 'Attacher une entreprise',
      VISIT_ENTERPRISE: 'Voir entreprise',
      VISIT_ENTERPRISE_PARENT: 'Voir entreprise parente',
      ADD_ENTERPRISE_TO_CLIENT: 'Ajouter une entreprise à ce client individuel',
      ATTACH_CLIENT_TO_ENTERPRISE:
        'Lier ce client individuel à une entreprise / succursale',
      ADD_CLIENT_TO_BRANCH: 'AJouter un client individuel à cette succursale',
      ADD_CLIENT_TO_ENTERPRISE: 'AJouter un client individuel cette entreprise',
      ADD_BRANCH_TO_ENTERPRISE: 'AJouter une succursale à cette entreprise',
      DELETE_SUCCESS: 'Client effacé avec Succès',
      CREATE_CLIENT: 'Créer un client',
      CREATE_ENTERPRISE: 'Créer une entreprise',
      CREATE_CLIENT_ENTERPRISE: 'Créer un client & une entreprise',
      CREATE_CLIENT_ENTERPRISE_BRANCH:
        'Ajouter un client, une entreprise et une succursale',
      ROOMS: 'Salles',
      CLIENT_CATEGORIES: 'Catégorie de client',
      CLIENT_DELETE_CONFIRM: 'Êtes vous sûr de vouloir effacer ce client?',
      ENTERPRISE_DELETE_CONFIRM:
        'Êtes vous sûr de vouloir effacer cette entreprise?',
      NO_PROJECTS: 'Aucun projets pour ce client',
      NO_EVENTS: 'Aucun événement pour ce client',
      CREATE_PROPOSAL: 'Créer une proposition',
      CREATE_INVOICE: 'Créer une facture',
      CREATE_ORDER: 'Créer une commande',
      EVENT_NUMBER: "Numéro d'événement",
      EVENT_DATE: "Date d'événement",
      CAPACITY: 'Capacité',
      ROYALTY: 'Redevances',
      GENERATE_ACCOUNT_STATEMENT: 'Générer un état de compte',
      GENERATE_ACCOUNT_HISTORIC: 'Historique',
      GENERATE_ENTERPRISE_INVOICES: 'Factures par entreprise',
      PAYMENT_DEADLINE: 'Échéance de paiement',
      PAYMENT_DEADLINES: 'Échéances de paiement',
      SHOP_NAME: 'Nom de la boutique',
      SHOP_USER_LABEL: 'Utilisateur de la commande en ligne',
      CONTACT: 'Contact',
      IS_CORPO: 'Compte commercial',
      NO_ENTERPRISE_INVOICES: 'Aucune facture pour cette entreprise',
      ENTERPRISE_INVOICES: 'Encaissement',
      ENTERPRISE_INVOICE_NUMBER: '# Facture',
      PAID_AMOUNT: 'Montant payé',
      BALANCE: 'Balance',
      CLIENT_NAME: 'Nom client',
      DO_CASHING: 'Encaisser',
      CASHING_TITLE: 'Nouvelle encaissement - facture',
      SELECTED_INVOICES: 'Factures sélectionnées',
      DEPOSIT_TITLE: 'Nouvelle encaissement - Dépôt',
      DEPOSIT_EDIT_TITLE: 'Modifier encaissement - Dépôt',
      INVOICE_TITLE: 'Nouvelle encaissement - Facture',
      INVOICE_EDIT_TITLE: 'Modifier encaissement - Facture',
      MESSAGE_TITLE: 'Encaissement',
      MESSAGE_MESSAGE:
        'Vous n’avez pas de demande de dépôt disponible à l’encaissement, ni de facture',
      PAYMENT_MODES: 'Mode de paiement',
      REFERENCE_NUMBER: 'Numéro de référence',
      DEPOSIT_REQUEST: 'Demande de dépôt',
      TOTAL_UNPAID_INVOICES: 'Total des factures impayées:',
      CASHING_AMOUNT: "Entrer le montant de l'encaissement",
      TOTAL_INVOICES_SELECTED: 'Total des factures sélectionnées',
      CASHING_BALANCE: "Balance de l'encaissement",
      CHECK_INVOICES_TO_BE_PAID: 'COCHER LES FACTURES À PAYER',
    },
    REPORTS_HELP: {
      TITLE: 'Rapports',
      REPORT_CLIENT_STATEMENT_CLIENT:
        'Les dates de début et de fin sont applicables dans l’état de compte généré pour un client ou une entreprise.',
      //'Ce rapport comporte 2 lignes de pliage et un format pour l\'affichage de l\'adresse du client au travers d\'une fenêtre d\'enveloppe. ' +
      //    'Pour obtenir un résultat optimisé, veiller configurer votre impression sans les marges. ',
    },
  },
};
