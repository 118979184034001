import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '../../services/config.service';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'fuse-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class FuseSearchBarComponent implements OnInit, OnDestroy {
  collapsed: boolean;
  fuseConfig: any;
  @ViewChild('searchButton', { static: true }) searchButton?: ElementRef;
  @ViewChild('searchInput', { static: true }) searchInput?: ElementRef;

  // Listener pour capturer les touches CMD + K
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    // Vérifie si CMD (ou CTRL sur Windows) + K sont pressés
    if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
      event.preventDefault(); // Empêche les comportements par défaut du navigateur
      this.searchButton?.nativeElement.click(); // Fait le focus sur l'input
      setTimeout(() => {
        this.searchInput?.nativeElement.focus();
      }, 200);
    }
  }

  @Output()
  input: EventEmitter<any>;
  @Output() onSearch: EventEmitter<any> = new EventEmitter();
  @Output() onInput: EventEmitter<any> = new EventEmitter();

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private logger: NGXLogger,
  ) {
    // Set the defaults
    this.input = new EventEmitter();
    this.collapsed = true;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (config: any) => {
          this.fuseConfig = config;
        },
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(undefined);
    this._unsubscribeAll.complete();
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Collapse
   */
  collapse(): void {
    this.collapsed = true;
  }

  /**
   * Expand
   */
  expand(): void {
    this.collapsed = false;
  }

  /**
   * Search
   *
   * @param event
   */
  search(event: any): void {
    this.input.emit(event.target.value);
  }

  onSearchInput(event: any) {
    const value = event.target.value;
    // this.logger.info("FuseSearchBarComponent.onSearch() -> value: " + event.target.value);
    this.onInput.emit(value);
  }

  onKey(event: any) {
    // this.logger.info("FuseSearchBarComponent.onKey() -> key: " + event.keyCode);
    if (event.keyCode === 13) {
      const value = event.target.value;
      // this.onSearch.emit(value);
      this.onCallSearch(value);
    } else if (event.keyCode === 27) {
      this.collapse();
    }
  }

  public onCallSearch(value: any): void {
    // this.logger.info("FuseSearchBarComponent.onCallSearch() -> value: " + value);
    // this.onSearch.emit(value);
    this.onSearch.emit({ id: 'TEST', value: value });
    this.collapse();
  }

  public onCallSearchTest(value: any): void {
    // this.logger.info("FuseSearchBarComponent.onCallSearch() -> value: " + value);
    this.onSearch.emit({ id: 'TEST', value: value });
    this.collapse();
  }
}
