import { IconsService } from '../../../services/icons.service';
import { FileService } from '../../../../services/file.service';
import { ReportFactoryService } from './../../../reports/report-factory.service';
import { Router, RouterLink } from '@angular/router';
import { ItemUrlFinder } from '../../../utils/item-url-finder';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { AppRoutesService } from './../../../services/app-routes.service';
import { DatatableHelperService } from '../../../../services/datatable-helper.service';
import { Subscription, of, merge } from 'rxjs';
import { startWith, switchMap, catchError, map } from 'rxjs/operators';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {
  MatTable,
  MatTableDataSource,
  MatTableModule,
} from '@angular/material/table';
import { Gt2ApiService } from '../../../api/gt2-api.service';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import {
  CongressesService,
  CongressModel,
} from './../../../api/congresses.service';
import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CdkTableModule } from '@angular/cdk/table';
import { MatTabsModule } from '@angular/material/tabs';
import { TableSizePipeModule } from '../../../modules/table-size.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-clients-invoices-box',
  templateUrl: './clients-invoices-box.component.html',
  styleUrls: ['./clients-invoices-box.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatProgressBarModule,
    MatPaginatorModule,
    MatTableModule,
    MatButtonModule,
    TableSizePipeModule,
    RouterLink,
    FlexLayoutModule,
    MatTabsModule,
    CdkTableModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  animations: fuseAnimations,
})
export class ClientsInvoicesBoxComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  //
  @Input() congress!: CongressModel;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('table') table!: MatTable<any>;
  //
  loading: boolean = false;
  invoices: any[] = [];
  //
  // displayedColumns = ["name", "date_start", "client", "actions"];
  displayedColumns = ['name', 'open', 'actions'];
  filterValue: string = '';
  dataSource = new MatTableDataSource();
  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;
  fromEventSubscription!: Subscription;
  isFirstLoad: boolean = true;
  meta: any;
  @Output() onMeta = new EventEmitter<any>();
  //
  constructor(
    private congressesService: CongressesService,
    private translationLoader: FuseTranslationLoaderService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private logger: NGXLogger,
    private api: Gt2ApiService,
    public dialog: MatDialog,
    public datatableHelperService: DatatableHelperService,
    public appRoutesService: AppRoutesService,
    public router: Router,
    public reportFactoryService: ReportFactoryService,
    public fileService: FileService,
    public iconsService: IconsService,
  ) {
    super();
  }

  ngOnInit() {
    //this.logger.info("ClientsInvoicesBoxComponent.ngOnInit()");
  }

  ngAfterViewInit() {
    //this.logger.info("ClientsInvoicesBoxComponent.ngAfterViewInit()");

    this.sort.sortChange.subscribe({
      next: () => (this.paginator.pageIndex = 0),
    });

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(startWith(null))
      .pipe(
        switchMap(() => {
          setTimeout(() => {
            this.isLoadingResults = true;
          });

          // TODO: handle error here, how do?
          return this.congressesService.getClientsInvoicessWithPagination(
            this.congress.uuid,
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize,
          );
        }),
      )
      .pipe(
        map((data) => {
          // Flip flag to show that loading has finished.
          // this.isLoadingResults = false;
          setTimeout(() => {
            this.isLoadingResults = false;
          });
          this.isRateLimitReached = false;
          this.resultsLength = data.meta.pagination.total;
          // this.mainContainer.nativeElement.scrollTop = 0;
          //this.logger.info("CongressesComponent.ngAfterViewInit().getClientsInvoicessWithPagination().map() -> data : " + JSON.stringify(data.data));
          this.meta = data.meta;
          this.onMeta.next(this.meta);
          return data.data;
        }),
      )
      .pipe(
        catchError((error) => {
          setTimeout(() => {
            this.isLoadingResults = false;
            this.isRateLimitReached = true;
            //
            //this.logger.info("CongressesComponent.ngAfterViewInit().getClientsInvoicessWithPagination().catch() -> error : " + error);
            this.handleAPIError(error, this.dialog, null, null);
          });
          return of([]);
        }),
      )
      .subscribe({ next: (data) => (this.dataSource.data = data) });
  }

  ngOnDestroy() {
    //this.logger.info("ClientsInvoicesBoxComponent.ngOnDestroy()");
  }

  public loadClientsInvoices(): void {
    this.loading = true;
    this.congressesService.getClientsInvoices(this.congress?.uuid).subscribe({
      next: (response: any) => {
        // //this.logger.info("ClientsInvoicesBoxComponent.loadClientsInvoices() -> SUCCESS: ");
        //this.logger.info("ClientsInvoicesBoxComponent.loadClientsInvoices() -> SUCCESS: " + JSON.stringify(response));
        this.loading = false;
        this.invoices = response.data;
      },
      error: (error: any) => {
        this.logger.error(
          'ClientsInvoicesBoxComponent.loadClientsInvoices() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public syncPrimaryPaginator(event: PageEvent) {
    //this.logger.info("ClientsInvoicesBoxComponent.syncPrimaryPaginator()");
    this.paginator.pageIndex = event.pageIndex;
    this.paginator.pageSize = event.pageSize;
    this.paginator.page.emit(event);
  }

  public onOpenInNewTab(item: any): void {
    window.open(ItemUrlFinder.getItemURL(item), '_blank');
  }

  public onOpenPage(item: any): void {
    this.router.navigateByUrl(ItemUrlFinder.getItemURL(item));
  }

  // public onOpenClient(item): void {
  //     this.router.navigateByUrl(ItemUrlFinder.getItemURL(item));
  // }
}
