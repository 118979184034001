<h1 matDialogTitle>{{ "SHOPS.ADD_MULTI_POINT_TAKEOUT" | translate }}</h1>

<div
  fxLayout="column"
  fxLayoutAlign="center start"
  [formGroup]="form"
  (keyup.enter)="form.valid && !loading ? onCreatePoint() : null"
  class="mt-8"
  sty
>
  <mat-form-field fxFlex="100%" class="item-full-width">
    <mat-label>{{ "SHOPS.NAME_FR" | translate }}</mat-label>
    <input
      matInput
      fxFlex="100%"
      formControlName="name_fr_CA"
      autocomplete="nope"
      type="text"
      [(ngModel)]="point.name_fr_CA"
      required
    />
    <mat-error *ngIf="form.controls['name_fr_CA'].invalid"
      >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
    </mat-error>
  </mat-form-field>

  <mat-form-field fxFlex="100%" class="item-full-width">
    <mat-label>{{ "SHOPS.NAME_EN" | translate }}</mat-label>
    <input
      matInput
      fxFlex="100%"
      formControlName="name_en_CA"
      autocomplete="nope"
      type="text"
      [(ngModel)]="point.name_en_CA"
      required
    />
    <mat-error *ngIf="form.controls['name_en_CA'].invalid"
      >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
    </mat-error>
  </mat-form-field>
</div>

<div *ngIf="loading" fxLayout="row" fxLayoutAlign="center start">
  <mat-spinner [diameter]="28" class="mt-20"></mat-spinner>
</div>

<div
  mat-dialog-actions
  class="mt-16 gt2-buttons-theme"
  fxLayout="row"
  fxLayoutAlign="center start"
>
  <button
    mat-raised-button
    [disabled]="!form.valid || loading"
    color="accent"
    class="mr-16"
    (click)="onCreatePoint()"
  >
    {{ "GENERIC.SAVE" | translate }}
  </button>

  <button
    mat-raised-button
    color="primary"
    (click)="dialogRef.close(false)"
    class=""
  >
    {{ "GENERIC.CANCEL" | translate }}
  </button>
</div>
