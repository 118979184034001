import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Gt2ApiService } from '../../api/gt2-api.service';
import { ApiRoutes } from '../../api/ApiRoutes';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class FaqService implements Resolve<any> {
  faqs: any;
  onFaqsChanged: BehaviorSubject<any>;

  /*
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(
    private api: Gt2ApiService,
    private logger: NGXLogger,
    private _httpClient: HttpClient,
  ) {
    // Set the defaults
    this.onFaqsChanged = new BehaviorSubject({});
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getFaqs()]).then(() => {
        resolve();
      }, reject);
    });
  }

  /**
   * Get faqs
   */
  getFaqs(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const faqURL: string = this.api.createUrl(ApiRoutes.FAQ);
      //this.logger.info("FaqService.getFaqs() -> faqURL: " + faqURL);
      this._httpClient.get(faqURL).subscribe({
        next: (response: any) => {
          this.faqs = response;
          this.onFaqsChanged.next(this.faqs);
          resolve(this.faqs);
        },
        error: reject,
      });
    });
  }
}
