import { GT2CalendarUtils } from './../../utils/GT2CalendarUtils';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  EventEmitter,
  Output,
} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import {
  MatCalendarCellCssClasses,
  MatCalendar,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';

@Component({
  selector: 'app-business-days',
  templateUrl: './business-days.component.html',
  styleUrls: ['./business-days.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    MatButtonModule,
    MatInputModule,
    MatDatepickerModule,
    TranslateModule,
    Gt2PrimengModule,
    NgxMaterialTimepickerModule,
    MatRadioModule,
  ],
})
export class BusinessDaysComponent implements OnInit {
  @ViewChild('daysCalendar') daysCalendar?: MatCalendar<Date>;

  @Input() businessDays?: BusinessDayModel[] | any;
  @Output() businessDaysChange: EventEmitter<any> = new EventEmitter();
  @Input() selectedBusinessDay?: BusinessDayModel | any;
  @Input() editMode: boolean = false;

  selectedBusinessDayCopy?: BusinessDayModel | any;
  selectedDateStr?: string;
  selectedDate?: Date;
  calendarDayState: number = 0;
  calendarDayTakeOutState: number = 0;
  dateOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  dateClass = (d: Date): MatCalendarCellCssClasses => {
    if (d instanceof Date && !isNaN(d.getTime())) {
      const date = d.getUTCDate();
      if (this.businessDays) {
        const d1 = this.businessDays.find((data: any) => {
          const d2: Date = new Date(data.affected_date);
          return (
            d2.getUTCMonth() === d.getUTCMonth() &&
            d2.getUTCDate() === date &&
            d2.getUTCFullYear() === d.getUTCFullYear()
          );
        });
        if (d1) {
          this.businessDaysChange.emit();
          return 'data-custom-date-class';
        } else {
          this.businessDaysChange.emit();
          return '';
        }
      } else {
        return '';
      }
    }
    return '';
  };

  constructor(
    public logger: NGXLogger,
    public translate: TranslateService,
    private adapter: DateAdapter<any>,
  ) {
    this.adapter.setLocale(this.translate.currentLang);
  }

  ngOnInit(): void {
    this.selectedDate = new Date();
    this.selectedDateStr = this.selectedDate.toDateString();
  }

  public parentInit(): void {
    this.onBusinessDaySelect(this.selectedDate);
  }

  public refreshCalendar(): void {
    // this.logger.info("BusinessDaysComponent.refreshCalendar() ...");
    if (this.daysCalendar) {
      setTimeout(() => {
        this.daysCalendar?.updateTodaysDate();
      }, 500);
      // this.daysCalendar.updateTodaysDate();
    } else {
      //"BusinessDaysComponent.refreshCalendar() -> instance 'daysCalendar' NOT FOUND!");
    }
  }

  public onBusinessDaySelect(event: any): void {
    // this.logger.info("BusinessDaysComponent.onBusinessDaySelect() -> businessDays: " + JSON.stringify(this.businessDays));
    // this.logger.info("BusinessDaysComponent.ngOnInit() -> daysCalendar: " + this.daysCalendar);
    // this.logger.info("BusinessDaysComponent.onBusinessDaySelect() -> event: " + event);
    this.selectedDateStr = event;
    this.selectedDate = new Date(event);

    this.selectedBusinessDay = this.businessDays?.find((data: any) => {
      const d2: Date = new Date(data.affected_date);
      return (
        d2.getUTCMonth() === this.selectedDate?.getUTCMonth() &&
        d2.getUTCDate() === this.selectedDate.getUTCDate() &&
        d2.getUTCFullYear() === this.selectedDate.getUTCFullYear()
      );
    });
    this.businessDaysChange.emit();

    if (!this.selectedBusinessDay) {
      this.createNewSelectedBusinessDay();
    }

    this.selectedBusinessDayCopy = this.selectedBusinessDay;

    if (!this.selectedBusinessDay?.open_business_type) {
      this.calendarDayState = 0;
    } else if (this.selectedBusinessDay.open_business_type === 'TYPE_CLOSE') {
      this.calendarDayState = 1;
    } else if (
      this.selectedBusinessDay.open_business_type === 'TYPE_OPEN_CUSTOM_HOURS'
    ) {
      this.calendarDayState = 2;
    }

    if (!this.selectedBusinessDay?.takeout_business_type) {
      this.calendarDayTakeOutState = 0;
    } else if (
      this.selectedBusinessDay.takeout_business_type === 'TYPE_CLOSE'
    ) {
      this.calendarDayTakeOutState = 1;
    } else if (
      this.selectedBusinessDay.takeout_business_type ===
      'TYPE_OPEN_CUSTOM_HOURS'
    ) {
      this.calendarDayTakeOutState = 2;
    }

    // const d: Date = new Date(event);
    // this.logger.info("BusinessDaysComponent.onBusinessDaySelect() -> d: " + d);
    // this.logger.info("BusinessDaysComponent.onBusinessDaySelect() -> this.selectedDate: " + this.selectedDate);
    // this.logger.info("BusinessDaysComponent.onBusinessDaySelect() -> this.selectedBusinessDay: " + JSON.stringify(this.selectedBusinessDay));
  }

  public createNewSelectedBusinessDay(): void {
    this.selectedBusinessDay = {
      uuid: null,
      affected_date: GT2CalendarUtils.formatDateForAPI(this.selectedDateStr),
      open_business_custom_hour_start_at: null,
      open_business_custom_hour_end_at: null,
      takeout_business_custom_hour_start_at: null,
      takeout_business_custom_hour_end_at: null,
      open_business_type: null,
      takeout_business_type: null,
    };

    this.selectedBusinessDayCopy = this.selectedBusinessDay;
  }

  public onDaysRadioChange(event: any): void {
    // this.logger.info("BusinessDaysComponent.onDaysRadioChange() -> event: " + event);
    // this.logger.info("BusinessDaysComponent.onTakeoutRadioChange() -> calendarDayState: " + this.calendarDayState);
    // this.logger.info("BusinessDaysComponent.onTakeoutRadioChange() -> selectedBusinessDay: " + JSON.stringify(this.selectedBusinessDay));
    // this.logger.info("BusinessDaysComponent.onTakeoutRadioChange() -> selectedBusinessDay.open_business_custom_hour_start_at: " + this.selectedBusinessDay.open_business_custom_hour_start_at);

    if (this.calendarDayState === 0) {
      this.selectedBusinessDay.open_business_custom_hour_start_at = null;
      this.selectedBusinessDay.open_business_custom_hour_end_at = null;
      this.selectedBusinessDay.open_business_type = null;
    } else if (this.calendarDayState === 1) {
      this.selectedBusinessDay.open_business_custom_hour_start_at = null;
      this.selectedBusinessDay.open_business_custom_hour_end_at = null;
      this.selectedBusinessDay.open_business_type = 'TYPE_CLOSE';
    } else if (this.calendarDayState === 2) {
      this.selectedBusinessDay.open_business_custom_hour_start_at =
        this.selectedBusinessDayCopy.open_business_custom_hour_start_at;
      this.selectedBusinessDay.open_business_custom_hour_end_at =
        this.selectedBusinessDayCopy.open_business_custom_hour_end_at;
      this.selectedBusinessDay.open_business_type = 'TYPE_OPEN_CUSTOM_HOURS';
    }
  }

  public onDaysTakeoutRadioChange(event: any): void {
    // this.logger.info("BusinessDaysComponent.onTakeoutRadioChange() -> event: " + event);
    // this.logger.info("BusinessDaysComponent.onTakeoutRadioChange() -> calendarDayTakeOutState: " + this.calendarDayTakeOutState);

    if (this.calendarDayTakeOutState === 0) {
      this.selectedBusinessDay.takeout_business_custom_hour_start_at = null;
      this.selectedBusinessDay.takeout_business_custom_hour_end_at = null;
      this.selectedBusinessDay.takeout_business_type = null;
    } else if (this.calendarDayTakeOutState === 1) {
      this.selectedBusinessDay.takeout_business_custom_hour_start_at = null;
      this.selectedBusinessDay.takeout_business_custom_hour_end_at = null;
      this.selectedBusinessDay.takeout_business_type = 'TYPE_CLOSE';
    } else if (this.calendarDayTakeOutState === 2) {
      this.selectedBusinessDay.takeout_business_custom_hour_start_at =
        this.selectedBusinessDayCopy.takeout_business_custom_hour_start_at;
      this.selectedBusinessDay.takeout_business_custom_hour_end_at =
        this.selectedBusinessDayCopy.takeout_business_custom_hour_end_at;
      this.selectedBusinessDay.takeout_business_type = 'TYPE_OPEN_CUSTOM_HOURS';
    }
  }
}

// TYPE_OPEN_CUSTOM_HOURS
// TYPE_CLOSE
export interface BusinessDayModel {
  uuid?: string;
  affected_date?: string | null;
  open_business_custom_hour_start_at: string;
  open_business_custom_hour_end_at?: string;
  takeout_business_custom_hour_start_at?: string;
  takeout_business_custom_hour_end_at?: string;
  open_business_type?: string;
  takeout_business_type?: string;
}
