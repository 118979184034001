import { WrappingService } from './../../api/wrapping.service';
import { NGXLogger } from 'ngx-logger';
import { TaxesService } from './../../api/taxes.service';
import { LedgerAccountService } from './../../api/ledger-account.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ShopsService } from './../../api/shops.service';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { MatInputModule } from '@angular/material/input';
import { Gt2SelectComponent } from '../gt2-select/gt2-select.component';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-shop-preference',
  templateUrl: './shop-preference.component.html',
  styleUrls: ['./shop-preference.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    Gt2PrimengModule,
    MatInputModule,
    Gt2SelectComponent,
    MatButtonModule,
    TranslateModule,
  ],
})
export class ShopPreferenceComponent implements OnInit {
  @Input() editMode: boolean = false;
  @Input() shopPreference: any;

  constructor(
    private shopsService: ShopsService,
    public logger: NGXLogger,
    public translate: TranslateService,
    public ledgerAccountService: LedgerAccountService,
    public wrappingService: WrappingService,
    public taxesService: TaxesService,
  ) {}

  ngOnInit(): void {}

  public onLedgerTipNoTaxSelected(ledgerAccount: any): void {
    this.shopPreference.ledger_account_tips_no_tax = ledgerAccount;
  }

  public onDeliveryTaxSelected(tax: any): void {
    this.shopPreference.delivery_tax = tax;
  }

  public onLedgerAccountChange(event: any): void {
    this.shopPreference.ledger_account_delivery = event;
  }

  public onOrderWrappingSelected(wrapping: any): void {
    this.shopPreference.order_wrapping = wrapping;
  }

  public onDeliveryWrappingSelected(wrapping: any): void {
    this.shopPreference.delivery_request_wrapping = wrapping;
  }

  public onTakeoutWrappingSelected(wrapping: any): void {
    this.shopPreference.takeout_request_wrapping = wrapping;
  }
}
