export const locale = {
  lang: 'en-CA',
  data: {
    SHOPS: {
      SHOPS: 'Shops',
      SHOP: 'Shop',
      CONFIGURATION: 'General configurations',
      SHOP_TYPE: 'Type',
      SHOP_PRODUCT_TYPE: 'Product type',
      WEB_SHOP: 'Web shop',
      WOOCOMMERVE_SHOP: 'Woocommerce',
      GT_STORE_URL: 'Shop URL',
      STORE_URL: 'URL Online ordering shop',
      VERIFY_SSL: 'Verify SSL',
      CONSUMER_KEY: 'Consumer key',
      CONSUMER_SECRET: 'Consumer secret',
      TEST_WOO_CONNECTION: 'Test connection',
      CONNECTION: 'Connection',
      CONNECTION_SUCCESS: 'Connection success',
      CATEGORIES: 'Categories',
      INTEGRATION_TYPE: 'Integration type',
      SELL_TYPE: 'Sales mode',
      TIPS: 'Tips',
      TERMS_CONDITIONS: 'Terms and conditions',
      CHAR_COUNT_MESSAGE: '{{min}} of {{max}} characters',
      MESSAGES: 'Messages',
      IMAGE_MESSAGE: 'Messages Images',
      START_MESSAGE: 'Welcome message',
      START_MESSAGE_HINT:
        'Write here a message to be displayed when a user enters your page',
      NO_SERVICES_MESSAGE: 'Message service unavailable',
      NO_SERVICES_MESSAGE_HINT:
        'Write here a message to be displayed if the service is unavailable',
      DESCRIPTION: 'Description',
      MIN_TO_ORDER: 'Minimum amount ($) for a delivery',
      MIN_TO_TAKEOUT: 'Minimum amount ($) for a takeout',
      APPLICATION_FEE: 'Add the 2$ transaction fee to the order',
      APPLICATION_FEE_HINT:
        'Vous pouvez inclure automatiquement le frais d’application à chacune des commandes. Qu’elle soit incluse ou non à la commande, ce frais vous sera facturé mensuellement',
      NOTIFICATIONS: 'Notification',
      NOTIFICATIONS_BY_ROLE: 'Notification by role',
      NOTIFICATIONS_USER_CORPORATE: 'Notification by corporate user',
      NOTIFICATIONS_MENU: 'Notifications and corporate',
      NOTIFICATIONS_ORDERS: 'Notification nouvelle commande',
      NOTIFICATIONS_CORPO: 'Notification for corporate user',
      NOTIFY_SALE: 'Accounting',
      NOTIFY_ADMIN: 'Administration',
      NOTIFY_OWNER: 'Owner',
      NOTIFY_GOD: 'God',
      EMAILS_TO_NOTIFY: 'New order - emails to notify',
      EMAILS_TO_NOTIFY_ALERT_TITLE: 'Emails field',
      EMAILS_TO_NOTIFY_ALERT_MESSAGE:
        'Enter the list of emails separated by a comma (,).\n\n All emails must be valid, if an email is not valid, then the list cannot be saved',
      AUTOMATIC: 'Automatic',
      WITH_NOTIFICATION: 'By notification',
      NOTIFICATION_CORPORATE_DIRECT: 'Automatic company registration',
      NOTIFICATION_CORPORATE_NOTIFICATION2:
        'You will be notified when a user with a company registers. You will need to validate the request to be associated with the company',
      NOTIFICATION_CORPORATE_NOTIFICATION:
        'You will be notified when a user with a company registers.<br>You will need to validate the request to be associated with the company',
      NOTIFICATION_CORPORATE_NONE: 'None',
      USER_MODE_CORPORATE: 'Corporate user mode',
      NONE: '-- None --',
      ACCEPT_TIPS: 'Accept customer’s tips',
      VISIT: 'Visit',
      DEFAULT_OPTION: 'Default option',
      INTERNAL_SHOP: 'Internal shop',
      TIPS_HINT: 'The value is i percentage without the symbol.',
      LEDGER_ACCOUNT_TIPS_NO_TAX: 'Default tips no tax ledger account',
      DEFAULT_DELIVERY_TAX: 'Default delivery tax',
      OPTION_1: 'Option #1',
      OPTION_2: 'Option #2',
      OPTION_3: 'Option #3',
      BUSINESS_HOURS: 'Business hours',
      TAKEOUT_HOURS: 'Takeout hours',
      OFF: 'Off',
      BUSINESS_DAYS: 'Calendar schedule',
      NO_CHANGE: 'No change',
      CLOSED: 'Close',
      OPEN: 'Open',
      DELIVERY: 'Delivery',
      TAKE_OUT: 'Takeout',
      SCHEDULE: 'Schedule',
      PREFERENCE: 'Preferences',
      LEDGER_ACCOUNT_DELIVERY: 'Default delivery ledger account',
      DEFAULT_WRAPPING_ORDER: 'Default order wrapping',
      DEFAULT_WRAPPING_DELIVERY: 'Default delivery request wrapping',
      DEFAULT_WRAPPING_TAKOUT: 'Default takeout request wrapping',
      THEME: 'Theme',
      THEME_SELECT: 'Select default theme',
      THEME_MODE: 'Theme mode',
      SHOW_CTR_THEME: 'Show theme selection',
      SHOW_CTR_THEME_MODE: 'Show theme mode',
      LIGHT: 'Light',
      DARK: 'Dark',
      THEME_MODE_LIGHT: 'Light theme',
      THEME_MODE_DARK: 'Dark theme',
      DARK_THEME: 'Select default theme mode',
      SHOW_CTRL_THEME_HINT: 'Availability for the customer to change themes',
      SHOW_CTRL_THEME_MODE_HINT:
        'Availability for the customer to change theme modes',
      RTE_SCHEDULE: 'Delivery and takeout schedule',
      ON_PAUSE: ' - On pause',
      WITH_DELIVERY: 'With delivery',
      WITH_TAKEOUT: 'With takeout',
      ORDER_TIMELINE: 'Order taking period',
      PERIOD_DURATION: 'Period duration',
      STACK_AMOUNT: 'Number of cycles to offer',
      DAY_START: 'Start day for the period',
      TIME_START: 'Period finish and start hour',
      OPEN_DAYS: 'Days off',
      DELIVERY_START_DELAY: 'Delivery time in days',
      DELIVERY_START_DURATION: 'Duration of delivery cycle, in days',
      TAKEOUT_START_DELAY: 'Pickup time in days',
      TAKEOUT_START_DURATION: 'Duration of takeout cycle, in days',
      DAY_1_START_HOUR_DELIVERY: 'Start time of delivery cycle',
      DAY_1_START_HOUR_TAKEOUT: ' Start time of pick up cycle',
      LAST_DAY_DELIVERY_HOUR: 'End time of delivery cycle?',
      LAST_DAY_TAKEOUT_HOUR: 'End time of pick up cycle',
      PAUSE: 'PAUSE',
      UNPAUSE: 'UNPAUSE',
      GA_KEY: 'Google Analytics key',
      DELIVERY_RULES: 'Delivery rules',
      COST_MODE: 'Mode of delivery fee calculation',
      LIMIT_KM: 'Distance limite',
      RATE_MODE_FIXED_RATE: 'Fixed rate',
      RATE_MODE_RATE_PER_KM: 'Per kilometer',
      DISTANCE_MODE: 'Mode of delivery distance calculation',
      DISTANCE_MODE_GOOGLE_ROUTE: 'By Google Route',
      DISTANCE_MODE_CROW_FLIES: 'As the crow flies',
      BRACKET_MODE: 'Level',
      BRACKET_MODE_SINGLE: 'Unique',
      BRACKET_MODE_MULTI: 'Multiple',
      BRACKET_MODE_SINGLE_LABEL: 'Unique level',
      BRACKET_MODE_MULTI_LABEL: 'Multiple level',
      RATE: 'Rate:',
      MIN_BRACKET_DISTANCE: 'Distance minimum du palier',
      MAX_BRACKET_DISTANCE: 'Distance maximum du palier',
      ADD_BRACKETS: 'Add a bracket',
      STORE_URL_HINT:
        "The URL of the online order once it has been integrated into the customer's site (optional)",
      SHOW_NEXT_STACK: 'Voir les prochaines plages planifiées',
      SCHEDULE_RANGE: 'Time slot',
      MONERIS: 'Moneris',
      MONERIS_MERCHANT_ID: 'Merchant ID',
      MONERIS_MERCHANT_KEY: 'Merchant key',
      MONERIS_MERCHANT_PROFILES: 'Profiles',
      MONERIS_MERCHANT_ADD_PROFILES: 'Add a profile',
      MONERIS_MERCHANT_PROFILE_ID: 'Profile ID',
      MONERIS_MERCHANT_ID_HINT:
        'The merchant id text must contain at least 10 characters.',
      MONERIS_MERCHANT_KEY_HINT:
        'The merchant key text must contain at least 20 characters.',
      MONERIS_PROFILE_ID_HINT:
        'The profile id text must contain at least 15 characters.',
      MONERIS_PROFILE_URL: 'Profile domain (URL)',
      MONERIS_PROFILE_ID: 'Profile ID',
      MONERIS_TEST_MODE: 'Test Mode',
      STRIPE: 'Stripe',
      STRIPE_API_KEY: 'Stripe api key',
      STRIPE_SECRET_KEY: 'Stripe secret key',
      GLOBAL_PAYMENT: 'Global payment',
      GP_MERCHANT_ID: 'Merchant ID',
      GP_ACCOUNT_ID: 'Account ID',
      GP_SHARED_SECRET: 'Shared secret',
      GP_REABATE_PASSWORD: 'Rebate password',
      SHOW_SWITCH_LOCALE: 'Bilingual shop French/English',
      FRENCH_ONLY: 'French only',
      BILINGUAL: 'Bilingual',
      MULTI_POINT_TAKEOUT: 'Multi-location takeout',
      POINT_TAKEOUT: 'Takeout location',
      ACCEPT_MULTI_TAKEOUT: 'Accept multi-takeout',
      USE_MULTI_TAKEOUT_INPUT: 'Use a simple text field',
      MULTI_TAKEOUT_OPTION: 'Multi-takeout option',
      ADD_MULTI_POINT_TAKEOUT: 'Add takeout location',
      NAME_FR: 'Name (french)',
      NAME_EN: 'Name (english)',
      EDIT_DELIVERY: 'Edit delivery',
      EDIT_TAKEOUT: 'Edit takeout',
      RTE_SCHEDULE_V2: 'Delivery and takeout schedule',
      EDIT_SCHEDULE: 'Edit',
      RTE_SCHEDULE_TABLE: {
        start_at: 'Start at',

        end_at: 'End at',

        delivery_start_at: 'Delivery start at',

        delivery_end_at: 'Delivery end at',

        takeout_start_at: 'Takeout start at',

        takeout_end_at: 'Takeout end at',
      },
      SHOW_ON_HOME: 'Show on home page',
      REDIRECT_ULR: 'Redirect URL',
      CLICK_URL: 'URL redirection on image click',
      CLICK_TARGET: 'Target',
      SELECT_IMG_FR: 'Select an image FR',
      SELECT_IMG_M_FR: 'Select an image for mobile FR',
      SELECT_IMG_EN: 'Select an image EN',
      SELECT_IMG_M_EN: 'Select an image for mobile EN',
      SELECT_IMG_RULES:
        'JPEG or PNG image, 250px width and recommended height of 330px',
      SELECT_IMG_RULES_2:
        'JPEG or PNG image, 1200px width and recommended height of 450px (minimum: 50px, maximum: 600px).',
      SCHEDULE_MODE_TITLE: 'Select a Display mode',
      SCHEDULE_MODE: 'Display mode',
      SCHEDULE_MODE_SENTENCE: 'Display by phrase',
      SCHEDULE_MODE_DATE_TIME: 'Display by date and time',
    },
  },
};
