<div>
  <!-- THE CODE BELOW HAS BEEN DUPLICATED IN CreatePlaceComponent. If you find a bug, look there too for applying a fix -->
  <div *ngIf="sectors.length === 0" class="red-fg mat-caption">
    {{ "SECTORS.NO_SECTORS" | translate }}
  </div>

  <mat-form-field fxFlex="100%">
    <mat-label>{{ "SECTORS.CHOOSE_SECTOR" | translate }}</mat-label>
    <mat-select
      (selectionChange)="onSectorChange($event)"
      [(ngModel)]="selectedSector.uuid"
      [disabled]="sectors.length === 0"
    >
      <mat-option [value]="null">{{ "GENERIC.NONE" | translate }}</mat-option>
      <mat-option *ngFor="let sector of sectors" [value]="sector.uuid">{{
        sector.name
      }}</mat-option>
    </mat-select>
  </mat-form-field>

  <!--<mat-select *ngIf="!selectedSector" placeholder="{{ 'SECTORS.CHOOSE_SECTOR' | translate }}"-->
  <!--(selectionChange)="onSectorChange($event)"-->
  <!--[(ngModel)]="selectedSector.uuid"-->
  <!--[disabled]="sectors.length === 0">-->
  <!--<mat-option *ngFor="let sector of sectors" [value]="sector.uuid">{{sector.name}}</mat-option>-->
  <!--</mat-select>-->
</div>
