<div
  id="congress-page"
  class="page-layout simple left-sidebar inner-sidebar inner-scroll"
>
  <!-- HEADER -->
  <div class="gt2-theme">
    <div
      class="header accent pl-24 pr-24 h-80 mat-elevation-z1"
      fusePerfectScrollbar
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <div fxLayout="row" fxLayoutAlign="start center">
        <button
          (click)="onPageBack(location, router, '/congresses')"
          class="mr-0 mr-sm-16 white-fg"
          mat-icon-button
        >
          <mat-icon class="icon-mat">arrow_back</mat-icon>
        </button>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <div *ngIf="!congress" class="h1">
          {{ "CONGRESSES.CONGRESSES" | translate }}
        </div>
        <div
          *ngIf="
            congress &&
            (!modulesService.modulesAreSet || !modulesService.hasCTReport())
          "
          class="h1"
        >
          {{ "CONGRESSES.CONGRESSES" | translate }} - {{ congress.name }} -
          {{ congress.congress_number }}
        </div>
        <div
          *ngIf="
            congress &&
            modulesService.modulesAreSet &&
            modulesService.hasCTReport()
          "
          class="h1"
        >
          {{ "CONGRESSES.CONGRESSES" | translate }} - {{ congress.name }} -
          {{ "GENERIC.CONGRESS_NUMBER_DYN" | translate | scpmPipe }} -
          {{ congress.congress_number }}
        </div>
      </div>
    </div>
  </div>
  <!-- / HEADER -->

  <!-- SUB HEADER -->
  <div
    *ngIf="congress"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fusePerfectScrollbar
    class="primary mat-elevation-z1 content-subheader"
  >
    <div class="ml-16">
      <!-- projectsCount -->
      {{ dateStartFormatted }} {{ "GENERIC.TO2" | translate }}
      {{ dateEndFormatted }} - {{ projectsCount }}
      {{
        (projectsCount > 1 ? "GENERIC.PROJECTS" : "GENERIC.PROJECT")
          | translate
          | lowercase
      }}
      & {{ eventCount }}
      {{
        (eventCount > 1 ? "GENERIC.EVENTS" : "GENERIC.EVENT")
          | translate
          | lowercase
      }}
    </div>
  </div>
  <!-- /SUB HEADER -->

  <!-- gray band message -->
  <div *ngIf="congress">
    <div
      *ngIf="
        congress.canceled_at &&
        congress.canceled_at !== null &&
        congress.canceled_at !== ''
      "
      fxLayout="row"
      fxLayoutAlign="center center"
      style="height: 38px; min-height: 38px; max-height: 38px"
      class="gt2-gray mat-elevation-z1 py-8"
    >
      <div class="text-white font-weight-600">
        <b>{{ "CONGRESSES.CONGRES_CANCELED" | translate }}</b>
      </div>
    </div>
  </div>

  <div
    #mainContainer
    class="center p-24 pr-56 pr-sm-72 congress-content"
    fusePerfectScrollbar
  >
    <div
      *ngIf="congress"
      class="profile-box info-box general"
      fxLayout="column"
    >
      <header class="accent">
        <div class="title">{{ "GENERIC.INFORMATION" | translate }}</div>
      </header>

      <div class="content" fxLayout="column">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
          <div *ngIf="!editMode" class="info-line" fxFlex="100%">
            <div class="title">{{ "GENERIC.NAME" | translate }}</div>
            <div *ngIf="congress.name" class="info text-truncate">
              {{ congress.name }}
            </div>
            <div *ngIf="!congress.name" class="info empty-value"></div>
          </div>
          <div *ngIf="editMode" class="info-line" fxFlex="50%">
            <div class="info-editable">
              <div [formGroup]="form" class="">
                <mat-form-field class="form-input-container" fxFlex="100%">
                  <mat-label>{{ "GENERIC.NAME" | translate }}</mat-label>
                  <input
                    [(ngModel)]="congress.name"
                    autocomplete="nope"
                    formControlName="name"
                    matInput
                    required
                    type="text"
                  />
                  <mat-error *ngIf="form.controls['name'].invalid"
                    >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div *ngIf="!editMode" class="info-line" fxFlex="100%">
            <div class="title">
              {{ "GENERIC.CONGRESS_NUMBER_DYN" | translate | scpmPipe }}
            </div>
            <div class="info text-truncate">{{ congress.congress_number }}</div>
            <div
              *ngIf="!congress.congress_number"
              class="info empty-value"
            ></div>
          </div>
          <div *ngIf="editMode" class="info-line" fxFlex="50%">
            <div class="info-editable">
              <div [formGroup]="form" class="">
                <mat-form-field class="form-input-container" fxFlex="100%">
                  <mat-label>{{
                    "GENERIC.CONGRESS_NUMBER_DYN" | translate | scpmPipe
                  }}</mat-label>
                  <input
                    [(ngModel)]="congress.congress_number"
                    autocomplete="nope"
                    formControlName="congress_number"
                    matInput
                    required
                    type="text"
                  />
                  <mat-error *ngIf="form.controls['congress_number'].invalid"
                    >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>

        <!-- 2nd row -->
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
          <div *ngIf="!editMode" class="info-line" fxFlex="100%">
            <div class="title">{{ "GENERIC.DATE_START" | translate }}</div>
            <div class="info text-truncate">
              {{ congress.date_start_label }}
            </div>
            <div *ngIf="!congress.date_start" class="info empty-value"></div>
          </div>
          <div *ngIf="editMode" class="info-line" fxFlex="50%">
            <div class="info-editable">
              <div [formGroup]="form" class="">
                <mat-form-field class="form-input-container" fxFlex="100%">
                  <mat-label>{{ "GENERIC.DATE_START" | translate }}</mat-label>
                  <input
                    matInput
                    [matDatepicker]="dateFrom"
                    [(ngModel)]="congress.date_start"
                    (ngModelChange)="onDateChange($event)"
                    min="1900-01-01"
                    required
                    formControlName="date_start"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="dateFrom"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #dateFrom></mat-datepicker>

                  <mat-error *ngIf="form.controls['date_start'].invalid"
                    >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div *ngIf="!editMode" class="info-line" fxFlex="100%">
            <div class="title">{{ "GENERIC.DATE_END" | translate }}</div>
            <div class="info text-truncate">{{ congress.date_end_label }}</div>
            <div *ngIf="!congress.date_end" class="info empty-value"></div>
          </div>
          <div *ngIf="editMode" class="info-line" fxFlex="50%">
            <div class="info-editable">
              <div [formGroup]="form" class="">
                <mat-form-field class="form-input-container" fxFlex="100%">
                  <mat-label>{{ "GENERIC.DATE_END" | translate }}</mat-label>
                  <input
                    matInput
                    [matDatepicker]="dateTo"
                    [(ngModel)]="congress.date_end"
                    (ngModelChange)="onDateEndChange($event)"
                    min="1900-01-01"
                    required
                    formControlName="date_end"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="dateTo"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #dateTo></mat-datepicker>

                  <mat-error *ngIf="form.controls['date_end'].invalid"
                    >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="congress"
      style="min-width: 1023px"
      class="profile-box info-box general"
      fxLayout="column"
    >
      <header class="accent">
        <div
          fxFlex
          class="gt2-theme accent header-create"
          fxFlex
          fxLayout="row"
          fxLayoutAlign="space-between center"
          style="height: 56px"
        >
          <div class="title">{{ "GENERIC.PROJECTS" | translate }}</div>
          <div
            *ngxPermissionsOnly="rolesService.SALE_ROLES"
            class="gt2-light-green"
            style="width: 60px; height: 56px"
            fxLayout="row"
            fxLayoutAlign="center center"
          >
            <button
              type="button"
              matTooltip="{{ 'GENERIC.CREATE_PROJECT' | translate }}"
              mat-icon-button
              class=""
              (click)="onCreateProject()"
            >
              <mat-icon class="icon-mat-black" color="">{{
                getIcon("ADD")
              }}</mat-icon>
            </button>
          </div>
        </div>
      </header>
      <div class="" fxLayout="column">
        <app-project-box
          #projectBox
          (onMeta)="onProjecstMeta($event)"
          [congress]="congress"
        ></app-project-box>
      </div>
    </div>

    <!-- <div *ngIf="congress" class="profile-box info-box general" fxLayout="column">
            <header class="accent">
                <div class="title">{{ "GENERIC.CLIENT_INVOICE" | translate}}</div>
            </header>
            <div class="" fxLayout="column">
                <app-clients-invoices-box (onMeta)="onClientsMeta($event)" [congress]="congress"></app-clients-invoices-box>
            </div>
        </div> -->

    <div
      *ngIf="congress"
      class="profile-box info-box general"
      fxLayout="column"
    >
      <header class="accent">
        <div
          *ngIf="!modulesService.modulesAreSet || !modulesService.hasCTReport()"
          class="title"
        >
          {{ "GENERIC.CONTRAT_BANQUET_CLIENT" | translate }}
        </div>
        <div
          *ngIf="modulesService.modulesAreSet && modulesService.hasCTReport()"
          class="title"
        >
          {{ "GENERIC.CONTRAT_BANQUET_CLIENT_CT" | translate }}
        </div>
      </header>
      <div class="" fxLayout="column">
        <!-- <app-clients-invoices-box (onMeta)="onClientsMeta($event)" [congress]="congress"></app-clients-invoices-box> -->
        <app-reports-per-client [congress]="congress"></app-reports-per-client>
      </div>
    </div>

    <app-comment-section
      *ngIf="congress"
      [commentsModel]="congress"
      [serviceAPIUrl]="'/v1/congress/comments/' + congress.uuid"
    >
    </app-comment-section>

    <div *ngxPermissionsOnly="rolesService.RH_ROLES" class="mt-32">
      <div
        *ngIf="congress && !congress.canceled_at"
        fxFlex="100%"
        fxLayout="row"
        fxLayoutAlign="center center"
      >
        <button
          mat-raised-button
          (click)="cancelCongress()"
          class="warn"
          style="min-width: 240px"
        >
          {{ "GENERIC.CANCEL" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="congress && editable" fxLayout="row" fxLayoutAlign="end start">
  <div *ngxPermissionsOnly="rolesService.TEAM_LEAD_ROLES">
    <div *ngIf="!congress.canceled_at">
      <!-- TODO: disbaled canceled at does not work -->
      <div class="fixed-button">
        <button
          mat-fab
          #saveButton
          [ngClass]="editMode ? 'gt2-save-button' : 'gt2-edit-button'"
          id="edit-congres-button"
          (click)="onEdit($event)"
          [disabled]="loading || (editMode && !form?.valid)"
          *fuseIfOnDom
          [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }"
        >
          <mat-icon *ngIf="!editMode && !loading" class="icon-mat-black">{{
            getIcon("EDIT_FAB")
          }}</mat-icon>
          <mat-icon
            *ngIf="editMode && !loading && !saveButton.disabled"
            matTooltip="{{ 'GENERIC.SAVE' | translate }}"
            position="left"
            class="icon-mat-black"
            showDelay="900"
            hideDelay="900"
            >{{ getIcon("SAVE") }}</mat-icon
          >
          <mat-icon
            *ngIf="editMode && !loading && saveButton.disabled"
            matTooltip="{{ 'GENERIC.FORM_DIRTY_TIP' | translate }}"
            position="left"
            class="icon-mat-black"
            hideDelay="900"
            >{{ getIcon("SAVE") }}</mat-icon
          >
          <i [ngClass]="loading ? 'fa fa-spinner fa-spin' : ''"></i>
        </button>
      </div>
      <div class="fixed-button2">
        <button
          mat-fab
          *ngIf="editMode"
          id="cancel-edit-congres-button"
          class="gt2-cancel-edit-button"
          matTooltip="{{ 'GENERIC.CANCEL' | translate }}"
          position="left"
          showDelay="900"
          hideDelay="900"
          (click)="onCancelEdit()"
          [disabled]="loading"
          [@animate]="{ value: '*', params: { delay: '120ms', scale: '.2' } }"
        >
          <mat-icon class="s-24 icon-mat">{{ getIcon("CANCEL") }}</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
