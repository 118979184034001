import { AppRoutes } from './../const/AppRoutes';
import { Router } from '@angular/router';
import { PlatformLocation } from '@angular/common';
import { Injectable, Injector } from '@angular/core';
import { ApiRoutes } from './ApiRoutes';
import { environment } from '../../../environments/environment';

@Injectable()
export class Gt2ApiService {
  static LS_LOCAL_USE_DEV: string = 'LOCAL_USE_DEV';
  static LS_LOCAL_USE_DEV2: string = 'LOCAL_USE_DEV2';
  static API_TARGET_LOCAL: string = 'LOCAL';
  static API_TARGET_DEV: string = 'DEV';
  static API_TARGET_DEV2: string = 'DEV2';

  isLocal: boolean = false;
  isDev: boolean = false;
  isDemo: boolean = false;
  isLocalOrDev: boolean = false;
  localAPITarget: any;
  localAPI: string = 'https://api.gt3-dev.gestion-traiteur.app';
  //localAPI: string = "https://api.gt.test";

  devOrigin: string = 'https://gt3-dev.gestion-traiteur.app';
  //devOrigin: string = "https://ct-dev.gestion-traiteur.app";
  dev2Origin: string = 'https://dev2.gestion-traiteur.app';
  demoOrigin: string = 'https://gt3-dev.gestion-traiteur.app';
  prodOrigin: string = 'https://gestion-traiteur.app';

  private apiUrl: any;
  private domainUrl: any;
  private devOriginWhiteList: string[] = [
    'http://localhost:4200',
    'https://gt3-dev.gestion-traiteur.app',
    'https://gt-webclient-app.web.app',
    'https://gt-webclient-app.firebaseapp.com',
    'https://gt2-netlify-test.netlify.app',
    'https://5e98d8da9f7e8483cb3a63ff--gt2-netlify-test.netlify.app',
  ];

  private demoOriginWhiteList: string[] = [
    'https://gt3-dev.gestion-traiteur.app',
  ];
  private localOriginWhiteList: string[] = [
    'http://localhost:4300',
    'http://localhost:4200',
    'http://gestion-traiteur.test',
    'https://gestion-traiteur.test',
  ];

  constructor(
    private platformLocation: PlatformLocation,
    private injector: Injector,
  ) {
    this.createBaseUrl();
  }

  public toggleLocalAPI(): void {
    const newTarget: string =
      this.localAPITarget === Gt2ApiService.API_TARGET_DEV
        ? Gt2ApiService.API_TARGET_LOCAL
        : Gt2ApiService.API_TARGET_DEV;
    this.switchLocalAPI(newTarget);
  }

  public setLocalAPI(): void {
    const newTarget: string = Gt2ApiService.API_TARGET_LOCAL;
    this.switchLocalAPI(newTarget);
  }

  public setDevAPI(): void {
    const newTarget: string = Gt2ApiService.API_TARGET_DEV;
    this.switchLocalAPI(newTarget);
  }

  public setDev2API(): void {
    const newTarget: string = Gt2ApiService.API_TARGET_DEV2;
    this.switchLocalAPI(newTarget);
  }

  public switchLocalAPI(target: string): void {
    // this.api.setNewOrigin((platformLocation as any).location.origin);
    // reset as local, the following if will set the proper dev if is dev or dev2
    localStorage.setItem(Gt2ApiService.LS_LOCAL_USE_DEV, 'false');
    localStorage.setItem(Gt2ApiService.LS_LOCAL_USE_DEV2, 'false');
    if (target === Gt2ApiService.API_TARGET_DEV) {
      localStorage.setItem(Gt2ApiService.LS_LOCAL_USE_DEV, 'true');
    } else if (target === Gt2ApiService.API_TARGET_DEV2) {
      localStorage.setItem(Gt2ApiService.LS_LOCAL_USE_DEV2, 'true');
    }
    this.setNewOrigin((this.platformLocation as any).location.origin);
    const router = this.injector.get(Router);
    if (router) {
      router.navigateByUrl(AppRoutes.LOGIN);
    }
  }

  public setNewOrigin(origin: string, allowLocal: boolean = true): void {
    this.isLocal = this.localOriginWhiteList.includes(origin);
    this.isDev = this.devOriginWhiteList.includes(origin);
    this.isDemo = this.demoOriginWhiteList.includes(origin);
    this.isLocalOrDev = this.isLocal || this.isDev;
    if (this.isLocal && allowLocal) {
      let localUseDev: boolean =
        localStorage.getItem(Gt2ApiService.LS_LOCAL_USE_DEV) === 'true';
      let localUseDev2: boolean =
        localStorage.getItem(Gt2ApiService.LS_LOCAL_USE_DEV2) === 'true';
      // localUseDev = false;
      // localUseDev2 = true;

      if (localUseDev) {
        const ori: string = this.devOrigin;
        const domain: string = ori.replace(/(^\w+:|^)\/\//, ''); // remove http or https and/or www
        this.domainUrl = 'https://api.gt3-dev.gestion-traiteur.app';
        this.localAPITarget = Gt2ApiService.API_TARGET_DEV;
      } else if (localUseDev2) {
        const ori: string = this.dev2Origin;
        const domain: string = ori.replace(/(^\w+:|^)\/\//, ''); // remove http or https and/or www
        this.domainUrl = 'https://api.gt3-dev.gestion-traiteur.app';
        this.localAPITarget = Gt2ApiService.API_TARGET_DEV2;
      } else {
        // this.isLocal = true;
        this.domainUrl = 'https://api.gt3-dev.gestion-traiteur.app';
        this.localAPITarget = Gt2ApiService.API_TARGET_LOCAL;
      }
    } else {
      const ori: string = this.devOriginWhiteList.includes(origin)
        ? this.devOrigin
        : origin;
      const domain: string = ori.replace(/(^\w+:|^)\/\//, ''); // remove http or https and/or www
      const env = !domain.includes('demo')
        ? 'gestion-traiteur.app'
        : 'gt3-dev.gestion-traiteur.app';
      this.domainUrl = 'https://api.gt3-dev.gestion-traiteur.app';
    }
  }

  createBaseUrl(): string {
    this.domainUrl = 'https://api.gt3-dev.gestion-traiteur.app';
    this.apiUrl = this.domainUrl + environment.api.apiEndpoint;
    return this.apiUrl;
  }

  createUrl(prefix: string): string {
    return this.domainUrl + prefix;
  }

  createReportURL(
    prefix: string,
    section: string,
    local: string = 'fr-CA',
  ): string {
    prefix = prefix.replace('${SECTION}', section);
    return this.createUrl(prefix.replace('${LOCAL}', local));
  }

  createReportURLWithPaper(
    prefix: string,
    section: string,
    paper: string,
    local: string = 'fr-CA',
  ): string {
    prefix = prefix.replace('${SECTION}', section);
    prefix = prefix.replace('${PAPER}', paper);
    return this.createUrl(prefix.replace('${LOCAL}', local));
  }

  getApiUrl(): string {
    return this.apiUrl;
  }

  getDomainUrl(): string {
    return this.domainUrl;
  }

  getLoginURL(): string {
    return this.domainUrl + ApiRoutes.LOGIN_URL;
  }

  getRefreshTokenURL(): string {
    return this.domainUrl + ApiRoutes.REFRESH_TOKEN_URL;
  }

  getLogoutURL(): string {
    return this.domainUrl + ApiRoutes.LOGOUT_URL;
  }

  getPageAuthorizedURL(): string {
    return this.domainUrl + ApiRoutes.PAGE_AUTHORIZED;
  }

  getUserProfileUrl(): string {
    return this.domainUrl + ApiRoutes.USER_URL;
  }

  getAppUserProfileUrl(): string {
    return this.domainUrl + ApiRoutes.USER;
  }

  getEmployeeMeUrl(): string {
    return this.domainUrl + ApiRoutes.EMPLOYEE_ME;
  }

  getRegistrationUrl(): string {
    return this.domainUrl + ApiRoutes.REGISTRATION_URL;
  }

  getFinalRegistrationUrl(): string {
    return this.domainUrl + ApiRoutes.FINAL_REGISTRATION_URL;
  }

  getFinalInviteUrl(): string {
    return this.domainUrl + ApiRoutes.FINAL_INVITE_URL;
  }

  getForgotPasswordUrl(): string {
    return this.domainUrl + ApiRoutes.FORGOT_PASSWORD;
  }

  getResetPasswordUrl(): string {
    return this.domainUrl + ApiRoutes.RESET_PASSWORD;
  }
}
