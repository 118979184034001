import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GuestsService } from '../../../api/guests.service';
import { ValidatorHelper } from '../../../validators/ValidatorHelper';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { DropdownModule } from 'primeng/dropdown';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-create-guest',
  templateUrl: './create-guest.component.html',
  styleUrls: ['./create-guest.component.scss'],
  standalone: true,
  imports: [
    DropdownModule,
    CommonModule,
    MatInputModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatButtonModule,
    FormsModule,
    MatFormFieldModule,
    TranslateModule,
  ],
})
export class CreateGuestComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  static CREATE: string = 'CREATE';
  static EDIT: string = 'EDIT';

  //
  mode: string = CreateGuestComponent.CREATE;
  form: UntypedFormGroup;
  guest: any;
  guests?: any[];
  selectedGuest: any;
  guestToUpdate: any;
  eventUUID!: string;
  loading: boolean = false;
  autoRedirect: boolean = false;

  constructor(
    public override dialogRef: MatDialogRef<CreateGuestComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private guestsService: GuestsService,
    public dialog: MatDialog,
    public router: Router,
    private toastr: ToastrService,
    private logger: NGXLogger,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    this.form = this.formBuilder.group({
      selectedGuest: ['', [Validators.required]],
      guest_type_count: [
        '',
        [
          ValidatorHelper.isNumber0To1000000,
          ValidatorHelper.isInteger,
          Validators.required,
        ],
      ],
    });

    // this.logger.info("CreateGuestComponent.constructor() -> data: " + JSON.stringify(data));
    this.guest = {
      // custom_guest: null,
      // selected_guest_uuid: null,
      guest_type_count: 0,
    };

    if (data && data.mode !== null) {
      this.mode = data.mode;
    }

    if (data && data.eventUUID !== null) {
      this.eventUUID = data.eventUUID;
    }

    if (data && data.autoRedirect !== null) {
      this.autoRedirect = data.autoRedirect;
    }

    if (data && data.guestToUpdate) {
      this.guestToUpdate = data.guestToUpdate;
      this.guest.guest_type_count = this.guestToUpdate.guest_type_count;
      this.selectedGuest = this.guestToUpdate.name;
      // this.logger.info("CreateGuestComponent.constructor() -> guestToUpdate: " + JSON.stringify(data.guestToUpdate));
    }

    this.guestsService.getGuestsList().subscribe({
      next: (response) => {
        // this.logger.info("CreateGuestComponent.getFlowServiceList() -> SUCCESS: " + JSON.stringify(response));
        this.guests = response.data.map((item: any) => {
          return { label: item.name, value: item };
        });
        // this.logger.info("CreateGuestComponent.getFlowServiceList() -> this.flows: " + JSON.stringify(this.guests));
      },
      error: (error) => {
        this.logger.error(
          'CreateGuestComponent.onCreateProposal() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public onCreateGuest() {
    this.loading = true;
    // this.logger.info("CreateGuestComponent.onCreateGuest() -> flow: " + JSON.stringify(this.guest));

    if (this.mode === CreateGuestComponent.CREATE) {
      this.doCreateGuest();
    } else if (this.mode === CreateGuestComponent.EDIT) {
      this.updateGuest();
    }
  }

  public doCreateGuest() {
    // this.logger.info("CreateGuestComponent.doCreateFlow() -> selectedFlow: " + JSON.stringify(this.selectedFlow));
    // this.logger.info("CreateGuestComponent.doCreateFlow() -> selectedFlow instanceof object: " + (this.selectedFlow instanceof Object));
    // this.logger.info("CreateGuestComponent.doCreateFlow() -> selectedFlow typeof string: " + (typeof this.selectedFlow === "string"));

    if (typeof this.selectedGuest === 'string') {
      this.guest.custom_guest = this.selectedGuest;
    } else if (this.selectedGuest instanceof Object) {
      this.guest.selected_guest_uuid = this.selectedGuest.uuid;
    }

    // this.logger.info("CreateGuestComponent.doCreateGuest() -> ***** guest: " + JSON.stringify(this.guest));
    // this.logger.info("CreateGuestComponent.doCreateGuest() -> ***** this.selectedGuest: " + JSON.stringify(this.selectedGuest));

    this.guestsService.createGuest(this.guest, this.eventUUID).subscribe({
      next: (response) => {
        // this.logger.info("CreateGuestComponent.doCreateGuest() -> SUCCESS: " + JSON.stringify(response));
        this.loading = false;
        this.dialogRef.close(response);
      },
      error: (error) => {
        this.logger.error(
          'CreateGuestComponent.doCreateGuest() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        this.handleAPIError(
          error,
          this.dialog,
          this.toastr,
          this.translate.instant('GENERIC.ERRORS.GENERIC'),
        );
        // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
      },
    });
  }

  public updateGuest() {
    if (typeof this.selectedGuest === 'string') {
      this.guest.custom_guest = this.selectedGuest;
    } else if (this.selectedGuest instanceof Object) {
      this.guest.selected_guest_uuid = this.selectedGuest.uuid;
    }

    // this.logger.info("CreateGuestComponent.updateGuest() -> ***** guest: " + JSON.stringify(this.guest));
    this.guestsService
      .updateGuest(this.guest, this.guestToUpdate.uuid, this.eventUUID)
      .subscribe({
        next: (response) => {
          // this.logger.info("CreateGuestComponent.updateGuest() -> SUCCESS: " + JSON.stringify(response));
          this.loading = false;
          this.dialogRef.close(response);
        },
        error: (error) => {
          this.logger.error(
            'CreateGuestComponent.updateGuest() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.GENERIC'),
          );
          // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
        },
      });

    // const flowCopy = Object.assign({}, this.flowToUpdate);

    // if (typeof this.selectedFlow === "string")
    // {
    //   flowCopy.custom_flow = this.selectedFlow;
    // }
    // else if (this.selectedFlow instanceof Object)
    // {
    //   flowCopy.selected_flow_uuid = this.selectedFlow.uuid;
    // }

    // flowCopy.flow_date = this.flow.date;
    // flowCopy.flow_time = this.flow.selectedTime;
    // flowCopy.note = this.flow.note;
    // flowCopy.for_client_only = this.flow.for_client_only;

    // this.logger.info("CreateGuestComponent.updateFlow() -> ***** flowToUpdate: " + JSON.stringify(flowCopy));

    // this.flowService.updateFlow(flowCopy, this.eventUUID).subscribe(response => {
    //   this.logger.info("CreateGuestComponent.onCreateFlow() -> SUCCESS: " + JSON.stringify(response));
    //   this.loading = false;
    //   this.dialogRef.close(response);
    // }, error => {
    //     this.logger.error("CreateGuestComponent.onCreateFlow() -> ERROR: " + JSON.stringify(error));
    //     this.loading = false;
    //   });
  }
}
