<div *ngIf="vo">
  <!-- style="width: 60px; height: 60px;" -->

  <button
    tabindex="-1"
    [autofocus]="false"
    type="button"
    *ngIf="fileService.excelEnabled() && vo.export_format === 'XLSX'"
    matTooltip="{{ tooltipLabel | translate }}"
    mat-icon-button
    (click)="onReport(); $event.stopPropagation()"
  >
    <mat-icon
      matBadgeSize="small"
      fontSet="fa"
      fontIcon="{{ iconsService.getIcon('XLS') }}"
      class="fa-lg"
    >
    </mat-icon>
  </button>

  <button
    tabindex="-1"
    [autofocus]="false"
    type="button"
    *ngIf="
      fileService.pdfEnabled() &&
      (vo.export_format === 'PDF' || vo.export_format === 'PDF_STACK') &&
      vo.export_to === 'DOWNLOAD'
    "
    matTooltip="{{ tooltipLabel | translate }}"
    mat-icon-button
    (click)="onReport(); $event.stopPropagation()"
  >
    <mat-icon matBadgeSize="small" class="">{{
      iconsService.getIcon("PDF")
    }}</mat-icon>
  </button>

  <button
    tabindex="-1"
    [autofocus]="false"
    type="button"
    *ngIf="fileService.printEnabled() && vo.export_to === 'PRINT'"
    matTooltip="{{ tooltipLabel | translate }}"
    mat-icon-button
    (click)="onReport(); $event.stopPropagation()"
  >
    <mat-icon class="">{{ iconsService.getIcon("PRINT") }}</mat-icon>
  </button>

  <button
    tabindex="-1"
    [autofocus]="false"
    type="button"
    *ngIf="fileService.printEnabled() && vo.export_to === 'PREVIEW'"
    matTooltip="{{ tooltipLabel | translate }}"
    mat-icon-button
    (click)="onReport(); $event.stopPropagation()"
  >
    <mat-icon class="icon-mat s-28">
      {{ iconsService.getIcon("PREVIEW") }}
    </mat-icon>
  </button>
</div>
