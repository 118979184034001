import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ProductModel,
  ProductsService,
} from '../../../../api/products.service';
import { GT2PageAbstract } from '../../../abstract/GT2PageAbstract';
import { NGXLogger } from 'ngx-logger';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-choice-combo-select',
  templateUrl: './choice-combo-select.component.html',
  styleUrls: ['./choice-combo-select.component.scss'],
  standalone: true,
  imports: [
    MatProgressSpinnerModule,
    MatFormFieldModule,
    CommonModule,
    FlexLayoutModule,
    MatSelectModule,
    MatOptionModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
  ],
})
export class ChoiceComboSelectComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() product?: ProductModel;
  @Output() onSelection = new EventEmitter<any>();
  selectedValue: any = null;
  loading: boolean = false;

  constructor(
    private productsService: ProductsService,
    private dialog: MatDialog,
    private logger: NGXLogger,
  ) {
    super();
  }

  ngOnInit() {
    if (this.product) {
      this.loadProduct();
    }
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  public loadProduct(): void {
    this.loading = true;
    this.productsService.getProduct(this.product?.uuid).subscribe({
      next: (response: any) => {
        // //this.logger.info("ProductComponent.loadProduct() -> SUCCESS: ");
        // //this.logger.info(
        //     "ProductComponent.loadProduct() -> SUCCESS: " +
        //         JSON.stringify(response)
        // );
        const isFirstLoad: boolean =
          this.product === null || this.product === undefined;

        this.product = response.data;
        this.loading = false;
      },
      error: (error: any) => {
        this.logger.error(
          'ProductComponent.loadProduct() -> ERROR: ' + JSON.stringify(error),
        );
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public onSelect(event: any): void {
    //this.logger.info("ProductComponent.onSelect() -> product: " + JSON.stringify(event.value));
    this.onSelection.emit(event.value);
  }
}
