<div style="max-height: 100%; overflow: auto">
  <div matDialogTitle *ngIf="mode === 'CREATE'" class="event-dialog-title">
    {{ "CREATE_DELIVERY_REQUEST.TITLE" | translate }}
  </div>
  <div matDialogTitle *ngIf="mode === 'EDIT'" class="event-dialog-title">
    {{ "CREATE_DELIVERY_REQUEST.EDIT_TITLE" | translate }}
  </div>

  <div fxLayout="column" fxLayoutAlign="center start">
    <div
      fxFlex="100%"
      fxLayout="row"
      fxLayoutAlign="start start"
      style="width: 100%"
    >
      <app-gt2-select
        [service]="deliveryRequestTemplateService"
        [placeHolder]="'CREATE_DELIVERY_REQUEST.TEMPLATES' | translate"
        *ngIf="!templatesEmpty"
        [label]="'Model de demande de livraison'"
        [selectedModel]="deliveryRequestTemplate"
        (changeEvent)="onDeliveryRequestTemplateChange($event)"
        (modelSetEvent)="onDeliveryRequestTemplateModelSet($event)"
        [labelKey]="'template_name'"
        [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
        [nullableUseNone]="false"
        [isRequired]="false"
        fxFlex="100%"
        class="pl-4"
      ></app-gt2-select>

      <div
        fxFlex="100%"
        fxLayout="column"
        fxLayoutAlign="center center"
        class="no-templates"
        style="width: 100%"
        *ngIf="templatesEmpty"
      >
        <div>{{ "CREATE_DELIVERY_REQUEST.TEMPLATES_EMPTY" | translate }}</div>
        <a class="no-templates-link" (click)="onNavCreateTemplate()">
          {{ "CREATE_DELIVERY_REQUEST.TEMPLATES_EMPTY_CLICK" | translate }}
        </a>
      </div>
    </div>

    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="center start"
      [formGroup]="form"
      (keyup.enter)="
        form.valid &&
        !loading &&
        deliveryRequest.type &&
        deliveryRequest.wrapping &&
        deliveryRequest.content &&
        deliveryRequest.delivery_from_time &&
        deliveryRequest.delivery_from_time !== 'Invalid DateTime' &&
        !deliveryRequest.delivery_to_time &&
        deliveryRequest.delivery_to_time !== 'Invalid DateTime'
          ? onCreateDeliveryRequest()
          : null
      "
      class="item-full-width"
    >
      <!-- <div *ngIf="deliveryRequests" class="item-full-width mb-16">
            <p-dropdown [options]="deliveryRequests" [(ngModel)]="selectedDeliveryRequest" [style]="{'width':'100%'}" editable="true"
              formControlName="selectedDeliveryRequest" placeholder="{{ 'CREATE_DELIVERY_REQUEST.SELECT_FLOW' | translate }}">
            </p-dropdown>
        </div>
        <div *ngIf="!deliveryRequests" fxLayout="row" fxLayoutAlign="center center" class="item-full-width mb-16">
            <mat-spinner [diameter]="20" class=""></mat-spinner>
        </div> -->
      <div fxFlex="65" fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field fxFlex="100%" class="item-full-width mb-4 mr-4">
          <mat-label>{{ "GENERIC.NAME" | translate }}</mat-label>
          <input
            matInput
            fxFlex="100%"
            autocomplete="nope"
            formControlName="name"
            type="text"
            [(ngModel)]="deliveryRequest.name"
            required
          />
          <mat-error *ngIf="form.controls['name'].invalid">{{
            "GENERIC.INPUT_LENGTH_INVALID" | translate
          }}</mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="35" fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field fxFlex="100%" class="item-full-width ml-4">
          <mat-label>{{ "GENERIC.PRICE" | translate }}</mat-label>
          <input
            matInput
            type="text"
            formControlName="price"
            autocomplete="nope"
            pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
            [(ngModel)]="deliveryRequest.price"
            required
          />
          <mat-error *ngIf="form.controls['price'].invalid">
            {{ "GENERIC.INPUT_NUMBER_0_999999" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <!-- CHECKBOXES -->

    <div
      fxLayout="row"
      fxLayoutAlign="start start"
      fxFlex="100"
      class="item-full-width mt-12 mb-8"
    >
      <div fxFlex="100" fxLayout="column" fxLayoutAlign="center start">
        <mat-checkbox
          labelPosition="after"
          color="accent"
          [(ngModel)]="deliveryRequest.show_on_purchase_order_production"
          [ngModelOptions]="{ standalone: true }"
          class=""
        >
          {{
            "CREATE_DELIVERY_REQUEST.SHOW_ON_PURCHASE_ORDER_PRODUCTION"
              | translate
          }}
        </mat-checkbox>

        <mat-checkbox
          labelPosition="after"
          color="accent"
          [(ngModel)]="deliveryRequest.show_on_purchase_order_client_copy"
          [ngModelOptions]="{ standalone: true }"
        >
          {{
            "CREATE_DELIVERY_REQUEST.SHOW_ON_PURCHASE_ORDER_CLIENT_COPY"
              | translate
          }}
        </mat-checkbox>

        <mat-checkbox
          labelPosition="after"
          color="accent"
          [(ngModel)]="deliveryRequest.show_on_client_proposal_and_order"
          [ngModelOptions]="{ standalone: true }"
        >
          {{
            "CREATE_DELIVERY_REQUEST.SHOW_ON_CLIENT_PROPOSAL_AND_ORDER"
              | translate
          }}
        </mat-checkbox>

        <mat-checkbox
          labelPosition="after"
          color="accent"
          [(ngModel)]="deliveryRequest.show_on_purchase_order_kitchen"
          [ngModelOptions]="{ standalone: true }"
          class=""
        >
          {{
            "CREATE_DELIVERY_REQUEST.SHOW_ON_PURCHASE_ORDER_KITCHEN" | translate
          }}
        </mat-checkbox>
        <mat-checkbox
          labelPosition="after"
          color="accent"
          [(ngModel)]="deliveryRequest.show_on_purchase_material"
          [ngModelOptions]="{ standalone: true }"
        >
          {{ "CREATE_DELIVERY_REQUEST.SHOW_ON_PURCHASE_MATERIAL" | translate }}
        </mat-checkbox>
      </div>
    </div>

    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="start start"
      fxFlex="100"
      class="item-full-width"
      style="padding-top: 8px; padding-bottom: 16px"
    >
      <div
        [formGroup]="form"
        fxFlex="50"
        fxLayout="column"
        fxLayoutAlign="center start"
      >
        <mat-form-field fxFlex="100%" class="item-full-width mr-8">
          <mat-label>{{ "GENERIC.DATE_START" | translate }}</mat-label>
          <input
            matInput
            [matDatepicker]="deliveryFromDate"
            [(ngModel)]="deliveryRequest.delivery_from_date"
            min="1900-01-01"
            autocomplete="nope"
            formControlName="delivery_from_date"
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="deliveryFromDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #deliveryFromDate></mat-datepicker>
          <mat-error *ngIf="form.controls['delivery_from_date'].invalid"
            >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="100%" class="item-full-width mr-8">
          <mat-label>{{ "GENERIC.DATE_END" | translate }}</mat-label>
          <input
            matInput
            [matDatepicker]="deliveryToDate"
            [(ngModel)]="deliveryRequest.delivery_to_date"
            min="1900-01-01"
            autocomplete="nope"
            formControlName="delivery_to_date"
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="deliveryToDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #deliveryToDate></mat-datepicker>

          <mat-error *ngIf="form.controls['delivery_to_date'].invalid"
            >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="50" fxLayout="column" fxLayoutAlign="center start">
        <div
          class="item-full-width ml-8 ngx-timepicker-field-from"
          fxFlex="100%"
        >
          <div class="timepicker-custom-label">
            {{ "GENERIC.TIME_START" | translate }}*
          </div>
          <ngx-timepicker-field
            [buttonAlign]="'left'"
            [format]="24"
            [(ngModel)]="deliveryRequest.delivery_from_time"
            class="ngx-material-timepicker-toggle"
          >
          </ngx-timepicker-field>
        </div>

        <div class="item-full-width ml-8 ngx-timepicker-field-to" fxFlex="100%">
          <div class="timepicker-custom-label">
            {{ "GENERIC.TIME_END" | translate }}*
          </div>
          <ngx-timepicker-field
            [buttonAlign]="'left'"
            [format]="24"
            [(ngModel)]="deliveryRequest.delivery_to_time"
            class="ngx-material-timepicker-toggle"
          >
          </ngx-timepicker-field>
        </div>
      </div>
    </div>

    <div
      *ngIf="initGt2Select"
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="start start"
      fxFlex="100"
      [formGroup]="form"
      class="item-full-width"
    >
      <div fxFlex="33" fxLayout="row" fxLayoutAlign="start start">
        <app-gt2-select
          #wrapping
          [service]="wrappingService"
          [nullableUseNone]="false"
          [placeHolder]="'GENERIC.WRAPPING' | translate"
          [label]="'ORDERS.WRAPPING' | translate"
          [selectedModel]="deliveryRequest.wrapping"
          (changeEvent)="onWrappingSelected($event)"
          [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
          [isRequired]="true"
          fxFlex="100%"
          class="pr-4"
        ></app-gt2-select>
      </div>
      <div fxFlex="33" fxLayout="row" fxLayoutAlign="start start">
        <app-gt2-select
          [service]="deliveryRequestTypeService"
          [placeHolder]="'GENERIC.TYPE' | translate"
          [label]="'Personnal title'"
          [selectedModel]="deliveryRequest.type"
          (changeEvent)="onDeliveryRequestTypeChange($event)"
          [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
          [nullableUseNone]="false"
          [isRequired]="true"
          fxFlex="100%"
          class="px-4"
        ></app-gt2-select>
      </div>
      <div fxFlex="33" fxLayout="row" fxLayoutAlign="start start">
        <app-gt2-select
          [service]="deliveryRequestContentService"
          [placeHolder]="'GENERIC.CONTENT' | translate"
          [label]="'Personnal title'"
          [selectedModel]="deliveryRequest.content"
          (changeEvent)="onDeliveryRequestContentChange($event)"
          [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
          [nullableUseNone]="false"
          [isRequired]="true"
          fxFlex="100%"
          class="pl-4"
        ></app-gt2-select>
      </div>
    </div>

    <!-- Delivery contact Person Notes -->
    <!--
    <div *ngIf="modulesService.modulesAreSet && modulesService.hasJRReport()" fxFlex="100%" fxLayout="column" fxLayoutAlign="start start" style="width: 100%;" [formGroup]="form">
        <div fxFlex="100"  fxLayout="column" fxLayoutAlign="start start" style="width: 100%;">
            <mat-form-field fxFlex="100%" class="item-full-width mb-4 mr-4">
                <input matInput fxFlex="100%" placeholder="{{ 'CREATE_DELIVERY_REQUEST.NAME_OF_CONTACT_PERSON_ON_SITE' | translate }}"
                       autocomplete="nope"
                       formControlName="contact_person_name" type="text"
                       [(ngModel)]="deliveryRequest.contact_person_name" >
                <mat-error
                    *ngIf="form.controls['contact_person_name'].invalid">{{ 'GENERIC.INPUT_LENGTH_INVALID' | translate }}</mat-error>
            </mat-form-field>

        </div> 
        <div fxLayout="column" fxLayoutAlign="start start" style="width: 100%;">
            <mat-form-field fxFlex="100%" class="item-full-width ml-8">
                <input matInput fxFlex="100%" placeholder="{{ 'CREATE_DELIVERY_REQUEST.PHONE_NUMBER_OF_PERSON_ON_SITE' | translate }}"
                       autocomplete="nope" numeric
                       formControlName="contact_person_phone" type="text"
                       [(ngModel)]="deliveryRequest.contact_person_phone" >
                <mat-error
                    *ngIf="form.controls['contact_person_phone'].invalid">{{ 'GENERIC.INPUT_LENGTH_INVALID' | translate }}</mat-error>
            </mat-form-field>
        </div> 

    </div>
    -->

    <div fxFlex="100%" class="item-full-width mt-8 note-section">
      <p-editor
        #noteEditor
        [(ngModel)]="deliveryRequest.note"
        [style]="{
          height: '74px',
          width: '100%',
          'background-color': '#ffffff',
        }"
      >
        <ng-template pTemplate="header" class="">
          <span><b>Note | </b></span>
          <span class="ql-formats">
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
            <button class="ql-underline"></button>
            <button class="ql-strike"></button>
          </span>

          <span class="ql-formats">
            <select class="ql-color"></select>
            <select class="ql-background"></select>
          </span>

          <span class="ql-formats">
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
            <button class="ql-indent" value="-1"></button>
            <button class="ql-indent" value="+1"></button>
          </span>
        </ng-template>
      </p-editor>
      <mat-error
        *ngIf="noteInvalid"
        fxLayout="row"
        fxLayoutAlign="end center"
        class="p-4 pr-8"
      >
        {{ "GENERIC.NOTE_EDITOR_LENGTH_INVALID_2048" | translate }}
      </mat-error>
    </div>
  </div>

  <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center start">
    <mat-spinner [diameter]="40" class="mt-20"></mat-spinner>
  </div>

  <div
    mat-dialog-actions
    class="mt-16 gt2-buttons-theme"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button
      mat-raised-button
      [disabled]="
        !form.valid ||
        loading ||
        !deliveryRequest.type ||
        !deliveryRequest.wrapping ||
        !deliveryRequest.content ||
        !deliveryRequest.delivery_from_time ||
        deliveryRequest.delivery_from_time === 'Invalid DateTime' ||
        !deliveryRequest.delivery_to_time ||
        deliveryRequest.delivery_to_time === 'Invalid DateTime'
      "
      color="accent"
      class="mr-16"
      (click)="onCreateDeliveryRequest()"
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>

    <button
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(false)"
      class=""
    >
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
