<div class="profile-box info-box width-100-content" fxLayout="column">
  <header class="accent" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="title">{{ headerLabel }}</div>
    <div
      class="gt2-light-green"
      style="width: 60px; height: 56px"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <button
        matTooltip="{{ 'CLIENTS_CATEGORIES.CREATE_NEW_CATEGORY' | translate }}"
        mat-icon-button
        class=""
        (click)="onCreateNew()"
      >
        <mat-icon class="icon-mat-black" color="">add</mat-icon>
      </button>
    </div>
  </header>
  <div style="min-width: 370px" class="content" fxLayout="column">
    <div
      *ngIf="
        !isLoadingResults && dataSource.data && dataSource.data.length === 0
      "
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      {{ "GENERIC.NO_RESULTS" | translate }}
    </div>

    <mat-table
      #table
      class=""
      matSort
      matSortStart="desc"
      [@animateStagger]="{ value: '50' }"
      [dataSource]="dataSource"
    >
      <!-- Name Column -->
      <ng-container cdkColumnDef="name">
        <mat-header-cell fxFlex="100%" *cdkHeaderCellDef mat-sort-header
          >{{ "CLIENTS_CATEGORIES.CATEGORY_NAME" | translate }}
        </mat-header-cell>

        <mat-cell class="no-text-cursor" *cdkCellDef="let row">
          <div
            *ngIf="editSelected != row"
            fxFlex="100%"
            (click)="onSelect(row)"
          >
            {{ row.name }}
          </div>

          <div *ngIf="editSelected == row" fxFlex="100%">
            <div [formGroup]="form" fxLayout="row" fxLayoutAlign="start center">
              <mat-form-field fxFlex="100%" class="" floatLabel="auto">
                <input
                  matInput
                  autocomplete="nope"
                  type="text"
                  formControlName="name"
                  [(ngModel)]="editSelected.name"
                />
                <mat-error *ngIf="form.controls['name'].invalid"
                  >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                </mat-error>
              </mat-form-field>
            </div>
            <div
              fxLayout="row"
              fxLayoutAlign="end center"
              class="gt2-buttons-theme"
            >
              <button
                mat-raised-button
                color="accent"
                class="mb-16"
                [disabled]="form.invalid"
                (click)="autosaveDataChange(editSelected)"
              >
                {{ "GENERIC.SAVE" | translate }}
              </button>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <!-- EDIT Column -->
      <ng-container cdkColumnDef="edit">
        <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
        <mat-cell
          fxLayoutAlign="center center"
          fxFlex="50px"
          *cdkCellDef="let row"
        >
          <button
            mat-icon-button
            *ngIf="row.editable"
            matTooltip="{{
              editSelected != row
                ? translate.instant('GENERIC.EDIT')
                : translate.instant('GENERIC.EDIT_STOP')
            }}"
            (click)="onEdit(row)"
          >
            <mat-icon *ngIf="editSelected != row" class="s-16">{{
              getIcon("EDIT")
            }}</mat-icon>
            <mat-icon *ngIf="editSelected == row" class="s-16">{{
              getIcon("UNDO")
            }}</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <!-- Delete Column -->
      <ng-container cdkColumnDef="delete">
        <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
        <mat-cell
          fxFlex="50px"
          fxLayoutAlign="center center"
          *cdkCellDef="let row"
        >
          <button
            matSuffix
            mat-icon-button
            *ngIf="row.editable"
            matTooltip="{{ 'GENERIC.DELETE' | translate }}"
            (click)="onDelete(row)"
          >
            <mat-icon class="s-16">delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        class="mat-row-color"
        *cdkRowDef="let row; columns: displayedColumns"
        [@animate]="{ value: '*', params: { y: '100%' } }"
      >
      </mat-row>
    </mat-table>
    <!--<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>-->
    <mat-paginator
      #paginator
      [length]="resultsLength"
      (page)="datatableHelperService.onPaginator($event, 'clientsCategories')"
      showFirstLastButtons="true"
      [pageIndex]="0"
      [pageSize]="'clientsCategories' | tableSizePipe: 'large'"
      [pageSizeOptions]="datatableHelperService.pageSizeOptions"
    >
    </mat-paginator>
  </div>
</div>
