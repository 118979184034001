import {
  GlobalPaymentModel,
  MonerisModel,
} from './../content/shops/shop-web-detailed/shop-web-detailed.component';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ApiRoutes } from './ApiRoutes';
import { Gt2ApiService } from './gt2-api.service';
import { Observable, forkJoin } from 'rxjs';

@Injectable()
export class ShopsService {
  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private api: Gt2ApiService,
  ) {}

  public getShops(keyword: string): any {
    // this.logger.info("ProductsService.getProducts()");
    // return this.http.get<any>(this.api.createUrl(ApiRoutes.PRODUCTS_LIST));
    return this.http.get<any>(this.api.createUrl(ApiRoutes.SHOPS));
  }

  public getShop(uuid: any): Observable<any> {
    // this.logger.info("ProductsService.getProduct() -> uuid: " + uuid);
    return this.http.get<any>(this.api.createUrl(ApiRoutes.SHOP) + uuid);
  }

  public getShopCategories(shopUUID: string): any {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.SHOP) + shopUUID + '/categories/list',
    );
  }

  public getShopWithCategories(shopUUID: string): Observable<any[]> {
    const response1 = this.getShop(shopUUID);
    const response2 = this.getShopCategories(shopUUID);
    return forkJoin([response1, response2]);
  }

  public getShopHours(businessUUID: string, takeOutUUID: string): any {
    const response1 = this.getBusinessHours(businessUUID);
    const response2 = this.getTakeOutHours(takeOutUUID);
    return forkJoin([response1, response2]);
    // return this.http.get<any>(this.api.createUrl(ApiRoutes.SHOP_BUSINESS_HOURS) + businessUUID);
  }

  public saveShopHours(businessHours: any): any {
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.SHOP_BUSINESS_HOURS) + businessHours.uuid,
      businessHours,
    );
  }

  public getBusinessHours(businessUUID: string): any {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.SHOP_BUSINESS_HOURS) + businessUUID,
    );
  }

  public getTakeOutHours(takeOutUUID: string): any {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.SHOP_BUSINESS_HOURS) + takeOutUUID,
    );
  }

  public getBusinnesDays(uuid: string): any {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.SHOP_BUSINESS_DAYS) + uuid,
    );
  }

  public getBusinnesDaysByShopUUID(uuid: string): any {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.SHOP_BUSINESS_DAYS_BY_SHOP) + uuid,
    );
  }

  public getShopPreference(uuid: string): any {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.SHOP_PREFERENCE) + uuid,
    );
  }

  public savePreferences(prefs: any): any {
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.SHOP_PREFERENCE) + prefs.uuid,
      prefs,
    );
  }

  public getShopRTETemplate(uuid: string): any {
    //
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.SHOP_RTE_TEMPLATE) + uuid,
    );
  }

  public saveRTEScheduleTemplate(rte: any): any {
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.SHOP_RTE_TEMPLATE) + rte.uuid,
      rte,
    );
  }

  public getDeliveryRules(uuid: string): any {
    //
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.SHOP_DELIVERY_RULES) + uuid,
    );
  }

  public saveDeliveryRules(rules: any): any {
    //
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.SHOP_DELIVERY_RULES) + rules.uuid,
      rules,
    );
  }

  public saveShopDays(businessDays: any, shopUUID: any): any {
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.SHOP_BUSINESS_DAYS_BY_SHOP) + shopUUID,
      businessDays,
    );
  }

  public updateShopDays(businessDays: any): any {
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.SHOP_BUSINESS_DAYS) + businessDays.uuid,
      businessDays,
    );
  }

  public getMonerisInfo(uuid: string): any {
    //
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.SHOP_MONERIS_INFO) + uuid,
    );
  }

  public saveMonerisInfo(info: MonerisModel): any {
    //
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.SHOP_MONERIS_INFO) + info.uuid,
      info,
    );
  }

  public getStripeInfo(uuid: string): any {
    //
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.SHOP_STRIPE_INFO) + uuid,
    );
  }

  public saveStripeInfo(info: MonerisModel): any {
    //
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.SHOP_STRIPE_INFO) + info.uuid,
      info,
    );
  }

  public getGlobalPaymentsInfo(uuid: string): any {
    //
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.SHOP_GLOBAL_PAYMENT_INFO) + uuid,
    );
  }

  public saveGlobalPaymentsInfo(info: GlobalPaymentModel): any {
    //
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.SHOP_GLOBAL_PAYMENT_INFO) + info.uuid,
      info,
    );
  }

  public getMultiPointTakeout(shopUUID: string): any {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.SHOP_MULTI_TAKEOUT_LIST) + shopUUID,
    );
  }

  public createMultiPointTakeout(point: any): any {
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.SHOP_MULTI_TAKEOUT),
      point,
    );
  }

  public editMultiPointTakeout(point: any): any {
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.SHOP_MULTI_TAKEOUT) + '/' + point.uuid,
      point,
    );
  }

  public deletePointTakeout(uuid: string): any {
    return this.http.delete<any>(
      this.api.createUrl(ApiRoutes.SHOP_MULTI_TAKEOUT) + '/' + uuid,
    );
  }

  public getShopMessage(uuid: string): any {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.SHOP_MESSAGE) + uuid,
    );
  }

  public getShopMessageImage(uuid: string): any {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.SHOP_MESSAGE_IMAGE) + uuid,
    );
  }

  public getShopsWithPagination(
    confirmedOnly: boolean,
    comboOnly: boolean,
    sort: string,
    direction: string,
    page: number,
    pageSize: number,
    filterValue: string,
  ): Promise<any> {
    // const confirmed: string = (confirmedOnly) ? "1" : "0";
    // const isCombo: string = (comboOnly) ? "1" : "0";
    const routeURL: string = this.api.createUrl(ApiRoutes.SHOPS) + '?';

    // this.logger.info("ProductsService.updateProduct() -> sort: " + sort);

    let requestUrl: string;
    if (!sort || direction === '') {
      requestUrl = `${routeURL}page=${page + 1}&limit=${pageSize}`;
    } else {
      requestUrl = `${routeURL}orderBy=${sort}&sortedBy=${direction}&page=${
        page + 1
      }&limit=${pageSize}`;
    }

    // Remove special caracters from the filter value for proper searching
    filterValue = filterValue.replace(/%/g, '');

    if (filterValue !== '') {
      requestUrl += '&search=' + filterValue;
    }

    // this.logger.info("getProductsWithPagination() -> routeURL: " + routeURL);
    // this.logger.info("getProductsWithPagination() -> requestUrl: " + requestUrl);

    return new Promise((resolve, reject) => {
      this.http.get(requestUrl).subscribe({
        next: (response: any) => {
          // this.logger.info("ProductsService.getProductsWithPagination() -> this.employees: " + JSON.stringify(response.data));
          resolve(response);
        },
        error: reject,
      });
    });
  }

  public createShop(shop: any): any {
    return this.http.post<any>(this.api.createUrl(ApiRoutes.SHOPS), shop);
  }

  public updateShopByUUID(data: any, uuid: string): any {
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.SHOP) + uuid,
      data,
    );
  }

  public updateShopWoocommerceByUUID(data: any, uuid: string): any {
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.WOOCOMMERCE) + uuid,
      data,
    );
  }

  public deleteShop(uuid: string): any {
    return this.http.delete<any>(this.api.createUrl(ApiRoutes.SHOP) + uuid);
  }

  public testShopWoocommerce(shopUUID: string): any {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.SHOP) + shopUUID + '/test-connect',
    );
  }

  public categoryChildMoveAfter(
    shopUUID: string,
    categoryUUID: string,
    afterUUID: string,
  ): any {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.SHOP) +
        shopUUID +
        '/categories/move-after/' +
        categoryUUID +
        '/' +
        afterUUID,
    );
  }

  public categoryChildMoveBefore(
    shopUUID: string,
    categoryUUID: string,
    beforeUUID: string,
  ): any {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.SHOP) +
        shopUUID +
        '/categories/move-before/' +
        categoryUUID +
        '/' +
        beforeUUID,
    );
  }

  public updateShopMessage(uuid: string, data: any): any {
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.SHOP_MESSAGE) + uuid,
      data,
    );
  }

  public updateShopMessageImage(uuid: string, data: any): any {
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.SHOP_MESSAGE_IMAGE) + uuid,
      data,
    );
  }

  public clearShopMessageImage(uuid: string, data: any): any {
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.SHOP_MESSAGE_IMAGE_CLEAR) + uuid,
      data,
    );
  }

  public clearShopMessage(uuid: string): any {
    //
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.SHOP_CLEAR_MESSAGE) + uuid,
      {},
    );
  }

  public getShopNextRTEScheduleStack(uuid: string): any {
    //
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.SHOP_RTE_SCHEDULE_STACK) + uuid,
    );
  }

  public getAllScheduleByShopUUID(uuid: string): any {
    // const response1 = this.getScheduleByUUID("delivery", uuid);
    // const response2 = this.getScheduleByUUID("takeout", uuid);
    // return forkJoin([response1, response2]);
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.SHOP_RTE_SCHEDULE_SHOP) + uuid,
    );
  }

  public getScheduleByUUID(type: string, uuid: string): any {
    //
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.SHOP_RTE_SCHEDULE) + '/' + type + '/' + uuid,
    );
  }

  public postScheduleByUUID(schedule: any): any {
    //
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.SHOP_RTE_SCHEDULE),
      schedule,
    );
  }

  public postScheduleUnifiedByUUID(schedule: any): any {
    //
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.SHOP_RTE_SCHEDULE_UNIFIED),
      schedule,
    );
  }

  public saveScheduleByUUID(uuid: string, schedule: any): any {
    //
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.SHOP_RTE_SCHEDULE) + '/' + uuid,
      schedule,
    );
  }

  public saveScheduleUnifiedByUUID(uuid: string, schedule: any): any {
    //
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.SHOP_RTE_SCHEDULE_UNIFIED) + '/' + uuid,
      schedule,
    );
  }
  public deleteScheduleByUUID(uuid: string): any {
    //
    return this.http.delete<any>(
      this.api.createUrl(ApiRoutes.SHOP_RTE_SCHEDULE) + '/' + uuid,
    );
  }
}
