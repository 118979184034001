export const locale = {
  lang: 'fr-CA',
  data: {
    ORDERS: {
      ORDERS: 'Commandes',
      ORDER: 'Commande',
      PROPOSAL: 'Proposition',
      INVOICE: 'Facture',
      GUESS_COUNT: 'Nombre de convives',
      RESPONSIBLE_EMPLOYEE: 'Conseiller',
      PROJECT_AND_EVENT: 'Projet et événement',
      PROGRESS: 'Déroulement',
      PROJECT: 'Projet',
      DEPOSIT: 'Dépôt',
      STAFF: 'Personnel',
      SERVICE_CHARGE: 'Frais de service',
      SERVICE_CHARGE_CT: "Frais d'administration",
      SPECIFICS_CONDITIONS: 'Conditions spécifiques',
      KITCHEN_NOTE: 'Production',
      ALLERGIES_NOTE: 'Allergies et restrictions alimentaires',
      MATERIAL_NOTE: 'Matériel',
      ADD_PAYMENT: 'Ajouter un paiement',
      WRAPPING_SELECT: 'Sélectionner un emballage',
      WRAPPING: 'Emballage spécifique à la commande',
      PRODUCTS_NO_RESULTS: 'Aucune nourritures et boissons pour cette commande',
      SUB_TOTAL_PRODUCTS: 'Sous-total nourritures & boissons:',
      SUB_TOTAL_SERVICE_CHARGE: 'Sous-total frais de services:',
      SUB_TOTAL_STAFFS: 'Sous-total personnels:',
      SUB_TOTAL_PER_PERSON: 'Prix/personne:',
      PRICE_SUBTOTAL: 'Sous-total prix',
      ADD_PRODUCT: 'Ajouter des produits',
      VISIT_EVENT: 'Voir événement',
      VISIT_PROJECT: 'Voir projet',
      ADD_MATERIAL: 'Ajouter du matériel',
      MATERIALS_NO_RESULTS: 'Aucun matériel pour cette commande',
      SUB_TOTAL_MATERIALS: 'Sous-total matériels:',
      DOES_NOT_NEED_DELIVERY: 'Le client viendra au comptoir',
      NO_DELIVERY_REQUEST: 'Aucune demande de livraison',
      CAPACITY: 'Capacité',
      ROYALTY: 'Redevances',
      SELECT_RESPONSIBLE_EMPLOYEE: 'Sélectionner le conseiller',

      CLONE_INTO_EVENT: 'Copier dans un nouvel événement',
      CLONE_ORDER_INTO_EVENT: 'Copier pour un autre client',
      CLONE_ORDER_INTO_EVENT_KEEP_INFO:
        'Copier cet événement à une nouvelle date',
      CLONE_PROPOSITION_INTO_EVENT: 'Copier pour un autre client',
      CLONE_PROPOSITION_INTO_EVENT_KEEP_INFO:
        'Copier cet événement à une nouvelle date',
      CLONE_PROPOSITION: 'Copier proposition',
      ACCEPT_PROPOSAL: 'Accepter proposition',
      ACCEPT_PROPOSAL_SUCESS: 'Proposition accepté!',
      REFUSE_PROPOSAL: 'Refuser proposition',
      REFUSE_PROPOSAL_SUCESS: 'Proposition refusé!',
      VALIDATE_DATE_MESSAGE: 'Veuillez prendre le temps de réviser les dates.',
      CANCEL_ORDER: 'Annuler la commande',
      CANCEL_ORDER_SUCCESS: 'Commande annulé!',
      CONFIRM_ORDER: 'Confirmer la commande',
      CONFIRM_ORDER_SUCCESS: 'Commande confirmée!',
      NON_TAXABLE_TIP: 'Pourboire sans taxe',
      NO_VENUES: 'Aucun lieu',
      ADD_SERVICE_CHARGE: 'Ajouter un frais de service',
      NO_SERVICE_CHARGE: 'Aucun frais de service pour cette commande',
      ADD_SPECIFIC_CONDITION: 'Ajouter une condition spécifique',
      NO_SPECIFIC_CONDITION: 'Aucune condition spécifique pour cette commande',
      SEND_ORDER: 'Envoyer la commande',
      SEND_ORDER_TOOLTIP: 'Envoyer<br/>commande',
      SEND_ORDER_PROPOSAL_TOOLTIP: 'Envoyer<br/>commande ou proposition',
      PURCHASE_ORDER: 'Bon commande',
      PURCHASE_ORDER_KITCHEN: 'Bon commande - Cuisine',
      PURCHASE_ORDER_CLIENT: 'Bon commande - Client',
      PURCHASE_ORDER_MATERIAL: 'Bon de matériel par fournisseur',
      PURCHASE_ORDER_ONLY_MATERIAL: 'Bon de matériel',
      PURCHASE_ORDER_STAFF: ' Bon de personnel',
      EDIT_PRODUCT: 'Modifier nourriture/boisson',
      EDIT_MATERIAL: 'Modifier un matériel',
      NO_STAFF: 'Aucun personnel assigné pour cette commande',
      ADD_STAFF: 'Ajouté du personnel',
      COMPLETE_ORDER: 'Créer facture',
      CONFIRM_ORDER_ALERT_TITLE: 'Confirmer la commande',
      CONFIRM_ORDER_ALERT_PRE_MESSAGE:
        'Cette commande ne peut être confirmée que si elle possède ces items: ',
      CONFIRM_ORDER_ALERT_NO_INVOICING_CLIENT: 'client facturé',
      CONFIRM_ORDER_ALERT_NO_ORDERING_CLIENT: 'client qui commande',
      CONFIRM_ORDER_ALERT_NO_SPOT: 'un lieu',
      CONFIRM_ORDER_ALERT_NO_DELIVERY_TYPE: 'un type de livraison',
      CONFIRM_ORDER_ALERT_NO_EVENT_TIME: "l'heure de l'événement",
      PROPOSAL_EXPIRED_AT: "Valide jusqu'au",
      INVOICED_AT: 'Facturé le',
      INVOICE_SENT_TITLE: 'Facture envoyée',
      INVOICE_SENT_MESSAGE:
        "Vous modifiez une facture déjà envoyée, n'oubliez pas de renvoyer la nouvelle version de la facture modifiée.",
      COMPLETE_INVOICE_PAID: 'Complété et payé',
      COMPLETE_INVOICE_DIALOG_MESSAGE:
        'La balance de la facture est maintenant de 0$ ou inférieur. Voulez-compléter la facture avec le statut payé ?',
      CANCEL_INVOICE: 'Annuler la facture',
      CLOSE_INVOICE: 'Fermer cette facture UBR',
      APPLY_CHILDREN: 'Appliquer à tout les enfants',
      WARNING_COMBO_1:
        'Attention: Avec cette configuration, les rapports n’afficheront pas l’item et par conséquence le prix aussi mais sera calculé.',
      WARNING_COMBO_2:
        'Attention: Avec cette configuration, les rapports n’afficheront pas le prix de l’item, mais sera calculé.',
      INTERNAL_VIEW_TRUE: 'Afficher à l’interne seulement',
      INTERNAL_VIEW_FALSE: 'Afficher à l’interne seulement',
      SHOW_UNIT_PRICE_TRUE: 'Afficher le prix unitaire',
      SHOW_UNIT_PRICE_FALSE: 'Afficher le prix unitaire',
      REFUSE_PROPOSAL_ALERT:
        'Êtes vous sûr de vouloir refuser cette proposition?',
      CANCEL_INVOICE_ALERT: 'Êtes-vous sûr de vouloir annuler cette facture?',
      CLOSE_INVOICE_ALERT: 'Êtes-vous sûr de vouloir fermer cette facture?',
      CANCEL_ORDER_ALERT: 'Êtes vous sûr de vouloir annuler cette commande?',
      VISIBILITY: 'Visibilité',
      GROUP_ACTION_PRICE_MODAL_TITLE: 'Modifier les prix',
      GROUP_ACTION_QTY_MODAL_TITLE: 'Modifier les quantités',
      GROUP_ACTION_PRODUCT_CATEGORY_MODAL_TITLE:
        'Modifier les catégories de produit',
      GROUP_ACTION_MATERIAL_CATEGORY_MODAL_TITLE:
        'Modifier les catégories de matériel',
      GROUP_ACTION_TOOLTIP:
        'Action de groupe. Au moins 1 item doit être sélectionné',
      GROUP_ACTION_SELECTED: 'sélectionné',
      GROUP_ACTION_SELECTED_POSTFIX: 's',
      GROUP_ACTION_MODIFY_QTY: 'Modifier quantité',
      GROUP_ACTION_MODIFY_PRICE: 'Modifier prix',
      GROUP_ACTION_MODIFY_FREE: 'Gratuité',
      GROUP_ACTION_CLIENT_VISIBLE: 'Visible au client',
      GROUP_ACTION_CLIENT_INVISIBLE: 'Invisible au client',
      GROUP_ACTION_SHOW_CLIENT_PRICE: 'Afficher le prix au client',
      GROUP_ACTION_SHOW_CLIENT_PRICE_NOT: 'Ne pas afficher le prix au client',
      LETTER_FORMAT: 'Format lettre',
      LEGAL_FORMAT: 'Format légal',
      ASSIGNMENTS: 'Affectations',
      PRODUCTION_NOTE: 'Note de production',
      SALE_NOTE: 'Note du produit vente',
      BUFFET_LABEL_5963: 'Étiquette plateaux et buffet',
      BOX_LABEL_55164: 'Étiquette coffret',
      REPORT_MNGT_ORDER_PURCHASE_ORDER_KITCHEN_STAFF:
        'Bon commande cuisine et personnel - DAN',
      REPORT_DAN_ORDER_PURCHASE_ORDER_DELIVERY: 'Bon commande livraison - DAN',
      REPORT_DAN_ORDER_PURCHASE_ORDER_CLIENT: 'Bon commande client - DAN',
      REPORT_DAN_ORDER_PURCHASE_ORDER_STACK: 'Bon de commande production - DAN',
      ORDER_GAB: "Bon d'événement | St-Gab",
      CONTRAT_GAB: 'Contrat',
      CONTRAT_BANQUET: 'Contrat Banquet',
      SC_FIX_PRICE: 'Prix Fixe',
      SC_PERCENT: 'Pourcentage',
      SC_NIGHT: 'Chambre',
      RP_CONTRACT_FULL: 'Contrat complet | RP',
      RP_CONTRACT_HOSTING: 'Contrat hébergement | RP',
      RP_CONTRACT_ROOMS: 'Contrat salle | RP',
      VISIT_DEPOSITS: 'Encaissement et dépôt',
      CT_ESTIMATION: 'Estimation',
      PURCHASE_ORDER_CHEF: 'Bon commande Chef - MC',
      PURCHASE_ORDER_HOT: 'Bon commande Chaud - MC',
      PURCHASE_ORDER_COLD: 'Bon commande Froid - MC',
      PURCHASE_ORDER_PASTRY: 'Bon commande Pâtisserie - MC',
      PURCHASE_ORDER_SERVICE: 'Bon commande service - MC',
      PURCHASE_ORDER_HOD_AND_COLD: 'Bon commande Chaud/Froid - MC',
      PRODUCT_DESCRIPTION_FR: 'Description (FR)',
      PRODUCT_DESCRIPTION_EN: 'Description (EN)',
      EXTERNAL_NAME_EN: 'Nom externe (EN)',
      EXTERNAL_NAME_FR: 'Nom externe (FR)',
      INTERNAL_NAME_EN: 'Nom interne (EN)',
      INTERNAL_NAME_FR: 'Nom interne (FR)',
      SYNC_STAFF: 'Staff',
      CHANGELOG: 'Avis de changement',
      PRODUCTION_ZONE: 'Zone de production',
      NOTE_LOGISTIC: 'Note matériel logistique',
      NOTE_SALE_MATERIAL: 'Note matériel vente',
      MATERIAL_PROVIDER: 'Nom du fournisseur',
      NO_MATERIAL_PROVIDER_RESULTS: 'Pas de fournisseur',
      REPORT_CLIENT_RECEIPT_NOTICE_UQAM: 'Avis de réception',
      INVOICE_UBR: 'Facture UBR',
      INVOICE_GLOBAL_PAYMENTS: 'Facture pmt en ligne (GP)',
      UBR_MANAGER: 'Responsable du UBR',
    },
  },
};
