import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { EnterprisesService } from '../../../api/enterprises.service';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import { Settings } from '../../../settings/settings';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-create-enterprise-for-client',
  templateUrl: './create-enterprise-for-client.component.html',
  styleUrls: ['./create-enterprise-for-client.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    FlexLayoutModule,
    MatInputModule,
    MatProgressSpinnerModule,
    TranslateModule,
  ],
})
export class CreateEnterpriseForClientComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  form: UntypedFormGroup;
  clientEnterprise: any;
  loading: boolean = false;
  clientUUID: string;

  constructor(
    public override dialogRef: MatDialogRef<CreateEnterpriseForClientComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    private enterpriseService: EnterprisesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private logger: NGXLogger,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    this.clientUUID = data.uuid;
    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
    });

    this.clientEnterprise = {
      name: null,
    };
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public onCreateEnterpriseForClient(): void {
    this.loading = true;
    this.enterpriseService
      .createEnterpriseForClient(this.clientEnterprise, this.clientUUID)
      .subscribe({
        next: (response) => {
          // this.logger.info("CreateClientEnterpriseComponent.onCreateClient() -> SUCCESS: " + JSON.stringify(response));
          this.toastr.success(
            this.translate.instant('CREATE_ENTERPRISE_CLIENT.CREATE_SUCCESS'),
          );
          this.dialogRef.close(response);
          this.loading = false;
        },
        error: (error) => {
          this.logger.error(
            'CreateClientEnterpriseComponent.onCreateClient() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.GENERIC'),
          );
        },
      });
  }
}
