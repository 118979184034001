import { TakeoutRequestService } from './../../../api/takeout-request.service';
import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { WrappingService } from '../../../api/wrapping.service';
import { ValidatorHelper } from '../../../validators/ValidatorHelper';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Editor } from 'primeng/editor';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import { Settings } from '../../../settings/settings';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatSelectModule } from '@angular/material/select';
import { Gt2SelectComponent } from '../../gt2-select/gt2-select.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CalendarDateFormatter } from 'angular-calendar';
import { CustomDateFormatter } from '../../../content/calendar/CustomDateFormatter';
import { GT2DateAdapter } from '../../../utils/GT2DateAdapter';
//import * as _ from 'lodash';

@Component({
  selector: 'app-create-takeout-request',
  templateUrl: './create-takeout-request.component.html',
  styleUrls: ['./create-takeout-request.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDatepickerModule,
    FlexLayoutModule,
    FormsModule,
    MatSelectModule,
    Gt2SelectComponent,
    NgxMaterialTimepickerModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    TranslateModule,
    MatProgressSpinnerModule,
  ],
  providers: [
    { provide: DateAdapter, useClass: GT2DateAdapter },
    { provide: CalendarDateFormatter, useClass: CustomDateFormatter },
  ],
})
export class CreateTakeoutRequestComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  static CREATE: string = 'CREATE';
  static EDIT: string = 'EDIT';

  //
  mode: string = CreateTakeoutRequestComponent.CREATE;
  form: UntypedFormGroup;
  takeoutRequest: any;
  takeoutRequestTemplate: any;
  eventUUID!: string;
  event: any;
  loading: boolean = false;
  autoRedirect: boolean = false;
  noteInvalid: boolean = false;
  noteEditorSubscription?: Subscription;
  noteEditordebounceTimeValue: number = 1500;
  @ViewChild('noteEditor') noteEditor?: Editor;
  takeoutRequests?: any[];
  selectedTakeoutRequest: any;
  takoutRequestToUpdate: any;
  takeoutRequestData: any;
  initGt2Select: boolean = false;
  templatesEmpty: boolean = false;

  takeoutRequestType: string = 'TYPE_CLIENT_TAKEOUT';
  takeoutRequestContent: string = 'CONTENT_FOOD';

  constructor(
    public override dialogRef: MatDialogRef<CreateTakeoutRequestComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    // public deliveryRequestTypeService: DeliveryRequestTypeService,
    // public deliveryRequestContentService: DeliveryRequestContentService,
    // public deliveryRequestTemplateService: DeliveryRequestTemplateService,
    public wrappingService: WrappingService,
    // private deliveryRequestService: DeliveryRequestService,
    private takeoutRequestService: TakeoutRequestService,
    //private atp: any,
    public dialog: MatDialog,
    public router: Router,
    private adapter: DateAdapter<any>,
    private toastr: ToastrService,
    private logger: NGXLogger,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    this.adapter.setLocale(this.translate.currentLang);

    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      takeout_from_date: ['', [ValidatorHelper.dateRangeDefault]],
      takeout_to_date: ['', [ValidatorHelper.dateRangeDefault]],
      // price: ['', [ValidatorHelper.isNumber0To1000000, Validators.required]],
    });

    this.setTakeoutRequestDefault();

    //
    if (data && data.mode !== null) {
      this.mode = data.mode;
    }

    if (data && data.eventUUID !== null) {
      this.eventUUID = data.eventUUID;

      if (this.mode === CreateTakeoutRequestComponent.CREATE) {
        this.takeoutRequestService
          .getNewTakoutRequestData(this.eventUUID)
          .subscribe({
            next: (response: any) => {
              //this.logger.info("CreateTakeoutRequestComponent.constructor() -> SUCCESS: " + JSON.stringify(response));
              this.takeoutRequestData = response.data;
              this.takeoutRequest = this.takeoutRequestData;
              this.takeoutRequest.content = 'CONTENT_FOOD'; // default
              this.takeoutRequest.type = 'TYPE_CLIENT_TAKEOUT'; // default
              this.initGt2Select = true;
              // this.deliveryRequest.price = this.deliveryRequestData.price;
              // this.deliveryRequest.name = this.deliveryRequestData.name;
            },
            error: (error: any) => {
              this.logger.error(
                'CreateTakeoutRequestComponent.constructor() -> ERROR: ' +
                  JSON.stringify(error),
              );
              this.handleAPIError(error, this.dialog, null, null);
            },
          });
      } else {
        this.initGt2Select = true;
      }
    }

    if (data && data.event !== null) {
      //this.event = _.cloneDeep(data.event); // data.event;

      this.takeoutRequest.takeout_from_date =
        data.event.informations.event_date;
      this.takeoutRequest.takeout_to_date = data.event.informations.event_date;
      this.takeoutRequest.takeout_from_time =
        data.event.informations.event_time;
      this.takeoutRequest.takeout_to_time = data.event.informations.event_time;
      // //this.logger.info("CreateTakeoutRequestComponent.constructor() -> this.deliveryRequest: " + JSON.stringify(this.deliveryRequest));

      // if (this.event.spot.delivery_by_sector_price) {
      //   this.takeoutRequest.price = this.event.spot.delivery_by_sector_price;
      // }
    }

    if (data && data.autoRedirect !== null) {
      this.autoRedirect = data.autoRedirect;
    }

    if (data && data.takoutRequestToUpdate) {
      //this.takeoutRequest = _.cloneDeep(data.takoutRequestToUpdate);
      // //this.logger.info("CreateTakeoutRequestComponent.constructor() -> deliveryRequestToUpdate TO data.deliveryRequestToUpdate: " + JSON.stringify(data.deliveryRequestToUpdate));
      // //this.logger.info("CreateTakeoutRequestComponent.constructor() -> deliveryRequestToUpdate TO this.deliveryRequest: " + JSON.stringify(this.deliveryRequest));
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.noteEditorSubscription = this.noteEditor?.onTextChange
      .pipe(debounceTime(this.noteEditordebounceTimeValue))
      .pipe(distinctUntilChanged())
      .subscribe({
        next: (data) => {
          if (data.htmlValue) {
            this.noteInvalid =
              data.htmlValue.length > Settings.editorMaxCharLong;
            // //this.logger.info("CreateTakeoutRequestComponent.noteEditor.onTextChange() -> this.noteInvalid: " + this.noteInvalid);
            if (this.noteInvalid) {
              return;
            }
          }
        },
      });

    // //this.logger.info("CreateTakeoutRequestComponent.onDeliveryRequestTemplateChange() -> deliveryRequest: " + JSON.stringify(this.takeoutRequest));
  }

  ngOnDestroy(): void {}

  public setTakeoutRequestDefault(): void {
    this.takeoutRequest = {
      name: '',
      note: '',
      show_on_purchase_order_production: false,
      show_on_purchase_order_client_copy: false,
      show_on_client_proposal_and_order: false,
      show_on_purchase_order_kitchen: false,
      show_on_purchase_material: false,
      takeout_from_date: null,
      takeout_to_date: null,
      takeout_from_time: null,
      takeout_to_time: null,
      wrapping: null,
      content: 'CONTENT_FOOD', // default
      type: 'TYPE_CLIENT_TAKEOUT', // default
    };

    //this.logger.info("setTakeoutRequestDefault() >>>>>> " + JSON.stringify(this.takeoutRequest));
    // 'type' => 'required',
  }

  public onTakeoutRequestTypeChange(event: any): void {
    // //this.logger.info("CreateTakeoutRequestComponent.onDeliveryRequestTypeChange() -> event: " + JSON.stringify(event));
    //this.logger.info("CreateTakeoutRequestComponent.onDeliveryRequestTypeChange() -> event: " + event.value);
    this.takeoutRequest.type = event.value;
    // //this.logger.info("CreateTakeoutRequestComponent.onWrappingSelected() -> type: " + JSON.stringify(this.deliveryRequest.type));
    //this.logger.info("setTakeoutRequestDefault() >>>>>> " + JSON.stringify(this.takeoutRequest));
  }

  public onTakeoutRequestContentChange(event: any): void {
    // //this.logger.info("CreateTakeoutRequestComponent.onDeliveryRequestContentChange() -> event: " + JSON.stringify(event));
    //this.logger.info("CreateTakeoutRequestComponent.onDeliveryRequestContentChange() -> event: " + event.value);
    this.takeoutRequest.content = event.value;
    // //this.logger.info("CreateTakeoutRequestComponent.onWrappingSelected() -> content: " + JSON.stringify(this.deliveryRequest.content));
    //this.logger.info("onTakeoutRequestContentChange() >>>>>> " + JSON.stringify(this.takeoutRequest));
  }

  public openStartTimePicker(): void {
    // const amazingTimePicker = this.atp.open({
    //   time: this.takeoutRequest.takeout_from_time,
    // });
    // amazingTimePicker.afterClose().subscribe((time: any) => {
    //   this.takeoutRequest.takeout_from_time = time;
    // });
  }
  public openEndTimePicker(): void {
    // const amazingTimePicker = this.atp.open({
    //   time: this.takeoutRequest.takeout_to_time,
    // });
    // amazingTimePicker.afterClose().subscribe((time: any) => {
    //   this.takeoutRequest.takeout_to_time = time;
    // });
  }

  public onWrappingSelected(event: any): void {
    // //this.logger.info("CreateTakeoutRequestComponent.onWrappingSelected() -> event: " + JSON.stringify(event));
    this.takeoutRequest.wrapping = event;
    // //this.logger.info("CreateTakeoutRequestComponent.onWrappingSelected() -> wrapping: " + JSON.stringify(this.deliveryRequest.wrapping));
    //this.logger.info("onWrappingSelected() >>>>>> " + JSON.stringify(this.takeoutRequest));
  }

  public onCreateTakeoutRequest(): void {
    this.loading = true;
    // //this.logger.info("CreateDeliveryRequestComponent.onCreateFlow() -> flow: " + JSON.stringify(this.deliveryRequest));

    if (this.mode === CreateTakeoutRequestComponent.CREATE) {
      this.doCreateTakeoutRequest();
    } else if (this.mode === CreateTakeoutRequestComponent.EDIT) {
      this.updateTakeoutRequest();
    }
  }

  public doCreateTakeoutRequest(): void {
    // //this.logger.info("CreateTakeoutRequestComponent.doCreateFlow() -> selectedFlow: " + JSON.stringify(this.selectedDeliveryRequest));
    this.takeoutRequestService
      .createTakoutRequest(this.takeoutRequest, this.eventUUID)
      .subscribe({
        next: (response: any) => {
          // //this.logger.info("CreateTakeoutRequestComponent.doCreateDeliveryRequest() -> SUCCESS: " + JSON.stringify(response));
          this.loading = false;
          this.dialogRef.close(response);
        },
        error: (error: any) => {
          this.logger.error(
            'CreateTakeoutRequestComponent.doCreateDeliveryRequest() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.GENERIC'),
          );
          // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
        },
      });
  }

  public updateTakeoutRequest(): void {
    // //this.logger.info("CreateTakeoutRequestComponent.updateFlow() -> ***** UPDATE this.deliveryRequest: " + JSON.stringify(this.deliveryRequest));
    this.takeoutRequestService
      .updateTakoutRequest(this.takeoutRequest, this.eventUUID)
      .subscribe({
        next: (response: any) => {
          // //this.logger.info("CreateDeliveryRequestComponent.onCreateFlow() -> SUCCESS: " + JSON.stringify(response));
          this.loading = false;
          this.dialogRef.close(response);
        },
        error: (error: any) => {
          this.logger.error(
            'CreateTakeoutRequestComponent.onCreateFlow() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.GENERIC'),
          );
          // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
        },
      });
  }

  // public onDeliveryRequestTemplateChange(model: any): void {
  //   //this.logger.info("CreateTakeoutRequestComponent.onDeliveryRequestTemplateChange() -> 0. this.deliveryRequest: " + JSON.stringify(this.takeoutRequest));
  //   //this.logger.info("CreateTakeoutRequestComponent.onDeliveryRequestTemplateChange() -> model:" + JSON.stringify(model));
  //   // //this.logger.info("CreateDeliveryRequestComponent.onDeliveryRequestTemplateChange() -> 1. this.deliveryRequest: " + JSON.stringify(this.deliveryRequest));
  //   // this.deliveryRequest = model;
  //   for (const key in model) {
  //     if (model.hasOwnProperty(key)
  //       && key !== 'object'
  //       && key !== 'uuid') {
  //       this.takeoutRequest[key] = model[key];
  //     }
  //   }

  //   // if (this.deliveryRequest.price === 0)
  //   // {
  //   //     this.deliveryRequest.price = null;
  //   // }

  //   this.takeoutRequest.delivery_from_date = this.event.informations.event_date;
  //   this.takeoutRequest.delivery_to_date = this.event.informations.event_date;

  //   //this.logger.info("CreateTakeoutRequestComponent.onDeliveryRequestTemplateChange() -> 1. this.deliveryRequest: " + JSON.stringify(this.takeoutRequest));
  // }

  // public onDeliveryRequestTemplateModelSet(data: any): void {
  //   //this.logger.info("CreateTakeoutRequestComponent.onDeliveryRequestTemplateModelSet() -> " + JSON.stringify(data));
  //   this.templatesEmpty = data.isEmpty;
  // }

  // public onNavCreateTemplate(): void {
  //   //this.logger.info("CreateTakeoutRequestComponent.onNavCreateTemplate()");
  //   this.dialogRef.close();

  //   this.router.navigateByUrl("/preferences/model-demande-livraison");
  // }
}
