<div class="dialog-content">
  <h1 class="h1-title" matDialogTitle>
    {{ "ASSIGNMENTS.ASSIGNMENTS_GROUP" | translate }}
  </h1>

  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    name="assignmentSpiner"
    size="medium"
    color="#90ee90"
    [fullScreen]="true"
    type="ball-scale-multiple"
  >
  </ngx-spinner>

  <div *ngIf="jobsFunction && jobsFunction.length > 0 && !editMode" class="">
    <mat-form-field style="width: 100%">
      <mat-label>{{ "GENERIC.JOB_FUNCTIONS" | translate }}</mat-label>
      <mat-select
        (selectionChange)="onJobsChange($event)"
        [(ngModel)]="selectedJobsFunction"
        required
      >
        <mat-option *ngFor="let fonction of jobsFunction" [value]="fonction">
          <span class="iso">{{ fonction.name }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div
    style="
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 12px;
      font-family: Roboto, &quot;Helvetica Neue&quot;, sans-serif;
    "
  >
    {{ "ASSIGNMENTS.ASSIGMENTS_DEFAULT_VALUES_LABEL" | translate }}
  </div>

  <div *ngIf="jobsFunction && editMode" class="">
    <mat-form-field style="width: 100%">
      <mat-label>{{ "GENERIC.JOB_FUNCTIONS" | translate }}</mat-label>
      <input
        matInput
        fxFlex="100%"
        type="text"
        disabled="true"
        [ngModel]="selectedJobsFunction.name"
        autocomplete="nope"
      />
    </mat-form-field>
    <!-- <div class="" style="font">{{ 'GENERIC.JOB_FUNCTIONS' | translate }}</div>
    <div class="mat-h3">{{ selectedJobsFunction.name }}</div> -->
  </div>

  <div
    fxLayout="column"
    fxLayoutAlign="center start"
    [formGroup]="form"
    (keyup.enter)="
      form.valid &&
      !loading &&
      group.job_function_uuid &&
      group.requested_time_from &&
      group.requested_time_to
        ? onCreateGroup()
        : null
    "
  >
    <mat-form-field style="width: 100%">
      <mat-label>{{ "ASSIGNMENTS.GROUP_NAME" | translate }}</mat-label>
      <input
        matInput
        fxFlex="100%"
        formControlName="name"
        type="text"
        autocomplete="nope"
        [(ngModel)]="group.name"
        required
      />
      <mat-error *ngIf="form.controls['name'].invalid">{{
        "GENERIC.INPUT_LENGTH_INVALID_SMALL" | translate
      }}</mat-error>
    </mat-form-field>

    <div
      style="width: 100%; margin-bottom: 10px; margin-top: 4px"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <!-- <div fxFlex="50">
            <mat-checkbox   
                [(ngModel)]="group.requested_all_day"
                [ngModelOptions]="{standalone: true}">
                {{ 'ASSIGNMENTS.REQUESTED_ALL_DAY' | translate }}
            </mat-checkbox>
        </div> -->

      <div fxFlex="50">
        <mat-checkbox
          [(ngModel)]="group.billable"
          [ngModelOptions]="{ standalone: true }"
        >
          {{ "ASSIGNMENTS.BILLABLE" | translate }}
        </mat-checkbox>
      </div>

      <div fxFlex="50">
        <mat-checkbox
          [(ngModel)]="group.tips_enabled"
          [ngModelOptions]="{ standalone: true }"
        >
          {{ "ASSIGNMENTS.TIPS_ENABLE" | translate }}
        </mat-checkbox>
      </div>
    </div>

    <!-- labelPosition="before" -->

    <div style="width: 100%" fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="50">
        <mat-form-field style="width: 100%; margin-right: 6px" class="mt-16">
          <mat-label>{{ "ASSIGNMENTS.DATE_START" | translate }}</mat-label>
          <input
            matInput
            [matDatepicker]="eventDateFrom"
            [(ngModel)]="group.requested_date_from"
            min="1900-01-01"
            formControlName="date_from"
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="eventDateFrom"
          ></mat-datepicker-toggle>
          <mat-datepicker #eventDateFrom></mat-datepicker>

          <mat-error *ngIf="form.controls['date_from'].invalid"
            >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div
        fxFlex="50"
        style="margin-bottom: 16px; margin-left: 6px"
        [ngClass]="group.requested_all_day ? 'time-disabled' : ''"
      >
        <mat-label> {{ "ASSIGNMENTS.TIME_START" | translate }} * </mat-label>
        <ngx-timepicker-field
          [buttonAlign]="'left'"
          [format]="24"
          [disabled]="group.requested_all_day"
          [(ngModel)]="group.requested_time_from"
          [ngModelOptions]="{ standalone: true }"
          class="item-full-width ngx-material-timepicker-toggle"
        >
        </ngx-timepicker-field>
      </div>
    </div>

    <div style="width: 100%" fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="50">
        <mat-form-field style="width: 100%; margin-right: 6px" class="mt-16">
          <mat-label>{{ "ASSIGNMENTS.DATE_END" | translate }}</mat-label>
          <input
            matInput
            [matDatepicker]="eventDateTo"
            [(ngModel)]="group.requested_date_to"
            min="1900-01-01"
            formControlName="date_to"
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="eventDateTo"
          ></mat-datepicker-toggle>
          <mat-datepicker #eventDateTo></mat-datepicker>

          <mat-error *ngIf="form.controls['date_to'].invalid"
            >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div
        fxFlex="50"
        style="margin-bottom: 16px; margin-left: 6px"
        [ngClass]="group.requested_all_day ? 'time-disabled' : ''"
      >
        <mat-label> {{ "ASSIGNMENTS.TIME_END" | translate }} * </mat-label>
        <ngx-timepicker-field
          [buttonAlign]="'left'"
          [format]="24"
          [disabled]="group.requested_all_day"
          [(ngModel)]="group.requested_time_to"
          [ngModelOptions]="{ standalone: true }"
          class="item-full-width ngx-material-timepicker-toggle"
        >
        </ngx-timepicker-field>
      </div>
    </div>

    <div style="width: 100%" fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="50">
        <mat-form-field style="width: 100%; margin-right: 6px">
          <mat-label>{{ "ASSIGNMENTS.MEAL_INCLUDED" | translate }}</mat-label>
          <input
            matInput
            fxFlex="100%"
            formControlName="meal_included"
            numeric
            type="text"
            autocomplete="nope"
            pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
            [(ngModel)]="group.meal_included"
            required
          />
          <!-- <mat-error *ngIf="form.controls['meal_included'].invalid">{{ 'GENERIC.INPUT_LENGTH_INVALID' | translate }}</mat-error> -->
        </mat-form-field>
      </div>

      <div *ngIf="editMode" fxFlex="50" style="margin-left: 6px; height: 74px">
        <div class="edit-mode-label mat-caption">
          {{ "ASSIGNMENTS.JOBS_COUNT" | translate }}
        </div>
        <div class="edit-mode-value mt-4">{{ group.job_count }}</div>
      </div>

      <div *ngIf="!editMode" fxFlex="50">
        <mat-form-field style="width: 100%; margin-left: 6px">
          <mat-label>{{ "ASSIGNMENTS.JOBS_COUNT" | translate }}</mat-label>
          <input
            matInput
            fxFlex="100%"
            formControlName="job_count"
            numeric
            [decimals]="0"
            [allowNegative]="false"
            type="text"
            autocomplete="nope"
            pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
            [(ngModel)]="group.job_count"
            required
          />

          <!-- TODO: ERROR VALIDATION -->
          <mat-error *ngIf="form.controls['job_count'].invalid">{{
            "ASSIGNMENTS.JOBS_COUNT_OVER_50" | translate
          }}</mat-error>
        </mat-form-field>
      </div>
    </div>

    <app-rich-text-locale
      [richText]="group"
      [localKey]="'note'"
      [charCountLocal]="'ASSIGNMENTS.CHAR_COUNT_MESSAGE'"
      [applyFocus]="true"
      [maxLength]="2048"
      [editMode]="true"
      [showClearButton]="false"
      [showSaveButton]="false"
      [allowColors]="false"
      style="width: 100%; margin-top: 4px"
    >
    </app-rich-text-locale>
  </div>

  <div
    mat-dialog-actions
    [formGroup]="form"
    class="mt-24 gt2-buttons-theme"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button
      *ngIf="group.requested_all_day"
      mat-raised-button
      color="accent"
      class="mr-16"
      type="submit"
      [disabled]="!form.valid || loading || !group.job_function_uuid"
      (click)="onCreateGroup()"
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>

    <button
      *ngIf="!group.requested_all_day"
      mat-raised-button
      color="accent"
      class="mr-16"
      type="submit"
      [disabled]="
        !form.valid ||
        loading ||
        !group.job_function_uuid ||
        !group.requested_time_from ||
        !group.requested_time_to
      "
      (click)="onCreateGroup()"
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>

    <button
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(false)"
      class=""
    >
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
