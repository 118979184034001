import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { EmployeeAssignmentsComposeService } from './../../../api/employee-assignments-compose.service';
import { AssignmentReportComposeComponent } from './../crud-employee/assignment-report-compose/assignment-report-compose.component';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import {
  CalendarModule as AngularCalendarModule,
  DateAdapter,
} from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import { ClipboardModule } from 'ngx-clipboard';
import { EditorModule } from 'primeng/editor';
import { FuseSidebarModule } from '../../../../../@fuse/components/sidebar/sidebar.module';
import { FuseWidgetModule } from '../../../../../@fuse/components/widget/widget.module';
import { DatatableHelperService } from '../../../../services/datatable-helper.service';
import { CreateClientService } from '../../../api/create-client.service';
import { EmployeesService } from '../../../api/employees.service';
import { JobsFunctionService } from '../../../api/jobs-function.service';
import { AddJobsFunctionDialogComponent } from '../../../components/dialogs/add-jobs-function-dialog/add-jobs-function-dialog.component';
import { CreateEmployeeComponent } from '../../../components/dialogs/create-employee/create-employee.component';
import { CanDeactivateGuard } from '../../../gards/can-deactivate-guard.service';
import { Gt2PrimengModule } from '../../../modules/gt2-primeng.module';
import { CrudEmployeeComponent } from '../crud-employee/crud-employee.component';
import { CrudEmployeeService } from '../crud-employee/crud-employee.service';
import { CrudEmployeesComponent } from './crud-employees.component';
import { ConvertEmployeeToUserComponent } from '../../../components/dialogs/convert-employee-to-user/convert-employee-to-user.component';
import { EmployeeAttachedSignatureComponent } from '../../employee/employee-attached-signature/employee-attached-signature.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { EmployeeAttachedSignatureService } from './../../../api/employee-attached-signature.service';
import { AppRoutesName } from '../../../const/AppRoutesName';
import { ClientsModule } from '../../clients/clients.module';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TableSizePipeModule } from '../../../modules/table-size.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FileNameExtensionIconPipeModule } from '../../../modules/file-name-extension-icon.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { CdkTableModule } from '@angular/cdk/table';
import { MatSortModule } from '@angular/material/sort';
import { CommentSectionComponent } from '../../../components/comment-section/comment-section.component';
import { AddressComponent } from '../../../components/address/address.component';
import { CreatedUpdatedAtComponent } from '../../../components/created-updated-at/created-updated-at.component';
import { ErrorDialogComponent } from '../../../components/dialogs/error-dialog/error-dialog.component';
import { GenericAlertComponent } from '../../../components/generic-alert/generic-alert.component';
import { Gt2SelectComponent } from '../../../components/gt2-select/gt2-select.component';
import { SectionHistoryPanelComponent } from '../../../components/section-history-panel/section-history-panel.component';
import { TagsByModelComponent } from '../../../components/tags-by-model/tags-by-model.component';
import { ContactComponent } from '../../../components/contact/contact.component';
import { ContactService } from '../../../components/contact/contact.service';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../../@fuse/pipes/pipes.module';
import moment from 'moment';

const routes = [
  {
    path: AppRoutesName.FR.EMPLOYEES,
    component: CrudEmployeesComponent,
    // resolve : {
    //     data: EmployeesService
    // }
  },
  {
    path: AppRoutesName.FR.EMPLOYEES + '/:slug' + '/:id',
    component: CrudEmployeeComponent,
    canDeactivate: [CanDeactivateGuard],
    // resolve  : {
    //     data: EcommerceOrderService
  },
  {
    path: AppRoutesName.EN.EMPLOYEES,
    component: CrudEmployeesComponent,
    // resolve : {
    //     data: EmployeesService
    // }
  },
  {
    path: AppRoutesName.EN.EMPLOYEES + '/:slug' + '/:id',
    component: CrudEmployeeComponent,
    canDeactivate: [CanDeactivateGuard],
    // resolve  : {
    //     data: EcommerceOrderService
  },
];

function momentAdapterFactory() {
  return adapterFactory(moment);
}
@NgModule({
  declarations: [CrudEmployeesComponent, CrudEmployeeComponent],
  imports: [
    AddJobsFunctionDialogComponent,
    CreateEmployeeComponent,
    EmployeeAttachedSignatureComponent,
    ClientsModule,
    FileNameExtensionIconPipeModule,
    AssignmentReportComposeComponent,
    TableSizePipeModule,
    ConvertEmployeeToUserComponent,
    MatTooltipModule,
    NgxPermissionsModule,
    MatOptionModule,
    CdkTableModule,
    CommentSectionComponent,
    MatButtonModule,
    MatMenuModule,
    MatSortModule,
    FormsModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatRippleModule,
    MatInputModule,
    MatTabsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatIconModule,
    TranslateModule,
    MatProgressBarModule,
    MatTableModule,
    GenericAlertComponent,
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    Gt2PrimengModule,
    ErrorDialogComponent,
    AddressComponent,
    ContactComponent,
    CreatedUpdatedAtComponent,
    MatSnackBarModule,
    FuseWidgetModule,
    RouterModule.forChild(routes),
    EditorModule,
    AngularCalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: momentAdapterFactory,
    }),
    ClipboardModule,
    FuseSidebarModule,
    TagsByModelComponent,
    Gt2SelectComponent,
    SectionHistoryPanelComponent,
    NgxDropzoneModule,
    NgxMaterialTimepickerModule,
  ],
  exports: [CrudEmployeesComponent, CrudEmployeeComponent],
  providers: [
    EmployeesService,
    CrudEmployeeService,
    JobsFunctionService,
    CurrencyPipe,
    ContactService,
    CanDeactivateGuard,
    CreateClientService,
    DatatableHelperService,
    EmployeeAssignmentsComposeService,
    EmployeeAttachedSignatureService,
  ],
})
export class CrudEmployeesModule {}
