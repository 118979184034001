<div class="dialog-content-wrapper">
  <mat-toolbar class="mat-accent m-0">
    <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">{{ "COMPOSE.TITLE" | translate }}</span>
      <button
        mat-icon-button
        (click)="matDialogRef.close()"
        aria-label="Close dialog"
        class="ml-8"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar>
    <div
      *ngIf="loading"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxFlex
      class="py-32"
    >
      <mat-spinner [diameter]="28" class="product-list-spinner"></mat-spinner>
    </div>

    <form
      *ngIf="!loading && composeData"
      name="composeForm"
      [formGroup]="composeForm"
      class="compose-form"
      fxLayout="column"
      fxFlex
    >
      <div
        [formGroup]="dateForm"
        style="width: 100%"
        fxLayout="row"
        fxLayoutAlign="start center"
      >
        <div fxFlex="50" class="mr-8">
          <mat-form-field style="width: 100%" class="mt-16">
            <mat-label>{{ "GENERIC.DATE_START" | translate }}</mat-label>
            <input
              matInput
              [matDatepicker]="eventDateFrom"
              [(ngModel)]="dateRange.date_from"
              min="1900-01-01"
              formControlName="real_date_from"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="eventDateFrom"
            ></mat-datepicker-toggle>
            <mat-datepicker #eventDateFrom></mat-datepicker>

            <mat-error *ngIf="dateForm.controls['real_date_from'].invalid"
              >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="50" class="mr-8">
          <mat-form-field style="width: 100%" class="mt-16">
            <mat-label>{{ "GENERIC.DATE_END" | translate }}</mat-label>
            <input
              matInput
              [matDatepicker]="eventDateTo"
              [(ngModel)]="dateRange.date_to"
              min="1900-01-01"
              formControlName="real_date_to"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="eventDateTo"
            ></mat-datepicker-toggle>
            <mat-datepicker #eventDateTo></mat-datepicker>

            <mat-error *ngIf="dateForm.controls['real_date_to'].invalid"
              >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="show-hide-extra-fields" (click)="toggleExtraToFields()">
        <span [fxShow]="!showExtraToFields">{{
          "COMPOSE.SHOW_CC_BCC" | translate
        }}</span>
        <span [fxShow]="showExtraToFields">{{
          "COMPOSE.HIDE_CC_BCC" | translate
        }}</span>
      </div>

      <mat-form-field appearance="outline">
        <mat-label>{{ "GENERIC.TO" | translate | titlecase }}</mat-label>
        <input
          matInput
          name="to"
          formControlName="to"
          [(ngModel)]="composeData.vo.to"
          type="email"
          required
        />
      </mat-form-field>

      <mat-form-field appearance="outline" [fxShow]="showExtraToFields">
        <mat-label>Cc</mat-label>
        <input
          matInput
          name="cc"
          [(ngModel)]="composeData.vo.cc"
          formControlName="cc"
          type="email"
        />
      </mat-form-field>

      <mat-form-field appearance="outline" [fxShow]="showExtraToFields">
        <mat-label>Bcc</mat-label>
        <input
          matInput
          name="bcc"
          [(ngModel)]="composeData.vo.bcc"
          formControlName="bcc"
          type="email"
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ "GENERIC.SUBJECT" | translate }}</mat-label>
        <input
          matInput
          name="subject"
          [(ngModel)]="composeData.vo.subject"
          formControlName="subject"
        />
      </mat-form-field>

      <div class="note-section">
        <p-editor
          #noteEditor
          formControlName="message"
          [(ngModel)]="composeData.vo.message"
          [style]="{
            height: '120px',
            width: '100%',
            'background-color': editMode ? '#ffffff' : '',
          }"
        >
          <ng-template pTemplate="header" class="">
            <span class="ql-formats">
              <select class="ql-font"></select>
              <select class="ql-size"></select>
            </span>

            <span class="ql-formats">
              <button class="ql-bold"></button>
              <button class="ql-italic"></button>
              <button class="ql-underline"></button>
              <button class="ql-strike"></button>
            </span>

            <span class="ql-formats">
              <select class="ql-color"></select>
              <select class="ql-background"></select>
            </span>

            <span class="ql-formats">
              <button class="ql-list" value="ordered"></button>
              <button class="ql-list" value="bullet"></button>
              <button class="ql-indent" value="-1"></button>
              <button class="ql-indent" value="+1"></button>
            </span>
          </ng-template>
        </p-editor>
      </div>
      <div class="attachment-list mt-4">
        <div
          class="attachment"
          fxLayout="row"
          fxLayoutAlign="space-between center"
        >
          <div *ngFor="let file of composeData.vo.files" class="">
            <div fxLayout="row" class="attachment-file">
              <mat-icon class="s-16 mr-4">attach_file</mat-icon>
              <span class="filename">{{ file.filename }}</span>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div
    mat-dialog-actions
    class="m-0 pl-20 pt-8 pb-16"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <div>
      <!-- <button mat-raised-button
                    color="accent"
                    (click)="sendData()"
                    class="save-button"
                    [disabled]="composeForm.invalid || dateForm.invalid || loading || !composeData"
                    aria-label="SAVE">
                {{ "GENERIC.SEND" | translate }}
            </button> -->
      <button
        *ngIf="!byJobFunction"
        mat-raised-button
        color="accent"
        (click)="
          menuReportsService.buildAndRunVo(
            ReportsEnum.REPORT_ASSIGNMENT_SCHEDULE_PER_DATE,
            DistributionModeEnum.EMAIL_ATTACHMENT,
            ExportFormatEnum.PDF,
            ExportToEnum.NONE,
            LocaleEnum.FR_CA,
            this.paperFormat,
            employeeReportModel
          )
        "
      >
        {{ "GENERIC.SEND" | translate }}
      </button>

      <button
        *ngIf="byJobFunction"
        mat-raised-button
        color="accent"
        (click)="
          menuReportsService.buildAndRunVo(
            ReportsEnum.REPORT_ASSIGNMENT_SCHEDULE_PER_JOB_FUNCTION,
            DistributionModeEnum.EMAIL_ATTACHMENT,
            ExportFormatEnum.PDF,
            ExportToEnum.NONE,
            LocaleEnum.FR_CA,
            this.paperFormat,
            employeeReportModel
          )
        "
      >
        {{ "GENERIC.SEND" | translate }}
      </button>
      <!-- <button mat-raised-button color="accent" (click)="onSendEmail()">
                {{ "GENERIC.SEND" | translate }}
            </button> -->
    </div>
  </div>
</div>
