<div class="container" style="display: flex; flex: 1; flex-direction: column">
  <div>
    <div
      class="header accent pl-24 pr-24 h-80 mat-elevation-z1"
      fxLayout="row"
      fxLayoutAlign="start center"
      style="width: 100%"
    >
      <div fxLayout="column" fxLayoutAlign="center start">
        <div class="h1">{{ "SETTINGS.SETTINGS" | translate }}</div>
      </div>
    </div>
  </div>
  <mat-drawer-container class="container" style="display: flex; flex: 1">
    <mat-drawer
      class="container"
      style="margin-top: 24px; min-width: 275px"
      [autoFocus]="false"
      [mode]="drawerMode"
      [opened]="drawerOpened"
      #drawer
    >
      <!-- Accordéon -->
      <cdk-accordion>
        <ng-container *ngFor="let panel of panels; trackBy: trackByFn">
          <!-- Item principal de l'accordéon -->
          <cdk-accordion-item [expanded]="selectedPanel === panel.id">
            <div
              class="panel"
              [ngClass]="{ highlighted: selectedPanel === panel.id }"
              (click)="togglePanel(panel.id)"
            >
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  flex: 1;
                  align-items: center;
                "
              >
                <mat-icon
                  style="margin-right: 16px"
                  class="title s-20"
                  [ngClass]="{ contentColor: selectedPanel === panel.id }"
                >
                  {{ panel.icon }}
                </mat-icon>
                <div
                  class="title"
                  [ngClass]="{ contentColor: selectedPanel === panel.id }"
                >
                  {{ panel.title }}
                </div>
              </div>

              <mat-icon
                class="chevron-icon"
                [ngClass]="{ rotated: selectedPanel === panel.id }"
              >
                chevron_right
              </mat-icon>
            </div>

            <!-- Sous-éléments -->
            <div class="sub-items" *ngIf="selectedPanel === panel.id">
              <div
                *ngFor="let subPanel of panel.subPanel"
                [ngClass]="{ highlighted: selectedSubPanel === subPanel.id }"
                class="sub-item"
                (click)="onSelect(subPanel)"
              >
                {{ subPanel.name }}
              </div>
            </div>
          </cdk-accordion-item>
          <div class="item-separator"></div>
        </ng-container>
      </cdk-accordion>
    </mat-drawer>

    <mat-drawer-content style="display: flex; flex: 1">
      <div *ngIf="selected" class="drawer-content">
        <button class="toggle-button" mat-icon-button (click)="drawer.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
        <div [ngSwitch]="selected.id" class="width-100-content">
          <div
            *ngSwitchCase="'EVENT_LOGISTIC_STATUS'"
            class=""
            fxLayout="column"
          >
            <app-event-logistic-status> </app-event-logistic-status>
            <!-- 
                            <app-crud-datatable-generic [headerLabel]="'SETTINGS.EVENT_LOGISTIC_STATUS' | translate"
                                [useLanguages]="true" [useSortWithLocal]="true"
                                [serviceURL]="eventLogisticStatusServiceURL"
                                [tableHeaderLabel]="translate.instant('SETTINGS.EVENT_LOGISTIC_STATUS_NAME')"
                                [createLabel]="translate.instant('SETTINGS.EVENT_LOGISTIC_STATUS_CREATE_NEW')"
                                [createPlaceholder]="translate.instant('SETTINGS.EVENT_LOGISTIC_STATUS_CREATE_NEW_PLACEHOLDER')">
                            </app-crud-datatable-generic> -->
          </div>

          <div *ngSwitchCase="'ALLERGENS'" class="" fxLayout="column">
            <app-crud-datatable-generic
              [headerLabel]="'SETTINGS.ALLERGENS' | translate"
              [useLanguages]="true"
              [useSortWithLocal]="true"
              [serviceURL]="allergensServiceURL"
              [tableHeaderLabel]="translate.instant('SETTINGS.ALLERGENS_NAME')"
              [createLabel]="translate.instant('SETTINGS.ALLERGENS_CREATE_NEW')"
              [createPlaceholder]="
                translate.instant('SETTINGS.ALLERGENS_CREATE_NEW_PLACEHOLDER')
              "
            >
            </app-crud-datatable-generic>
          </div>

          <div
            *ngSwitchCase="'WRAPPING'"
            class="width-100-content"
            fxLayout="column"
          >
            <app-wrapping [headerLabel]="'SETTINGS.WRAPPING' | translate">
            </app-wrapping>
          </div>

          <div
            *ngSwitchCase="'WRAPPING_DEFAULT'"
            class="width-100-content"
            fxLayout="column"
          >
            <app-wrapping-default
              [settingsModel]="setting"
            ></app-wrapping-default>
          </div>

          <div
            *ngSwitchCase="'JOB_FUNCTIONS'"
            class="width-100-content"
            fxLayout="column"
          >
            <app-jobs-functions
              [headerLabel]="'SETTINGS.JOB_FUNCTIONS' | translate"
            >
            </app-jobs-functions>
          </div>

          <div
            *ngSwitchCase="'CLIENTS_CATEGORIES'"
            class="width-100-content"
            fxLayout="column"
          >
            <app-clients-categories-settings
              [headerLabel]="'SETTINGS.CLIENTS_CATEGORIES' | translate"
            >
            </app-clients-categories-settings>
          </div>

          <div
            *ngSwitchCase="'MATERIALS_CATEGORIES'"
            class="width-100-content"
            fxLayout="column"
          >
            <app-material-categories-settings
              [headerLabel]="'SETTINGS.MATERIALS_CATEGORIES' | translate"
            >
            </app-material-categories-settings>
          </div>

          <div
            *ngSwitchCase="'PRODUCTS_CATEGORIES'"
            class="width-100-content"
            fxLayout="column"
          >
            <app-products-categories
              [headerLabel]="'SETTINGS.PRODUCTS_CATEGORIES' | translate"
            >
            </app-products-categories>
          </div>

          <div
            *ngSwitchCase="'PRODUCTION_ZONES'"
            class="width-100-content"
            fxLayout="column"
          >
            <app-crud-datable-generic-no-local
              [useLanguages]="false"
              [headerLabel]="'SETTINGS.PRODUCTION_ZONES' | translate"
              [serviceURL]="productionZoneServiceURL"
              [tableHeaderLabel]="
                translate.instant('SETTINGS.PRODUCTION_ZONE_NAME')
              "
              [createLabel]="
                translate.instant('SETTINGS.PRODUCTION_ZONE_CREATE_NEW')
              "
              [createPlaceholder]="
                translate.instant(
                  'SETTINGS.PRODUCTION_ZONE_CREATE_NEW_PLACEHOLDER'
                )
              "
            >
            </app-crud-datable-generic-no-local>
          </div>

          <div
            *ngSwitchCase="'EVENTS_TYPES'"
            class="width-100-content"
            fxLayout="column"
          >
            <app-crud-datatable-generic
              [headerLabel]="'SETTINGS.EVENTS_TYPES' | translate"
              [useLanguages]="true"
              [useSortWithLocal]="true"
              [serviceURL]="eventsTypeServiceURL"
              [tableHeaderLabel]="
                translate.instant('SETTINGS.EVENTS_TYPES_NAME')
              "
              [createLabel]="
                translate.instant('SETTINGS.EVENTS_TYPES_CREATE_NEW')
              "
              [createPlaceholder]="
                translate.instant(
                  'SETTINGS.EVENTS_TYPES_CREATE_NEW_PLACEHOLDER'
                )
              "
            >
            </app-crud-datatable-generic>
          </div>

          <div
            *ngSwitchCase="'MATERIALS_PROVIDERS'"
            class="width-100-content"
            fxLayout="column"
          >
            <app-crud-datatable-generic
              [useLanguages]="false"
              [headerLabel]="'SETTINGS.MATERIALS_PROVIDERS' | translate"
              [serviceURL]="providersCategoryServiceURL"
              [tableHeaderLabel]="translate.instant('SETTINGS.PROVIDERS_NAME')"
              [createLabel]="
                translate.instant('SETTINGS.PROVIDERS_CATEGORY_CREATE_NEW')
              "
              [createPlaceholder]="
                translate.instant(
                  'SETTINGS.PROVIDERS_CATEGORY_CREATE_NEW_PLACEHOLDER'
                )
              "
            >
            </app-crud-datatable-generic>
          </div>

          <div
            *ngSwitchCase="'STAFF_PROVIDERS'"
            class="width-100-content"
            fxLayout="column"
          >
            <app-crud-datatable-generic
              [useLanguages]="false"
              [headerLabel]="'SETTINGS.STAF_PROVIDERS_NAME' | translate"
              [serviceURL]="staffProvidersCategoryServiceURL"
              [tableHeaderLabel]="translate.instant('SETTINGS.PROVIDERS_NAME')"
              [createLabel]="
                translate.instant(
                  'SETTINGS.STAFF_PROVIDERS_CATEGORY_CREATE_NEW'
                )
              "
              [createPlaceholder]="
                translate.instant(
                  'SETTINGS.PROVIDERS_CATEGORY_CREATE_NEW_PLACEHOLDER'
                )
              "
            >
            </app-crud-datatable-generic>
          </div>

          <div
            *ngSwitchCase="'SERVICE_CHARGE'"
            class="width-100-content"
            fxLayout="column"
          >
            <app-service-charge
              [headerLabel]="'SETTINGS.SERVICE_CHARGE' | translate"
            >
            </app-service-charge>
          </div>

          <div
            *ngSwitchCase="'DELIVERY_TEMPLATES'"
            class="width-100-content"
            fxLayout="column"
          >
            <!--                            <app-service-charge [headerLabel]="'SETTINGS.SERVICE_CHARGE' | translate">-->
            <!--                            </app-service-charge>-->
            <app-delivery-requests-templates
              [headerLabel]="'SETTINGS.DELIVERY_TEMPLATES' | translate"
            >
            </app-delivery-requests-templates>
          </div>

          <div
            *ngSwitchCase="'LEDGER_ACCOUNT'"
            class="width-100-content"
            fxLayout="column"
          >
            <app-ledger-account
              [headerLabel]="'SETTINGS.LEDGER_ACCOUNT' | translate"
            >
            </app-ledger-account>
          </div>

          <div
            *ngSwitchCase="'LEDGER_ACCOUNT_DEFAULT'"
            class="width-100-content"
            fxLayout="column"
          >
            <app-ledger-account-default
              [settingsModel]="setting"
            ></app-ledger-account-default>
          </div>

          <div
            *ngSwitchCase="'SOCIAL_CHARGE'"
            class="profile-box info-box width-100-content"
            fxLayout="column"
          >
            <header class="accent">
              <div class="title no-text-cursor">
                {{ "SETTINGS.SOCIAL_CHARGE" | translate }}
              </div>
            </header>

            <div
              class="content p-12"
              fxLayout="row"
              fxLayoutAlign="start center"
            >
              <div [formGroup]="form" class="" fxFlex="78%">
                <mat-form-field *ngIf="setting" fxFlex="100%" class="mr-16">
                  <mat-label>{{
                    "SETTINGS.SOCIAL_CHARGE_PERCENT" | translate
                  }}</mat-label>
                  <input
                    matInput
                    type="text"
                    formControlName="social_charge"
                    pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
                    [(ngModel)]="setting.social_charge"
                  />
                  <mat-error *ngIf="form.controls['social_charge'].invalid">
                    {{ "GENERIC.INPUT_NUMBER_0_999" | translate }}
                    {{ "GENERIC.INPUT_NUMBER_POSTFIX_2_DECIMAL" | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
              <button
                mat-raised-button
                color="accent"
                (click)="onSocialChargeUpdate(setting.social_charge)"
                style="font-size: 11px"
                [disabled]="!form.valid"
                class=""
              >
                {{ "SETTINGS.UPDATE" | translate | uppercase }}
              </button>
            </div>
          </div>

          <div
            *ngSwitchCase="'LANG'"
            class="profile-box info-box width-100-content"
            fxLayout="column"
          >
            <header class="accent">
              <div class="title no-text-cursor">
                {{ "SETTINGS.LANG" | translate }}
              </div>
            </header>

            <div class="content p-16" fxLayout="column">
              <div class="title">LANG Title</div>
              <div class="info">LANG Info</div>
            </div>
          </div>

          <div
            *ngSwitchCase="'PRINT'"
            class="profile-box info-box width-100-content"
            fxLayout="column"
          >
            <header class="accent">
              <div class="title no-text-cursor">
                {{ "SETTINGS.PRINT" | translate }}
              </div>
            </header>

            <div class="content p-16" fxLayout="column">
              <div class="title">PRINT Title</div>
              <div class="info">PRINT Info</div>
            </div>
          </div>

          <div
            *ngSwitchCase="'TAXES'"
            class="profile-box info-box width-100-content"
            fxLayout="column"
          >
            <header class="accent">
              <div class="title no-text-cursor p-16">
                {{ "SETTINGS.TAXES" | translate }}
              </div>
            </header>

            <div class="content p-16" fxLayout="column">
              <app-taxes></app-taxes>
            </div>
          </div>

          <div
            *ngSwitchCase="'TAXES_DEFAULT'"
            class="width-100-content"
            fxLayout="column"
          >
            <app-taxes-default [settingsModel]="setting"></app-taxes-default>
          </div>

          <div
            *ngSwitchCase="'DATE_TIME'"
            class="profile-box info-box width-100-content"
            fxLayout="column"
          >
            <header class="accent">
              <div class="title no-text-cursor p-16">
                {{ "SETTINGS.DATE_TIME_SETTINGS" | translate }}
              </div>
            </header>

            <div *ngIf="setting" class="content p-16" fxLayout="column">
              <app-gt2-select
                [service]="timezoneService"
                [placeHolder]="'SETTINGS.TIMEZONE_SELECT' | translate"
                [label]="'Unit'"
                [labelKey]="'name'"
                [selectedModel]="setting.timezone"
                [nullableUseNone]="false"
                (changeEvent)="onTimeZoneSelected($event)"
                [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
                fxFlex="100%"
                class="p-16"
              ></app-gt2-select>

              <app-gt2-select
                [service]="dateFormatService"
                [placeHolder]="'SETTINGS.DATE_FORMAT_SELECT' | translate"
                [labelKey]="'picker_format'"
                [label]="'Unit'"
                [nullableUseNone]="false"
                [selectedModel]="setting.date_format"
                (changeEvent)="onDateFormatSelected($event)"
                [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
                fxFlex="100%"
                class="p-16"
              ></app-gt2-select>

              <app-gt2-select
                [service]="dateTimeFormatService"
                [placeHolder]="'SETTINGS.DATE_TIME_FORMAT_SELECT' | translate"
                [labelKey]="'format'"
                [label]="'Unit'"
                [nullableUseNone]="false"
                [selectedModel]="setting.datetime_format"
                (changeEvent)="onDateTimeFormatSelected($event)"
                [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
                fxFlex="100%"
                class="p-16"
              ></app-gt2-select>

              <div
                fxLayout="row"
                fxLayoutAlign="center start"
                fxFlex="100%"
                class="gt2-buttons-theme content px-16 pb-16"
              >
                <button
                  mat-raised-button
                  color="accent"
                  class=""
                  (click)="onDateTimePreferencesData()"
                >
                  {{ "SETTINGS.UPDATE" | translate }}
                </button>
              </div>
            </div>
          </div>

          <div
            *ngSwitchCase="'ONLINE_PAYMENT'"
            class="profile-box info-box width-100-content"
            fxLayout="column"
          >
            <header class="accent">
              <div class="title no-text-cursor">
                {{ "SETTINGS.ONLINE_PAYMENT" | translate }}
              </div>
            </header>

            <div class="content p-16" fxLayout="column">
              <div class="title">ONLINE_PAYMENT Title</div>
              <div class="info">ONLINE_PAYMENT Info</div>
            </div>
          </div>

          <div
            *ngSwitchCase="'THEME'"
            class="profile-box info-box width-100-content"
            fxLayout="column"
          >
            <header class="accent">
              <div class="title no-text-cursor">
                {{ "SETTINGS.THEME" | translate }}
              </div>
            </header>

            <div class="content p-16" fxLayout="column">
              <div class="title">THEME Title</div>
              <div class="info">THEME Info</div>
            </div>
          </div>

          <div
            *ngSwitchCase="'DELIVERY_SECTOR'"
            class="width-100-content"
            fxLayout="column"
          >
            <app-crud-delivery-sectors></app-crud-delivery-sectors>
          </div>

          <div
            *ngSwitchCase="'DELIVERY'"
            class="profile-box info-box width-100-content"
            fxLayout="column"
          >
            <header class="accent">
              <div class="title no-text-cursor">
                {{ "SETTINGS.DELIVERY" | translate }}
              </div>
            </header>

            <div class="content p-16" fxLayout="column">
              <app-delivery-settings
                *ngIf="setting"
                [settingsModel]="setting"
              ></app-delivery-settings>
            </div>
          </div>

          <div
            *ngSwitchCase="'NOTIFICATIONS'"
            class="profile-box info-box width-100-content"
            fxLayout="column"
          >
            <header class="accent">
              <div class="title no-text-cursor">
                {{ "SETTINGS.NOTIFICATIONS" | translate }}
              </div>
            </header>

            <div class="content p-16" fxLayout="column">
              <div class="title">NOTIFICATIONS Title</div>
              <div class="info">NOTIFICATIONS Info</div>
            </div>
          </div>

          <div
            *ngSwitchCase="'DOWNLOADS'"
            class="profile-box info-box width-100-content"
            fxLayout="column"
          >
            <header class="accent">
              <div class="title no-text-cursor">
                {{ "SETTINGS.DOWNLOADS" | translate }}
              </div>
            </header>

            <div class="content p-16" fxLayout="column">
              <div class="title">DOWNLOADS Title</div>
              <div class="info">DOWNLOADS Info</div>
            </div>
          </div>

          <div
            *ngSwitchCase="'NUMBERING'"
            class="profile-box info-box width-100-content"
            fxLayout="column"
          >
            <header class="accent">
              <div class="title no-text-cursor p-16">
                {{ "SETTINGS.NUMBERING" | translate }}
              </div>
            </header>

            <div class="content p-16" fxLayout="column">
              <app-numbering> </app-numbering>
            </div>
          </div>

          <div
            *ngSwitchCase="'GENERAL_CONDITIONS'"
            class="profile-box info-box width-100-content"
            fxLayout="column"
          >
            <header class="accent">
              <div class="title no-text-cursor p-16">
                {{ "SETTINGS.GENERAL_CONDITIONS" | translate }}
              </div>
            </header>

            <div class="content p-16" fxLayout="column">
              <app-general-conditions></app-general-conditions>
            </div>
          </div>

          <div
            *ngSwitchCase="'SPECIFICS_CONDITIONS'"
            class="width-100-content"
            fxLayout="column"
          >
            <app-specific-conditions
              [useLanguages]="true"
              [headerLabel]="'SETTINGS.SPECIFICS_CONDITIONS' | translate"
              [serviceURL]="specificConditionsServiceURL"
              [tableHeaderLabel]="
                translate.instant('SETTINGS.SPECIFIC_CONDITION_NAME')
              "
              [createLabel]="
                translate.instant('SETTINGS.SPECIFIC_CONDITION_CREATE_NEW')
              "
              [createPlaceholder]="
                translate.instant(
                  'SETTINGS.SPECIFIC_CONDITION_CREATE_NEW_PLACEHOLDER'
                )
              "
            >
            </app-specific-conditions>
          </div>

          <!-- NOTIFICATION_MESSAGES -->
          <div
            *ngSwitchCase="'NOTIFICATION_MESSAGE_PROPOSAL'"
            class="profile-box info-box width-100-content"
            fxLayout="column"
          >
            <header class="accent">
              <div class="title no-text-cursor p-16">
                {{ "SETTINGS.NOTIFICATION_MESSAGE_PROPOSAL" | translate }}
              </div>
            </header>

            <div class="content p-16" fxLayout="column">
              <app-notification-message [key]="'proposal_notification'">
              </app-notification-message>
            </div>
          </div>

          <div
            *ngSwitchCase="'NOTIFICATION_MESSAGE_INVOICE'"
            class="profile-box info-box width-100-content"
            fxLayout="column"
          >
            <header class="accent">
              <div class="title no-text-cursor p-16">
                {{ "SETTINGS.NOTIFICATION_MESSAGE_INVOICE" | translate }}
              </div>
            </header>

            <div class="content p-16" fxLayout="column">
              <app-notification-message [key]="'invoice_notification'">
              </app-notification-message>
            </div>
          </div>

          <div
            *ngSwitchCase="'NOTIFICATION_MESSAGE_INVOICE_ON_LINE_GP'"
            class="profile-box info-box width-100-content"
            fxLayout="column"
          >
            <header class="accent">
              <div class="title no-text-cursor p-16">
                {{
                  "SETTINGS.NOTIFICATION_MESSAGE_INVOICE_ON_LINE_GP_PAGE"
                    | translate
                }}
              </div>
            </header>

            <div class="content p-16" fxLayout="column">
              <app-notification-message
                [key]="'invoice_notification_online_gp'"
              >
              </app-notification-message>
            </div>
          </div>

          <div
            *ngSwitchCase="'NOTIFICATION_MESSAGE_ALL_RECEIPT_NOTICES'"
            class="profile-box info-box width-100-content"
            fxLayout="column"
          >
            <header class="accent">
              <div class="title no-text-cursor p-16">
                {{
                  "SETTINGS.NOTIFICATION_MESSAGE_ALL_RECEIPT_NOTICES"
                    | translate
                }}
              </div>
            </header>

            <div class="content p-16" fxLayout="column">
              <app-notification-message
                [key]="'notification_all_receipt_notices'"
              >
              </app-notification-message>
            </div>
          </div>

          <div
            *ngSwitchCase="'NOTIFICATION_MESSAGE_ORDER'"
            class="profile-box info-box width-100-content"
            fxLayout="column"
          >
            <header class="accent">
              <div class="title no-text-cursor p-16">
                {{ "SETTINGS.NOTIFICATION_MESSAGE_ORDER" | translate }}
              </div>
            </header>

            <div class="content p-16" fxLayout="column">
              <app-notification-message [key]="'order_notification'">
              </app-notification-message>
            </div>
          </div>

          <div
            *ngSwitchCase="'NOTIFICATION_MESSAGE_RECEIPT'"
            class="profile-box info-box width-100-content"
            fxLayout="column"
          >
            <header class="accent">
              <div class="title no-text-cursor p-16">
                {{ "SETTINGS.NOTIFICATION_MESSAGE_RECEIPT" | translate }}
              </div>
            </header>

            <div class="content p-16" fxLayout="column">
              <app-notification-message [key]="'event_receipt_notification'">
              </app-notification-message>
            </div>
          </div>

          <div
            *ngSwitchCase="'NOTIFICATION_MESSAGE_CLIENT_STATEMENT'"
            class="profile-box info-box width-100-content"
            fxLayout="column"
          >
            <header class="accent">
              <div class="title no-text-cursor p-16">
                {{
                  "SETTINGS.NOTIFICATION_MESSAGE_CLIENT_STATEMENT" | translate
                }}
              </div>
            </header>

            <div class="content p-16" fxLayout="column">
              <app-notification-message [key]="'client_statement_notification'">
              </app-notification-message>
            </div>
          </div>

          <div
            *ngSwitchCase="'NOTIFICATION_MESSAGE_ENTERPRISE_STATEMENT'"
            class="profile-box info-box width-100-content"
            fxLayout="column"
          >
            <header class="accent">
              <div class="title no-text-cursor p-16">
                {{
                  "SETTINGS.NOTIFICATION_MESSAGE_ENTERPRISE_STATEMENT"
                    | translate
                }}
              </div>
            </header>

            <div class="content p-16" fxLayout="column">
              <app-notification-message
                [key]="'enterprise_statement_notification'"
              >
              </app-notification-message>
            </div>
          </div>

          <div
            *ngSwitchCase="'NOTIFICATION_MESSAGE_SCHEDULE'"
            class="profile-box info-box width-100-content"
            fxLayout="column"
          >
            <header class="accent">
              <div class="title no-text-cursor p-16">
                {{ "SETTINGS.NOTIFICATION_MESSAGE_SCHEDULE" | translate }}
              </div>
            </header>

            <div class="content p-16" fxLayout="column">
              <app-notification-message [key]="'schedule_notification'">
              </app-notification-message>
            </div>
          </div>

          <div
            *ngSwitchCase="'NOTIFICATION_MESSAGE_BANQUET_CONTRACT'"
            class="profile-box info-box width-100-content"
            fxLayout="column"
          >
            <header class="accent">
              <div class="title no-text-cursor p-16">
                {{
                  "SETTINGS.NOTIFICATION_MESSAGE_BANQUET_CONTRACT" | translate
                }}
              </div>
            </header>

            <div class="content p-16" fxLayout="column">
              <app-notification-message [key]="'congress_banquet_contract'">
              </app-notification-message>
            </div>
          </div>

          <!--<div *ngSwitchCase="'NOTIFICATION_MESSAGE_HISTORIC_ACCOUNT'" class="profile-box info-box width-100-content"
                             fxLayout="column">
                             <header class="accent">
                                <div class="title">{{'SETTINGS.NOTIFICATION_MESSAGE_HISTORIC_ACCOUNT' | translate}}</div>
                            </header>

                            <div class="content p-16" fxLayout="column">
                                <app-notification-message
                                    [key]="'historic-account'">
                                </app-notification-message>
                            </div>
                        </div>-->

          <div
            *ngSwitchCase="'INTEGRATION'"
            class="profile-box info-box width-100-content"
            fxLayout="column"
          >
            <header class="accent">
              <div class="title no-text-cursor p-16">
                {{ "SETTINGS.INTEGRATION" | translate }}
              </div>
            </header>

            <div class="content p-16" fxLayout="column">
              <div class="title">
                Courte description pour Sage, Lorem ipsum dolor sit amet,
                consectetur adipiscing elit.
              </div>
              <div fxLayout="row" fxLayoutAlign="center center" class="mt-8">
                <button mat-raised-button color="accent" (click)="onSage()">
                  Autoriser Sage
                </button>
              </div>
            </div>
          </div>

          <div
            *ngSwitchCase="'MAX_USERS'"
            class="profile-box info-box width-100-content"
            fxLayout="column"
          >
            <header class="accent">
              <div class="title no-text-cursor p-16">
                {{ "SETTINGS.MAX_USERS" | translate }}
              </div>
            </header>

            <div class="content p-16" fxLayout="column">
              <app-user-employee-setting></app-user-employee-setting>
            </div>
          </div>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
