import { Subscription } from 'rxjs';
import { ModulesService } from '../../../services/modules.service';
import { ICanDeactivateComponent } from '../../../gards/can-deactivate-guard.service';
import { CreateEventComponent } from '../../../components/dialogs/create-event/create-event.component';
import { ItemUrlFinder } from '../../../utils/item-url-finder';
import { IconsService } from '../../../services/icons.service';
import { AppRoutesService } from './../../../services/app-routes.service';
import { ValidatorHelper } from '../../../validators/ValidatorHelper';
import { Settings } from '../../../settings/settings';
import { GenericAlertComponent } from '../../../components/generic-alert/generic-alert.component';
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { ProjectsService } from './../../../api/projects.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { Gt2ApiService } from '../../../api/gt2-api.service';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import {
  CongressesService,
  ProjectModel,
} from './../../../api/congresses.service';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { RolesService } from '../../../services/roles.service';
import { CommonModule, Location } from '@angular/common';
import { locale as english } from '../i18n/en-CA';
import { locale as french } from '../i18n/fr-CA';
import { CreateEventProjectComponent } from './create-event-project/create-event-project.component';
import * as moment from 'moment';
import { CreateCongresDialogComponent } from '../create-congres-dialog/create-congres-dialog.component';
import { AppRoutes } from '../../../const/AppRoutes';
import { ConseillersService } from './../../../api/conseillers.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { Gt2SelectComponent } from '../../../components/gt2-select/gt2-select.component';
import { ClientsBoxComponent } from './clients-box/clients-box.component';
import { EventsBoxComponent } from '../projet/events-box/events-box.component';
import { CommentSectionComponent } from '../../../components/comment-section/comment-section.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../../@fuse/directives/directives';
import { CustomDateFormatter } from '../../calendar/CustomDateFormatter';
import { CalendarDateFormatter, DateAdapter } from 'angular-calendar';
import { GT2DateAdapter } from '../../../utils/GT2DateAdapter';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    EventsBoxComponent,
    CommentSectionComponent,
    ClientsBoxComponent,
    FlexLayoutModule,
    Gt2SelectComponent,
    MatDialogModule,
    FuseDirectivesModule,
    MatMenuModule,
    MatIconModule,
    MatDatepickerModule,
    NgxPermissionsModule,
    MatTooltipModule,
    MatButtonModule,
    MatInputModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
  ],
  animations: fuseAnimations,
  providers: [
    ConseillersService,
    { provide: DateAdapter, useClass: GT2DateAdapter },
    { provide: CalendarDateFormatter, useClass: CustomDateFormatter },
  ],
})
export class ProjectComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy, ICanDeactivateComponent
{
  //
  project: ProjectModel | any;
  orderingClient: any;
  projectUUID: string | any;
  congresUUID!: string;
  loading: boolean = false;
  editable: boolean = true;
  editMode: boolean = false;
  form: UntypedFormGroup | any;
  confirmDialogRef!: MatDialogRef<GenericAlertComponent>;
  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;
  eventCount: number = 0;
  timeZone: string;
  dateStartFormatted!: string;
  dateEndFormatted!: string;

  //
  constructor(
    private congressesService: CongressesService,
    private projectsService: ProjectsService,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    private toastr: ToastrService,
    private logger: NGXLogger,
    private api: Gt2ApiService,
    public dialog: MatDialog,
    private titleService: Title,
    public route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    public router: Router,
    public appRoutesService: AppRoutesService,
    public rolesService: RolesService,
    public location: Location,
    public iconsService: IconsService,
    public modulesService: ModulesService,
    public conseillersService: ConseillersService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    this.timeZone = 'America/Montreal';
  }

  ngOnInit() {
    //this.logger.info("ProjectComponent.ngOnInit()");
    //this.titleService.setTitle(this.translate.instant("GENERIC.PROJECT"));
    //
    const iSub: Subscription = this.modulesService.modulesSet.subscribe({
      next: (data) => {
        if (data) {
          this.route.params.subscribe({
            next: (params: any) => {
              this.projectUUID = params.id;
              //this.logger.info("ProjectComponent.ngOnInit() -> this.projectUUID: " + this.projectUUID);
              this.loadProject();
              iSub.unsubscribe();
            },
          });
        }
      },
    });
    //

    //
    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
        ],
      ],
      project_deposit: [
        '',
        [
          Validators.minLength(1),
          ValidatorHelper.isNumber0To1000000,
          ValidatorHelper.isValidPriceNumber,
        ],
      ],
      date_start: ['', [ValidatorHelper.dateRangeDefault, Validators.required]],
      date_end: ['', [ValidatorHelper.dateRangeDefault, Validators.required]],
    });
  }

  ngAfterViewInit() {
    //this.logger.info("ProjectComponent.ngAfterViewInit()");
  }

  ngOnDestroy() {
    //this.logger.info("ProjectComponent.ngOnDestroy()");
  }

  public canDeactivate(): boolean {
    //this.logger.info("ProjectComponent.loadClient() -> canDeactivate: " + this.form.dirty);

    return !this.form?.dirty;
  }

  public loadProject(): void {
    this.loading = true;
    this.projectsService.getProject(this.projectUUID).subscribe({
      next: (response) => {
        // //this.logger.info("ProjectComponent.loadCongress() -> SUCCESS: ");
        //this.logger.info("ProjectComponent.loadProject() -> SUCCESS: " + JSON.stringify(response));
        this.project = response.data;
        this.congresUUID = this.project?.congress.uuid;
        this.orderingClient = response.data.ordering_client;
        this.dateStartFormatted = this.project?.date_start;
        this.dateEndFormatted = this.project?.date_end;

        // this.eventCount = response.meta.pagination.total;
        //this.logger.info("ProjectComponent.Ordering Client  DATA -> SUCCESS: " + JSON.stringify(this.orderingClient));
        this.loading = false;
      },
      error: (error) => {
        this.logger.error(
          'ProjectComponent.loadProject() -> ERROR: ' + JSON.stringify(error),
        );
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public onNavigateToCongres(): void {
    this.router.navigateByUrl(
      ItemUrlFinder.getItemURL({
        object: 'Congress',
        uuid: this.project?.congress.uuid,
      }),
    );
  }

  public cancelProject(): void {
    //this.logger.info("ProjectComponent.cancelProject()");
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });
    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.CANCEL');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.CANCEL_PROJECT_CONFIRM');

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result) => {
        //this.logger.info("ProjectComponent.cancelProject() close.result: " + result);
        if (result) {
          this.loading = true;
          this.projectsService.cancelProject(this.projectUUID).subscribe({
            next: (response) => {
              this.loading = false;
              this.project = response.data;
              //this.logger.info("ProjectComponent.cancelProject() -> SUCCESS: " + JSON.stringify(response));
            },
            error: (error) => {
              this.loading = false;
              this.logger.error(
                'ProjectComponent.cancelProject() -> ERROR: ' +
                  JSON.stringify(error),
              );
              this.handleAPIError(error, this.dialog, null, null);
            },
          });
        }
      },
    });
  }

  public onEdit(event: any): void {
    this.editMode = !this.editMode;
    //this.logger.info("ProjectComponent.onEdit() -> editMode now at " + this.editMode);
    //
    if (this.editMode) {
      // this.loading = false;
    } else {
      this.loading = true;
      //this.project.date_start = this.dateStart0;
      this.projectsService.updateProject(this.project).subscribe({
        next: (response) => {
          //this.logger.info("ProjectComponent.updateProject() -> SUCCESS: " + JSON.stringify(response));

          this.form?.markAsPristine();
          this.form?.markAsUntouched();
          this.loading = false;
          this.loadProject();
        },
        error: (error) => {
          this.logger.error(
            'ProjectComponent.updateProject() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          this.handleAPIError(error, this.dialog, null, null);
        },
      });
    }
  }

  public onCancelEdit(): void {
    this.editMode = false;
    this.form?.markAsPristine();
    this.form?.markAsUntouched();
    this.loadProject();
  }

  public closeMyMenu() {
    this.trigger?.closeMenu();
  }

  public onCreateEventWithhOrder(): void {
    //this.logger.info("ProjectComponent.onCreateEventWithhOrder()");
    this.dialogRef = this.dialog.open(CreateEventProjectComponent, {
      width: '35%',
      minWidth: 350,
      disableClose: false,
      panelClass: 'scrollable-dialog',
      data: {
        subType: CreateEventComponent.ORDER_SUBTYPE,
        projectUUID: this.projectUUID,
        event_date: this.project?.date_start,
      },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        //this.logger.info("ProjectComponent.onCreateEventWithhOrder() -> result: " + JSON.stringify(result));
        if (result) {
          this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
        }
        this.dialogRef = null;
      },
    });
  }

  public onCreateEventWithProposal(): void {
    //this.logger.info("ProjectComponent.onCreateEventWithProposal()");
    this.dialogRef = this.dialog.open(CreateEventProjectComponent, {
      width: '35%',
      minWidth: 350,
      disableClose: false,
      panelClass: 'scrollable-dialog',
      data: {
        subType: CreateEventComponent.PROPOSAL_SUBTYPE,
        projectUUID: this.projectUUID,
        event_date: this.project?.date_start,
      },
    });
    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        //this.logger.info("ProjectComponent.onCreateEventWithProposal() -> result: " + JSON.stringify(result));
        if (result) {
          this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
        }
        this.dialogRef = null;
      },
    });
  }

  public onComponentModelUpdated(): void {
    //this.logger.info("ProjectComponent.onComponentModelUpdated()");
    // this.modelUpdatedSubject.next();
    this.loadProject();
  }

  public onMeta($event: any): void {
    //this.logger.info("ProjectComponent.onMeta() : " + JSON.stringify($event));
    this.eventCount = $event.pagination.total;
  }
  public onOpenInNewTab(): void {
    window.open(this.router.url, '_blank');
  }
  public onDateChange(event: any): void {
    var dt = moment.tz(event, this.timeZone).format();
    /*//this.logger.info(
            "ProjectComponent.onDateChange() Use of Moment -> event value: " +
                dt
        );*/
    this.project.date_start = dt;
    this.dateStartFormatted = moment
      .tz(event, this.timeZone)
      .format('YYYY-MM-DD');
  }
  public onDateEndChange(event: any): void {
    var dt = moment.tz(event, this.timeZone).format();
    this.project.date_end = dt;
    this.dateEndFormatted = moment
      .tz(event, this.timeZone)
      .format('YYYY-MM-DD');
  }

  public onCreateNewCongress(event: any): void {
    //this.logger.info("CongressesComponent.onCreateNewCongress() -> event: " + event);
    this.dialogRef = this.dialog.open(CreateCongresDialogComponent, {
      width: '440px',
      minWidth: 350,
      disableClose: false,
      panelClass: 'scrollable-dialog',
      data: { autoRedirect: true },
    });
    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          // this.reloadTable();
        }
        this.dialogRef = null;
      },
    });
  }

  public onGoToCongresses(): void {
    this.router.navigateByUrl('/' + AppRoutes.CONGRESSES);
  }
  public onResponsibleEmployeeSelected(employee: any): void {
    //this.logger.info("ProjectComponent.onResponsibleEmployeeSelected() -> employee: " + JSON.stringify(employee));
    this.project.responsible_employee = employee;
    //this.logger.info("ProjectComponent.onResponsibleEmployeeSelected() -> this.order.responsible_employee: " + JSON.stringify(this.project.responsible_employee));
    this.form?.markAsDirty();
  }
}
