import { Component, OnInit } from '@angular/core';
import { ApiRoutes } from '../../../api/ApiRoutes';
import { AuthenticationService } from '../../../api/auth-service.service';
import { HttpClient } from '@angular/common/http';
import { Gt2ApiService } from '../../../api/gt2-api.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { Router } from '@angular/router';
import { GT2PageAbstract } from '../../abstract/GT2PageAbstract';

@Component({
  selector: 'app-god-emails',
  templateUrl: './god-emails.component.html',
  styleUrls: ['./god-emails.component.scss'],
})
export class GodEmailsComponent extends GT2PageAbstract implements OnInit {
  emailFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.email,
  ]);

  constructor(
    private authService: AuthenticationService,
    private http: HttpClient,
    private logger: NGXLogger,
    private router: Router,
    private api: Gt2ApiService,
  ) {
    super();
  }

  ngOnInit() {
    // this.logger.info("GodEmailsComponent.ngOnInit() -> this.router.url: " + this.router.url);
    this.validatePageAccess(this.authService, this.router);
  }

  onSendInvite(userID: string): void {
    const userData: any = { email: null, role: 'ADMIN', userLocale: 'fr-CA' };
    // this.logger.info("onSendInvite() -> userData: " + JSON.stringify(userData));

    if (userID === 'input') {
      userData.email = this.emailFormControl.value;
    } else if (userID === 'martin') {
      userData.email = 'martin+invite-001@agence.ninja';
    } else {
      userData.email = 'andre+invite-001@agence.ninja';
    }
    // this.logger.info("onSendInvite() -> userData final: " + JSON.stringify(userData));

    this.http
      .post<any>(this.api.createUrl(ApiRoutes.INVITE_URL), userData)
      .subscribe({
        next: (response) => {
          // this.logger.info("onSendInvite() -> SUCCESS: " + JSON.stringify(response));
        },
        error: (error) => {
          this.logger.error(
            'onSendInvite() -> ERROR: ' + JSON.stringify(error),
          );
        },
      });
  }
}
