<div
  id="event"
  class="page-layout simple left-sidebar inner-sidebar inner-scroll"
>
  <!-- Original -->
  <!-- <div id="event" class="page-layout simple"> -->
  <!-- Like dashboard top div-->

  <!-- HEADER -->
  <div class="gt2-theme">
    <div
      class="header primary py-24 pl-24 pr-8 h-80 mat-elevation-z1"
      fusePerfectScrollbar
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div fxLayout="row" fxLayoutAlign="start center">
        <button
          class="mr-0 mr-sm-16 white-fg"
          mat-icon-button
          (click)="onPageBack(location, router, '/dashboard')"
        >
          <mat-icon class="icon-mat">arrow_back</mat-icon>
        </button>

        <div fxLayout="column" fxLayoutAlign="center start">
          <div class="user-info" fxLayout="row" fxLayoutAlign="start start">
            <div
              class="event-title text-truncate"
              *fuseIfOnDom
              [@animate]="{
                value: '*',
                params: { delay: '100ms', x: '-25px' },
              }"
            >
              <div *ngIf="event && event.informations" class="text-truncate">
                <span
                  *ngIf="
                    !event.shop ||
                    !event.shop.shop_type ||
                    !event.shop.shop_type.key ||
                    event.shop.shop_type.key !== 'SHOP_WEB'
                  "
                  class="text-truncate pr-8"
                >
                  {{ "EVENTS.EVENT" | translate }} -
                  {{ event.informations.name }} - {{ event.event_number }}
                </span>
                <span
                  *ngIf="
                    event.shop &&
                    event.shop.shop_type &&
                    event.shop.shop_type.key === 'SHOP_WEB'
                  "
                  class="text-truncate pr-8"
                >
                  {{ "EVENTS.EVENT" | translate | titlecase }} -
                  {{ event.informations.name }} - {{ event.event_number }}
                </span>
              </div>
            </div>

            <!-- <div *ngIf="event" class="user-info" fxLayout="row" fxLayoutAlign="start start">
                        
                    </div> -->
          </div>

          <!-- <div *ngIf="event && event.informations">{{event.informations.event_date}}</div> -->
          <div
            *ngIf="event"
            fxLayout="row"
            fxLayoutAlign="start start"
            class=""
          >
            <div *ngIf="event.clients && event.clients.ordering_client">
              {{ event.clients.ordering_client.name_label }} - &nbsp;
            </div>
            <div *ngIf="event.status_label">
              {{ "GENERIC.STATUS" | translate }}: {{ event.status_label }}
            </div>
            <div *ngIf="event && event.stats" class="">
              &nbsp; - {{ "GENERIC.GUEST_S" | translate }}(s):
              {{ event.stats.guest_count }}
            </div>
            <div
              *ngIf="
                event && event.stats && event.stats.responsible_employee_name
              "
              matTooltip="{{ 'GENERIC.RESPONSIBLE_EMPLOYEE' | translate }}"
              class=""
            >
              &nbsp;- {{ event.stats.responsible_employee_name }}
            </div>
            <div *ngIf="event && event.congress_number && event.is_ctm">
              &nbsp; - {{ "GENERIC.SPCM_NUMBER" | translate }} :
              {{ event.congress_number }}
            </div>
            <div *ngIf="event && event.congress_number && !event.is_ctm">
              &nbsp; - # {{ "GENERIC.CONGRESS" | translate }}:
              {{ event.congress_number }}
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="end center">
        <div
          *ngIf="deliveryIconReady && event && event.delivery_type"
          matTooltip="{{
            'GENERIC.DELIVERY_TYPE' + event.delivery_type.delivery_type_id
              | translate
          }}"
        >
          <!-- {{event.delivery_type.delivery_type_id}} -->
          <mat-icon
            class="icon-mat mx-12 fa-lg"
            *ngIf="event.delivery_type.delivery_type_id === 1"
            fontSet="fa"
            fontIcon="fa-shipping-fast"
          >
          </mat-icon>
          <mat-icon
            class="icon-mat mx-12 fa-lg"
            *ngIf="event.delivery_type.delivery_type_id === 2"
            fontSet="fa"
            fontIcon="fa-utensils"
          >
          </mat-icon>
          <mat-icon
            class="icon-mat mx-12 fa-lg"
            *ngIf="event.delivery_type.delivery_type_id === 3"
            fontSet="fa"
            fontIcon="fa-users"
          >
          </mat-icon>
          <mat-icon
            class="icon-mat mx-12 fa-lg"
            *ngIf="event.delivery_type.delivery_type_id === 4"
            fontSet="fa"
            fontIcon="fa-concierge-bell"
          >
          </mat-icon>
        </div>

        <div
          *ngIf="
            event &&
            event.informations &&
            event.informations.organization_identity
          "
        >
          <img
            *ngIf="event.informations.organization_identity.logo"
            class="pr-12 pt-4"
            src="{{ event.informations.organization_identity.logo }}"
          />
        </div>
        <div
          *ngIf="event"
          class="user-info mr-12"
          fxLayout="column"
          fxLayoutAlign="center end"
        >
          <div *ngIf="event.informations" class="event-date">
            {{ event.informations.event_date_label }}
            {{ event.informations.event_time_label }}
          </div>
          <div *ngIf="event.shop" class="mt-4">
            {{ event.shop.shop_type_label }}
          </div>
        </div>

        <button
          mat-button
          matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
          (click)="onOpenInNewTab()"
          class="mat-icon-button"
        >
          <mat-icon class="icon-mat s-30">{{ getIcon("NEW_TAB") }}</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <!-- / HEADER -->

  <!-- SUB HEADER -->
  <div
    *ngIf="event"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fusePerfectScrollbar
    class="primary mat-elevation-z1 content-subheader"
  >
    <!-- <div fxLayout="row" fxLayoutAlign="start center" fusePerfectScrollbar>
            <button mat-button *ngIf="event.documents && event.documents.order" (click)="onVisitOrder()" class="">
              <mat-icon class="s-24">{{ getIcon('ORDER') }}</mat-icon>
              {{ 'EVENTS.VISIT_ORDER' | translate}}
            </button>
        </div> -->

    <!-- ASSIGNMENTS -->

    <div *ngxPermissionsOnly="rolesService.TEAM_LEAD_ROLES" fxLayout="row">
      <button
        mat-button
        *ngIf="
          event &&
          moduleService.modulesAreSet &&
          moduleService.moduleExist('MODULE_STAFF_ASSIGNMENT')
        "
        (click)="onAffectations()"
        class=""
      >
        <!-- <mat-icon class="s-20">{{ getIcon('EMPLOYEE') }}</mat-icon> -->
        <mat-icon
          fontSet="fa"
          fontIcon="{{ getIcon('ASSIGNMENT_USER') }}"
          class="icon-mat fa-lg fa-icon-btn"
        ></mat-icon>
        <p class="icon-mat">{{ "EVENTS.ASSIGNEMENTS" | translate }}</p>
      </button>

      <!-- <button mat-button *ngIf="event" (click)="onNavigateToOrder()" class="">
                <mat-icon fontSet="fa" fontIcon="{{ getIcon('ASSIGNMENT_USER') }}" class="fa-lg fa-icon-btn"></mat-icon>
                {{ ('GENERIC.' + ((event.current_order_type === 'PROPOSAL')? 'PROPOSAL' : (event.current_order_type === 'ORDER')?
                'ORDER' : 'INVOICE')) | translate}}
            </button> -->
      <button
        mat-button
        *ngIf="event.documents && event.documents.invoice"
        (click)="onVisitInvoice()"
        class=""
      >
        <mat-icon class="icon-mat s-24">{{ getIcon("INVOICE") }}</mat-icon>
        <p class="icon-mat">{{ "EVENTS.VISIT_INVOICE" | translate }}</p>
      </button>

      <div *ngIf="event && event.congress_uuid && event.congress_slug" class="">
        <button mat-button (click)="onNavigateToCongress()" class="">
          <!-- <mat-icon fontSet="fa" fontIcon="{{ getIcon('CONGRESS') }}" class="fa-lg fa-icon-btn"></mat-icon> -->
          <mat-icon class="icon-mat s-24">{{ getIcon("CONGRESS") }}</mat-icon>
          <p class="icon-mat">{{ "GENERIC.CONGRESS" | translate }}</p>
        </button>
      </div>

      <div *ngIf="event && event.project_uuid && event.project_slug" class="">
        <button mat-button (click)="onNavigateToProject()" class="">
          <!-- <mat-icon fontSet="fa" fontIcon="{{ getIcon('CONGRESS') }}" class="fa-lg fa-icon-btn"></mat-icon> -->
          <mat-icon class="icon-mat s-24">{{ getIcon("PROJECT") }}</mat-icon>
          <p class="icon-mat">{{ "GENERIC.PROJECT" | translate }}</p>
        </button>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center">
      <!-- <button mat-button *ngIf="event.documents && event.documents.order" (click)="onVisitOrder()" class="">
                <mat-icon class="s-24">{{ getIcon('CLONE') }}</mat-icon>
                {{ 'EVENTS.CLONE' | translate}}
              </button> -->

      <div *ngxPermissionsOnly="rolesService.SALE_ROLES" fxLayout="row">
        <button
          mat-button
          *ngIf="
            event &&
            event.status !== 'EVENT_CANCEL' &&
            event.status !== 'EVENT_ABORT' &&
            event.current_order_type !== 'INVOICE'
          "
          (click)="onCancelEvent()"
          class=""
        >
          <mat-icon class="icon-mat s-20">{{ getIcon("CANCEL") }}</mat-icon>
          <p class="icon-mat">{{ "EVENTS.CANCEL_EVENT" | translate }}</p>
        </button>

        <button
          mat-button
          *ngIf="
            event &&
            event.status !== 'EVENT_ABORT' &&
            event.status !== 'EVENT_CANCEL' &&
            event.current_order_type !== 'INVOICE'
          "
          (click)="onAbortEvent()"
          class=""
        >
          <mat-icon class="icon-mat s-20">{{ getIcon("CANCEL") }}</mat-icon>
          <p class="icon-mat">{{ "EVENTS.ABORT_EVENT" | translate }}</p>
        </button>
      </div>

      <!-- <button mat-button *ngIf="fileService.pdfEnabled()" matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}" (click)="onDownloadPDF()" class="mat-icon-button">
                <mat-icon class="s-30">picture_as_pdf</mat-icon>
              </button>

              <mat-spinner *ngIf="print.loadingPrint" [diameter]="20" class="mt-12"></mat-spinner>

              <button mat-button *ngIf="fileService.printEnabled()" matTooltip="{{ 'GENERIC.PRINT' | translate }}" (click)="onPrint()" class="mat-icon-button">
                <mat-icon class="s-30">print</mat-icon>
              </button> -->

      <!-- <div> | &nbsp;</div> -->

      <!-- <button mat-button [matMenuTriggerFor]="menu">Rapports</button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item>Rapport 1</button>
              <button mat-menu-item>Rapports 2</button>
              <button mat-menu-item>Rapports 3</button>
            </mat-menu> -->

      <!-- ASSIGNEMENTS PRINT & DOWNLOAD -->
      <!-- TEAM_LEAD_ROLES -->
      <!-- <div *ngxPermissionsOnly="rolesService.SALE_ROLES">
                <button mat-button *ngIf="fileService.printEnabled() && moduleService.modulesAreSet && moduleService.moduleExist('MODULE_STAFF_ASSIGNMENT')" 
                    class="primary" 
                    [matMenuTriggerFor]="menuPrintWithFormat">
                    <mat-icon color="" class="s-28 subheader-menu-icon">{{ getIcon("PRINT") }}</mat-icon>
                    <span class="subheader-menu-text ml-4">{{ 'GENERIC.PRINT' | translate }}</span>
                    <mat-icon class="">more_vert</mat-icon>
                </button>
                <mat-menu #menuPrintWithFormat="matMenu">
                    <button mat-menu-item [matMenuTriggerFor]="printEmployeeAssignmentSale">{{ "EVENTS.ASSIGNMENT_SALE" | translate }}</button>
                    <button mat-menu-item [matMenuTriggerFor]="printEmployeeAssignmentCost">{{ "EVENTS.ASSIGNMENT_COST" | translate }}</button>
                    <button mat-menu-item [matMenuTriggerFor]="printEmployeeAssignmentAttendance">{{ "EVENTS.ASSIGNMENT_ATTENDANCE" | translate }}</button>
                </mat-menu>
                <mat-menu #printEmployeeAssignmentSale="matMenu">
                    <button mat-menu-item 
                            (click)="onPrintEmployeesAssigments('assignment/report-assignment-sales', 'letter')">
                                {{ "GENERIC.LETTER_FORMAT" | translate }}
                        </button>
                    <button mat-menu-item 
                            (click)="onPrintEmployeesAssigments('assignment/report-assignment-sales', 'legal')">
                                {{ "GENERIC.LEGAL_FORMAT" | translate }}
                    </button>
                </mat-menu>
                <mat-menu #printEmployeeAssignmentCost="matMenu">
                    <button mat-menu-item 
                            (click)="onPrintEmployeesAssigments('assignment/report-assignment-cost', 'letter')">
                                {{ "GENERIC.LETTER_FORMAT" | translate }}
                        </button>
                    <button mat-menu-item 
                            (click)="onPrintEmployeesAssigments('assignment/report-assignment-cost', 'legal')">
                                {{ "GENERIC.LEGAL_FORMAT" | translate }}
                    </button>
                </mat-menu>
                <mat-menu #printEmployeeAssignmentAttendance="matMenu">
                    <button mat-menu-item 
                            (click)="onPrintEmployeesAssigments('assignment/report-assignment-attendance-record', 'letter')">
                                {{ "GENERIC.LETTER_FORMAT" | translate }}
                        </button>
                    <button mat-menu-item 
                            (click)="onPrintEmployeesAssigments('assignment/report-assignment-attendance-record', 'legal')">
                                {{ "GENERIC.LEGAL_FORMAT" | translate }}
                    </button>
                </mat-menu>


                
                <button mat-button 
                    *ngIf="fileService.pdfEnabled() && moduleService.modulesAreSet && moduleService.moduleExist('MODULE_STAFF_ASSIGNMENT')" 
                    class="primary" [matMenuTriggerFor]="menuDownloadWithFormat">
                    <mat-icon class="s-28 subheader-menu-icon">{{ getIcon("PDF") }}</mat-icon>
                    <span class="subheader-menu-text ml-4">{{ 'GENERIC.DOWNLOAD' | translate }}</span>
                    <mat-icon class="">more_vert</mat-icon>
                </button>
                <mat-menu #menuDownloadWithFormat="matMenu">
                    <button mat-menu-item [matMenuTriggerFor]="downloadEmployeeAssignmentSale">{{ "EVENTS.ASSIGNMENT_SALE" | translate }}</button>
                    <button mat-menu-item [matMenuTriggerFor]="downloadEmployeeAssignmentCost">{{ "EVENTS.ASSIGNMENT_COST" | translate }}</button>
                    <button mat-menu-item [matMenuTriggerFor]="downloadEmployeeAssignmentAttendance">{{ "EVENTS.ASSIGNMENT_ATTENDANCE" | translate }}</button>
                </mat-menu>
                <mat-menu #downloadEmployeeAssignmentSale="matMenu">
                    <button mat-menu-item 
                            (click)="onDownloadEmployeesAssigments('assignment/report-assignment-sales', 'letter')">
                                {{ "GENERIC.LETTER_FORMAT" | translate }}
                        </button>
                    <button mat-menu-item 
                            (click)="onDownloadEmployeesAssigments('assignment/report-assignment-sales', 'legal')">
                                {{ "GENERIC.LEGAL_FORMAT" | translate }}
                    </button>
                </mat-menu>
                <mat-menu #downloadEmployeeAssignmentCost="matMenu">
                    <button mat-menu-item 
                            (click)="onDownloadEmployeesAssigments('assignment/report-assignment-cost', 'letter')">
                                {{ "GENERIC.LETTER_FORMAT" | translate }}
                        </button>
                    <button mat-menu-item 
                            (click)="onDownloadEmployeesAssigments('assignment/report-assignment-cost', 'legal')">
                                {{ "GENERIC.LEGAL_FORMAT" | translate }}
                    </button>
                </mat-menu>
                <mat-menu #downloadEmployeeAssignmentAttendance="matMenu">
                    <button mat-menu-item 
                            (click)="onDownloadEmployeesAssigments('assignment/report-assignment-attendance-record', 'letter')">
                                {{ "GENERIC.LETTER_FORMAT" | translate }}
                        </button>
                    <button mat-menu-item 
                            (click)="onDownloadEmployeesAssigments('assignment/report-assignment-attendance-record', 'legal')">
                                {{ "GENERIC.LEGAL_FORMAT" | translate }}
                    </button>
                </mat-menu>
            </div> -->
    </div>
  </div>
  <!-- / SUB HEADER -->

  <!-- Read only top banner -->
  <div
    *ngIf="event && !editable"
    fxLayout="row"
    fxLayoutAlign="start center"
    style="height: 38px; min-height: 38px; max-height: 38px"
    class="gt2-gray mat-elevation-z1 py-8"
  >
    <div fxFlex="176px" fxLayout="row" fxLayoutAlign="end center" class="mr-4">
      &nbsp;
    </div>
    <div fxFlex fxLayout="row" fxLayoutAlign="center center">
      <div class="text-white font-weight-600">
        <b>{{ "GENERIC.READ_ONLY_MODE" | translate }}</b> :
        {{ event.status_label }}
      </div>
    </div>
    <div fxFlex="176px" fxLayout="row" fxLayoutAlign="end center" class="mr-8">
      <button mat-button (click)="onReopenEvent()" class="reopen-event-button">
        <mat-icon class="icon-mat s-20">{{ getIcon("LOCK_OPEN") }}</mat-icon>
        <p class="icon-mat">{{ "EVENTS.REOPEN_EVENT" | translate }}</p>
      </button>
    </div>
  </div>
  <!-- / Read only top banner -->

  <!-- RE-CLOSE EVENT TOP BANNER (Read only top banner) -->
  <div
    *ngIf="
      event &&
      event.status === 'EVENT_IN_INVOICE' &&
      event.documents &&
      event.documents.invoice &&
      event.documents.invoice.status === 'INVOICE_PAID'
    "
    fxLayout="row"
    fxLayoutAlign="center center"
    style="height: 38px; min-height: 38px; max-height: 38px"
    class="gt2-blue-grey mat-elevation-z1 py-8"
  >
    <!--        <div class="text-white font-weight-600"><b>{{ 'GENERIC.READ_ONLY_MODE' | translate }}</b> : {{ event.status_label }}</div>-->
    <button mat-button (click)="onReClosepenEvent()" class="">
      <mat-icon class="icon-mat s-20">{{ getIcon("LOCK") }}</mat-icon>
      <p class="icon-mat">{{ "EVENTS.RECLOSE_EVENT" | translate }}</p>
    </button>
  </div>
  <!-- / RE-CLOSE EVENT TOP BANNER (Read only top banner) -->

  <!--HISTORY SIDEBAR-->
  <div *ngIf="!isSmallScreen" class="container-history-panel">
    <fuse-sidebar
      *ngIf="eventUUDID"
      name="eventHistoryPanel"
      position="right"
      class="section-history-panel"
    >
      <app-section-history-panel
        *ngIf="event"
        panelName="eventHistoryPanel"
        section="events"
        sectionUUID="{{ eventUUDID }}"
      >
      </app-section-history-panel>
    </fuse-sidebar>
  </div>
  <!-- / HISTORY SIDEBAR-->

  <!--HISTORY SIDEBAR BUTTON-->
  <div *ngIf="!isSmallScreen">
    <!-- <div *ngIf="editable"> -->
    <button
      mat-icon-button
      class="warn mat-elevation-z2 historic-button"
      *fuseIfOnDom
      [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }"
      (click)="toggleHistoricSidebarOpen('eventHistoryPanel')"
    >
      <mat-icon class="icon-mat">history</mat-icon>
    </button>
    <!-- </div> -->
  </div>
  <!-- /HISTORY SIDEBAR BUTTON-->

  <div
    class="content center"
    *ngIf="
      moduleService.modulesAreSet &&
      !moduleService.moduleExist('MODULE_EVENT_TABBED')
    "
  >
    <!-- TABs -->
    <mat-tab-group
      [(selectedIndex)]="tabIndex"
      #tabGroup
      animationDuration="100ms"
      mat-stretch-tabs="false"
      mat-align-tabs="start"
    >
      <mat-tab label="{{ 'EVENTS.LOGISTIC' | translate }}">
        <div>
          <!-- CENTER -->
          <div class="p-24 pb-64 pr-sm-72" fusePerfectScrollbar>
            <!-- CONTENT -->
            <div class="event-content">
              <div fxLayout="row" fxLayoutAlign="start end">
                <app-tags-by-model
                  #tags
                  fxFlex="100%"
                  class="ml-4"
                  [tagPlaceholder]="'GENERIC.NEW_TAG' | translate"
                  [disabled]="!editable"
                  [tagFamily]="[
                    'tags-order-service-level',
                    'tags-recurrent-event',
                    'tags-event-custom',
                  ]"
                  [customTagFamily]="'tags-event-custom'"
                  [tagModel]="'event'"
                  [tagModelUUID]="eventUUDID"
                ></app-tags-by-model>
              </div>

              <!-- button changelogs -->
              <div
                *ngxPermissionsOnly="rolesService.ACCOUNTANT_ROLES"
                class="mb-16"
                fxLayoutAlign="center"
                fxLayout="row"
              >
                <button
                  mat-raised-button
                  color="accent"
                  [disabled]="loading"
                  class="accent ml-12"
                  *ngIf="
                    event &&
                    event.documents &&
                    event.documents.order &&
                    event.documents.order.type === 'ORDER' &&
                    event.documents.order.status === 'ORDER_CONFIRM' &&
                    event.documents.order.status !== 'ORDER_CANCEL' &&
                    moduleService.hasChangelogsModule()
                  "
                  (click)="openChangelogDialog()"
                >
                  {{ "EVENTS.CHANGELOG" | translate }}
                </button>
              </div>

              <!-- Informations -->
              <div
                *ngIf="event"
                class="profile-box info-box general"
                fxLayout="column"
              >
                <div class="gt2-theme" style="display: contents">
                  <header class="primary">
                    <div class="title p-16">
                      {{ "GENERIC.INFORMATION" | translate }}
                    </div>
                  </header>
                </div>

                <div class="content" fxLayout="column">
                  <app-event-information
                    [event]="event"
                    (modelUpdatedEvent)="onComponentModelUpdated()"
                  ></app-event-information>
                </div>
              </div>

              <!-- DOCUMENTS -->
              <div
                *ngIf="event"
                class="profile-box info-box general"
                style="min-width: 1023px"
                fxLayout="column"
              >
                <div class="gt2-theme">
                  <div
                    class="primary header-create"
                    fxLayout="row"
                    fxLayoutAlign="space-between center"
                    style="height: 56px"
                  >
                    <div class="title">
                      {{ "EVENTS.DOCUMENTS" | translate }}
                    </div>

                    <div *ngIf="editable">
                      <div
                        *ngxPermissionsOnly="rolesService.SALE_ROLES"
                        class="gt2-light-green"
                        style="width: 60px; height: 56px"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                      >
                        <button
                          *ngIf="!event.documents.order"
                          type="button"
                          matTooltip="{{ 'EVENTS.ADD_PROPOSAL' | translate }}"
                          mat-icon-button
                          class=""
                          (click)="onAddNewProposal()"
                        >
                          <mat-icon class="icon-mat" color="">{{
                            getIcon("ADD")
                          }}</mat-icon>
                        </button>
                        <button
                          *ngIf="event.documents.order"
                          type="button"
                          matTooltip="{{ 'EVENTS.ORDER_EXIST' | translate }}"
                          mat-icon-button
                          [disabled]="false"
                          color=""
                          class="fake-disabled-btn"
                        >
                          <mat-icon class="icon-mat" color="">{{
                            getIcon("ADD")
                          }}</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="" fxLayout="column">
                  <app-event-documents
                    [event]="event"
                    [(editable)]="editable"
                    (modelUpdatedEvent)="onComponentModelUpdated()"
                  ></app-event-documents>
                </div>
              </div>

              <!-- CLIENTS -->
              <div
                *ngIf="event"
                class="profile-box info-box general"
                fxLayout="column"
              >
                <div class="gt2-theme" style="display: contents">
                  <header class="primary">
                    <div class="title p-16">
                      {{ "GENERIC.CLIENTS" | translate }}
                    </div>
                  </header>
                </div>

                <div class="content" fxLayout="column">
                  <app-event-clients
                    [(event)]="event"
                    [(editable)]="editable"
                    [hasCongress]="
                      event.congress_uuid !== null || event.congress_uuid !== ''
                    "
                    (modelUpdatedEvent)="onComponentModelUpdated()"
                  ></app-event-clients>
                </div>
              </div>

              <!-- VENUES -->
              <div
                *ngIf="event"
                class="profile-box info-box general"
                style="width: 100%"
                fxLayout="column"
              >
                <div class="gt2-theme" style="display: contents">
                  <header class="primary">
                    <div class="title p-16">
                      {{ "GENERIC.VENUES" | translate }}
                    </div>
                  </header>
                </div>

                <div class="content" fxLayout="column">
                  <app-event-venues
                    [event]="event"
                    [(editable)]="isEditable"
                    (modelUpdatedEvent)="onComponentModelUpdated()"
                    (newModelEvent)="onVenuesComponentNewModel($event)"
                  ></app-event-venues>
                </div>
              </div>

              <!-- DELIVERY_REQUEST -->
              <div
                *ngIf="event && !event.is_jul"
                class="profile-box info-box general"
                style="min-width: 1023px"
                fxLayout="column"
              >
                <div class="gt2-theme">
                  <div
                    class="primary header-create"
                    fxLayout="row"
                    fxLayoutAlign="space-between center"
                    style="height: 56px"
                  >
                    <div class="title">
                      {{ "EVENTS.DELIVERY_REQUEST" | translate }}
                    </div>

                    <div *ngIf="editable">
                      <div
                        *ngxPermissionsOnly="rolesService.TEAM_LEAD_ROLES"
                        class="gt2-light-green"
                        style="width: 60px; height: 56px"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                      >
                        <button
                          type="button"
                          matTooltip="{{
                            'EVENTS.ADD_DELIVERY_REQUEST' | translate
                          }}"
                          mat-icon-button
                          class=""
                          (click)="onAddNewDeliveryRequest()"
                        >
                          <mat-icon class="icon-mat" color="">{{
                            getIcon("ADD")
                          }}</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="" fxLayout="column">
                  <app-event-delivery-request
                    [event]="event"
                    [(editable)]="editable"
                    (modelUpdatedEvent)="onComponentModelUpdated()"
                  ></app-event-delivery-request>
                </div>
                <div
                  fxLayout="row"
                  fxLayoutAlign="end center"
                  class="p-8 section-footer"
                >
                  <div>
                    <span class="total-number">{{
                      event.footer_delivery_requests_label
                    }}</span>
                  </div>
                </div>
              </div>

              <!-- TAKEOUT_REQUEST -->
              <div
                *ngIf="
                  event &&
                  event.takeout_requests &&
                  event.takeout_requests.length > 0
                "
                class="profile-box info-box general"
                style="min-width: 1023px"
                fxLayout="column"
              >
                <div class="gt2-theme">
                  <div
                    class="primary header-create"
                    fxLayout="row"
                    fxLayoutAlign="space-between center"
                    style="height: 56px"
                  >
                    <div class="title">
                      {{ "EVENTS.TAKEOUT_REQUEST" | translate }}
                    </div>

                    <div *ngIf="editable">
                      <div
                        *ngxPermissionsOnly="rolesService.TEAM_LEAD_ROLES"
                        class="gt2-light-green"
                        style="width: 60px; height: 56px"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                      >
                        <button
                          type="button"
                          matTooltip="{{
                            'EVENTS.ADD_TAKEOUT_REQUEST' | translate
                          }}"
                          mat-icon-button
                          class=""
                          (click)="onAddNewTakeoutRequest()"
                        >
                          <mat-icon class="icon-mat" color="">{{
                            getIcon("ADD")
                          }}</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="" fxLayout="column">
                  <app-event-takout-request
                    [event]="event"
                    [(editable)]="editable"
                    (modelUpdatedEvent)="onComponentModelUpdated()"
                  >
                  </app-event-takout-request>
                </div>

                <!-- <div fxLayout="row" fxLayoutAlign="end center" class="p-8 section-footer">
                                            <div><span class="total-number">{{event.footer_delivery_requests_label}}</span></div>
                                        </div> -->
              </div>

              <!-- UQAM NOTICE OF RECEIPT-->
              <div
                *ngIf="
                  modulesService.modulesAreSet &&
                  modulesService.hasUQAMReport() &&
                  event
                "
                class="profile-box info-box general"
                style="min-width: 1023px"
                fxLayout="column"
              >
                <div class="gt2-theme">
                  <header class="primary">
                    <div class="title p-16">
                      {{ "EVENTS.RECEIPT_NOTICE" | translate }}
                    </div>
                  </header>
                </div>

                <div class="content" fxLayout="column">
                  <app-event-notice-of-receipt
                    [event]="event"
                    (modelUpdatedEvent)="onComponentModelUpdated()"
                  ></app-event-notice-of-receipt>
                </div>
              </div>

              <!-- UQAM STORAGE NOTICE -->
              <div
                *ngIf="
                  event &&
                  modulesService.modulesAreSet &&
                  modulesService.hasUQAMReport()
                "
                class="profile-box info-box general"
                style="min-width: 1023px"
                fxLayout="column"
              >
                <div class="gt2-theme">
                  <header class="primary">
                    <div class="title p-16">
                      {{ "EVENTS.STORAGE_NOTICE" | translate }}
                    </div>
                  </header>
                </div>

                <div class="content" fxLayout="column">
                  <app-event-storage-notice
                    [event]="event"
                    (modelUpdatedEvent)="onComponentModelUpdated()"
                  ></app-event-storage-notice>
                </div>
              </div>

              <!-- FLOW -->
              <div
                *ngIf="event"
                class="profile-box info-box general"
                style="min-width: 1023px"
                fxLayout="column"
              >
                <div class="gt2-theme">
                  <div
                    class="primary header-create"
                    fxLayout="row"
                    fxLayoutAlign="space-between center"
                    style="height: 56px"
                  >
                    <div class="title">{{ "EVENTS.FLOW" | translate }}</div>

                    <div *ngIf="editable">
                      <div
                        *ngxPermissionsOnly="rolesService.SALE_ROLES"
                        class="gt2-light-green"
                        style="width: 60px; height: 56px"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                      >
                        <button
                          type="button"
                          matTooltip="{{ 'EVENTS.ADD_FLOW' | translate }}"
                          mat-icon-button
                          class=""
                          (click)="onAddNewFlow()"
                        >
                          <mat-icon class="icon-mat" color="">{{
                            getIcon("ADD")
                          }}</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="" fxLayout="column">
                  <app-event-flow
                    [event]="event"
                    [(editable)]="editable"
                    (modelUpdatedEvent)="onComponentModelUpdated()"
                  ></app-event-flow>
                </div>
                <div
                  fxLayout="row"
                  fxLayoutAlign="end center"
                  class="p-8 section-footer"
                >
                  <div>
                    <span class="total-number">{{
                      event.footer_flows_range_label
                    }}</span>
                  </div>
                </div>
              </div>

              <!-- PROGRAMS -->
              <div
                *ngIf="
                  event &&
                  moduleService.modulesAreSet &&
                  moduleService.moduleExist('MODULE_EVENT_PROGRAMS') &&
                  !event.is_slave
                "
                style="min-width: 1023px"
                class="profile-box info-box general"
                fxLayout="column"
              >
                <div class="gt2-theme">
                  <div
                    class="primary header-create"
                    fxLayout="row"
                    fxLayoutAlign="space-between center"
                    style="height: 56px"
                  >
                    <div class="title">
                      {{ "GENERIC.PROGRAMS" | translate }}
                    </div>

                    <div *ngIf="editable">
                      <div
                        *ngxPermissionsOnly="rolesService.SALE_ROLES"
                        class="gt2-light-green"
                        style="width: 60px; height: 56px"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                      >
                        <button
                          type="button"
                          matTooltip="{{ 'EVENTS.ADD_PROGRAM' | translate }}"
                          mat-icon-button
                          class=""
                          (click)="onAddNewProgram()"
                        >
                          <mat-icon class="icon-mat" color="">{{
                            getIcon("ADD")
                          }}</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="" fxLayout="column">
                  <app-event-programs
                    [event]="event"
                    [(editable)]="editable"
                    (modelUpdatedEvent)="onComponentModelUpdated()"
                  >
                  </app-event-programs>
                </div>
                <!-- <div fxLayout="row" fxLayoutAlign="end center" class="p-8 section-footer">
                                            <div><span class="total-number">{{event.footer_flows_range_label}}</span></div>
                                        </div> -->
              </div>

              <!-- GUEST -->
              <div
                *ngIf="event"
                class="profile-box info-box general"
                fxLayout="column"
              >
                <div class="gt2-theme" style="display: contents">
                  <div
                    class="primary header-create"
                    fxLayout="row"
                    fxLayoutAlign="space-between center"
                    style="height: 56px"
                  >
                    <div class="title">{{ "EVENTS.GUESTS" | translate }}</div>

                    <div *ngIf="editable">
                      <div
                        *ngxPermissionsOnly="rolesService.SALE_ROLES"
                        class="gt2-light-green"
                        style="width: 60px; height: 56px"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                      >
                        <button
                          type="button"
                          matTooltip="{{ 'EVENTS.ADD_GUEST' | translate }}"
                          mat-icon-button
                          class=""
                          (click)="onAddNewGuest()"
                        >
                          <mat-icon class="icon-mat" color="">{{
                            getIcon("ADD")
                          }}</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="" fxLayout="column">
                  <app-event-guest
                    [event]="event"
                    [(editable)]="editable"
                    (modelUpdatedEvent)="onComponentModelUpdated()"
                  ></app-event-guest>
                </div>
                <div
                  fxLayout="row"
                  fxLayoutAlign="end center"
                  class="p-8 section-footer"
                >
                  <div>
                    <span class="total-number">{{
                      event.footer_guests_total_label
                    }}</span>
                  </div>
                </div>
              </div>

              <div
                *ngIf="
                  event &&
                  moduleService.modulesAreSet &&
                  moduleService.moduleExist('MODULE_EVENT_FILES')
                "
                class="profile-box info-box general"
              >
                <div class="gt2-theme" style="display: contents">
                  <div
                    class="primary header-create"
                    fxLayout="row"
                    fxLayoutAlign="space-between center"
                    style="height: 56px"
                  >
                    <div class="title">{{ "GENERIC.FILES" | translate }}</div>

                    <div
                      *ngxPermissionsOnly="rolesService.SALE_ROLES"
                      class="gt2-light-green"
                      style="width: 60px; height: 56px"
                      fxLayout="row"
                      fxLayoutAlign="center center"
                    >
                      <button
                        type="button"
                        matTooltip="{{ 'GENERIC.ATTACH_FILE' | translate }}"
                        mat-icon-button
                        class=""
                        [disabled]="event.invoice"
                        (click)="eventAttachFile.onAttachDocument()"
                      >
                        <mat-icon class="icon-mat" color="">add</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>

                <div class="">
                  <app-event-attached-files
                    [eventUUID]="event.uuid"
                    #eventAttachFile
                  ></app-event-attached-files>
                </div>
              </div>

              <app-comment-section
                *ngIf="event"
                [commentsModel]="event"
                [serviceAPIUrl]="'/v1/events/comments/' + event.uuid"
              >
              </app-comment-section>

              <app-created-updated-at
                *ngIf="event"
                [historicModel]="event.historic_model"
              >
              </app-created-updated-at>

              <!-- <div *ngxPermissionsOnly="rolesService.RH_ROLES">
                                            <div *ngIf="order" fxFlex="100%" fxLayout="row" fxLayoutAlign="center center" class="mt-8">
                                                <button mat-raised-button
                                                        (click)="onDeleteOrder()"
                                                        class="warn mb-32">
                                                    {{ 'GENERIC.DELETE' | translate }}
                                                </button>
                                            </div>
                            </div> -->
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab
        *ngxPermissionsOnly="rolesService.SALE_ROLES"
        label="{{ 'EVENTS.ACCOUNTING' | translate }}"
      >
        <div class="content">
          <!-- CENTER -->
          <div class="p-24 pb-56 pr-sm-72" fusePerfectScrollbar>
            <!-- CONTENT -->
            <div class="event-content">
              <!-- STATS -->
              <app-event-stats
                [event]="event"
                [(editable)]="editable"
                (modelUpdatedEvent)="onComponentModelUpdated()"
              ></app-event-stats>

              <!-- DEPOSIT_REQUEST -->
              <div
                *ngxPermissionsOnly="rolesService.SALE_ROLES"
                style="min-width: 1023px"
              >
                <div
                  *ngIf="event"
                  class="profile-box info-box general"
                  fxLayout="column"
                >
                  <div class="gt2-theme">
                    <div
                      class="primary header-create"
                      fxLayout="row"
                      fxLayoutAlign="space-between center"
                      style="height: 56px"
                    >
                      <div class="title">
                        {{ "EVENTS.DEPOSIT_REQUEST" | translate }}
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div
                          *ngIf="
                            event &&
                            event.deposit_requests &&
                            event.deposit_requests.length > 0 &&
                            modulesService.modulesAreSet &&
                            (modulesService.hasParreiraReport() ||
                              modulesService.hasRPReport())
                          "
                          class="gt2-buttons-theme"
                        >
                          <button
                            mat-raised-button
                            color="primary"
                            class="mr-12"
                            [matMenuTriggerFor]="menuDeposits"
                          >
                            {{ "EVENTS.REPORT_RECEIVED" | translate }}
                          </button>

                          <mat-menu #menuDeposits="matMenu">
                            <button
                              mat-menu-item
                              (click)="
                                menuReportsService.buildAndRunVo(
                                  ReportsEnum.REPORT_CLIENT_EVENT_DEPOSIT_REQUEST,
                                  DistributionModeEnum.FILE,
                                  ExportFormatEnum.PDF,
                                  ExportToEnum.PRINT,
                                  LocaleEnum.FR_CA,
                                  PaperEnum.A4,
                                  { uuid_event: eventUUDID }
                                )
                              "
                            >
                              {{ "GENERIC.PRINT" | translate }}
                            </button>

                            <button
                              mat-menu-item
                              (click)="
                                menuReportsService.buildAndRunVo(
                                  ReportsEnum.REPORT_CLIENT_EVENT_DEPOSIT_REQUEST,
                                  DistributionModeEnum.FILE,
                                  ExportFormatEnum.PDF,
                                  ExportToEnum.DOWNLOAD,
                                  LocaleEnum.FR_CA,
                                  PaperEnum.A4,
                                  { uuid_event: eventUUDID }
                                )
                              "
                            >
                              {{ "GENERIC.DOWNLOAD" | translate }}
                            </button>

                            <!-- <button mat-menu-item 
                                                                *ngIf="modulesService.modulesAreSet && modulesService.hasGABReport()" 
                                                                    (click)="menuReportsService.buildAndRunVo(
                                                                            ReportsEnum.REPORT_CLIENT_EVENT_DEPOSIT_REQUEST,
                                                                            DistributionModeEnum.FILE,
                                                                            ExportFormatEnum.PDF,
                                                                            ExportToEnum.PRINT,
                                                                            LocaleEnum.FR_CA,
                                                                            PaperEnum.A4,
                                                                            { uuid_event: eventUUDID})">
                                                            {{ "GENERIC.SEND" | translate }}
                                                        </button> -->

                            <!-- <button mat-menu-item (click)="onPrintDeposits()">{{ "GENERIC.PRINT" | translate }}</button>
                                                        <button mat-menu-item (click)="onDownloadDeposits()">{{ "GENERIC.DOWNLOAD" | translate }}</button> -->
                            <!-- <button mat-menu-item (click)="onSendDeposits()">{{ "GENERIC.SEND" | translate }}</button> -->
                          </mat-menu>
                        </div>

                        <div
                          class="gt2-buttons-theme"
                          *ngIf="
                            event &&
                            event.current_order_type !== 'INVOICE' &&
                            modulesService.modulesAreSet &&
                            modulesService.hasOkaReport()
                          "
                        >
                          <button
                            mat-raised-button
                            color="primary"
                            [class.btn-spinner]="loading"
                            [disabled]="loading"
                            class="mr-12"
                            (click)="onAddNewOkaDepositRequest()"
                          >
                            {{
                              "EVENTS.CREATE_OKA_DEPOSIT_REQUEST" | translate
                            }}
                          </button>
                        </div>

                        <div
                          *ngIf="
                            event && event.current_order_type !== 'INVOICE'
                          "
                        >
                          <div *ngIf="editable">
                            <div
                              *ngxPermissionsOnly="rolesService.SALE_ROLES"
                              class="gt2-light-green"
                              style="width: 60px; height: 56px"
                              fxLayout="row"
                              fxLayoutAlign="center center"
                            >
                              <button
                                type="button"
                                matTooltip="{{
                                  'EVENTS.CREATE_DEPOSIT_REQUEST' | translate
                                }}"
                                mat-icon-button
                                class=""
                                [disabled]="event.invoice"
                                (click)="onAddNewDepositRequest()"
                              >
                                <mat-icon class="icon-mat" color="">{{
                                  getIcon("ADD")
                                }}</mat-icon>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="" fxLayout="column">
                    <app-event-deposit-request
                      [(event)]="event"
                      [(editable)]="editable"
                      (modelUpdatedEvent)="onComponentModelUpdated()"
                    ></app-event-deposit-request>
                  </div>
                  <div
                    fxLayout="row"
                    fxLayoutAlign="end center"
                    class="p-8 section-footer"
                  >
                    <div>
                      <span class="total-number">{{
                        event.footer_deposit_requests_label
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                *ngxPermissionsOnly="rolesService.ACCOUNTANT_ROLES"
                style="min-width: 1023px"
              >
                <!-- Deposit In / Encaissement-->
                <div
                  *ngIf="event"
                  class="profile-box info-box general"
                  fxLayout="column"
                >
                  <div class="gt2-theme">
                    <div
                      class="primary header-create"
                      fxLayout="row"
                      fxLayoutAlign="space-between center"
                      style="height: 56px"
                    >
                      <div class="title">
                        {{ "EVENTS.DEPOSIT_IN" | translate }}
                      </div>

                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div
                          *ngIf="
                            event && event.cashings && event.cashings.length > 0
                          "
                          class="gt2-buttons-theme"
                        >
                          <button
                            mat-raised-button
                            color="primary"
                            class="mr-12"
                            [matMenuTriggerFor]="menuReceipt"
                          >
                            {{ "EVENTS.REPORT_RECEIVED" | translate }}
                          </button>
                          <mat-menu #menuReceipt="matMenu">
                            <button mat-menu-item (click)="onPrintReceipt()">
                              {{ "GENERIC.PRINT" | translate }}
                            </button>
                            <button mat-menu-item (click)="onDownloadReceipt()">
                              {{ "GENERIC.DOWNLOAD" | translate }}
                            </button>
                            <button mat-menu-item (click)="onSendReceipt()">
                              {{ "GENERIC.SEND" | translate }}
                            </button>
                          </mat-menu>
                        </div>
                        <div *ngIf="editable">
                          <div
                            *ngxPermissionsOnly="rolesService.ACCOUNTANT_ROLES"
                            class="gt2-light-green"
                            style="width: 60px; height: 56px"
                            fxLayout="row"
                            fxLayoutAlign="center center"
                          >
                            <button
                              type="button"
                              matTooltip="{{
                                'EVENTS.CREATE_DEPOSIT_IN' | translate
                              }}"
                              mat-icon-button
                              class=""
                              (click)="onAddNewCashing()"
                            >
                              <mat-icon class="icon-mat" color="">{{
                                getIcon("ADD")
                              }}</mat-icon>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="" fxLayout="column">
                    <app-event-deposit-in
                      [event]="event"
                      [(editable)]="editable"
                      (modelUpdatedEvent)="onComponentModelUpdated()"
                    ></app-event-deposit-in>
                  </div>
                  <div
                    fxLayout="row"
                    fxLayoutAlign="end center"
                    class="p-8 section-footer"
                  >
                    <div>
                      <span class="total-number">{{
                        event.footer_cashings_label
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
    <!-- / TABs -->
  </div>

  <!-- DISPLAY PAGE EVENT BASED ON TABS FOR ACCOUNTS THAT HOLD MODULE_EVENT_TABBED -->
  <div
    class="content center"
    *ngIf="
      moduleService.modulesAreSet &&
      moduleService.moduleExist('MODULE_EVENT_TABBED')
    "
  >
    <!-- TABs -->
    <div class="p-24 pb-8 pr-sm-72">
      <div class="event-content">
        <div fxLayout="row" fxLayoutAlign="start end">
          <app-tags-by-model
            #tags
            fxFlex="100%"
            class="ml-4"
            [tagPlaceholder]="'GENERIC.NEW_TAG' | translate"
            [disabled]="!editable"
            [tagFamily]="[
              'tags-order-service-level',
              'tags-recurrent-event',
              'tags-event-custom',
            ]"
            [customTagFamily]="'tags-event-custom'"
            [tagModel]="'event'"
            [tagModelUUID]="eventUUDID"
          >
          </app-tags-by-model>
        </div>
      </div>
    </div>
    <mat-tab-group
      [(selectedIndex)]="tabIndex"
      #tabGroup
      animationDuration="100ms"
      mat-stretch-tabs="false"
      mat-align-tabs="start"
    >
      <mat-tab label="{{ 'GENERIC.INFORMATION' | translate }}">
        <div>
          <div class="p-24 pb-64 pr-sm-72" fusePerfectScrollbar>
            <div class="event-content">
              <!-- button changelogs -->
              <div
                *ngxPermissionsOnly="rolesService.ACCOUNTANT_ROLES"
                class="mb-16"
                fxLayoutAlign="center"
                fxLayout="row"
              >
                <button
                  mat-raised-button
                  color="accent"
                  [disabled]="loading"
                  class="accent ml-12"
                  *ngIf="
                    event &&
                    event.documents &&
                    event.documents.order &&
                    event.documents.order.type === 'ORDER' &&
                    event.documents.order.status === 'ORDER_CONFIRM' &&
                    event.documents.order.status !== 'ORDER_CANCEL' &&
                    moduleService.hasChangelogsModule()
                  "
                  (click)="openChangelogDialog()"
                >
                  {{ "EVENTS.CHANGELOG" | translate }}
                </button>
              </div>

              <!-- Informations -->
              <div
                *ngIf="event"
                class="profile-box info-box general"
                fxLayout="column"
              >
                <div class="gt2-theme" style="display: contents">
                  <header class="primary">
                    <div class="title p-16">
                      {{ "GENERIC.INFORMATION" | translate }}
                    </div>
                  </header>
                </div>

                <div class="content" fxLayout="column">
                  <app-event-information
                    [event]="event"
                    (modelUpdatedEvent)="onComponentModelUpdated()"
                  ></app-event-information>
                </div>
              </div>

              <!-- File upload-->
              <div
                *ngIf="
                  event &&
                  moduleService.modulesAreSet &&
                  moduleService.moduleExist('MODULE_EVENT_FILES')
                "
                class="profile-box info-box general mt-16"
              >
                <div class="gt2-theme">
                  <div
                    class="primary header-create"
                    fxLayout="row"
                    fxLayoutAlign="space-between center"
                    style="height: 56px"
                  >
                    <div class="title">{{ "GENERIC.FILES" | translate }}</div>

                    <div
                      *ngxPermissionsOnly="rolesService.SALE_ROLES"
                      class="gt2-light-green"
                      style="width: 60px; height: 56px"
                      fxLayout="row"
                      fxLayoutAlign="center center"
                    >
                      <button
                        type="button"
                        matTooltip="{{ 'GENERIC.ATTACH_FILE' | translate }}"
                        mat-icon-button
                        class=""
                        [disabled]="event.invoice"
                        (click)="eventAttachFile.onAttachDocument()"
                      >
                        <mat-icon class="icon-mat" color="">{{
                          getIcon("ADD")
                        }}</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>

                <div class="">
                  <app-event-attached-files
                    [eventUUID]="event.uuid"
                    #eventAttachFile
                  ></app-event-attached-files>
                </div>
              </div>

              <app-comment-section
                *ngIf="event"
                [commentsModel]="event"
                [serviceAPIUrl]="'/v1/events/comments/' + event.uuid"
              >
              </app-comment-section>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="{{ 'GENERIC.CLIENTS' | translate }}">
        <ng-template matTabContent>
          <div>
            <div class="p-24 pb-64 pr-sm-72" fusePerfectScrollbar>
              <div class="event-content">
                <!-- button changelogs -->
                <div
                  *ngxPermissionsOnly="rolesService.ACCOUNTANT_ROLES"
                  class="mb-16"
                  fxLayoutAlign="center"
                  fxLayout="row"
                >
                  <button
                    mat-raised-button
                    color="accent"
                    [disabled]="loading"
                    class="accent ml-12"
                    *ngIf="
                      event &&
                      event.documents &&
                      event.documents.order &&
                      event.documents.order.type === 'ORDER' &&
                      event.documents.order.status === 'ORDER_CONFIRM' &&
                      event.documents.order.status !== 'ORDER_CANCEL' &&
                      moduleService.hasChangelogsModule()
                    "
                    (click)="openChangelogDialog()"
                  >
                    {{ "EVENTS.CHANGELOG" | translate }}
                  </button>
                </div>

                <!-- Clients -->
                <div
                  *ngIf="event"
                  class="profile-box info-box general"
                  fxLayout="column"
                >
                  <div class="gt2-theme">
                    <header class="primary">
                      <div class="title p-16">
                        {{ "GENERIC.CLIENTS" | translate }}
                      </div>
                    </header>
                  </div>

                  <div class="content" fxLayout="column">
                    <app-event-clients
                      [(event)]="event"
                      [(editable)]="editable"
                      [hasCongress]="
                        event.congress_uuid !== null ||
                        event.congress_uuid !== ''
                      "
                      (modelUpdatedEvent)="onComponentModelUpdated()"
                    ></app-event-clients>
                  </div>
                </div>

                <!-- File upload-->
                <div
                  *ngIf="
                    event &&
                    moduleService.modulesAreSet &&
                    moduleService.moduleExist('MODULE_EVENT_FILES')
                  "
                  class="profile-box info-box general mt-16"
                >
                  <div class="gt2-theme">
                    <div
                      class="primary header-create"
                      fxLayout="row"
                      fxLayoutAlign="space-between center"
                      style="height: 56px"
                    >
                      <div class="title">{{ "GENERIC.FILES" | translate }}</div>

                      <div
                        *ngxPermissionsOnly="rolesService.SALE_ROLES"
                        class="gt2-light-green"
                        style="width: 60px; height: 56px"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                      >
                        <button
                          type="button"
                          matTooltip="{{ 'GENERIC.ATTACH_FILE' | translate }}"
                          mat-icon-button
                          class=""
                          [disabled]="event.invoice"
                          (click)="eventAttachFile.onAttachDocument()"
                        >
                          <mat-icon class="icon-mat" color="">{{
                            getIcon("ADD")
                          }}</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="">
                    <app-event-attached-files
                      [eventUUID]="event.uuid"
                      #eventAttachFile
                    ></app-event-attached-files>
                  </div>
                </div>

                <app-comment-section
                  *ngIf="event"
                  [commentsModel]="event"
                  [serviceAPIUrl]="'/v1/events/comments/' + event.uuid"
                >
                </app-comment-section>
              </div>
            </div>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab label="{{ 'GENERIC.VENUES_TAB' | translate }}">
        <ng-template matTabContent>
          <div>
            <div class="p-24 pb-64 pr-sm-72" fusePerfectScrollbar>
              <div class="event-content">
                <!-- button changelogs -->
                <div
                  *ngxPermissionsOnly="rolesService.ACCOUNTANT_ROLES"
                  class="mb-16"
                  fxLayoutAlign="center"
                  fxLayout="row"
                >
                  <button
                    mat-raised-button
                    color="accent"
                    [disabled]="loading"
                    class="accent ml-12"
                    *ngIf="
                      event &&
                      event.documents &&
                      event.documents.order &&
                      event.documents.order.type === 'ORDER' &&
                      event.documents.order.status === 'ORDER_CONFIRM' &&
                      event.documents.order.status !== 'ORDER_CANCEL' &&
                      moduleService.hasChangelogsModule()
                    "
                    (click)="openChangelogDialog()"
                  >
                    {{ "EVENTS.CHANGELOG" | translate }}
                  </button>
                </div>

                <!-- VENUES -->
                <div
                  *ngIf="event"
                  class="profile-box info-box general"
                  fxLayout="column"
                >
                  <div class="gt2-theme">
                    <header class="primary">
                      <div class="title p-16">
                        {{ "GENERIC.VENUES" | translate }}
                      </div>
                    </header>
                  </div>

                  <div class="content" fxLayout="column">
                    <app-event-venues
                      [event]="event"
                      [(editable)]="isEditable"
                      (modelUpdatedEvent)="onComponentModelUpdated()"
                      (newModelEvent)="onVenuesComponentNewModel($event)"
                    ></app-event-venues>
                  </div>
                </div>

                <!-- DELIVERY_REQUEST -->
                <div
                  *ngIf="event && !event.is_jul"
                  class="profile-box info-box general"
                  fxLayout="column"
                >
                  <div class="gt2-theme">
                    <div
                      class="primary header-create"
                      fxLayout="row"
                      fxLayoutAlign="space-between center"
                      style="height: 56px"
                    >
                      <div class="title">
                        {{ "EVENTS.DELIVERY_REQUEST" | translate }}
                      </div>

                      <div *ngIf="editable">
                        <div
                          *ngxPermissionsOnly="rolesService.TEAM_LEAD_ROLES"
                          class="gt2-light-green"
                          style="width: 60px; height: 56px"
                          fxLayout="row"
                          fxLayoutAlign="center center"
                        >
                          <button
                            type="button"
                            matTooltip="{{
                              'EVENTS.ADD_DELIVERY_REQUEST' | translate
                            }}"
                            mat-icon-button
                            class=""
                            (click)="onAddNewDeliveryRequest()"
                          >
                            <mat-icon class="icon-mat" color="">{{
                              getIcon("ADD")
                            }}</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="" fxLayout="column">
                    <app-event-delivery-request
                      [event]="event"
                      [(editable)]="editable"
                      (modelUpdatedEvent)="onComponentModelUpdated()"
                    ></app-event-delivery-request>
                  </div>
                  <div
                    fxLayout="row"
                    fxLayoutAlign="end center"
                    class="p-8 section-footer"
                  >
                    <div>
                      <span class="total-number">{{
                        event.footer_delivery_requests_label
                      }}</span>
                    </div>
                  </div>
                </div>

                <!-- File upload-->
                <div
                  *ngIf="
                    event &&
                    moduleService.modulesAreSet &&
                    moduleService.moduleExist('MODULE_EVENT_FILES')
                  "
                  class="profile-box info-box general mt-16"
                >
                  <div class="gt2-theme">
                    <div
                      class="primary header-create"
                      fxLayout="row"
                      fxLayoutAlign="space-between center"
                      style="height: 56px"
                    >
                      <div class="title">{{ "GENERIC.FILES" | translate }}</div>

                      <div
                        *ngxPermissionsOnly="rolesService.SALE_ROLES"
                        class="gt2-light-green"
                        style="width: 60px; height: 56px"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                      >
                        <button
                          type="button"
                          matTooltip="{{ 'GENERIC.ATTACH_FILE' | translate }}"
                          mat-icon-button
                          class=""
                          [disabled]="event.invoice"
                          (click)="eventAttachFile.onAttachDocument()"
                        >
                          <mat-icon class="icon-mat" color="">{{
                            getIcon("ADD")
                          }}</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="">
                    <app-event-attached-files
                      [eventUUID]="event.uuid"
                      #eventAttachFile
                    ></app-event-attached-files>
                  </div>
                </div>

                <app-comment-section
                  *ngIf="event"
                  [commentsModel]="event"
                  [serviceAPIUrl]="'/v1/events/comments/' + event.uuid"
                >
                </app-comment-section>
              </div>
            </div>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab
        *ngIf="
          event &&
          moduleService.modulesAreSet &&
          moduleService.moduleExist('MODULE_EVENT_PROGRAMS') &&
          !event.is_slave
        "
        label="{{ 'GENERIC.PROGRAMS' | translate }}"
      >
        <ng-template matTabContent>
          <div>
            <div class="p-24 pb-64 pr-sm-72" fusePerfectScrollbar>
              <div class="event-content">
                <!-- button changelogs -->
                <div
                  *ngxPermissionsOnly="rolesService.ACCOUNTANT_ROLES"
                  class="mb-16"
                  fxLayoutAlign="center"
                  fxLayout="row"
                >
                  <button
                    mat-raised-button
                    color="accent"
                    [disabled]="loading"
                    class="accent ml-12"
                    *ngIf="
                      event &&
                      event.documents &&
                      event.documents.order &&
                      event.documents.order.type === 'ORDER' &&
                      event.documents.order.status === 'ORDER_CONFIRM' &&
                      event.documents.order.status !== 'ORDER_CANCEL' &&
                      moduleService.hasChangelogsModule()
                    "
                    (click)="openChangelogDialog()"
                  >
                    {{ "EVENTS.CHANGELOG" | translate }}
                  </button>
                </div>

                <!-- PROGRAMS -->
                <div class="profile-box info-box general" fxLayout="column">
                  <div class="gt2-theme">
                    <div
                      class="primary header-create"
                      fxLayout="row"
                      fxLayoutAlign="space-between center"
                      style="height: 56px"
                    >
                      <div class="title">
                        {{ "GENERIC.PROGRAMS" | translate }}
                      </div>

                      <div *ngIf="editable">
                        <div
                          *ngxPermissionsOnly="rolesService.SALE_ROLES"
                          class="gt2-light-green"
                          style="width: 60px; height: 56px"
                          fxLayout="row"
                          fxLayoutAlign="center center"
                        >
                          <button
                            type="button"
                            matTooltip="{{ 'EVENTS.ADD_PROGRAM' | translate }}"
                            mat-icon-button
                            class=""
                            (click)="onAddNewProgram()"
                          >
                            <mat-icon class="icon-mat" color="">{{
                              getIcon("ADD")
                            }}</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="" fxLayout="column">
                    <app-event-programs
                      [event]="event"
                      [(editable)]="editable"
                      (modelUpdatedEvent)="onComponentModelUpdated()"
                    >
                    </app-event-programs>
                  </div>
                  <!-- <div fxLayout="row" fxLayoutAlign="end center" class="p-8 section-footer">
                                                                    <div><span class="total-number">{{event.footer_flows_range_label}}</span></div>
                                                                </div> -->
                </div>

                <!-- File upload-->
                <div
                  *ngIf="
                    event &&
                    moduleService.modulesAreSet &&
                    moduleService.moduleExist('MODULE_EVENT_FILES')
                  "
                  class="profile-box info-box general mt-16"
                >
                  <div class="gt2-theme">
                    <div
                      class="primary header-create"
                      fxLayout="row"
                      fxLayoutAlign="space-between center"
                      style="height: 56px"
                    >
                      <div class="title">{{ "GENERIC.FILES" | translate }}</div>

                      <div
                        *ngxPermissionsOnly="rolesService.SALE_ROLES"
                        class="gt2-light-green"
                        style="width: 60px; height: 56px"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                      >
                        <button
                          type="button"
                          matTooltip="{{ 'GENERIC.ATTACH_FILE' | translate }}"
                          mat-icon-button
                          class=""
                          [disabled]="event.invoice"
                          (click)="eventAttachFile.onAttachDocument()"
                        >
                          <mat-icon class="icon-mat" color="">{{
                            getIcon("ADD")
                          }}</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="">
                    <app-event-attached-files
                      [eventUUID]="event.uuid"
                      #eventAttachFile
                    ></app-event-attached-files>
                  </div>
                </div>

                <app-comment-section
                  *ngIf="event"
                  [commentsModel]="event"
                  [serviceAPIUrl]="'/v1/events/comments/' + event.uuid"
                >
                </app-comment-section>
              </div>
            </div>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab label="{{ 'EVENTS.FLOW' | translate }}">
        <ng-template matTabContent>
          <div>
            <div class="p-24 pb-64 pr-sm-72" fusePerfectScrollbar>
              <div class="event-content">
                <!-- button changelogs -->
                <div
                  *ngxPermissionsOnly="rolesService.ACCOUNTANT_ROLES"
                  class="mb-16"
                  fxLayoutAlign="center"
                  fxLayout="row"
                >
                  <button
                    mat-raised-button
                    color="accent"
                    [disabled]="loading"
                    class="accent ml-12"
                    *ngIf="
                      event &&
                      event.documents &&
                      event.documents.order &&
                      event.documents.order.type === 'ORDER' &&
                      event.documents.order.status === 'ORDER_CONFIRM' &&
                      event.documents.order.status !== 'ORDER_CANCEL' &&
                      moduleService.hasChangelogsModule()
                    "
                    (click)="openChangelogDialog()"
                  >
                    {{ "EVENTS.CHANGELOG" | translate }}
                  </button>
                </div>

                <!-- FLOW -->
                <div
                  *ngIf="event"
                  class="profile-box info-box general"
                  fxLayout="column"
                >
                  <div class="gt2-theme">
                    <div
                      class="primary header-create"
                      fxLayout="row"
                      fxLayoutAlign="space-between center"
                      style="height: 56px"
                    >
                      <div class="title">{{ "EVENTS.FLOW" | translate }}</div>

                      <div *ngIf="editable">
                        <div
                          *ngxPermissionsOnly="rolesService.SALE_ROLES"
                          class="gt2-light-green"
                          style="width: 60px; height: 56px"
                          fxLayout="row"
                          fxLayoutAlign="center center"
                        >
                          <button
                            type="button"
                            matTooltip="{{ 'EVENTS.ADD_FLOW' | translate }}"
                            mat-icon-button
                            class=""
                            (click)="onAddNewFlow()"
                          >
                            <mat-icon class="icon-mat" color="">{{
                              getIcon("ADD")
                            }}</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="" fxLayout="column">
                    <app-event-flow
                      [event]="event"
                      [(editable)]="editable"
                      (modelUpdatedEvent)="onComponentModelUpdated()"
                    ></app-event-flow>
                  </div>
                  <div
                    fxLayout="row"
                    fxLayoutAlign="end center"
                    class="p-8 section-footer"
                  >
                    <div>
                      <span class="total-number">{{
                        event.footer_flows_range_label
                      }}</span>
                    </div>
                  </div>
                </div>
                <!-- File upload-->
                <div
                  *ngIf="
                    event &&
                    moduleService.modulesAreSet &&
                    moduleService.moduleExist('MODULE_EVENT_FILES')
                  "
                  class="profile-box info-box general mt-16"
                >
                  <div class="gt2-theme">
                    <div
                      class="primary header-create"
                      fxLayout="row"
                      fxLayoutAlign="space-between center"
                      style="height: 56px"
                    >
                      <div class="title">{{ "GENERIC.FILES" | translate }}</div>

                      <div
                        *ngxPermissionsOnly="rolesService.SALE_ROLES"
                        class="gt2-light-green"
                        style="width: 60px; height: 56px"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                      >
                        <button
                          type="button"
                          matTooltip="{{ 'GENERIC.ATTACH_FILE' | translate }}"
                          mat-icon-button
                          class=""
                          [disabled]="event.invoice"
                          (click)="eventAttachFile.onAttachDocument()"
                        >
                          <mat-icon class="icon-mat" color="">{{
                            getIcon("ADD")
                          }}</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="">
                    <app-event-attached-files
                      [eventUUID]="event.uuid"
                      #eventAttachFile
                    ></app-event-attached-files>
                  </div>
                </div>

                <app-comment-section
                  *ngIf="event"
                  [commentsModel]="event"
                  [serviceAPIUrl]="'/v1/events/comments/' + event.uuid"
                >
                </app-comment-section>
              </div>
            </div>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab label="{{ 'EVENTS.GUESTS' | translate }}">
        <ng-template matTabContent>
          <div>
            <div class="p-24 pb-64 pr-sm-72" fusePerfectScrollbar>
              <div class="event-content">
                <!-- button changelogs -->
                <div
                  *ngxPermissionsOnly="rolesService.ACCOUNTANT_ROLES"
                  class="mb-16"
                  fxLayoutAlign="center"
                  fxLayout="row"
                >
                  <button
                    mat-raised-button
                    color="accent"
                    [disabled]="loading"
                    class="accent ml-12"
                    *ngIf="
                      event &&
                      event.documents &&
                      event.documents.order &&
                      event.documents.order.type === 'ORDER' &&
                      event.documents.order.status === 'ORDER_CONFIRM' &&
                      event.documents.order.status !== 'ORDER_CANCEL' &&
                      moduleService.hasChangelogsModule()
                    "
                    (click)="openChangelogDialog()"
                  >
                    {{ "EVENTS.CHANGELOG" | translate }}
                  </button>
                </div>

                <!-- GUEST -->
                <div
                  *ngIf="event"
                  class="profile-box info-box general"
                  fxLayout="column"
                >
                  <div class="gt2-theme">
                    <div
                      class="primary header-create"
                      fxLayout="row"
                      fxLayoutAlign="space-between center"
                      style="height: 56px"
                    >
                      <div class="title">{{ "EVENTS.GUESTS" | translate }}</div>

                      <div *ngIf="editable">
                        <div
                          *ngxPermissionsOnly="rolesService.SALE_ROLES"
                          class="gt2-light-green"
                          style="width: 60px; height: 56px"
                          fxLayout="row"
                          fxLayoutAlign="center center"
                        >
                          <button
                            type="button"
                            matTooltip="{{ 'EVENTS.ADD_GUEST' | translate }}"
                            mat-icon-button
                            class=""
                            (click)="onAddNewGuest()"
                          >
                            <mat-icon class="icon-mat" color="">{{
                              getIcon("ADD")
                            }}</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="" fxLayout="column">
                    <app-event-guest
                      [event]="event"
                      [(editable)]="editable"
                      (modelUpdatedEvent)="onComponentModelUpdated()"
                    ></app-event-guest>
                  </div>
                  <div
                    fxLayout="row"
                    fxLayoutAlign="end center"
                    class="p-8 section-footer"
                  >
                    <div>
                      <span class="total-number">{{
                        event.footer_guests_total_label
                      }}</span>
                    </div>
                  </div>
                </div>

                <!-- File upload-->
                <div
                  *ngIf="
                    event &&
                    moduleService.modulesAreSet &&
                    moduleService.moduleExist('MODULE_EVENT_FILES')
                  "
                  class="profile-box info-box general mt-16"
                >
                  <div class="gt2-theme">
                    <div
                      class="primary header-create"
                      fxLayout="row"
                      fxLayoutAlign="space-between center"
                      style="height: 56px"
                    >
                      <div class="title">{{ "GENERIC.FILES" | translate }}</div>

                      <div
                        *ngxPermissionsOnly="rolesService.SALE_ROLES"
                        class="gt2-light-green"
                        style="width: 60px; height: 56px"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                      >
                        <button
                          type="button"
                          matTooltip="{{ 'GENERIC.ATTACH_FILE' | translate }}"
                          mat-icon-button
                          class=""
                          [disabled]="event.invoice"
                          (click)="eventAttachFile.onAttachDocument()"
                        >
                          <mat-icon class="icon-mat" color="">{{
                            getIcon("ADD")
                          }}</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="">
                    <app-event-attached-files
                      [eventUUID]="event.uuid"
                      #eventAttachFile
                    ></app-event-attached-files>
                  </div>
                </div>

                <app-comment-section
                  *ngIf="event"
                  [commentsModel]="event"
                  [serviceAPIUrl]="'/v1/events/comments/' + event.uuid"
                >
                </app-comment-section>
              </div>
            </div>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab label="{{ 'EVENTS.DOCUMENTS' | translate }}">
        <ng-template matTabContent>
          <div>
            <div class="p-24 pb-64 pr-sm-72" fusePerfectScrollbar>
              <div class="event-content">
                <!-- button changelogs -->
                <div
                  *ngxPermissionsOnly="rolesService.ACCOUNTANT_ROLES"
                  class="mb-16"
                  fxLayoutAlign="center"
                  fxLayout="row"
                >
                  <button
                    mat-raised-button
                    color="accent"
                    [disabled]="loading"
                    class="accent ml-12"
                    *ngIf="
                      event &&
                      event.documents &&
                      event.documents.order &&
                      event.documents.order.type === 'ORDER' &&
                      event.documents.order.status === 'ORDER_CONFIRM' &&
                      event.documents.order.status !== 'ORDER_CANCEL' &&
                      moduleService.hasChangelogsModule()
                    "
                    (click)="openChangelogDialog()"
                  >
                    {{ "EVENTS.CHANGELOG" | translate }}
                  </button>
                </div>

                <!-- DOCUMENTS -->
                <div
                  *ngIf="event"
                  class="profile-box info-box general"
                  fxLayout="column"
                >
                  <div class="gt2-theme">
                    <div
                      class="primary header-create"
                      fxLayout="row"
                      fxLayoutAlign="space-between center"
                      style="height: 56px"
                    >
                      <div class="title">
                        {{ "EVENTS.DOCUMENTS" | translate }}
                      </div>

                      <div *ngIf="editable">
                        <div
                          *ngxPermissionsOnly="rolesService.SALE_ROLES"
                          class="gt2-light-green"
                          style="width: 60px; height: 56px"
                          fxLayout="row"
                          fxLayoutAlign="center center"
                        >
                          <button
                            *ngIf="!event.documents.order"
                            type="button"
                            matTooltip="{{ 'EVENTS.ADD_PROPOSAL' | translate }}"
                            mat-icon-button
                            class=""
                            (click)="onAddNewProposal()"
                          >
                            <mat-icon class="icon-mat" color="">{{
                              getIcon("ADD")
                            }}</mat-icon>
                          </button>
                          <button
                            *ngIf="event.documents.order"
                            type="button"
                            matTooltip="{{ 'EVENTS.ORDER_EXIST' | translate }}"
                            mat-icon-button
                            [disabled]="false"
                            color=""
                            class="fake-disabled-btn"
                          >
                            <mat-icon class="icon-mat" color="">{{
                              getIcon("ADD")
                            }}</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="" fxLayout="column">
                    <app-event-documents
                      [event]="event"
                      [(editable)]="editable"
                      (modelUpdatedEvent)="onComponentModelUpdated()"
                    ></app-event-documents>
                  </div>
                </div>

                <!-- File upload-->
                <div
                  *ngIf="
                    event &&
                    moduleService.modulesAreSet &&
                    moduleService.moduleExist('MODULE_EVENT_FILES')
                  "
                  class="profile-box info-box general mt-16"
                >
                  <div class="gt2-theme">
                    <div
                      class="primary header-create"
                      fxLayout="row"
                      fxLayoutAlign="space-between center"
                      style="height: 56px"
                    >
                      <div class="title">{{ "GENERIC.FILES" | translate }}</div>

                      <div
                        *ngxPermissionsOnly="rolesService.SALE_ROLES"
                        class="gt2-light-green"
                        style="width: 60px; height: 56px"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                      >
                        <button
                          type="button"
                          matTooltip="{{ 'GENERIC.ATTACH_FILE' | translate }}"
                          mat-icon-button
                          class=""
                          [disabled]="event.invoice"
                          (click)="eventAttachFile.onAttachDocument()"
                        >
                          <mat-icon class="icon-mat" color="">{{
                            getIcon("ADD")
                          }}</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="">
                    <app-event-attached-files
                      [eventUUID]="event.uuid"
                      #eventAttachFile
                    ></app-event-attached-files>
                  </div>
                </div>

                <app-comment-section
                  *ngIf="event"
                  [commentsModel]="event"
                  [serviceAPIUrl]="'/v1/events/comments/' + event.uuid"
                >
                </app-comment-section>
              </div>
            </div>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab
        *ngxPermissionsOnly="rolesService.SALE_ROLES"
        label="{{ 'EVENTS.ACCOUNTING' | translate }}"
      >
        <ng-template matTabContent>
          <div class="content">
            <!-- CENTER -->
            <div class="p-24 pb-56 pr-sm-72" fusePerfectScrollbar>
              <!-- CONTENT -->
              <div class="event-content">
                <!-- STATS -->
                <app-event-stats
                  [event]="event"
                  [(editable)]="editable"
                  (modelUpdatedEvent)="onComponentModelUpdated()"
                ></app-event-stats>

                <!-- DEPOSIT_REQUEST -->
                <div *ngxPermissionsOnly="rolesService.SALE_ROLES">
                  <div
                    *ngIf="event"
                    class="profile-box info-box general"
                    fxLayout="column"
                  >
                    <div class="gt2-theme">
                      <div
                        class="primary header-create"
                        fxLayout="row"
                        fxLayoutAlign="space-between center"
                        style="height: 56px"
                      >
                        <div class="title">
                          {{ "EVENTS.DEPOSIT_REQUEST" | translate }}
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div
                            *ngIf="
                              event &&
                              event.deposit_requests &&
                              event.deposit_requests.length > 0 &&
                              modulesService.modulesAreSet &&
                              (modulesService.hasParreiraReport() ||
                                modulesService.hasRPReport())
                            "
                            class="gt2-buttons-theme"
                          >
                            <button
                              mat-raised-button
                              color="primary"
                              class="mr-12"
                              [matMenuTriggerFor]="menuDeposits"
                            >
                              {{ "EVENTS.REPORT_RECEIVED" | translate }}
                            </button>

                            <mat-menu #menuDeposits="matMenu">
                              <button
                                mat-menu-item
                                (click)="
                                  menuReportsService.buildAndRunVo(
                                    ReportsEnum.REPORT_CLIENT_EVENT_DEPOSIT_REQUEST,
                                    DistributionModeEnum.FILE,
                                    ExportFormatEnum.PDF,
                                    ExportToEnum.PRINT,
                                    LocaleEnum.FR_CA,
                                    PaperEnum.A4,
                                    { uuid_event: eventUUDID }
                                  )
                                "
                              >
                                {{ "GENERIC.PRINT" | translate }}
                              </button>

                              <button
                                mat-menu-item
                                (click)="
                                  menuReportsService.buildAndRunVo(
                                    ReportsEnum.REPORT_CLIENT_EVENT_DEPOSIT_REQUEST,
                                    DistributionModeEnum.FILE,
                                    ExportFormatEnum.PDF,
                                    ExportToEnum.DOWNLOAD,
                                    LocaleEnum.FR_CA,
                                    PaperEnum.A4,
                                    { uuid_event: eventUUDID }
                                  )
                                "
                              >
                                {{ "GENERIC.DOWNLOAD" | translate }}
                              </button>

                              <!-- <button mat-menu-item 
                                                                            *ngIf="modulesService.modulesAreSet && modulesService.hasGABReport()" 
                                                                                (click)="menuReportsService.buildAndRunVo(
                                                                                        ReportsEnum.REPORT_CLIENT_EVENT_DEPOSIT_REQUEST,
                                                                                        DistributionModeEnum.FILE,
                                                                                        ExportFormatEnum.PDF,
                                                                                        ExportToEnum.PRINT,
                                                                                        LocaleEnum.FR_CA,
                                                                                        PaperEnum.A4,
                                                                                        { uuid_event: eventUUDID})">
                                                                        {{ "GENERIC.SEND" | translate }}
                                                                    </button> -->

                              <!-- <button mat-menu-item (click)="onPrintDeposits()">{{ "GENERIC.PRINT" | translate }}</button>
                                                                    <button mat-menu-item (click)="onDownloadDeposits()">{{ "GENERIC.DOWNLOAD" | translate }}</button> -->
                              <!-- <button mat-menu-item (click)="onSendDeposits()">{{ "GENERIC.SEND" | translate }}</button> -->
                            </mat-menu>
                          </div>

                          <div class="gt2-buttons-theme">
                            <button
                              mat-raised-button
                              color="primary"
                              class="mr-12"
                              (click)="onAddNewOkaDepositRequest()"
                            >
                              {{
                                "EVENTS.CREATE_OKA_DEPOSIT_REQUEST" | translate
                              }}
                            </button>
                          </div>

                          <div
                            *ngIf="
                              event && event.current_order_type !== 'INVOICE'
                            "
                          >
                            <div *ngIf="editable">
                              <div
                                *ngxPermissionsOnly="rolesService.SALE_ROLES"
                                class="gt2-light-green"
                                style="width: 60px; height: 56px"
                                fxLayout="row"
                                fxLayoutAlign="center center"
                              >
                                <button
                                  type="button"
                                  matTooltip="{{
                                    'EVENTS.CREATE_DEPOSIT_REQUEST' | translate
                                  }}"
                                  mat-icon-button
                                  class=""
                                  [disabled]="event.invoice"
                                  (click)="onAddNewDepositRequest()"
                                >
                                  <mat-icon class="icon-mat" color="">{{
                                    getIcon("ADD")
                                  }}</mat-icon>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="" fxLayout="column">
                      <app-event-deposit-request
                        [(event)]="event"
                        [(editable)]="editable"
                        (modelUpdatedEvent)="onComponentModelUpdated()"
                      ></app-event-deposit-request>
                    </div>
                    <div
                      fxLayout="row"
                      fxLayoutAlign="end center"
                      class="p-8 section-footer"
                    >
                      <div>
                        <span class="total-number">{{
                          event.footer_deposit_requests_label
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div *ngxPermissionsOnly="rolesService.ACCOUNTANT_ROLES">
                  <!-- Deposit In / Encaissement-->
                  <div
                    *ngIf="event"
                    class="profile-box info-box general"
                    fxLayout="column"
                  >
                    <div class="gt2-theme">
                      <div
                        class="primary header-create"
                        fxLayout="row"
                        fxLayoutAlign="space-between center"
                        style="height: 56px"
                      >
                        <div class="title">
                          {{ "EVENTS.DEPOSIT_IN" | translate }}
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div
                            *ngIf="
                              event &&
                              event.cashings &&
                              event.cashings.length > 0
                            "
                            class="gt2-buttons-theme"
                          >
                            <button
                              mat-raised-button
                              color="primary"
                              class="mr-12"
                              [matMenuTriggerFor]="menuReceipt"
                            >
                              {{ "EVENTS.REPORT_RECEIVED" | translate }}
                            </button>
                            <mat-menu #menuReceipt="matMenu">
                              <button mat-menu-item (click)="onPrintReceipt()">
                                {{ "GENERIC.PRINT" | translate }}
                              </button>
                              <button
                                mat-menu-item
                                (click)="onDownloadReceipt()"
                              >
                                {{ "GENERIC.DOWNLOAD" | translate }}
                              </button>
                              <button mat-menu-item (click)="onSendReceipt()">
                                {{ "GENERIC.SEND" | translate }}
                              </button>
                            </mat-menu>
                          </div>

                          <div *ngIf="editable">
                            <div
                              *ngxPermissionsOnly="
                                rolesService.ACCOUNTANT_ROLES
                              "
                              class="gt2-light-green"
                              style="width: 60px; height: 56px"
                              fxLayout="row"
                              fxLayoutAlign="center center"
                            >
                              <button
                                type="button"
                                matTooltip="{{
                                  'EVENTS.CREATE_DEPOSIT_IN' | translate
                                }}"
                                mat-icon-button
                                class=""
                                (click)="onAddNewCashing()"
                              >
                                <mat-icon class="icon-mat" color="">{{
                                  getIcon("ADD")
                                }}</mat-icon>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="" fxLayout="column">
                      <app-event-deposit-in
                        [event]="event"
                        [(editable)]="editable"
                        (modelUpdatedEvent)="onComponentModelUpdated()"
                      ></app-event-deposit-in>
                    </div>
                    <div
                      fxLayout="row"
                      fxLayoutAlign="end center"
                      class="p-8 section-footer"
                    >
                      <div>
                        <span class="total-number">{{
                          event.footer_cashings_label
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
    <!-- / TABs -->
  </div>
</div>

<!--HISTORY SIDEBAR BUTTON-->
<!-- <div *ngxPermissionsOnly="'GOD'">
    <button mat-icon-button class="warn mat-elevation-z2 historic-button" *fuseIfOnDom [@animate]="{value:'*', params:{delay:'300ms',scale:'.2'}}"
        (click)="toggleEventHistorySidebar('eventHistoryPanel')">
        <mat-icon>history</mat-icon>
    </button>
</div> -->

<!-- EDIT - BACK YELLOW ROUND BUTTON -->
<div *ngIf="event && editable" fxLayout="row" fxLayoutAlign="end start">
  <div *ngxPermissionsOnly="rolesService.TEAM_LEAD_ROLES">
    <div class="fixed-button">
      <!-- <button mat-fab class="gt2-edit-button" id="edit-event-button" [disabled]="loading" (click)="onEdit($event)"
                    *fuseIfOnDom [@animate]="{value:'*', params:{delay:'300ms',scale:'.2'}}">
                <mat-icon *ngIf="!editMode">{{ getIcon('EDIT_FAB') }}</mat-icon>
                <mat-icon *ngIf="editMode">{{ getIcon('UNDO') }}</mat-icon>
            </button> -->
      <button
        mat-fab
        #saveButton
        [ngClass]="editMode ? 'gt2-save-button' : 'gt2-edit-button'"
        id="edit-event-button"
        (click)="onEdit()"
        [disabled]="
          loading ||
          (editMode &&
            !eventInformationComponent?.form?.valid &&
            !eventInformationComponent?.dirty) ||
          (editMode &&
            (event.informations.event_time === '' ||
              event.informations.event_time === null))
        "
        *fuseIfOnDom
        [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }"
      >
        <mat-icon class="icon-mat-black" *ngIf="!editMode && !loading">{{
          getIcon("EDIT_FAB")
        }}</mat-icon>
        <mat-icon
          *ngIf="editMode && !loading && !saveButton.disabled"
          matTooltip="{{ 'GENERIC.SAVE' | translate }}"
          position="left"
          showDelay="900"
          class="icon-mat-black"
          hideDelay="900"
          >{{ getIcon("SAVE") }}</mat-icon
        >
        <mat-icon
          *ngIf="editMode && !loading && saveButton.disabled"
          matTooltip="{{ 'GENERIC.FORM_DIRTY_TIP' | translate }}"
          position="left"
          class="icon-mat-black"
          hideDelay="900"
          >{{ getIcon("SAVE") }}</mat-icon
        >
        <i [ngClass]="loading ? 'fa fa-spinner fa-spin' : ''"></i>
      </button>
    </div>
    <div class="fixed-button2">
      <button
        mat-fab
        *ngIf="editMode"
        matTooltip="{{ 'GENERIC.CANCEL' | translate }}"
        position="left"
        showDelay="900"
        hideDelay="900"
        id="cancel-edit-event-button"
        class="gt2-cancel-edit-button"
        (click)="onCancelEdit($event)"
        [@animate]="{ value: '*', params: { delay: '120ms', scale: '.2' } }"
      >
        <mat-icon class="icon-mat s-24">{{ getIcon("CANCEL") }}</mat-icon>
      </button>
    </div>
  </div>
</div>
<!-- / EDIT - BACK YELLOW ROUND BUTTON -->

<!-- SAVE BUTTON FOOTER -->
<!-- <div *ngIf="event && editMode" id="edit-mode-footer" fxFlex="100%" fxLayout="row" fxLayoutAlign="center center" class="p-12"
  [@animate]="{value:'*',params:{delay:'100ms',y:'120px'}}">
  <button mat-raised-button (click)="onSaveData()" color="warn" class="">
    {{ 'GENERIC.SAVE' | translate | uppercase }}
  </button>
</div> -->
