<div
  id="organization"
  class="page-layout simple left-sidebar inner-sidebar inner-scroll"
>
  <div class="gt2-org-theme">
    <div
      class="header accent pl-24 pr-24 h-80 mat-elevation-z1"
      fusePerfectScrollbar
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div fxLayout="row" fxLayoutAlign="start center">
        <button
          class="mr-0 mr-sm-16 white-fg"
          mat-icon-button
          (click)="onPageBack(location, router)"
        >
          <mat-icon class="icon-mat">arrow_back</mat-icon>
        </button>
        <div class="mr-12">
          <mat-icon
            class="icon-mat"
            fontSet="fa"
            fontIcon="{{ getIcon('ORG') }}"
            class="fa-2x"
          ></mat-icon>
        </div>

        <div fxLayout="column" fxLayoutAlign="center start">
          <div class="user-info" fxLayout="row" fxLayoutAlign="start center">
            <div
              class="organization-title"
              *fuseIfOnDom
              [@animate]="{
                value: '*',
                params: { delay: '100ms', x: '-25px' },
              }"
            >
              <div
                *ngIf="organization"
                fxLayout="row"
                fxLayoutAlign="start center"
                class=""
              >
                <div class="text-truncate">
                  {{ organization.name | uppercase }}
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="organization"
            fxLayout="row"
            fxLayoutAlign="start start"
            class=""
          >
            {{ "GENERIC.ORGANIZATION" | translate }}
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="end center">
        <button
          mat-button
          matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
          (click)="onOpenInNewTab()"
          class="mat-icon-button"
        >
          <mat-icon class="s-30 icon-mat">{{ getIcon("NEW_TAB") }}</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <!-- / HEADER -->

  <!-- SUB HEADER -->
  <div
    *ngIf="organization"
    fxLayout="row"
    fxLayoutAlign="end center"
    fusePerfectScrollbar
    class="primary mat-elevation-z1 py-8 gt2-sub-header content-subheader"
  ></div>
  <!-- / SUB HEADER -->

  <!--HISTORY SIDEBAR-->
  <!-- <fuse-sidebar name="productHistoryPanel" position="right" class="section-history-panel">
            <app-section-history-panel panelName="productHistoryPanel" section="products" sectionUUID="{{productID}}"></app-section-history-panel>
        </fuse-sidebar> -->

  <div class="content">
    <!--HISTORY SIDEBAR BUTTON-->
    <!-- <div *ngxPermissionsOnly="['GOD']">
                <button mat-icon-button class="warn mat-elevation-z2 historic-button" *fuseIfOnDom [@animate]="{value:'*', params:{delay:'300ms',scale:'.2'}}"
                    (click)="toggleHistoricSidebarOpen('productHistoryPanel')">
                    <mat-icon>history</mat-icon>
                </button>
            </div> -->

    <!-- CENTER -->
    <div class="center p-24 pb-56 pr-sm-72" #mainContainer fusePerfectScrollbar>
      <!-- CONTENT -->
      <div fxLayout="column" class="caterer-content">
        <!-- INFORMATION -->
        <div class="profile-box info-box general mt-12" fxLayout="column">
          <div class="gt2-org-theme">
            <header class="accent">
              <div class="title">
                {{ "GENERIC.GENERAL_INFORMATION" | translate }}
              </div>
            </header>
          </div>
          <div class="content">
            <div
              *ngIf="organization"
              fxLayout="row"
              fxLayout.xs="column"
              fxLayoutAlign="start start"
            >
              <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                <div class="title">{{ "GENERIC.NAME" | translate }}</div>
                <div class="info text-truncate">{{ organization.name }}</div>
                <div *ngIf="!organization.name" class="info empty-value"></div>
              </div>
              <div *ngIf="editMode" class="info-line" fxFlex="50%">
                <div class="info-editable">
                  <div [formGroup]="organizationForm" class="">
                    <mat-form-field class="form-input-container" fxFlex="100%">
                      <mat-label>{{ "GENERIC.NAME" | translate }}</mat-label>
                      <input
                        matInput
                        autocomplete="nope"
                        formControlName="name"
                        type="text"
                        [(ngModel)]="organizationName"
                        required
                      />
                      <mat-error
                        *ngIf="organizationForm.controls['name'].invalid"
                        >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                <div class="title">{{ "GENERIC.WEBSITE" | translate }}</div>
                <div class="info text-truncate">
                  {{ organization.website | displayHtmlLinkPipe }}
                </div>
                <div
                  *ngIf="!organization.website"
                  class="info empty-value"
                ></div>
              </div>
              <div *ngIf="editMode" class="info-line" fxFlex="50%">
                <div class="info-editable">
                  <div [formGroup]="organizationForm" class="">
                    <mat-form-field class="form-input-container" fxFlex="100%">
                      <mat-label>{{ "GENERIC.WEBSITE" | translate }}</mat-label>
                      <input
                        matInput
                        formControlName="website"
                        type="text"
                        autocomplete="nope"
                        [(ngModel)]="organization.website"
                      />
                      <mat-error
                        *ngIf="organizationForm.controls['website'].invalid"
                        >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>

            <div
              *ngIf="organization"
              fxLayout="row"
              fxLayout.xs="column"
              fxLayoutAlign="start start"
            >
              <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                <div class="title">{{ "GENERIC.FACEBOOK" | translate }}</div>
                <div class="info text-truncate">
                  {{ organization.facebook_url | displayHtmlLinkPipe }}
                </div>
                <div
                  *ngIf="!organization.facebook_url"
                  class="info empty-value"
                ></div>
              </div>
              <div *ngIf="editMode" class="info-line" fxFlex="50%">
                <div class="info-editable">
                  <div [formGroup]="organizationForm" class="">
                    <mat-form-field class="form-input-container" fxFlex="100%">
                      <mat-label>{{
                        "GENERIC.FACEBOOK" | translate
                      }}</mat-label>
                      <input
                        matInput
                        formControlName="facebook_url"
                        type="text"
                        autocomplete="nope"
                        [(ngModel)]="organization.facebook_url"
                      />
                      <mat-error
                        *ngIf="
                          organizationForm.controls['facebook_url'].invalid
                        "
                        >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                <div class="title">{{ "GENERIC.TWITTER" | translate }}</div>
                <div class="info text-truncate">
                  {{ organization.twitter_url | displayHtmlLinkPipe }}
                </div>
                <div
                  *ngIf="!organization.twitter_url"
                  class="info empty-value"
                ></div>
              </div>
              <div *ngIf="editMode" class="info-line" fxFlex="50%">
                <div class="info-editable">
                  <div [formGroup]="organizationForm" class="">
                    <mat-form-field class="form-input-container" fxFlex="100%">
                      <mat-label>{{ "GENERIC.TWITTER" | translate }}</mat-label>
                      <input
                        matInput
                        formControlName="twitter_url"
                        type="text"
                        autocomplete="nope"
                        [(ngModel)]="organization.twitter_url"
                      />
                      <mat-error
                        *ngIf="organizationForm.controls['twitter_url'].invalid"
                        >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>

            <div
              *ngIf="organization"
              fxLayout="row"
              fxLayout.xs="column"
              fxLayoutAlign="start start"
            >
              <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                <div class="title">{{ "GENERIC.LINKEDIN" | translate }}</div>
                <div class="info text-truncate">
                  {{ organization.linkedin_url | displayHtmlLinkPipe }}
                </div>
                <div
                  *ngIf="!organization.linkedin_url"
                  class="info empty-value"
                ></div>
              </div>
              <div *ngIf="editMode" class="info-line" fxFlex="50%">
                <div class="info-editable">
                  <div [formGroup]="organizationForm" class="">
                    <mat-form-field class="form-input-container" fxFlex="100%">
                      <mat-label>{{
                        "GENERIC.LINKEDIN" | translate
                      }}</mat-label>
                      <input
                        matInput
                        autocomplete="nope"
                        formControlName="linkedin_url"
                        type="text"
                        [(ngModel)]="organization.linkedin_url"
                      />
                      <mat-error
                        *ngIf="
                          organizationForm.controls['linkedin_url'].invalid
                        "
                        >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                <div class="title">{{ "GENERIC.INSTAGRAM" | translate }}</div>
                <div class="info text-truncate">
                  {{ organization.instagram_url | displayHtmlLinkPipe }}
                </div>
                <div
                  *ngIf="!organization.instagram_url"
                  class="info empty-value"
                ></div>
              </div>
              <div *ngIf="editMode" class="info-line" fxFlex="50%">
                <div class="info-editable">
                  <div [formGroup]="organizationForm" class="">
                    <mat-form-field class="form-input-container" fxFlex="100%">
                      <mat-label>{{
                        "GENERIC.INSTAGRAM" | translate
                      }}</mat-label>
                      <input
                        matInput
                        formControlName="instagram_url"
                        type="text"
                        autocomplete="nope"
                        [(ngModel)]="organization.instagram_url"
                      />
                      <mat-error
                        *ngIf="
                          organizationForm.controls['instagram_url'].invalid
                        "
                        >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>

            <div
              *ngIf="organization"
              fxLayout="row"
              fxLayout.xs="column"
              fxLayoutAlign="start start"
            >
              <div *ngIf="!editMode" class="info-line" fxFlex="50%">
                <div class="title">{{ "GENERIC.YOUTUBE" | translate }}</div>
                <div class="info text-truncate">
                  {{ organization.youtube_url | displayHtmlLinkPipe }}
                </div>
                <div
                  *ngIf="!organization.youtube_url"
                  class="info empty-value"
                ></div>
              </div>
              <div *ngIf="editMode" class="info-line" fxFlex="50%">
                <div class="info-editable">
                  <div [formGroup]="organizationForm" class="">
                    <mat-form-field class="form-input-container" fxFlex="100%">
                      <mat-label>{{ "GENERIC.YOUTUBE" | translate }}</mat-label>
                      <input
                        matInput
                        formControlName="youtube_url"
                        type="text"
                        autocomplete="nope"
                        [(ngModel)]="organization.youtube_url"
                      />
                      <mat-error
                        *ngIf="organizationForm.controls['youtube_url'].invalid"
                        >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div
              *ngIf="organization"
              fxLayout="row"
              fxLayout.xs="column"
              fxLayoutAlign="start start"
            >
              <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                <div class="title">{{ "ORG.SUPPORT_EMAIL" | translate }}</div>
                <div class="info text-truncate">
                  {{ organization.support_email }}
                </div>
                <div
                  *ngIf="!organization.support_email"
                  class="info empty-value"
                ></div>
              </div>
              <div *ngIf="editMode" class="info-line" fxFlex="50%">
                <div class="info-editable">
                  <div [formGroup]="organizationForm" class="">
                    <mat-form-field class="form-input-container" fxFlex="100%">
                      <mat-label>{{
                        "ORG.SUPPORT_EMAIL" | translate
                      }}</mat-label>
                      <input
                        matInput
                        formControlName="support_email"
                        type="text"
                        autocomplete="nope"
                        [(ngModel)]="organization.support_email"
                      />
                      <mat-error
                        *ngIf="
                          organizationForm.controls['support_email'].invalid
                        "
                        >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                <div class="title">{{ "ORG.SUPPORT_TEL" | translate }}</div>
                <div class="info text-truncate">
                  {{ organization.support_tel }}
                </div>
                <div
                  *ngIf="!organization.support_tel"
                  class="info empty-value"
                ></div>
              </div>
              <div *ngIf="editMode" class="info-line" fxFlex="50%">
                <div class="info-editable">
                  <div [formGroup]="organizationForm" class="">
                    <mat-form-field class="form-input-container" fxFlex="100%">
                      <mat-label>{{ "ORG.SUPPORT_TEL" | translate }}</mat-label>
                      <input
                        matInput
                        formControlName="support_tel"
                        type="text"
                        autocomplete="nope"
                        [(ngModel)]="organization.support_tel"
                      />
                      <mat-error
                        *ngIf="organizationForm.controls['support_tel'].invalid"
                        >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>

            <div
              *ngIf="organization"
              fxLayout="row"
              fxLayout.xs="column"
              fxLayoutAlign="start start"
            >
              <div *ngIf="!editMode" class="info-line" fxFlex="50%">
                <div class="title">{{ "ORG.SIGNATURE" | translate }}</div>
                <div class="info text-truncate">
                  {{ organization.signature }}
                </div>
                <div
                  *ngIf="!organization.signature"
                  class="info empty-value"
                ></div>
              </div>
              <div *ngIf="editMode" class="info-line" fxFlex="50%">
                <div class="info-editable">
                  <div [formGroup]="organizationForm" class="">
                    <mat-form-field class="form-input-container" fxFlex="100%">
                      <mat-label>{{ "ORG.SIGNATURE" | translate }}</mat-label>
                      <input
                        matInput
                        formControlName="signature"
                        type="text"
                        autocomplete="nope"
                        [(ngModel)]="organization.signature"
                      />
                      <mat-error
                        *ngIf="organizationForm.controls['signature'].invalid"
                        >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div
              *ngIf="editMode"
              fxLayout="row"
              fxLayoutAlign="end start"
              class="mt-16"
            >
              <button
                (click)="onManualSaveOrganization()"
                color="accent"
                class="save-btn"
                [disabled]="organizationForm.invalid"
                mat-raised-button
              >
                {{ "GENERIC.SAVE" | translate }}
              </button>
            </div>
          </div>
        </div>

        <!-- ADDRESS -->
        <div
          *ngIf="organization"
          class="profile-box info-box general mt-12"
          fxLayout="column"
        >
          <div class="gt2-org-theme">
            <header class="accent">
              <div class="title">{{ "GENERIC.ADDRESS" | translate }}</div>
            </header>
          </div>
          <div class="">
            <app-address
              #addressComponent
              [groupModel]="organization.address"
              [isVisible]="true"
              [editMode]="false"
              [useAutosave]="false"
              class=""
            ></app-address>
          </div>
        </div>

        <!-- CONTACT -->
        <div
          *ngIf="organization"
          class="profile-box info-box general mt-12"
          fxLayout="column"
        >
          <div class="gt2-org-theme">
            <header class="accent">
              <div class="title">{{ "GENERIC.CONTACT" | translate }}</div>
            </header>
          </div>
          <div class="">
            <app-contact
              #contactComponent
              [groupModel]="organization.contact"
              [editMode]="false"
              [useAutosave]="false"
              class=""
            >
            </app-contact>
          </div>
        </div>

        <!--<div *ngIf="organization && modulesService.modulesAreSet && modulesService.hasSage()" class="profile-box info-box general mt-12" fxLayout="column">
                    <div class="gt2-org-theme">
                    <header class="accent">
                        <div class="title">{{ 'GENERIC.SAGE' | translate }}</div>
                    </header>
                    </div>
                    <div class="content">
                        Sage - À venir
                    </div>
                </div>-->

        <!-- <div *ngIf="organization && modulesService.modulesAreSet && modulesService.hasGoogleCalendar()" class="profile-box info-box general mt-12" fxLayout="column">
                    <div class="gt2-org-theme">
                    <header class="accent">
                        <div class="title">{{ 'GENERIC.GOOGLE_CALENDER' | translate }}</div>
                    </header>
                    </div>
                    <div class="content">
                        Google Calendar - À venir
                    </div>
                </div>-->

        <!--<div *ngIf="organization && modulesService.modulesAreSet && modulesService.hasShopWeb()" class="profile-box info-box general mt-12" fxLayout="column">
                    <div class="gt2-org-theme">
                    <header class="accent">
                        <div class="title">{{ 'GENERIC.STRIPE' | translate }}</div>
                    </header>
                    </div>
                    <div class="content">
                        Stripe - À venir
                    </div>
                </div>-->

        <!--<div *ngIf="organization && modulesService.modulesAreSet && modulesService.hasShopWeb()" class="profile-box info-box general mt-12" fxLayout="column">
                    <div class="gt2-org-theme">
                    <header class="accent">
                        <div class="title">{{ 'GENERIC.SHOP_WEB' | translate }}</div>
                    </header>
                    </div>
                    <div class="content">
                        Web shop - À venir
                    </div>
                </div>-->
      </div>
      <!-- / CONTENT -->
    </div>
    <!-- / CENTER -->
  </div>
</div>

<div *ngIf="organization" fxLayout="row" fxLayoutAlign="end start">
  <div *ngxPermissionsOnly="rolesService.RH_ROLES">
    <div class="fixed-button">
      <button
        mat-fab
        class="gt2-edit-button"
        id="edit-organization-button"
        [disabled]="loading"
        (click)="onEdit($event)"
        *fuseIfOnDom
        [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }"
      >
        <mat-icon *ngIf="!editMode" class="icon-mat-black">{{
          getIcon("EDIT_FAB")
        }}</mat-icon>
        <mat-icon *ngIf="editMode" class="icon-mat-black">{{
          getIcon("UNDO")
        }}</mat-icon>
      </button>
    </div>
  </div>
</div>
