<div>
  <div fxLayout="row" fxLayoutAlign="space-between start" class="primary">
    <div class="p-12 history-title">{{ "HISTORY.HISTORY" | translate }}</div>
    <button
      mat-icon-button
      class="mt-4"
      (click)="toggleHistoricSidebarOpen(panelName)"
    >
      <mat-icon class="icon-mat">close</mat-icon>
    </button>
  </div>

  <mat-divider></mat-divider>

  <div
    *ngIf="loading"
    fxLayout="row"
    fxLayoutAlign="center center"
    style="height: 100%"
  >
    <mat-spinner [diameter]="32" class="my-48"></mat-spinner>
  </div>

  <div *ngIf="history && !loading" class="content" fusePerfectScrollbar>
    <div class="pb-8">
      <div
        *ngIf="history.length === 0"
        fxLayout="row"
        fxLayoutAlign="center center"
        class="px-12 pt-32"
      >
        <div>{{ "HISTORY.NO_HISTORY" | translate }}</div>
      </div>
      <div *ngFor="let item of history">
        <div class="py-4">
          <div fxLayout="row" fxLayoutAlign="start center" class="px-12">
            <div [ngSwitch]="item.audit_event">
              <mat-icon
                color="primary"
                *ngSwitchCase="'created'"
                class="s-16 history-icon blue-600-fg"
                >{{ getIcon("ADD_OUTLINE") }}</mat-icon
              >
              <mat-icon
                color="primary"
                *ngSwitchCase="'updated'"
                class="s-16 history-icon green-600-fg"
                >{{ getIcon("EDIT") }}</mat-icon
              >
              <mat-icon
                color="primary"
                *ngSwitchCase="'deleted'"
                class="s-16 history-icon red-600-fg"
                >{{ getIcon("DELETE") }}</mat-icon
              >
            </div>
            <div
              [ngClass]="
                item.audit_event === 'created'
                  ? 'history-item-created'
                  : 'history-item'
              "
              class="font-size-14 text-wrap"
            >
              {{ item.label }}
            </div>
          </div>
          <div class="history-subitem">
            <ul *ngIf="item.labels && item.labels.length > 0">
              <li *ngFor="let subitem of item.labels">
                <div class="py-4 text-wrap">{{ subitem }}</div>
              </li>
            </ul>
          </div>
          <!--                    TODO: this border-bottom does not work but do we want it?-->
          <!--                    <div style="border-bottom: 1px gray"></div>-->
        </div>
      </div>
    </div>
  </div>
</div>
