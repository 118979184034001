import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ItemUrlFinder } from '../../utils/item-url-finder';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { merge, fromEvent, of } from 'rxjs';
import {
  startWith,
  switchMap,
  catchError,
  map,
  debounceTime,
  distinctUntilChanged,
  tap,
} from 'rxjs/operators';
import { fuseAnimations } from '../../../../@fuse/animations/index';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { DatatableHelperService } from '../../../services/datatable-helper.service';
import { FileService } from '../../../services/file.service';
import { PrintService } from '../../../services/print.service';
import { ClientsService } from '../../api/clients.service';
import { CreateClientService } from '../../api/create-client.service';
import { EnterprisesService } from '../../api/enterprises.service';
import { Gt2ApiService } from '../../api/gt2-api.service';
import { CreateClientEnterpriseBranchComponent } from '../../components/dialogs/create-client-enterprise-branch/create-client-enterprise-branch.component';
import { CreateClientEnterpriseComponent } from '../../components/dialogs/create-client-enterprise/create-client-enterprise.component';
import { CreateClientComponent } from '../../components/dialogs/create-client/create-client.component';
import { CreateEnterpriseBranchComponent } from '../../components/dialogs/create-enterprise-branch/create-enterprise-branch.component';
import { CreateEnterpriseForClientComponent } from '../../components/dialogs/create-enterprise-for-client/create-enterprise-for-client.component';
import { CreateEnterpriseComponent } from '../../components/dialogs/create-enterprise/create-enterprise.component';
import { GenericAlertComponent } from '../../components/generic-alert/generic-alert.component';
import { AppRoutesService } from '../../services/app-routes.service';
import { GT2PageAbstract } from '../abstract/GT2PageAbstract';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { ApiRoutes } from '../../api/ApiRoutes';
import { Title } from '@angular/platform-browser';
import { ModulesService } from '../../services/modules.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
  animations: fuseAnimations,
})
export class ClientsComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  // static LS_CLIENT_LAST_SORT: string = 'LS_CLIENT_LAST_SORT';
  // static LS_CLIENT_LAST_SORT_DIRECTION: string = 'LS_CLIENT_LAST_SORT_DIRECTION';
  static LS_CLIENTS: string = 'CLIENTS';
  static LS_ENTERPRISES: string = 'ENTERPRISES';

  visible: boolean = true;
  confirmDialogRef: any;

  // clients
  // /v1/clients?with=enterprise&orderBy=enterprise%7Cname&sortedBy=desc&page=1&limit=25
  clientsDisplayedColumns = [
    'first_name',
    'last_name',
    'client_number',
    'enterprise',
    'newTab',
  ];
  @ViewChild('clientsPaginator') clientsPaginator!: MatPaginator;
  @ViewChild('clientsSort') clientsSort!: MatSort;
  @ViewChild('clientsTable') clientsTable!: MatTable<any>;
  @ViewChild('clientsFilter') clientsFilter!: ElementRef;
  @ViewChild('mainContainer') mainContainer!: ElementRef;
  clientsFilterValue: string = '';
  clientsDataSource = new MatTableDataSource([]);
  clientsResultsLength: number = 0;
  isClientsLoadingResults: boolean = false;
  isClientsRateLimitReached: boolean = false;
  clientsParentsColor: Object = {};

  // enterprises
  enterprisesDisplayedColumns = ['name', 'enterprise_number', 'newTab'];
  @ViewChild('enterprisesPaginator') enterprisesPaginator!: MatPaginator;
  @ViewChild('enterprisesSort') enterprisesSort!: MatSort;
  @ViewChild('enterprisesTable') enterprisesTable!: MatTable<any>;
  @ViewChild('enterprisesFilter') enterprisesFilter!: ElementRef;
  enterprisesFilterValue: string = '';
  enterprisesDataSource = new MatTableDataSource();
  enterprisesResultsLength = 0;
  isEnterprisesLoadingResults = false;
  isEnterprisesRateLimitReached = false;

  //
  isFirstLoad: boolean = true;
  viewSize: string = '50%';

  constructor(
    private clientsService: ClientsService,
    private enterprisesService: EnterprisesService,
    private createClientService: CreateClientService,
    public dialog: MatDialog,
    public datatableHelperService: DatatableHelperService,
    public router: Router,
    private logger: NGXLogger,
    public print: PrintService,
    public fileService: FileService,
    private toastr: ToastrService,
    public translate: TranslateService,
    private api: Gt2ApiService,
    public appRoutesService: AppRoutesService,
    private titleService: Title,
    private translationLoader: FuseTranslationLoaderService,
    private modulesService: ModulesService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    ////this.logger.info("ClientsComponent.constructor()");
  }

  ngOnInit() {
    ////this.logger.info("ClientsComponent.ngOnInit()");
    //
    //this.titleService.setTitle(this.translate.instant("GENERIC.CLIENTS_ENTERPRISE"));
  }

  ngAfterViewInit() {
    //this.logger.info("ClientsComponent.ngAfterViewInit()");
    this.modulesService.modulesSet.subscribe({
      next: (data) => {
        //this.logger.info("ClientsComponent.ngAfterViewInit.modulesSet() data: " + JSON.stringify(data));
        if (data) {
          this.setUpClientsDataTable();
          this.setUpEnterprisesDataTable();
        }
      },
    });
    // this.setUpClientsDataTable();
    // this.setUpEnterprisesDataTable();
  }

  ngOnDestroy() {}

  public clientsSyncPrimaryPaginator(event: PageEvent) {
    //this.logger.info("clientsSyncPrimaryPaginator()");
    this.clientsPaginator.pageIndex = event.pageIndex;
    this.clientsPaginator.pageSize = event.pageSize;
    this.clientsPaginator?.page.emit(event);
  }

  public enterprisesSyncPrimaryPaginator(event: PageEvent) {
    //this.logger.info("enterprisesSyncPrimaryPaginator()");
    this.enterprisesPaginator.pageIndex = event.pageIndex;
    this.enterprisesPaginator.pageSize = event.pageSize;
    this.enterprisesPaginator?.page.emit(event);
  }

  public setUpClientsDataTable(): void {
    if (!this.clientsSort) {
      this.logger.error('Clients.setUpClientsDataTable() -> NO clientsSort');
      return;
    }

    this.datatableHelperService.setSort(
      this.clientsSort,
      ClientsComponent.LS_CLIENTS,
      'first_name',
      'asc',
    );

    this.clientsSort.sortChange.subscribe({
      next: () => {
        //this.logger.info("1. clientsSort exist! this.clientsSort.direction: " + this.clientsSort.direction);
        this.clientsPaginator.pageIndex = 0;
      },
    });

    merge(this.clientsSort.sortChange, this.clientsPaginator.page)
      .pipe(startWith(null))
      .pipe(
        switchMap(() => {
          setTimeout(() => {
            this.isClientsLoadingResults = true;
            // //this.logger.info('2 this.clientsSort.direction: ' + this.clientsSort.direction);
          });

          // //this.logger.info('3 this.clientsSort.direction: ' + this.clientsSort.direction);
          // localStorage.setItem(ClientsComponent.LS_CLIENT_LAST_SORT, this.clientsSort.active);
          // localStorage.setItem(ClientsComponent.LS_CLIENT_LAST_SORT_DIRECTION, this.clientsSort.direction);
          this.datatableHelperService.updateSort(
            ClientsComponent.LS_CLIENTS,
            this.clientsSort.active,
            this.clientsSort.direction,
          );
          console.log(this.clientsSort);
          return this.clientsService.getClientsWithPagination(
            this.clientsSort.active,
            this.clientsSort.direction,
            this.clientsPaginator.pageIndex,
            this.clientsPaginator.pageSize,
            this.clientsFilterValue,
          );
        }),
      )
      .pipe(
        map((data) => {
          // Flip flag to show that loading has finished.
          this.isClientsLoadingResults = false;
          this.isClientsRateLimitReached = false;
          this.clientsResultsLength = data.meta.pagination.total;
          this.isFirstLoad = false;
          this.mainContainer.nativeElement.scrollTop = 0;
          return data.data;
        }),
      )
      .pipe(
        catchError(() => {
          setTimeout(() => {
            this.isClientsLoadingResults = false;
            this.isClientsRateLimitReached = true;
          });
          return of([]);
        }),
      )
      .subscribe({ next: (data: any) => (this.clientsDataSource.data = data) });

    fromEvent(this.clientsFilter?.nativeElement, 'keyup')
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => {
          if (
            this.clientsFilter?.nativeElement.value.length > 1 ||
            this.clientsFilter?.nativeElement.value === ''
          ) {
            this.clientsPaginator.pageIndex = 0;
            this.reloadClientsTable();
          }
        }),
      )
      .subscribe();
  }

  public setUpEnterprisesDataTable(): void {
    this.enterprisesSort.sortChange.subscribe({
      next: () => {
        this.enterprisesPaginator.pageIndex = 0;
      },
    });

    this.datatableHelperService.setSort(
      this.enterprisesSort,
      ClientsComponent.LS_ENTERPRISES,
      'name',
      'asc',
    );

    this.enterprisesSort.sortChange.subscribe({
      next: () => {
        this.enterprisesPaginator.pageIndex = 0;
      },
    });

    // Observable.merge(this.enterprisesSort.sortChange, this.enterprisesPaginator.page)

    merge(this.enterprisesSort.sortChange, this.enterprisesPaginator.page)
      .pipe(startWith(null))
      .pipe(
        switchMap(() => {
          setTimeout(() => {
            this.isEnterprisesLoadingResults = true;
          });
          this.datatableHelperService.updateSort(
            ClientsComponent.LS_ENTERPRISES,
            this.enterprisesSort.active,
            this.enterprisesSort.direction,
          );

          return this.enterprisesService.getEnterprisesWithPagination(
            this.enterprisesSort.active,
            this.enterprisesSort.direction,
            this.enterprisesPaginator.pageIndex,
            this.enterprisesPaginator.pageSize,
            this.enterprisesFilterValue,
          );
        }),
      )
      .pipe(
        map((data) => {
          // Flip flag to show that loading has finished.
          this.isEnterprisesLoadingResults = false;
          this.isEnterprisesRateLimitReached = false;
          this.enterprisesResultsLength = data.meta.pagination.total;
          this.isFirstLoad = false;
          return data.data;
        }),
      )
      .pipe(
        catchError(() => {
          setTimeout(() => {
            this.isEnterprisesLoadingResults = false;
            this.isEnterprisesRateLimitReached = true;
          });
          return of([]);
        }),
      )
      .subscribe({ next: (data) => (this.enterprisesDataSource.data = data) });

    fromEvent(this.enterprisesFilter?.nativeElement, 'keyup')
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => {
          if (
            this.enterprisesFilter?.nativeElement.value.length > 1 ||
            this.enterprisesFilter?.nativeElement.value === ''
          ) {
            this.enterprisesPaginator.pageIndex = 0;
            this.reloadEnterprisesTable();
          }
        }),
      )
      .subscribe();
  }

  public reloadTables(): void {
    // --o Hack to force reload of tables
    this.reloadClientsTable();
    this.reloadEnterprisesTable();
  }

  public reloadClientsTable(): void {
    this.clientsPaginator?._changePageSize(this.clientsPaginator.pageSize);
  }

  public reloadEnterprisesTable(): void {
    this.enterprisesPaginator?._changePageSize(
      this.enterprisesPaginator.pageSize,
    );
  }

  // public mapParentsColors(data: ClientsModelData[]): void
  // {
  //     // TODO: after demo
  //     // //this.logger.info("--> " + data);
  //     // let i: number = 0;
  //     // const length: number = data.length;
  //     // for ( i; i < length; ++i )
  //     // {
  //     //     if( data[i].enterprise )
  //     //     {
  //     //         if( this.clientsParentsColor[])
  //     //     }
  //     // }
  // }

  public clearSearch(): void {
    this.clientsFilterValue = '';
    this.clientsPaginator.pageIndex = 0;
    this.clientsPaginator?._changePageSize(this.clientsPaginator.pageSize);
  }

  public clearEnterpriseSearch(): void {
    this.enterprisesFilterValue = '';
    this.enterprisesPaginator.pageIndex = 0;
    this.enterprisesPaginator?._changePageSize(
      this.enterprisesPaginator.pageSize,
    );
  }

  public onDeleteClient(uuid: string): void {
    // //this.logger.info("ClientsComponent.onDeleteClient()");

    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.clientsService.deleteClient(uuid).subscribe({
            next: (response) => {
              // //this.logger.info("ClientsComponent.onDeleteClient() -> SUCCESS: " + JSON.stringify(response));
              this.toastr.success(
                this.translate.instant('GENERIC.DELETE_SUCCESS'),
              );
              this.reloadClientsTable();
            },
            error: (error) => {
              this.logger.error(
                'ClientsComponent.onDeleteClient() -> ERROR: ' +
                  JSON.stringify(error),
              );
              this.handleAPIError(
                error,
                this.dialog,
                this.toastr,
                this.translate.instant('GENERIC.ERRORS.GENERIC'),
              );
              // this.toastr.error('', this.translate.instant("GENERIC.ERRORS.GENERIC"));
            },
          });
        }
        this.confirmDialogRef = null;
      },
    });
  }

  public onCreateClient(): void {
    this.logger.debug('ClientComponent.onCreateClient()');

    this.dialogRef = this.dialog.open(CreateClientComponent, {
      width: '340px',
      disableClose: false,
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          // //this.logger.info("POP UP Closed!");
          // this.reloadClientsTable();
          // this.router.navigateByUrl( "/" + AppRoutes.CLIENTS + "/" + result.slug + "/" + result.uuid);
          this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
        }
        this.dialogRef = null;
      },
    });
  }

  public onCreateEnterprise(): void {
    this.logger.debug('ClientComponent.onCreateEnterprise()');

    this.dialogRef = this.dialog.open(CreateEnterpriseComponent, {
      width: '340px',
      disableClose: false,
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          // //this.logger.info("POP UP Closed! -> " + JSON.stringify(result));
          // this.reloadClientsTable();
          // this.router.navigateByUrl( "/" + AppRoutes.ENTERPRISES + "/" + result.slug + "/" + result.uuid);
          this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
        }
        this.dialogRef = null;
      },
    });
  }

  public onCreateClientAndEnterprise(): void {
    this.logger.debug('ClientComponent.onCreateClientAndEnterprise()');
    this.dialogRef = this.dialog.open(CreateClientEnterpriseComponent, {
      width: '340px',
      disableClose: false,
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result && result.data) {
          // //this.logger.info("POP UP Closed!");
          // this.reloadTables();
          // this.router.navigateByUrl("/" + AppRoutes.CLIENTS + "/" + result.data.slug + "/" + result.data.uuid);
          this.router.navigateByUrl(ItemUrlFinder.getItemURL(result.data));
        }
        this.dialogRef = null;
      },
    });
  }

  public onCreateClientEnterpriseAndBranch(): void {
    this.logger.debug('ClientComponent.onCreateClientEnterpriseAndBranch()');
    this.dialogRef = this.dialog.open(CreateClientEnterpriseBranchComponent, {
      width: '340px',
      disableClose: false,
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          // //this.logger.info("POP UP Closed!");
          this.reloadTables();
        }
        this.dialogRef = null;
      },
    });
  }

  public onAddEnterpriseToClient(uuid: string): void {
    this.logger.debug('ClientsComponent.onAddEnterpriseToClient()');
    this.dialogRef = this.dialog.open(CreateEnterpriseForClientComponent, {
      width: '340px',
      disableClose: false,
      data: { uuid: uuid },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          // //this.logger.info("POP UP Closed! -> result: " + result);
          this.reloadTables();
        }
        this.dialogRef = null;
      },
    });
  }

  // TODO later, NICE to Have
  public onAttachClientToEnterprise(uuid: string): void {
    // //this.logger.info("ClientsComponent.onAttachClientToEnterprise()");
    // this.clientsService.attachClientToEnterprise
    this.dialogRef = this.dialog.open(CreateClientComponent, {
      width: '340px',
      disableClose: false,
      data: { uuid: uuid },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          // //this.logger.info("POP UP Closed!");
          this.reloadTables();
        }
        this.dialogRef = null;
      },
    });
  }

  public onAddClientToBranch(uuid: string): void {
    // //this.logger.info("ClientsComponent.onAddClientToBranch()");
    this.dialogRef = this.dialog.open(CreateClientComponent, {
      width: '340px',
      disableClose: false,
      data: {
        type: CreateClientComponent.CREATE_FOR_BRANCH,
        uuid: uuid,
      },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          // //this.logger.info("POP UP Closed!");
          this.reloadTables();
        }
        this.dialogRef = null;
      },
    });
  }

  public onAddClientToEnterprise(uuid: string): void {
    // //this.logger.info("ClientsComponent.onAddClientToEnterprise()");
    this.dialogRef = this.dialog.open(CreateClientComponent, {
      width: '340px',
      disableClose: false,
      data: {
        type: CreateClientComponent.CREATE_FOR_ENTERPRISE,
        uuid: uuid,
      },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          // //this.logger.info("POP UP Closed!");
          this.reloadTables();
        }
        this.dialogRef = null;
      },
    });
  }

  public onAddBranchToEnterprise(uuid: string): void {
    // //this.logger.info("ClientsComponent.onAddBranchToEnterprise()");
    this.dialogRef = this.dialog.open(CreateEnterpriseBranchComponent, {
      width: '340px',
      disableClose: false,
      data: {
        type: CreateEnterpriseBranchComponent.CREATE_BRANCH_FOR_ENTERPRISE,
        uuid: uuid,
      },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          // //this.logger.info("POP UP Closed!");
          this.reloadTables();
        }
        this.dialogRef = null;
      },
    });
  }

  public onOpenInNewTab(): void {
    window.open(this.router.url, '_blank');
  }

  public doPrint(url: string): void {
    this.print.printHTML(url).subscribe({
      next: (response: any) => {
        // //this.logger.info("ClientsComponent.doPrint() -> SUCCESS: " + JSON.stringify(response));
      },
      error: (error: any) => {
        this.logger.error(
          'ClientsComponent.doPrint() -> ERROR: ' + JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public doDownload(url: string): void {
    this.fileService.downloadFile(url).subscribe({
      next: (response: any) => {
        // //this.logger.info("ClientsComponent.doDownload() -> SUCCESS: " + JSON.stringify(response));
      },
      error: (error: any) => {
        this.logger.error(
          'ClientsComponent.doDownload() -> ERROR: ' + JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public onPrint(section: string): void {
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PRINT,
      section,
      'fr-CA',
    );
    // this.print.printHTML(url);
    this.doPrint(url);
  }

  public onDownloadPDF(section: string): void {
    // //this.logger.info("onDownloadPDF() ");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PDF,
      section,
      'fr-CA',
    );
    // this.fileService.downloadFile(url);
    this.doDownload(url);
  }

  public onDownloadVCard(section: string): void {
    // //this.logger.info("onDownloadVCard() ");
    // const url: string = this.api.createReportURL(ApiRoutes.REPORT_VCARD, section), "fr-CA";
    // window.location.href = url;
  }

  public onDownloadXLS(section: string): void {
    // //this.logger.info("onDownloadVCard() ");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_XLS,
      section,
      'fr-CA',
    );
    // this.fileService.downloadFile(url);
    this.doDownload(url);
  }

  public onOpenItemInNewTab(item: any): void {
    window.open(ItemUrlFinder.getItemURL(item), '_blank');
  }
}
