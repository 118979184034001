import { RoomsComponent } from './../rooms/rooms.component';
import { RichTextsValidator } from './../../utils/RichTextsValidator';
import { ICanDeactivateComponent } from './../../gards/can-deactivate-guard.service';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { VenuesService } from '../venues/venues.service';
import { locale as english } from '../venues/i18n/en-CA';
import { locale as french } from '../venues/i18n/fr-CA';
import { VenuesModelData } from '../../api/models/VenuesModel';
import { AddressComponent } from '../../components/address/address.component';
import { ContactComponent } from '../../components/contact/contact.component';
import { Editor, EditorModule } from 'primeng/editor';
import { MatDialog } from '@angular/material/dialog';
import { Gt2ApiService } from '../../api/gt2-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PrintService } from '../../../services/print.service';
import { FileService } from '../../../services/file.service';
import { NGXLogger } from 'ngx-logger';
import { GT2FormPageAbstract } from '../abstract/GT2FormPageAbstract';
import { ApiRoutes } from '../../api/ApiRoutes';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { fuseAnimations } from '../../../../@fuse/animations/index';
import { CommonModule, Location } from '@angular/common';
import { RolesService } from '../../services/roles.service';
import { Settings } from '../../settings/settings';
import { GenericAlertComponent } from '../../components/generic-alert/generic-alert.component';
import { ToastrService } from 'ngx-toastr';
import { AppRoutes } from '../../const/AppRoutes';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { FuseSidebarService } from '../../../../@fuse/components/sidebar/sidebar.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatIconModule } from '@angular/material/icon';
import { TooltipModule } from 'primeng/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseSidebarModule } from '../../../../@fuse/components';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RoomsModule } from '../rooms/rooms.module';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { LocalizationConfig } from '../../../localization/LocalizationConfig';
import { CommentSectionComponent } from '../../components/comment-section/comment-section.component';
import { CreatedUpdatedAtComponent } from '../../components/created-updated-at/created-updated-at.component';
import { SectionHistoryPanelComponent } from '../../components/section-history-panel/section-history-panel.component';
import { ContactService } from '../../components/contact/contact.service';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';

@Component({
  selector: 'app-venue',
  standalone: true,
  templateUrl: './venue.component.html',
  styleUrls: ['./venue.component.scss'],
  imports: [
    TranslateModule,
    MatIconModule,
    TooltipModule,
    ReactiveFormsModule,
    EditorModule,
    FormsModule,
    MatButtonModule,
    NgxPermissionsModule,
    CommonModule,
    FlexLayoutModule,
    MatInputModule,
    MatFormFieldModule,
    FuseDirectivesModule,
    MatTooltipModule,
    FuseSidebarModule,
    SectionHistoryPanelComponent,
    MatCheckboxModule,
    RoomsModule,
    MatTabsModule,
    ContactComponent,
    AddressComponent,
    CommentSectionComponent,
    CreatedUpdatedAtComponent,
  ],
  providers: [ContactService],
  animations: fuseAnimations,
})
export class VenueComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy, ICanDeactivateComponent
{
  venueID!: string;
  venue!: VenuesModelData;
  loading: boolean = false;
  editMode: boolean = false;
  editable: boolean = true;
  @ViewChild('addressComponent')
  addressComponent!: AddressComponent;
  @ViewChild('contactComponent')
  contactComponent!: ContactComponent;
  @ViewChild('noteEditor') noteEditor!: Editor;
  @ViewChild('approom') approom!: RoomsComponent;
  form: UntypedFormGroup;
  isVisible: boolean = true;
  confirmDialogRef: any;
  noteEditordebounceTimeValue: number = 1500;
  noteEditorSubscription!: Subscription;
  roomsServiceURL!: string;
  noteInvalid: boolean = false;
  isSmallScreen!: boolean;

  richTextsValidator!: RichTextsValidator;

  constructor(
    private venuesService: VenuesService,
    private formBuilder: UntypedFormBuilder,
    private translationLoader: FuseTranslationLoaderService,
    public dialog: MatDialog,
    public api: Gt2ApiService,
    public translate: TranslateService,
    public route: ActivatedRoute,
    public router: Router,
    public location: Location,
    private titleService: Title,
    private toastr: ToastrService,
    public print: PrintService,
    public fileService: FileService,
    public rolesService: RolesService,
    private _fuseSidebarService: FuseSidebarService,
    private logger: NGXLogger,
    private breakpointObserver: BreakpointObserver,
  ) {
    super();
    // Initialisé la langue
    const local_lang =
      localStorage.getItem(LocalizationConfig.LOCAL_STORED_LANG_ID) ||
      LocalizationConfig.DEFAULT_LANGUAGE;

    // Ajouter les traductions locales
    if (local_lang === 'en-CA')
      translate.setTranslation(english.lang, english.data, true);
    else translate.setTranslation(french.lang, french.data, true);

    // Définir la langue à utiliser
    translate.setDefaultLang(LocalizationConfig.DEFAULT_LANGUAGE);
    translate.use(local_lang);

    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      contact_name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
        ],
      ],
    });
  }

  ngOnInit() {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe({
      next: (result) => {
        this.isSmallScreen = result.matches;
      },
    });
    //this.titleService.setTitle(this.translate.instant("VENUES.VENUE"));

    this.route.params.subscribe({
      next: (params: any) => {
        this.venueID = params.id;
        this.loading = true;
        this.roomsServiceURL = this.api.createUrl(
          ApiRoutes.GET_VENUE_ROOMS + this.venueID,
        );
        this.loadVenue();
      },
    });

    this.richTextsValidator = new RichTextsValidator();
  }

  public initRichText(): void {
    this.richTextsValidator?.registerRichText(this.noteEditor, 'noteEditor');
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    if (this.richTextsValidator) {
      this.richTextsValidator.destroy();
    }
  }

  public loadVenue(): void {
    this.loading = true;
    this.venuesService.getVenue(this.venueID).subscribe({
      next: (response) => {
        // //this.logger.info("VenueComponent.loadVenue() -> SUCCESS: " + JSON.stringify(response));
        const isFirstLoad: boolean =
          this.venue === null || this.venue == undefined;

        this.venue = response.data;
        this.loading = false;
        this.editable = !this.venue?.isArchive;
        //this.titleService.setTitle(this.translate.instant("VENUES.VENUE") + " " + this.venue.name);

        this.updateAppRooms();
        //
        if (isFirstLoad) {
          setTimeout(() => {
            this.initRichText();
          }, 500);
        }
      },
      error: (error) => {
        this.logger.error(
          'VenueComponent.loadVenue() -> ERROR: ' + JSON.stringify(error),
        );
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public updateAppRooms(): void {
    setTimeout(() => {
      //this.logger.info("approom: " + this.approom);
      if (this.approom) {
        this.approom.reloadData();
      }
    }, 100);
  }

  public deleteVenue(): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });
    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.venuesService.deleteVenue(this.venue).subscribe({
            next: (response) => {
              // //this.logger.info("ProductComponent.deleteEnterprise() -> SUCCESS: " + JSON.stringify(response));
              this.toastr.success(
                '',
                this.translate.instant('GENERIC.DELETE_SUCCESS'),
              );
              this.router.navigateByUrl('/' + AppRoutes.VENUES);
            },
            error: (error) => {
              this.logger.error(
                'ProductComponent.deleteClient() -> ERROR: ' +
                  JSON.stringify(error),
              );
              // this.toastr.error('', this.translate.instant("GENERIC.ERRORS.GENERIC"));
              this.loading = false;
              this.handleAPIError(
                error,
                this.dialog,
                this.toastr,
                this.translate.instant('GENERIC.ERRORS.GENERIC'),
              );
            },
          });
        }
      },
    });
  }

  public onEdit($event: any): void {
    this.editMode = !this.editMode;

    ////this.logger.info("VenueComponent.onEdit() -> : addressComponent: " + this.addressComponent);

    ////this.logger.info("VenueComponent.onEdit() -> : contactComponent: " + this.contactComponent);

    if (this.addressComponent) {
      this.addressComponent.editMode = this.editMode;
      if (!this.editMode) {
        this.addressComponent.onManualSave();
      }
    }

    if (this.contactComponent) {
      this.contactComponent.editMode = this.editMode;
      if (!this.editMode) {
        this.contactComponent.onManualSave();
      }
    }

    ////this.logger.info("VenueComponent.onEdit() -> : addressComponent.editMode : " + this.addressComponent.editMode);

    ////this.logger.info("VenueComponent.onEdit() -> : contactComponent.editMode : " + this.contactComponent.editMode);

    if (this.noteEditor) {
      this.noteEditor.readonly = !this.editMode;
    }

    if (this.editMode) {
      //
    } else {
      this.venuesService.updateVenue(this.venue, this.venueID).subscribe({
        next: (response) => {
          // //this.logger.info("VenueComponent.autosaveDataChange() -> SUCCESS: " + JSON.stringify(response));
          this.loading = false;
          this.form.markAsPristine();
          this.form.markAsUntouched();
          this.loadVenue();
          if (this.addressComponent && this.addressComponent.form.dirty) {
            this.addressComponent.form.markAsPristine();
            this.addressComponent.form.markAsUntouched();
          }

          if (this.contactComponent && this.contactComponent.form.dirty) {
            this.contactComponent.form.markAsPristine();
            this.contactComponent.form.markAsUntouched();
          }

          if (this.richTextsValidator) {
            this.richTextsValidator.clean();
          }
        },
        error: (error) => {
          this.logger.error(
            'VenueComponent.autosaveDataChange() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          this.handleAPIError(error, this.dialog, null, null);
        },
      });
    }
  }

  public canDeactivate(): boolean {
    if (this.addressComponent && this.addressComponent.form.dirty) {
      return false;
    }

    if (this.contactComponent && this.contactComponent.form.dirty) {
      return false;
    }
    return !this.form.dirty && !this.richTextsValidator?.dirty();
  }

  public onCancelEdit(event: any): void {
    this.editMode = false;

    if (this.noteEditor) {
      this.noteEditor.readonly = false;
    }

    if (this.addressComponent) {
      this.addressComponent.editMode = false;
    }

    if (this.contactComponent) {
      this.contactComponent.editMode = false;
    }

    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.loadVenue();
  }

  public override autosaveDataChange(data: any): void {}

  public doPrint(url: string): void {
    this.print.printHTML(url).subscribe({
      next: (response: any) => {
        // //this.logger.info("VenueComponent.doPrint() -> SUCCESS: " + JSON.stringify(response));
      },
      error: (error: any) => {
        this.logger.error(
          'VenueComponent.doPrint() -> ERROR: ' + JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public doDownload(url: string): void {
    this.fileService.downloadFile(url).subscribe({
      next: (response: any) => {
        // //this.logger.info("VenueComponent.doDownload() -> SUCCESS: " + JSON.stringify(response));
      },
      error: (error: any) => {
        this.logger.error(
          'VenueComponent.doDownload() -> ERROR: ' + JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public onPrint(): void {
    const url: string =
      this.api.createUrl('/reports/places/html/') + this.venue?.uuid;
    // this.print.printHTML(url);
    this.doPrint(url);
  }

  public onOpenInNewTab(): void {
    window.open(this.router.url, '_blank');
  }

  public onDownloadPDF(): void {
    const url: string =
      this.api.createUrl('/reports/places/pdf/') + this.venue?.uuid;
    // const fileName: string = this.fileService.createFileName(null, "venues", "pdf");
    // this.fileService.downloadFile(url, fileName);
    this.doDownload(url);
  }

  public onDownloadVCard(): void {
    // //this.logger.info("onDownloadVCard() ");
    const url: string =
      this.api.createUrl('/reports/places/vcard/') + this.venue?.uuid; // environment.api.protocol + environment.api.domain + '/venues/vcard/' + this.venueID;
    window.location.href = url;
  }

  public onVenueIsInternalChange(event: any): void {
    // //this.logger.info("VenueComponent.onVenueIsInternalChange() -> internal: " + this.venue.internal);
    this.autosaveDataChange({ internal: this.venue?.internal });
  }

  public toggleHistoricSidebarOpen(key: string): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }
}
