import { NGXLogger } from 'ngx-logger';
import { Settings } from './../../settings/settings';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Editor, EditorModule } from 'primeng/editor';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-rich-text-locale',
  templateUrl: './rich-text-locale.component.html',
  styleUrls: ['./rich-text-locale.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    Gt2PrimengModule,
    MatInputModule,
    MatButtonModule,
    TranslateModule,
    EditorModule,
    FormsModule,
  ],
})
export class RichTextLocaleComponent implements OnInit, AfterViewInit {
  @ViewChild('richTextEditor') richTextEditor!: Editor;
  // @Input() test: any;
  @Input() richText: any;
  @Input() localKey!: string;
  @Input() maxLength: number = Settings.inputMaxCharLong;
  @Input() charCountLocal: string = 'SHOPS.CHAR_COUNT_MESSAGE';
  @Input() showSaveButton: boolean = true;
  @Input() showClearButton: boolean = true;
  @Input() invalid: boolean = false;
  @Input() applyFocus: boolean = false;
  @Input() editMode: boolean = true;
  @Input() allowColors: boolean = true;
  @Output() saveEvent: EventEmitter<string> = new EventEmitter();
  @Output() clearEvent: EventEmitter<any> = new EventEmitter();
  @Output() changeEvent: EventEmitter<RichTextChangeModel> = new EventEmitter();
  currentLength: number = 0;
  richTextEditorSubscription!: Subscription;
  richTextEditorDebounceTimeValue: number = 500;
  richTextInvalid: boolean = false;
  // Ajouter un max de characteres et avec string qui s'affiche et s'update ex: 209 de 2000 characters

  constructor(public logger: NGXLogger) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.richText && this.localKey && this.richText[this.localKey]) {
        const strippedText: string = this.richText[this.localKey].replace(
          /<[^>]*>/g,
          '',
        );
        // this.logger.info('>>>> strippedText: ' + strippedText);
        this.crunchRichText(strippedText);
      } else {
        this.crunchRichText(null);
      }
    }, 500);

    this.richTextEditorSubscription = this.richTextEditor?.onTextChange
      .pipe(debounceTime(this.richTextEditorDebounceTimeValue))
      .pipe(distinctUntilChanged())
      .subscribe({
        next: (data) => {
          this.crunchRichText(data.textValue);
          // this.logger.info('textValue: ' + data.textValue);
          // this.logger.info('htmlValue: ' + data.htmlValue);
          // if (data.htmlValue) {
          //   this.currentLength = data.htmlValue.length;
          //   this.richTextInvalid = (data.htmlValue.length > this.maxLength);
          //   const evt: RichTextChangeModel = { richText: data.htmlValue, invalid: this.richTextInvalid };
          //   this.changeEvent.emit(evt);
          //   this.logger.info("RichTextLocaleComponent.richTextEditor.onTextChange() -> RichTextChangeModel: " + JSON.stringify(evt));
          //   if (this.richTextInvalid) {
          //     return;
          //   }
          // }
          // else {
          //   const evt: RichTextChangeModel = { richText: null, invalid: true };
          //   this.changeEvent.emit(evt);
          // }
        },
      });

    // this.logger.info("RichTextLocaleComponent.ngAfterViewInit() -> : ");
  }

  public crunchRichText(txt: string | null): void {
    if (txt) {
      this.currentLength = txt.length;
      //   this.logger.info("RichTextLocaleComponent.crunchRichText() -> txt: " + txt);
      // this.logger.info("RichTextLocaleComponent.crunchRichText() -> txt.length: " + txt.length);
      this.richTextInvalid = txt.length > this.maxLength;
      const evt: RichTextChangeModel = {
        richText: this.richText[this.localKey],
        invalid: this.richTextInvalid,
      };
      this.changeEvent.emit(evt);
      // this.logger.info("RichTextLocaleComponent.crunchRichText() -> RichTextChangeModel: " + JSON.stringify(evt));
      if (this.richTextInvalid) {
        return;
      }
    } else {
      const evt: RichTextChangeModel = { richText: null, invalid: true };
      this.changeEvent.emit(evt);
    }
  }

  public setFocus(event: any): void {
    // this.logger.info('RichTextLocaleComponent.setFocus() -> localKey: ' + this.localKey + '    applyFocus: ' + this.applyFocus);
    if (this.applyFocus) {
      setTimeout(() => {
        event.editor.focus();
      }, 250);
      // event.editor.focus();
    }
  }

  public resetCount(): void {
    this.currentLength = 0;
  }

  public onSaveRichText(): void {
    this.saveEvent.emit(this.richText);
  }

  public onClearRichText(): void {
    this.clearEvent.emit();
    // this.resetCount();
  }
}

export interface RichTextChangeModel {
  richText: string | null;
  invalid: boolean;
}
