<div id="eventVenues">
  <!-- IS CEL ORDER -->
  <!-- IS CEL TAKEOUT -->
  <div
    *ngIf="
      event &&
      event.delivery_type &&
      !event.delivery_type.need_delivery &&
      event.spot &&
      event.spot.event_address &&
      event.shop &&
      (event.shop.shop_type.key === 'SHOP_WEB' ||
        event.shop.shop_type.key === 'SHOP_API') &&
      !event.is_uqam
    "
    fxFlex="100%"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <div class="spot-name" fxLayout="row" fxLayoutAlign="start center">
      <div>
        {{ "GENERIC.WEB_CLIENT" | translate }}:
        {{ event.spot.full_address.name }}
      </div>
    </div>

    <div *ngIf="event.takeout_location" fxLayout="column" class="spot-address">
      <div>{{ event.takeout_location.name }}</div>
    </div>
    <div
      *ngIf="event.informations && event.informations.multi_takeout_input"
      fxLayout="column"
      class="web-order-room"
    >
      <div>
        {{ "GENERIC.WEB_ORDER_ROOM" | translate }}:
        {{ event.informations.multi_takeout_input }}
      </div>
    </div>
    <div
      *ngIf="
        event.shop &&
        !event.takeout_location &&
        !event.informations.multi_takeout_input
      "
      fxLayout="column"
      class="spot-address"
    >
      <div class>{{ event.shop.line_street }}</div>
      <div class>{{ event.shop.line_city }}</div>
      <div class>{{ event.shop.line_country }}</div>
      <div class>{{ event.shop.line2 }}</div>
    </div>
    <!-- Salle CEL -->
  </div>

  <div
    *ngIf="
      event &&
      event.delivery_type &&
      event.delivery_type.need_delivery &&
      event.spot &&
      event.spot.event_address &&
      event.shop &&
      (event.shop.shop_type.key === 'SHOP_WEB' ||
        event.shop.shop_type.key === 'SHOP_API') &&
      !event.is_uqam
    "
    fxFlex="100%"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <div class="spot-name" fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="70" fxLayout="column" fxLayoutAlign="center center">
        <!-- <ngxgeo-autocomplete *ngIf="editMode" 
                                            [userSettings]="userSettings" 
                                            (componentCallback)="handleAddressChange($event)"
                                            class="">
                                        </ngxgeo-autocomplete> -->
        <!--<div class="font-size-16" style="padding-top: 32px;">
                    {{ 'EVENTS.SPORADIC_ADDRESS' | translate }}
                </div>-->

        <div>
          {{ "GENERIC.WEB_CLIENT" | translate }}:
          {{ event.spot.full_address.name }}
        </div>
        <div style="min-width: 300px; padding: -16px">
          <app-address
            style="width: 70%; min-width: 400px; max-width: 750px"
            #addressComponent
            [groupModel]="event.spot.event_address"
            [isVisible]="true"
            [editMode]="true"
            [showVenuNameInput]="true"
            [useAutosave]="false"
            [hideSaveButton]="true"
            class
          ></app-address>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" class="mb-12">
          <button
            mat-raised-button
            (click)="onDeleteSporadicAddress()"
            class="mr-16"
            color="accent"
          >
            {{ "GENERIC.DELETE" | translate }}
          </button>
          <button
            mat-raised-button
            (click)="addressComponent.onManualSave()"
            class
            color="accent"
          >
            {{ "GENERIC.SAVE" | translate }}
          </button>
        </div>
      </div>

      <!-- <mat-icon *ngIf="!editable" matTooltip="{{ 'GENERIC.AVAILABLE_EDIT_MODE_ONLY' | translate }}"
                        class="s-16 ml-8 mr-4 secondary-text icon-disabled">{{ getIcon('CANCEL') }}</mat-icon>
                
                    <button (click)="onResetSpot()" *ngIf="editable" mat-icon-button>
                        <mat-icon class="s-16">{{ getIcon('CANCEL') }}</mat-icon>
                    </button> -->
    </div>

    <!--<div *ngIf="event.spot && event.spot.event_address" fxLayout="column" class="spot-address">
            <div class>{{ event.spot.event_address.line_street }}</div>
            <div class>{{ event.spot.event_address.line_city }}</div>
            <div class>{{ event.spot.event_address.line_country }}</div>
            <div class>{{ event.spot.event_address.line2 }}</div>
        </div>-->
    <div
      *ngIf="
        event.informations &&
        event.informations.multi_takeout_input &&
        event.is_ctm
      "
      fxLayout="column"
      class="web-order-room"
    >
      <div>
        {{ "GENERIC.WEB_ORDER_ROOM" | translate }}:
        {{ event.informations.multi_takeout_input }}
      </div>
    </div>

    <!-- Salle CEL -->
  </div>

  <!-- IS *NOT* CEL ORDER -->
  <!-- STEP #1 -->
  <div
    *ngIf="
      event &&
      !event.spot.event_address &&
      (!event.clients.ordering_client || !event.clients.invoicing_client) &&
      event.shop &&
      (event.shop.shop_type.key !== 'SHOP_WEB' ||
        event.shop.shop_type.key === 'SHOP_API')
    "
    fxFlex="100%"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    <div class="font-size-20">
      {{ "EVENTS.SELECT_CLIENT_FIRST" | translate }}
    </div>
  </div>

  <!-- STEP #1.5 -->
  <!--        *ngIf="event &&-->
  <!--                event.clients.ordering_client &&-->
  <!--                event.clients.invoicing_client &&-->
  <!--                !event.clients.ordering_client.address.street_number &&-->
  <!--                !event.clients.ordering_client.address.street"-->

  <!-- !event.event_address && -->
  <div
    *ngIf="
      event &&
      !event.spot.event_address &&
      event.clients.ordering_client &&
      event.clients.invoicing_client &&
      (!event.clients.ordering_client.address ||
        (!event.clients.ordering_client.address.street_number &&
          !event.clients.ordering_client.address.street)) &&
      event.shop &&
      (event.shop.shop_type.key !== 'SHOP_WEB' ||
        event.shop.shop_type.key === 'SHOP_API')
    "
    fxFlex="100%"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <!-- <div fxFlex="100%" fxLayout="row">
            <div class="font-size-20">{{ 'EVENTS.SET_CLIENT_ADDRESS' | translate }}</div>
            <button (click)="onOpenClientInNewTab(event.clients.ordering_client)" class="mat-icon-button" mat-button
                matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}">
                <mat-icon class="s-20">{{ getIcon('NEW_TAB') }}</mat-icon>
            </button>
        </div> -->

    <div fxLayout="row" fxLayoutAlign="center center">
      <div class="font-size-20 mb-12">
        {{ "EVENTS.DOES_NOT_NEED_DELIVERY" | translate }}
      </div>
    </div>

    <div fxFlex fxLayout="row" fxLayoutAlign="center center">
      <div *ngFor="let deliveryType of deliveryTypes; let i = index">
        <button
          fxFlex="180px"
          (click)="onDeliveryTypeChange(deliveryType)"
          [disabled]="
            (event.delivery_type &&
              event.delivery_type.delivery_type_id ===
                deliveryType.delivery_type_id) ||
            deliveryType.delivery_type_id !== 4
          "
          class="mb-16 mr-16"
          color="accent"
          mat-raised-button
        >
          {{ lang === "fr-CA" ? deliveryType.name_fr : deliveryType.name_en }}
        </button>
      </div>
    </div>

    <!-- <div>need_delivery: {{ event.delivery_type.need_delivery }}</div>
        <div>MODULE_ORG_IDENTITY existe: {{ moduleService.moduleExist('MODULE_ORG_IDENTITY') }}</div>
        <div>takeout_organization_identity_list: {{event.informations.takeout_organization_identity_list | json}}</div> -->
    <!-- Cueillette & multi-identité -->
    <div
      *ngIf="
        event.delivery_type &&
        !event.delivery_type.need_delivery &&
        moduleService.modulesAreSet &&
        moduleService.moduleExist('MODULE_ORG_IDENTITY')
      "
      fxLayout="column"
      fxLayoutAlign="center center"
      fxFlex
    >
      <div
        *ngIf="
          event.informations &&
          event.informations.takeout_organization_identity_list &&
          event.informations.takeout_organization_identity_list.length > 0
        "
        class="mt-16 mb-8"
        fxFlex="100"
        fxLayout="row"
        fxLayoutAlign="center center"
      >
        <div fxLayout="row" fxLayoutAlign="start center" class="font-size-16">
          <div>{{ "EVENTS.ORG_IDENTITY_SELECT" | translate }}:</div>

          <button
            class=""
            mat-icon-button
            matTooltip="{{ 'EVENTS.ORG_IDENTITY_HINT' | translate }}"
            content-type="template"
            type="button"
          >
            <mat-icon class="s-20" color content-type="template">
              {{ getIcon("HELP") }}
            </mat-icon>
          </button>
        </div>
      </div>

      <div
        class="info-editable"
        *ngIf="
          event.informations &&
          event.informations.takeout_organization_identity_list &&
          event.informations.takeout_organization_identity_list.length > 0
        "
      >
        <div
          class
          style="width: 250px"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <mat-form-field class="form-input-container" fxFlex="100%">
            <mat-label>{{
              "EVENTS.ORG_IDENTITY_DROPDOWN_ITEM" | translate
            }}</mat-label>
            <mat-select
              (selectionChange)="onTakeoutIdentityChange($event)"
              [(ngModel)]="selectedIdentityUUID"
            >
              <mat-option [value]="null">
                {{ "GENERIC.NONE" | translate }}
              </mat-option>
              <mat-option
                *ngFor="
                  let identity of event.informations
                    .takeout_organization_identity_list
                "
                [value]="identity.uuid"
              >
                {{ identity.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <!-- END Cueillette & multi-identité -->
  </div>

  <!-- STEP #2 -->
  <div
    *ngIf="
      event &&
      event.clients.ordering_client &&
      event.clients.invoicing_client &&
      ((event.clients.ordering_client.address &&
        event.clients.ordering_client.address.street_number &&
        event.clients.ordering_client.address.street) ||
        (event.shop.shop_type.key === 'SHOP_WEB' && event.is_uqam)) &&
      event.shop &&
      (event.shop.shop_type.key !== 'SHOP_WEB' ||
        event.is_uqam ||
        event.shop.shop_type.key === 'SHOP_API')
    "
    fxFlex="100"
  >
    <!-- SPINNER / LOADING  -->
    <div
      *ngIf="loading"
      class
      fxFlex="100%"
      fxLayout="row"
      fxLayoutAlign="center center"
      style="height: 100%"
    >
      <mat-spinner [diameter]="28" class></mat-spinner>
    </div>

    <div
      *ngIf="!loading"
      fxFlex="100"
      fxLayout="column"
      fxLayoutAlign="center center"
      style="width: 100%"
    >
      <!-- DELIVERY TYPE -->
      <!-- <div *ngIf="!event.delivery_type || event.delivery_type.need_delivery" -->
      <div fxLayout="column" fxLayoutAlign="start start">
        <div fxFlex fxLayout="column">
          <div
            class="mb-8 mt-8"
            fxLayout="column"
            *ngIf="deliveryTypes && deliveryTypes.length > 0"
          >
            <div
              class="mb-16"
              fxFlex="100"
              fxLayout="row"
              fxLayoutAlign="center center"
            >
              <div class="font-size-16">
                {{ "EVENTS.SELECT_DELIVERY_TYPE" | translate }}
              </div>
            </div>

            <!-- <app-gt2-select [service]="deliveryTypeService" [selectedModel]="event.delivery_type"
                                                        [disabled]="false" [placeHolder]=" 'GENERIC.TYPE' | translate"
                                                        (changeEvent)="onDeliveryTypeChange($event)" [label]="''"
                                                        [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')" [nullableUseNone]="false"
                                                        class="mx-4" style="width: 250px;"></app-gt2-select> -->

            <div
              fxFlex
              fxLayout="row"
              fxLayout.xs="column"
              fxLayoutAlign="center center"
            >
              <div *ngFor="let deliveryType of deliveryTypes; let i = index">
                <button
                  fxFlex="180px"
                  (click)="onDeliveryTypeChange(deliveryType)"
                  [disabled]="
                    event.delivery_type &&
                    event.delivery_type.delivery_type_id ===
                      deliveryType.delivery_type_id
                  "
                  class="mb-16 mr-16"
                  color="accent"
                  mat-raised-button
                >
                  {{
                    lang === "fr-CA"
                      ? deliveryType.name_fr
                      : deliveryType.name_en
                  }}
                </button>
              </div>
            </div>

            <!-- <div fxFlex="100" fxLayout="row" fxLayoutAlign="center center">
                            <button fxFlex="180px" (click)="onDeliveryTypeChange(deliveryTypeWithStaff)" 
                                    [disabled]="event.delivery_type && event.delivery_type.uuid === deliveryTypeWithStaff.uuid"
                                    class="mb-16 mr-16" color="accent" mat-raised-button>
                                {{"EVENTS.WITH_STAFF" | translate}}
                            </button>
                            <button fxFlex="180px" (click)="onDeliveryTypeChange(deliveryTypePickup)" 
                                [disabled]="event.delivery_type && event.delivery_type.uuid === deliveryTypePickup.uuid"
                                class="mb-16" color="accent" mat-raised-button>
                                {{"EVENTS.TAKEOUT" | translate}}
                            </button>
                        </div>
                        <div fxFlex="100" fxLayout="row" fxLayoutAlign="center center">
                            <button fxFlex="180px" (click)="onDeliveryTypeChange(deliveryTypeDeliveryOnly)" 
                                [disabled]="event.delivery_type && event.delivery_type.uuid === deliveryTypeDeliveryOnly.uuid"
                                class="mb-16 mr-16" color="accent" mat-raised-button>
                                {{"EVENTS.DELIVERY_ONLY" | translate}}
                            </button>
                            <button fxFlex="180px" (click)="onDeliveryTypeChange(deliveryTypeDelivveryAndInstall)" 
                                [disabled]="event.delivery_type && event.delivery_type.uuid === deliveryTypeDelivveryAndInstall.uuid"
                                class="mb-16" color="accent" mat-raised-button>
                                {{"EVENTS.DELIVERY_INSTALL" | translate}}
                            </button>
                        </div>
                    </div> -->

            <!-- IS A PICK UP -->
            <div
              *ngIf="event.delivery_type && !event.delivery_type.need_delivery"
            >
              <div
                *ngIf="event && event.is_uqam"
                fxLayout="column"
                fxLayoutAlign="center center"
                style="width: 100%"
              >
                <div *ngIf="!editMode" class="mt-12" style="width: 100%">
                  <div class="font-size-20">
                    Entrer un point de cueillette de votre choix:
                  </div>
                  <div class="title center mt-12">
                    {{ "Point de cueillette" | translate }}
                  </div>
                  <div class="info text-truncate center">
                    {{ event.informations.takeout_location_input }}
                  </div>
                  <div
                    *ngIf="
                      !event.informations ||
                      !event.informations.takeout_location_input
                    "
                    class="info center empty-value"
                  ></div>
                </div>
                <div
                  *ngIf="editMode"
                  [formGroup]="form"
                  class="mt-12"
                  style="width: 100%"
                >
                  <div class="font-size-20">
                    Entrer un point de cueillette de votre choix:
                  </div>
                  <mat-form-field
                    class="form-input-container mt-4"
                    style="width: 100%"
                  >
                    <mat-label>{{
                      "Point de cueillette" | translate
                    }}</mat-label>
                    <input
                      matInput
                      autocomplete="nope"
                      [(ngModel)]="event.informations.takeout_location_input"
                      formControlName="takeout_location_input"
                      type="text"
                    />
                    <mat-error
                      *ngIf="form.controls['takeout_location_input'].invalid"
                    >
                      {{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div
                *ngIf="event && !event.is_uqam"
                fxLayout="column"
                fxLayoutAlign="center center"
                style="width: 100%"
              >
                <div class="font-size-20">
                  {{ "EVENTS.DOES_NOT_NEED_DELIVERY" | translate }}
                </div>
              </div>
            </div>

            <!-- NEED DELIVERY -->
            <div
              *ngIf="
                event.delivery_type &&
                event.delivery_type.need_delivery &&
                !event.spot.enterprise &&
                !event.spot.private_client &&
                !event.spot.place &&
                !event.spot.room
              "
            >
              <div
                fxLayout="column"
                fxLayoutAlign="center center"
                style="width: 100%"
              >
                <div class="font-size-16 mb-16">
                  {{ "EVENTS.NO_VENUES_SELECTED" | translate }}
                </div>

                <div fxLayout="row" fxLayoutAlign="start center">
                  <button
                    (click)="onSelectVenue()"
                    class="mb-16 mr-16"
                    color="primary"
                    mat-raised-button
                  >
                    {{ "EVENTS.SELECT_VENUE_PLACE" | translate }}
                  </button>

                  <button
                    (click)="onSelectOrderingClient()"
                    *ngIf="!event.clients.ordering_client.enterprise"
                    class="mb-16"
                    color="primary"
                    mat-raised-button
                  >
                    {{ "EVENTS.SELECT_ORDERING_CLIENT" | translate }}
                  </button>
                </div>

                <button
                  (click)="onSelectOrderingClientEnterprise()"
                  *ngIf="event.clients.ordering_client.enterprise"
                  class="mb-16"
                  color="primary"
                  mat-raised-button
                >
                  {{ "EVENTS.SELECT_ENTERPRISE_ORDERING_CLIENT" | translate }}
                </button>

                <button
                  (click)="onCreateSporadicAddress()"
                  *ngIf="!event.spot.event_address"
                  class="mb-16"
                  color="primary"
                  mat-raised-button
                >
                  {{ "EVENTS.ADD_SPORADIC_ADDRESS" | translate }}
                </button>
              </div>
            </div>
          </div>

          <div
            *ngIf="
              event.delivery_type &&
              event.spot.event_address &&
              event.delivery_type.need_delivery &&
              !event.spot.enterprise &&
              !event.spot.private_client &&
              !event.spot.place &&
              !event.spot.room
            "
            fxFlex="70"
            fxLayout="column"
            fxLayoutAlign="center center"
          >
            <!-- <ngxgeo-autocomplete *ngIf="editMode" 
                                            [userSettings]="userSettings" 
                                            (componentCallback)="handleAddressChange($event)"
                                            class="">
                                        </ngxgeo-autocomplete> -->
            <div class="font-size-16" style="padding-top: 32px">
              {{ "EVENTS.SPORADIC_ADDRESS" | translate }}
            </div>
            <div style="min-width: 300px; padding: -16px">
              <app-address
                style="width: 70%; min-width: 400px; max-width: 750px"
                #addressComponent
                [groupModel]="event.spot.event_address"
                [isVisible]="true"
                [editMode]="true"
                [showVenuNameInput]="true"
                [useAutosave]="false"
                [hideSaveButton]="true"
                class
              ></app-address>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" class="mb-12">
              <button
                mat-raised-button
                (click)="onDeleteSporadicAddress()"
                class="mr-16"
                color="accent"
              >
                {{ "GENERIC.DELETE" | translate }}
              </button>
              <button
                mat-raised-button
                (click)="addressComponent.onManualSave()"
                class
                color="accent"
              >
                {{ "GENERIC.SAVE" | translate }}
              </button>
            </div>
          </div>
        </div>

        <!-- EVERYTHING IS SETUP, SHOW ONE OF 3 LAST SCREEN -->
        <div
          *ngIf="
            event.delivery_type &&
            event.delivery_type.need_delivery &&
            (event.spot.enterprise ||
              event.spot.private_client ||
              event.spot.place ||
              event.spot.room)
          "
          class="mb-16"
          fxLayout="column"
          style="width: 100%"
        >
          <!-- IS ENTERPRISE -->
          <div
            *ngIf="event.spot.enterprise"
            fxLayout="row"
            fxLayoutAlign="center center"
            style="width: 100%"
          >
            <div fxLayout="column" fxLayoutAlign="start start">
              <div
                class="spot-name"
                fxLayout="row"
                fxLayoutAlign="start center"
              >
                {{ "GENERIC.ENTERPRISE" | translate }}:
                {{ event.spot.enterprise.name }}

                <!-- <mat-icon *ngIf="!editable"
                                    matTooltip="{{ 'GENERIC.AVAILABLE_EDIT_MODE_ONLY' | translate }}"
                                    class="s-16 ml-8 mr-4 secondary-text icon-disabled">{{ getIcon('CANCEL') }}
                                </mat-icon> -->

                <button (click)="onResetSpot()" mat-icon-button>
                  <mat-icon class="s-16">{{ getIcon("CANCEL") }}</mat-icon>
                </button>

                <button
                  (click)="onOpenInNewTab(event.spot.enterprise)"
                  class="mat-icon-button"
                  mat-icon-button
                  matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
                >
                  <mat-icon class="s-16">{{ getIcon("NEW_TAB") }}</mat-icon>
                </button>
              </div>
              <div
                *ngIf="event.spot.room"
                class="spot-primary"
                fxLayout="row"
                fxLayoutAlign="start center"
              >
                {{ "GENERIC.ROOM" | translate }}: {{ event.spot.room.name }}
                <button
                  (click)="onOpenInNewTab(event.spot.room)"
                  class="mat-icon-button"
                  mat-icon-button
                  matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
                >
                  <mat-icon class="s-16">{{ getIcon("NEW_TAB") }}</mat-icon>
                </button>
              </div>
              <div *ngIf="event.spot.room" class="spot-primary">
                {{ "EVENTS.CAPACITY" | translate }}:
                {{ event.spot.room.max_place }}
              </div>
              <div
                *ngIf="
                  event.spot &&
                  event.spot.enterprise &&
                  event.spot.enterprise.address
                "
                class="spot-address"
              >
                <div class>
                  {{ event.spot.enterprise.address.line_street }}
                </div>
                <div class>
                  {{ event.spot.enterprise.address.line_city }}
                </div>
                <div class>
                  {{ event.spot.enterprise.address.line_country }}
                </div>
                <div class>{{ event.spot.enterprise.address.line2 }}</div>
              </div>

              <div
                *ngIf="
                  event.spot.enterprise &&
                  event.spot.enterprise.address &&
                  event.spot.enterprise.address.sector
                "
                class="spot-primary"
              >
                {{ "GENERIC.SECTOR" | translate }}:
                {{ event.spot.enterprise.address.sector.name }}, &nbsp;{{
                  event.spot.enterprise.address.sector.price_label
                }}
              </div>

              <div
                *ngIf="
                  event.spot.enterprise.address &&
                  event.spot.enterprise.address.long &&
                  event.spot.enterprise.address.lat
                "
              >
                <a
                  class="mat-caption"
                  href="{{ event.spot.enterprise.address | googleLinkPipe }}"
                  target="_blank"
                >
                  <span class="mt-8">
                    {{ "GENERIC.SEE_ON_GOOGLE_MAP" | translate }}
                  </span>
                </a>
              </div>
            </div>
          </div>

          <!-- IS PLACE -->
          <div
            *ngIf="event.spot.place"
            fxLayout="row"
            fxLayoutAlign="center center"
            style="width: 100%"
          >
            <div fxLayout="column" fxLayoutAlign="start start">
              <div
                class="spot-name"
                fxLayout="row"
                fxLayoutAlign="start center"
              >
                {{ "GENERIC.VENUE" | translate }}: {{ event.spot.place.name }}

                <!-- <mat-icon *ngIf="!editable"
                                    matTooltip="{{ 'GENERIC.AVAILABLE_EDIT_MODE_ONLY' | translate }}"
                                    class="s-16 ml-8 mr-4 secondary-text icon-disabled">{{ getIcon('CANCEL') }}
                                </mat-icon> -->

                <button (click)="onResetSpot()" mat-icon-button>
                  <mat-icon class="s-16">{{ getIcon("CANCEL") }}</mat-icon>
                </button>

                <button
                  (click)="onOpenInNewTab(event.spot.place)"
                  class="mat-icon-button"
                  mat-icon-button
                  matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
                >
                  <mat-icon class="s-16">{{ getIcon("NEW_TAB") }}</mat-icon>
                </button>
              </div>
              <div
                *ngIf="event.spot.room"
                class="spot-primary"
                fxLayout="row"
                fxLayoutAlign="start center"
              >
                {{ "GENERIC.ROOM" | translate }}: {{ event.spot.room.name }}
                <button
                  (click)="onOpenInNewTab(event.spot.room)"
                  class="mat-icon-button"
                  mat-icon-button
                  matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
                >
                  <mat-icon class="s-16">{{ getIcon("NEW_TAB") }}</mat-icon>
                </button>
              </div>
              <div *ngIf="event.spot.room" class="spot-primary">
                {{ "EVENTS.CAPACITY" | translate }}:
                {{ event.spot.room.max_place }}
              </div>
              <div *ngIf="event.spot.room" class="spot-primary">
                {{ "EVENTS.ROYALTY" | translate }}:
                {{ event.royalty_percent_label }}
              </div>
              <div
                *ngIf="
                  event.spot && event.spot.place && event.spot.place.address
                "
                class="spot-address"
              >
                <div class>{{ event.spot.place.address.line_street }}</div>
                <div class>{{ event.spot.place.address.line_city }}</div>
                <div class>{{ event.spot.place.address.line_country }}</div>
                <div class>{{ event.spot.place.address.line2 }}</div>
              </div>
              <div
                *ngIf="
                  event.spot.place &&
                  event.spot.place.address &&
                  event.spot.place.address.sector
                "
                class="spot-primary"
              >
                {{ "GENERIC.SECTOR" | translate }}:
                {{ event.spot.place.address.sector.name }}, &nbsp;{{
                  event.spot.place.address.sector.price_label
                }}
              </div>

              <div
                *ngIf="
                  event.spot.place.address &&
                  event.spot.place.address.long &&
                  event.spot.place.address.lat
                "
              >
                <a
                  class="mat-caption"
                  href="{{ event.spot.place.address | googleLinkPipe }}"
                  target="_blank"
                >
                  <span class="mt-8">
                    {{ "GENERIC.SEE_ON_GOOGLE_MAP" | translate }}
                  </span>
                </a>
              </div>
            </div>
          </div>

          <!-- IS PRIVATE CLIENT -->
          <div
            *ngIf="event.spot.private_client"
            fxLayout="row"
            fxLayoutAlign="center center"
            style="width: 100%"
          >
            <div fxLayout="column" fxLayoutAlign="start start">
              <div
                class="spot-name"
                fxLayout="row"
                fxLayoutAlign="start center"
              >
                <div
                  *ngIf="
                    event.spot.private_client &&
                    event.spot.private_client.address
                  "
                >
                  {{ "GENERIC.CLIENT" | translate }}:
                  {{ event.spot.private_client.name }}
                </div>
                <div
                  *ngIf="
                    event.spot.full_address &&
                    event.spot.full_address.street_number &&
                    !event.spot.private_client
                  "
                >
                  {{ "GENERIC.WEB_CLIENT" | translate }}:
                  {{ event.spot.full_address.name }}
                </div>

                <!-- <mat-icon *ngIf="!editable"
                                    matTooltip="{{ 'GENERIC.AVAILABLE_EDIT_MODE_ONLY' | translate }}"
                                    class="s-16 ml-8 mr-4 secondary-text icon-disabled">{{ getIcon('CANCEL') }}
                                </mat-icon> -->

                <button (click)="onResetSpot()" mat-icon-button>
                  <mat-icon class="s-16">{{ getIcon("CANCEL") }}</mat-icon>
                </button>

                <button
                  (click)="onOpenInNewTab(event.spot.private_client)"
                  class="mat-icon-button"
                  mat-icon-button
                  matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
                >
                  <mat-icon class="s-16">{{ getIcon("NEW_TAB") }}</mat-icon>
                </button>
              </div>

              <div
                *ngIf="
                  event.spot.private_client.address &&
                  (!event.spot.event_address || !event.spot.event_address.name)
                "
                class="spot-address"
              >
                <div class>
                  {{ event.spot.private_client.address.line_street }}
                </div>
                <div class>
                  {{ event.spot.private_client.address.line_city }}
                </div>
                <div class>
                  {{ event.spot.private_client.address.line_country }}
                </div>
                <div class>
                  {{ event.spot.private_client.address.line2 }}
                </div>
              </div>
              <div
                class="spot-address"
                *ngIf="
                  event.spot.event_address && event.spot.event_address.name
                "
              >
                <div class>
                  {{ event.spot.event_address.street_number }},
                  {{ event.spot.event_address.street }}
                </div>
                <div class>
                  {{ event.spot.event_address.city }},
                  {{ event.spot.event_address.province }}
                </div>
                <div class>
                  {{ event.spot.event_address.postal_code }},
                  {{ event.spot.event_address.country }}
                </div>
                <div class>{{ event.spot.event_address.line2 }}</div>
              </div>

              <div
                *ngIf="
                  event.spot.private_client.address &&
                  event.spot.private_client.address.sector
                "
                class="spot-primary"
              >
                {{ "GENERIC.SECTOR" | translate }}:
                {{ event.spot.private_client.address.sector.name }}, &nbsp;{{
                  event.spot.private_client.address.sector.price_label
                }}
              </div>
              <div
                *ngIf="
                  event.spot.private_client.address &&
                  event.spot.private_client.address.long &&
                  event.spot.private_client.address.lat
                "
              >
                <a
                  class="mat-caption"
                  href="{{
                    event.spot.private_client.address | googleLinkPipe
                  }}"
                  target="_blank"
                >
                  <span class="mt-8">
                    {{ "GENERIC.SEE_ON_GOOGLE_MAP" | translate }}
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <!-- Cueillette & multi-identité -->
        <div
          *ngIf="
            event.delivery_type &&
            !event.delivery_type.need_delivery &&
            moduleService.modulesAreSet &&
            moduleService.moduleExist('MODULE_ORG_IDENTITY')
          "
          fxLayout="column"
          fxLayoutAlign="center center"
          style="width: 100%"
        >
          <div
            *ngIf="
              event.informations &&
              event.informations.takeout_organization_identity_list &&
              event.informations.takeout_organization_identity_list.length > 0
            "
            class="mt-16 mb-8"
            fxFlex="100"
            fxLayout="row"
            fxLayoutAlign="center center"
          >
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              class="font-size-16"
            >
              <div>{{ "EVENTS.ORG_IDENTITY_SELECT" | translate }}:</div>

              <button
                class=""
                mat-icon-button
                matTooltip="{{ 'EVENTS.ORG_IDENTITY_HINT' | translate }}"
                content-type="template"
                type="button"
              >
                <mat-icon class="s-20 ml-8" color content-type="template">
                  {{ getIcon("HELP") }}
                </mat-icon>
              </button>
            </div>
          </div>

          <div
            class="info-editable"
            *ngIf="
              event.informations &&
              event.informations.takeout_organization_identity_list &&
              event.informations.takeout_organization_identity_list.length > 0
            "
          >
            <div
              class
              style="width: 250px"
              fxLayout="row"
              fxLayoutAlign="start center"
            >
              <mat-form-field class="form-input-container" fxFlex="100%">
                <mat-label>{{
                  "EVENTS.ORG_IDENTITY_DROPDOWN_ITEM" | translate
                }}</mat-label>
                <mat-select
                  (selectionChange)="onTakeoutIdentityChange($event)"
                  [(ngModel)]="selectedIdentityUUID"
                >
                  <mat-option [value]="null">
                    {{ "GENERIC.NONE" | translate }}
                  </mat-option>
                  <!-- <mat-option [value]="null" >{{ crudOrganizationService.organization.data.name }}</mat-option> -->
                  <mat-option
                    *ngFor="
                      let identity of event.informations
                        .takeout_organization_identity_list
                    "
                    [value]="identity.uuid"
                  >
                    {{ identity.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <!-- <mat-icon class="s-20 ml-8" matTooltip="{{ 'EVENTS.ORG_IDENTITY_HELP' | translate }}" color="">help</mat-icon> -->
            </div>
          </div>
        </div>
        <!-- END Cueillette & multi-identité -->
      </div>
    </div>

    <!-- <div *ngIf="event 
                    && event.clients.ordering_client 
                    && event.clients.invoicing_client
                    && (!event.clients.ordering_client.address 
                    || !event.clients.ordering_client.address.street_number
                    || !event.clients.ordering_client.address.street)
                    && event.shop
                    && event.shop.shop_type.key !== 'SHOP_WEB'" fxFlex="100">
        
        <div class="mb-8 mt-8" fxLayout="column">
            <div class="mb-16" fxFlex="100" fxLayout="row" fxLayoutAlign="center center">
                <div class="font-size-16 ">{{ 'EVENTS.SELECT_DELIVERY_TYPE' | translate }}</div>
            </div>
            <div fxFlex="100" fxLayout="row" fxLayoutAlign="center center">
                <app-gt2-select 
                    [service]="deliveryTypeService" 
                    [selectedModel]="event.delivery_type" 
                    [disabled]="true"
                    [placeHolder]=" 'GENERIC.TYPE' | translate" 
                    (changeEvent)="onDeliveryTypeChange($event)" [label]="''"
                    [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')" 
                    [nullableUseNone]="false" class="mx-4"
                    style="width: 250px;"></app-gt2-select>
            </div>
        </div>
    </div> -->
  </div>
</div>
