import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardJulComponent } from './dashboard-jul.component';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { RouterModule } from '@angular/router';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { GT2DateAdapter } from '../../utils/GT2DateAdapter';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import { CalendarModule as AngularCalendarModule } from 'angular-calendar';
import { AppRoutesName } from '../../const/AppRoutesName';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CollectionTableSortComponent } from '../../components/collection-table-sort/collection-table-sort.component';
import { CreateCongressesButtonComponent } from '../../components/create-congresses-button/create-congresses-button.component';
import { CreateOrderButtonsComponent } from '../../components/create-order-buttons/create-order-buttons.component';
import { GenericAlertComponent } from '../../components/generic-alert/generic-alert.component';
import { DashboardNoteComponent } from '../dashboard/components/dashboard-note/dashboard-note.component';
import { ProdZoneTableComponent } from '../dashboard/components/prod-zone-table/prod-zone-table.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MatRippleModule } from '@angular/material/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import moment from 'moment';

const routes = [
  {
    path: AppRoutesName.FR.DASHBOARD_JUL,
    component: DashboardJulComponent,
  },
  {
    path: AppRoutesName.EN.DASHBOARD_JUL,
    component: DashboardJulComponent,
  },
];

function momentAdapterFactory() {
  return adapterFactory(moment);
}
@NgModule({
  declarations: [DashboardJulComponent],
  imports: [
    GenericAlertComponent,
    CollectionTableSortComponent,
    MatIconModule,
    TranslateModule,
    MatButtonModule,
    MatFormFieldModule,
    MatRippleModule,
    NgxPermissionsModule,
    FormsModule,
    MatDatepickerModule,
    NgxSpinnerModule,
    MatRadioModule,
    MatInputModule,
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    Gt2PrimengModule,
    RouterModule.forChild(routes),
    AngularCalendarModule.forRoot({
      provide: GT2DateAdapter,
      useFactory: momentAdapterFactory,
    }),
    NgxMaterialTimepickerModule,
    CreateCongressesButtonComponent,
    CreateOrderButtonsComponent,
    DashboardNoteComponent,
    ProdZoneTableComponent,
  ],
  exports: [],
})
export class DashboardJulModule {}
