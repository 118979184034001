<div style="max-height: 100%; overflow: auto">
  <div class="result-title">Broken Error</div>

  <div *ngIf="testFromRequestResult" class="broken-result">
    <div class="request-info">
      <div>url: {{ testFromRequestResult.request.url }}</div>
      <div *ngIf="testFromRequestResult.request.body">
        <div>Request body: &nbsp;</div>
        <pre class="result-body">{{
          testFromRequestResult.request.body | json
        }}</pre>
      </div>
    </div>

    <mat-tab-group
      animationDuration="100ms"
      mat-stretch-tabs="false"
      mat-align-tabs="start"
      class="tabs-content"
    >
      <mat-tab label="JSON REGEXed">
        <pre>{{ testFromRequestResultError | json }}</pre>
      </mat-tab>

      <mat-tab label="JSON">
        <pre>{{ testFromRequestResult.result | json }}</pre>
      </mat-tab>

      <mat-tab label="html">
        TODO test with html response
        <div [innerHTML]="testFromRequestResultRawHTML"></div>
      </mat-tab>

      <mat-tab label="raw">
        <div>{{ testFromRequestResult.result | json }}</div>
      </mat-tab>
    </mat-tab-group>
  </div>

  <div *ngIf="!testFromRequestResult" class="p-8 broken-result">
    THE REQUEST HAS NO RESULT
  </div>

  <div class="" fxLayout="row" fxLayoutAlign="center start">
    <button
      mat-raised-button
      (click)="dialogRef.close(false)"
      color="accent"
      class=""
    >
      {{ "GENERIC.OK" | translate }}
    </button>
  </div>
</div>
