import { MatDialog } from '@angular/material/dialog';
import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewChecked,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AddressService } from './address.service';
import { GT2FormPageAbstract } from '../../content/abstract/GT2FormPageAbstract';
import { AddressModel } from '../../api/models/AddressModel';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SectorModel } from '../sectors-select/sectors.service';
import { Settings } from '../../settings/settings';
import { NGXLogger } from 'ngx-logger';
import { ModulesService } from '../../services/modules.service';
import { LocalizationConfig } from '../../../localization/LocalizationConfig';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { GoogleLinkPipeModule } from '../../modules/google-link.module';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { SectorsSelectComponent } from '../sectors-select/sectors-select.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    TranslateModule,
    MatInputModule,
    MatAutocompleteModule,
    FormsModule,
    GoogleLinkPipeModule,
    ReactiveFormsModule,
    SectorsSelectComponent,
    MatButtonModule,
    MatFormFieldModule,
  ],
  providers: [AddressService],
})
export class AddressComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy, AfterViewChecked
{
  @ViewChild('autocompleteInput', { static: false })
  autocompleteInput!: ElementRef;
  //@ts-ignore
  autocomplete!: google.maps.places.Autocomplete;

  userSettings: any = {
    inputPlaceholderText: '',
    showRecentSearch: true,
  };

  @Input() groupModel!: AddressModel | any;
  @Output() groupModelChange = new EventEmitter<AddressModel>();
  @Input() isVisible: boolean = false;
  @Input() editMode: boolean = true;
  @Input() useAutosave: boolean = false;
  @Input() hideSaveButton: boolean = false;
  @Input() showVenuNameInput: boolean = false;
  @Output() addressEvent: EventEmitter<AddressModel> = new EventEmitter();
  noGeoLocation: boolean = false;
  form: UntypedFormGroup;
  addressPrefillDirty: boolean = false;
  sectorChanged: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private logger: NGXLogger,
    private dialog: MatDialog,
    private addressService: AddressService,
    public moduleService: ModulesService,
  ) {
    super();
    // Initialisé la langue
    const local_lang =
      localStorage.getItem(LocalizationConfig.LOCAL_STORED_LANG_ID) ||
      LocalizationConfig.DEFAULT_LANGUAGE;

    // Ajouter les traductions locales
    if (local_lang === 'en-CA')
      translate.setTranslation(english.lang, english.data, true);
    else translate.setTranslation(french.lang, french.data, true);

    // Définir la langue à utiliser
    translate.setDefaultLang(LocalizationConfig.DEFAULT_LANGUAGE);
    translate.use(local_lang);

    this.userSettings.inputPlaceholderText = this.translate.instant(
      'GENERIC.GOOGLE_ADDRESS_PLACEHOLDER',
    );

    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
        ],
      ],
      street_number: [
        '',
        [
          Validators.minLength(1),
          Validators.maxLength(25),
          Validators.required,
        ],
      ],
      street: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      apartment: '',
      line2: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
        ],
      ],
      city: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      province: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      country: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      postal_code: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      on_site_contact: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
        ],
      ],
    });

    // translate.get("ADDRESS.SEARCH_ADDRESS").subscribe( translated =>
    // {
    //     this.userSettings.inputPlaceholderText = translated;
    // });
  }

  ngOnInit() {
    // this.logger.info(
    //     "AddressComponent.ngOnInit() --> groupModel: " +
    //         JSON.stringify(this.groupModel)
    // );
  }

  ngAfterViewChecked(): void {
    if (this.editMode && this.autocompleteInput && !this.autocomplete) {
      this.initializeAutocomplete();
    }
  }

  ngAfterViewInit() {
    if (!this.groupModel) {
      this.groupModel = {
        object: 'Address',
        name: null,
        uuid: null,
        street_number: null,
        street: null,
        apartment: null,
        line2: null,
        city: null,
        province: null,
        country: null,
        postal_code: null,
        long: null,
        lat: null,
        full_address: null,
        sector: null,
        on_site_contact: null,
      };
    }

    // this.logger.info("AddressComponent.ngAfterViewInit() --> groupModel: " + JSON.stringify(this.groupModel) );
    if (this.useAutosave) {
      // this.setControlsChanges(this.form);
    }
  }

  ngOnDestroy() {}

  initializeAutocomplete(): void {
    //@ts-ignore
    this.autocomplete = new google.maps.places.Autocomplete(
      this.autocompleteInput.nativeElement,
      {
        types: ['geocode'],
        componentRestrictions: { country: 'ca' },
      },
    );

    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete.getPlace();
      if (place && place.address_components) {
        this.isVisible = true;
        this.noGeoLocation = false;
        this.extractAddressComponents(place);

        this.addressPrefillDirty = true;
        if (this.useAutosave) {
          // this.autosaveDataChange(this.groupModel);
        }
        this.groupModelChange.emit(this.groupModel);

        this.addressEvent.emit(this.groupModel);
      } else {
        this.noGeoLocation = true;
        return;
      }
    });
  }

  extractAddressComponents(
    //@ts-ignore
    place: google.maps,
  ): any {
    //@ts-ignore
    let addressComponents: google.maps.GeocoderAddressComponent[] =
      place.address_components;
    let street_number = '';
    let street = '';
    let city = '';
    let country = '';
    let postal_code = '';
    let province = '';
    let full_address = place.formatted_address;
    let lat = place.geometry.location.lat();
    let long = place.geometry.location.lng();
    addressComponents.forEach((component) => {
      const types = component.types;
      if (types.includes('street_number')) {
        street_number = component.long_name;
      } else if (types.includes('route')) {
        street = component.long_name;
      } else if (types.includes('locality')) {
        city = component.long_name;
      } else if (types.includes('country')) {
        country = component.long_name;
      } else if (types.includes('postal_code')) {
        postal_code = component.long_name;
      } else if (types.includes('administrative_area_level_1')) {
        province = component.long_name;
      }
    });

    this.groupModel = {
      ...this.groupModel,
      street_number,
      street,
      city,
      country,
      postal_code,
      province,
      full_address,
      lat,
      long,
    };
  }

  public onManualSave(): void {
    if (this.form.dirty || this.addressPrefillDirty || this.sectorChanged) {
      this.autosaveDataChange(this.groupModel);
    }
    // this.autosaveDataChange(this.groupModel);
  }

  public override autosaveDataChange(data: any): void {
    //this.logger.info("AddressComponent.autosaveDataChange() -> data: " + JSON.stringify(data));
    if (this.groupModel?.street_number && this.groupModel?.street_number) {
    }

    this.addressService.updateAddress(data, this.groupModel.uuid).subscribe({
      next: (response) => {
        //this.logger.info("AddressComponent.autosaveDataChange() -> SUCCESS: " + JSON.stringify(response));
        this.addressPrefillDirty = false;
        this.sectorChanged = false;
      },
      error: (error) => {
        this.logger.error(
          'AddressComponent.autosaveDataChange() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public onSectorSelected(sector: SectorModel): void {
    // this.logger.info("AddressComponent.onSectorSelected() -> sector: " + JSON.stringify(sector));

    this.groupModel.sector = sector;
    this.sectorChanged = true;
    // this.autosaveDataChange(this.groupModel);
  }
}
