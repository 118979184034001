import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  CalendarDateFormatter,
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarMonthViewDay,
  DateAdapter,
} from 'angular-calendar';
import { ItemUrlFinder } from '../../../utils/item-url-finder';
import moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  startWith,
  switchMap,
  catchError,
  map,
} from 'rxjs/operators';
import { Subject, merge, Subscription, fromEvent, of } from 'rxjs';
import { fuseAnimations } from '../../../../../@fuse/animations/index';
import { FuseConfirmDialogComponent } from '../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { DatatableHelperService } from '../../../../services/datatable-helper.service';
import { FileService } from '../../../../services/file.service';
import { PrintService } from '../../../../services/print.service';
import { EmployeesService } from '../../../api/employees.service';
import { Gt2ApiService } from '../../../api/gt2-api.service';
import { CreateClientComponent } from '../../../components/dialogs/create-client/create-client.component';
import { CreateEmployeeComponent } from '../../../components/dialogs/create-employee/create-employee.component';
import { GenericAlertComponent } from '../../../components/generic-alert/generic-alert.component';
import { AppRoutesService } from '../../../services/app-routes.service';
import { GT2PageAbstract } from '../../abstract/GT2PageAbstract';
import { CustomDateFormatter } from '../../calendar/CustomDateFormatter';
import { FuseCalendarEventFormDialogComponent } from '../../calendar/event-form/event-form.component';
import { CalendarEventModel } from '../../calendar/event.model';
import { CrudEmployeeService } from '../crud-employee/crud-employee.service';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { ApiRoutes } from '../../../api/ApiRoutes';
import { Title } from '@angular/platform-browser';
import { RolesService } from '../../../services/roles.service';
import { GT2DateAdapter } from '../../../utils/GT2DateAdapter';

@Component({
  selector: 'app-crud-employees',
  templateUrl: './crud-employees.component.html',
  styleUrls: ['./crud-employees.component.scss'],
  animations: fuseAnimations,
  providers: [
    { provide: DateAdapter, useClass: GT2DateAdapter },
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})
export class CrudEmployeesComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  visible: boolean = true;
  confirmDialogRef: any;
  displayedColumns = [
    'avatar',
    'first_name',
    'employee_number',
    'email',
    'active',
    'phone',
  ];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('table') table!: MatTable<any>;
  @ViewChild('filter') filter!: ElementRef;
  @ViewChild('mainContainer') mainContainer!: ElementRef;
  filterValue: string = '';
  dataSource = new MatTableDataSource();
  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;
  isFirstLoad: boolean = true;

  // --o CALENDAR
  employeesCalendar: any;
  view: string;
  viewDate: Date;
  events!: CalendarEvent[];
  public actions: CalendarEventAction[];
  activeDayIsOpen: boolean;
  refresh: Subject<any> = new Subject();
  declare dialogRef: any;
  calendarConfirmDialogRef!: MatDialogRef<FuseConfirmDialogComponent>;
  selectedDay: any;

  // --o Subscription
  getEmployeesCalendarSubscription!: Subscription;
  fromEventSubscription!: Subscription;
  confirmDialogRefSubscription!: Subscription;
  resendInvitationSubscription!: Subscription;

  constructor(
    private employeesService: EmployeesService,
    private crudEmployeeService: CrudEmployeeService,
    private translationLoader: FuseTranslationLoaderService,
    public dialog: MatDialog,
    public router: Router,
    public rolesService: RolesService,
    public datatableHelperService: DatatableHelperService,
    private toastr: ToastrService,
    public print: PrintService,
    public fileService: FileService,
    public translate: TranslateService,
    private titleService: Title,
    private api: Gt2ApiService,
    public appRoutesService: AppRoutesService,
    private logger: NGXLogger,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    // --o Calendar
    this.view = 'month';
    this.viewDate = new Date();
    this.activeDayIsOpen = false;
    this.selectedDay = { date: moment(new Date()).startOf('day') };

    this.actions = [
      {
        label: '<i class="material-icons s-16">edit</i>',
        onClick: ({ event }: { event: CalendarEvent }): void => {
          // TODO:
          this.editEvent('edit', event);
        },
      },
      {
        label: '<i class="material-icons s-16">delete</i>',
        // TODO:
        onClick: ({ event }: { event: CalendarEvent }): void => {
          this.deleteEvent(event);
        },
      },
    ];
  }

  ngOnInit() {
    //this.titleService.setTitle(this.translate.instant("GENERIC.EMPLOYEES"));
    // --o Calendar
    // this.getEmployeesCalendarSubscription = this.employeesService.getEmployeesCalendar().subscribe(response => {
    //         // this.logger.info("CrudEmployeesComponent.getEmployeesCalendar() -> SUCCESS: " + JSON.stringify(response));
    //         this.employeesCalendar = response.data;
    //         this.events = this.employeesCalendar.map(item => {
    //             item.actions = this.actions;
    //             return new CalendarEventModel(item);
    //         });
    //     },
    //     error => {
    //         this.logger.info("CrudEmployeesComponent.getEmployeesCalendar()() -> ERROR: " + JSON.stringify(error));
    //         this.handleAPIError( error, this.dialog );
    //     }
    // );
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe({
      next: () => (this.paginator.pageIndex = 0),
    });

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(startWith(null))
      .pipe(
        switchMap(() => {
          setTimeout(() => {
            this.isLoadingResults = true;
          });

          return this.employeesService.getEmployeesWithPagination(
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize,
            this.filterValue,
          );
        }),
      )
      .pipe(
        map((data) => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.meta.pagination.total;
          this.isFirstLoad = false;
          this.mainContainer.nativeElement.scrollTop = 0;
          return data.data;
        }),
      )
      .pipe(
        catchError(() => {
          setTimeout(() => {
            this.isLoadingResults = false;
            this.isRateLimitReached = true;
            //this.logger.info("CrudEmployeesComponent.ngAfterViewInit().catch()  ");
          });
          return of([]);
        }),
      )
      .subscribe({ next: (data) => (this.dataSource.data = data) });

    this.fromEventSubscription = fromEvent(this.filter.nativeElement, 'keyup')
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => {
          if (
            this.filter?.nativeElement.value.length > 1 ||
            this.filter?.nativeElement.value === ''
          ) {
            this.paginator.pageIndex = 0;
            this.paginator?._changePageSize(this.paginator.pageSize);
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    if (this.getEmployeesCalendarSubscription) {
      this.getEmployeesCalendarSubscription.unsubscribe();
    }

    if (this.fromEventSubscription) {
      this.fromEventSubscription.unsubscribe();
    }

    if (this.confirmDialogRefSubscription) {
      this.confirmDialogRefSubscription.unsubscribe();
    }

    if (this.resendInvitationSubscription) {
      this.resendInvitationSubscription.unsubscribe();
    }
  }

  public clearSearch(): void {
    this.filterValue = '';
    this.paginator.pageIndex = 0;
    this.paginator?._changePageSize(this.paginator.pageSize);
  }

  public syncPrimaryPaginator(event: PageEvent) {
    //this.logger.info("syncPrimaryPaginator()");
    this.paginator.pageIndex = event.pageIndex;
    this.paginator.pageSize = event.pageSize;
    this.paginator?.page.emit(event);
  }

  public onCreateNewEmployee(event: any): void {
    // this.router.navigateByUrl("employee/create");
    this.dialogRef = this.dialog.open(CreateEmployeeComponent, {
      width: '340px',
      disableClose: false,
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          // this.reloadClientsTable();
          // this.logger.info("======> " + AppRoutes.EMPLOYEES + "/" + result.slug + "/" + result.uuid);
          // this.router.navigateByUrl(AppRoutes.EMPLOYEES + "/" + result.slug + "/" + result.uuid);
          this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
        }
        this.dialogRef = null;
      },
    });
  }

  public onDeleteEmployee(uuid: string): void {
    // this.logger.info("CrudEmployeesComponent.onDeleteEmployee()");

    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');
    // this.confirmDialogRef.componentInstance.confirmMessage = this.translate.instant("EMPLOYEES.DELETE_CONFIRM");

    this.confirmDialogRefSubscription = this.confirmDialogRef
      .afterClosed()
      .subscribe({
        next: (result: any) => {
          if (result) {
            this.crudEmployeeService.deleteEmployee({ uuid: uuid }).subscribe({
              next: (response) => {
                // this.logger.info("CrudEmployeesComponent.onDeleteEmployee() -> SUCCESS: " + JSON.stringify(response));
                this.toastr.success(
                  this.translate.instant('GENERIC.DELETE_SUCCESS'),
                );
              },
              error: (error) => {
                this.logger.error(
                  'CrudEmployeesComponent.onDeleteEmployee() -> ERROR: ' +
                    JSON.stringify(error),
                );
                // this.toastr.error('', 'Un erreur est survenue lors de l\'effaçage de l\'employé');
                // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
                this.handleAPIError(
                  error,
                  this.dialog,
                  this.toastr,
                  this.translate.instant('GENERIC.ERRORS.GENERIC'),
                );
              },
            });
          }
          this.confirmDialogRef = null;
        },
      });
  }

  public onDuplicateEmployee(): void {
    // this.logger.info("CrudEmployeesComponent.onDuplicateEmployee()");
  }

  public onSendInvite(uuid: string): void {
    this.logger.debug('CrudEmployeesComponent.onSendInvite()');
    this.resendInvitationSubscription = this.crudEmployeeService
      .resendInvitation(uuid)
      .subscribe({
        next: (response) => {
          // this.logger.info("EmployeeLeftNavComponent.onSendInvite() -> SUCCESS: " + JSON.stringify(response));
          this.toastr.success(
            this.translate.instant('EMPLOYEES.INVITE_SUCCESS'),
          );
        },
        error: (error) => {
          this.logger.error(
            'CrudEmployeesComponent.onSendInvite() -> ERROR: ' +
              JSON.stringify(error),
          );
          // this.loading = false;
          // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.GENERIC'),
          );
        },
      });
  }

  /**
   * Before View Renderer
   * @param {any} header
   * @param {any} body
   */
  beforeMonthViewRender({ header, body }: { header: any; body: any }) {
    // console.info('beforeMonthViewRender');
    /**
     * Get the selected day
     */
    const _selectedDay = body.find((_day: any) => {
      return _day.date.getTime() === this.selectedDay.date.getTime();
    });

    if (_selectedDay) {
      /**
       * Set selectedday style
       * @type {string}
       */
      _selectedDay.cssClass = 'mat-elevation-z3';
    }
  }

  /**
   * Day clicked
   * @param {MonthViewDay} day
   */
  dayClicked(day: CalendarMonthViewDay): void {
    const date: Date = day.date;
    const events: CalendarEvent[] = day.events;

    if (moment(date).isSame(moment(this.viewDate), 'month')) {
      if (
        (moment(this.viewDate).isSame(moment(date), 'day') &&
          this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.viewDate = date;
      }
    }
    this.selectedDay = day;
    this.refresh.next(undefined);
  }

  /**
   * Event times changed
   * Event dropped or resized
   * @param {CalendarEvent} event
   * @param {Date} newStart
   * @param {Date} newEnd
   */
  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    // console.warn('Dropped or resized', event);
    this.refresh.next(true);
  }

  /**
   * Delete Event
   * @param event
   */
  deleteEvent(event: any) {
    // this.calendarConfirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
    //     disableClose: false
    // });
    //
    // this.calendarConfirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';
    //
    // this.calendarConfirmDialogRef.afterClosed().subscribe(result => {
    //     if ( result )
    //     {
    //         const eventIndex = this.events.indexOf(event);
    //         this.events.splice(eventIndex, 1);
    //         this.refresh.next(true);
    //     }
    //     this.calendarConfirmDialogRef = null;
    // });
  }

  /**
   * Edit Event
   * @param {string} action
   * @param {CalendarEvent} event
   */
  editEvent(action: string, event: CalendarEvent) {
    // this.logger.info("CrudEmployeesComponent.editEvent() -> event: " + JSON.stringify(event));
    const eventModel: CalendarEventModel = <CalendarEventModel>event;
    const url: string =
      '/employees/' + eventModel.context.slug + '/' + eventModel.context.uuid;
    this.router.navigateByUrl(url);

    // const eventIndex = this.events.indexOf(event);
    //
    // this.dialogRef = this.dialog.open(FuseCalendarEventFormDialogComponent, {
    //     panelClass: 'event-form-dialog',
    //     data      : {
    //         event : event,
    //         action: action
    //     }
    // });
    //
    // this.dialogRef.afterClosed()
    //     .subscribe(response => {
    //         if ( !response )
    //         {
    //             return;
    //         }
    //         const actionType: string = response[0];
    //         const formData: FormGroup = response[1];
    //         switch ( actionType )
    //         {
    //             /**
    //              * Save
    //              */
    //             case 'save':
    //
    //                 this.events[eventIndex] = Object.assign(this.events[eventIndex], formData.getRawValue());
    //                 this.refresh.next(true);
    //
    //                 break;
    //             /**
    //              * Delete
    //              */
    //             case 'delete':
    //
    //                 this.deleteEvent(event);
    //
    //                 break;
    //         }
    //     });
  }

  /**
   * Add Event
   */
  addEvent(): void {
    this.dialogRef = this.dialog.open(FuseCalendarEventFormDialogComponent, {
      panelClass: 'event-form-dialog',
      data: {
        action: 'new',
        date: this.selectedDay.date,
      },
    });
    this.dialogRef.afterClosed().subscribe({
      next: (response: UntypedFormGroup) => {
        if (!response) {
          return;
        }
        const newEvent = response.getRawValue();
        newEvent.actions = this.actions;
        this.events?.push(newEvent);
        this.refresh.next(true);
      },
    });
  }

  public doPrint(url: string): void {
    this.print.printHTML(url).subscribe({
      next: (response: any) => {
        // this.logger.info("CrudEmployeesComponent.doPrint() -> SUCCESS: " + JSON.stringify(response));
      },
      error: (error: any) => {
        this.logger.error(
          'CrudEmployeesComponent.doPrint() -> ERROR: ' + JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public doDownload(url: string): void {
    this.fileService.downloadFile(url).subscribe({
      next: (response: any) => {
        // this.logger.info("CrudEmployeesComponent.doDownload() -> SUCCESS: " + JSON.stringify(response));
      },
      error: (error: any) => {
        this.logger.error(
          'CrudEmployeesComponent.doDownload() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public onPrint(): void {
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PRINT,
      'employees',
      'fr-CA',
    );
    // this.print.printHTML(url);
    this.doPrint(url);
  }

  public onDownloadPDF(): void {
    // this.logger.info("onDownloadPDF()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PDF,
      'employees',
      'fr-CA',
    );
    // this.fileService.downloadFile(url);
    this.doDownload(url);
  }

  public onDownloadVCard(): void {
    // this.logger.info("onDownloadVCard()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_XLS,
      'employees',
      'fr-CA',
    );
    window.location.href = url;
  }

  public onDownloadXLS(): void {
    // this.logger.info("onDownloadVCard()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_XLS,
      'employees',
      'fr-CA',
    );
    // this.fileService.downloadFile(url);
    this.doDownload(url);
  }

  public onOpenInNewTab(): void {
    window.open(this.router.url, '_blank');
  }

  // TEST
  public onCreateClient(): void {
    this.logger.debug('CrudEmployeesComponent.onCreateClient()');

    this.dialogRef = this.dialog.open(CreateClientComponent, {
      width: '340px',
      disableClose: false,
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          // this.logger.info("POP UP Closed!");
        }
        this.dialogRef = null;
      },
    });
  }
}
