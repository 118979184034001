{
  "name": "ninja-gt3-app",
  "version": "3.0.0",
  "subVersion": "1",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "build-dev-gt3": "node --max_old_space_size=16000 ./node_modules/@angular/cli/bin/ng build --prod --aot --build-optimizer --configuration=dev",
    "build-demo": "node --max_old_space_size=16000 ./node_modules/@angular/cli/bin/ng build --prod --aot --build-optimizer --configuration=demo",
    "deploy-gt3": "firebase deploy --only hosting:gt3"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.0",
    "@angular/cdk": "^18.2.12",
    "@angular/common": "^18.2.0",
    "@angular/compiler": "^18.2.0",
    "@angular/core": "^18.2.0",
    "@angular/fire": "^18.0.1",
    "@angular/flex-layout": "^15.0.0-beta.42",
    "@angular/forms": "^18.2.0",
    "@angular/localize": "^18.2.11",
    "@angular/material": "^18.2.12",
    "@angular/material-moment-adapter": "^18.2.12",
    "@angular/platform-browser": "^18.2.0",
    "@angular/platform-browser-dynamic": "^18.2.0",
    "@angular/router": "^18.2.0",
    "@bugsnag/js": "^8.1.2",
    "@bugsnag/plugin-angular": "^8.1.2",
    "@fortawesome/fontawesome-free": "^6.7.1",
    "@ngneat/transloco": "^6.0.4",
    "@ngx-progressbar/http": "^5.3.2",
    "@ngx-translate/core": "^16.0.3",
    "@ngx-translate/http-loader": "^16.0.0",
    "@stripe/stripe-js": "^4.9.0",
    "@swimlane/ngx-charts": "^20.5.0",
    "@swimlane/ngx-datatable": "^20.1.0",
    "@types/googlemaps": "^3.43.3",
    "angular-calendar": "^0.31.1",
    "angular2-text-mask": "^9.0.0",
    "angularx-flatpickr": "^8.0.0",
    "apexcharts": "3.54.1",
    "bootstrap": "^5.3.3",
    "chart.js": "^4.4.6",
    "crypto-js": "^4.2.0",
    "file-saver": "^2.0.5",
    "firebase": "^10.14.1",
    "flatpickr": "^4.6.13",
    "fullcalendar": "4.0.0-alpha.4",
    "fullcalendar-scheduler": "4.0.0-alpha.4",
    "highlight.js": "^11.10.0",
    "lodash-es": "^4.17.21",
    "luxon": "^3.5.0",
    "moment": "^2.30.1",
    "moment-timezone": "^0.5.46",
    "ng-apexcharts": "1.12.0",
    "ng-select2-component": "^15.2.1",
    "ng2-currency-mask": "^13.0.3",
    "ngx-clipboard": "^16.0.0",
    "ngx-color-picker": "^17.0.0",
    "ngx-colors": "^3.6.0",
    "ngx-cookie-service": "^18.0.0",
    "ngx-daterangepicker-material": "^6.0.4",
    "ngx-device-detector": "^8.0.0",
    "ngx-dropzone": "^3.1.0",
    "ngx-ellipsis": "^5.0.1",
    "ngx-filesaver": "^18.0.0",
    "ngx-logger": "^5.0.12",
    "ngx-mat-daterange-picker": "^1.1.5",
    "ngx-material-timepicker": "^13.1.1",
    "ngx-multiple-dates": "^18.0.0",
    "ngx-permissions": "^17.1.0",
    "ngx-pipes": "^3.2.2",
    "ngx-popper": "^7.0.0",
    "ngx-progressbar": "^12.0.2",
    "ngx-select-ex": "^8.0.2",
    "ngx-spinner": "^17.0.0",
    "ngx-stripe": "^18.1.0",
    "ngx-toastr": "^19.0.0",
    "perfect-scrollbar": "^1.5.6",
    "popper.js": "^1.16.1",
    "primeicons": "^7.0.0",
    "primeng": "^17.18.11",
    "prismjs": "^1.29.0",
    "quill": "^2.0.2",
    "react": "^18.3.1",
    "react-dom": "^18.3.1",
    "rrule": "^2.8.1",
    "rxjs": "~7.8.0",
    "select2": "^4.1.0-rc.0",
    "text-mask-addons": "^3.8.0",
    "tslib": "^2.3.0",
    "urlcat": "^3.1.0",
    "xlsx": "^0.18.5",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.11",
    "@angular/cli": "^18.2.11",
    "@angular/compiler-cli": "^18.2.0",
    "@angularclass/hmr": "^3.0.0",
    "@tailwindcss/typography": "^0.5.15",
    "@types/chroma-js": "^2.4.4",
    "@types/crypto-js": "^4.2.2",
    "@types/file-saver": "^2.0.7",
    "@types/highlight.js": "^10.1.0",
    "@types/jasmine": "~5.1.0",
    "@types/lodash": "^4.17.13",
    "@types/lodash-es": "^4.17.12",
    "@types/luxon": "^3.4.2",
    "@types/node": "^22.9.0",
    "@types/prismjs": "^1.26.5",
    "@types/react": "^18.3.12",
    "@types/react-dom": "^18.3.1",
    "autoprefixer": "^10.4.20",
    "chroma-js": "^3.1.2",
    "firebase-tools": "^13.24.2",
    "install": "^0.13.0",
    "jasmine-core": "~5.2.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "lodash": "^4.17.21",
    "npm": "^10.9.0",
    "postcss": "^8.4.49",
    "prettier": "^3.3.3",
    "prettier-plugin-organize-imports": "^4.1.0",
    "prettier-plugin-tailwindcss": "^0.6.9",
    "tailwindcss": "^3.4.15",
    "typescript": "~5.5.2"
  }
}
