<div
  id="congresses"
  class="page-layout simple left-sidebar inner-sidebar inner-scroll"
  style="overflow-x: scroll"
>
  <!-- HEADER -->
  <div class="gt2-theme">
    <div
      class="header accent pl-24 pr-24 h-80 mat-elevation-z1"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fusePerfectScrollbar
      style="min-width: 1023px"
    >
      <div fxFlex="100" fxLayout="column" fxLayoutAlign="center start">
        <div class="h1 mt-8">{{ "CONGRESSES.CONGRESSES" | translate }}</div>
      </div>
    </div>
  </div>
  <!-- / HEADER -->
  <div
    #mainContainer
    class="center p-24 pr-56"
    fusePerfectScrollbar
    style="min-width: 1023px"
  >
    <div
      fxLayout="column"
      fxLayoutAlign="center center"
      class="range-picker-container gt2-theme"
    >
      <div fxLayout="row" fxLayoutAlign="center center">
        <mat-icon
          (click)="onOpenRangePicker($event)"
          class="s-36 mr-12 button-cursor"
          >{{ getIcon("TIME") }}</mat-icon
        >

        <input
          matInput
          type="text"
          ngxDaterangepickerMd
          [(ngModel)]="selected"
          [showCustomRangeLabel]="true"
          [alwaysShowCalendars]="true"
          [ranges]="ranges"
          [linkedCalendars]="true"
          class="datepicker-content"
          placeholder="{{ 'GENERIC.CHOOSE_A_DATE' | translate }}"
          [showCancel]="true"
          [locale]="localeDate"
          (ngModelChange)="updateRange($event)"
        />
        <!-- <ngx-mat-drp
          (selectedDateRangeChanged)="updateRange($event)"
          [options]="rangeOptions"
          #dateRangePicker
        >
        </ngx-mat-drp> -->
      </div>

      <mat-checkbox
        class="mb-24"
        labelPosition="after"
        (change)="onShowCancelled($event)"
        [(ngModel)]="includeCancelled"
        [ngModelOptions]="{ standalone: true }"
      >
        {{ "GENERIC.INCLUDE_SHOW_CANCELED" | translate }}
      </mat-checkbox>
    </div>
    <div style="min-width: 1023px" class="mat-white-bg mat-elevation-z2">
      <div class="px-8 pt-8" fxLayout="row" fxLayoutAlign="end center">
        <!-- SEARCH -->
        <div
          class=""
          fxFlex="1 0 auto"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <label for="search" class="mr-8">
            <mat-icon class="">search</mat-icon>
          </label>
          <mat-form-field floatLabel="auto" fxFlex="1 0 auto" class="">
            <mat-label>{{
              "GENERIC.FILTERING_PLACEHOLDER" | translate | titlecase
            }}</mat-label>
            <input
              id="search"
              [(ngModel)]="filterValue"
              matInput
              #filter
              [disabled]="
                false &&
                dataSource &&
                dataSource.data &&
                dataSource.data.length === 0
              "
            />
            <button
              mat-button
              *ngIf="filterValue"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="clearSearch()"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>

        <!-- / SEARCH -->

        <div class="mat-body-2 p-8 ml-8">
          {{ resultsLength }}
          {{ "CONGRESSES.CONGRESSES" | translate }}
        </div>
      </div>
      <div class="border-bottom">
        <div
          *ngIf="!isLoadingResults && resultsLength === 0"
          fxLayout="row"
          fxLayoutAlign="center start"
          class="pb-8 mat-title"
          style="margin-bottom: -3px"
        >
          {{ "GENERIC.NO_RESULTS" | translate }}
        </div>

        <div *ngIf="isLoadingResults" class="">
          <mat-progress-bar
            mode="query"
            color="warn"
            style="height: 3px"
          ></mat-progress-bar>
        </div>
      </div>

      <div>
        <div class="border-bottom">
          <mat-paginator
            #paginator
            [length]="resultsLength"
            [ngStyle]="{
              display:
                dataSource && dataSource.data && dataSource.data.length === 0
                  ? 'none'
                  : '',
            }"
            (page)="datatableHelperService.onPaginator($event, 'products')"
            showFirstLastButtons="true"
            [pageIndex]="0"
            [pageSize]="'products' | tableSizePipe: 'large'"
            [pageSizeOptions]="datatableHelperService.pageSizeOptions"
          >
          </mat-paginator>
        </div>

        <mat-table
          #table
          class=""
          matSort
          matSortStart="desc"
          *ngIf="dataSource && dataSource.data"
          [ngStyle]="{
            display:
              dataSource && dataSource.data && dataSource.data.length === 0
                ? 'none'
                : '',
          }"
          [@animateStagger]="{ value: '50' }"
          [dataSource]="dataSource"
        >
          <ng-container cdkColumnDef="name">
            <mat-header-cell fxFlex="50" *cdkHeaderCellDef mat-sort-header>
              {{ "GENERIC.NAME" | translate }}
            </mat-header-cell>

            <mat-cell
              class="no-text-cursor"
              fxFlex="50"
              *cdkCellDef="let congress"
            >
              <p class="text-truncate font-weight-500">{{ congress.name }}</p>
            </mat-cell>
          </ng-container>

          <!-- START DATE-->
          <ng-container cdkColumnDef="date_start">
            <mat-header-cell fxFlex="50" *cdkHeaderCellDef mat-sort-header>
              {{ "GENERIC.DATE_START" | translate }}
            </mat-header-cell>

            <mat-cell
              class="no-text-cursor"
              fxFlex="50"
              *cdkCellDef="let congress"
            >
              <p class="text-truncate font-weight-500">
                {{ congress.date_start }}
              </p>
            </mat-cell>
          </ng-container>

          <!-- END DATE-->
          <ng-container cdkColumnDef="date_end">
            <mat-header-cell fxFlex="50" *cdkHeaderCellDef mat-sort-header>
              {{ "GENERIC.DATE_END" | translate }}
            </mat-header-cell>

            <mat-cell
              class="no-text-cursor"
              fxFlex="50"
              *cdkCellDef="let congress"
            >
              <p class="text-truncate font-weight-500">
                {{ congress.date_end }}
              </p>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="congress_number">
            <mat-header-cell fxFlex="50" *cdkHeaderCellDef mat-sort-header>
              {{ "GENERIC.CONGRESS_NUMBER_DYN" | translate | scpmPipe }}
            </mat-header-cell>

            <mat-cell
              class="no-text-cursor"
              fxFlex="50"
              *cdkCellDef="let congress"
            >
              <p class="text-truncate font-weight-500">
                {{ congress.congress_number }}
              </p>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="newTab">
            <mat-header-cell fxFlex="10" *cdkHeaderCellDef> </mat-header-cell>
            <mat-cell
              class="no-text-cursor"
              fxFlex="10"
              *cdkCellDef="let congress"
            >
              <button
                mat-icon-button
                matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
                (click)="
                  $event.stopPropagation(); onOpenCongresInNewTab(congress)
                "
                class="mat-icon-button"
              >
                <mat-icon class="s-20">{{ getIcon("NEW_TAB") }}</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>

          <mat-row
            *cdkRowDef="let congress; columns: displayedColumns"
            class="mat-row-color"
            matRipple
            [routerLink]="
              '/' +
              appRoutesService.congresses +
              '/' +
              congress.slug +
              '/' +
              congress.uuid
            "
          >
          </mat-row>
        </mat-table>
        <mat-paginator
          #paginatorBottom
          [length]="paginator.length"
          (page)="syncPrimaryPaginator($event)"
          [ngStyle]="{
            display:
              dataSource && dataSource.data && dataSource.data.length === 0
                ? 'none'
                : '',
          }"
          showFirstLastButtons="true"
          [pageIndex]="paginator.pageIndex"
          [pageSize]="paginator.pageSize"
          [pageSizeOptions]="paginator.pageSizeOptions"
        >
        </mat-paginator>
        <div
          *ngIf="isLoadingResults && !isFirstLoad"
          fxLayout="row"
          fxLayoutAlign="center start"
          class=""
        >
          <mat-progress-bar
            mode="query"
            color="warn"
            style="height: 3px"
          ></mat-progress-bar>
        </div>
      </div>
    </div>
    <div class="spacer" style="height: 46px"></div>
  </div>
</div>

<div *ngxPermissionsOnly="rolesService.TEAM_LEAD_ROLES">
  <div class="fixed-button">
    <button
      mat-fab
      class="gt2-create-button"
      id="add-congress-button"
      (click)="onCreateNewCongress($event)"
      *fuseIfOnDom
      [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }"
    >
      <mat-icon class="icon-mat-black">add</mat-icon>
    </button>
  </div>
</div>
