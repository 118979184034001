import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { GT2FormPageAbstract } from '../../content/abstract/GT2FormPageAbstract';
import { ApiBenching, APICallBenching } from '../api-benching';
import { MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';

@Component({
  selector: 'app-benching-dialog',
  templateUrl: './benching-dialog.component.html',
  styleUrls: ['./benching-dialog.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    MatButtonModule,
    TranslateModule,
    MatInputModule,
    Gt2PrimengModule,
    CommonModule,
  ],
})
export class BenchingDialogComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  callStack?: APICallBenching[];

  constructor(public compDialogRef: MatDialogRef<BenchingDialogComponent>) {
    super();
  }

  ngOnInit() {
    this.callStack = ApiBenching.callStack;
    ApiBenching.callStackChanged.subscribe({
      next: (callStack) => {
        this.callStack = callStack;
      },
    });
  }

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public clearStack(): void {
    ApiBenching.clearStack();
  }
}
