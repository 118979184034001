import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ItemUrlFinder } from '../../../utils/item-url-finder';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { CreateEnterpriseBranchService } from '../../../api/create-enterprise-branch.service';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import { Settings } from '../../../settings/settings';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-create-enterprise-branch',
  templateUrl: './create-enterprise-branch.component.html',
  styleUrls: ['./create-enterprise-branch.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    FormsModule,
    MatButtonModule,
    FlexLayoutModule,
    MatInputModule,
    ReactiveFormsModule,
    CommonModule,
  ],
})
export class CreateEnterpriseBranchComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  static CREATE: string = 'CREATE';
  static CREATE_BRANCH_FOR_ENTERPRISE: string = 'CREATE_BRANCH_FOR_ENTERPRISE';

  form: UntypedFormGroup;
  branchOrEnterprise: any;
  loading: boolean = false;
  type: string;
  uuid!: string;

  constructor(
    public override dialogRef: MatDialogRef<CreateEnterpriseBranchComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    private createEnterpriseService: CreateEnterpriseBranchService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService,
    private router: Router,
    public dialog: MatDialog,
    private logger: NGXLogger,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    // //this.logger.info(">>> >> > " + JSON.stringify(data));
    if (data) {
      this.type = data.type;
      this.uuid = data.uuid;
    } else {
      this.type = CreateEnterpriseBranchComponent.CREATE;
    }

    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      // last_name: ['', [Validators.minLength(2), Validators.maxLength(100), Validators.required]],
      // gender: '',
    });

    this.branchOrEnterprise = {
      name: null,
    };
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public onCreateEnterpriseBranch(): void {
    this.loading = true;
    //this.logger.info("CreateEnterpriseBranchComponent.onCreateEnterpriseBranch() -> " + this.form.valid);
    //this.logger.info("CreateEnterpriseBranchComponent.onCreateEnterpriseBranch() -> this.branchOrEnterprise: " + JSON.stringify(this.branchOrEnterprise));
    if (this.form.valid) {
      if (
        this.type ===
        CreateEnterpriseBranchComponent.CREATE_BRANCH_FOR_ENTERPRISE
      ) {
        this.createBranchForEnterprise();
      } else {
        this.createEnterprise();
      }
    }
  }

  public createBranchForEnterprise(): void {
    this.loading = true;
    this.createEnterpriseService
      .createBranchForEnterprise(this.branchOrEnterprise, this.uuid)
      .subscribe({
        next: (response: any) => {
          // //this.logger.info("CreateEnterpriseBranchComponent.createBranchForEnterprise() -> SUCCESS: " + JSON.stringify(response));
          this.toastr.success(
            '',
            this.translate.instant('CREATE_ENTERPRISE.CREATE_SUCCESS'),
          );

          // this.router.navigateByUrl(AppRoutes.ENTERPRISES + "/" + response.data.slug + "/" +  response.data.uuid);
          this.router.navigateByUrl(ItemUrlFinder.getItemURL(response.data));

          this.dialogRef.close(response.data);
          this.loading = false;
        },
        error: (error: any) => {
          this.logger.error(
            'CreateEnterpriseBranchComponent.createBranchForEnterprise() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.GENERIC'),
          );
        },
      });
  }

  public createEnterprise(): void {}
}
