<div fxLayout="column" class="custom-dialog-container">
  <div class="profile-box info-box gt2-theme-extended2" fxLayout="column">
    <header class="warn" fxLayout="row" fxLayoutAlign="space-between center">
      <div *ngIf="!group" fxLayout="row" fxLayoutAlign="start center">
        <div class="title">{{ "PRODUCTS.ADD_GROUP_OPTION" | translate }}</div>
      </div>

      <div
        *ngIf="group && !editTitle"
        fxLayout="row"
        fxLayoutAlign="start center"
      >
        <div class="title">{{ group.name }}</div>
        <button
          class=""
          mat-icon-button
          matTooltip="{{ 'PRODUCTS.EDIT_GROUP_NAME' | translate }}"
          (click)="onEditGroupName()"
          tabindex="-1"
        >
          <mat-icon class="s-16">{{ getIcon("EDIT") }}</mat-icon>
        </button>
      </div>

      <div
        *ngIf="group && editTitle"
        fxLayout="row"
        fxLayoutAlign="start center"
        [formGroup]="groupNameform"
      >
        <mat-form-field class="title-edit">
          <input
            matInput
            formControlName="name"
            type="text"
            autocomplete="nope"
            [(ngModel)]="group.name"
            required
          />
          <mat-error *ngIf="groupNameform.controls['name'].invalid">{{
            "GENERIC.INPUT_LENGTH_INVALID" | translate
          }}</mat-error>
        </mat-form-field>

        <button
          mat-raised-button
          class="accent p-2 mr-16"
          (click)="onSaveGroupName()"
        >
          {{ "GENERIC.SAVE" | translate }}
        </button>

        <button
          mat-raised-button
          class="primary p-2 mr-16"
          (click)="editTitle = false"
        >
          {{ "GENERIC.CANCEL" | translate }}
        </button>
      </div>

      <div *ngIf="group" class="mr-24">
        <mat-checkbox
          color="accent"
          [(ngModel)]="group.choice_required_no_skip"
          [ngModelOptions]="{ standalone: true }"
          class=""
        >
          {{ "PRODUCTS.CHOICE_REQUIRED_NO_SKIP" | translate }}
        </mat-checkbox>
      </div>

      <!-- <div *ngIf="creationMode" class="title">{{'ORDER_PRODUCTS.TITLE_CREATE' | translate }}</div> -->

      <!--<div class="gt2-light-green" style="width: 60px; height: 56px;" fxLayout="row"
                 fxLayoutAlign="center center">
                <button type="button" matTooltip="{{ 'ORDER_PRODUCTS.CREATE_PRODUCT' | translate }}" mat-icon-button
                        class="" (click)="onCreateProduct()">
                    <mat-icon class="" color="">add</mat-icon>
                </button>
            </div>-->
    </header>
  </div>

  <div fxLayout="column" class="px-16">
    <mat-form-field floatLabel="auto" fxFlex="1 0 auto" class="mr-4">
      <mat-label>{{ "GENERIC.SEARCH_PRODUCT_DETAILED" | translate }}</mat-label>
      <input #filter id="search" [(ngModel)]="filterValue" matInput />
      <button
        mat-button
        *ngIf="filterValue"
        matSuffix
        mat-icon-button
        aria-label="Search"
        (click)="loadProductList()"
      >
        <mat-icon>search</mat-icon>
      </button>
      <button
        mat-button
        *ngIf="filterValue"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="clearSearch()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <div fxLayout="row">
      <div fxLayout="column" fxFlex="100%">
        <div
          fxLayout="row"
          fxLayoutAlign="center start"
          class="pb-4 mat-caption"
        >
          {{ "GENERIC.LEFT_LIST_LABEL" | translate }}
        </div>
      </div>
      <div fxLayout="column" fxFlex="100%">
        <div
          fxLayout="row"
          fxLayoutAlign="center start"
          class="pb-4 mat-caption"
        >
          {{ "GENERIC.RIGHT_LIST_LABEL" | translate }}
        </div>
      </div>
    </div>

    <div>
      <div class="" fxLayout="row">
        <div
          #productList
          class="ngx-dnd-container-custom mr-4 ngx-dnd-scrollable"
          ngxDroppable
          [ngModel]="products"
          fxLayout="column"
          fxFlex="50%"
          style="height: 400px"
        >
          <div
            *ngIf="isLoading"
            fxLayout="column"
            fxFlex="100%"
            fxLayoutAlign="center center"
          >
            <mat-spinner [diameter]="28" class=""></mat-spinner>
          </div>

          <div
            *ngIf="isEmpty"
            fxLayout="column"
            fxFlex="100%"
            fxLayoutAlign="center center"
          >
            {{ "GENERIC.SEARCH_PRODUCTS" | translate }}
          </div>

          <!-- <div *ngIf="!isLoading"> -->
          <div
            ngxDraggable="['example-target']"
            [ngModel]="item"
            *ngFor="let item of products; let i = index"
          >
            <!-- <div *ngIf="(filterValue === '' || item.name.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1)"
                                matRipple class="product-child-item" fxLayout="row" fxLayoutAlign="space-between center"> -->
            <div
              *ngIf="!isLoading"
              matRipple
              class="product-child-item"
              fxLayout="row"
              fxLayoutAlign="space-between center"
            >
              <div fxLayout="row">
                <mat-icon class="mr-8">drag_handle</mat-icon>
                <!--                                <div matTooltip="{{item.name + ' | ' + item.price_label}}" [matTooltipPosition]="'right'">{{item.name_internal}}</div>-->
                <div fxLayout="column" fxLayoutAlign="start start">
                  <div class="item-name-internal">{{ item.name_internal }}</div>
                  <div class="item-name">
                    {{
                      item.name +
                        " | " +
                        item.price_label +
                        (item.is_combo ? " | Combo" : "")
                    }}
                  </div>
                </div>
              </div>
              <div fxFlex="62px" fxLayoutAlign="end center">
                <button mat-icon-button (click)="onAddItem(item, true)">
                  <mat-icon class="">add</mat-icon>
                </button>

                <button
                  mat-button
                  matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
                  (click)="onOpenInNewTab(item)"
                  class="mat-icon-button"
                >
                  <mat-icon class="s-20">{{ getIcon("NEW_TAB") }}</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <!-- </div> -->
        </div>

        <div
          #comboList
          class="ngx-dnd-container-custom ml-4 combo-list ngx-dnd-scrollable"
          ngxDroppable="example-target"
          [ngModel]="combo"
          (drop)="onDrop($event)"
          style="height: 400px"
          fxLayout="column"
          fxFlex="50%"
        >
          <div
            class="product-child-item"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            matRipple
            ngxDraggable
            [ngModel]="item"
            *ngFor="let item of combo; let i = index"
          >
            <div fxLayout="row">
              <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="mr-8">drag_handle</mat-icon>

                <!-- <mat-chip-listbox>
                                    <mat-chip>{{item.qty}}</mat-chip>
                                </mat-chip-listbox> -->

                <!--<mat-form-field
                                    class="form-input-container" fxFlex="50px">
                                    <input matInput class="text-center" type="text"
                                           pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
                                           [(ngModel)]="item.qty"
                                           (change)="onItemQtyChange(item)" style="text-align:center;">
                                </mat-form-field>-->

                <div
                  matTooltip="{{ item.name }}"
                  [matTooltipPosition]="'right'"
                  class="ml-16"
                >
                  {{ item.name_internal }}
                </div>
              </div>
            </div>

            <div fxFlex="98px">
              <!--<mat-icon (click)="onAddItem(item, true)" class="ml-8">add</mat-icon>
                            <mat-icon (click)="onLessItem(item, i)" class="ml-8">remove</mat-icon>-->
              <mat-icon (click)="onRemoveItem(item, i)" class="ml-8"
                >delete</mat-icon
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div fxLayout="row">
      <div fxLayout="row" fxFlex="50%">&nbsp;</div>

      <div fxLayout="row" fxLayoutAlign="end start" fxFlex="50%">
        <div *ngIf="combo && combo.length > 0" class="pr-4">
          {{ combo.length }} {{ combo && combo.length > 1 ? "items" : "item" }}
        </div>
      </div>
    </div>
  </div>

  <!--HACK TO MAKE THE D&D ABOVE WORK (TODO: fix the style/code that does not seem to import here if not present-->
  <!-- <ngx-dnd-container
    [ngModel]="['Item 1', 'Item 2', 'Item 3']"
    style="display: none"
  >
  </ngx-dnd-container> -->

  <div
    mat-dialog-actions
    class="p-16"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button mat-raised-button (click)="onSaveOptions()" class="accent mr-16">
      {{ "GENERIC.SAVE" | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(false)"
      class=""
    >
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
