<div style="max-height: 100%">
  <div matDialogTitle class="custom-dialog-title mb-12">
    {{ "CONVERT_TO_USER.TITLE" | translate }}
  </div>

  <div [formGroup]="form" class="item-full-width">
    <mat-form-field class="form-input-container" fxFlex="100%">
      <mat-label>{{ "GENERIC.EMAIL" | translate }}</mat-label>
      <input
        matInput
        formControlName="email"
        type="email"
        [(ngModel)]="user_email"
      />
      <mat-error *ngIf="form.controls['email'].invalid"
        >{{ "GENERIC.EMAIL_INVALID" | translate }}
      </mat-error>
    </mat-form-field>
  </div>

  <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center start">
    <mat-spinner [diameter]="40" class="mt-20"></mat-spinner>
  </div>

  <div
    mat-dialog-actions
    class="mt-24 gt2-buttons-theme"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button
      mat-raised-button
      [disabled]="!form.valid"
      color="accent"
      class="mr-16"
      (click)="onConvertToUser()"
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>

    <button
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(false)"
      class=""
    >
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
