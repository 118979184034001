import { RolesService } from '../../../../../services/roles.service';
import { GenericAlertComponent } from '../../../../../components/generic-alert/generic-alert.component';
import { JobExpensesModalComponent } from './../job-expenses-modal/job-expenses-modal.component';
import { StaffProviderService } from './../staff-provider.service';
import { EditJobAssignmentModalComponent } from './../edit-job-assignment-modal/edit-job-assignment-modal.component';
import { ItemUrlFinder } from './../../../../../utils/item-url-finder';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreateGroupModalComponent } from './../create-group-modal/create-group-modal.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { GT2PageAbstract } from './../../../../abstract/GT2PageAbstract';
import { NGXLogger } from 'ngx-logger';
import { IconsService } from '../../../../../services/icons.service';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AssignementsService } from '../assignements.service';
import { Router } from '@angular/router';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { CdkScrollableModule, ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { Gt2SelectComponent } from '../../../../../components/gt2-select/gt2-select.component';
import { GroupAssigmentsCompletePipeModule } from '../../../../../modules/group-assigments-complete.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import PerfectScrollbar from 'perfect-scrollbar';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-assignment-group',
  templateUrl: './assignment-group.component.html',
  styleUrls: ['./assignment-group.component.scss'],
  standalone: true,
  imports: [
    MatExpansionModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    Gt2SelectComponent,
    MatSelectModule,
    GroupAssigmentsCompletePipeModule,
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatInputModule,
    CdkScrollableModule,
    ScrollingModule,
    NgxPermissionsModule,
    CdkTableModule,
    MatOptionModule,
    MatFormFieldModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
  ],
  providers: [AssignementsService, StaffProviderService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignmentGroupComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit
{
  @Input() assignmentGroup: any;
  @Input() panelOpenState: boolean = false;
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() newAssigmentModel: EventEmitter<any> = new EventEmitter();
  @Output() stateChange: EventEmitter<GroupState> = new EventEmitter();

  declare dialogRef: any;
  confirmDialogRef: any;
  noteHintMaxChar = 200;

  proposalsEnums?: ProposalSelectItem[];

  date1?: number;
  date2?: number;

  tooltipOptions = {
    'show-delay': 200,
    hideDelayTouchscreen: 200,
    // 'displayTouchscreen': true
  };

  constructor(
    private logger: NGXLogger,
    private dialog: MatDialog,
    public translate: TranslateService,
    public assignmentsService: AssignementsService,
    private spinner: NgxSpinnerService,
    public router: Router,
    public iconService: IconsService,
    public rolesService: RolesService,
    public staffProvider: StaffProviderService,
  ) {
    super();
    // this.date1 = new Date().getTime();
  }

  ngOnInit(): void {
    // //this.logger.info("AssignmentGroupComponent.ngOnInit() -> ****** !!!!! ******");
    this.proposalsEnums = [
      {
        label: this.translate.instant('ASSIGNMENTS.JOB_PROPOSAL_UNSET'),
        enum: ProposalEnums.JOB_PROPOSAL_UNSET,
      },
      {
        label: this.translate.instant('ASSIGNMENTS.JOB_PROPOSAL_ASSIGNED'),
        enum: ProposalEnums.JOB_PROPOSAL_ASSIGNED,
      },
      {
        label: this.translate.instant(
          'ASSIGNMENTS.JOB_PROPOSAL_AVAILABLE_UNASSIGNED',
        ),
        enum: ProposalEnums.JOB_PROPOSAL_AVAILABLE_UNASSIGNED,
      },
      {
        label: this.translate.instant('ASSIGNMENTS.JOB_PROPOSAL_CANCEL'),
        enum: ProposalEnums.JOB_PROPOSAL_CANCEL,
      },
      {
        label: this.translate.instant('ASSIGNMENTS.JOB_PROPOSAL_ON_HOLD'),
        enum: ProposalEnums.JOB_PROPOSAL_ON_HOLD,
      },
      {
        label: this.translate.instant('ASSIGNMENTS.JOB_PROPOSAL_PROPOSED'),
        enum: ProposalEnums.JOB_PROPOSAL_PROPOSED,
      },
      {
        label: this.translate.instant('ASSIGNMENTS.JOB_PROPOSAL_REFUSED'),
        enum: ProposalEnums.JOB_PROPOSAL_REFUSED,
      },
    ];
  }

  ngAfterViewInit(): void {
    // this.date2 = new Date().getTime();
    // //this.logger.info("AssignmentGroupComponent.ngAfterViewInit() -> date1: " + this.date1);
    // //this.logger.info("AssignmentGroupComponent.ngAfterViewInit() -> date2: " + this.date2);
    // //this.logger.info("AssignmentGroupComponent.ngAfterViewInit() -> milliseconds: " + (this.date2 - this.date1));
    //this.logger.info("AssignmentGroupComponent.ngAfterViewInit() -> assignmentGroup: " + JSON.stringify(this.assignmentGroup));
    this.sortAssigmentsList();
  }

  public sortAssigmentsList(): void {
    this.assignmentGroup.proposals = this.assignmentGroup.proposals.sort(
      (t1: any, t2: any) => {
        if (!t1.employee || !t2.employee) {
          return 0;
        }
        const name1 = t1.employee.first_name.toLowerCase();
        const name2 = t2.employee.first_name.toLowerCase();
        if (name1 > name2) {
          return 1;
        }
        if (name1 < name2) {
          return -1;
        }
        return 0;
      },
    );

    this.assignmentGroup.jobs = this.assignmentGroup.jobs.sort(
      (t1: any, t2: any) => {
        if (!t1.employee || !t2.employee) {
          return 0;
        }
        const name1 = t1.employee.first_name.toLowerCase();
        const name2 = t2.employee.first_name.toLowerCase();
        if (name1 > name2) {
          return 1;
        }
        if (name1 < name2) {
          return -1;
        }
        return 0;
      },
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    const chng = changes['assignmentGroup'];
    if (chng && chng.currentValue) {
      // //this.logger.info('AssignmentGroupComponent.ngOnChanges() -> CHANGED: assignmentGroup : ' + JSON.stringify(chng));
      //this.logger.info("AssignmentGroupComponent.ngOnChanges() -> CHANGED: assignmentGroup : ");
      // const jobs = chng.currentValue.jobs;
      // this.assignmentGroup.jobs = [...jobs];
      this.sortAssigmentsList();
    }
  }

  public trackByUUID(index: any, item: any) {
    return item.uuid;
  }

  public onIncrementGroup(): void {
    //this.logger.info("AssignmentGroupComponent.onIncrementGroup()");
    this.spinner.show('assignmentGroupSpinner');
    //this.logger.info("CreateGroupModalComponent.onIncrementGroup()" + JSON.stringify(this.assignmentGroup));
    this.assignmentsService.addAssigment(this.assignmentGroup.uuid).subscribe({
      next: (response: any) => {
        // //this.logger.info("AssignmentGroupComponent.onIncrementGroup() -> SUCCESS: " + JSON.stringify(response));
        this.spinner.hide('assignmentGroupSpinner');
        this.newAssigmentModel.emit(response);
      },
      error: (error: any) => {
        this.logger.error(
          'AssignmentGroupComponent.onIncrementGroup() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
        this.spinner.hide('assignmentGroupSpinner');
      },
    });
  }

  public onEdit(): void {
    //this.logger.info("AssignmentGroupComponent.onEdit()");
    this.dialogRef = this.dialog.open(CreateGroupModalComponent, {
      width: '580px',
      minWidth: 350,
      disableClose: false,
      panelClass: 'scrollable-dialog',
      data: {
        group: this.assignmentGroup,
      },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          //this.logger.info("AssignmentGroupComponent.onEdit() -> result: " + result);
          this.newAssigmentModel.emit(result);
        } else {
          // this.spinner.hide('assignmentSpiner');
        }
      },
    });
  }

  public onDelete(): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });
    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.spinner.show('assignmentGroupSpinner');
          //this.logger.info("CreateGroupModalComponent.onDelete()" + JSON.stringify(this.assignmentGroup));
          this.assignmentsService
            .deleteAssigmentGroup(this.assignmentGroup.uuid)
            .subscribe({
              next: (response: any) => {
                //this.logger.info("AssignmentGroupComponent.onDelete() -> SUCCESS: " + JSON.stringify(response));
                this.spinner.hide('assignmentGroupSpinner');
                this.delete.emit(true);
                this.newAssigmentModel.emit(response);
              },
              error: (error: any) => {
                this.logger.error(
                  'AssignmentGroupComponent.onDelete() -> ERROR: ' +
                    JSON.stringify(error),
                );
                this.handleAPIError(error, this.dialog, null, null);
                this.spinner.hide('assignmentGroupSpinner');
              },
            });
        }
      },
    });
  }

  public onViewEmployee(model: any): void {
    if (model && model.employee) {
      this.openInNewTab(ItemUrlFinder.getItemURL(model.employee));
    }
  }

  public onEditAssignment(assignment: any): void {
    this.dialogRef = this.dialog.open(EditJobAssignmentModalComponent, {
      width: '580px',
      minWidth: 350,
      disableClose: false,
      panelClass: 'scrollable-dialog',
      data: {
        job: assignment,
      },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        //this.logger.info("AssignmentGroupComponent.onEditAssignment().afterClosed -> : result" + result);
        if (result) {
          this.newAssigmentModel.emit(result);
        } else {
          // this.spinner.hide('assignmentSpiner');
          //this.logger.info("AssignmentGroupComponent.onDelete().afterClosed -> : else ");
        }
      },
    });
  }

  public onDeleteAssignment(assignment: any): void {
    this.spinner.show('assignmentGroupSpinner');
    this.assignmentsService.deleteAssigment(assignment.uuid).subscribe({
      next: (response: any) => {
        // //this.logger.info("AssignmentGroupComponent.onDeleteAssignment() -> SUCCESS: " + JSON.stringify(response));
        this.spinner.hide('assignmentGroupSpinner');
        this.newAssigmentModel.emit(response);
        // this.assignmentsModelUpdateService.update(response);
      },
      error: (error: any) => {
        this.logger.error(
          'AssignmentGroupComponent.onDeleteAssignment() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
        this.spinner.hide('assignmentGroupSpinner');
      },
    });
  }

  public onStateChange(value: boolean): void {
    this.panelOpenState = value;
    this.stateChange.emit({
      state: value,
      uuid: this.assignmentGroup.uuid,
    });
  }

  public onProposalStatusChange(event: any, prop: any): void {
    const oldValue = prop.status;
    prop.status = event.value;
    this.spinner.show('assignmentGroupSpinner');
    this.assignmentsService.setProposalSatus(prop.uuid, event.value).subscribe({
      next: (response: any) => {
        //this.logger.info("AssignmentGroupComponent.onProposalStatusChange() -> SUCCESS: " + JSON.stringify(response));
        this.spinner.hide('assignmentGroupSpinner');
        this.newAssigmentModel.emit(response);
      },
      error: (error: any) => {
        this.logger.error(
          'AssignmentGroupComponent.onProposalStatusChange() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
        this.spinner.hide('assignmentGroupSpinner');
        prop.status = oldValue;
      },
    });
  }

  public onJobStaffProviderChange(event: any, assignment: any): void {
    //this.logger.info("AssignmentGroupComponent.onJobStaffProviderChange()");
    assignment.staff_provider = event;
    this.spinner.show('assignmentGroupSpinner');
    this.assignmentsService
      .editJobAssigment(assignment.uuid, assignment)
      .subscribe({
        next: (response: any) => {
          // //this.logger.info("EditJobAssignmentModalComponent.onSaveJob() -> SUCCESS: " + JSON.stringify(response));
          //this.logger.info("EditJobAssignmentModalComponent.onSaveJob() -> SUCCESS: ");
          // setTimeout( () => {
          //     this.newAssigmentModel.emit(response);
          // }, 2000);
          // this.newAssigmentModel.emit(response);
          this.spinner.hide('assignmentGroupSpinner');
        },
        error: (error: any) => {
          this.logger.error(
            'EditJobAssignmentModalComponent.onSaveJob() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.handleAPIError(error, this.dialog, null, null);
          this.spinner.hide('assignmentGroupSpinner');
        },
      });
  }

  public onUnlink(assignment: any): void {
    // //this.logger.info("AssignmentGroupComponent.onUnlink() -> staffProvider: " + JSON.stringify(staffProvider));
    if (assignment.staff_provider) {
      this.spinner.show('assignmentGroupSpinner');
      this.assignmentsService.unlinkJobAssignment(assignment.uuid).subscribe({
        next: (response: any) => {
          // //this.logger.info("EditJobAssignmentModalComponent.onUnlink() -> SUCCESS: " + JSON.stringify(response));
          this.spinner.hide('assignmentGroupSpinner');
          this.newAssigmentModel.emit(response);
        },
        error: (error: any) => {
          this.logger.error(
            'EditJobAssignmentModalComponent.onUnlink() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.handleAPIError(error, this.dialog, null, null);
          this.spinner.hide('assignmentGroupSpinner');
        },
      });
    }
  }

  public onJobExpenses(assignment: any): void {
    //this.logger.info("AssignmentGroupComponent.onJobExpenses() -> assignment: " + JSON.stringify(assignment));
    this.dialogRef = this.dialog.open(JobExpensesModalComponent, {
      width: '580px',
      minWidth: 350,
      disableClose: false,
      panelClass: 'custom-dialog-container',
      data: {
        groupUUID: this.assignmentGroup.uuid,
        job: assignment,
      },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        //this.logger.info("AssignmentGroupComponent.onJobExpenses().afterClosed() -> result: " + result);
        if (!result) {
          // cleanup unsaved expenses
          assignment.job_expenses = assignment.job_expenses.filter(
            (item: any) => {
              return item.uuid;
            },
          );
        } else {
          //this.logger.info("AssignmentGroupComponent.onJobExpenses().afterClosed() -> result: " + result);
          this.newAssigmentModel.emit(result);
        }
      },
    });
  }
}

export interface GroupState {
  state: boolean;
  uuid: string;
}

export enum ProposalEnums {
  JOB_PROPOSAL_UNSET = 'JOB_PROPOSAL_UNSET',
  JOB_PROPOSAL_ASSIGNED = 'JOB_PROPOSAL_ASSIGNED',
  JOB_PROPOSAL_AVAILABLE_UNASSIGNED = 'JOB_PROPOSAL_AVAILABLE_UNASSIGNED',
  JOB_PROPOSAL_CANCEL = 'JOB_PROPOSAL_CANCEL',
  JOB_PROPOSAL_ON_HOLD = 'JOB_PROPOSAL_ON_HOLD',
  JOB_PROPOSAL_PROPOSED = 'JOB_PROPOSAL_PROPOSED',
  JOB_PROPOSAL_REFUSED = 'JOB_PROPOSAL_REFUSED',
}

export interface ProposalSelectItem {
  label: string;
  enum: string;
}
