import { Settings } from './../../../settings/settings';
import { NotificationMessagesService } from './../../../api/notification-messages.service';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Editor, EditorModule } from 'primeng/editor';
import { Subscription } from 'rxjs';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { GT2FormPageAbstract } from '../../abstract/GT2FormPageAbstract';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-notification-message',
  templateUrl: './notification-message.component.html',
  styleUrls: ['./notification-message.component.scss'],
  standalone: true,
  imports: [
    MatInputModule,
    FormsModule,
    EditorModule,
    MatSelectModule,
    FlexLayoutModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    TranslateModule,
    CommonModule,
  ],
})
export class NotificationMessageComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  //
  @Input() key!: string;

  @ViewChild('notificationEditorFR')
  notificationEditorFR?: Editor;
  @ViewChild('notificationEditorEN')
  notificationEditorEN?: Editor;
  form: UntypedFormGroup;
  response: any;
  model: any;
  modelSubject: any;
  editMode: boolean = true;

  proposalEditorSubscription?: Subscription;
  proposalEditorSubscriptionEn?: Subscription;
  noteEditordebounceTimeValue: number = 1500;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private translate: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private logger: NGXLogger,
    private dialog: MatDialog,
    private notificationMessagesService: NotificationMessagesService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    this.form = this.formBuilder.group({
      subject_fr_CA: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      subject_en_CA: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
    });
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.loadData();
  }

  ngOnDestroy() {}

  setFocus(event: any) {
    event.editor.focus();
  }

  initEditors(): void {}

  loadData(): void {
    this.notificationMessagesService.getMessage().subscribe({
      next: (response: any) => {
        this.response = response.data;
        this.modelSubject = response.data[this.key + '_subject'];
        this.model = response.data[this.key];
        this.cdr.detectChanges();
        setTimeout(() => {
          if (this.notificationEditorFR) {
            this.notificationEditorFR.getQuill().focus();
          }
        }, 300);
      },
      error: (error: any) => {
        this.logger.error(
          'NotificationMessageComponent -> getConditions()  ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  onSaveEditor() {
    const data: any = {};
    data[this.key + '_subject'] = this.modelSubject;
    data[this.key] = this.model;
    this.notificationMessagesService
      .updateMessage(data, this.response.uuid)
      .subscribe({
        next: (response) => {
          this.toastr.success(
            '',
            this.translate.instant('GENERIC.API_CALL_SUCCESS.SAVE_SUCCESS'),
          );
          this.loadData();
        },
        error: (error) => {
          this.logger.error(
            'NotificationMessageComponent.onSaveEditor() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.GENERIC'),
          );
        },
      });
  }
}
