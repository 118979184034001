import { MenuReportsService } from '../../../reports/menu-reports.service';
import { IconsService } from '../../../services/icons.service';
import { FileService } from '../../../../services/file.service';
import { ReportFactoryService } from './../../../reports/report-factory.service';
import { Router } from '@angular/router';
import { ItemUrlFinder } from '../../../utils/item-url-finder';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { AppRoutesService } from './../../../services/app-routes.service';
import { DatatableHelperService } from '../../../../services/datatable-helper.service';
import { Subscription, of, merge } from 'rxjs';
import { startWith, switchMap, catchError, map } from 'rxjs/operators';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import {
  MatTable,
  MatTableDataSource,
  MatTableModule,
} from '@angular/material/table';
import { Gt2ApiService } from '../../../api/gt2-api.service';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import {
  CongressesService,
  CongressModel,
} from './../../../api/congresses.service';
import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { MailComposeDialogComponent } from '../../compose/compose.component';
import { ClientOrderCTStackComposeService } from '../../../api/client-order-ct-stack-compose.service';
import { ModulesService } from '../../../services/modules.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { TableSizePipeModule } from '../../../modules/table-size.module';
import { ReportButtonComponent } from '../../../reports/report-button/report-button.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-reports-per-client',
  templateUrl: './reports-per-client.component.html',
  styleUrls: ['./reports-per-client.component.scss'],
  standalone: true,
  imports: [
    MatTableModule,
    MatPaginatorModule,
    CdkTableModule,
    MatMenuModule,
    ReportButtonComponent,
    MatSortModule,
    TableSizePipeModule,
    ReactiveFormsModule,
    MatTooltipModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    TranslateModule,
    CommonModule,
    MatProgressBarModule,
  ],
  animations: fuseAnimations,
})
export class ReportsPerClientComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  //
  @Input() congress!: CongressModel;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('table') table!: MatTable<any>;
  //
  loading: boolean = false;
  invoices: any[] = [];
  //
  // displayedColumns = ["name", "date_start", "client", "actions"];
  displayedColumns = ['enterprise', 'name', 'actions'];
  filterValue: string = '';
  dataSource = new MatTableDataSource();
  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;
  fromEventSubscription?: Subscription;
  isFirstLoad: boolean = true;
  meta: any;
  editable: boolean = true;
  mailInfo: any;
  @Output() onMeta = new EventEmitter<any>();
  //
  constructor(
    private congressesService: CongressesService,
    private translationLoader: FuseTranslationLoaderService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private logger: NGXLogger,
    private api: Gt2ApiService,
    public dialog: MatDialog,
    public datatableHelperService: DatatableHelperService,
    public appRoutesService: AppRoutesService,
    public router: Router,
    public reportFactoryService: ReportFactoryService,
    public fileService: FileService,
    public iconsService: IconsService,
    public menuReportsService: MenuReportsService,
    public ClientOrderCTStackComposeService: ClientOrderCTStackComposeService,
    public modulesService: ModulesService,
  ) {
    super();
  }

  ngOnInit() {
    //this.logger.info("ReportsPerClientComponent.ngOnInit()");
  }

  ngAfterViewInit() {
    //this.logger.info("ReportsPerClientComponent.ngAfterViewInit()");

    this.sort.sortChange.subscribe({
      next: () => (this.paginator.pageIndex = 0),
    });

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(startWith(null))
      .pipe(
        switchMap(() => {
          setTimeout(() => {
            this.isLoadingResults = true;
          });

          // TODO: handle error here, how do?
          return this.congressesService.getClientsInvoicessWithPagination(
            this.congress.uuid,
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize,
          );
        }),
      )
      .pipe(
        map((data) => {
          // Flip flag to show that loading has finished.
          // this.isLoadingResults = false;
          setTimeout(() => {
            this.isLoadingResults = false;
          });
          this.isRateLimitReached = false;
          this.resultsLength = data.meta.pagination.total;
          // this.mainContainer.nativeElement.scrollTop = 0;
          //this.logger.info("ReportsPerClientComponent.ngAfterViewInit().getClientsInvoicessWithPagination().map() -> data : " + JSON.stringify(data.data));
          this.meta = data.meta;
          this.onMeta.next(this.meta);
          return data.data;
        }),
      )
      .pipe(
        catchError((error) => {
          setTimeout(() => {
            this.isLoadingResults = false;
            this.isRateLimitReached = true;
            //
            //this.logger.info("ReportsPerClientComponent.ngAfterViewInit().getClientsInvoicessWithPagination().catch() -> error : " + error);
            this.handleAPIError(error, this.dialog, null, null);
          });
          return of([]);
        }),
      )
      .subscribe({ next: (data) => (this.dataSource.data = data) });

    this.mailInfo = {
      uuid_congress: this.congress?.uuid,
    };
  }

  ngOnDestroy() {
    //this.logger.info("ReportsPerClientComponent.ngOnDestroy()");
  }

  public loadClientsInvoices(): void {
    this.loading = true;
    this.congressesService.getClientsInvoices(this.congress?.uuid).subscribe({
      next: (response: any) => {
        // //this.logger.info("ReportsPerClientComponent.loadClientsInvoices() -> SUCCESS: ");
        //this.logger.info("ReportsPerClientComponent.loadClientsInvoices() -> SUCCESS: " + JSON.stringify(response));
        this.loading = false;
        this.invoices = response.data;
      },
      error: (error: any) => {
        this.logger.error(
          'ReportsPerClientComponent.loadClientsInvoices() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public syncPrimaryPaginator(event: PageEvent) {
    //this.logger.info("ReportsPerClientComponent.syncPrimaryPaginator()");
    this.paginator.pageIndex = event.pageIndex;
    this.paginator.pageSize = event.pageSize;
    this.paginator.page.emit(event);
  }

  public onOpenInNewTab(item: any): void {
    window.open(ItemUrlFinder.getItemURL(item), '_blank');
  }

  public onOpenPage(item: any): void {
    this.router.navigateByUrl(ItemUrlFinder.getItemURL(item));
  }

  // public onOpenClient(item): void {
  //     this.router.navigateByUrl(ItemUrlFinder.getItemURL(item));
  // }
  public onSendOrder(uuid_client: any, local: any): void {
    // //this.logger.info("OrderComponent.onSendOrder() ---->");
    this.mailInfo.local = local;
    // IMPORTANT
    this.ClientOrderCTStackComposeService.parentUUID = uuid_client;
    this.dialogRef = this.dialog.open(MailComposeDialogComponent, {
      panelClass: 'mail-compose-dialog',
      data: {
        composeService: this.ClientOrderCTStackComposeService,
        postData: this.mailInfo,
      },
    });
    this.dialogRef.afterClosed().subscribe({
      next: (response: any) => {
        if (response) {
          // //this.logger.info("OrderComponent.onSendOrder() -> Dialog closed, response exist, reloading data NOW!!");
          this.editable = true;
          //this.loadData();
        }

        // if (!response) {
        //     return;
        // }

        // //this.logger.info("OrderComponent.onSendOrder() -> response: " + JSON.stringify(response));
        // if (response) {
        //     //this.logger.info("OrderComponent.onSendOrder() -> Dialog closed, response exist, reloading data NOW!!");
        //     this.loadData();
        // }
        this.dialogRef = null;
      },
    });
  }
  public onNavigateToEnterprise(uuid: string, slug: string): void {
    this.router.navigateByUrl(
      ItemUrlFinder.getItemURL({
        object: 'Enterprise',
        uuid: uuid,
        slug: slug,
      }),
    );
  }
  public onNavigateToClient(uuid: string, slug: string): void {
    this.router.navigateByUrl(
      ItemUrlFinder.getItemURL({
        object: 'Client',
        uuid: uuid,
        slug: slug,
      }),
    );
  }
}
