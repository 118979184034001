import { GenericAlertComponent } from './../../../../components/generic-alert/generic-alert.component';
import { CrudOrganizationService } from './../../../crud/crud-organization/crud-organization.service';
import { ModulesService } from '../../../../services/modules.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonModule, Location } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { fuseAnimations } from '../../../../../../@fuse/animations';
import { FuseTranslationLoaderService } from '../../../../../../@fuse/services/translation-loader.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DeliveryTypeService } from '../../../../api/delivery-type.service';
import { EventsService } from '../../../../api/events.service';
import { GT2FormPageAbstract } from '../../../../content/abstract/GT2FormPageAbstract';
import { RolesService } from '../../../../services/roles.service';
import { ItemUrlFinder } from '../../../../utils/item-url-finder';
import { NGXLogger } from 'ngx-logger';
import { locale as english } from '../../i18n/en-CA';
import { locale as french } from '../../i18n/fr-CA';
import { SelectEnterpriseRoomComponent } from './select-enterprise-room/select-enterprise-room.component';
import { SelectPlaceRoomComponent } from './select-place-room/select-place-room.component';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Settings } from '../../../../settings/settings';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { AddressComponent } from '../../../../components/address/address.component';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { GoogleLinkPipeModule } from '../../../../modules/google-link.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-event-venues',
  templateUrl: './event-venues.component.html',
  styleUrls: ['./event-venues.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    AddressComponent,
    FormsModule,
    MatSelectModule,
    MatMenuModule,
    FlexLayoutModule,
    MatInputModule,
    MatButtonModule,
    MatTooltipModule,
    GoogleLinkPipeModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    CommonModule,
  ],
  animations: fuseAnimations,
})
export class EventVenuesComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @Output() eventChange = new EventEmitter<any>();
  @Input() event: any;
  @Input() editable: boolean = false;
  @Output() editableChange = new EventEmitter<boolean>();
  loading = false;
  editMode = false;
  @Output() editModeChange = new EventEmitter<boolean>();
  declare dialogRef: any;
  @Output() modelUpdatedEvent = new EventEmitter<any>();
  @Output() newModelEvent = new EventEmitter<any>();
  isDirty = false;
  selectedIdentityUUID?: string | null = null;
  confirmDialogRef: any = null;
  deliveryTypes?: any[];
  lang: string;
  form: UntypedFormGroup;
  // deliveryTypeWithStaff: any = {
  //     object: "DeliveryType",
  //     uuid: "26a35f86-ad2e-43f9-8a8c-41b9b5c09e75",
  //     name: "Avec du personnel",
  //     name_fr: "Avec du personnel",
  //     name_en: "Staff on site",
  //     slug: "avec-du-personnel",
  //     delivery_type_id: 3,
  //     need_delivery: 1,
  // };
  // deliveryTypePickup: any = {
  //     object: "DeliveryType",
  //     uuid: "29512f55-8c6f-4172-90bd-a6a15677fd5b",
  //     name: "Cueillette au comptoir",
  //     name_fr: "Cueillette au comptoir",
  //     name_en: "Picking",
  //     slug: "cueillette-au-comptoir",
  //     delivery_type_id: 4,
  //     need_delivery: 0,
  // };
  // deliveryTypeDeliveryOnly: any = {
  //     object: "DeliveryType",
  //     uuid: "5bd766ef-d5fb-48cc-8eec-5dcada3dd496",
  //     name: "Livraison seulement",
  //     name_fr: "Livraison seulement",
  //     name_en: "Delivery only",
  //     slug: "livraison-seulement",
  //     delivery_type_id: 1,
  //     need_delivery: 1,
  // };
  // deliveryTypeDelivveryAndInstall: any = {
  //     object: "DeliveryType",
  //     uuid: "d138b8a3-e7f3-4888-99fe-ec8327401b2f",
  //     name: "Livrer et installer",
  //     name_fr: "Livrer et installer",
  //     name_en: "Delivery and installation",
  //     slug: "livrer-et-installer",
  //     delivery_type_id: 2,
  //     need_delivery: 1,
  // };

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    public logger: NGXLogger,
    public location: Location,
    private dialog: MatDialog,
    public rolesService: RolesService,
    private eventsService: EventsService,
    public deliveryTypeService: DeliveryTypeService,
    private spinner: NgxSpinnerService,
    public router: Router,
    public moduleService: ModulesService,
    public crudOrganizationService: CrudOrganizationService,
    private formBuilder: UntypedFormBuilder,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    //
    this.lang = this.translate.currentLang;
    //
    this.form = this.formBuilder.group({
      takeout_location_input: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
        ],
      ],
    });
  }

  // tslint:disable-next-line:typedef
  ngOnInit() {
    this.deliveryTypeService.getData().subscribe({
      next: (response) => {
        // //this.logger.info(response);
        this.deliveryTypes = response.data;
      },
      error: (error) => {
        this.logger.error(error);
      },
    });
  }

  // tslint:disable-next-line:typedef
  ngAfterViewInit() {
    if (
      this.event.informations &&
      this.event.informations.takeout_organization_identity
    ) {
      this.selectedIdentityUUID =
        this.event.informations.takeout_organization_identity.uuid;
    }
    // //this.logger.info(this.event);

    // this.selectedIdentityUUID = null;
    // this.event.informations.takeout_organization_identity_list.push({
    //     uuid: null,
    //     name: this.crudOrganizationService.organization.data.name,
    // });
  }

  // tslint:disable-next-line:typedef
  ngOnDestroy() {}

  public loadData(): void {}

  public onToggleEdit(): void {
    this.editMode = !this.editMode;
    // //this.logger.info("EventVenuesComponent.onEdit() -> this.editMode: " + this.editMode);
  }

  public onSaveData(): void {
    // //this.logger.info("EventVenuesComponent.onSaveData()");
  }

  public onManualSave(): void {
    // //this.logger.info("EventVenuesComponent.onManualSave()");
  }

  public onModelLoaded(): void {
    this.loading = false;
  }

  public onDispatchModelUdpated(): void {
    // //this.logger.info("EventVenuesComponent.onDispatchModelUdpated()");
    this.modelUpdatedEvent.next(undefined);
  }

  // tslint:disable-next-line:typedef
  public onDeliveryTypeChange(event: any) {
    //this.logger.info("EventVenuesComponent.onDeliveryTypeChange() -> event: " + JSON.stringify(event));

    if (this.event.spot.event_address && !event.need_delivery) {
      this.onDeleteSporadicAddress();
    }
    this.loading = true;
    this.eventsService
      .updateEventDeliveryType(this.event.uuid, { delivery_type: event })
      .subscribe({
        next: (response: any) => {
          // //this.logger.info("EventVenuesComponent.loadData() -> SUCCESS: " + JSON.stringify(response));
          this.onDispatchModelUdpated();
          // this.loading = false;
        },
        error: (error: any) => {
          this.logger.error(
            'EventVenuesComponent.loadData() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          this.handleAPIError(error, this.dialog, null, null);
        },
      });
  }

  public onSelectVenue(): void {
    // //this.logger.info("EventVenuesComponent.onSelectVenue() -> ");

    this.dialogRef = this.dialog.open(SelectPlaceRoomComponent, {
      width: '80%',
      minWidth: 350,
      disableClose: false,
      panelClass: 'scrollable-dialog',
      data: {
        eventUUID: this.event.uuid,
      },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          //this.logger.info("EventVenuesComponent.onSelectVenue() -> this.dialogRef.afterClosed() " + JSON.stringify(result));
          this.loading = true;
          this.onDispatchModelUdpated();

          if (this.event.spot.event_address) {
            this.onDeleteSporadicAddress();
          }

          //
          //this.logger.info("time end: " + result.data.informations.event_time_end);
          //this.logger.info("place internal: " + result.data.spot.place.internal);
          if (
            (result.data.informations.event_time_end === null ||
              result.data.informations.event_time_end === '') &&
            result.data.spot.place.internal
          ) {
            this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
              disableClose: false,
            });

            this.confirmDialogRef.componentInstance.useCancel = false;
            this.confirmDialogRef.componentInstance.alertMessage =
              this.translate.instant(
                'GENERIC.NO_END_TIME_FOR_EVENT_WITH_ROOM_INTERNAL',
              );
            this.confirmDialogRef.afterClosed().subscribe({
              next: (result: any) => {
                this.confirmDialogRef = null;
              },
            });
          }
        }
        this.dialogRef = null;
      },
    });
  }

  public onSelectOrderingClient(): void {
    // //this.logger.info("EventVenuesComponent.onSelectOrderingCLient() ");
    if (this.event.spot.event_address) {
      this.onDeleteSporadicAddress();
    }

    const requestObj: any = {
      private_client: this.event.clients.ordering_client,
    };
    // //this.logger.info("SelectPlaceRoomComponent.onRoomSelected() -> requestObj: " + JSON.stringify(requestObj));
    this.loading = true;
    this.eventsService
      .updateEventVenues(this.event.uuid, requestObj)
      .subscribe({
        next: (response: any) => {
          // //this.logger.info("SelectPlaceRoomComponent.onRoomSelected() -> SUCCESS: " + JSON.stringify(response));
          this.onDispatchModelUdpated();
        },
        error: (error: any) => {
          this.logger.error(
            'SelectPlaceRoomComponent.onRoomSelected() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          this.handleAPIError(error, this.dialog, null, null);
        },
      });
  }

  public onSelectOrderingClientEnterprise(): void {
    // //this.logger.info("EventVenuesComponent.onSelectOrderingClientEnterprise() ");
    this.dialogRef = this.dialog.open(SelectEnterpriseRoomComponent, {
      width: '80%',
      minWidth: 350,
      disableClose: false,
      panelClass: 'scrollable-dialog',
      data: {
        eventUUID: this.event.uuid,
        enterprise: this.event.clients.ordering_client.enterprise,
      },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          // //this.logger.info("EventVenuesComponent.onSelectVenue() -> this.dialogRef.afterClosed()");
          this.loading = true;
          this.onDispatchModelUdpated();
        }
        this.dialogRef = null;
      },
    });
  }

  public onCreateSporadicAddress(): void {
    ////this.logger.info("EventVenuesComponent.onSelectSporadicAddress() ");
    //
    this.spinner.show('reportSpinner');
    this.eventsService.addSporadicAddress(this.event.uuid).subscribe({
      next: (response: any) => {
        // //this.logger.info(
        //     "EventVenuesComponent.onSelectSporadicAddress() -> SUCCESS: " +
        //         JSON.stringify(response)
        // );
        // this.onDispatchModelUdpated();
        this.newModelEvent.next(response.data);
        this.spinner.hide('reportSpinner');
      },
      error: (error: any) => {
        this.logger.error(
          'EventVenuesComponent.onSelectSporadicAddress() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
        this.spinner.hide('reportSpinner');
      },
    });
  }

  public onDeleteSporadicAddress(): void {
    //this.logger.info("EventVenuesComponent.onDeleteSporadicAddress() ");
    //
    this.spinner.show('reportSpinner');
    this.eventsService.deleteSporadicAddress(this.event.uuid).subscribe({
      next: (response: any) => {
        //this.logger.info("EventVenuesComponent.onDeleteSporadicAddress() -> SUCCESS: " + JSON.stringify(response));
        // this.onDispatchModelUdpated();
        this.newModelEvent.next(response.data);
        this.spinner.hide('reportSpinner');
      },
      error: (error: any) => {
        this.logger.error(
          'EventVenuesComponent.onDeleteSporadicAddress() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
        this.spinner.hide('reportSpinner');
      },
    });
  }

  // public onSaveSporadicAddress(): void {
  //     //this.logger.info("EventVenuesComponent.onSaveSporadicAddress() ");
  // }

  public onResetSpot(): void {
    // //this.logger.info("EventVenuesComponent.onResetSpot()");
    this.loading = true;
    this.eventsService.resetSpot(this.event.uuid).subscribe({
      next: (response: any) => {
        // //this.logger.info("EventVenuesComponent.onResetSpot() -> SUCCESS: " + JSON.stringify(response));
        this.onDispatchModelUdpated();
      },
      error: (error: any) => {
        this.logger.error(
          'EventVenuesComponent.onResetSpot() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public onOpenClientInNewTab(client: any): void {
    window.open(ItemUrlFinder.getItemURL(client), '_blank');
  }

  public onOpenInNewTab(item: any): void {
    window.open(ItemUrlFinder.getItemURL(item), '_blank');
  }

  //
  public onTakeoutIdentityChange(event: any): void {
    this.event.takeout_organization_identity = event.value;
    let identity: any = null;
    identity = this.event.informations.takeout_organization_identity_list.find(
      (item: any) => {
        return event.value === item.uuid;
      },
    );

    this.loading = true;
    this.eventsService
      .updateEventDeliveryType(this.event.uuid, {
        delivery_type: this.event.delivery_type,
        takeout_organization_identity:
          identity !== null && !(identity == undefined) ? identity : null,
      })
      .subscribe({
        next: (response: any) => {
          this.onDispatchModelUdpated();
        },
        error: (error: any) => {
          this.logger.error(
            'EventVenuesComponent.loadData() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          this.handleAPIError(error, this.dialog, null, null);
        },
      });
  }
}
