import { Subscription, Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { fuseAnimations } from '../../../../@fuse/animations';
import { TranslateService } from '@ngx-translate/core';
import {
  CalendarDateFormatter,
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarMonthViewDay,
  CalendarView,
} from 'angular-calendar';
import { OrdersService } from '../../api/orders.service';
import { UserSettingsService } from '../../api/user-settings.service';
import { CreateEventComponent } from '../../components/dialogs/create-event/create-event.component';
import { GT2PageAbstract } from '../../content/abstract/GT2PageAbstract';
import { CalendarEventModel } from '../../content/calendar/event.model';
import { GT2CalendarUtils } from '../../utils/GT2CalendarUtils';
import { ItemUrlFinder } from '../../utils/item-url-finder';
import moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { Moment } from 'moment';
import { ModulesService } from '../../services/modules.service';
import { CustomDateFormatter } from '../calendar/CustomDateFormatter';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { locale as english } from '../dashboard/i18n/en-CA';
import { locale as french } from '../dashboard/i18n/fr-CA';
import { CollectionTableSortComponent } from '../../components/collection-table-sort/collection-table-sort.component';
import { RolesService } from '../../services/roles.service';
import { GenericAlertComponent } from '../../components/generic-alert/generic-alert.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GT2DateAdapter } from '../../utils/GT2DateAdapter';

@Component({
  selector: 'app-dashboard-jul',
  templateUrl: './dashboard-jul.component.html',
  styleUrls: ['./dashboard-jul.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
  providers: [
    { provide: DateAdapter, useClass: GT2DateAdapter },
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})
export class DashboardJulComponent extends GT2PageAbstract implements OnInit {
  actions?: CalendarEventAction[];
  activeDayIsOpen: boolean;
  confirmDialogRef: MatDialogRef<any> | any;
  declare dialogRef: any;
  events: CalendarEvent[] | any;
  refresh: Subject<any> = new Subject();
  selectedDay: any;
  dashboardJul: string = 'jul';
  selectedDayForEvents: any;
  view: CalendarView | 'month' | 'week' | 'day';
  timeZone: string;
  currentDateWithTZ?: string;
  viewDate: Date;
  displayedColumns: string[] = [
    'delivery_type_id',
    'time',
    'event_name',
    'guest_count',
    'client_name',
    'responsible_employee_name',
    'place',
    'current_order_type',
    'status',
  ];
  day: any;
  iniDate: Moment;
  dateFormat?: string;
  orders?: any[];
  isMobile: boolean = false;
  viewEventsDate: any;
  ordersOfTheDay: any[] | any;
  productionZonesOfTheDay?: any[];
  tabSelectedInit: boolean = false;
  routeParams?: Subscription;
  refreshCalendar: Subject<void> = new Subject();
  firstLoad: boolean = true;
  @ViewChild('ordersList') ordersList: ElementRef | any;
  @ViewChild(CollectionTableSortComponent)
  collectionList?: CollectionTableSortComponent;

  includeCancelAbortEvents: boolean = true;
  listState: 0 | 1 = 0;
  //
  treeData?: any[];
  //
  guestCountByDay: number = 0;
  //
  minDate: Date;
  maxDate: Date;

  constructor(
    private adapter: DateAdapter<any>,
    public translate: TranslateService,
    public dialog: MatDialog,
    public ordersService: OrdersService,
    public router: Router,
    private deviceService: DeviceDetectorService,
    private logger: NGXLogger,
    private spinner: NgxSpinnerService,
    public userSettingsService: UserSettingsService,
    private route: ActivatedRoute,
    public modulesService: ModulesService,
    public rolesService: RolesService,
    private translationLoader: FuseTranslationLoaderService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    //
    // Set navigation calandar min and max dates.
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 10, 0, 1);
    this.maxDate = new Date(currentYear + 6, 11, 31);
    //
    // set first initial date
    //
    this.timeZone = 'America/Montreal';
    this.iniDate = moment().tz(this.timeZone);
    //
    // Set the defaults
    //
    this.view = 'month';
    this.viewEventsDate = this.iniDate;
    // Day use when navigating between months
    this.day = this.iniDate.date();
    if (this.day >= 28) {
      this.day = 1;
    }
    this.viewDate = new Date(
      this.iniDate.year(),
      this.iniDate.month(),
      this.day,
    );
    this.activeDayIsOpen = false;
    this.selectedDay = { date: this.viewEventsDate };
    this.selectedDayForEvents = this.selectedDay;
    this.adapter.setLocale(this.translate.currentLang);
    //
    this.userSettingsService.getSettings().subscribe({
      next: (response: any) => {
        this.dateFormat = response.data.date_format.format_moment;
        this.timeZone = response.data.timezone.code; // 'Europe/Paris';
        this.currentDateWithTZ = moment()
          .tz(this.timeZone)
          .format('YYYY-MM-DD');
        this.loadMonthModelEvents(this.currentDateWithTZ);
        // this.loadMonthModelProdZone(this.currentDateWithTZ);
      },
      error: (error: any) => {
        this.logger.error(
          'DashboardJulComponent.loadStartingData() -> userSettingsService ERRORS: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, dialog, null, null);
      },
    });
  }

  ngOnInit(): void {
    this.isMobile = this.detectMobileDevice();
    if (this.isMobile) {
      this.displayedColumns = this.displayedColumns.filter(
        (column) =>
          column !== 'guest_count' && column !== 'responsible_employee_name',
      );
    }
    ////this.logger.info("DashboardJulComponent.ngOnInit()");
  }
  detectMobileDevice(): boolean {
    return this.deviceService.isMobile() || this.deviceService.isTablet();
  }

  public watchParams(): void {
    this.route.queryParams.subscribe({
      next: (params) => {
        let date = params['date'];
        if (date && date !== null && !(date == undefined)) {
          this.dayDateParamReceived(date);
        }
      },
    });
  }

  public onIncludeCancelAbortEvent() {
    // this.checkboxAllComplete = completed;

    this.loadMonthModelEvents(this.currentDateWithTZ);
    this.loadMonthModelProdZone(this.selectedDay.date);
  }

  public tabSelected(): void {
    //this.logger.info("DashboardJulComponent.tabSelected() -> " + this.events);
    this.tabSelectedInit = true;
    if (!this.events) {
      this.currentDateWithTZ = moment().tz(this.timeZone).format('YYYY-MM-DD');
      this.loadMonthModelEvents(this.currentDateWithTZ);
    }
  }

  public onCreateEvent(): void {
    //this.logger.info("DashboardJulComponent.onCreateEvent()");
    this.dialogRef = this.dialog.open(CreateEventComponent, {
      width: '30%',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: {
        // event_date: GT2CalendarUtils.formatDateForAPI(this.selectedDay.date)
      },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        // //this.logger.info('DashboardJulComponent.onNewEvent() -> result: ' + JSON.stringify(result));
        if (result) {
          this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
        }

        this.dialogRef = null;
      },
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  public loadMonthModelEvents(date: any): void {
    // //this.logger.info('DashboardJulComponent.loadMonthModelEvents() -> date: ' + date);
    if (!date) {
      date = moment().tz(this.timeZone).format('YYYY-MM-DD');
    }
    this.spinner.show('calenderSpinner');
    this.ordersService
      .getOrdersByMonthJul(date, this.includeCancelAbortEvents)
      .subscribe({
        next: (response: any) => {
          this.orders = response.data;
          this.setEvents();
          this.spinner.hide('calenderSpinner');

          if (this.firstLoad) {
            this.watchParams();
            this.firstLoad = false;
          }
          //
          if (this.collectionList) {
            this.collectionList.reload(this.ordersOfTheDay);
          }
          //
          this.setGuestCountByDay();
        },
        error: (error: any) => {
          this.spinner.hide('calenderSpinner');
          this.logger.error(
            'DashboardJulComponent.loadMonthModelEvents() -> getOrdersByMonthJul ERRORS: ' +
              JSON.stringify(error),
          );
          this.handleAPIError(error, this.dialog, null, null);
        },
      });
  }

  public loadDayModelProdZone(date: any): void {
    this.spinner.show('calenderSpinner');

    this.ordersService
      .getProdZonesByDateJul(GT2CalendarUtils.formatDateForAPI(date), false) // this.includeCancelAbortEvents
      .subscribe({
        next: (response: any) => {
          this.spinner.hide('calenderSpinner');
          this.treeData = response;
        },
        error: (error: any) => {
          this.logger.error(
            'DashboardJulComponent.loadDayModelProdZone() -> getProdZonesByDateJul ERRORS: ' +
              JSON.stringify(error),
          );
          this.spinner.hide('calenderSpinner');
          this.handleAPIError(error, this.dialog, null, null);
        },
      });
  }

  public loadMonthModelProdZone(date: any): void {
    this.spinner.show('calenderSpinner');
    this.ordersService
      .getProdZonesByMonthJul(
        GT2CalendarUtils.formatDateForAPI(date),
        this.includeCancelAbortEvents,
      )
      .subscribe({
        next: (response: any) => {
          this.spinner.hide('calenderSpinner');
          this.treeData = response;
          this.setProdZoneList(date);
        },
        error: (error: any) => {
          this.logger.error(
            'DashboardJulComponent.loadMonthModelProdZone() -> getProdZonesByMonthJul ERRORS: ' +
              JSON.stringify(error),
          );
          this.spinner.hide('calenderSpinner');
          this.handleAPIError(error, this.dialog, null, null);
        },
      });
  }

  public loadWeekModelEvents(date: any): void {
    this.ordersService
      .getOrdersByWeek(GT2CalendarUtils.formatDateForAPI(date))
      .subscribe({
        next: (response: any) => {
          this.orders = response.data;
          this.setEvents();
          if (this.firstLoad) {
            this.watchParams();
            this.firstLoad = false;
          }
        },
        error: (error: any) => {
          this.logger.error(
            'DashboardJulComponent.loadWeekModelEvents() -> getOrdersByWeek ERRORS: ' +
              JSON.stringify(error),
          );
          this.handleAPIError(error, this.dialog, null, null);
        },
      });
  }

  public loadDayModelEvents(date: any): void {
    this.ordersService
      .getOrdersByDate(GT2CalendarUtils.formatDateForAPI(date))
      .subscribe({
        next: (response: any) => {
          this.orders = response.data;
          this.setEvents();
        },
        error: (error: any) => {
          this.logger.error(
            'DashboardJulComponent.loadDayModelEvents() -> getOrdersByDate ERRORS: ' +
              JSON.stringify(error),
          );
          this.handleAPIError(error, this.dialog, null, null);
        },
      });
  }

  openDatePicker(dp: any) {
    dp.open();
  }

  closeDatePicker(eventData: any, dp?: any) {
    // get month and year from eventData and close datepicker, thus not allowing user to select date
    dp.close();
    this.viewDate = eventData;
    this.setSelectedDay(eventData);
  }

  public setSelectedDay(event: any): void {
    this.selectedDay = { date: event };

    // if week we will update by loading events per week because some weeks may overlap 2 months
    if (this.view === 'week') {
      if (!moment(this.selectedDay.date).isSame(this.viewEventsDate, 'week')) {
        this.viewEventsDate = this.selectedDay.date;
        this.loadWeekModelEvents(this.viewEventsDate);
      }
    } // if month or day, we can compare if the date is outside of the current month, in both case we load events per months
    else {
      if (!moment(this.selectedDay.date).isSame(this.viewEventsDate, 'month')) {
        this.viewEventsDate = this.selectedDay.date;
        this.loadMonthModelEvents(
          moment(this.viewEventsDate).tz(this.timeZone).format('YYYY-MM-DD'),
        );
        this.loadMonthModelProdZone(this.selectedDay.date);
      }
    }
  }

  public setWeekView(): void {
    this.view = 'week';
    this.loadWeekModelEvents(this.viewEventsDate);
  }

  public setMonthView(): void {
    this.view = 'month';
    this.loadMonthModelEvents(this.viewEventsDate);
    // this.loadMonthModelProdZone(this.viewEventsDate);
  }

  public setDayOrdersList(date: any): void {
    // //this.logger.info("DashboardJulComponent.setDayOrdersList() -> " + date);
    const momentDate = moment(date);
    this.ordersOfTheDay = this.orders?.filter((item) => {
      const sameDay = momentDate.isSame(item.start, 'day');
      if (sameDay) {
        // //this.logger.info('DashboardJulComponent.setDayOrdersList() -> isSame date: ' + date);
      }
      return sameDay; // isSameDay(item.start, date);
    });

    if (this.collectionList) {
      this.collectionList.reload(this.ordersOfTheDay);
    }
    //
    this.setGuestCountByDay();
  }

  public setGuestCountByDay(): void {
    this.guestCountByDay = 0;
    ////this.logger.info("setGuestCountByDay => " + JSON.stringify(this.ordersOfTheDay));
    for (var i = 0; i < this.ordersOfTheDay.length; i++) {
      if (
        this.ordersOfTheDay[i].status !== 'EVENT_CANCEL' &&
        this.ordersOfTheDay[i].status !== 'EVENT_ABORT' &&
        this.ordersOfTheDay[i].order_status !== 'ORDER_OPEN' &&
        this.ordersOfTheDay[i].order_status !== 'ORDER_CANCEL' &&
        this.ordersOfTheDay[i].order_status !== 'PROPOSAL_OPEN' &&
        this.ordersOfTheDay[i].order_status !== 'PROPOSAL_PROPOSE' &&
        this.ordersOfTheDay[i].order_status !== 'PROPOSAL_REFUSE' &&
        this.ordersOfTheDay[i].order_status !== 'INVOICE_CANCEL'
      ) {
        this.guestCountByDay += this.ordersOfTheDay[i].guest_count;
      }
    }
  }

  public setProdZoneList(date: any): void {
    this.productionZonesOfTheDay = [];
    //
    if (this.treeData) {
      const momentDate = moment(date).format('YYYY-MM-DD');
      //this.logger.info("DashboardCalendarComponent.setProdZoneList() -> treeData: " + JSON.stringify(this.treeData));
      let newChildren = [];
      //
      for (const k in this.treeData) {
        const item = this.treeData[k];
        let itemClone: any = null;
        for (let i = 0; i < item.children.length; i++) {
          let child = item.children[i];
          const sameDay = moment
            .tz(momentDate, 'UTC')
            .isSame(child.event_date, 'day');
          // //this.logger.info("Child event date :" + child.event_date);
          if (sameDay) {
            if (itemClone == null) {
              itemClone = item;
              itemClone.children = [];
            }
            itemClone.children.push(child);
          }
        }
        if (itemClone && itemClone.children.length > 0) {
          newChildren.push(itemClone);
        }
      }

      //
      /*this.treeData.filter((item) => {
                let itemClone: any = null;
                for (let i = 0; i < item.children.length; i++) {
                    let child = item.children[i];
                    const sameDay = moment.tz(momentDate, "UTC")
                        .isSame(child.event_date, "day");
                    if (sameDay) {
                        if (itemClone == null) {
                            itemClone = (item);
                            itemClone.children = [];
                        }
                        itemClone.children.push(child);
                    }
                }
                if (itemClone && itemClone.children.length > 0) {
                    newChildren.push(itemClone);
                }
            });*/
      setTimeout(() => {
        this.productionZonesOfTheDay = newChildren;
        //this.logger.info("DashboardCalendarComponent.setProdZoneList() -> productionZonesOfTheDay: " + JSON.stringify(this.productionZonesOfTheDay));
      }, 50);
    }

    ////this.logger.info('DashboardCalendarComponent.setProdZoneList() -> treeData: ' + JSON.stringify(this.treeData));
    // if(this.treeData) {
    //     const momentDate = moment(date);
    //     // //this.logger.info('DashboardCalendarComponent.setProdZoneList() -> treeData: ' + JSON.stringify(this.treeData));
    //     //this.logger.info('DashboardCalendarComponent.setProdZoneList() -> date: ' + date);
    //     //this.logger.info('DashboardCalendarComponent.setProdZoneList() -> momentDate: ' + momentDate);
    //     this.productionZonesOfTheDay = this.treeData.filter((item) => {
    //         // const day = moment(item.date).tz(this.timeZone)
    //         const sameDay = momentDate.utc().isSame(item.date, "day");
    //         if (sameDay) {
    //             // //this.logger.info('DashboardCalendarComponent.setProdZoneList() -> isSame date: ' + date);
    //         }
    //         return sameDay; // isSameDay(item.start, date);
    //     });
    // }
    // //this.logger.info('DashboardCalendarComponent.setProdZoneList() -> productionZonesOfTheDay: ' + JSON.stringify(this.productionZonesOfTheDay));
  }

  public onNavigateToOrder(order: any): void {
    this.router.navigateByUrl(ItemUrlFinder.getItemURL(order.context));
  }

  public onNavigateToOrderSecondContext(order: any): void {
    if (order.order_context) {
      this.router.navigateByUrl(ItemUrlFinder.getItemURL(order.order_context));
    } else {
      this.onNavigateToOrder(order);
    }
  }

  public onNavigateToProjectContext(order: any): void {
    //this.logger.info("DashboardJulComponent.onNavigateToProjectContext() -> order: " + JSON.stringify(order));
    this.router.navigateByUrl(ItemUrlFinder.getItemURL(order.project_context));
  }

  //

  public onCreateOrder(): void {
    const curDate = this.viewDate ? this.viewDate : new Date().toISOString();

    //this.logger.info("DashboardJulComponent.onCreateOrder() -> curDate: " + curDate);
    this.dialogRef = this.dialog.open(CreateEventComponent, {
      width: '30%',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: {
        subType: CreateEventComponent.ORDER_SUBTYPE,
        event_date: curDate,
      },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        //this.logger.info("DashboardJulComponent.onCreateOrder() -> result: " + JSON.stringify(result));
        if (result) {
          this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
        }

        this.dialogRef = null;
      },
    });
  }

  public onQuickOrder(): void {
    //this.logger.info("DashboardJulComponent.onQuickOrder()");
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = false;
    this.confirmDialogRef.componentInstance.alertTitle = 'Commande rapide';
    this.confirmDialogRef.componentInstance.alertMessage = 'À venir!';

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        this.confirmDialogRef = null;
      },
    });
  }

  public onNewProposal(): void {
    //this.logger.info("DashboardJulComponent.onNewProposal()");
    const curDate = this.viewDate ? this.viewDate : new Date().toISOString();
    // //this.logger.info("DashboardJulComponent.onNewProposal() -> ******: " + this.addressComponent.selectedDay.date);
    this.dialogRef = this.dialog.open(CreateEventComponent, {
      width: '30%',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: {
        subType: CreateEventComponent.PROPOSAL_SUBTYPE,
        event_date: curDate,
      },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        //this.logger.info("DashboardJulComponent.onNewEvent() -> result: " + JSON.stringify(result));
        if (result) {
          this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
        }

        this.dialogRef = null;
      },
    });
  }

  public onListRadioChange(event: any): void {
    //this.logger.info("DashboardJulComponent.onListRadioChange() -> event: " + JSON.stringify(event.value));
    //this.logger.info("selectedDay: " + JSON.stringify(this.selectedDay));
    //this.logger.info("selectedDay: " + this.selectedDay.date);
    if (event.value === 1) {
      // this.loadDayModelProdZone(this.selectedDay.date);
      this.loadMonthModelProdZone(this.selectedDay.date);
    }
  }

  //
  //
  /**
   * Set events
   */
  setEvents(): void {
    this.events = this.orders?.map((item) => {
      return new CalendarEventModel(item);
    });
    // //this.logger.info('DashboardJulComponent.setEvents() -> this.viewDate: ' + this.viewDate);
    // //this.logger.info(
    //     "DashboardJulComponent.setEvents() -> this.events.length: " +
    //         this.events.length
    // );

    if (
      moment(this.selectedDayForEvents.date).isSame(
        moment(this.viewDate),
        'month',
      )
    ) {
      this.setDayOrdersList(this.selectedDayForEvents.date);
      this.setProdZoneList(this.selectedDayForEvents.date);
    }
  }

  /**
   * Before View Renderer
   *
   * @param {any} header
   * @param {any} body
   */
  // beforeMonthViewRender({ header, body }): void {
  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {}

  /**
   * Day clicked
   *
   * @param {MonthViewDay} day
   */
  dayClicked(day: CalendarMonthViewDay): void {
    //this.logger.info("DashboardJulComponent.dayClicked() -> day: " + JSON.stringify(day));
    const date: Date = day.date;
    const events: CalendarEvent[] = day.events;

    if (moment(date).isSame(moment(this.viewDate), 'month')) {
      if (
        (moment(this.viewDate).isSame(moment(date), 'day') &&
          this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        // do nothing
      } else {
        this.viewDate = date;
      }
      // this.viewDate = date;
      this.setDayOrdersList(date);
      this.setProdZoneList(date);
    } else {
      // do nothing
      // //this.logger.info('DashboardJulComponent.dayClicked() -> IS NOT SAME MONTH ');
    }
    //
    //Do update production zones on day change
    this.loadMonthModelProdZone(date);

    this.selectedDay = day;
    this.selectedDayForEvents = this.selectedDay;
    // const strDate = date.toISOString().split("T")[0];
    const strDate = date.toISOString();
    const queryParams = { date: strDate };
    if (strDate && strDate !== null && strDate !== '') {
      // this.router.navigateByUrl(AppRoutes.DASHBOARD + "/" + strDate);
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    }
  }

  public resetOrdersScroll(): void {
    if (this.ordersList && this.ordersList.nativeElement) {
      setTimeout(() => {
        this.ordersList.nativeElement.scrollTop = 0;
      }, 1);
    }
  }

  dayDateParamReceived(dateStr: string): void {
    //this.logger.info("DashboardJulComponent.dayDateParamReceived() -> date: " + dateStr);
    //
    // //this.logger.info(
    //     "DashboardJulComponent.dayDateParamReceived() -> ordersList: " +
    //         this.ordersList
    // );

    this.resetOrdersScroll();
    //
    const day = { date: new Date(dateStr) };
    const date: Date = day.date;
    if (moment(date).isSame(moment(this.viewDate), 'month')) {
      if (
        moment(this.viewDate).isSame(moment(date), 'day') &&
        this.activeDayIsOpen === true
      ) {
        // do nothing
      } else {
        this.viewDate = date;
      }
      // this.viewDate = date;
      this.setDayOrdersList(date);
      this.setProdZoneList(date);
    } else {
      // //this.logger.info('DashboardJulComponent.dayClicked() -> IS NOT SAME MONTH ');
    }
    //
    this.viewDate = date;
    this.selectedDay = day;
    this.selectedDayForEvents = this.selectedDay;
    //
    if (this.view === 'week') {
      if (!moment(this.selectedDay.date).isSame(this.viewEventsDate, 'week')) {
        // //this.logger.info('DashboardJulComponent.setSelectedDay() -> IS NOT SAME WEEK!!!!!! ' + this.selectedDay.date);
        this.viewEventsDate = this.selectedDay.date;
        this.loadWeekModelEvents(this.viewEventsDate);
      }
    } // if month or day, we can compare if the date is outside of the current month, in both case we load events per months
    else {
      if (!moment(this.selectedDay.date).isSame(this.viewEventsDate, 'month')) {
        // //this.logger.info('DashboardJulComponent.setSelectedDay() -> IS NOT SAME MONTH !!!!!! ' + this.selectedDay.date);
        this.viewEventsDate = this.selectedDay.date;
        let date = moment(this.viewEventsDate)
          .tz(this.timeZone)
          .format('YYYY-MM-DD');
        this.loadMonthModelEvents(date);
        // this.loadMonthModelProdZone(date);
      }
    }

    //
    // setTimeout(() => {
    //     this.refreshCalendar.next();
    // }, 1000);
  }

  /**
   * Event times changed
   * Event dropped or resized
   *
   * @param {CalendarEvent} event
   * @param {Date} newStart
   * @param {Date} newEnd
   */
  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    // //this.logger.info('DashboardJulComponent.eventTimesChanged() -> event: ' + event);
    event.start = newStart;
    event.end = newEnd;
  }

  /**
   * Delete Event
   *
   * @param event
   */
  deleteEvent(event: any): void {}

  /**
   * Edit Event
   *
   * @param {string} action
   * @param {CalendarEvent} event
   */
  editEvent(action: string, event: CalendarEvent): void {}

  /**
   * Add Event
   */
  addEvent(): void {}
}
