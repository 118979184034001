export const locale = {
  lang: 'fr-CA',
  data: {
    SETTINGS: {
      SETTINGS: 'Préférences',
      BASIC_SETTINGS: 'Préférences de base',
      'CATEGORIES_&_ZONE': 'Catégories et zones',
      ORDERS_BILLING_SETTINGS: 'Commandes et facturation',
      TAXES_SETTINGS: 'Taxes et comptes',
      DATE_TIME_SETTINGS: 'Date et heure',
      CONDITONS_SETTINGS: 'Conditions',
      CLIENTS_CATEGORIES: 'Catégories de clients',
      MATERIALS_CATEGORIES: 'Catégories de matériels',
      STAFF_CATEGORIES: 'Catégories de ressources humaines',
      PRODUCTS_CATEGORIES: 'Catégories de produits',
      PRODUCTION_ZONES: 'Zones de production',
      MATERIALS_PROVIDERS: 'Fournisseurs de matériel',
      STAFF_PROVIDERS: 'Fournisseurs de ressources humaines',
      EVENT_LOGISTIC_STATUS: 'Statut logistique',
      SERVICE_CHARGE: 'Frais de service',
      SERVICE_CHARGE_CT: "Frais d'administration",
      LEDGER_ACCOUNT: 'Comptes de grand livre',
      LEDGER_ACCOUNT_DEFAULT: 'Compte de grand livre par défaut',
      ALLERGENS: 'Allergènes alimentaires',
      JOB_FUNCTIONS: 'Fonctions de travail',
      WRAPPING: 'Emballages',
      WRAPPING_DEFAULT: 'Emballages par défaut',
      SOCIAL_CHARGE: 'Charge sociale globale',
      SOCIAL_CHARGE_PERCENT:
        'Charge sociale globale (en pourcentage, sans le charactère %)',
      LANG: 'Language par défault',
      TIMEZONE: 'Fuseau horaire',
      PRINT: 'Imprimer',
      DELIVERY_SECTOR: 'Secteurs de livraison',
      DELIVERY: 'Livraison',
      TAXES: 'Numéros de taxe',
      TAXES_DEFAULT: 'Taxes par défaut',
      ONLINE_PAYMENT: 'Paiment en ligne',
      THEME: 'Thème',
      NOTIFICATIONS: 'Notifications',
      DOWNLOADS: 'Téléchargement de fichiers',
      SECTORS: 'Secteurs de livraison',
      PRICE: 'Prix',
      ORDERS_NUMBER: 'Numéros de commande',
      NUMBERING: 'Numérotation',
      INVOICES_NUMBER: 'Numéros de facturation',
      PROPOSALS_NUMBER: 'Numéros de proposition',
      DATE_FORMAT: 'Format des dates',
      DATE_TIME_FORMAT: 'Format du temps',
      GENERAL_CONDITIONS: 'Conditions générales',
      SPECIFICS_CONDITIONS: 'Conditions spécifiques',
      INTEGRATION: 'Intégration',
      SAGE: 'Sage',

      SECTOR_NAME: 'Nom du secteur',
      CREATE_SECTOR: 'Nouveau secteur',

      ALLERGENS_NAME: 'Nom des allergènes',
      ALLERGENS_CREATE_NEW: 'Nouvel allergène',
      ALLERGENS_CREATE_NEW_PLACEHOLDER: 'Nom du nouvel allergène',

      EVENT_LOGISTIC_STATUS_NAME: 'Nom',
      EVENT_LOGISTIC_STATUS_CREATE_NEW: 'Nouveau statut logistique',
      EVENT_LOGISTIC_STATUS_CREATE_NEW_PLACEHOLDER: 'Nom du statut logistique',

      PRODUCTION_ZONE_NAME: 'Nom de la zone',
      PRODUCTION_ZONE_CREATE_NEW: 'Nouvelle zone',
      PRODUCTION_ZONE_CREATE_NEW_PLACEHOLDER: 'Nom de la nouvelle zone',

      MATERIALS_CATEGORY_NAME: 'Nom de la catégorie',
      MATERIALS_CATEGORY_CREATE_NEW: 'Nouvelle catégorie',
      MATERIALS_CATEGORY_CREATE_NEW_PLACEHOLDER: 'Nom de la nouvelle catégorie',

      PRODUCTION_CATEGORY_NAME: 'Nom de la catégorie',
      PRODUCTION_CATEGORY_CREATE_NEW: 'Nouvelle catégorie',
      PRODUCTION_CATEGORY_CREATE_NEW_PLACEHOLDER:
        'Nom de la nouvelle catégorie',

      PROVIDERS: 'Fournisseur',
      PROVIDERS_NAME: 'Nom du fournisseur',
      STAF_PROVIDERS_NAME: 'Nom du fournisseur de ressources humaines',
      PROVIDERS_CATEGORY_CREATE_NEW: 'Nouveau fournisseur',
      STAFF_PROVIDERS_CATEGORY_CREATE_NEW:
        'Nouveau fournisseur de ressources humaines',
      PROVIDERS_CATEGORY_CREATE_NEW_PLACEHOLDER: 'Nom du nouveau fournisseur',

      SPECIFIC_CONDITION_NAME: 'Nom de la condition',
      SPECIFIC_CONDITION_CREATE_NEW: 'Nouvelle condition',
      SPECIFIC_CONDITION_CREATE_NEW_PLACEHOLDER:
        'Nom de la nouvelles condition',

      TIMEZONE_SELECT: 'Sélectionner un fuseau horaire',
      DATE_FORMAT_SELECT: 'Sélectionner un format de date',
      DATE_TIME_FORMAT_SELECT: "Sélectionner un format d'heure",

      UPDATE: 'Mise à jour',

      ONLINE_DELIVERY: 'Livraison commande en ligne',

      DELIVERY_FIXED_COST: 'Frais fixe de livraison',
      DELIVERY_MAX_DISTANCE_KM: 'Distance maximum de livraison (km)',
      TAXES_SELECT: 'Sélectionner les taxes applicables',

      DEFAULT_WRAPPING_PRODUCT: 'Emballage produit par défaut',
      DEFAULT_WRAPPING_ORDER: 'Emballage commande par défaut',
      DEFAULT_WRAPPING_DELIVERY: 'Emballage livraison par défaut',

      DEFAULT_DELIVERY_TAX: 'Taxe livraison par défaut',
      DEFAULT_PRODUCT_TAX: 'Taxe produit par défaut',
      DEFAULT_STAFF_CHARGE_TAX: 'Taxe frais de personnel par défaut',
      DEFAULT_MATERIAL_TAX: 'Taxe matériel par défaut',
      DEFAULT_SERVICE_CHARGE_TAX: 'Taxe frais de sercice par défaut',

      LEDGER_ACCOUNT_FOOD: 'Comptes de grand livre nourriture par défaut',
      LEDGER_ACCOUNT_DRINK: 'Comptes de grand livre boisson par défaut',
      LEDGER_ACCOUNT_MATERIAL: 'Comptes de grand livre matériel par défaut',
      LEDGER_ACCOUNT_SERVICE_CHARGE:
        'Comptes de grand livre frais de service par défaut',
      LEDGER_ACCOUNT_STAFF_CHARGE:
        'Comptes de grand livre frais de personnel par défaut',
      LEDGER_ACCOUNT_DELIVERY: 'Comptes de grand livre livraison par défaut',
      LEDGER_ACCOUNT_ROYALTY: 'Comptes de grand livre redevances par défaut',
      LEDGER_ACCOUNT_DISCOUNT: 'Comptes de grand livre rabais par défaut',
      LEDGER_ACCOUNT_TIPS_NO_TAX:
        'Comptes de grand livre pourboire non taxable par défaut',
      LEDGER_ACCOUNT_TAX: 'Comptes de grand livre taxe par défaut',
      ROYALTY_TAX: 'Taxe sur les redevances',
      EVENTS_TYPES: "Types d'événements",
      EVENTS_TYPES_NAME: "Types d'événements",
      EVENTS_TYPES_CREATE_NEW: "Nouveau type d'événement",
      EVENTS_TYPES_CREATE_NEW_PLACEHOLDER: "Nom du nouveau type d'événement",

      DELIVERY_TEMPLATES: 'Modèle de livraison',
      CREATE_NEW_TEMPLATE: 'Nouveau modèle de demande de livraison',
      TEMPLATE_NAME: 'Nom du modèle ',

      NOTIFICATION_MESSAGES: 'Messages de notification',
      NOTIFICATION_MESSAGE_PROPOSAL: 'Proposition',
      NOTIFICATION_MESSAGE_ORDER: 'Commande',
      NOTIFICATION_MESSAGE_INVOICE: 'Facture',
      NOTIFICATION_MESSAGE_INVOICE_ON_LINE_GP: 'Facture paiement en ligne (GP)',
      NOTIFICATION_MESSAGE_INVOICE_ON_LINE_GP_PAGE:
        'Facture paiement en ligne | Global Payment',
      NOTIFICATION_MESSAGE_ALL_RECEIPT_NOTICES: 'Avis de réception',
      NOTIFICATION_MESSAGE_RECEIPT: 'Reçu',
      NOTIFICATION_MESSAGE_CLIENT_STATEMENT: 'État de compte client',
      NOTIFICATION_MESSAGE_ENTERPRISE_STATEMENT: 'État de compte entreprise',
      NOTIFICATION_MESSAGE_SCHEDULE: 'Horaire',
      NOTIFICATION_MESSAGE_BANQUET_CONTRACT: 'Congrès - Contrat banquet',
      NOTIFICATION_MESSAGE_HISTORIC_ACCOUNT: 'Historique de compte client',
      USERS_AND_EMPLOYEES: 'Utilisateurs et employés',
      MAX_USERS: "Nombre d'utilisateurs",
    },
  },
};
