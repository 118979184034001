export const locale = {
  lang: 'fr-CA',
  data: {
    SHOPS: {
      SHOPS: 'Commande en ligne',
      SHOP: 'Commande en ligne',
      CONFIGURATION: 'Configuration générale',
      SHOP_TYPE: 'Type',
      SHOP_PRODUCT_TYPE: 'Type de produit',
      WEB_SHOP: 'Web shop',
      WOOCOMMERVE_SHOP: 'Woocommerce',
      GT_STORE_URL: 'URL Commande Gestion-Traiteur',
      STORE_URL: 'URL Commande en ligne',
      VERIFY_SSL: 'Vérifier SSL',
      CONSUMER_KEY: 'Consumer key',
      CONSUMER_SECRET: 'Consumer secret',
      TEST_WOO_CONNECTION: 'Tester la connexion',
      CONNECTION: 'Connexion',
      CONNECTION_SUCCESS: 'Connexion établie avec succès',
      CATEGORIES: 'Catégories',
      INTEGRATION_TYPE: 'Integration type',
      SELL_TYPE: 'Mode de vente',
      TIPS: 'Pourboire',
      TERMS_CONDITIONS: 'Termes et conditions',
      CHAR_COUNT_MESSAGE: '{{min}} de {{max}} charactères',
      MESSAGES: 'Messages',
      IMAGE_MESSAGE: 'Messages Images',
      START_MESSAGE: 'Message d’accueil',
      START_MESSAGE_HINT:
        'Écrivez ici un message à afficher lorsqu’un utilisateur rentre sur votre page',
      NO_SERVICES_MESSAGE: 'Message service non-disponible',
      NO_SERVICES_MESSAGE_HINT:
        'Écrivez ici un message à afficher dans le cas que le service est indisponible',
      DESCRIPTION: 'Description',
      MIN_TO_ORDER: 'Montant minimum pour une livraison',
      MIN_TO_TAKEOUT: 'Montant minimum pour une cueillette',
      APPLICATION_FEE: 'Ajouter le frais d’application de 2.00$ à la commande',
      APPLICATION_FEE_HINT:
        'Vous pouvez inclure automatiquement le frais d’application à chacune des commandes. Qu’elle soit incluse ou non à la commande, ce frais vous sera facturé mensuellement',
      NOTIFICATIONS: 'Notification',
      NOTIFICATIONS_BY_ROLE: 'Notification par rôle',
      NOTIFICATIONS_USER_CORPORATE: 'Notification pour utilisateur corporatif',
      NOTIFICATIONS_MENU: 'Notifications et corporatif',
      NOTIFICATIONS_ORDERS: 'Notification nouvelle commande',
      NOTIFICATIONS_CORPO: 'Notification pour utilisateur corporatif',
      NOTIFY_SALE: 'Notifier les employés de rôle Vente',
      NOTIFY_ADMIN: 'Notifier les employés de rôle Administrateur',
      NOTIFY_OWNER: 'Notifier les employés de rôle Propriétaire',
      NOTIFY_GOD: 'Notifier les employés de rôle God',
      EMAILS_TO_NOTIFY: 'Nouvelle commande - courriels à notifier',
      EMAILS_TO_NOTIFY_ALERT_TITLE: 'Champ Courriel',
      EMAILS_TO_NOTIFY_ALERT_MESSAGE:
        "Entrer la liste des emails séparés par une virgule (,).\n\n Toutes les emails doivent être valides, si un email n'est pas valide, la liste ne pourra pas être sauvegardé",
      AUTOMATIC: 'Automatique',
      WITH_NOTIFICATION: 'Par notification',
      NOTIFICATION_CORPORATE_DIRECT:
        'Inscription de l’entreprise automatiquement',
      NOTIFICATION_CORPORATE_NOTIFICATION2:
        'Vous serez notifié lorsqu’un utilisateur avec une entreprise s’enregistre. Vous devrez valider la demande d’être associé à une entreprise',
      NOTIFICATION_CORPORATE_NOTIFICATION:
        'Vous serez notifié lorsqu’un utilisateur avec une entreprise s’enregistre.<br>Vous devrez valider la demande d’être associé à une entreprise',
      NOTIFICATION_CORPORATE_NONE: 'Aucun',
      USER_MODE_CORPORATE: 'Mode utilisateur corporatif',
      NONE: '-- Aucun --',
      ACCEPT_TIPS: 'Accepter les pourboires des clients',
      VISIT: 'Visiter',
      DEFAULT_OPTION: 'Option par défaut',
      INTERNAL_SHOP: 'Commande en ligne interne',
      TIPS_HINT: 'La valeur est une valeur de pourcentage sans le symbole.',
      LEDGER_ACCOUNT_TIPS_NO_TAX:
        'Comptes de grand livre pourboire non taxable par défaut',
      DEFAULT_DELIVERY_TAX: 'Taxe livraison par défaut',
      OPTION_1: 'Option #1',
      OPTION_2: 'Option #2',
      OPTION_3: 'Option #3',
      BUSINESS_HOURS: 'Heures d’ouverture',
      TAKEOUT_HOURS: 'Heures de cueillette',
      OFF: 'Fermé',
      BUSINESS_DAYS: 'Calendrier des disponibilités',
      NO_CHANGE: 'Pas de changement',
      CLOSED: 'Fermé',
      OPEN: 'Ouvert',
      DELIVERY: 'Livraison',
      TAKE_OUT: 'Cueillette',
      SCHEDULE: 'Disponibilité',
      PREFERENCE: 'Préférences',
      LEDGER_ACCOUNT_DELIVERY: 'Comptes de grand livre livraison par défaut',
      DEFAULT_WRAPPING_ORDER: 'Emballage commande par défaut',
      DEFAULT_WRAPPING_DELIVERY: 'Emballage livraison par défaut',
      DEFAULT_WRAPPING_TAKOUT: 'Emballage cueillette par défaut',
      THEME: 'Thème',
      THEME_SELECT: 'Choix du Thème par défaut',
      THEME_MODE: 'Mode du thème',
      SHOW_CTR_THEME: 'Afficher le choix du thème',
      SHOW_CTR_THEME_MODE: 'Afficher le mode du thème',
      LIGHT: 'Clair',
      DARK: 'Sombre',
      THEME_MODE_LIGHT: 'Thème clair',
      THEME_MODE_DARK: 'Thème sombre',
      DARK_THEME: 'Choix du Mode du Thème par défaut',
      SHOW_CTRL_THEME_HINT:
        'Détermine si l’utilisateur de la boutique en ligne a l’option de changer le thème dans son profil d’utilisateur',
      SHOW_CTRL_THEME_MODE_HINT:
        'Détermine si l’utilisateur de la boutique en ligne a l’option de changer le mode du thème dans son profil d’utilisateur',
      RTE_SCHEDULE: 'Horaire des livraisons et cueillettes',
      ON_PAUSE: ' - Sur pause',
      WITH_DELIVERY: 'Avec livraison',
      WITH_TAKEOUT: 'Avec cueillette',
      ORDER_TIMELINE: 'Période de prise de commandes',
      PERIOD_DURATION: 'Durée de la période',
      STACK_AMOUNT: 'Nombre de cycles à offrir à la fois',
      DAY_START: 'Jour de la semaine que la période commence',
      TIME_START: 'Heure de début et de fin des périodes',
      OPEN_DAYS: 'Journée de disponibilité du service',
      DELIVERY_START_DELAY: 'Délai en jours du début de la livraison',
      DELIVERY_START_DURATION: 'Durée de la livraison en jours',
      TAKEOUT_START_DELAY: 'Délai en jours du début de la cueillette',
      TAKEOUT_START_DURATION: 'Durée de la cueillette en jours',
      DAY_1_START_HOUR_DELIVERY: "L'heure du début de la période de livraison",
      DAY_1_START_HOUR_TAKEOUT: "L'heure du début de la période de cueillette",
      LAST_DAY_DELIVERY_HOUR: "L'heure du début de la période de livraison",
      LAST_DAY_TAKEOUT_HOUR: "L'heure du début de la période de cueillette",
      PAUSE: 'AUTO-GÉNÉRATION ACTIF',
      UNPAUSE: 'AUTO-GÉNÉRATION INACTIF',
      GA_KEY: 'Clé Google Analytics',
      DELIVERY_RULES: 'Règles de livraison',
      COST_MODE: 'Mode de calcul du coût',
      LIMIT_KM: 'Distance limite',
      RATE_MODE_FIXED_RATE: 'Fixe',
      RATE_MODE_RATE_PER_KM: 'Par kilomètre',
      DISTANCE_MODE: 'Mode de calcul de la distance',
      DISTANCE_MODE_GOOGLE_ROUTE: 'Par Google Route',
      DISTANCE_MODE_CROW_FLIES: "À vol d'oiseau",
      BRACKET_MODE: 'Palier',
      BRACKET_MODE_SINGLE: 'Unique',
      BRACKET_MODE_MULTI: 'Multiple',
      BRACKET_MODE_SINGLE_LABEL: 'Palier unique',
      BRACKET_MODE_MULTI_LABEL: 'Palier multiple',
      RATE: 'Taux:',
      MIN_BRACKET_DISTANCE: 'Distance minimum du palier',
      MAX_BRACKET_DISTANCE: 'Distance maximum du palier',
      ADD_BRACKETS: 'Ajouter un palier',
      STORE_URL_HINT:
        'L’url de la commande en ligne une fois intégré au site du client (optionnel)',
      SHOW_NEXT_STACK: 'Voir les prochaines plages planifiées',
      SCHEDULE_RANGE: 'Plage horaire',
      MONERIS: 'Moneris',
      MONERIS_MERCHANT_ID: 'Identifiant de marchant',
      MONERIS_MERCHANT_KEY: 'Clé de marchant',
      MONERIS_MERCHANT_PROFILES: 'Profils',
      MONERIS_MERCHANT_ADD_PROFILES: 'Ajouter un profil',
      MONERIS_MERCHANT_PROFILE_ID: 'Identifiant de profil',
      MONERIS_MERCHANT_ID_HINT:
        'Le texte merchant id doit contenir au moins 10 caractères.',
      MONERIS_MERCHANT_KEY_HINT:
        'Le texte merchant key doit contenir au moins 20 caractères.',
      MONERIS_PROFILE_ID_HINT:
        'Le texte identifiant de profil doit contenir au moins 15 caractères.',
      MONERIS_PROFILE_URL: 'Domaine (URL) du profil',
      MONERIS_PROFILE_ID: 'Identifiant de profil',
      MONERIS_TEST_MODE: 'Mode test',
      STRIPE: 'Stripe',
      STRIPE_API_KEY: 'Clé API',
      STRIPE_SECRET_KEY: 'Clé secrète',
      GLOBAL_PAYMENT: 'Global payment',
      GP_MERCHANT_ID: 'Identifiant de marchant',
      GP_ACCOUNT_ID: 'Identifiant de compte',
      GP_SHARED_SECRET: 'Secret partagé',
      GP_REABATE_PASSWORD: 'Mot de passe de remboursement',
      SHOW_SWITCH_LOCALE: 'Boutique bilingue Anglais et Français',
      FRENCH_ONLY: 'Français seulement',
      BILINGUAL: 'Bilingue',
      MULTI_POINT_TAKEOUT: 'Multi-point de cueillette',
      POINT_TAKEOUT: 'Points de cueillette',
      ACCEPT_MULTI_TAKEOUT: 'Accepter les points de cueillette',
      USE_MULTI_TAKEOUT_INPUT: 'Utiliser un simple champ texte',
      MULTI_TAKEOUT_OPTION: 'Option de Multi-point de cueillette',
      ADD_MULTI_POINT_TAKEOUT: 'Ajouter un point de cueillette',
      NAME_FR: 'Nom (français)',
      NAME_EN: 'Nom (anglais)',
      EDIT_DELIVERY: 'Modifier livraisons',
      EDIT_TAKEOUT: 'Modifier cueillettes',
      RTE_SCHEDULE_V2: 'Générateur d’horaire des commandes V2',
      EDIT_SCHEDULE: 'Modifier',
      RTE_SCHEDULE_TABLE: {
        start_at: 'Commence le',

        end_at: 'Se termine le',

        delivery_start_at: 'Livraison commence le',

        delivery_end_at: 'Livraison se termine le',

        takeout_start_at: 'Cueillette commence le',

        takeout_end_at: 'Cueillette se termine le',
      },
      SHOW_ON_HOME: "Afficher sur la page d'accueil",
      REDIRECT_ULR: 'URL de redirection',
      CLICK_URL: "URL de redirection sur clique de l'image",
      CLICK_TARGET: 'Cible (target)',
      SELECT_IMG_FR: 'Sélectionner une image FR',
      SELECT_IMG_M_FR: 'Sélectionner une image pour mobile FR',
      SELECT_IMG_EN: 'Sélectionner une image EN',
      SELECT_IMG_M_EN: 'Sélectionner une image pour mobile EN',
      SELECT_IMG_RULES:
        'Image jpg ou png de 250px de large et recommandé 330px de haut',
      SELECT_IMG_RULES_2:
        'Image jpg ou png de 1200px de large et recommandé 450px de haut (minimum: 50, maximum 600)',
      SCHEDULE_MODE_TITLE: "Sélectionnez un mode d'affichage",
      SCHEDULE_MODE: "Mode d'affichage",
      SCHEDULE_MODE_SENTENCE: 'Affichage par phrase',
      SCHEDULE_MODE_DATE_TIME: 'Affichage par date et heure',
    },
  },
};
