import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DeliveryRequestContentService } from '../../../api/delivery-request-content.service';
import { DeliveryRequestTypeService } from '../../../api/delivery-request-type.service';
import { DepositInService } from '../../../api/deposit-in.service';
import { DepositRequestService } from '../../../api/deposit-request.service';
import { PaymentModesService } from '../../../api/payment-modes.service';
import { WrappingService } from '../../../api/wrapping.service';
import { ValidatorHelper } from '../../../validators/ValidatorHelper';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Editor } from 'primeng/editor';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import { Settings } from '../../../settings/settings';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { GT2DateAdapter } from '../../../utils/GT2DateAdapter';
import { ModulesService } from '../../../services/modules.service';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Gt2SelectComponent } from '../../gt2-select/gt2-select.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { CalendarDateFormatter } from 'angular-calendar';
import { CustomDateFormatter } from '../../../content/calendar/CustomDateFormatter';

@Component({
  selector: 'app-create-deposit-in',
  templateUrl: './create-deposit-in.component.html',
  styleUrls: ['./create-deposit-in.component.scss'],
  standalone: true,
  imports: [
    Gt2SelectComponent,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatButtonModule,
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
  ],
  providers: [
    { provide: DateAdapter, useClass: GT2DateAdapter },
    { provide: CalendarDateFormatter, useClass: CustomDateFormatter },
  ],
})
export class CreateDepositInComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  static CREATE: string = 'CREATE';
  static EDIT: string = 'EDIT';

  static DEPOSIT_MODE: string = 'DEPOSIT';
  static INVOICE_MODE: string = 'INVOICE';
  static MESSAGE_MODE: string = 'MESSAGE';

  //
  mode: string = CreateDepositInComponent.CREATE;
  uiMode?: string;
  form: UntypedFormGroup;
  formRef: UntypedFormGroup;
  formUdm: UntypedFormGroup;
  depositIn: any;
  eventUUID!: string;
  event: any;
  loading: boolean = false;
  autoRedirect: boolean = false;
  depositInToUpdate: any;
  todayDate: string;
  noteInvalid: boolean = false;
  noteEditorSubscription?: Subscription;
  noteEditordebounceTimeValue: number = 1500;
  @ViewChild('noteEditor') noteEditor?: Editor;

  static getDepositMode(event: any): string {
    const depositRequests: any[] =
      CreateDepositInComponent.filterNonCompletedDepositRequests(
        event.deposit_requests,
      );
    if (depositRequests.length > 0) {
      return CreateDepositInComponent.DEPOSIT_MODE;
    } else if (event.documents.invoice !== null) {
      return CreateDepositInComponent.INVOICE_MODE;
    } else {
      return CreateDepositInComponent.MESSAGE_MODE;
    }
  }

  static getDepositEditMode(depositInObj: any): string {
    if (depositInObj.deposit_request) {
      return CreateDepositInComponent.DEPOSIT_MODE;
    } else {
      return CreateDepositInComponent.INVOICE_MODE;
    }
  }

  static filterNonCompletedDepositRequests(depositRequests: any): any {
    if (depositRequests === null) {
      return [];
    }
    return depositRequests.filter((deposit: any) => {
      return deposit.received_by === null;
    });
  }

  constructor(
    public override dialogRef: MatDialogRef<CreateDepositInComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    public deliveryRequestTypeService: DeliveryRequestTypeService,
    public deliveryRequestContentService: DeliveryRequestContentService,
    public wrappingService: WrappingService,
    public depositRequestService: DepositRequestService,
    public paymentModesService: PaymentModesService,
    private depositInService: DepositInService,
    public dialog: MatDialog,
    public router: Router,
    private adapter: DateAdapter<any>,
    private toastr: ToastrService,
    private logger: NGXLogger,
    public moduleService: ModulesService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    this.adapter.setLocale(this.translate.currentLang);

    this.form = this.formBuilder.group({
      received_at: ['', [ValidatorHelper.dateTodaysOrLower]],
      amount: ['', [Validators.required]],
      // amount: ['', [ValidatorHelper.isAboveZero]],

      // ref_number: ['', [Validators.minLength(Settings.inputMinChar), Validators.maxLength(Settings.inputMaxCharMid)]],
      // ref_number: '',
    });

    this.formRef = this.formBuilder.group({
      ref_number: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
        ],
      ],
    });
    //
    this.formUdm = this.formBuilder.group({
      type_srce: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
        ],
      ],
      activity: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
        ],
      ],
      project_udem: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
        ],
      ],
      account: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
        ],
      ],
    });

    // this.logger.info("CreateDeliveryRequestComponent.constructor() -> data: " + JSON.stringify(data));
    this.depositIn = {
      received_at: new Date().toISOString(),
      amount: null,
      ref_number: null,
      note: null,
      type_srce: null,
      activity: null,
      project_udem: null,
      account: null,
      payment_mode: null,
    };

    if (data && data.mode !== null) {
      this.mode = data.mode;
    }

    if (data && data.eventUUID !== null) {
      this.eventUUID = data.eventUUID;
      this.depositRequestService.depositRequestListEventUUID = this.eventUUID;

      // this.logger.info("CreateDepositInComponent.constructor() -> this.depositRequestService.depositRequestListEventUUID: " + this.depositRequestService.depositRequestListEventUUID);
    }

    if (data && data.event !== null) {
      this.event = data.event;
      if (data.event.cashing_udem) {
        this.depositIn = data.event.cashing_udem;

        // {
        //     "object": "PaymentMode",
        //     "uuid": "80f07c2c-dca8-4821-aa5c-0e652c91d15d",
        //     "name": "Paiement interne UDM",
        //     "slug": "paiement-interne-udm",
        //     "name_local": {
        //         "name_fr_CA": "Paiement interne UDM",
        //         "name_en_CA": "Internal Payment UDM"
        //     }
        // }
        // this.depositIn.payment_mode =
      }
      this.depositIn.amount = this.event.balance;
      //this.logger.info("CreateDepositInComponent.constructor() -> this.event: " + JSON.stringify(this.event));
    }

    if (data && data.uiMode !== null) {
      this.uiMode = data.uiMode;
      //this.logger.info("CreateDepositInComponent.constructor() -> this.uiMode: " + this.uiMode);

      if (this.uiMode === CreateDepositInComponent.DEPOSIT_MODE) {
        this.form = this.formBuilder.group({
          received_at: ['', [ValidatorHelper.dateTodaysOrLower]],
          amount: '',
          // ref_number: ['', [Validators.minLength(Settings.inputMinChar), Validators.maxLength(Settings.inputMaxCharMid)]],
        });

        this.formRef = this.formBuilder.group({
          ref_number: [
            '',
            [
              Validators.minLength(Settings.inputMinChar),
              Validators.maxLength(Settings.inputMaxCharMid),
            ],
          ],
        });
      }
    }

    if (data && data.autoRedirect !== null) {
      this.autoRedirect = data.autoRedirect;
    }

    if (data && data.depositInToUpdate) {
      this.depositInToUpdate = data.depositInToUpdate;
      this.depositIn = this.depositInToUpdate;
      //this.logger.info("CreateDepositInComponent.doCreateDepositRequest() -> data.depositInToUpdate: " + JSON.stringify(data.depositInToUpdate));
      //this.logger.info("CreateDepositInComponent.doCreateDepositRequest() -> this.depositIn: " + JSON.stringify(this.depositIn));
    }

    //
    this.todayDate = new Date().toISOString();
    //
    this.depositIn.received_at = new Date().toISOString();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    //this.logger.info("CreateDepositInComponent.ngAfterViewInit() -> this.noteEditor: " + this.noteEditor);
    if (this.noteEditor) {
      this.noteEditorSubscription = this.noteEditor.onTextChange
        .pipe(debounceTime(this.noteEditordebounceTimeValue))
        .pipe(distinctUntilChanged())
        .subscribe({
          next: (data) => {
            if (data.htmlValue) {
              this.noteInvalid =
                data.htmlValue.length > Settings.editorMaxCharLong;
              //this.logger.info("CreateDepositInComponent.noteEditor.onTextChange() -> this.noteInvalid: " + this.noteInvalid);
              if (this.noteInvalid) {
                return;
              }
            }
          },
        });
    }
  }

  ngOnDestroy(): void {}

  public onCreateDepositRequest(): void {
    this.loading = true;
    //this.logger.info("CreateDepositInComponent.onCreateDepositRequest() -> deposit request: " + JSON.stringify(this.depositIn));
    // this.logger.info("CreateDepositInComponent.onCreateDepositRequest() -> typeof date: " + typeof this.depositIn.due_date);

    // if ( typeof this.depositIn.due_date !== 'string' )
    // {
    //   this.depositIn.due_date = GT2CalendarUtils.formatDateForAPI(this.depositIn.due_date);
    // }

    if (this.mode === CreateDepositInComponent.CREATE) {
      this.doCreateDepositRequest();
    } else if (this.mode === CreateDepositInComponent.EDIT) {
      this.updateDepositRequest();
    }
  }

  public doCreateDepositRequest(): void {
    //this.logger.info("CreateDepositInComponent.doCreateDepositRequest() ");
    this.depositInService
      .createDepositInRequest(this.depositIn, this.eventUUID)
      .subscribe({
        next: (response) => {
          // this.logger.info("CreateDepositInComponent.doCreateDepositRequest() -> SUCCESS: " + JSON.stringify(response));
          this.loading = false;
          this.dialogRef.close(response);
        },
        error: (error) => {
          this.logger.error(
            'CreateDepositInComponent.doCreateDepositRequest() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.GENERIC'),
          );
          // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
        },
      });
  }

  public updateDepositRequest(): void {
    //this.logger.info("CreateDepositInComponent.updateDepositRequest() -> ***** UPDATE this.deliveryRequest: " + JSON.stringify(this.depositIn));
    this.depositInService
      .updateDepositInRequest(this.depositIn, this.eventUUID)
      .subscribe({
        next: (response) => {
          // this.logger.info("CreateDepositInComponent.updateDepositRequest() -> SUCCESS: " + JSON.stringify(response));
          this.loading = false;
          this.dialogRef.close(response);
        },
        error: (error) => {
          this.logger.error(
            'CreateDepositInComponent.updateDepositRequest() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.GENERIC'),
          );
          // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
        },
      });
  }

  public onPaymentModeChange(event: any): void {
    //this.logger.info("CreateDepositInComponent.onPaymentModeChange() -> event: " + JSON.stringify(event));
    this.depositIn.payment_mode = event;
  }

  public onDepositRequestChange(event: any): void {
    //this.logger.info("CreateDepositInComponent.onDepositRequestChange() -> event: " + JSON.stringify(event));
    this.depositIn.deposit_request = event;
    this.depositIn.amount = this.depositIn.deposit_request.amount;
  }

  public onPaymentModeListLoaded(list: any): void {
    //this.logger.info("CreateDepositInComponent.onPaymentModeListLoaded() -> list: " + JSON.stringify(list));
    // {
    //     "object": "PaymentMode",
    //     "uuid": "80f07c2c-dca8-4821-aa5c-0e652c91d15d",
    //     "name": "Paiement interne UDM",
    //     "slug": "paiement-interne-udm",
    //     "name_local": {
    //         "name_fr_CA": "Paiement interne UDM",
    //         "name_en_CA": "Internal Payment UDM"
    //     }
    // }
    // this.depositIn.payment_mode =
    // TODO: CONTINUE
    // let paymentMode = this.data.find((item) => {
    //         return item.slug = "paiement-interne-udm";
    // });
    // this.logger.info(
    //     "CreateDepositInComponent.onPaymentModeListLoaded() -> paymentMode: " +
    //         JSON.stringify(paymentMode)
    // );
    // if(paymentMode) {
    //     this.depositIn.payment_mode = paymentMode;
    // }
  }
}
