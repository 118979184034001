import { MatRadioModule } from '@angular/material/radio';
import { LocaleHelperService } from './../../../../services/locale-helper.service';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-poinst-takeout',
  templateUrl: './poinst-takeout.component.html',
  styleUrls: ['./poinst-takeout.component.scss'],
  standalone: true,
  imports: [
    MatRadioModule,
    TranslateModule,
    FlexLayoutModule,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    CommonModule,
    MatButtonModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
  ],
})
export class PoinstTakeoutComponent implements OnInit {
  //
  @Input() shop: any;
  @Input() multiPointTakeoutModel: any;
  @Input() editMode: boolean = false;
  @Output() saveInfo: EventEmitter<any> = new EventEmitter();
  @Output() addMultiPointTakeout: EventEmitter<any> = new EventEmitter();
  @Output() editPointTakeout: EventEmitter<any> = new EventEmitter();
  @Output() removePointTakeout: EventEmitter<any> = new EventEmitter();
  //
  multiTakeoutRadioValue: number = 0;

  constructor(public localeHelperService: LocaleHelperService) {
    //
  }

  ngOnInit() {
    this.multiTakeoutRadioValue = 0;
    if (this.shop.is_multi_takeout_location) {
      this.multiTakeoutRadioValue = 0;
    } else if (this.shop.is_multi_takeout_input) {
      this.multiTakeoutRadioValue = 1;
    }
  }

  public onSaveInfo(): void {
    this.saveInfo.emit();
  }

  public onAddMultiPointTakeout(): void {
    this.addMultiPointTakeout.emit();
  }

  public onEditPointTakeout(item: any): void {
    this.editPointTakeout.emit(item);
  }

  public onRemovePointTakeout(item: any, i: any): void {
    this.removePointTakeout.emit({ item: item, position: i });
  }

  public onRadioGroupChange(event: any): void {
    this.shop.is_multi_takeout_location = this.multiTakeoutRadioValue === 0;
    this.shop.is_multi_takeout_input = this.multiTakeoutRadioValue === 1;
  }
}
