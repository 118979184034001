<div class="profile-box info-box width-100-content">
  <header class="accent" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="title">{{ headerLabel }}</div>
    <div
      class="gt2-light-green"
      style="width: 60px; height: 56px"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <button
        type="button"
        matTooltip="{{ createLabel }}"
        mat-icon-button
        class=""
        (click)="onCreateNew()"
      >
        <mat-icon class="icon-mat-black" color="">add</mat-icon>
      </button>
    </div>
  </header>
  <div style="min-width: 370px" class="content" fxLayout="column">
    <div
      *ngIf="
        !isLoadingResults && dataSource.data && dataSource.data.length === 0
      "
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      {{ "GENERIC.NO_RESULTS" | translate }}
    </div>
    <mat-table
      #table
      class=""
      matSort
      matSortStart="desc"
      [@animateStagger]="{ value: '50' }"
      [dataSource]="dataSource"
    >
      <!-- Name Column -->
      <ng-container cdkColumnDef="name">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header>{{
          tableHeaderLabel
        }}</mat-header-cell>
        <mat-cell class="no-text-cursor" *cdkCellDef="let row">
          <div
            *ngIf="!editSelected || editSelected.uuid != row.uuid"
            [innerHtml]="row.name"
          ></div>

          <div
            *ngIf="editSelected && editSelected.uuid == row.uuid"
            fxLayout="column"
            fxFlex="1 0 auto"
          >
            <div fxLayout="row" fxLayoutAlign="start start" fxFlex="100%">
              <!-- <mat-form-field fxFlex="100" class="" floatLabel="auto">
                            <input matInput
                            type="text"
                            [(ngModel)]="editSelected.name">
                            </mat-form-field> -->
              <div fxLayout="column" [formGroup]="form" fxFlex="1 0 auto">
                <div class="mat-body-2 mt-4">Français</div>
                <mat-form-field fxFlex="100" class="" floatLabel="auto">
                  <textarea
                    matInput
                    [(ngModel)]="editSelected.name_local.name_fr_CA"
                    formControlName="name_fr_CA"
                    placeholder=""
                    mat-maxlength="250"
                    max-rows="4"
                  >
                  </textarea>
                </mat-form-field>

                <!-- <p-editor
                                        [(ngModel)]="editSelected.name_local.name_fr_CA"
                                        [style]="{'height':'100px', 'width' : '100%', 'background-color': '#FFFFFF'}">
                                        <ng-template pTemplate="header"  class="">
                                                <span class="ql-formats">
                                                    <select class="ql-font" ></select>
                                                    <select class="ql-size"></select>
                                                </span>
                                      
                                                <span class="ql-formats">
                                                    <button class="ql-bold" ></button>
                                                    <button class="ql-italic"></button>
                                                    <button class="ql-underline"></button>
                                                    <button class="ql-strike"></button>
                                                </span>
                                      
                                                <span class="ql-formats">
                                                    <select class="ql-color"></select>
                                                    <select class="ql-background"></select>
                                                </span>
                                      
                                                <span class="ql-formats">
                                                    <button class="ql-list" value="ordered"></button>
                                                    <button class="ql-list" value="bullet"></button>
                                                    <button class="ql-indent" value="-1"></button>
                                                    <button class="ql-indent" value="+1"></button>
                                                </span>
                                            </ng-template>
                                </p-editor> -->
              </div>

              <!-- <button mat-icon-button
                                    color=""
                                    class="mt-16"
                                    matTooltip="{{ 'GENERIC.SAVE' | translate }}"
                                    (click)="onEditCategoryFocusOut(editSelected, editSelected.name, 'fr-CA')">
                                <mat-icon class="mat-accent">save</mat-icon>
                            </button> -->
            </div>

            <div fxLayout="row" fxLayoutAlign="start start" class="mt-4">
              <!--<mat-form-field fxFlex="100" class="" floatLabel="auto">-->
              <!--<input matInput-->
              <!--placeholder=""-->
              <!--type="text"-->
              <!--[(ngModel)]="editSelected.name_local.name_en_CA">-->
              <!--</mat-form-field>-->

              <div fxLayout="column" [formGroup]="form" fxFlex="1 0 auto">
                <div class="mat-body-2 mt-4">English</div>
                <mat-form-field fxFlex="100" class="" floatLabel="auto">
                  <textarea
                    matInput
                    [(ngModel)]="editSelected.name_local.name_en_CA"
                    formControlName="name_en_CA"
                    placeholder=""
                    mat-maxlength="250"
                    max-rows="4"
                  >
                  </textarea>
                </mat-form-field>
                <!-- <p-editor
                                        [(ngModel)]="editSelected.name_local.name_en_CA"
                                        [style]="{'height':'100px', 'width' : '100%', 'background-color': '#FFFFFF'}">
                                        <ng-template pTemplate="header"  class="">
                                                <span class="ql-formats">
                                                    <select class="ql-font" ></select>
                                                    <select class="ql-size"></select>
                                                </span>
                                      
                                                <span class="ql-formats">
                                                    <button class="ql-bold" ></button>
                                                    <button class="ql-italic"></button>
                                                    <button class="ql-underline"></button>
                                                    <button class="ql-strike"></button>
                                                </span>
                                      
                                                <span class="ql-formats">
                                                    <select class="ql-color"></select>
                                                    <select class="ql-background"></select>
                                                </span>
                                      
                                                <span class="ql-formats">
                                                    <button class="ql-list" value="ordered"></button>
                                                    <button class="ql-list" value="bullet"></button>
                                                    <button class="ql-indent" value="-1"></button>
                                                    <button class="ql-indent" value="+1"></button>
                                                </span>
                                            </ng-template>
                                </p-editor> -->
              </div>

              <!-- <button mat-icon-button
                                    color=""
                                    class="mt-16"
                                    matTooltip="{{ 'GENERIC.SAVE' | translate }}"
                                    (click)="onEditCategoryFocusOut(editSelected, editSelected.name_local.name_en_CA, 'en-CA')">
                                <mat-icon class="mat-accent">save</mat-icon>
                            </button> -->
            </div>

            <div
              fxLayout="row"
              fxLayoutAlign="end center"
              class="gt2-buttons-theme mt-8"
            >
              <button
                mat-raised-button
                color="accent"
                class="mb-16"
                (click)="autosaveDataChange(editSelected)"
              >
                {{ "GENERIC.SAVE" | translate }}
              </button>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <!-- EDIT Column -->
      <ng-container cdkColumnDef="edit">
        <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
        <mat-cell
          fxLayoutAlign="center center"
          fxFlex="50px"
          *cdkCellDef="let row"
        >
          <button
            mat-icon-button
            matTooltip="{{
              editSelected != row
                ? translate.instant('GENERIC.EDIT')
                : translate.instant('GENERIC.EDIT_STOP')
            }}"
            (click)="onEdit(row)"
          >
            <mat-icon
              *ngIf="!editSelected || editSelected.uuid != row.uuid"
              class="s-16"
            >
              {{ getIcon("EDIT") }}
            </mat-icon>
            <mat-icon
              *ngIf="editSelected && editSelected.uuid == row.uuid"
              class="s-16"
            >
              {{ getIcon("UNDO") }}
            </mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <!-- Delete Column -->
      <ng-container cdkColumnDef="delete">
        <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
        <mat-cell
          fxLayoutAlign="center center"
          fxFlex="50px"
          *cdkCellDef="let row"
        >
          <button
            matSuffix
            mat-icon-button
            matTooltip="{{ 'GENERIC.DELETE' | translate }}"
            (click)="onDelete(row)"
          >
            <mat-icon class="s-16">delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        class="mat-row-color"
        *cdkRowDef="let row; columns: displayedColumns; let index = index"
        (click)="onSelect(row)"
        [@animate]="{ value: '*', params: { y: '100%' } }"
      >
        <!--[ngClass]="{'highlight': index%2 == 1}"-->
      </mat-row>
    </mat-table>

    <mat-paginator
      #paginator
      [length]="resultsLength"
      showFirstLastButtons="true"
      (page)="datatableHelperService.onPaginator($event, 'specificConditions')"
      [pageIndex]="0"
      [pageSize]="'specificConditions' | tableSizePipe: 'large'"
      [pageSizeOptions]="datatableHelperService.pageSizeOptions"
    >
    </mat-paginator>
  </div>
</div>
