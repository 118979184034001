<h1 matDialogTitle>{{ "CREATE_ORDER.TITLE" | translate }}</h1>
<!-- <div class="">{{ event_date | calendarDate:('dayViewTitle'):translate.currentLang }}</div> -->

<div
  fxLayout="column"
  fxLayoutAlign="center start"
  [formGroup]="form"
  (keyup.enter)="form.valid && !loading ? onCreateOrder() : null"
  class="mt-8 create-client-width"
>
  <mat-form-field class="create-order-width">
    <mat-label>{{ "GENERIC.NAME" | translate }}</mat-label>
    <input
      matInput
      fxFlex="100%"
      formControlName="name"
      type="text"
      autocomplete="nope"
      [(ngModel)]="order.name"
      required
    />
    <mat-error *ngIf="form.controls['name'].invalid">{{
      "GENERIC.INPUT_LENGTH_INVALID" | translate
    }}</mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="event_date" class="create-order-width">
    <mat-label>{{ "GENERIC.DATE" | translate }}</mat-label>
    <input
      fxFlex="100%"
      matInput
      [matDatepicker]="datePicker"
      [(ngModel)]="event_date"
      (dateChange)="onDatePickerChange($event)"
      min="1900-01-01"
      formControlName="event_date"
    />
    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
    <mat-datepicker #datePicker></mat-datepicker>
  </mat-form-field>
</div>

<div *ngIf="loading" fxLayout="row" fxLayoutAlign="center start">
  <mat-spinner [diameter]="40" class="mt-20"></mat-spinner>
</div>

<div
  mat-dialog-actions
  [formGroup]="form"
  class="mt-16 gt2-buttons-theme"
  fxLayout="row"
  fxLayoutAlign="center start"
>
  <button
    mat-raised-button
    color="accent"
    class="mr-16"
    type="submit"
    [disabled]="!form.valid || loading"
    (click)="onCreateOrder()"
  >
    {{ "GENERIC.SAVE" | translate }}
  </button>

  <button
    mat-raised-button
    color="primary"
    (click)="dialogRef.close(false)"
    class=""
  >
    {{ "GENERIC.CANCEL" | translate }}
  </button>
</div>
