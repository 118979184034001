import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { GT2PageAbstract } from '../../../../../content/abstract/GT2PageAbstract';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '../../../../../../../@fuse/services/translation-loader.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { EnterprisesService } from '../../../../../api/enterprises.service';
import { ClientsService } from '../../../../../api/clients.service';
import { EventsService } from '../../../../../api/events.service';
import { VenuesPlaceService } from '../../../../../api/venues-place.service';
import { CreateRoomComponent } from '../../../../../components/dialogs/create-room/create-room.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'app-select-enterprise-room',
  templateUrl: './select-enterprise-room.component.html',
  styleUrls: ['./select-enterprise-room.component.scss'],
  standalone: true,
  imports: [
    MatTooltipModule,
    MatIconModule,
    FormsModule,
    FlexLayoutModule,
    MatButtonModule,
    MatInputModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
})
export class SelectEnterpriseRoomComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  places?: any[];
  eventUUID!: string;
  enterprise: any;
  // enterpriseUUID: string;
  filterValue: string = '';
  selectedRoom: any;
  isLoading: boolean = false;
  createDialogRef: any;

  constructor(
    public override dialogRef: MatDialogRef<SelectEnterpriseRoomComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    private logger: NGXLogger,
    public dialog: MatDialog,
    private clientsService: ClientsService,
    private enterprisesService: EnterprisesService,
    private eventsService: EventsService,
    private venuesPlaceService: VenuesPlaceService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    if (data.eventUUID) {
      this.eventUUID = data.eventUUID;
    }

    // if (data.enterpriseUUID) {
    //   this.enterpriseUUID = data.enterpriseUUID;
    // }

    if (data.enterprise) {
      this.enterprise = data.enterprise;
    }

    this.loadData();
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public loadData(): void {
    // this.logger.info("SelectEnterpriseRoomComponent.loadData()");
    this.isLoading = true;
    this.venuesPlaceService
      .getEnterpriseRoomsList(this.enterprise.uuid)
      .subscribe({
        next: (response) => {
          // this.logger.info("SelectEnterpriseRoomComponent.constructor() -> SUCCESS: " + JSON.stringify(response));
          this.places = response.data;
          this.isLoading = false;
        },
        error: (error) => {
          this.logger.error(
            'SelectEnterpriseRoomComponent.constructor() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.isLoading = false;
          this.handleAPIError(error, this.dialog, null, null);
        },
      });
  }

  public onSelectRoom(item: any): void {
    // this.logger.info("SelectEnterpriseRoomComponent.onSelectRoom()");
    this.selectedRoom = item;
    // this.logger.info("SelectEnterpriseRoomComponent.onSelectRoom() -> this.selectedRoom: " + JSON.stringify(this.selectedRoom));
  }

  public onRoomSelected(): void {
    // this.logger.info("SelectEnterpriseRoomComponent.onRoomSelected()");
    this.isLoading = true;

    // TODO:
    this.eventsService
      .updateEventVenues(this.eventUUID, {
        room: this.selectedRoom,
        enterprise: this.enterprise,
      })
      .subscribe({
        next: (response: any) => {
          // this.logger.info("SelectEnterpriseRoomComponent.onRoomSelected() -> SUCCESS: " + JSON.stringify(response));
          this.isLoading = false;
          this.dialogRef.close(response);
        },
        error: (error: any) => {
          this.logger.error(
            'SelectEnterpriseRoomComponent.onRoomSelected() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.isLoading = false;
          this.handleAPIError(error, this.dialog, null, null);
        },
      });
  }

  public onAddNewRoom() {
    // this.logger.info("SelectEnterpriseRoomComponent.onAddNewRom()");
    this.createDialogRef = this.dialog.open(CreateRoomComponent, {
      width: '440px',
      minWidth: 350,
      disableClose: false,
      panelClass: 'scrollable-dialog',
      data: {
        parentType: CreateRoomComponent.ENTERPRISE,
        parentUUID: this.enterprise.uuid,
      },
    });

    this.createDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          // this.logger.info("SelectEnterpriseRoomComponent.onAddNewRom() -> result:  " + JSON.stringify(result));
          this.loadData();
        }
        this.createDialogRef = null;
      },
    });
  }

  public onSelectEnterpriseNoRoom() {
    // this.logger.info("SelectEnterpriseRoomComponent.onSelectEnterpriseNoRoom()");
    this.eventsService
      .updateEventVenues(this.eventUUID, { enterprise: this.enterprise })
      .subscribe({
        next: (response: any) => {
          // this.logger.info("SelectPlaceRoomComponent.onRoomSelected() -> SUCCESS: " + JSON.stringify(response));
          this.isLoading = false;
          this.dialogRef.close(response);
        },
        error: (error: any) => {
          this.logger.error(
            'SelectPlaceRoomComponent.onRoomSelected() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.isLoading = false;
          this.handleAPIError(error, this.dialog, null, null);
        },
      });
  }

  public clearSearch(): void {
    this.filterValue = '';
  }
}
