import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { CreateClientService } from '../../../api/create-client.service';
import { ToastrService } from 'ngx-toastr';
import { NGXLogger } from 'ngx-logger';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Settings } from '../../../settings/settings';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-create-client-enterprise',
  templateUrl: './create-client-enterprise.component.html',
  styleUrls: ['./create-client-enterprise.component.scss'],
  standalone: true,
  imports: [
    MatInputModule,
    FormsModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    MatDialogModule,
    FlexLayoutModule,
    MatButtonModule,
    MatFormFieldModule,
    CommonModule,
    TranslateModule,
  ],
})
export class CreateClientEnterpriseComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  form: UntypedFormGroup;
  clientEnterprise: any;
  loading: boolean = false;

  constructor(
    public override dialogRef: MatDialogRef<CreateClientEnterpriseComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    private createClientService: CreateClientService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private logger: NGXLogger,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    this.form = this.formBuilder.group({
      first_name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      last_name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
    });

    this.clientEnterprise = {
      client: {
        first_name: null,
        last_name: null,
      },
      enterprise: {
        name: null,
      },
    };
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public onCreateClient(): void {
    this.loading = true;
    // this.logger.info("CreateClientEnterpriseComponent.onCreateClient() -> " + this.form.valid);
    // this.logger.info("CreateClientEnterpriseComponent.onCreateClient() -> this.client: " + JSON.stringify(this.clientEnterprise));
    if (this.form.valid) {
      this.createClientService
        .createClientAndEnterprise(this.clientEnterprise)
        .subscribe({
          next: (response: any) => {
            // this.logger.info("CreateClientEnterpriseComponent.onCreateClient() -> SUCCESS: " + JSON.stringify(response));
            this.toastr.success(
              this.translate.instant(
                'CREATE_CLIENT_ENTERPRISE.CREATE_CLIENT_ENTERPRISE_SUCCESS',
              ),
            );
            this.dialogRef.close(response);
            this.loading = false;
          },
          error: (error: any) => {
            this.logger.error(
              'CreateClientEnterpriseComponent.onCreateClient() -> ERROR: ' +
                JSON.stringify(error),
            );
            this.loading = false;
            // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
            this.handleAPIError(
              error,
              this.dialog,
              this.toastr,
              this.translate.instant('GENERIC.ERRORS.GENERIC'),
            );
          },
        });
    }
  }
}
