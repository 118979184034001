<!-- <div id="dashboard" class="page-layout simple fullwidth" fxLayout="column" fusePerfectScrollbar> --><!-- old -->
<!-- <div id="dashboard" class="page-layout simple left-sidebar inner-sidebar inner-scroll" fxLayout="column"> --><!-- originall -->

<div id="dashboard" class="page-layout simple fullwidth" fxLayout="column">
  <!--Scroll tout la page et pas les contenus de la tab-->
  <!-- HEADER -->
  <div class="gt2-theme">
    <div
      class="header accent pl-24 pr-24 h-80 mat-elevation-z1"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <div fxLayout="column" fxLayoutAlign="center start">
        <div class="h1 mt-8">{{ "GENERIC.DASHBOARD" | translate }}</div>
      </div>
    </div>
  </div>
  <!-- / HEADER -->
  <!-- SUB HEADER -->
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fusePerfectScrollbar
    class="primary mat-elevation-z1 content-subheader"
  >
    <div fxLayout="row" fxLayoutAlign="start center"></div>

    <div fxLayout="row" fxLayoutAlign="end center">
      <div
        fxLayout="row"
        fxLayoutAlign="end center"
        *ngIf="
          modulesService.modulesAreSet &&
          !modulesService.hasCTReport() &&
          !modulesService.hasRPReport()
        "
      >
        <button
          *ngxPermissionsOnly="rolesService.SALE_ROLES"
          mat-button
          (click)="onNewProposal()"
          class="icon-mat"
        >
          <mat-icon
            class="icon-mat s-24"
            style="margin-right: 8px !important"
            >{{ getIcon("PROPOSAL") }}</mat-icon
          >
          {{ "GENERIC.CREATE_PROPOSAL" | translate }}
        </button>

        <button
          *ngxPermissionsOnly="rolesService.SALE_ROLES"
          mat-button
          (click)="onCreateOrder()"
          class="icon-mat"
        >
          <mat-icon
            class="icon-mat s-24"
            style="margin-right: 8px !important"
            >{{ getIcon("ORDER") }}</mat-icon
          >
          {{ "DASHBOARD.CREATE_ORDER" | translate }}
        </button>
      </div>

      <div
        *ngIf="
          modulesService.modulesAreSet &&
          (modulesService.hasCTReport() || modulesService.hasRPReport())
        "
      >
        <app-create-congresses-button></app-create-congresses-button>
      </div>
      <div
        *ngIf="modulesService.modulesAreSet && modulesService.hasQuickOrder()"
      >
        <button mat-button (click)="onQuickOrder()" class="icon-mat">
          <mat-icon class="icon-mat s-24">{{ getIcon("SHOP") }}</mat-icon>
          {{ "GENERIC.QUICK_ORDER" | translate }}
        </button>
      </div>
    </div>
  </div>
  <!-- / SUB HEADER -->

  <!-- Spinner -->
  <div *ngIf="!modulesService.modulesAreSet">
    <div
      fxLayout="column"
      fxFlex="100%"
      fxLayoutAlign="center center"
      class="py-80"
    >
      <!--            <mat-spinner [diameter]="24" class=""></mat-spinner>-->
      <ngx-spinner
        bdColor="rgba(51,51,51,0.8)"
        size="medium"
        color="#90ee90"
        [fullScreen]="false"
        type="ball-scale-multiple"
      >
        <p style="font-size: 20px; color: white">
          {{ "DASHBOARD.LOADING" | translate }}
        </p>
      </ngx-spinner>
    </div>
  </div>

  <!-- TABs (Dashboard calendar & Room Management) -->
  <div *ngIf="modulesService.modulesAreSet">
    <app-dashboard-calendar #dashboarCalendar></app-dashboard-calendar>

    <!-- <mat-tab-group #tabsGroup (selectedTabChange)='onSelectChange($event)' animationDuration="0ms" dynamicHeight>

            <mat-tab label="{{ 'GENERIC.CALENDAR' | translate }}">
                <app-dashboard-calendar #dashboarCalendar></app-dashboard-calendar>
            </mat-tab>

            <mat-tab *ngIf="modulesService.hasDetailedCalendar()" label="{{ 'DASHBOARD.DETAILED_CALENDAR' | translate }}">
                <app-dashboard-detailed-calendar #calendarDetailed></app-dashboard-detailed-calendar>
            </mat-tab>

            <mat-tab *ngIf="modulesService.hasRoomManagement()" label="{{ 'DASHBOARD.ROOM_MANAGEMENT' | translate }}">
                <app-room-management #dashboarRoomManagement></app-room-management>
            </mat-tab>

        </mat-tab-group> -->

    <!--        <div *ngIf="modulesService.hasRoomManagement()">-->
    <!--            <mat-tab-group #tabsGroup (selectedTabChange)='onSelectChange($event)' animationDuration="0ms" dynamicHeight>-->

    <!--                <mat-tab label="{{ 'DASHBOARD.DETAILED_CALENDAR' | translate }}">-->
    <!--                    <app-dashboard-detailed-calendar></app-dashboard-detailed-calendar>-->
    <!--                </mat-tab>-->

    <!--                <mat-tab label="{{ 'GENERIC.CALENDAR' | translate }}">-->
    <!--                        <app-dashboard-calendar #dashboarCalendar></app-dashboard-calendar>-->
    <!--                </mat-tab>-->

    <!--                <mat-tab label="{{ 'DASHBOARD.ROOM_MANAGEMENT' | translate }}">-->
    <!--                        <app-room-management #dashboarRoomManagement></app-room-management>-->
    <!--                </mat-tab>-->

    <!--            </mat-tab-group>-->
    <!--        </div>-->
    <!--    -->
    <!--        <div *ngIf="!modulesService.hasRoomManagement()">-->
    <!--&lt;!&ndash;            <app-dashboard-calendar #dashboarCalendar></app-dashboard-calendar>&ndash;&gt;-->
    <!--            <mat-tab-group #tabsGroup (selectedTabChange)='onSelectChange($event)' animationDuration="0ms" dynamicHeight>-->

    <!--                <mat-tab label="{{ 'DASHBOARD.DETAILED_CALENDAR' | translate }}">-->
    <!--                    <app-dashboard-detailed-calendar></app-dashboard-detailed-calendar>-->
    <!--                </mat-tab>-->

    <!--                <mat-tab label="{{ 'GENERIC.CALENDAR' | translate }}">-->
    <!--                    <app-dashboard-calendar #dashboarCalendar></app-dashboard-calendar>-->
    <!--                </mat-tab>-->

    <!--            </mat-tab-group>-->
    <!--        </div>-->
  </div>
</div>
