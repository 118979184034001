import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import {
  MatTable,
  MatTableDataSource,
  MatTableModule,
} from '@angular/material/table';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { of, merge } from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { fuseAnimations } from '../../../../../@fuse/animations/index';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { DatatableHelperService } from '../../../../services/datatable-helper.service';
import { ApiRoutes } from '../../../api/ApiRoutes';
import { ClientsCategoryModel } from '../../../api/clients-categories.service';
import { CrudDatatableGenericService } from '../../../api/crud-datatable-generic.service';
import { LedgerAccountService } from '../../../api/ledger-account.service';
import { GenericCreateComponent } from '../../../components/dialogs/generic-create/generic-create.component';
import { GenericAlertComponent } from '../../../components/generic-alert/generic-alert.component';
import { Settings } from '../../../settings/settings';
import { GT2PageAbstract } from '../../abstract/GT2PageAbstract';
import { CrudDatatableGenericModel } from '../../crud/crud-datatable-generic/crud-datatable-generic.component';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TableSizePipeModule } from '../../../modules/table-size.module';
import { CdkTableModule } from '@angular/cdk/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-wrapping',
  templateUrl: './wrapping.component.html',
  styleUrls: ['./wrapping.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    MatPaginatorModule,
    MatInputModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatSortModule,
    CdkTableModule,
    MatTableModule,
    TableSizePipeModule,
    MatTooltipModule,
    TranslateModule,
    CommonModule,
  ],
  providers: [CrudDatatableGenericService],
  animations: fuseAnimations,
})
export class WrappingComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() headerLabel?: string;
  serviceURL: string;
  dataSource = new MatTableDataSource();
  displayedColumns = ['name', 'edit', 'delete'];
  selected!: ClientsCategoryModel;
  editSelected: any;
  form: UntypedFormGroup;
  newCategory!: string;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('table') table!: MatTable<any>;
  @ViewChild(MatSort) sort!: MatSort;
  confirmDialogRef: any;
  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;
  currentLanguage?: string;

  constructor(
    private logger: NGXLogger,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private toastr: ToastrService,
    public translate: TranslateService,
    public ledgerAccountService: LedgerAccountService,
    public datatableHelperService: DatatableHelperService,
    private crudDatatableGenericService: CrudDatatableGenericService,
    private translationLoader: FuseTranslationLoaderService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    this.form = this.formBuilder.group({
      name_fr_CA: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      name_en_CA: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
    });

    this.serviceURL = ApiRoutes.WRAPPING;
  }

  ngOnInit() {}

  ngOnDestroy() {}

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe({
      next: () => (this.paginator.pageIndex = 0),
    });

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(startWith(null))
      .pipe(
        switchMap(() => {
          setTimeout(() => {
            this.isLoadingResults = true;
          });

          return this.crudDatatableGenericService.getAll(
            this.serviceURL,
            this.sort?.active + '->' + this.translate.currentLang,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize,
          );
        }),
      )
      .pipe(
        map((data) => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.meta.pagination.total;
          return data.data;
        }),
      )
      .pipe(
        catchError(() => {
          setTimeout(() => {
            this.isLoadingResults = false;
            this.isRateLimitReached = true;
          });
          return of([]);
        }),
      )
      .subscribe({ next: (data) => (this.dataSource.data = data) });
  }

  public onSelect(selected: ClientsCategoryModel) {
    // this.logger.info("ClientsCategoriesSettingsComponent.onSelect() -> " + selected);
    this.onEdit(selected);
  }

  public reloadData(): void {
    this.paginator?._changePageSize(this.paginator.pageSize);
  }

  public onCreateNew() {
    // this.logger.info("WrappingComponent.onCreateNew() ");

    this.confirmDialogRef = this.dialog.open(GenericCreateComponent, {
      disableClose: false,
      width: '400px',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      data: {
        dialogTitle: this.translate.instant('WRAPPING.CREATE_NEW_CATEGORY'),
        serviceURL: this.serviceURL,
        createPlaceholder: this.translate.instant('WRAPPING.NEW_CATEGORY_NAME'),
        useEnglish: true,
      },
    });

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          // this.logger.info("WrappingComponent.onCreateNew() -> CREATION SUCCESS");
          this.reloadData();
        } else {
          // this.logger.info("WrappingComponent.onCreateNew() -> CREATION CANCELED");
        }
        this.confirmDialogRef = null;
      },
    });
  }

  public onEdit(selected: ClientsCategoryModel) {
    // this.logger.info("WrappingComponent.onEdit() -> " + JSON.stringify(selected));

    if (this.editSelected === selected) {
      this.editSelected = null;
    } else {
      this.editSelected = selected;
    }
  }

  public onDelete(selected: ClientsCategoryModel) {
    // this.logger.info("WrappingComponent.onDelete() -> " + selected);
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.crudDatatableGenericService
            .delete(ApiRoutes.WRAPPING, selected)
            .subscribe({
              next: (response: any) => {
                // this.logger.info("WrappingComponent.onDelete() -> SUCCESS:" + JSON.stringify(response));
                this.toastr.success(
                  '',
                  this.translate.instant('GENERIC.DELETE_SUCCESS'),
                );
                this.reloadData();
              },
              error: (error: any) => {
                this.logger.error(
                  'WrappingComponent.onDelete() -> ERROR' +
                    JSON.stringify(error),
                );
                // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
                this.handleAPIError(
                  error,
                  this.dialog,
                  this.toastr,
                  this.translate.instant('GENERIC.ERRORS.GENERIC'),
                );
              },
            });
        }
        this.confirmDialogRef = null;
      },
    });
  }

  public onEditCategoryFocusOut(
    selected: CrudDatatableGenericModel,
    name: string,
    local: string,
  ): void {
    // this.logger.info("WrappingComponent.onEditCategoryFocusOut() -> name: " + name);
    // this.logger.info("WrappingComponent.onEditCategoryFocusOut() -> local: " + local);

    this.autosaveDataChange(selected);
  }

  public onSaveHourlyWage(editSelected: any): void {
    // this.logger.info("WrappingComponent.onSaveAccoutningNumber() -> editSelected: " + JSON.stringify(editSelected));
    this.autosaveDataChange(editSelected);
  }

  public autosaveDataChange(data: any): void {
    // this.logger.info("WrappingComponent.autosaveDataChange() -> data to save: " + JSON.stringify(data));
    this.crudDatatableGenericService.edit(this.serviceURL, data).subscribe({
      next: (response: any) => {
        // this.logger.info("WrappingComponent.onEdit() -> SUCCESS:" + JSON.stringify(response));
        this.editSelected = null;
        this.toastr.success(this.translate.instant('WRAPPING.EDIT_SUCCESS'));
        this.reloadData();
      },
      error: (error: any) => {
        this.logger.error(
          'WrappingComponent.onEdit() -> ERROR' + JSON.stringify(error),
        );
        // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
        this.handleAPIError(
          error,
          this.dialog,
          this.toastr,
          this.translate.instant('GENERIC.ERRORS.GENERIC'),
        );
      },
    });
  }

  public onLanguageChange(lang: string): void {
    // this.logger.info("WrappingComponent.onLanguageChange() -> " + lang);
    this.reloadData();
  }
}
