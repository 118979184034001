import { GT2CalendarUtils } from './../../../utils/GT2CalendarUtils';
import { EnterpriseInvoiceService } from './../EnterpriseInvoice.service';
import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DeliveryRequestContentService } from '../../../api/delivery-request-content.service';
import { DeliveryRequestTypeService } from '../../../api/delivery-request-type.service';
import { DepositInService } from '../../../api/deposit-in.service';
import { DepositRequestService } from '../../../api/deposit-request.service';
import { PaymentModesService } from '../../../api/payment-modes.service';
import { WrappingService } from '../../../api/wrapping.service';
import { ValidatorHelper } from '../../../validators/ValidatorHelper';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import { Settings } from '../../../settings/settings';
import { locale as english } from './../../clients/i18n/en-CA';
import { locale as french } from './../../clients/i18n/fr-CA';
import { GT2DateAdapter } from '../../../utils/GT2DateAdapter';
import { DatatableHelperService } from '../../../../services/datatable-helper.service';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Gt2SelectComponent } from '../../../components/gt2-select/gt2-select.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { EnterpriseCashingInvoiceComponent } from '../enterprise-cashing-invoice/enterprise-cashing-invoice.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { CalendarDateFormatter } from 'angular-calendar';
import { CustomDateFormatter } from '../../calendar/CustomDateFormatter';

@Component({
  selector: 'app-enterprise-cashing-modal',
  templateUrl: './enterprise-cashing-modal.component.html',
  styleUrls: ['./enterprise-cashing-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatInputModule,
    FlexLayoutModule,
    EnterpriseCashingInvoiceComponent,
    MatDatepickerModule,
    Gt2SelectComponent,
    MatButtonModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
  ],
  providers: [
    { provide: DateAdapter, useClass: GT2DateAdapter },
    { provide: CalendarDateFormatter, useClass: CustomDateFormatter },
    EnterpriseInvoiceService,
    DatatableHelperService,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class EnterpriseCashingModalComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  parentUUID?: any;
  form: UntypedFormGroup;
  formRef: UntypedFormGroup;
  //
  depositIn: any;
  //
  invoices!: any[];
  invoicesModel!: any[];
  totalEntered: number = 0;
  totalEnteredRounded: number = 0;
  //
  noteInvalid: boolean = false;
  //
  loading: boolean = false;
  //
  constructor(
    public override dialogRef: MatDialogRef<EnterpriseCashingModalComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    public deliveryRequestTypeService: DeliveryRequestTypeService,
    public deliveryRequestContentService: DeliveryRequestContentService,
    public wrappingService: WrappingService,
    public depositRequestService: DepositRequestService,
    public paymentModesService: PaymentModesService,
    private depositInService: DepositInService,
    public dialog: MatDialog,
    public router: Router,
    private adapter: DateAdapter<any>,
    private toastr: ToastrService,
    private logger: NGXLogger,
    private enterpriseInvoiceService: EnterpriseInvoiceService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    this.adapter.setLocale(this.translate.currentLang);
    //
    this.form = this.formBuilder.group({
      // cashing_amount: ["", [Validators.required]],
      received_at: ['', [ValidatorHelper.dateTodaysOrLower]],
      amount: ['', [Validators.required]],
    });
    //
    this.formRef = this.formBuilder.group({
      ref_number: [
        '',
        [
          Validators.required,
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
        ],
      ],
    });
    //
    this.depositIn = {
      received_at: new Date().toISOString(),
      amount: null,
      ref_number: null,
      note: null,
      payment_mode: null,
    };
    //

    if (data.parentUUID) {
      this.parentUUID = data.parentUUID;
    }
    if (data.cashing_amount) {
      this.depositIn.amount = data.cashing_amount;
    }
    if (data.invoices) {
      this.invoices = data.invoices;
      this.crunchData();
      // if (this.depositIn.amount) {
      //     this.crunchData();
      // }
    }
    // //this.logger.info(
    //     "EnterpriseCashingModalComponent invoices: " +
    //         JSON.stringify(this.invoices)
    // );
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  public crunchData(): void {
    this.invoicesModel = [];
    let amount = this.depositIn.amount;
    if (!amount) {
      // amount = 0;
      this.crunchNoAmount();
    } else {
      this.crunchAmount(amount);
    }
    //
    this.crunchTotal();
  }

  public gtRound(num: number): number {
    return Math.round((num + Number.EPSILON) * 100) / 100;
    // Math.round((num + Number.EPSILON) * 100) / 100;
  }

  public crunchAmount(amount: number): void {
    // const formatter = new Intl.NumberFormat("en-US", {
    //     minimumFractionDigits: 2,
    //     maximumFractionDigits: 2,
    // });
    // TODO: get rid of all toFixed(2) and create a custom round method instead
    for (const invoice in this.invoices) {
      const invoiceTotal: number = parseFloat(
        this.invoices[invoice].event.stats.total_raw,
      );
      let newAmount: number = amount - invoiceTotal;
      amount = newAmount;
      let finalAmount: number = 0;
      if (amount < 0) {
        finalAmount = this.gtRound(invoiceTotal + amount);
      } else {
        finalAmount = this.gtRound(invoiceTotal);
      }
      this.invoicesModel?.push({
        amount: finalAmount,
        received_at: GT2CalendarUtils.formatDateForAPI(
          this.depositIn.received_at,
        ),
        // received_at: this.depositIn.received_at,
        ref_number: this.depositIn.ref_number,
        note: this.depositIn.note,
        payment_mode: this.depositIn.payment_mode,
        invoice: this.invoices[invoice],
      });
    }
  }

  // public crunchAmount(amount: number): void {
  //     // const formatter = new Intl.NumberFormat("en-US", {
  //     //     minimumFractionDigits: 2,
  //     //     maximumFractionDigits: 2,
  //     // });
  //     // TODO: get rid of all toFixed(2) and create a custom round method instead
  //     for (const invoice in this.invoices) {
  //         const invoiceTotal: number = parseFloat(
  //             this.invoices[invoice].event.stats.total_raw
  //         );
  //         let newAmount: number = amount - invoiceTotal;
  //         amount = newAmount;
  //         let finalAmount: number = 0;
  //         if (amount < 0) {
  //             finalAmount = parseFloat((invoiceTotal + amount).toFixed(2));
  //         } else {
  //             finalAmount = parseFloat(invoiceTotal.toFixed(2));
  //         }
  //         this.invoicesModel.push({
  //             amount: finalAmount,
  //             received_at: GT2CalendarUtils.formatDateForAPI(
  //                 this.depositIn.received_at
  //             ),
  //             // received_at: this.depositIn.received_at,
  //             ref_number: this.depositIn.ref_number,
  //             note: this.depositIn.note,
  //             payment_mode: this.depositIn.payment_mode,
  //             invoice: this.invoices[invoice],
  //         });
  //     }
  // }

  public crunchNoAmount(): void {
    for (const invoice in this.invoices) {
      this.invoicesModel?.push({
        amount: 0,
        received_at: GT2CalendarUtils.formatDateForAPI(
          this.depositIn.received_at,
        ),
        // received_at: this.depositIn.received_at,
        ref_number: this.depositIn.ref_number,
        note: this.depositIn.note,
        payment_mode: this.depositIn.payment_mode,
        invoice: this.invoices[invoice],
      });
    }
  }

  public crunchTotal(): void {
    this.totalEntered = 0;
    this.totalEnteredRounded = 0;
    for (const item of this.invoicesModel) {
      this.totalEntered += parseFloat(item.amount);
      this.totalEnteredRounded +=
        Math.round((parseFloat(item.amount) + Number.EPSILON) * 100) / 100;
    }
    // for (const invoice in this.invoicesModel) {
    //     this.totalEntered += parseFloat(this.invoicesModel[invoice].amount);
    // }
  }

  public onCreateCashings(): void {
    this.loading = true;
    //
    this.crunchData();
    //
    //this.logger.info("EnterpriseCashingModalComponent.onCreateCashings()" + JSON.stringify(this.invoicesModel));
    this.enterpriseInvoiceService
      .createMultiCqashing(this.parentUUID, this.invoicesModel)
      .subscribe({
        next: (response: any) => {
          //this.logger.info("EnterpriseCashingModalComponent.onCreateCashings() -> response:" + JSON.stringify(response));
          this.loading = false;
          this.dialogRef.close(true);
        },
        error: (error: any) => {
          this.logger.error(
            'EnterpriseCashingModalComponent.onCreateCashings() -> ERROR:' +
              JSON.stringify(error),
          );
          this.loading = false;
        },
      });
  }

  public onPaymentModeChange(event: any): void {
    //this.logger.info("EnterpriseCashingModalComponent.onPaymentModeChange() -> event: " + JSON.stringify(event));
    this.depositIn.payment_mode = event;
  }

  public onNewAmount(event: any): void {
    //this.logger.info("EnterpriseCashingModalComponent.onNewAmount() - amount: " + event);
    this.crunchData();
  }
}
