<div style="max-height: 100%; overflow: auto">
  <div *ngIf="rawHTML">
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
      class="viewer-header mat-elevation-z1"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        class="p-4 ml-12 icon-mat dialog-title"
      >
        {{ "GENERIC.PREVIEW" | translate }}
      </div>

      <div fxLayout="row" fxLayoutAlign="end center" class="p-4 mr-12">
        <app-report-button
          [vo]="
            reportFactoryService.getVO(
              vo.report_key,
              DistributionModeEnum.FILE,
              ExportFormatEnum.PDF,
              ExportToEnum.PRINT,
              vo.params
            )
          "
          class="report-btn mr-8"
        >
        </app-report-button>

        <app-report-button
          [vo]="
            reportFactoryService.getVO(
              vo.report_key,
              DistributionModeEnum.FILE,
              ExportFormatEnum.PDF,
              ExportToEnum.DOWNLOAD,
              vo.params
            )
          "
          class="report-btn mr-8"
        >
        </app-report-button>

        <button
          tabindex="-1"
          mat-icon-button
          (click)="dialogRef.close()"
          aria-label=""
          class="report-btn"
        >
          <mat-icon class="s-24">close</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div #previewContainer *ngIf="rawHTML" class="viewer">
    <div [innerHTML]="rawHTML" class="inner-html"></div>
  </div>
</div>
