export const locale = {
  lang: 'en-CA',
  data: {
    CONGRESSES: {
      CONGRESSES: 'Congress',
      CONGRESS: 'Congress',
      CONGRESS_NUMBER: 'Congress number',
      SPCM_NUMBER: '# SPCM',
      PROJECT_NUMBER: 'Project #',
      CREATE_CONGRESS_TITLE: 'Create a congress',
      CREATE_PROJECT_TITLE: 'Create a project',
      NO_PROJECTS: 'No project for this congress',
      NO_INVOICES: 'No invoices for this congress',
      CONGRES_CANCELED: 'CONGRESS CANCELED',
      PROJECT_CANCELED: 'PROJECT CANCELED',
      GROUP_ACTION_TOOLTIP: 'Group action. At least 1 item must be selected',
      CUSTOM_RANGE: 'Custom range',
    },
  },
};
