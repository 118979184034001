<div id="storageNotice" *ngIf="event">
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
    <div *ngIf="!editMode" class="info-line" fxFlex="50%">
      <div class="title">{{ "EVENTS.ORGANIZER_PERSON" | translate }}</div>
      <div class="info text-truncate">
        {{ event.informations.organizer_person }}
      </div>
      <div
        *ngIf="!event.informations || !event.informations.organizer_person"
        class="info empty-value"
      ></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <div class="info-editable">
        <div [formGroup]="form" class="">
          <mat-form-field class="form-input-container" fxFlex="100%">
            <mat-label>{{ "EVENTS.ORGANIZER_PERSON" | translate }}</mat-label>
            <input
              matInput
              autocomplete="nope"
              formControlName="organizer_person"
              type="text"
              [(ngModel)]="event.informations.organizer_person"
            />
            <mat-error *ngIf="form.controls['organizer_person'].invalid"
              >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <!-- reciept notice local field -->
    <div *ngIf="!editMode" class="info-line" fxFlex="50%">
      <div class="title">Local d’entreposage</div>
      <div class="info text-truncate">
        {{ event.informations.receipt_notice_local }}
      </div>
      <div
        *ngIf="!event.informations || !event.informations.receipt_notice_local"
        class="info empty-value"
      ></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <div class="info-editable">
        <div [formGroup]="form" class="">
          <mat-form-field class="form-input-container" fxFlex="100%">
            <mat-label>Local d’entreposage</mat-label>
            <input
              matInput
              autocomplete="nope"
              formControlName="receipt_notice_local"
              type="text"
              [(ngModel)]="event.informations.receipt_notice_local"
            />
            <mat-error *ngIf="form.controls['receipt_notice_local'].invalid"
              >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <!-- Storage Date range -->
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
      <div class="title">{{ "EVENTS.STORAGE_DATE" | translate }}</div>
      <div class="info">{{ event.informations.storage_date_label }}</div>
      <div
        *ngIf="!event.informations || !event.informations.storage_date"
        class="info empty-value"
      ></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <div class="info-editable">
        <div [formGroup]="form" fxLayout="row" class="">
          <mat-form-field class="" fxFlex="100%">
            <mat-label>{{ "EVENTS.STORAGE_DATE" | translate }}</mat-label>
            <input
              matInput
              formControlName="storage_date"
              autocomplete="nope"
              [matDatepicker]="storageDate"
              min="1900-01-01"
              max="2099-01-01"
              (ngModelChange)="onStorageDateChange()"
              [(ngModel)]="event.informations.storage_date"
            />

            <mat-datepicker-toggle
              matSuffix
              [for]="storageDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #storageDate></mat-datepicker>

            <mat-error
              *ngIf="
                form.controls['storage_date'].invalid ||
                event.informations.storage_date === null
              "
              >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
            </mat-error>
          </mat-form-field>
          <button
            mat-button
            [disabled]="!event.informations.storage_date"
            mat-icon-button
            aria-label="Clear"
            (click)="clearStorageDate()"
          >
            <mat-icon class="s-16">close</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
      <div class="title">{{ "EVENTS.STORAGE_DATE_END" | translate }}</div>
      <div class="info">{{ event.informations.storage_date_end_label }}</div>
      <div
        *ngIf="!event.informations || !event.informations.storage_date_end"
        class="info empty-value"
      ></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <div class="info-editable">
        <div [formGroup]="form" fxLayout="row" class="">
          <mat-form-field class="" fxFlex="100%">
            <mat-label>{{ "EVENTS.STORAGE_DATE_END" | translate }}</mat-label>
            <input
              matInput
              formControlName="storage_date_end"
              autocomplete="nope"
              [matDatepicker]="storageDateEnd"
              min="1900-01-01"
              max="2099-01-01"
              (ngModelChange)="onStorageDateEndChange()"
              [(ngModel)]="event.informations.storage_date_end"
            />

            <mat-datepicker-toggle
              matSuffix
              [for]="storageDateEnd"
            ></mat-datepicker-toggle>
            <mat-datepicker #storageDateEnd></mat-datepicker>

            <mat-error
              *ngIf="
                form.controls['storage_date'].invalid ||
                event.informations.storage_date_end === null
              "
              >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
            </mat-error>
          </mat-form-field>
          <button
            mat-button
            [disabled]="!event.informations.storage_date_end"
            mat-icon-button
            aria-label="Clear"
            (click)="clearStorageDateEndChange()"
          >
            <mat-icon class="s-16">close</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Receipt notice hour range -->
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
    <div *ngIf="!editMode" class="info-line" fxFlex="50%">
      <div class="title">{{ "EVENTS.START_TIME" | translate }}</div>
      <div class="info">{{ event.informations.receipt_notice_hour_label }}</div>
      <div
        *ngIf="
          !event.informations || !event.informations.receipt_notice_hour_label
        "
        class="info empty-value"
      ></div>
    </div>
    <div
      *ngIf="editMode"
      class="ngx-timepicker-field-custom"
      fxLayout="column"
      fxFlex="50%"
    >
      <div
        class="custom-label"
        [ngStyle]="{
          color:
            event.informations.receipt_notice_hour === '' ||
            event.informations.receipt_notice_hour === null
              ? '#ff0000'
              : '',
        }"
      >
        {{ "EVENTS.START_TIME" | translate }}
      </div>
      <div fxLayout="row" fxFlex>
        <ngx-timepicker-field
          fxFlex
          [buttonAlign]="'left'"
          #ngxTimeStart
          [format]="24"
          [(ngModel)]="event.informations.receipt_notice_hour"
          (ngModelChange)="onTimeChange()"
          class="ngx-material-timepicker-toggle"
        >
        </ngx-timepicker-field>
        <button
          mat-button
          matSuffix
          [disabled]="!event.informations.receipt_notice_hour"
          mat-icon-button
          aria-label="Clear"
          (click)="event.informations.receipt_notice_hour = ''"
        >
          <mat-icon class="s-16">close</mat-icon>
        </button>
      </div>
    </div>

    <div *ngIf="!editMode" class="info-line" fxFlex="50%">
      <div class="title">{{ "EVENTS.END_TIME" | translate }}</div>
      <div class="info">
        {{ event.informations.receipt_notice_hour_end_label }}
      </div>
      <div
        *ngIf="
          !event.informations ||
          !event.informations.receipt_notice_hour_end_label
        "
        class="info empty-value"
      ></div>
    </div>
    <div
      *ngIf="editMode"
      class="ngx-timepicker-field-custom"
      fxLayout="column"
      fxFlex="50%"
    >
      <div
        class="custom-label"
        [ngStyle]="{
          color:
            event.informations.receipt_notice_hour_end === '' ||
            event.informations.receipt_notice_hour_end === null
              ? '#ff0000'
              : '',
        }"
      >
        {{ "EVENTS.END_TIME" | translate }}
      </div>
      <div fxLayout="row" fxFlex>
        <ngx-timepicker-field
          fxFlex
          [buttonAlign]="'left'"
          #ngxTimeEnd
          [format]="24"
          [(ngModel)]="event.informations.receipt_notice_hour_end"
          (ngModelChange)="onTimeChange()"
          class="ngx-material-timepicker-toggle"
        >
        </ngx-timepicker-field>
        <button
          mat-button
          matSuffix
          [disabled]="!event.informations.receipt_notice_hour_end"
          mat-icon-button
          aria-label="Clear"
          (click)="event.informations.receipt_notice_hour_end = ''"
        >
          <mat-icon class="s-16">close</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
