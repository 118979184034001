import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { GT2DateAdapter } from '../../../../utils/GT2DateAdapter';
import { GT2PageAbstract } from '../../../abstract/GT2PageAbstract';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Editor, EditorModule } from 'primeng/editor';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '../../../../../../@fuse/services/translation-loader.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DeliveryRequestTypeService } from '../../../../api/delivery-request-type.service';
import { DeliveryRequestContentService } from '../../../../api/delivery-request-content.service';
import { DeliveryRequestTemplateService } from '../../../../api/delivery-request-template.service';
import { WrappingService } from '../../../../api/wrapping.service';
//import { AmazingTimePickerService } from 'amazing-time-picker';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NGXLogger } from 'ngx-logger';
import { locale as english } from '../i18n/en-CA';
import { locale as french } from '../i18n/fr-CA';
import { Settings } from '../../../../settings/settings';
import { ValidatorHelper } from '../../../../validators/ValidatorHelper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { Gt2SelectComponent } from '../../../../components/gt2-select/gt2-select.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-create-new-delivery-template',
  templateUrl: './create-new-delivery-template.component.html',
  styleUrls: ['./create-new-delivery-template.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    MatInputModule,
    CommonModule,
    NgxMaterialTimepickerModule,
    MatCheckboxModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    FlexLayoutModule,
    Gt2SelectComponent,
    EditorModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
  ],
  providers: [{ provide: DateAdapter, useClass: GT2DateAdapter }],
})
export class CreateNewDeliveryTemplateComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  static CREATE: string = 'CREATE';
  static EDIT: string = 'EDIT';

  //
  mode: string = CreateNewDeliveryTemplateComponent.CREATE;
  form: UntypedFormGroup;
  deliveryRequestTemplate: any;
  eventUUID!: string;
  event: any;
  loading: boolean = false;
  autoRedirect: boolean = false;
  noteInvalid: boolean = false;
  noteEditorSubscription?: Subscription;
  noteEditordebounceTimeValue: number = 1500;
  @ViewChild('noteEditor') noteEditor?: Editor;
  deliveryRequestToUpdate: any;

  constructor(
    public override dialogRef: MatDialogRef<CreateNewDeliveryTemplateComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    public deliveryRequestTypeService: DeliveryRequestTypeService,
    public deliveryRequestContentService: DeliveryRequestContentService,
    public deliveryRequestTemplateService: DeliveryRequestTemplateService,
    public wrappingService: WrappingService,
    //private atp: AmazingTimePickerService,
    public dialog: MatDialog,
    public router: Router,
    private adapter: DateAdapter<any>,
    private toastr: ToastrService,
    private logger: NGXLogger,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    this.adapter.setLocale(this.translate.currentLang);

    this.form = this.formBuilder.group({
      template_name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
        ],
      ],
      price: ['', [ValidatorHelper.isNumber0To1000000orNull]],
    });

    // //this.logger.info("CreateDeliveryRequestComponent.constructor() -> data: " + JSON.stringify(data));
    this.setDeliveryRequestDefault();

    if (data && data.mode !== null) {
      this.mode = data.mode;
    }

    if (data && data.autoRedirect !== null) {
      this.autoRedirect = data.autoRedirect;
    }

    if (data && data.deliveryRequestToUpdate) {
      this.deliveryRequestTemplate = data.deliveryRequestToUpdate;
      //this.logger.info("CreateDeliveryRequestComponent.constructor() -> deliveryRequestTemplate TO UPDATE: " + JSON.stringify(data.deliveryRequestToUpdate));
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.noteEditorSubscription = this.noteEditor?.onTextChange
      .pipe(debounceTime(this.noteEditordebounceTimeValue))
      .pipe(distinctUntilChanged())
      .subscribe({
        next: (data) => {
          if (data.htmlValue) {
            this.noteInvalid =
              data.htmlValue.length > Settings.editorMaxCharLong;
            // //this.logger.info("CreateDeliveryRequestComponent.noteEditor.onTextChange() -> this.noteInvalid: " + this.noteInvalid);
            if (this.noteInvalid) {
              return;
            }
          }
        },
      });
  }

  ngOnDestroy(): void {}

  public setDeliveryRequestDefault(): void {
    this.deliveryRequestTemplate = {
      name: '',
      template_name: '',
      note: '',
      show_on_purchase_order_production: false,
      show_on_purchase_order_client_copy: false,
      show_on_client_proposal_and_order: false,
      show_on_purchase_order_kitchen: false,
      show_on_purchase_material: false,
      delivery_from_time: null,
      delivery_to_time: null,
      wrapping: null,
      delivery_request_content: null,
      delivery_request_type: null,
      price: null,
    };
  }

  public onDeliveryRequestTypeChange(event: any): void {
    // //this.logger.info("CreateDeliveryRequestComponent.onDeliveryRequestTypeChange() -> event: " + JSON.stringify(event));
    this.deliveryRequestTemplate.delivery_request_type = event;
  }

  public onDeliveryRequestContentChange(event: any): void {
    // //this.logger.info("CreateDeliveryRequestComponent.onDeliveryRequestContentChange() -> event: " + JSON.stringify(event));
    this.deliveryRequestTemplate.delivery_request_content = event;
  }

  public onWrappingSelected(event: any): void {
    // //this.logger.info("CreateDeliveryRequestComponent.onWrappingSelected() -> event: " + JSON.stringify(event));
    this.deliveryRequestTemplate.wrapping = event;
  }

  public onCreateDeliveryRequest(): void {
    this.loading = true;
    // //this.logger.info("CreateDeliveryRequestComponent.onCreateFlow() -> flow: " + JSON.stringify(this.deliveryRequest));

    if (this.mode === CreateNewDeliveryTemplateComponent.CREATE) {
      this.doCreateDeliveryRequest();
    } else if (this.mode === CreateNewDeliveryTemplateComponent.EDIT) {
      this.updateDeliveryRequest();
    }
  }

  public doCreateDeliveryRequest(): void {
    // //this.logger.info("CreateDeliveryRequestComponent.doCreateFlow() -> selectedFlow: " + JSON.stringify(this.selectedDeliveryRequest));
    this.deliveryRequestTemplateService
      .createDeliveryRequestTemplate(this.deliveryRequestTemplate)
      .subscribe({
        next: (response: any) => {
          //this.logger.info("CreateDeliveryRequestComponent.doCreateDeliveryRequest() -> SUCCESS: " + JSON.stringify(response));
          this.loading = false;
          this.dialogRef.close(response);
        },
        error: (error: any) => {
          this.logger.error(
            'CreateDeliveryRequestComponent.doCreateDeliveryRequest() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.GENERIC'),
          );
        },
      });
  }

  public updateDeliveryRequest(): void {
    // //this.logger.info("CreateDeliveryRequestComponent.updateFlow() -> ***** UPDATE this.deliveryRequest: " + JSON.stringify(this.deliveryRequest));
    this.deliveryRequestTemplateService
      .updateDeliveryRequestTemplate(this.deliveryRequestTemplate)
      .subscribe({
        next: (response: any) => {
          // //this.logger.info("CreateDeliveryRequestComponent.onCreateFlow() -> SUCCESS: " + JSON.stringify(response));
          this.loading = false;
          this.dialogRef.close(response);
        },
        error: (error: any) => {
          this.logger.error(
            'CreateDeliveryRequestComponent.onCreateFlow() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.GENERIC'),
          );
        },
      });
  }
}
