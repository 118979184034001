import { ModulesService } from '../../services/modules.service';
import { RichTextsValidator } from './../../utils/RichTextsValidator';
import { ICanDeactivateComponent } from './../../gards/can-deactivate-guard.service';
import { CommonModule, Location } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import {
  MatTable,
  MatTableDataSource,
  MatTableModule,
} from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  CalendarDateFormatter,
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarMonthViewDay,
} from 'angular-calendar';
import { ItemUrlFinder } from '../../utils/item-url-finder';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Editor, EditorModule } from 'primeng/editor';
import { of, Subscription, Subject, merge } from 'rxjs';
import { startWith, switchMap, catchError, map } from 'rxjs/operators';
import { fuseAnimations } from '../../../../@fuse/animations/index';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { LocalizationConfig } from '../../../localization/LocalizationConfig';
import { FileService } from '../../../services/file.service';
import { PrintService } from '../../../services/print.service';
import { ApiRoutes } from '../../api/ApiRoutes';
import { EnterprisesService } from '../../api/enterprises.service';
import { Gt2ApiService } from '../../api/gt2-api.service';
import { EnterprisesModelData } from '../../api/models/EnterprisesModel';
import { AddressComponent } from '../../components/address/address.component';
import { CollectionTableComponent } from '../../components/collection-table/collection-table.component';
import { ContactComponent } from '../../components/contact/contact.component';
import { CreateClientEnterpriseComponent } from '../../components/dialogs/create-client-enterprise/create-client-enterprise.component';
import { CreateClientComponent } from '../../components/dialogs/create-client/create-client.component';
import { CreateEnterpriseBranchComponent } from '../../components/dialogs/create-enterprise-branch/create-enterprise-branch.component';
import { IDynamicTableItemAction } from '../../components/dynamic-table/dynamic-table.component';
import { GenericAlertComponent } from '../../components/generic-alert/generic-alert.component';
import { AppRoutes } from '../../const/AppRoutes';
import { RolesService } from '../../services/roles.service';
import { Settings } from '../../settings/settings';
import { GT2FormPageAbstract } from '../abstract/GT2FormPageAbstract';
import { CustomDateFormatter } from '../calendar/CustomDateFormatter';
import { FuseCalendarEventFormDialogComponent } from '../calendar/event-form/event-form.component';
import { locale as english } from '../clients/i18n/en-CA';
import { locale as french } from '../clients/i18n/fr-CA';
import { RoomsComponent } from '../rooms/rooms.component';
import { DatatableHelperService } from '../../../services/datatable-helper.service';
import { EnterpriseReceiptComposeService } from '../../api/enterprise-receipt-compose.service';
import { HistoricAccountComposeService } from '../../api/historic-account-client-compose.service';
import { MenuReportsService } from './../../reports/menu-reports.service';
import { MailComposeDialogComponent } from '../compose/compose.component';
import { Title } from '@angular/platform-browser';
import { FuseSidebarService } from '../../../../@fuse/components/sidebar/sidebar.service';
import moment from 'moment';
import { ServiceChargeMultiplierService } from '../../api/service-charge-multiplier.service';
import { LedgerAccountService } from '../../api/ledger-account.service';
import { ServiceChargeCreateComponent } from '../settings/service-charge/service-charge-create/service-charge-create.component';
import { ServiceChargeMultiplyEnum } from '../../enum/service-charge-multiply-enum';
import { CrudDatatableGenericService } from '../../api/crud-datatable-generic.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatMenuModule } from '@angular/material/menu';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SectionHistoryPanelComponent } from '../../components/section-history-panel/section-history-panel.component';
import { TableSizePipeModule } from '../../modules/table-size.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { CreatedUpdatedAtComponent } from '../../components/created-updated-at/created-updated-at.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FuseSidebarModule } from '../../../../@fuse/components';
import { CommentSectionComponent } from '../../components/comment-section/comment-section.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CdkTableModule } from '@angular/cdk/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { DisplayHtmlLinkPipeModule } from '../../modules/display-html-link.module';
import { EnterpriseClientsComponent } from '../clients/enterprise-clients/enterprise-clients.component';
import { EnterpriseInvoiceComponent } from '../enterprise-invoice/enterprise-invoice.component';
import { RoomsModule } from '../rooms/rooms.module';
import { GT2DateAdapter } from '../../utils/GT2DateAdapter';

@Component({
  selector: 'app-enterprise',
  templateUrl: './enterprise.component.html',
  styleUrls: ['./enterprise.component.scss'],
  animations: fuseAnimations,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatMenuModule,
    CollectionTableComponent,
    MatSortModule,
    FlexLayoutModule,
    SectionHistoryPanelComponent,
    TableSizePipeModule,
    NgxPermissionsModule,
    CreatedUpdatedAtComponent,
    MatTabsModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    FuseSidebarModule,
    CommentSectionComponent,
    MatPaginatorModule,
    AddressComponent,
    ContactComponent,
    DisplayHtmlLinkPipeModule,
    EditorModule,
    RoomsModule,
    EnterpriseInvoiceComponent,
    EnterpriseClientsComponent,
    MatIconModule,
    MatTableModule,
    MatTooltipModule,
    CdkTableModule,
    MatFormFieldModule,
    TranslateModule,
    MatButtonModule,
    MatInputModule,
    FuseDirectivesModule,
  ],
  providers: [
    CrudDatatableGenericService,
    { provide: DateAdapter, useClass: GT2DateAdapter },
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})
export class EnterpriseComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy, ICanDeactivateComponent
{
  static DATE_FROM_KEY: string = 'entyerprisesDateFrom';
  static DATE_TO_KEY: string = 'entyerprisesDateTo';
  //
  languages: any;
  selectedLanguage: any;
  isSmallScreen!: boolean;
  loading: boolean = false;
  editMode: boolean = false;
  enterpriseID!: string;
  enterprise!: EnterprisesModelData | any;
  enterpriseCalendar: any;
  form!: UntypedFormGroup;
  editable: boolean = true;
  showEditButton: boolean = true;
  @ViewChild('addressComponent')
  addressComponent!: AddressComponent;
  @ViewChild('contactComponent')
  contactComponent!: ContactComponent;
  @ViewChild('rooms') rooms!: RoomsComponent;
  @ViewChild('noteEditor') noteEditor!: Editor;
  noteEditordebounceTimeValue: number = 1500;
  noteInvalid: boolean = false;

  //
  succursaleItemAction!: SuccursaleTableItemAction;
  clientsItemAction!: ClientsTableItemAction;
  @ViewChild('succursaleCollectionTable')
  succursaleCollectionTable!: CollectionTableComponent;

  // --o Commandes
  clientsDisplayedColumns = ['order_number', 'client_name'];
  @ViewChild('ordersPaginator')
  ordersPaginator!: MatPaginator;
  @ViewChild('ordersSort') ordersSort!: MatSort;
  @ViewChild('ordersTable') ordersTable!: MatTable<any>;
  @ViewChild('ordersFilter') ordersFilter!: ElementRef;
  ordersFilterValue: string = '';
  ordersDataSource = new MatTableDataSource();
  ordersResultsLength: number = 0;
  isOrdersLoadingResults: boolean = false;
  isOrdersRateLimitReached: boolean = false;

  roomsServiceURL!: string;

  // --o EVENTS DATA TABLE
  dataSource = new MatTableDataSource();
  displayedColumns = [
    'name',
    'event_number',
    'event_date',
    'status',
    'shop_name',
    'btn',
  ];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('table') table!: MatTable<any>;
  @ViewChild(MatSort) sort!: MatSort;
  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;

  // --o CALENDAR
  view: string;
  viewDate: Date;
  events!: CalendarEvent[];
  public actions: CalendarEventAction[];
  activeDayIsOpen: boolean = false;
  refresh: Subject<any> = new Subject();
  declare dialogRef: any;
  confirmDialogRef!: MatDialogRef<GenericAlertComponent> | any;
  selectedDay: any;

  // --o Subscription
  enterpriseMatTabGroupSubscription!: Subscription;
  routerSubscription!: Subscription;

  //
  richTextsValidator?: RichTextsValidator;
  //
  formDates: UntypedFormGroup;
  dateRange1: any;
  ledgerAccounts: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private translationLoader: FuseTranslationLoaderService,
    private translate: TranslateService,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public router: Router,
    public location: Location,
    private logger: NGXLogger,
    public print: PrintService,
    public fileService: FileService,
    public api: Gt2ApiService,
    public toastr: ToastrService,
    public rolesService: RolesService,
    private titleService: Title,
    public datatableHelperService: DatatableHelperService,
    private enterpriseReceiptComposeService: EnterpriseReceiptComposeService,
    private historicAccountComposeService: HistoricAccountComposeService,
    private _fuseSidebarService: FuseSidebarService,
    private enterprisesService: EnterprisesService,
    private adapter: DateAdapter<any>,
    public moduleService: ModulesService,
    public serviceChargeMultiplierService: ServiceChargeMultiplierService,
    public ledgerAccountService: LedgerAccountService,
    private crudDatatableGenericService: CrudDatatableGenericService,
    public menuReportsService: MenuReportsService,
    private breakpointObserver: BreakpointObserver,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      enterprise_number: [
        '',
        [
          Validators.minLength(1),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      website: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
        ],
      ],
    });

    //
    this.formDates = this.formBuilder.group({
      date_from1: ['', [Validators.required]],
      date_to1: ['', [Validators.required]],
      // date_from2: ['', [Validators.required]],
      // date_to2: ['', [Validators.required]],
      // date_from3: ['', [Validators.required]],
      // date_to3: ['', [Validators.required]],
    });
    //
    this.adapter.setLocale(this.translate.currentLang);

    const dateFrom1 = localStorage.getItem(
      EnterpriseComponent.DATE_FROM_KEY + '_1',
    );
    const dateTo1 = localStorage.getItem(
      EnterpriseComponent.DATE_TO_KEY + '_1',
    );
    this.dateRange1 = {
      date_from: dateFrom1
        ? moment(dateFrom1).format('YYYY-MM-DD')
        : moment().startOf('isoWeek').format('YYYY-MM-DD'),
      date_to: dateTo1
        ? moment(dateTo1).format('YYYY-MM-DD')
        : moment().endOf('isoWeek').format('YYYY-MM-DD'),
    };

    // --o Calendar
    this.view = 'month';
    this.viewDate = new Date();
    this.activeDayIsOpen = false;
    this.selectedDay = { date: moment(new Date()).startOf('day') };

    this.actions = [
      {
        label: '<i class="material-icons s-16">edit</i>',
        onClick: ({ event }: { event: CalendarEvent }): void => {
          // TODO:
          this.editEvent('edit', event);
        },
      },
      {
        label: '<i class="material-icons s-16">delete</i>',
        // TODO:
        onClick: ({ event }: { event: CalendarEvent }): void => {
          this.deleteEvent(event);
        },
      },
    ];
    this.ledgerAccountService.getLedgersAccount().subscribe({
      next: (response: any) => {
        this.ledgerAccounts = response.data;
      },
      error: (error: any) => {
        this.logger.error(
          'EnterpriseComponent.getLedgersAccount() -> ERROR:' +
            JSON.stringify(error),
        );
      },
    });
  }

  ngOnInit() {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe({
      next: (result) => {
        this.isSmallScreen = result.matches;
      },
    });
    //this.titleService.setTitle(this.translate.instant("GENERIC.ENTERPRISE"));

    // Scroll to top when page loaded new data (NOT WORKING SO FAR)
    this.routerSubscription = this.route.params.subscribe({
      next: (params: any) => {
        // //this.logger.info("this.route.params.subscribe() -> params.id: " + params.id);
        this.enterpriseID = params.id;
        // this.loading = true;
        this.roomsServiceURL = this.api.createUrl(
          ApiRoutes.GET_ENTERPRISES_ROOMS + this.enterpriseID,
        );
        // //this.logger.info("this.route.params.subscribe() -> this.roomsServiceURL: " + this.roomsServiceURL);
        this.loadEnterprise();
        this.loading = false;

        if (this.rooms) {
          this.rooms.reset(this.enterpriseID, this.roomsServiceURL);
        }

        //
        this.richTextsValidator = new RichTextsValidator();
      },
    });

    // language
    this.languages = LocalizationConfig.LANGUAGES;
    this.selectedLanguage = LocalizationConfig.getCurrentLanguage();

    this.succursaleItemAction = new SuccursaleTableItemAction(this.router);
    this.succursaleItemAction.itemClick.subscribe({
      next: (item) => {
        this.onSuccursale(item);
      },
    });

    this.clientsItemAction = new ClientsTableItemAction(this.router);
    this.dateRange1.uuid_enterprise = this.enterpriseID;
  }

  //
  public onDateFromChange1(event: any): void {
    // //this.logger.info(
    //     "EnterpriseComponent.onDateFromChange() -> this.dateRange1: " +
    //         JSON.stringify(this.dateRange1)
    // );
    this.dateRange1.date_from = event;
    // //this.logger.info("EnterpriseComponent.onDateFromChange() -> this.dateRange1: " + JSON.stringify(this.dateRange1));
    this.setDateFrom1(this.dateRange1.date_from);
    // this.setEmployeeReportModel();
    this.reloadTable();
  }

  public setDateFrom1(dateFrom: string): void {
    const formatedDate = moment(dateFrom).format('YYYY-MM-DD');
    localStorage.setItem(
      EnterpriseComponent.DATE_FROM_KEY + '_1',
      formatedDate.toString(),
    );
  }

  public setDateTo1(dateTo: string): void {
    const formatedDate = moment(dateTo).format('YYYY-MM-DD');
    localStorage.setItem(
      EnterpriseComponent.DATE_TO_KEY + '_1',
      formatedDate.toString(),
    );
  }

  public reloadTable(): void {
    // //this.logger.info("EnterpriseComponent.reloadAssignmentsTable() -> *** this.paginator: " + this.paginator);
    // if (this.paginator) {
    //     // //this.logger.info("EnterpriseComponent.reloadAssignmentsTable() -> *** this.paginator EXIST ");
    //     this.paginator._changePageSize(this.paginator.pageSize);
    // }
  }
  public onDateToChange1(event: any): void {
    // //this.logger.info(
    //     "EnterpriseComponent.onDateToChange() -> event: " + event
    // );

    //this.logger.info("EnterpriseComponent.onDateToChange() -> this.dateRange1: " + JSON.stringify(this.dateRange1));
    //this.logger.info("EnterpriseComponent.onDateToChange() -> event: " + event);

    // //this.logger.info("EnterpriseComponent.onDateToChange1() " + this.sort);
    // //this.logger.info("EnterpriseComponent.onDateToChange() -> this.dateRange1: " + JSON.stringify(this.dateRange1));
    this.dateRange1.date_to = event;
    this.setDateTo1(this.dateRange1.date_to);
    // this.setEmployeeReportModel();
    this.reloadTable();
  }

  public onSelectToday1(): void {
    //this.logger.info("EnterpriseComponent.onSelectToday1() " + this.sort);
    const today: any = moment().format('YYYY-MM-DD');
    this.dateRange1.date_from = today;
    this.dateRange1.date_to = today;
    // //this.logger.info("EnterpriseComponent.onSelectToday() -> totday: " + today);
    this.reloadTable();
  }

  public onSelectThisWeek1(): void {
    // //this.logger.info(
    //     "EnterpriseComponent.onSelectThisWeek() " + this.sort
    // );
    const dateFrom: any = moment().startOf('isoWeek').format('YYYY-MM-DD');
    const dateTo: any = moment().endOf('isoWeek').format('YYYY-MM-DD');
    this.dateRange1.date_from = dateFrom;
    this.dateRange1.date_to = dateTo;
    this.reloadTable();
  }

  public onSelectThisMonth1(): void {
    // //this.logger.info("EnterpriseComponent.onSelectThisMonth() ");
    const dateFrom: any = moment().startOf('month').format('YYYY-MM-DD');
    const dateTo: any = moment().endOf('month').format('YYYY-MM-DD');
    this.dateRange1.date_from = dateFrom;
    this.dateRange1.date_to = dateTo;
    // this.reloadAssignmentsTable();
  }

  public initRichText(): void {
    this.richTextsValidator?.registerRichText(this.noteEditor, 'noteEditor');
  }

  ngAfterViewInit() {
    this.setMatTabListener();

    //
    this.sort?.sortChange.subscribe({
      next: () => (this.paginator.pageIndex = 0),
    });

    this.isLoadingResults = true;

    merge(this.sort?.sortChange, this.paginator.page)
      .pipe(startWith(null))
      .pipe(
        switchMap(() => {
          setTimeout(() => {
            this.isLoadingResults = true;
          });

          return this.enterprisesService.getAllEnterpriseEvents(
            this.sort?.active,
            this.sort?.direction,
            this.paginator?.pageIndex,
            this.paginator?.pageSize,
            this.enterpriseID,
          );
        }),
      )
      .pipe(
        map((data) => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.meta.pagination.total;
          return data.data;
        }),
      )
      .pipe(
        catchError(() => {
          setTimeout(() => {
            this.isLoadingResults = false;
            this.isRateLimitReached = true;
          });
          return of([]);
        }),
      )
      .subscribe({ next: (data) => (this.dataSource.data = data) });
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }

    if (this.enterpriseMatTabGroupSubscription) {
      this.enterpriseMatTabGroupSubscription.unsubscribe();
    }

    if (this.richTextsValidator) {
      this.richTextsValidator.destroy();
    }
  }

  public canDeactivate(): boolean {
    // //this.logger.info(
    //     "EnterpriseComponent.canDeactivate() : " + this.form.dirty
    // );
    if (this.addressComponent && this.addressComponent.form.dirty) {
      return false;
    }

    if (this.contactComponent && this.contactComponent.form.dirty) {
      return false;
    }
    return !this.form.dirty && !this.richTextsValidator?.dirty();
  }

  public setMatTabListener(): void {
    // this.enterpriseMatTabGroupSubscription = this.enterpriseMatTabGroup.selectedIndexChange.subscribe( index =>
    // {
    //     //this.logger.info("EnterpriseComponent.setMatTabListener() -> index: " + index);
    //     this.showEditButton = index === 0;
    // });
  }

  public loadEnterprise(): void {
    this.loading = true;
    this.enterprisesService.getEnterprise(this.enterpriseID).subscribe({
      next: (response) => {
        //this.logger.info("EnterpriseComponent.loadEnterprise() -> SUCCESS: " + JSON.stringify(response));
        const isFirstLoad: boolean =
          this.enterprise === null || this.enterprise == undefined;
        this.loading = false;
        this.enterprise = response.data;
        // //this.logger.info("EnterpriseComponent.loadEnterprise() -> CLIENTS: " + JSON.stringify(this.enterprise.clients));
        this.editable = !this.enterprise?.isArchive;
        //this.titleService.setTitle(this.translate.instant("GENERIC.ENTERPRISE") + " " + this.enterprise.name);
        if (this.succursaleCollectionTable) {
          this.succursaleCollectionTable.reload(this.enterprise?.child);
        }

        //
        if (isFirstLoad) {
          setTimeout(() => {
            this.initRichText();
          }, 500);
        }
      },
      error: (error) => {
        this.logger.error(
          'EnterpriseComponent.loadEnterprise() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public deleteEnterprise(): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });
    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.enterprisesService.deleteEnterprise(this.enterprise).subscribe({
            next: (response) => {
              // //this.logger.info("EnterpriseComponent.deleteEnterprise() -> SUCCESS: " + JSON.stringify(response));
              this.toastr.success(
                '',
                this.translate.instant('GENERIC.DELETE_SUCCESS'),
              );
              this.router.navigateByUrl('/' + AppRoutes.CLIENTS);
            },
            error: (error) => {
              this.logger.error(
                'EnterpriseComponent.deleteEnterprise() -> ERROR: ' +
                  JSON.stringify(error),
              );
              // this.toastr.error('', this.translate.instant("GENERIC.ERRORS.GENERIC"));
              this.loading = false;
              this.handleAPIError(
                error,
                this.dialog,
                this.toastr,
                this.translate.instant('GENERIC.ERRORS.GENERIC'),
              );
            },
          });
        }
      },
    });
  }

  public onOpenInNewTab(): void {
    window.open(this.router.url, '_blank');
  }

  public onOpenEventInNewTab(event: any): void {
    //this.logger.info("onOpenEventInNewTab() -> " + JSON.stringify(event));
    this.openInNewTab(ItemUrlFinder.getItemURL(event));
  }

  public onNavigateToItem(item: any): void {
    this.router.navigateByUrl(ItemUrlFinder.getItemURL(item));
  }

  public doPrint(url: string, report_mode: string): void {
    this.dateRange1.report_mode = report_mode;
    this.print.postPrintHTML(url, this.dateRange1).subscribe({
      next: (response: any) => {
        // //this.logger.info("EnterpriseComponent.doPrint() -> SUCCESS: " + JSON.stringify(response));
      },
      error: (error: any) => {
        this.logger.error(
          'EnterpriseComponent.doPrint() -> ERROR: ' + JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public doDownload(url: string, report_mode: string): void {
    this.dateRange1.report_mode = report_mode;
    this.fileService.postDownloadFile(url, this.dateRange1).subscribe({
      next: (response) => {
        // //this.logger.info("EnterpriseComponent.doDownload() -> SUCCESS: " + JSON.stringify(response));
      },
      error: (error) => {
        this.logger.error(
          'EnterpriseComponent.doDownload() -> ERROR: ' + JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  // EVENT REPORT
  public onPrintEventsReport(report_mode: string): void {
    // //this.logger.info("EnterpriseComponent.onPrintEventsReport()");
    // reports/client/report-client-statement-enterprise/html/{uuid_enterprise}
    const url: string =
      this.api.createReportURL(
        ApiRoutes.REPORT_PRINT,
        'client/report-client-statement-enterprise',
        'fr-CA',
      ) +
      '/' +
      this.enterpriseID;
    // this.print.printHTML(url);
    this.doPrint(url, report_mode);
  }

  public onDowloadEventsReport(report_mode: string): void {
    // //this.logger.info("EnterpriseComponent.onDowloadEventsReport()");
    // reports/client/report-client-statement-enterprise/pdf/{uuid_enterprise}
    const url: string =
      this.api.createReportURL(
        ApiRoutes.REPORT_PDF,
        'client/report-client-statement-enterprise',
        'fr-CA',
      ) +
      '/' +
      this.enterpriseID;
    // this.fileService.downloadFile(url);
    this.doDownload(url, report_mode);
  }

  public onSendEventsReport(report_mode: string): void {
    // //this.logger.info("EnterpriseComponent.onSendEventsReport()");

    // IMPORTANT
    this.enterpriseReceiptComposeService.parentUUID = this.enterpriseID;
    this.dateRange1.report_mode = report_mode;

    this.dialogRef = this.dialog.open(MailComposeDialogComponent, {
      panelClass: 'mail-compose-dialog',
      data: {
        composeService: this.enterpriseReceiptComposeService,
        postData: this.dateRange1,
      },
    });
    this.dialogRef.afterClosed().subscribe({
      next: (response: any) => {
        if (!response) {
          return;
        }
        this.dialogRef = null;
      },
    });
  }

  // Hisoric Report

  /*public onPrintHistoricReport(): void {
        //this.logger.info("EnterpriseComponent.onPrintHistoricReport()");
        // reports/client/report-historical-account-enterprise/html/{uuid_enterprise}
        const url: string =
            this.api.createReportURL(
                ApiRoutes.REPORT_PRINT,
                "client/report-historical-account-enterprise",
                "fr-CA"
            ) +
            "/" +
            this.enterpriseID;
        // this.print.printHTML(url);
        this.doPrint(url);
    }*/

  public onDownloadHistoricXLS(): void {
    let report_mode = '';
    //this.logger.info("EnterpriseComponent.onDowloadHistoricXLS()");
    // reports/client/report-historical-account-enterprise/pdf/{uuid_enterprise}
    const url: string =
      this.api.createReportURL(
        ApiRoutes.REPORT_XLS,
        'report-historical-account-enterprise',
        'fr-CA',
      ) +
      '/' +
      this.enterpriseID;
    // this.fileService.downloadFile(url);
    this.doDownload(url, report_mode);
  }

  public onDownloadXLS(): void {
    let report_mode = '';
    //this.logger.info("EnterpriseComponent.onDownloadXLS()");
    // reports/client/report-historical-account-enterprise/pdf/{uuid_enterprise}
    const url: string =
      this.api.createReportURL(
        ApiRoutes.REPORT_XLS,
        'invoices-by-enterprise',
        'fr-CA',
      ) +
      '/' +
      this.enterpriseID;
    // this.fileService.downloadFile(url);
    this.doDownload(url, report_mode);
  }

  /*public onSendHistoricReport(): void {
        //this.logger.info("EnterpriseComponent.onSendHistoricReport()");

        // IMPORTANT
        this.historicAccountComposeService.parentUUID = this.enterpriseID;

        this.dialogRef = this.dialog.open(MailComposeDialogComponent, {
            panelClass: "mail-compose-dialog",
            data: {
                composeService: this.historicAccountComposeService,
                postData: this.dateRange1,
            },
        });
        this.dialogRef.afterClosed().subscribe((response) => {
            if (!response) {
                return;
            }
            this.dialogRef = null;
        });
    }*/
  // END HISTORIC REPORT FUNCTIONS

  public onPrint(): void {
    let report_mode = '';
    const url: string =
      this.api.createReportURL(ApiRoutes.REPORT_PRINT, 'enterprises', 'fr-CA') +
      this.enterprise?.uuid;
    this.doPrint(url, report_mode);
  }

  public onDownloadPDF(): void {
    let report_mode = '';
    // //this.logger.info("EnterpriseComponent.onDownloadPDF() ");
    const url: string =
      this.api.createReportURL(ApiRoutes.REPORT_PDF, 'enterprises', 'fr-CA') +
      this.enterprise?.uuid;
    this.doDownload(url, report_mode);
  }

  public onDownloadVCard(): void {
    let report_mode = '';
    // //this.logger.info("onDownloadVCard() ");
    const url: string =
      this.api.createReportURL(ApiRoutes.REPORT_VCARD, 'enterprises', 'fr-CA') +
      this.enterprise?.uuid;
    this.doDownload(url, report_mode);
  }

  public onSuccursale(enterprise: any): void {
    // //this.logger.info("onSuccursale() ");
    // const url: string = '/enterprises/' + enterprise.slug + "/" + enterprise.uuid;
    // this.router.navigateByUrl(url);
    // this.enterpriseMatTabGroup.selectedIndex = 0;
    // this.succursaleInfoMatTabGroup.selectedIndex = 0;
  }

  public onAddSuccursale(): void {
    // //this.logger.info("-----> onAddSuccursale()");
    this.dialogRef = this.dialog.open(CreateEnterpriseBranchComponent, {
      width: '340px',
      disableClose: false,
      data: {
        type: CreateEnterpriseBranchComponent.CREATE_BRANCH_FOR_ENTERPRISE,
        uuid: this.enterpriseID,
      },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        // if (result)
        // {
        // }
        this.dialogRef = null;
      },
    });
  }

  public onClient(client: any): void {
    const url: string = '/clients/' + client.slug + '/' + client.uuid;
    this.router.navigateByUrl(url);
    // this.succursaleInfoMatTabGroup.selectedIndex = 0;
    // this.enterpriseMatTabGroup.selectedIndex = 0;
  }

  public onEdit($event: any): void {
    this.editMode = !this.editMode;

    if (this.addressComponent) {
      this.addressComponent.editMode = this.editMode;
      if (!this.editMode) {
        this.addressComponent.onManualSave();
      }
    }

    if (this.contactComponent) {
      this.contactComponent.editMode = this.editMode;
      if (!this.editMode) {
        this.contactComponent.onManualSave();
      }
    }

    if (this.noteEditor) {
      this.noteEditor.readonly = !this.editMode;
    }

    if (!this.editMode) {
      this.loading = true;
      this.enterprisesService
        .updateEnterprise(this.enterprise, this.enterpriseID)
        .subscribe({
          next: (response) => {
            //this.logger.info("EnterpriseComponent.autosaveDataChange() -> SUCCESS: " + JSON.stringify(response));
            this.form.markAsPristine();
            this.form.markAsUntouched();
            this.loading = false;
            this.loadEnterprise();
            //
            if (this.addressComponent && this.addressComponent.form.dirty) {
              this.addressComponent.form.markAsPristine();
              this.addressComponent.form.markAsUntouched();
            }

            if (this.contactComponent && this.contactComponent.form.dirty) {
              this.contactComponent.form.markAsPristine();
              this.contactComponent.form.markAsUntouched();
            }

            if (this.richTextsValidator) {
              this.richTextsValidator.clean();
            }
          },
          error: (error) => {
            this.logger.error(
              'EnterpriseComponent.autosaveDataChange() -> ERROR: ' +
                JSON.stringify(error),
            );
            this.loading = false;
            this.handleAPIError(error, this.dialog, null, null);
          },
        });
    }
  }

  public onCancelEdit(event: any): void {
    this.editMode = false;
    if (this.addressComponent) {
      this.addressComponent.editMode = this.editMode;
    }

    if (this.contactComponent) {
      this.contactComponent.editMode = this.editMode;
    }

    if (this.noteEditor) {
      this.noteEditor.readonly = !this.editMode;
    }

    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.loadEnterprise();
  }

  public onChange($event: any): void {
    this.loadEnterprise();
  }

  public override autosaveDataChange(data: any): void {}

  // TEST
  public onCreateClient(): void {
    this.logger.debug('EnterpriseComponent.onCreateClient()');

    this.dialogRef = this.dialog.open(CreateClientComponent, {
      width: '340px',
      disableClose: false,
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          // //this.logger.info("POP UP Closed!");
        }
        this.dialogRef = null;
      },
    });
  }

  public onCreateClientAndEnterprise(): void {
    this.dialogRef = this.dialog.open(CreateClientEnterpriseComponent, {
      width: '340px',
      disableClose: false,
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          // //this.logger.info("POP UP Closed!");
          // this.loadEnterprise();
          // this.router.navigateByUrl("/" + AppRoutes.CLIENTS + "/" + result.slug + "/" + result.uuid);
          this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
        }
        this.dialogRef = null;
      },
    });
  }

  // public onAddSuccursale(): void
  // {
  //     //this.logger.info("EnterpriseLeftNavComponent.onAddSuccursale()");
  //     this.dialogRef = this.dialog.open(CreateEnterpriseBranchComponent, {
  //         width: '340px',
  //         disableClose: false,
  //         data: {
  //             type: CreateEnterpriseBranchComponent.CREATE_BRANCH_FOR_ENTERPRISE,
  //             uuid: this.enterprise.uuid
  //         },
  //     });
  //
  //     this.dialogRef.afterClosed().subscribe(result => {
  //         if (result)
  //         {
  //             //this.logger.info("POP UP Closed!");
  //             this.changeEvent.next("EnterpriseLeftNavComponent.ON_CHANGE");
  //         }
  //         this.dialogRef = null;
  //     });
  // }

  public onAddClient(): void {
    // //this.logger.info("EnterpriseComponent.onAddClient()");
    this.dialogRef = this.dialog.open(CreateClientComponent, {
      width: '340px',
      disableClose: false,
      data: {
        type: CreateClientComponent.CREATE_FOR_ENTERPRISE,
        uuid: this.enterprise?.uuid,
      },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          // //this.logger.info("POP UP Closed!");
        }
        this.dialogRef = null;
      },
    });
  }

  public onAddOrder(): void {
    // //this.logger.info("EnterpriseComponent.onAddOrder()");
  }

  public onOpenObjectPage(item: any): void {
    // //this.logger.info("EnterpriseComponent.onOpenObjectPage() -> item: " + JSON.stringify(item));
    this.router.navigateByUrl(ItemUrlFinder.getItemURL(item));
  }

  public onOpenWebSite(): void {
    // //this.logger.info("EnterpriseComponent.onAddOrder()");
    window.open(this.enterprise?.website, '_blank');
  }

  // --o CALENDAR
  /**
   * Before View Renderer
   * @param {any} header
   * @param {any} body
   */
  beforeMonthViewRender({ header, body }: { header: any; body: any }) {
    // console.info('beforeMonthViewRender');
    /**
     * Get the selected day
     */
    const _selectedDay = body.find((_day: any) => {
      return _day.date.getTime() === this.selectedDay.date.getTime();
    });

    if (_selectedDay) {
      /**
       * Set selectedday style
       * @type {string}
       */
      _selectedDay.cssClass = 'mat-elevation-z3';
    }
  }

  /**
   * Day clicked
   * @param {MonthViewDay} day
   */
  dayClicked(day: CalendarMonthViewDay): void {
    const date: Date = day.date;
    const events: CalendarEvent[] = day.events;

    if (moment(date).isSame(moment(this.viewDate), 'month')) {
      if (
        (moment(this.viewDate).isSame(moment(date), 'day') &&
          this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.viewDate = date;
      }
    }
    this.selectedDay = day;
    this.refresh.next(undefined);
  }

  /**
   * Event times changed
   * Event dropped or resized
   * @param {CalendarEvent} event
   * @param {Date} newStart
   * @param {Date} newEnd
   */
  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    // console.warn('Dropped or resized', event);
    this.refresh.next(true);
  }

  /**
   * Delete Event
   * @param event
   */
  deleteEvent(event: any) {
    // this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
    //     disableClose: false
    // });
    //
    // this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';
    //
    // this.confirmDialogRef.afterClosed().subscribe(result => {
    //     if ( result )
    //     {
    //         const eventIndex = this.events.indexOf(event);
    //         this.events.splice(eventIndex, 1);
    //         this.refresh.next(true);
    //     }
    //     this.confirmDialogRef = null;
    // });
  }

  /**
   * Edit Event
   * @param {string} action
   * @param {CalendarEvent} event
   */
  editEvent(action: string, event: CalendarEvent) {
    // //this.logger.info("EnterpriseComponent.editEvent() -> event: " + JSON.stringify(event));
    // const eventIndex = this.events.indexOf(event);
    //
    // this.dialogRef = this.dialog.open(FuseCalendarEventFormDialogComponent, {
    //     panelClass: 'event-form-dialog',
    //     data      : {
    //         event : event,
    //         action: action
    //     }
    // });
    //
    // this.dialogRef.afterClosed()
    //     .subscribe(response => {
    //         if ( !response )
    //         {
    //             return;
    //         }
    //         const actionType: string = response[0];
    //         const formData: FormGroup = response[1];
    //         switch ( actionType )
    //         {
    //             /**
    //              * Save
    //              */
    //             case 'save':
    //
    //                 this.events[eventIndex] = Object.assign(this.events[eventIndex], formData.getRawValue());
    //                 this.refresh.next(true);
    //
    //                 break;
    //             /**
    //              * Delete
    //              */
    //             case 'delete':
    //
    //                 this.deleteEvent(event);
    //
    //                 break;
    //         }
    //     });
  }

  /**
   * Add Event
   */
  addEvent(): void {
    this.dialogRef = this.dialog.open(FuseCalendarEventFormDialogComponent, {
      panelClass: 'event-form-dialog',
      data: {
        action: 'new',
        date: this.selectedDay.date,
      },
    });
    this.dialogRef.afterClosed().subscribe({
      next: (response: UntypedFormGroup) => {
        if (!response) {
          return;
        }
        const newEvent = response.getRawValue();
        newEvent.actions = this.actions;
        this.events?.push(newEvent);
        this.refresh.next(true);
      },
    });
  }

  public onGENERATE_ACCOUNT_STATEMENTInfo(): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });
    this.confirmDialogRef.componentInstance.useHTML = true;
    this.confirmDialogRef.componentInstance.useCancel = false;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('REPORTS_HELP.TITLE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('REPORTS_HELP.REPORT_CLIENT_STATEMENT_CLIENT');

    this.confirmDialogRef
      .afterClosed()
      .subscribe({ next: (result: any) => {} });
  }

  public toggleHistoricSidebarOpen(key: string): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }
  public onVisitEnterpriseParent(): void {
    //this.logger.info("OrderComponent.onVisitEnterpriseParent() - > enterprise parent: " + ItemUrlFinder.getItemURL(this.enterprise.parent));
    this.router.navigateByUrl(
      ItemUrlFinder.getItemURL(this.enterprise?.parent),
    );
  }
  public onCreateNewEnterpriseServiceCharge() {
    // //this.logger.info("EnterpriseComponent.onCreateNew() ");

    this.confirmDialogRef = this.dialog.open(ServiceChargeCreateComponent, {
      disableClose: false,
      width: '400px',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      data: {
        dialogTitle: this.translate.instant('GENERIC.NEW_SERVICE_CHARGE'),
        serviceURL: ApiRoutes.SERVICE_CHARGE,
        ledgerAccounts: this.ledgerAccounts,
        createPlaceholder: this.translate.instant(
          'GENERIC.NEW_SERVICE_CHARGE_NAME',
        ),
        useEnglish: true,
        isEnterpriseServiceCharge: true,
        enterpriseUuid: this.enterprise ? this.enterprise.uuid : null,
        serviceChargeMultiplierDefaultValue: {
          uuid: '3',
          value: ServiceChargeMultiplyEnum.PERCENTAGE_BY_INVOICE_CATEGORIES,
        },
        hideServiceChargeMultiplierInput: true,
      },
    });

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.loadEnterprise();
        } else {
          // //this.logger.info("EnterpriseComponent.onCreateNew() -> CREATION CANCELED");
        }
        this.confirmDialogRef = null;
      },
    });
  }
  public onEditEnterpriseServiceCharge(item: any) {
    //this.logger.info("EnterpriseComponent.onEditEnterpriseServiceCharge() " + JSON.stringify(item));

    this.confirmDialogRef = this.dialog.open(ServiceChargeCreateComponent, {
      disableClose: false,
      width: '400px',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      data: {
        dialogTitle: this.translate.instant('GENERIC.UPDATE_SERVICE_CHARGE'),
        serviceURL: ApiRoutes.SERVICE_CHARGE,
        ledgerAccounts: this.ledgerAccounts,
        serviceChargeToUpdate: item,
        createPlaceholder: this.translate.instant(
          'GENERIC.NEW_SERVICE_CHARGE_NAME',
        ),
        useEnglish: true,
        isEnterpriseServiceCharge: true,
        enterpriseUuid: this.enterprise ? this.enterprise.uuid : null,
        serviceChargeMultiplierDefaultValue: {
          uuid: '3',
          value: ServiceChargeMultiplyEnum.PERCENTAGE_BY_INVOICE_CATEGORIES,
        },
        hideServiceChargeMultiplierInput: true,
      },
    });

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.loadEnterprise();
        } else {
          // //this.logger.info("EnterpriseComponent.onCreateNew() -> CREATION CANCELED");
        }
        this.confirmDialogRef = null;
      },
    });
  }
  public onDeleteEnterpriseServiceCharge(selected: any) {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.crudDatatableGenericService
            .delete(ApiRoutes.SERVICE_CHARGE, selected)
            .subscribe({
              next: (response: any) => {
                // //this.logger.info("EnterpriseComponent.onDelete() -> SUCCESS:" + JSON.stringify(response));
                this.toastr.success(
                  '',
                  this.translate.instant('GENERIC.DELETE_SUCCESS'),
                );
                this.loadEnterprise();
              },
              error: (error: any) => {
                this.logger.error(
                  'EnterpriseComponent.onDelete() -> ERROR' +
                    JSON.stringify(error),
                );
                // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
                this.handleAPIError(
                  error,
                  this.dialog,
                  this.toastr,
                  this.translate.instant('GENERIC.ERRORS.GENERIC'),
                );
              },
            });
        }
        this.confirmDialogRef = null;
      },
    });
  }

  //
  public copyEnterpriseAddressInAllAttachedClients(): void {
    //this.logger.info("EnterpriseComponent.copyEnterpriseAddressInAllAttachedClients()");
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });
    this.confirmDialogRef.componentInstance.useCancel = true;
    // this.confirmDialogRef.componentInstance.alertTitle = this.translate.instant("GENERIC.DELETE");
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.COPY_ENTERPRISE_ADDRESS_CLIENTS_CONFIRM');

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.doCopyEnterpriseAddressInAllAttachedClients();
        }
      },
    });
  }

  public doCopyEnterpriseAddressInAllAttachedClients(): void {
    this.loading = true;
    this.enterprisesService
      .copyEnterpriseAddressToClients(this.enterpriseID)
      .subscribe({
        next: (response) => {
          //this.logger.info("EnterpriseComponent.doCopyEnterpriseAddressInAllAttachedClients() -> SUCCESS: " + JSON.stringify(response));
          this.enterprise = response.data;
          this.loading = false;
        },
        error: (error) => {
          this.logger.error(
            'EnterpriseComponent.doCopyEnterpriseAddressInAllAttachedClients() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          this.handleAPIError(error, this.dialog, null, null);
        },
      });
  }
}

export class SuccursaleTableItemAction implements IDynamicTableItemAction {
  itemClick: Subject<any> = new Subject();

  constructor(public router: Router) {}

  onItemClick(item: any): void {
    const url: string = ItemUrlFinder.getItemURL(item);
    this.itemClick.next(item);
    this.router.navigateByUrl(url);
  }
}

export class ClientsTableItemAction implements IDynamicTableItemAction {
  itemClick: Subject<any> = new Subject();

  constructor(public router: Router) {}

  onItemClick(item: any): void {
    // const url: string = '/' + AppRoutes.CLIENTS + "/" + item.slug + "/" + item.uuid;
    const url: string = ItemUrlFinder.getItemURL(item);
    this.itemClick.next(item);
    this.router.navigateByUrl(url);
  }
}
