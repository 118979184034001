import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  MatPaginator,
  MatPaginatorModule,
  PageEvent,
} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {
  MatTable,
  MatTableDataSource,
  MatTableModule,
} from '@angular/material/table';
import { Router, RouterLink } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ItemUrlFinder } from '../../utils/item-url-finder';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { fromEvent, merge, of, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  startWith,
  switchMap,
  catchError,
  map,
} from 'rxjs/operators';
import { fuseAnimations } from '../../../../@fuse/animations/index';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { DatatableHelperService } from '../../../services/datatable-helper.service';
import { FileService } from '../../../services/file.service';
import { PrintService } from '../../../services/print.service';
import { Gt2ApiService } from '../../api/gt2-api.service';
import { ProductsService } from '../../api/products.service';
import { CreateProductComponent } from '../../components/dialogs/create-product/create-product.component';
import { AppRoutesService } from '../../services/app-routes.service';
import { RolesService } from '../../services/roles.service';
import { GT2PageAbstract } from '../abstract/GT2PageAbstract';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { ApiRoutes } from '../../api/ApiRoutes';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  animations: fuseAnimations,
  providers: [ProductsService, DatatableHelperService],
})
export class ProductsComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  // @ViewChild(MatPaginator, { static: false }) paginatorTop: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('table') table!: MatTable<any>;
  @ViewChild('filter') filter!: ElementRef;
  @ViewChild('mainContainer') mainContainer!: ElementRef;
  displayedColumns = [
    'name_internal',
    'product_number',
    'price',
    'confirmed',
    'is_combo',
    'newTab',
  ];
  filterValue: string = '';
  dataSource = new MatTableDataSource();
  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;
  fromEventSubscription?: Subscription;
  isFirstLoad: boolean = true;
  confirmedOnly: boolean = false;
  comboOnly: boolean = false;

  constructor(
    private productsService: ProductsService,
    private translationLoader: FuseTranslationLoaderService,
    private translate: TranslateService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    public datatableHelperService: DatatableHelperService,
    public fileService: FileService,
    public print: PrintService,
    private router: Router,
    private logger: NGXLogger,
    public appRoutesService: AppRoutesService,
    public rolesService: RolesService,
    private titleService: Title,
    private api: Gt2ApiService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    // this.testDynamicTableServiceURL = ApiRoutes.PRODUCTS;
  }

  ngOnInit() {
    //this.titleService.setTitle(this.translate.instant("PRODUCTS.PRODUCTS"));
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe({
      next: () => (this.paginator.pageIndex = 0),
    });

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(startWith(null))
      .pipe(
        switchMap(() => {
          setTimeout(() => {
            this.isLoadingResults = true;
          });

          // TODO: handle error here, how do?
          return this.productsService.getProductsWithPagination(
            this.confirmedOnly,
            this.comboOnly,
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize,
            this.filterValue,
          );
        }),
      )
      .pipe(
        map((data) => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.meta.pagination.total;
          this.mainContainer.nativeElement.scrollTop = 0;
          // // //this.logger.info("... confirmed : " + data.data.confirmed);
          return data.data;
        }),
      )
      .pipe(
        catchError(() => {
          setTimeout(() => {
            this.isLoadingResults = false;
            this.isRateLimitReached = true;
          });
          return of([]);
        }),
      )
      .subscribe({ next: (data) => (this.dataSource.data = data) });

    this.fromEventSubscription = fromEvent(this.filter?.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          if (
            this.filter?.nativeElement.value.length > 1 ||
            this.filter?.nativeElement.value === ''
          ) {
            this.paginator.pageIndex = 0;
            this.paginator?._changePageSize(this.paginator.pageSize);
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {}

  public clearSearch(): void {
    this.filterValue = '';
    this.paginator.pageIndex = 0;
    this.paginator?._changePageSize(this.paginator.pageSize);
  }

  public reloadTable(): void {
    //// //this.logger.info("reloadTable()");
    this.paginator?._changePageSize(this.paginator.pageSize);
  }

  public syncPrimaryPaginator(event: PageEvent) {
    //this.logger.info("syncPrimaryPaginator()");
    this.paginator.pageIndex = event.pageIndex;
    this.paginator.pageSize = event.pageSize;
    this.paginator?.page.emit(event);
  }

  public onDeleteProduct(uuid: string): void {
    //// //this.logger.info("onDeleteProduct() -> uuid: " + uuid);
    this.productsService.deleteProduct(uuid).subscribe({
      next: (response) => {
        //// //this.logger.info("onDeleteProduct() -> SUCCESS: " + JSON.stringify(response));
        this.toastr.success('Produit effacée avec succès');
      },
      error: (error) => {
        //// //this.logger.info("onDeleteProduct() -> ERROR: " + JSON.stringify(error));
        this.toastr.error(this.translate.instant('GENERIC.ERRORS.GENERIC'));
      },
    });
  }

  public onCloneProduct(uuid: string): void {
    //// //this.logger.info("onCloneProduct() -> uuid: " + uuid);
    this.productsService.cloneProduct(uuid).subscribe({
      next: (response) => {
        //// //this.logger.info("onCloneProduct() -> SUCCESS: " + JSON.stringify(response));
        if (response.data) {
          this.router.navigateByUrl(ItemUrlFinder.getItemURL(response.data));
        }
      },
      error: (error) => {
        //// //this.logger.info("onCloneProduct() -> ERROR: " + JSON.stringify(error));
        this.toastr.error(this.translate.instant('GENERIC.ERRORS.GENERIC'));
      },
    });
  }

  public doPrint(url: string): void {
    this.print.printHTML(url).subscribe({
      next: (response: any) => {
        //// //this.logger.info("ProductsComponent.doPrint() -> SUCCESS: " + JSON.stringify(response));
      },
      error: (error: any) => {
        this.logger.error(
          'ProductsComponent.doPrint() -> ERROR: ' + JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public doDownload(url: string): void {
    this.fileService.downloadFile(url).subscribe({
      next: (response: any) => {
        //// //this.logger.info("ProductsComponent.doDownload() -> SUCCESS: " + JSON.stringify(response));
      },
      error: (error: any) => {
        this.logger.error(
          'ProductsComponent.doDownload() -> ERROR: ' + JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public onPrint(): void {
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PRINT,
      'products',
      'fr-CA',
    );
    // this.print.printHTML(url);
    this.doPrint(url);
  }

  public onDownloadPDF(): void {
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PDF,
      'products',
      'fr-CA',
    );
    // this.fileService.downloadFile(url);
    this.doDownload(url);
  }

  public onDownloadXLS(): void {
    //// //this.logger.info("onDownloadVCard() ");

    // const url: string = this.api.createUrl('/reports/products/xls');
    // const fileName: string = this.fileService.createFileName(null, "products", "xls");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_XLS,
      'products',
      'fr-CA',
    );
    // this.fileService.downloadFile(url);
    this.doDownload(url);
  }

  public onOpenInNewTab(): void {
    window.open(this.router.url, '_blank');
  }

  public onOpenProductInNewTab(product: any): void {
    window.open(ItemUrlFinder.getItemURL(product), '_blank');
  }

  public onConfirmedChange(value: boolean): void {
    //// //this.logger.info("ProductsComponent.onConfirmedChange() -> value: " + value);
    this.confirmedOnly = value;
    this.reloadTable();
  }

  public onComboChange(event: any) {
    //// //this.logger.info("ProductsComponent.onCombo() -> event: " + event);
    //// //this.logger.info("ProductsComponent.onCombo() -> event.value: " + event.checked);
    this.reloadTable();
  }

  public onCreateNewProduct(event: any): void {
    //// //this.logger.info("ProductsComponent.onCreateNewProduct() -> event: " + event);
    this.dialogRef = this.dialog.open(CreateProductComponent, {
      width: '340px',
      disableClose: false,
      data: { autoRedirect: true },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
        }
        this.dialogRef = null;
      },
    });
  }
}
