import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../api/auth-service.service';
import { NGXLogger } from 'ngx-logger';
import { GT2PageAbstract } from '../../abstract/GT2PageAbstract';
import { ReportsService } from '../../../reports/reports.service';
import { ReportFactoryService } from '../../../reports/report-factory.service';
import moment from 'moment';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { CalendarDateFormatter, DateAdapter } from 'angular-calendar';
import { CustomDateFormatter } from '../../calendar/CustomDateFormatter';
import { GT2DateAdapter } from '../../../utils/GT2DateAdapter';

@Component({
  selector: 'app-god-reports',
  templateUrl: './god-reports.component.html',
  styleUrls: ['./god-reports.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: GT2DateAdapter },
    { provide: CalendarDateFormatter, useClass: CustomDateFormatter },
  ],
})
export class GodReportsComponent extends GT2PageAbstract implements OnInit {
  static DATE_FROM_KEY: string = 'reportsGodDateFrom';
  static DATE_TO_KEY: string = 'reportsGodDateTo';
  //
  dateRangeGodReport: any;
  historicForm!: UntypedFormGroup;
  //
  constructor(
    private authService: AuthenticationService,
    private logger: NGXLogger,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    public reportsService: ReportsService,
    public reportFactoryService: ReportFactoryService,
  ) {
    super();
  }

  ngOnInit() {
    const dateFrom1 = localStorage.getItem(
      GodReportsComponent.DATE_FROM_KEY + '_1',
    );
    const dateTo1 = localStorage.getItem(
      GodReportsComponent.DATE_TO_KEY + '_1',
    );
    //
    //this.logger.info("dateFrom1: " + dateFrom1);
    //this.logger.info("dateTo1: " + dateTo1);

    this.dateRangeGodReport = {
      date_from: dateFrom1
        ? moment(dateFrom1).format('YYYY-MM-DD hh:mm')
        : moment().startOf('month').format('YYYY-MM-DD hh:mm'),
      date_to: dateTo1
        ? moment(dateTo1).format('YYYY-MM-DD hh:mm')
        : moment().endOf('month').format('YYYY-MM-DD hh:mm'),
    };
    //
    this.historicForm = this.formBuilder.group({
      date_from: ['', [Validators.required]],
      date_to: ['', [Validators.required]],
    });
  }

  public onDateFromChange1(event: any): void {
    this.dateRangeGodReport.date_from = event;
    this.setDateFrom1(this.dateRangeGodReport.date_from);
  }

  public setDateFrom1(dateFrom: string): void {
    const formatedDate = moment(dateFrom).format('YYYY-MM-DD hh:mm');
    localStorage.setItem(
      GodReportsComponent.DATE_FROM_KEY + '_1',
      formatedDate.toString(),
    );
  }

  public onDateToChange1(event: any): void {
    this.dateRangeGodReport.date_to = event;
    this.setDateTo1(this.dateRangeGodReport.date_to);
  }

  public setDateTo1(dateTo: string): void {
    const formatedDate = moment(dateTo).format('YYYY-MM-DD hh:mm');
    localStorage.setItem(
      GodReportsComponent.DATE_TO_KEY + '_1',
      formatedDate.toString(),
    );
  }

  public onSelectToday1(): void {
    //this.logger.info("GodReportsComponent.onSelectToday1()");
    const today: any = moment().format('YYYY-MM-DD hh:mm');
    this.dateRangeGodReport.date_from = today;
    this.dateRangeGodReport.date_to = today;
  }

  public onSelectThisWeek1(): void {
    //this.logger.info("GodReportsComponent.onSelectThisWeek()");
    const dateFrom: any = moment()
      .startOf('isoWeek')
      .format('YYYY-MM-DD hh:mm');
    const dateTo: any = moment().endOf('isoWeek').format('YYYY-MM-DD hh:mm');
    this.dateRangeGodReport.date_from = dateFrom;
    this.dateRangeGodReport.date_to = dateTo;
  }

  public onSelectThisMonth1(): void {
    //this.logger.info("GodReportsComponent.onSelectThisMonth()");
    const dateFrom: any = moment().startOf('month').format('YYYY-MM-DD hh:mm');
    const dateTo: any = moment().endOf('month').format('YYYY-MM-DD hh:mm');
    this.dateRangeGodReport.date_from = dateFrom;
    this.dateRangeGodReport.date_to = dateTo;
  }
}
