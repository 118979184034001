import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { GT2PageAbstract } from '../../content/abstract/GT2PageAbstract';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { SectorModel, SectorsService } from './sectors.service';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-sectors-select',
  templateUrl: './sectors-select.component.html',
  styleUrls: ['./sectors-select.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    Gt2PrimengModule,
    MatButtonModule,
    MatOptionModule,
    MatInputModule,
    FormsModule,
    MatFormFieldModule,
    TranslateModule,
    MatSelectModule,
  ],
  providers: [SectorsService],
})
export class SectorsSelectComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  // sectorChange: Subject<SectorModel> = new Subject<SectorModel>();
  @Output() changeEvent = new EventEmitter<SectorModel>();
  @Input() defaultSector!: string;
  sectors: SectorModel[] = [];
  selectedSector: any;

  constructor(
    private sectorsService: SectorsService,
    private translationLoader: FuseTranslationLoaderService,
    private logger: NGXLogger,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    // //this.logger.info("SectorsSelectComponent.constructor()");

    this.sectorsService.getSectors().subscribe({
      next: (response: any) => {
        // //this.logger.info("SectorsSelectComponent.sectorsService.getSectors() -> SUCCESS: " + JSON.stringify(response));
        this.sectors = response.data;
        // this.sectors = [];
      },
      error: (error: any) => {
        //this.logger.info("SectorsSelectComponent.sectorsService.getSectors() -> ERROR: " + JSON.stringify(error));
      },
    });
  }

  ngOnInit() {
    // //this.logger.info("SectorsSelectComponent.ngOnInit() --------> sector: " + JSON.stringify(this.defaultSector));
    if (this.defaultSector) {
      this.selectedSector = this.defaultSector;
      // //this.logger.info("SectorsSelectComponent.ngOnInit() --------> selectedSector: " + JSON.stringify(this.selectedSector));
    } else {
      this.selectedSector = {
        object: 'Sector',
        uuid: null,
        name: null,
        slug: null,
        price_label: null,
        price: null,
      };
    }
  }

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public onSectorChange(event: any): void {
    // //this.logger.info("SectorsSelectComponent.onSectorChange() -> event: " + event);
    // //this.logger.info("SectorsSelectComponent.onSectorChange() -> event uuid: " + event.value);

    // uuid is null = user selected Aucun/None
    if (!event.value) {
      //@ts-ignore
      this.changeEvent.next(null);
      return;
    }

    const newSector: any = this.sectors.find((item) => {
      // //this.logger.info("SectorsSelectComponent.onSectorChange() -> event uuid: " + event.value);
      return item.uuid === event.value;
    });
    // //this.logger.info("SectorsSelectComponent.onSectorChange() -> newSector" + JSON.stringify(newSector));
    this.changeEvent.next(newSector);
  }
}
