import { CommonModule, Location } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { NumberingService } from '../../../api/numbering.service';
import { GT2PageAbstract } from '../../abstract/GT2PageAbstract';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { RolesService } from '../../../services/roles.service';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxPermissionsModule } from 'ngx-permissions';

@Component({
  selector: 'app-numbering',
  templateUrl: './numbering.component.html',
  styleUrls: ['./numbering.component.scss'],
  standalone: true,
  imports: [
    MatDividerModule,
    CommonModule,
    MatFormFieldModule,
    NgxPermissionsModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FormsModule,
    TranslateModule,
  ],
})
export class NumberingComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  numbering: any;
  form: UntypedFormGroup;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    public rolesService: RolesService,
    public location: Location,
    private toastr: ToastrService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private logger: NGXLogger,
    private numberingService: NumberingService,
    public router: Router,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    this.numberingService.getNumberings().subscribe({
      next: (response) => {
        // this.logger.info("NumberingComponent.constructor.getNumberings() -> SUCCESS: " + JSON.stringify(response));
        this.numbering = response.data;
      },
      error: (error) => {
        this.logger.error(
          'NumberingComponent.constructor.getNumberings() -> ERROR: ' +
            JSON.stringify(error),
        );
      },
    });

    this.form = this.formBuilder.group({
      index_next: '',
      index_invoice_next: '',
      // project_prefix: ['', [Validators.minLength(3), Validators.maxLength(6), ValidatorHelper.isInteger, Validators.required]],
      event_prefix: [
        '',
        [Validators.minLength(3), Validators.maxLength(6), Validators.required],
      ],
      proposal_prefix: [
        '',
        [Validators.minLength(3), Validators.maxLength(6), Validators.required],
      ],
      order_prefix: [
        '',
        [Validators.minLength(3), Validators.maxLength(6), Validators.required],
      ],
      invoice_prefix: [
        '',
        [Validators.minLength(3), Validators.maxLength(6), Validators.required],
      ],
    });
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public onSaveData(): void {
    // this.logger.info("NumberingComponent.onSaveData() -> form.valid: " + this.form.valid);
    // this.logger.info("NumberingComponent.onSaveData() -> " + this.numbering.uuid);

    this.numberingService
      .saveNumbering(this.form.value, this.numbering.uuid)
      .subscribe({
        next: (response) => {
          // this.logger.info("NumberingComponent.onSaveData() -> SUCCESS: " + JSON.stringify(response));
          this.toastr.success(
            '',
            this.translate.instant('GENERIC.API_CALL_SUCCESS.SAVE_SUCCESS'),
          );
        },
        error: (error) => {
          this.logger.error(
            'NumberingComponent.onSaveData() -> ERROR: ' +
              JSON.stringify(error),
          );
          // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.GENERIC'),
          );
        },
      });
  }
}
