<div class="god-reports">
  <div
    fxFlex
    class="historic-report"
    fxLayout="row"
    fxLayoutAlign="start start"
  >
    <!-- <header class="accent" fxLayout="row" fxLayoutAlign="start center">
            <div class="title">{{ 'PRODUCTS.COMBO' | translate }}</div>
        </header>
        <div class="" fusePerfectScrollbar fxLayout="column" style="max-height: 340px">
            test
        </div> -->

    <div fxFlex fxLayout="row" fxLayoutAlign="end center">
      <div class="">REPORT_ALL_CLIENT_HISTORICAL_ACCOUNT_GOD</div>
      <app-report-button
        [vo]="
          reportFactoryService.getVO(
            'REPORT_ALL_CLIENT_HISTORICAL_ACCOUNT_GOD',
            DistributionModeEnum.FILE,
            ExportFormatEnum.PDF,
            ExportToEnum.DOWNLOAD,
            dateRangeGodReport
          )
        "
      >
      </app-report-button>
      <app-report-button
        [vo]="
          reportFactoryService.getVO(
            'REPORT2_DATA_ALL_CLIENT_HISTORICAL_ACCOUNT_XLSX_GOD',
            DistributionModeEnum.FILE,
            ExportFormatEnum.XLSX,
            ExportToEnum.DOWNLOAD,
            dateRangeGodReport
          )
        "
      >
      </app-report-button>
    </div>

    <div fxLayout="row" class="container-custom-date-picker">
      <div
        [formGroup]="historicForm"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <mat-form-field class="custom-date-picker">
          <mat-label>{{ "GENERIC.START" | translate }}</mat-label>
          <input
            matInput
            formControlName="date_from"
            [matDatepicker]="eventDateFrom1"
            min="1900-01-01"
            max="2099-01-01"
            (ngModelChange)="onDateFromChange1($event)"
            [(ngModel)]="dateRangeGodReport.date_from"
            class=""
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="eventDateFrom1"
          ></mat-datepicker-toggle>
          <mat-datepicker #eventDateFrom1></mat-datepicker>

          <mat-error *ngIf="historicForm.controls['date_from'].invalid"
            >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
          </mat-error>
        </mat-form-field>
        <div style="padding: 5px">&nbsp;</div>
        <mat-form-field class="custom-date-picker">
          <mat-label>{{ "GENERIC.END" | translate }}</mat-label>
          <input
            matInput
            formControlName="date_to"
            [matDatepicker]="eventDateTo1"
            min="1900-01-01"
            max="2099-01-01"
            (ngModelChange)="onDateToChange1($event)"
            [(ngModel)]="dateRangeGodReport.date_to"
            class=""
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="eventDateTo1"
          ></mat-datepicker-toggle>
          <mat-datepicker #eventDateTo1></mat-datepicker>

          <mat-error *ngIf="historicForm.controls['date_to'].invalid"
            >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
          </mat-error>
        </mat-form-field>

        <!-- <div>
                            <button mat-icon-button class="" [matMenuTriggerFor]="menuFrom1">
                                <mat-icon class="">more</mat-icon>
                            </button>
                        </div>
                        <mat-menu #menuFrom1="matMenu">
                            <button mat-menu-item (click)="onSelectToday1()">{{ "GENERIC.TODAY" | translate }}</button>
                            <button mat-menu-item (click)="onSelectThisWeek1()">{{ "GENERIC.WEEK" | translate }}</button>
                            <button mat-menu-item (click)="onSelectThisMonth1()">{{ "GENERIC.MONTH" | translate }}</button>
                        </mat-menu> -->
      </div>
    </div>
  </div>
</div>
