import { ErrorDialogComponent } from './../../../components/dialogs/error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ProductPictureUploadService } from './product-picture-upload.service';
import {
  HttpClient,
  HttpEventType,
  HttpErrorResponse,
} from '@angular/common/http';
import { AuthenticationService } from './../../../api/auth-service.service';
import { ApiRoutes } from '../../../api/ApiRoutes';

import { Gt2ApiService } from './../../../api/gt2-api.service';
import { NGXLogger } from 'ngx-logger';
import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../../@fuse/pipes/pipes.module';
import { Gt2PrimengModule } from '../../../modules/gt2-primeng.module';

@Component({
  selector: 'app-product-picture-upload',
  templateUrl: './product-picture-upload.component.html',
  styleUrls: ['./product-picture-upload.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    MatInputModule,
    MatCardModule,
    MatProgressBarModule,
    MatIconModule,
    MatButtonModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    Gt2PrimengModule,
  ],
  providers: [ProductPictureUploadService],
})
export class ProductPictureUploadComponent implements OnInit, OnDestroy {
  @Input() productUUID: any;
  @Input() media: any;
  // @Input() imageURL: string;
  // @Input() imageUUID: string;
  @Output() imageDeleted = new EventEmitter();
  @ViewChild('fileUpload')
  fileUpload!: ElementRef;
  files: any = [];
  fileProgress!: number;
  fileUploading: boolean = false;
  errorDialogRef: any;

  // uploader: FileUploader;
  // public uploader: FileUploader = new FileUploader({url: ''});
  // hasBaseDropZoneOver: boolean;
  // hasAnotherDropZoneOver: boolean;
  // response: string;

  constructor(
    private logger: NGXLogger,
    private http: HttpClient,
    public pictureService: ProductPictureUploadService,
    public dialog: MatDialog,
    public api: Gt2ApiService,
    public auth: AuthenticationService,
  ) {}

  ngOnInit(): void {
    // if (this.media && this.media.photos && this.media.photos.photo )
    // {
    //   this.imageURL = this.media.photos.photo.url;
    //   this.imageUUID = this.media.photos.photo.uuid;
    //   //this.logger.info('ProductPictureUploadComponent.ngOnInit() -> imageUUID: ' + this.imageUUID);
    // }
  }

  ngOnDestroy(): void {}

  // public fileOverBase(e: any): void {
  //   // //this.logger.info('ProductPictureUploadComponent.fileOverBase() -> e: ' + e);
  //   this.hasBaseDropZoneOver = e;
  // }

  public onFileDrop(e: any): void {
    //this.logger.info("ProductPictureUploadComponent.constructor() -> files: " + JSON.stringify(e));
  }

  public uploadFile(file: any): void {
    const uploadURL: string = this.api.createUrl(ApiRoutes.PICTURE_UPLOAD);
    //this.logger.info("ProductPictureUploadComponent.constructor() -> uploadURL: " + uploadURL);
    //this.logger.info("ProductPictureUploadComponent.constructor() -> productUUID: " + this.productUUID);

    const formData = new FormData();
    formData.append('file', file.data);
    formData.append('model_type', 'PRODUCT');
    formData.append('model_uuid', this.productUUID);
    file.inProgress = true;

    this.fileUploading = true;

    // this.pictureService.uploadPicture(uploadURL, formData)
    this.http
      .post<any>(uploadURL, formData, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        map((event: any) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round((event.loaded * 100) / event.total);
              this.fileProgress = file.progress;
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          file.inProgress = false;
          this.fileUploading = false;
          this.showError(error);
          return of(`${file.data.name} upload failed.`);
        }),
      )
      .subscribe({
        next: (event: any) => {
          //this.logger.info("UPLOAD: " + JSON.stringify(event));
          if (typeof event === 'object') {
            // this.imageURL = event.body.data.url;
            // this.imageUUID = event.body.data.uuid;
            this.media.photos.photo.url = event.body.data.url;
            this.media.photos.photo.uuid = event.body.data.uuid;
            this.fileUploading = false;
          }
        },
      });
  }

  private uploadFiles(): void {
    this.fileUpload.nativeElement.value = '';
    this.files.forEach((file: any) => {
      this.uploadFile(file);
    });
  }

  public onSelectAndUpload(): void {
    // TODO: reset array as for now we only support single image upload (delet line below for supporting multi-image)
    this.files = [];

    const fileUpload = this.fileUpload?.nativeElement;
    fileUpload.onchange = () => {
      // tslint:disable-next-line: prefer-for-of
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push({ data: file, inProgress: false, progress: 0 });
      }
      this.uploadFiles();
    };
    fileUpload.click();
  }

  public onDeleteImage(): void {
    //this.logger.info("ProductPictureUploadComponent.onDeleteImage() -> ");
    this.pictureService.deletePicture(this.media.photos.photo.uuid).subscribe({
      next: (response: any) => {
        //this.logger.info("ProductPictureUploadComponent.onDeleteImage() -> SUCCESS - response: " + JSON.stringify(response));
        this.imageDeleted.emit(this.media.photos.photo.uuid);
        this.media.photos.photo.url = null;
        this.media.photos.photo.uuid = null;
        // this.imageURL = null;
        // this.imageUUID = null;
      },
      error: (error: any) => {
        this.logger.error(
          'ProductPictureUploadComponent.onDeleteImage() -> ERROR - response: ' +
            JSON.stringify(error),
        );
      },
    });
  }

  public showError(error: HttpErrorResponse): void {
    if (error.status === 422) {
      //   this.dialogRef = this.dialog.open(GenericAlertComponent, {
      //     disableClose: false
      // });

      //   this.dialogRef.componentInstance.useCancel = false;
      //   this.dialogRef.componentInstance.alertMessage = error.message;
      //   this.dialogRef.afterClosed().subscribe(result => {
      //       this.dialogRef = null;
      //   });
      this.errorDialogRef = this.dialog.open(ErrorDialogComponent, {
        disableClose: false,
      });

      this.errorDialogRef.componentInstance.setError(error);

      this.errorDialogRef.afterClosed().subscribe({
        next: (result: any) => {
          this.errorDialogRef = null;
        },
      });
    }
  }
}
