import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormGroup,
} from '@angular/forms';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NumericDirectiveModule } from '../../../../modules/numeric.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-tips-settings',
  templateUrl: './tips-settings.component.html',
  styleUrls: ['./tips-settings.component.scss'],
  standalone: true,
  imports: [
    MatCheckboxModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    FlexLayoutModule,
    MatRadioModule,
    ReactiveFormsModule,
    NumericDirectiveModule,
    TranslateModule,
    CommonModule,
  ],
})
export class TipsSettingsComponent implements OnInit {
  @Input() shop: any;
  @Input() editMode: boolean = false;
  @Input() formTips!: UntypedFormGroup;
  @Output() saveInfo: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  public onSaveInfo(): void {
    this.saveInfo.emit();
  }
}
