import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormGroup,
} from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GlobalPaymentModel } from '../shop-web-detailed.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-global-payments-settings',
  templateUrl: './global-payments-settings.component.html',
  styleUrls: ['./global-payments-settings.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    FlexLayoutModule,
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
  ],
})
export class GlobalPaymentsSettingsComponent implements OnInit {
  @Input() shop: any;
  @Input() editMode: boolean = false;
  @Input() globalPaymentsModel!: GlobalPaymentModel;
  @Input() formGP!: UntypedFormGroup;
  @Output() saveGlobalPaymentsInfo: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  public onSaveGlobalPaymentsInfo(): void {
    this.saveGlobalPaymentsInfo.emit();
  }
}
