import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiRoutes } from './ApiRoutes';
import { Gt2ApiService } from './gt2-api.service';
import { PlatformLocation } from '@angular/common';
import { Settings } from '../settings/settings';
import * as moment from 'moment-timezone';
import { NGXLogger } from 'ngx-logger';
import { CrudOrganizationService } from '../content/crud/crud-organization/crud-organization.service';
import { ModulesService } from '../services/modules.service';
import { catchError, tap } from 'rxjs';

@Injectable()
export class AppConfig {
  private config: any = null;
  private env: any = null;

  constructor(
    private http: HttpClient,
    public api: Gt2ApiService,
    private platformLocation: PlatformLocation,
    private orgService: CrudOrganizationService,
    private modulesService: ModulesService,
    private logger: NGXLogger,
  ) {
    // --o API Endpoint Switch

    const origin: string = window.location.origin
      ? window.location.origin
      : 'http://localhost:4200/';
    const isDev: boolean =
      origin !== this.api.demoOrigin && origin !== this.api.prodOrigin;
    const isDemo: boolean = origin === this.api.demoOrigin;
    const isProd: boolean = origin === this.api.prodOrigin;

    this.api.setNewOrigin(
      window.location.origin
        ? window.location.origin
        : 'http://localhost:4200/',
    );

    // --o Timezone
    Settings.timezone = moment.tz.guess();
  }

  public getConfig(key: any): any {
    return this.config[key];
  }

  public getEnv(key: any): any {
    return this.env[key];
  }

  public load(): any {
    const url = this.api.createUrl(ApiRoutes.EXT_ENV);

    return new Promise((resolve, reject) => {
      this.http
        .get<any>(url)
        .pipe(
          tap((data) => {
            this.config = data;

            for (const key in environment) {
              if (environment.hasOwnProperty(key) && data.hasOwnProperty(key)) {
                environment[key] = data[key];
              }
            }

            // Override App Version for bugsnag
            const titleCase = (str: string) => {
              return str.charAt(0).toUpperCase() + str.slice(1);
            };

            environment.bugsnag.appVersion =
              'v' +
              environment.appVersion +
              ' ' +
              titleCase(environment.firebaseEnvironment);
            environment.bugsnag.apiMinimumVersionNumber =
              environment.appVersion;
            environment.bugsnag.apiMinimumVersionLabel =
              'v' +
              environment.appVersion +
              ' ' +
              titleCase(environment.firebaseEnvironment);

            // --o Logger
            this.logger.updateConfig({ level: environment.logLevel });

            resolve(data);
          }),
          catchError((error: any): any => {
            // --o TODO: Fatal Error ... do something
            reject(error);
          }),
        )
        .subscribe();
    });
  }
}
