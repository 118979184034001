import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ApiRoutes } from '../../api/ApiRoutes';
import { Gt2ApiService } from '../../api/gt2-api.service';
import { RoomsModel, RoomsModelData } from '../../api/models/RoomsModel';

@Injectable()
// export class VenuesService implements Resolve<any>
export class RoomsService {
  rooms?: RoomsModelData[];

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private api: Gt2ApiService,
  ) {}

  public getRoomsByIDWithPagination(
    serviceURL: string,
    sort: string,
    direction: string,
    page: number,
    pageSize: number,
    filterValue: string,
  ): Promise<RoomsModel> {
    // //this.logger.info("RoomsService.getRoomsWithPagination() -> serviceURL: " + serviceURL);
    const routeURL: string = serviceURL; // this.api.createUrl(ApiRoutes.GET_VENUE_ROOMS + venueID);
    let requestUrl: string;
    if (!sort || direction === '') {
      requestUrl = `${routeURL}?page=${page + 1}&limit=${pageSize}`;
    } else {
      requestUrl = `${routeURL}?orderBy=${sort}&sortedBy=${direction}&page=${
        page + 1
      }&limit=${pageSize}`;
    }

    // Remove special caracters from the filter value for proper searching
    filterValue = filterValue.replace(/%/g, '');

    if (filterValue !== '') {
      requestUrl += '&search=' + filterValue;
    }

    return new Promise((resolve, reject) => {
      this.http.get(requestUrl).subscribe({
        next: (response: any) => {
          this.rooms = response.data;
          // //this.logger.info("RoomsService.getRoomsWithPagination() -> response: " + JSON.stringify(response));
          // this.onEmployeesChanged.next(this.employees);
          resolve(response);
        },
        error: reject,
      });
    });
  }

  public deleteRoom(room: any) {
    //this.logger.info("RoomsService.deleteRoom() -> venue: " + JSON.stringify(room));
    return this.http.delete<any>(
      this.api.createUrl(ApiRoutes.DELETE_ROOM) + room.uuid,
    );
  }

  public getRoom(roomID: string) {
    //this.logger.info("RoomsService.getRoom() -> roomID: " + roomID);
    return this.http.get<any>(this.api.createUrl(ApiRoutes.GET_ROOM) + roomID);
  }

  public updateRoom(room: any, roomID: string) {
    //this.logger.info("RoomsService.updateRoom() -> roomID: " + roomID);
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.UPDATE_ROOM) + roomID,
      room,
    );
  }

  public createRoom(room: any) {
    //this.logger.info("RoomsService.updateRoom() -> room: " + JSON.stringify(room));
    return this.http.post<any>(this.api.createUrl(ApiRoutes.ROOMS), room);
  }

  public createSubRoom(room: any, parent_room_uuid: string) {
    //this.logger.info("RoomsService.updateRoom() -> room: " + JSON.stringify(room));
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.ROOMS) + '/' + parent_room_uuid,
      room,
    );
  }

  public getAllAttachableRooms(uuid: string) {
    //this.logger.info("RoomsService.getAllAttachableRooms() -> uuid: " + uuid);

    const url = ApiRoutes.ROOMS_SUB_LIST.replace('{uuid}', uuid);
    //this.logger.info("RoomsService.getAllAttachableRooms() -> url: " + url);

    return this.http.get<any>(this.api.createUrl(url));
  }

  public attachSubRoom(roomUUID: string, subRoomUUID: string) {
    //this.logger.info("RoomsService.attachSubRoom() -> roomUUID: " + roomUUID);
    //this.logger.info("RoomsService.attachSubRoom() -> subRoomUUID: " + subRoomUUID);
    const url = ApiRoutes.ATTACH_SUBROOM.replace('{uuid}', roomUUID).replace(
      '{subroom_uuid}',
      subRoomUUID,
    );
    //this.logger.info("RoomsService.attachSubRoom() -> url: " + url);
    return this.http.patch<any>(this.api.createUrl(url), {});
  }

  public detachSubRoom(roomUUID: string, subRoomUUID: string) {
    //this.logger.info("RoomsService.detachSubRoom() -> roomUUID: " + roomUUID);
    //this.logger.info("RoomsService.detachSubRoom() -> subRoomUUID: " + subRoomUUID);
    const url = ApiRoutes.DETACH_SUBROOM.replace('{uuid}', roomUUID).replace(
      '{subroom_uuid}',
      subRoomUUID,
    );
    //this.logger.info("RoomsService.detachSubRoom() -> url: " + url);
    return this.http.patch<any>(this.api.createUrl(url), {});
  }
}
