export const locale = {
  lang: 'fr-CA',
  data: {
    APP: {
      TITLE: 'Gestion-Traiteur',
      LOGO: 'fuse.png',
    },
    GENERIC: {
      INGREDIENTS: 'Ingrédients',
      CHOOSE_DATE: 'Sélectionner une date',
      START_TIME: 'Heure (Début)',
      END_TIME: 'Heure (Fin)',
      START_DATE: 'Date (Début)',
      END_DATE: 'Date (Fin)',
      CREATED_AT: 'Date de création',
      EVENT_NAME: 'Nom de l’événement',
      GUEST_COUNT_DT: 'Nb de convive',
      EVENT_LOGISTIC_STATUS: 'Statut logistique',
      SELECT_RESPONSIBLE_EMPLOYEE: 'Conseiller',
      PROJECT_DEPOSIT: 'Dépôt du projet',
      REPORT_MODE_NORMAL: 'Période',
      REPORT_MODE_DATE_NOW: 'À ce jour',
      NEXT: 'Prochain',
      BACK: 'Précédent',
      PREVIOUS: 'Précédent',
      YES: 'Oui',
      NO: 'Non',
      ON: 'on',
      OFF: 'off',
      OK: 'OK',
      CLEAR: 'Clear',
      ADD: 'Ajouter',
      CONFIRM: 'Confirmer',
      CANCEL: 'Annuler',
      SUBMIT: 'Soumettre',
      LOGIN: "S'identifier",
      LOGOUT: 'Déconnexion',
      BILLING: 'Facturation',
      PRICE_PER_GUEST: 'PRIX/PERSONNE',
      FOOD: 'Nourriture',
      DRINK: 'Boisson',
      CHARGED: 'Chargé',
      INCLUDED: 'Inclus',
      AND: 'et',
      GENDER: {
        GENDER: 'Sexe',
        FEMALE: 'Femme',
        MALE: 'Homme',
        MR: 'Mr.',
        MS: 'Ms.',
      },
      MISS: 'Mlle',
      MS: 'Mme',
      MRS: 'Mme',
      MR: 'M.',
      'fr-CA': 'Français',
      'en-CA': 'Anglais',
      'mobile-fr-CA': 'Image pour mobile Français',
      'mobile-en-CA': 'Image pour mobile Anglais',
      APPLICATION: 'Application',
      SUPPORT: 'Support',
      LANGUAGE: 'Langue',
      EMAIL: 'Courriel',
      PASSWORD: 'Mot de passe',
      ROLE: 'Rôle',
      GOD: 'God',
      ROLE_OWNER: 'Propriétaire',
      ROLE_ADMIN: 'Administrateur',
      ROLE_EMPLOYEE: 'Employé',
      ROLE_SALE: 'Vente',
      ROLE_ACCOUNTANT: 'Comtable',
      ROLE_HR: 'Ressources humaines',
      ROLE_TEAM_LEAD: 'Chef de section',

      ADMIN: 'Admin',
      KITCHEN: 'Cuisine',
      SALE: 'Vente',
      SERVICE: 'Service',
      OTHER: 'Autre',
      TAX: 'Taxe',
      CREATE: 'Créer',
      DELETE_CONFIRM: 'Êtes vous sûr de vouloir effacer cet item?',
      CANCEL_CONFIRM: 'Êtes vous sûr de vouloir annuler cet item?',
      DELETE_CONFIRM_S: 'Êtes vous sûr de vouloir effacer ces items?',
      DELETE_SUCCESS: 'Effacé avec succès',

      API_CALL_SUCCESS: {
        SAVE_SUCCESS: 'Sauvegarde effectuée avec succès',
        CREATE_SUCCESS: 'Créé avec succès',
        CREATE_CLIENT: 'Client créé avec succès',
        CREATE_PRODUCT: 'Produit créé avec succès',
        CREATE_MATERIAL: 'Matériel créé avec succès',
        CREATE_ENTERPRISE: 'Entreprise créée avec succès',
        DELETE_EMPLOYEE: 'Employé effacé avec succès',
        INVITE: 'Invitation réenvoyée avec succès',
        CREATE_EMPLOYEE: 'Employé créé avec succès',
        CREATE_ROOM: 'Salle créé avec succès',
        CREATE_PLACE: 'Lieu créé avec succès',
        CREATE_SECTOR: 'Secteur créé avec succès',
        CREATE_SHOP: 'Commande en ligne créé avec succès',
      },
      ERROR: 'Erreur',
      ERROR_DIALOG_TITLE: 'Erreur',
      ERROR_DIALOG_422_TITLE: 'Attention - Erreur de validation',
      ERROR_DIALOG_422_MESSAGE: "Les données fournies n'étaient pas valides.",
      ERROR_DIALOG_TITLE_422: 'Validation',
      ERRORS: {
        GENERIC: 'Impossible de traiter la requête. SVP essayer de nouveau',
        GENERIC_ERROR: 'Une erreur inconnue est survenue.',
        API_CALL_STATUS_NOT_FOUND:
          'Impossible de traiter la requête. SVP essayer de nouveau',
        CREATE_CLIENT: 'Une erreur est survenu lors de la création du client',
        DELETE_EMPLOYEE:
          "Un erreur est survenue lors de l'effaçage de l'employé",
        INVITE: "Un erreur est survenue lors de l'envoi de l'invitation.",
      },
      TERMS_CONDITIONS: 'termes et conditions',
      SEARCH: 'recherche',
      FILTERING_PLACEHOLDER: 'filtre',
      NAME: 'Nom',
      CLIENT_NAME: 'Nom du client',
      NAME_INTERNAL: 'Nom interne',
      PRICE: 'Prix',
      RATE: 'Tarif',
      AMOUNT: 'Montant',
      ACTIVE: 'Actif',
      CATEGORY: 'Categorie',
      QUANTITY: 'Quantité',
      PHONE: 'Téléphone',
      PHONE_NUMBER: 'Numéro de Téléphone',
      MOBILE: 'Mobile',
      OFFICE_PHONE: 'Téléphone bureau',
      OFFICE_EXT: 'Ext',
      SAVE: 'Sauvegarder',
      DELETE: 'Effacer',
      EDIT: 'Édition',
      EDIT_STOP: "Annuler l'édition",
      ID: 'ID',
      SEND: 'Envoyer',
      TOTAL: 'Total',
      SUBTOTAL: 'Sous-total',
      PAYMENT: 'Paiement',
      PAYMENT_MODE: 'Mode de paiement',
      STATUS: 'Statut',
      TYPE: 'Type',
      DATE: 'Date',
      TIME: 'Heure',
      FROM: 'de',
      TO: 'à',
      TO2: 'au',
      SUBJECT: 'Sujet',
      START: 'Début',
      END: 'Fin',
      UNTIL: "Jusqu'aux",
      DATE_START: 'Date début',
      DATE_END: 'Date fin',
      TIME_START: 'Heure début',
      TIME_END: 'Heure fin',
      SEE_ON_GOOGLE_MAP: 'Voir sur Google Map',
      CONTENT: 'Contenu',
      FILTER: 'Filtrer...',
      FIRST_NAME: 'Prénom',
      LAST_NAME: 'Nom de famille',
      CONFIRMED: 'Confirmé',
      SELECT_LANGUAGE: 'Choisissez votre langue',
      EMPLOYEES: 'Employés',
      EMPLOYEE: 'Employé',
      USER: 'Utilisateur',
      CLIENTS: 'Clients',
      CLIENT: 'Client',
      WEB_CLIENT: 'Client web',
      WEB_ORDER_ROOM: 'Salle commande WEB',
      CLIENTS_ENTERPRISE: 'Clients et entreprises',
      ENTERPRISES: 'Entreprises',
      ENTERPRISE: 'Entreprise',
      ORGANIZATION: 'Organisation',
      VENUE: 'Lieu',
      VENUES: 'Lieux',
      VENUES_TAB: 'Lieu et livraison',
      ROOM: 'Salle',
      ROOMS: 'Salles',
      ROOM_WEB: 'Salle commande WEB',
      ALCOHOL_PERMIT_LOCAL: "Local d'événement",
      SECTOR: 'Secteur',
      PRODUCTS: 'Produits',
      FOOD_DRINK: 'Nourritures & boissons',
      PRODUCT: 'Produit',
      MATERIALS: 'Matériels',
      MATERIAL: 'Matériels',
      DASHBOARD: 'Tableau de bord',
      DASHBOARD_DETAILED: 'Tableau de bord détaillé',
      DASHBOARD_DETAILED_JUL: 'Tableau de bord détaillé Jul',
      ROOM_MANAGEMENT: 'Gestion des salles',
      DELIVERY: 'Livraison',

      EVENT: 'Événement',
      EVENTS: 'Événements',
      NO_RESULTS: 'Aucun résultat',
      TABLE_ITEMS_PER_PAGE: 'Articles per page',
      TABLE_NEXT_PAGE: 'Prochaine page',
      TABLE_PREVIOUS_PAGE: 'Page précedente',
      TABLE_FIRST_PAGE: 'Première page',
      TABLE_LAST_PAGE: 'Dernière page',
      INFORMATION: 'Information',
      GENERAL_INFORMATION: 'Général',
      PAGE_FORM_GARD:
        'Vous avez un formulaire non sauvegardé! Si vous quittez, vos données seront perdues.',
      NONE: 'Aucun',
      PRINT: 'Imprimer',
      PRINT_ORDER: 'Imprimer le bon de commande',
      PRINT_INVOICE: 'Imprimer la facture',
      DOWNLOAD_ORDER: 'Télécharger le bon de commande',
      DOWNLOAD_INVOICE: 'Télécharger la facture',
      SEND_INVOICE: 'Envoyer la facture',
      PRINT_PROPOSAL: 'Imprimer la proposition',
      DOWNLOAD_PROPOSAL: 'Télécharger la proposition',
      PRINT_DOWNLOAD_PROPOSAL: 'Télécharger/imprimer proposition',
      SEND_PROPOSAL: 'Envoyer la proposition',
      PREVIEW: 'Aperçu',
      OPEN_NEW_TAB: 'Ouvrir dans un nouvel onglet',
      OPEN_SAME_TAB: 'Ouvrir dans le même onglet',
      EDIT_COMBO_ACTION:
        "Changer l'ordre des items ou cliquer le bouton + pour en ajouter/enlever",
      OPEN_CLIENT: 'Ouvrir page client',
      OPEN_ENTERPRISE: 'Ouvrir page entreprise',
      ORDERS: 'Commandes',
      ORDER: 'Commande',
      PROPOSAL: 'Proposition',
      INVOICE: 'Facture',
      WRAPPING: 'Emballage',

      CONTACT: 'Contact',
      ADDRESS: 'Adresse',
      ADDRESSES: 'Adresses',
      NOTE: 'Note',
      NOTES: 'Notes',
      DAY_NOTES: 'Note du jour',
      CALENDAR: 'Calendrier',
      TODAY: "Aujourd'hui",
      DAY: 'Jour',
      WEEK: 'Semaine',
      MONTH: 'Mois',
      THIS_WEEK: 'Cette semaine',
      NEXT_2_WEEK: '2 prochaines semaines',
      THIS_MONTH: 'Présent mois',
      NEXT_MONTH: 'Prochain mois',
      DOWNLOAD: 'Télécharger',
      DOWNLOAD_PDF: 'Télécharger PDF',
      DOWNLOAD_VCARD: 'Télécharger VCard',
      DOWNLOAD_XLS: 'Télécharger en format Excel',
      DOWNLOAD_CSV: 'Télécharger en format CSV',
      WEBSITE: 'Site web',
      BRANCH: 'Succursale',
      GENERAL: 'Général',
      GENERALS: 'Générales',
      REPORT: 'Rapport',
      CLICK_TO_COPY: 'Cliquer pour copier',
      GOOGLE_ADDRESS_PLACEHOLDER: 'Rechercher une adresse',
      MEDIA: 'Media',
      ALLERGENS: 'Allergènes alimentaires',
      NUMBER: 'Numéro',
      FACEBOOK: 'Facebook',
      LINKEDIN: 'LinkedIn',
      TWITTER: 'Twitter',
      YOUTUBE: 'Youtube',
      INSTAGRAM: 'Instagram',
      DURATION: 'Durée',
      EMAIL_INVALID: 'Doit être un email valide',
      INPUT_LENGTH_INVALID_MIN_2: '2 caractères minimum',
      INPUT_LENGTH_INVALID_SMALL: 'Doit contenir entre 2 et 50 caractères',
      INPUT_LENGTH_INVALID: 'Doit contenir entre 2 et 120 caractères',
      INPUT_LENGTH_INVALID_1: 'Doit contenir entre 1 et 120 caractères',
      INPUT_LENGTH_INVALID_20: 'Doit contenir entre 2 et 20 caractères',
      INPUT_LENGTH_INVALID_30: 'Doit contenir entre 2 et 30 caractères',
      INPUT_LENGTH_INVALID_40: 'Doit contenir entre 2 et 40 caractères',
      NOTE_EDITOR_LENGTH_INVALID_2048:
        'Les notes ne peuvent dépasser 2048 caractères.',
      NOTE_EDITOR_LENGTH_INVALID:
        'Les notes ne peuvent dépasser 50 000 caractères.',
      RICH_TEXT_EDITOR_LENGTH_INVALID:
        'Ce texte ne peuvent dépasser 50 000 caractères.',
      TAG_LENGTH_INVALID: 'Les tags ne peuvent dépasser 30 caractères.',
      MODEL_NUMBER_INPUT_LENGTH_INVALID:
        'Doit contenir entre 1 et 75 caractères',
      INPUT_LENGTH_INVALID_191: 'Doit contenir entre 2 et 191 caractères',
      INPUT_LONG_LENGTH_INVALID: 'Doit contenir entre 2 et 2048 caractères',
      INPUT_SHORT_LENGTH_INVALID: 'Doit contenir entre 2 et 30 caractères',
      INPUT_NUMBER: 'La valeur doit être un chiffre',
      INPUT_NUMBER_0_100: 'Le chiffre doit être entre 0 et 100 inclus',
      INPUT_NUMBER_0_999: 'Le chiffre doit être entre 0 et 999',
      INPUT_NUMBER_0_9999: 'Le chiffre doit être entre 0 et 9999',
      INPUT_NUMBER_0_999999: 'Le chiffre doit être entre 0 et 1 million',
      INPUT_NUMBER_POSTFIX_999999: ' et maximum de 1 million',
      INPUT_NUMBER_ABOVE_ZERO: 'Le chiffre doit être supérieur à zéro',
      INPUT_NUMBER_POSTFIX_2_DECIMAL: ', maximum de 2 décimales',
      INPUT_NUMBER_POSTFIX_INTEGER_ONLY: ', nombre entier seulement',
      INPUT_NUMBER_INTEGER_ONLY: 'Nombre entier seulement',
      INPUT_NUMBER_MINUS_100_100: 'Nombre entre -100 et 100 seulement',
      INPUT_NUMBER_NOT_ZERO: 'Nombre ne doit pas être zéro',
      DATE_DOB_INVALID:
        "La date doit être entre l'an 1900 et la date d'aujourd'hui",
      DATE_DEFAULT_RANGE_INVALID:
        "La date doit être entre l'an 1900 et l'an 2099",
      DATE_TODAY_UP_RANGE_INVALID:
        "La date doit être supérieur ou égale à la date d'aujourd'hui",
      DATE_TODAY_DOWN_RANGE_INVALID:
        "La date doit être inférieur ou égale à la date d'aujourd'hui",
      NEW_TAG: 'Tags:',
      LEDGER_ACCOUNT: 'Compte de grand livre',
      AVAILABLE: 'Disponible',
      ALCOHOLIC_PRODUCT: 'Produit alcoolisé',
      DATA: 'Données',
      CONFORMED_INFO_TITLE: 'Produit confirmé et non confirmé',
      // tslint:disable-next-line:max-line-length
      CONFORMED_INFO_DESCRIPTION:
        'Produit confirmé: Un produit confirmé est un produit régulier approuvé par le Chef ou le responsable de l’élaboration des menus. Le coût de nourriture et le prix de vente ont été validés par le responsable. La mention confirmée indique que le produit est conforme à vos standards et vos objectifs de coût de nourriture. \n \n \n Produit non confirmé:\n Est un produit créé ou modifié à l’intérieur d’une commande ou dans la section produit non validé par le Chef ou le responsable de l’élaboration des menus. Ex. Produit de dernière minute, sur mesure, adapté pour un client en particulier, etc. Seulement le Chef ou le responsable de l’élaboration des menus peuvent changer le statut d’un produit.',
      INTERNAL_NAME: 'Nom interne',
      EXTERNAL_NAME: 'Nom externe',
      SAGE: 'Sage',
      GOOGLE_CALENDER: 'Google Calendar',
      SHOP_WEB: 'Shop web',
      STRIPE: 'Stripe',
      QUICK_ORDER: 'Commande rapide',
      READ_ONLY_MODE: 'MODE LECTURE SEULEMENT',
      ARCHIVE_PAGE: 'Page archivée',
      SECTORS: 'Secteurs',
      CHOOSE_SECTOR: 'Choisir un secteur',
      NO_SECTORS: 'Aucun secteur créé à ce jour.',
      MATERIAL_COMBO: 'Ensemble',
      DAYS: {
        MONDAY: 'Lundi',
        TUESDAY: 'Mardi',
        WEDNESDAY: 'Mercredi',
        THURSDAY: 'Jeudi',
        FRIDAY: 'Vendredi',
        SATURDAY: 'Samedi',
        SUNDAY: 'Dimanche',
      },

      ASSIGNMENTS: 'Affectations',
      ASSIGNMENT: 'Affectation',
      ALL_DAY: 'Toute la journée',
      LETTER_FORMAT: 'Format lettre',
      LEGAL_FORMAT: 'Format légal',
      NO_PERMISSIONS: 'Accès refusé',
      JOB_FUNCTIONS: 'Fonctions de travail',
      AVAILABLE_EDIT_MODE_ONLY: 'En mode édition seulement',
      PRODUCT_CATEGORY: 'Catégorie du produit',
      FOOD_DRINK_CATEGORY: 'Catégorie de nourritures & boissons',
      PRODUCT_CATEGORY_SELECT: 'Sélectionner une categorie',
      MATERIAL_CATEGORY: 'Catégorie du matériel',
      MATERIAL_CATEGORY_SELECT: 'Sélectionner une catégorie',
      WITH_DELIVERY: 'Avec livraison',
      WITH_TAKEOUT: 'Avec cueillette',
      DELIVERY_TYPE1: 'Livraison seulement',
      DELIVERY_TYPE2: 'Livrer et installer',
      DELIVERY_TYPE3: 'Avec du personnel',
      DELIVERY_TYPE4: 'Cueillette au comptoir',
      RESPONSIBLE_EMPLOYEE: 'Conseiller',
      FILES: 'Fichiers',
      ATTACH_FILE: 'Attacher un fichier',
      SELECT_IMG_RULES: 'Image jpg ou png de 10 Mo maximum',
      NO_ATTACHED_FILES: 'Aucun fichier',
      FILESIZE: 'Taille du fichier',
      NIGHT: 'Nuit',
      PERCENTAGE: 'Pourcentage',
      ROYALTY_FOOD_BEVERAGE: 'Pourcentage sur la nourriture et boissons',
      ROYALTY_FOOD: 'Pourcentage sur la nourriture',
      ROYALTY_BEVERAGE: 'Pourcentage sur les boissons',
      ROYALTY_MATERIAL: 'Pourcentage sur le matériel',
      ROYALTY_SERVICE: 'Pourcentage sur le personnel',
      ROYALTY_DELIVERY_FEE: 'Pourcentage sur les frais de livraison',
      ROYALTY_TOTAL_TAXES_INCLUDED_FEE:
        'Pourcentage sur le montant total taxes incluses',
      ROYALTY_SERVICE_CHARGE: 'Frais de service',
      NO_ATTACHED_FILES_DROPZONE:
        'Glisser un fichier ici ou cliquer sur le bouton + vert',
      FORM_DIRTY_TIP:
        'Un ou des champs obligatoire sont manquant ou incorrect.',
      NEW: 'Nouveau',
      RESERVATION: 'Réservation',
      BLOCKED: 'Bloqué',
      OVERLAP_ERROR_MESSAGE:
        'Un événement ne peux chevaucher avec un autre pour la même salle.',
      ATTEMPT: 'Tentatif',
      EVENT_IN_PROPOSAL: 'En proposition',
      EVENT_IN_ORDER: 'En commande',
      EVENT_IN_INVOICE: 'En facturation',

      RP_ATTEMPT: 'Tentatif',
      RP_EVENT_IN_PROPOSAL: 'Prospect',
      RP_EVENT_IN_ORDER: 'Définitif',
      RP_EVENT_IN_INVOICE: 'En facturation',

      NO_END_TIME_FOR_EVENT_WITH_ROOM_INTERNAL:
        'Vous avez sélectionné un lieu interne, veuillez sélectionner une heure de fin pour que votre réservation s’affiche dans votre calendrier de gestion de salle',
      ROOM_STATUS: 'Status de la salle',
      INTERNAL: 'Interne',
      PROGRAMS: 'Programmes',
      DESCRIPTION: 'Description',
      MONTAGE: 'Montage',
      GUEST: 'Convives',
      GUEST_S: 'Convive',
      GUEST_COUNT: 'Nombre de convives',
      ESPACE: 'Espace',
      TARIFS: 'Tarifs',
      SELECT_ROOM: 'Sélectionner une salle',
      SELECT: 'Sélectionner',
      GUEST_COUNT2: '# pers',
      SC_ROOM: 'Chambre',

      INVOICE_OPEN: 'Facture émise',
      INVOICE_SEND: 'Facture envoyée',
      INVOICE_PAID: 'Facture payé',
      INVOICE_OVER_DUE: 'Facture en retard',
      INVOICE_CANCEL: 'Facture annulé',
      CLIENT_INVOICE: 'Factures Clients',
      CONTRAT_BANQUET_CLIENT: 'Commande par entreprise',
      CONTRAT_BANQUET_CLIENT_CT: 'Contrat Banquet par entreprise',

      CREATE_PROJECT: 'Créer un projet',
      CREATE_EVENT: 'Nouvel événement',

      EVENT_DATE: "Date de l'événement",
      EVENT_DATE_START: "Date début de l'événement",
      EVENT_DATE_END: "Date fin de l'événement",
      EVENT_TIME_START: "Heure début de l'événement",
      EVENT_TIME_END: "Heure fin de l'événement",
      EVENT_TIME: "Heure de l'événement",
      EVENT_NUMBER: "Numéro d'événement",
      CHOOSE_A_DATE: 'Choisir une ou des dates',
      INCLUDE_SHOW_CANCELED: 'Afficher les congrès annulés',
      CONGRESS_NUMBER_DYN: 'Numéro de congrès',
      SPCM_NUMBER_DYN: 'Numéro de SPCM',
      PROJECTS: 'Projets',
      PROJECT: 'Projet',
      CONGRESS: 'Congrès',
      CONGRESSES: 'Congrès',
      SEND_CONTRAT_BANQUET: 'Envoyer contrat banquet',
      SPCM_NUMBER: '# SPCM',
      PROJECT_NUMBER: '# projet',
      ALERT_DISTRUBITION_MODE_MAIL_ME:
        'Votre demande est en cours de traitement lorsque le rapport est prêt, il sera envoyé par email',
      SEND_PDF_WITH_EMAIL: "M'envoyer le rapport PDF par email",

      MOVE_TO_PROJECT: 'Déplacer vers un projet',
      COPY_TO_PROJECT: 'Copier vers un projet',
      GROUP_ACTION_MOVE_EVENT: 'Déplacer des événements',
      GROUP_ACTION_COPY_EVENT: 'Copier des événements',
      ACTION: 'Action',
      EVENT_TYPE: "Type d'événement",
      CLONED_SUCCESSFULLY: 'CLONÉ AVEC SUCCÈS',
      MOVED_SUCCESSFULLY: 'Déplacé AVEC SUCCÈS',

      CREATE_CONGRESS_TITLE: 'Créer un congrès',

      CREATE_ORDER: 'Créer une commande',
      CREATE_PROPOSAL: 'Créer une proposition',
      CREATE_INVOICE: 'Créer une facture',

      CANCEL_PROJECT_CONFIRM: 'Êtes vous sûr de vouloir annuler ce projet?',
      CANCEL_CONGRESS_CONFIRM: 'Êtes vous sûr de vouloir annuler ce congrès?',

      MAX_USER_TOO_LOW_ERROR:
        'Le nombre d’utilisateurs entrés est inférieur à votre nombre d’utilisateurs actifs.',
      USERS_COUNT: "Nombre d'utilisateur actifs",
      DISCOUNT_OR_SURCHARGE: 'Escompte /Majoration',
      NO_SERVICE_CHARGE: 'Aucun frais de service pour cette entreprise',
      NEW_SERVICE_CHARGE: 'Nouveau frais de service',
      NEW_SERVICE_CHARGE_NAME: 'Nom du nouveau frais de service',
      ADD_SERVICE_CHARGE: 'Ajouter un frais de service',
      UPDATE_SERVICE_CHARGE: 'Modifier un frais de service',
      UPDATE: 'Modifer',
      CONTRAT_BANQUET: 'Contrat Banquet',
      PRODUCT_UPSALE: 'Upsale',
      SET_PRODUCT_CYCLIC_DATE: 'Horaire menu cyclique',
      CLEAR_ALL_DATES: 'Effacer tout',
      CLEAR_ALL_DATES_CONFIRM:
        'Êtes vous sûr de vouloir effacer toute les dates?',
      PRODUCTION_ZONE: 'Zone de production',
      SHOW_CHARGED_PRICE: 'Prix chargé',
      GL_TOOLTIP_HINT:
        "Si vous utilisez le champ pourboires non-taxables dans les factures ou dans vos boutiques de commande en ligne, le sous-total - vente nette du rapport compte de grand livre n'est pas égale au montant net de la liste des factures et autres rapports de ventes parce qu’il inclut le montant des pourboires non-taxables.",
      ENTERPRISE_COPY_ADDRESS_CLIENT:
        'Copier l’adresse de l’entreprise aux clients',
      COPY_ENTERPRISE_ADDRESS_CLIENTS_CONFIRM:
        'Cette action va modifier les adresses de tous les clients attachés à cette entreprise. Êtes-vous sûr de vouloir procéder?',
      COPY_INTERNAL_NAME_TO_EXTERNAL: 'Copier Nom interne',
      SEARCH_PRODUCT_DETAILED:
        'recherche par nom interne, nom externe ou code de produit',
      SEARCH_PRODUCT: 'Effectuez une recherche de votre produit.',
      LEFT_LIST_LABEL:
        'Glisser et déposer ou utiliser le bouton + pour ajouter des produits',
      RIGHT_LIST_LABEL: 'Réordonner et/ou enlever un produit',
      SEARCH_PRODUCTS: 'Rechercher des produits',
    },
    CLIENT_OVERRIDE: {
      'capital-traiteur': {
        GENERIC: {
          ENTERPRISES: 'Clients',
          ENTERPRISE: 'Client',
          CLIENTS: 'Contacts',
          CLIENT: 'Contact',
          CLIENT_NAME: 'Nom du contact',
          CREATE_CLIENT: 'Contact créé avec succès',
          CLIENT_INVOICE: 'Factures Contacts',
          OPEN_CLIENT: 'Ouvrir page contact',
          WEB_CLIENT: 'Contact web',
          CLIENTS_ENTERPRISE: 'Contacts et entreprises',
          OPEN_ENTERPRISE: 'Ouvrir page client',
        },
        ERRORS: {
          CREATE_CLIENT:
            'Une erreur est survenu lors de la création du contact',
        },
        API_CALL_SUCCESS: {
          CREATE_CLIENT: 'Contact créé avec succès',
          CREATE_ENTERPRISE: 'Client créée avec succès',
        },
      },
      'agence-traiteur': {
        GENERIC: {
          ENTERPRISES: 'Clients',
          ENTERPRISE: 'Client',
          CLIENTS: 'Contacts',
          CLIENT: 'Contact',
          CLIENT_NAME: 'Nom du contact',
          CREATE_CLIENT: 'Contact créé avec succès',
          CLIENT_INVOICE: 'Factures Contacts',
          OPEN_CLIENT: 'Ouvrir page contact',
          WEB_CLIENT: 'Contact web',
          CLIENTS_ENTERPRISE: 'Contacts et entreprises',
          OPEN_ENTERPRISE: 'Ouvrir page client',
        },
        ERRORS: {
          CREATE_CLIENT:
            'Une erreur est survenu lors de la création du contact',
        },
        API_CALL_SUCCESS: {
          CREATE_CLIENT: 'Contact créé avec succès',
          CREATE_ENTERPRISE: 'Client créée avec succès',
        },
      },
      CREATE_ORDER: 'Créer une commande',
      CREATE_PROPOSAL: 'Créer une proposition',
    },
  },
};
