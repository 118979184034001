import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormGroup,
} from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NumericDirectiveModule } from '../../../../modules/numeric.module';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-delivery-rules',
  templateUrl: './delivery-rules.component.html',
  styleUrls: ['./delivery-rules.component.scss'],
  standalone: true,
  imports: [
    MatSelectModule,
    FlexLayoutModule,
    TranslateModule,
    ReactiveFormsModule,
    MatInputModule,
    NumericDirectiveModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    CommonModule,
    FormsModule,
    MatFormFieldModule,
  ],
})
export class DeliveryRulesComponent implements OnInit {
  @Input() shop: any;
  @Input() editMode: boolean = false;
  @Input() deliveryRules: any;
  @Input() form!: UntypedFormGroup;
  @Input() shopPreference: any;
  @Output() saveDeliveryRules: EventEmitter<any> = new EventEmitter();

  constructor(private logger: NGXLogger) {}

  ngOnInit() {}

  public onAddDeliveryBracket(): void {
    this.deliveryRules.distance_brackets.push({ min: 0, max: 0 });
    this.deliveryRules.rate_brackets.push(0);
  }

  public onRemoveBracket(item: any, i: any): void {
    //this.logger.info("ShopWebComponent.onRemoveBracket() -> i: " + i);
    //this.logger.info("ShopWebComponent.onRemoveBracket() -> item: " + JSON.stringify(item));
    this.deliveryRules.distance_brackets.splice(i, 1);
    this.deliveryRules.rate_brackets.splice(i, 1);
  }

  public onSaveDeliveryRules(): void {
    this.saveDeliveryRules.emit();
  }
}
