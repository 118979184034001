<div
  class="product-picture-upload"
  style="min-width: 600px"
  fxLayout="row"
  fxLayoutAlign="center center"
>
  <div class="image-delete-section">
    <mat-card class="card-image">
      <mat-card-content>
        <div
          class="image-section"
          xLayout="column"
          fxLayoutAlign="center center"
        >
          <div *ngIf="!fileUploading" class="image-container">
            <img
              *ngIf="media.photos.photo.url"
              [src]="media.photos.photo.url"
            />
          </div>
          <mat-progress-bar
            *ngIf="fileUploading"
            [value]="fileProgress"
            class="progress-bar"
          >
          </mat-progress-bar>

          <!-- <div *ngIf="!imageURL">Votre image</div> -->
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button
          mat-icon-button
          color="warn"
          [disabled]="media.photos.photo.uuid === null"
          (click)="onDeleteImage()"
          class="delete-btn"
          matTooltip="Effacer"
          matTooltipPosition="right"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </mat-card-actions>
    </mat-card>
  </div>

  <div class="upload-section" fxLayout="column" fxLayoutAlign="center center">
    <!-- <div id="drop_zone" ondrop="onFileDrop($event);"
                [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
                class="well drop-zone">
                Déposer une image ici
            </div> -->

    <!-- <div class="or-section">OU</div> -->

    <div class="select-section">
      <ul>
        <li *ngFor="let file of files">
          <!-- <mat-progress-bar [value]="file.progress"></mat-progress-bar> -->
          <span id="file-label"> </span>
        </li>
      </ul>

      <div fxLayout="row" fxLayoutAlign="start center">
        <button mat-button color="warn" (click)="onSelectAndUpload()">
          <mat-icon>file_upload</mat-icon>
          Sélectionner une image
        </button>
        <button
          type="button"
          mat-icon-button
          class=""
          matTooltip="Image jpg ou png de 250 de large minimun et de 250px de haut minimum"
          matTooltipPosition="above"
        >
          <mat-icon class="s-20" color="">help</mat-icon>
        </button>
      </div>

      <input
        type="file"
        #fileUpload
        id="fileUpload"
        name="fileUpload"
        accept="image/png, image/jpeg, image/jpg"
        style="display: none"
      />
    </div>

    <div></div>
  </div>
</div>
