<div
  id="employee"
  class="page-layout simple left-sidebar inner-sidebar inner-scroll"
>
  <div class="gt2-theme">
    <div
      class="header accent pl-24 pr-24 h-80 mat-elevation-z1"
      fusePerfectScrollbar
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div fxLayout="row" fxLayoutAlign="start center">
        <button
          class="mr-0 mr-sm-16 white-fg"
          mat-icon-button
          (click)="onPageBack(location, router, '/employees')"
        >
          <mat-icon class="icon-mat">arrow_back</mat-icon>
        </button>

        <div fxLayout="column" fxLayoutAlign="center start">
          <div class="user-info" fxLayout="row" fxLayoutAlign="start start">
            <div
              class="employee-title"
              *fuseIfOnDom
              [@animate]="{
                value: '*',
                params: { delay: '100ms', x: '-25px' },
              }"
            >
              <div *ngIf="employee" class="">
                <span class="text-truncate"
                  >{{ "GENERIC.EMPLOYEE" | translate }} -
                  {{ employee.first_name }}
                  {{ employee.last_name }}</span
                >
              </div>
            </div>
          </div>

          <div
            *ngIf="employee"
            fxLayout="row"
            fxLayoutAlign="start start"
            class=""
          >
            <div class="">Type: {{ employee.employee_type.name }}</div>
            <div class="" *ngIf="employee.user">
              &nbsp;- {{ "EMPLOYEES.PAYING_CUSTOMER" | translate }}
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="end center">
        <!-- <button mat-button
                        *ngIf="fileService.pdfEnabled()"
                        matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                        (click)="onDownloadPDF()" class="mat-icon-button">
                    <mat-icon class="s-30">picture_as_pdf</mat-icon>
                </button>

                <mat-spinner *ngIf="print.loadingPrint" [diameter]="20" class="mt-12"></mat-spinner>

                <button mat-button
                        *ngIf="fileService.printEnabled()"
                        matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                        (click)="onPrint()" class="mat-icon-button">
                    <mat-icon class="s-30">print</mat-icon>
                </button>

                <button mat-button
                        *ngIf="fileService.excelEnabled()"
                        matTooltip="{{ 'GENERIC.DOWNLOAD_VCARD' | translate }}"
                        (click)="onDownloadVCard()" class="mat-icon-button">
                    <mat-icon fontSet="fa" fontIcon="fa-id-card" class="s-30"></mat-icon>
                </button> -->

        <button
          mat-button
          matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
          (click)="onOpenInNewTab()"
          class="mat-icon-button"
        >
          <mat-icon class="icon-mat s-30">{{ getIcon("NEW_TAB") }}</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <!-- / HEADER -->

  <!-- SUB HEADER -->
  <div
    *ngIf="employee"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fusePerfectScrollbar
    class="primary mat-elevation-z1 py-8 gt2-sub-header content-subheader"
  >
    <div
      *ngIf="employee.user && employee.user.email_label"
      fxLayout="row"
      fxLayoutAlign="start center"
      class="ml-12 s-24"
    >
      <a
        mat-button
        style="color: white"
        href="mailto:{{ employee.user.email_label }}"
      >
        {{ employee.user.email_label }}
      </a>
      <!-- <button
        mat-button
        (click)="onOpenMailto(employee.user.email_label)"
        class="gt2-sub-header-button"
      > -->
      <!-- <mat-icon class="mr-8 s-20">email</mat-icon>  -->
      <!-- <p class="icon-mat">{{ employee.user.email_label }}</p>
      </button> -->
    </div>

    <div fxLayout="row" fxLayoutAlign="end center">
      <div *ngxPermissionsOnly="rolesService.ADMIN_ROLES">
        <button
          mat-button
          *ngIf="employee.user && !employee.user.confirmed"
          (click)="onSendInvite()"
          class="gt2-sub-header-button"
        >
          <mat-icon class="icon-mat s-24">mail_outline</mat-icon>
          <span class="ml-4 mr-8 icon-mat">{{
            "EMPLOYEES.RESEND_INVITE" | translate
          }}</span>
        </button>
      </div>

      <div *ngxPermissionsOnly="rolesService.ADMIN_ROLES">
        <button
          mat-button
          *ngIf="!employee.user"
          (click)="onConvertToUser()"
          class=""
        >
          <mat-icon
            fontSet="fa"
            fontIcon="fa-user-plus"
            class="icon-mat s-20"
            style="margin-right: 8px !important"
          ></mat-icon>
          <!--<mat-icon class="s-24">mail_outline</mat-icon>-->
          <p class="icon-mat">
            {{ "EMPLOYEES.TRANSFORM_INTO_USER" | translate }}
          </p>
        </button>
      </div>

      <div *ngxPermissionsOnly="rolesService.ADMIN_ROLES">
        <button
          mat-button
          *ngIf="employee.user && employee.user.confirmed"
          (click)="onRemoveAsUser()"
          class=""
        >
          <mat-icon
            fontSet="fa"
            fontIcon="fa-user-minus"
            class="icon-mat s-20"
            style="margin-right: 8px !important"
          ></mat-icon>
          <p class="icon-mat">{{ "EMPLOYEES.REMOVE_AS_USER" | translate }}</p>
        </button>
      </div>
    </div>
  </div>
  <!-- / SUB HEADER -->

  <!--HISTORY SIDEBAR-->
  <div *ngIf="!isSmallScreen" class="container-history-panel">
    <fuse-sidebar
      *ngIf="employeeID"
      name="employeeHistoryPanel"
      position="right"
      class="section-history-panel"
    >
      <app-section-history-panel
        *ngIf="employee"
        panelName="employeeHistoryPanel"
        section="employees"
        sectionUUID="{{ employeeID }}"
      >
      </app-section-history-panel>
    </fuse-sidebar>
  </div>
  <!-- / HISTORY SIDEBAR-->

  <div
    *ngIf="employee && !editable"
    fxLayout="row"
    fxLayoutAlign="center center"
    style="height: 38px; min-height: 38px; max-height: 38px"
    class="gt2-gray mat-elevation-z1 py-8"
  >
    <div class="text-white font-weight-600">
      <b>{{ "GENERIC.READ_ONLY_MODE" | translate }}</b> :
      {{ "GENERIC.ARCHIVE_PAGE" | translate }}
    </div>
  </div>

  <!--HISTORY SIDEBAR BUTTON-->
  <div *ngIf="!isSmallScreen">
    <!-- <div *ngIf="editable"> -->
    <button
      mat-icon-button
      class="warn mat-elevation-z2 historic-button"
      *fuseIfOnDom
      [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }"
      (click)="toggleHistoricSidebarOpen('employeeHistoryPanel')"
    >
      <mat-icon class="icon-mat">history</mat-icon>
    </button>
    <!-- </div> -->
  </div>
  <!-- /HISTORY SIDEBAR BUTTON-->

  <!-- CONTENT -->
  <div class="content">
    <!-- CENTER -->
    <div class="center p-24 pb-56 pr-sm-72" fusePerfectScrollbar>
      <!-- CONTENT -->
      <div class="employee-content">
        <!--TAGS-->
        <div fxLayout="row" fxLayoutAlign="start end">
          <app-tags-by-model
            #tags
            fxFlex="100%"
            class="ml-4"
            [tagPlaceholder]="'GENERIC.NEW_TAG' | translate"
            [tagFamily]="[
              'tags-language',
              'tags-employee-type',
              'tags-employee-quality-general',
              'tags-employee-classification-cook',
              'tags-employee-expertise-cook',
              'tags-employee-custom',
            ]"
            [customTagFamily]="'tags-employee-custom'"
            [tagModel]="'employee'"
            [tagModelUUID]="employeeID"
          >
          </app-tags-by-model>
        </div>

        <div
          *ngIf="employee"
          class="profile-box info-box general"
          fxLayout="column"
        >
          <header class="accent">
            <div class="title">{{ "GENERIC.INFORMATION" | translate }}</div>
          </header>

          <mat-tab-group
            animationDuration="100ms"
            mat-stretch-tabs="false"
            mat-align-tabs="start"
          >
            <mat-tab label="{{ 'GENERIC.GENERAL' | translate }}">
              <div class="content" fxLayout="column">
                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="start start"
                >
                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "GENERIC.FIRST_NAME" | translate }}
                    </div>
                    <div class="info text-truncate">
                      {{ employee.first_name }}
                    </div>
                    <div
                      *ngIf="!employee.first_name"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field
                          class="form-input-container"
                          fxFlex="100%"
                        >
                          <mat-label>{{
                            "GENERIC.FIRST_NAME" | translate
                          }}</mat-label>
                          <input
                            matInput
                            autocomplete="nope"
                            formControlName="first_name"
                            type="text"
                            [(ngModel)]="employee.first_name"
                            required
                          />
                          <mat-error *ngIf="form.controls['first_name'].invalid"
                            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "GENERIC.LAST_NAME" | translate }}
                    </div>
                    <div class="info text-truncate">
                      {{ employee.last_name }}
                    </div>
                    <div
                      *ngIf="!employee.last_name"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field
                          class="form-input-container"
                          fxFlex="100%"
                        >
                          <mat-label>{{
                            "GENERIC.LAST_NAME" | translate
                          }}</mat-label>
                          <input
                            matInput
                            autocomplete="nope"
                            formControlName="last_name"
                            type="text"
                            [(ngModel)]="employee.last_name"
                            required
                          />
                          <mat-error *ngIf="form.controls['last_name'].invalid"
                            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="space-between start"
                >
                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "EMPLOYEES.EMPLOYEE_NUMBER" | translate }}
                    </div>
                    <!-- <div ngxClipboard
                                             [cbContent]="employee.employee_number"
                                             matTooltip="{{ 'GENERIC.CLICK_TO_COPY' | translate }}"
                                             [matTooltipPosition]="'above'"
                                             class="info text-truncate">{{employee.employee_number}}
                                        </div> -->
                    <div
                      ngxClipboard
                      [cbContent]="employee.employee_number"
                      matTooltip="{{ 'GENERIC.CLICK_TO_COPY' | translate }}"
                      placement="left"
                      class="info text-truncate"
                    >
                      {{ employee.employee_number }}
                    </div>
                    <div
                      *ngIf="!employee.employee_number"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field
                          class="form-input-container"
                          fxFlex="100%"
                        >
                          <mat-label>{{
                            "EMPLOYEES.EMPLOYEE_NUMBER" | translate
                          }}</mat-label>
                          <input
                            matInput
                            formControlName="employee_number"
                            autocomplete="nope"
                            type="text"
                            [(ngModel)]="employee.employee_number"
                            required
                          />
                          <mat-error
                            *ngIf="form.controls['employee_number'].invalid"
                            >{{
                              "GENERIC.MODEL_NUMBER_INPUT_LENGTH_INVALID"
                                | translate
                            }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">{{ "EMPLOYEES.TYPE" | translate }}</div>
                    <div class="info text-truncate">
                      {{ employee.employee_type.name }}
                    </div>
                    <div
                      *ngIf="!employee.employee_type"
                      class="info empty-value"
                    ></div>
                    <!--TODO: -->
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <app-gt2-select
                        [service]="employeeService"
                        [placeHolder]="'EMPLOYEES.TYPE' | translate"
                        [label]="'EMPLOYEES.TYPE' | translate"
                        [selectedModel]="employee.employee_type"
                        (changeEvent)="onEmployeeTypeChange($event)"
                        [noResultsLabel]="
                          translate.instant('GENERIC.NO_RESULTS')
                        "
                        [nullableUseNone]="false"
                        class=""
                      ></app-gt2-select>

                      <!--<div [formGroup]="form" class="">-->
                      <!--<mat-form-field class="form-input-container" fxFlex="100%">-->

                      <!--<mat-select placeholder="{{ 'EMPLOYEES.TYPE' | translate}}"-->
                      <!--formControlName="employee_type"-->
                      <!--[(ngModel)]="employee.employee_type">-->
                      <!--<mat-option value="ADMIN">{{ 'GENERIC.ADMIN' | translate }}-->
                      <!--</mat-option>-->
                      <!--<mat-option value="KITCHEN">{{ 'GENERIC.KITCHEN' | translate-->
                      <!--}}-->
                      <!--</mat-option>-->
                      <!--<mat-option value="SALE">{{ 'GENERIC.SALE' | translate }}-->
                      <!--</mat-option>-->
                      <!--<mat-option value="SERVICE">{{ 'GENERIC.SERVICE' | translate-->
                      <!--}}-->
                      <!--</mat-option>-->
                      <!--<mat-option value="OTHER">{{ 'GENERIC.OTHER' | translate }}-->
                      <!--</mat-option>-->
                      <!--</mat-select>-->
                      <!--</mat-form-field>-->
                      <!--</div>-->
                    </div>
                  </div>
                </div>

                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="space-between start"
                >
                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">{{ "EMPLOYEES.DOB" | translate }}</div>
                    <div class="info text-truncate">
                      {{ employee.dob_label }}
                    </div>
                    <div *ngIf="!employee.dob" class="info empty-value"></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field
                          class="form-input-container"
                          fxFlex="100%"
                        >
                          <mat-label>{{
                            "EMPLOYEES.DOB" | translate
                          }}</mat-label>
                          <input
                            matInput
                            [matDatepicker]="dob"
                            [(ngModel)]="employee.dob"
                            min="1900-01-01"
                            max="{{ todayDate }}"
                            formControlName="dob"
                          />
                          <mat-datepicker-toggle matSuffix [for]="dob">
                          </mat-datepicker-toggle>
                          <mat-datepicker #dob></mat-datepicker>

                          <mat-error *ngIf="form.controls['dob'].invalid"
                            >{{ "GENERIC.DATE_DOB_INVALID" | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "GENERIC.GENDER.GENDER" | translate }}
                    </div>
                    <div class="info" *ngIf="employee.gender === 'MALE'">
                      {{ "GENERIC.GENDER.MALE" | translate }}
                    </div>
                    <div class="info" *ngIf="employee.gender === 'FEMALE'">
                      {{ "GENERIC.GENDER.FEMALE" | translate }}
                    </div>
                    <div
                      *ngIf="!employee.gender"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" fxLayout="column" class="">
                        <div class="mat-form-field-label">
                          {{ "GENERIC.GENDER.GENDER" | translate }}
                        </div>
                        <mat-radio-group
                          formControlName="gender"
                          [(ngModel)]="employee.gender"
                          class=""
                          margin
                        >
                          <mat-radio-button
                            class="mr-16"
                            checked="{{ employee.gender === 'MALE' }}"
                            value="MALE"
                          >
                            {{ "GENERIC.GENDER.MALE" | translate }}
                          </mat-radio-button>
                          <mat-radio-button
                            checked="{{ employee.gender === 'FEMALE' }}"
                            value="FEMALE"
                          >
                            {{ "GENERIC.GENDER.FEMALE" | translate }}
                          </mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="space-between start"
                >
                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "EMPLOYEES.HAVE_DRIVER_LICENSE" | translate }}
                    </div>
                    <div *ngIf="employee.have_driver_licence" class="info">
                      {{ "GENERIC.YES" | translate }}
                    </div>
                    <div *ngIf="!employee.have_driver_licence" class="info">
                      {{ "GENERIC.NO" | translate }}
                    </div>
                    <div
                      *ngIf="employee.have_driver_licence === null"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field class="" fxFlex="100%">
                          <mat-label>{{
                            "EMPLOYEES.HAVE_DRIVER_LICENSE" | translate
                          }}</mat-label>
                          <mat-select
                            formControlName="have_driver_licence"
                            [(ngModel)]="employee.have_driver_licence"
                          >
                            <mat-option [value]="true">
                              <span class="iso">{{
                                "GENERIC.YES" | translate
                              }}</span>
                            </mat-option>
                            <mat-option [value]="false">
                              <span class="iso">{{
                                "GENERIC.NO" | translate
                              }}</span>
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "EMPLOYEES.OWN_CAR" | translate }}
                    </div>
                    <!--<div class="info">{{!!employee.have_car}}</div>-->
                    <div *ngIf="employee.have_car" class="info">
                      {{ "GENERIC.YES" | translate }}
                    </div>
                    <div *ngIf="!employee.have_car" class="info">
                      {{ "GENERIC.NO" | translate }}
                    </div>
                    <div
                      *ngIf="!employee.have_car === null"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field class="" fxFlex="100%">
                          <mat-label>{{
                            "EMPLOYEES.OWN_CAR" | translate
                          }}</mat-label>
                          <mat-select
                            formControlName="have_car"
                            [(ngModel)]="employee.have_car"
                          >
                            <mat-option [value]="1">
                              <span class="iso">{{
                                "GENERIC.YES" | translate
                              }}</span>
                            </mat-option>
                            <mat-option [value]="0">
                              <span class="iso">{{
                                "GENERIC.NO" | translate
                              }}</span>
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="space-between start"
                >
                  <div *ngIf="!editMode" class="info-line" fxFlex="50%">
                    <div class="title text-truncate">
                      {{ "EMPLOYEES.DRIVER_LICENSE_TYPE" | translate }}
                    </div>
                    <div class="info">{{ employee.driver_licence_type }}</div>
                    <div
                      *ngIf="!employee.driver_licence_type"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field
                          class="form-input-container"
                          fxFlex="100%"
                        >
                          <mat-label>{{
                            "EMPLOYEES.DRIVER_LICENSE_TYPE" | translate
                          }}</mat-label>
                          <input
                            matInput
                            formControlName="driver_licence_type"
                            type="text"
                            autocomplete="nope"
                            [(ngModel)]="employee.driver_licence_type"
                          />
                          <mat-error
                            *ngIf="form.controls['driver_licence_type'].invalid"
                          >
                            {{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <!-- <div *ngIf="!editMode && !employeeIsSelf" class="info-line" fxFlex="50%"></div> -->
                  <!-- <div *ngIf="employee && employee.user" fxFlex="50%"> -->
                  <div
                    *ngIf="!editMode && employee.user"
                    class="info-line"
                    fxFlex="50%"
                  >
                    <div class="title">
                      {{ "EMPLOYEES.EMPLOYEE_ROLE" | translate }}
                    </div>
                    <div
                      *ngIf="employee && employee.employee_role"
                      class="info text-truncate"
                    >
                      {{ employee.employee_role.name }}
                    </div>
                    <div
                      *ngIf="!employee || !employee.employee_role"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div
                    *ngIf="editMode && employee.user"
                    class="info-line"
                    fxFlex="50%"
                  >
                    <div
                      *ngxPermissionsOnly="rolesService.ADMIN_ROLES"
                      [formGroup]="form"
                      class=""
                    >
                      <app-gt2-select
                        [service]="rolesService"
                        [placeHolder]="'EMPLOYEES.EMPLOYEE_ROLE' | translate"
                        [label]="'EMPLOYEES.EMPLOYEE_ROLE' | translate"
                        [selectedModel]="employee.employee_role"
                        (changeEvent)="onEmployeeRoleChange($event)"
                        [noResultsLabel]="
                          translate.instant('GENERIC.NO_RESULTS')
                        "
                        [nullableUseNone]="false"
                        class=""
                      ></app-gt2-select>
                    </div>
                  </div>
                  <!-- </div> -->
                </div>
              </div>
            </mat-tab>

            <mat-tab
              label="{{ 'EMPLOYEES.CONFIDENTIAL' | translate }}"
              *ngxPermissionsOnly="rolesService.RH_ROLES"
            >
              <div class="content" fxLayout="column">
                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="start start"
                >
                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "EMPLOYEES.DATE_HIRE" | translate }}
                    </div>
                    <div class="info">{{ employee.hire_at_label }}</div>
                    <div
                      *ngIf="!employee.hire_at"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field class="" fxFlex="100%">
                          <mat-label>
                            {{ "EMPLOYEES.DATE_HIRE" | translate }}
                          </mat-label>
                          <input
                            matInput
                            formControlName="hire_at"
                            [matDatepicker]="hireAt"
                            min="1900-01-01"
                            max="2099-01-01"
                            [(ngModel)]="employee.hire_at"
                          />
                          <mat-datepicker-toggle matSuffix [for]="hireAt">
                          </mat-datepicker-toggle>
                          <mat-datepicker #hireAt></mat-datepicker>

                          <mat-error *ngIf="form.controls['hire_at'].invalid"
                            >{{
                              "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate
                            }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="!editMode" class="info-line" fxFlex="50%">
                    <div class="title">
                      {{ "EMPLOYEES.DATE_TERMINATION" | translate }}
                    </div>
                    <div class="info">{{ employee.termination_at_label }}</div>
                    <div
                      *ngIf="!employee.termination_at"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field
                          class="form-input-container"
                          fxFlex="100%"
                        >
                          <mat-label>{{
                            "EMPLOYEES.DATE_TERMINATION" | translate
                          }}</mat-label>
                          <input
                            matInput
                            [matDatepicker]="terminationAt"
                            formControlName="termination_at"
                            min="1900-01-01"
                            max="2099-01-01"
                            [(ngModel)]="employee.termination_at"
                          />
                          <mat-datepicker-toggle
                            matSuffix
                            [for]="terminationAt"
                          >
                          </mat-datepicker-toggle>
                          <mat-datepicker #terminationAt></mat-datepicker>
                          <mat-error
                            *ngIf="form.controls['termination_at'].invalid"
                            >{{
                              "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate
                            }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="start start"
                >
                  <div *ngIf="!editMode" class="info-line" fxFlex="50%">
                    <div class="title">
                      {{ "EMPLOYEES.SOCIAL_NUMBER" | translate }}
                    </div>
                    <!--<div fxLayout="row" fxLayoutAlign="start center" >-->
                    <div
                      class="info"
                      fxLayout="row"
                      fxLayoutAlign="start center"
                      *ngIf="viewSocialNumber && employee.social_number"
                    >
                      <div>{{ employee.social_number }}</div>
                      <div
                        matTooltip="{{
                          'EMPLOYEES.SHOW_HIDE_SOCIAL_NUMBER' | translate
                        }}"
                        [matTooltipPosition]="'above'"
                        *ngIf="employee.social_number"
                        (click)="onToggleViewSocialNumber()"
                        class="ml-8"
                      >
                        <mat-icon color="icon-mat" class="s-16"
                          >visibility</mat-icon
                        >
                      </div>
                    </div>
                    <div
                      class="info"
                      *ngIf="!viewSocialNumber && employee.social_number"
                      fxLayout="row"
                      fxLayoutAlign="start center"
                    >
                      <div>********</div>
                      <div
                        matTooltip="{{
                          'EMPLOYEES.SHOW_HIDE_SOCIAL_NUMBER' | translate
                        }}"
                        [matTooltipPosition]="'above'"
                        *ngIf="employee.social_number"
                        (click)="onToggleViewSocialNumber()"
                        class="ml-8"
                      >
                        <mat-icon color="icon-mat" class="s-16"
                          >visibility</mat-icon
                        >
                      </div>
                    </div>
                    <div
                      *ngIf="!employee.social_number"
                      class="info empty-value"
                    ></div>

                    <!--</div>-->
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field
                          class="form-input-container"
                          fxFlex="100%"
                        >
                          <mat-label>{{
                            "EMPLOYEES.SOCIAL_NUMBER" | translate
                          }}</mat-label>
                          <input
                            matInput
                            formControlName="social_number"
                            autocomplete="nope"
                            type="text"
                            [(ngModel)]="employee.social_number"
                          />
                          <mat-error
                            *ngIf="form.controls['social_number'].invalid"
                            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "EMPLOYEES.TRANSIT" | translate }}
                    </div>
                    <div class="info">{{ employee.bank_transit }}</div>
                    <div
                      *ngIf="!employee.bank_transit"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field
                          class="form-input-container"
                          fxFlex="100%"
                        >
                          <mat-label>{{
                            "EMPLOYEES.TRANSIT" | translate
                          }}</mat-label>
                          <input
                            matInput
                            formControlName="bank_transit"
                            type="text"
                            autocomplete="nope"
                            [(ngModel)]="employee.bank_transit"
                          />
                          <mat-error
                            *ngIf="form.controls['bank_transit'].invalid"
                            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="start start"
                >
                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "EMPLOYEES.BANK_NUMBER" | translate }}
                    </div>
                    <div class="info">{{ employee.bank_institution }}</div>
                    <div
                      *ngIf="!employee.bank_institution"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field
                          class="form-input-container"
                          fxFlex="100%"
                        >
                          <mat-label>{{
                            "EMPLOYEES.BANK_NUMBER" | translate
                          }}</mat-label>
                          <input
                            matInput
                            formControlName="bank_institution"
                            autocomplete="nope"
                            type="text"
                            [(ngModel)]="employee.bank_institution"
                          />
                          <mat-error
                            *ngIf="form.controls['bank_institution'].invalid"
                            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "EMPLOYEES.BANK_ACCOUNT_NUMBER" | translate }}
                    </div>
                    <div class="info">{{ employee.bank_account }}</div>
                    <div
                      *ngIf="!employee.bank_account"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field
                          class="form-input-container"
                          fxFlex="100%"
                        >
                          <mat-label>{{
                            "EMPLOYEES.BANK_ACCOUNT_NUMBER" | translate
                          }}</mat-label>
                          <input
                            matInput
                            formControlName="bank_account"
                            autocomplete="nope"
                            type="text"
                            [(ngModel)]="employee.bank_account"
                          />
                          <mat-error
                            *ngIf="form.controls['bank_account'].invalid"
                            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>

            <!--<mat-tab label="Fonctions">-->
            <!--</mat-tab>-->
          </mat-tab-group>
        </div>

        <!-- RH_ROLES ROLES ASSIGNMENTS -->
        <div
          *ngIf="
            moduleService.modulesAreSet &&
            moduleService.moduleExist('MODULE_STAFF_ASSIGNMENT')
          "
        >
          <!-- <div *ngxPermissionsOnly="['GOD']" class="profile-box info-box general mt-12" fxLayout="column"> -->
          <!-- RH_ROLES or ACCOUNTANT_ROLES -->
          <div
            *ngxPermissionsOnly="rolesService.SALE_ROLES"
            style="min-width: 1023px"
            class="profile-box info-box general mt-12"
            fxLayout="column"
          >
            <header
              class="accent"
              fxFlex="100"
              fxLayout="row"
              fxLayoutAlign="space-between center"
            >
              <div class="title">{{ "GENERIC.ASSIGNMENTS" | translate }}</div>

              <!-- <button mat-raised-button color='primary' class="mr-12">
                                {{ 'CLIENTS.GENERATE_ACCOUNT_STATEMENT' | translate}}
                            </button> -->

              <!-- REPORT MENU -->

              <!-- ASSIGNEMENTS PRINT & DOWNLOAD -->
              <!-- <div *ngxPermissionsOnly="rolesService.GOD_ROLES"> -->
              <div
                *ngIf="dataSource && dataSource.data"
                fxLayout="row"
                fxLayoutAlign="end center"
              >
                <div
                  [formGroup]="formDates"
                  fxFlex
                  fxLayout="row"
                  fxLayoutAlign="end center"
                >
                  <mat-form-field style="margin-right: 16px; padding-top: 16px">
                    <mat-label class="icon-mat">{{
                      "GENERIC.START" | translate
                    }}</mat-label>
                    <input
                      matInput
                      formControlName="date_from1"
                      [matDatepicker]="eventDateFrom1"
                      min="1900-01-01"
                      max="2099-01-01"
                      (ngModelChange)="onDateFromChange1($event)"
                      [(ngModel)]="dateRange1.date_from"
                      class="icon-mat"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="eventDateFrom1"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #eventDateFrom1></mat-datepicker>

                    <mat-error *ngIf="formDates.controls['date_from1'].invalid"
                      >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field style="margin-right: 16px; padding-top: 16px">
                    <mat-label class="icon-mat">{{
                      "GENERIC.END" | translate
                    }}</mat-label>
                    <input
                      matInput
                      formControlName="date_to1"
                      [matDatepicker]="eventDateTo1"
                      min="1900-01-01"
                      max="2099-01-01"
                      (ngModelChange)="onDateToChange1($event)"
                      [(ngModel)]="dateRange1.date_to"
                      class="icon-mat"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="eventDateTo1"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #eventDateTo1></mat-datepicker>

                    <mat-error *ngIf="formDates.controls['date_to1'].invalid"
                      >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
                    </mat-error>
                  </mat-form-field>

                  <button
                    style="margin-right: 16px"
                    mat-icon-button
                    [matMenuTriggerFor]="menuFrom1"
                  >
                    <mat-icon class="icon-mat">more</mat-icon>
                  </button>

                  <mat-menu style="margin-right: 16px" #menuFrom1="matMenu">
                    <button mat-menu-item (click)="onSelectToday1()">
                      {{ "GENERIC.TODAY" | translate }}
                    </button>
                    <button mat-menu-item (click)="onSelectThisWeek1()">
                      {{ "GENERIC.WEEK" | translate }}
                    </button>
                    <button mat-menu-item (click)="onSelectThisMonth1()">
                      {{ "GENERIC.MONTH" | translate }}
                    </button>
                  </mat-menu>
                </div>

                <button
                  mat-raised-button
                  color="primary"
                  [matMenuTriggerFor]="assignmentsReportMenu"
                >
                  <mat-icon class="icon-mat s-28">{{
                    getIcon("REPORT")
                  }}</mat-icon>
                  <span style="text-wrap: wrap">{{
                    "EMPLOYEES.GENERATE_ASSIGNMENTS_REPORT" | translate
                  }}</span>
                </button>

                <mat-menu #assignmentsReportMenu="matMenu">
                  <button
                    mat-menu-item
                    *ngxPermissionsOnly="rolesService.RH_ROLES"
                    [matMenuTriggerFor]="assignmentsReportMenuByJobFunctions"
                  >
                    {{
                      "EMPLOYEES.GENERATE_ASSIGNMENTS_REPORT_BY_JOB" | translate
                    }}
                  </button>
                  <button
                    mat-menu-item
                    [matMenuTriggerFor]="assignmentsReportMenuByDates"
                  >
                    {{
                      "EMPLOYEES.GENERATE_ASSIGNMENTS_REPORT_BY_DATES"
                        | translate
                    }}
                  </button>
                </mat-menu>

                <mat-menu #assignmentsReportMenuByJobFunctions="matMenu">
                  <button
                    mat-menu-item
                    *ngIf="fileService.printEnabled()"
                    [matMenuTriggerFor]="
                      assignmentsReportMenuByJobFunctionsPrint
                    "
                  >
                    {{ "GENERIC.PRINT" | translate }}
                  </button>
                  <button
                    mat-menu-item
                    [matMenuTriggerFor]="
                      assignmentsReportMenuByJobFunctionsDownload
                    "
                  >
                    {{ "GENERIC.DOWNLOAD" | translate }}
                  </button>
                  <button
                    mat-menu-item
                    [matMenuTriggerFor]="
                      assignmentsReportMenuByJobFunctionsSend
                    "
                  >
                    {{ "GENERIC.SEND" | translate }}
                  </button>
                </mat-menu>

                <mat-menu #assignmentsReportMenuByJobFunctionsPrint="matMenu">
                  <!-- <button mat-menu-item (click)="onPrintReportByJob('assignment/report-assignment-schedule-per-job-function', 'letter')">{{ "EMPLOYEES.LETTER_FORMAT" | translate }}</button>
                                        <button mat-menu-item (click)="onPrintReportByJob('assignment/report-assignment-schedule-per-job-function', 'legal')">{{ "EMPLOYEES.LEGAL_FORMAT" | translate }}</button> -->
                  <button
                    mat-menu-item
                    (click)="
                      menuReportsService.buildAndRunVo(
                        ReportsEnum.REPORT_ASSIGNMENT_SCHEDULE_PER_JOB_FUNCTION,
                        DistributionModeEnum.FILE,
                        ExportFormatEnum.PDF,
                        ExportToEnum.PRINT,
                        LocaleEnum.FR_CA,
                        PaperEnum.LETTER,
                        employeeReportModel
                      )
                    "
                  >
                    {{ "EMPLOYEES.LETTER_FORMAT" | translate }}
                  </button>
                  <button
                    mat-menu-item
                    (click)="
                      menuReportsService.buildAndRunVo(
                        ReportsEnum.REPORT_ASSIGNMENT_SCHEDULE_PER_JOB_FUNCTION,
                        DistributionModeEnum.FILE,
                        ExportFormatEnum.PDF,
                        ExportToEnum.PRINT,
                        LocaleEnum.FR_CA,
                        PaperEnum.LEGAL,
                        employeeReportModel
                      )
                    "
                  >
                    {{ "EMPLOYEES.LEGAL_FORMAT" | translate }}
                  </button>
                </mat-menu>

                <mat-menu
                  #assignmentsReportMenuByJobFunctionsDownload="matMenu"
                >
                  <!-- <button mat-menu-item (click)="onDownloadReportByJob('assignment/report-assignment-schedule-per-job-function', 'letter')">{{ "EMPLOYEES.LETTER_FORMAT" | translate }}</button>
                                        <button mat-menu-item (click)="onDownloadReportByJob('assignment/report-assignment-schedule-per-job-function', 'legal')">{{ "EMPLOYEES.LEGAL_FORMAT" | translate }}</button> -->
                  <button
                    mat-menu-item
                    (click)="
                      menuReportsService.buildAndRunVo(
                        ReportsEnum.REPORT_ASSIGNMENT_SCHEDULE_PER_JOB_FUNCTION,
                        DistributionModeEnum.FILE,
                        ExportFormatEnum.PDF,
                        ExportToEnum.DOWNLOAD,
                        LocaleEnum.FR_CA,
                        PaperEnum.LETTER,
                        employeeReportModel
                      )
                    "
                  >
                    {{ "EMPLOYEES.LETTER_FORMAT" | translate }}
                  </button>
                  <button
                    mat-menu-item
                    (click)="
                      menuReportsService.buildAndRunVo(
                        ReportsEnum.REPORT_ASSIGNMENT_SCHEDULE_PER_JOB_FUNCTION,
                        DistributionModeEnum.FILE,
                        ExportFormatEnum.PDF,
                        ExportToEnum.DOWNLOAD,
                        LocaleEnum.FR_CA,
                        PaperEnum.LEGAL,
                        employeeReportModel
                      )
                    "
                  >
                    {{ "EMPLOYEES.LEGAL_FORMAT" | translate }}
                  </button>
                </mat-menu>

                <mat-menu #assignmentsReportMenuByJobFunctionsSend="matMenu">
                  <button mat-menu-item (click)="onSendReportByJob('letter')">
                    {{ "EMPLOYEES.LETTER_FORMAT" | translate }}
                  </button>
                  <button mat-menu-item (click)="onSendReportByJob('legal')">
                    {{ "EMPLOYEES.LEGAL_FORMAT" | translate }}
                  </button>
                </mat-menu>

                <mat-menu #assignmentsReportMenuByDates="matMenu">
                  <button
                    mat-menu-item
                    *ngIf="fileService.printEnabled()"
                    [matMenuTriggerFor]="assignmentsReportMenuByDatesPrint"
                  >
                    {{ "GENERIC.PRINT" | translate }}
                  </button>
                  <button
                    mat-menu-item
                    [matMenuTriggerFor]="assignmentsReportMenuByDatesDownload"
                  >
                    {{ "GENERIC.DOWNLOAD" | translate }}
                  </button>
                  <button
                    mat-menu-item
                    [matMenuTriggerFor]="assignmentsReportMenuByDatesSend"
                  >
                    {{ "GENERIC.SEND" | translate }}
                  </button>
                </mat-menu>

                <mat-menu #assignmentsReportMenuByDatesPrint="matMenu">
                  <!-- <button mat-menu-item (click)="onPrintReportByDates('assignment/report-assignment-schedule-per-date', 'letter')">{{ "EMPLOYEES.LETTER_FORMAT" | translate }}</button> -->
                  <!-- <button mat-menu-item (click)="onPrintReportByDates('assignment/report-assignment-schedule-per-date', 'legal')">{{ "EMPLOYEES.LEGAL_FORMAT" | translate }}</button> -->

                  <button
                    mat-menu-item
                    (click)="
                      menuReportsService.buildAndRunVo(
                        ReportsEnum.REPORT_ASSIGNMENT_SCHEDULE_PER_DATE,
                        DistributionModeEnum.FILE,
                        ExportFormatEnum.PDF,
                        ExportToEnum.PRINT,
                        LocaleEnum.FR_CA,
                        PaperEnum.LETTER,
                        employeeReportModel
                      )
                    "
                  >
                    {{ "EMPLOYEES.LETTER_FORMAT" | translate }}
                  </button>
                  <button
                    mat-menu-item
                    (click)="
                      menuReportsService.buildAndRunVo(
                        ReportsEnum.REPORT_ASSIGNMENT_SCHEDULE_PER_DATE,
                        DistributionModeEnum.FILE,
                        ExportFormatEnum.PDF,
                        ExportToEnum.PRINT,
                        LocaleEnum.FR_CA,
                        PaperEnum.LEGAL,
                        employeeReportModel
                      )
                    "
                  >
                    {{ "EMPLOYEES.LEGAL_FORMAT" | translate }}
                  </button>
                </mat-menu>

                <mat-menu #assignmentsReportMenuByDatesDownload="matMenu">
                  <!-- <button mat-menu-item (click)="onDownloadReportByDates('assignment/report-assignment-schedule-per-date', 'letter')">{{ "EMPLOYEES.LETTER_FORMAT" | translate }}</button>
                                        <button mat-menu-item (click)="onDownloadReportByDates('assignment/report-assignment-schedule-per-date', 'legal')">{{ "EMPLOYEES.LEGAL_FORMAT" | translate }}</button>
                                     -->
                  <button
                    mat-menu-item
                    (click)="
                      menuReportsService.buildAndRunVo(
                        ReportsEnum.REPORT_ASSIGNMENT_SCHEDULE_PER_DATE,
                        DistributionModeEnum.FILE,
                        ExportFormatEnum.PDF,
                        ExportToEnum.DOWNLOAD,
                        LocaleEnum.FR_CA,
                        PaperEnum.LETTER,
                        employeeReportModel
                      )
                    "
                  >
                    {{ "EMPLOYEES.LETTER_FORMAT" | translate }}
                  </button>
                  <button
                    mat-menu-item
                    (click)="
                      menuReportsService.buildAndRunVo(
                        ReportsEnum.REPORT_ASSIGNMENT_SCHEDULE_PER_DATE,
                        DistributionModeEnum.FILE,
                        ExportFormatEnum.PDF,
                        ExportToEnum.DOWNLOAD,
                        LocaleEnum.FR_CA,
                        PaperEnum.LEGAL,
                        employeeReportModel
                      )
                    "
                  >
                    {{ "EMPLOYEES.LEGAL_FORMAT" | translate }}
                  </button>
                </mat-menu>

                <mat-menu #assignmentsReportMenuByDatesSend="matMenu">
                  <button
                    mat-menu-item
                    (click)="onSendReportByDates(PaperEnum.LETTER)"
                  >
                    {{ "EMPLOYEES.LETTER_FORMAT" | translate }}
                  </button>
                  <button
                    mat-menu-item
                    (click)="onSendReportByDates(PaperEnum.LEGAL)"
                  >
                    {{ "EMPLOYEES.LEGAL_FORMAT" | translate }}
                  </button>

                  <!-- <button mat-menu-item (click)="menuReportsService.buildAndRunVo(
                                                                                                ReportsEnum.REPORT_ASSIGNMENT_SCHEDULE_PER_DATE,
                                                                                                DistributionModeEnum.EMAIL_ATTACHMENT,
                                                                                                ExportFormatEnum.PDF,
                                                                                                ExportToEnum.DOWNLOAD,
                                                                                                LocaleEnum.FR_CA,
                                                                                                PaperEnum.LETTER,
                                                                                                employeeReportModel)">
                                                {{ "EMPLOYEES.LETTER_FORMAT" | translate }}
                                            </button>
                                            <button mat-menu-item (click)="menuReportsService.buildAndRunVo(
                                                                                                ReportsEnum.REPORT_ASSIGNMENT_SCHEDULE_PER_DATE,
                                                                                                DistributionModeEnum.EMAIL_ATTACHMENT,
                                                                                                ExportFormatEnum.PDF,
                                                                                                ExportToEnum.DOWNLOAD,
                                                                                                LocaleEnum.FR_CA,
                                                                                                PaperEnum.LEGAL,
                                                                                                employeeReportModel)">
                                                {{ "EMPLOYEES.LETTER_FORMAT" | translate }}
                                            </button> -->
                </mat-menu>
              </div>
              <!-- </div> -->
            </header>

            <div class="" fxLayout="column">
              <div
                *ngIf="
                  !isLoadingResults &&
                  dataSource &&
                  dataSource.data &&
                  dataSource.data.length === 0
                "
                fxLayout="row"
                fxLayoutAlign="center center"
                class="my-16 font-size-20 font-weight-500"
              >
                {{ "GENERIC.NO_RESULTS" | translate }}
              </div>

              <div
                *ngIf="isLoadingResults"
                fxLayout="row"
                fxLayoutAlign="center center"
              >
                <mat-spinner [diameter]="26" class="my-12"></mat-spinner>
              </div>

              <mat-table
                #tableAssignments
                class=""
                matSort
                matSortStart="desc"
                [@animateStagger]="{ value: '50' }"
                [ngStyle]="{
                  display:
                    dataSource &&
                    dataSource.data &&
                    dataSource.data.length === 0
                      ? 'none'
                      : '',
                }"
                [dataSource]="dataSource"
              >
                <!-- real_date_from Column -->
                <ng-container cdkColumnDef="real_date_from">
                  <mat-header-cell
                    fxFlex="140px"
                    *cdkHeaderCellDef
                    mat-sort-header
                  >
                    {{ "GENERIC.DATE_START" | translate }}
                  </mat-header-cell>
                  <mat-cell
                    fxFlex="140px"
                    class="no-text-cursor"
                    *cdkCellDef="let row"
                  >
                    <div>
                      {{ row.real_date_from }}
                    </div>
                  </mat-cell>
                </ng-container>

                <!-- real_date_to Column -->
                <ng-container cdkColumnDef="real_date_to">
                  <mat-header-cell
                    fxFlex="140px"
                    *cdkHeaderCellDef
                    mat-sort-header
                  >
                    {{ "GENERIC.DATE_END" | translate }}
                  </mat-header-cell>
                  <mat-cell
                    fxFlex="140px"
                    class="no-text-cursor"
                    *cdkCellDef="let row"
                  >
                    <div>
                      {{ row.real_date_to }}
                    </div>
                  </mat-cell>
                </ng-container>

                <!-- real_time_from Column -->
                <ng-container cdkColumnDef="real_time_from">
                  <mat-header-cell
                    fxFlex="160px"
                    *cdkHeaderCellDef
                    mat-sort-header
                  >
                    {{ "GENERIC.TIME" | translate }}
                  </mat-header-cell>
                  <mat-cell
                    fxFlex="160px"
                    class="no-text-cursor"
                    *cdkCellDef="let row"
                  >
                    <div *ngIf="!row.all_day">
                      {{ row.real_time_from }} - {{ row.real_time_to }}
                    </div>
                    <div *ngIf="row.all_day">
                      {{ "GENERIC.ALL_DAY" | translate }}
                    </div>
                  </mat-cell>
                </ng-container>

                <!-- job_function -->
                <ng-container cdkColumnDef="job_function">
                  <mat-header-cell fxFlex *cdkHeaderCellDef>
                    {{ "EMPLOYEES.JOB_FUNCTION" | translate }}
                  </mat-header-cell>
                  <mat-cell class="no-text-cursor" fxFlex *cdkCellDef="let row">
                    <div
                      *ngIf="
                        row.group_assignement &&
                        row.group_assignement.job_function
                      "
                    >
                      {{ row.group_assignement.job_function.name }}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="event_name">
                  <mat-header-cell fxFlex *cdkHeaderCellDef>
                    {{ "GENERIC.EVENT" | translate }}
                  </mat-header-cell>
                  <mat-cell class="no-text-cursor" fxFlex *cdkCellDef="let row">
                    <div>
                      {{ row.event.name }}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="real_duration_label_hour">
                  <mat-header-cell fxFlex="80px" *cdkHeaderCellDef>
                    {{ "GENERIC.DURATION" | translate }}
                  </mat-header-cell>
                  <mat-cell
                    fxFlex="80px"
                    class="no-text-cursor"
                    *cdkCellDef="let row"
                  >
                    <div>
                      {{ row.real_duration_label_hour }}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="note_instruction">
                  <mat-header-cell fxFlex="80px" *cdkHeaderCellDef>
                    {{ "GENERIC.NOTE" | translate }}
                  </mat-header-cell>
                  <mat-cell
                    fxFlex="80px"
                    class="no-text-cursor"
                    *cdkCellDef="let row"
                  >
                    <div class="ml-8">
                      <mat-icon
                        *ngIf="row.note_instruction"
                        matTooltip="{{
                          row.note_instruction.substring(0, noteHintMaxChar)
                        }} {{
                          row.note_instruction.length > noteHintMaxChar
                            ? '...'
                            : ''
                        }}"
                        placement="top"
                        show-delay="100"
                        class="icon-mat s-20 icon-cursor"
                      >
                        {{ getIcon("INFO") }}
                      </mat-icon>
                    </div>
                  </mat-cell>
                </ng-container>

                <mat-header-row
                  *cdkHeaderRowDef="displayedColumns"
                ></mat-header-row>
                <mat-row
                  *cdkRowDef="let row; columns: displayedColumns"
                  (click)="onNavigateToAssignment(row)"
                  matRipple
                  class="mat-row-color"
                  [@animate]="{ value: '*', params: { y: '100%' } }"
                >
                </mat-row>
              </mat-table>
              <mat-paginator
                #paginator
                [length]="resultsLength"
                (page)="datatableHelperService.onPaginator($event, 'employee')"
                [ngStyle]="{
                  display:
                    dataSource.data && dataSource.data.length === 0
                      ? 'none'
                      : '',
                }"
                showFirstLastButtons="true"
                [pageIndex]="0"
                [pageSize]="'employee' | tableSizePipe: 'short'"
                [pageSizeOptions]="datatableHelperService.pageSizeShortOptions"
              >
              </mat-paginator>
            </div>
          </div>
        </div>

        <div *ngxPermissionsOnly="rolesService.RH_ROLES">
          <div *ngIf="employee" class="profile-box info-box general">
            <div
              class="accent header-create"
              fxLayout="row"
              fxLayoutAlign="space-between center"
            >
              <div class="title">
                {{ "EMPLOYEES.JOB_FUNCTION" | translate }}
              </div>
              <div
                class="gt2-light-green"
                style="width: 60px; height: 56px"
                fxLayout="row"
                fxLayoutAlign="center center"
              >
                <button
                  type="button"
                  matTooltip="{{ 'EMPLOYEES.ADD_JOB_FUNCTION' | translate }}"
                  mat-icon-button
                  class=""
                  (click)="onAddNewJobFunction()"
                >
                  <mat-icon class="icon-mat-black" color="">add</mat-icon>
                </button>
              </div>
            </div>

            <div class="">
              <div
                *ngIf="jobsFunctions.dataSource && jobFunctionEmpty"
                fxLayout="row"
                fxLayoutAlign="center start"
                style="padding-left: 16px; padding-right: 16px"
                class="py-16 mat-body-2"
              >
                <div *ngxPermissionsOnly="rolesService.RH_ROLES">
                  <p style="text-align: center">
                    {{ "EMPLOYEES.NO_JOB_FUNCTIONS_HR" | translate }}
                  </p>
                </div>
                <div *ngxPermissionsExcept="rolesService.RH_ROLES">
                  <p style="text-align: center">
                    {{ "EMPLOYEES.NO_JOB_FUNCTIONS" | translate }}
                  </p>
                </div>
              </div>
              <mat-table
                #table
                *ngIf="!jobFunctionEmpty"
                class="jobs-table"
                [dataSource]="jobsFunctions.dataSource"
              >
                <!-- Nom Column -->
                <ng-container cdkColumnDef="name">
                  <mat-header-cell fxFlex *cdkHeaderCellDef
                    >{{ "EMPLOYEES.FUNCTION_NAME" | translate }}
                  </mat-header-cell>
                  <mat-cell fxFlex class="no-text-cursor" *cdkCellDef="let job">
                    <p class="text-truncate">
                      {{ job.name }}
                    </p>
                  </mat-cell>
                </ng-container>

                <!-- hourly_wage Column -->
                <ng-container cdkColumnDef="salary">
                  <mat-header-cell fxFlex *cdkHeaderCellDef
                    >{{ "EMPLOYEES.HOURLY_WAGE" | translate }}
                  </mat-header-cell>
                  <mat-cell fxFlex class="no-text-cursor" *cdkCellDef="let job">
                    <p *ngIf="!editMode" class="text-truncate">
                      {{ job.hourly_wage_label }}
                    </p>

                    <div *ngIf="editMode" class="">
                      <mat-form-field floatLabel="auto">
                        <input
                          matInput
                          type="text"
                          placeholder=""
                          pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
                          autocomplete="nope"
                          [ngModel]="job.hourly_wage"
                          (ngModelChange)="job.hourly_wage = $event"
                          (blur)="onHourlyWageFocusOut(job)"
                        />
                        <!-- TODO: ERROR HINT -->
                      </mat-form-field>
                      <!--</div>-->
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actionsColumn">
                  <mat-header-cell
                    *cdkHeaderCellDef
                    fxFlex="50px"
                    style="padding: 0; margin: 0"
                  >
                  </mat-header-cell>
                  <mat-cell
                    *cdkCellDef="let row"
                    class="no-text-cursor"
                    fxFlex="50px"
                    style="padding: 0; margin: 0"
                  >
                    <button
                      *ngIf="editMode"
                      mat-icon-button
                      color="primary"
                      type="button"
                      focusable="false"
                      (click)="onDeleteJobFunction(row)"
                    >
                      <span
                        matTooltip="{{
                          'EMPLOYEES.REMOVE_JOB_FUNCTION' | translate
                        }}"
                        [matTooltipPosition]="'left'"
                      >
                        <mat-icon color="primary">delete</mat-icon>
                      </span>
                    </button>
                  </mat-cell>
                </ng-container>

                <mat-header-row
                  *cdkHeaderRowDef="displayedJobsFunctionsColumns"
                ></mat-header-row>
                <mat-row
                  *cdkRowDef="let job; columns: displayedJobsFunctionsColumns"
                  class="mat-row-color"
                  matRipple
                >
                </mat-row>
              </mat-table>
            </div>
          </div>
        </div>

        <div
          *ngIf="employee"
          class="profile-box info-box general mt-12"
          fxLayout="column"
        >
          <header class="accent">
            <div class="title">{{ "GENERIC.CONTACT" | translate }}</div>
          </header>

          <mat-tab-group
            animationDuration="100ms"
            mat-stretch-tabs="false"
            mat-align-tabs="start"
          >
            <mat-tab label="{{ 'GENERIC.GENERAL' | translate }}">
              <app-contact
                #contactComponent
                [useAutosave]="false"
                [groupModel]="employee.contact"
                [editMode]="false"
                [useAutosave]="false"
                [hideSaveButton]="true"
                class=""
              ></app-contact>
            </mat-tab>

            <mat-tab label="{{ 'GENERIC.ADDRESS' | translate }}">
              <app-address
                #addressComponent
                [useAutosave]="false"
                [groupModel]="employee.address"
                [isVisible]="true"
                [editMode]="false"
                [useAutosave]="false"
                class=""
              ></app-address>
            </mat-tab>
          </mat-tab-group>
        </div>

        <!-- Employee attached signature -->
        <div *ngIf="employee" class="profile-box info-box general">
          <div
            class="accent header-create"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            style="height: 56px"
          >
            <div fxLayout="row" fxLayoutAlign="center center">
              <div class="title">{{ "GENERIC.FILES" | translate }}</div>
              <button
                mat-icon-button
                matTooltip="{{ 'GENERIC.SELECT_IMG_RULES' | translate }}"
                type="button"
              >
                <mat-icon class="s-20 icon-mat">info</mat-icon>
              </button>
            </div>
            <div
              class="gt2-light-green"
              style="width: 60px; height: 56px"
              fxLayout="row"
              fxLayoutAlign="center center"
            >
              <button
                type="button"
                matTooltip="{{ 'GENERIC.ATTACH_FILE' | translate }}"
                mat-icon-button
                class=""
                (click)="employeeAttachSignature.onAttachDocument()"
              >
                <mat-icon class="icon-mat-black" color="">add</mat-icon>
              </button>
            </div>
          </div>

          <div class="">
            <app-employee-attached-signature
              [employeeUUID]="employee.uuid"
              #employeeAttachSignature
            ></app-employee-attached-signature>
          </div>
        </div>

        <div *ngxPermissionsOnly="rolesService.RH_ROLES">
          <div
            *ngIf="employee"
            class="profile-box info-box general"
            fxLayout="column"
          >
            <header class="accent">
              <div class="title">{{ "GENERIC.NOTES" | translate }}</div>
            </header>

            <div class="note-section">
              <p-editor
                #noteEditor
                [(ngModel)]="employee.note"
                [readonly]="!editMode"
                [style]="{
                  height: '120px',
                  width: '100%',
                  'background-color': editMode ? '#ffffff' : '',
                }"
              >
                <ng-template pTemplate="header" class="">
                  <span class="ql-formats">
                    <select class="ql-font"></select>
                    <select class="ql-size"></select>
                  </span>

                  <span class="ql-formats">
                    <button class="ql-bold"></button>
                    <button class="ql-italic"></button>
                    <button class="ql-underline"></button>
                    <button class="ql-strike"></button>
                  </span>

                  <span class="ql-formats">
                    <select class="ql-color"></select>
                    <select class="ql-background"></select>
                  </span>

                  <span class="ql-formats">
                    <button class="ql-list" value="ordered"></button>
                    <button class="ql-list" value="bullet"></button>
                    <button class="ql-indent" value="-1"></button>
                    <button class="ql-indent" value="+1"></button>
                  </span>
                </ng-template>
              </p-editor>
              <mat-error
                *ngIf="noteInvalid"
                fxLayout="row"
                fxLayoutAlign="end center"
                class="p-4 pr-8"
              >
                {{ "GENERIC.NOTE_EDITOR_LENGTH_INVALID_2048" | translate }}
              </mat-error>
            </div>
          </div>
        </div>

        <div *ngxPermissionsOnly="rolesService.RH_ROLES">
          <app-comment-section
            *ngIf="employee"
            [commentsModel]="employee"
            [serviceAPIUrl]="'/v1/employees/comments/' + employee.uuid"
          >
          </app-comment-section>
        </div>

        <app-created-updated-at
          *ngIf="employee"
          [historicModel]="employee.historic_model"
        >
        </app-created-updated-at>

        <div *ngxPermissionsOnly="rolesService.RH_ROLES">
          <div
            *ngIf="employee"
            fxFlex="100%"
            fxLayout="row"
            fxLayoutAlign="center center"
            class="mt-8"
          >
            <button mat-raised-button (click)="deleteEmployee()" class="warn">
              {{ "GENERIC.DELETE" | translate }}
            </button>
          </div>
        </div>
      </div>
      <!-- / CONTENT -->
    </div>
    <!-- / CENTER -->
  </div>
</div>

<div *ngIf="employee" fxLayout="row" fxLayoutAlign="end start">
  <div *ngxPermissionsOnly="rolesService.RH_ROLES">
    <div class="fixed-button">
      <!-- <button mat-fab class="gt2-edit-button" id="edit-employee-button" (click)="onEdit($event)"
                [disabled]="loading" *fuseIfOnDom [@animate]="{value:'*', params:{delay:'300ms',scale:'.2'}}">
                <mat-icon *ngIf="!editMode">{{ getIcon('EDIT_FAB') }}</mat-icon>
                <mat-icon *ngIf="editMode">{{ getIcon('UNDO') }}</mat-icon>
            </button> -->

      <button
        mat-fab
        #saveButton
        [ngClass]="editMode ? 'gt2-save-button' : 'gt2-edit-button'"
        id="edit-employee-button"
        (click)="onEdit($event)"
        [disabled]="
          loading || (editMode && (!form.valid || !contactComponent.form.valid))
        "
        *fuseIfOnDom
        [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }"
      >
        <mat-icon class="icon-mat-black" *ngIf="!editMode && !loading">{{
          getIcon("EDIT_FAB")
        }}</mat-icon>
        <mat-icon
          *ngIf="editMode && !loading && !saveButton.disabled"
          matTooltip="{{ 'GENERIC.SAVE' | translate }}"
          position="left"
          showDelay="900"
          class="icon-mat-black"
          hideDelay="900"
          >{{ getIcon("SAVE") }}</mat-icon
        >
        <mat-icon
          *ngIf="editMode && !loading && saveButton.disabled"
          matTooltip="{{ 'GENERIC.FORM_DIRTY_TIP' | translate }}"
          position="left"
          class="icon-mat-black"
          hideDelay="900"
          >{{ getIcon("SAVE") }}</mat-icon
        >
        <i [ngClass]="loading ? 'fa fa-spinner fa-spin' : ''"></i>
      </button>
    </div>

    <div class="fixed-button2">
      <button
        mat-fab
        *ngIf="editMode"
        matTooltip="{{ 'GENERIC.CANCEL' | translate }}"
        position="left"
        showDelay="900"
        hideDelay="900"
        id="cancel-edit-employee-button"
        class="gt2-cancel-edit-button"
        (click)="onCancelEdit($event)"
        [disabled]="loading"
        [@animate]="{ value: '*', params: { delay: '120ms', scale: '.2' } }"
      >
        <mat-icon class="icon-mat s-24">{{ getIcon("CANCEL") }}</mat-icon>
      </button>
    </div>
  </div>
</div>
