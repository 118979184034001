import { RolesService } from './../../services/roles.service';
import { ModulesService } from '../../services/modules.service';
import { ReportFactoryService } from './../../reports/report-factory.service';
import { ReportsService } from './../../reports/reports.service';
import { MenuReportsService } from './../../reports/menu-reports.service';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GT2PageAbstract } from '../abstract/GT2PageAbstract';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { IconsService } from '../../services/icons.service';
import { NGXLogger } from 'ngx-logger';
import { Gt2ApiService } from '../../api/gt2-api.service';
import { PrintService } from '../../../services/print.service';
import { FileService } from '../../../services/file.service';
import { ApiRoutes } from '../../api/ApiRoutes';
import moment from 'moment';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ValidatorHelper } from '../../validators/ValidatorHelper';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { GT2DateAdapter } from '../../utils/GT2DateAdapter';
import { Title } from '@angular/platform-browser';
import { CatererService } from '../caterer/caterer.service';
import { CrudOrganizationService } from '../crud/crud-organization/crud-organization.service';
import { NgxTimepickerFieldComponent } from 'ngx-material-timepicker';
import { GenericAlertComponent } from '../../components/generic-alert/generic-alert.component';
import { ActivatedRoute } from '@angular/router';
import { CalendarDateFormatter } from 'angular-calendar';
import { CustomDateFormatter } from '../calendar/CustomDateFormatter';

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: GT2DateAdapter },
    { provide: CalendarDateFormatter, useClass: CustomDateFormatter },
  ],
})
export class ManagementComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  static DATE_FROM_KEY_PROD: string = 'managementDateFromProd_001';
  static DATE_TO_KEY_PROD: string = 'managementDateToProd_001';
  static DATE_FROM_KEY_LOGISTIC: string = 'managementDateFromLogistic_001';
  static DATE_TO_KEY_LOGISTIC: string = 'managementDateToLogistic_001';
  static DATE_FROM_KEY_EVENT: string = 'managementDateFromEvent_001';
  static DATE_TO_KEY_EVENT: string = 'managementDateToEvent_001';
  static DATE_FROM_KEY_ORDERS_DAY: string = 'managementDateFromOrdersDay_001';
  static DATE_FROM_KEY_EMPLOYEE: string = 'managementDateFromEmployee_001';
  static DATE_TO_KEY_EMPLOYEE: string = 'managementDateToEmployee_001';
  static DATE_FROM_KEY_CONGRESS: string = 'managementDateFromCongress_001';
  static DATE_TO_KEY_CONGRESS: string = 'managementDateToCongress_001';

  static DATE_FROM_KEY_ORDERS_PURCHASE_ORDERS: string =
    'managementDateFromOrdersAndPurchaseOrders_001';
  static DATE_TO_KEY_ORDERS_PURCHASE_ORDERS: string =
    'managementDateTOOrdersAndPurchaseOrders_001';

  static DATE_TO_KEY_ORDERS_DAY: string = 'managementDateToEventOrdersDay_001';

  dateRangeProd: any;
  dateRangeLogistic: any;
  dateRangeEvent: any;
  dateRangeOrdersDay: any;
  dateRangeEmployee: any;
  dateRangeCongress: any;
  dateRangeOrderAndPurchaseOrder: any;
  form: UntypedFormGroup;
  dateFormat = 'YYYY-MM-DD'; // YYYY-MM-DD hh:mm
  timeFormat = 'HH:mm';
  @ViewChild('ngxTimeStart')
  ngxTimeStart?: NgxTimepickerFieldComponent;
  confirmDialogRef: any;
  constructor(
    public translate: TranslateService,
    public iconsService: IconsService,
    public logger: NGXLogger,
    private dialog: MatDialog,
    private api: Gt2ApiService,
    private route: ActivatedRoute,
    public print: PrintService,
    public fileService: FileService,
    private formBuilder: UntypedFormBuilder,
    private adapter: DateAdapter<any>,
    private titleService: Title,
    public catererService: CatererService,
    private translationLoader: FuseTranslationLoaderService,
    public reportsService: ReportsService,
    public menuReportsService: MenuReportsService,
    public reportFactoryService: ReportFactoryService,
    public modulesService: ModulesService,
    public rolesService: RolesService,
    public orgService: CrudOrganizationService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    this.adapter.setLocale(this.translate.currentLang);

    // Production
    const dateFromProd = localStorage.getItem(
      ManagementComponent.DATE_FROM_KEY_PROD,
    );
    const dateToProd = localStorage.getItem(
      ManagementComponent.DATE_TO_KEY_PROD,
    );
    this.dateRangeProd = {
      date_from: dateFromProd
        ? moment(dateFromProd).format(this.dateFormat)
        : moment().startOf('month').format(this.dateFormat),
      date_to: dateToProd
        ? moment(dateToProd).format(this.dateFormat)
        : moment().endOf('month').format(this.dateFormat),
      key_from: ManagementComponent.DATE_FROM_KEY_PROD,
      key_to: ManagementComponent.DATE_TO_KEY_PROD,
    };

    // Logistic
    const dateFromLogistic = localStorage.getItem(
      ManagementComponent.DATE_FROM_KEY_LOGISTIC,
    );
    const dateToLogistic = localStorage.getItem(
      ManagementComponent.DATE_TO_KEY_LOGISTIC,
    );
    this.dateRangeLogistic = {
      date_from: dateFromLogistic
        ? moment(dateFromLogistic).format(this.dateFormat)
        : moment().startOf('month').format(this.dateFormat),
      date_to: dateToLogistic
        ? moment(dateToLogistic).format(this.dateFormat)
        : moment().endOf('month').format(this.dateFormat),
      key_from: ManagementComponent.DATE_FROM_KEY_LOGISTIC,
      key_to: ManagementComponent.DATE_TO_KEY_LOGISTIC,
    };

    // Events
    const dateFromEvent = localStorage.getItem(
      ManagementComponent.DATE_FROM_KEY_EVENT,
    );
    const dateToEvent = localStorage.getItem(
      ManagementComponent.DATE_TO_KEY_EVENT,
    );
    this.dateRangeEvent = {
      date_from: dateFromEvent
        ? moment(dateFromEvent).format(this.dateFormat)
        : moment().startOf('month').format(this.dateFormat),
      date_to: dateToEvent
        ? moment(dateToEvent).format(this.dateFormat)
        : moment().endOf('month').format(this.dateFormat),
      key_from: ManagementComponent.DATE_FROM_KEY_EVENT,
      key_to: ManagementComponent.DATE_TO_KEY_EVENT,
    };

    // Orders Day
    const dateFromOrdersDay = localStorage.getItem(
      ManagementComponent.DATE_FROM_KEY_ORDERS_DAY,
    );
    const dateToOrdersDay = localStorage.getItem(
      ManagementComponent.DATE_TO_KEY_ORDERS_DAY,
    );
    this.dateRangeOrdersDay = {
      date_from: dateFromOrdersDay
        ? moment(dateFromOrdersDay).format(this.dateFormat)
        : moment().startOf('month').format(this.dateFormat),
      // date_to: dateToOrdersDay
      //     ? moment(dateToOrdersDay).format(this.dateFormat)
      //     : moment().endOf("month").format(this.dateFormat),
      key_from: ManagementComponent.DATE_FROM_KEY_ORDERS_DAY,
      // key_to: ManagementComponent.DATE_TO_KEY_ORDERS_DAY,
    };

    // congress
    // dateRangeCongress;
    const dateFromCongress = localStorage.getItem(
      ManagementComponent.DATE_FROM_KEY_CONGRESS,
    );
    const dateToCongress = localStorage.getItem(
      ManagementComponent.DATE_TO_KEY_CONGRESS,
    );
    this.dateRangeCongress = {
      date_from: dateFromCongress
        ? moment(dateFromCongress).format(this.dateFormat)
        : moment().startOf('month').format(this.dateFormat),
      date_to: dateToCongress
        ? moment(dateToCongress).format(this.dateFormat)
        : moment().endOf('month').format(this.dateFormat),
      /*event_date_from: dateFromCongress
                ? moment(dateFromCongress).format(this.dateFormat)
                : moment().startOf("month").format(this.dateFormat),
            event_date_to: dateToCongree
                ? moment(dateFromCongress).format(this.dateFormat)
                : moment().endOf("month").format(this.dateFormat),
            */
      time_from: moment().format(this.timeFormat),
      time_to: moment().format(this.timeFormat),

      key_from: ManagementComponent.DATE_FROM_KEY_CONGRESS,
      key_to: ManagementComponent.DATE_TO_KEY_CONGRESS,
    };

    //dateRangeOrderAndPurchaseOrder
    const dateFromOrderAndPurchaseOrder = localStorage.getItem(
      ManagementComponent.DATE_FROM_KEY_ORDERS_PURCHASE_ORDERS,
    );
    const dateToOrderAndPurchaseOrder = localStorage.getItem(
      ManagementComponent.DATE_TO_KEY_ORDERS_PURCHASE_ORDERS,
    );
    this.dateRangeOrderAndPurchaseOrder = {
      date_from: dateFromOrderAndPurchaseOrder
        ? moment(dateFromOrderAndPurchaseOrder).format(this.dateFormat)
        : moment().startOf('month').format(this.dateFormat),
      date_to: dateFromOrderAndPurchaseOrder
        ? moment(dateFromOrderAndPurchaseOrder).format(this.dateFormat)
        : moment().endOf('month').format(this.dateFormat),
      key_from: ManagementComponent.DATE_FROM_KEY_ORDERS_PURCHASE_ORDERS,
      key_to: ManagementComponent.DATE_TO_KEY_ORDERS_PURCHASE_ORDERS,
    };

    // Employees
    const dateFromEmployee = localStorage.getItem(
      ManagementComponent.DATE_FROM_KEY_EMPLOYEE,
    );
    const dateToEmployee = localStorage.getItem(
      ManagementComponent.DATE_TO_KEY_EMPLOYEE,
    );
    this.dateRangeEmployee = {
      date_from: dateFromEmployee
        ? moment(dateFromEmployee).format(this.dateFormat)
        : moment().startOf('month').format(this.dateFormat),
      date_to: dateToEmployee
        ? moment(dateToEmployee).format(this.dateFormat)
        : moment().endOf('month').format(this.dateFormat),
      key_from: ManagementComponent.DATE_FROM_KEY_EMPLOYEE,
      key_to: ManagementComponent.DATE_TO_KEY_EMPLOYEE,
    };

    this.form = this.formBuilder.group({
      date_from_prod: ['', [Validators.required]],
      date_to_prod: ['', [Validators.required]],
      date_from_logistic: ['', [Validators.required]],
      date_to_logistic: ['', [Validators.required]],
      date_from_event: ['', [Validators.required]],
      date_to_event: ['', [Validators.required]],
      date_from_orders_day: ['', [Validators.required]],
      date_to_orders_day: ['', [Validators.required]],
      date_from_employee: ['', [Validators.required]],
      date_to_employee: ['', [Validators.required]],
      date_from_congress: ['', [Validators.required]],
      date_to_congress: ['', [Validators.required]],
      date_from_event_changelog: ['', [Validators.required]],
      date_to_event_changelog: ['', [Validators.required]],
      date_to_orderAndPurchaseOrder: ['', [Validators.required]],
    });

    //this.logger.info("ManagementComponent.constructor() -> dateRange: " + JSON.stringify(this.dateRangeProd));
  }

  ngOnInit() {
    //this.titleService.setTitle(this.translate.instant("MANAGEMENT.TITLE"));
  }

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public setDateFrom(range: any): void {
    // range.date_from;
    localStorage.setItem(range.key_from, range.date_from);
    // //this.logger.info("ManagementComponent.setDateFrom() -> *** dateFrom: " + localStorage.getItem(ManagementComponent.DATE_FROM_KEY));
  }

  public setDateTo(range: any): void {
    localStorage.setItem(range.key_to, range.date_to);
    // //this.logger.info("ManagementComponent.setDateFrom() -> *** dateTo: " + localStorage.getItem(ManagementComponent.DATE_TO_KEY));
  }

  // public setDateFrom(dateFrom: string): void {
  //     localStorage.setItem(ManagementComponent.DATE_FROM_KEY, dateFrom);
  //     // //this.logger.info("ManagementComponent.setDateFrom() -> *** dateFrom: " + localStorage.getItem(ManagementComponent.DATE_FROM_KEY));
  // }

  // public setDateTo(dateTo: string): void {
  //     localStorage.setItem(ManagementComponent.DATE_TO_KEY, dateTo);
  //     // //this.logger.info("ManagementComponent.setDateFrom() -> *** dateTo: " + localStorage.getItem(ManagementComponent.DATE_TO_KEY));
  // }

  public onSelectToday(range: any): void {
    const today: any = moment().format(this.dateFormat);
    range.date_from = today;
    range.date_to = today;
    // //this.logger.info("ManagementComponent.onSelectToday() -> totday: " + today);
  }

  public onSelectThisWeek(range: any): void {
    // //this.logger.info("ManagementComponent.onSelectThisWeek()");
    const dateFrom: any = moment().startOf('isoWeek').format(this.dateFormat);
    const dateTo: any = moment().endOf('isoWeek').format(this.dateFormat);
    range.date_from = dateFrom;
    range.date_to = dateTo;
  }

  public onSelectThisMonth(range: any): void {
    // //this.logger.info("ManagementComponent.onSelectThisMonth()");
    const dateFrom: any = moment().startOf('month').format(this.dateFormat);
    const dateTo: any = moment().endOf('month').format(this.dateFormat);
    range.date_from = dateFrom;
    range.date_to = dateTo;
  }
  public onSelectTodayEvent(range: any): void {
    const today: any = moment().format(this.dateFormat);
    range.event_date_from = today;
    range.event_date_to = today;
    // //this.logger.info("ManagementComponent.onSelectToday() -> totday: " + today);
  }

  public onSelectThisWeekEvent(range: any): void {
    // //this.logger.info("ManagementComponent.onSelectThisWeek()");
    const dateFrom: any = moment().startOf('isoWeek').format(this.dateFormat);
    const dateTo: any = moment().endOf('isoWeek').format(this.dateFormat);
    range.event_date_from = dateFrom;
    range.event_date_to = dateTo;
  }

  public onSelectThisEvent(range: any): void {
    // //this.logger.info("ManagementComponent.onSelectThisMonth()");
    const dateFrom: any = moment().startOf('month').format(this.dateFormat);
    const dateTo: any = moment().endOf('month').format(this.dateFormat);
    range.event_date_from = dateFrom;
    range.event_date_to = dateTo;
  }

  public validatorDateBeforeThan(): any {
    // //this.logger.info("ManagementComponent.validatorDateBeforeThan() -> this.dateRange.date_to: " + this.dateRange.date_to);
    return ValidatorHelper.dateBeforeThan(this.dateRangeProd.date_to);
  }

  public onDateFromChange(event: any, range: any): void {
    // //this.logger.info("ManagementComponent.onDateFromChange() -> event: " + event);
    range.date_from = moment(event).format(this.dateFormat);
    this.setDateFrom(range);
    // //this.logger.info("ManagementComponent.onDateFromChange() -> this.dateRange: " + JSON.stringify(this.dateRange));
  }

  public onEventDateFromChange(event: any, range: any): void {
    ////this.logger.info("ManagementComponent.onEventDateFromChange() -> event: " + event);
    range.event_date_from = moment(event).format(this.dateFormat);
  }

  public onEventDateToChange(event: any, range: any): void {
    ////this.logger.info("ManagementComponent.onEventDateToChange() -> event: " + event);
    range.event_date_to = moment(event).format(this.dateFormat);
  }

  public onTimeFromChange(event: any, range: any): void {
    ////this.logger.info(
    //    "ManagementComponent.onTimeFromChange() -> event: " + event
    //);
    range.time_from = event;
    /*//this.logger.info(
            "ManagementComponent.onTimeFromChange() -> this.dateRange: " +
                JSON.stringify(this.dateRangeCongress)
        );*/
  }

  public onTimeToChange(event: any, range: any): void {
    /*//this.logger.info(
            "ManagementComponent.onTimeToChange() -> event: " + event
        );*/
    range.time_to = event;
    //this.logger.info("ManagementComponent.onTimeToChange() -> this.dateRange: " + JSON.stringify(this.dateRangeCongress));
  }

  public onDateToChange(event: any, range: any): void {
    /*//this.logger.info(
            "ManagementComponent.onDateToChange() -> event: " + event
        );*/
    range.date_to = moment(event).format(this.dateFormat);
    this.setDateTo(range);
  }

  // public onDateFromChange(event: any): void {
  //     // //this.logger.info("ManagementComponent.onDateFromChange() -> event: " + event);
  //     this.dateRangeProd.date_from = moment(event).format(this.dateFormat);
  //     this.setDateFrom(this.dateRangeProd.date_from);
  //     // //this.logger.info("ManagementComponent.onDateFromChange() -> this.dateRange: " + JSON.stringify(this.dateRange));
  // }

  // public onDateToChange(event: any): void {
  //     //this.logger.info(
  //         "ManagementComponent.onDateToChange() -> event: " + event
  //     );
  //     this.dateRangeProd.date_to = moment(event).format(this.dateFormat);
  //     this.setDateTo(this.dateRangeProd.date_to);
  //     //this.logger.info(
  //         "ManagementComponent.onDateToChange() -> this.dateRange: " +
  //             JSON.stringify(this.dateRangeProd)
  //     );
  // }

  // TODO: CHANGE / DYNAMIZE RANGE
  public printPost(url: string, range: any): void {
    // //this.logger.info("ManagementComponent.printPost() -> url: " + url);
    this.print.postPrintHTML(url, range).subscribe({
      next: (response: any) => {
        // //this.logger.info("ManagementComponent.printPost() -> SUCCESS: " + JSON.stringify(response));
      },
      error: (error: any) => {
        this.logger.error(
          'ManagementComponent.printPost() -> ERROR: ' + JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  // TODO: CHANGE / DYNAMIZE RANGE
  public downloadPost(url: string, range: any): void {
    // //this.logger.info("ManagementComponent.downloadPost() -> url: " + url);
    this.fileService.postDownloadFile(url, range).subscribe({
      next: (response) => {
        // //this.logger.info("ManagementComponent.downloadPost() -> SUCCESS: " + JSON.stringify(response));
      },
      error: (error) => {
        this.logger.error(
          'downloadPost.downloadPost() -> ERROR: ' + JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  //
  // public onPrintProductionZoneList(format: string = "a4"): void {
  //     //this.logger.info("ManagementComponent.onPrintProductionZoneList()");
  //     const url: string = this.api.createReportURL(
  //         ApiRoutes.REPORT_PRINT,
  //         "mngt/report-mngt-prod-list-by-prod-zone",
  //         "fr-CA"
  //     );

  //     // const url: string = this.api.createReportURL(ApiRoutes.REPORT_PDF, "mngt/report-mngt-prod-list-by-prod-zone", (format + "/fr-CA"));
  //     // const url= "https://api.dev.gestion-traiteur.app/maintenance";
  //     this.printPost(url);
  // }

  public onPrintProductionZoneList(format: string = 'a4'): void {
    // //this.logger.info("ManagementComponent.onPrintProductionZoneList()");

    // //this.logger.info(
    //     "ManagementComponent.onPrintProductionZoneList() -> " +
    //         JSON.stringify(this.dateRangeProd)
    // );
    const url: string = this.api.createReportURLWithPaper(
      ApiRoutes.REPORT_PRINT_WITH_PAPER,
      'mngt/report-mngt-prod-list-by-prod-zone',
      format,
      'fr-CA',
    );

    // const url: string = this.api.createReportURL(ApiRoutes.REPORT_PDF, "mngt/report-mngt-prod-list-by-prod-zone", (format + "/fr-CA"));
    // const url= "https://api.dev.gestion-traiteur.app/maintenance";
    this.printPost(url, this.dateRangeProd);
  }

  public onDownloadProductionZoneList(format: string = 'a4'): void {
    //this.logger.info("ManagementComponent.onPrintProductionZoneList() -> format: " + format);

    // new
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PDF,
      'mngt/report-mngt-prod-list-by-prod-zone',
      format + '/fr-CA',
    );

    // OLD
    // const url: string = this.api.createReportURL(ApiRoutes.REPORT_PDF, "mngt/report-mngt-prod-list-by-prod-zone", "fr-CA");
    //this.logger.info("ManagementComponent.onPrintProductionZoneList() -> url: " + url);
    this.downloadPost(url, this.dateRangeProd);
  }

  public onPrintProductionZoneJulList(format: string = 'a4'): void {
    //this.logger.info("ManagementComponent.onPrintProductionZoneJulList()");

    //this.logger.info("ManagementComponent.onPrintProductionZoneJulList() -> " + JSON.stringify(this.dateRangeProd));
    const url: string = this.api.createReportURLWithPaper(
      ApiRoutes.REPORT_PRINT_WITH_PAPER,
      'mngt/report-mngt-prod-list-by-prod-zone-jul',
      format,
      'fr-CA',
    );

    // const url: string = this.api.createReportURL(ApiRoutes.REPORT_PDF, "mngt/report-mngt-prod-list-by-prod-zone", (format + "/fr-CA"));
    // const url= "https://api.dev.gestion-traiteur.app/maintenance";
    this.printPost(url, this.dateRangeProd);
  }

  public onDownloadProductionZoneJulList(format: string = 'a4'): void {
    //this.logger.info("ManagementComponent.onPrintProductionZoneJulList() -> format: " + format);

    // new
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PDF,
      'mngt/report-mngt-prod-list-by-prod-zone-jul',
      format + '/fr-CA',
    );

    // OLD
    // const url: string = this.api.createReportURL(ApiRoutes.REPORT_PDF, "mngt/report-mngt-prod-list-by-prod-zone", "fr-CA");
    //this.logger.info("ManagementComponent.onPrintProductionZoneJulList() -> url: " + url);
    this.downloadPost(url, this.dateRangeProd);
  }

  public onPrintProductList(): void {
    // //this.logger.info("ManagementComponent.onPrintProductList()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PRINT,
      'mngt/report-mngt-product-and-drink-list',
      'fr-CA',
    );
    this.printPost(url, this.dateRangeProd);
  }

  public onDownloadProductList(): void {
    // //this.logger.info("ManagementComponent.onDownloadProductList()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PDF,
      'mngt/report-mngt-product-and-drink-list',
      'fr-CA',
    );
    this.downloadPost(url, this.dateRangeProd);
  }

  public onPrintEventByContact(): void {
    // //this.logger.info("ManagementComponent.onPrintEventByContact()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PRINT,
      'mngt/report-mngt-event-by-seller',
      'fr-CA',
    );
    this.printPost(url, this.dateRangeEvent);
  }

  public onDownloadEventByContact(): void {
    // //this.logger.info("ManagementComponent.onDownloadEventByContact()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PDF,
      'mngt/report-mngt-event-by-seller',
      'fr-CA',
    );
    this.downloadPost(url, this.dateRangeEvent);
  }

  public onPrintIncomingEvent(): void {
    // //this.logger.info("ManagementComponent.onPrintIncomingEvent()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PRINT,
      'mngt/report-mngt-new-client',
      'fr-CA',
    );
    this.printPost(url, this.dateRangeEvent);
  }

  public onDownloadIncomingEvent(): void {
    // //this.logger.info("ManagementComponent.onDownloadIncomingEvent()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PDF,
      'mngt/report-mngt-new-client',
      'fr-CA',
    );
    this.downloadPost(url, this.dateRangeEvent);
  }

  public onPrintActivityReport(): void {
    // //this.logger.info("ManagementComponent.onPrintActivityReport()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PRINT,
      'mngt/report-mngt-event-by-status',
      'fr-CA',
    );
    this.printPost(url, this.dateRangeEvent);
  }

  public onDownloadActivityReport(): void {
    // //this.logger.info("ManagementComponent.onDownloadActivityReport()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PDF,
      'mngt/report-mngt-event-by-status',
      'fr-CA',
    );
    this.downloadPost(url, this.dateRangeEvent);
  }

  public onPrintDeliveryA(): void {
    // //this.logger.info("ManagementComponent.onPrintDeliveryA()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PRINT,
      'mngt/report-mngt-delivery-a',
      'fr-CA',
    );
    this.printPost(url, this.dateRangeLogistic);
  }

  public onDownloadDeliveryA(): void {
    // //this.logger.info("ManagementComponent.onDownloadDeliveryA()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PDF,
      'mngt/report-mngt-delivery-a',
      'fr-CA',
    );
    this.downloadPost(url, this.dateRangeLogistic);
  }

  public onDownloadDeliveryA_XLS(): void {
    // //this.logger.info("ManagementComponent.onDownloadDeliveryA_XLS()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_XLS,
      'mngt/report-mngt-delivery-a',
      'fr-CA',
    );
    this.downloadPost(url, this.dateRangeLogistic);
  }

  public onPrintDeliveryB(): void {
    // //this.logger.info("ManagementComponent.onPrintDeliveryB()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PRINT,
      'mngt/report-mngt-delivery-b',
      'fr-CA',
    );
    this.printPost(url, this.dateRangeLogistic);
  }

  public onDownloadDeliveryB(): void {
    // //this.logger.info("ManagementComponent.onDownloadDeliveryB()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PDF,
      'mngt/report-mngt-delivery-b',
      'fr-CA',
    );
    this.downloadPost(url, this.dateRangeLogistic);
  }

  public onDownloadDeliveryB_XLS(): void {
    // //this.logger.info("ManagementComponent.onDownloadDeliveryB()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_XLS,
      'mngt/report-mngt-delivery-b',
      'fr-CA',
    );
    this.downloadPost(url, this.dateRangeLogistic);
  }

  public onPrintMaterialList(): void {
    // //this.logger.info("ManagementComponent.onPrintMaterialList()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PRINT,
      'mngt/report-mngt-material-list',
      'fr-CA',
    );
    this.printPost(url, this.dateRangeLogistic);
  }

  public onDownloadMaterialList(): void {
    // //this.logger.info("ManagementComponent.onDownloadMaterialList()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PDF,
      'mngt/report-mngt-material-list',
      'fr-CA',
    );
    this.downloadPost(url, this.dateRangeLogistic);
  }

  public onDownloadTakeoutList(): void {
    //this.logger.info("ManagementComponent.onDownloadTakeoutList()");
    // g-t.app/reports/mngt/report-mngt-takeout/xls/fr-CA/bla-bla-aléatoire
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_XLS,
      'mngt/report-mngt-takeout',
      'fr-CA',
    );
    this.downloadPost(url, this.dateRangeLogistic);
  }

  public onShowReportsInfosCTM(): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });
    this.confirmDialogRef.componentInstance.useHTML = true;
    this.confirmDialogRef.componentInstance.useCancel = false;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('REPORTS_HELP.TITLE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('REPORTS_HELP.CTM_HELP_MESSAGE');

    this.confirmDialogRef
      .afterClosed()
      .subscribe({ next: (result: any) => {} });
  }
}
