import { MatDialog } from '@angular/material/dialog';
import { TestErrorService } from './gt2/api/test-error.service';
import { Platform } from '@angular/cdk/platform';
import { CommonModule, DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  NavigationEnd,
  Router,
  NavigationStart,
  ActivatedRoute,
} from '@angular/router';
import { FuseNavigationService } from '../@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '../@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '../@fuse/services/config.service';
import { FuseSplashScreenService } from '../@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '../@fuse/services/translation-loader.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { navigation, navigationEn } from './navigation/navigation';
import { BROWSERS, DeviceDetectorService } from 'ngx-device-detector';
import { NGXLogger } from 'ngx-logger';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { AuthenticationService } from './gt2/api/auth-service.service';
import { EmployeeModelData } from './gt2/api/models/CrudEmployeeModel';
import { ResponsesStatus } from './gt2/api/ResponsesStatus';
import { UserSettingsService } from './gt2/api/user-settings.service';
import { TopAlertModel } from './gt2/components/top-alerts/top-alerts.component';
import { AppRoutes } from './gt2/const/AppRoutes';
import { GT2ConstantsEnum } from './gt2/const/GT2ConstantsEnum';
import { GT2PageAbstract } from './gt2/content/abstract/GT2PageAbstract';
import { CrudEmployeeService } from './gt2/content/crud/crud-employee/crud-employee.service';
import { CrudOrganizationService } from './gt2/content/crud/crud-organization/crud-organization.service';
import { JwtInterceptor } from './gt2/interceptors/JwtInterceptor';
import { SettingsService } from './gt2/settings/settings.service';
import { ValidatorHelper } from './gt2/validators/ValidatorHelper';
import { locale as genericEnglish } from './localization/i18n/en-CA';
import { locale as genericFrench } from './localization/i18n/fr-CA';
import {
  LanguageModel,
  LocalizationConfig,
} from './localization/LocalizationConfig';
import { locale as navigationEnglish } from './navigation/i18n/en-CA';
import { locale as navigationFrench } from './navigation/i18n/fr-CA';
import { DevGroupNavigationModel } from './navigation/restricted-group/dev-group.navigation.model';
import { GodGroupNavigationModel } from './navigation/restricted-group/god-group.navigation.model';
import { ModulesService } from './gt2/services/modules.service';
import { Title } from '@angular/platform-browser';
import { RolesService } from './gt2/services/roles.service';
import { CatererService } from './gt2/content/caterer/caterer.service';
import { NavigationFactory } from './navigation/NavigationFactory';
import { Gt2ApiService } from './gt2/api/gt2-api.service';
import { TestErrorResultComponent } from './gt2/components/dialogs/test-error-result/test-error-result.component';
import { GtTools } from './gt2/directives/gt-clients.directive';
import { FuseProgressBarModule } from '../@fuse/components/progress-bar/progress-bar.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FuseSidebarModule } from '../@fuse/components/sidebar/sidebar.module';
import { FuseThemeOptionsModule } from '../@fuse/components/theme-options/theme-options.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FirebaseProcessComponent } from './gt2/components/firebase-process/firebase-process.component';
import { HorizontalLayout1Component } from './layout/horizontal/layout-1/layout-1.component';
import { VerticalLayout1Component } from './layout/vertical/layout-1/layout-1.component';
import { VerticalLayout2Component } from './layout/vertical/layout-2/layout-2.component';
import { VerticalLayout3Component } from './layout/vertical/layout-3/layout-3.component';

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FuseProgressBarModule,
    NgxSpinnerModule,
    MatButtonModule,
    MatInputModule,
    VerticalLayout1Component,
    VerticalLayout2Component,
    VerticalLayout3Component,
    TranslateModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    HorizontalLayout1Component,
    FirebaseProcessComponent,
    FuseSidebarModule,
    FuseThemeOptionsModule,
  ],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  fuseConfig: any;
  navigation: any;

  dialogRef: any;
  topAlertModelUser!: TopAlertModel | null;
  topAlertModelSettings!: TopAlertModel | null;
  topAlertModelCaterer!: TopAlertModel | null;

  pingInterval: any;
  innerWidth: any;
  doPing: boolean = false;

  // @ViewChild('firebaseProcessComponent') firebaseProcessComponent: FirebaseProcessComponent;

  // Private
  private _unsubscribeAll: Subject<any>;
  private _bottomSheetRef: any;

  /*
   * Constructor
   *
   * @param {DOCUMENT} document
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseNavigationService} _fuseNavigationService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {FuseSplashScreenService} _fuseSplashScreenService
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   * @param {Platform} _platform
   * @param {TranslateService} _translateService
   * @param {AuthenticationService} auth
   * @param {Router} router
   * @param {MatDialog} dialog
   * @param {CrudEmployeeService} employeeService
   * @param {CrudOrganizationService} orgService
   * @param {NgxPermissionsService} permissionsService
   * @param {SettingsService} settings
   */
  constructor(
    @Inject(DOCUMENT) private document: any,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _translateService: TranslateService,
    private _platform: Platform,
    private auth: AuthenticationService,
    private router: Router,
    private employeeService: CrudEmployeeService,
    private orgService: CrudOrganizationService,
    private permissionsService: NgxPermissionsService,
    private rolesService: RolesService,
    private appSettings: SettingsService,
    public userSettingsService: UserSettingsService,
    private logger: NGXLogger,
    private titleService: Title,
    private translate: TranslateService,
    private modulesService: ModulesService,
    private deviceService: DeviceDetectorService,
    private catererService: CatererService,
    private api: Gt2ApiService,
    private testErrorService: TestErrorService,
    private dialog: MatDialog,
    private activeRoute: ActivatedRoute,
  ) {
    // --o METRICS: Google Analytics, HotJar
    this.router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationEnd) {
          // this.logger.info("NavigationEnd -> Google Analytic tracking - url: " + event.urlAfterRedirects);
        }
        if (event instanceof NavigationStart) {
          // this.logger.info("NavigationStart -> ");
          this.titleService.setTitle(this.getTitle(event.url));
        }
      },
    });
    //
    // init
    GtTools.client.init(modulesService);

    // Add languages
    this._translateService.addLangs(['en-CA', 'fr-CA']);

    // Set the default language
    // this._translateService.setDefaultLang('en');
    this._translateService.setDefaultLang(LocalizationConfig.DEFAULT_LANGUAGE);

    // Set the navigation translations
    // Set the navigation and generic translations
    this._fuseTranslationLoaderService.loadTranslations(
      navigationEnglish,
      navigationFrench,
    );
    this._fuseTranslationLoaderService.loadTranslations(
      genericEnglish,
      genericFrench,
    );

    // Use a language
    const langModel: LanguageModel = LocalizationConfig.getCurrentLanguage();
    this._translateService.use(langModel.id);

    // Get default navigation copy
    if (langModel.id !== 'fr-CA') {
      this.navigation = JSON.parse(JSON.stringify(navigationEn));
    } else {
      this.navigation = JSON.parse(JSON.stringify(navigation));
    }

    // Register the navigation to the service
    this._fuseNavigationService.register('main', this.navigation);

    // Set the main navigation as our current navigation
    this._fuseNavigationService.setCurrentNavigation('main');

    // Add is-mobile class to the body if the platform is mobile
    if (this._platform.ANDROID || this._platform.IOS) {
      // Keeping this line just in case.
      this.document.body.classList.add('is-mobile');
    }

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    //
    const isMobile: boolean = this.deviceService.isMobile();
    JwtInterceptor.IS_MOBILE = isMobile;

    const browser: string = this.deviceService.browser.toLowerCase();
    const browserIsSupported: boolean =
      browser !== BROWSERS.IE && browser !== BROWSERS.OPERA;
    JwtInterceptor.BROSWER_SUPPORTED = browserIsSupported;
    // this.logger.info("BROWSER: " + browser);
    this.innerWidth = window.innerWidth;

    JwtInterceptor.IS_MOBILE = false;
    this.setUpGT2App();

    /*if (isMobile && this.innerWidth < 800) {
      // this.logger.info("PLATFORM: IS MOBILE");
      // this.logger.info(window.innerWidth);
      if (this.api.isDemo === false) {
        this.router.navigateByUrl("/" + AppRoutes.DEVICE_NOT_SUPPORTED);
      }
      // this._fuseSplashScreenService.hide();
    } else if (!browserIsSupported) {
      //this.logger.info("BROWSER NOT SUPPORTED");
      this.router.navigateByUrl(AppRoutes.BROWSER_NOT_SUPPORTED);
      // this._fuseSplashScreenService.hide();
    } else {
      JwtInterceptor.IS_MOBILE = false;
      // this.logger.info("PLATFORM: IS NOT MOBILE (I REPEAT, NOT MOBILE)");
      // this.getExternalEnv();
      this.setUpGT2App();
    }*/

    //
    this.testErrorService.onTestFromRequest.subscribe({
      next: (testFromRequestResult) => {
        if (testFromRequestResult) {
          /* this.logger.info(
                        "AppComponent.constructor().testErrorService() -> testFromRequestResult:  " +
                            JSON.stringify(testFromRequestResult)
                    ); */

          //
          this.dialogRef = this.dialog.open(TestErrorResultComponent, {
            width: '96vw',
            maxHeight: '98vh',
            minWidth: 350,
            disableClose: false,
            panelClass: 'scrollable-dialog',
            data: {
              testFromRequestResult: testFromRequestResult,
            },
          });

          this.dialogRef.afterClosed().subscribe({
            next: (result: any) => {
              if (result) {
                // this.logger.info("POP UP Closed!");
              }
              this.dialogRef = null;
            },
          });
        }
      },
    });
  }

  public setUpGT2App(): void {
    // this.logger.info("AppComponent.constructor() -> isLoggedIn:  " + this.auth.isLoggedIn());
    this.employeeService.employee.subscribe({
      next: (employee: any) => {
        if (employee) {
          // this.logger.info("AppComponent.constructor() -> EMPLOYEE:  " + JSON.stringify(employee));
          /* this.logger.info(
                    "AppComponent.constructor() -> MY EMPLOYEE UUID:  " +
                        employee.uuid
                ); */
          // this.logger.info("AppComponent.constructor() -> MY USER UUID:  " + employee.user.uuid);
          // this.logger.info("AppComponent.constructor() -> employee:  " + JSON.stringify(employee));
          this.topAlertModelUser = employee.top_alert;

          // override the current to use of LocalizationConfig.getCurrentLanguage() once the employee is loaded.
          // The employee local set in the Database has the priority.
          if (employee.employee_locale) {
            this._translateService.use(employee.employee_locale);
            // this.logger.info("AppComponent.constructor() -> employee loaded, lang set :  " + this._translateService.currentLang);
          }

          // navigationModel = new FuseNavigationModel();

          // MODULES MENU
          // TODO: plug back the modules available
          // navigationModel.model.push(new AppModulesGroupNavigationModel(employee.user.appModules));

          // employee.user.role = "SALE";
          /////////////////////
          // PERMISSIONS PLUGIN
          /* this.logger.info(
                    "AppComponent.constructor() -> employee.user.role: " +
                        employee.user.role
                ); */
          const perm = [];
          perm.push(employee.user.role);
          GT2PageAbstract.USER_ROLE = employee.user.role;

          //
          const langModel: LanguageModel =
            LocalizationConfig.getCurrentLanguage();

          if (langModel.id !== 'fr-CA') {
            this.navigation = NavigationFactory.create(
              navigationEn,
              employee.navigation_menu,
            );
          } else {
            this.navigation = NavigationFactory.create(
              navigation,
              employee.navigation_menu,
            );
          }
          // this.logger.info('AppComponent.constructor() -> navigation_menu: ' + JSON.stringify(employee.navigation_menu) );
          // this.logger.info('AppComponent.constructor() -> employee.user.role: ' + employee.user.role );

          // sales AND HIGHER CAN SEE EVERYTHING
          if (this.rolesService.RH_ROLES.indexOf(employee.user.role) !== -1) {
            // this.logger.info("AppComponent.constructor() -> LEFT MENU: HAS ALL PERMISSSION!");
          } else if (
            this.rolesService.ACCOUNTANT_ROLES.indexOf(employee.user.role) !==
            -1
          ) {
            // const newNav = this.navigation[0].children.filter(
            //     (item) => {
            //         return item.id !== "employees";
            //     }
            // );
            // this.navigation[0].children = newNav;
            // this.logger.info("AppComponent.constructor() -> ACCOUNTANT_ROLES newNav: " + JSON.stringify(newNav));
          } else if (
            this.rolesService.SALE_ROLES.indexOf(employee.user.role) !== -1
          ) {
            const newNav = this.navigation[0].children.filter((item: any) => {
              return (
                // item.id !== "employees" &&
                item.id !== 'reports' && item.id !== 'settings'
              );
            });
            this.navigation[0].children = newNav;
            // this.logger.info("AppComponent.constructor() -> SALE_ROLES newNav: " + JSON.stringify(newNav));
          } else if (
            this.rolesService.TEAM_LEAD_ROLES.indexOf(employee.user.role) !== -1
          ) {
            const newNav = this.navigation[0].children.filter((item: any) => {
              return (
                item.id !== 'employees' &&
                item.id !== 'reports' &&
                item.id !== 'settings' &&
                item.id !== 'venues' &&
                item.id !== 'clients'
              );
            });
            this.navigation[0].children = newNav;
            // this.logger.info("AppComponent.constructor() -> TEAM_LEAD_ROLES newNav: " + JSON.stringify(newNav));
          } else {
            const newNav = this.navigation[0].children.filter((item: any) => {
              return (
                item.id !== 'employees' &&
                item.id !== 'reports' &&
                item.id !== 'settings' &&
                item.id !== 'venues' &&
                item.id !== 'clients' &&
                item.id !== 'products' &&
                //item.id !== "upsale" &&
                item.id !== 'materials'
              );

              // TODO: is management missing ????
            });
            this.navigation[0].children = newNav;
            //this.logger.info("AppComponent.constructor() -> EMPLOYEE_ROLES newNav: " + JSON.stringify(newNav));
          }
          //
          // --o
          if (
            this.modulesService.hasCTReport() &&
            !this.rolesService.OWNER_ROLES.includes(employee.user.role)
          ) {
            const newNav = this.navigation[0].children.filter((item: any) => {
              return item.id !== 'reports';
            });
            this.navigation[0].children = newNav;
          }

          //
          // this.logger.info('AppComponent.constructor() -> this.navigation[0].children: ' + JSON.stringify(this.navigation[0].children));
          // this.logger.info("AppComponent.constructor() -> EMPLOYEE_ROLES: " + employee.user.role);
          if (employee.user.role === GT2ConstantsEnum.GOD) {
            this.navigation.push(new GodGroupNavigationModel());
            this.navigation.push(new DevGroupNavigationModel());
            // this.navigation.push(new AdminGroupNavigationModel());
            // this.logger.info(
            //     "AppComponent.constructor() -> EMPLOYEE_ROLES GOD newNav: " +
            //         JSON.stringify(this.navigation[0].children)
            // );
          }

          // Register the navigation to the service
          this._fuseNavigationService.unregister('main');
          this._fuseNavigationService.register('main', this.navigation);
          this._fuseNavigationService.setCurrentNavigation('main');

          this.permissionsService.loadPermissions(perm);
          // this.logger.info("AppComponent.constructor() -> PERMISSIONS: " + JSON.stringify(this.permissionsService.getPermissions()));

          // --o reset language
          // const langModel: LanguageModel = LocalizationConfig.getCurrentLanguage();
          this._translateService.setDefaultLang(
            LocalizationConfig.DEFAULT_LANGUAGE,
          );
          this._translateService.use(
            LocalizationConfig.getCurrentLanguage().id,
          );

          //
          this.loadStartingData(employee);
          // this.logger.info("app.component -> 1. ***** HIDE SPLASH SCREEN *****");
          this._fuseSplashScreenService.hide();
        }
      },
    });

    // load app settings
    this.appSettings
      .loadSettings()
      .pipe(
        tap((response) => {
          // this.logger.info('AppComponent.constructor().settings() -> SUCCESS: ' + JSON.stringify(response));
          if (response.response_status === ResponsesStatus.OK) {
            LocalizationConfig.SUPPORTED_LANGUAGES =
              response.response.supportedLanguagesSimple;
            LocalizationConfig.LANGUAGES = response.response.supportedLanguages;
            this.doPing = response.response.do_ping;
            this.topAlertModelSettings =
              LocalizationConfig.getCurrentLanguage().id === 'en-CA'
                ? response.response.top_alert_en_ca
                : response.response.top_alert_fr_ca;
          }
        }),
        catchError((error: any): any => {
          this.logger.error(
            'AppComponent.constructor().settings() -> ERRORS: ' +
              JSON.stringify(error),
          );
          if (
            error.status === 500 ||
            error.status === 503 ||
            error.status === 404 ||
            error.status === 429
          ) {
            // Errors 400, 429, 500 and 503 (maintenance), we hide the splashscreen so we can see the error page
            this.logger.error(
              'AppComponent.constructor().settings() -> ERROR 503!!!! ',
            );
            this._fuseSplashScreenService.hide();
          }
        }),
      )
      .subscribe();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Replace the native minLength validator with a custom one (also validate string with trim())
    Validators.minLength = ValidatorHelper.minLength;

    // Subscribe to config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (config: any) => {
          this.fuseConfig = config;

          // Boxed
          if (this.fuseConfig.layout.width === 'boxed') {
            this.document.body.classList.add('boxed');
          } else {
            this.document.body.classList.remove('boxed');
          }

          // Color theme - Use normal for loop for IE11 compatibility
          for (let i = 0; i < this.document.body.classList.length; i++) {
            const className = this.document.body.classList[i];

            if (className.startsWith('theme-')) {
              this.document.body.classList.remove(className);
            }
          }
          let colorCache = localStorage.getItem('theme');
          let currentTheme =
            colorCache === 'theme-blue-gray-dark' ||
            colorCache === 'theme-default'
              ? colorCache
              : 'theme-default';
          localStorage.setItem('theme', currentTheme);

          this.document.body.classList.add(currentTheme);
        },
      });

    // --o PING
    // this.pingInterval = setInterval(() => {
    //     if (this.doPing) {
    //         this.appSettings.doPing().subscribe(
    //             (response) => {
    //                 // this.logger.info("AppComponent.doPing() -> SUCCESS: " + JSON.stringify(response));
    //             },
    //             (error) => {
    //                 this.logger.error(
    //                     "AppComponent.doPing() -> Error: " +
    //                         JSON.stringify(error)
    //                 );
    //             }
    //         );
    //     }
    // }, 1000 * 60 * 30);

    this.auth.logoutSubject.subscribe({
      next: (value) => {
        this.topAlertModelUser = null;
        this.topAlertModelSettings = null;
        this.topAlertModelCaterer = null;
      },
    });

    document.body.className = localStorage.getItem('theme') || 'theme-default';
  }

  ngAfterViewInit(): void {
    //this.initGoogleAnalyticsPageView();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(undefined);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  @HostListener('window:keydown.control.k', ['$event'])
  openSearch(event: KeyboardEvent) {
    event.preventDefault();
  }
  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key: any): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  public loadStartingData(employee: EmployeeModelData): void {
    const caterer = this.orgService.getCatererMeObject();
    if (caterer === null) {
      this.orgService
        .loadCatererMe('App.Component.loadStartingData')
        .subscribe({
          next: (response) => {
            this.topAlertModelCaterer = response.data.top_alert;
            this.catererService.setCaterer(response.data);
          },
          error: (error) => {
            // don't show long error message in console if just a 401
            if (error.status !== 401) {
              this.logger.error(
                'AppComponent.loadStartingData().load Caterer Me() -> Error: ' +
                  JSON.stringify(error),
              );
            }
          },
        });
    } else {
      this.topAlertModelCaterer = caterer.top_alert;
      this.catererService.setCaterer(caterer);
    }

    // this.logger.info("AppComponent.loadStartingData().loadOrganization() ->  ABOUT TO LOAD ORGANIZATION");
    const organizationMe = this.orgService.getOrganizationMeObject();
    if (organizationMe === null) {
      this.orgService
        .loadOrganizationMe('App.Component.loadStartingData')
        .subscribe({
          next: (organizations) => {
            this.modulesService.setModules(organizations.data.appModules);
          },
        });
    } else {
      this.modulesService.setModules(organizationMe.data.appModules);
    }

    this.modulesService.modulesSet.subscribe({
      next: (data) => {
        if (data) {
          // REMOVE BUTTONS FROM MENU IF THE ORG DOES NOT HAVE THE CORRESPONDANT MODULES
          if (!this.modulesService.hasShopWeb()) {
            const newNav = this.navigation[0].children.filter((item: any) => {
              return item.id !== 'shops';
            });
            this.navigation[0].children = newNav;
          }
          //
          // if is JUL and were on the main dashboard
          // redirect to JUL dashboard
          if (this.modulesService.hasJLReport()) {
            if (
              this.router.url === '/tableau-de-bord' ||
              this.router.url === '/dashboard'
            ) {
              this.router.navigateByUrl(AppRoutes.DASHBOARD_JUL);
            }
          }

          if (!this.modulesService.hasRoomManagement()) {
            const newNav = this.navigation[0].children.filter((item: any) => {
              return item.id !== 'room-management';
            });
            this.navigation[0].children = newNav;
            //
            this._fuseNavigationService.unregister('main');
            this._fuseNavigationService.register('main', this.navigation);
            this._fuseNavigationService.setCurrentNavigation('main');
          }

          if (
            this.modulesService.hasCTReport() &&
            !this.rolesService.OWNER_ROLES.includes(employee.user.role)
          ) {
            const newNav = this.navigation[0].children.filter((item: any) => {
              return item.id !== 'reports';
            });
            this.navigation[0].children = newNav;

            this._fuseNavigationService.unregister('main');
            this._fuseNavigationService.register('main', this.navigation);
            this._fuseNavigationService.setCurrentNavigation('main');
          }
        }
      },
    });

    this.userSettingsService.getSettings().subscribe({
      next: (response: any) => {
        // this.logger.info('AppComponent.loadStartingData() -> SUCCESS: ' + JSON.stringify(response));
        // this.userSettingsService.setUserSettings(response.data);
        // localStorage.setItem(DateFormatPreference.DATE_FORMAT_KEY, null);
      },
      error: (error: any) => {
        this.logger.error(
          'AppComponent.loadStartingData() -> userSettingsService ERRORS: ' +
            JSON.stringify(error),
        );
      },
    });
  }
  getTitle(url: string) {
    const lang =
      localStorage.getItem(LocalizationConfig.LOCAL_STORED_LANG_ID) || 'fr-CA';
    const prefix = lang === 'en-CA' ? 'CM - ' : 'GT - ';

    const fromUrlToTitle = [
      {
        url: '/preferences/categories-clients',
        title: 'Préférences - Catégories de clients',
      },
      {
        url: '/preferences/categories-materiels',
        title: 'Préférences - Catégories de matériels',
      },
      {
        url: '/preferences/categories-produits',
        title: 'Préférences - Catégories de produits',
      },
      {
        url: '/preferences/zone-de-production',
        title: 'Préférences - Zones de production',
      },
      {
        url: '/preferences/types-evenements',
        title: "Préférences - Types d'événements",
      },
      {
        url: '/preferences/allergenes',
        title: 'Préférences - Allergènes alimentaires',
      },
      {
        url: '/preferences/fonction-de-travail',
        title: 'Préférences - Fonctions de travail',
      },
      {
        url: '/preferences/fournisseur-materiel',
        title: 'Préférences - Fournisseurs de matériel',
      },
      {
        url: '/preferences/fournisseur-ressources-humaine',
        title: 'Préférences - Nom du fournisseur de ressources humaines',
      },
      {
        url: '/preferences/frais-de-service',
        title: 'Préférences - Frais de service',
      },
      {
        url: '/preferences/livraison-secteur',
        title: 'Préférences - Secteurs de livraison',
      },
      {
        url: '/preferences/model-demande-livraison',
        title: 'Préférences - Modèle de livraison',
      },
      {
        url: '/preferences/date-et-heure',
        title: 'Préférences - Date et heure',
      },
      { url: '/preferences/numerotation', title: 'Préférences - Numérotation' },
      { url: '/preferences/emballage', title: 'Préférences - Emballages' },
      {
        url: '/preferences/emballage-defaut',
        title: 'Préférences - Emballages par défaut',
      },
      { url: '/preferences/taxes', title: 'Préférences - Numéros de taxe' },
      {
        url: '/preferences/taxes-defaut',
        title: 'Préférences - Taxes par défaut',
      },
      {
        url: '/preferences/compte-de-grand-livre-defaut',
        title: 'Préférences - Compte de grand livre par défaut',
      },
      {
        url: '/preferences/compte-de-grand-livre',
        title: 'Préférences - Comptes de grand livre',
      },
      {
        url: '/preferences/conditions-generales',
        title: 'Préférences - Conditions générales',
      },
      {
        url: '/preferences/conditions-specifiques',
        title: 'Préférences - Conditions spécifiques',
      },
      {
        url: '/preferences/messages-notification-proposition',
        title: 'Préférences - Proposition',
      },
      {
        url: '/preferences/messages-notification-commande',
        title: 'Préférences - Commande',
      },
      {
        url: '/preferences/messages-notification-facture',
        title: 'Préférences - Facture',
      },
      {
        url: '/preferences/messages-notification-recu',
        title: 'Préférences - Reçu',
      },
      {
        url: '/preferences/messages-notification-etat-de-compte-client',
        title: 'Préférences - État de compte client',
      },
      {
        url: '/preferences/messages-notification-etat-de-compte-entreprise',
        title: 'Préférences - État de compte entreprise',
      },
      {
        url: '/preferences/messages-notification-horaire',
        title: 'Préférences - Horaire',
      },
      {
        url: '/preferences/notification_all_receipt_notices',
        title: 'Préférences - Avis de réception',
      },
      {
        url: '/preferences/user-employee-setting',
        title: "Préférences - Nombre d'utilisateurs",
      },

      {
        url: '/settings/categories-clients',
        title: 'Settings - Clients Categories',
      },
      {
        url: '/settings/categories-materiels',
        title: 'Settings - Equipment categories',
      },
      {
        url: '/settings/categories-produits',
        title: 'Settings - Products categories',
      },
      {
        url: '/settings/zone-de-production',
        title: 'Settings - Production zones',
      },
      { url: '/settings/types-evenements', title: 'Settings - Events types' },
      { url: '/settings/allergenes', title: 'Settings - Allergens' },
      {
        url: '/settings/fonction-de-travail',
        title: 'Settings - Job functions',
      },
      {
        url: '/settings/fournisseur-materiel',
        title: 'Settings - Equipments Providers',
      },
      {
        url: '/settings/fournisseur-ressources-humaine',
        title: "Settings - Provider's name",
      },
      {
        url: '/settings/frais-de-service',
        title: 'Settings - Service charges',
      },
      {
        url: '/settings/livraison-secteur',
        title: 'Settings - Delivery sectors',
      },
      {
        url: '/settings/model-demande-livraison',
        title: 'Settings - Delivery request templates',
      },
      { url: '/settings/date-et-heure', title: 'Settings - Date and time' },
      { url: '/settings/numerotation', title: 'Settings - Numbering' },
      { url: '/settings/emballage', title: 'Settings - Wrapping' },
      {
        url: '/settings/emballage-defaut',
        title: 'Settings - Default wrapping',
      },
      { url: '/settings/taxes', title: 'Settings - Taxes number' },
      { url: '/settings/taxes-defaut', title: 'Settings - Default taxes' },
      {
        url: '/settings/compte-de-grand-livre-defaut',
        title: 'Settings - Default ledger account',
      },
      {
        url: '/settings/compte-de-grand-livre',
        title: 'Settings - Ledger account',
      },
      {
        url: '/settings/conditions-generales',
        title: 'Settings - General conditions',
      },
      {
        url: '/settings/conditions-specifiques',
        title: 'Settings - Specific conditions',
      },
      {
        url: '/settings/messages-notification-proposition',
        title: 'Settings - Proposal',
      },
      {
        url: '/settings/messages-notification-commande',
        title: 'Settings - Order',
      },
      {
        url: '/settings/messages-notification-facture',
        title: 'Settings - Invoice',
      },
      {
        url: '/settings/messages-notification-recu',
        title: 'Settings - Receipt',
      },
      {
        url: '/settings/messages-notification-etat-de-compte-client',
        title: 'Settings - Client statement',
      },
      {
        url: '/settings/messages-notification-etat-de-compte-entreprise',
        title: 'Settings - Entreprise statement',
      },
      {
        url: '/settings/messages-notification-horaire',
        title: 'Settings - Schedule',
      },
      {
        url: '/settings/notification_all_receipt_notices',
        title: 'Settings - Receipt notice',
      },
      {
        url: '/settings/user-employee-setting',
        title: 'Settings - Number of users',
      },
      { url: '/tableau-de-bord-detaille', title: 'Tableau de bord détaillé' },
      { url: '/tableau-de-bord-jul', title: 'Tableau de bord Jul' },
      { url: '/produits-upsale', title: 'Produits upsale' },
      { url: '/gestion-salles', title: 'Gestion des salles' },
      { url: '/sous-salles-2', title: 'Sous salles' },
      { url: '/sous-salles', title: 'Sous salles' },
      { url: '/base-de-connaissance', title: 'Base de connaissance' },
      { url: '/foire-aux-questions', title: 'FAQ' },
      { url: '/appareil-non-supporte', title: 'Erreur Appareil' },
      { url: '/browser-non-supporte', title: 'Erreur Navigateur' },
      { url: '/note-mise-a-jour', title: 'Note de mise à jour' },
      { url: '/god-emails', title: '' },
      { url: '/god-impersonation', title: '' },
      { url: '/god-local-storage', title: '' },
      { url: '/god-reports', title: 'God Reports' },
      { url: '/errors/error-404', title: 'Erreur 404' },
      { url: '/errors/error-500', title: 'Erreur 500' },
      { url: '/errors/error-429', title: 'Erreur 429' },
      { url: '/commande-en-ligne', title: 'Commande en ligne' },
      { url: '/traiteur/organisation', title: '' },
      { url: '/employe/create', title: '' },
      { url: '/tableau-de-bord', title: 'Tableau de bord' },
      { url: '/mot-de-passe', title: 'Mot de passe' },
      { url: '/test', title: 'Test' },
      { url: '/authentification', title: 'Connexion' },
      { url: '/profile', title: 'Profile' },
      { url: '/produits', title: 'Produits' },
      { url: '/clients', title: 'Clients' },
      { url: '/entreprises', title: 'Entreprises' },
      { url: '/lieux', title: 'Lieux' },
      { url: '/salles', title: 'Salles' },
      { url: '/commandes', title: 'Commandes' },
      { url: '/evenements', title: 'Evenements' },
      { url: '/propositions', title: 'Propositions' },
      { url: '/factures', title: 'Factures' },
      { url: '/rapports', title: 'Rapports' },
      { url: '/support', title: 'Support' },
      { url: '/preferences', title: 'Préférences' },
      { url: '/recherche', title: 'Recherche' },
      { url: '/contact', title: 'Contact' },
      { url: '/documentation', title: 'Documentation' },
      { url: '/employes', title: 'Employés' },
      { url: '/traiteur', title: 'Traiteur' },
      { url: '/materiels', title: 'Materiels' },
      { url: '/gestion', title: 'Gestion' },
      { url: '/module1', title: 'Module1' },
      { url: '/maintenance', title: 'Maintenance' },
      { url: '/congresses', title: 'Congrès' },
      { url: '/congres', title: 'Congrès' },
      { url: '/projet', title: 'Projet' },
      { url: '/projets', title: 'Projets' },
      { url: '/upsale', title: 'Upsale' },
      { url: '/fonction-de-travail', title: 'Fonction de travail' },
      { url: '/advanced-dashboard', title: 'Advanced Dashboard' },
      { url: '/dashboard-jul', title: 'Dashboard Jul' },
      { url: '/upsale-products', title: 'Upsale products' },
      { url: '/room-management', title: 'Room management' },
      { url: '/basement-rooms-2', title: 'Basement rooms' },
      { url: '/basement-rooms', title: 'Basement rooms' },
      { url: '/knowledge-base', title: 'Knowledge base' },
      { url: '/frequently-asked-questions', title: 'FAQ' },
      { url: '/device-not-supported', title: 'Error device' },
      { url: '/browser-not-supported', title: 'Error browser' },
      { url: '/note-update', title: 'Note update' },
      { url: '/shops', title: 'Shops' },
      { url: '/caterer/organization', title: '' },
      { url: '/employee/create', title: '' },
      { url: '/dashboard', title: 'Dashboard' },
      { url: '/password', title: 'Password' },
      { url: '/login', title: 'Login' },
      { url: '/products', title: 'Products' },
      { url: '/companies', title: 'Companies' },
      { url: '/places', title: 'Places' },
      { url: '/rooms', title: 'Rooms' },
      { url: '/orders', title: 'Orders' },
      { url: '/events', title: 'Events' },
      { url: '/proposals', title: 'Proposals' },
      { url: '/invoices', title: 'Invoices' },
      { url: '/reports', title: 'Reports' },
      { url: '/settings', title: 'Settings' },
      { url: '/research', title: 'Research' },
      { url: '/employees', title: 'Employees' },
      { url: '/caterer', title: 'Caterer' },
      { url: '/equipments', title: 'Equipments' },
      { url: '/management', title: 'Management' },
      { url: '/congress', title: 'Congress' },
      { url: '/project', title: 'Project' },
      { url: '/projects', title: 'Projects' },
    ];

    const title = fromUrlToTitle.find((t) => t.url === url)?.title || '';

    return prefix + title;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE methods
  // -----------------------------------------------------------------------------------------------------
  private initGoogleAnalyticsPageView(): void {
    const interval = setInterval(() => {
      if ((window as any).ga && (window as any).ga.getAll) {
        this.router.events.subscribe({
          next: (event) => {
            const ga = (window as any).ga;
            if (event instanceof NavigationEnd) {
              const tracker = ga.getAll()[0];
              tracker.set('page', event.urlAfterRedirects);
              tracker.send('pageview');
            }
          },
        });
        clearInterval(interval);
      }
    }, 50);
  }
}
