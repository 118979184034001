import { GenericAlertComponent } from './../../../../../components/generic-alert/generic-alert.component';
import { JobExpensesService } from './../job-expenses.service';
import { NGXLogger } from 'ngx-logger';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogModule,
} from '@angular/material/dialog';
import { GT2PageAbstract } from './../../../../abstract/GT2PageAbstract';
import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { IconsService } from '../../../../../services/icons.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { JobExpensesItemComponent } from '../job-expenses-item/job-expenses-item.component';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-job-expenses-modal',
  templateUrl: './job-expenses-modal.component.html',
  styleUrls: ['./job-expenses-modal.component.scss'],
  standalone: true,
  imports: [
    MatTooltipModule,
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    JobExpensesItemComponent,
    NgxSpinnerModule,
    MatIconModule,
    TranslateModule,
  ],
})
export class JobExpensesModalComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  jobExpenses: any[] | any;
  job: any;
  jobAssignmentUUID?: string;
  groupUUID?: string;
  assignmentModel: any;
  confirmDialogRef: any;
  deleteNewModel: any;

  constructor(
    public override dialogRef: MatDialogRef<JobExpensesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    private formBuilder: UntypedFormBuilder,
    public router: Router,
    private dialog: MatDialog,
    // private jobsFunctionService: JobsFunctionService,
    // public assignmentsService: AssignementsService,
    private spinner: NgxSpinnerService,
    private logger: NGXLogger,
    public iconService: IconsService,
    private expensesService: JobExpensesService,
  ) {
    super();

    // if (data && data.jobExpenses) {
    //     this.jobExpenses = data.jobExpenses;
    // }

    if (data && data.job) {
      this.jobExpenses = data.job.job_expenses;
      this.jobAssignmentUUID = data.job.uuid;
      this.groupUUID = data.groupUUID;
    }
  }

  ngOnInit() {
    //this.logger.info("JobExpensesModalComponent.ngOnInit() -> jobExpenses: " + JSON.stringify(this.jobExpenses));
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  public onAddExpense(): void {
    const expense: any = {
      job_assignment_uuid: this.jobAssignmentUUID,
      name: null,
      amount: null,
      uuid: null,
    };
    this.jobExpenses?.push(expense);
  }

  public onSaveExpense(expense: any): void {
    this.spinner.show('expensesSpiner');
    this.expensesService.addJobExpense(expense).subscribe({
      next: (response: any) => {
        //this.logger.info("JobExpensesModalComponent.onSaveExpense() -> SUCCESS: " + JSON.stringify(response));

        this.assignmentModel = response;
        // this.dialogRef.close(true);
        this.spinner.hide('expensesSpiner');

        // //this.logger.info("JobExpensesModalComponent.onSaveExpense() -> groupUUID: " + this.groupUUID);
        const group: any = response.group_assignments.find((item: any) => {
          return item.uuid === this.groupUUID;
        });

        // //this.logger.info("JobExpensesModalComponent.onSaveExpense() -> group: " + JSON.stringify(group));
        // //this.logger.info("JobExpensesModalComponent.onSaveExpense() -> group.jobs: " + JSON.stringify(group.jobs));
        if (group) {
          const job: any = group.jobs.find((item: any) => {
            return item.uuid === this.jobAssignmentUUID;
          });
          //this.logger.info("JobExpensesModalComponent.onSaveExpense() -> job: " + JSON.stringify(job));
          if (job) {
            this.jobExpenses = job.job_expenses;
          }
        }
      },
      error: (error: any) => {
        this.logger.error(
          'JobExpensesModalComponent.onSaveExpense() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
        this.spinner.hide('expensesSpiner');
      },
    });
  }

  public onEditExpense(expense: any): void {
    this.spinner.show('expensesSpiner');
    this.expensesService.editJobExpense(expense.uuid, expense).subscribe({
      next: (response: any) => {
        //this.logger.info("JobExpensesModalComponent.onEditExpense() -> SUCCESS: " + JSON.stringify(response));
        this.spinner.hide('expensesSpiner');
        this.assignmentModel = response;
        // //this.logger.info("JobExpensesModalComponent.onSaveExpense() -> groupUUID: " + this.groupUUID);
        const group: any = response.group_assignments.find((item: any) => {
          return item.uuid === this.groupUUID;
        });

        if (group) {
          const job: any = group.jobs.find((item: any) => {
            return item.uuid === this.jobAssignmentUUID;
          });
          //this.logger.info("JobExpensesModalComponent.onSaveExpense() -> job: " + JSON.stringify(job));
          if (job) {
            this.jobExpenses = job.job_expenses;
          }
        }
      },
      error: (error: any) => {
        this.logger.error(
          'JobExpensesModalComponent.onEditExpense() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
        this.spinner.hide('expensesSpiner');
      },
    });
  }

  public onDeleteExpense(expense: any): void {
    if (expense.uuid) {
      this.spinner.show('expensesSpiner');
      this.expensesService.deletedJobExpense(expense.uuid).subscribe({
        next: (response: any) => {
          //this.logger.info("JobExpensesModalComponent.onDeleteExpense() -> SUCCESS: " + JSON.stringify(response));
          this.deleteNewModel = response;
          this.spinner.hide('expensesSpiner');

          const group: any = response.group_assignments.find((item: any) => {
            return item.uuid === this.groupUUID;
          });

          if (group) {
            const job: any = group.jobs.find((item: any) => {
              return item.uuid === this.jobAssignmentUUID;
            });
            //this.logger.info("JobExpensesModalComponent.onDeleteExpense() -> job: " + JSON.stringify(job));
            if (job) {
              this.jobExpenses = job.job_expenses;
            }
          }
        },
        error: (error: any) => {
          this.logger.error(
            'JobExpensesModalComponent.onDeleteExpense() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.handleAPIError(error, this.dialog, null, null);
          this.spinner.hide('expensesSpiner');
        },
      });
    } else {
      const itemIndex = this.jobExpenses?.findIndex((item: any) => {
        return item.name === expense.name && item.amount == expense.amount;
      }) as any;
      //this.logger.info("JobExpensesModalComponent.onDeleteExpense() -> itemIndex: " + itemIndex);
      this.jobExpenses?.splice(itemIndex, 1);
    }
  }

  public onOK(): void {
    // //this.logger.info('JobExpensesModalComponent.onOK() -> jobExpenses: ' + JSON.stringify(this.jobExpenses));
    const unsavedExpenses = this.jobExpenses?.filter((item: any) => {
      return item.uuid === null;
    });
    //this.logger.info("JobExpensesModalComponent.onOK() -> unsavedExpenses: " + JSON.stringify(unsavedExpenses));

    if (unsavedExpenses && unsavedExpenses.length > 0) {
      this.warnOfUnsaved();
    } else {
      this.closeModal();
    }
  }

  public warnOfUnsaved(): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });
    this.confirmDialogRef.componentInstance.useYesNo = true;
    this.confirmDialogRef.componentInstance.alertTitle = this.translate.instant(
      'ASSIGNMENTS.UNSAVED_EXPENSES_TITLE',
    );
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('ASSIGNMENTS.UNSAVED_EXPENSES_MESSAGE');

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        //this.logger.info("JobExpensesModalComponent.onOK() -> result: " + result);
        if (result) {
          this.closeModal();
        }
      },
    });
  }

  public closeModal(): void {
    //this.logger.info("JobExpensesModalComponent.closeModal() -> " + this.assignmentModel);
    if (this.assignmentModel) {
      this.dialogRef.close(this.assignmentModel);
    } else if (this.deleteNewModel) {
      this.dialogRef.close(this.deleteNewModel);
    } else {
      this.dialogRef.close(false);
    }
  }
}
