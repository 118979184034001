import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { ContactModel } from '../../api/models/ContactModel';
import { GT2FormPageAbstract } from '../../content/abstract/GT2FormPageAbstract';
import { ContactService } from './contact.service';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { CrudOrganizationService } from '../../content/crud/crud-organization/crud-organization.service';
import { LocalizationConfig } from '../../../localization/LocalizationConfig';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    MatInputModule,
    MatButtonModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    TranslateModule,
  ],
})
export class ContactComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() groupModel: ContactModel | any;
  @Output() groupModelChange = new EventEmitter<ContactModel>();
  form: UntypedFormGroup | any;
  @Input() editMode: boolean = true;
  @Input() useAutosave: boolean = true;
  @Input() hideSaveButton: boolean = false;

  public showEmailCC: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public translate: TranslateService,
    private logger: NGXLogger,
    private contactService: ContactService,
    public dialog: MatDialog,
    private orgService: CrudOrganizationService,
  ) {
    super();
    // Initialisé la langue
    const local_lang =
      localStorage.getItem(LocalizationConfig.LOCAL_STORED_LANG_ID) ||
      LocalizationConfig.DEFAULT_LANGUAGE;

    // Ajouter les traductions locales
    if (local_lang === 'en-CA')
      translate.setTranslation(english.lang, english.data, true);
    else translate.setTranslation(french.lang, french.data, true);

    // Définir la langue à utiliser
    translate.setDefaultLang(LocalizationConfig.DEFAULT_LANGUAGE);
    translate.use(local_lang);

    this.form = this.formBuilder.group({
      email_organisation: ['', [Validators.email]],
      email_personnal: ['', [Validators.email]],
      email_cc: ['', [Validators.email]],
      telephone_home: [
        '',
        [Validators.minLength(2), Validators.maxLength(100)],
      ],
      mobile: ['', [Validators.minLength(2), Validators.maxLength(100)]],
      telephone_office: [
        '',
        [Validators.minLength(2), Validators.maxLength(100)],
      ],
      telephone_office_ext: [
        '',
        [Validators.minLength(2), Validators.maxLength(20)],
      ],
      telephone_other: [
        '',
        [Validators.minLength(2), Validators.maxLength(100)],
      ],
      skype: ['', [Validators.minLength(2), Validators.maxLength(100)]],
      fax: ['', [Validators.minLength(2), Validators.maxLength(100)]],
    });
  }

  ngOnInit() {}

  ngAfterViewInit() {
    if (!this.groupModel) {
      this.groupModel = {
        object: 'Contact',
        uuid: null,
        email_organisation: null,
        email_organisation_label: null,
        email_personnal: null,
        email_personnal_label: null,
        email_cc: null,
        email_cc_label: null,
        mobile: null,
        telephone_office: null,
        telephone_office_ext: null,
        telephone_home: null,
        telephone_other: null,
        skype: null,
        fax: null,
        note: null,
      };
    }

    if (this.useAutosave) {
      this.setControlsChanges(this.form);
    }
  }

  ngOnDestroy() {}

  public onManualSave(): void {
    if (this.form.dirty) {
      this.autosaveDataChange(this.groupModel);
    }
  }

  public override autosaveDataChange(data: any): void {
    this.contactService.updateContact(data, this.groupModel.uuid).subscribe({
      next: (response) => {
        this.groupModel = response.data;

        this.groupModelChange.emit(this.groupModel);
      },
      error: (error) => {
        this.logger.error(
          'ContactComponent.autosaveDataChange() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }
}
