<div
  id="enterprise"
  class="page-layout simple left-sidebar inner-sidebar inner-scroll"
>
  <!-- HEADER -->
  <div class="gt2-theme">
    <div
      class="header accent pl-24 pr-24 h-80 mat-elevation-z1"
      fusePerfectScrollbar
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div fxLayout="row" fxLayoutAlign="start center">
        <button
          class="mr-0 mr-sm-16 white-fg"
          mat-icon-button
          (click)="onPageBack(location, router, '/clients')"
        >
          <mat-icon class="icon-mat">arrow_back</mat-icon>
        </button>
        <div fxLayout="column">
          <div
            style="width: 100%"
            fxLayout="row"
            fxLayoutAlign="start center"
            class="h1 text-truncate"
          >
            <div>{{ "GENERIC.ENTERPRISE" | translate }}</div>
            <div *ngIf="enterprise && enterprise.parent">
              &nbsp;- {{ "GENERIC.BRANCH" | translate }}
            </div>
            <div *ngIf="enterprise">&nbsp;-&nbsp;</div>
            <div *ngIf="enterprise">
              <span class="text-truncate">{{ enterprise.name }}</span>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="end center">
        <!-- <button mat-button
                        *ngIf="fileService.pdfEnabled()"
                        matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                        (click)="onDownloadPDF()" class="mat-icon-button">
                    <mat-icon class="s-30">picture_as_pdf</mat-icon>
                </button>

                <mat-spinner *ngIf="print.loadingPrint" [diameter]="20" class="mt-12"></mat-spinner>
                <button mat-button
                        *ngIf="fileService.printEnabled()"
                        matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                        (click)="onPrint()" class="mat-icon-button">
                    <mat-icon class="s-30">print</mat-icon>
                </button> -->

        <button
          mat-button
          matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
          (click)="onOpenInNewTab()"
          class="mat-icon-button"
        >
          <mat-icon class="s-30 icon-mat">{{ getIcon("NEW_TAB") }}</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <!-- / HEADER -->

  <!-- SUB HEADER -->
  <div
    *ngIf="enterprise"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fusePerfectScrollbar
    class="primary mat-elevation-z1 py-8 gt2-sub-header content-subheader"
  >
    <!-- TODO: fix navigation to parent 
        https://docs.google.com/document/d/1ZL7od2w5HTgTJG420dQ4XXeEmJ-UeBegBdca0M_jRE8/edit -->

    <!-- <div fxLayout="row" fxLayoutAlign="start center">
            <button mat-button *ngIf="enterprise && enterprise.parent" (click)="onVisitEnterpriseParent()" class="gt2-sub-header-button">
            <mat-icon class="s-24">{{ getIcon('ENTERPRISE') }}</mat-icon>
            {{ 'CLIENTS.VISIT_ENTERPRISE_PARENT' | translate }}
           </button>
        </div> -->

    <div fxLayout="row" fxLayoutAlign="end center">
      <button
        mat-button
        *ngIf="enterprise && !enterprise.parent"
        (click)="onAddSuccursale()"
        class="gt2-sub-header-button"
      >
        <mat-icon class="icon-mat s-24">{{ getIcon("BRANCH") }}</mat-icon>
        <!--<mat-icon fontSet="fa" fontIcon="fa-code-branch" class="s-20"></mat-icon>-->
        <span class="ml-4 mr-8 icon-mat">{{
          "CLIENTS.ADD_BRANCH" | translate
        }}</span>
      </button>

      <button
        mat-button
        *ngIf="enterprise"
        (click)="onAddClient()"
        class="gt2-sub-header-button"
      >
        <mat-icon
          fontSet="fa"
          fontIcon="{{ getIcon('CLIENT') }}"
          class="icon-mat fa-lg"
        ></mat-icon>
        <span class="ml-4 mr-8 icon-mat">{{
          "CLIENTS.ADD_CLIENT" | translate
        }}</span>
      </button>

      <!--<button mat-button-->
      <!--*ngIf="enterprise"-->
      <!--(click)="onAddOrder()"-->
      <!--class="gt2-sub-header-button">-->
      <!--<mat-icon class="s-24">{{ getIcon('ORDER') }}</mat-icon>-->
      <!--{{ 'CLIENTS.ADD_ORDER' | translate }}-->
      <!--</button>-->

      <!--<button mat-button-->
      <!--(click)="onCreateClient()"-->
      <!--class="gt2-sub-header-button">-->
      <!--<mat-icon class="s-20">{{ getIcon('ORDER') }}</mat-icon>-->
      <!--{{ 'CLIENTS.CREATE_CLIENT' | translate }}-->
      <!--</button>-->

      <!--<button mat-button-->
      <!--(click)="onCreateClientAndEnterprise()"-->
      <!--class="gt2-sub-header-button">-->
      <!--<mat-icon class="s-24">add_box</mat-icon>-->
      <!--{{ 'CLIENTS.CREATE_CLIENT_ENTERPRISE' | translate }}-->
      <!--</button>-->

      <!--<button mat-menu-item (click)="onCreateClient()">-->
      <!--&lt;!&ndash;<mat-icon>account_circle</mat-icon>&ndash;&gt;-->
      <!--<span>{{ 'CLIENTS.CREATE_CLIENT' | translate }}</span>-->
      <!--</button>-->

      <!--<button mat-menu-item (click)="onCreateClientAndEnterprise()">-->
      <!--&lt;!&ndash;<mat-icon>account_circle</mat-icon>&ndash;&gt;-->
      <!--<span>{{ 'CLIENTS.CREATE_CLIENT_ENTERPRISE' | translate }}</span>-->
      <!--</button>-->
    </div>
  </div>
  <!-- / SUB HEADER -->

  <div
    *ngIf="enterprise && !editable"
    fxLayout="row"
    fxLayoutAlign="center center"
    style="height: 38px; min-height: 38px; max-height: 38px"
    class="gt2-gray mat-elevation-z1 py-8"
  >
    <div class="text-white font-weight-600">
      <b>{{ "GENERIC.READ_ONLY_MODE" | translate }}</b> :
      {{ "GENERIC.ARCHIVE_PAGE" | translate }}
    </div>
  </div>

  <!--HISTORY SIDEBAR-->
  <div *ngIf="!isSmallScreen" class="container-history-panel">
    <fuse-sidebar
      *ngIf="enterpriseID"
      name="enterpriseHistoryPanel"
      position="right"
      class="section-history-panel"
    >
      <app-section-history-panel
        *ngIf="enterprise"
        panelName="enterpriseHistoryPanel"
        section="enterprises"
        sectionUUID="{{ enterpriseID }}"
      >
      </app-section-history-panel>
    </fuse-sidebar>
  </div>
  <!-- / HISTORY SIDEBAR-->

  <!-- CONTENT -->
  <div class="content">
    <!--HISTORY SIDEBAR BUTTON-->
    <div *ngIf="!isSmallScreen">
      <!-- <div *ngIf="editable"> -->
      <button
        mat-icon-button
        class="warn mat-elevation-z2 historic-button"
        *fuseIfOnDom
        [@animate]="{
          value: '*',
          params: { delay: '300ms', scale: '.2' },
        }"
        (click)="toggleHistoricSidebarOpen('enterpriseHistoryPanel')"
      >
        <mat-icon class="icon-mat">history</mat-icon>
      </button>
      <!-- </div> -->
    </div>
    <!-- /HISTORY SIDEBAR BUTTON-->

    <!-- CENTER -->
    <div class="center p-24 pb-56 pr-sm-72" fusePerfectScrollbar>
      <!-- CONTENT -->
      <div class="enterprise-content">
        <!--TAGS-->
        <div fxLayout="row" fxLayoutAlign="start end">
          <!-- <app-tags-by-model #tags
                        fxFlex="100%"
                        class="ml-4"
                        [tagPlaceholder]="'GENERIC.NEW_TAG' | translate"
                        [tagFamily]="['tags-language', 'tags-recurrent-event', 'tags-client-crm', 'tags-client-type', 'tags-client-custom']"
                        [customTagFamily]="'tags-client-custom'"
                        [tagModel]="'enterprise'"
                        [tagModelUUID]="enterpriseID">
                    </app-tags-by-model> -->
        </div>

        <div
          *ngIf="enterprise"
          class="profile-box info-box general"
          fxLayout="column"
        >
          <header class="accent">
            <div class="title">
              {{ "GENERIC.INFORMATION" | translate }}
            </div>
          </header>
          <mat-tab-group
            animationDuration="100ms"
            mat-stretch-tabs="false"
            mat-align-tabs="start"
            #succursaleInfoMatTabGroup
            dynamicHeight
          >
            <mat-tab label="{{ 'GENERIC.GENERAL' | translate }}">
              <div class="content" fxLayout="column">
                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="start start"
                >
                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "GENERIC.NAME" | translate }}
                    </div>
                    <div class="info">
                      <span class="text-truncate">{{ enterprise.name }}</span>
                    </div>
                    <div
                      *ngIf="!enterprise.name"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field
                          class="form-input-container"
                          fxFlex="100%"
                        >
                          <mat-label>{{
                            "GENERIC.NAME" | translate
                          }}</mat-label>
                          <input
                            matInput
                            formControlName="name"
                            type="text"
                            [(ngModel)]="enterprise.name"
                            required
                          />
                          <mat-error *ngIf="form.controls['name'].invalid"
                            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "CLIENTS.ENTERPRISE_NUMBER" | translate }}
                    </div>
                    <div class="info">
                      {{ enterprise.enterprise_number }}
                    </div>
                    <div
                      *ngIf="!enterprise.enterprise_number"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field
                          class="form-input-container"
                          fxFlex="100%"
                        >
                          <mat-label>{{
                            "CLIENTS.ENTERPRISE_NUMBER" | translate
                          }}</mat-label>
                          <input
                            matInput
                            formControlName="enterprise_number"
                            type="text"
                            [(ngModel)]="enterprise.enterprise_number"
                            required
                          />
                          <mat-error
                            *ngIf="form.controls['enterprise_number'].invalid"
                            >{{
                              "GENERIC.MODEL_NUMBER_INPUT_LENGTH_INVALID"
                                | translate
                            }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="start start"
                >
                  <div *ngIf="!editMode" class="info-line" fxFlex="50%">
                    <div class="title">
                      {{ "GENERIC.WEBSITE" | translate }}
                    </div>
                    <a (click)="onOpenWebSite()" class="button-cursor">
                      {{ enterprise.website | displayHtmlLinkPipe }}</a
                    >
                    <div
                      *ngIf="!enterprise.website"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field
                          class="form-input-container"
                          fxFlex="100%"
                        >
                          <mat-label>{{
                            "GENERIC.WEBSITE" | translate
                          }}</mat-label>
                          <input
                            matInput
                            formControlName="website"
                            type="text"
                            [(ngModel)]="enterprise.website"
                          />
                          <mat-error
                            *ngIf="form.controls['enterprise_number'].invalid"
                            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab
              *ngIf="enterprise.child"
              label="{{ 'GENERIC.BRANCH' | translate }}"
            >
              <div *ngIf="editMode" fxLayout="row" fxLayoutAlign="end center">
                <button
                  type="button"
                  (click)="onAddSuccursale()"
                  mat-icon-button
                  matTooltip="{{ 'CLIENTS.ADD_BRANCH' | translate }}"
                  [matTooltipPosition]="'left'"
                  class="add-branch-button"
                >
                  <mat-icon color="accent">add</mat-icon>
                </button>
              </div>
              <div class="content" fxLayout="column">
                <div *ngIf="enterprise && enterprise.child">
                  <app-collection-table
                    #succursaleCollectionTable
                    class="pt-20"
                    [collectionData]="enterprise.child"
                    [displayedColumns]="['name']"
                    [dynamicTableItemAction]="succursaleItemAction"
                  >
                  </app-collection-table>
                </div>
              </div>
            </mat-tab>

            <!-- <mat-tab *ngIf="moduleService.modulesAreSet && moduleService.hasCTReport()" label="{{ 'GENERIC.DISCOUNT_OR_SURCHARGE' | translate }}">
                            <div fxFlex fxLayout="column" fusePerfectScrollbar>

                                
                                <div class="" fxLayout="column" fxFlex="100">
                                    <div class="" fxLayout="row" fxFlex="100">
                                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100"
                                            class="section-list-header"
                                            style="background-color: #fafafa; height: 42px;">

                                            <div fxFlex class="section-list-item-border-right">
                                                <div class="p-4 pl-12">{{ "GENERIC.NAME" | translate }}</div>
                                            </div>
                                            <div fxFlex class="section-list-item section-list-item-border-right">
                                                <div class="p-4">{{ "GENERIC.DISCOUNT_OR_SURCHARGE" | translate }}</div>
                                            </div>

                                            <div fxFlex="15" class="" fxLayout="row" fxLayoutAlign="end start">
                                                <div *ngIf="editable"
                                                    class="gt2-light-green" style="width: 42px; height: 42px;"
                                                    fxLayout="row" fxLayoutAlign="center center">
                                                    <button type="button"
                                                        matTooltip="3 {{ 'GENERIC.ADD_SERVICE_CHARGE' | translate }}"
                                                        mat-icon-button class="add-product"
                                                        (click)="onCreateNewEnterpriseServiceCharge()">
                                                        <mat-icon class="" color="">{{ getIcon('ADD') }}</mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div>
                                        <div fxFlex="100">
                                            <div *ngFor="let item of enterprise.service_charges"
                                                fxLayout="row" fxLayout="start center" class="child-item">
                                                <div fxFlex fxLayout="row" fxLayout="start center"
                                                    class="section-list-item-border-right pl-8 py-8">
                                                    {{ item.name }}
                                                </div>
                                                <div fxFlex
                                                    class="section-list-item section-list-item-border-right py-8">
                                                    {{ item.percentage_by_enterprise }}
                                                </div>

                                                <div fxFlex="15" class="section-list-item">
                                                    <button class="" mat-icon-button
                                                        *ngxPermissionsOnly="rolesService.SALE_ROLES"
                                                        [disabled]="!editable" tabIndex="-1"
                                                        (click)="onEditEnterpriseServiceCharge(item)">
                                                        <mat-icon class="s-16">{{ getIcon("EDIT") }}</mat-icon>
                                                    </button>
                                                    <button class="" mat-icon-button
                                                        *ngxPermissionsOnly="rolesService.SALE_ROLES" tabIndex="-1"
                                                        [disabled]="!editable || loading"
                                                        (click)="onDeleteEnterpriseServiceCharge(item)">
                                                        <mat-icon class="s-16">{{ getIcon("DELETE") }}</mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                
                                <div *ngIf="enterprise.service_charges && enterprise.service_charges.length === 0"
                                    fxFlex="100" fxLayout="row" fxLayoutAlign="center center">
                                    <div class="font-size-20 my-16">{{ 'GENERIC.NO_SERVICE_CHARGE' | translate }}</div>
                                </div>

                                
                                <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center center"
                                    style="height: 100%; width: 100%;">
                                    <mat-spinner [diameter]="40" class="my-40"></mat-spinner>
                                </div>
                            </div>
                        </mat-tab> -->

            <mat-tab
              label="{{ 'GENERIC.CLIENTS' | translate }} {{
                enterpriseClients.clientsResultsLength > 0
                  ? '(' + enterpriseClients.clientsResultsLength + ')'
                  : ''
              }}"
              [disabled]="!enterpriseClients.hasClients"
            >
              <div class="" fxLayout="column">
                <app-enterprise-clients
                  #enterpriseClients
                  [enterpriseUUID]="enterpriseID"
                ></app-enterprise-clients>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>

        <div *ngIf="enterprise" class="" fxLayout="column">
          <div
            *ngIf="
              moduleService.modulesAreSet && moduleService.hasMultiCashing()
            "
            class=""
            fxLayout="column"
          >
            <app-enterprise-invoice
              #invoices
              [(parentUUID)]="enterpriseID"
              [(roomsServiceURL)]="roomsServiceURL"
              [editMode]="editMode"
            >
            </app-enterprise-invoice>
          </div>
        </div>

        <div
          class="profile-box info-box general mt-12"
          style="min-width: 1023px"
          fxLayout="column"
        >
          <header class="accent" fxLayout="column">
            <div
              fxFlex="100"
              fxLayout="row"
              fxLayoutAlign="space-between center"
            >
              <div class="title">
                {{ "GENERIC.EVENTS" | translate }}
              </div>

              <div
                fxLayout="column"
                *ngxPermissionsOnly="rolesService.ACCOUNTANT_ROLES"
              >
                <div
                  *ngIf="
                    dataSource && dataSource.data && dataSource.data.length > 0
                  "
                >
                  <button
                    type="button"
                    mat-icon-button
                    class=""
                    matTooltip="{{
                      'REPORTS_HELP.REPORT_CLIENT_STATEMENT_CLIENT' | translate
                    }}"
                  >
                    <mat-icon class="s-20" color="">help</mat-icon>
                  </button>

                  <div
                    [formGroup]="formDates"
                    fxFlex
                    fxLayout="row"
                    fxLayoutAlign="end center"
                    class="pl-24 pr-24 dates-assignments"
                  >
                    <mat-form-field
                      class="custom-date-picker mr-24"
                      style="margin-bottom: -25px"
                    >
                      <mat-label>{{ "GENERIC.START" | translate }}</mat-label>
                      <input
                        matInput
                        formControlName="date_from1"
                        [matDatepicker]="eventDateFrom1"
                        min="1900-01-01"
                        max="2099-01-01"
                        (ngModelChange)="onDateFromChange1($event)"
                        [(ngModel)]="dateRange1.date_from"
                        class=""
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="eventDateFrom1"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #eventDateFrom1></mat-datepicker>

                      <mat-error
                        *ngIf="formDates.controls['date_from1'].invalid"
                        >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field
                      class="custom-date-picker"
                      style="margin-bottom: -25px"
                    >
                      <mat-label>{{ "GENERIC.END" | translate }}</mat-label>
                      <input
                        matInput
                        formControlName="date_to1"
                        [matDatepicker]="eventDateTo1"
                        min="1900-01-01"
                        max="2099-01-01"
                        (ngModelChange)="onDateToChange1($event)"
                        [(ngModel)]="dateRange1.date_to"
                        class=""
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="eventDateTo1"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #eventDateTo1></mat-datepicker>

                      <mat-error *ngIf="formDates.controls['date_to1'].invalid"
                        >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
                      </mat-error>
                    </mat-form-field>

                    <div class="ml-16 mb-4">
                      <button
                        mat-icon-button
                        class=""
                        [matMenuTriggerFor]="menuFrom1"
                      >
                        <mat-icon class="">more</mat-icon>
                      </button>
                    </div>
                    <mat-menu #menuFrom1="matMenu">
                      <button mat-menu-item (click)="onSelectToday1()">
                        {{ "GENERIC.TODAY" | translate }}
                      </button>
                      <button mat-menu-item (click)="onSelectThisWeek1()">
                        {{ "GENERIC.WEEK" | translate }}
                      </button>
                      <button mat-menu-item (click)="onSelectThisMonth1()">
                        {{ "GENERIC.MONTH" | translate }}
                      </button>
                    </mat-menu>
                  </div>
                </div>
              </div>
            </div>
            <!-- reports -->
            <div
              *ngIf="
                dataSource && dataSource.data && dataSource.data.length > 0
              "
              class="mt-16"
              fxFlex="100"
              fxLayout="row"
              fxLayoutAlign="end center"
            >
              <!-- Invoices by enterprise report xsl -->
              <button
                mat-raised-button
                class="mr-8"
                color="primary"
                [matMenuTriggerFor]="eventsReportInvoicesMenu"
              >
                <mat-icon class="s-28">{{ getIcon("REPORT") }}</mat-icon>
                {{ "CLIENTS.GENERATE_ENTERPRISE_INVOICES" | translate }}
              </button>
              <mat-menu #eventsReportInvoicesMenu="matMenu">
                <button mat-menu-item [matMenuTriggerFor]="eventsPdfMenu">
                  PDF
                </button>
                <button
                  mat-menu-item
                  *ngIf="
                    moduleService.modulesAreSet && moduleService.hasJRReport()
                  "
                  (click)="onDownloadXLS()"
                  matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
                >
                  Excel
                </button>
              </mat-menu>
              <mat-menu #eventsPdfMenu="matMenu">
                <button
                  mat-menu-item
                  *ngIf="fileService.printEnabled()"
                  (click)="
                    menuReportsService.buildAndRunVo(
                      ReportsEnum.REPORT2_INVOICES_BY_ENTERPRISE,
                      DistributionModeEnum.FILE,
                      ExportFormatEnum.PDF_STACK,
                      ExportToEnum.PRINT,
                      LocaleEnum.FR_CA,
                      PaperEnum.LEGAL,
                      dateRange1
                    )
                  "
                >
                  {{ "GENERIC.PRINT" | translate }}
                </button>
                <button
                  mat-menu-item
                  (click)="
                    menuReportsService.buildAndRunVo(
                      ReportsEnum.REPORT2_INVOICES_BY_ENTERPRISE,
                      DistributionModeEnum.FILE,
                      ExportFormatEnum.PDF_STACK,
                      ExportToEnum.DOWNLOAD,
                      LocaleEnum.FR_CA,
                      PaperEnum.LETTER,
                      dateRange1
                    );
                    $event.stopPropagation()
                  "
                >
                  {{ "GENERIC.DOWNLOAD" | translate }}
                </button>
              </mat-menu>
              <!-- Invoices by enterprise report End -->
              <!-- Historic report xsls -->
              <button
                mat-raised-button
                class="mr-8"
                color="primary"
                matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
                (click)="onDownloadHistoricXLS()"
              >
                <mat-icon class="s-28">{{ getIcon("REPORT") }}</mat-icon>
                {{ "CLIENTS.GENERATE_ACCOUNT_HISTORIC" | translate }}
              </button>
              <!-- Historic report End -->

              <!-- ACCOUNT STATEMENT report -->

              <button
                mat-raised-button
                color="primary"
                [matMenuTriggerFor]="eventsReportMenu"
              >
                <mat-icon class="s-28">{{ getIcon("REPORT") }}</mat-icon>
                {{ "CLIENTS.GENERATE_ACCOUNT_STATEMENT" | translate }}
              </button>
              <mat-menu #eventsReportMenu="matMenu">
                <button
                  mat-menu-item
                  [matMenuTriggerFor]="eventsReportMenuNormalMode"
                >
                  {{ "GENERIC.REPORT_MODE_NORMAL" | translate }}
                </button>
                <button
                  mat-menu-item
                  [matMenuTriggerFor]="eventsReportMenuToDateNowMode"
                >
                  {{ "GENERIC.REPORT_MODE_DATE_NOW" | translate }}
                </button>
              </mat-menu>
              <mat-menu #eventsReportMenuNormalMode="matMenu">
                <button
                  mat-menu-item
                  *ngIf="fileService.printEnabled()"
                  (click)="onPrintEventsReport('normal')"
                >
                  {{ "GENERIC.PRINT" | translate }}
                </button>
                <button mat-menu-item (click)="onDowloadEventsReport('normal')">
                  {{ "GENERIC.DOWNLOAD" | translate }}
                </button>
                <button mat-menu-item (click)="onSendEventsReport('normal')">
                  {{ "GENERIC.SEND" | translate }}
                </button>
              </mat-menu>
              <mat-menu #eventsReportMenuToDateNowMode="matMenu">
                <button
                  mat-menu-item
                  *ngIf="fileService.printEnabled()"
                  (click)="onPrintEventsReport('date_now')"
                >
                  {{ "GENERIC.PRINT" | translate }}
                </button>
                <button
                  mat-menu-item
                  (click)="onDowloadEventsReport('date_now')"
                >
                  {{ "GENERIC.DOWNLOAD" | translate }}
                </button>
                <button mat-menu-item (click)="onSendEventsReport('date_now')">
                  {{ "GENERIC.SEND" | translate }}
                </button>
              </mat-menu>
              <!-- ACCOUNT STATEMENT report END -->
            </div>
          </header>

          <div class="" fxLayout="column">
            <div
              *ngIf="
                !isLoadingResults &&
                dataSource.data &&
                dataSource.data.length === 0
              "
              fxLayout="row"
              fxLayoutAlign="center center"
              class="my-16 font-size-20 font-weight-500"
            >
              {{ "GENERIC.NO_RESULTS" | translate }}
            </div>

            <mat-table
              #table
              class=""
              matSort
              matSortStart="desc"
              [ngStyle]="{
                display:
                  dataSource.data && dataSource.data.length === 0 ? 'none' : '',
              }"
              [@animateStagger]="{ value: '50' }"
              [dataSource]="dataSource"
            >
              <!-- Name Column -->
              <ng-container cdkColumnDef="name">
                <mat-header-cell fxFlex="50" *cdkHeaderCellDef mat-sort-header
                  >{{ "GENERIC.NAME" | translate }}
                </mat-header-cell>
                <mat-cell fxFlex="50" *cdkCellDef="let row">
                  <div>
                    {{ row.name }}
                  </div>
                </mat-cell>
              </ng-container>

              <!-- event_number Column -->
              <ng-container cdkColumnDef="event_number">
                <mat-header-cell fxFlex="15" *cdkHeaderCellDef mat-sort-header
                  >{{ "CLIENTS.EVENT_NUMBER" | translate }}
                </mat-header-cell>
                <mat-cell fxFlex="15" *cdkCellDef="let row">
                  <div>
                    {{ row.event_number }}
                  </div>
                </mat-cell>
              </ng-container>

              <!-- event_date Column -->
              <ng-container cdkColumnDef="event_date">
                <mat-header-cell fxFlex="18" *cdkHeaderCellDef mat-sort-header
                  >{{ "CLIENTS.EVENT_DATE" | translate }}
                </mat-header-cell>
                <mat-cell fxFlex="18" *cdkCellDef="let row">
                  <div>
                    {{ row.event_date_label }}
                  </div>
                </mat-cell>
              </ng-container>

              <!-- status Column -->
              <ng-container cdkColumnDef="status">
                <mat-header-cell fxFlex="18" *cdkHeaderCellDef mat-sort-header
                  >{{ "GENERIC.STATUS" | translate }}
                </mat-header-cell>
                <mat-cell fxFlex="18" *cdkCellDef="let row">
                  <div>
                    {{ row.status_label }}
                  </div>
                </mat-cell>
              </ng-container>

              <!-- Shop name Column -->
              <ng-container cdkColumnDef="shop_name">
                <mat-header-cell fxFlex="18" *cdkHeaderCellDef mat-sort-header
                  >{{ "CLIENTS.SHOP_NAME" | translate }}
                </mat-header-cell>
                <mat-cell fxFlex="18" *cdkCellDef="let row">
                  <div>
                    {{ row.shop.name }}
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container cdkColumnDef="btn">
                <mat-header-cell fxFlex="100px" *cdkHeaderCellDef>
                  <div
                    *ngIf="isLoadingResults"
                    fxLayout="row"
                    fxLayoutAlign="center center"
                  >
                    <mat-spinner [diameter]="20" class="my-12"></mat-spinner>
                  </div>
                </mat-header-cell>
                <mat-cell fxFlex="100px" *cdkCellDef="let row">
                  <mat-icon
                    [ngStyle]="{
                      visibility: row.congress_uuid ? '' : 'hidden',
                    }"
                    class="s-24 mr-12"
                    >{{ getIcon("CONGRESS") }}</mat-icon
                  >
                  <button
                    mat-button
                    matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
                    (click)="onOpenEventInNewTab(row); $event.stopPropagation()"
                    class="mat-icon-button"
                  >
                    <mat-icon class="s-24">{{ getIcon("NEW_TAB") }}</mat-icon>
                  </button>
                </mat-cell>
              </ng-container>

              <mat-header-row
                *cdkHeaderRowDef="displayedColumns"
              ></mat-header-row>
              <mat-row
                *cdkRowDef="let row; columns: displayedColumns"
                (click)="onNavigateToItem(row)"
                matRipple
                [@animate]="{
                  value: '*',
                  params: { y: '100%' },
                }"
              >
              </mat-row>
            </mat-table>
            <mat-paginator
              #paginator
              [length]="resultsLength"
              (page)="datatableHelperService.onPaginator($event, 'enterprise')"
              [ngStyle]="{
                display:
                  dataSource.data && dataSource.data.length === 0 ? 'none' : '',
              }"
              showFirstLastButtons="true"
              [pageIndex]="0"
              [pageSize]="'enterprise' | tableSizePipe: 'short'"
              [pageSizeOptions]="datatableHelperService.pageSizeMedium2"
            >
            </mat-paginator>
          </div>
        </div>

        <div *ngIf="enterprise" class="" fxLayout="column">
          <div class="" fxLayout="column">
            <app-rooms
              #rooms
              [(roomsServiceURL)]="roomsServiceURL"
              [parentType]="'ENTERPRISE'"
              [(parentUUID)]="enterpriseID"
              [editMode]="editMode"
              [breadCrumbName]="'GENERIC.ENTERPRISE' | translate"
            ></app-rooms>
          </div>
        </div>

        <div
          *ngIf="enterprise && enterprise.contact"
          style="min-width: 500px"
          class="profile-box info-box general mt-12"
          fxLayout="column"
        >
          <header
            class="accent"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <div class="title">
              {{ "GENERIC.CONTACT" | translate }}
            </div>
            <button
              mat-raised-button
              (click)="copyEnterpriseAddressInAllAttachedClients()"
              class="primary"
            >
              {{ "GENERIC.ENTERPRISE_COPY_ADDRESS_CLIENT" | translate }}
            </button>
          </header>

          <mat-tab-group
            animationDuration="100ms"
            mat-stretch-tabs="false"
            mat-align-tabs="start"
          >
            <mat-tab label="{{ 'GENERIC.GENERAL' | translate }}">
              <div class="">
                <app-contact
                  #contactComponent
                  [groupModel]="enterprise.contact"
                  [editMode]="false"
                  [useAutosave]="false"
                  [hideSaveButton]="true"
                  class=""
                >
                </app-contact>
              </div>
            </mat-tab>

            <mat-tab label="{{ 'GENERIC.ADDRESS' | translate }}">
              <div class="">
                <app-address
                  #addressComponent
                  [groupModel]="enterprise.address"
                  [isVisible]="true"
                  [editMode]="false"
                  [useAutosave]="false"
                  class=""
                ></app-address>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>

        <div
          *ngIf="enterprise"
          class="profile-box info-box general"
          fxLayout="column"
        >
          <header class="accent">
            <div class="title">
              {{ "GENERIC.NOTES" | translate }}
            </div>
          </header>

          <div class="note-section">
            <p-editor
              #noteEditor
              [(ngModel)]="enterprise.note"
              [readonly]="!editMode"
              [style]="{
                height: '120px',
                width: '100%',
                'background-color': editMode ? '#ffffff' : '',
              }"
            >
              <ng-template pTemplate="header" class="">
                <span class="ql-formats">
                  <select class="ql-font"></select>
                  <select class="ql-size"></select>
                </span>

                <span class="ql-formats">
                  <button class="ql-bold"></button>
                  <button class="ql-italic"></button>
                  <button class="ql-underline"></button>
                  <button class="ql-strike"></button>
                </span>

                <span class="ql-formats">
                  <select class="ql-color"></select>
                  <select class="ql-background"></select>
                </span>

                <span class="ql-formats">
                  <button class="ql-list" value="ordered"></button>
                  <button class="ql-list" value="bullet"></button>
                  <button class="ql-indent" value="-1"></button>
                  <button class="ql-indent" value="+1"></button>
                </span>
              </ng-template>
            </p-editor>
            <mat-error
              *ngIf="noteInvalid"
              fxLayout="row"
              fxLayoutAlign="end center"
              class="p-4 pr-8"
            >
              {{ "GENERIC.NOTE_EDITOR_LENGTH_INVALID_2048" | translate }}
            </mat-error>
          </div>
        </div>

        <app-comment-section
          *ngIf="enterprise"
          class="ml-12 mr-12"
          [commentsModel]="enterprise"
          [disabled]="!editable"
          [serviceAPIUrl]="'/v1/enterprises/comments/' + enterprise.uuid"
        >
        </app-comment-section>

        <app-created-updated-at
          *ngIf="enterprise"
          class="mt-8 ml-12 mr-12"
          [historicModel]="enterprise.historic_model"
        ></app-created-updated-at>

        <div *ngxPermissionsOnly="rolesService.RH_ROLES">
          <div
            *ngIf="
              enterprise &&
              moduleService.modulesAreSet &&
              (moduleService.hasBTFReport() ||
                moduleService.hasBistroVReport() ||
                moduleService.hasSOTReport())
            "
            fxFlex="100%"
            fxLayout="row"
            fxLayoutAlign="center center"
            class="mt-8"
          >
            <button mat-raised-button (click)="deleteEnterprise()" class="warn">
              {{ "GENERIC.DELETE" | translate }}
            </button>
          </div>
        </div>
      </div>
      <!-- / CONTENT -->
    </div>
    <!-- / CENTER -->
  </div>
</div>

<div *ngxPermissionsOnly="rolesService.TEAM_LEAD_ROLES">
  <!-- <button mat-fab
            class="gt2-edit-button"
            id="mat-menu-fab-button"
            (click)="onEdit($event)"
            [disabled]="loading"
            *fuseIfOnDom [@animate]="{value:'*', params:{delay:'300ms',scale:'.2'}}">
        <mat-icon *ngIf="!editMode">{{ getIcon('EDIT_FAB') }}</mat-icon>
        <mat-icon *ngIf="editMode">{{ getIcon('UNDO') }}</mat-icon>
    </button> -->
  <div class="fixed-button">
    <button
      mat-fab
      #saveButton
      [ngClass]="editMode ? 'gt2-save-button' : 'gt2-edit-button'"
      id="edit-enterprise-button"
      (click)="onEdit($event)"
      [disabled]="loading || !editable"
      *fuseIfOnDom
      [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }"
    >
      <mat-icon *ngIf="!editMode && !loading" class="icon-mat-black">{{
        getIcon("EDIT_FAB")
      }}</mat-icon>
      <mat-icon
        *ngIf="editMode && !loading && !saveButton.disabled"
        matTooltip="{{ 'GENERIC.SAVE' | translate }}"
        position="left"
        showDelay="900"
        hideDelay="900"
        >{{ getIcon("SAVE") }}</mat-icon
      >
      <mat-icon
        *ngIf="editMode && !loading && saveButton.disabled"
        matTooltip="{{ 'GENERIC.FORM_DIRTY_TIP' | translate }}"
        position="left"
        hideDelay="900"
        >{{ getIcon("SAVE") }}</mat-icon
      >
      <i [ngClass]="loading ? 'fa fa-spinner fa-spin' : ''"></i>
    </button>
  </div>
  <div class="fixed-button2">
    <button
      mat-fab
      *ngIf="editable && editMode"
      id="cancel-edit-enterprise-button"
      class="gt2-cancel-edit-button"
      matTooltip="{{ 'GENERIC.CANCEL' | translate }}"
      position="left"
      showDelay="900"
      hideDelay="900"
      (click)="onCancelEdit($event)"
      [disabled]="loading"
      [@animate]="{ value: '*', params: { delay: '120ms', scale: '.2' } }"
    >
      <mat-icon class="s-24">{{ getIcon("CANCEL") }}</mat-icon>
    </button>
  </div>
</div>
