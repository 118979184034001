import { Subscription } from 'rxjs';
import { Editor, EditorModule } from 'primeng/editor';
import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { OrdersService } from '../../../api/orders.service';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { LabourChargeService } from '../../../api/labour-charge.service';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ValidatorHelper } from '../../../validators/ValidatorHelper';
//import { AmazingTimePickerService } from 'amazing-time-picker';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Gt2PrimengModule } from '../../../modules/gt2-primeng.module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { Gt2SelectComponent } from '../../gt2-select/gt2-select.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../../@fuse/pipes/pipes.module';
import { CalendarDateFormatter } from 'angular-calendar';
import { CustomDateFormatter } from '../../../content/calendar/CustomDateFormatter';
import { GT2DateAdapter } from '../../../utils/GT2DateAdapter';

@Component({
  selector: 'app-create-labour-charge',
  templateUrl: './create-labour-charge.component.html',
  styleUrls: ['./create-labour-charge.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    MatButtonModule,
    TranslateModule,
    EditorModule,
    MatInputModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    FormsModule,
    Gt2PrimengModule,
    Gt2SelectComponent,
    NgxMaterialTimepickerModule,
  ],
  providers: [
    LabourChargeService,
    { provide: DateAdapter, useClass: GT2DateAdapter },
    { provide: CalendarDateFormatter, useClass: CustomDateFormatter },
  ],
})
export class CreateLabourChargeComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  static CREATE: string = 'CREATE';
  static EDIT: string = 'EDIT';

  //
  mode: string = CreateLabourChargeComponent.CREATE;
  orderUUID!: string;
  loading: boolean = false;
  autoRedirect: boolean = false;
  // specificConditions: any[];
  // selectedSpecificCondition: any;
  // labourCharge: any;
  form: UntypedFormGroup;
  selectedLabourCharge: any;
  labourChargeToUpdate: any;
  @ViewChild('noteEditor') noteEditor?: Editor;
  noteEditorSubscription?: Subscription;

  constructor(
    public override dialogRef: MatDialogRef<CreateLabourChargeComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public orderService: OrdersService,
    public dialog: MatDialog,
    public router: Router,
    private adapter: DateAdapter<any>,
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService,
    //private atp: AmazingTimePickerService,
    public labourChargeService: LabourChargeService,
    private logger: NGXLogger,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    this.adapter.setLocale(this.translate.currentLang);

    // this.form = this.formBuilder.group({
    //   name: ['', [ValidatorHelper.isNumber0To1000000, Validators.required]],
    // });
    this.form = this.formBuilder.group({
      unit_price: [
        '',
        [ValidatorHelper.isNumber0To1000000, Validators.required],
      ],
      qty: ['', [ValidatorHelper.isNumber0To1000000, Validators.required]],
      from_date: ['', [ValidatorHelper.dateRangeDefault]],
      to_date: ['', [ValidatorHelper.dateRangeDefault]],
      // from_time: ['', [Validators.required]],
      // to_time: ['', [Validators.required]],
    });

    //
    // this.selectedLabourCharge = {
    //   selectedLabourCharge: null,
    //   price: null,
    //   qty: null
    // };

    if (data && data.mode !== null) {
      this.mode = data.mode;
    }

    if (data && data.labourChargeToUpdate) {
      this.labourChargeToUpdate = data.labourChargeToUpdate;
    }

    if (data && data.eventUUID !== null) {
      this.orderUUID = data.orderUUID;
    }

    if (data && data.autoRedirect !== null) {
      this.autoRedirect = data.autoRedirect;
    }
  }

  ngOnInit() {
    //this.logger.info("AddSpecificConditionComponent.ngOnInit() ");
  }

  ngAfterViewInit() {
    //this.logger.info("AddSpecificConditionComponent.ngAfterViewInit() " + JSON.stringify(this.labourChargeToUpdate));
  }

  ngOnDestroy() {
    if (this.noteEditorSubscription) {
      this.noteEditorSubscription.unsubscribe();
    }
  }

  public onSubmitLabourCharge() {
    this.loading = true;
    //this.logger.info("AddSpecificConditionComponent.onCreateSpecificCondition() " + this.mode);

    if (this.mode === CreateLabourChargeComponent.CREATE) {
      this.doCreateLabourCharge();
    } else if (this.mode === CreateLabourChargeComponent.EDIT) {
      this.updateLabourCharge();
    }
  }

  public doCreateLabourCharge() {
    //this.logger.info("CreateLabourChargeComponent.doCreateLabourCharge() -> labourCharge");
    this.orderService
      .addLabourCharge(this.orderUUID, {
        staffs: [this.selectedLabourCharge],
      })
      .subscribe({
        next: (response: any) => {
          // //this.logger.info("CreateLabourChargeComponent.doCreateLabourCharge() -> SUCCESS: " + JSON.stringify(response));
          this.loading = false;
          this.dialogRef.close(response.data);
        },
        error: (error: any) => {
          this.logger.error(
            'CreateLabourChargeComponent.doCreateLabourCharge() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.GENERIC'),
          );
          // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
        },
      });
  }

  public updateLabourCharge(): void {
    //this.logger.info("CreateLabourChargeComponent.updateLabourCharge() -> ***** this.labourChargeToUpdate : " + JSON.stringify(this.labourChargeToUpdate));
    // this.labourChargeToUpdate.note = "une note...";
    this.orderService
      .updateLabourCharges(this.orderUUID, [this.labourChargeToUpdate])
      .subscribe({
        next: (response: any) => {
          //this.logger.info("CreateLabourChargeComponent.updateLabourCharge() -> SUCCESS: " + JSON.stringify(response));
          this.loading = false;
          this.dialogRef.close(response.data);
        },
        error: (error: any) => {
          this.logger.error(
            'CreateLabourChargeComponent.updateLabourCharge() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.GENERIC'),
          );
          // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
        },
      });
  }

  public onLabourChargeChange(event: any): void {
    // //this.logger.info("CreateLabourChargeComponent.onLabourChargeChange() -> event: " + JSON.stringify(event));
    this.selectedLabourCharge = event;
    // this.labourCharge.price = this.labourCharge.selectedLabourCharge.price;
    // this.labourCharge.qty = 1;
  }
  public openStartTimePicker(): void {
    // const amazingTimePicker = this.atp.open({
    //   time: this.labourChargeToUpdate.from_time,
    // });
    // amazingTimePicker.afterClose().subscribe((time: any) => {
    //   this.labourChargeToUpdate.from_time = time;
    // });
  }
  public openEndTimePicker(): void {
    // const amazingTimePicker = this.atp.open({
    //   time: this.labourChargeToUpdate.to_time,
    // });
    // amazingTimePicker.afterClose().subscribe((time: any) => {
    //   this.labourChargeToUpdate.to_time = time;
    // });
  }
}
