<div
  id="products"
  class="page-layout simple fullwidth"
  fxLayout="column"
  fusePerfectScrollbar
>
  <!-- HEADER -->
  <div class="gt2-theme">
    <div
      class="header accent p-24 h-80 mat-elevation-z1"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <button
        class="mr-0 mr-sm-16 white-fg"
        mat-icon-button
        (click)="onPageBack(location, router)"
      >
        <mat-icon class="icon-mat">arrow_back</mat-icon>
      </button>
      <div fxLayout="column" fxLayoutAlign="center start">
        <div class="user-info" fxLayout="row" fxLayoutAlign="start start">
          <div
            class="products-title"
            *fuseIfOnDom
            [@animate]="{ value: '*', params: { delay: '100ms', x: '-25px' } }"
          >
            <!--<div *ngIf="employee">{{employee.first_name}} {{employee.last_name}}</div>-->
            <div>{{ "GENERIC.SEARCH" | translate | titlecase }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- / HEADER -->

  <div
    class="result-info"
    fxLayout="row"
    style="padding: 20px"
    fxLayoutAlign="space-between center"
    fxLayout.xs="column"
    fxLayoutAlign.xs="start start"
  >
    <span class="result-count h3 secondary-text">
      <span>{{ searchData.length }}</span
      >&nbsp;
      <span>{{ "SEARCH.RESULTS_FOR" | translate }}</span>
      <span>"{{ searchString }}"</span>
    </span>
  </div>
  <div class="classic-tab p-24" fxFlex fusePerfectScrollbar>
    <div class="results">
      <div class="result-item" *ngFor="let item of searchData">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div fxLayout="column" fxLayoutAlign="start start" class="mr-12">
            <div>
              <div [ngSwitch]="item.type">
                <mat-icon *ngSwitchCase="'Employee'" class="s-40">{{
                  getIcon("EMPLOYEE")
                }}</mat-icon>
                <mat-icon *ngSwitchCase="'Room'" class="s-40">{{
                  getIcon("ROOM")
                }}</mat-icon>
                <mat-icon *ngSwitchCase="'Place'" class="s-40">{{
                  getIcon("PLACE")
                }}</mat-icon>
                <mat-icon
                  *ngSwitchCase="'Client'"
                  class="fa-3x"
                  style="height: 40px; width: 40px"
                  fontSet="fa"
                  fontIcon="{{ getIcon('CLIENT') }}"
                ></mat-icon>
                <mat-icon *ngSwitchCase="'Enterprise'" class="s-40">{{
                  getIcon("ENTERPRISE")
                }}</mat-icon>
                <mat-icon *ngSwitchCase="'Product'" class="s-40">{{
                  getIcon("PRODUCT")
                }}</mat-icon>
                <mat-icon *ngSwitchCase="'Material'" class="s-40">{{
                  getIcon("MATERIAL")
                }}</mat-icon>
                <mat-icon *ngSwitchCase="'Event'" class="s-40">{{
                  getIcon("EVENT")
                }}</mat-icon>
                <mat-icon *ngSwitchCase="'Project'" class="s-40">{{
                  getIcon("PROJECT")
                }}</mat-icon>
                <mat-icon *ngSwitchCase="'Congress'" class="s-40">{{
                  getIcon("CONGRESS")
                }}</mat-icon>

                <div *ngSwitchCase="'Order'">
                  <mat-icon
                    *ngIf="
                      item.subtype === 'Commande' || item.subtype === 'Order'
                    "
                    class="s-40"
                    >{{ getIcon("ORDER") }}</mat-icon
                  >
                  <mat-icon
                    *ngIf="
                      item.subtype === 'Proposition' ||
                      item.subtype === 'Proposal'
                    "
                    class="s-40"
                    >{{ getIcon("PROPOSAL") }}</mat-icon
                  >
                  <mat-icon
                    *ngIf="
                      item.subtype === 'Facture' || item.subtype === 'Invoice'
                    "
                    class="s-40"
                    >{{ getIcon("INVOICE") }}</mat-icon
                  >
                </div>
                <!--<div *ngSwitchDefault>DEFAULT ngSwitchCase not found</div>-->
              </div>
            </div>
          </div>
          <div
            fxFlex="80"
            fxLayout="column"
            fxLayoutAlign="start start"
            (click)="onSearchItem(item)"
          >
            <div class="title blue-fg">{{ item.label }}</div>
            <div class="excerpt">{{ item.message }}</div>
          </div>

          <div fxLayout="row" fxLayoutAlign="end start">
            <button
              mat-button
              matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
              (click)="onOpenInNewTab(item)"
              class="mat-icon-button"
            >
              <mat-icon class="s-24">{{ getIcon("NEW_TAB") }}</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
