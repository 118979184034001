<div style="max-height: 100%; overflow: auto">
  <div
    fxLayout="row"
    fxLayoutAlign="space-between start"
    (keyup.enter)="form.valid && !loading ? onCreateClientEnterprise() : null"
  >
    <div class="mr-24" fxLayout="column" fxLayoutAlign="center start" fx="50%">
      <h1 matDialogTitle class="center">
        {{ "CREATE_CLIENT.TITLE" | translate }}
      </h1>

      <div
        fxLayout="column"
        fxLayoutAlign="center start"
        [formGroup]="form"
        class="mt-8 create-client-width"
      >
        <mat-form-field class="create-client-width">
          <mat-label>{{ "GENERIC.FIRST_NAME" | translate }}</mat-label>
          <input
            matInput
            fxFlex="100%"
            formControlName="first_name"
            type="text"
            autocomplete="nope"
            [(ngModel)]="clientEnterprise.client.first_name"
            required
          />
          <mat-error *ngIf="form.controls['first_name'].invalid">{{
            "GENERIC.INPUT_LENGTH_INVALID" | translate
          }}</mat-error>
        </mat-form-field>

        <mat-form-field class="create-client-width">
          <mat-label>{{ "GENERIC.LAST_NAME" | translate }}</mat-label>
          <input
            matInput
            formControlName="last_name"
            autocomplete="nope"
            type="text"
            [(ngModel)]="clientEnterprise.client.last_name"
            required
          />
          <mat-error *ngIf="form.controls['last_name'].invalid">{{
            "GENERIC.INPUT_LENGTH_INVALID" | translate
          }}</mat-error>
        </mat-form-field>
        <div class="info-editable" style="width: 100%">
          <app-gt2-select
            #wrapping
            [service]="gt2LocalService"
            [nullableUseNone]="false"
            [placeHolder]="'CREATE_CLIENT.COMM_LANGUAGE' | translate"
            [label]="'CREATE_CLIENT.COMM_LANGUAGE' | translate"
            [selectedModel]="clientEnterprise.client.communication_local"
            (changeEvent)="onLocalSelected($event)"
            [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
            fxFlex="100%"
            class="pr-4"
          >
          </app-gt2-select>
        </div>

        <h2>{{ "CREATE_CLIENT.TITLE_CONTACT" | translate }}</h2>
        <mat-form-field class="create-client-width">
          <mat-label>{{ "CONTACT.ORG_EMAIL" | translate }}</mat-label>
          <input
            matInput
            formControlName="professional_email"
            autocomplete="nope"
            type="email"
            [(ngModel)]="clientEnterprise.client.contact.email_organisation"
          />
          <mat-error *ngIf="form.controls['professional_email'].invalid">{{
            "GENERIC.EMAIL_INVALID" | translate
          }}</mat-error>
        </mat-form-field>
        <mat-form-field class="create-client-width" fxFlex="100%">
          <mat-label>{{ "CONTACT.OFFICE_PHONE" | translate }}</mat-label>
          <input
            matInput
            formControlName="telephone_office"
            [(ngModel)]="clientEnterprise.client.contact.telephone_office"
            type="tel"
          />
          <mat-error *ngIf="form.controls['telephone_office'].invalid"
            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="create-client-width" fxFlex="40%">
          <mat-label>{{ "CONTACT.OFFICE_EXT" | translate }}</mat-label>
          <input
            matInput
            formControlName="telephone_office_ext"
            type="text"
            [(ngModel)]="clientEnterprise.client.contact.telephone_office_ext"
          />
          <mat-error *ngIf="form.controls['telephone_office_ext'].invalid"
            >{{ "GENERIC.INPUT_LENGTH_INVALID_20" | translate }}
          </mat-error>
        </mat-form-field>

        <h2>{{ "CREATE_CLIENT.TITLE_ADDRESS" | translate }}</h2>
        <!-- <ngxgeo-autocomplete
          [userSettings]="userSettings"
          (componentCallback)="handleAddressEnterpriseChange($event)"
          class="app-address"
        >
        </ngxgeo-autocomplete> -->

        <mat-form-field class="create-client-width" fxFlex="100%">
          <mat-label>Adresse</mat-label>
          <input matInput placeholder="Entrez une adresse" #autocompleteInput />
        </mat-form-field>

        <mat-form-field class="create-client-width" fxFlex="100%">
          <mat-label
            >{{ "ADDRESS.ADDRESS_NUMBER" | translate }}# addresse</mat-label
          >
          <input
            matInput
            formControlName="street_number"
            type="text"
            [(ngModel)]="clientEnterprise.client.address.street_number"
            required
          />
          <mat-error *ngIf="form.controls['street_number'].invalid"
            >{{ "GENERIC.INPUT_LENGTH_INVALID_1" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="create-client-width" fxFlex="100%">
          <mat-label>{{ "ADDRESS.STREET" | translate }}</mat-label>
          <input
            matInput
            formControlName="street"
            type="text"
            [(ngModel)]="clientEnterprise.client.address.street"
            required
          />
          <mat-error *ngIf="form.controls['street'].invalid"
            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="create-client-width" fxFlex="100%">
          <mat-label>{{ "ADDRESS.APP_NUMBER" | translate }}</mat-label>
          <input
            matInput
            formControlName="apartment"
            [(ngModel)]="clientEnterprise.client.address.apartment"
            type="text"
          />
          <mat-error *ngIf="form.controls['apartment'].invalid"
            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="create-client-width" fxFlex="100%">
          <mat-label>{{ "ADDRESS.LINE_2" | translate }}</mat-label>
          <input
            matInput
            formControlName="line2"
            [(ngModel)]="clientEnterprise.client.address.line2"
            type="text"
          />
          <mat-error *ngIf="form.controls['line2'].invalid"
            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="create-client-width" fxFlex="100%">
          <mat-label>{{ "ADDRESS.CITY" | translate }}</mat-label>
          <input
            matInput
            formControlName="city"
            [(ngModel)]="clientEnterprise.client.address.city"
            type="text"
            required
          />
          <mat-error *ngIf="form.controls['city'].invalid"
            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="create-client-width" fxFlex="100%">
          <mat-label>{{ "ADDRESS.PROVINCE" | translate }}</mat-label>
          <input
            matInput
            formControlName="province"
            type="text"
            [(ngModel)]="clientEnterprise.client.address.province"
            required
          />
          <mat-error *ngIf="form.controls['province'].invalid"
            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="create-client-width" fxFlex="100%">
          <mat-label>{{ "ADDRESS.COUNTRY" | translate }}</mat-label>
          <input
            matInput
            formControlName="country"
            type="text"
            [(ngModel)]="clientEnterprise.client.address.country"
            required
          />
          <mat-error *ngIf="form.controls['country'].invalid"
            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="create-client-width" fxFlex="100%">
          <mat-label>{{ "ADDRESS.POSTAL_CODE" | translate }}</mat-label>
          <input
            matInput
            formControlName="postal_code"
            type="text"
            [(ngModel)]="clientEnterprise.client.address.postal_code"
            required
          />
          <mat-error *ngIf="form.controls['postal_code'].invalid"
            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
          </mat-error>
        </mat-form-field>

        <!-- CLIENT NOTES -->
        <div class="profile-box info-box general" fxLayout="column">
          <header class="accent">
            <div class="title">{{ "GENERIC.NOTES" | translate }}</div>
          </header>

          <div class="note-section">
            <p-editor
              #noteEditor
              [(ngModel)]="clientEnterprise.client.note"
              [style]="{
                height: '120px',
                width: '100%',
                'background-color': '#ffffff',
              }"
            >
              <ng-template pTemplate="header" class="">
                <span class="ql-formats">
                  <select class="ql-font"></select>
                  <select class="ql-size"></select>
                </span>

                <span class="ql-formats">
                  <button class="ql-bold"></button>
                  <button class="ql-italic"></button>
                  <button class="ql-underline"></button>
                  <button class="ql-strike"></button>
                </span>

                <span class="ql-formats">
                  <select class="ql-color"></select>
                  <select class="ql-background"></select>
                </span>

                <span class="ql-formats">
                  <button class="ql-list" value="ordered"></button>
                  <button class="ql-list" value="bullet"></button>
                  <button class="ql-indent" value="-1"></button>
                  <button class="ql-indent" value="+1"></button>
                </span>
              </ng-template>
            </p-editor>
            <!--</form>-->
          </div>
        </div>
      </div>
    </div>

    <div fxLayout="column" fxLayoutAlign="center start" fxFlex="50%">
      <h1 matDialogTitle class="center">
        {{ "CREATE_ENTERPRISE.TITLE" | translate }}
      </h1>

      <div
        fxLayout="column"
        fxLayoutAlign="center start"
        [formGroup]="form"
        class="mt-8 create-client-width"
      >
        <mat-form-field class="create-client-width">
          <mat-label>{{ "GENERIC.NAME" | translate }}</mat-label>
          <input
            matInput
            fxFlex="100%"
            formControlName="name"
            type="text"
            autocomplete="nope"
            [(ngModel)]="clientEnterprise.enterprise.name"
            required
          />
          <mat-error *ngIf="form2.controls['name'].invalid">{{
            "GENERIC.INPUT_LENGTH_INVALID" | translate
          }}</mat-error>
        </mat-form-field>

        <mat-form-field class="create-client-width" style="visibility: hidden">
          <mat-label>{{ "GENERIC.FIRST_NAME" | translate }}</mat-label>
          <input
            matInput
            fxFlex="100%"
            formControlName="name"
            type="text"
            autocomplete="nope"
            [(ngModel)]="clientEnterprise.enterprise.name"
            required
          />
          <mat-error *ngIf="form2.controls['name'].invalid">{{
            "GENERIC.INPUT_LENGTH_INVALID" | translate
          }}</mat-error>
        </mat-form-field>

        <mat-form-field class="create-client-width" style="visibility: hidden">
          <mat-label>{{ "GENERIC.FIRST_NAME" | translate }}</mat-label>
          <input
            matInput
            fxFlex="100%"
            formControlName="name"
            type="text"
            autocomplete="nope"
            [(ngModel)]="clientEnterprise.enterprise.name"
            required
          />
          <mat-error *ngIf="form2.controls['name'].invalid">{{
            "GENERIC.INPUT_LENGTH_INVALID" | translate
          }}</mat-error>
        </mat-form-field>

        <h2>{{ "CREATE_ENTERPRISE.TITLE_CONTACT" | translate }}</h2>
        <mat-form-field class="create-client-width">
          <mat-label>{{ "CONTACT.ORG_EMAIL" | translate }}</mat-label>
          <input
            matInput
            formControlName="professional_email"
            autocomplete="nope"
            type="email"
            [(ngModel)]="clientEnterprise.enterprise.contact.email_organisation"
          />
          <mat-error *ngIf="form2.controls['professional_email'].invalid">{{
            "GENERIC.EMAIL_INVALID" | translate
          }}</mat-error>
        </mat-form-field>
        <mat-form-field class="create-client-width" fxFlex="100%">
          <mat-label>{{ "CONTACT.OFFICE_PHONE" | translate }}</mat-label>
          <input
            matInput
            formControlName="telephone_office"
            [(ngModel)]="clientEnterprise.enterprise.contact.telephone_office"
            type="tel"
          />
          <mat-error *ngIf="form2.controls['telephone_office'].invalid"
            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="create-client-width" fxFlex="40%">
          <mat-label>{{ "CONTACT.OFFICE_EXT" | translate }}</mat-label>
          <input
            matInput
            formControlName="telephone_office_ext"
            type="text"
            [(ngModel)]="
              clientEnterprise.enterprise.contact.telephone_office_ext
            "
          />
          <mat-error *ngIf="form2.controls['telephone_office_ext'].invalid"
            >{{ "GENERIC.INPUT_LENGTH_INVALID_20" | translate }}
          </mat-error>
        </mat-form-field>

        <h2>{{ "CREATE_ENTERPRISE.TITLE_CONTACT" | translate }}</h2>
        <!-- <ngxgeo-autocomplete
          [userSettings]="userSettings"
          (componentCallback)="handleAddressEnterpriseChange($event)"
          class="app-address"
        >
        </ngxgeo-autocomplete> -->

        <mat-form-field class="create-client-width" fxFlex="100%">
          <mat-label>Adresse</mat-label>
          <input
            matInput
            placeholder="Entrez une adresse"
            #autocompleteInput2
          />
        </mat-form-field>
        <mat-form-field class="create-client-width" fxFlex="100%">
          <mat-label
            >{{ "ADDRESS.ADDRESS_NUMBER" | translate }}# addresse</mat-label
          >
          <input
            matInput
            formControlName="street_number"
            type="text"
            [(ngModel)]="clientEnterprise.enterprise.address.street_number"
            required
          />
          <mat-error *ngIf="form2.controls['street_number'].invalid"
            >{{ "GENERIC.INPUT_LENGTH_INVALID_1" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="create-client-width" fxFlex="100%">
          <mat-label>{{ "ADDRESS.STREET" | translate }}</mat-label>
          <input
            matInput
            formControlName="street"
            type="text"
            [(ngModel)]="clientEnterprise.enterprise.address.street"
            required
          />
          <mat-error *ngIf="form2.controls['street'].invalid"
            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="create-client-width" fxFlex="100%">
          <mat-label>{{ "ADDRESS.APP_NUMBER" | translate }}</mat-label>
          <input
            matInput
            formControlName="apartment"
            [(ngModel)]="clientEnterprise.enterprise.address.apartment"
            type="text"
          />
          <mat-error *ngIf="form2.controls['apartment'].invalid"
            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="create-client-width" fxFlex="100%">
          <mat-label>{{ "ADDRESS.LINE_2" | translate }}</mat-label>
          <input
            matInput
            formControlName="line2"
            [(ngModel)]="clientEnterprise.enterprise.address.line2"
            type="text"
          />
          <mat-error *ngIf="form2.controls['line2'].invalid"
            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="create-client-width" fxFlex="100%">
          <mat-label>{{ "ADDRESS.CITY" | translate }}</mat-label>
          <input
            matInput
            formControlName="city"
            [(ngModel)]="clientEnterprise.enterprise.address.city"
            type="text"
            required
          />
          <mat-error *ngIf="form2.controls['city'].invalid"
            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="create-client-width" fxFlex="100%">
          <mat-label>{{ "ADDRESS.PROVINCE" | translate }}</mat-label>
          <input
            matInput
            formControlName="province"
            type="text"
            [(ngModel)]="clientEnterprise.enterprise.address.province"
            required
          />
          <mat-error *ngIf="form2.controls['province'].invalid"
            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="create-client-width" fxFlex="100%">
          <mat-label>{{ "ADDRESS.COUNTRY" | translate }}</mat-label>
          <input
            matInput
            formControlName="country"
            type="text"
            [(ngModel)]="clientEnterprise.enterprise.address.country"
            required
          />
          <mat-error *ngIf="form2.controls['country'].invalid"
            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="create-client-width" fxFlex="100%">
          <mat-label>{{ "ADDRESS.POSTAL_CODE" | translate }}</mat-label>
          <input
            matInput
            formControlName="postal_code"
            type="text"
            [(ngModel)]="clientEnterprise.enterprise.address.postal_code"
            required
          />
          <mat-error *ngIf="form2.controls['postal_code'].invalid"
            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
          </mat-error>
        </mat-form-field>

        <!-- ENTERPRISE NOTES -->
        <div class="profile-box info-box general" fxLayout="column">
          <header class="accent">
            <div class="title">{{ "GENERIC.NOTES" | translate }}</div>
          </header>

          <div class="note-section">
            <p-editor
              #noteEditor
              [(ngModel)]="clientEnterprise.enterprise.note"
              [style]="{
                height: '120px',
                width: '100%',
                'background-color': '#ffffff',
              }"
            >
              <ng-template pTemplate="header" class="">
                <span class="ql-formats">
                  <select class="ql-font"></select>
                  <select class="ql-size"></select>
                </span>

                <span class="ql-formats">
                  <button class="ql-bold"></button>
                  <button class="ql-italic"></button>
                  <button class="ql-underline"></button>
                  <button class="ql-strike"></button>
                </span>

                <span class="ql-formats">
                  <select class="ql-color"></select>
                  <select class="ql-background"></select>
                </span>

                <span class="ql-formats">
                  <button class="ql-list" value="ordered"></button>
                  <button class="ql-list" value="bullet"></button>
                  <button class="ql-indent" value="-1"></button>
                  <button class="ql-indent" value="+1"></button>
                </span>
              </ng-template>
            </p-editor>
            <!--</form>-->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center start">
    <mat-spinner [diameter]="40" class="mt-20"></mat-spinner>
  </div>

  <div
    mat-dialog-actions
    [formGroup]="form"
    (submit)="onCreateClientEnterprise()"
    class="mt-16 gt2-buttons-theme"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button
      mat-raised-button
      color="accent"
      class="mr-16"
      type="submit"
      [disabled]="!(form.valid && form2.valid) || loading"
      (click)="onCreateClientEnterprise()"
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>

    <button
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(false)"
      class=""
    >
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
