<div id="eventReceipt" *ngIf="event">
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
    <div *ngIf="!editMode" class="info-line" fxFlex="50%">
      <div class="title">{{ "EVENTS.EVENT_NAME" | translate }}</div>
      <div class="info text-truncate">
        {{ event.informations.alcoholic_permit_event_name }}
      </div>
      <div
        *ngIf="
          !event.informations || !event.informations.alcoholic_permit_event_name
        "
        class="info empty-value"
      ></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <div class="info-editable">
        <div [formGroup]="form" class="">
          <mat-form-field class="form-input-container" fxFlex="100%">
            <mat-label>{{ "EVENTS.EVENT_NAME" | translate }}</mat-label>
            <input
              matInput
              autocomplete="nope"
              formControlName="alcoholic_permit_event_name"
              type="text"
              [(ngModel)]="event.informations.alcoholic_permit_event_name"
            />
            <mat-error
              *ngIf="form.controls['alcoholic_permit_event_name'].invalid"
              >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <!-- alcoholic_permit_date -->
    <div *ngIf="!editMode" class="info-line" fxFlex="50%">
      <div class="title">Date</div>
      <div class="info text-truncate">
        {{ event.informations.alcoholic_permit_date_label }}
      </div>
      <div
        *ngIf="!event.informations || !event.informations.alcoholic_permit_date"
        class="info empty-value"
      ></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <div class="info-editable">
        <div [formGroup]="form" fxLayout="row" class="">
          <mat-form-field class="" fxFlex="100%">
            <mat-label>Date</mat-label>
            <input
              matInput
              formControlName="alcoholic_permit_date"
              autocomplete="nope"
              [matDatepicker]="alcoholicPermitDate"
              min="1900-01-01"
              max="2099-01-01"
              (ngModelChange)="onStorageDateChange()"
              [(ngModel)]="event.informations.alcoholic_permit_date"
            />

            <mat-datepicker-toggle
              matSuffix
              [for]="alcoholicPermitDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #alcoholicPermitDate></mat-datepicker>

            <mat-error
              *ngIf="
                form.controls['alcoholic_permit_date'].invalid ||
                event.informations.storage_date === null
              "
              >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
            </mat-error>
          </mat-form-field>
          <button
            mat-button
            [disabled]="!event.informations.alcoholic_permit_date"
            mat-icon-button
            aria-label="Clear"
            (click)="clearAlcoholicPermitDate()"
          >
            <mat-icon class="s-16">close</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
    <div *ngIf="!editMode" class="info-line" fxFlex="50%">
      <div class="title">{{ "EVENTS.EVENT_PLACE" | translate }}</div>
      <div class="info text-truncate">
        {{ event.informations.alcoholic_permit_local }}
      </div>
      <div
        *ngIf="
          !event.informations || !event.informations.alcoholic_permit_local
        "
        class="info empty-value"
      ></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <div class="info-editable">
        <div [formGroup]="form" class="">
          <mat-form-field class="form-input-container" fxFlex="100%">
            <mat-label>{{ "EVENTS.EVENT_PLACE" | translate }}</mat-label>
            <input
              matInput
              autocomplete="nope"
              formControlName="alcoholic_permit_local"
              type="text"
              [(ngModel)]="event.informations.alcoholic_permit_local"
            />
            <mat-error *ngIf="form.controls['alcoholic_permit_local'].invalid"
              >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div *ngIf="!editMode" class="info-line" fxFlex="50%">
      <div class="title">{{ "EVENTS.ORGANIZER_PERSON" | translate }}</div>
      <div class="info text-truncate">
        {{ event.informations.alcoholic_permit_in_charge }}
      </div>
      <div
        *ngIf="
          !event.informations || !event.informations.alcoholic_permit_in_charge
        "
        class="info empty-value"
      ></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <div class="info-editable">
        <div [formGroup]="form" class="">
          <mat-form-field class="form-input-container" fxFlex="100%">
            <mat-label>{{ "EVENTS.ORGANIZER_PERSON" | translate }}</mat-label>
            <input
              matInput
              autocomplete="nope"
              formControlName="alcoholic_permit_in_charge"
              type="text"
              [(ngModel)]="event.informations.alcoholic_permit_in_charge"
            />
            <mat-error
              *ngIf="form.controls['alcoholic_permit_in_charge'].invalid"
              >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <!-- Receipt notice hour -->
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
    <div *ngIf="!editMode" class="info-line" fxFlex="50%">
      <div class="title">{{ "EVENTS.START_TIME" | translate }}</div>
      <div class="info">
        {{ event.informations.alcoholic_permit_start_time_label }}
      </div>
      <div
        *ngIf="
          !event.informations ||
          !event.informations.alcoholic_permit_start_time_label
        "
        class="info empty-value"
      ></div>
    </div>
    <div
      *ngIf="editMode"
      class="ngx-timepicker-field-custom"
      fxLayout="column"
      fxFlex="50%"
    >
      <div
        class="custom-label"
        [ngStyle]="{
          color:
            event.informations.alcoholic_permit_start_time === '' ||
            event.informations.alcoholic_permit_start_time === null
              ? '#ff0000'
              : '',
        }"
      >
        {{ "EVENTS.START_TIME" | translate }}
      </div>
      <div fxLayout="row" fxFlex>
        <ngx-timepicker-field
          fxFlex
          [buttonAlign]="'left'"
          #ngxTimeStart
          [format]="24"
          [(ngModel)]="event.informations.alcoholic_permit_start_time"
          (ngModelChange)="onTimeChange()"
          class="ngx-material-timepicker-toggle"
        >
        </ngx-timepicker-field>
        <button
          mat-button
          matSuffix
          [disabled]="!event.informations.alcoholic_permit_start_time"
          mat-icon-button
          aria-label="Clear"
          (click)="event.informations.alcoholic_permit_start_time = ''"
        >
          <mat-icon class="s-16">close</mat-icon>
        </button>
      </div>
    </div>

    <div *ngIf="!editMode" class="info-line" fxFlex="50%">
      <div class="title">{{ "EVENTS.END_TIME" | translate }}</div>
      <div class="info">
        {{ event.informations.alcoholic_permit_end_time_label }}
      </div>
      <div
        *ngIf="
          !event.informations ||
          !event.informations.alcoholic_permit_end_time_label
        "
        class="info empty-value"
      ></div>
    </div>
    <div
      *ngIf="editMode"
      class="ngx-timepicker-field-custom"
      fxLayout="column"
      fxFlex="50%"
    >
      <div
        class="custom-label"
        [ngStyle]="{
          color:
            event.informations.alcoholic_permit_end_time === '' ||
            event.informations.alcoholic_permit_end_time === null
              ? '#ff0000'
              : '',
        }"
      >
        {{ "EVENTS.END_TIME" | translate }}
      </div>
      <div fxLayout="row" fxFlex>
        <ngx-timepicker-field
          fxFlex
          [buttonAlign]="'left'"
          #ngxTimeStart
          [format]="24"
          [(ngModel)]="event.informations.alcoholic_permit_end_time"
          (ngModelChange)="onTimeChange()"
          class="ngx-material-timepicker-toggle"
        >
        </ngx-timepicker-field>
        <button
          mat-button
          matSuffix
          [disabled]="!event.informations.alcoholic_permit_end_time"
          mat-icon-button
          aria-label="Clear"
          (click)="event.informations.alcoholic_permit_end_time = ''"
        >
          <mat-icon class="s-16">close</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
