<div fxLayout="column">
  <div class="mat-title">{{ "NUMBERING.TITLE" | translate }}</div>
  <div class="mat-subheading-2 mb-24">
    {{ "NUMBERING.SUBTITLE" | translate }}
  </div>

  <div *ngIf="numbering" fxLayout="column" fxFlex="100%" class="p-12">
    <div class="mat-body-2 mb-4">{{ "NUMBERING.PREFIX" | translate }}</div>
    <mat-divider></mat-divider>
    <div fxLayout="row" fxFlex="100%" class="mt-16">
      <div fxLayout="column" fxFlex="50%" class="mr-12">
        <div [formGroup]="form" class="" fxLayout="column">
          <!-- <mat-form-field class="form-input-container" fxFlex="50%">
                            <input matInput
                                   placeholder="{{ 'NUMBERING.PROJECT_PREFIX' | translate }}"
                                   formControlName="project_prefix"
                                   type="text"
                                   [(ngModel)]="numbering.project_prefix">
                            <mat-error *ngIf="form.controls['project_prefix'].invalid">{{
                                'GENERIC.INPUT_LENGTH_INVALID' | translate }}
                            </mat-error>
                        </mat-form-field> -->

          <mat-form-field
            class="form-input-container"
            fxFlex="100%"
            class="mb-12"
          >
            <mat-label>{{ "NUMBERING.EVENT_PREFIX" | translate }}</mat-label>
            <input
              matInput
              formControlName="event_prefix"
              autocomplete="nope"
              type="text"
              [(ngModel)]="numbering.event_prefix"
            />
            <mat-error *ngIf="form.controls['event_prefix'].invalid">
              {{ "NUMBERING.PREFIX_VALIDATION_ERROR" | translate }}
            </mat-error>
          </mat-form-field>

          <mat-form-field
            class="form-input-container"
            fxFlex="100%"
            class="mb-12"
          >
            <mat-label>{{
              "NUMBERING.PROPOSITION_PREFIX" | translate
            }}</mat-label>
            <input
              matInput
              formControlName="proposal_prefix"
              autocomplete="nope"
              type="text"
              [(ngModel)]="numbering.proposal_prefix"
            />
            <mat-error *ngIf="form.controls['proposal_prefix'].invalid">
              {{ "NUMBERING.PREFIX_VALIDATION_ERROR" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="column" fxFlex="50%" class="ml-12">
        <div [formGroup]="form" class="" fxLayout="column">
          <mat-form-field
            class="form-input-container"
            fxFlex="100%"
            class="mb-12"
          >
            <mat-label>{{ "NUMBERING.ORDER_PREFIX" | translate }}</mat-label>
            <input
              matInput
              formControlName="order_prefix"
              autocomplete="nope"
              type="text"
              [(ngModel)]="numbering.order_prefix"
            />
            <mat-error *ngIf="form.controls['order_prefix'].invalid">
              {{ "NUMBERING.PREFIX_VALIDATION_ERROR" | translate }}
            </mat-error>
          </mat-form-field>

          <mat-form-field
            class="form-input-container"
            fxFlex="100%"
            class="mb-12"
          >
            <mat-label>{{ "NUMBERING.INVOICE_PREFIX" | translate }}</mat-label>
            <input
              matInput
              formControlName="invoice_prefix"
              autocomplete="nope"
              type="text"
              [(ngModel)]="numbering.invoice_prefix"
            />
            <mat-error *ngIf="form.controls['invoice_prefix'].invalid">
              {{ "NUMBERING.PREFIX_VALIDATION_ERROR" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <!-- SHOW THIS SECTION ONLY FOR OWNERS USERS -->
    <div
      fxLayout="column"
      fxFlex="100%"
      *ngxPermissionsOnly="rolesService.OWNER_ROLES"
    >
      <div class="mat-body-2 mb-4 mt-16">
        {{ "NUMBERING.NUMBERING" | translate }}
      </div>
      <mat-divider></mat-divider>
      <div fxLayout="row" fxFlex="100%" class="mt-16">
        <div fxLayout="column" fxFlex="50%" class="mr-12">
          <div [formGroup]="form" class="" fxLayout="column">
            <mat-form-field class="form-input-container" fxFlex="100%">
              <mat-label>{{ "NUMBERING.NUMBER_NEXT" | translate }}</mat-label>
              <input
                matInput
                formControlName="index_next"
                autocomplete="nope"
                type="text"
                [(ngModel)]="numbering.index_next"
              />
              <mat-error *ngIf="form.controls['index_next'].invalid">
                {{ "NUMBERING.PREFIX_VALIDATION_ERROR" | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="column" fxFlex="50%" class="mr-12">
          <div [formGroup]="form" class="" fxLayout="column">
            <mat-form-field class="form-input-container" fxFlex="100%">
              <mat-label>{{
                "NUMBERING.NUMBER_INVOICE_NEXT" | translate
              }}</mat-label>
              <input
                matInput
                formControlName="index_invoice_next"
                autocomplete="nope"
                type="text"
                [(ngModel)]="numbering.index_invoice_next"
              />
              <mat-error *ngIf="form.controls['index_invoice_next'].invalid">
                {{ "NUMBERING.PREFIX_VALIDATION_ERROR" | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="center start"
      fxFlex="100%"
      class="mt-12"
    >
      <button
        mat-raised-button
        color="accent"
        [disabled]="!form.valid"
        class=""
        (click)="onSaveData()"
      >
        {{ "GENERIC.SAVE" | translate }}
      </button>
    </div>
  </div>
</div>
