import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { GT2PageAbstract } from '../abstract/GT2PageAbstract';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  startWith,
  switchMap,
  map,
  catchError,
} from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { Gt2ApiService } from '../../api/gt2-api.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { fromEvent, of, Subscription, merge } from 'rxjs';
import { DatatableHelperService } from '../../../services/datatable-helper.service';
import { ShopsService } from '../../api/shops.service';
import { AppRoutesService } from '../../services/app-routes.service';
import { RolesService } from '../../services/roles.service';
import { MatDialog } from '@angular/material/dialog';
import { fuseAnimations } from '../../../../@fuse/animations';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { CreateShopComponent } from '../../components/dialogs/create-shop/create-shop.component';

@Component({
  selector: 'app-shops',
  templateUrl: './shops.component.html',
  styleUrls: ['./shops.component.scss'],
  animations: fuseAnimations,
})
export class ShopsComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('table') table!: MatTable<any>;
  @ViewChild('filter') filter!: ElementRef;
  @ViewChild('mainContainer') mainContainer!: ElementRef;
  displayedColumns = ['name', 'shop_type_label', 'shop_product_type'];
  filterValue = '';
  dataSource = new MatTableDataSource();
  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;
  fromEventSubscription?: Subscription;
  isFirstLoad = true;
  confirmedOnly = false;
  comboOnly = false;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private shopsService: ShopsService,
    public translate: TranslateService,
    public location: Location,
    private logger: NGXLogger,
    private titleService: Title,
    private api: Gt2ApiService,
    public appRoutesService: AppRoutesService,
    public rolesService: RolesService,
    public dialog: MatDialog,
    public datatableHelperService: DatatableHelperService,
    public router: Router,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
  }

  ngOnInit(): void {
    //this.titleService.setTitle(this.translate.instant("SHOPS.SHOPS"));
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe({
      next: () => (this.paginator.pageIndex = 0),
    });

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(startWith(null))
      .pipe(
        switchMap(() => {
          setTimeout(() => {
            this.isLoadingResults = true;
          });

          // TODO: handle error here, how do?
          return this.shopsService.getShopsWithPagination(
            this.confirmedOnly,
            this.comboOnly,
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize,
            this.filterValue,
          );
        }),
      )
      .pipe(
        map((data) => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.meta.pagination.total;
          this.mainContainer.nativeElement.scrollTop = 0;
          //this.logger.info("SHOPS data : " + JSON.stringify(data));
          return data.data;
        }),
      )
      .pipe(
        catchError(() => {
          setTimeout(() => {
            this.isLoadingResults = false;
            this.isRateLimitReached = true;
          });
          return of([]);
        }),
      )
      .subscribe({ next: (data) => (this.dataSource.data = data) });

    this.fromEventSubscription = fromEvent(this.filter?.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          if (
            this.filter?.nativeElement.value.length > 1 ||
            this.filter?.nativeElement.value === ''
          ) {
            this.paginator.pageIndex = 0;
            this.paginator?._changePageSize(this.paginator.pageSize);
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {}

  public clearSearch(): void {
    this.filterValue = '';
    this.paginator.pageIndex = 0;
    this.paginator?._changePageSize(this.paginator.pageSize);
  }

  public syncPrimaryPaginator(event: PageEvent) {
    //this.logger.info("syncPrimaryPaginator(): " + JSON.stringify(event));
    this.paginator.pageIndex = event.pageIndex;
    this.paginator.pageSize = event.pageSize;
    //this.paginator._changePageSize(this.paginator.pageSize);
    this.paginator?.page.emit(event);
  }

  public reloadTable(): void {
    //// //this.logger.info("reloadTable()");
    this.paginator?._changePageSize(this.paginator.pageSize);
  }

  public onCreateNewShop(event: any): void {
    //// //this.logger.info("ProductsComponent.onCreateNewProduct() -> event: " + event);
    this.dialogRef = this.dialog.open(CreateShopComponent, {
      width: '400px',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: { autoRedirect: true },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
        }
        this.dialogRef = null;
      },
    });
  }

  public onShopSelected(shop: any): void {
    //this.logger.info("ShopsComponent.onShopSelected() -> shop: " + JSON.stringify(shop));
    //this.logger.info("ShopsComponent.onShopSelected() -> shop.key: " + shop.shop_type.key);
    let url: string | any;
    if (shop.shop_type.key === 'SHOP_WEB') {
      url =
        this.appRoutesService.shops +
        '/' +
        shop.shop_type.slug +
        '/' +
        shop.slug +
        '/' +
        shop.uuid;
    } else if (shop.shop_type.key === 'SHOP_INTERNAL') {
      url =
        this.appRoutesService.shops +
        '/' +
        shop.shop_type.slug +
        '/' +
        shop.slug +
        '/' +
        shop.uuid;
    }
    //this.logger.info("ShopsComponent.onShopSelected() -> shop url: " + url);

    this.router.navigateByUrl(url);
    // key
    // SHOP_WEB
    // SHOP_INTERNAL
    // [routerLink]="'/' + appRoutesService.shops + '/' + shop.shop_type.slug + '/' + shop.slug + '/' + shop.uuid"> -->
  }

  public onShopDetailesSelected(event: any, shop: any): void {
    // event.stopPropagation();
    //this.logger.info("ShopsComponent.onShopDetailesSelected() -> shop: " + JSON.stringify(shop));
    // //this.logger.info('ShopsComponent.onShopSelected() -> shop.key: ' + shop.shop_type.key);
    let url: string | any;
    if (shop.shop_type.key === 'SHOP_WEB') {
      url =
        this.appRoutesService.shops +
        '/shop-web-detailed/' +
        shop.slug +
        '/' +
        shop.uuid;
    } else if (shop.shop_type.key === 'SHOP_INTERNAL') {
      url =
        this.appRoutesService.shops +
        '/' +
        shop.shop_type.slug +
        '/' +
        shop.slug +
        '/' +
        shop.uuid;
    }
    //this.logger.info("ShopsComponent.onShopSelected() -> shop url: " + url);

    this.router.navigateByUrl(url);
  }
}
