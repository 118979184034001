<div class="" fxLayout="row" fxLayoutAlign="start center">
  <div fxLayout="column" fxLayoutAlign="start center" class="">
    <div class="upload-section" fxLayout="column" fxLayoutAlign="center center">
      <!-- <div id="drop_zone" ondrop="onFileDrop($event);"
                    [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
                    class="well drop-zone">
                    Déposer une image ici
                </div> -->

      <!-- <div class="or-section">OU</div> -->

      <div class="select-section">
        <ul>
          <li *ngFor="let file of files">
            <!-- <mat-progress-bar [value]="file.progress"></mat-progress-bar> -->
            <span id="file-label"> </span>
          </li>
        </ul>

        <div fxLayout="row" fxLayoutAlign="start center">
          <button mat-button color="warn" (click)="onSelectAndUpload()">
            <mat-icon>file_upload</mat-icon>
            {{ selectLabel }}
          </button>
          <button
            class=""
            mat-icon-button
            matTooltip="{{ requirementMessage }}"
            matTooltipPosition="above"
            type="button"
          >
            <mat-icon class="s-20" color=""> help </mat-icon>
          </button>
        </div>

        <input
          type="file"
          #fileUpload
          id="fileUpload"
          name="fileUpload"
          accept="image/png, image/jpeg, image/jpg"
          style="display: none"
        />
      </div>

      <div></div>
    </div>

    <mat-progress-bar
      *ngIf="fileUploading"
      [value]="fileProgress"
      class="progress-bar"
    >
    </mat-progress-bar>
    <mat-card *ngIf="media && media.url" class="card-image">
      <mat-card-content>
        <div
          class="image-section"
          xLayout="column"
          fxLayoutAlign="center center"
        >
          <div *ngIf="!fileUploading" class="image-container">
            <img [src]="media.url" class="img-media" />
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions align="end">
        <button
          mat-icon-button
          color="warn"
          *ngIf="useDeleteButton"
          [disabled]="!media || !media.uuid || media.uuid === null"
          (click)="onDeleteImage()"
          class="delete-btn"
          matTooltip="Effacer"
          matTooltipPosition="right"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
