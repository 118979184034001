<div *ngIf="model">
  <div
    *ngFor="let tax of model; let i = index"
    fxLayout="column"
    fxLayoutAlign="0 1 auto"
    class="taxes-child-item"
  >
    <!--name-->
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100%">
      <div class="mr-12 font-weight-500" fxFlex="30%">
        {{ tax.name }}
      </div>

      <!-- <div class="" style="height: 70px; border-right: 1px solid;"></div> -->

      <!--taxe number input-->
      <div [formGroup]="form" fxFlex="70%" class="" fxLayout="column">
        <mat-form-field class="" fxFlex="100%">
          <mat-label>{{ "TAXES.TAX_NUMBER" | translate }}</mat-label>
          <input
            matInput
            type="text"
            autocomplete="nope"
            maxlength="191"
            [ngModelOptions]="{ standalone: true }"
            (change)="onTaxNumberChange(tax)"
            [(ngModel)]="tax.pref.tax_number"
          />
          <!--<mat-error *ngIf="isInputValid(tax)">{{ 'GENERIC.INPUT_LENGTH_INVALID' | translate }}</mat-error>-->
          <!--<mat-error *ngIf="form.controls['tax_number'].invalid">{{ 'GENERIC.INPUT_LENGTH_INVALID' | translate }}</mat-error>-->
        </mat-form-field>

        <div>
          <app-gt2-select
            [service]="ledgerAccountService"
            [nullableUseNone]="false"
            [selectedModel]="tax.pref.ledger_account"
            [placeHolder]="'SETTINGS.LEDGER_ACCOUNT' | translate"
            [label]="'Taxes'"
            (changeEvent)="onLedgerAccountSelected($event, tax)"
            [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
            fxFlex="100%"
            class=""
          ></app-gt2-select>
        </div>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center">
      <button
        mat-raised-button
        color="accent"
        (click)="onTaxUpdate(tax)"
        [disabled]="
          !form.valid || !tax.pref.tax_number || !tax.pref.ledger_account
        "
        style="font-size: 11px"
        class=""
      >
        {{ "TAXES.UPDATE" | translate | uppercase }}
      </button>
    </div>
  </div>
</div>
