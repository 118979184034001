import { MatSort, MatSortModule } from '@angular/material/sort';
import { fuseAnimations } from './../../../../../../@fuse/animations/index';
import { ErrorDialogComponent } from './../../../../components/dialogs/error-dialog/error-dialog.component';
import {
  HttpClient,
  HttpEventType,
  HttpErrorResponse,
} from '@angular/common/http';
import { Gt2ApiService } from '../../../../api/gt2-api.service';
import { EventAttachedFilesService } from './../../../../api/event-attached-files.service';
import { GT2FormPageAbstract } from '../../../../content/abstract/GT2FormPageAbstract';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GenericAlertComponent } from './../../../../components/generic-alert/generic-alert.component';
import { NGXLogger } from 'ngx-logger';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiRoutes } from '../../../../api/ApiRoutes';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Gt2PrimengModule } from '../../../../modules/gt2-primeng.module';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FileNameExtensionIconPipeModule } from '../../../../modules/file-name-extension-icon.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CdkTableModule } from '@angular/cdk/table';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-event-attached-files',
  templateUrl: './event-attached-files.component.html',
  styleUrls: ['./event-attached-files.component.scss'],
  standalone: true,
  imports: [
    Gt2PrimengModule,
    MatSortModule,
    MatProgressBarModule,
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    CdkTableModule,
    FlexLayoutModule,
    FileNameExtensionIconPipeModule,
    MatInputModule,
    MatIconModule,
    TranslateModule,
    MatButtonModule,
    MatTableModule,
    NgxDropzoneModule,
  ],
  providers: [EventAttachedFilesService],
  animations: fuseAnimations,
})
export class EventAttachedFilesComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit
{
  supportedFileType: string =
    'application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,image/gif,image/GIF,image/png,image/PNG,image/jpeg,image/JPEG';
  @Input() eventUUID!: string;
  @ViewChild('fileUpload') fileUpload?: ElementRef | any;
  confirmDialogRef: any;
  files: any = [];
  fileProgress?: number;
  fileUploading: boolean = false;
  declare errorDialogRef: any;

  attachedFiles?: AttachedFile[];

  // datatable
  // dataSource;
  dataSource: any = new MatTableDataSource<AttachedFile>();
  displayedColumns: string[] = ['icon', 'name', 'fileSize', 'action'];
  @ViewChild(MatSort) sort?: MatSort;

  // hasBaseDropZoneOver: boolean;

  constructor(
    public logger: NGXLogger,
    private http: HttpClient,
    private dialog: MatDialog,
    public translate: TranslateService,
    public eventAttachedFilesService: EventAttachedFilesService,
    public api: Gt2ApiService,
  ) {
    super();
  }

  ngOnInit() {
    this.loadAttachedFiles();
  }

  ngAfterViewInit() {
    // //this.logger.info("this.sort: " + this.sort);
    // this.dataSource.sort = this.sort;
  }

  onSelect(event: any) {
    // //this.logger.info(
    //     "EventAttachedFilesComponent.onSelect() -> e: " +
    //         event.addedFiles[0].name
    // );

    // //this.logger.info(
    //     "event.rejectedFiles: type " + event.rejectedFiles[0].reason
    // );
    if (event.rejectedFiles.length > 0) {
      switch (event.rejectedFiles[0].reason) {
        case 'type': {
          this.showUnsupportedFileAlert();
          return;
        }
        case 'size': {
          this.fileTooBigAlert();
          return;
        }
        case 'no_multiple': {
          this.showTooManyFileAlert();
          return;
        }
      }
    }
    this.files = [];
    this.files.push({
      data: event.addedFiles[0],
      inProgress: false,
      progress: 0,
    });
    this.dataSource.data = this.attachedFiles;
    this.uploadFiles();
  }

  onRemove(event: any) {
    //this.logger.info("EventAttachedFilesComponent.onRemove() -> e: " + event);
  }

  public showUnsupportedFileAlert(): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle = this.translate.instant(
      'EVENTS.UNSUPPORTED_FILE_TITLE',
    );
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('EVENTS.UNSUPPORTED_FILE_MESSAGE');

    this.confirmDialogRef.componentInstance.useCancel = false;

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        this.confirmDialogRef = null;
      },
    });
  }

  public showTooManyFileAlert(): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.ERROR');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('EVENTS.TOO_MANY_FILE_MESSAGE');

    this.confirmDialogRef.componentInstance.useCancel = false;

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        this.confirmDialogRef = null;
      },
    });
  }

  public fileTooBigAlert(): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.ERROR');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('EVENTS.FILE_TO_BIG_MESSAGE');

    this.confirmDialogRef.componentInstance.useCancel = false;

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        this.confirmDialogRef = null;
      },
    });
  }

  public loadAttachedFiles(): void {
    // //this.logger.info("EventAttachedFilesComponent.loadAttachedFiles()");
    this.eventAttachedFilesService.getFiles(this.eventUUID).subscribe({
      next: (response) => {
        // //this.logger.info(
        //     "EventAttachedFilesComponent.loadAttachedFiles() -> files: " +
        //         JSON.stringify(response)
        // );
        this.attachedFiles = response;
        this.dataSource.data = this.attachedFiles;
        this.dataSource.sort = this.sort;
        // //this.logger.info(
        //     "EventAttachedFilesComponent.loadAttachedFiles() -> files: " +
        //         JSON.stringify(this.attachedFiles)
        // );
        // //this.logger.info(
        //     "EventAttachedFilesComponent.loadAttachedFiles() -> files.length: " +
        //         this.attachedFiles.length
        // );
      },
      error: (error) => {
        this.logger.error(
          'EventAttachedFilesComponent.ngOnInit() -> ERROR: ' +
            JSON.stringify(error),
        );
      },
    });
  }

  public onAttachDocument(): void {
    //this.logger.info("onAttachDocument() -> " + this.fileUpload);
    this.files = [];

    const fileUpload = this.fileUpload?.nativeElement;
    //this.logger.info("onAttachDocument() -> " + fileUpload);
    fileUpload.onchange = () => {
      //this.logger.info("onAttachDocument() -> onchange()");
      // tslint:disable-next-line: prefer-for-of
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push({ data: file, inProgress: false, progress: 0 });
      }
      this.uploadFiles();
    };

    fileUpload.click();
  }

  private uploadFiles(): void {
    // //this.logger.info("uploadFiles()");
    this.fileUpload.nativeElement.value = '';
    this.files.forEach((file: any) => {
      this.uploadFile(file);
    });
  }

  public uploadFile(file: any): void {
    //this.logger.info("uploadFile()");
    if (!this.eventUUID) {
      this.logger.warn(
        'EventAttachedFilesComponent.uploadFile() -> WARNING: no event uuid!',
      );
      return;
    }
    //
    let uploadURL: string = this.api.createUrl(ApiRoutes.EVENT_FILE);
    uploadURL = uploadURL.replace('${UUID}', this.eventUUID);
    //this.logger.info("xxx.uploadFile() -> uploadURL: " + uploadURL);

    const formData = new FormData();
    formData.append('file', file.data);
    formData.append('model_type', 'EVENT');
    formData.append('model_uuid', this.eventUUID);
    file.inProgress = true;
    this.fileUploading = true;

    const newFile = {
      uuid: '',
      url: '',
      name: '',
      progress: 0,
      loading: true,
      fileSize: 0,
    };
    this.attachedFiles?.push(newFile);
    this.dataSource.data = this.attachedFiles;

    // this.pictureService.uploadPicture(uploadURL, formData)
    this.http
      .post<any>(uploadURL, formData, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        map((event: any) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round((event.loaded * 100) / event.total);
              this.fileProgress = file.progress;
              newFile.progress = file.progress;
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          file.inProgress = false;
          this.fileUploading = false;
          this.showError(error);
          newFile.loading = false;
          this.loadAttachedFiles();
          return of(`${file.data.name} upload failed.`);
        }),
      )
      .subscribe({
        next: (event: any) => {
          if (typeof event === 'object') {
            // //this.logger.info("UPLOAD: " + JSON.stringify(event));
            this.fileUploading = false;
            newFile.loading = false;
            this.loadAttachedFiles();
          }
        },
      });
  }

  public override showError(error: HttpErrorResponse): void {
    if (error.status === 422 || error.status === 412) {
      this.errorDialogRef = this.dialog.open(ErrorDialogComponent, {
        disableClose: false,
      });

      this.errorDialogRef.componentInstance.setError(error);

      this.errorDialogRef.afterClosed().subscribe({
        next: (result: any) => {
          this.errorDialogRef = null;
        },
      });
    }
  }

  public onDeleteAttachedFile(file: AttachedFile): void {
    // //this.logger.info("onDeleteAttachedFile()");
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        // //this.logger.info("onDeleteAttachedFile() -> " + result);
        if (result) {
          // Delete call
          this.eventAttachedFilesService.deleteFile(file).subscribe({
            next: (response) => {
              // //this.logger.info(
              //     "EventAttachedFilesComponent.onDeleteAttachedFile() -> response: " +
              //         JSON.stringify(response)
              // );
              this.loadAttachedFiles();
            },
            error: (error) => {
              this.logger.error(
                'EventAttachedFilesComponent.onDeleteAttachedFile() -> ERROR: ' +
                  JSON.stringify(error),
              );
            },
          });
        }
      },
    });
  }

  public onOpen(file: AttachedFile): void {
    // //this.logger.info("onOpen()" + JSON.stringify(file));
    window.open(file.url, '_blank');

    // _FileSaverService
    // var link = document.createElement("a");
    // document.body.appendChild(link);
    // link.href = file.url;
    // link.download = "";
    // link.click();

    // this.http
    //     .get(file.url, {
    //         responseType: "blob", // This must be a Blob type
    //     })
    //     .subscribe((res) => {
    //         this._FileSaverService.save((<any>res)._body, file.name);
    //         // this._FileSaverService.
    //     });
  }

  public onFileSaverSuccess(event: any): void {
    //this.logger.info("onFileSaverSuccess()" + JSON.stringify(event));
  }

  public onFileSaverError(error: any): void {
    //this.logger.info("onFileSaverError()" + JSON.stringify(error));
  }
}

export interface AttachedFile {
  uuid: string;
  url: string;
  name: string;
  progress: number;
  loading: boolean;
  fileSize: number;
}
