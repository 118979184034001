import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { GT2FormPageAbstract } from '../../abstract/GT2FormPageAbstract';
import { ICanDeactivateComponent } from '../../../gards/can-deactivate-guard.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { ShopsService } from '../../../api/shops.service';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { FileService } from '../../../../services/file.service';
import { MatDialog } from '@angular/material/dialog';
import { PrintService } from '../../../../services/print.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { RolesService } from '../../../services/roles.service';
import { FuseSidebarService } from '../../../../../@fuse/components/sidebar/sidebar.service';
import { Title } from '@angular/platform-browser';
import { ShopTypeService } from '../../../api/shop-type.service';
import { Gt2ApiService } from '../../../api/gt2-api.service';
import { locale as english } from '../i18n/en-CA';
import { locale as french } from '../i18n/fr-CA';
import { Settings } from '../../../settings/settings';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { GenericAlertComponent } from '../../../components/generic-alert/generic-alert.component';
//import { ProductPickerComponent } from '../../../components/product-picker/product-picker.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-shop-woocommerce',
  templateUrl: './shop-woocommerce.component.html',
  styleUrls: ['./shop-woocommerce.component.scss'],
  animations: fuseAnimations,
})
export class ShopWoocommerceComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy, ICanDeactivateComponent
{
  shop: any;
  shopID: string | any;
  loading?: boolean;
  form: UntypedFormGroup;
  editMode = false;
  confirmDialogRef: any;
  editable = true;
  connectionMessageSuccess?: string;
  connectionTitleSuccess?: string;
  @ViewChild('testConnectionTemplate', { static: false })
  testConnectionTemplate: TemplateRef<any> | any;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private shopsService: ShopsService,
    public translate: TranslateService,
    public location: Location,
    private toastr: ToastrService,
    private formBuilder: UntypedFormBuilder,
    private fileService: FileService,
    private dialog: MatDialog,
    private _bottomSheet: MatBottomSheet,
    public print: PrintService,
    private route: ActivatedRoute,
    private logger: NGXLogger,
    public rolesService: RolesService,
    private _fuseSidebarService: FuseSidebarService,
    private titleService: Title,
    public shopTypeService: ShopTypeService,
    private api: Gt2ApiService,
    public router: Router,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      // TODO: validate url???
      store_url: [
        '',
        [Validators.minLength(Settings.inputMinChar), Validators.required],
      ],
      consumer_secret: [
        '',
        [Validators.minLength(Settings.inputMinChar), Validators.required],
      ],
      consumer_key: [
        '',
        [Validators.minLength(Settings.inputMinChar), Validators.required],
      ],
    });
  }

  ngOnInit(): void {
    //this.titleService.setTitle(this.translate.instant("SHOPS.SHOPS"));
    this.route.params.subscribe({
      next: (params: any) => {
        this.shopID = params.id;
        this.loading = true;
        this.shop = null;
        this.loadShop();
      },
    });

    this.connectionTitleSuccess = this.translate.instant('SHOPS.CONNECTION');
    this.connectionMessageSuccess = this.translate.instant(
      'SHOPS.CONNECTION_SUCCESS',
    );
  }

  ngAfterViewInit(): void {
    if (this.editMode) {
      this.setControlsChanges(this.form);
    }
  }

  ngOnDestroy(): void {}

  public canDeactivate(): boolean {
    // //this.logger.info("canDeactivate() -> this.form.dirty: " + this.form.dirty);
    return true;
    // return !this.editMode && !this.form.dirty;
  }

  public loadShop(): void {
    this.loading = true;
    this.shopsService.getShop(this.shopID).subscribe({
      next: (response) => {
        //this.logger.info("ShopWoocommerceComponent.loadShop() -> SUCCESS: " + JSON.stringify(response));

        this.shop = response.data;
        this.loading = false;
        // this.editable = false; // !this.shop.isArchive;
        // //this.logger.info("ProductComponent.loadProduct() -> isArchive: " + this.product.isArchive);
        //this.titleService.setTitle(this.translate.instant("SHOPS.SHOPS") + " " + this.shop.name);
      },
      error: (error) => {
        this.logger.error(
          'ShopWoocommerceComponent.loadShop() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public onEdit(event: any): void {
    this.editMode = !this.editMode;
  }

  public override autosaveDataChange(data: any): void {
    //this.logger.info("ShopWoocommerceComponent.autosaveDataChange() -> *** data: " + JSON.stringify(data));
    // //this.logger.info("ShopComponent.autosaveDataChange() -> *** this.order: " + JSON.stringify(this.order));
    this.loading = true;
    this.shopsService.updateShopByUUID(data, this.shopID).subscribe({
      next: (response: any) => {
        //this.logger.info("ShopWoocommerceComponent.autosaveDataChange() -> SUCCESS: " + JSON.stringify(response));
        this.loading = false;
        this.form.markAsPristine();
        this.shop = response.data;
        this.form.markAsUntouched();
      },
      error: (error: any) => {
        this.logger.error(
          'ShopWoocommerceComponent.autosaveDataChange() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public woocommerceAutosaveDataChange(data: any): void {
    //this.logger.info("ShopWoocommerceComponent.autosaveDataChange() -> *** data: " + JSON.stringify(data));
    // //this.logger.info("ShopComponent.autosaveDataChange() -> *** this.order: " + JSON.stringify(this.order));
    this.loading = true;
    this.shopsService
      .updateShopWoocommerceByUUID(data, this.shop.shop_woocommerce.uuid)
      .subscribe({
        next: (response: any) => {
          //this.logger.info("ShopWoocommerceComponent.autosaveDataChange() -> SUCCESS: " + JSON.stringify(response));
          this.loading = false;
          this.form.markAsPristine();
          this.shop.shop_woocommerce = response.data;
          this.form.markAsUntouched();
        },
        error: (error: any) => {
          this.logger.error(
            'ShopWoocommerceComponent.autosaveDataChange() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          this.handleAPIError(error, this.dialog, null, null);
        },
      });
  }

  public woocommerceSaveData(): void {
    this.loading = true;
    const wooData = {
      store_url: this.shop.shop_woocommerce.store_url,
      verify_ssl: this.shop.shop_woocommerce.verify_ssl,
      consumer_key: this.shop.shop_woocommerce.consumer_key,
      consumer_secret: this.shop.shop_woocommerce.consumer_secret,
    };
    //this.logger.info("woocommerceSaveData.woocommerceSaveData() -> *** data: " + JSON.stringify(wooData));
    this.shopsService
      .updateShopWoocommerceByUUID(wooData, this.shop.shop_woocommerce.uuid)
      .subscribe({
        next: (response: any) => {
          //this.logger.info("ShopWoocommerceComponent.autosaveDataChange() -> SUCCESS: " + JSON.stringify(response));
          this.loading = false;
          this.form.markAsPristine();
          this.shop.shop_woocommerce = response.data;
          this.form.markAsUntouched();
        },
        error: (error: any) => {
          this.logger.error(
            'ShopWoocommerceComponent.autosaveDataChange() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          this.handleAPIError(error, this.dialog, null, null);
        },
      });
  }

  public woocommerceTestConnection(): void {
    // this.dialogRef = this.dialog.open(this.testConnectionTemplate);
    //this.logger.info("ShopWoocommerceComponent.woocommerceTestConnection() ");
    this.shopsService.testShopWoocommerce(this.shop.uuid).subscribe({
      next: (response: any) => {
        //this.logger.info("ShopWoocommerceComponent.woocommerceTestConnection() -> SUCCESS: " + JSON.stringify(response));
        this.loading = false;
        this.connectionMessageSuccess = response.message;
        this.dialogRef = this.dialog.open(this.testConnectionTemplate);
      },
      error: (error: any) => {
        this.logger.error(
          'ShopWoocommerceComponent.autosaveDataChange() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public testProductPicker(): void {
    // this.dialogRef = this.dialog.open(ProductPickerComponent, {
    //   disableClose: false,
    //   panelClass: 'custom-dialog-container',
    //   minWidth: 350,
    //   width: '50%',
    //   // width: '92%',
    //   // height: '92%'
    // });
    // this.dialogRef.componentInstance.tablePageSize = 8;
    // this.dialogRef.componentInstance.tablePageSizeOptions = [5, 8, 10];
    // this.dialogRef.componentInstance.showSaveButton = true;
    // this.dialogRef.componentInstance.mode = ProductPickerComponent.MULTI;
    // const onChange = this.dialogRef.componentInstance.onChange.subscribe(
    //   (product: any) => {
    //     //this.logger.info("PRODUCT CHANGE " + JSON.stringify(product));
    //     // this.dialogRef.close();
    //   }
    // );
    // const onSelected = this.dialogRef.componentInstance.onSelected.subscribe(
    //   (product: any) => {
    //     //this.logger.info("PRODUCT SELECTED " + JSON.stringify(product));
    //     this.dialogRef.close();
    //   }
    // );
    // const onClose = this.dialogRef.componentInstance.onClose.subscribe(() => {
    //   //this.logger.info("PRODUCT PICKER CLOSE ");
    //   this.dialogRef.close();
    // });
    // this.dialogRef.afterClosed().subscribe((result: any) => {
    //   //this.logger.info("DIALOG OF PRODUCT PICKER CLOSE");
    // });
  }

  public testProductPicker2(): void {
    // const sheet = this._bottomSheet.open(ProductPickerComponent);
    // // this.dialogRef = this.dialog.open(ProductPickerComponent, {
    // //     disableClose: false,
    // //     panelClass: 'custom-dialog-container',
    // //     // width: '92%',
    // //     // height: '92%'
    // // });
    // sheet.instance.tablePageSize = 10;
    // sheet.instance.tablePageSizeOptions = [5, 10, 12];
    // const onSelected = sheet.instance.onSelected.subscribe((product) => {
    //   //this.logger.info("PRODUCT SELECTED " + JSON.stringify(product));
    //   sheet.dismiss();
    // });
    // const onClose = sheet.instance.onClose.subscribe(() => {
    //   //this.logger.info("PRODUCT PICKER CLOSE ");
    //   sheet.dismiss();
    // });
  }

  public deleteShop(): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });
    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.shopsService.deleteShop(this.shopID).subscribe({
            next: (response: any) => {
              ////this.logger.info("ShopWoocommerceComponent.deleteShop() -> SUCCESS: " + JSON.stringify(response));
              this.toastr.success(
                '',
                this.translate.instant('GENERIC.DELETE_SUCCESS'),
              );
              this.shop = response.data;
              // this.editable = !this.shop.isArchive;
              // this.router.navigateByUrl("/" + AppRoutes.PRODUCTS);
            },
            error: (error: any) => {
              this.logger.error(
                'ShopWoocommerceComponent.deleteShop() -> ERROR: ' +
                  JSON.stringify(error),
              );
              // this.toastr.error('', this.translate.instant("GENERIC.ERRORS.GENERIC"));
              this.loading = false;
              this.handleAPIError(
                error,
                this.dialog,
                this.toastr,
                this.translate.instant('GENERIC.ERRORS.GENERIC'),
              );
            },
          });
        }
      },
    });
  }

  public onVerifySSLChange(event: any): void {
    // //this.logger.info("ProductComponent.onProductActiveChange() -> " + event.checked);
    this.shop.shop_woocommerce.verify_ssl = event.checked ? 1 : 0;
    // this.woocommerceAutosaveDataChange( {verify_ssl: (event.checked) ? 1 : 0});
  }

  public onOpenInNewTab(): void {
    window.open(this.router.url, '_blank');
  }
}
