import { RoomsModelData } from './../../../api/models/RoomsModel';
import { GT2PageAbstract } from './../../../content/abstract/GT2PageAbstract';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
} from '@angular/forms';
import { RoomsService } from './../../../content/rooms/rooms.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from './../../../../../@fuse/services/translation-loader.service';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Gt2PrimengModule } from '../../../modules/gt2-primeng.module';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../../@fuse/pipes/pipes.module';

@Component({
  selector: 'app-attach-room',
  templateUrl: './attach-room.component.html',
  styleUrls: ['./attach-room.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    TranslateModule,
    MatButtonModule,
    MatSelectModule,
    MatOptionModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    Gt2PrimengModule,
  ],
})
export class AttachRoomComponent extends GT2PageAbstract implements OnInit {
  //
  loading: boolean = false;
  parentUUID: string | any;
  subRooms!: RoomsModelData[];
  selectedRoom!: RoomsModelData;
  //
  constructor(
    public compDialogRef: MatDialogRef<AttachRoomComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    private roomsService: RoomsService,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private logger: NGXLogger,
  ) {
    super();
    if (data) {
      this.parentUUID = data.parentUUID;
      this.loadSubRoomList();
    }
  }

  ngOnInit() {}

  public loadSubRoomList(): void {
    this.loading = true;
    this.roomsService.getAllAttachableRooms(this.parentUUID).subscribe({
      next: (response) => {
        //this.logger.info("AttachRoomComponent.loadSubRoomList() -> SUCCESS: " + JSON.stringify(response));
        this.loading = false;
        this.subRooms = response.data;
      },
      error: (error) => {
        this.logger.error(
          'AttachRoomComponent.loadSubRoomList() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public onAttachRoom(subroom: any): void {
    this.loading = true;
    //this.logger.info("onAttachRoom() -> subroom: " + JSON.stringify(subroom));
    this.roomsService.attachSubRoom(this.parentUUID, subroom.uuid).subscribe({
      next: (response) => {
        //this.logger.info("AttachRoomComponent.onAttachRoom() -> SUCCESS: " + JSON.stringify(response));
        this.loading = false;
        // this.subRooms = response.data;
        this.compDialogRef.close(response.data);
      },
      error: (error) => {
        this.logger.error(
          'AttachRoomComponent.onAttachRoom() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public onSelectedRoomChange(room: any): void {
    //this.logger.info("onSelectedRoomChange()-> " + JSON.stringify(room));
  }
}
