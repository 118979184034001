import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FuseSidebarService } from '../../../../@fuse/components/sidebar/sidebar.service';
import { GT2FormPageAbstract } from '../../content/abstract/GT2FormPageAbstract';
import { SectionHistoryService } from './section-history.service';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-section-history-panel',
  templateUrl: './section-history-panel.component.html',
  styleUrls: ['./section-history-panel.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    Gt2PrimengModule,
    TranslateModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatProgressSpinnerModule,
  ],
  providers: [SectionHistoryService],
})
export class SectionHistoryPanelComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() panelName!: string;
  @Input() section!: string;
  @Input() sectionUUID!: string;

  history: HistoryItem[] = [];
  loading = false;

  constructor(
    private _fuseSidebarService: FuseSidebarService,
    private sectionHistoryService: SectionHistoryService,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    private logger: NGXLogger,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
  }

  ngOnInit() {
    // //this.logger.info("SectionHistoryPanelComponent.ngOnInit() -> ");
    this._fuseSidebarService
      .getSidebar(this.panelName)
      .openedChanged.subscribe({
        next: (value: any) => {
          //this.logger.info("SectionHistoryPanelComponent._fuseSidebarService.openedChanged()-> value: " + value);
          if (value) {
            this.loadHistory();
          }
        },
      });
  }

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public loadHistory(): void {
    // //this.logger.info("SectionHistoryPanelComponent.loadHistory() -> ");
    this.loading = true;
    this.sectionHistoryService
      .getHistory(this.section, this.sectionUUID)
      .subscribe({
        next: (response: any) => {
          //this.logger.info("SectionHistoryPanelComponent.ngAfterViewInit()-> SUCCESS: " + JSON.stringify(response));
          this.history = response;
          this.loading = false;
        },
        error: (error: any) => {
          //this.logger.info("SectionHistoryPanelComponent.ngAfterViewInit()-> ERROR: " + JSON.stringify(error));
          this.loading = false;
        },
      });
  }

  public toggleHistoricSidebarOpen(key: string): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }
}

export interface HistoryItem {
  user: any;
  employee: any;
  audit_event: string; // created, updated, deleted-
  label: string;
  labels: string[];
  created_at: string;
  updated_at: string;
  deleted_at: string;
}
