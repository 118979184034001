<div
  fxLayout="row"
  *ngIf="expenseCopy"
  fxLayoutAlign="center start"
  [formGroup]="form"
>
  <mat-form-field style="width: 100%" class="mr-4">
    <mat-label>{{ "GENERIC.NAME" | translate }}</mat-label>
    <input
      matInput
      fxFlex="100%"
      placeholder="{{ 'GENERIC.NAME' | translate }}"
      formControlName="name"
      type="text"
      autocomplete="nope"
      [(ngModel)]="expenseCopy.name"
      required
    />
    <mat-error *ngIf="form.controls['name'].invalid">{{
      "GENERIC.INPUT_LENGTH_INVALID" | translate
    }}</mat-error>
  </mat-form-field>

  <mat-form-field style="width: 100%" class="ml-4">
    <mat-label>{{ "GENERIC.AMOUNT" | translate }}</mat-label>
    <input
      matInput
      fxFlex="100%"
      numeric
      [decimals]="2"
      [allowNegative]="false"
      formControlName="amount"
      type="text"
      autocomplete="nope"
      pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
      [(ngModel)]="expenseCopy.amount"
      required
    />
    <!-- <mat-error *ngIf="form.controls['meal_included'].invalid">{{ 'GENERIC.INPUT_LENGTH_INVALID' | translate }}</mat-error> -->
  </mat-form-field>

  <div class="expense-actions" fxLayoutAlign="start center" [formGroup]="form">
    <button mat-icon-button (click)="onSaveExpense()" [disabled]="form.invalid">
      <mat-icon
        class="secondary-text icon-btn ml-4"
        [ngClass]="form.invalid ? 'icon-btn-disabled' : ''"
        >{{ getIcon("SAVE") }}</mat-icon
      >
    </button>

    <button mat-icon-button (click)="onDeleteExpense()">
      <mat-icon class="secondary-text icon-btn">{{
        getIcon("DELETE")
      }}</mat-icon>
    </button>
  </div>
</div>
