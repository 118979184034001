import { Subscription } from 'rxjs';
import { ModulesService } from '../../../services/modules.service';
import { ICanDeactivateComponent } from '../../../gards/can-deactivate-guard.service';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { CreateProjectModalComponent } from './../create-project-modal/create-project-modal.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GenericAlertComponent } from '../../../components/generic-alert/generic-alert.component';
import { ValidatorHelper } from '../../../validators/ValidatorHelper';
import { Settings } from '../../../settings/settings';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  ReactiveFormsModule,
  FormsModule,
} from '@angular/forms';
import {
  CongressesService,
  CongressModel,
} from './../../../api/congresses.service';
import { Gt2ApiService } from '../../../api/gt2-api.service';
import { Title } from '@angular/platform-browser';
import { RolesService } from '../../../services/roles.service';
import { AppRoutesService } from './../../../services/app-routes.service';
import { NGXLogger } from 'ngx-logger';
import { ActivatedRoute, Router } from '@angular/router';
import { DatatableHelperService } from '../../../../services/datatable-helper.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { locale as english } from '../i18n/en-CA';
import { locale as french } from '../i18n/fr-CA';
import { CommonModule, Location } from '@angular/common';
import * as moment from 'moment';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ScpmPipeModule } from '../../../modules/spcm.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommentSectionComponent } from '../../../components/comment-section/comment-section.component';
import { ReportsPerClientComponent } from '../reports-per-client/reports-per-client.component';
import { ProjectBoxComponent } from '../project-box/project-box.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../../@fuse/directives/directives';
import { CalendarDateFormatter, DateAdapter } from 'angular-calendar';
import { CustomDateFormatter } from '../../calendar/CustomDateFormatter';
import { GT2DateAdapter } from '../../../utils/GT2DateAdapter';

@Component({
  selector: 'app-congres',
  templateUrl: './congres.component.html',
  styleUrls: ['./congres.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    FormsModule,
    ScpmPipeModule,
    MatDatepickerModule,
    MatFormFieldModule,
    ProjectBoxComponent,
    MatInputModule,
    FuseDirectivesModule,
    FlexLayoutModule,
    NgxPermissionsModule,
    ReportsPerClientComponent,
    MatTooltipModule,
    CommentSectionComponent,
    MatButtonModule,
    ReactiveFormsModule,
    TranslateModule,
    CommonModule,
  ],
  animations: fuseAnimations,
  providers: [
    { provide: DateAdapter, useClass: GT2DateAdapter },
    { provide: CalendarDateFormatter, useClass: CustomDateFormatter },
  ],
})
export class CongresComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy, ICanDeactivateComponent
{
  //
  loading: boolean = false;
  editMode: boolean = false;
  editable: boolean = true;
  congressUUID!: string;
  congress!: CongressModel | any;
  form: UntypedFormGroup | any;
  confirmDialogRef?: MatDialogRef<GenericAlertComponent>;
  projectsCount: number = 0;
  eventCount: number = 0;
  timeZone: string;
  dateStartFormatted?: string;
  dateEndFormatted?: string;

  //
  constructor(
    private congressesService: CongressesService,
    private translationLoader: FuseTranslationLoaderService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private logger: NGXLogger,
    private titleService: Title,
    private api: Gt2ApiService,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    public location: Location,
    public dialog: MatDialog,
    public datatableHelperService: DatatableHelperService,
    public router: Router,
    public appRoutesService: AppRoutesService,
    public rolesService: RolesService,
    public modulesService: ModulesService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    this.timeZone = 'America/Montreal';
  }

  ngOnInit() {
    //this.logger.info("CongresComponent.ngOnInit()");
    //this.titleService.setTitle(this.translate.instant("CONGRESSES.CONGRESS"));

    const iSub: Subscription = this.modulesService.modulesSet.subscribe({
      next: (data) => {
        if (data) {
          this.route.params.subscribe({
            next: (params: any) => {
              this.congressUUID = params.id;
              this.loading = true;
              this.congress = null;
              this.loadCongress();
              iSub.unsubscribe();
            },
          });
        }
      },
    });
    //
    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
        ],
      ],
      congress_number: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      date_start: ['', [ValidatorHelper.dateRangeDefault, Validators.required]],
      date_end: ['', [ValidatorHelper.dateRangeDefault, Validators.required]],
    });
  }

  ngAfterViewInit() {
    //this.logger.info("CongresComponent.ngAfterViewInit()");
  }

  ngOnDestroy() {
    //this.logger.info("CongresComponent.ngOnDestroy()");
  }

  public canDeactivate(): boolean {
    //this.logger.info("CongresComponent.loadClient() -> canDeactivate: " + this.form.dirty);

    return !this.form?.dirty;
  }

  public loadCongress(): void {
    this.loading = true;
    this.congressesService.getCongress(this.congressUUID).subscribe({
      next: (response: any) => {
        //this.logger.info("CongresComponent.loadCongress() -> SUCCESS: " + JSON.stringify(response));
        //
        this.congress = response.data;
        this.eventCount = response.data.events_count;
        this.dateStartFormatted = moment
          .tz(this.congress.date_start, this.timeZone)
          .format('YYYY-MM-DD');
        this.dateEndFormatted = moment
          .tz(this.congress.date_end, this.timeZone)
          .format('YYYY-MM-DD');
        this.congress?.date_end;
        this.loading = false;
        //
        //this.titleService.setTitle(this.translate.instant("CONGRESSES.CONGRESS") + " " + this.congress.name);
      },
      error: (error: any) => {
        this.logger.error(
          'CongresComponent.loadCongress() -> ERROR: ' + JSON.stringify(error),
        );
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public cancelCongress(): void {
    //this.logger.info("CongresComponent.cancelCongress()");
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });
    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.CANCEL');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.CANCEL_CONGRESS_CONFIRM');

    //

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result) => {
        //this.logger.info("CongresComponent.cancelCongress() close.result: " + result);
        if (result) {
          this.loading = true;
          this.congressesService.cancelCongress(this.congressUUID).subscribe({
            next: (response) => {
              this.loading = false;
              this.congress = response.data;
              //this.logger.info("CongresComponent.cancelCongress() -> SUCCESS: " + JSON.stringify(response));
            },
            error: (error) => {
              this.loading = false;
              this.logger.error(
                'CongresComponent.cancelCongress() -> ERROR: ' +
                  JSON.stringify(error),
              );
              this.handleAPIError(error, this.dialog, null, null);
            },
          });
        }
      },
    });
  }

  public onCreateProject(): void {
    //this.logger.info("CongresComponent.onCreateProject()");
    this.dialogRef = this.dialog.open(CreateProjectModalComponent, {
      width: '440px',
      minWidth: 350,
      maxHeight: '96vh',
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: {
        congress: this.congress,
        autoRedirect: true,
      },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.loadCongress();
        }
        this.dialogRef = null;
      },
    });
  }

  public onEdit(event: any): void {
    this.editMode = !this.editMode;
    //
    if (!this.editMode) {
      this.loading = true;
      this.congressesService.updateCongress(this.congress).subscribe({
        next: (response: any) => {
          //this.logger.info("CongresComponent.onEdit().updateCongress() -> SUCCESS: " + JSON.stringify(response));
          this.form?.markAsPristine();
          this.form?.markAsUntouched();
          this.loading = false;
          this.congress = response.data;
        },
        error: (error: any) => {
          this.loading = false;
          this.handleAPIError(error, this.dialog, null, null);
        },
      });
    }
  }

  public onCancelEdit(): void {
    this.editMode = false;
    this.form?.markAsPristine();
    this.form?.markAsUntouched();
    this.loadCongress();
  }

  public onProjecstMeta($event: any): void {
    //this.logger.info("CongresComponent.onEdit().onProjectsMeta() : " + JSON.stringify(event));
    this.projectsCount = $event.pagination.total;
  }

  public onClientsMeta($event: any): void {
    // //this.logger.info(
    //     "CongresComponent.onEdit().onClientsMeta() : " +
    //         JSON.stringify(event)
    // );
    // this.eventCount = $event.pagination.total;
  }
  public onDateChange(event: any): void {
    var dt = moment.tz(event, this.timeZone).format();
    /*//this.logger.info(
            "ProjectComponent.onDateChange() Use of Moment -> event value: " +
                dt
        );*/
    this.congress.date_start = dt;
    this.dateStartFormatted = moment
      .tz(event, this.timeZone)
      .format('YYYY-MM-DD');
  }
  public onDateEndChange(event: any): void {
    var dt = moment.tz(event, this.timeZone).format();
    this.congress.date_end = dt;
    this.dateEndFormatted = moment
      .tz(event, this.timeZone)
      .format('YYYY-MM-DD');
  }
}
