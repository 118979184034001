import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ItemUrlFinder } from '../../utils/item-url-finder';
import { NGXLogger } from 'ngx-logger';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fuseAnimations } from '../../../../@fuse/animations/index';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { MaterialsService } from '../../api/materials.service';
import { GT2PageAbstract } from '../../content/abstract/GT2PageAbstract';
import { CreateMaterialComponent } from '../dialogs/create-material/create-material.component';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import * as _ from 'lodash';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatIconModule } from '@angular/material/icon';
import { NgPipesModule } from 'ngx-pipes';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-create-material-combo',
  templateUrl: './create-material-combo.component.html',
  styleUrls: ['./create-material-combo.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    Gt2PrimengModule,
    MatButtonModule,
    MatInputModule,
    MatDialogModule,
    MatBadgeModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    NgPipesModule,
    TranslateModule,
    MatIconModule,
  ],
  providers: [MaterialsService],
  animations: fuseAnimations,
})
export class CreateMaterialComboComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  material: any;
  materials!: any[];
  combo: any[] = [];
  comboCopy!: any[];
  isLoading: boolean = false;
  saving: boolean = false;
  disableBtn = false;
  isEmpty: boolean = true;
  creationMode: boolean = false;
  applyCall: boolean = true;
  @ViewChild('filter') filter!: ElementRef;
  @ViewChild('productList') productList!: ElementRef;
  filterValue: string = '';
  newDialogRef: any;
  fromEventSubscription!: Subscription;
  isSmallScreen!: boolean;

  constructor(
    public override dialogRef: MatDialogRef<CreateMaterialComboComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    public materialsService: MaterialsService,
    private dialog: MatDialog,
    private logger: NGXLogger,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private breakpointObserver: BreakpointObserver,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    if (data.material) {
      this.material = data.material;
    }

    if (data.materials) {
      this.combo = data.materials;
      this.comboCopy = JSON.parse(JSON.stringify(this.combo));
    }

    if (data.creationMode) {
      this.creationMode = data.creationMode;
    }
  }

  ngOnInit() {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe({
      next: (result) => {
        this.isSmallScreen = result.matches;
      },
    });
    //this.loadMaterialList();
  }

  loadMaterialList(): void {
    this.isLoading = true;
    this.isEmpty = false;
    this.materialsService.getMaterialsWithoutCombo(this.filterValue).subscribe({
      next: (response) => {
        this.isLoading = false;
        if (response.data.length > 0) {
          this.isEmpty = false;
          this.materials = response.data;
          this.productList.nativeElement.scrollTop = 0;
        } else {
          this.isEmpty = true;
          this.materials = [];
        }
      },
      error: (error) => {
        this.logger.error(
          'CreateMaterialComboComponent.getMaterialsWithoutCombo() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
        this.isLoading = false;
        this.isEmpty = true;
      },
    });
  }

  ngAfterViewInit() {
    this.fromEventSubscription = fromEvent(this.filter.nativeElement, 'keyup')
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => {
          // this.logger.info("CreateMaterialComboComponent.onFilterChange() -> " + this.productList);
          // this.productList.nativeElement.scrollTop = 0;
          if (this.filterValue.length >= 3) {
            this.loadMaterialList();
          } else {
            this.materials = [];
            this.isEmpty = true;
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {}

  public saveData(): void {
    // this.logger.info("CreateMaterialComboComponent.saveData() -> this.combo: " + JSON.stringify(this.combo));
    // this.logger.info("CreateMaterialComboComponent.saveData() -> this.comboCopy: " + JSON.stringify(this.comboCopy));
    //this.logger.info("CreateMaterialComboComponent.saveData() -> ARE EQUAL: " + _.isEqual(this.combo, this.comboCopy));

    // TODO: 3. apply same changes in other similar pop up
    if (!_.isEqual(this.combo, this.comboCopy)) {
      // this.logger.info("CreateMaterialComboComponent.saveData() -> IS NOT EQUAL, UPDATING THE DATABASE ");
      const children: any[] = [];
      for (let i = 0; i < this.combo.length; ++i) {
        children.push({
          uuid: this.combo[i].uuid,
          qty: this.combo[i].qty,
        });
      }

      // this.logger.info("CreateMaterialComboComponent.saveData() -> TO UPDATE: " + JSON.stringify(children));
      this.saving = true;
      this.disableBtn = true;
      this.materialsService
        .materialComboQtyUpdate(this.material.uuid, children)
        .subscribe({
          next: (response: any) => {
            // this.logger.info("CreateMaterialComboComponent.saveData() UPDATE -> SUCCESS: " + JSON.stringify(response));
            this.disableBtn = false;
            this.saving = false;
            this.dialogRef.close(true);
          },
          error: (error: any) => {
            this.disableBtn = false;
            this.saving = false;
            this.logger.error(
              'CreateMaterialComboComponent.saveData() UPDATE -> ERROR: ' +
                JSON.stringify(error),
            );
            this.handleAPIError(error, this.dialog, null, null);
          },
        });
    } else {
      //this.logger.info("CreateMaterialComboComponent.saveData() -> IS EQUAL, CLOSING WINDOW");
      this.dialogRef.close(true);
    }
  }

  onDrop(event: any) {
    // this.logger.info(event.source);
    // this.logger.info(typeof event.source);
    // this.logger.info(event.source.className.indexOf("combo-list"));
    // this.logger.info(isUndefined(event.value));
    if (event.value == undefined) {
      return;
    }

    if (event.source.className.indexOf('combo-list') !== -1) {
      this.onProductChildDrop(event);
      return;
    }

    event.value.qty = 1;
    const items: any[] = this.combo.filter((item) => {
      return item.uuid === event.value.uuid;
    });
    //("CreateMaterialComboComponent.onDrop() -> items: " + items.length);

    // If this product was already in the list, we add to qty
    if (items && items.length > 1) {
      // remove all except event.value & set qty to items.length
      let previousQuantity: number = 1;
      for (let i = 0; i < this.combo.length; ++i) {
        if (
          this.combo[i].uuid === event.value.uuid &&
          this.combo[i] !== event.value
        ) {
          previousQuantity = this.combo[i].qty;
          this.combo.splice(i, 1);
        }
      }
      event.value.qty += previousQuantity;

      //
      if (this.applyCall) {
        // this.logger.info("::: >>> uuid: " + this.material);
        // this.materialsService.productComboUpdateBoolean(this.material.uuid,
        // this.materialsService.productComboUpdate(this.material.uuid, event.value.uuid, event.value.qty)

        this.materialsService
          .productComboUpdateBoolean(
            this.material.uuid,
            event.value.uuid,
            items[0].qty,
            event.value.internal_view_only === false ? 0 : 1,
            event.value.show_unit_price === false ? 0 : 1,
          )
          .subscribe({
            next: (response) => {
              // this.logger.info("CreateMaterialComboComponent.onDrop() UPDATE -> SUCCESS: " + JSON.stringify(response));
            },
            error: (error) => {
              this.logger.error(
                'CreateMaterialComboComponent.onDrop() UPDATE -> ERROR: ' +
                  JSON.stringify(error),
              );
              this.handleAPIError(error, this.dialog, null, null);
            },
          });
      }
    } else if (this.applyCall) {
      this.materialsService
        .productComboAttach(
          this.material.uuid,
          event.value.uuid,
          event.value.qty,
        )
        .subscribe({
          next: (response) => {
            //this.logger.info("CreateMaterialComboComponent.onDrop() ATTACH -> SUCCESS: " + JSON.stringify(response.data.childs));
            this.combo = response.data.childs;
          },
          error: (error) => {
            this.logger.error(
              'CreateMaterialComboComponent.onDrop() ATTACH -> ERROR: ' +
                JSON.stringify(error),
            );
            this.handleAPIError(error, this.dialog, null, null);
          },
        });
    }
  }

  public onProductChildDrop(event: any) {
    // this.logger.info("ProductComponent.onProductChildDrop() -> " + JSON.stringify(event.value));
    // this.logger.info("CreateMaterialComboComponent.onProductChildDrop() -> " + JSON.stringify(this.material.childs));
    if (this.combo.length < 2 || !this.applyCall) {
      return;
    }

    let i: number = 0;
    const length: number = this.combo.length;
    for (i; i < length; ++i) {
      if (this.combo[i].uuid === event.value.uuid) {
        // move before
        if (i === 0) {
          // this.logger.info("Combo UUID: " + this.material.uuid);
          // this.logger.info("productUUID: " + event.value.uuid);
          // this.logger.info("beforeUUID: " +  this.combo[i + 1].uuid);
          this.materialsService
            .productChildMoveBefore(
              this.material.uuid,
              event.value.uuid,
              this.combo[i + 1].uuid,
            )
            .subscribe({
              next: (response) => {
                // this.logger.info("CreateMaterialComboComponent.onProductChildDrop() -> SUCCESS (productChildMoveBefore): " + JSON.stringify(response));
              },
              error: (error) => {
                this.logger.error(
                  'CreateMaterialComboComponent.onProductChildDrop() -> ERROR (productChildMoveBefore): ' +
                    JSON.stringify(error),
                );
                this.handleAPIError(error, this.dialog, null, null);
              },
            });
        } // move after
        else {
          // this.logger.info("Combo UUID: " + this.material.uuid);
          // this.logger.info("productUUID: " + event.value.uuid);
          // this.logger.info("afterUUID: " +  this.combo[i - 1].uuid);
          this.materialsService
            .productChildMoveAfter(
              this.material.uuid,
              event.value.uuid,
              this.combo[i - 1].uuid,
            )
            .subscribe({
              next: (response) => {
                // this.logger.info("CreateMaterialComboComponent.onProductChildDrop() -> SUCCESS (productChildMoveBefore): " + JSON.stringify(response));
              },
              error: (error) => {
                this.logger.error(
                  'CreateMaterialComboComponent.onProductChildDrop() -> ERROR (productChildMoveBefore): ' +
                    JSON.stringify(error),
                );
                this.handleAPIError(error, this.dialog, null, null);
              },
            });
        }
        break;
      }
    }
  }

  public onItemQtyChange(item: any, i: number): void {
    if (item.qty <= 0) {
      return;
    }

    if (this.applyCall) {
      this.materialsService
        .productComboUpdateBoolean(
          this.material.uuid,
          item.uuid,
          item.qty,
          item.internal_view_only === false ? 0 : 1,
          item.show_unit_price === false ? 0 : 1,
        )
        .subscribe({
          next: (response) => {
            // this.logger.info("CreateMaterialComboComponent.onLessItem() UPDATE -> SUCCESS: " + JSON.stringify(response));
          },
          error: (error) => {
            this.logger.error(
              'CreateMaterialComboComponent.onLessItem() UPDATE -> ERROR: ' +
                JSON.stringify(error),
            );
            this.handleAPIError(error, this.dialog, null, null);
            // case of error, re-add the item to quantiy
            item.qty += 1;
          },
        });
    }
  }

  public onRemoveItem(item: any, index: number) {
    // this.logger.info("CreateMaterialComboComponent.onRemoveItem() -> index: " + index);
    item.qty = 0;
    for (let i = 0; i < this.combo.length; ++i) {
      if (this.combo[i] === item) {
        this.combo.splice(i, 1);
        this.materialsService
          .productComboDetach(this.material.uuid, item.uuid, item.qty)
          .subscribe({
            next: (response) => {
              //("CreateMaterialComboComponent.onRemoveItem() Detach -> SUCCESS: " + JSON.stringify(response));
            },
            error: (error) => {
              this.logger.error(
                'CreateMaterialComboComponent.onRemoveItem() Detach -> ERROR: ' +
                  JSON.stringify(error),
              );
              this.handleAPIError(error, this.dialog, null, null);
              // in case of error, add the item back
              this.combo.splice(i, 0, item);
            },
          });
        break;
      }
    }
  }

  public onAddItem(item: any, doAddItemInModel: boolean = false) {
    const items: any[] = this.combo.filter((filterItem) => {
      // this.logger.info(filterItem.uuid === item.uuid);
      return filterItem.uuid === item.uuid;
    });

    if (items.length === 0) {
      item.qty = 1;
      this.combo.push(item);
      if (this.applyCall) {
        this.materialsService
          .productComboAttach(this.material.uuid, item.uuid, item.qty)
          .subscribe({
            next: (response) => {
              // this.logger.info("CreateMaterialComboComponent.onAddItem() ATTACH -> SUCCESS: " + JSON.stringify(response));
            },
            error: (error) => {
              this.logger.error(
                'CreateMaterialComboComponent.onAddItem() ATTACH -> ERROR: ' +
                  JSON.stringify(error),
              );
              this.handleAPIError(error, this.dialog, null, null);
              for (let i = 0; i < this.combo.length; ++i) {
                if (
                  this.combo[i].uuid === item.uuid &&
                  this.combo[i] !== item
                ) {
                  this.combo.splice(i, 1);
                  break;
                }
              }
            },
          });
      }
    } else {
      items[0].qty++;
      if (this.applyCall) {
        this.materialsService
          .productComboUpdateBoolean(
            this.material.uuid,
            item.uuid,
            items[0].qty,
            item.internal_view_only === false ? 0 : 1,
            item.show_unit_price === false ? 0 : 1,
          )
          .subscribe({
            next: (response) => {
              // this.logger.info("CreateMaterialComboComponent.onAddItem() UPDATE -> SUCCESS: " + JSON.stringify(response));
            },
            error: (error) => {
              this.logger.error(
                'CreateMaterialComboComponent.onAddItem() UPDATE -> ERROR: ' +
                  JSON.stringify(error),
              );
              this.handleAPIError(error, this.dialog, null, null);
              items[0].qty -= 1;
              item.qty -= 1;
            },
          });
      }
    }
    // this.logger.info("CreateComboComponent.onAddItem() -> items: " + items.length);
  }

  public onLessItem(item: any, i: number): void {
    if (item.qty - 1 === 0) {
      // this.onRemoveItem(item, i);
    } else {
      item.qty -= 1;
      if (this.applyCall) {
        this.materialsService
          .productComboUpdateBoolean(
            this.material.uuid,
            item.uuid,
            item.qty,
            item.internal_view_only === false ? 0 : 1,
            item.show_unit_price === false ? 0 : 1,
          )
          .subscribe({
            next: (response) => {
              // this.logger.info("CreateMaterialComboComponent.onLessItem() UPDATE -> SUCCESS: " + JSON.stringify(response));
            },
            error: (error) => {
              this.logger.error(
                'CreateMaterialComboComponent.onLessItem() UPDATE -> ERROR: ' +
                  JSON.stringify(error),
              );
              this.handleAPIError(error, this.dialog, null, null);
              // case of error, re-add the item to quantiy
              item.qty += 1;
            },
          });
      }
    }
  }

  public onRemove(event: any) {
    // this.logger.info("CreateComboComponent.onRemove() -> combo: " + this.combo.length);
    // this.logger.info("CreateMaterialComboComponent.onRemove() -> combo: " + event);

    if (event.value.qty > 1) {
      event.value.qty -= 1;
      this.combo.push(event.value);
    }
  }

  public clearSearch(): void {
    this.filterValue = '';
    this.materials = [];
    this.isEmpty = true;
  }

  public onCreateMaterial(): void {
    // this.logger.info("CreateMaterialComboComponent.onCreateMaterial()");
    this.newDialogRef = this.dialog.open(CreateMaterialComponent, {
      width: '340px',
      disableClose: false,
      data: {
        autoRedirect: false,
      },
    });

    this.newDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          // this.logger.info("CreateMaterialComboComponent.onCreateMaterial() -> SUCCESS: " + JSON.stringify(result));

          result.qty = 1;
          this.combo.push(result);

          this.materialsService
            .productComboAttach(this.material.uuid, result.uuid, result.qty)
            .subscribe({
              next: (response) => {
                // this.logger.info("CreateMaterialComboComponent.onCreateMaterial() ATTACH -> SUCCESS: " + JSON.stringify(response));
              },
              error: (error) => {
                this.logger.error(
                  'CreateMaterialComboComponent.onCreateMaterial() ATTACH -> ERROR: ' +
                    JSON.stringify(error),
                );
                this.handleAPIError(error, this.dialog, null, null);
                for (let i = 0; i < this.combo.length; ++i) {
                  if (this.combo[i].uuid === result.uuid) {
                    this.combo.splice(i, 1);
                    break;
                  }
                }
              },
            });

          this.loadMaterialList();
        }
        this.newDialogRef = null;
      },
    });
  }

  public onOpenInNewTab(item: any): void {
    window.open(ItemUrlFinder.getItemURL(item), '_blank');
  }
}
