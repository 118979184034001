import { Subject } from 'rxjs';
import { GT2FirebaseService } from '../../services/gt2-firebase.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { GT2ConstantsEnum } from './../const/GT2ConstantsEnum';
import { GT2PageAbstract } from './../content/abstract/GT2PageAbstract';
import { ErrorDialogComponent } from './../components/dialogs/error-dialog/error-dialog.component';
import {
  IComposeService,
  MailComposeDialogComponent,
} from './../content/compose/compose.component';
import { ReportViewerComponent } from './report-viewer/report-viewer.component';
import { ReportsService } from './reports.service';
import { MatDialog } from '@angular/material/dialog';
import {
  DistributionModeEnum,
  ExportFormatEnum,
  ExportToEnum,
  LocaleEnum,
  PaperEnum,
  ReportsEnum,
  SocketModeEnum,
} from './ReportsEnum';
import { ReportsFactory, ReportVOModel } from './reports-factory';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { GenericAlertComponent } from '../components/generic-alert/generic-alert.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class MenuReportsService {
  vo?: ReportVOModel;
  dialogRef: any;
  errorDialogRef: any;
  onProcessEmailAttachmentComplete: Subject<boolean> = new Subject();
  constructor(
    private logger: NGXLogger,
    // public fileService: FileService,
    // public iconsService: IconsService,
    public reportsService: ReportsService,
    private spinner: NgxSpinnerService,
    // private printService: PrintService,
    public translate: TranslateService,
    private dialog: MatDialog,
  ) {}

  public sendReport(orderUUID: string, composeService: IComposeService): void {
    //this.logger.info("MenuReportsService.sendReport() -> orderUUID: " + orderUUID);
    // this.logger.info(
    //     "MenuReportsService.sendReport() -> composeService: " +
    //         composeService
    // );
    this.onSendInvoiceReport(orderUUID, composeService);
  }

  public buildAndRunVo(
    report_key: ReportsEnum,
    distributionMode: DistributionModeEnum,
    exportFormat: ExportFormatEnum,
    exportTo?: ExportToEnum,
    local: LocaleEnum = LocaleEnum.FR_CA,
    paper: PaperEnum = PaperEnum.A4,
    params?: any,
    filename: string | null = null,
    job_queue: string | null = null,
  ): void {
    this.vo = ReportsFactory.getVO(
      report_key as string,
      distributionMode,
      exportFormat,
      exportTo,
      params,
      filename,
      local,
      paper,
      job_queue,
    );
    this.vo.local = local;
    this.vo.paper = paper;
    //this.logger.info("MenuReportsService.buildAndRunVo() -> Final VO: " + JSON.stringify(this.vo));
    this.runVO();
  }

  public runVO(): void {
    if (this.vo?.job_queue !== null) {
      this.vo.socket_key = GT2FirebaseService.CLIENT_KEY;
      this.vo.job_connection = 'database';
      this.vo.socket_mode =
        this.vo?.distribution_mode !== DistributionModeEnum.MAIL_ME
          ? SocketModeEnum.FIREBASE
          : SocketModeEnum.NONE;
      //this.logger.info("MenuReportsService.runVO() -> IS JOB QUEUE: " + JSON.stringify(this.vo));
    }

    //this.logger.info("MenuReportsService.runVO() -> IS JOB QUEUE: " + JSON.stringify(this.vo));
    if (this.vo?.export_to === ExportToEnum.PRINT) {
      this.onProcessPrint();
    } else if (this.vo?.export_to === ExportToEnum.PREVIEW) {
      this.onProcessPreview();
    } else if (this.vo?.export_to === ExportToEnum.DOWNLOAD) {
      this.onProcessDownload();
    } else if (
      this.vo?.export_to === ExportToEnum.NONE &&
      (this.vo.distribution_mode === DistributionModeEnum.EMAIL_ATTACHMENT ||
        this.vo.distribution_mode === DistributionModeEnum.MAIL_ME)
    ) {
      this.onProcessEmailAttachment();
    }
  }

  public onProcessPrint(): void {
    //this.logger.info("MenuReportsService.onProcessPrint()");
    if (this.vo?.job_queue === null) {
      this.spinner.show('reportSpinner');
    }

    this.reportsService
      .printPDFReports(this.vo)
      .catch((error) => {
        this.logger.error(
          'MenuReportsService.onProcessPrint() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.hideSpinner();
        this.handleAPIError(error, this.dialog, null, null);
      })
      .then((response) => {
        this.hideSpinner();
      });
  }

  public onProcessDownload(): void {
    //this.logger.info("MenuReportsService.onProcessDownload()");
    if (this.vo?.job_queue === null) {
      this.spinner.show('reportSpinner');
    }
    this.reportsService
      .processDownloadFile(this.vo)
      .catch((error) => {
        this.logger.error(
          'MenuReportsService.onProcessDownload().then() -> ERROR: ' +
            JSON.stringify(error),
        );

        this.hideSpinner();
        this.handleAPIError(error, this.dialog, null, null);
      })
      .then((response) => {
        // this.logger.info(
        //     "MenuReportsService.onProcessDownload().then() -> SUCCESS: " +
        //         JSON.stringify(response)
        // );
        this.hideSpinner();
      });
  }

  public onProcessEmailAttachment(): void {
    if (this.vo?.distribution_mode !== DistributionModeEnum.MAIL_ME) {
      this.spinner.show('reportSpinner');
    }
    this.reportsService
      .processEmailAttachment(this.vo)
      .catch((error) => {
        this.logger.error(
          'MenuReportsService.onProcessEmailAttachment().then() -> ERROR: ' +
            JSON.stringify(error),
        );

        this.hideSpinner();
        this.handleAPIError(error, this.dialog, null, null);
      })
      .then((response) => {
        if (this.vo?.distribution_mode === DistributionModeEnum.MAIL_ME) {
          this.showGenericMessage(
            this.translate.instant('GENERIC.ALERT_DISTRUBITION_MODE_MAIL_ME'),
          );
        }
        this.onProcessEmailAttachmentComplete.next(true);
        this.hideSpinner();
      });
  }
  public showGenericMessage(message: any) {
    this.dialogRef = this.dialog.open(GenericAlertComponent, {
      width: '45%',
      minWidth: 350,
      disableClose: false,
      panelClass: 'scrollable-dialog',
    });
    this.dialogRef.componentInstance.alertMessage = message;
  }
  public onProcessPreview(): void {
    //this.logger.info("MenuReportsService.onProcessPreview()");
    this.spinner.show('reportSpinner');
    this.reportsService
      .preparePreviewReports(this.vo)
      .catch((error: any) => {
        this.logger.error(
          'MenuReportsService.onProcessPreview().then() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.hideSpinner();
        this.handleAPIError(error, this.dialog, null, null);
      })
      .then((response: any) => {
        // this.logger.info(
        //     "ReportButtonComponent.onProcessPreview().then() -> SUCCESS: " +
        //         JSON.stringify(response)
        // );
        this.hideSpinner();
        this.openReportPreview(response);
      });
  }

  public openReportPreview(rawHTML: string): void {
    // this.logger.info("TestComponent.openReportPreview() --> rawHTML: " + rawHTML);
    this.dialogRef = this.dialog.open(ReportViewerComponent, {
      maxWidth: '8.8in',
      minWidth: 350,
      height: '96vh',
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: {
        vo: this.vo,
        rawHTML: rawHTML,
      },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        // if (result) {
        // }
        this.dialogRef = null;
      },
    });
  }

  public onSendInvoiceReport(
    orderUUDID: string,
    composeService: IComposeService,
  ): void {
    // TODO: IMPLEMENT V2
    // this.logger.info("OrderComponent.onSendInvoiceReport()");

    // IMPORTANT
    composeService.parentUUID = orderUUDID;

    this.dialogRef = this.dialog.open(MailComposeDialogComponent, {
      panelClass: 'mail-compose-dialog',
      data: {
        composeService: composeService,
      },
    });
    this.dialogRef.afterClosed().subscribe({
      next: (response: any) => {
        if (response) {
          // this.logger.info("MenuReportsService.onSendInvoiceReport() -> Dialog closed, response exist, reloading data NOW!!");
          // TODO: FIND A SOLUTION FOR RELOADING DATA IN PARENT. EVENT EMITTER AS OUTPUT?
          // this.loadData();
        }
        this.dialogRef = null;
      },
    });
  }

  public hideSpinner(): void {
    setTimeout(() => {
      this.spinner.hide('reportSpinner');
    }, 50);
  }

  public handleAPIError(
    error: any,
    dialog: MatDialog,
    toastr: ToastrService,
    toastrError: string,
  ): void {
    if (!dialog || !error) {
      return;
    }

    // Show a toastr error message if error is not a 412
    if (toastr && toastrError && error.status !== 412) {
      toastr.error('', toastrError);
    }

    if (error.status === 422) {
      // OK
      this.errorDialogRef = dialog.open(ErrorDialogComponent, {
        disableClose: false,
      });

      this.errorDialogRef.componentInstance.setError(error);

      this.errorDialogRef.afterClosed().subscribe({
        next: (result: any) => {
          this.errorDialogRef = null;
        },
      });
    } else if (error.status === 412) {
      // OK
      this.errorDialogRef = dialog.open(ErrorDialogComponent, {
        disableClose: false,
      });

      this.errorDialogRef.componentInstance.useError456Template = true;
      this.errorDialogRef.componentInstance.setError(error);

      this.errorDialogRef.afterClosed().subscribe({
        next: (result: any) => {
          this.errorDialogRef = null;
        },
      });
    } else if (error.status === 401) {
      // Do nothing
    } else if (error.status === 404) {
      // Do nothing
    } else if (error.status === 500) {
      // Do nothing
    } else if (error.status === 0) {
      this.errorDialogRef = dialog.open(ErrorDialogComponent, {
        disableClose: false,
      });

      let customError: any = {
        error: {
          title: 'Erreur',
          errors: {
            key: ['Une erreur inconnue est survenue.'],
          },
        },
      };

      if (GT2PageAbstract.USER_ROLE === GT2ConstantsEnum.GOD) {
        customError = {
          error: {
            title: 'Error status 0 (God user only)',
            errors: {
              key: [
                'Error status 0, Http failure response for (unknown url). Please check for code that could break execution like a dump() and that the JSON object is valid.',
              ],
            },
          },
        };
      }

      if (customError) {
        this.errorDialogRef.componentInstance.setError(customError);
        this.errorDialogRef.afterClosed().subscribe({
          next: (result: any) => {
            this.errorDialogRef = null;
          },
        });
      } else {
        this.errorDialogRef.componentInstance.setError(error);
      }
    } else {
      this.errorDialogRef = dialog.open(ErrorDialogComponent, {
        disableClose: false,
      });

      let customError: any = {
        error: {
          title: 'Erreur',
          errors: {
            key: ['Une erreur inconnue est survenue.'],
          },
        },
      };
      if (GT2PageAbstract.USER_ROLE === GT2ConstantsEnum.GOD) {
        this.errorDialogRef.componentInstance.setError(error);
      } else {
        this.errorDialogRef.componentInstance.setError(customError);
      }
    }
  }
}
