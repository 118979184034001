<div
  class="page-layout simple left-sidebar inner-sidebar inner-scroll"
  id="shop-web"
>
  <ng-template #visitHintTemplate>
    <p>{{ "SHOPS.VISIT" | translate }}</p>
  </ng-template>
  <!-- HEADER -->
  <div class="gt2-theme">
    <div
      class="header accent pl-24 pr-24 h-80 mat-elevation-z1"
      fusePerfectScrollbar
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div fxLayout="row" fxLayoutAlign="start center">
        <button
          (click)="onPageBack(location, router, '/shops')"
          class="mr-0 mr-sm-16 white-fg"
          mat-icon-button
        >
          <mat-icon class="icon-mat">arrow_back</mat-icon>
        </button>
        <div fxLayout="column" fxLayoutAlign="center start">
          <div class="user-info" fxLayout="row" fxLayoutAlign="start start">
            <div
              *fuseIfOnDom
              [@animate]="{
                value: '*',
                params: { delay: '100ms', x: '-25px' },
              }"
              class="shop-title"
            >
              <div *ngIf="shop" class="">
                <span class="text-truncate"
                  >{{ "SHOPS.WEB_SHOP" | translate }} - {{ shop.name }}</span
                >
              </div>
            </div>
          </div>
          <div fxLayout="row">
            <div *ngIf="shop && shop.shop_type">
              {{ "SHOPS.INTEGRATION_TYPE" | translate }}:
              {{ shop.shop_type.name }}
            </div>
            <div *ngIf="shop && shop.shop_product_type">
              &nbsp;| {{ "SHOPS.SELL_TYPE" | translate }}:
              {{ shop.shop_product_type.name }}
            </div>
          </div>
        </div>

        <div style="margin-right: 12px">
          <img *ngIf="shop && shop.logos" src="{{ shop.logos.logo_white }}" />
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="end start" style="width: 40px">
        <button
          (click)="onOpenInNewTab()"
          class="mat-icon-button"
          mat-button
          matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
        >
          <mat-icon class="icon-mat s-30">{{ getIcon("NEW_TAB") }}</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <!-- / HEADER -->

  <div
    *ngIf="shop"
    class="primary mat-elevation-z1 py-8 gt2-sub-header"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    style="height: 40px; min-height: 40px; max-height: 40px"
  >
    <div
      *ngIf="shop.shop_web"
      fxLayout="row"
      fxLayoutAlign="start center"
      style="margin-left: 20px"
    >
      <div *ngIf="!shop.shop_web.gt_store_url" class="info text-truncate">
        {{ "SHOPS.GT_STORE_URL" | translate }}: {{ shop.shop_web.gt_store_url }}
      </div>
      <div
        *ngIf="shop.shop_web.gt_store_url"
        (click)="openURL(shop.shop_web.gt_store_url)"
        class="info text-truncate link-cursor"
      >
        {{ "SHOPS.GT_STORE_URL" | translate }}: {{ shop.shop_web.gt_store_url }}
      </div>

      <mat-icon
        *ngIf="shop.shop_web.gt_store_url"
        matTooltip="visitHintTemplate"
        content-type="template"
        style="margin-left: 10px; cursor: pointer"
        (click)="openURL(shop.shop_web.gt_store_url)"
        color="accent"
      >
        link</mat-icon
      >
    </div>
  </div>

  <!--HISTORY SIDEBAR-->
  <div *ngIf="!isSmallScreen" class="container-history-panel">
    <fuse-sidebar
      class="section-history-panel"
      name="shopHistoryPanel"
      position="right"
    >
      <app-section-history-panel
        panelName="shopHistoryPanel"
        section="shops"
        sectionUUID="{{ shopID }}"
      >
      </app-section-history-panel>
    </fuse-sidebar>
  </div>

  <div class="content">
    <!--HISTORY SIDEBAR BUTTON-->
    <div *ngIf="!isSmallScreen">
      <button
        (click)="toggleHistoricSidebarOpen('shopHistoryPanel')"
        *fuseIfOnDom
        [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }"
        class="warn mat-elevation-z2 historic-button"
        mat-icon-button
      >
        <mat-icon class="icon-mat">history</mat-icon>
      </button>
    </div>

    <!-- CENTER -->
    <div #mainContainer class="center p-24 pb-56 pr-sm-72" fusePerfectScrollbar>
      <!-- CONTENT -->
      <div class="shop-content">
        <div fxFlex="100" fxLayout="row" fxLayoutAlign="start start">
          <div
            *ngIf="shop"
            fxLayout="column"
            fxFlex="300px"
            style="margin-right: 16px"
          >
            <div class="profile-box info-box" fxLayout="column">
              <header
                class="accent"
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                <div class="title">Menu</div>
              </header>

              <div
                *ngIf="currentSection && menuItems && menuItems.length > 0"
                class=""
                fxLayout="column"
              >
                <div *ngFor="let item of menuItems">
                  <div
                    class="menu-child-item"
                    *ngxPermissionsOnly="item.role"
                    [ngClass]="
                      currentSection.id === item.id
                        ? 'menu-child-item-selected'
                        : ''
                    "
                    (click)="onMenuSelected(item)"
                  >
                    {{ item.label }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="shop" fxLayout="column" fxFlex style="margin-right: 16px">
            <div
              [ngSwitch]="currentSection.id"
              fxFlex="100"
              class="width-100-content"
            >
              <div *ngSwitchCase="'configuration'" class="" fxLayout="column">
                <div class="profile-box info-box general">
                  <header class="accent">
                    <div class="title">
                      {{ "SHOPS.CONFIGURATION" | translate }}
                    </div>
                  </header>

                  <div class="content" fxLayout="column">
                    <app-general-configurations
                      [shop]="shop"
                      [editMode]="editMode"
                      [form]="form"
                      (saveInfo)="onSaveInfo()"
                    >
                    </app-general-configurations>
                  </div>
                </div>
              </div>

              <div *ngSwitchCase="'points-takeout'" class="" fxLayout="column">
                <div class="profile-box info-box general">
                  <header class="accent">
                    <div class="title">
                      {{ "SHOPS.MULTI_POINT_TAKEOUT" | translate }}
                    </div>
                  </header>

                  <div class="content" fxLayout="column">
                    <app-poinst-takeout
                      [shop]="shop"
                      [editMode]="editMode"
                      [multiPointTakeoutModel]="multiPointTakeoutModel"
                      (addMultiPointTakeout)="onAddMultiPointTakeout()"
                      (editPointTakeout)="onEditPointTakeout($event)"
                      (removePointTakeout)="onRemovePointTakeout($event)"
                      (saveInfo)="onSaveInfo()"
                    >
                    </app-poinst-takeout>
                  </div>
                </div>
              </div>

              <!-- MONERIS -->
              <div *ngSwitchCase="'moneris'" class="" fxLayout="column">
                <div class="profile-box info-box general">
                  <header class="accent">
                    <div class="title">{{ "SHOPS.MONERIS" | translate }}</div>
                  </header>

                  <div *ngIf="monerisModel" class="content" fxLayout="column">
                    <app-moneris-settings
                      [shop]="shop"
                      [editMode]="editMode"
                      [monerisModel]="monerisModel"
                      [formMoneris]="formMoneris"
                      (addMonerisProfile)="onAddMonerisProfile()"
                      (removeMonerisProfile)="onRemoveMonerisProfile($event)"
                      (saveMonerisInfo)="onSaveMonerisInfo()"
                    >
                    </app-moneris-settings>
                  </div>
                </div>
              </div>

              <!-- STRIPE -->
              <div *ngSwitchCase="'stripe'" class="" fxLayout="column">
                <div class="profile-box info-box general">
                  <header class="accent">
                    <div class="title">{{ "SHOPS.STRIPE" | translate }}</div>
                  </header>

                  <div *ngIf="stripeModel" class="content" fxLayout="column">
                    <app-stripe-settings
                      [shop]="shop"
                      [editMode]="editMode"
                      [stripeModel]="stripeModel"
                      [formStripe]="formStripe"
                      (saveStripeInfo)="onSaveStripeInfo()"
                    >
                    </app-stripe-settings>
                  </div>
                </div>
              </div>

              <!-- GLOBAL PAYMENTS -->
              <div *ngSwitchCase="'global_payments'" class="" fxLayout="column">
                <div class="profile-box info-box general">
                  <header class="accent">
                    <div class="title">
                      {{ "SHOPS.GLOBAL_PAYMENT" | translate }}
                    </div>
                  </header>
                  <div
                    *ngIf="globalPaymentsModel"
                    class="content"
                    fxLayout="column"
                  >
                    <app-global-payments-settings
                      [shop]="shop"
                      [editMode]="editMode"
                      [globalPaymentsModel]="globalPaymentsModel"
                      [formGP]="formGP"
                      (saveGlobalPaymentsInfo)="saveGlobalPaymentsInfo()"
                    >
                    </app-global-payments-settings>
                  </div>
                </div>
              </div>

              <!-- THEME -->
              <div *ngSwitchCase="'theme'" class="" fxLayout="column">
                <div class="profile-box info-box general">
                  <header class="accent">
                    <div class="title">{{ "SHOPS.THEME" | translate }}</div>
                  </header>
                  <div class="content" fxLayout="column">
                    <app-themes-settings
                      [shop]="shop"
                      [editMode]="editMode"
                      [(defaultTheme)]="defaultTheme"
                      [(isThemeDark)]="isThemeDark"
                      (saveInfo)="onSaveInfo()"
                    >
                    </app-themes-settings>
                  </div>
                </div>
              </div>

              <!-- DELIVERY RULE -->
              <div *ngSwitchCase="'deliveryRules'" class="" fxLayout="column">
                <div class="profile-box info-box general">
                  <header class="accent">
                    <div class="title">
                      {{ "SHOPS.DELIVERY_RULES" | translate }}
                    </div>
                  </header>

                  <div *ngIf="deliveryRules" class="content" fxLayout="column">
                    <app-delivery-rules
                      [shop]="shop"
                      [editMode]="editMode"
                      [form]="form"
                      [shopPreference]="shopPreference"
                      (saveDeliveryRules)="onSaveDeliveryRules()"
                      [deliveryRules]="deliveryRules"
                    >
                    </app-delivery-rules>
                  </div>
                </div>
              </div>

              <!-- scheduleGenerator -->
              <!-- for role rule to show up, check TS file 'MenuItems' AND godOnlySection-->
              <div *ngSwitchCase="'scheduleGenerator'" fxLayout="column">
                <div class="profile-box info-box general">
                  <header
                    class="accent"
                    fxLayout="row"
                    fxLayoutAlign="space-between center"
                  >
                    <div class="title">
                      {{ "SHOPS.RTE_SCHEDULE" | translate }}
                    </div>
                  </header>

                  <div class="content" fxLayout="column">
                    <app-rte-schedule
                      [shopUUID]="shop.uuid"
                      [shop]="shop"
                      (pauseChange)="rteTemplateModelPauseChange($event)"
                      [editMode]="editMode"
                      [index]="0"
                      [rteUUID]="shop.shop_rte_schedule_template_uuids[0]"
                      #rteSchedule
                      [shopTypeSlug]="shop.shop_type.slug"
                      [shopSlug]="shop.slug"
                      (saveInfo)="onSaveInfo()"
                    >
                    </app-rte-schedule>

                    <div
                      *ngIf="
                        editMode && rteSchedule && rteSchedule.rteTemplateModel
                      "
                      fxLayout="row"
                      fxLayoutAlign="end start"
                      class=""
                    >
                      <button
                        (click)="rteSchedule.onSaveRTETemplate()"
                        color="accent"
                        class="save-btn"
                        [disabled]="
                          !shopPreference ||
                          (!rteSchedule.rteTemplateModel.with_delivery &&
                            !rteSchedule.rteTemplateModel.with_takeout)
                        "
                        mat-raised-button
                      >
                        {{ "GENERIC.SAVE" | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- address -->
              <div *ngSwitchCase="'address'" class="" fxLayout="column">
                <div class="profile-box info-box general">
                  <header class="accent">
                    <div class="title">{{ "GENERIC.ADDRESS" | translate }}</div>
                  </header>

                  <div class="content-no-padding" fxLayout="column">
                    <app-address
                      #addressComponent
                      [groupModel]="shop.address"
                      [isVisible]="true"
                      [editMode]="editMode"
                      [hideSaveButton]="true"
                      [useAutosave]="false"
                      (addressEvent)="onNewAdress($event)"
                      class=""
                    >
                    </app-address>

                    <div
                      *ngIf="editMode"
                      fxLayout="row"
                      fxLayoutAlign="end start"
                      class="pb-16 pr-16"
                    >
                      <button
                        (click)="onSaveAdress()"
                        color="accent"
                        class="save-btn"
                        [disabled]="
                          !shop.address ||
                          !shop.address.street_number ||
                          !shop.address.street ||
                          !shop.address.province ||
                          !shop.address.country ||
                          !shop.address.postal_code
                        "
                        mat-raised-button
                      >
                        {{ "GENERIC.SAVE" | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngSwitchCase="'preferences'" class="" fxLayout="column">
                <div class="profile-box info-box general">
                  <header class="accent">
                    <div class="title">
                      {{ "SHOPS.PREFERENCE" | translate }}
                    </div>
                  </header>

                  <div class="content" fxLayout="column">
                    <app-shop-preference
                      *ngIf="shopPreference"
                      [editMode]="editMode"
                      [shopPreference]="shopPreference"
                    >
                    </app-shop-preference>

                    <div
                      *ngIf="editMode"
                      fxLayout="row"
                      fxLayoutAlign="end start"
                      class=""
                    >
                      <button
                        (click)="onSavePreference()"
                        color="accent"
                        class="save-btn"
                        [disabled]="!shopPreference"
                        mat-raised-button
                      >
                        {{ "GENERIC.SAVE" | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngSwitchCase="'calendar'" class="" fxLayout="column">
                <div class="profile-box info-box general">
                  <header class="accent">
                    <div class="title">
                      {{ "SHOPS.BUSINESS_DAYS" | translate }}
                    </div>
                  </header>

                  <div class="content-no-padding" fxLayout="column">
                    <app-business-days
                      #businessDaysComponent
                      [editMode]="editMode"
                      [(businessDays)]="businessDays"
                    >
                    </app-business-days>

                    <div
                      *ngIf="editMode"
                      fxLayout="row"
                      fxLayoutAlign="end start"
                      class="mr-16 mb-16"
                    >
                      <button
                        (click)="onSaveBusinessDay()"
                        color="accent"
                        class="save-btn"
                        [disabled]="
                          (businessDaysComponent.calendarDayState === 2 &&
                            (!businessDaysComponent.selectedBusinessDay
                              .open_business_custom_hour_start_at ||
                              !businessDaysComponent.selectedBusinessDay
                                .open_business_custom_hour_end_at)) ||
                          (businessDaysComponent.calendarDayTakeOutState ===
                            2 &&
                            (!businessDaysComponent.selectedBusinessDay
                              .takeout_business_custom_hour_start_at ||
                              !businessDaysComponent.selectedBusinessDay
                                .takeout_business_custom_hour_end_at))
                        "
                        mat-raised-button
                      >
                        {{ "GENERIC.SAVE" | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngSwitchCase="'businessHours'" class="" fxLayout="column">
                <div class="profile-box info-box general">
                  <header class="accent">
                    <div class="title">
                      {{ "SHOPS.BUSINESS_HOURS" | translate }}
                    </div>
                  </header>

                  <div class="content" fxLayout="column">
                    <div class="info-line">
                      <div class="title">
                        {{ "SHOPS.BUSINESS_HOURS" | translate }}
                      </div>
                    </div>
                    <app-hours-grid
                      *ngIf="businessHours"
                      [(hoursModel)]="businessHours"
                      [editMode]="editMode"
                    >
                    </app-hours-grid>

                    <div
                      *ngIf="editMode"
                      fxLayout="row"
                      fxLayoutAlign="end start"
                      class="mt-16"
                    >
                      <button
                        (click)="onSaveBusinessHours(businessHours)"
                        color="accent"
                        class="save-btn"
                        [disabled]="!shop.address"
                        mat-raised-button
                      >
                        {{ "GENERIC.SAVE" | translate }}
                      </button>
                    </div>

                    <div class="info-line mt-32">
                      <div class="title">
                        {{ "SHOPS.TAKEOUT_HOURS" | translate }}
                      </div>
                    </div>
                    <app-hours-grid
                      *ngIf="takeOutHours"
                      [(hoursModel)]="takeOutHours"
                      [editMode]="editMode"
                    >
                    </app-hours-grid>

                    <div
                      *ngIf="editMode"
                      fxLayout="row"
                      fxLayoutAlign="end start"
                      class="mt-16"
                    >
                      <button
                        (click)="onSaveBusinessHours(takeOutHours)"
                        color="accent"
                        class="save-btn"
                        [disabled]="!shop.address"
                        mat-raised-button
                      >
                        {{ "GENERIC.SAVE" | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngSwitchCase="'tips'" class="" fxLayout="column">
                <div class="profile-box info-box general">
                  <header class="accent">
                    <div class="title">{{ "SHOPS.TIPS" | translate }}</div>
                  </header>

                  <div class="content" fxLayout="column">
                    <app-tips-settings
                      [shop]="shop"
                      [editMode]="editMode"
                      [formTips]="formTips"
                      (saveInfo)="onSaveInfo()"
                    >
                    </app-tips-settings>
                  </div>
                </div>
              </div>

              <div *ngSwitchCase="'terms'" class="" fxLayout="column">
                <div class="profile-box info-box general">
                  <header class="accent">
                    <div class="title">
                      {{ "SHOPS.TERMS_CONDITIONS" | translate }}
                    </div>
                  </header>

                  <div class="content" fxLayout="column">
                    <div
                      fxLayout="row"
                      fxLayout.xs="column"
                      fxLayoutAlign="start start"
                    >
                      <div class="info-line" fxFlex="100%">
                        <div class="title">
                          {{ "GENERIC.fr-CA" | translate }}
                        </div>
                        <app-rich-text-locale
                          #termsFR
                          [richText]="shop.terms_local"
                          [localKey]="'terms_fr_CA'"
                          [maxLength]="8000"
                          [showClearButton]="false"
                          [showSaveButton]="false"
                          [invalid]="termsFRInvalid || termsENInvalid"
                          [editMode]="editMode"
                          (changeEvent)="termsFRInvalid = $event.invalid"
                          (saveEvent)="onSaveTerms($event, 'fr-CA')"
                        ></app-rich-text-locale>

                        <div class="title mt-16">
                          {{ "GENERIC.en-CA" | translate }}
                        </div>
                        <app-rich-text-locale
                          #termsEN
                          [richText]="shop.terms_local"
                          [localKey]="'terms_en_CA'"
                          [applyFocus]="false"
                          [maxLength]="8000"
                          [editMode]="editMode"
                          [showClearButton]="true"
                          [invalid]="
                            termsFRInvalid || termsENInvalid || !editMode
                          "
                          (changeEvent)="termsENInvalid = $event.invalid"
                          (clearEvent)="onClearTerms()"
                          (saveEvent)="onSaveTerms($event, 'en-CA')"
                        >
                        </app-rich-text-locale>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngSwitchCase="'notifications'" class="" fxLayout="column">
                <div class="profile-box info-box general">
                  <header class="accent">
                    <div class="title">
                      {{ "SHOPS.NOTIFICATIONS" | translate }}
                    </div>
                  </header>

                  <!-- ORDER (LEFT) / CORPO (RIGHT) -->
                  <div class="content" fxLayout="column">
                    <app-notifications-settings
                      [shop]="shop"
                      [editMode]="editMode"
                      [boxName]="'box_notification3'"
                      (saveInfo)="onSaveInfo()"
                    >
                    </app-notifications-settings>
                  </div>
                </div>
                <div class="profile-box info-box general">
                  <header class="accent">
                    <div class="title">
                      {{ "SHOPS.NOTIFICATIONS_BY_ROLE" | translate }}
                    </div>
                  </header>

                  <!-- ORDER (LEFT) / CORPO (RIGHT) -->
                  <div class="content" fxLayout="column">
                    <app-notifications-settings
                      [shop]="shop"
                      [editMode]="editMode"
                      [boxName]="'box_notification'"
                      (saveInfo)="onSaveInfo()"
                    >
                    </app-notifications-settings>
                  </div>
                </div>
                <div class="profile-box info-box general">
                  <header class="accent">
                    <div class="title">
                      {{ "SHOPS.NOTIFICATIONS_USER_CORPORATE" | translate }}
                    </div>
                  </header>

                  <!-- ORDER (LEFT) / CORPO (RIGHT) -->
                  <div class="content" fxLayout="column">
                    <app-notifications-settings
                      [shop]="shop"
                      [editMode]="editMode"
                      [boxName]="'box_notification2'"
                      (saveInfo)="onSaveInfo()"
                    >
                    </app-notifications-settings>
                  </div>
                </div>
              </div>

              <div *ngSwitchCase="'messages'" class="" fxLayout="column">
                <div class="profile-box info-box general">
                  <header class="accent">
                    <div class="title">{{ "SHOPS.MESSAGES" | translate }}</div>
                  </header>

                  <div class="content" fxLayout="column">
                    <app-messages-settings
                      [shopMessage]="shopMessageModel"
                      [editMode]="editMode"
                      (saveUnavailableMessage)="
                        onSaveUnavailableMessage($event)
                      "
                      (clearUnavailableMessage)="onClearUnavailableMessage()"
                      (clearMessage)="onClearMessage()"
                      (saveMessage)="onSaveMessage($event)"
                    >
                    </app-messages-settings>
                  </div>
                </div>

                <!-- GOD ONLY FOR NOW -->
                <div
                  *ngxPermissionsOnly="rolesService.GOD_ROLES"
                  class="profile-box info-box general"
                >
                  <header class="accent">
                    <div class="title">
                      {{ "SHOPS.IMAGE_MESSAGE" | translate }}
                    </div>
                  </header>

                  <div class="content" fxLayout="column">
                    <app-message-image-settings
                      [shopMessageImage]="shopMessageImageModel"
                      [editMode]="editMode"
                      [form]="formMessageImage"
                      (saveMessageImage)="onSaveMessageImage($event)"
                      (clealMessageImage)="onClearMessageImage($event)"
                    >
                    </app-message-image-settings>
                  </div>
                </div>
              </div>

              <!-- CATEGORIES WITH DRAG AND DROP -->
              <div *ngSwitchCase="'categories'" class="" fxLayout="column">
                <div class="profile-box info-box general">
                  <header class="accent">
                    <div class="title">
                      {{ "SHOPS.CATEGORIES" | translate }}
                    </div>
                  </header>

                  <div class="" fxLayout="column">
                    <div
                      class=""
                      fxLayout="column"
                      #categoriesContainer
                      style="min-width: 370px; height: 100%"
                      fusePerfectScrollbar
                    >
                      <!-- HACK! display: none-->
                      <!-- <ngx-dnd-container [ngModel]="['Item 1', 'Item 2', 'Item 3']" style="display: none"> </ngx-dnd-container> -->

                      <div
                        *ngIf="editMode"
                        [ngModel]="shopCatgories"
                        cdkDropList
                        (cdkDropListDropped)="onCategoryChildDrop($event)"
                      >
                        @for (item of shopCatgories; track item) {
                          <div
                            class="categories-child-item"
                            fxLayout="row"
                            [cdkDragData]="item"
                            fxLayoutAlign="start center"
                            matRipple
                            cdkDrag
                          >
                            <mat-icon class="icon-mat-dark mr-8"
                              >drag_handle</mat-icon
                            >
                            <div
                              [matTooltipPosition]="'right'"
                              fxLayout="row"
                              fxLayoutAlign="start center"
                              matTooltip="{{ item.name }}"
                            >
                              {{ item.name }}
                            </div>
                          </div>
                        }
                      </div>

                      <div *ngIf="!editMode" class="">
                        <div
                          *ngFor="let item of shopCatgories; let i = index"
                          class="categories-child-item-disabled"
                          fxLayout="row"
                          fxLayoutAlign="space-between center"
                        >
                          <div
                            (click)="onNavigateToCategory(item)"
                            [matTooltipPosition]="'right'"
                            fxLayout="row"
                            fxLayoutAlign="start center"
                            matTooltip="{{ item.name }}"
                          >
                            {{ item.name }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- / CONTENT -->
    </div>
    <!-- / CENTER -->
  </div>

  <div *ngIf="shop && editable" fxLayout="row" fxLayoutAlign="end start">
    <div *ngxPermissionsOnly="rolesService.TEAM_LEAD_ROLES">
      <div class="fixed-button">
        <button
          (click)="onEdit($event)"
          *fuseIfOnDom
          [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }"
          [disabled]="loading"
          class="gt2-edit-button"
          id="edit-shop-button"
          mat-fab
        >
          <mat-icon class="icon-mat-black" *ngIf="!editMode">{{
            getIcon("EDIT_FAB")
          }}</mat-icon>
          <mat-icon class="icon-mat-black" *ngIf="editMode">{{
            getIcon("UNDO")
          }}</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
