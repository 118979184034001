import { MenuReportsService } from './../../reports/menu-reports.service';
import { ReportFactoryService } from './../../reports/report-factory.service';
import { CalendarModule as AngularCalendarModule } from 'angular-calendar';
import { ReportsService } from './../../reports/reports.service';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FlatpickrModule } from 'angularx-flatpickr';
import { NgxSelectModule } from 'ngx-select-ex';
import { NgxStripeModule } from 'ngx-stripe';
import { ButtonModule } from 'primeng/button';
import { EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { Gt2DashboardService } from '../../api/gt2-dashboard.service';
import { PhoneNumberWithExtComponent } from '../../components/phone-number-with-ext/phone-number-with-ext.component';
import { TestComponent } from './test.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { EventsService } from '../../api/events.service';
import { GT2DateAdapter } from '../../utils/GT2DateAdapter';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import { ColorPickerComponent } from './../../../color-picker/color-picker.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { AppRoutesName } from '../../const/AppRoutesName';
import { TranslateModule } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { ReportButtonComponent } from '../../reports/report-button/report-button.component';
import { ReportViewerComponent } from '../../reports/report-viewer/report-viewer.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import moment from 'moment';

const routes = [
  {
    path: AppRoutesName.EN.TEST,
    component: TestComponent,
  },
  {
    path: AppRoutesName.FR.TEST,
    component: TestComponent,
  },
];

function momentAdapterFactory() {
  return adapterFactory(moment);
}

@NgModule({
  declarations: [TestComponent],
  imports: [
    ColorPickerComponent,
    MatTooltipModule,
    MatIconModule,
    PhoneNumberWithExtComponent,
    MatMenuModule,
    TranslateModule,
    ReportButtonComponent,
    ColorPickerModule,
    MatButtonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    RouterModule.forChild(routes),
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MultiSelectModule,
    NgxSelectModule,
    TabMenuModule,
    MessagesModule,
    EditorModule,
    CommonModule,
    FormsModule,
    ButtonModule,
    TabViewModule,
    FileUploadModule,
    NgxStripeModule,
    FlatpickrModule.forRoot(),
    BrowserModule,
    NgxMaterialTimepickerModule,
    Gt2PrimengModule,
    ReportViewerComponent,
    AngularCalendarModule.forRoot({
      provide: GT2DateAdapter,
      useFactory: momentAdapterFactory,
    }),
  ],
  exports: [TestComponent],
  providers: [
    Gt2DashboardService,
    EventsService,
    ReportsService,
    ReportFactoryService,
    MenuReportsService,
  ],
})
export class TestModule {}
