import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ApiRoutes } from '../../api/ApiRoutes';
import { Gt2ApiService } from '../../api/gt2-api.service';
import { VenuesModel } from '../../api/models/VenuesModel';

@Injectable()
export class VenuesService {
  places?: any[];

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private api: Gt2ApiService,
  ) {}

  public getPlacesWithPagination(
    sort: string,
    direction: string,
    page: number,
    pageSize: number,
    filterValue: string,
  ): Promise<VenuesModel> {
    const routeURL: string = this.api.createUrl(ApiRoutes.PLACES);
    let requestUrl: string;
    if (!sort || direction === '') {
      requestUrl = `${routeURL}?page=${page + 1}&limit=${pageSize}`;
    } else {
      requestUrl = `${routeURL}?orderBy=${sort}&sortedBy=${direction}&page=${
        page + 1
      }&limit=${pageSize}`;
    }

    // Remove special caracters from the filter value for proper searching
    filterValue = filterValue.replace(/%/g, '');

    if (filterValue !== '') {
      requestUrl += '&search=' + filterValue;
    }

    return new Promise((resolve, reject) => {
      this.http.get(requestUrl).subscribe({
        next: (response: any) => {
          this.places = response.data;
          // this.logger.info("VenuesService.getPlacesWithPagination() -> response: " + JSON.stringify(response));
          // this.logger.info("VenuesService.getPlacesWithPagination() -> this.places: " + JSON.stringify(this.places));
          resolve(response);
        },
        error: reject,
      });
    });
  }

  public deleteVenue(venue: any) {
    // this.logger.info("VenuesService.deleteVenue() -> venue: " + JSON.stringify(venue));
    return this.http.delete<any>(
      this.api.createUrl(ApiRoutes.DELETE_PLACE) + venue.uuid,
    );
  }

  public getVenue(venueID: string) {
    // this.logger.info("VenuesService.getVenue() -> venueID: " + venueID);
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.GET_PLACE) + venueID,
    );
  }

  public updateVenue(venue: any, venueID: string) {
    // this.logger.info("VenuesService.updateEmployee() -> updateVenue: " + venueID);
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.UPDATE_PLACE) + venueID,
      venue,
    );
  }

  public createVenue(venue: any) {
    // this.logger.info("VenuesService.createVenue() -> venue: " + JSON.stringify(venue));
    return this.http.post<any>(this.api.createUrl(ApiRoutes.PLACES), venue);
  }
}
