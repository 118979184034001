import { AppRoutesService } from './../../services/app-routes.service';
import { RoomsComponent } from './../rooms/rooms.component';
import { RichTextsValidator } from './../../utils/RichTextsValidator';
import { ICanDeactivateComponent } from './../../gards/can-deactivate-guard.service';
import { Location } from '@angular/common';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ItemUrlFinder } from '../../utils/item-url-finder';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Editor } from 'primeng/editor';
import { Subscription } from 'rxjs';
import { fuseAnimations } from '../../../../@fuse/animations/index';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { FileService } from '../../../services/file.service';
import { PrintService } from '../../../services/print.service';
import { Gt2ApiService } from '../../api/gt2-api.service';
import { SimpleEnterpriseModel } from '../../api/models/EnterprisesModel';
import { RoomsModelData } from '../../api/models/RoomsModel';
import { GenericAlertComponent } from '../../components/generic-alert/generic-alert.component';
import { AppRoutes } from '../../const/AppRoutes';
import { RolesService } from '../../services/roles.service';
import { Settings } from '../../settings/settings';
import { ValidatorHelper } from '../../validators/ValidatorHelper';
import { GT2FormPageAbstract } from '../abstract/GT2FormPageAbstract';
import { locale as english } from '../rooms/i18n/en-CA';
import { locale as french } from '../rooms/i18n/fr-CA';
import { RoomsService } from '../rooms/rooms.service';
import { Title } from '@angular/platform-browser';
import { FuseSidebarService } from '../../../../@fuse/components/sidebar/sidebar.service';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-room',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.scss'],
  animations: fuseAnimations,
})
export class RoomComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy, ICanDeactivateComponent
{
  roomID!: string;
  room!: RoomsModelData;
  loading: boolean = false;
  editMode: boolean = false;
  editable: boolean = true;
  @ViewChild('noteEditor') noteEditor!: Editor;
  @ViewChild('roomsComponent')
  roomsComponent!: RoomsComponent;
  form!: UntypedFormGroup;
  isVisible: boolean = true;
  confirmDialogRef: any;
  noteEditordebounceTimeValue: number = 1500;
  noteEditorSubscription?: Subscription;
  noteInvalid: boolean = false;
  isSmallScreen!: boolean;

  backURL!: string;
  breadCrumbName!: string;
  richTextsValidator!: RichTextsValidator;
  subRoomFinalLayer: boolean = false;

  constructor(
    private roomsService: RoomsService,
    private formBuilder: UntypedFormBuilder,
    private translationLoader: FuseTranslationLoaderService,
    public dialog: MatDialog,
    public api: Gt2ApiService,
    private route: ActivatedRoute,
    public router: Router,
    public translate: TranslateService,
    public location: Location,
    private toastr: ToastrService,
    public print: PrintService,
    public fileService: FileService,
    private titleService: Title,
    public rolesService: RolesService,
    private _fuseSidebarService: FuseSidebarService,
    private logger: NGXLogger,
    public appRoutesService: AppRoutesService,
    private breakpointObserver: BreakpointObserver,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
  }

  ngOnInit() {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe({
      next: (result) => {
        this.isSmallScreen = result.matches;
      },
    });
    //this.titleService.setTitle(this.translate.instant("ROOMS.ROOM"));

    this.subRoomFinalLayer =
      this.route.snapshot.url[0].path === this.appRoutesService.sub_rooms2;
    //this.logger.info("ngOnInit() -> " + this.subRoomFinalLayer);
    this.route.params.subscribe({
      next: (params: any) => {
        this.roomID = params.id;
        this.loading = true;
        this.loadRoom();
      },
    });

    this.route.queryParams.subscribe({
      next: (params: any) => {
        this.breadCrumbName = params.loc;
      },
    });

    this.richTextsValidator = new RichTextsValidator();
  }

  public initRichText(): void {
    this.richTextsValidator?.registerRichText(this.noteEditor, 'noteEditor');
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    if (this.richTextsValidator) {
      this.richTextsValidator.destroy();
    }
  }

  public createForm(): void {
    if (this.room?.place) {
      this.form = this.formBuilder.group({
        name: [
          '',
          [
            Validators.minLength(2),
            Validators.maxLength(Settings.inputMaxCharMid),
            Validators.required,
          ],
        ],
        royalty_percent: [
          '',
          [ValidatorHelper.isPositive, ValidatorHelper.isNumber0To100],
        ],
        royalty_on_food: '',
        royalty_on_beverage: '',
        max_place: [
          '',
          [ValidatorHelper.isNumber0To10000, ValidatorHelper.isInteger],
        ],
      });
    } else {
      this.form = this.formBuilder.group({
        name: [
          '',
          [
            Validators.minLength(2),
            Validators.maxLength(Settings.inputMaxCharMid),
            Validators.required,
          ],
        ],
        max_place: [
          '',
          [ValidatorHelper.isNumber0To10000, ValidatorHelper.isInteger],
        ],
      });
    }
  }

  public canDeactivate(): boolean {
    return !this.form?.dirty && !this.richTextsValidator?.dirty();
  }

  public onCancelEdit(event: any): void {
    this.editMode = false;

    if (this.noteEditor) {
      this.noteEditor.readonly = !this.editMode;
    }

    this.form?.markAsPristine();
    this.form?.markAsUntouched();
    this.loadRoom();
  }

  public loadRoom(): void {
    this.loading = true;
    this.roomsService.getRoom(this.roomID).subscribe({
      next: (response) => {
        // //this.logger.info(
        //     "RoomComponent.loadRoom() -> SUCCESS: " +
        //         JSON.stringify(response)
        // );
        const isFirstLoad: boolean =
          this.room === null || this.room == undefined;
        this.room = response.data;
        this.createForm();
        this.loading = false;
        this.editable = !this.room?.isArchive;
        //this.titleService.setTitle(this.translate.instant("ROOMS.ROOM") + " " + this.room.name);

        if (isFirstLoad) {
          setTimeout(() => {
            this.initRichText();
          }, 500);
        }
      },
      error: (error) => {
        this.logger.error(
          'RoomComponent.loadRoom() -> ERROR: ' + JSON.stringify(error),
        );
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public deleteRoom(): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });
    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.roomsService.deleteRoom(this.room).subscribe({
            next: (response) => {
              // //this.logger.info("RoomComponent.deleteEnterprise() -> SUCCESS: " + JSON.stringify(response));
              this.toastr.success(
                '',
                this.translate.instant('GENERIC.DELETE_SUCCESS'),
              );

              if (this.room?.enterprise) {
                // this.router.navigateByUrl("/" + AppRoutes.ENTERPRISES + "/" + this.room.enterprise.slug + "/" + this.room.enterprise.uuid);
                this.router.navigateByUrl(
                  ItemUrlFinder.getItemURL(this.room.enterprise),
                );
              } else if (this.room?.place) {
                // this.router.navigateByUrl("/" + AppRoutes.VENUES + "/" + this.room.place.slug + "/" + this.room.place.uuid);
                this.router.navigateByUrl(
                  ItemUrlFinder.getItemURL(this.room.place),
                );
              } else {
                this.router.navigateByUrl('/' + AppRoutes.VENUES);
              }
            },
            error: (error) => {
              this.logger.error(
                'RoomComponent.deleteClient() -> ERROR: ' +
                  JSON.stringify(error),
              );
              // this.toastr.error('', this.translate.instant("GENERIC.ERRORS.GENERIC"));
              this.loading = false;
              this.handleAPIError(
                error,
                this.dialog,
                this.toastr,
                this.translate.instant('GENERIC.ERRORS.GENERIC'),
              );
            },
          });
        }
      },
    });
  }

  public onEdit($event: any): void {
    this.editMode = !this.editMode;

    if (this.editMode) {
      // this.setControlsChanges(this.form);
    } else {
      // this.unsetControlsChanges();
      // this.loadRoom();
      // this.form.markAsPristine();
      // this.form.markAsUntouched();
      this.loading = true;
      this.roomsService.updateRoom(this.room, this.roomID).subscribe({
        next: (response) => {
          // //this.logger.info("RoomComponent.autosaveDataChange() -> SUCCESS: " + JSON.stringify(response));
          this.loading = false;
          this.form?.markAsUntouched();
          this.form?.markAsPristine();
          this.room = response.data;
          // if (Settings.refreshFormData)
          // {
          //     this.loadRoom();
          // }
        },
        error: (error) => {
          this.logger.error(
            'RoomComponent.autosaveDataChange() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          this.handleAPIError(error, this.dialog, null, null);
        },
      });
    }
  }

  public override autosaveDataChange(data: any): void {
    // //this.logger.info("RoomComponent.autosaveDataChange() -> data: " + JSON.stringify(data));
    // this.loading = true;
    // this.roomsService.updateRoom(data, this.roomID).subscribe(
    //     (response) => {
    //         // //this.logger.info("RoomComponent.autosaveDataChange() -> SUCCESS: " + JSON.stringify(response));
    //         this.loading = false;
    //         this.form.markAsUntouched();
    //         this.form.markAsPristine();
    //         this.room = response.data;
    //         // if (Settings.refreshFormData)
    //         // {
    //         //     this.loadRoom();
    //         // }
    //     },
    //     (error) => {
    //         this.logger.error(
    //             "RoomComponent.autosaveDataChange() -> ERROR: " +
    //                 JSON.stringify(error)
    //         );
    //         this.loading = false;
    //         this.handleAPIError(error, this.dialog, null, null);
    //     }
    // );
  }

  public onEnterprise(enterprise: SimpleEnterpriseModel): void {
    // //this.logger.info("RoomComponent.onEnterprise() -> enterprise: " + JSON.stringify(enterprise));
    const url: string =
      '/' +
      AppRoutes.ENTERPRISES +
      '/' +
      enterprise.slug +
      '/' +
      enterprise.uuid;
    this.router.navigateByUrl(url);
  }

  public doPrint(url: string): void {
    this.print.printHTML(url).subscribe({
      next: (response: any) => {
        // //this.logger.info("RoomComponent.doPrint() -> SUCCESS: " + JSON.stringify(response));
      },
      error: (error: any) => {
        this.logger.error(
          'RoomComponent.doPrint() -> ERROR: ' + JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public doDownload(url: string): void {
    this.fileService.downloadFile(url).subscribe({
      next: (response: any) => {
        // //this.logger.info("RoomComponent.doDownload() -> SUCCESS: " + JSON.stringify(response));
      },
      error: (error: any) => {
        this.logger.error(
          'RoomComponent.doDownload() -> ERROR: ' + JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public onPrint(): void {
    const url: string =
      this.api.createUrl('/reports/rooms/html/') + this.room?.uuid;
    // this.print.printHTML(url);
    this.doPrint(url);
  }

  public onOpenInNewTab(): void {
    window.open(this.router.url, '_blank');
  }

  public onDownloadPDF(): void {
    const url: string =
      this.api.createUrl('/reports/rooms/pdf/') + this.room?.uuid;
    // const fileName: string = this.fileService.createFileName(null, "rooms", "pdf");
    // this.fileService.downloadFile(url, fileName);
    this.doDownload(url);
  }

  public onDownloadVCard(): void {
    // //this.logger.info("onDownloadVCard() ");
    // const url: string = this.api.createUrl('/reports/rooms/vcard/') + this.room.uuid; // environment.api.protocol + environment.api.domain + '/rooms/vcard/' + this.roomID;
    // window.location.href = url;
  }

  public onRoyaltyBeverageChange(event: any): void {
    // //this.logger.info("RoomComponent.onRoyaltyBeverageChange() -> royalty_on_beverage: " + this.room.royalty_on_beverage);
    this.autosaveDataChange({
      royalty_on_beverage: this.room?.royalty_on_beverage,
    });
  }

  public onRoyaltyFoodChange(event: any): void {
    // //this.logger.info("RoomComponent.onRoyaltyFoodChange() -> royalty_on_food: " + this.room.royalty_on_food);
    this.autosaveDataChange({ royalty_on_food: this.room?.royalty_on_food });
  }

  public onRoyaltyMaterialChange(event: any): void {
    // //this.logger.info("RoomComponent.onRoyaltyMaterialChange() -> royalty_on_material: " + this.room.royalty_on_material);
    this.autosaveDataChange({
      royalty_on_material: this.room?.royalty_on_material,
    });
  }

  public onRoyaltyServiceChange(event: any): void {
    // //this.logger.info("RoomComponent.onRoyaltyServiceChange() -> royalty_on_service: " + this.room.royalty_on_service);
    this.autosaveDataChange({
      royalty_on_service: this.room?.royalty_on_service,
    });
  }

  public toggleHistoricSidebarOpen(key: string): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  public onRoomModelUpdated(room: RoomsModelData): void {
    //this.logger.info("onRoomModelUpdated() " + this.roomsComponent);
    this.room = room;
    if (this.roomsComponent) {
      this.roomsComponent.bindDataSource(room.sub_rooms);
    }
    // this.loadRoom();
    // setTimeout(() => {
    //     this.loadRoom();
    // }, 900);
  }

  public onGoToPlace(): void {
    this.router.navigateByUrl(
      '/' +
        AppRoutes.VENUES +
        '/' +
        this.room?.place.slug +
        '/' +
        this.room?.place.uuid,
    );
  }
}
