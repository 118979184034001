import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { OrdersService } from '../../../api/orders.service';
import { ProductsService } from '../../../api/products.service';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { GT2PageAbstract } from '../../abstract/GT2PageAbstract';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../../@fuse/pipes/pipes.module';
import { Gt2PrimengModule } from '../../../modules/gt2-primeng.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ChoiceComboSelectComponent } from './choice-combo-select/choice-combo-select.component';

@Component({
  selector: 'app-choice-combo-select-dialog',
  templateUrl: './choice-combo-select-dialog.component.html',
  styleUrls: ['./choice-combo-select-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    ChoiceComboSelectComponent,
    FusePipesModule,
    Gt2PrimengModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatButtonModule,
    TranslateModule,
    MatProgressSpinnerModule,
  ],
})
export class ChoiceComboSelectDialogComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  choicesProduct?: any[];
  choices: any = {};
  forProduct: string = '';
  forProductUUID: string = '';

  constructor(
    public override dialogRef: MatDialogRef<ChoiceComboSelectDialogComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    public productsService: ProductsService,
    public ordersService: OrdersService,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private logger: NGXLogger,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super();
    //
    if (data && data.choicesProduct) {
      this.choicesProduct = data.choicesProduct;
    }
    if (data && data.forProduct) {
      this.forProduct = data.forProduct;
    }
    if (data && data.forProductUUID) {
      this.forProductUUID = data.forProductUUID;
    }
  }

  ngOnInit() {
    //this.logger.info("ChoiceComboSelectDialogComponent.ngOnInit() " + JSON.stringify(this.choicesProduct));
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  public onSaveChoices(): void {
    //this.logger.info("ChoiceComboSelectDialogComponent.onSaveChoices() choicesProduct: " + JSON.stringify(this.choicesProduct));
    //this.logger.info("ChoiceComboSelectDialogComponent.onSaveChoices() choices: " + JSON.stringify(this.choices));

    //  this.dialogRef.close(this.choices);
    this.productsService
      .createChoiceComboFromChoice(this.choices, this.forProductUUID)
      .subscribe({
        next: (response) => {
          //this.logger.info("ChoiceComboSelectDialogComponent.onSaveChoices() -> SUCCESS: " + JSON.stringify(response));
          this.dialogRef.close(response);
        },
        error: (error) => {
          this.logger.error(
            'ChoiceComboSelectDialogComponent.onSaveChoices() -> ERROR: ' +
              JSON.stringify(error),
          );
          // this.loading = false;
          this.handleAPIError(error, this.dialog, null, null);
        },
      });
  }

  public onChoiceSelection(event: any, product: any): void {
    //this.logger.info("ChoiceComboSelectDialogComponent.onChoiceSelection() " + JSON.stringify(event));
    this.choices[product.uuid] = event;
  }
}
