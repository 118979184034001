import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppRoutes } from '../../const/AppRoutes';
import { GT2FormPageAbstract } from '../../content/abstract/GT2FormPageAbstract';
import { CreateCongresDialogComponent } from '../../content/congresses/create-congres-dialog/create-congres-dialog.component';
import { RolesService } from '../../services/roles.service';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { NgPipesModule } from 'ngx-pipes';
import { MatIconModule } from '@angular/material/icon';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { NgxPermissionsModule } from 'ngx-permissions';

@Component({
  selector: 'app-create-congresses-button',
  templateUrl: './create-congresses-button.component.html',
  styleUrls: ['./create-congresses-button.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    Gt2PrimengModule,
    NgxPermissionsModule,
    MatBadgeModule,
    MatButtonModule,
    MatInputModule,
    NgPipesModule,
    TranslateModule,
    MatIconModule,
  ],
})
export class CreateCongressesButtonComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() date?: Date;
  //
  declare dialogRef: any;
  declare errorDialogRef: any;

  constructor(
    public translate: TranslateService,
    public router: Router,
    public dialog: MatDialog,
    public rolesService: RolesService,
  ) {
    super();
  }

  ngOnInit() {}

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  public onCreateNewCongress(event: any): void {
    this.dialogRef = this.dialog.open(CreateCongresDialogComponent, {
      width: '440px',
      maxHeight: '96vh',
      minWidth: 350,
      disableClose: false,
      panelClass: 'scrollable-dialog',
      data: { autoRedirect: true },
    });
    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          // this.reloadTable();
        }
        this.dialogRef = null;
      },
    });
  }

  public onGoToCongresses(): void {
    this.router.navigateByUrl('/' + AppRoutes.CONGRESSES);
  }
}
