import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GT2FormPageAbstract } from '../abstract/GT2FormPageAbstract';
import { KnowledgeBaseArticleComponent } from './dialogs/article/article.component';
import { KnowledgeBaseService } from './knowledge-base.service';

@Component({
  selector: 'app-knowledge-base',
  templateUrl: './knowledge-base.component.html',
  styleUrls: ['./knowledge-base.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class KnowledgeBaseComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  knowledgeBase: any;
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _matDialog: MatDialog,
    private logger: NGXLogger,
    private _knowledgeBaseService: KnowledgeBaseService,
  ) {
    super();
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this._knowledgeBaseService.onKnowledgeBaseChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (response) => {
          // this.logger.info("KnowledgeBaseComponent.ngOnInit() -> SUCCESS: " + JSON.stringify(response));
          this.knowledgeBase = response.data;
        },
      });
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    this._unsubscribeAll.next(undefined);
    this._unsubscribeAll.complete();
  }

  readArticle(article: any): void {
    this._matDialog.open(KnowledgeBaseArticleComponent, {
      panelClass: 'knowledgebase-article-dialog',
      data: { article: article },
    });
  }
}
