import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { RouterModule } from '@angular/router';
import { Gt2PrimengModule } from './../../modules/gt2-primeng.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardDetailedComponent } from './dashboard-detailed.component';
import { AppRoutesName } from '../../const/AppRoutesName';
import { MatIconModule } from '@angular/material/icon';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { CreateCongressesButtonComponent } from '../../components/create-congresses-button/create-congresses-button.component';
import { CreateOrderButtonsComponent } from '../../components/create-order-buttons/create-order-buttons.component';
import { GenericAlertComponent } from '../../components/generic-alert/generic-alert.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { MatTableModule } from '@angular/material/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DropdownModule } from 'primeng/dropdown';
import { SliderModule } from 'primeng/slider';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MatBadgeModule } from '@angular/material/badge';
import { EditorModule } from 'primeng/editor';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CdkTableModule } from '@angular/cdk/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ColorPickerModule } from 'ngx-color-picker';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { ClipboardModule } from 'ngx-clipboard';
import { MatRippleModule } from '@angular/material/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NumberNotInfinityPipeModule } from '../../modules/number-not-infinity.module';
import { MomentFormatModule } from '../../modules/moment-format.module';
import { NumericDirectiveModule } from '../../modules/numeric.module';

const routes = [
  {
    path: AppRoutesName.EN.DASHBOARD_DETAILED,
    component: DashboardDetailedComponent,
  },
  {
    path: AppRoutesName.FR.DASHBOARD_DETAILED,
    component: DashboardDetailedComponent,
  },
];

@NgModule({
  declarations: [DashboardDetailedComponent],
  imports: [
    MatIconModule,
    NgxSpinnerModule,
    MatInputModule,
    MatMenuModule,
    MatTooltipModule,
    SliderModule,
    ContextMenuModule,
    NgxPermissionsModule,
    MultiSelectModule,
    MatButtonModule,
    TranslateModule,
    MatSortModule,
    DropdownModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    GenericAlertComponent,
    CommonModule,
    InputTextModule,
    TableModule,
    MatTableModule,
    FormsModule,
    NgxDaterangepickerMd,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    Gt2PrimengModule,
    RouterModule.forChild(routes),
    NgxMaterialTimepickerModule,
    CreateCongressesButtonComponent,
    CreateOrderButtonsComponent,

    MatBadgeModule,
    NumberNotInfinityPipeModule,
    MomentFormatModule,
    TranslatePipe,
    EditorModule,
    NumericDirectiveModule,
    MatStepperModule,
    MatFormFieldModule,
    MatSelectModule,
    MatRadioModule,
    MatListModule,
    MatCardModule,
    MatPaginatorModule,
    CdkTableModule,
    MatTabsModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatToolbarModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    ColorPickerModule,
    MatButtonToggleModule,
    MatDividerModule,
    ClipboardModule,
    MatRippleModule,
    ScrollingModule,
  ],
})
export class DashboardDetailedModule {}
