<div style="max-height: 100%; overflow: auto">
  <div [formGroup]="form" fxLayout="column">
    <div matDialogTitle class="dialog-title mb-12">
      {{ title }}
    </div>

    <!-- <div class="item-full-width">
      <mat-form-field class="form-input-container" fxFlex="100%">
        <mat-select
          formControlName="project"
          placeholder="{{ 'GENERIC.PROJECT' | translate }}"
          [(ngModel)]="selectedProject"
          (selectionChange)="changeSelectedProject($event.value)"
        >
          <mat-option *ngFor="let project of projects" [value]="project">
            <span class="iso">{{ project.name }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div> -->
    <div
      mat-dialog-actions
      class="mt-16 gt2-buttons-theme"
      fxFlex
      fxLayout="row"
      fxLayoutAlign="center start"
    >
      <button
        mat-raised-button
        color="accent"
        class="mr-16"
        [disabled]="!selectedProject"
        (click)="dialogRef.close(selectedProject)"
      >
        {{ "GENERIC.SAVE" | translate }}
      </button>
      <button
        mat-raised-button
        color="primary"
        (click)="dialogRef.close(false)"
        class=""
      >
        {{ "GENERIC.CANCEL" | translate }}
      </button>
    </div>
  </div>
</div>
