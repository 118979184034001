import { MonerisModel } from './../shop-web-detailed.component';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormGroup,
} from '@angular/forms';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-moneris-settings',
  templateUrl: './moneris-settings.component.html',
  styleUrls: ['./moneris-settings.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    MatFormFieldModule,
    MatIconModule,
    MatTooltipModule,
    FlexLayoutModule,
    MatButtonModule,
    MatSlideToggleModule,
    CommonModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class MonerisSettingsComponent implements OnInit {
  @Input() shop: any;
  @Input() editMode: boolean = false;
  @Input() monerisModel!: MonerisModel;
  @Input() formMoneris!: UntypedFormGroup;
  @Output() addMonerisProfile: EventEmitter<any> = new EventEmitter();
  @Output() removeMonerisProfile: EventEmitter<any> = new EventEmitter();
  @Output() saveMonerisInfo: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  public onAddMonerisProfile(): void {
    this.addMonerisProfile.emit();
  }

  public onRemoveMonerisProfile(item: any, i: any): void {
    this.removeMonerisProfile.emit({ item: item, position: i });
  }

  public onSaveMonerisInfo(): void {
    this.saveMonerisInfo.emit();
  }
}
