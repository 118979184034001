<div
  class="page-layout simple left-sidebar inner-sidebar inner-scroll"
  id="product"
>
  <div class="gt2-theme">
    <div
      class="header accent pl-24 pr-24 h-80 mat-elevation-z1"
      fusePerfectScrollbar
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div fxLayout="row" fxLayoutAlign="start center">
        <button
          (click)="onPageBack(location, router, '/products')"
          class="mr-0 mr-sm-16 white-fg"
          mat-icon-button
        >
          <mat-icon class="icon-mat">arrow_back</mat-icon>
        </button>
        <div fxLayout="column" fxLayoutAlign="center start">
          <div class="user-info" fxLayout="row" fxLayoutAlign="start start">
            <div
              *fuseIfOnDom
              [@animate]="{
                value: '*',
                params: { delay: '100ms', x: '-25px' },
              }"
              class="product-title"
            >
              <!--                        TODO: WHY DO WE HAVE A TOOLTIP HERE -->
              <!--                        <div *ngIf="product" class="" matTooltip="{{product.name}}" [matTooltipPosition]="'right'">-->
              <div *ngIf="product" class="">
                <span class="text-truncate"
                  >{{ "PRODUCTS.PRODUCT" | translate }} -
                  {{ product.name_internal }}</span
                >
              </div>
            </div>
          </div>

          <div
            *ngIf="product"
            class=""
            fxLayout="row"
            fxLayoutAlign="start start"
          >
            <div *ngIf="product.is_combo">
              {{ "PRODUCTS.COMBO" | translate | uppercase }}
            </div>
            <div *ngIf="!product.is_combo">
              {{ "PRODUCTS.SINGLE_PRODUCT" | translate | uppercase }}
            </div>

            <!-- <div fxLayout="row" fxLayoutAlign="start center">&nbsp; &nbsp;| &nbsp;{{ 'PRODUCTS.CONFIRMED' |
                        translate }}: &nbsp;
                        <mat-icon *ngIf="product && product.confirmed" class="s-16 green">check
                        </mat-icon>
                        <mat-icon *ngIf="product && !product.confirmed" class="s-16 red">clear
                        </mat-icon>
                    </div> -->

            <div
              *ngIf="product && product.confirmed"
              fxLayout="row"
              fxLayoutAlign="start center"
            >
              &nbsp; &nbsp;| &nbsp;{{ "PRODUCTS.CONFIRMED" | translate }}
            </div>
            <div
              *ngIf="product && !product.confirmed"
              fxLayout="row"
              fxLayoutAlign="start center"
            >
              &nbsp; &nbsp;| &nbsp;{{ "PRODUCTS.NOT_CONFIRMED" | translate }}
            </div>
            <div
              (click)="onOpenObjectPage(product.order)"
              *ngIf="product && product.ordered && product.order"
              class="text-button-cursor"
              fxLayout="row"
              fxLayoutAlign="start center"
            >
              &nbsp; &nbsp;| &nbsp;{{ "GENERIC.ORDER" | translate }}:
              {{ product.order.name }} -
              {{ product.order.order_number }}
            </div>
            <div
              (click)="onOpenObjectPage(product.order)"
              *ngIf="product && product.ordered && !product.order"
              class="text-button-cursor"
              fxLayout="row"
              fxLayoutAlign="start center"
            >
              &nbsp; &nbsp;| &nbsp;{{ "PRODUCTS.ORDERED_PRODUCT" | translate }}
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="end center">
        <!-- <button mat-button
                        *ngIf="fileService.pdfEnabled()"
                        matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                        (click)="onDownloadPDF()" class="mat-icon-button">
                    <mat-icon class="s-30">picture_as_pdf</mat-icon>
                </button>

                <mat-spinner *ngIf="print.loadingPrint" [diameter]="20" class="mt-12"></mat-spinner>
                <button mat-button
                        *ngIf="fileService.printEnabled()"
                        matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                        (click)="onPrint()" class="mat-icon-button">
                    <mat-icon class="s-30">print</mat-icon>
                </button> -->

        <button
          (click)="onOpenInNewTab()"
          class="mat-icon-button"
          mat-button
          matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
        >
          <mat-icon class="icon-mat s-30">{{ getIcon("NEW_TAB") }}</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <!-- / HEADER -->

  <!-- SUB HEADER -->
  <div
    *ngIf="product"
    class="primary mat-elevation-z1 py-8 gt2-sub-header content-subheader"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fusePerfectScrollbar
  >
    <div fxLayout="row" fxLayoutAlign="start center">
      <!--<button mat-button (click)="onUpsale()" class="">
                <mat-icon class="s-20">{{ getIcon('EMPLOYEE') }}</mat-icon> 
                <mat-icon fontSet="fa" fontIcon="{{ getIcon('ASSIGNMENT_USER') }}" class="fa-lg fa-icon-btn"></mat-icon>
                {{ 'GENERIC.PRODUCT_UPSALE' | translate}}
            </button>-->

      <button
        (click)="onNavigateToOrder($event)"
        *ngIf="product.order"
        class="gt2-sub-header-button"
        mat-button
      >
        <mat-icon
          *ngIf="product.order.type === 'ORDER'"
          [matTooltipPosition]="'right'"
          class="icon-mat s-24"
          matTooltip="{{ 'GENERIC.ORDER' | translate }}"
          >{{ getIcon("ORDER") }}</mat-icon
        >
        <mat-icon
          *ngIf="product.order.type === 'PROPOSAL'"
          [matTooltipPosition]="'right'"
          class="icon-mat s-24"
          matTooltip="{{ 'GENERIC.PROPOSAL' | translate }}"
          >{{ getIcon("PROPOSAL") }}</mat-icon
        >
        <mat-icon
          *ngIf="product.order.type === 'INVOICE'"
          [matTooltipPosition]="'right'"
          class="icon-mat s-24"
          matTooltip="{{ 'GENERIC.INVOICE' | translate }}"
          >{{ getIcon("INVOICE") }}</mat-icon
        >
        <span class="ml-4 mr-8 icon-mat">
          {{ "PRODUCTS.SEE_" + product.order.type | translate }}
        </span>
      </button>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center">
      <button
        (click)="onCloneProduct($event)"
        *ngIf="!product.childs && !product.ordered"
        class="gt2-sub-header-button"
        mat-button
      >
        <mat-icon class="icon-mat s-24">{{ getIcon("CLONE") }}</mat-icon>
        <span class="ml-4 mr-8 icon-mat">{{
          "PRODUCTS.ClONE_PRODUCT" | translate
        }}</span>
      </button>

      <button
        (click)="onCloneCombo($event)"
        *ngIf="product.childs && !product.ordered"
        class="gt2-sub-header-button"
        mat-button
      >
        <mat-icon class="icon-mat s-24">{{ getIcon("CLONE") }}</mat-icon>
        <span class="ml-4 mr-8 icon-mat">{{
          "PRODUCTS.ClONE_COMBO" | translate
        }}</span>
      </button>

      <button
        (click)="onCombo($event)"
        *ngIf="!product.childs && !product.ordered && editable"
        class="gt2-sub-header-button"
        mat-button
      >
        <mat-icon
          class="icon-mat fa-2x"
          fontIcon="{{ getIcon('COMBO') }}"
          fontSet="fa"
        ></mat-icon>
        <span class="ml-4 mr-8 icon-mat">{{
          "PRODUCTS.CREATE_COMBO" | translate
        }}</span>
      </button>
    </div>
  </div>
  <!-- / SUB HEADER -->

  <div
    *ngIf="product && !editable"
    class="gt2-gray mat-elevation-z1 py-8"
    fxLayout="row"
    fxLayoutAlign="center center"
    style="height: 38px; min-height: 38px; max-height: 38px"
  >
    <div class="text-white font-weight-600">
      <b>{{ "GENERIC.READ_ONLY_MODE" | translate }}</b> :
      {{ "GENERIC.ARCHIVE_PAGE" | translate }}
    </div>
  </div>

  <!--HISTORY SIDEBAR-->
  <div *ngIf="!isSmallScreen" class="container-history-panel">
    <fuse-sidebar
      class="section-history-panel"
      name="productHistoryPanel"
      position="right"
    >
      <app-section-history-panel
        panelName="productHistoryPanel"
        section="products"
        sectionUUID="{{ productID }}"
      >
      </app-section-history-panel>
    </fuse-sidebar>
  </div>

  <div class="content">
    <!--HISTORY SIDEBAR BUTTON-->
    <div *ngIf="!isSmallScreen">
      <button
        (click)="toggleHistoricSidebarOpen('productHistoryPanel')"
        *fuseIfOnDom
        [@animate]="{
          value: '*',
          params: { delay: '300ms', scale: '.2' },
        }"
        class="warn mat-elevation-z2 historic-button"
        mat-icon-button
      >
        <mat-icon class="icon-mat">history</mat-icon>
      </button>
    </div>

    <!-- CENTER -->
    <div #mainContainer class="center p-24 pb-56 pr-sm-72" fusePerfectScrollbar>
      <!-- CONTENT -->
      <div class="product-content">
        <!-- <div style="width: 40%; height: 520px;">
                    <app-fullcalendar-picker></app-fullcalendar-picker>
                </div> -->

        <div *ngIf="product" fxLayout="row" fxLayoutAlign="start end">
          <app-tags-by-model
            #tags
            [customTagFamily]="'tags-product-custom'"
            [disabled]="!editable"
            [tagFamily]="[
              'tags-product-moment',
              'tags-product-season',
              'tags-product-feature',
              'tags-product-custom',
            ]"
            [tagModelUUID]="productID"
            [tagModel]="'product'"
            [tagPlaceholder]="'GENERIC.NEW_TAG' | translate"
            class="ml-4"
            fxFlex="100%"
          ></app-tags-by-model>
        </div>

        <div
          *ngIf="product"
          class="profile-box info-box general"
          fxLayout="column"
        >
          <header class="accent">
            <div class="title">
              {{ "GENERIC.INFORMATION" | translate }}
            </div>
          </header>

          <mat-tab-group
            animationDuration="100ms"
            mat-stretch-tabs="false"
            mat-align-tabs="start"
            dynamicHeight="true"
            (selectedIndexChange)="onGeneralTabChange($event)"
          >
            <mat-tab label="{{ 'GENERIC.GENERAL' | translate }}">
              <div class="content" fxLayout="column">
                <!-- PRODUCT INTERNAL NAME FR -->
                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="start start"
                >
                  <!--  VIEW MODE -->
                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "GENERIC.INTERNAL_NAME" | translate }}
                    </div>
                    <div class="info text-truncate">
                      <app-copy-wrapper>{{
                        product.name_internal_local.name_internal_fr_CA
                      }}</app-copy-wrapper>
                    </div>
                    <div
                      *ngIf="!product.name_internal_local.name_internal_fr_CA"
                      class="info empty-value"
                    ></div>
                  </div>
                  <!--  EDIT MODE -->
                  <div
                    *ngIf="editMode"
                    class="info-line"
                    fxFlex="50%"
                    style="margin-right: 0 !important"
                  >
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field
                          class="form-input-container"
                          fxFlex="100%"
                        >
                          <mat-label>{{
                            "GENERIC.INTERNAL_NAME" | translate
                          }}</mat-label>
                          <input
                            [(ngModel)]="
                              product.name_internal_local.name_internal_fr_CA
                            "
                            autocomplete="nope"
                            formControlName="name_internal_fr_CA"
                            matInput
                            required
                            type="text"
                          />
                          <mat-error
                            *ngIf="form.controls['name_internal_fr_CA'].invalid"
                          >
                            {{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <button
                    *ngIf="editMode"
                    class=""
                    mat-icon-button
                    (click)="
                      product.name_local.name_fr_CA =
                        form.controls['name_internal_fr_CA'].value
                    "
                    class="mt-4"
                    matTooltip="{{
                      'GENERIC.COPY_INTERNAL_NAME_TO_EXTERNAL' | translate
                    }}"
                  >
                    <mat-icon class="icon-mat s-20">redo</mat-icon>
                  </button>

                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "GENERIC.EXTERNAL_NAME" | translate }}
                    </div>
                    <div class="info text-truncate">
                      <app-copy-wrapper>{{
                        product.name_local.name_fr_CA
                      }}</app-copy-wrapper>
                    </div>
                    <div
                      *ngIf="
                        !product.name_local || !product.name_local.name_fr_CA
                      "
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div
                      class="info-editable"
                      fxLayout="row"
                      fxLayoutAlign="start center"
                      fxFlex="100%"
                    >
                      <div
                        [formGroup]="form"
                        class=""
                        fxFlex="100%"
                        fxLayout="row"
                      >
                        <mat-form-field
                          class="form-input-container"
                          fxFlex="100%"
                        >
                          <mat-label>{{
                            "GENERIC.EXTERNAL_NAME" | translate
                          }}</mat-label>
                          <input
                            [(ngModel)]="product.name_local.name_fr_CA"
                            autocomplete="nope"
                            formControlName="name_fr_CA"
                            matInput
                            required
                            type="text"
                          />
                          <!-- 
                                                        repeat
                                                        redo
                                                        sync_alt 
                                                        content_copy
                                                        -->

                          <mat-error *ngIf="form.controls['name_fr_CA'].invalid"
                            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="mb-8"
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="start start"
                >
                  <div class="info-line" fxFlex="100%" [formGroup]="form">
                    <div class="title">
                      {{ "PRODUCTS.DESCRIPTION" | translate }}
                    </div>
                    <p-editor
                      #descriptionFrEditor
                      formControlName="description_fr_CA"
                      [(ngModel)]="product.description_local.description_fr_CA"
                      [readonly]="!editMode"
                      [style]="{
                        height: '120px',
                        width: '100%',
                        'background-color': editMode ? '#ffffff' : '#EDEDED',
                      }"
                    >
                      <ng-template pTemplate="header" class="">
                        <span class="ql-formats">
                          <button class="ql-bold"></button>
                          <button class="ql-italic"></button>
                          <button class="ql-underline"></button>
                          <button class="ql-strike"></button>
                        </span>
                        <span class="ql-formats">
                          <button class="ql-list" value="ordered"></button>
                          <button class="ql-list" value="bullet"></button>
                          <button class="ql-indent" value="-1"></button>
                          <button class="ql-indent" value="+1"></button>
                        </span>
                      </ng-template>
                    </p-editor>

                    <mat-error
                      *ngIf="form.controls['description_fr_CA'].invalid"
                      class="p-4 pr-8"
                      fxLayout="row"
                      fxLayoutAlign="end center"
                    >
                      {{
                        "GENERIC.NOTE_EDITOR_LENGTH_INVALID_2048" | translate
                      }}
                    </mat-error>
                  </div>
                </div>

                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="start start"
                >
                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "PRODUCTS.PRICE" | translate }}
                    </div>
                    <div class="info text-truncate">
                      <app-copy-wrapper>{{
                        product.price_label
                      }}</app-copy-wrapper>
                    </div>
                    <div
                      *ngIf="!product.price_label"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field
                          class="form-input-container"
                          fxFlex="100%"
                        >
                          <mat-label>{{
                            "PRODUCTS.PRICE" | translate
                          }}</mat-label>
                          <input
                            [(ngModel)]="product.price"
                            autocomplete="nope"
                            formControlName="price"
                            matInput
                            pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
                            required
                            type="text"
                          />
                          <mat-error *ngIf="form.controls['price'].invalid"
                            >{{ "GENERIC.INPUT_NUMBER_0_999999" | translate }}
                            {{
                              "GENERIC.INPUT_NUMBER_POSTFIX_2_DECIMAL"
                                | translate
                            }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "PRODUCTS.COST" | translate }}
                    </div>
                    <div *ngIf="product.cost" class="info">
                      <app-copy-wrapper>{{
                        product.cost_label
                      }}</app-copy-wrapper>
                    </div>
                    <div *ngIf="!product.cost" class="info empty-value"></div>
                  </div>
                  <div *ngIf="editMode" class="info-line mb-4" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field
                          class="form-input-container mr-4"
                          fxFlex="100%"
                        >
                          <mat-label>{{
                            "PRODUCTS.COST" | translate
                          }}</mat-label>
                          <input
                            [(ngModel)]="product.cost"
                            autocomplete="nope"
                            formControlName="cost"
                            matInput
                            pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
                            type="text"
                          />
                          <mat-error *ngIf="form.controls['cost'].invalid"
                            >{{ "GENERIC.INPUT_NUMBER_0_999999" | translate }}
                            {{
                              "GENERIC.INPUT_NUMBER_POSTFIX_2_DECIMAL"
                                | translate
                            }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="4"
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="start start"
                >
                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "PRODUCTS.PRODUCT_NUMBER" | translate }}
                    </div>
                    <div class="info text-truncate">
                      <app-copy-wrapper>{{
                        product.product_number
                      }}</app-copy-wrapper>
                    </div>
                    <div
                      *ngIf="!product.product_number"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line mb-4" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field
                          class="form-input-container mr-4"
                          fxFlex="100%"
                        >
                          <mat-label>{{
                            "PRODUCTS.PRODUCT_NUMBER" | translate
                          }}</mat-label>
                          <input
                            [(ngModel)]="product.product_number"
                            autocomplete="nope"
                            formControlName="product_number"
                            matInput
                            type="text"
                            required
                          />
                          <mat-error
                            *ngIf="form.controls['product_number'].invalid"
                            >{{ "GENERIC.INPUT_LENGTH_INVALID_1" | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "PRODUCTS.SKU" | translate }}
                    </div>
                    <div class="info text-truncate">
                      <app-copy-wrapper>{{ product.sku }}</app-copy-wrapper>
                    </div>
                    <div *ngIf="!product.sku" class="info empty-value"></div>
                  </div>
                  <div *ngIf="editMode" class="info-line mb-4" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field
                          class="form-input-container mr-4"
                          fxFlex="100%"
                        >
                          <mat-label>{{
                            "PRODUCTS.SKU" | translate
                          }}</mat-label>
                          <input
                            [(ngModel)]="product.sku"
                            autocomplete="nope"
                            formControlName="sku"
                            matInput
                            type="text"
                          />
                          <mat-error *ngIf="form.controls['sku'].invalid"
                            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="4"
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="start start"
                >
                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "PRODUCTS.PRODUCTION_DELAY" | translate }}
                    </div>
                    <div
                      *ngIf="
                        product &&
                        (product.production_delay ||
                          product.production_delay === 0)
                      "
                      class="info text-truncate"
                    >
                      <app-copy-wrapper
                        >{{ product.production_delay }}
                        {{
                          "PRODUCTS.PRODUCTION_DELAY_DAYS" | translate
                        }}</app-copy-wrapper
                      >
                    </div>
                    <div
                      *ngIf="
                        !product ||
                        (!product.production_delay &&
                          product.production_delay !== 0)
                      "
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line mb-4" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field
                          class="form-input-container mr-4"
                          fxFlex="100%"
                        >
                          <mat-label>{{
                            "PRODUCTS.PRODUCTION_DELAY" | translate
                          }}</mat-label>
                          <input
                            [(ngModel)]="product.production_delay"
                            autocomplete="nope"
                            formControlName="production_delay"
                            matInput
                            pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
                            type="text"
                          />
                          <mat-error
                            *ngIf="form.controls['production_delay'].invalid"
                          >
                            {{ "GENERIC.INPUT_NUMBER_0_9999" | translate }}
                            {{
                              "GENERIC.INPUT_NUMBER_POSTFIX_2_DECIMAL"
                                | translate
                            }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "GENERIC.CONFIRMED" | translate }}
                    </div>
                    <div
                      *ngIf="product.confirmed"
                      class="info text-truncate"
                      fxLayoutAlign="start center"
                    >
                      {{ "GENERIC.YES" | translate }}
                      <button
                        matTooltip="{{
                          'GENERIC.CONFORMED_INFO_DESCRIPTION' | translate
                        }}"
                        class=""
                        mat-icon-button
                        type="button"
                      >
                        <mat-icon class="icon-mat s-20" color="">help</mat-icon>
                      </button>
                    </div>
                    <div
                      *ngIf="!product.confirmed"
                      class="info text-truncate"
                      fxLayoutAlign="start center"
                    >
                      {{ "GENERIC.NO" | translate }}
                      <button
                        matTooltip="{{
                          'GENERIC.CONFORMED_INFO_DESCRIPTION' | translate
                        }}"
                        class=""
                        mat-icon-button
                        type="button"
                      >
                        <mat-icon class="icon-mat s-20" color="">help</mat-icon>
                      </button>
                    </div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="100%">
                    <div
                      *ngxPermissionsOnly="rolesService.ADMIN_ROLES"
                      class="info-editable"
                    >
                      <div [formGroup]="form" class="">
                        <mat-form-field
                          class="form-input-container"
                          fxFlex="100%"
                        >
                          <mat-label>{{
                            "GENERIC.CONFIRMED" | translate
                          }}</mat-label>
                          <mat-select
                            [(ngModel)]="product.confirmed"
                            formControlName="confirmed"
                          >
                            <mat-option [value]="1">
                              <span class="iso">{{
                                "GENERIC.YES" | translate
                              }}</span>
                            </mat-option>
                            <mat-option [value]="0">
                              <span class="iso">{{
                                "GENERIC.NO" | translate
                              }}</span>
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>

                    <div
                      *ngxPermissionsExcept="['ADMIN', 'GOD', 'OWNER']"
                      class="info-editable"
                    >
                      <div class="title">
                        {{ "GENERIC.CONFIRMED" | translate }}
                      </div>
                      <div *ngIf="product.confirmed" class="info">
                        {{ "GENERIC.YES" | translate }}
                      </div>
                      <div *ngIf="!product.confirmed" class="info">
                        {{ "GENERIC.NO" | translate }}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="start start"
                >
                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "PRODUCTS.SERVING_COUNT" | translate }}
                    </div>
                    <div class="info">
                      <app-copy-wrapper>{{
                        product.serving_count
                      }}</app-copy-wrapper>
                    </div>
                    <div
                      *ngIf="
                        !product.serving_count && product.serving_count !== 0
                      "
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field
                          class="form-input-container"
                          fxFlex="100%"
                        >
                          <!-- <input matInput placeholder="{{ 'PRODUCTS.SERVING_COUNT' | translate }}"
                                                        formControlName="serving_count" 
                                                        pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
                                                        type="text" 
                                                        oninput="validity.valid||(value='');" 
                                                        [(ngModel)]="product.serving_count"> -->

                          <mat-label>{{
                            "PRODUCTS.SERVING_COUNT" | translate
                          }}</mat-label>
                          <input
                            [(ngModel)]="product.serving_count"
                            autocomplete="nope"
                            formControlName="serving_count"
                            matInput
                            pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
                            type="text"
                            required
                          />
                          <mat-error
                            *ngIf="form.controls['serving_count'].invalid"
                            >{{ "GENERIC.INPUT_NUMBER_0_999999" | translate }}
                            {{
                              "GENERIC.INPUT_NUMBER_POSTFIX_2_DECIMAL"
                                | translate
                            }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "PRODUCTS.MIN_QUANTITY_ORDER" | translate }}
                    </div>
                    <div class="info">
                      <app-copy-wrapper>{{
                        product.min_qty_order
                      }}</app-copy-wrapper>
                    </div>
                    <div
                      *ngIf="!product.min_qty_order"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field
                          class="form-input-container mr-4"
                          fxFlex="100%"
                        >
                          <mat-label>{{
                            "PRODUCTS.MIN_QUANTITY_ORDER" | translate
                          }}</mat-label>
                          <input
                            [(ngModel)]="product.min_qty_order"
                            autocomplete="nope"
                            formControlName="min_qty_order"
                            matInput
                            min="0"
                            oninput="validity.valid||(value='');"
                            step="1"
                            type="number"
                            required
                          />
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class=""
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="start start"
                >
                  <!--ITEM QUANTITY PER PORTION-->
                  <div *ngIf="!editMode" class="info-line mr-4" fxFlex="100%">
                    <div class="title">
                      {{ "PRODUCTS.ITEM_QUANTITY" | translate }}
                    </div>
                    <div class="info">
                      <app-copy-wrapper>{{
                        product.item_qty
                      }}</app-copy-wrapper>
                    </div>
                    <div
                      *ngIf="product.item_qty === null"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field
                          class="form-input-container mr-4"
                          fxFlex="100%"
                        >
                          <!-- <input matInput placeholder="{{ 'PRODUCTS.ITEM_QUANTITY' | translate }}"
                                                        formControlName="item_qty" 
                                                        type="number" min="0" [(ngModel)]="product.item_qty"> -->

                          <!-- <input [ngModel]="product.item_qty" autocomplete="nope"
                                                           formControlName="item_qty"
                                                           matInput
                                                           pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
                                                           placeholder="{{ 'PRODUCTS.ITEM_QUANTITY' | translate }}"
                                                           type="text"> -->

                          <mat-label>{{
                            "PRODUCTS.ITEM_QUANTITY" | translate
                          }}</mat-label>
                          <input
                            [(ngModel)]="product.item_qty"
                            autocomplete="nope"
                            formControlName="item_qty"
                            matInput
                            numeric
                            type="text"
                            required
                          />

                          <mat-error *ngIf="form.controls['item_qty'].invalid"
                            >{{ "GENERIC.INPUT_NUMBER_0_999999" | translate }}
                            {{
                              "GENERIC.INPUT_NUMBER_POSTFIX_2_DECIMAL"
                                | translate
                            }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="!editMode" class="info-line ml-8" fxFlex="100%">
                    <div class="title">
                      {{ "PRODUCTS.UNIT" | translate }}
                    </div>
                    <div *ngIf="product.item_unit" class="info">
                      <app-copy-wrapper>{{
                        product.item_unit.name
                      }}</app-copy-wrapper>
                    </div>
                    <div
                      *ngIf="!product.item_unit"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="">
                      <app-gt2-select
                        (changeEvent)="onUnitSelected($event)"
                        [label]="'Unit'"
                        [noResultsLabel]="
                          translate.instant('GENERIC.NO_RESULTS')
                        "
                        [nullableUseNone]="false"
                        [placeHolder]="'PRODUCTS.UNIT_SELECT' | translate"
                        [selectedModel]="product.item_unit"
                        [service]="unitService"
                        class=""
                        fxFlex="100%"
                      ></app-gt2-select>
                    </div>
                  </div>
                </div>

                <div
                  class="mt-8"
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="start start"
                >
                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "PRODUCTS.WRAPPING" | translate }}
                    </div>
                    <div *ngIf="product.wrapping" class="info">
                      <app-copy-wrapper>{{
                        product.wrapping.name
                      }}</app-copy-wrapper>
                    </div>
                    <div
                      *ngIf="!product.wrapping"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <!--<div class="title">{{ 'PRODUCTS.WRAPPING' | translate }}</div>-->
                    <div class="mr-4">
                      <app-gt2-select
                        #wrapping
                        (changeEvent)="onWrappingSelected($event)"
                        [label]="'PRODUCTS.WRAPPING' | translate"
                        [noResultsLabel]="
                          translate.instant('GENERIC.NO_RESULTS')
                        "
                        [nullableUseNone]="false"
                        [placeHolder]="'PRODUCTS.WRAPPING_SELECT' | translate"
                        [selectedModel]="product.wrapping"
                        [service]="wrappingService"
                        class=""
                        fxFlex="100%"
                      ></app-gt2-select>
                    </div>
                  </div>

                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "PRODUCTS.TAXES" | translate }}
                    </div>
                    <div *ngIf="product.tax" class="info">
                      <app-copy-wrapper>{{
                        product.tax.name
                      }}</app-copy-wrapper>
                    </div>
                    <div *ngIf="!product.tax" class="info empty-value"></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="">
                      <app-gt2-select
                        (changeEvent)="onTaxesSelected($event)"
                        [label]="'PRODUCTS.TAXES' | translate"
                        [noResultsLabel]="
                          translate.instant('GENERIC.NO_RESULTS')
                        "
                        [placeHolder]="'PRODUCTS.TAXES_SELECT' | translate"
                        [selectedModel]="product.tax"
                        [service]="taxesService"
                        class=""
                        fxFlex="100%"
                      ></app-gt2-select>
                    </div>
                  </div>
                </div>

                <div
                  class=""
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="start start"
                >
                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "PRODUCTS.INVOICE_CATEGORIES" | translate }}
                    </div>
                    <div *ngIf="product.invoice_category" class="info">
                      <app-copy-wrapper>{{
                        product.invoice_category.name
                      }}</app-copy-wrapper>
                    </div>
                    <div
                      *ngIf="!product.invoice_category"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="mr-4">
                      <app-gt2-select
                        (changeEvent)="onInvoiceCategorySelected($event)"
                        [label]="'PRODUCTS.INVOICE_CATEGORY' | translate"
                        [noResultsLabel]="
                          translate.instant('GENERIC.NO_RESULTS')
                        "
                        [nullableUseNone]="false"
                        [placeHolder]="'PRODUCTS.INVOICE_SELECT' | translate"
                        [selectedModel]="product.invoice_category"
                        [service]="invoicesCategoriesService"
                        class=""
                        fxFlex="100%"
                      >
                      </app-gt2-select>
                    </div>
                  </div>

                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "PRODUCTS.PRODUCT_CATEGORY" | translate }}
                    </div>
                    <div *ngIf="product.product_category" class="info">
                      <app-copy-wrapper>{{
                        product.product_category.name
                      }}</app-copy-wrapper>
                    </div>
                    <div
                      *ngIf="!product.product_category"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div *ngIf="product" class="">
                      <app-gt2-select
                        (changeEvent)="onProductCategorySelected($event)"
                        [label]="'PRODUCTS.PRODUCT_CATEGORY' | translate"
                        [noResultsLabel]="
                          translate.instant('GENERIC.NO_RESULTS')
                        "
                        [nullableUseNone]="false"
                        [placeHolder]="
                          'PRODUCTS.PRODUCT_CATEGORY_SELECT' | translate
                        "
                        [selectedModel]="product.product_category"
                        [service]="productCategoryService"
                        class=""
                        fxFlex="100%"
                      >
                      </app-gt2-select>
                    </div>
                  </div>
                </div>

                <div
                  class=""
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="start start"
                >
                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "GENERIC.AVAILABLE" | translate }}
                    </div>
                    <div *ngIf="product.active" class="info">
                      {{ "GENERIC.YES" | translate }}
                    </div>
                    <div *ngIf="!product.active" class="info">
                      {{ "GENERIC.NO" | translate }}
                    </div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <mat-checkbox
                      (change)="onProductActiveChange($event)"
                      [(ngModel)]="product.active"
                      [ngModelOptions]="{
                        standalone: true,
                      }"
                      color="accent"
                      labelPosition="before"
                    >
                      {{ "GENERIC.AVAILABLE" | translate }}
                    </mat-checkbox>
                  </div>

                  <!-- IS ALCOHOLIC PRODUCT -->
                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "GENERIC.ALCOHOLIC_PRODUCT" | translate }}
                    </div>
                    <div *ngIf="product.is_alcoholic_product" class="info">
                      {{ "GENERIC.YES" | translate }}
                    </div>
                    <div *ngIf="!product.is_alcoholic_product" class="info">
                      {{ "GENERIC.NO" | translate }}
                    </div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <mat-checkbox
                      (change)="onProductAlcoholicStateChange($event)"
                      [(ngModel)]="product.is_alcoholic_product"
                      [ngModelOptions]="{
                        standalone: true,
                      }"
                      color="accent"
                      labelPosition="before"
                    >
                      {{ "GENERIC.ALCOHOLIC_PRODUCT" | translate }}
                    </mat-checkbox>
                  </div>
                </div>

                <!-- <div class="" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
                                    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                                        <div class="title">{{ 'GENERIC.SHOW_CHARGED_PRICE' | translate }}</div>
                                        <div *ngIf="product.show_charged_price" class="info">{{ 'GENERIC.YES' | translate}}</div>
                                        <div *ngIf="!product.show_charged_price" class="info">{{ 'GENERIC.NO' | translate}}</div>
                                    </div>
                                    <div *ngIf="editMode" class="info-line" fxFlex="50%">
                                        <mat-checkbox  [(ngModel)]="product.show_charged_price"
                                            [ngModelOptions]="{standalone: true}" color="accent" labelPosition="before">
                                            {{ 'GENERIC.SHOW_CHARGED_PRICE' | translate }}
                                        </mat-checkbox>
                                    </div>
                                </div> -->
              </div>
            </mat-tab>

            <mat-tab label="{{ 'GENERIC.en-CA' | translate }}">
              <ng-template matTabContent>
                <div class="content" fxLayout="column">
                  <!-- PRODUCT INTERNAL NAME EN -->
                  <div
                    fxLayout="row"
                    fxLayout.xs="column"
                    fxLayoutAlign="start start"
                  >
                    <!-- VIEW MODE -->
                    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                      <div class="title">
                        {{ "GENERIC.INTERNAL_NAME" | translate }}
                      </div>
                      <div class="info">
                        <app-copy-wrapper>{{
                          product.name_internal_local.name_internal_en_CA
                        }}</app-copy-wrapper>
                      </div>
                      <div
                        *ngIf="
                          !product.name_internal_local ||
                          !product.name_internal_local.name_internal_en_CA
                        "
                        class="info empty-value"
                      ></div>
                    </div>
                    <!-- EDIT MODE -->
                    <div *ngIf="editMode" class="info-line" fxFlex="50%">
                      <div class="info-editable">
                        <div [formGroup]="formEnTab" class="">
                          <mat-form-field
                            class="form-input-container"
                            fxFlex="100%"
                          >
                            <mat-label>{{
                              "GENERIC.INTERNAL_NAME" | translate
                            }}</mat-label>
                            <input
                              [(ngModel)]="
                                product.name_internal_local.name_internal_en_CA
                              "
                              autocomplete="nope"
                              formControlName="name_internal_en_CA"
                              matInput
                              required
                              type="text"
                            />
                            <mat-error
                              *ngIf="
                                formEnTab.controls['name_internal_en_CA']
                                  .invalid
                              "
                            >
                              {{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>

                    <!-- PRODUCT EXTERNAL NAME EN -->
                    <button
                      *ngIf="editMode"
                      class=""
                      mat-icon-button
                      (click)="
                        product.name_local.name_en_CA =
                          formEnTab.controls['name_internal_en_CA'].value
                      "
                      class="mt-4"
                      matTooltip="{{
                        'GENERIC.COPY_INTERNAL_NAME_TO_EXTERNAL' | translate
                      }}"
                    >
                      <mat-icon class="icon-mat s-20">redo</mat-icon>
                    </button>

                    <!-- VIEW MODE -->
                    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                      <div class="title">
                        {{ "GENERIC.EXTERNAL_NAME" | translate }}
                      </div>
                      <div class="info">
                        <app-copy-wrapper>{{
                          product.name_local.name_en_CA
                        }}</app-copy-wrapper>
                      </div>
                      <div
                        *ngIf="
                          !product.name_local || !product.name_local.name_en_CA
                        "
                        class="info empty-value"
                      ></div>
                    </div>
                    <!-- EDIT MODE -->
                    <div *ngIf="editMode" class="info-line" fxFlex="100%">
                      <div class="info-editable">
                        <div [formGroup]="formEnTab" class="">
                          <mat-form-field
                            class="form-input-container"
                            fxFlex="100%"
                          >
                            <mat-label>{{
                              "GENERIC.EXTERNAL_NAME" | translate
                            }}</mat-label>
                            <input
                              [(ngModel)]="product.name_local.name_en_CA"
                              autocomplete="nope"
                              formControlName="name_en_CA"
                              matInput
                              required
                              type="text"
                            />
                            <mat-error
                              *ngIf="formEnTab.controls['name_en_CA'].invalid"
                            >
                              {{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- DESCRIPTION -->
                  <div
                    class="mt-8"
                    fxLayout="row"
                    fxLayout.xs="column"
                    fxLayoutAlign="start start"
                  >
                    <div
                      class="info-line"
                      fxFlex="100%"
                      [formGroup]="formEnTab"
                    >
                      <div class="title">
                        {{ "PRODUCTS.DESCRIPTION" | translate }}
                      </div>
                      <p-editor
                        #descriptionEnEditor
                        formControlName="description_en_CA"
                        [(ngModel)]="
                          product.description_local.description_en_CA
                        "
                        [readonly]="!editMode"
                        [style]="{
                          height: '120px',
                          width: '100%',
                          'background-color': editMode ? '#ffffff' : '#EDEDED',
                        }"
                      >
                        <ng-template pTemplate="header" class="">
                          <span class="ql-formats">
                            <button class="ql-bold"></button>
                            <button class="ql-italic"></button>
                            <button class="ql-underline"></button>
                            <button class="ql-strike"></button>
                          </span>
                          <span class="ql-formats">
                            <button class="ql-list" value="ordered"></button>
                            <button class="ql-list" value="bullet"></button>
                            <button class="ql-indent" value="-1"></button>
                            <button class="ql-indent" value="+1"></button>
                          </span>
                        </ng-template>
                      </p-editor>
                      <mat-error
                        *ngIf="formEnTab.controls['description_en_CA'].invalid"
                        class="p-4 pr-8"
                        fxLayout="row"
                        fxLayoutAlign="end center"
                      >
                        {{
                          "GENERIC.NOTE_EDITOR_LENGTH_INVALID_2048" | translate
                        }}
                      </mat-error>
                    </div>
                  </div>
                </div>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </div>

        <!--CHILDS-->
        <div
          *ngIf="product && product.is_combo"
          class="profile-box info-box general"
          fxLayout="column"
        >
          <header
            class="accent"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <div class="title">
              {{ "PRODUCTS.COMBO" | translate }}
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-checkbox
                *ngIf="
                  modulesService.modulesAreSet &&
                  modulesService.hasComboDynamicModule()
                "
                class=""
                matTooltip="{{
                  'PRODUCTS.CHOICE_PRODUCT_COMBO_TIP' | translate
                }}"
                [(ngModel)]="product.is_choice_product_combo"
                (change)="onChoiceProductComboValueChange($event)"
                [ngModelOptions]="{ standalone: true }"
                color="primary"
                class="mr-12"
                >{{ "PRODUCTS.CHOICE_PRODUCT_COMBO" | translate }}</mat-checkbox
              >
              <div
                class="gt2-light-green"
                fxLayout="row"
                fxLayoutAlign="center center"
                style="width: 60px; height: 56px"
              >
                <button
                  (click)="onEditCombo()"
                  class=""
                  mat-icon-button
                  matTooltip="{{ 'PRODUCTS.CHANGE_COMBO_ORDER' | translate }}"
                  type="button"
                >
                  <mat-icon class="icon-mat" color="">add</mat-icon>
                </button>
              </div>
            </div>
          </header>
          <div
            class=""
            fusePerfectScrollbar
            fxLayout="column"
            style="max-height: 340px"
          >
            <div *ngIf="product && product.childs">
              <!--<div *ngIf="editMode" fxLayout="row" fxLayoutAlign="space-between center">-->
              <!--<div class="pl-8 mat-caption">{{ 'PRODUCTS.CHANGE_COMBO_ORDER' | translate }}</div>-->
              <!--<button type="button"-->
              <!--mat-icon-button-->
              <!--(click)="onEditCombo()">-->
              <!--<mat-icon color="accent">add</mat-icon>-->
              <!--</button>-->
              <!--</div>-->

              <!-- HACK! display: none-->
              <!-- <ngx-dnd-container
                [ngModel]="['Item 1', 'Item 2', 'Item 3']"
                style="display: none"
              >
              </ngx-dnd-container> -->

              <div
                (drop)="onProductChildDrop($event)"
                *ngIf="editMode"
                [ngModel]="product.childs"
                class=""
                ngxDroppable
              >
                <div
                  *ngFor="let item of product.childs; let i = index"
                  [ngModel]="item"
                  class="product-child-item"
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  matRipple
                  ngxDraggable
                >
                  <mat-icon class="icon-mat mr-8">drag_handle</mat-icon>
                  <div
                    [matTooltipPosition]="'right'"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    matTooltip="{{ item.name }}"
                  >
                    <mat-chip-listbox style="margin: 5px">
                      <mat-chip>{{ item.qty }}</mat-chip>
                    </mat-chip-listbox>
                    &nbsp;{{ item.name_internal }}
                  </div>
                </div>
              </div>

              <div *ngIf="!editMode" class="">
                <div
                  *ngFor="let item of product.childs; let i = index"
                  class="product-child-item"
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                  matRipple
                >
                  <div
                    (click)="onNavigateToProduct(item)"
                    [matTooltipPosition]="'right'"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    matTooltip="{{ item.name }}"
                  >
                    <mat-icon class="icon-mat mr-4">link</mat-icon>
                    <mat-chip-listbox style="margin: 5px">
                      <mat-chip>{{ item.qty }}</mat-chip>
                    </mat-chip-listbox>
                    &nbsp;{{ item.name_internal }}
                  </div>
                  <!--<div fxLayout="row" fxLayoutAlign="end center">
                                        <div fxFlex="62px" fxLayoutAlign="end center">
                                            <button mat-button matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
                                                    (click)="onOpenInNewTab(item)" class="mat-icon-button">
                                                <mat-icon class="s-20">{{ getIcon('NEW_TAB') }}</mat-icon>
                                            </button>
                                        </div>
                                    </div>-->
                </div>
              </div>
            </div>

            <div
              *ngIf="product && !product.childs"
              fxLayout="row"
              fxLayoutAlign="center center"
            >
              <div class="mat-subheading-1 pb-8 pt-12">
                {{ "PRODUCTS.NO_CHILDS" | translate }}
              </div>
            </div>
          </div>

          <div
            *ngIf="editMode"
            class="px-12 py-8 section-footer"
            fxLayout="row"
            fxLayoutAlign="end start"
          >
            <div class="pr-16" *ngxPermissionsOnly="rolesService.GOD_ROLES">
              <!-- Only God Users can see this box -->
              <mat-checkbox
                (change)="onComboShowChildNameChange($event)"
                [(ngModel)]="product.combo_show_child_name"
                [ngModelOptions]="{ standalone: true }"
                color="accent"
                labelPosition="before"
                >{{ "PRODUCTS.COMBO_CHECKBOX_LABEL" | translate }}
              </mat-checkbox>
            </div>
            <div fxLayout="column" fxLayoutAlign="start end">
              <mat-checkbox
                (change)="onComboShowChildQuantityChange($event)"
                [(ngModel)]="product.combo_show_child_qty"
                [ngModelOptions]="{ standalone: true }"
                color="accent"
                labelPosition="before"
                >{{ "PRODUCTS.COMBO_CHECKBOX_QTY_LABEL" | translate }}
              </mat-checkbox>

              <mat-checkbox
                (change)="onComboShowChildPriceChange($event)"
                [(ngModel)]="product.combo_show_child_price"
                [ngModelOptions]="{ standalone: true }"
                color="accent"
                labelPosition="before"
                >{{ "PRODUCTS.COMBO_CHECKBOX_PRICE_LABEL" | translate }}
              </mat-checkbox>
            </div>
          </div>
        </div>

        <!--PARENTS-->
        <div
          *ngIf="product && product.parents"
          class="profile-box info-box general"
          fxLayout="column"
        >
          <header
            class="accent"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <div class="title">
              {{ "PRODUCTS.COMBO_PARENTS" | translate }}
            </div>
          </header>
          <div
            class=""
            fusePerfectScrollbar
            fxLayout="column"
            style="max-height: 340px"
          >
            <div>
              <div class="">
                <div
                  *ngFor="let item of product.parents; let i = index"
                  class="product-child-item"
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                  matRipple
                >
                  <div
                    (click)="onNavigateToProduct(item)"
                    [matTooltipPosition]="'right'"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    matTooltip="{{ item.name }}"
                  >
                    &nbsp;{{ item.name_internal }}
                  </div>

                  <div>
                    <button
                      mat-icon-button
                      [disabled]="!editable"
                      *ngxPermissionsOnly="rolesService.SALE_ROLES"
                      matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
                      (click)="
                        onOpenProductInNewTab(item); $event.stopPropagation()
                      "
                      class="mat-icon-button"
                      tabindex="-1"
                    >
                      <mat-icon class="icon-mat s-16"
                        >{{ getIcon("NEW_TAB") }}
                      </mat-icon>
                    </button>

                    <button
                      mat-icon-button
                      [disabled]="!editable"
                      *ngxPermissionsOnly="rolesService.SALE_ROLES"
                      matTooltip="{{ 'GENERIC.OPEN_SAME_TAB' | translate }}"
                      (click)="onOpenObjectPage(item); $event.stopPropagation()"
                      class="mat-icon-button"
                      tabindex="-1"
                    >
                      <mat-icon
                        fontSet="fa"
                        fontIcon="{{ getIcon('SAME_TAB') }}"
                        class="icon-mat"
                      >
                      </mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- SHOPS -->
        <div
          *ngIf="product && product.shops && !product.ordered"
          class="profile-box info-box general"
          fxLayout="column"
        >
          <header
            class="accent"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <div class="title">{{ "PRODUCTS.SHOPS" | translate }}</div>
            <mat-checkbox
              *ngIf="
                modulesService.modulesAreSet &&
                modulesService.hasCyclicMenuModule()
              "
              [(ngModel)]="product.is_cyclic"
              (change)="onCyclicValueChange($event)"
              [ngModelOptions]="{ standalone: true }"
              color="primary"
              class="mr-12"
              >{{ "PRODUCTS.CYCLIC_PRODUCT" | translate }}</mat-checkbox
            >
          </header>

          <div class="" fusePerfectScrollbar fxLayout="column">
            <mat-tab-group
              animationDuration="100ms"
              mat-stretch-tabs="false"
              mat-align-tabs="start"
            >
              <mat-tab label="Liste">
                <div style="max-height: 500px">
                  <app-product-shops
                    #productShop
                    [hasCyclicMenuModule]="
                      modulesService.modulesAreSet &&
                      modulesService.hasCyclicMenuModule()
                    "
                    [shops]="product.shops"
                    [(product)]="product"
                    (changeEvent)="onShopCheckboxEvent($event)"
                    (productChangeEvent)="onShopProductEvent($event)"
                  ></app-product-shops>
                </div>
              </mat-tab>
              <mat-tab label="Image">
                <div style="max-height: 480px">
                  <app-product-picture-upload
                    [productUUID]="product.uuid"
                    [media]="product.medias"
                    (imageDeleted)="loadProduct()"
                  >
                  </app-product-picture-upload>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>

        <!--  UPSALE  -->

        <div
          *ngIf="
            modulesService.modulesAreSet &&
            modulesService.hasUpsaleModule() &&
            product
          "
          class="profile-box info-box general"
          fxLayout="column"
        >
          <header
            class="accent"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <div class="title">
              {{ "PRODUCTS.UPSALE" | translate }}
            </div>
            <div
              *ngIf="editMode"
              class="gt2-light-green"
              fxLayout="row"
              fxLayoutAlign="center center"
              style="width: 60px; height: 56px"
            >
              <button
                (click)="onEditProductUpsale()"
                class=""
                mat-icon-button
                matTooltip="{{
                  'PRODUCTS.ADD_REMOVE_UPSALE_QUESTIONS' | translate
                }}"
                type="button"
              >
                <mat-icon class="icon-mat" color="">add</mat-icon>
              </button>
            </div>
          </header>

          <div
            class=""
            fusePerfectScrollbar
            fxLayout="column"
            style="max-height: 340px"
            matRipple
          >
            <div
              *ngIf="
                !product.upsale_questions ||
                product.upsale_questions.length === 0
              "
              class="mat-subheading-1 pb-8 pt-12"
              fxLayout="row"
              fxLayoutAlign="center center"
            >
              <div *ngxPermissionsOnly="rolesService.RH_ROLES">
                {{ "PRODUCTS.NO_UPSALE_QUESTIONS_HR" | translate }}
              </div>
              <div *ngxPermissionsExcept="rolesService.RH_ROLES">
                {{ "PRODUCTS.NO_UPSALE_QUESTIONS" | translate }}
              </div>
            </div>
            <div
              class="product-child-full-item-no-pointer"
              *ngFor="let item of product.upsale_questions; let i = index"
              fxLayout="row"
              fxLayoutAlign="space-between center"
              matRipple
            >
              <div
                (click)="onUpsale()"
                class="product-child-left-item-no-pointer"
              >
                {{ item.name }}
              </div>
              <button
                (click)="onRemoveUpsaleQuestion(item)"
                *ngIf="editMode"
                [disabled]="!editable || loading"
                class=""
                mat-icon-button
              >
                <mat-icon class="icon-mat s-16 secondary-text">{{
                  getIcon("DELETE")
                }}</mat-icon>
              </button>
            </div>
          </div>
        </div>

        <div
          *ngIf="product"
          class="profile-box info-box general"
          fxLayout="column"
        >
          <header
            class="accent"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <div class="title">
              {{ "GENERIC.ALLERGENS" | translate }}
            </div>
            <div
              *ngIf="editMode"
              class="gt2-light-green"
              fxLayout="row"
              fxLayoutAlign="center center"
              style="width: 60px; height: 56px"
            >
              <button
                (click)="onEditAllergens()"
                class=""
                mat-icon-button
                matTooltip="{{ 'PRODUCTS.ADD_REMOVE_ALLERGENS' | translate }}"
                type="button"
              >
                <mat-icon class="icon-mat" color="">add</mat-icon>
              </button>
            </div>
          </header>

          <div
            class=""
            fusePerfectScrollbar
            fxLayout="column"
            style="max-height: 340px"
          >
            <div
              *ngIf="!product.allergens || product.allergens.length === 0"
              class="mat-subheading-1 pb-8 pt-12"
              fxLayout="row"
              fxLayoutAlign="center center"
            >
              <div *ngxPermissionsOnly="rolesService.RH_ROLES">
                {{ "PRODUCTS.NO_ALLERGENS_HR" | translate }}
              </div>
              <div *ngxPermissionsExcept="rolesService.RH_ROLES">
                {{ "PRODUCTS.NO_ALLERGENS" | translate }}
              </div>
            </div>
            <div
              *ngFor="let item of product.allergens; let i = index"
              class="product-child-item-no-pointer"
              matRipple
              fxLayout="row"
              fxLayoutAlign="space-between center"
            >
              <!--<mat-icon class="mr-4">link</mat-icon>-->
              <div>{{ item.name }}</div>
              <button
                (click)="onRemoveAllergen(item)"
                *ngIf="editMode"
                [disabled]="!editable || loading"
                class=""
                mat-icon-button
              >
                <mat-icon class="icon-mat s-16 secondary-text">{{
                  getIcon("DELETE")
                }}</mat-icon>
              </button>
            </div>
          </div>
        </div>

        <div
          *ngIf="product"
          class="profile-box info-box general"
          fxLayout="column"
        >
          <header
            class="accent"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <div class="title">
              {{ "PRODUCTS.PRODUCTION_ZONE" | translate }}
            </div>
            <div
              *ngIf="editMode"
              class="gt2-light-green"
              fxLayout="row"
              fxLayoutAlign="center center"
              style="width: 60px; height: 56px"
            >
              <button
                (click)="onEditProductionsZone()"
                class=""
                mat-icon-button
                matTooltip="{{
                  'PRODUCTS.ADD_REMOVE_PRODUCTION_ZONE' | translate
                }}"
                type="button"
              >
                <mat-icon class="icon-mat" color="">add</mat-icon>
              </button>
            </div>
          </header>

          <div
            class=""
            fusePerfectScrollbar
            fxLayout="column"
            style="max-height: 340px"
          >
            <div
              *ngIf="
                !product.production_zones ||
                product.production_zones.length === 0
              "
              class="mat-subheading-1 pb-8 pt-12"
              fxLayout="row"
              fxLayoutAlign="center center"
            >
              <div *ngxPermissionsOnly="rolesService.RH_ROLES">
                {{ "PRODUCTS.NO_PRODUCTION_ZONES_HR" | translate }}
              </div>
              <div *ngxPermissionsExcept="rolesService.RH_ROLES">
                {{ "PRODUCTS.NO_PRODUCTION_ZONES" | translate }}
              </div>
            </div>
            <div
              *ngFor="let item of product.production_zones; let i = index"
              class="product-child-item-no-pointer"
              fxLayout="row"
              fxLayoutAlign="space-between center"
              matRipple
            >
              <div>{{ item.name }}</div>
              <button
                (click)="onRemoveProductionZone(item)"
                *ngIf="editMode"
                [disabled]="!editable || loading"
                class=""
                mat-icon-button
              >
                <mat-icon class="icon-mat s-16 secondary-text">{{
                  getIcon("DELETE")
                }}</mat-icon>
              </button>
            </div>
          </div>
        </div>

        <!--<div *ngIf="product" class="profile-box info-box general" fxLayout="column">-->

        <!--<header class="accent">-->
        <!--<div class="title">{{ "PRODUCTS.AVAILABILITY" | translate}}</div>-->
        <!--</header>-->

        <!--<div class="">-->
        <!--<div fxLayout="row"-->
        <!--fxLayoutAlign="center center"-->
        <!--class="mat-subheading-1 pb-8 pt-12">-->
        <!--À venir!-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->

        <!--<div class="mb-24"-->
        <!--mwlResizable-->
        <!--style="background-color: #2AA198;"-->
        <!--[resizeSnapGrid]="{left: 50, right: 50}"-->
        <!--(resizeEnd)="onResizeEnd($event)"-->
        <!--[enableGhostResize]="true"-->
        <!--[resizeEdges]="{bottom: true, right: true, top: true, left: true}">-->

        <!--<img-->
        <!--src="http://i.imgur.com/eqzz2dl.gif"-->
        <!--class="resize-handle"-->
        <!--mwlResizeHandle-->
        <!--[resizeEdges]="{bottom: true, right: true}">-->
        <!--</div>-->

        <!--NOTE-->
        <div
          *ngIf="product"
          class="profile-box info-box general"
          fxLayout="column"
        >
          <header class="accent">
            <div class="title">
              {{ "GENERIC.NOTES" | translate }}
            </div>
          </header>

          <mat-tab-group
            animationDuration="100ms"
            mat-stretch-tabs="false"
            mat-align-tabs="start"
          >
            <mat-tab label="{{ 'GENERIC.GENERALS' | translate }}">
              <div class="note-section">
                <p-editor
                  #noteEditor
                  [(ngModel)]="product.note"
                  [readonly]="!editMode"
                  [style]="{
                    height: '120px',
                    width: '100%',
                    'background-color': editMode ? '#ffffff' : '#EDEDED',
                  }"
                >
                  <ng-template pTemplate="header" class="">
                    <span class="ql-formats">
                      <select class="ql-font"></select>
                      <select class="ql-size"></select>
                    </span>

                    <span class="ql-formats">
                      <button class="ql-bold"></button>
                      <button class="ql-italic"></button>
                      <button class="ql-underline"></button>
                      <button class="ql-strike"></button>
                    </span>

                    <span class="ql-formats">
                      <select class="ql-color"></select>
                      <select class="ql-background"></select>
                    </span>

                    <!-- <span class="ql-formats">
                                            <button class="ql-script" value="sub"></button>
                                            <button class="ql-script" value="super"></button>
                                        </span>

                                        <span class="ql-formats">
                                            <button class="ql-header" value="1"></button>
                                            <button class="ql-header" value="2"></button>
                                            <button class="ql-blockquote"></button>
                                            <button class="ql-code-block"></button>
                                        </span> -->

                    <span class="ql-formats">
                      <button class="ql-list" value="ordered"></button>
                      <button class="ql-list" value="bullet"></button>
                      <button class="ql-indent" value="-1"></button>
                      <button class="ql-indent" value="+1"></button>
                    </span>

                    <!-- <span class="ql-formats">
                                            <button class="ql-direction" value="rtl"></button>
                                            <select class="ql-align"></select>
                                        </span> -->

                    <!-- <span class="ql-formats">
                                            <button class="ql-link"></button>
                                            <button class="ql-image"></button>
                                            <button class="ql-video"></button>
                                            <button class="ql-formula"></button>
                                        </span>
                                        <span class="ql-formats">
                                            <button class="ql-clean"></button>
                                        </span> -->
                  </ng-template>
                </p-editor>
                <mat-error
                  *ngIf="noteInvalid.note"
                  class="p-4 pr-8"
                  fxLayout="row"
                  fxLayoutAlign="end center"
                >
                  {{ "GENERIC.NOTE_EDITOR_LENGTH_INVALID_2048" | translate }}
                </mat-error>
              </div>
            </mat-tab>

            <mat-tab label="{{ 'GENERIC.SALE' | translate }}">
              <ng-template matTabContent>
                <div class="note-section">
                  <p-editor
                    #noteSaleEditor
                    [(ngModel)]="product.sale_note"
                    [readonly]="!editMode"
                    [style]="{
                      height: '120px',
                      width: '100%',
                      'background-color': editMode ? '#ffffff' : '',
                    }"
                  >
                    <ng-template pTemplate="header" class="">
                      <span class="ql-formats">
                        <select class="ql-font"></select>
                        <select class="ql-size"></select>
                      </span>

                      <span class="ql-formats">
                        <button class="ql-bold"></button>
                        <button class="ql-italic"></button>
                        <button class="ql-underline"></button>
                        <button class="ql-strike"></button>
                      </span>

                      <span class="ql-formats">
                        <select class="ql-color"></select>
                        <select class="ql-background"></select>
                      </span>

                      <span class="ql-formats">
                        <button class="ql-list" value="ordered"></button>
                        <button class="ql-list" value="bullet"></button>
                        <button class="ql-indent" value="-1"></button>
                        <button class="ql-indent" value="+1"></button>
                      </span>
                    </ng-template>
                  </p-editor>
                  <mat-error
                    *ngIf="noteInvalid.sale_note"
                    class="p-4 pr-8"
                    fxLayout="row"
                    fxLayoutAlign="end center"
                  >
                    {{ "GENERIC.NOTE_EDITOR_LENGTH_INVALID_2048" | translate }}
                  </mat-error>
                </div>
              </ng-template>
            </mat-tab>

            <mat-tab label="Production">
              <ng-template matTabContent>
                <div class="note-section">
                  <p-editor
                    #noteProdEditor
                    [(ngModel)]="product.prod_note"
                    [readonly]="!editMode"
                    [style]="{
                      height: '120px',
                      width: '100%',
                      'background-color': editMode ? '#ffffff' : '',
                    }"
                  >
                    <ng-template pTemplate="header" class="">
                      <span class="ql-formats">
                        <select class="ql-font"></select>
                        <select class="ql-size"></select>
                      </span>

                      <span class="ql-formats">
                        <button class="ql-bold"></button>
                        <button class="ql-italic"></button>
                        <button class="ql-underline"></button>
                        <button class="ql-strike"></button>
                      </span>

                      <span class="ql-formats">
                        <select class="ql-color"></select>
                        <select class="ql-background"></select>
                      </span>

                      <span class="ql-formats">
                        <button class="ql-list" value="ordered"></button>
                        <button class="ql-list" value="bullet"></button>
                        <button class="ql-indent" value="-1"></button>
                        <button class="ql-indent" value="+1"></button>
                      </span>
                    </ng-template>
                  </p-editor>
                  <mat-error
                    *ngIf="noteInvalid.prod_note"
                    class="p-4 pr-8"
                    fxLayout="row"
                    fxLayoutAlign="end center"
                  >
                    {{ "GENERIC.NOTE_EDITOR_LENGTH_INVALID_2048" | translate }}
                  </mat-error>
                </div>
              </ng-template>
            </mat-tab>

            <mat-tab label="{{ 'GENERIC.INGREDIENTS' | translate }}">
              <ng-template matTabContent>
                <div class="note-section">
                  <p-editor
                    #noteIngredientsEditor
                    [(ngModel)]="product.ingredient_note"
                    [readonly]="!editMode"
                    [style]="{
                      height: '120px',
                      width: '100%',
                      'background-color': editMode ? '#ffffff' : '',
                    }"
                  >
                    <ng-template pTemplate="header" class="">
                      <span class="ql-formats">
                        <select class="ql-font"></select>
                        <select class="ql-size"></select>
                      </span>

                      <span class="ql-formats">
                        <button class="ql-bold"></button>
                        <button class="ql-italic"></button>
                        <button class="ql-underline"></button>
                        <button class="ql-strike"></button>
                      </span>

                      <span class="ql-formats">
                        <select class="ql-color"></select>
                        <select class="ql-background"></select>
                      </span>

                      <span class="ql-formats">
                        <button class="ql-list" value="ordered"></button>
                        <button class="ql-list" value="bullet"></button>
                        <button class="ql-indent" value="-1"></button>
                        <button class="ql-indent" value="+1"></button>
                      </span>
                    </ng-template>
                  </p-editor>
                  <mat-error
                    *ngIf="noteInvalid.ingredient_note"
                    class="p-4 pr-8"
                    fxLayout="row"
                    fxLayoutAlign="end center"
                  >
                    {{ "GENERIC.NOTE_EDITOR_LENGTH_INVALID_2048" | translate }}
                  </mat-error>
                </div>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </div>

        <app-comment-section
          *ngIf="product"
          [commentsModel]="product"
          [disabled]="!editable"
          [serviceAPIUrl]="'/v1/products/comments/' + product.uuid"
        >
        </app-comment-section>

        <app-created-updated-at
          *ngIf="product"
          [historicModel]="product.historic_model"
        >
        </app-created-updated-at>

        <div *ngxPermissionsOnly="rolesService.RH_ROLES">
          <div
            *ngIf="product && editable"
            class="mt-8"
            fxFlex="100%"
            fxLayout="row"
            fxLayoutAlign="center center"
          >
            <button (click)="deleteProduct()" class="warn" mat-raised-button>
              {{ "GENERIC.DELETE" | translate }}
            </button>
          </div>
        </div>
      </div>
      <!-- / CONTENT -->
    </div>
    <!-- / CENTER -->
  </div>
</div>

<div *ngIf="product && editable" fxLayout="row" fxLayoutAlign="end start">
  <div *ngxPermissionsOnly="rolesService.TEAM_LEAD_ROLES">
    <div class="fixed-button">
      <!-- <button (click)="onEdit($event)" *fuseIfOnDom [@animate]="{value:'*', params:{delay:'300ms',scale:'.2'}}"
                [disabled]="loading" class="gt2-edit-button" id="edit-product-button" mat-fab>
                <mat-icon *ngIf="!editMode">{{ getIcon('EDIT_FAB') }}</mat-icon>
                <mat-icon *ngIf="editMode">{{ getIcon('UNDO') }}</mat-icon>
            </button> -->

      <button
        mat-fab
        #saveButton
        [ngClass]="editMode ? 'gt2-save-button' : 'gt2-edit-button'"
        id="edit-product-button"
        (click)="onEdit($event)"
        [disabled]="
          loading || (editMode && !form.valid) || (editMode && !formEnTabValid)
        "
        *fuseIfOnDom
        [@animate]="{
          value: '*',
          params: { delay: '300ms', scale: '.2' },
        }"
      >
        <mat-icon class="icon-mat-black" *ngIf="!editMode && !loading">{{
          getIcon("EDIT_FAB")
        }}</mat-icon>
        <mat-icon
          *ngIf="editMode && !loading && !saveButton.disabled"
          matTooltip="{{ 'GENERIC.SAVE' | translate }}"
          position="left"
          showDelay="900"
          hideDelay="900"
          class="icon-mat-black"
          >{{ getIcon("SAVE") }}</mat-icon
        >
        <mat-icon
          *ngIf="editMode && !loading && saveButton.disabled"
          matTooltip="{{ 'GENERIC.FORM_DIRTY_TIP' | translate }}"
          position="left"
          hideDelay="900"
          class="icon-mat-black"
          >{{ getIcon("SAVE") }}</mat-icon
        >
        <i [ngClass]="loading ? 'fa fa-spinner fa-spin' : ''"></i>
      </button>
    </div>
    <div class="fixed-button2">
      <button
        mat-fab
        *ngIf="editMode"
        id="cancel-edit-product-button"
        class="gt2-cancel-edit-button"
        matTooltip="{{ 'GENERIC.CANCEL' | translate }}"
        position="left"
        showDelay="900"
        hideDelay="900"
        (click)="onCancelEdit($event)"
        [disabled]="loading"
        [@animate]="{
          value: '*',
          params: { delay: '120ms', scale: '.2' },
        }"
      >
        <mat-icon class="icon-mat s-24">{{ getIcon("CANCEL") }}</mat-icon>
      </button>
    </div>
  </div>
</div>
