<div style="max-height: 100%; overflow: auto">
  <h1 matDialogTitle>{{ "ASSIGNMENTS.ASSIGNMENT" | translate }}</h1>

  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    name="proposalSpiner"
    size="medium"
    color="#90ee90"
    [fullScreen]="true"
    type="ball-scale-multiple"
  >
  </ngx-spinner>

  <div
    style="width: 100%; margin-bottom: 10px; margin-top: 4px"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <div fxFlex="50">
      <mat-checkbox
        [(ngModel)]="job.all_day"
        [ngModelOptions]="{ standalone: true }"
      >
        {{ "ASSIGNMENTS.REQUESTED_ALL_DAY" | translate }}
      </mat-checkbox>
    </div>

    <div fxFlex="50">
      <mat-checkbox
        [(ngModel)]="job.billable"
        [ngModelOptions]="{ standalone: true }"
      >
        {{ "ASSIGNMENTS.BILLABLE" | translate }}
      </mat-checkbox>
    </div>

    <div fxFlex="50">
      <mat-checkbox
        [(ngModel)]="job.tips_enabled"
        [ngModelOptions]="{ standalone: true }"
      >
        {{ "ASSIGNMENTS.TIPS_ENABLE" | translate }}
      </mat-checkbox>
    </div>
  </div>

  <div fxLayout="column" fxLayoutAlign="center start" [formGroup]="form">
    <div style="width: 100%" fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="50" class="mr-8">
        <mat-form-field style="width: 100%" class="mt-16">
          <mat-label>{{ "ASSIGNMENTS.DATE_START" | translate }}</mat-label>
          <input
            matInput
            [matDatepicker]="eventDateFrom"
            [(ngModel)]="job.real_date_from"
            min="1900-01-01"
            formControlName="real_date_from"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="eventDateFrom"
          ></mat-datepicker-toggle>
          <mat-datepicker #eventDateFrom></mat-datepicker>

          <mat-error *ngIf="form.controls['real_date_from'].invalid"
            >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div
        fxFlex="50"
        class="mr-8 ml-8"
        style="margin-bottom: 15px"
        [ngClass]="job.all_day ? 'time-disabled' : ''"
      >
        <div class="mat-caption time-title">
          {{ "ASSIGNMENTS.TIME_START" | translate }}
        </div>
        <ngx-timepicker-field
          [buttonAlign]="'left'"
          [format]="24"
          [disabled]="false"
          [(ngModel)]="job.real_time_from"
          [ngModelOptions]="{ standalone: true }"
          class="item-full-width ngx-material-timepicker-toggle"
        >
        </ngx-timepicker-field>
      </div>
    </div>

    <div style="width: 100%" fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="50" class="mr-8">
        <mat-form-field style="width: 100%" class="mt-16">
          <mat-label>{{ "ASSIGNMENTS.DATE_END" | translate }}</mat-label>
          <input
            matInput
            [matDatepicker]="eventDateTo"
            [(ngModel)]="job.real_date_to"
            min="1900-01-01"
            formControlName="real_date_to"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="eventDateTo"
          ></mat-datepicker-toggle>
          <mat-datepicker #eventDateTo></mat-datepicker>

          <mat-error *ngIf="form.controls['real_date_to'].invalid"
            >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div
        fxFlex="50"
        class="ml-8"
        style="margin-bottom: 15px"
        [ngClass]="job.all_day ? 'time-disabled' : ''"
      >
        <div class="mat-caption time-title">
          {{ "ASSIGNMENTS.TIME_END" | translate }}
        </div>
        <ngx-timepicker-field
          [buttonAlign]="'left'"
          [format]="24"
          [disabled]="false"
          [(ngModel)]="job.real_time_to"
          [ngModelOptions]="{ standalone: true }"
          class="item-full-width ngx-material-timepicker-toggle"
        >
        </ngx-timepicker-field>
      </div>
    </div>

    <div style="width: 100%" fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="32" class="mr-8">
        <mat-form-field style="width: 100%">
          <mat-label>{{ "ASSIGNMENTS.MEAL_INCLUDED" | translate }}</mat-label>
          <input
            matInput
            fxFlex="100%"
            formControlName="meal_included"
            type="text"
            autocomplete="nope"
            pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
            [(ngModel)]="job.meal_included"
            required
          />
        </mat-form-field>
      </div>

      <div fxFlex="31" class="mr-8 ml-8">
        <!-- <input matInput
                    fxFlex="100%"
                    placeholder="{{ 'ASSIGNMENTS.TIPS_AMOUNT' | translate }}"
                    [disabled]="!job.tips_enabled"
                    formControlName="tips_amount"
                    type="text"
                    autocomplete="nope"
                    pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
                    [(ngModel)]="job.tips_amount"> -->

        <mat-form-field *ngIf="job.tips_enabled" style="width: 100%">
          <mat-label>{{ "ASSIGNMENTS.TIPS_AMOUNT" | translate }}</mat-label>
          <input
            matInput
            fxFlex="100%"
            formControlName="tips_amount"
            [disabled]="!job.tips_enabled"
            type="text"
            autocomplete="nope"
            pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
            [(ngModel)]="job.tips_amount"
          />
        </mat-form-field>

        <mat-form-field *ngIf="!job.tips_enabled" style="width: 100%">
          <mat-label>{{ "ASSIGNMENTS.NO_TIPS" | translate }}</mat-label>
          <input
            matInput
            fxFlex="100%"
            [disabled]="true"
            type="text"
            autocomplete="nope"
            pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
          />
        </mat-form-field>
        <!-- <div *ngIf="!job.tips_enabled">
                
            </div> -->
      </div>

      <div fxFlex="31" class="ml-8">
        <mat-form-field style="width: 100%">
          <mat-label>{{ "ASSIGNMENTS.HOURLY_RATE" | translate }}</mat-label>
          <input
            matInput
            fxFlex="100%"
            formControlName="hourly_wage"
            type="text"
            autocomplete="nope"
            pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
            [(ngModel)]="job.hourly_wage"
            required
          />
        </mat-form-field>
      </div>
    </div>
  </div>

  <app-rich-text-locale
    [richText]="job"
    [localKey]="'note_instruction'"
    [charCountLocal]="'ASSIGNMENTS.CHAR_COUNT_MESSAGE'"
    [applyFocus]="true"
    [maxLength]="2048"
    [editMode]="true"
    [showClearButton]="false"
    [showSaveButton]="false"
    [allowColors]="false"
    style="width: 100%"
  >
  </app-rich-text-locale>

  <div
    mat-dialog-actions
    [formGroup]="form"
    class="mt-24 gt2-buttons-theme"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button
      mat-raised-button
      color="accent"
      class="mr-16"
      type="submit"
      [disabled]="!form.valid"
      (click)="onSaveJob()"
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>

    <button
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(false)"
      class=""
    >
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
