export const locale = {
  lang: 'en-CA',
  data: {
    VENUES: {
      VENUES: 'Places',
      VENUE: 'Place',
      DELETE_VENUES: 'Delete place',
      DELETE_CONFIRM: 'Êtes vous sûr de vouloir effacer cette place?',
      DELETE_SUCCESS: 'Place effacé avec Succès',
      INFORMATION: 'Information',
      NAME: 'Name',
      YES: 'Yes',
      CONTACT: 'Contact',
      GENERAL: 'General',
      ADDRESS: 'Address',
      EMAIL: 'Email',
      PHONE: 'Telephone',
      NOTES: 'Notes',
      ORG_EMAIL: 'Professional email',
      MOBILE: 'Mobile',
      OFFICE_PHONE: 'Office phone',
      OFFICE_EXT: 'Ext',
      PHONE_OTHER: 'Phone other',
      FAX: 'Fax',
      SKYPE: 'Skype',
      NO_CONTACT_INFO: 'No contact informations.',
      EMAIL_CC: 'Email CC',
      ROOMS: 'Rooms',
      CONTACT_NAME: 'Contact name',
      INTERNAL: 'Internal place',
      SEARCH_ADDRESS: 'Search an address',
      SEE_ON_GOOGLE_MAP: 'See Google Map',
      NO_GEO_LOCATION: 'No geo location permission granted.',
      ADDRESS_NUMBER: 'Address number',
      STREET: 'Street',
      APP_NUMBER: 'App #',
      LINE_2: 'Address line 2',
      CITY: 'City',
      PROVINCE: 'Province/State',
      COUNTRY: 'Country',
      POSTAL_CODE: 'Postal/Zip Code',
      SECTOR: 'Sector',
      NO_ADDRESS_INFO: 'No address information',
      ON_SITE_CONTACT: 'Delivery and contact person on site',
      CREATED_ON: 'Created on',
      BY: 'by',
      LAST_MODIFICATION: 'Last modification on',
      DELETE: 'Delete',
    },
  },
};
