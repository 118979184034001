import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { GT2CalendarUtils } from './../../../../../utils/GT2CalendarUtils';
import { GT2DateAdapter } from './../../../../../utils/GT2DateAdapter';
import { ValidatorHelper } from './../../../../../validators/ValidatorHelper';
import { JobsFunctionService } from './../../../../../api/jobs-function.service';
import { JobFunctionsModel } from './../../../../../api/models/JobFunctionsModel';
import { Settings } from '../../../../../settings/settings';
import { GT2PageAbstract } from '../../../../../content/abstract/GT2PageAbstract';
import { NGXLogger } from 'ngx-logger';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DateAdapter, MatOptionModule } from '@angular/material/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';

import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { AssignementsService } from '../assignements.service';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { RichTextLocaleComponent } from '../../../../../components/rich-text-locale/rich-text-locale.component';
import { NumericDirectiveModule } from '../../../../../modules/numeric.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { CustomDateFormatter } from '../../../../calendar/CustomDateFormatter';
import { CalendarDateFormatter } from 'angular-calendar';

@Component({
  selector: 'app-create-group-modal',
  templateUrl: './create-group-modal.component.html',
  styleUrls: ['./create-group-modal.component.scss'],
  standalone: true,
  imports: [
    NgxSpinnerModule,
    CommonModule,
    NumericDirectiveModule,
    TranslateModule,
    NgxMaterialTimepickerModule,
    RichTextLocaleComponent,
    MatInputModule,
    FlexLayoutModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDialogModule,
    MatDatepickerModule,
    MatButtonModule,
    MatSelectModule,
    MatOptionModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
  ],
  providers: [
    { provide: DateAdapter, useClass: GT2DateAdapter },
    { provide: CalendarDateFormatter, useClass: CustomDateFormatter },
  ],
})
export class CreateGroupModalComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  editMode: boolean = false;
  name!: string;
  eventUUDID!: string;
  form: UntypedFormGroup | any;
  loading: boolean = false;
  group: any;
  groupAll: any;
  groupUUID!: string;
  jobsFunction?: JobFunctionsModel[];
  selectedJobsFunction?: JobFunctionsModel | any;
  startDate?: string | null = null;
  startTime?: string | null = null;
  endDate?: string | null = null;
  endTime?: string | null = null;

  constructor(
    public override dialogRef: MatDialogRef<CreateGroupModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    private formBuilder: UntypedFormBuilder,
    public router: Router,
    private dialog: MatDialog,
    private jobsFunctionService: JobsFunctionService,
    public assignmentsService: AssignementsService,
    private adapter: DateAdapter<any>,
    private spinner: NgxSpinnerService,
    private logger: NGXLogger,
  ) {
    super();
    this.adapter.setLocale(this.translate.currentLang);

    // //this.logger.info("CreateGroupModalComponent.constructor() -> data.group: " + JSON.stringify(data.group));
    if (data && data.group) {
      this.groupAll = data.group;
      this.group = data.group.details;
      this.group.name = data.group.name;
      this.groupUUID = data.group.uuid;
      this.editMode = true;
      //this.logger.info("CreateGroupModalComponent.constructor() -> this.group: " + JSON.stringify(this.group));
    }

    if (data && data.name) {
      this.name = data.name;
    }

    if (data && data.eventUUDID) {
      this.eventUUDID = data.eventUUDID;
    }

    if (data && data.startDate) {
      this.startDate = data.startDate;
    }

    if (data && data.startTime) {
      this.startTime = data.startTime;
    }

    if (data && data.endDate) {
      this.endDate = data.endDate;
    }

    if (data && data.endTime) {
      this.endTime = data.endTime;
    }

    this.jobsFunctionService.geList().subscribe({
      next: (response: any) => {
        // //this.logger.info("AddJobsFunctionDialogComponent.jobsFunctionService.getAll() -> SUCCESS: " + JSON.stringify(response));
        this.jobsFunction = response.data;

        if (this.editMode && this.groupAll.job_function) {
          this.selectedJobsFunction = this.jobsFunction?.find((item) => {
            return item.uuid === this.groupAll.job_function.uuid;
          });
          // this.group.job_function_uuid = event.value.uuid;

          if (this.editMode && this.selectedJobsFunction) {
            this.group.job_function_uuid = this.selectedJobsFunction.uuid;
          }
          // //this.logger.info("CreateGroupModalComponent.constructor() -> this.selectedJobsFunction: " + JSON.stringify(this.selectedJobsFunction));
          // //this.logger.info("CreateGroupModalComponent.constructor() 2 -> this.group: " + JSON.stringify(this.group));
        }
      },
      error: (error: any) => {
        this.logger.error(
          'AddJobsFunctionDialogComponent.jobsFunctionService.getAll() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  ngOnInit(): void {
    if (!this.editMode) {
      this.group = {
        job_function_uuid: null,
        name: this.name,
        requested_all_day: false,
        requested_date_from: this.startDate,
        requested_date_to: this.startDate,
        requested_time_from: this.startTime,
        requested_time_to: this.startTime,
        meal_included: 1,
        tips_enabled: true,
        billable: true,
        job_count: 1,
        note: '',
      };

      this.form = this.formBuilder.group({
        name: [
          '',
          [
            Validators.minLength(Settings.inputMinChar),
            Validators.maxLength(Settings.inputMaxCharSmall),
            Validators.required,
          ],
        ],
        // name: ['', [Validators.minLength(Settings.inputMinChar), Validators.maxLength(Settings.inputMaxCharMid), Validators.required]],
        date_from: ['', [ValidatorHelper.dateRangeDefault]],
        date_to: ['', [ValidatorHelper.dateRangeDefault]],
        meal_included: [
          'meal_included',
          [Validators.required, ValidatorHelper.isNumber0To1000Inclusively],
        ],
        job_count: ['', [Validators.required, ValidatorHelper.isNumber0To50]],
      });
    } else {
      this.form = this.formBuilder.group({
        name: [
          '',
          [
            Validators.minLength(Settings.inputMinChar),
            Validators.maxLength(Settings.inputMaxCharSmall),
            Validators.required,
          ],
        ],
        // name: ['', [Validators.minLength(Settings.inputMinChar), Validators.maxLength(Settings.inputMaxCharMid), Validators.required]],
        date_from: ['', [ValidatorHelper.dateRangeDefault]],
        date_to: ['', [ValidatorHelper.dateRangeDefault]],
        meal_included: [
          'meal_included',
          [Validators.required, ValidatorHelper.isNumber0To1000Inclusively],
        ],
        // job_count: ['', [Validators.required]],
      });
    }
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  public onJobsChange(event: any): void {
    this.group.job_function_uuid = event.value.uuid;
    // //this.logger.info("CreateGroupModalComponent.onJobsChange() -> event: " + JSON.stringify(event.value));
    // //this.logger.info("CreateGroupModalComponent.onJobsChange() -> this.group.job_function_uuid: " + this.group.job_function_uuid);
  }

  public onCreateGroup(): void {
    this.spinner.show('assignmentSpiner');
    //this.logger.info("CreateGroupModalComponent.onCreateGroup() -> this.group: " + JSON.stringify(this.group));
    this.group.requested_date_from = GT2CalendarUtils.formatDateForAPI(
      this.group.requested_date_from,
    );
    this.group.requested_date_to = GT2CalendarUtils.formatDateForAPI(
      this.group.requested_date_to,
    );
    //this.logger.info("CreateGroupModalComponent.onCreateGroup() -> this.group: " + JSON.stringify(this.group));

    if (!this.editMode) {
      this.assignmentsService
        .createGroupAssigment(this.eventUUDID, this.group)
        .subscribe({
          next: (response: any) => {
            //this.logger.info("CreateGroupModalComponent.onCreateGroup().createGroupAssigment() -> SUCCESS: " + JSON.stringify(response));
            this.dialogRef.close(response);
            this.spinner.hide('assignmentSpiner');
          },
          error: (error: any) => {
            this.logger.error(
              'CreateGroupModalComponent.onCreateGroup().createGroupAssigment() -> ERROR: ' +
                JSON.stringify(error),
            );
            this.handleAPIError(error, this.dialog, null, null);
            this.spinner.hide('assignmentSpiner');
          },
        });
    } else {
      //this.logger.info("CreateGroupModalComponent.onCreateGroup() ELSE !!! -> this.group: " + JSON.stringify(this.group));
      this.assignmentsService
        .editGroupAssigment(this.groupUUID, this.group)
        .subscribe({
          next: (response: any) => {
            //this.logger.info("CreateGroupModalComponent.onCreateGroup().createGroupAssigment() -> SUCCESS: " + JSON.stringify(response));
            this.dialogRef.close(response);
            this.spinner.hide('assignmentSpiner');
          },
          error: (error: any) => {
            this.logger.error(
              'CreateGroupModalComponent.onCreateGroup().createGroupAssigment() -> ERROR: ' +
                JSON.stringify(error),
            );
            this.handleAPIError(error, this.dialog, null, null);
            this.spinner.hide('assignmentSpiner');
          },
        });
    }
  }
}
