import { NotesService } from './../../../../api/notes.service';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Editor, EditorModule } from 'primeng/editor';
import { NGXLogger } from 'ngx-logger';
import { MatDialog } from '@angular/material/dialog';
import { GT2PageAbstract } from '../../../../content/abstract/GT2PageAbstract';
import moment from 'moment';
import { debounceTime } from 'rxjs/operators';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-dashboard-note',
  templateUrl: './dashboard-note.component.html',
  styleUrls: ['./dashboard-note.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    EditorModule,
    MatInputModule,
    FlexLayoutModule,
    MatButtonModule,
    MatFormFieldModule,
    FormsModule,
    TranslateModule,
  ],
})
export class DashboardNoteComponent
  extends GT2PageAbstract
  implements OnInit, OnChanges
{
  @ViewChild('noteEditor') noteEditor?: Editor;
  @Input() date: any;
  @Input() dashboardJul?: string;
  note: string = '';
  noteControl: UntypedFormControl = new UntypedFormControl('');

  constructor(
    private logger: NGXLogger,
    private notesService: NotesService,
    public dialog: MatDialog,
  ) {
    super();
    //
    this.noteControl = new UntypedFormControl();
    this.noteControl.valueChanges.pipe(debounceTime(1000)).subscribe({
      next: (res) => {
        const formatedDate = moment(this.date.date).format('YYYY-MM-DD');
        //
        // TODO: Allow save of empty note, kind of a delete
        if (res && res != '') {
          this.updateNoteByDate(formatedDate, res);
        } else if (res == null) {
          //??? WHY - Martin -- this.updateNoteByDate(formatedDate, "");
        }
      },
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const chng = changes['date'];

    this.getNoteByDate(chng.currentValue.date);
  }

  ngOnInit() {}

  public onNoteChange(event: any): void {}

  public getNoteByDate(date: string): void {
    const formatedDate = moment(date).format('YYYY-MM-DD');
    this.notesService.getNoteByDate(formatedDate).subscribe({
      next: (response: any) => {
        if (response != null) {
          //this.note = response.data.note;
          this.noteControl.patchValue(response.data.note, { emitEvent: false });
        } else {
          this.note = '';
        }
      },
      error: (error: any) => {
        this.logger.error(
          'DashboardNoteComponent.getNoteByDate() -> NotesService ERRORS: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public updateNoteByDate(date: string, note: string): void {
    this.notesService.updateNote(date, note).subscribe({
      next: (response: any) => {
        //this.note = response.data.note;
      },
      error: (error: any) => {
        this.logger.error(
          'DashboardNoteComponent.onNoteChange() -> NotesService ERRORS: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }
}
