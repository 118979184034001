import { NgModule } from '@angular/core';
import { InvoiceComposeCTService } from './../../api/invoice-compose-ct.service';
import { ProposalComposeCTService } from './../../api/proposal-compose-ct.service';
import { EventAttachedFilesComponent } from '../events/event/event-attached-files/event-attached-files.component';
import { ReportButtonComponent } from './../../reports/report-button/report-button.component';
import { ReportViewerComponent } from './../../reports/report-viewer/report-viewer.component';
import { MenuReportsService } from './../../reports/menu-reports.service';
import { ReportFactoryService } from './../../reports/report-factory.service';
import { ReportsService } from './../../reports/reports.service';
import { ConseillersService } from './../../api/conseillers.service';
import { RouterModule } from '@angular/router';
import { FuseSidebarModule } from '../../../../@fuse/components';
import { SpecificConditionService } from '../../api/specific-condision.service';
import { WrappingService } from '../../api/wrapping.service';
import { AddServiceChargeComponent } from '../../components/dialogs/add-service-charge/add-service-charge.component';
import { AddSpecificConditionComponent } from '../../components/dialogs/add-specific-condition/add-specific-condition.component';
import { DragDropListsComponent } from '../../components/dialogs/drag-drop-lists/drag-drop-lists.component';
import { Gt2SelectComponent } from '../../components/gt2-select/gt2-select.component';
import { OrderMaterialDndComponent } from '../../components/order-material-dnd/order-material-dnd.component';
import { OrderProductDndComponent } from '../../components/order-product-dnd/order-product-dnd.component';
import { SectionHistoryPanelComponent } from '../../components/section-history-panel/section-history-panel.component';
import { TagsByModelComponent } from '../../components/tags-by-model/tags-by-model.component';
import { OrderComponent } from './order.component';
import { ProposalComposeService } from '../../api/proposal-compose.service';
import { ReceiptNoticeComposeService } from '../../api/receipt-notice-compose.service';
import { OrderComposeService } from '../../api/order-compose.service';
import { InvoiceComposeService } from '../../api/invoice-compose.service';
import { MailComposeDialogComponent } from '../compose/compose.component';
import { CreateLabourChargeComponent } from '../../components/dialogs/create-labour-charge/create-labour-charge.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { CatererService } from '../caterer/caterer.service';
import { AlertDrinksValidationComponent } from '../../components/dialogs/alert-drinks-validation-dialog/alert-drinks-validation-dialog.component';
import { Select2Module } from 'ng-select2-component';
import { InvoiceComposeGlobalPaymentsService } from '../../api/invoice-compose-global-payments.service';
import { ReceiptNoticeSellOnlyComposeService } from '../../api/receipt-notice-sell-only-compose.service';
import { StorageNoticeComposeService } from '../../api/storage-notice-compose.service';
import { CreatedUpdatedAtComponent } from '../../components/created-updated-at/created-updated-at.component';
import { CommentSectionComponent } from '../../components/comment-section/comment-section.component';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { ErrorDialogComponent } from '../../components/dialogs/error-dialog/error-dialog.component';
import { AppRoutesName } from '../../const/AppRoutesName';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EditorModule } from 'primeng/editor';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { NumberNotInfinityPipeModule } from '../../modules/number-not-infinity.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { GoogleLinkPipeModule } from '../../modules/google-link.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MatCheckboxModule } from '@angular/material/checkbox';

const routes = [
  {
    path: AppRoutesName.FR.ORDER,
    component: OrderComponent,
  },
  {
    path: AppRoutesName.FR.PROPOSITION,
    component: OrderComponent,
  },
  {
    path: AppRoutesName.FR.INVOICE,
    component: OrderComponent,
  },
  {
    path: AppRoutesName.EN.ORDER,
    component: OrderComponent,
  },
  {
    path: AppRoutesName.EN.PROPOSITION,
    component: OrderComponent,
  },
  {
    path: AppRoutesName.EN.INVOICE,
    component: OrderComponent,
  },
];

@NgModule({
  declarations: [OrderComponent],
  imports: [
    FormsModule,
    AddSpecificConditionComponent,
    MatSelectModule,
    MatChipsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NumberNotInfinityPipeModule,
    MatDatepickerModule,
    MatOptionModule,
    AddServiceChargeComponent,
    GoogleLinkPipeModule,
    AlertDrinksValidationComponent,
    MatTabsModule,
    MatTableModule,
    MatProgressSpinnerModule,
    FuseDirectivesModule,
    FusePipesModule,
    ErrorDialogComponent,
    MatInputModule,
    EditorModule,
    NgxPermissionsModule,
    MatButtonModule,
    MatCheckboxModule,
    TranslateModule,
    RouterModule.forChild(routes),
    FuseSidebarModule,
    CommentSectionComponent,
    CreatedUpdatedAtComponent,
    EventAttachedFilesComponent,
    ReportButtonComponent,
    ReportViewerComponent,
    DragDropListsComponent,
    CommonModule,
    MatMenuModule,
    MatTooltipModule,
    Gt2SelectComponent,
    OrderMaterialDndComponent,
    OrderProductDndComponent,
    SectionHistoryPanelComponent,
    TagsByModelComponent,
    CreateLabourChargeComponent,
    MailComposeDialogComponent,
    NgxMaterialTimepickerModule,
    Select2Module,
    MatIconModule,
  ],
  exports: [OrderComponent],
  providers: [
    WrappingService,
    SpecificConditionService,
    ProposalComposeService,
    ProposalComposeCTService,
    OrderComposeService,
    InvoiceComposeService,
    InvoiceComposeCTService,
    InvoiceComposeGlobalPaymentsService,
    CatererService,
    ConseillersService,
    ReportsService,
    ReportFactoryService,
    MenuReportsService,
    ReceiptNoticeComposeService,
    ReceiptNoticeSellOnlyComposeService,
    StorageNoticeComposeService,
  ],
})
export class OrdersModule {}
