import { CommonModule, Location } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from '../../../../../../@fuse/services/translation-loader.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GT2PageAbstract } from '../../../../content/abstract/GT2PageAbstract';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Settings } from '../../../../settings/settings';
import {
  Option,
  UpsaleService,
  OptionGroup,
} from '../../../../api/upsale.service';
import { CreateUpsaleOptionsComponent } from '../create-upsale-options/create-upsale-options.component';
import { CreateUpsaleMaterialsComponent } from '../create-upsale-materials/create-upsale-materials.component';
import { GT2ConstantsEnum } from '../../../../const/GT2ConstantsEnum';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';
@Component({
  selector: 'app-create-options-group',
  templateUrl: './create-options-group.component.html',
  styleUrls: ['./create-options-group.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    MatButtonModule,
    MatInputModule,
    FlexLayoutModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
  ],
})
export class CreateOptionsGroupComponent
  extends GT2PageAbstract
  implements OnInit
{
  questionUUID?: string;
  question: any;
  groups?: OptionGroup[];
  options?: Option[];
  form: UntypedFormGroup;
  group_name?: string;
  loading: boolean = false;
  constructor(
    public override dialogRef: MatDialogRef<CreateOptionsGroupComponent> | null,
    private translationLoader: FuseTranslationLoaderService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public router: Router,
    public location: Location,
    private toastr: ToastrService,
    private logger: NGXLogger,
    public upsaleService: UpsaleService,
  ) {
    super();
    if (data && data.question) {
      this.question = data.question;
    }

    this.form = this.formBuilder.group({
      group_name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
    });
  }

  ngOnInit() {}

  public onCreateMaterialsGroup() {
    //this.logger.info("CreateOptionsGroupComponent.onCreateMaterialsGroup");
    const dialogRef: any = this.dialog.open(CreateUpsaleMaterialsComponent, {
      width: '94%',
      minWidth: 350,
      disableClose: false,
      panelClass: 'custom-dialog-container',
      data: {
        question: this.question,
        questionUUID: this.question.uuid,
        name: this.group_name,
        type: GT2ConstantsEnum.GROUP_MATERIALS,
        mode: 'CREATE',
      },
    });
    dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          //
          //this.logger.info("onCreateMaterialsGroup>> results>>" + JSON.stringify(result));
          this.dialogRef?.close(result);
        }
        this.dialogRef = null;
      },
    });
  }

  public onCreateOptionsGroup() {
    //this.logger.info("CreateOptionsGroupComponent.onCreateOptionsGroup");
    var dialogRef: any = this.dialog.open(CreateUpsaleOptionsComponent, {
      width: '94%',
      minWidth: 350,
      disableClose: false,
      panelClass: 'custom-dialog-container',
      data: {
        questionUUID: this.question.uuid,
        name: this.group_name,
        type: GT2ConstantsEnum.GROUP_PRODUCTS,
        mode: 'CREATE',
      },
    });
    dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          //
          ////this.logger.info("onCreateOptionsGroup>> results>>" + JSON.stringify(result));
          this.dialogRef?.close(result);
        }
        dialogRef = null;
      },
    });
  }
}
