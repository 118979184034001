import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { OrdersService } from '../../../api/orders.service';
import { NGXLogger } from 'ngx-logger';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';

import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { WrappingService } from '../../../api/wrapping.service';
import { SpecificConditionService } from '../../../api/specific-condision.service';
import { ProposalComposeService } from '../../../api/proposal-compose.service';
import { ProposalComposeCTService } from '../../../api/proposal-compose-ct.service';
import { OrderComposeService } from '../../../api/order-compose.service';
import { InvoiceComposeService } from '../../../api/invoice-compose.service';
import { InvoiceComposeCTService } from '../../../api/invoice-compose-ct.service';
import { InvoiceComposeGlobalPaymentsService } from '../../../api/invoice-compose-global-payments.service';
import { CatererService } from '../../../content/caterer/caterer.service';
import { ConseillersService } from '../../../api/conseillers.service';
import { ReportsService } from '../../../reports/reports.service';
import { ReportFactoryService } from '../../../reports/report-factory.service';
import { MenuReportsService } from '../../../reports/menu-reports.service';
import { ReceiptNoticeComposeService } from '../../../api/receipt-notice-compose.service';
import { ReceiptNoticeSellOnlyComposeService } from '../../../api/receipt-notice-sell-only-compose.service';
import { StorageNoticeComposeService } from '../../../api/storage-notice-compose.service';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-alert-drinks-validation-dialog',
  templateUrl: './alert-drinks-validation-dialog.component.html',
  standalone: true,
  styleUrls: ['./alert-drinks-validation-dialog.component.scss'],
  imports: [
    TranslateModule,
    MatButtonModule,
    FormsModule,
    MatTableModule,
    CommonModule,
    MatFormFieldModule,
    MatCheckboxModule,
    FlexLayoutModule,
    MatInputModule,
  ],
  providers: [
    WrappingService,
    SpecificConditionService,
    ProposalComposeService,
    ProposalComposeCTService,
    OrderComposeService,
    InvoiceComposeService,
    InvoiceComposeCTService,
    InvoiceComposeGlobalPaymentsService,
    CatererService,
    ConseillersService,
    ReportsService,
    ReportFactoryService,
    MenuReportsService,
    ReceiptNoticeComposeService,
    ReceiptNoticeSellOnlyComposeService,
    StorageNoticeComposeService,
  ],
})
export class AlertDrinksValidationComponent
  extends GT2PageAbstract
  implements OnInit, OnDestroy
{
  selectedDrinks: any[] = [];
  initialqtys: number[] = [];
  displayedColumns: string[] = ['name', 'intial-qty', 'real-qty', 'confirmed'];
  allDrinksChecked: boolean = false;
  drinksChecked: any = {};
  saveDisabled: boolean = true;
  orderUUID!: string;
  loading: boolean = false;

  constructor(
    public override dialogRef: MatDialogRef<AlertDrinksValidationComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private logger: NGXLogger,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public ordersService: OrdersService,
    public dialog: MatDialog,
  ) {
    super();

    this.translationLoader.loadTranslations(english, french);
    if (data && data.selectedDrinks !== null) {
      this.selectedDrinks = data.selectedDrinks;
      this.orderUUID = data.orderUUID;
    }
    //this.logger.info("initial qantities" + JSON.stringify(this.selectedDrinks[0]));
    for (let drink of this.selectedDrinks) {
      this.initialqtys.push(drink.qty);
    }
  }

  ngOnInit() {}
  ngOnDestroy() {}

  public onConfirmed(event: any, element: any): void {
    this.drinksChecked[element.slug] = event.checked ? 1 : 0;
    //this.logger.info("drinks checked" + JSON.stringify(this.drinksChecked));
    //this.logger.info("all drinks checked : " + this.allDrinksChecked);
    this.allDrinksChecked = this.validateDrinks();
  }

  public closeDialog(): void {
    this.dialogRef.close(false);
  }

  public updateDrinks(): void {
    this.loading = true;
    if (!this.saveDisabled && this.allDrinksChecked) {
      this.ordersService
        .updateProducts(this.orderUUID, this.selectedDrinks)
        .subscribe({
          next: (response: any) => {
            this.loading = false;
            this.dialogRef.close(true);
          },
          error: (error: any) => {
            this.loading = false;
            this.handleAPIError(error, this.dialog, null, null);
          },
        });
    } else {
      if (this.allDrinksChecked) {
        this.dialogRef.close(true);
      }
    }
  }

  public modelRealQtyChange(): void {
    this.saveDisabled = false;
  }

  public validateDrinks(): boolean {
    if (Object.keys(this.drinksChecked).length < this.selectedDrinks.length) {
      return false;
    } else {
      for (let drink in this.drinksChecked) {
        if (this.drinksChecked[drink] == 0) {
          return false;
        }
      }
    }
    return true;
  }
}
