import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class DateUtilsService {
  constructor() {}

  // Méthode pour formater la date au format YYYY-MM-DD
  formatDate(date: Date | moment.Moment): string {
    return moment(date).format('YYYY-MM-DD');
  }

  // Méthode pour formater l'heure au format hh:mm
  formatTime(date: Date | moment.Moment): string {
    return moment(date).format('HH:mm');
  }
}
