import { GT2CalendarUtils } from '../utils/GT2CalendarUtils';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ApiRoutes } from './ApiRoutes';
import { Gt2ApiService } from './gt2-api.service';

@Injectable()
export class CongressesService {
  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private api: Gt2ApiService,
  ) {}

  public getCongressesWithPagination(
    sort: string,
    direction: string,
    page: number,
    pageSize: number,
    filterValue: string,
    fromDate: string,
    toDate: string,
    includeCancelled: boolean,
  ): Promise<any> {
    const routeURL: string = this.api.createUrl(ApiRoutes.CONGRESSES) + '?';
    let requestUrl: string;
    if (!sort || direction === '') {
      requestUrl = `${routeURL}page=${page + 1}&limit=${pageSize}`;
    } else {
      requestUrl = `${routeURL}orderBy=${sort}&sortedBy=${direction}&page=${
        page + 1
      }&limit=${pageSize}`;
    }

    // Remove special caracters from the filter value for proper searching
    filterValue = filterValue.replace(/%/g, '');

    if (filterValue !== '') {
      requestUrl += '&search=' + filterValue;
    }

    if (fromDate !== '') {
      requestUrl +=
        '&date_start=' + GT2CalendarUtils.formatDateForAPI(fromDate);
    }
    if (toDate !== '') {
      requestUrl += '&date_end=' + GT2CalendarUtils.formatDateForAPI(toDate);
    }
    if (includeCancelled !== null) {
      requestUrl += '&show_canceled=' + includeCancelled;
    }

    // this.logger.info("getProductsWithPagination() -> routeURL: " + routeURL);
    // this.logger.info("getProductsWithPagination() -> requestUrl: " + requestUrl);

    return new Promise((resolve, reject) => {
      this.http.get(requestUrl).subscribe({
        next: (response: any) => {
          // this.logger.info("CongressesService.getProductsWithPagination() -> this.employees: " + JSON.stringify(response.data));
          resolve(response);
        },
        error: reject,
      });
    });
  }

  public getCongress(uuid: any): any {
    //this.logger.info("CongressesService.getCongress() -> uuid: " + uuid);
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.CONGRESSES) + '/' + uuid,
    );
  }

  public updateCongress(congress: CongressModel | any): any {
    // const uuid = congress.uuid;
    if (congress.date_start) {
      congress.date_start = GT2CalendarUtils.formatDateForAPI(
        congress.date_start,
      );
    }

    if (congress.date_end) {
      congress.date_end = GT2CalendarUtils.formatDateForAPI(congress.date_end);
    }
    // delete unwanted key & values
    delete congress.object;
    delete congress.slug;
    //this.logger.info("CongressesService.updateCongress() -> congress: " + JSON.stringify(congress));
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.CONGRESSES) + '/' + congress.uuid,
      congress,
    );
  }

  public getCongressProjects(uuid: string): any {
    //this.logger.info("CongressesService.getCongressProjects() -> uuid: " + uuid);
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.CONGRESSES) + '/' + uuid + '/projects',
    );
  }
  public getCongressProjectsList(uuid: string): any {
    //this.logger.info("CongressesService.getCongressProjects() -> uuid: " + uuid);
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.CONGRESSES) + '/' + uuid + '/projects/list',
    );
  }

  public getCongressProjectsWithPagination(
    uuid: string,
    sort: string,
    direction: string,
    page: number,
    pageSize: number,
  ): Promise<any> {
    //this.logger.info("CongressesService.getCongressProjectsWithPagination() -> uuid: " + uuid);
    const routeURL: string =
      this.api.createUrl(ApiRoutes.CONGRESSES) + '/' + uuid + '/projects' + '?';
    let requestUrl: string;
    if (!sort || direction === '') {
      requestUrl = `${routeURL}page=${page + 1}&limit=${pageSize}`;
    } else {
      requestUrl = `${routeURL}orderBy=${sort}&sortedBy=${direction}&page=${
        page + 1
      }&limit=${pageSize}`;
    }
    //
    return new Promise((resolve, reject) => {
      this.http.get(requestUrl).subscribe({
        next: (response: any) => {
          // this.logger.info("CongressesService.getProductsWithPagination() -> this.employees: " + JSON.stringify(response.data));
          resolve(response);
        },
        error: reject,
      });
    });
  }

  public getClientsInvoices(uuid: any): any {
    //this.logger.info("CongressesService.getClientsInvoices() -> uuid: " + uuid);
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.CONGRESSES) + '/' + uuid + '/invoices',
    );
  }

  public getClientsInvoicessWithPagination(
    uuid: string,
    sort: string,
    direction: string,
    page: number,
    pageSize: number,
  ): Promise<any> {
    //this.logger.info("CongressesService.getClientsInvoicessWithPagination() -> uuid: " + uuid);
    const routeURL: string =
      this.api.createUrl(ApiRoutes.CONGRESSES) + '/' + uuid + '/invoices' + '?';
    let requestUrl: string;
    if (!sort || direction === '') {
      requestUrl = `${routeURL}page=${page + 1}&limit=${pageSize}`;
    } else {
      requestUrl = `${routeURL}orderBy=${sort}&sortedBy=${direction}&page=${
        page + 1
      }&limit=${pageSize}`;
    }

    // this.logger.info("getProductsWithPagination() -> routeURL: " + routeURL);
    // this.logger.info("getProductsWithPagination() -> requestUrl: " + requestUrl);

    return new Promise((resolve, reject) => {
      this.http.get(requestUrl).subscribe({
        next: (response: any) => {
          // this.logger.info("CongressesService.getProductsWithPagination() -> this.employees: " + JSON.stringify(response.data));
          resolve(response);
        },
        error: reject,
      });
    });
    // return this.http.get<any>(
    //     this.api.createUrl(ApiRoutes.CONGRESSES) + "/" + uuid + "/projects"
    // );
  }

  public createCongress(congress: CongressModel | any) {
    if (
      congress.date_start &&
      congress.date_start !== 'Invalid DateTime' &&
      congress.date_start !== 'Invalid Date'
    ) {
      congress.date_start = GT2CalendarUtils.formatDateForAPI(
        congress.date_start,
      );
    }

    if (
      congress.date_end &&
      congress.date_end !== 'Invalid DateTime' &&
      congress.date_end !== 'Invalid Date'
    ) {
      congress.date_end = GT2CalendarUtils.formatDateForAPI(congress.date_end);
    }

    //
    delete congress.canceled_at;
    delete congress.uuid;
    delete congress.slug;
    delete congress.object;
    //
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.CONGRESSES),
      congress,
    );
  }

  public cancelCongress(congressUUID: string) {
    return this.http.get<any>(
      this.api.createUrl(
        ApiRoutes.CONGRESS_CANCEL.replace('{{UUID}}', congressUUID),
      ),
    );
  }
}

export interface CongressesModel {
  data: CongressModel[];
}
//
export interface CongressModel {
  uuid: string;
  object: string;
  slug: string;
  name: string;
  congress_number: string;
  date_start: string;
  date_end: string;
  canceled_at: string;
}
//
export interface ProjectModel {
  uuid: string;
  name: string;
  slug: string;
  congress_id: string;
  project_number: string;
  date_start: string;
  date_end: string;
  canceled_at: string;
  congress: CongressModel;
  responsible_employee_id: number;
  responsible_employee: any;
  project_deposit: number;
}
