import { OrdersService } from './../../../api/orders.service';
import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DeliveryRequestContentService } from '../../../api/delivery-request-content.service';
import { DeliveryRequestTypeService } from '../../../api/delivery-request-type.service';
import { DepositRequestService } from '../../../api/deposit-request.service';
import { WrappingService } from '../../../api/wrapping.service';
import { GT2CalendarUtils } from '../../../utils/GT2CalendarUtils';
import { ValidatorHelper } from '../../../validators/ValidatorHelper';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { GT2DateAdapter } from '../../../utils/GT2DateAdapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CustomDateFormatter } from '../../../content/calendar/CustomDateFormatter';
import { CalendarDateFormatter } from 'angular-calendar';

@Component({
  selector: 'app-create-deposit-request',
  templateUrl: './create-deposit-request.component.html',
  styleUrls: ['./create-deposit-request.component.scss'],
  standalone: true,
  imports: [
    MatDatepickerModule,
    CommonModule,
    FormsModule,
    MatInputModule,
    FlexLayoutModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    TranslateModule,
    MatFormFieldModule,
  ],
  providers: [
    { provide: DateAdapter, useClass: GT2DateAdapter },
    { provide: CalendarDateFormatter, useClass: CustomDateFormatter },
  ],
})
export class CreateDepositRequestComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  static CREATE: string = 'CREATE';
  static EDIT: string = 'EDIT';

  //
  mode: string = CreateDepositRequestComponent.CREATE;
  form: UntypedFormGroup;
  depositRequest: any;
  eventUUID!: string;
  event: any;
  order: any;
  serviceCharge: any;
  // order: any;
  loading: boolean = false;
  autoRedirect: boolean = false;
  depositRequestToUpdate: any;
  todayDate: string;
  depositRequestPercent: number = 0;
  totalDepositRequestAmount: number = 0;

  constructor(
    public override dialogRef: MatDialogRef<CreateDepositRequestComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    public deliveryRequestTypeService: DeliveryRequestTypeService,
    public deliveryRequestContentService: DeliveryRequestContentService,
    public wrappingService: WrappingService,
    private depositRequestService: DepositRequestService,
    public dialog: MatDialog,
    public router: Router,
    private adapter: DateAdapter<any>,
    private toastr: ToastrService,
    private logger: NGXLogger,
    public ordersService: OrdersService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    this.adapter.setLocale(this.translate.currentLang);

    this.form = this.formBuilder.group({
      due_date: ['', []],
      amount: [
        '',
        [ValidatorHelper.isAboveZero, ValidatorHelper.isNumber0To1000000],
      ],
      percent: [],
    });

    this.depositRequest = {
      due_date: new Date().toISOString(),
      amount: null,
    };

    if (data && data.mode !== null) {
      this.mode = data.mode;
    }

    if (data && data.eventUUID !== null) {
      this.eventUUID = data.eventUUID;
    }

    if (data && data.event !== null) {
      this.event = data.event;
      this.totalDepositRequestAmount = this.event.service_charges_percent_total;
      this.loadOrder();
    }

    // if (data && data.order !== null) {
    //     this.order = data.order;
    //     // this.totalDepositRequestAmount = this.event.
    // }

    if (data && data.autoRedirect !== null) {
      this.autoRedirect = data.autoRedirect;
    }

    if (data && data.depositRequestToUpdate) {
      this.depositRequestToUpdate = data.depositRequestToUpdate;
      this.depositRequest = this.depositRequestToUpdate;
      this.onPriceSet(this.depositRequest.amount);
    }

    //
    this.todayDate = new Date().toISOString();
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public loadOrder(): void {
    let orderUUID: string | null | any = null;
    if (this.event && this.event.documents.order) {
      orderUUID = this.event.documents.order.uuid;
    } else if (this.event && this.event.documents.invoice) {
      orderUUID = this.event.documents.invoice.uuid;
    } else if (
      this.event &&
      this.event.documents.proposals &&
      this.event.documents.proposals.length > 0
    ) {
      orderUUID = this.event.documents.proposals[0].uuid;
    }

    this.loading = true;
    this.ordersService.getOrdersByUUID(orderUUID).subscribe({
      next: (response: any) => {
        //this.logger.info("CreateDepositRequestComponent.loadOrder() -> SUCCESS: " + JSON.stringify(response.data));
        this.order = response.data;
        this.serviceCharge = {
          has_food: false,
          has_beverage: false,
          has_material: false,
          has_service: false,
          has_delivery: false,
          has_service_charge: false,
          has_chambre: false,
        };
        this.loading = false;
      },
      error: (error: any) => {
        this.logger.error(
          'OrderComponent.loadData() -> ERROR: ' + JSON.stringify(error),
        );
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public onCreateDepositRequest() {
    this.loading = true;
    // //this.logger.info("CreateDepositRequestComponent.onCreateDepositRequest() -> deposit request: " + JSON.stringify(this.depositRequest));
    // //this.logger.info("CreateDepositRequestComponent.onCreateDepositRequest() -> typeof date: " + typeof this.depositRequest.due_date);

    if (typeof this.depositRequest.due_date !== 'string') {
      this.depositRequest.due_date = GT2CalendarUtils.formatDateForAPI(
        this.depositRequest.due_date,
      );
    }

    if (this.mode === CreateDepositRequestComponent.CREATE) {
      this.doCreateDepositRequest();
    } else if (this.mode === CreateDepositRequestComponent.EDIT) {
      this.updateDepositRequest();
    }
  }

  public doCreateDepositRequest() {
    // //this.logger.info("CreateDepositRequestComponent.doCreateDepositRequest() ");
    this.depositRequestService
      .createDepositRequest(this.depositRequest, this.eventUUID)
      .subscribe({
        next: (response) => {
          // //this.logger.info("CreateDepositRequestComponent.doCreateDepositRequest() -> SUCCESS: " + JSON.stringify(response));
          this.loading = false;
          this.dialogRef.close(response);
        },
        error: (error) => {
          this.logger.error(
            'CreateDepositRequestComponent.doCreateDepositRequest() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.GENERIC'),
          );
          // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
        },
      });
  }

  public updateDepositRequest() {
    // //this.logger.info("CreateDepositRequestComponent.updateDepositRequest() -> ***** UPDATE this.deliveryRequest: " + JSON.stringify(this.depositRequest));
    this.depositRequestService
      .updateDepositRequest(this.depositRequest, this.eventUUID)
      .subscribe({
        next: (response) => {
          // //this.logger.info("CreateDepositRequestComponent.updateDepositRequest() -> SUCCESS: " + JSON.stringify(response));
          this.loading = false;
          this.dialogRef.close(response);
        },
        error: (error) => {
          this.logger.error(
            'CreateDepositRequestComponent.updateDepositRequest() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.GENERIC'),
          );
          // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
        },
      });
  }

  public onPercentSet(): void {
    // //this.logger.info(
    //     "CreateDepositRequestComponent.onPercentSet() -> depositRequestPercent: " +
    //         this.depositRequestPercent
    // );
    // this.depositRequest.amount =
    //     this.totalDepositRequestAmount * (this.depositRequestPercent / 100);

    // //this.logger.info(
    //     "CreateDepositRequestComponent.onPercentSet() -> getRoomsSubtotal(): " +
    //         this.getRoomsSubtotal()
    // );

    // this.order.product_subtotal_raw;

    this.totalDepositRequestAmount = 0;
    if (this.serviceCharge.has_food) {
      this.totalDepositRequestAmount += parseFloat(
        this.order.product_subtotal_raw,
      );
    }

    // if (this.serviceCharge.has_beverage) {
    //     this.totalDepositRequestAmount += this.order.product_subtotal_raw;
    // }

    if (this.serviceCharge.has_material) {
      this.totalDepositRequestAmount += parseFloat(
        this.order.material_subtotal_raw,
      );
    }

    if (this.serviceCharge.has_service) {
      this.totalDepositRequestAmount += parseFloat(
        this.order.staff_subtotal_raw,
      );
    }

    if (this.serviceCharge.has_service_charge) {
      // //this.logger.info(
      //     "CreateDepositRequestComponent.onPercentSet() -> service_charge_subtotal_raw: " +
      //         parseFloat(this.order.service_charge_subtotal_raw)
      // );
      // //this.logger.info(
      //     "CreateDepositRequestComponent.onPercentSet() -> this.getRoomsSubtotal(): " +
      //         this.getRoomsSubtotal()
      // );
      this.totalDepositRequestAmount +=
        parseFloat(this.order.service_charge_subtotal_raw) -
        this.getRoomsSubtotal();
    }

    if (this.serviceCharge.has_chambre) {
      this.totalDepositRequestAmount += this.getRoomsSubtotal();
    }

    // if (this.serviceCharge.has_delivery) {
    //     this.totalDepositRequestAmount += parseFloat(
    //         this.order.staff_subtotal_raw
    //     );
    // }

    if (this.depositRequestPercent && this.depositRequestPercent > 0) {
      // this.depositRequestPercent
      this.depositRequest.amount =
        this.totalDepositRequestAmount * (this.depositRequestPercent / 100);
    }

    // if (this.depositRequest.amount > )
    this.depositRequest.amount = this.depositRequest.amount.toFixed(2);

    //
    //this.logger.info("CreateDepositRequestComponent.onPercentSet() -> new amount: " + this.totalDepositRequestAmount);
  }

  public getRoomsSubtotal(): number {
    let scChambre: number = 0;
    for (
      let i = 0;
      i < this.order.service_charges_multiply_date_range.length;
      ++i
    ) {
      //this.logger.info("CreateDepositRequestComponent.getRoomsSubtotal() -> I: " + JSON.stringify(this.order.service_charges_multiply_date_range[i]));
      scChambre +=
        this.order.service_charges_multiply_date_range[i]
          .sub_total_item_price_raw;
    }
    //this.logger.info("CreateDepositRequestComponent.getRoomsSubtotal() -> scChambre: " + scChambre);
    return scChambre;
  }

  public onPriceSet(amount: number): void {
    // //this.logger.info(
    //     "CreateDepositRequestComponent.onPriceSet() -> amount: " + amount
    // );
    // //this.logger.info(
    //     "CreateDepositRequestComponent.onPriceSet() -> percent of: " +
    //         this.event.footer_cashings
    // );
    // parseFloat().toFixed(2);
    // const num = (amount / this.totalDepositRequestAmount) * 100;
    // this.depositRequestPercent = num;
    // Math.round((num + Number.EPSILON) * 100) / 100;
  }
}
