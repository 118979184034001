import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { ApiRoutes } from '../../../api/ApiRoutes';
import { Gt2ApiService } from '../../../api/gt2-api.service';
import { OrganizationModel } from '../../../api/models/OrganizationModel';

@Injectable()
export class CrudOrganizationService {
  organizationMeObject: OrganizationModel | null = null;

  private organizationDataSource = new BehaviorSubject<any>({});
  organizationData = this.organizationDataSource.asObservable();
  orgObservable: Observable<any> | null = null;
  catererObs?: Observable<any> | null = null;
  catererData: any = null;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private api: Gt2ApiService,
  ) {}

  // --- ORGANIZATION ---
  private setOrganization(organization: any): void {
    this.orgObservable = null;
    this.organizationMeObject = organization;
    this.organizationDataSource.next(organization);
  }

  public getOrganizationMeObject(): any {
    return this.organizationMeObject;
  }
  public getOrganization(): Observable<OrganizationModel> {
    return this.organizationData;
  }

  public getAllOrganizations(): Observable<any> {
    //ORGANIZATIONS = "/v1/organizations"
    return this.http.get<any>(this.api.createUrl(ApiRoutes.ORGANIZATIONS));
  }
  public loadOrganizationMe(from: string) {
    if (!this.orgObservable) {
      this.logger.info('load Organization Me from: ' + from + ' call API');
      //GET_ORGANIZATION_ME = "/v1/organizations/me"
      this.orgObservable = this.http
        .get<any>(this.api.createUrl(ApiRoutes.GET_ORGANIZATION_ME))
        .pipe(
          tap((data) => this.setOrganization(data)),
          shareReplay(1),
        );
    } else {
      this.logger.info('Multiple call for load Organization Me from: ' + from);
    }
    return this.orgObservable;
  }

  public loadOrgByUUID(uuid: string) {
    //ORGANIZATION = "/v1/organizations/";
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.ORGANIZATION) + uuid,
    );
  }

  public updateOrganization(organization: any, orgUUID: string) {
    //UPDATE_ORGANIZATION = "/v1/organizations/"
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.UPDATE_ORGANIZATION) + orgUUID,
      organization,
    );
  }

  public getCatererMeObject() {
    if (this.catererData) {
      return this.catererData;
    }
    return null;
  }

  public loadCatererMe(from: string) {
    if (!this.catererObs) {
      this.logger.info('load Caterer Me from: ' + from + ' call API');
      this.catererObs = this.http
        .get<any>(this.api.createUrl(ApiRoutes.GET_CATERER))
        .pipe(
          tap((data) => this.resetCaterer(data)),
          shareReplay(1),
        );
    } else {
      this.logger.info('Multiple call for load Caterer Me from: ' + from);
    }
    return this.catererObs;
  }

  public loadCatererByUUID(uuid: string) {
    return this.http.get<any>(this.api.createUrl(ApiRoutes.CATERER) + uuid);
  }

  public updateCaterer(caterer: any, catererUUID: string) {
    //
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.UPDATE_CATERER) + catererUUID,
      caterer,
    );
  }

  private resetCaterer(caterer: any): void {
    this.catererData = caterer;
  }
  // --- CATERER ---

  public addLocale(locale: any, orgUUID: string) {
    const url: string = this.api
      .createUrl(ApiRoutes.ADD_ORGANIZATION_LOCALE)
      .replace('${ORG_UUID}', orgUUID)
      .replace('${LOCALE_UUID}', locale.uuid);
    return this.http.get<any>(url);
  }

  public deleteLocale(locale: any, orgUUID: string) {
    const url: string = this.api
      .createUrl(ApiRoutes.DELETE_ORGANIZATION_LOCALE)
      .replace('${ORG_UUID}', orgUUID)
      .replace('${LOCALE_UUID}', locale.uuid);
    return this.http.get<any>(url);
  }
}
