import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Gt2ApiService } from '../api/gt2-api.service';

@Injectable({
  providedIn: 'root',
})
export class IconsService {
  // FONT AWESOME
  static CLIENT: string = 'fa-user-circle';
  static MANAGEMENT: string = 'fa-clipboard';
  static COMBO: string = 'fa-object-group';
  static XLS: string = 'fa-table';
  static CSV: string = 'fa-file-csv';
  static ORG: string = 'fa-building';
  static SAME_TAB: string = 'fa-chevron-circle-right';
  static ASSIGNMENT_USER: string = 'fa-user-clock';
  static LANG: string = 'language';

  // static REPORT: string = "fa-file-invoice";

  // MATERIAL DESIGN
  static DARKMODE: string = 'brightness_4';
  static ORDER: string = 'assignment';
  static PROPOSAL: string = 'announcement';
  static TABLE: string = 'table';
  static INVOICE: string = 'monetization_on';
  static EVENT: string = 'calendar_today';
  static EMPLOYEE: string = 'person';
  static EMPLOYEES: string = 'group';
  static PROFILE: string = 'account_circle';
  static ROOM: string = 'room_service';
  static PLACE: string = 'place';
  static ENTERPRISE: string = 'business'; // also, "business_center" was used in some place, which is better?
  static BRANCH: string = 'card_travel';
  static PRODUCT: string = 'restaurant_menu';
  static MATERIAL: string = 'restaurant';
  static REPORT: string = 'insert_chart';
  static PDF: string = 'picture_as_pdf';
  static MAIL: string = 'mail';
  static EMAIL: string = 'mail';
  static SHOPPING: string = 'shopping_cart';
  static NEW_TAB: string = 'open_in_new';
  static COPY: string = 'content_copy';
  static CLONE: string = 'content_copy';
  static CLOSE: string = 'close';
  static EDIT: string = 'edit';
  static EDIT_FAB: string = 'mode_edit';
  static UNDO: string = 'undo';
  static DELETE: string = 'delete';
  static ADD: string = 'add';
  static ADD_OUTLINE: string = 'add_circle_outline';
  static CANCEL: string = 'cancel';
  static CHECK: string = 'check';
  static CIRCLE_CHECK: string = 'check_circle_outline';
  static TIME: string = 'access_time';
  static SETTINGS: string = 'settings';
  static HELP: string = 'help';
  static ZOOM_OUT_MAP: string = 'zoom_out_map';
  static PRINT: string = 'print';
  static WARNING: string = 'warning'; // <mat-icon class="bold" color="warn">{{ getIcon('WARNING') }}</mat-icon>
  static CATERER: string = 'account_balance';
  static QUICK: string = 'fast_forward';
  static SHOP: string = 'shop';
  static VISIBLILITY: string = 'visibility';
  static VISIBLILITY_OFF: string = 'visibility_off';
  static ATTACH_MONEY: string = 'attach_money';
  static MONEY_OFF: string = 'money_off';
  static LOCK_OPEN: string = 'lock_open';
  static LOCK: string = 'lock';
  static SAVE: string = 'save';
  static ASSIGNMENT: string = 'assignment';
  static UP: string = 'keyboard_arrow_up';
  static DOWN: string = 'keyboard_arrow_down';
  static INFO: string = 'info';
  static LINK_OFF: string = 'link_off';
  static PREVIEW: string = 'pageview';
  static FILE_DOWNLOAD: string = 'file_download';
  static IMAGE: string = 'image';
  static PROJECT: string = 'fact_check';
  static CONGRESS: string = 'groups';
  static ACTION: string = 'more_vert';
  static UPSALE: string = 'add_shopping_cart';
  static MAIL_GP: string = 'credit_card';

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private api: Gt2ApiService,
  ) {}

  static getIconStatic(iconName: string): string {
    // this.logger.info("IconsService.getIcon() -> iconName: " + iconName);
    if (IconsService[iconName.toUpperCase()]) {
      return IconsService[iconName.toUpperCase()];
    }
    console.error(
      'IconsService.getIcon() -> ERROR: NO_ICON for this name: ' + iconName,
    );
    return 'NO_ICON';
  }

  public getIcon(iconName: string): string {
    // this.logger.info("IconsService.getIcon() -> iconName: " + iconName);
    return IconsService.getIconStatic(iconName);
    // if (IconsService[iconName.toUpperCase()])
    // {
    //     return IconsService[iconName.toUpperCase()];
    // }
    // this.logger.error("IconsService.getIcon() -> ERROR: NO_ICON for this name: " + iconName);
    // return "NO_ICON";
  }
}
