import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocaleHelperService {
  lang?: string;
  name: string = 'name_fr_CA';
  description: string = 'description_fr_CA';

  constructor(
    private logger: NGXLogger,
    private translate: TranslateService,
  ) {
    this.translate.onLangChange.subscribe({
      next: (event: LangChangeEvent) => {
        // this.logger.info('LocaleHelperService.onLangChange() -> lang: ' + event.lang);
        this.setValues(event.lang);
      },
    });

    this.setValues(this.translate.currentLang);
  }

  public setValues(lang: string): void {
    this.lang = lang.replace('-', '_');
    this.name = 'name_' + this.lang;
    this.description = 'description_' + this.lang;
    // this.logger.info('LocaleHelperService.setValues() -> name: ' + this.name);
    // this.logger.info('LocaleHelperService.setValues() -> description: ' + this.description);
  }
}
