<div style="max-height: 100%; overflow: auto">
  <div mat-dialog-content>
    <div>
      <div class="center changelogs-dialog--title">
        {{ "CHANGELOGS_DIALOG.TITLE" | translate }}
      </div>
      <div class="note-section">
        <p-editor
          #noteEditor
          [(ngModel)]="log"
          [style]="{ height: '120px', width: '100%' }"
        >
          <ng-template pTemplate="header" class="">
            <span class="ql-formats">
              <select class="ql-font"></select>
              <select class="ql-size"></select>
            </span>

            <span class="ql-formats">
              <button class="ql-bold"></button>
              <button class="ql-italic"></button>
              <button class="ql-underline"></button>
              <button class="ql-strike"></button>
            </span>

            <span class="ql-formats">
              <select class="ql-color"></select>
              <select class="ql-background"></select>
            </span>

            <span class="ql-formats">
              <button class="ql-list" value="ordered"></button>
              <button class="ql-list" value="bullet"></button>
              <button class="ql-indent" value="-1"></button>
              <button class="ql-indent" value="+1"></button>
            </span>
          </ng-template>
        </p-editor>
      </div>
      <div
        class="changelogs-dialog--buttons"
        fxLayout="row"
        fxLayoutAlign="center start"
      >
        <button
          mat-raised-button
          color="accent"
          class="changelogs-dialog--save-button"
          (click)="OnSaveChangeLogs()"
        >
          {{ "GENERIC.SAVE" | translate }}
        </button>
        <button
          mat-raised-button
          (click)="dialogRef.close(false)"
          color="primary"
          class=""
        >
          {{ "GENERIC.CANCEL" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
