import { MaterialCategoryService } from '../../../api/material-category.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { OrdersService } from '../../../api/orders.service';
import { GT2PageAbstract } from '../../abstract/GT2PageAbstract';
import { Settings } from '../../../settings/settings';
import { ValidatorHelper } from '../../../validators/ValidatorHelper';
import { locale as english } from '../i18n/en-CA';
import { locale as french } from '../i18n/fr-CA';
import { Editor } from 'primeng/editor';
import { MaterialProviderService } from '../../../api/material-provider.service';
import { StorageNoticeComposeService } from '../../../api/storage-notice-compose.service';
import { ReceiptNoticeSellOnlyComposeService } from '../../../api/receipt-notice-sell-only-compose.service';
import { ReceiptNoticeComposeService } from '../../../api/receipt-notice-compose.service';
import { MenuReportsService } from '../../../reports/menu-reports.service';
import { ReportFactoryService } from '../../../reports/report-factory.service';
import { ReportsService } from '../../../reports/reports.service';
import { ConseillersService } from '../../../api/conseillers.service';
import { CatererService } from '../../caterer/caterer.service';
import { InvoiceComposeGlobalPaymentsService } from '../../../api/invoice-compose-global-payments.service';
import { InvoiceComposeCTService } from '../../../api/invoice-compose-ct.service';
import { InvoiceComposeService } from '../../../api/invoice-compose.service';
import { OrderComposeService } from '../../../api/order-compose.service';
import { ProposalComposeCTService } from '../../../api/proposal-compose-ct.service';
import { ProposalComposeService } from '../../../api/proposal-compose.service';
import { SpecificConditionService } from '../../../api/specific-condision.service';
import { WrappingService } from '../../../api/wrapping.service';
import { MatIconModule } from '@angular/material/icon';
import { Select2Module } from 'ng-select2-component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatMenuModule } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { FuseSidebarModule } from '../../../../../@fuse/components';
import { MatInputModule } from '@angular/material/input';
import { FusePipesModule } from '../../../../../@fuse/pipes/pipes.module';
import { FuseDirectivesModule } from '../../../../../@fuse/directives/directives';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { Gt2SelectComponent } from '../../../components/gt2-select/gt2-select.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-edit-material',
  templateUrl: './edit-material.component.html',
  styleUrls: ['./edit-material.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    FlexLayoutModule,
    Gt2SelectComponent,
    FuseDirectivesModule,
    FusePipesModule,
    MatDialogModule,
    MatInputModule,
    TranslateModule,
    FuseSidebarModule,
    CommonModule,
    MatMenuModule,
    NgxMaterialTimepickerModule,
    Select2Module,
    MatIconModule,
  ],
  providers: [
    WrappingService,
    SpecificConditionService,
    ProposalComposeService,
    ProposalComposeCTService,
    OrderComposeService,
    InvoiceComposeService,
    InvoiceComposeCTService,
    InvoiceComposeGlobalPaymentsService,
    CatererService,
    ConseillersService,
    ReportsService,
    ReportFactoryService,
    MenuReportsService,
    ReceiptNoticeComposeService,
    ReceiptNoticeSellOnlyComposeService,
    StorageNoticeComposeService,
  ],
})
export class EditMaterialComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('prodNoteEditor', { static: false }) prodNoteEditor!: Editor;
  noteSubscription: any;
  material: any;
  orderUUID!: string;
  loading: boolean = false;
  form!: FormGroup | any;
  materialCategory: any;
  noteInvalid: any = {
    sale_note: false,
    logistic_notes: false,
    description_fr_CA: false,
    description_en_CA: false,
  };

  constructor(
    dialogRef: MatDialogRef<EditMaterialComponent>,
    private translationLoader: FuseTranslationLoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public translate: TranslateService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public router: Router,
    public ordersService: OrdersService,
    public materialCategoryService: MaterialCategoryService,
    public materialProviderService: MaterialProviderService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    if (data) {
      this.material = data.material;
      this.orderUUID = data.orderUUID;
      this.materialCategory = data.materialCategory;
    }
    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      name_fr_CA: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      name_internal_fr_CA: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      name_internal_en_CA: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      unit_price: [
        '',
        [ValidatorHelper.isValidPriceNumber, Validators.required],
      ],
      quantity: ['', [ValidatorHelper.isAboveZero, Validators.required]],
      logistic_notes: ['', [Validators.maxLength(Settings.inputMaxCharLong)]],
      sale_note: ['', [Validators.maxLength(Settings.inputMaxCharLong)]],
      description_fr_CA: [
        '',
        [Validators.maxLength(Settings.inputMaxCharLong)],
      ],
      description_en_CA: [
        '',
        [Validators.maxLength(Settings.inputMaxCharLong)],
      ],
    });
  }

  ngOnInit() {}

  ngAfterViewInit() {
    if (this.prodNoteEditor) {
      this.noteSubscription = this.prodNoteEditor.onTextChange.subscribe(
        (data) => {
          this.noteInvalid = data.htmlValue.length > Settings.editorMaxCharLong;
        },
      );
    }
  }

  ngOnDestroy() {}

  public onSaveMaterial() {
    this.material.material_category = this.materialCategory;
    this.loading = true;
    this.ordersService
      .updateMaterials(this.orderUUID, [this.material])
      .subscribe(
        (response: any) => {
          this.loading = false;
          this.dialogRef.close(response.data);
        },
        (error: any) => {
          this.handleAPIError(error, this.dialog, null, null);
          this.loading = false;
        },
      );
  }

  public onMaterialCategoryChange(event: any) {
    this.materialCategory = event;
  }
  public onMaterialProviderSelected(event: any) {
    this.material.material_provider = event;
  }
}
