import { ServiceChargeMultiplierService } from './../../api/service-charge-multiplier.service';
import { NotificationMessagesService } from './../../api/notification-messages.service';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UserSettingsService } from '../../api/user-settings.service';
import { EditorModule } from 'primeng/editor';
import { FuseConfirmDialogModule } from '../../../../@fuse/components/confirm-dialog/confirm-dialog.module';
import { ClientsCategoriesService } from '../../api/clients-categories.service';
import { CrudDeliverySectorsService } from '../../api/crud-delivery-sectors.service';
import { DateFormatService } from '../../api/date-format.service';
import { DateTimeFormatService } from '../../api/date-time-format.service';
import { GeneralConditionsService } from '../../api/general-conditions.service';
import { LedgerAccountService } from '../../api/ledger-account.service';
import { NumberingService } from '../../api/numbering.service';
import { ServiceChargeService } from '../../api/service-charge.service';
import { EventLogisticStatusService } from '../../api/event-logistic-status.service';
import { TaxesService } from '../../api/taxes.service';
import { TimezoneService } from '../../api/timezone.service';
import { CreateDeliverySectorComponent } from '../../components/dialogs/create-delivery-sector/create-delivery-sector.component';
import { GenericCreateEditorComponent } from '../../components/dialogs/generic-create-editor/generic-create-editor.component';
import { GenericCreateComponent } from '../../components/dialogs/generic-create/generic-create.component';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { ClientsCategoriesSettingsComponent } from './clients-categories-settings/clients-categories-settings.component';
import { CrudDeliverySectorsComponent } from './crud-delivery-sectors/crud-delivery-sectors.component';
import { DeliverySettingsComponent } from './delivery-settings/delivery-settings.component';
import { EventLogisticStatusComponent } from './event-logistic-status-settings/event-logistic-status.component';
import { EventLogisticStatusCreateComponent } from './event-logistic-status-settings/event-logistic-status-create/event-logistic-status-create.component';
import { GeneralConditionsComponent } from './general-conditions/general-conditions.component';
import { LedgerAccountDefaultComponent } from './ledger-account-default/ledger-account-default.component';
import { LedgerAccountCreateComponentComponent } from './ledger-account/ledger-account-create-component/ledger-account-create-component.component';
import { LedgerAccountComponent } from './ledger-account/ledger-account.component';
import { MaterialCategoriesCreateComponent } from './material-categories-settings/material-categories-create/material-categories-create.component';
import { MaterialCategoriesSettingsComponent } from './material-categories-settings/material-categories-settings.component';
import { NumberingComponent } from './numbering/numbering.component';
import { ProductCategoriesCreateComponent } from './products-categories/product-categories-create/product-categories-create.component';
import { ProductsCategoriesComponent } from './products-categories/products-categories.component';
import { ServiceChargeCreateComponent } from './service-charge/service-charge-create/service-charge-create.component';
import { ServiceChargeComponent } from './service-charge/service-charge.component';
import { SettingsComponent } from './settings.component';
import { SpecificConditionsComponent } from './specific-conditions/specific-conditions.component';
import { TaxesDefaultComponent } from './taxes-default/taxes-default.component';
import { TaxesComponent } from './taxes/taxes.component';
import { WrappingDefaultComponent } from './wrapping-default/wrapping-default.component';
import { WrappingComponent } from './wrapping/wrapping.component';
import { DeliveryRequestsTemplatesComponent } from './delivery-requests-templates/delivery-requests-templates.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NotificationMessageComponent } from './notification-message/notification-message.component';
import { UserEmployeeSettingComponent } from './user-employee-setting/user-employee-setting.component';
import { AppRoutesName } from '../../const/AppRoutesName';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TableSizePipeModule } from '../../modules/table-size.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatSortModule } from '@angular/material/sort';
import { CdkTableModule } from '@angular/cdk/table';
import { Gt2SelectComponent } from '../../components/gt2-select/gt2-select.component';
import { CrudDatableGenericNoLocalComponent } from '../crud/crud-datable-generic-no-local/crud-datable-generic-no-local.component';
import { CrudDatatableGenericComponent } from '../crud/crud-datatable-generic/crud-datatable-generic.component';
import { JobsFunctionsComponent } from './jobs-functions/jobs-functions.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ButtonModule } from 'primeng/button';
import { CommonModule, NgClass } from '@angular/common';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { FuseSidebarModule } from '../../../../@fuse/components';

const routes = [
  {
    path: AppRoutesName.EN.SETTINGS,
    component: SettingsComponent,
  },
  {
    path: AppRoutesName.EN.SETTINGS + '/:section',
    component: SettingsComponent,
  },
  {
    path: AppRoutesName.FR.SETTINGS,
    component: SettingsComponent,
  },
  {
    path: AppRoutesName.FR.SETTINGS + '/:section',
    component: SettingsComponent,
  },
];

@NgModule({
  declarations: [SettingsComponent],
  imports: [
    MaterialCategoriesCreateComponent,
    TaxesDefaultComponent,
    WrappingDefaultComponent,
    WrappingComponent,
    ServiceChargeCreateComponent,
    UserEmployeeSettingComponent,
    TaxesComponent,
    SpecificConditionsComponent,
    ServiceChargeComponent,
    ProductCategoriesCreateComponent,
    ProductsCategoriesComponent,
    NumberingComponent,
    MatOptionModule,
    NotificationMessageComponent,
    MaterialCategoriesSettingsComponent,
    DeliverySettingsComponent,
    LedgerAccountCreateComponentComponent,
    FlexLayoutModule,
    LedgerAccountDefaultComponent,
    EventLogisticStatusCreateComponent,
    MatRippleModule,
    LedgerAccountComponent,
    MatRadioModule,
    EventLogisticStatusComponent,
    ReactiveFormsModule,
    DeliveryRequestsTemplatesComponent,
    MatDividerModule,
    MatButtonModule,
    ButtonModule,
    MatButtonToggleModule,
    GenericCreateEditorComponent,
    GeneralConditionsComponent,
    ClientsCategoriesSettingsComponent,
    GenericCreateComponent,
    CreateDeliverySectorComponent,
    MatSelectModule,
    MatIconModule,
    MatTooltipModule,
    CrudDeliverySectorsComponent,
    CdkTableModule,
    MatPaginatorModule,
    TableSizePipeModule,
    MatSortModule,
    MatProgressSpinnerModule,
    Gt2SelectComponent,
    CrudDatableGenericNoLocalComponent,
    MatFormFieldModule,
    MatInputModule,
    CdkAccordionModule,
    JobsFunctionsComponent,
    MatSidenavModule,
    NgClass,
    TranslateModule,
    MatTableModule,
    MatTabsModule,
    CrudDatatableGenericComponent,
    CommonModule,
    FuseDirectivesModule,
    FusePipesModule,
    Gt2PrimengModule,
    RouterModule.forChild(routes),
    EditorModule,
    FormsModule,
    FuseConfirmDialogModule,
    NgxMaterialTimepickerModule,
    FuseSidebarModule,
  ],
  exports: [SettingsComponent],
  providers: [
    ClientsCategoriesService,
    CrudDeliverySectorsService,
    TimezoneService,
    UserSettingsService,
    GeneralConditionsService,
    DateFormatService,
    DateTimeFormatService,
    TaxesService,
    LedgerAccountService,
    NumberingService,
    ServiceChargeService,
    EventLogisticStatusService,
    NotificationMessagesService,
    ServiceChargeMultiplierService,
  ],
})
export class SettingsModule {}
