import { NgxLoggerLevel } from "ngx-logger";

export const environment = {
  production: true,
  hmr: false,
  appVersion: require("../../package.json").version + require("../../package.json").subVersion,
  api: {
    protocol: "https://",
    domain: "api.demo.gestion-traiteur.app",
    apiEndpoint: "/api",
  },
  logLevel: NgxLoggerLevel.DEBUG, // TODO: PUT TO 'OFF' WHEN WE START DEPLOYING ON PROD
  gt2Website: "https://www.gestiontraiteur.com",
  firebaseEnvironment: "demo",
  firebase: {
    apiKey: "AIzaSyDk2ieArNLY8y1gLKVIsgVNZMueKoADszw",
    authDomain: "local-martin-gt-app.firebaseapp.com",
    databaseURL: "https://demoo-gt-app.firebaseio.com/",
    projectId: "local-martin-gt-app",
    storageBucket: "local-martin-gt-app.appspot.com",
    messagingSenderId: "335574569693",
  },
  bugsnag: {
    apiKey: "491a1a6cf40210d7575b71309d3d5693",
    apiKeyDebugFreeAccount: "da0f34649464d294fd624a6afa38697b",
    appVersion: "v2.1.7 Demo",
    apiMinimumVersionNumber: "2.1.7",
    apiMinimumVersionLabel: "v2.1.7 Demo",
    releaseStage: "demo",
    notifyReleaseStages: ["qa", "prod", "hmr", "dev", "demo", "beta"],
  },
  hotjarID: "1056130",
};
