import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Gt2ApiService } from './gt2-api.service';
import { ApiRoutes } from './ApiRoutes';
import { EmployeeModel, EmployeeModelData } from './models/CrudEmployeeModel';
import { NGXLogger } from 'ngx-logger';
import { IGetDataService } from '../components/gt2-select/gt2-select.component';

@Injectable()
export class EmployeesService implements Resolve<any>, IGetDataService {
  employees?: EmployeeModelData[];
  onEmployeesChanged: BehaviorSubject<any> = new BehaviorSubject({});

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private api: Gt2ApiService,
  ) {}

  public getEmployeesList() {
    //this.logger.info("EmployeesService.getEmployeesList()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.GET_EMPLOYEES_LIST));
  }

  public getData(): Observable<any> {
    //this.logger.info("EmployeesService.getData()");
    return this.getEmployeesList();
  }

  /**
   * Resolve
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getEmployees()]).then(() => {
        resolve();
      }, reject);
    });
  }

  public getEmployees(): Promise<EmployeeModelData[]> {
    // const routeURL: string = 'api/test-employees';
    const routeURL: string = this.api.createUrl(ApiRoutes.EMPLOYEES);

    return new Promise((resolve, reject) => {
      this.http.get(routeURL).subscribe({
        next: (response: any) => {
          this.employees = response.data;
          // this.logger.info("EmployeesService.getEmployees() -> this.employees: " + JSON.stringify(this.employees));
          this.onEmployeesChanged.next(this.employees);
          resolve(response.data);
        },
        error: reject,
      });
    });
  }

  public getEmployeesWithPagination(
    sort: string,
    direction: string,
    page: number,
    pageSize: number,
    filterValue: string,
  ): Promise<EmployeeModel> {
    const routeURL: string = this.api.createUrl(ApiRoutes.EMPLOYEES);
    let requestUrl: string;
    if (!sort || direction === '') {
      requestUrl = `${routeURL}?page=${page + 1}&limit=${pageSize}`;
    } else {
      requestUrl = `${routeURL}?orderBy=${sort}&sortedBy=${direction}&page=${
        page + 1
      }&limit=${pageSize}`;
    }

    // Remove special caracters from the filter value for proper searching
    filterValue = filterValue.replace(/%/g, '');

    if (filterValue !== '') {
      requestUrl += '&search=' + filterValue;
    }

    return new Promise((resolve, reject) => {
      this.http.get(requestUrl).subscribe({
        next: (response: any) => {
          this.employees = response.data;
          // this.logger.info("EmployeesService.getEmployees() -> response: " + JSON.stringify(response));
          // this.logger.info("EmployeesService.getEmployees() -> this.employees: " + JSON.stringify(this.employees));
          resolve(response);
        },
        error: reject,
      });
    });
  }

  public getEmployeesUsersWithPagination(
    sort: string,
    direction: string,
    page: number,
    pageSize: number,
    filterValue: string,
  ): Promise<EmployeeModel> {
    const routeURL: string = this.api.createUrl(ApiRoutes.EMPLOYEES_USERS);
    let requestUrl: string;
    if (!sort || direction === '') {
      requestUrl = `${routeURL}?page=${page + 1}&limit=${pageSize}`;
    } else {
      requestUrl = `${routeURL}?orderBy=${sort}&sortedBy=${direction}&page=${
        page + 1
      }&limit=${pageSize}`;
    }

    // Remove special caracters from the filter value for proper searching
    filterValue = filterValue.replace(/%/g, '');

    if (filterValue !== '') {
      requestUrl += '&search=' + filterValue;
    }

    return new Promise((resolve, reject) => {
      this.http.get(requestUrl).subscribe({
        next: (response: any) => {
          this.employees = response.data;
          // this.logger.info("EmployeesService.getEmployees() -> this.employees: " + JSON.stringify(this.employees));
          resolve(response);
        },
        error: reject,
      });
    });
  }

  public getEmployeesCalendar() {
    //this.logger.info("EmployeesService.getEmployeesCalendar()");
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.GET_EMPLOYEES_CALENDAR),
    );
  }
}
