import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Observable, BehaviorSubject } from 'rxjs';
import { ApiRoutes } from './ApiRoutes';
import { Gt2ApiService } from './gt2-api.service';
import {
  EnterprisesModel,
  EnterprisesModelData,
} from './models/EnterprisesModel';

@Injectable()
export class EnterprisesService implements Resolve<any> {
  enterprises?: EnterprisesModelData[];
  onEnterprisesChanged: BehaviorSubject<any> = new BehaviorSubject({});

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private api: Gt2ApiService,
  ) {}

  /**
   * Resolve
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([this.getEnterprises()]).then((result) => {
        resolve(result);
      }, reject);
    });
  }

  public getEnterprises(): Promise<any> {
    const routeURL: string = this.api.createUrl(ApiRoutes.ENTERPRISES);
    return new Promise((resolve, reject) => {
      this.http.get(routeURL).subscribe({
        next: (response: any) => {
          this.enterprises = response.data;
          // this.logger.info("ClientsService.getClients() -> this.clients: " + JSON.stringify(this.enterprises));
          this.onEnterprisesChanged.next(this.enterprises);
          resolve(response.data);
        },
        error: reject,
      });
    });
  }

  public getEnterprisesWithPagination(
    sort: string,
    direction: string,
    page: number,
    pageSize: number,
    filterValue: string,
  ): Promise<EnterprisesModel> {
    const routeURL: string = this.api.createUrl(ApiRoutes.ENTERPRISES);
    let requestUrl: string;
    if (!sort || direction === '') {
      requestUrl = `${routeURL}?page=${page + 1}&limit=${pageSize}`;
    } else {
      requestUrl = `${routeURL}?orderBy=${sort}&sortedBy=${direction}&page=${
        page + 1
      }&limit=${pageSize}`;
    }

    // Remove special caracters from the filter value for proper searching
    filterValue = filterValue.replace(/%/g, '');

    if (filterValue !== '') {
      requestUrl += '&search=' + filterValue;
    }

    return new Promise((resolve, reject) => {
      this.http.get(requestUrl).subscribe({
        next: (response: any) => {
          this.enterprises = response.data;
          // this.logger.info("************* EmployeesService.getEmployees() -> this.employees: " + JSON.stringify(this.employees));
          // this.onEmployeesChanged.next(this.employees);
          resolve(response);
        },
        error: reject,
      });
    });
  }

  public getAllEnterprise() {
    // this.logger.info("EnterprisesService.getAllEnterprise()");
    // return this.http.get<any>(this.api.createUrl(ApiRoutes.ENTERPRISES));
    return this.http.get<any>(this.api.createUrl(ApiRoutes.ENTERPRISES_LIST));
  }

  public getEnterprise(enterpriseID: string) {
    // this.logger.info("EnterprisesService.getEnterprise() -> employeeID: " + enterpriseID);
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.GET_ENTERPRISES) + enterpriseID,
    );
  }

  public copyEnterpriseAddressToClients(enterpriseID: string) {
    // this.logger.info("EnterprisesService.getEnterprise() -> employeeID: " + enterpriseID);
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.COPY_ENTERPRISE_ADDRESS) + enterpriseID,
    );
  }

  public getEnterpriseCalendar(enterpriseID: string) {
    // this.logger.info("EnterprisesService.getEnterpriseCalendar() -> employeeID: " + enterpriseID);
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.GET_ENTERPRISES_CALENDAR) + enterpriseID,
    );
  }

  public getEnterpriseClients(
    enterpriseUUID: string,
    sort: string,
    direction: string,
    page: number,
    pageSize: number,
    filterValue: string,
  ): Promise<any> {
    // this.logger.info("EnterprisesService.getEnterpriseClients() -> employeeID: " + enterpriseID);
    // return this.http.get<any>(this.api.createUrl(ApiRoutes.GET_ENTERPRISES_CLIENTS) + enterpriseID);

    const routeURL: string =
      this.api.createUrl(ApiRoutes.GET_ENTERPRISES_CLIENTS) + enterpriseUUID;
    let requestUrl: string;
    if (!sort || direction === '') {
      requestUrl = `${routeURL}?page=${page + 1}&limit=${pageSize}`;
    } else {
      requestUrl = `${routeURL}?orderBy=${sort}&sortedBy=${direction}&page=${
        page + 1
      }&limit=${pageSize}`;
    }

    // Remove special caracters from the filter value for proper searching
    filterValue = filterValue.replace(/%/g, '');

    if (filterValue !== '') {
      requestUrl += '&search=' + filterValue;
    }

    return new Promise((resolve, reject) => {
      this.http.get(requestUrl).subscribe({
        next: (response: any) => {
          // this.clientsCategory = response.data;
          // this.logger.info("ClientsService.getAllClientEvents() -> this.response: " + JSON.stringify(response));
          resolve(response);
        },
        error: reject,
      });
    });
  }

  public getEnterpriseClients2(enterpriseUUID: string) {
    // this.logger.info("EnterprisesService.updateEnetrprise() -> employeeUUID: " + enterpriseUUID);
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.GET_ENTERPRISES_CLIENTS) + enterpriseUUID,
    );
  }

  public updateEnterprise(enterprise: any, enterpriseUUID: string) {
    // this.logger.info("EnterprisesService.updateEnetrprise() -> employeeUUID: " + enterpriseUUID);
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.UPDATE_ENTERPRISE) + enterpriseUUID,
      enterprise,
    );
  }

  public createEnterpriseForClient(enterprise: any, clientUUID: string) {
    // this.logger.info("EnterprisesService.createEnterpriseForClient() -> clientUUID: " + clientUUID);
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.CREATE_ENTERPRISE_FOR_CLIENT) + clientUUID,
      enterprise,
    );
  }

  public deleteEnterprise(enterprise: any) {
    // this.logger.info("CrudEmployeeService.deleteEnterprise() -> enterprise: " + JSON.stringify(enterprise));
    return this.http.delete<any>(
      this.api.createUrl(ApiRoutes.ENTERPRISES) + '/' + enterprise.uuid,
    );
  }

  public getAllEnterpriseEvents(
    sort: string,
    direction: string,
    page: number,
    pageSize: number,
    enterpriseUUID: any,
  ): Promise<any> {
    // this.logger.info("ClientsService.getAllClientEvents()");
    const routeURL: string =
      this.api.createUrl(ApiRoutes.GET_ENTERPRISES_EVENTS) + enterpriseUUID;
    let requestUrl: string;
    if (!sort || direction === '') {
      requestUrl = `${routeURL}?page=${page + 1}&limit=${pageSize}`;
    } else {
      requestUrl = `${routeURL}?orderBy=${sort}&sortedBy=${direction}&page=${
        page + 1
      }&limit=${pageSize}`;
    }

    return new Promise((resolve, reject) => {
      this.http.get(requestUrl).subscribe({
        next: (response: any) => {
          // this.clientsCategory = response.data;
          // this.logger.info("ClientsService.getAllClientEvents() -> this.response: " + JSON.stringify(response));
          resolve(response);
        },
        error: reject,
      });
    });
  }
}
