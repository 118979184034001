import { ErrorDialogComponent } from './../components/dialogs/error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpHeaderResponse,
  HttpSentEvent,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable, Injector } from '@angular/core';
import { AuthenticationService } from '../api/auth-service.service';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { AppRoutes } from '../const/AppRoutes';
import { catchError, switchMap, filter, take, finalize } from 'rxjs/operators';
import { AuthInterceptor } from './AuthInterceptor';
import { ApiRoutes } from '../api/ApiRoutes';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  static IS_MOBILE: boolean = false;
  static BROSWER_SUPPORTED: boolean = true;

  static LAST_URL_401: string | null = null;
  static LAST_URL_404: string | null = null;
  static LAST_URL_500: string | null = null;
  static LAST_URL_429: string | null = null;

  //
  authService?: AuthenticationService;
  isRefreshingToken: boolean = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    public inj: Injector,
    public logger: NGXLogger,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<
    | HttpSentEvent
    | HttpHeaderResponse
    | HttpProgressEvent
    | HttpResponse<any>
    | HttpUserEvent<any>
    | any
  > {
    // this.logger.info("-> JwtInterceptor.intercept()");
    const router = this.inj.get(Router);
    const dialog = this.inj.get(MatDialog);
    this.authService = this.inj.get(AuthenticationService);

    return next.handle(request).pipe(
      catchError((err) => {
        if (JwtInterceptor.IS_MOBILE) {
          router.navigateByUrl('/' + AppRoutes.DEVICE_NOT_SUPPORTED + 'JWT');
          return throwError(err);
        } else if (!JwtInterceptor.BROSWER_SUPPORTED) {
          router.navigateByUrl(AppRoutes.BROWSER_NOT_SUPPORTED);
          return throwError(err);
        } else if (err instanceof HttpErrorResponse) {
          // this.logger.error("ERROR! JwtInterceptor.intercept() - Error: " + JSON.stringify(err));

          switch ((<HttpErrorResponse>err).status) {
            case 401:
              //this.logger.info("-> JwtInterceptor.intercept() - Error 401! -> url: " + err.url);
              //this.logger.info("-> JwtInterceptor.intercept() - Error 401! -> index of: " + err.url.indexOf(ApiRoutes.REFRESH_TOKEN_URL));

              if (router.url && router.url.indexOf(AppRoutes.LOGIN) < 0) {
                JwtInterceptor.LAST_URL_401 = router.url;
                //this.logger.info("-> JwtInterceptor.intercept() - Error 401! -> IS NOT LOGIN!!!! url: " + err.url);
              } else {
                //this.logger.info("-> JwtInterceptor.intercept() - Error 401! -> *****IS LOGIN!!!! url: " + err.url);
                return throwError(err);
              }
              // return <any>this.authService.logout();

              // --o IF REFRESH TOKEN CALL IS ITSELF A 401
              if (err.url.indexOf(ApiRoutes.REFRESH_TOKEN_URL) >= 0) {
                //this.logger.info("-> JwtInterceptor.intercept() - REFRESH TOKEN CALL IS ITSELF A 401! -> doing a soft logout");
                this.authService?.softLogout();
                return throwError(err);
              }

              // TEST : when i refresh a page and im expired, 2 or more call end up here while its
              // this.isRefreshingToken = true;
              return this.handle401Error(request, next);
            case 400:
              //this.logger.info("-> JwtInterceptor.intercept() - Error 400! : " + this.tokenSubject);
              this.authService?.softLogout();

              // THIS LINE IS A TEST (EDIT: SEEM TO WORK)
              this.tokenSubject.next('CANCEL');
              return throwError(err);
            case 404:
              if (router.url.indexOf(AppRoutes.LOGIN) < 0) {
                JwtInterceptor.LAST_URL_404 = router.url;
                router.navigateByUrl(AppRoutes.ERROR_404);
              }
              return throwError(err);
            case 500:
              JwtInterceptor.LAST_URL_500 = router.url;
              router.navigateByUrl(AppRoutes.ERROR_500);
              return throwError(err);
            case 503:
              router.navigateByUrl('/' + AppRoutes.MAINTENANCE);
              return throwError(err);
            case 429:
              router.navigateByUrl(AppRoutes.ERROR_429);
              return throwError(err);
            case 444:
              router.navigateByUrl(AppRoutes.DASHBOARD);
              return throwError(err);
            case 422:
              // router.navigateByUrl(AppRoutes.ERROR_429);
              /* this.handleCodeError(
                                request,
                                next,
                                err,
                                dialog,
                                "422"
                            ); */
              return throwError(err);
            case 417:
              // router.navigateByUrl(AppRoutes.ERROR_429);
              this.handleCodeError(request, next, err, dialog, '417');
              return throwError(err);
            case 428:
              // router.navigateByUrl(AppRoutes.ERROR_429);

              this.handleCodeError(request, next, err, dialog, '428');
              return throwError(err);
            case 504:
              // router.navigateByUrl(AppRoutes.ERROR_429);
              //this.logger.info("-> JwtInterceptor.intercept() - Error 504!");
              this.handleCodeError(request, next, err, dialog, '504');

              return throwError(err);
            case 0:
              // router.navigateByUrl(AppRoutes.ERROR_429);
              //this.logger.info("-> JwtInterceptor.intercept() - **** Error 0! ****");
              return throwError(err);
            default:
              //this.logger.info("JwtInterceptor.intercept() -> UNHANDLED ERROR CODE, DEFAULT THROW ERROR!");
              if ((err as HttpErrorResponse).status > 500) {
                JwtInterceptor.LAST_URL_500 = router.url;
                router.navigateByUrl(AppRoutes.ERROR_500);
              }

              return throwError(err);
          }
        } else {
          return throwError(err);
        }
      }),
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): any {
    // this.logger.info("handle401Error() -> this.isRefreshingToken: " + this.isRefreshingToken);
    //this.logger.info("handle401Error() -> request url: " + request.url);
    const router = this.inj.get(Router);
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);
      //this.logger.info("handle401Error() -> STARTING REFRESHING TOKEN PROCESS!");
      return this.authService?.refreshToken().pipe(
        switchMap((response: any) => {
          // if (user) {
          //     this.tokenSubject.next(user.accessToken);;
          //     localStorage.setItem('currentUser', JSON.stringify(user));
          //     return next.handle(this.addTokenToRequest(request, user.accessToken));
          // }

          // TEST
          // if (response === "CANCEL")
          // {
          //     this.logger.info("handle401Error() -> switchMap() -> response IS CANCEL: " + JSON.stringify(response));
          // }

          //this.logger.info("handle401Error() -> REFRESHING TOKEN PROCESS SUCCESS!");
          this.tokenSubject.next(response);
          const clone = AuthInterceptor.injectHeaders(request);
          // this.logger.info("handle401Error() -> switchMap() -> response: " + JSON.stringify(response));
          // this.logger.info("handle401Error() -> switchMap() -> request: " + JSON.stringify(request));
          // this.logger.info("handle401Error() -> switchMap() -> response.headers: " + JSON.stringify(request.headers));
          return next.handle(clone);
          // return <any>this.authService.logout();
        }),
        catchError((err) => {
          // --o IF REFRESH TOKEN CALL IS ITSELF A 401
          this.logger.error('handle401Error() -> catchError()');
          // router.navigateByUrl(AppRoutes.LOGIN);
          // return <any>this.authService.logout();

          // test
          this.authService?.softLogout();

          // TEST for loading bar not disappearing
          this.tokenSubject.next('CANCEL');
          return throwError(err);
        }),
        finalize(() => {
          //this.logger.info("handle401Error() -> finalize()");
          this.isRefreshingToken = false;
        }),
      );
    } else {
      // this.isRefreshingToken = false;
      //this.logger.info("handle401Error() -> ELSE");
      return this.tokenSubject.pipe(
        filter((response) => response != null),
        take(1),
        switchMap((response) => {
          //this.logger.info("handle401Error() -> ELSE -> switchMap() : QUEUED REQUEST HANDLING!!!!");
          const clone = AuthInterceptor.injectHeaders(request);
          //this.logger.info("handle401Error() -> ELSE -> switchMap() clone headers: " + JSON.stringify(clone.headers));
          return next.handle(clone);
          // return next.handle(this.addTokenToRequest(request, token));
        }),
      );
    }
  }

  private handleCodeError(
    request: HttpRequest<any>,
    next: HttpHandler,
    error: HttpErrorResponse,
    dialog: MatDialog,
    errorCode: any,
  ): void {
    //this.logger.info("handle" + errorCode + "Error()");
    let dialogRef = dialog.open(ErrorDialogComponent, {
      disableClose: false,
      width: '400px',
    });

    var errorMessage = error.error.message;
    if (error.error.errors && error.error.errors.Erreur) {
      errorMessage = error.error.errors.Erreur;
    }

    const customError = {
      error: {
        title: '[' + errorCode + '] ' + error.error.title,
        message: 'Details: ' + errorMessage,
      },
    };
    dialogRef.componentInstance.setError(customError);

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        dialogRef = null;
      },
    });

    // let alert = await alertController.create({
    //     header: error.error.title,
    //     message: error.error.message, // 'Erreur 428',
    //     backdropDismiss: false,
    //     buttons: [{
    //       text: 'OK',
    //       role: 'cancel',
    //       cssClass: 'secondary',
    //       handler: () => {
    //         alert = null;
    //       }
    //     }
    //   ]
    //   });
    // await alert.present();
  }
}
