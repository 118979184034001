<div style="max-height: 100%">
  <h1 matDialogTitle>{{ dialogTitle }}</h1>
  <div fxLayout="row" fxLayoutAlign="start end" class="p-8">
    <div [formGroup]="form" fxFlex="100" class="mr-16" fxLayout="column">
      <mat-form-field fxFlex="100" class="mr-4">
        <mat-label>{{ createPlaceholder }} (Français)</mat-label>
        <input
          matInput
          type="text"
          autocomplete="nope"
          [(ngModel)]="data_obj.name_local.name_fr_CA"
          formControlName="name_fr_CA"
        />
      </mat-form-field>

      <mat-form-field *ngIf="useEnglish" class="">
        <mat-label>{{ createPlaceholder }} (English)</mat-label>
        <input
          matInput
          type="text"
          autocomplete="nope"
          [(ngModel)]="data_obj.name_local.name_en_CA"
          formControlName="name_en_CA"
        />
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="center start" class="gt2-buttons-theme">
    <button
      mat-raised-button
      *ngIf="!data_obj_to_update"
      color="accent"
      (click)="onCreateNew()"
      [disabled]="!form.valid"
      class="mr-16"
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>
    <button
      mat-raised-button
      *ngIf="data_obj_to_update"
      color="accent"
      (click)="onUpdate()"
      class="mr-16"
    >
      {{ "GENERIC.UPDATE" | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(false)"
      class=""
    >
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
