<div
  *ngIf="error && error.error && error.error.title"
  class="mat-title h1-title"
>
  {{ error.error.title }}
</div>
<!-- <div *ngIf="error && error.title" class="mat-title">{{ error.title }}</div> -->
<div
  *ngIf="error && (!error.error || !error.error.title)"
  class="mat-title h1-title"
>
  E1 {{ "GENERIC.ERROR_DIALOG_TITLE" | translate }}
</div>

<div *ngIf="useError456Template">
  <div
    *ngIf="error && error.error && error.error.message"
    style="padding: 0; margin: 0; padding-top: 16px; padding-bottom: 8px"
  >
    {{ error.error.message }}
  </div>

  <div
    *ngIf="!error || !error.error || !error.error.message"
    style="padding: 0; margin: 0; padding-top: 16px; padding-bottom: 8px"
  >
    {{ "GENERIC.ERRORS.GENERIC_ERROR" | translate }}
  </div>

  <div *ngIf="error && errorsData">
    <ul style="padding: 0; margin: 0; padding-bottom: 16px">
      <div *ngFor="let item of errorsData">
        <div *ngFor="let errorString of item.data">
          <li
            mat-dialog-content
            style="padding: 0; margin: 0; padding-left: 16px"
            class="mb-4 error-dialog"
          >
            {{ item.key }} - {{ errorString }}
          </li>
        </div>
      </div>
    </ul>
  </div>
</div>
<div *ngIf="!useError456Template">
  <div
    *ngIf="error && error.error && error.error.message"
    style="padding: 0; margin: 0; padding-top: 16px; padding-bottom: 8px"
  >
    {{ error.error.message }}
  </div>

  <div
    *ngIf="!error || !error.error || !error.error.message"
    style="padding: 0; margin: 0; padding-top: 16px; padding-bottom: 8px"
  >
    {{ "GENERIC.ERRORS.GENERIC_ERROR" | translate }}
  </div>

  <div *ngIf="error && errorsData">
    <ul style="padding: 0; margin: 0; padding-bottom: 16px">
      <div *ngFor="let item of errorsData">
        <div *ngFor="let errorString of item.data">
          <li
            mat-dialog-content
            style="padding: 0; margin: 0; padding-left: 16px"
            class="mb-4 error-dialog"
          >
            {{ errorString }} [{{ item.key }}]
          </li>
        </div>
      </div>
    </ul>
  </div>
</div>

<div
  mat-dialog-actions
  class="pt-12"
  fxLayout="row"
  fxLayoutAlign="center center"
>
  <button
    mat-raised-button
    color="accent"
    class=""
    (click)="dialogRef.close(true)"
  >
    {{ "GENERIC.OK" | translate }}
  </button>
</div>
