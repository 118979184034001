import {
  Component,
  OnInit,
  AfterViewInit,
  ViewEncapsulation,
  OnDestroy,
  HostListener,
} from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ProcessesBottomSheetComponent } from '../dialogs/processes-bottom-sheet/processes-bottom-sheet.component';
import { CrudEmployeeService } from '../../content/crud/crud-employee/crud-employee.service';
import { CrudOrganizationService } from '../../content/crud/crud-organization/crud-organization.service';
import { Router } from '@angular/router';
import { GT2FirebaseService } from '../../../services/gt2-firebase.service';
import { EmployeeModelData } from '../../api/models/CrudEmployeeModel';
import { AuthenticationService } from '../../api/auth-service.service';
import { combineLatest, Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-firebase-process',
  templateUrl: './firebase-process.component.html',
  styleUrls: ['./firebase-process.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    Gt2PrimengModule,
    MatButtonModule,
    TranslateModule,
    MatInputModule,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class FirebaseProcessComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  private _bottomSheetRef: any;
  private firebaseSubscription!: Subscription | null;
  private hasFocus: boolean = true;

  constructor(
    private logger: NGXLogger,
    private bottomSheet: MatBottomSheet,
    private employeeService: CrudEmployeeService,
    private orgService: CrudOrganizationService,
    private router: Router,
    private auth: AuthenticationService,
    private gt2FirebaseService: GT2FirebaseService,
  ) {
    // //this.logger.info("FirebaseProcessComponent.constructor()");

    this.loadData();
    this.auth.loginSuccess.subscribe({
      next: (value) => {
        //this.logger.info("FirebaseProcessComponent.constructor() -- > loginSuccess");
        this.loadData();
      },
    });

    this.auth.logoutSubject.subscribe({
      next: (value) => {
        // //this.logger.info("FirebaseProcessComponent.logoutSubject()");
        if (this._bottomSheetRef) {
          this._bottomSheetRef.dismiss(null);
        }

        // //this.logger.info("FirebaseProcessComponent.logoutSubject() -> logoutSubject this.firebaseSubscription: " + this.firebaseSubscription);
        if (this.firebaseSubscription) {
          this.firebaseSubscription.unsubscribe();
          this.firebaseSubscription = null;
          // //this.logger.info("FirebaseProcessComponent.logoutSubject() -> logoutSubject this.firebaseSubscription.unsubscribe()");
        }

        this.gt2FirebaseService.unsubscribeProcesses();
      },
    });
  }

  ngOnInit() {
    // //this.logger.info("FirebaseProcessComponent.ngOnInit()");
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    if (this.firebaseSubscription) {
      this.firebaseSubscription.unsubscribe();
    }

    this.gt2FirebaseService.unsubscribeProcesses();
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: FocusEvent): void {
    // //this.logger.info("FirebaseProcessComponent.@HostListener().onFocus() -> BROWSER TAB: THIS IS onFocus()");
    this.hasFocus = true;
  }

  @HostListener('window:blur', ['$event'])
  onBlur(event: FocusEvent): void {
    // //this.logger.info("FirebaseProcessComponent.@HostListener().onBlur() -> BROWSER TAB: THIS IS onBlur()");
    this.hasFocus = false;
  }

  //
  public loadData(): void {
    const organizationMe = this.orgService.getOrganizationMeObject();
    if (organizationMe === null) {
      const combined = combineLatest([
        this.orgService.loadOrganizationMe('fb.loadData'),
        this.employeeService.getMe(),
      ]);
      combined.subscribe({
        next: (latestValues) => {
          const employee: any = latestValues[1];
          const org: any = latestValues[0].data;
          this.setUpFirebase(employee, org);
        },
      });
    } else {
      const combined = combineLatest([this.employeeService.getMe()]);
      combined.subscribe({
        next: (latestValues) => {
          const employee: any = latestValues[0];
          this.setUpFirebase(employee, organizationMe.data);
        },
      });
    }
  }

  public setUpFirebase(employee: EmployeeModelData, org: any): void {
    if (this.firebaseSubscription) {
      this.firebaseSubscription.unsubscribe();
      this.firebaseSubscription = null;
    }

    this.firebaseSubscription = this.gt2FirebaseService.onProcesses.subscribe({
      next: (processes: any[]) => {
        const inProgress: any[] = processes.filter((item) => {
          return (
            item.status === GT2FirebaseService.PROCESS_IN_PROGRESS ||
            item.status === GT2FirebaseService.PROCESS_ERROR
          );
        });

        if (this.hasFocus) {
          for (let i: number = 0; i < inProgress.length; i++) {
            this.gt2FirebaseService.acceptProcess(inProgress[i]);
          }
        }

        const completed: any[] = processes.filter((item) => {
          return item.status === GT2FirebaseService.PROCESS_COMPLETED;
        });

        // const backdropItem: any = inProgress.find(item => {
        //     return item.type === GT2FirebaseService.PROCESS_TYPE_PAGE;
        // });

        const backdropItem = false;

        const hasBackdrop: boolean =
          backdropItem !== null && !(backdropItem == undefined);

        if (inProgress.length > 0 && !this._bottomSheetRef && this.hasFocus) {
          this._bottomSheetRef = this.bottomSheet.open(
            ProcessesBottomSheetComponent,
            {
              disableClose: false,
              hasBackdrop: false,
              panelClass: 'custom-sheet-container',
              data: {
                processes: inProgress,
              },
            },
          );

          this._bottomSheetRef.afterDismissed().subscribe({
            next: (result: any) => {
              if (result) {
              }
              this._bottomSheetRef = null;
            },
          });
        } else if (inProgress.length > 0 && this._bottomSheetRef) {
          this._bottomSheetRef.instance.setProcesses(inProgress);
        } else if (inProgress.length === 0 && this._bottomSheetRef) {
          this._bottomSheetRef.dismiss(null);
        }

        //
        if (completed && completed.length > 0) {
          this.gt2FirebaseService.processesCompletedActionDispatch(completed);
        }
      },
    });
    //
    this.gt2FirebaseService.setupFirebaseProcesses(employee, org);
  }
}
