import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { GT2PageAbstract } from '../../content/abstract/GT2PageAbstract';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import {
  MatTable,
  MatTableDataSource,
  MatTableModule,
} from '@angular/material/table';
import { NGXLogger } from 'ngx-logger';
import { CrudDatatableGenericService } from '../../api/crud-datatable-generic.service';
import { DatatableHelperService } from '../../../services/datatable-helper.service';
import { fuseAnimations } from '../../../../@fuse/animations/index';
import { of, merge } from 'rxjs';
import { startWith, switchMap, catchError, map } from 'rxjs/operators';
import { MatTabsModule } from '@angular/material/tabs';
import { TableSizePipeModule } from '../../modules/table-size.module';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    MatButtonModule,
    MatSortModule,
    MatInputModule,
    Gt2PrimengModule,
    TranslateModule,
    CommonModule,
    MatTableModule,
    CdkTableModule,
    MatPaginatorModule,
    TableSizePipeModule,
    MatTabsModule,
  ],
  providers: [DatatableHelperService],

  animations: fuseAnimations,
})
export class DynamicTableComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() serviceURL!: string;
  @Input() displayedColumns!: string[];
  @Input() displayedColumnsHeaderName!: string[];
  @Input() dynamicTableItemAction!: IDynamicTableItemAction;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('table') table!: MatTable<any>;
  @ViewChild(MatSort) sort!: MatSort;
  dataSource = new MatTableDataSource();
  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;

  constructor(
    private logger: NGXLogger,
    public datatableHelperService: DatatableHelperService,
    private crudDatatableGenericService: CrudDatatableGenericService,
  ) {
    super();
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.sort.sortChange.subscribe({
      next: () => (this.paginator.pageIndex = 0),
    });

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(startWith(null))
      .pipe(
        switchMap(() => {
          setTimeout(() => {
            this.isLoadingResults = true;
          });

          return this.crudDatatableGenericService.getAll(
            this.serviceURL,
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize,
          );
        }),
      )
      .pipe(
        map((data) => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.meta.pagination.total;
          return data.data;
        }),
      )
      .pipe(
        catchError(() => {
          setTimeout(() => {
            this.isLoadingResults = false;
            this.isRateLimitReached = true;
          });
          return of([]);
        }),
      )
      .subscribe({ next: (data) => (this.dataSource.data = data) });
  }

  ngOnDestroy() {}

  public reloadData(): void {
    // Hack to refresh the Table
    this.paginator?._changePageSize(this.paginator.pageSize);
  }

  public onSelect(selected: any) {
    //this.logger.info("DynamicTableComponent.onSelect() -> " + JSON.stringify(selected));
    this.dynamicTableItemAction?.onItemClick(selected);
  }
}

export interface IDynamicTableItemAction {
  onItemClick(item: any): void;
}
