import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
  ViewChild,
} from '@angular/core';
import { FuseTranslationLoaderService } from '../../../../../../@fuse/services/translation-loader.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { MatDialog } from '@angular/material/dialog';
import { RolesService } from '../../../../services/roles.service';
import { locale as english } from '../../i18n/en-CA';
import { locale as french } from '../../i18n/fr-CA';
import { CommonModule, Location } from '@angular/common';
import { fuseAnimations } from '../../../../../../@fuse/animations';
import { GT2FormPageAbstract } from '../../../../content/abstract/GT2FormPageAbstract';
import { ItemUrlFinder } from '../../../../utils/item-url-finder';
import { SelectClientComponent } from '../../../../components/dialogs/select-client/select-client.component';
import { EventClientSectionComponent } from './event-client-section/event-client-section.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-event-clients',
  templateUrl: './event-clients.component.html',
  styleUrls: ['./event-clients.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    EventClientSectionComponent,
    NgxPermissionsModule,
    MatButtonModule,
    FlexLayoutModule,
    MatTooltipModule,
    MatIconModule,
    TranslateModule,
  ],
  animations: fuseAnimations,
})
export class EventClientsComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @Output() eventChange = new EventEmitter<any>();
  @Input() event: any;
  @Input() editable: boolean = true;
  @Output() editableChange = new EventEmitter<boolean>();
  @Input() hasCongress: boolean = false;
  loading: boolean = false;
  editMode: boolean = false;
  declare dialogRef: any;
  @Output() modelUpdatedEvent = new EventEmitter<any>();
  @ViewChild('orderingClient')
  billingClient?: EventClientSectionComponent;
  @ViewChild('invoicingClient')
  invoicingClient?: EventClientSectionComponent;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    public logger: NGXLogger,
    public location: Location,
    private dialog: MatDialog,
    public rolesService: RolesService,
    public router: Router,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
  }

  ngOnInit() {}

  ngAfterViewInit() {
    /* //this.logger.info(
            "EventClientsComponent.ngAfterViewInit() -> this.hasCongress: " +
                this.hasCongress
        ); */
  }

  ngOnDestroy() {}

  public loadData(): void {}

  public onToggleEdit(): void {
    this.editMode = !this.editMode;
    // //this.logger.info("EventClientsComponent.onEdit() -> this.editMode: " + this.editMode);
  }

  public onSaveData(): void {
    //this.logger.info("EventClientsComponent.onSaveData()");
  }

  public onManualSave(): void {
    //this.logger.info("EventClientsComponent.onManualSave()");
  }

  public onModelLoaded(): void {
    if (this.billingClient) {
      this.billingClient.onModelLoaded();
    }
    if (this.invoicingClient) {
      this.invoicingClient.onModelLoaded();
    }
    this.loading = false;
  }

  public onDispatchModelUdpated(result: any): void {
    //this.logger.info("EventClientsComponent.onDispatchModelUdpated() -> result: " + JSON.stringify(result));
    this.modelUpdatedEvent.next(result);
  }

  public onComponentModelUpdated(): void {
    //this.logger.info("EventClientsComponent.onComponentModelUpdated()");
    this.modelUpdatedEvent.next(undefined);
  }

  public getClientUUID(type: string): string | null {
    if (type === 'ordering') {
      if (this.event.clients && this.event.clients.ordering_client) {
        return this.event.clients.ordering_client.uuid;
      } else {
        return null;
      }
    } else {
      if (this.event.clients && this.event.clients.invoicing_client) {
        return this.event.clients.invoicing_client.uuid;
      } else {
        return null;
      }
    }
  }

  public onOpenPage(item: any): void {
    this.router.navigateByUrl(ItemUrlFinder.getItemURL(item));
  }

  public onOpenInNewTab(client: any): void {
    // //this.logger.info("EventClientsComponent.onOpenInNewTab() -> client: " + JSON.stringify(client));
    // //this.logger.info("EventClientsComponent.onOpenInNewTab() -> client url: " + ItemUrlFinder.getItemURL(client));
    window.open(ItemUrlFinder.getItemURL(client), '_blank');
  }

  public onOpenSelectClient(client: any, section: any): void {
    // //this.logger.info(
    //     "EventClientsComponent.onOpenSelectClient() -> client: " +
    //         JSON.stringify(client)
    // );
    this.dialogRef = this.dialog.open(SelectClientComponent, {
      width: '80%',
      minWidth: 350,
      disableClose: false,
      panelClass: 'scrollable-dialog',
      data: {
        eventUUID: this.event.uuid,
        clientSection: section,
        client: client,
      },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.loading = true;
          this.onDispatchModelUdpated(result);
          //this.logger.info("EventClientsComponent.onOpenSelectClient().afterClosed() -> result: " + JSON.stringify(result));
          //this.logger.info("EventClientsComponent.onOpenSelectClient().afterClosed() -> section: " + section);

          if (
            section === 'ordering' &&
            result.data.note &&
            result.data.note != '' &&
            this.billingClient
          ) {
            this.billingClient.onSeeNote(result.data.note);
          }
          // if (section === 'ordering') {
          //     this.event.clients.ordering_client =
          // }
        }
        this.dialogRef = null;
      },
    });
  }
}
