<div style="max-height: 100%">
  <h1 matDialogTitle>{{ dialogTitle }}</h1>
  <div fxLayout="row" fxLayoutAlign="start end" class="p-8">
    <div [formGroup]="form" fxFlex="100" class="mr-16" fxLayout="column">
      <mat-form-field fxFlex="100" class="mr-4">
        <mat-label>{{ createPlaceholder }}</mat-label>
        <input
          matInput
          type="text"
          [(ngModel)]="newCategory_fr_CA"
          autocomplete="nope"
          formControlName="name"
        />
        <mat-error *ngIf="form.controls['name'].invalid"
          >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="100" class="mr-4">
        <mat-label>{{ "LEDGER_ACCOUNT.ACCOUNT_NUMBER" | translate }}</mat-label>
        <input
          matInput
          type="number"
          autocomplete="nope"
          [(ngModel)]="accountNumber"
          formControlName="account_number"
        />
        <mat-error *ngIf="form.controls['account_number'].invalid">
          {{ "GENERIC.INPUT_NUMBER_ABOVE_ZERO" | translate
          }}{{ "GENERIC.INPUT_NUMBER_POSTFIX_INTEGER_ONLY" | translate }}
        </mat-error>
      </mat-form-field>

      <!--<mat-form-field *ngIf="useEnglish" fxFlex="100" class="">-->
      <!--<input matInput-->
      <!--type="text"-->
      <!--placeholder="{{createPlaceholder}} (English)"-->
      <!--[(ngModel)]="newCategory_en_CA"-->
      <!--formControlName="name_en_CA">-->
      <!--</mat-form-field>-->
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="center start" class="gt2-buttons-theme">
    <button
      mat-raised-button
      color="accent"
      (click)="onCreateNew()"
      [disabled]="!form.valid"
      class="mr-16"
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(false)"
      class=""
    >
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
