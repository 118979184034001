import { GT2DateAdapter } from '../../../utils/GT2DateAdapter';
import { ItemUrlFinder } from '../../../utils/item-url-finder';
import { ProjectsService } from './../../../api/projects.service';
import { ValidatorHelper } from '../../../validators/ValidatorHelper';
import { Settings } from '../../../settings/settings';
import { CongressModel, ProjectModel } from './../../../api/congresses.service';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  Inject,
} from '@angular/core';
import { locale as english } from '../i18n/en-CA';
import { locale as french } from '../i18n/fr-CA';
import { DateAdapter } from '@angular/material/core';
import { ConseillersService } from './../../../api/conseillers.service';
import { Gt2SelectComponent } from '../../../components/gt2-select/gt2-select.component';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CalendarDateFormatter } from 'angular-calendar';
import { CustomDateFormatter } from '../../calendar/CustomDateFormatter';

@Component({
  selector: 'app-create-project-modal',
  templateUrl: './create-project-modal.component.html',
  styleUrls: ['./create-project-modal.component.scss'],
  standalone: true,
  imports: [
    Gt2SelectComponent,
    CommonModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    TranslateModule,
    FormsModule,
    MatDialogModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
  ],
  providers: [
    ConseillersService,
    { provide: DateAdapter, useClass: GT2DateAdapter },
    { provide: CalendarDateFormatter, useClass: CustomDateFormatter },
  ],
})
export class CreateProjectModalComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  //
  congress!: CongressModel;
  project: ProjectModel | any = null;
  form: UntypedFormGroup;
  loading: boolean = false;
  autoRedirect: boolean = false;
  //
  constructor(
    public override dialogRef: MatDialogRef<CreateProjectModalComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public router: Router,
    private toastr: ToastrService,
    private logger: NGXLogger,
    private projectsService: ProjectsService,
    public conseillersService: ConseillersService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    //
    this.project = {
      uuid: null,
      name: null,
      slug: null,
      congress_id: null,
      project_number: null,
      date_start: null,
      date_end: null,
      canceled_at: null,
      congress: null,
      responsible_employee_id: null,
      responsible_employee: null,
      project_deposit: 0,
    };

    //
    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      date_start: ['', [ValidatorHelper.dateRangeDefault, Validators.required]],
      date_end: ['', []],
    });
    //
    if (data && data.congress) {
      this.congress = data.congress;
      this.project.name = this.congress?.name;
      this.project.date_start = this.congress?.date_start;
    } else {
      this.logger.warn(
        'CreateProjectModalComponent.contructor()2 -> data.congress DOES NOT EXIST OR UNSET',
      );
    }

    if (data && data.autoRedirect) {
      this.autoRedirect = data.autoRedirect;
    }
  }

  ngOnInit() {
    //this.logger.info("CreateProjectModalComponent.ngOnInit()");
  }

  ngAfterViewInit() {
    //this.logger.info("CreateProjectModalComponent.ngAfterViewInit()");
  }

  ngOnDestroy() {
    //this.logger.info("CreateProjectModalComponent.ngOnDestroy()");
  }

  public onResponsibleEmployeeSelected(employee: any): void {
    //this.logger.info("OrderComponent.onResponsibleEmployeeSelected() -> employee: " + JSON.stringify(employee));
    this.project.responsible_employee_id = employee.id;
    this.form.markAsDirty();
  }

  public onCreateProject(): void {
    this.loading = true;
    //
    if (!this.project?.date_end || this.project?.date_end === '') {
      this.project.date_end = this.project?.date_start;
    }
    //
    //this.logger.info("CreateProjectModalComponent.onCreateProject() : " + JSON.stringify(this.congress));
    this.projectsService
      .createProject(this.congress.uuid, this.project)
      .subscribe({
        next: (response) => {
          this.logger.log(
            'CreateProjectModalComponent.onCreateProject() -> SUCCESS: ' +
              JSON.stringify(response),
          );
          this.dialogRef.close(response.data);
          this.loading = false;
          //
          this.router.navigateByUrl(ItemUrlFinder.getItemURL(response.data));
        },
        error: (error) => {
          this.loading = false;
          this.logger.error(
            'CreateProjectModalComponent.createCongress() -> ERROR: ' +
              JSON.stringify(error),
          );
          //
          this.handleAPIError(error, this.dialog, null, null);
        },
      });
  }
}
