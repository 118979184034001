<div style="max-height: 100%">
  <h1 class="h1-title" matDialogTitle>
    {{ "JOBS_FUNCTION.FUNCTION" | translate }}
  </h1>
  <div mat-dialog-content class="pt-20">
    {{ "JOBS_FUNCTION.CHOOSE_FUNCTION" | translate }}
  </div>

  <div *ngIf="jobsFunction && jobsFunction.length > 0" class="pl-40 pr-40 pb-4">
    <mat-form-field class="jobs-function-select">
      <mat-label>{{ "JOBS_FUNCTION.FUNCTIONS" | translate }}</mat-label>
      <mat-select [(ngModel)]="selectedJobsFunction">
        <mat-option *ngFor="let fonction of jobsFunction" [value]="fonction">
          <span class="iso">{{ fonction.name }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="jobsFunction && jobsFunction.length === 0" class="mt-8">
    <p>{{ "JOBS_FUNCTION.NO_FUNCTION_LEFT" | translate }}</p>
  </div>

  <div *ngIf="!jobsFunction" fxLayout="row" fxLayoutAlign="center center">
    <mat-spinner [diameter]="40" class="mt-20"></mat-spinner>
    <div class="spacer" style="height: 4px"></div>
  </div>

  <div class="" fxLayout="column" fxLayoutAlign="center center">
    <span class="text">{{
      "JOBS_FUNCTION.FUNCTION_NOT_IN_LIST" | translate
    }}</span>
    <a
      class="link"
      [routerLink]="
        '/' + appRoutesService.settings + '/' + appRoutesService.jobsFunctions
      "
      (click)="dialogRef.close(false)"
      >{{ "JOBS_FUNCTION.CREATE_NEW" | translate }}</a
    >
  </div>

  <div
    mat-dialog-actions
    class="pt-24 gt2-buttons-theme"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button
      mat-raised-button
      class="mr-16"
      color="accent"
      [disabled]="!selectedJobsFunction"
      (click)="dialogRef.close(selectedJobsFunction)"
    >
      {{ "GENERIC.CONFIRM" | translate }}
    </button>
    <button
      mat-raised-button
      (click)="dialogRef.close(false)"
      color="primary"
      class=""
    >
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
