<div class="profile-box info-box width-100-content">
  <header class="accent" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="title">{{ headerLabel }}</div>
    <div
      class="gt2-light-green"
      style="width: 60px; height: 56px"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <button
        type="button"
        matTooltip="{{ 'JOB_FUNCTIONS.CREATE_NEW_CATEGORY' | translate }}"
        mat-icon-button
        class=""
        (click)="onCreateNew()"
      >
        <mat-icon class="icon-mat-black" color="">add</mat-icon>
      </button>
    </div>
  </header>
  <div style="min-width: 370px" class="content" fxLayout="column">
    <div
      *ngIf="
        !isLoadingResults && dataSource.data && dataSource.data.length === 0
      "
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      {{ "GENERIC.NO_RESULTS" | translate }}
    </div>
    <mat-table
      #table
      class=""
      matSort
      matSortStart="desc"
      [@animateStagger]="{ value: '50' }"
      [dataSource]="dataSource"
    >
      <!-- Name Column -->
      <ng-container cdkColumnDef="name">
        <mat-header-cell fxFlex="100%" *cdkHeaderCellDef mat-sort-header
          >{{ "JOB_FUNCTIONS.CATEGORY_NAME" | translate }}
        </mat-header-cell>
        <mat-cell class="no-text-cursor" fxFlex="100%" *cdkCellDef="let row">
          <div
            *ngIf="editSelected != row"
            fxFlex="100%"
            (click)="onSelect(row)"
          >
            {{ row.name }} - {{ row.hourly_wage_label }}
          </div>

          <div
            [formGroup]="form"
            *ngIf="editSelected == row"
            class=""
            fxFlex="100%"
          >
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-form-field class="" fxFlex="100%">
                <mat-label>Français</mat-label>
                <input
                  matInput
                  formControlName="name_fr_CA"
                  autocomplete="nope"
                  type="text"
                  [(ngModel)]="editSelected.name_local.name_fr_CA"
                />
                <mat-error *ngIf="form.controls['name_fr_CA'].invalid"
                  >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-form-field fxFlex="100%" class="">
                <mat-label>English</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="name_en_CA"
                  autocomplete="nope"
                  [(ngModel)]="editSelected.name_local.name_en_CA"
                />
                <mat-error *ngIf="form.controls['name_en_CA'].invalid"
                  >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-form-field class="" fxFlex="100%">
                <mat-label>{{
                  "JOB_FUNCTIONS.HOURLY_WAGE" | translate
                }}</mat-label>
                <input
                  matInput
                  formControlName="hourly_wage"
                  autocomplete="nope"
                  pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
                  type="text"
                  [(ngModel)]="editSelected.hourly_wage"
                />
                <mat-error
                  *ngIf="form.controls['hourly_wage'].invalid"
                  class=""
                >
                  {{ "GENERIC.INPUT_NUMBER_0_999999" | translate
                  }}{{ "GENERIC.INPUT_NUMBER_POSTFIX_2_DECIMAL" | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <!-- PRICE -->
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-form-field class="" fxFlex="100%">
                <mat-label>{{ "GENERIC.PRICE" | translate }}</mat-label>
                <input
                  matInput
                  formControlName="price"
                  autocomplete="nope"
                  pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
                  type="text"
                  [(ngModel)]="editSelected.price"
                />
                <mat-error *ngIf="form.controls['price'].invalid" class="">
                  {{ "GENERIC.INPUT_NUMBER_0_999999" | translate
                  }}{{ "GENERIC.INPUT_NUMBER_POSTFIX_2_DECIMAL" | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center">
              <app-gt2-select
                #ledgerAccountSelect
                fxFlex="100%"
                [service]="ledgerAccountService"
                [placeHolder]="'GENERIC.LEDGER_ACCOUNT' | translate"
                [label]="'GENERIC.LEDGER_ACCOUNT' | translate"
                [labelKey]="'name_label'"
                [selectedModel]="editSelected.ledger_account"
                (changeEvent)="onLedgerAccountChange($event)"
                [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
                [nullableUseNone]="false"
                class=""
              ></app-gt2-select>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center">
              <app-gt2-select
                #ledgerAccountSelect
                fxFlex="100%"
                [service]="taxesService"
                [placeHolder]="'GENERIC.TAX' | translate"
                [label]="'GENERIC.TAX' | translate"
                [selectedModel]="editSelected.tax"
                (changeEvent)="onTaxChange($event)"
                [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
                [nullableUseNone]="false"
                class=""
              ></app-gt2-select>
            </div>

            <div
              fxLayout="row"
              fxLayoutAlign="space-between center"
              class="gt2-buttons-theme"
            >
              <mat-checkbox
                labelPosition="after"
                [(ngModel)]="editSelected.chargeable"
                [ngModelOptions]="{ standalone: true }"
              >
                {{ "JOB_FUNCTIONS.BILLABLE" | translate }}
              </mat-checkbox>
              <button
                mat-raised-button
                color="accent"
                class="mb-16"
                [disabled]="form.invalid"
                (click)="autosaveDataChange(editSelected)"
              >
                {{ "GENERIC.SAVE" | translate }}
              </button>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <!-- EDIT Column -->
      <ng-container cdkColumnDef="edit">
        <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
        <mat-cell
          fxLayoutAlign="center center"
          fxFlex="50px"
          *cdkCellDef="let row"
        >
          <button
            mat-icon-button
            *ngIf="row.editable"
            matTooltip="{{
              editSelected != row
                ? translate.instant('GENERIC.EDIT')
                : translate.instant('GENERIC.EDIT_STOP')
            }}"
            (click)="onEdit(row)"
          >
            <mat-icon *ngIf="editSelected != row" class="s-16">{{
              getIcon("EDIT")
            }}</mat-icon>
            <mat-icon *ngIf="editSelected == row" class="s-16">{{
              getIcon("UNDO")
            }}</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <!-- Delete Column -->
      <ng-container cdkColumnDef="delete">
        <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
        <mat-cell
          fxLayoutAlign="center center"
          fxFlex="50px"
          *cdkCellDef="let row"
        >
          <button
            matSuffix
            mat-icon-button
            *ngIf="row.editable"
            matTooltip="{{ 'GENERIC.DELETE' | translate }}"
            (click)="onDelete(row)"
          >
            <mat-icon class="s-16">delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        class="mat-row-color"
        *cdkRowDef="let row; columns: displayedColumns"
        [@animate]="{ value: '*', params: { y: '100%' } }"
      >
      </mat-row>
    </mat-table>

    <mat-paginator
      #paginator
      [length]="resultsLength"
      showFirstLastButtons="true"
      (page)="datatableHelperService.onPaginator($event, 'jobsFunctions')"
      [pageIndex]="0"
      [pageSize]="'jobsFunctions' | tableSizePipe: 'large'"
      [pageSizeOptions]="datatableHelperService.pageSizeOptions"
    >
    </mat-paginator>
  </div>
</div>
