<div class="profile-box info-box width-100-content">
  <header class="accent" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="title">{{ "SETTINGS.DELIVERY_SECTOR" | translate }}</div>
    <div
      class="gt2-light-green"
      style="width: 60px; height: 56px"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <button
        type="button"
        matTooltip="{{ 'SETTINGS.CREATE_SECTOR' | translate }}"
        mat-icon-button
        class=""
        (click)="onCreateNew()"
      >
        <mat-icon class="icon-mat-black" color="">add</mat-icon>
      </button>
    </div>
  </header>
  <div style="min-width: 370px" class="content" fxLayout="column">
    <div
      *ngIf="
        !isLoadingResults && dataSource.data && dataSource.data.length === 0
      "
      fxLayout="row"
      fxLayoutAlign="center center"
      class="p-8"
    >
      {{ "GENERIC.NO_RESULTS" | translate }}
    </div>
    <mat-table
      #table
      class=""
      matSort
      matSortStart="desc"
      [@animateStagger]="{ value: '50' }"
      [dataSource]="dataSource"
    >
      <!-- Name Column -->
      <ng-container cdkColumnDef="name">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header>{{
          "GENERIC.NAME" | translate
        }}</mat-header-cell>
        <mat-cell class="no-text-cursor" *cdkCellDef="let row">
          <div
            *ngIf="editSelected != row"
            fxFlex="100%"
            (click)="onSelect(row)"
          >
            {{ row.name }} - {{ row.price_label }}
          </div>

          <div
            *ngIf="editSelected == row"
            fxFlex="100%"
            fxLayout="column"
            class="pt-4"
          >
            <div
              fxLayout="row"
              [formGroup]="form"
              fxLayoutAlign="start center"
              fxFlex="100%"
            >
              <mat-form-field class="" fxFlex="100%">
                <mat-label>{{ "GENERIC.NAME" | translate }}</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="name"
                  autocomplete="nope"
                  [(ngModel)]="editSelected.name"
                />
                <mat-error *ngIf="form.controls['name'].invalid">
                  {{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div
              [formGroup]="form"
              fxLayout="row"
              fxLayoutAlign="start center"
              fxFlex="100%"
            >
              <mat-form-field class="" fxFlex="100%">
                <mat-label>{{ "GENERIC.PRICE" | translate }}</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="price"
                  autocomplete="nope"
                  pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
                  [(ngModel)]="editSelected.price"
                />
                <mat-error *ngIf="form.controls['price'].invalid">
                  {{ "GENERIC.INPUT_NUMBER_0_999999" | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div
              fxLayout="row"
              fxLayoutAlign="end center"
              class="gt2-buttons-theme pb-12"
            >
              <button
                mat-raised-button
                color="accent"
                class="mb-16"
                [disabled]="form.invalid"
                (click)="autosaveDataChange(editSelected)"
              >
                {{ "GENERIC.SAVE" | translate }}
              </button>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <!-- Price Column -->
      <ng-container cdkColumnDef="price">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header>{{
          "GENERIC.PRICE" | translate
        }}</mat-header-cell>
        <mat-cell *cdkCellDef="let row">
          <!--{{row.price_label}}-->

          <div
            *ngIf="editSelected != row"
            fxFlex="100%"
            (click)="onSelect(row)"
          >
            {{ row.price_label }}
          </div>

          <div *ngIf="editSelected == row" fxFlex="100%">
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100%">
              <mat-form-field class="" floatLabel="auto" fxFlex="100%">
                <!--<input matInput-->
                <!--type="number"-->
                <!--[(ngModel)]="editSelected.price">-->
                <input
                  matInput
                  type="text"
                  pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
                  autocomplete="nope"
                  [(ngModel)]="editSelected.price"
                />
                <mat-error *ngIf="form.controls['price'].invalid">
                  {{ "GENERIC.INPUT_NUMBER_0_999999" | translate }}
                </mat-error>
              </mat-form-field>

              <!-- <button mat-icon-button
                                    color="accent"
                                    class="ml-8 mr-4"
                                    matTooltip="{{ 'GENERIC.SAVE' | translate }}"
                                    (click)="onEditCategoryFocusOut(editSelected)">
                                <mat-icon class="mat-accent">save</mat-icon>
                            </button> -->
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <!-- EDIT Column -->
      <ng-container cdkColumnDef="edit">
        <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
        <mat-cell
          fxLayoutAlign="center center"
          fxFlex="50px"
          *cdkCellDef="let row"
        >
          <button
            mat-icon-button
            matTooltip="{{
              editSelected != row
                ? translate.instant('GENERIC.EDIT')
                : translate.instant('GENERIC.EDIT_STOP')
            }}"
            (click)="onEdit(row)"
          >
            <mat-icon *ngIf="editSelected != row" class="s-16">{{
              getIcon("EDIT")
            }}</mat-icon>
            <mat-icon *ngIf="editSelected == row" class="s-16">{{
              getIcon("UNDO")
            }}</mat-icon>
            <!--<i class="fa file-excel-o" aria-hidden="true"></i>-->
            <!--<mdb-icon icon="camera-retro"></mdb-icon>-->
          </button>
        </mat-cell>
      </ng-container>

      <!-- Delete Column -->
      <ng-container cdkColumnDef="delete">
        <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
        <mat-cell
          fxLayoutAlign="center center"
          fxFlex="50px"
          *cdkCellDef="let row"
        >
          <button
            matSuffix
            mat-icon-button
            matTooltip="{{ 'GENERIC.DELETE' | translate }}"
            (click)="onDelete(row)"
          >
            <mat-icon class="s-16">delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        class="mat-row-color"
        *cdkRowDef="let row; columns: displayedColumns"
        [@animate]="{ value: '*', params: { y: '100%' } }"
      >
      </mat-row>
    </mat-table>
    <!--<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>-->
    <mat-paginator
      #paginator
      [length]="resultsLength"
      (page)="datatableHelperService.onPaginator($event, 'deliverySectors')"
      showFirstLastButtons="true"
      [pageIndex]="0"
      [pageSize]="'deliverySectors' | tableSizePipe: 'large'"
      [pageSizeOptions]="datatableHelperService.pageSizeOptions"
    >
    </mat-paginator>
  </div>
</div>
