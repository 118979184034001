import { Subject } from 'rxjs';
import { IDynamicTableItemAction } from './../../../components/dynamic-table/dynamic-table.component';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { GT2FormPageAbstract } from '../../../content/abstract/GT2FormPageAbstract';
import { ICanDeactivateComponent } from '../../../gards/can-deactivate-guard.service';
import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { ShopsService } from '../../../api/shops.service';
import { Location } from '@angular/common';
import { locale as english } from '../i18n/en-CA';
import { locale as french } from '../i18n/fr-CA';

@Component({
  selector: 'app-shop-rte-schedule-edit',
  templateUrl: './shop-rte-schedule-edit.component.html',
  styleUrls: ['./shop-rte-schedule-edit.component.scss'],
  animations: fuseAnimations,
})
export class ShopRteScheduleEditComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy, ICanDeactivateComponent
{
  @ViewChild('scrollMe', { static: false })
  private myScrollContainer!: ElementRef;

  loading: boolean = false;
  shopID!: string;
  scheduleUUID!: string;
  scheduleType!: string;
  shop: any;
  shopCatgories!: any[];

  schedule!: any[];
  tableColumns!: string[];
  tableColumnsDelivery: string[] = [
    'start_at',
    'end_at',
    'delivery_start_at',
    'delivery_end_at',
    'action',
  ];
  tableColumnsTakeout: string[] = [
    'start_at',
    'end_at',
    'takeout_start_at',
    'takeout_end_at',
    'action',
  ];
  scheduleTableItemAction?: ScheduleTableItemAction;

  constructor(
    private shopsService: ShopsService,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public location: Location,
    public router: Router,
    private logger: NGXLogger,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
  }

  ngOnInit(): void {
    // this.loadShop();
    this.scheduleTableItemAction = new ScheduleTableItemAction();

    this.route.params.subscribe({
      next: (params: any) => {
        // //this.logger.info(
        //     "ShopRteScheduleEditComponent.ngOnInit().route.params"
        // );
        this.shopID = params.id;
        this.scheduleUUID = params.scheduleUUID;
        this.scheduleType = params.scheduleType;
        // //this.logger.info(
        //     "ShopRteScheduleEditComponent.ngOnInit().route.params -> scheduleUUID: " +
        //         this.scheduleUUID
        // );
        // //this.logger.info(
        //     "ShopRteScheduleEditComponent.ngOnInit().route.params -> scheduleType: " +
        //         this.scheduleType
        // );
        this.loading = true;
        this.shop = null;
        this.loadShop();
        // this.loadShopSchedule();
        this.loadSchedule();

        if (this.scheduleType === 'delivery') {
          this.tableColumns = this.tableColumnsDelivery;
        } else {
          this.tableColumns = this.tableColumnsTakeout;
        }
      },
    });
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  public canDeactivate(): boolean {
    return true;
  }

  public loadSchedule(): void {
    // //this.logger.info("ShopRteScheduleEditComponent.loadSchedule()");
    this.loading = true;
    this.shopsService
      .getScheduleByUUID(this.scheduleType, this.shopID)
      .subscribe({
        next: (response: any) => {
          // //this.logger.info(
          //     "ShopRteScheduleEditComponent.loadSchedule() -> SUCCESS: " +
          //         JSON.stringify(response)
          // );
          this.loading = false;
          this.schedule = response.data;
        },
        error: (error: any) => {
          this.logger.error(
            'ShopRteScheduleEditComponent.loadSchedule() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          this.handleAPIError(error, this.dialog, null, null);
        },
      });
  }

  public loadShop(): void {
    //this.logger.info("ShopRteScheduleEditComponent.loadShop()");
    this.loading = true;
    this.shopsService.getShopWithCategories(this.shopID).subscribe({
      next: (responseList) => {
        // //this.logger.info("ShopWebComponent.loadShop() -> SUCCESS: " + JSON.stringify(responseList));
        this.shop = responseList[0].data;
        this.shopCatgories = responseList[1].data;
        this.loading = false;
        // this.titleService.setTitle( this.translate.instant("SHOPS.SHOPS") + " " +  this.shop.name);
      },
      error: (error) => {
        this.logger.error(
          'ShopRteScheduleEditComponent.loadShop() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        // TODO: the errors are from a forkJoin, so the handleAPIError might fail
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public loadShopSchedule(): void {
    this.shopsService.getShopNextRTEScheduleStack(this.shopID).subscribe({
      next: (response: any) => {
        //this.logger.info("ShopRteScheduleEditComponent.ngOnInit() -> SUCCESS: " + JSON.stringify(response));
        this.loading = false;
        // this.spinner.hide('rteSpinner');
        // this.schedulesDelivery = response.DELIVERY;
        // this.schedulesTakeout = response.TAKEOUT;
        // //this.logger.info('RteScheduleStackComponent.ngOnInit() -> this.schedulesDelivery: ' + JSON.stringify(this.schedulesDelivery));
        // //this.logger.info('RteScheduleStackComponent.ngOnInit() -> this.schedulesTakeout: ' + JSON.stringify(this.schedulesTakeout));
      },
      error: (error: any) => {
        this.logger.error(
          'ShopRteScheduleEditComponent.ngOnInit() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        // this.spinner.hide('rteSpinner');
      },
    });
  }

  public loadShopRTETemplate(uuid: string): void {
    this.shopsService.getShopRTETemplate(uuid).subscribe({
      next: (response: any) => {
        //this.logger.info("ShopRteScheduleEditComponent.loadShopRTETemplate() -> response: " + JSON.stringify(response));
        this.loading = false;
        // this.rteScheduleTemplate = response.data;

        // setTimeout( () => {
        //     if (this.rteSchedule) {
        //         this.rteSchedule.parentInit();
        //     }
        // }, 100);
      },
      error: (error: any) => {
        this.logger.error(
          'ShopRteScheduleEditComponent.loadShopRTETemplate() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  // public loadMultiPointTakeout(): void {
  //     this.shopsService.getMultiPointTakeout().subscribe( response => {
  //         //this.logger.info("ShopRteScheduleEditComponent.loadMultiPointTakeout() -> response: " + JSON.stringify(response));
  //         // this.multiPointTakeoutModel = response.data;
  //     }, error => {
  //         this.logger.error("ShopRteScheduleEditComponent.loadMultiPointTakeout() -> ERROR: " + JSON.stringify(error));
  //         this.handleAPIError(error, this.dialog, null, null);
  //     });
  // }

  public onOpenInNewTab(): void {
    window.open(this.router.url, '_blank');
  }

  public openURL(url: string): void {
    window.open(url, '_blank');
  }

  public onScrollPage(): void {
    //this.logger.info("ShopRteScheduleEditComponent.onScrollPage() -> this.myScrollContainer: " + this.myScrollContainer);
    this.myScrollContainer.nativeElement.scrollTop =
      this.myScrollContainer?.nativeElement.scrollHeight;
  }
}

export class ScheduleTableItemAction implements IDynamicTableItemAction {
  itemClick: Subject<any> = new Subject();

  constructor() {}

  onItemClick(item: any): void {
    // const url: string = ItemUrlFinder.getItemURL(item);
    // this.itemClick.next(item);
    // this.router.navigateByUrl(url);
  }
}
