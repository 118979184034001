import { Component, Inject, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { CrudDeliverySectorsService } from '../../../api/crud-delivery-sectors.service';
import { GT2FormPageAbstract } from '../../../content/abstract/GT2FormPageAbstract';
import { Settings } from '../../../settings/settings';
import { ValidatorHelper } from '../../../validators/ValidatorHelper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-create-delivery-sector',
  templateUrl: './create-delivery-sector.component.html',
  styleUrls: ['./create-delivery-sector.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    CommonModule,
    FlexLayoutModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    TranslateModule,
    MatProgressSpinnerModule,
  ],
})
export class CreateDeliverySectorComponent
  extends GT2FormPageAbstract
  implements OnInit
{
  form: UntypedFormGroup;
  newCategory?: string;
  newCategoryPrice?: number;
  loading: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService,
    private logger: NGXLogger,
    public dialog: MatDialog,
    public override dialogRef: MatDialogRef<CreateDeliverySectorComponent>,
    private translate: TranslateService,
    private crudDeliverySectorsService: CrudDeliverySectorsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super();
    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      price: ['', [ValidatorHelper.isNumber0To1000000, Validators.required]],
    });
  }

  ngOnInit() {}

  public onCreateNew() {
    // this.logger.info("CrudDeliverySectorsComponent.onCreateNew() -> this.newCategory: " + this.newCategory);
    // this.logger.info("CrudDeliverySectorsComponent.onCreateNew() -> this.newCategoryPrice: " + this.newCategoryPrice);
    this.loading = true;
    this.crudDeliverySectorsService
      .createSector({ name: this.newCategory, price: this.newCategoryPrice })
      .subscribe({
        next: (response) => {
          // this.logger.info("CrudDeliverySectorsComponent.onCreateNew() -> SUCCESS:" + JSON.stringify(response));
          this.loading = false;
          this.toastr.success(
            this.translate.instant('GENERIC.API_CALL_SUCCESS.CREATE_SECTOR'),
          );
          this.dialogRef.close(response.data);
        },
        error: (error) => {
          this.logger.error(
            'CrudDeliverySectorsComponent.onCreateNew() -> ERROR' +
              JSON.stringify(error),
          );
          this.loading = false;
          // this.toastr.error(this.translate.instant('GENERIC.ERRORS.GENERIC'));
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.GENERIC'),
          );
        },
      });
  }
}
