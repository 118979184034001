import { GT2CalendarUtils } from '../../../utils/GT2CalendarUtils';
import { GT2DateAdapter } from './../../../utils/GT2DateAdapter';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { NGXLogger } from 'ngx-logger';
import { CollectionTableDataSource } from './../../../components/collection-table/collection-table.component';
import { IDynamicTableItemAction } from './../../../components/dynamic-table/dynamic-table.component';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { ShopsService } from '../../../api/shops.service';
import { MatTableModule } from '@angular/material/table';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CdkTableModule } from '@angular/cdk/table';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../../@fuse/pipes/pipes.module';
import { MatInputModule } from '@angular/material/input';
import { CalendarDateFormatter } from 'angular-calendar';
import { CustomDateFormatter } from '../../calendar/CustomDateFormatter';

@Component({
  selector: 'app-shop-rte-schedule-edit-data-table-unified',
  templateUrl: './shop-rte-schedule-edit-data-table-unified.component.html',
  styleUrls: ['./shop-rte-schedule-edit-data-table-unified.component.scss'],
  standalone: true,
  imports: [
    MatTableModule,
    FormsModule,
    NgxMaterialTimepickerModule,
    CdkTableModule,
    MatDatepickerModule,
    MatButtonModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    CommonModule,
    TranslateModule,
  ],
  animations: fuseAnimations,
  providers: [
    { provide: DateAdapter, useClass: GT2DateAdapter },
    { provide: CalendarDateFormatter, useClass: CustomDateFormatter },
  ],
})
export class ShopRteScheduleEditDataTableUnifiedComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() shopUUID!: string;
  @Input() collectionData!: any[];
  @Input() displayedColumns!: string[];
  @Input() displayedColumnsHeaderName!: string[];
  @Input() dynamicTableItemAction!: IDynamicTableItemAction;
  @Output() scrollEvent = new EventEmitter<any>();
  dataSource!: CollectionTableDataSource | null;
  loading: boolean = false;
  columnWidth: string = '228px';

  constructor(
    private changeDetectorRefs: ChangeDetectorRef,
    private logger: NGXLogger,
    private dialog: MatDialog,
    private shopService: ShopsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.dataSource = new CollectionTableDataSource(this.collectionData);
    // //this.logger.info('>>> collectionData: ' + JSON.stringify(this.collectionData));
  }

  ngAfterViewInit(): void {
    // //this.logger.info(
    //     ">>> collectionData: " + JSON.stringify(this.collectionData)
    // );
  }

  ngOnDestroy(): void {}

  public reload(data: any): void {
    // //this.logger.info(
    //     "ShopRteScheduleEditDataTableComponent.reload() !!!!!!!!! "
    // );
    this.collectionData = data;
    this.dataSource = new CollectionTableDataSource(this.collectionData);
    this.changeDetectorRefs.detectChanges();
  }

  public onDateChange(model: any, key: any): void {
    // //this.logger.info("ShopRteScheduleEditDataTableComponent.onDateChange() -> " + JSON.stringify(model));
    // //this.logger.info("ShopRteScheduleEditDataTableComponent.onDateChange() -> " + model[key]);
    model[key] = GT2CalendarUtils.formatDateForAPI(model[key]);
  }

  public onSelect(selected: any): void {
    // //this.logger.info("ShopRteScheduleEditDataTableComponent.onSelect() -> " + JSON.stringify(selected));
    // this.dynamicTableItemAction.onItemClick(selected);
  }

  public onSaveRow(row: any): void {
    this.loading = true;
    // //this.logger.info(
    //     "ShopRteScheduleEditDataTableComponent.onSaveRow() -> " +
    //         JSON.stringify(row)
    // );
    // //this.logger.info(
    //     "ShopRteScheduleEditDataTableComponent.onSaveRow() -> row.uuid: " +
    //         row.uuid
    // );
    if (!row.uuid) {
      this.postNewRow(row);
    } else {
      this.shopService.saveScheduleUnifiedByUUID(row.uuid, row).subscribe({
        next: (response: any) => {
          // //this.logger.info(
          //     "ShopRteScheduleEditDataTableComponent.onSaveRow() -> SUCCESS: " +
          //         JSON.stringify(response)
          // );
          this.loading = false;
        },
        error: (error: any) => {
          this.logger.error(
            'ShopRteScheduleEditDataTableComponent.onSaveRow() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          this.handleAPIError(error, this.dialog, null, null);
        },
      });
    }
  }

  public postNewRow(row: any): void {
    // TODO: CHANGE THE POST SO IT REFLECT THE NEW DATA
    this.shopService.postScheduleUnifiedByUUID(row).subscribe({
      next: (response: any) => {
        //this.logger.info("ShopRteScheduleEditDataTableComponent.postNewRow() -> SUCCESS: " + JSON.stringify(response));
        this.loading = false;
      },
      error: (error: any) => {
        this.logger.error(
          'ShopRteScheduleEditDataTableComponent.postNewRow() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public onDeleteRow(row: any): void {
    //this.logger.info("ShopRteScheduleEditDataTableComponent.onDeleteRow() -> " + JSON.stringify(row));
    this.loading = true;
    this.shopService.deleteScheduleByUUID(row.uuid).subscribe({
      next: (response: any) => {
        //this.logger.info("ShopRteScheduleEditDataTableComponent.onDeleteRow() -> SUCCESS: " + JSON.stringify(response));
        this.collectionData = this.collectionData?.filter((item) => {
          if (!item.uuid && !row.uuid) {
            return false;
          }
          return item.uuid !== row.uuid;
        });
        this.reload(this.collectionData);
      },
      error: (error: any) => {
        this.logger.error(
          'ShopRteScheduleEditDataTableComponent.loadShop() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public onAddRow(): void {
    // //this.logger.info(
    //     "ShopRteScheduleEditDataTableComponent.onAddRow() -> : " +
    //         new Date()
    // );
    // //this.logger.info(
    //     "ShopRteScheduleEditDataTableComponent.onAddRow() -> toUTCString: " +
    //         new Date().toUTCString()
    // );
    const today = GT2CalendarUtils.formatDateForAPI(new Date().toUTCString());
    const time: string = '00:00';
    const newRow: any = {
      shop_uuid: this.shopUUID,
      with_takeout: true, // this.scheduleType === 'takeout',
      with_delivery: true, // this.scheduleType !== 'takeout',
      start_at: today,
      start_time: time,
      end_at: today,
      end_time: time,
      delivery_start_at: today,
      delivery_start_time: time,
      delivery_end_at: today,
      delivery_end_time: time,
      takeout_start_at: today,
      takeout_start_time: time,
      takeout_end_at: today,
      takeout_end_time: time,
    };
    this.collectionData?.push(newRow);
    this.reload(this.collectionData);
    this.scrollEvent.emit();
  }
}
