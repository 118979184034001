import { ApiRoutes } from '../../../../api/ApiRoutes';
import { Gt2ApiService } from '../../../../api/gt2-api.service';
import { NGXLogger } from 'ngx-logger';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AssignementsService {
  // employeeAssignmentsObs: Observable<any>;
  employeeAssignmentsPro?: Promise<any>;
  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private api: Gt2ApiService,
  ) {}

  public getAssigments(eventUUID: string): any {
    return this.http.get<any>(
      this.api.createUrl(
        ApiRoutes.ASSIGNMENTS.replace('${uuid_event}', eventUUID),
      ),
    );
  }

  public createGroupAssigment(eventUUID: string, groupInfo: any): any {
    return this.http.post<any>(
      this.api.createUrl(
        ApiRoutes.GROUP_ASSIGNMENT.replace('${uuid_event}', eventUUID),
      ),
      groupInfo,
    );
  }

  public editGroupAssigment(groupUUID: string, groupInfo: any): any {
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.GROUP_ASSIGNMENT_ACTION + groupUUID),
      groupInfo,
    );
  }

  public deleteAssigmentGroup(groupUUID: string): any {
    return this.http.delete<any>(
      this.api.createUrl(ApiRoutes.GROUP_ASSIGNMENT_ACTION + groupUUID),
    );
  }

  public addAssigment(groupUUID: string): any {
    return this.http.post<any>(this.api.createUrl(ApiRoutes.JOB_ASSIGNMENT), {
      group_assignment_uuid: groupUUID,
    });
  }

  public deleteAssigment(groupUUID: string): any {
    return this.http.delete<any>(
      this.api.createUrl(ApiRoutes.JOB_ASSIGNMENT + '/' + groupUUID),
    );
  }

  public editJobAssigment(jobUUID: string, jobInfo: any): any {
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.JOB_ASSIGNMENT + '/' + jobUUID),
      jobInfo,
    );
  }

  public setProposalSatus(proposalUUID: string, proposalStatus: string): any {
    return this.http.post<any>(
      this.api.createUrl(
        ApiRoutes.PROPOSAL_STATUS.replace('${UUID}', proposalUUID),
      ),
      {
        status: proposalStatus,
      },
    );
  }

  public unlinkJobAssignment(jobAssignmentUUID: string): any {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.JOB_ASSIGNMENT_UNLINK + jobAssignmentUUID),
    );
  }

  public getEmployeeAssignments(
    sort: string,
    direction: string,
    page: number,
    pageSize: number,
    employeeUUID: string,
    dateRange: any,
  ): Promise<any> {
    // this.logger.info("AssignementsService.getEmployeeAssignments() dateRange: -> " + JSON.stringify(dateRange));
    const routeURL: string = this.api.createUrl(
      ApiRoutes.EMPLOYEE_ASSIGNMENTS.replace('${UUID}', employeeUUID),
    );
    let requestUrl: string;
    if (!sort || direction === '') {
      requestUrl = `${routeURL}?page=${page + 1}&limit=${pageSize}`;
    } else {
      requestUrl = `${routeURL}?orderBy=${sort}&sortedBy=${direction}&page=${
        page + 1
      }&limit=${pageSize}`;
    }

    if (!this.employeeAssignmentsPro) {
      this.employeeAssignmentsPro = new Promise((resolve, reject) => {
        this.http
          .post(requestUrl, dateRange)
          .pipe(shareReplay())
          .subscribe({
            next: (response: any) => {
              this.employeeAssignmentsPro = null;
              // this.logger.info("AssignementsService.getEmployeeAssignments() -> this.response: " + JSON.stringify(response));
              resolve(response);
            },
            error: reject,
          });
      });
    }

    return this.employeeAssignmentsPro;
  }
}
