import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  CalendarDateFormatter,
  CalendarEvent,
  DateAdapter,
} from 'angular-calendar';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { CalendarEventModel } from '../event.model';
import { MatColors } from '../../../../../@fuse/mat-colors/index';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { CustomDateFormatter } from '../CustomDateFormatter';
import { GT2DateAdapter } from '../../../utils/GT2DateAdapter';

@Component({
  selector: 'fuse-calendar-event-form-dialog',
  templateUrl: './event-form.component.html',
  styleUrls: ['./event-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatToolbarModule,
    MatIconModule,
    TranslateModule,
    MatFormFieldModule,
    MatButtonModule,
    FlexLayoutModule,
    MatDatepickerModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
  ],
  providers: [
    { provide: DateAdapter, useClass: GT2DateAdapter },
    { provide: CalendarDateFormatter, useClass: CustomDateFormatter },
  ],
})
export class FuseCalendarEventFormDialogComponent implements OnInit {
  event: CalendarEvent;
  dialogTitle: string;
  eventForm: UntypedFormGroup;
  action: string;
  presetColors = MatColors.presets;

  constructor(
    public dialogRef: MatDialogRef<FuseCalendarEventFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.event = data.event;
    this.action = data.action;

    if (this.action === 'edit') {
      this.dialogTitle = this.event.title;
    } else {
      this.dialogTitle = 'New Event';
      this.event = new CalendarEventModel({
        start: data.date,
        end: data.date,
      });
    }

    this.eventForm = this.createEventForm();
  }

  ngOnInit() {}

  createEventForm() {
    return new UntypedFormGroup({
      title: new UntypedFormControl(this.event.title),
      start: new UntypedFormControl(this.event.start),
      end: new UntypedFormControl(this.event.end),
      allDay: new UntypedFormControl(this.event.allDay),
      color: this.formBuilder.group({
        primary: new UntypedFormControl(this.event.color?.primary),
        secondary: new UntypedFormControl(this.event.color?.secondary),
      }),
      meta: this.formBuilder.group({
        location: new UntypedFormControl(this.event.meta.location),
        notes: new UntypedFormControl(this.event.meta.notes),
      }),
    });
  }
}
