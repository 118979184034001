<div class="profile-box info-box width-100-content">
  <header class="accent" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="title">{{ "EVENT_LOGISTIC_STATUS.TITLE" | translate }}</div>
    <div
      class="gt2-light-green"
      style="width: 60px; height: 56px"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <button
        type="button"
        matTooltip="{{ 'TODO1' | translate }}"
        mat-icon-button
        class=""
        (click)="onCreateNew()"
      >
        <mat-icon class="icon-mat-black" color="">add</mat-icon>
      </button>
    </div>
  </header>
  <div style="min-width: 370px" class="content" fxLayout="column">
    <div
      *ngIf="
        !isLoadingResults && dataSource.data && dataSource.data.length === 0
      "
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      {{ "GENERIC.NO_RESULTS" | translate }}
    </div>
    <mat-table
      #table
      class=""
      matSort
      matSortStart="desc"
      [@animateStagger]="{ value: '50' }"
      [dataSource]="dataSource"
    >
      <!-- Name Column -->
      <ng-container cdkColumnDef="name">
        <mat-header-cell fxFlex="100%" *cdkHeaderCellDef mat-sort-header
          >{{ "EVENT_LOGISTIC_STATUS.LIST_COLUMN_NAME" | translate }}
        </mat-header-cell>
        <mat-cell class="no-text-cursor" fxFlex="100%" *cdkCellDef="let row">
          <div
            *ngIf="editSelected != row"
            (click)="onSelect(row)"
            fxFlex="100%"
          >
            <div fxFlex="50%">
              <div
                fxLayoutAlign="center center"
                [ngStyle]="{
                  margin: '5px',
                  minWidth: '160px',
                  minHeight: '30px',
                  'background-color': row.color_code,
                }"
              >
                <div [ngStyle]="{ color: '#FFFFFF', padding: '7px' }">
                  {{ row.name }}
                </div>
              </div>
            </div>
          </div>

          <div
            *ngIf="editSelected == row"
            class=""
            fxLayout="column"
            fxFlex="100%"
          >
            <div [formGroup]="form" fxLayout="row" fxLayoutAlign="start center">
              <mat-form-field class="" fxFlex="100%">
                <mat-label>{{ "Français#" }}</mat-label>
                <input
                  matInput
                  formControlName="name_fr_CA"
                  autocomplete="nope"
                  type="text"
                  [(ngModel)]="editSelected.name_local.name_fr_CA"
                />
                <mat-error *ngIf="form.controls['name_fr_CA'].invalid">{{
                  "GENERIC.INPUT_LENGTH_INVALID" | translate
                }}</mat-error>
              </mat-form-field>
            </div>

            <div [formGroup]="form" fxLayout="row" fxLayoutAlign="start center">
              <mat-form-field fxFlex="100%" class="">
                <mat-label>{{ "English#" }}</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="name_en_CA"
                  autocomplete="nope"
                  [(ngModel)]="editSelected.name_local.name_en_CA"
                />
                <mat-error *ngIf="form.controls['name_en_CA'].invalid">{{
                  "GENERIC.INPUT_LENGTH_INVALID" | translate
                }}</mat-error>
              </mat-form-field>
            </div>

            <div
              [formGroup]="form"
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="20px"
            >
              <div>Couleur</div>

              <!-- <pre>currentColor {{currentColor | json}}</pre> -->
              <div>
                <!-- https://github.com/bytelabsco/ngx-color-selector -->
                <!-- https://github.com/bytelabsco/ngx-color-selector/blob/master/src/color-palettes/material-colors.ts -->

                <!-- <bytelabs-color-selector
                  [(color)]="currentColor"
                  [options]="options"
                  [ngStyle]="{ margin: '10px', width: '160px', height: '30px', 'background-color': row.color_code }"
                  (colorChange)="setColor($event)"
                ></bytelabs-color-selector> -->
                <ngx-colors
                  ngx-colors-trigger
                  [(ngModel)]="currentColor"
                  (change)="setColor($event)"
                  [palette]="colorPalette"
                  [hideColorPicker]="true"
                  [hideTextInput]="true"
                ></ngx-colors>

                <mat-error *ngIf="form.controls['name_en_CA'].invalid">{{
                  "GENERIC.INPUT_LENGTH_INVALID" | translate
                }}</mat-error>
              </div>
              <!-- <div>{{editSelected.color_name}} ({{editSelected.color_code}})</div> -->
            </div>

            <div
              fxLayout="row"
              fxLayoutAlign="end center"
              class="gt2-buttons-theme"
            >
              <button
                mat-raised-button
                color="accent"
                class="mb-16"
                [disabled]="form.invalid"
                (click)="autosaveDataChange(editSelected)"
              >
                {{ "GENERIC.SAVE" | translate }}
              </button>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <!-- EDIT Column -->
      <ng-container cdkColumnDef="edit">
        <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
        <mat-cell
          fxLayoutAlign="center center"
          fxFlex="50px"
          *cdkCellDef="let row"
        >
          <button
            mat-icon-button
            *ngIf="row.editable"
            matTooltip="{{
              editSelected != row
                ? translate.instant('GENERIC.EDIT')
                : translate.instant('GENERIC.EDIT_STOP')
            }}"
            (click)="onEdit(row)"
          >
            <mat-icon *ngIf="editSelected != row" class="s-16">{{
              getIcon("EDIT")
            }}</mat-icon>
            <mat-icon *ngIf="editSelected == row" class="s-16">{{
              getIcon("UNDO")
            }}</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <!-- Delete Column -->
      <ng-container cdkColumnDef="delete">
        <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
        <mat-cell
          fxLayoutAlign="center center"
          fxFlex="50px"
          *cdkCellDef="let row"
        >
          <button
            matSuffix
            mat-icon-button
            *ngIf="row.editable"
            matTooltip="{{ 'GENERIC.DELETE' | translate }}"
            (click)="onDelete(row)"
          >
            <mat-icon class="s-16">delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        *cdkRowDef="let row; columns: displayedColumns"
        [@animate]="{ value: '*', params: { y: '100%' } }"
      >
      </mat-row>
    </mat-table>

    <mat-paginator
      #paginator
      [length]="resultsLength"
      showFirstLastButtons="true"
      (page)="datatableHelperService.onPaginator($event, 'serviceCharge')"
      [pageIndex]="0"
      [pageSize]="'serviceCharge' | tableSizePipe: 'large'"
      [pageSizeOptions]="datatableHelperService.pageSizeOptions"
    >
    </mat-paginator>
  </div>
</div>
