import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { fuseAnimations } from '../../../../@fuse/animations/index';
import { FuseConfigService } from '../../../../@fuse/services/config.service';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import {
  LanguageModel,
  LocalizationConfig,
} from '../../../localization/LocalizationConfig';
import {
  AuthenticationService,
  FinalizedInviteModel,
} from '../../api/auth-service.service';
import { Gt2ApiService } from '../../api/gt2-api.service';
import { ResponsesStatus } from '../../api/ResponsesStatus';
import { AppRoutes } from '../../const/AppRoutes';
import { GT2ConstantsEnum } from '../../const/GT2ConstantsEnum';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { AppRoutesService } from '../../services/app-routes.service';

@Component({
  selector: 'app-invite-wizard',
  templateUrl: './invite-wizard.component.html',
  styleUrls: ['./invite-wizard.component.scss'],
  animations: fuseAnimations,
})
export class InviteWizardComponent implements OnInit {
  // isLinear = false;
  defaultGender: string = GT2ConstantsEnum.MALE;
  languages: LanguageModel[] = [];
  defaultLanguage: string;
  inviteResponse: any;
  loading: boolean = false;

  uuid?: string;

  profileFormGroup!: UntypedFormGroup;
  profileFormGroupErrors: any;

  mailingList: boolean = true;
  webinar: boolean = true;

  userAlreadyExist: boolean = false;
  userNotFound: boolean = false;

  constructor(
    private fuseConfig: FuseConfigService,
    private translationLoader: FuseTranslationLoaderService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,

    public appRoutesService: AppRoutesService,
    private logger: NGXLogger,
    private api: Gt2ApiService,
    private auth: AuthenticationService,
  ) {
    this.translationLoader.loadTranslations(english, french);

    this.fuseConfig.setConfig({
      layout: {
        navigation: 'none',
        toolbar: 'none',
        footer: 'none',
      },
    });

    // Route params
    this.route.params.subscribe({
      next: (params: any) => {
        // //this.logger.info("RegisterWizardComponent.constructor() -> params: " + params.id);
        this.uuid = params.id;
        //this.logger.info("InviteWizardComponent.constructor() -> params: " + this.uuid);
      },
    });

    // Languages
    this.languages = LocalizationConfig.LANGUAGES;
    this.defaultLanguage = LocalizationConfig.getCurrentLanguage().locale;
  }

  ngOnInit() {
    this.profileFormGroup = this.formBuilder.group({
      genderCtrl: ['', Validators.required],
      firstNameCtrl: ['', Validators.required],
      lastNameCtrl: ['', Validators.required],
      password1Ctrl: ['', Validators.required],
      password2Ctrl: ['', [Validators.required, confirmPassword]],
      localeCtrl: ['', Validators.required],
      phoneNumberCtrl: ['', Validators.required],
      phoneNumberExtensionCtrl: [''],
    });

    this.profileFormGroupErrors = {
      genderCtrl: {},
      firstNameCtrl: {},
      lastNameCtrl: {},
      password1Ctrl: {},
      password2Ctrl: {},
      localeCtrl: {},
      phoneNumberCtrl: {},
      phoneNumberExtensionCtrl: {},
    };

    this.profileFormGroup.valueChanges.subscribe({
      next: () => {
        this.onFormGroupValuesChanged(
          this.profileFormGroup,
          this.profileFormGroupErrors,
        );
      },
    });
  }

  onFormGroupValuesChanged(formGroup: UntypedFormGroup, formGroupErrors: any) {
    //this.logger.info("InviteWizardComponent.onFormGroupValuesChanged()");
    for (const field in formGroupErrors) {
      if (!formGroupErrors.hasOwnProperty(field)) {
        continue;
      }

      // Clear previous errors
      formGroupErrors[field] = {};

      // Get the control
      const control = formGroup.get(field);
      if (control && control.dirty && !control.valid) {
        formGroupErrors[field] = control.errors;
      }
    }
  }

  onCreateAccount(): void {
    //this.logger.info("InviteWizardComponent.onCreateAccount()");
    const registerData: FinalizedInviteModel = this.createModelForm();
    //this.logger.info("InviteWizardComponent.onCreateAccount() -> registerData: " + JSON.stringify(registerData));

    this.loading = true;
    this.inviteResponse = null;
    this.auth.finalizedInvite(registerData).subscribe({
      next: (response: any) => {
        //this.logger.info("InviteWizardComponent.onCreateAccount() -> Success: " + JSON.stringify(response));

        this.loading = false;
        this.userAlreadyExist = false;
        this.userNotFound = false;

        if (
          response.response_status === ResponsesStatus.USER_ALREADY_REGISTERED
        ) {
          this.userAlreadyExist = true;
        } else if (
          response.response_status === ResponsesStatus.USER_NOT_FOUND
        ) {
          this.userNotFound = true;
        } else if (response.response_status === ResponsesStatus.OK) {
          this.inviteResponse = response;
        } else {
          // TODO:
        }
      },
      error: (error: any) => {
        //this.logger.info("InviteWizardComponent.onCreateAccount() -> Error: " + JSON.stringify(error));
        this.loading = false;
        this.userAlreadyExist = false;
        this.userNotFound = false;
      },
    });
  }

  onLogin(): void {
    this.router.navigateByUrl(AppRoutes.LOGIN);
  }

  // PRIVATE
  private createModelForm(): FinalizedInviteModel {
    const registerData: FinalizedInviteModel = {
      uuid: this.uuid,
      gender: this.profileFormGroup.get('genderCtrl')?.value,
      firstName: this.profileFormGroup.get('firstNameCtrl')?.value,
      lastName: this.profileFormGroup.get('lastNameCtrl')?.value,
      password: this.profileFormGroup.get('password1Ctrl')?.value,
      userLocale: this.profileFormGroup.get('localeCtrl')?.value,
      phoneNumber: this.profileFormGroup.get('phoneNumberCtrl')?.value,
      phoneNumberExtension: this.profileFormGroup.get(
        'phoneNumberExtensionCtrl',
      )?.value,
      mailingList: this.mailingList,
      webinar: this.webinar,
    };

    return registerData;
  }
}

function confirmPassword(control: AbstractControl): any {
  // //this.logger.info("InviteWizardComponent.confirmPassword() -> " + control);
  if (!control.parent || !control) {
    return;
  }

  const password = control.parent.get('password1Ctrl');
  const passwordConfirm = control.parent.get('password2Ctrl');

  if (!password || !passwordConfirm) {
    return;
  }

  if (passwordConfirm.value === '') {
    return;
  }

  if (password.value !== passwordConfirm.value) {
    return {
      passwordsNotMatch: true,
    };
  }
}
