import { Editor } from 'primeng/editor';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ProductCategoryService } from '../../../api/product-category.service';
import { ProductionZonesService } from '../../../api/production-zones.service';
import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { OrdersService } from '../../../api/orders.service';
import { GT2PageAbstract } from '../../abstract/GT2PageAbstract';
import { Settings } from '../../../settings/settings';
import { ValidatorHelper } from '../../../validators/ValidatorHelper';
import { locale as english } from '../i18n/en-CA';
import { locale as french } from '../i18n/fr-CA';
import { FormControl } from '@angular/forms';
import { ModulesService } from '../../../services/modules.service';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../../@fuse/pipes/pipes.module';
import { MatInputModule } from '@angular/material/input';
import { FuseSidebarModule } from '../../../../../@fuse/components';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { Select2Module } from 'ng-select2-component';
import { MatIconModule } from '@angular/material/icon';
import { WrappingService } from '../../../api/wrapping.service';
import { SpecificConditionService } from '../../../api/specific-condision.service';
import { ProposalComposeService } from '../../../api/proposal-compose.service';
import { ProposalComposeCTService } from '../../../api/proposal-compose-ct.service';
import { OrderComposeService } from '../../../api/order-compose.service';
import { InvoiceComposeService } from '../../../api/invoice-compose.service';
import { InvoiceComposeCTService } from '../../../api/invoice-compose-ct.service';
import { InvoiceComposeGlobalPaymentsService } from '../../../api/invoice-compose-global-payments.service';
import { CatererService } from '../../caterer/caterer.service';
import { ConseillersService } from '../../../api/conseillers.service';
import { ReportsService } from '../../../reports/reports.service';
import { ReportFactoryService } from '../../../reports/report-factory.service';
import { MenuReportsService } from '../../../reports/menu-reports.service';
import { ReceiptNoticeComposeService } from '../../../api/receipt-notice-compose.service';
import { ReceiptNoticeSellOnlyComposeService } from '../../../api/receipt-notice-sell-only-compose.service';
import { StorageNoticeComposeService } from '../../../api/storage-notice-compose.service';
import { Gt2SelectComponent } from '../../../components/gt2-select/gt2-select.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatOptionModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatButtonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    MatSelectModule,
    MatCheckboxModule,
    MatInputModule,
    TranslateModule,
    FuseSidebarModule,
    Gt2SelectComponent,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatOptionModule,
    CommonModule,
    MatMenuModule,
    NgxMaterialTimepickerModule,
    Select2Module,
    MatIconModule,
  ],
  providers: [
    WrappingService,
    SpecificConditionService,
    ProposalComposeService,
    ProposalComposeCTService,
    OrderComposeService,
    InvoiceComposeService,
    InvoiceComposeCTService,
    InvoiceComposeGlobalPaymentsService,
    CatererService,
    ConseillersService,
    ReportsService,
    ReportFactoryService,
    MenuReportsService,
    ReceiptNoticeComposeService,
    ReceiptNoticeSellOnlyComposeService,
    StorageNoticeComposeService,
  ],
})
export class EditProductComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('txtEditor', { static: false }) txtEditor!: Editor;
  product: any;
  productCategory: any;
  orderUUID!: string;
  parentUUID!: string;
  loading: boolean = false;
  form!: FormGroup | any;
  priceForm!: FormGroup | any;
  chargedPriceForm!: FormGroup | any;

  noteInvalid: boolean = false;
  noteSubscription: any;
  editable: boolean = false;
  zones = new FormControl('');
  zonesList: any = {};
  productionZones: string[] = [];
  isComboChild: boolean = false;

  constructor(
    dialogRef: MatDialogRef<EditProductComponent>,
    private translationLoader: FuseTranslationLoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public translate: TranslateService,
    public dialog: MatDialog,
    public router: Router,
    public productCategoryService: ProductCategoryService,
    public productionZonesService: ProductionZonesService,
    public ordersService: OrdersService,
    public modulesService: ModulesService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    if (data) {
      this.product = data.product;
      this.isComboChild = data.isComboChild;
      this.productCategory = data.productCategory;
      this.orderUUID = data.orderUUID;
      this.parentUUID = data.parentUUID;
      this.editable = data.editable;
      if (this.product.production_zones) {
        for (let zone of this.product.production_zones) {
          this.productionZones.push(zone['uuid']);
        }
      }
    }

    this.form = this.formBuilder.group({
      name: [
        { value: '', disabled: !this.editable },
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      name_fr_CA: [
        { value: '', disabled: !this.editable },
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      name_internal_fr_CA: [
        { value: '', disabled: !this.editable },
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      name_internal_en_CA: [
        { value: '', disabled: !this.editable },
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      description_fr_CA: [
        '',
        [Validators.maxLength(Settings.inputMaxCharLong)],
      ],
      description_en_CA: [
        '',
        [Validators.maxLength(Settings.inputMaxCharLong)],
      ],
      prod_note: ['', [Validators.maxLength(Settings.inputMaxCharLong)]],
      sale_note: ['', [Validators.maxLength(Settings.inputMaxCharLong)]],
      quantity: [
        { value: '', disabled: !this.editable },
        [Validators.required],
      ],
    });

    this.priceForm = this.formBuilder.group({
      unit_price: [
        { value: '', disabled: !this.editable },
        [ValidatorHelper.isValidPriceNumber],
      ],
    });
    this.chargedPriceForm = this.formBuilder.group({
      unit_price: [
        { value: '', disabled: !this.editable },
        [ValidatorHelper.isValidPriceNumber],
      ],
    });

    this.productionZonesService.getDataList().subscribe(
      (response) => {
        var data = response.data;
        for (const zone of data) {
          this.zonesList[zone['uuid']] = zone['name'];
        }
      },
      (error) => {},
    );
  }

  ngOnInit() {}

  ngAfterViewInit() {
    if (this.txtEditor) {
      this.noteSubscription = this.txtEditor.onTextChange.subscribe((data) => {
        this.noteInvalid = data.htmlValue.length > Settings.editorMaxCharLong;
      });
    }
  }

  ngOnDestroy() {
    if (this.noteSubscription) {
      this.noteSubscription.unsubscribe();
    }
  }

  public onSaveProduct(closeDialog: boolean = true) {
    this.product.product_category = this.productCategory;
    this.product.production_zones = this.productionZones;
    this.product.parent_uuid = this.parentUUID;
    this.loading = true;

    if (this.product.show_charged_price && this.modulesService.hasJLReport()) {
      this.product.unit_price = this.product.charged_price;
    }
    this.loading = true;
    this.ordersService.updateProducts(this.orderUUID, [this.product]).subscribe(
      (response: any) => {
        this.loading = false;
        if (closeDialog) {
          this.dialogRef.close(response.data);
        } else {
          for (const prodCol of response.data.product_category_collection) {
            for (const product of prodCol.product_collection) {
              if (product.uuid == this.product.uuid) {
                this.product = product;
                break;
              }
            }
          }
        }
      },
      (error: any) => {
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    );
  }

  public onProductCategoryChange(event: any) {
    this.productCategory = event;
  }

  public onProductShowPrice(event: any) {
    this.product.show_charged_price = event.checked;
    this.evaluateShowPrice();
    if (event.checked == false) {
      this.onSaveProduct(false);
    }
  }

  public evaluateShowPrice() {
    if (this.product.show_charged_price) {
      this.product.unit_price = parseFloat(this.product.charged_price);
    } else {
      this.product.unit_price = 0;
    }
  }

  public productZonesChange(event: any) {
    this.productionZones = event.value;
  }
}
