import { GenericAlertComponent } from './../../components/generic-alert/generic-alert.component';
import { ModulesService } from '../../services/modules.service';
import { OrderComponent } from '../orders/order.component';
import { SearchResultsTypeEnum } from './../search-results/SearchResultsTypeEnum';
import { ItemUrlFinder } from './../../utils/item-url-finder';
import { SortEvent } from 'primeng/api';
import { NGXLogger } from 'ngx-logger';
import { NgxSpinnerService } from 'ngx-spinner';
import { RolesService } from './../../services/roles.service';
import { DatatableHelperService } from '../../../services/datatable-helper.service';
import { FileService } from '../../../services/file.service';
import { Router } from '@angular/router';
import { EventsService } from '../../api/events.service';
import { FuseTranslationLoaderService } from './../../../../@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import {
  NgxDrpOptions,
  PresetItem,
  Range,
  NgxMatDrpComponent,
} from 'ngx-mat-daterange-picker';
import { Table } from 'primeng/table';
import { GT2PageAbstract } from '../../content/abstract/GT2PageAbstract';
import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { locale as english } from '../dashboard/i18n/en-CA';
import { locale as french } from '../dashboard/i18n/fr-CA';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { MatDialog } from '@angular/material/dialog';
import { CreateCongresDialogComponent } from '../congresses/create-congres-dialog/create-congres-dialog.component';
import { AppRoutes } from '../../const/AppRoutes';
import { DateFormatPreference } from '../../utils/DateFormatPreference';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import { CalendarDateFormatter, DateAdapter } from 'angular-calendar';
import { GT2DateAdapter } from '../../utils/GT2DateAdapter';
import { CustomDateFormatter } from '../calendar/CustomDateFormatter';

@Component({
  selector: 'app-dashboard-detailed',
  templateUrl: './dashboard-detailed.component.html',
  styleUrls: ['./dashboard-detailed.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: GT2DateAdapter },
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})
export class DashboardDetailedComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  static LOCAL_STORED_RANGE_FROM_ID: string = 'LOCAL_STORED_RANGE_FROM_001';
  static LOCAL_STORED_RANGE_TO_ID: string = 'LOCAL_STORED_RANGE_TO_001';
  static LOCAL_STORED_SELECTED_COL_ID: string = 'LOCAL_STORED_SELECTED_COL_005';
  static LOCAL_STORED_DELIVERY_TYPE_SELECT_MODEL_ID: string =
    'LOCAL_STORED_DELIVERY_TYPE_SELECT_MODEL_001';
  static LOCAL_STORED_STATUS_SELECT_MODEL_ID: string =
    'LOCAL_STORED_STATUS_SELECT_MODEL_001';
  static LOCAL_STORED_ORDER_STATUS_SELECT_MODEL_ID: string =
    'LOCAL_STORED_ORDER_STATUS_SELECT_MODEL_001';

  static LOCAL_STORED_EVENT_LOGISTIC_STATUS_SELECT_MODEL_ID: string =
    'LOCAL_STORED_EVENT_LOGISTIC_STATUS_SELECT_MODEL_001';

  extra: any = {
    total_guest_count: 0,
    total_event: 0,
  };

  // PrimeNG Table
  @ViewChild('primengTable') private primengTable?: Table;
  primeNGData: any;
  primeNGCols: any[] | any;
  primeNGColsField: string[] | any;
  primeNGColsHeader: any;
  selectedColumns: any[] | any;

  selectedEvent: any;
  loading?: boolean; //  = false;
  includeCancelAbort: boolean = false;
  rowsPerPage: number = 100;
  useColumnsFilter: boolean = true;
  deliveryTypes?: any[];
  deliveryTypesModel: any = null;
  status?: any[];
  statusModel: any = null;
  ordersStatus?: any[];
  ordersStatusModel: any = null;
  eventLogisticStatus?: any[];
  eventLogisticStatusModel: any = null;
  maxGuestsCount: number = 3;
  guestsSliderValue?: number | null = null;
  guestsSliderTimeout: any;
  rowsContextMenu?: any[];
  cmSelectedEvent: any;
  sellingTotal: number = 0;

  // Date range picker
  @ViewChild('dateRangePicker')
  private dateRangePicker?: NgxMatDrpComponent;
  range: Range = { fromDate: new Date(), toDate: new Date() };
  rangeOptions?: NgxDrpOptions;
  presets: Array<PresetItem> = [];

  currencyColumns: any[] = [];
  currencyColumnsKey: string[] = ['total', 'total_sub_1', 'balance'];
  //
  dateColumns: any[] = [];
  dateColumnsKeys: string[] = [
    'event_date_label',
    'event_date_end_label',
    'created_at_label',
  ];
  // currencyColumnKey: string[] = [];

  tabSelectedInit: boolean = false;
  confirmDialogRef: any;
  selected: any;
  alwaysShowCalendars: boolean = false;
  ranges: any = {
    "Aujourd'hui": [moment(), moment()],
    'Cette semaine': [moment(), moment().add(7, 'days')],
    '2 prochaines semaines': [moment(), moment().add(14, 'days')],
    'Présent mois': [moment().startOf('month'), moment().endOf('month')],
    'Prochain mois': [
      moment().add(1, 'months').startOf('month'),
      moment().add(1, 'months').endOf('month'),
    ],
  };
  localeDate: LocaleConfig;

  constructor(
    public translate: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    public eventsService: EventsService,
    public router: Router,
    public fileService: FileService,
    public datatableHelperService: DatatableHelperService,
    public rolesService: RolesService,
    private spinner: NgxSpinnerService,
    private logger: NGXLogger,
    public modulesService: ModulesService,
    public dialog: MatDialog,
    public moduleService: ModulesService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    this.localeDate = {
      applyLabel: this.translate.instant('GENERIC.SUBMIT'),
      cancelLabel: this.translate.instant('GENERIC.CANCEL'),
      format: 'DD MMMM YYYY',
      // displayFormat: 'DD MMMM YYYY HH:mm',
      separator: ' - ',
      customRangeLabel: this.translate.instant('CONGRESSES.CUSTOM_RANGE'),
    };
    // //this.logger.info("DashboardDetailedCalendarComponent.constructor()");
  }

  ngOnInit(): void {
    this.selected = {
      startDate: moment().startOf('month'),
      endDate: moment().endOf('month'),
    };

    this.modulesService.modulesSet.subscribe({
      next: (data) => {
        /* //this.logger.info(
                "DashboardDetailedCalendarComponent.ngOnInit.modulesSet() data: " +
                    JSON.stringify(data)
            ); */
        if (data) {
          this.initData();
        }
      },
    });
    //
    this.initData();
  }

  ngAfterViewInit(): void {
    // this.onResetTable();
    // this.primengTable
    // setTimeout();
    // //this.logger.info(
    //     ">>>> ngAfterViewInit(): " +
    //         JSON.stringify(this.primengTable._columns)
    // );

    if (this.primengTable) {
      // this.primengTable.columns = this.selectedColumns;
      this.primengTable.columns = this.primengTable.columns?.filter((item) => {
        return item !== undefined && item !== null;
      });
    }
  }

  ngOnDestroy(): void {
    this.primeNGData = null;
    this.sellingTotal = 0;
  }

  public initData(): void {
    ////this.logger.info("DashboardDetailed.initData()");
    this.primeNGCols = [
      {
        field: 'delivery_type',
        header: 'Type',
        toggle: true,
        showFilter: true,
        sortable: true,
        applyCSS: null,
        colWidth: '92px',
      },
      {
        field: 'event_logistic_status',
        header: this.translate.instant('GENERIC.EVENT_LOGISTIC_STATUS'),
        toggle: true,
        showFilter: true,
        sortable: true,
        applyCSS: null,
        colWidth: '300px',
      },
      {
        field: 'event_time_label',
        header: this.translate.instant('GENERIC.START_TIME'),
        toggle: true,
        showFilter: true,
        sortable: true,
        applyCSS: null,
        colWidth: '150px',
      },
      {
        field: 'name',
        header: this.translate.instant('GENERIC.EVENT_NAME'),
        toggle: true,
        showFilter: true,
        sortable: true,
        applyCSS: null,
        colWidth: '250px',
      },
      {
        field: 'room_name',
        header: this.translate.instant('GENERIC.ROOM'),
        toggle: true,
        showFilter: true,
        sortable: true,
        applyCSS: null,
        colWidth: '200px',
      },
      {
        field: 'guest_count',
        header: this.translate.instant('GENERIC.GUEST_COUNT_DT'),
        toggle: true,
        showFilter: true,
        sortable: true,
        applyCSS: null,
        colWidth: '165px',
      },
      {
        field: 'status_order_label',
        header: this.translate.instant('GENERIC.STATUS'),
        toggle: true,
        showFilter: true,
        sortable: true,
        applyCSS: null,
        colWidth: '260px',
      },
      {
        field: 'event_type',
        header: this.translate.instant('DASHBOARD_DETAILED.EVENT_TYPE'),
        toggle: true,
        showFilter: true,
        sortable: true,
        applyCSS: null,
        colWidth: '175px',
      },
      {
        field: 'order_code',
        header: this.translate.instant('DASHBOARD_DETAILED.ORDER_NUMBER'),
        toggle: true,
        showFilter: true,
        sortable: true,
        applyCSS: null,
        colWidth: '155px',
      },
      {
        field: 'ordering_client_name',
        header: this.translate.instant('DASHBOARD_DETAILED.ORDER_CLIENT_NAME'),
        toggle: true,
        showFilter: true,
        sortable: true,
        applyCSS: null,
        colWidth: '240px',
      },
      {
        field: 'client_enterprise_name',
        header: this.translate.instant('DASHBOARD_DETAILED.ENTERPRISE_NAME'),
        toggle: true,
        showFilter: true,
        sortable: true,
        applyCSS: null,
        colWidth: '192px',
      },
      {
        field: 'enterprise_name',
        header: this.translate.instant(
          'DASHBOARD_DETAILED.ENTERPRISE_DELIVERED_TO',
        ),
        toggle: true,
        showFilter: true,
        sortable: true,
        applyCSS: null,
        colWidth: '190px',
      },
      {
        field: 'responsible_employee_name',
        header: this.translate.instant('GENERIC.RESPONSIBLE_EMPLOYEE'),
        toggle: true,
        showFilter: true,
        sortable: true,
        applyCSS: null,
        colWidth: '180px',
      },
      {
        field: 'event_date_label',
        header: this.translate.instant('GENERIC.START_DATE'),
        toggle: true,
        showFilter: true,
        sortable: true,
        applyCSS: null,
        colWidth: '145px',
      },
      {
        field: 'event_date_end_label',
        header: this.translate.instant('GENERIC.END_DATE'),
        toggle: false,
        showFilter: true,
        sortable: true,
        applyCSS: null,
        colWidth: '125px',
      },
      {
        field: 'event_time_end_label',
        header: this.translate.instant('GENERIC.END_TIME'),
        toggle: false,
        showFilter: true,
        sortable: true,
        applyCSS: null,
        colWidth: '150px',
      },
      {
        field: 'event_number',
        header: this.translate.instant('GENERIC.EVENT_NUMBER'),
        toggle: false,
        showFilter: true,
        sortable: true,
        applyCSS: null,
        colWidth: '180px',
      },
      {
        field: 'order_name',
        header: this.translate.instant('DASHBOARD_DETAILED.ORDER_NAME'),
        toggle: false,
        showFilter: true,
        sortable: true,
        applyCSS: null,
        colWidth: '220px',
      },
      {
        field: 'po_number',
        header: this.translate.instant(
          'DASHBOARD_DETAILED.PURCHASE_ORDER_NUMBER',
        ),
        toggle: false,
        showFilter: true,
        sortable: true,
        applyCSS: null,
        colWidth: '192px',
      },
      {
        field: 'invoicing_client_name',
        header: this.translate.instant(
          'DASHBOARD_DETAILED.INVOICE_CUSTOMER_NAME',
        ),
        toggle: false,
        showFilter: true,
        sortable: true,
        applyCSS: null,
        colWidth: '220px',
      },
      {
        field: 'invoice_code',
        header: this.translate.instant('DASHBOARD_DETAILED.INVOICE_NUMBER'),
        toggle: false,
        showFilter: true,
        sortable: true,
        applyCSS: null,
        colWidth: '130px',
      },
      {
        field: 'balance',
        header: this.translate.instant('DASHBOARD_DETAILED.BALANCE'),
        toggle: false,
        showFilter: true,
        sortable: true,
        applyCSS: null,
        colWidth: '120px',
      },
      {
        field: 'total_sub_1',
        header: this.translate.instant('DASHBOARD_DETAILED.NET_AMOUNT'),
        toggle: false,
        showFilter: true,
        sortable: true,
        applyCSS: null,
        colWidth: '150px',
      },
      {
        field: 'total',
        header: this.translate.instant('DASHBOARD_DETAILED.AMOUNT_WITH_TAXES'),
        toggle: false,
        showFilter: true,
        sortable: true,
        applyCSS: null,
        colWidth: '225px',
      },
      {
        field: 'status_label',
        header: this.translate.instant('DASHBOARD_DETAILED.EVENT_STATUS'),
        toggle: false,
        showFilter: true,
        sortable: true,
        applyCSS: null,
        colWidth: '220px',
      },
      {
        field: 'created_at_label',
        header: this.translate.instant('GENERIC.CREATED_AT'),
        toggle: false,
        showFilter: true,
        sortable: true,
        applyCSS: null,
        colWidth: '125px',
      },
      // { field: 'uuid', header: 'UUID',           toggle: false, showFilter: true, sortable: true, applyCSS: null, colWidth: "220px" },
    ];

    if (this.modulesService.modulesSet && this.modulesService.hasUQAMReport()) {
      this.primeNGCols.push({
        field: 'payment_mode',
        header: this.translate.instant('GENERIC.PAYMENT_MODE'),
        toggle: false,
        showFilter: true,
        sortable: true,
        applyCSS: null,
        colWidth: '125px',
      });
    }

    if (
      this.modulesService.modulesSet &&
      this.modulesService.hasCongressModule()
    ) {
      this.primeNGCols.push({
        field: 'congress_name',
        header: 'Nom du congrès',
        toggle: false,
        showFilter: true,
        sortable: true,
        applyCSS: null,
        colWidth: '220px',
      });
      //
      this.primeNGCols.push({
        field: 'congress_number',
        header: '# du congrès',
        toggle: false,
        showFilter: true,
        sortable: true,
        applyCSS: null,
        colWidth: '220px',
      });
      //
      this.primeNGCols.push({
        field: 'project_name',
        header: 'Nom du projet',
        toggle: false,
        showFilter: true,
        sortable: true,
        applyCSS: null,
        colWidth: '220px',
      });
    }

    this.currencyColumns = this.primeNGCols.filter((item: any) => {
      return this.currencyColumnsKey.includes(item.field);
    });
    //
    this.dateColumns = this.primeNGCols.filter((item: any) => {
      return this.currencyColumnsKey.includes(item.field);
    });

    this.primeNGColsField = this.primeNGCols.map((item: any) => {
      return item.field;
    });

    this.primeNGColsHeader = {};
    for (let entry of this.primeNGCols) {
      this.primeNGColsHeader[entry.field] = entry.header;
    }

    // //this.logger.info(">>>> primeNGColsField: " + JSON.stringify(this.primeNGColsField));
    // //this.logger.info(">>>> primeNGColsHeader: " + JSON.stringify(this.primeNGColsHeader));

    const selectedCol: string =
      localStorage.getItem(
        DashboardDetailedComponent.LOCAL_STORED_SELECTED_COL_ID,
      ) || '';
    ////this.logger.info(">>>> selectedCol: " + selectedCol);
    if (selectedCol && selectedCol.length > 0 && !(selectedCol == undefined)) {
      this.selectedColumns = JSON.parse(selectedCol);
      ////this.logger.info(">>>> WARNING: selectedCol: " + selectedCol);
    } else {
      this.selectedColumns = this.primeNGCols.filter((item: any) => {
        return item.toggle;
      });
      // this.selectedColumns = [this.primeNGCols[0]];
      // this.selectedColumns = [];
      // //this.logger.info(">>>> SET: selectedCol: " + JSON.stringify(this.selectedColumns));
    }

    this.deliveryTypes = [
      {
        label: this.translate.instant('DASHBOARD.ALL_DELIVERY_TYPE'),
        value: null,
        icon: 'fa-truck',
      },
      {
        label: this.translate.instant('DASHBOARD.DELIVERY_ONLY'),
        value: 1,
        icon: 'fa-shipping-fast',
      },
      {
        label: this.translate.instant('DASHBOARD.DELIVER_INSTALL'),
        value: 2,
        icon: 'fa-utensils',
      },
      {
        label: this.translate.instant('DASHBOARD.DELIVERY_EMPLOYEES'),
        value: 3,
        icon: 'fa-users',
      },
      {
        label: this.translate.instant('DASHBOARD.DELIVERY_PICKUP'),
        value: 4,
        icon: 'fa-concierge-bell',
      },
    ];

    this.status = [
      {
        label: this.translate.instant('DASHBOARD.ALL_STATUS_TYPE'),
        value: null,
      },
      {
        label: this.translate.instant('DASHBOARD.EVENT_OPEN'),
        value: 'EVENT_OPEN',
      },
      {
        label: this.translate.instant('DASHBOARD.EVENT_IN_ORDER'),
        value: 'EVENT_IN_ORDER',
      },
      {
        label: this.translate.instant('DASHBOARD.EVENT_IN_INVOICE'),
        value: 'EVENT_IN_INVOICE',
      },
      {
        label: this.translate.instant('DASHBOARD.EVENT_IN_PROPOSAL'),
        value: 'EVENT_IN_PROPOSAL',
      },
      {
        label: this.translate.instant('DASHBOARD.EVENT_CANCEL'),
        value: 'EVENT_CANCEL',
      },
      {
        label: this.translate.instant('DASHBOARD.EVENT_ABORT'),
        value: 'EVENT_ABORT',
      },
      {
        label: this.translate.instant('DASHBOARD.EVENT_COMPLETE'),
        value: 'EVENT_COMPLETE',
      },
      {
        label: this.translate.instant('DASHBOARD.EVENT_PAST_EVENT'),
        value: 'EVENT_PAST_EVENT',
      },
    ];

    this.ordersStatus = [
      {
        label: this.translate.instant('DASHBOARD.ALL_ORDER_TYPE'),
        value: null,
      },
      {
        label: this.translate.instant('DASHBOARD.ALL_ORDERS'),
        value: 'ORDER',
      },
      {
        label: this.translate.instant('DASHBOARD.ALL_PROPOSALS'),
        value: 'PROPOSAL',
      },
      {
        label: this.translate.instant('DASHBOARD.ALL_INVOICES'),
        value: 'INVOICE',
      },
      {
        label: this.translate.instant('DASHBOARD.ORDER_OPEN'),
        value: 'ORDER_OPEN',
      },
      {
        label: this.translate.instant('DASHBOARD.ORDER_SEND'),
        value: 'ORDER_SEND',
      },
      {
        label: this.translate.instant('DASHBOARD.ORDER_CANCEL'),
        value: 'ORDER_CANCEL',
      },
      {
        label: this.translate.instant('DASHBOARD.ORDER_CONFIRM'),
        value: 'ORDER_CONFIRM',
      },

      {
        label: this.translate.instant('DASHBOARD.PROPOSAL_OPEN'),
        value: 'PROPOSAL_OPEN',
      },
      {
        label: this.translate.instant('DASHBOARD.PROPOSAL_PROPOSE'),
        value: 'PROPOSAL_PROPOSE',
      },
      {
        label: this.translate.instant('DASHBOARD.PROPOSAL_REFUSE'),
        value: 'PROPOSAL_REFUSE',
      },

      {
        label: this.translate.instant('DASHBOARD.INVOICE_OPEN'),
        value: 'INVOICE_OPEN',
      },
      {
        label: this.translate.instant('DASHBOARD.INVOICE_SEND'),
        value: 'INVOICE_SEND',
      },
      {
        label: this.translate.instant('DASHBOARD.INVOICE_PAID'),
        value: 'INVOICE_PAID',
      },
      {
        label: this.translate.instant('DASHBOARD.INVOICE_CANCEL'),
        value: 'INVOICE_CANCEL',
      },
    ];

    this.rowsContextMenu = [
      {
        label: 'Ouvrir dans un nouvel onglet',
        command: (event: any) => this.openInNewTabContextMenu(event),
      },
    ];

    // select / dropdown model
    const dtModel = localStorage.getItem(
      DashboardDetailedComponent.LOCAL_STORED_DELIVERY_TYPE_SELECT_MODEL_ID,
    );
    // //this.logger.info(">>>> dtModel: " + dtModel);
    if (dtModel && !(dtModel == undefined)) {
      this.deliveryTypesModel = Number(dtModel);
    }

    const sModel = localStorage.getItem(
      DashboardDetailedComponent.LOCAL_STORED_STATUS_SELECT_MODEL_ID,
    );
    // //this.logger.info(">>>> sModel: " + sModel);
    if (sModel && !(sModel == undefined)) {
      this.statusModel = sModel;
    }

    const osModel = localStorage.getItem(
      DashboardDetailedComponent.LOCAL_STORED_ORDER_STATUS_SELECT_MODEL_ID,
    );
    // //this.logger.info(">>>> osModel: " + osModel);
    if (osModel && !(osModel == undefined)) {
      this.ordersStatusModel = osModel;
    }

    const elsModel = localStorage.getItem(
      DashboardDetailedComponent.LOCAL_STORED_EVENT_LOGISTIC_STATUS_SELECT_MODEL_ID,
    );
    // //this.logger.info(">>>> osModel: " + osModel);
    if (elsModel && !(elsModel == undefined)) {
      this.eventLogisticStatusModel = elsModel;
    }

    //
    this.setupRangePresets();
    const today = new Date();
    this.rangeOptions = {
      presets: this.presets,
      format: 'mediumDate',
      locale: this.translate.currentLang,
      range: {
        fromDate: new Date(today.getFullYear(), today.getMonth(), 1),
        toDate: new Date(today.getFullYear(), today.getMonth() + 1, 0),
      },
      animation: false,
      applyLabel: this.translate.instant('GENERIC.SUBMIT'),
      cancelLabel: this.translate.instant('GENERIC.CANCEL'),
      placeholder: this.translate.instant('DASHBOARD.CHOOSE_A_DATE'),
      startDatePrefix: this.translate.instant('DASHBOARD.FROM'),
      endDatePrefix: this.translate.instant('DASHBOARD.TO'),
      calendarOverlayConfig: {
        shouldCloseOnBackdropClick: true,
        hasBackdrop: true,
        // panelClass: 'ngx-daterange-picker'
      },
    };

    // set range
    const rangeFrom = localStorage.getItem(
      DashboardDetailedComponent.LOCAL_STORED_RANGE_FROM_ID,
    );
    const rangeTo = localStorage.getItem(
      DashboardDetailedComponent.LOCAL_STORED_RANGE_TO_ID,
    );

    if (rangeFrom && rangeTo) {
      // //this.logger.info(">>> rangeFrom: " + rangeFrom);
      // //this.logger.info(">>> rangeTo: " + rangeTo);
      this.rangeOptions.range.fromDate = new Date(rangeFrom);
      this.rangeOptions.range.toDate = new Date(rangeTo);
    }

    this.range = this.rangeOptions.range;
    // this.loadAllData();
    // this.tabSelected();
  }

  public tabSelected(): void {
    //this.logger.info("DashboardDetailedCalendarComponent.tabSelected()");
    this.tabSelectedInit = true;
    if (!this.primeNGData) {
      this.loadAllData();
    }
  }

  customSort(event: SortEvent | any) {
    //this.logger.info("customSort() " + event.field);
    // var a = moment([2007, 0, 29]);
    // var b = moment([2007, 1, 28]);
    // a.diff(b, 'days') // 1

    // return 1;
    if (event.field === 'event_logistic_status') {
      /*             //this.logger.info("customSort() for event_date_label");*/
      event.data?.sort((data1: any, data2: any) => {
        let value1 = data1[event.field];
        let value2 = data2[event.field];
        let result = null;

        let value1_label = value1.label;
        let value2_label = value2.label;
        result = value1_label.localeCompare(value2_label);
        return event.order * result;
      });
    } else {
      event.data?.sort((data1: any, data2: any) => {
        let value1 = data1[event.field];
        let value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null) {
          result = -1;
        } else if (value1 != null && value2 == null) {
          result = 1;
        } else if (value1 == null && value2 == null) {
          result = 0;
        } else if (typeof value1 === 'string' && typeof value2 === 'string')
          if (
            event.field === 'event_date_label' ||
            event.field === 'event_date_end_label' ||
            event.field === 'created_at_label'
          ) {
            const newKey: string = event.field.replace('_label', '');
            value1 = data1[newKey];
            value2 = data2[newKey];
            let date1 = moment(value1);
            let date2 = moment(value2);
            result = date1.diff(date2, 'days');
          } else if (event.field === 'order_code') {
            const cmd1 = value1.split('-');
            const cmd2 = value2.split('-');
            if (cmd1[0] === cmd2[0]) {
              result = -1;
              if (parseInt(cmd1[1]) >= parseInt(cmd1[1])) {
                result = 1;
              }
            } else {
              result = value1.localeCompare(value2);
            }
          } else {
            result = value1.localeCompare(value2);
          }
        else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

        // return result;
        return event.order * result;
      });
    }
  }

  public loadAllData(): void {
    ////this.logger.info("DashboardDetailedCalendarComponent.loadAllData()");
    this.sellingTotal = 0;

    this.loading = true;
    this.spinner.show();

    this.eventsService.getEventLogisticStatusList().then(
      (val) => {
        this.eventLogisticStatus = [];
        this.eventLogisticStatus.push({
          label: 'Tous les statuts',
          color_code: '#FFFFFF',
          value: '0',
        });
        for (let els of val.data) {
          this.eventLogisticStatus.push({
            label: els.name,
            color_code: els.color_code,
            value: els.uuid,
          });
        }
        ////this.logger.info(this.eventLogisticStatus);
      },
      (err) => {
        this.logger.error(err);
      },
    );

    this.eventsService
      .allByDateRange(
        this.range.fromDate.toDateString(),
        this.range.toDate.toDateString(),
        this.includeCancelAbort,
      )
      .then(
        (val) => {
          this.computeExtra(val.data);
          this.primeNGData = val.data;
          // Crunch all events total that is loaded (all page included since its a PrimeNg Table)
          for (let event of this.primeNGData) {
            this.sellingTotal += event.total_sub_1;
          }
          //
          this.loading = false;
          this.spinner.hide();
          /* //this.logger.info(
                        "loadAllData() -> SUCCESS: " +
                            JSON.stringify(this.primeNGData)
                    ); */
        },
        (err) => {
          this.logger.error(err);
          this.handleAPIError(err, this.dialog, null, null);
        },
      );
  }

  public computeExtra(data: any[]): void {
    this.extra.total_event = data.length;
    let guests: number = 0;

    for (let i = 0; i < data.length; ++i) {
      guests += data[i].guest_count;
      if (this.maxGuestsCount < data[i].guest_count) {
        this.maxGuestsCount = data[i].guest_count;
      }
    }
    // //this.logger.info("maxGuestsCount: " + this.maxGuestsCount);
    // //this.logger.info("guestsSliderValue: " + this.guestsSliderValue);
    this.extra.total_guest_count = guests;
  }

  public updateRange(range: { startDate: Date; endDate: Date }): void {
    /*       //this.logger.info(
            "DashboardDetailedCalendarComponent.updateRange() --> " +
                this.tabSelectedInit
        ); */
    const endDate = new Date(range.endDate);
    endDate.setDate(endDate.getDate() - 1);
    //this.logger.info("CongressesComponent.updateRange() --> " + this.tabSelectedInit);
    this.range = {
      fromDate: new Date(range.startDate),
      toDate: new Date(endDate),
    };
    // this.loading = true;
    // if (this.tabSelectedInit) {
    //     this.loadAllData();
    // }

    this.loadAllData();

    // //this.logger.info("this.range.fromDate.toString() : " + this.range.fromDate.toString());
    // //this.logger.info("this.range.toDate.toString() : " + this.range.toDate.toString());

    // set in Local Storage
    localStorage.setItem(
      DashboardDetailedComponent.LOCAL_STORED_RANGE_FROM_ID,
      this.range.fromDate.toString(),
    );
    localStorage.setItem(
      DashboardDetailedComponent.LOCAL_STORED_RANGE_TO_ID,
      this.range.toDate.toString(),
    );
  }

  public setupRangePresets(): void {
    const backDate = (numOfDays: any) => {
      const todayDate = new Date();
      return new Date(todayDate.setDate(today.getDate() - numOfDays));
    };

    const upDate = (numOfDays: any) => {
      const todayDate = new Date();
      return new Date(todayDate.setDate(today.getDate() + numOfDays));
    };

    const today = new Date();
    const currentDate = moment();
    const weekStart = currentDate.clone().startOf('isoWeek').toDate();
    const weekEnd = currentDate.clone().endOf('isoWeek').toDate();
    const up14 = upDate(14);
    const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const currMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const nextMonthStart = currentDate
      .clone()
      .add(1, 'M')
      .startOf('month')
      .toDate();
    const nextMonthEnd = currentDate
      .clone()
      .add(1, 'M')
      .endOf('month')
      .toDate();

    this.presets = [
      {
        presetLabel: this.translate.instant('GENERIC.TODAY'),
        range: { fromDate: today, toDate: today },
      },
      {
        presetLabel: this.translate.instant('DASHBOARD.THIS_WEEK'),
        range: { fromDate: weekStart, toDate: weekEnd },
      },
      {
        presetLabel: this.translate.instant('DASHBOARD.NEXT_2_WEEK'),
        range: { fromDate: today, toDate: up14 },
      },
      {
        presetLabel: this.translate.instant('DASHBOARD.THIS_MONTH'),
        range: { fromDate: currMonthStart, toDate: currMonthEnd },
      },
      {
        presetLabel: this.translate.instant('DASHBOARD.NEXT_MONTH'),
        range: { fromDate: nextMonthStart, toDate: nextMonthEnd },
      },
    ];
  }

  public openInNewTabContextMenu(event: any): void {
    this.openInNewTab(
      ItemUrlFinder.getItemURL({
        object: SearchResultsTypeEnum.ORDER,
        uuid: this.cmSelectedEvent.order_uuid,
        slug: this.cmSelectedEvent.order_slug,
        type: this.cmSelectedEvent.current_order_type,
      }),
    );
  }

  public onIncludeCancelAbortChange(): void {
    // //this.logger.info(
    //     "DashboardDetailedCalendarComponent >> this.includeCancelAbort: " +
    //         this.includeCancelAbort
    // );
    this.loadAllData();
  }

  public onRowSelect(event: any): void {
    // //this.logger.info("onRowSelect() -> event.data: " + JSON.stringify(event.data));
    this.router.navigateByUrl(ItemUrlFinder.getItemURL(event.data));
  }

  public onOpenOrder(data: any): void {
    ////this.logger.info(
    //  "onOpenOrder() -> event.data: " + JSON.stringify(data)
    //);
    this.router.navigateByUrl(
      ItemUrlFinder.getItemURL({
        object: SearchResultsTypeEnum.ORDER,
        uuid: data.order_uuid,
        slug: data.order_slug,
        type: data.current_order_type,
      }),
    );
  }

  public onOpenClient(data: any): void {
    // //this.logger.info("onOpenClient() -> event.data: " + JSON.stringify(data));
    this.router.navigateByUrl(
      ItemUrlFinder.getItemURL({
        object: SearchResultsTypeEnum.CLIENT,
        uuid: data.ordering_client_uuid,
        slug: data.ordering_client_slug,
      }),
    );
  }

  public onOpenInvoicingClient(data: any): void {
    // //this.logger.info("onOpenInvoicingClient() -> event.data: " + JSON.stringify(data));
    this.router.navigateByUrl(
      ItemUrlFinder.getItemURL({
        object: SearchResultsTypeEnum.CLIENT,
        uuid: data.invoicing_client_uuid,
        slug: data.invoicing_client_slug,
      }),
    );
  }

  public onOpenCongres(data: any): void {
    //this.logger.info("onOpenCongres() -> event.data: " + JSON.stringify(data));
    this.router.navigateByUrl(
      ItemUrlFinder.getItemURL({
        object: SearchResultsTypeEnum.CONGRESS,
        uuid: data.congress_uuid,
        slug: data.congress_slug,
      }),
    );
  }

  public onOpenProject(data: any): void {
    //this.logger.info("onOpenProject() -> event.data: " + JSON.stringify(data));
    this.router.navigateByUrl(
      ItemUrlFinder.getItemURL({
        object: SearchResultsTypeEnum.PROJECT,
        uuid: data.project_uuid,
        slug: data.project_slug,
      }),
    );
  }

  public onOpenEnterprise(data: any): void {
    // //this.logger.info("onOpenEnterprise() -> event.data: " + JSON.stringify(data));
    this.router.navigateByUrl(
      ItemUrlFinder.getItemURL({
        object: SearchResultsTypeEnum.ENTERPRISE,
        uuid: data.enterprise_uuid,
        slug: data.enterprise_slug,
      }),
    );
  }

  public onOpenEmployee(data: any): void {
    // //this.logger.info("onOpenEmployee() -> event.data: " + JSON.stringify(data));
    this.router.navigateByUrl(
      ItemUrlFinder.getItemURL({
        object: SearchResultsTypeEnum.EMPLOYEE,
        uuid: data.responsible_employee_uuid,
        slug: data.responsible_employee_slug,
      }),
    );
  }

  public onOpenRangePicker($event: any): void {
    // //this.logger.info("--> " + this.dateRangePicker);
    this.dateRangePicker?.openCalendar($event);
  }

  public onResetTable(): void {
    //this.logger.info("onResetTable()");
    localStorage.removeItem(
      DashboardDetailedComponent.LOCAL_STORED_SELECTED_COL_ID,
    );
    localStorage.removeItem(
      DashboardDetailedComponent.LOCAL_STORED_RANGE_FROM_ID,
    );
    localStorage.removeItem(
      DashboardDetailedComponent.LOCAL_STORED_RANGE_TO_ID,
    );
    localStorage.removeItem(
      DashboardDetailedComponent.LOCAL_STORED_DELIVERY_TYPE_SELECT_MODEL_ID,
    );
    localStorage.removeItem(
      DashboardDetailedComponent.LOCAL_STORED_STATUS_SELECT_MODEL_ID,
    );
    localStorage.removeItem(
      DashboardDetailedComponent.LOCAL_STORED_ORDER_STATUS_SELECT_MODEL_ID,
    );

    this.primengTable?.clearState();

    setTimeout(() => {
      window.location.reload();
    }, 50);
  }

  public onFilter(event: any): void {
    // //this.logger.info("event.filteredValue : " + JSON.stringify(event.filteredValue ));
    this.computeExtra(event.filteredValue);
  }

  public onColumnVisibilityChange(data: any): void {
    // //this.logger.info(
    //     "onColumnVisibilityChange() -> this.selectedColumns:  " +
    //         JSON.stringify(this.selectedColumns)
    // );

    localStorage.setItem(
      DashboardDetailedComponent.LOCAL_STORED_SELECTED_COL_ID,
      JSON.stringify(this.selectedColumns),
    );

    // //this.logger.info(
    //     "onColumnVisibilityChange() -> this.primengTable.filteredValue:  " +
    //         JSON.stringify(this.primengTable.filteredValue)
    // );
  }

  public rowUnselect(event: any): void {
    //this.logger.info("rowUnselect() -> event:  " + JSON.stringify(event));
  }

  public onGuestChange(event: any, dt: any): void {
    if (this.guestsSliderTimeout) {
      clearTimeout(this.guestsSliderTimeout);
    }

    if (event.value === 0) {
      this.guestsSliderValue = null;
    }

    // //this.logger.info("onGuestChange() -> event.value:  " + event.value);
    this.guestsSliderTimeout = setTimeout(() => {
      dt.filter(this.guestsSliderValue, 'guest_count', 'gt');
    }, 250);
  }

  public onDateClickTest($event: any, date: string): void {
    // //this.logger.info("onDateClickTest() -> ");
    // confirm("Ajouter un click 'Goto' dans une cellule par dessus le click du row! \n -> VOUS AVEX CLICKER SUR CETTE DATE: " + date);
  }

  public onDeliveryTypeChange(event: any): void {
    ////this.logger.info("onDeliveryTypeChange() -> event: " + event);
    ////this.logger.info("onDeliveryTypeChange() -> this.deliveryTypesModel: " + this.deliveryTypesModel);
    localStorage.setItem(
      DashboardDetailedComponent.LOCAL_STORED_DELIVERY_TYPE_SELECT_MODEL_ID,
      this.deliveryTypesModel,
    );
  }

  public onEventLogisticStatusChange(event: any): void {
    //this.logger.info("onEventLogisticStatusChange() -> event");
    //this.logger.info(event);
    localStorage.setItem(
      DashboardDetailedComponent.LOCAL_STORED_EVENT_LOGISTIC_STATUS_SELECT_MODEL_ID,
      this.eventLogisticStatusModel,
    );
    //List of matchMode
    //contains, notEquals, equals
    //https://primeng.org/filterservice
    if (event.value !== '0') {
      this.primengTable?.filter(
        event.value,
        'event_logistic_status.uuid',
        'equals',
      );
    } else {
      this.primengTable?.filter(
        event.value,
        'event_logistic_status.uuid',
        'notEquals',
      );
    }
  }

  public onStatusChange(event: any): void {
    //this.logger.info("onStatusChange() -> event: " + event);
    this.primengTable?.filter(event, 'status', 'equals');
    localStorage.setItem(
      DashboardDetailedComponent.LOCAL_STORED_STATUS_SELECT_MODEL_ID,
      this.statusModel,
    );
  }

  public onOrderStatusChange(event: any): void {
    // //this.logger.info("onOrderStatusChange() -> event: " + JSON.stringify(event.value));
    // //this.logger.info("onOrderStatusChange() -> this.deliveryTypesModel: " + this.deliveryTypesModel);

    // const filterType: string = (event.value === "ORDERS_") ? "in" : "equals";
    // //this.logger.info("onOrderStatusChange() -> filterType: " + filterType);
    // this.primengTable.filter(event.value, 'value', filterType);

    if (
      event.value === 'ORDER' ||
      event.value === 'PROPOSAL' ||
      event.value === 'INVOICE'
    ) {
      // //this.logger.info("onOrderStatusChange() -> IS ORDERS_: ");
      this.primengTable?.filter(event.value, 'status_order', 'startsWith');
    } else {
      this.primengTable?.filter(event.value, 'status_order', 'equals');
    }
    localStorage.setItem(
      DashboardDetailedComponent.LOCAL_STORED_ORDER_STATUS_SELECT_MODEL_ID,
      this.ordersStatusModel,
    );
  }

  public onGroupClick(group: any): void {
    // //this.logger.info("onGroupClick() -> group: " + JSON.stringify(group));
    // this.primengTable.filter(group.items, 'status_order', 'filter');
    // this.ordersStatusModel = group.label;
  }

  public getOrderTypeColor(rowData: any): string {
    let type: string = 'accent-800';
    if (rowData.current_order_type === OrderComponent.TYPE_ORDER) {
      type = 'accent';
    } else if (rowData.current_order_type === OrderComponent.TYPE_PROPOSAL) {
      type = 'primary';
    } else if (rowData.current_order_type === OrderComponent.TYPE_INVOICE) {
      type = 'warn-900';
    }

    return type;
  }

  public exportExcel() {
    //this.logger.info("exportExcel() -> : " + JSON.stringify(this.primeNGData));
    const xlsData = [];
    for (let entry of this.primeNGData) {
      let item: any = {};
      for (let key in entry) {
        if (this.primeNGColsField?.includes(key)) {
          this.setDateColumnsProperFormat(entry, key);
          item[key] = entry[key];
        }
      }
      xlsData.push(item);
    }

    xlsData.unshift(this.primeNGColsHeader);
    const worksheet = XLSX.utils.json_to_sheet(xlsData, {
      skipHeader: true,
    });

    this.setCurrencyRows(worksheet);
    //
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.saveAsExcelFile(excelBuffer, 'rapport');
  }

  public exportShownColummnExcel() {
    // //this.logger.info(
    //     "exportShownColummnExcel() -> : " + JSON.stringify(this.primeNGData)
    // );
    const colsHeader: any = {};
    const cols = this.selectedColumns?.map((item: any) => {
      colsHeader[item.field] = item.header;
      return item.field;
    });

    const xlsData = [];
    for (let entry of this.primeNGData) {
      let item: any = {};
      for (let key in entry) {
        if (cols?.includes(key)) {
          this.setDateColumnsProperFormat(entry, key);
          item[key] = entry[key];
        }
      }
      xlsData.push(item);
    }

    xlsData.unshift(colsHeader);
    const worksheet = XLSX.utils.json_to_sheet(xlsData, {
      skipHeader: true,
    });

    this.setCurrencyRows(worksheet);

    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.saveAsExcelFile(excelBuffer, 'rapport');
  }

  public setCurrencyRows(worksheet: any): void {
    const currencyCols: any = [];
    for (let z in worksheet) {
      const cellAdd = XLSX.utils.decode_cell(z.toString());
      const cell = XLSX.utils.encode_cell(cellAdd);
      const workCell = worksheet[XLSX.utils.encode_cell(cellAdd)];

      for (let entry of this.currencyColumns) {
        if (
          workCell &&
          entry.header === workCell.v &&
          !currencyCols.includes(cell)
        ) {
          currencyCols.push(cell);
        }
      }
    }

    for (let entry of currencyCols) {
      var col = XLSX.utils.decode_col(entry[0]);
      this.formatColumnAsCurrency(worksheet, col);
    }
  }

  public setDateColumnsProperFormat(entry: any, key: any): void {
    if (this.dateColumnsKeys.includes(key)) {
      const preFormat = DateFormatPreference.getDateFormat();
      var badFormat = moment(entry[key], preFormat, true);
      if (badFormat.isValid()) {
        entry[key] = badFormat.format('YYYY-MM-DD').toString();
      }
    }
  }

  public formatColumnAsCurrency(worksheet: any, col: any): void {
    var fmt = '$#,##0.00'; // '$0.00'; // or '"$"#,##0.00_);[Red]\\("$"#,##0.00\\)' or any Excel number format

    /* get worksheet range */
    var range = XLSX.utils.decode_range(worksheet['!ref']);
    for (var i = range.s.r + 1; i <= range.e.r; ++i) {
      /* find the data cell (range.s.r + 1 skips the header row of the worksheet) */
      var ref = XLSX.utils.encode_cell({ r: i, c: col });
      /* if the particular row did not contain data for the column, the cell will not be generated */
      if (!worksheet[ref]) continue;
      /* `.t == "n"` for number cells */
      // if(worksheet[ref].t != 'n') continue;
      /* assign the `.z` number format */
      worksheet[ref].z = fmt;
    }
  }

  public exportFilteredExcel() {
    // //this.logger.info("exportFilteredExcel() -> : " + JSON.stringify(this.primengTable.filteredValue));
    if (!this.primengTable?.filteredValue) {
      this.exportShownColummnExcel();
      return;
    }
    // //this.logger.info("exportFilteredExcel() -> selectedColumns: " + JSON.stringify(this.selectedColumns));
    const colsHeader: any = {};
    const cols = this.selectedColumns?.map((item: any) => {
      colsHeader[item.field] = item.header;
      return item.field;
    });

    const xlsData = [];
    for (let entry of this.primengTable.filteredValue) {
      let item: any = {};
      for (let key in entry) {
        if (cols?.includes(key)) {
          this.setDateColumnsProperFormat(entry, key);
          item[key] = entry[key];
        }
      }
      xlsData.push(item);
    }

    xlsData.unshift(colsHeader);

    const worksheet = XLSX.utils.json_to_sheet(xlsData, {
      skipHeader: true,
    });

    this.setCurrencyRows(worksheet);

    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.saveAsExcelFile(excelBuffer, 'rapport');
  }

  public saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION,
    );
  }

  // public onNewProposal(): void {
  //     //this.logger.info("DashboardCalendarComponent.onNewProposal()");
  //     // //this.logger.info("DashboardCalendarComponent.onNewProposal() -> ******: " + this.addressComponent.selectedDay.date);
  //     this.dialogRef = this.dialog.open(CreateEventComponent, {
  //         width: "45%",
  //         disableClose: false,
  //         data: {
  //             subType: CreateEventComponent.PROPOSAL_SUBTYPE,
  //         },
  //     });

  //     this.dialogRef.afterClosed().subscribe((result) => {
  //         //this.logger.info(
  //             "DashboardCalendarComponent.onNewEvent() -> result: " +
  //             JSON.stringify(result)
  //         );
  //         if (result) {
  //             this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
  //         }

  //         this.dialogRef = null;
  //     });
  // }

  // public onCreateOrder(): void {
  //     this.dialogRef = this.dialog.open(CreateEventComponent, {
  //         width: "45%",
  //         disableClose: false,
  //         data: {
  //             subType: CreateEventComponent.ORDER_SUBTYPE,
  //         },
  //     });

  //     this.dialogRef.afterClosed().subscribe((result) => {
  //         //this.logger.info(
  //             "DashboardCalendarComponent.onCreateOrder() -> result: " +
  //             JSON.stringify(result)
  //         );
  //         if (result) {
  //             this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
  //         }

  //         this.dialogRef = null;
  //     });
  // }

  public onQuickOrder(): void {
    //this.logger.info("DashboardCalendarComponent.onQuickOrder()");
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = false;
    this.confirmDialogRef.componentInstance.alertTitle = 'Commande rapide';
    this.confirmDialogRef.componentInstance.alertMessage = 'À venir!';

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        this.confirmDialogRef = null;
      },
    });
  }

  //
  public onCreateNewCongress(): void {
    //this.logger.info("CongressesComponent.onCreateNewCongress() -> event: " + event);
    this.dialogRef = this.dialog.open(CreateCongresDialogComponent, {
      width: '440px',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      maxHeight: '96vh',
      disableClose: false,
      data: { autoRedirect: true },
    });
    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          // this.reloadTable();
        }
        this.dialogRef = null;
      },
    });
  }

  public onGoToCongresses(): void {
    this.router.navigateByUrl('/' + AppRoutes.CONGRESSES);
  }
}

export interface FilterSubjectModel {
  value: string;
  field: any;
  filterMatchMode: any;
}
