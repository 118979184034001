<div style="max-height: 100%; overflow: auto">
  <h1 matDialogTitle>{{ "ROOMS.ATTACH_ROOM" | translate }}</h1>

  <div *ngIf="subRooms && subRooms.length > 0">
    <mat-form-field class="roomw-select" fxFlex>
      <mat-label>Sous-Salles</mat-label>
      <mat-select [(ngModel)]="selectedRoom">
        <mat-option *ngFor="let subroom of subRooms" [value]="subroom">
          <span class="iso">{{ subroom.name }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div
    *ngIf="(!subRooms || (subRooms && subRooms.length === 0)) && !loading"
    fxLayout="row"
    fxLayoutAlign="center center"
    class="mat-body-2 pt-12 pb-12"
  >
    {{ "ROOMS.NO_ROOM_TO_ATTACH" | translate }}
  </div>

  <div
    mat-dialog-actions
    class="mt-16 gt2-buttons-theme"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button
      mat-raised-button
      [disabled]="!selectedRoom || loading"
      color="accent"
      class="mr-16"
      (click)="onAttachRoom(selectedRoom)"
    >
      {{ "GENERIC.ADD" | translate }}
    </button>

    <button
      mat-raised-button
      color="primary"
      (click)="compDialogRef.close(false)"
      class=""
    >
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
