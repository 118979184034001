import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { GT2FormPageAbstract } from '../../abstract/GT2FormPageAbstract';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { ProductModel, ProductsService } from '../../../api/products.service';
import { locale as english } from '../i18n/en-CA';
import { locale as french } from '../i18n/fr-CA';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { Title } from '@angular/platform-browser';
import { Gt2ApiService } from '../../../api/gt2-api.service';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { CreateQuestionComponent } from './create-question/create-question.component';
import { Question, UpsaleService } from '../../../api/upsale.service';
import { Subscription } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { RolesService } from '../../../services/roles.service';
import { QuestionState } from './upsale-question-item/upsale-question-item.component';

@Component({
  selector: 'app-product-upsale',
  templateUrl: './product-upsale.component.html',
  styleUrls: ['./product-upsale.component.scss'],
  animations: fuseAnimations,
})
export class ProductUpsaleComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  product?: ProductModel;
  productID?: string;
  loading?: boolean;
  questions!: Question[];
  formEnTab?: UntypedFormGroup;
  formEnTabValid = true;
  formEnTabValidSub?: Subscription;
  isLoading: boolean = false;
  confirmDialogRef: any = null;
  groupsOpened: string[] = [];

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    private productsService: ProductsService,
    private titleService: Title,
    private api: Gt2ApiService,
    public router: Router,
    private dialog: MatDialog,
    private logger: NGXLogger,
    public location: Location,
    public upsaleService: UpsaleService,
    public rolesService: RolesService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
  }

  ngOnInit() {
    //this.titleService.setTitle(this.translate.instant("PRODUCTS.UPSALE"));
    ///this.route.params.subscribe((params) => {
    //   this.productID = params.id;
    //  this.loading = true;
    //  this.product = null;
    // this.loadProduct();
    //});
    //
    this.loadQuestions();
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  public onGroupStateChange(state: QuestionState): void {
    this.groupsOpened = this.groupsOpened.filter((uuid) => {
      return uuid !== state.uuid;
    });
    if (state.state) {
      this.groupsOpened.push(state.uuid);
    }
  }

  public loadQuestions(): void {
    this.loading = true;
    this.upsaleService.getQuestions().subscribe({
      next: (response: any) => {
        //this.logger.info("ProductUpsaleComponent.loadQuestions() -> SUCCESS: " + JSON.stringify(response.data));
        this.questions = response.data;
        this.loading = false;
        //
        // start with the first question open
        if (this.questions[0]) {
          this.groupsOpened.push(this.questions[0].uuid);
        }
      },
      error: (error: any) => {
        this.logger.error(
          'ProductUpsaleComponent.loadQuestions() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public loadProduct(): void {
    this.loading = true;
    this.productsService.getProduct(this.productID).subscribe({
      next: (response: any) => {
        //this.logger.info("ProductUpsaleComponent.loadProduct() -> SUCCESS: ");
        // //this.logger.info(
        //     "ProductUpsaleComponent.loadProduct() -> SUCCESS: " +
        //         JSON.stringify(response)
        // );
        const isFirstLoad: boolean =
          this.product === null || this.product === undefined;

        this.product = response.data;
        this.loading = false;
        //this.titleService.setTitle(this.translate.instant("PRODUCTS.UPSALE") + " " + this.product.name);

        if (isFirstLoad) {
          setTimeout(() => {
            // this.initRichText();
          }, 500);
        }
      },
      error: (error: any) => {
        this.logger.error(
          'ProductUpsaleComponent.loadProduct() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public onChooseProduct(): void {
    // //this.logger.info('ProductUpsaleComponent.onChooseProduct()');
    // this.dialogRef = this.dialog.open(UpsaleOptionsComponent, {
    //     width: "440px",
    //     maxHeight: "96vh",
    //     disableClose: false,
    //     data: { product: this.product },
    // });
    // this.dialogRef.afterClosed().subscribe((result) => {
    //     if (result) {
    //         //
    //     }
    //     this.dialogRef = null;
    // });
  }

  public onAddNewQuestion(): void {
    //this.logger.info("ProductUpsaleComponent.onAddNewQuestion()");
    this.dialogRef = this.dialog.open(CreateQuestionComponent, {
      width: '440px',
      minWidth: 350,
      maxHeight: '96vh',
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: { product: this.product },
    });
    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          //
          this.dialogRef.close(true);
          this.loadQuestions();
        }
        this.dialogRef = null;
      },
    });
  }

  // public onCreateOptionsGroup(question: any):void {
  //     //this.logger.info(
  //         "ProductUpsaleComponent.onCreateOptionsGroup():: Question =>" +
  //             JSON.stringify(question)
  //     );
  //     const dialogRef = this.dialog.open(CreateOptionsGroupComponent, {
  //         width: "440px",
  //         maxHeight: "96vh",
  //         disableClose: false,
  //         data: { question: question },
  //     });

  //     dialogRef.afterClosed().subscribe((result) => {
  //         if (result) {
  //             //
  //             this.loadQuestions();
  //             this.dialogRef.close(true);
  //         }
  //         this.dialogRef = null;
  //     });

  // }

  public onGeneralTabChange(index: any): void {
    // //this.logger.info("onGeneralTabChange() " + index);
    if (index === 1) {
      setTimeout(() => {
        // this.generalTabGenerated = true;
        this.formEnTabValidSub = this.formEnTab?.statusChanges.subscribe({
          next: (status) => {
            // //this.logger.info("formEnTab.statusChanges() " + status);
            this.formEnTabValid = status === 'VALID';
            // //this.logger.info(
            //     "formEnTab.statusChanges() " + this.formEnTabValid
            // );
          },
        });
      }, 50);
    } else {
      this.formEnTabValidSub?.unsubscribe();
    }
  }

  // public onEditOptionsGroup(group: any, uuid: string): void {
  //    // //this.logger.info("onEditOptionsGroup");
  //    if(group.type == GT2ConstantsEnum.GROUP_PRODUCTS){
  //        var dialogRef = this.dialog.open(CreateUpsaleOptionsComponent, {
  //            width: "94%",
  //            disableClose: false,
  //            panelClass: "custom-dialog-container",
  //            data: {
  //                questionUUID: uuid,
  //                group: group,
  //                type: GT2ConstantsEnum.GROUP_PRODUCTS,
  //                mode: "EDIT",
  //            },
  //        });
  //        dialogRef.afterClosed().subscribe((result) => {
  //            if (result) {
  //                //
  //                dialogRef.close(true);
  //                this.loadQuestions();
  //            }
  //            dialogRef = null;
  //        });
  //    }else{
  //        this.onEditOptionsMaterialsGroup(group, uuid);
  //    }

  // }

  // public onEditOptionsMaterialsGroup(group: any, uuid: string): void {
  //     // //this.logger.info("onEditOptionsGroup");
  //     var dialogRef = this.dialog.open(CreateUpsaleMaterialsComponent, {
  //         width: "94%",
  //         disableClose: false,
  //         panelClass: "custom-dialog-container",
  //         data: {
  //             questionUUID: uuid,
  //             group: group,
  //             type: GT2ConstantsEnum.GROUP_MATERIALS,
  //             mode: "EDIT",
  //         },
  //     });
  //     dialogRef.afterClosed().subscribe((result) => {
  //         if (result) {
  //             //
  //             dialogRef.close(true);
  //             this.loadQuestions();
  //         }
  //         dialogRef = null;
  //     });
  // }

  public onRemoveOptionsGroup(uuid: string): void {
    //this.logger.info("onRemoveOptionsGroup");
    this.upsaleService.deleteGroupOptions(uuid).subscribe({
      next: (response: any) => {
        //this.logger.info("onRemoveOptionsGroup -> SUCCESS>>: " + JSON.stringify(response));
        this.isLoading = false;
        this.loadQuestions();
      },
      error: (error: any) => {
        this.logger.error(
          'onRemoveOptionsGroup -> ERROR: ' + JSON.stringify(error),
        );
        this.isLoading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  // public onDeleteQuestion(uuid: string): void {
  //     //this.logger.info("onDeleteQuestion");
  //     this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
  //         disableClose: false,
  //     });

  //     this.confirmDialogRef.componentInstance.useCancel = true;
  //     this.confirmDialogRef.componentInstance.alertTitle =
  //         this.translate.instant("GENERIC.DELETE");
  //     this.confirmDialogRef.componentInstance.alertMessage =
  //         this.translate.instant("GENERIC.DELETE_CONFIRM");

  //     this.confirmDialogRef.afterClosed().subscribe((result) => {
  //         if(result) {
  //             this.doDeleteQuestion(uuid);
  //         }

  //     });

  // }

  // public doDeleteQuestion(uuid: string): void {
  //     this.upsaleService.deleteQuestion(uuid).subscribe(
  //         (response) => {
  //             //this.logger.info(
  //                 "onDeleteQuestion -> SUCCESS>>: " +
  //                 JSON.stringify(response)
  //             );
  //             this.isLoading = false;
  //             this.loadQuestions();
  //         },
  //         (error) => {
  //             this.logger.error(
  //                 "onDeleteQuestion -> ERROR: " + JSON.stringify(error)
  //             );
  //             this.isLoading = false;
  //             this.handleAPIError(error, this.dialog, null, null);
  //         }
  //     );
  // }
}
