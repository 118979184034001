import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Editor } from 'primeng/editor';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { CrudDatatableGenericService } from '../../../api/crud-datatable-generic.service';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { Settings } from '../../../settings/settings';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-generic-create-editor',
  templateUrl: './generic-create-editor.component.html',
  styleUrls: ['./generic-create-editor.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    InputTextareaModule,
    TranslateModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
  ],
  providers: [CrudDatatableGenericService],
})
export class GenericCreateEditorComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('editor') editor!: Editor;
  @ViewChild('editorEN') editorEN!: Editor;
  serviceURL!: string;
  createPlaceholder!: string;
  dialogTitle!: string;
  useEnglish: boolean = false;
  form!: UntypedFormGroup;
  model: any;
  loading: boolean = false;
  newCategory_fr_CA!: any;
  newCategory_en_CA!: any;
  hasText: boolean = false;
  hasTextEN: boolean = false;

  constructor(
    public override dialogRef: MatDialogRef<GenericCreateEditorComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private crudDatatableGenericService: CrudDatatableGenericService,
    public dialog: MatDialog,
    public router: Router,
    private toastr: ToastrService,
    private formBuilder: UntypedFormBuilder,
    private logger: NGXLogger,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    this.form = this.formBuilder.group({
      newCategory_fr_CA: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharLong),
          Validators.required,
        ],
      ],
      newCategory_en_CA: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharLong),
          Validators.required,
        ],
      ],
    });

    // this.form = this.formBuilder.group({
    //     name_fr_CA: ['', [Validators.minLength(Settings.inputMinChar), Validators.maxLength(Settings.inputMaxChar), Validators.required]],
    //     name_en_CA: ['', [Validators.minLength(Settings.inputMinChar), Validators.maxLength(Settings.inputMaxChar)]],
    // });

    if (data) {
      this.dialogTitle = data.dialogTitle;
      this.serviceURL = data.serviceURL;
      this.createPlaceholder = data.createPlaceholder;

      if (data.useEnglish !== null) {
        this.useEnglish = data.useEnglish;
      }
    }

    // this.model = {
    //     name: null
    // };
  }

  ngOnInit() {}

  ngAfterViewInit() {
    // this.editor.onTextChange.debounceTime(250)
    //     .distinctUntilChanged().subscribe(data => {
    //     this.logger.info("1 ... " + data.htmlValue);
    //     this.hasText = (data.htmlValue.length > 2);
    // });
    // this.editorEN.onTextChange.debounceTime(250)
    //     .distinctUntilChanged().subscribe(data => {
    //     this.logger.info("2 ... " + data.htmlValue);
    //     this.hasTextEN = (data.htmlValue.length > 2);
    // });
  }

  ngOnDestroy() {}

  public onCreateNew() {
    // this.logger.info("GenericCreateComponent.onCreateNew()");

    // this.logger.info("GenericCreateComponent.onCreateNew() - this.newCategory_fr_CA: " + this.newCategory_fr_CA);
    // this.logger.info("GenericCreateComponent.onCreateNew() - this.newCategory_en_CA: " + this.newCategory_en_CA);
    // this.logger.info("GenericCreateComponent.onCreateNew() - this.serviceURL: " + this.serviceURL);
    this.crudDatatableGenericService
      .create(
        this.serviceURL,
        this.newCategory_fr_CA,
        this.newCategory_en_CA,
        this.useEnglish,
      )
      .subscribe({
        next: (response: any) => {
          // this.logger.info("GenericCreateComponent.onCreateNew() -> SUCCESS:" + JSON.stringify(response));
          this.toastr.success(
            this.translate.instant('GENERIC_CREATE.CREATE_SUCCESS_TOAST'),
          );
          this.newCategory_fr_CA = null;
          this.newCategory_en_CA = null;
          this.dialogRef.close(response.data);
          // this.form.markAsUntouched();
          // this.form.markAsPristine();
          // this.reloadData();
        },
        error: (error: any) => {
          this.logger.error(
            'GenericCreateComponent.onCreateNew() -> ERROR' +
              JSON.stringify(error),
          );
          // this.toastr.error(this.translate.instant('GENERIC_CREATE.CREATE_ERROR_TOAST'));
          // this.toastr.error("", this.translate.instant("GENERIC.ERRORS.GENERIC"));
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.GENERIC'),
          );
        },
      });
  }
}
