import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NGXLogger } from 'ngx-logger';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { CreateClientService } from '../../../api/create-client.service';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import { Router } from '@angular/router';
import { Settings } from '../../../settings/settings';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { Gt2LocaleService } from '../../../../localization/gt2-locale.service';
import { locale as englishAddress } from '../../address/i18n/en-CA';
import { locale as frenchAddress } from '../../address/i18n/fr-CA';
import { locale as englishContact } from '../../contact/i18n/en-CA';
import { locale as frenchContact } from '../../contact/i18n/fr-CA';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Gt2SelectComponent } from '../../gt2-select/gt2-select.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { EditorModule } from 'primeng/editor';

@Component({
  selector: 'app-create-client-enterprise-with-details',
  templateUrl: './create-client-enterprise-with-details.component.html',
  styleUrls: ['./create-client-enterprise-with-details.component.scss'],
  standalone: true,
  imports: [
    MatInputModule,
    CommonModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    FlexLayoutModule,
    EditorModule,
    Gt2SelectComponent,
    MatButtonModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
})
export class CreateClientEnterpriseWithDetailsComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit
{
  form: UntypedFormGroup;
  form2: UntypedFormGroup;
  clientEnterprise: any;
  loading: boolean = false;
  type!: string;
  uuid!: string;
  userSettings: any = {
    inputPlaceholderText: '',
    showRecentSearch: true,
  };
  noGeoLocation: boolean = false;
  addressPrefillDirty: boolean = false;
  noGeoLocation2: boolean = false;
  addressPrefillDirty2: boolean = false;
  @ViewChild('autocompleteInput', { static: false })
  autocompleteInput!: ElementRef;
  //@ts-ignore
  autocomplete!: google.maps.places.Autocomplete;
  @ViewChild('autocompleteInput2', { static: false })
  autocompleteInput2!: ElementRef;
  //@ts-ignore
  autocomplete2!: google.maps.places.Autocomplete;
  constructor(
    public override dialogRef: MatDialogRef<CreateClientEnterpriseWithDetailsComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    private createClientService: CreateClientService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private formBuilder2: UntypedFormBuilder,
    public dialog: MatDialog,
    public router: Router,
    private toastr: ToastrService,
    private logger: NGXLogger,
    public gt2LocalService: Gt2LocaleService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    this.translationLoader.loadTranslations(englishAddress, frenchAddress);
    this.translationLoader.loadTranslations(englishContact, frenchContact);
    this.userSettings.inputPlaceholderText = this.translate.instant(
      'GENERIC.GOOGLE_ADDRESS_PLACEHOLDER',
    );

    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      first_name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      last_name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      professional_email: ['', [Validators.email]],
      telephone_office: [
        '',
        [Validators.minLength(2), Validators.maxLength(100)],
      ],
      telephone_office_ext: [
        '',
        [Validators.minLength(2), Validators.maxLength(20)],
      ],
      street_number: [
        '',
        [
          Validators.minLength(1),
          Validators.maxLength(25),
          Validators.required,
        ],
      ],
      street: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      apartment: '',
      line2: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
        ],
      ],
      city: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      province: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      country: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      postal_code: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
    });

    this.form2 = this.formBuilder2.group({
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      first_name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      last_name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      professional_email: ['', [Validators.email]],
      telephone_office: [
        '',
        [Validators.minLength(2), Validators.maxLength(100)],
      ],
      telephone_office_ext: [
        '',
        [Validators.minLength(2), Validators.maxLength(20)],
      ],
      street_number: [
        '',
        [
          Validators.minLength(1),
          Validators.maxLength(25),
          Validators.required,
        ],
      ],
      street: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      apartment: '',
      line2: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
        ],
      ],
      city: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      province: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      country: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      postal_code: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
    });

    this.clientEnterprise = {
      client: {
        first_name: null,
        last_name: null,
        note: null,
        contact: {
          email_organisation: null,
          telephone_office: null,
          telephone_office_ext: null,
        },
        address: {
          street_number: null,
          street: null,
          apartment: null,
          line2: null,
          city: null,
          province: null,
          country: null,
          postal_code: null,
        },
      },
      enterprise: {
        name: null,
        note: null,
        contact: {
          email_organisation: null,
          telephone_office: null,
          telephone_office_ext: null,
        },
        address: {
          street_number: null,
          street: null,
          apartment: null,
          line2: null,
          city: null,
          province: null,
          country: null,
          postal_code: null,
        },
      },
    };
  }

  ngOnInit() {}
  ngAfterViewInit() {
    this.initializeAutocomplete();
    this.initializeAutocomplete2();
  }
  initializeAutocomplete(): void {
    //@ts-ignore
    this.autocomplete = new google.maps.places.Autocomplete(
      this.autocompleteInput.nativeElement,
      {
        types: ['geocode'],
        componentRestrictions: { country: 'ca' },
      },
    );

    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete.getPlace();
      if (place && place.address_components) {
        this.noGeoLocation = false;
        this.extractAddressComponents(place);
        this.addressPrefillDirty = true;
      } else {
        this.noGeoLocation = true;
        return;
      }
    });
  }

  extractAddressComponents(
    //@ts-ignore
    place: google.maps,
  ): any {
    //@ts-ignore
    let addressComponentsClient: google.maps.GeocoderAddressComponent[] =
      place.address_components;
    let street_numberClient = '';
    let streetClient = '';
    let cityClient = '';
    let countryClient = '';
    let postal_codeClient = '';
    let provinceClient = '';
    let full_addressClient = place.formatted_address;
    let latClient = place.geometry.location.lat();
    let longClient = place.geometry.location.lng();
    addressComponentsClient.forEach((component) => {
      const types = component.types;
      if (types.includes('street_number')) {
        street_numberClient = component.long_name;
      } else if (types.includes('route')) {
        streetClient = component.long_name;
      } else if (types.includes('locality')) {
        cityClient = component.long_name;
      } else if (types.includes('country')) {
        countryClient = component.long_name;
      } else if (types.includes('postal_code')) {
        postal_codeClient = component.long_name;
      } else if (types.includes('administrative_area_level_1')) {
        provinceClient = component.long_name;
      }
    });

    this.clientEnterprise.client.address = {
      ...this.clientEnterprise.client.address,
      street_number: street_numberClient,
      street: streetClient,
      city: cityClient,
      country: countryClient,
      postal_code: postal_codeClient,
      province: provinceClient,
      full_address: full_addressClient,
      lat: latClient,
      long: longClient,
    };
  }

  initializeAutocomplete2(): void {
    //@ts-ignore
    this.autocomplete2 = new google.maps.places.Autocomplete(
      this.autocompleteInput2.nativeElement,
      {
        types: ['geocode'],
        componentRestrictions: { country: 'ca' },
      },
    );

    this.autocomplete2.addListener('place_changed2', () => {
      const place2 = this.autocomplete2.getPlace();
      if (place2 && place2.address_components) {
        this.noGeoLocation2 = false;
        this.extractAddressComponents2(place2);
        this.addressPrefillDirty2 = true;
      } else {
        this.noGeoLocation2 = true;
        return;
      }
    });
  }

  extractAddressComponents2(
    //@ts-ignore
    place2: google.maps,
  ): any {
    //@ts-ignore
    let addressComponentsEntreprise: google.maps.GeocoderAddressComponent[] =
      place2.address_components;
    let street_numberEntreprise = '';
    let streetEntreprise = '';
    let cityEntreprise = '';
    let countryEntreprise = '';
    let postal_codeEntreprise = '';
    let provinceEntreprise = '';
    let full_addressEntreprise = place2.formatted_address;
    let latEntreprise = place2.geometry.location.lat();
    let longEntreprise = place2.geometry.location.lng();
    addressComponentsEntreprise.forEach((component) => {
      const types = component.types;
      if (types.includes('street_number')) {
        street_numberEntreprise = component.long_name;
      } else if (types.includes('route')) {
        streetEntreprise = component.long_name;
      } else if (types.includes('locality')) {
        cityEntreprise = component.long_name;
      } else if (types.includes('country')) {
        countryEntreprise = component.long_name;
      } else if (types.includes('postal_code')) {
        postal_codeEntreprise = component.long_name;
      } else if (types.includes('administrative_area_level_1')) {
        provinceEntreprise = component.long_name;
      }
    });

    this.clientEnterprise.enterprise.address = {
      ...this.clientEnterprise.enterprise.address,
      street_number: street_numberEntreprise,
      street: streetEntreprise,
      city: cityEntreprise,
      country: countryEntreprise,
      postal_code: postal_codeEntreprise,
      province: provinceEntreprise,
      full_address: full_addressEntreprise,
      lat: latEntreprise,
      long: longEntreprise,
    };
  }

  public onCreateClientEnterprise(): void {
    this.loading = true;
    this.createClientService
      .createClientAndEnterprise(this.clientEnterprise)
      .subscribe({
        next: (response: any) => {
          // this.logger.info("CreateClientComponent.onCreateClient() -> SUCCESS: " + JSON.stringify(response));
          this.toastr.success(
            '',
            this.translate.instant('GENERIC.API_CALL_SUCCESS.CREATE_CLIENT'),
          );
          this.dialogRef.close(response.data);
          this.loading = false;
          // this.router.navigateByUrl(
          //    ItemUrlFinder.getItemURL(response.data)
          //);
        },
        error: (error: any) => {
          this.logger.error(
            'CreateClientComponent.onCreateClient() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          // this.toastr.error("", this.translate.instant("GENERIC.ERRORS.CREATE_CLIENT"));
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.CREATE_CLIENT'),
          );
        },
      });
  }

  public onLocalSelected(event: any): void {
    this.clientEnterprise.client.communication_local = event;
    // this.autosaveDataChange({
    //     communication_local: this.client.communication_local,
    // });
  }
}
