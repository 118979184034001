import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CalendarModule as AngularCalendarModule } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import { OrdersService } from '../../api/orders.service';
import { CreateEventComponent } from '../../components/dialogs/create-event/create-event.component';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { GT2DateAdapter } from '../../utils/GT2DateAdapter';
import { DashboardCalendarComponent } from './components/dashboard-calendar/dashboard-calendar.component';
import { DashboardComponent } from './dashboard.component';
import { CreateOrderComponent } from '../../components/dialogs/create-order/create-order.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { TableUnsortDirective } from '../../directives/table-unsort-directive.directive';
import { NotesService } from '../../api/notes.service';
import { AppRoutesName } from '../../const/AppRoutesName';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { CreateCongressesButtonComponent } from '../../components/create-congresses-button/create-congresses-button.component';
import { CreateOrderButtonsComponent } from '../../components/create-order-buttons/create-order-buttons.component';
import { GenericAlertComponent } from '../../components/generic-alert/generic-alert.component';
import { DashboardNoteComponent } from './components/dashboard-note/dashboard-note.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MatRippleModule } from '@angular/material/core';
import { TagsByModelService } from '../../components/tags-by-model/tags.-by-model.service';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import moment from 'moment';

const routes = [
  {
    path: AppRoutesName.FR.DASHBOARD,
    component: DashboardComponent,
  },
  {
    path: AppRoutesName.FR.DASHBOARD_DATE,
    component: DashboardComponent,
  },
  {
    path: AppRoutesName.EN.DASHBOARD,
    component: DashboardComponent,
  },
  {
    path: AppRoutesName.EN.DASHBOARD_DATE,
    component: DashboardComponent,
  },
];

function momentAdapterFactory() {
  return adapterFactory(moment);
}
@NgModule({
  imports: [
    TranslateModule,
    MatSelectModule,
    CreateEventComponent,
    FormsModule,
    MatProgressSpinnerModule,
    DashboardCalendarComponent,
    MatButtonModule,
    MatInputModule,
    NgxPermissionsModule,
    MatRippleModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatIconModule,
    NgxSpinnerModule,
    CreateOrderComponent,
    GenericAlertComponent,
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    Gt2PrimengModule,
    RouterModule.forChild(routes),
    AngularCalendarModule.forRoot({
      provide: GT2DateAdapter,
      useFactory: momentAdapterFactory,
    }),
    NgxMaterialTimepickerModule,
    CreateOrderButtonsComponent,
    CreateCongressesButtonComponent,
    DashboardNoteComponent,
  ],
  declarations: [DashboardComponent, TableUnsortDirective],
  exports: [],
  providers: [OrdersService, NotesService, TagsByModelService],
})
export class DashboardModule {}
