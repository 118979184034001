import { Settings } from './../../../settings/settings';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { LocaleHelperService } from './../../../services/locale-helper.service';
import { ShopsService } from './../../../api/shops.service';
import { NGXLogger } from 'ngx-logger';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Gt2PrimengModule } from '../../../modules/gt2-primeng.module';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FusePipesModule } from '../../../../../@fuse/pipes/pipes.module';
import { FuseDirectivesModule } from '../../../../../@fuse/directives/directives';

@Component({
  selector: 'app-point-takeout',
  templateUrl: './point-takeout.component.html',
  styleUrls: ['./point-takeout.component.scss'],
  standalone: true,
  imports: [
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    Gt2PrimengModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
  ],
})
export class PointTakeoutComponent implements OnInit {
  form: UntypedFormGroup | any;
  point: any;
  loading: boolean = false;
  shopUUID?: string;
  editMode: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<PointTakeoutComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private logger: NGXLogger,
    private shopsService: ShopsService,
    private formBuilder: UntypedFormBuilder,
    public localeHelperService: LocaleHelperService,
  ) {
    if (data && data.shopUUID !== null) {
      this.shopUUID = data.shopUUID;
      //this.logger.info("PointTakeoutComponent.constructor() -> shopUUID: " + this.shopUUID);
    }

    if (data && data.point) {
      this.editMode = true;
      this.point = {
        uuid: data.point.uuid,
        name_fr_CA: data.point.name_local.name_fr_CA,
        name_en_CA: data.point.name_local.name_en_CA,
        shop_uuid: this.shopUUID,
      };
      //this.logger.info("PointTakeoutComponent.constructor() -> editMode: " + this.editMode);
      //this.logger.info("PointTakeoutComponent.constructor() -> point: " + JSON.stringify(data.point));
    } else {
      this.point = {
        uuid: null,
        name_fr_CA: null,
        name_en_CA: null,
        shop_uuid: this.shopUUID,
      };
    }
  }

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      name_fr_CA: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      name_en_CA: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
    });
  }

  public onCreatePoint(): void {
    //this.logger.info("PointTakeoutComponent.onCreatePoint() -> point: " + JSON.stringify(this.point));
    this.loading = true;

    if (this.editMode) {
      this.shopsService.editMultiPointTakeout(this.point).subscribe({
        next: (response: any) => {
          //this.logger.info("PointTakeoutComponent.onCreatePoint() -> SUCCESS: " + JSON.stringify(response));
          this.loading = false;
          this.dialogRef.close(true);
        },
        error: (error: any) => {
          this.logger.error(
            'PointTakeoutComponent.onCreatePoint() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
        },
      });
    } else {
      this.shopsService.createMultiPointTakeout(this.point).subscribe({
        next: (response: any) => {
          //this.logger.info("PointTakeoutComponent.onCreatePoint() -> SUCCESS: " + JSON.stringify(response));
          this.loading = false;
          this.dialogRef.close(true);
        },
        error: (error: any) => {
          this.logger.error(
            'PointTakeoutComponent.onCreatePoint() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
        },
      });
    }
  }
}
