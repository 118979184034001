import { HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { GT2FirebaseService } from './gt2-firebase.service';

@Injectable()
export class PrintService {
  private httpClientBypass: HttpClient;
  loadingPrint: boolean = false;
  currentPrint: any[] = [];

  constructor(
    private http: HttpClient,
    private handler: HttpBackend,
    private logger: NGXLogger,
  ) {
    this.httpClientBypass = new HttpClient(handler);
  }

  // public processPrintPDF(url: string): any
  // {
  //     this.logger.info("PrintService.processPrintPDF() -> url: " + url);
  //     return new Promise((resolve, reject) => {
  //         this.currentPrint.push(url);
  //         this.http.get<ArrayBuffer>(url, {responseType: 'arraybuffer' as 'json'})
  //             .do(() => this.loadingPrint = false)
  //             .subscribe((response) => {
  //                     const file = new Blob([response], {type: 'application/pdf'});
  //                     const fileURL = URL.createObjectURL(file);
  //                     const iframe = document.createElement('iframe');
  //                     iframe.style.display = 'none';
  //                     iframe.src = fileURL; // secureURL;
  //                     document.body.appendChild(iframe);
  //                     iframe.contentWindow.print();
  //                     this.removeCurrentPrint(url);
  //                     resolve(response);
  //                 },
  //                 error => {
  //                     this.loadingPrint = false;
  //                     this.logger.info('PrintService.processPrintPDF() -> ERROR: ' + JSON.stringify(error));
  //                     this.removeCurrentPrint(url);
  //                     reject(error);
  //                 });
  //     });
  // }

  public processPrintPDF(url: string): any {
    this.logger.info('PrintService.processPrintPDF() -> url: ' + url);
    return new Promise((resolve, reject) => {
      this.currentPrint.push(url);
      this.httpClientBypass
        .get<ArrayBuffer>(url, {
          responseType: 'arraybuffer' as 'json',
        })
        .pipe(tap(() => (this.loadingPrint = false)))
        .subscribe({
          next: (response) => {
            const file = new Blob([response], {
              type: 'application/pdf',
            });
            const fileURL = URL.createObjectURL(file);
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.src = fileURL; // secureURL;
            document.body.appendChild(iframe);
            iframe.contentWindow?.print();
            this.removeCurrentPrint(url);
            resolve(response);
          },
          error: (error) => {
            this.loadingPrint = false;
            this.logger.error(
              'PrintService.processPrintPDF() -> ERROR: ' +
                JSON.stringify(error),
            );
            this.removeCurrentPrint(url);
            reject(error);
          },
        });
    });
  }

  // TODO: OLD PRINT, NOT IN USE ANYMORE
  public printPDF(url: string): void {
    this.logger.info('PrintService.printPDF() ' + url);
    this.loadingPrint = true;
    this.http
      .get<ArrayBuffer>(url, { responseType: 'arraybuffer' as 'json' })
      .pipe(tap(() => (this.loadingPrint = false)))
      .subscribe({
        next: (response) => {
          const file = new Blob([response], {
            type: 'application/pdf',
          });
          // const file = new Blob([response], {type: '"plain/text"'});
          const fileURL = URL.createObjectURL(file);
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = fileURL; // secureURL;
          document.body.appendChild(iframe);
          iframe.contentWindow?.print();
        },
        error: (error) => {
          this.loadingPrint = false;
          this.logger.error(
            'PrintService.printPDF() -> ERROR: ' + JSON.stringify(error),
          );
          // TODO: HANDLE ERROR
        },
      });
  }

  public printHTML(url: string): any {
    //this.logger.info("PrintService.printHTML()");
    const newURL: string = url + '/' + GT2FirebaseService.CLIENT_KEY;
    //this.logger.info("PrintService.printHTML() -> newURL with clienKey: " + newURL);
    return this.http.get(newURL);
  }

  // public printHTML( url: string ): void
  // {
  //     this.logger.info("PrintService.printHTML()");
  //     this.http.get(url).subscribe( response =>
  //     {
  //         this.logger.info("PrintService.printHTML() -> SUCCESS: " + JSON.stringify(response));
  //     }, error =>
  //     {
  //         this.logger.error("PrintService.printHTML() -> ERROR: " + JSON.stringify(error));
  //         // TODO: HANDLE ERROR

  //     });
  // }

  // public postPrintHTML( url: string, data: any ): void
  // {
  //     this.logger.info("PrintService.postPrintHTML() -> data: " + JSON.stringify(data));
  //     this.http.post(url, data).subscribe( response =>
  //     {
  //         this.logger.info("PrintService.postPrintHTML() -> SUCCESS: " + JSON.stringify(response));
  //     }, error =>
  //     {
  //         this.logger.error("PrintService.postPrintHTML() -> ERROR: " + JSON.stringify(error));
  //         // TODO: HANDLE ERROR
  //     });
  // }

  public postPrintHTML(url: string, data: any): any {
    //this.logger.info("PrintService.postPrintHTML() -> data: " + JSON.stringify(data));
    const newURL: string = url + '/' + GT2FirebaseService.CLIENT_KEY;
    //this.logger.info("PrintService.downloadFile() -> newURL with clienKey: " + newURL);
    return this.http.post(newURL, data);
  }

  public processPrintHTML(url: string): any {
    //this.logger.info("PrintService.processPrintHTML()");
    // this.loadingPrint = true;

    if (this.currentPrint.indexOf(url) !== -1) {
      this.logger.error(
        'PrintService.processPrintHTML() -> ERROR!! PRINTING FILE IS ALREADY PRINTING, url: ' +
          url,
      );
      throw new Error('ERROR!! PRINTING FILE IS ALREADY PRINTING, url: ' + url);
      // return throwError(new Error());
      // return;
    }

    return new Promise((resolve, reject) => {
      this.currentPrint.push(url);
      this.http
        .get<ArrayBuffer>(url, {
          responseType: 'arraybuffer' as 'json',
        })
        .pipe(tap(() => (this.loadingPrint = false)))
        .subscribe({
          next: (response) => {
            const file = new Blob([response], {
              type: 'text/html',
            });
            const fileURL = URL.createObjectURL(file);
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.src = fileURL; // secureURL;
            document.body.appendChild(iframe);
            iframe.contentWindow?.print();
            this.removeCurrentPrint(url);
            resolve(response);
          },
          error: (error) => {
            this.loadingPrint = false;
            this.logger.error(
              'PrintService.processPrintHTML() -> ERROR: ' +
                JSON.stringify(error),
            );
            this.removeCurrentPrint(url);
            reject(error);
          },
        });
    });
  }

  public processPrintPdf(url: string): any {
    //this.logger.info("PrintService.processPrintPdf()");
    if (this.currentPrint.indexOf(url) !== -1) {
      this.logger.error(
        'PrintService.processPrintPdf() -> ERROR!! PRINTING FILE IS ALREADY PRINTING, url: ' +
          url,
      );
      throw new Error('ERROR!! PRINTING FILE IS ALREADY PRINTING, url: ' + url);
    }

    return new Promise((resolve, reject) => {
      this.currentPrint.push(url);
      this.loadingPrint = true;
      this.http
        .get<ArrayBuffer>(url, {
          responseType: 'arraybuffer' as 'json',
        })
        .pipe(tap(() => (this.loadingPrint = false)))
        .subscribe({
          next: (response) => {
            const file = new Blob([response], {
              type: 'application/pdf',
            });
            // const file = new Blob([response], {type: '"plain/text"'});
            const fileURL = URL.createObjectURL(file);
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.src = fileURL; // secureURL;
            document.body.appendChild(iframe);
            iframe.contentWindow?.print();
            this.removeCurrentPrint(url);
            resolve(response);
          },
          error: (error) => {
            this.loadingPrint = false;
            this.logger.error(
              'PrintService.processPrintPdf() -> ERROR: ' +
                JSON.stringify(error),
            );
            this.removeCurrentPrint(url);
            reject(error);
          },
        });
    });
  }

  public removeCurrentPrint(fileURL: string): void {
    const index: number = this.currentPrint.indexOf(fileURL);
    if (index !== -1) {
      this.currentPrint.splice(index, 1);
    }
  }
}
