import { Component, TemplateRef, OnInit, ViewChild } from '@angular/core';
import { BenchingDialogComponent } from '../../../gt2/benching/benching-dialog/benching-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LocalizationConfig } from '../../../localization/LocalizationConfig';
import { SettingsModel } from '../../../gt2/settings/SettingsModel';
import { ResponsesStatus } from '../../../gt2/api/ResponsesStatus';
import { SettingsService } from '../../../gt2/settings/settings.service';
import { NGXLogger } from 'ngx-logger';
import { GT2FirebaseService } from '../../../services/gt2-firebase.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import * as moment from 'moment-timezone';
import { Gt2ApiService } from '../../../gt2/api/gt2-api.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { NgxPermissionsModule } from 'ngx-permissions';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [
    RouterModule,
    TranslateModule,
    MatButtonModule,
    MatInputModule,
    MatMenuModule,
    NgxPermissionsModule,
    MatIconModule,
    MatToolbarModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
  ],
})
export class FooterComponent implements OnInit {
  readonly environment = environment;

  @ViewChild('firebaseDialogTemplate', { static: false })
  firebaseDialogTemplate!: TemplateRef<any>;
  dialogRef: any;
  currentYear: any;
  setting?: SettingsModel;
  facebookURL?: string;

  // TODO: add LinkedIn url in model and use it from there
  linkedInURL?: string =
    'https://www.linkedin.com/company/gestion-traiteur-com/';
  instagramInURL: string = 'https://www.instagram.com/gestiontraiteur/';
  firebaseSubscription: Subscription;
  firebaseConnected: boolean = false;
  timezone: string;
  isSmallScreen?: boolean;

  /**
   * Constructor
   */
  constructor(
    private _dialog: MatDialog,
    public translate: TranslateService,
    public logger: NGXLogger,
    public firebaseService: GT2FirebaseService,
    public api: Gt2ApiService,
    private settings: SettingsService,
    private breakpointObserver: BreakpointObserver,
  ) {
    this.currentYear = new Date().getFullYear();

    //
    this.settings.loadSettings().subscribe({
      next: (response) => {
        // this.logger.info('FooterComponent.constructor().settings() -> SUCCESS: ' + JSON.stringify(response));
        if (response.response_status === ResponsesStatus.OK) {
          this.setting = response.response;
          if (
            this.translate.currentLang === LocalizationConfig.DEFAULT_LANGUAGE
          ) {
            this.facebookURL = this.setting?.social_facebook_fr;
            this.linkedInURL = this.setting?.social_linkedin_fr;
          } else {
            this.facebookURL = this.setting?.social_facebook_en;
            this.linkedInURL = this.setting?.social_linkedin_en;
          }
        }
      },
      error: (error) => {
        this.logger.error(
          'FooterComponent.constructor().settings() -> ERRORS: ' +
            JSON.stringify(error),
        );
      },
    });

    this.firebaseSubscription = this.firebaseService.onProcesses.subscribe({
      next: (processes: any[]) => {
        // this.logger.info('FooterComponent.constructor() -> ***** processes: ' + JSON.stringify(processes));
        if (processes !== null) {
          this.firebaseConnected = true;
        }
      },
    });

    //
    this.timezone = moment.tz.guess();
  }
  ngOnInit(): void {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe({
      next: (result) => {
        this.isSmallScreen = result.matches;
      },
    });
  }
  public onOpenAPICallBenching(): void {
    //this.logger.info("FuseFooterComponent.onOpenAPICallBenching()");
    // this.openBench.next();
    this.dialogRef = this._dialog.open(BenchingDialogComponent, {
      height: '600px',
      minWidth: '780px',
      panelClass: 'scrollable-dialog',
      maxWidth: '100px',
      // maxHeight: '75%',
      disableClose: false,
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
        }
        this.dialogRef = null;
      },
    });
  }

  public onShowFirebaseStatus(): void {
    this.dialogRef = this._dialog.open(this.firebaseDialogTemplate);
  }

  public onSwitchLocalAPI(key: string): void {
    if (key === Gt2ApiService.API_TARGET_LOCAL) {
      this.api.setLocalAPI();
    } else if (key === Gt2ApiService.API_TARGET_DEV) {
      this.api.setDevAPI();
    } else if (key === Gt2ApiService.API_TARGET_DEV2) {
      this.api.setDev2API();
    }
    // this.api.toggleLocalAPI();
  }
}
