import { RoomsModelData } from './../../api/models/RoomsModel';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ItemUrlFinder } from '../../utils/item-url-finder';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { of, merge, Subscription } from 'rxjs';
import { startWith, map, switchMap, catchError } from 'rxjs/operators';
import { fuseAnimations } from '../../../../@fuse/animations/index';
import { DatatableHelperService } from '../../../services/datatable-helper.service';
import { FileService } from '../../../services/file.service';
import { PrintService } from '../../../services/print.service';
import { Gt2ApiService } from '../../api/gt2-api.service';
import { CreateRoomComponent } from '../../components/dialogs/create-room/create-room.component';
import { GenericAlertComponent } from '../../components/generic-alert/generic-alert.component';
import { AppRoutesService } from '../../services/app-routes.service';
import { RolesService } from '../../services/roles.service';
import { GT2PageAbstract } from '../abstract/GT2PageAbstract';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { RoomsService } from './rooms.service';
import { AttachRoomComponent } from '../../components/dialogs/attach-room/attach-room.component';
import { LocalizationConfig } from '../../../localization/LocalizationConfig';

@Component({
  selector: 'app-rooms',
  templateUrl: './rooms.component.html',
  styleUrls: ['./rooms.component.scss'],
  animations: fuseAnimations,
})
export class RoomsComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  static PARENT_TYPE_PLACE: string = 'PLACE';
  static PARENT_TYPE_ENTERPRISE: string = 'ENTERPRISE';

  @Input() useDataCollection: boolean = false; // has sub rooms
  @Input() roomDataCollection!: RoomsModelData[];
  @Output() roomDataCollectionChange = new EventEmitter<RoomsModelData[]>();
  @Output() roomsServiceURLChange = new EventEmitter<any>();
  @Input() roomsServiceURL: any;
  @Input() headerLabel!: string;
  @Input() parentType!: string;
  @Input() parentUUID: any;
  @Output() parentUUIDChange = new EventEmitter<any>();
  @Input() editMode: boolean = false;
  @Input() parentIsSubRoom: boolean = false;
  @Input() breadCrumbName!: string;
  @Input() disabledAddSubRoom: boolean = false;
  @Input() showUnlinkButton: boolean = true;
  @Output() modelUpdatedEvent = new EventEmitter<RoomsModelData>();
  roomURLSegment!: string;
  visible: boolean = true;
  confirmDialogRef: any;
  displayedColumns = ['name', 'options_button'];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('table') table!: MatTable<any>;
  filterValue: string = '';
  dataSource: any = new MatTableDataSource();
  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;
  isFirstLoad: boolean = true;
  tableIsEmpty: boolean = false;
  loading: boolean = false;

  // --o Subscription
  getEmployeesCalendarSubscription?: Subscription;
  fromEventSubscription?: Subscription;
  confirmDialogRefSubscription?: Subscription;
  resendInvitationSubscription?: Subscription;

  constructor(
    private roomsService: RoomsService,
    public dialog: MatDialog,
    public router: Router,
    private toastr: ToastrService,
    public datatableHelperService: DatatableHelperService,
    public print: PrintService,
    public fileService: FileService,
    public translate: TranslateService,
    public appRoutesService: AppRoutesService,
    private api: Gt2ApiService,
    public rolesService: RolesService,
    private logger: NGXLogger,
  ) {
    super();
    // Initialisé la langue
    const local_lang =
      localStorage.getItem(LocalizationConfig.LOCAL_STORED_LANG_ID) ||
      LocalizationConfig.DEFAULT_LANGUAGE;

    // Ajouter les traductions locales
    if (local_lang === 'en-CA')
      translate.setTranslation(english.lang, english.data, true);
    else translate.setTranslation(french.lang, french.data, true);

    // Définir la langue à utiliser
    translate.setDefaultLang(LocalizationConfig.DEFAULT_LANGUAGE);
    translate.use(local_lang);
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.setUpRooms();
  }

  ngOnDestroy() {
    // if (this.getEmployeesCalendarSubscription)
    // {
    //     this.getEmployeesCalendarSubscription.unsubscribe();
    // }

    if (this.fromEventSubscription) {
      this.fromEventSubscription.unsubscribe();
    }

    if (this.confirmDialogRefSubscription) {
      this.confirmDialogRefSubscription.unsubscribe();
    }

    if (this.resendInvitationSubscription) {
      this.resendInvitationSubscription.unsubscribe();
    }
  }

  public setUpRooms() {
    // //this.logger.info("setUpRooms()" + this.useDataCollection);
    // //this.logger.info(
    //     "setUpRooms()" + JSON.stringify(this.roomDataCollection)
    // );
    this.roomURLSegment = this.appRoutesService.rooms;
    if (this.useDataCollection) {
      this.dataSource.data = this.roomDataCollection;
      this.roomURLSegment = this.appRoutesService.sub_rooms;
      if (this.parentIsSubRoom) {
        this.roomURLSegment = this.appRoutesService.sub_rooms2;
      }
      //this.logger.info("setUpRooms()" + this.roomURLSegment);
      return;
    }
    // //this.logger.info("setUpRooms()" + this.roomURLSegment);

    // If the user changes the sort order, reset back to the first page.
    // //this.logger.info("RoomsComponent.setUpRooms() this.sort: " + this.sort);

    this.sort.sortChange.subscribe({
      next: () => (this.paginator.pageIndex = 0),
    });
    // //this.logger.info("RoomsComponent.setUpRooms() ");

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(startWith(null))
      .pipe(
        switchMap(() => {
          setTimeout(() => {
            // //this.logger.info("RoomsComponent.setUpRooms().setTimeout() ");
            this.isLoadingResults = true;
          });
          // //this.logger.info("RoomsComponent.setUpRooms().switchMap() ");
          return this.roomsService.getRoomsByIDWithPagination(
            this.roomsServiceURL,
            this.sort?.active,
            this.sort?.direction,
            this.paginator?.pageIndex,
            this.paginator?.pageSize,
            this.filterValue,
          );
        }),
      )
      .pipe(
        map((data) => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.meta.pagination.total;
          this.isFirstLoad = false;
          this.tableIsEmpty = data.data.length === 0;
          return data.data;
        }),
      )
      .pipe(
        catchError(() => {
          setTimeout(() => {
            // //this.logger.info("RoomsComponent.setUpRooms().catch()  ");
            this.isLoadingResults = false;
            this.isRateLimitReached = true;
          });
          return of([]);
        }),
      )
      .subscribe({
        next: (data) => {
          this.dataSource.data = data;
          // //this.logger.info("RoomsComponent.setUpRooms().subscribe() -> data: "  + JSON.stringify(data));
        },
      });

    // this.fromEventSubscription = fromEvent(this.filter.nativeElement, 'keyup')
    //     .pipe(
    //         debounceTime(200),
    //         distinctUntilChanged(),
    //         tap(() => {
    //             if (this.filter.nativeElement.value.length > 2 || this.filter.nativeElement.value === '')
    //             {
    //                 this.paginator.pageIndex = 0;
    //                 this.paginator._changePageSize(this.paginator.pageSize);
    //             }
    //         })
    //     )
    //     .subscribe();
  }

  public bindDataSource(newRoomDataCollection: RoomsModelData[]): void {
    //this.logger.info("bindDataSource() -> " + JSON.stringify(newRoomDataCollection));
    if (this.useDataCollection) {
      this.roomDataCollection = newRoomDataCollection;
      this.roomDataCollectionChange.emit(newRoomDataCollection);
      this.dataSource.data = this.roomDataCollection;
      return;
    }
  }

  public reset(parentUUID: string, roomsServiceURL: string): void {
    this.parentUUID = parentUUID;
    this.parentUUIDChange.emit(parentUUID);
    this.roomsServiceURL = roomsServiceURL;
    this.roomsServiceURLChange.emit(roomsServiceURL);
    // //this.logger.info("RoomsComponent.setUpRooms() -> roomsServiceURL: " + this.roomsServiceURL);
    // //this.logger.info("RoomsComponent.setUpRooms() -> parentUUID: " + this.parentUUID);
    this.setUpRooms();
  }

  public clearSearch(): void {
    this.filterValue = '';
    this.paginator.pageIndex = 0;
    this.paginator?._changePageSize(this.paginator.pageSize);
  }

  public onCreateNewRoom(event: any): void {
    // this.router.navigateByUrl("employee/create");
  }

  public onDeleteRoom(uuid: string): void {
    // //this.logger.info("RoomsComponent.onDeleteRoom()");

    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });
    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');

    this.confirmDialogRef.componentInstance.confirmMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');

    this.confirmDialogRefSubscription = this.confirmDialogRef
      .afterClosed()
      .subscribe({
        next: (result: any) => {
          if (result) {
            this.roomsService.deleteRoom({ uuid: uuid }).subscribe({
              next: (response) => {
                // //this.logger.info("RoomsComponent.onDeleteRoom() -> SUCCESS: " + JSON.stringify(response));
                this.toastr.success(
                  this.translate.instant('GENERIC.DELETE_SUCCESS'),
                );
                this.reloadData();
              },
              error: (error) => {
                this.logger.error(
                  'RoomsComponent.onDeleteVenue() -> ERROR: ' +
                    JSON.stringify(error),
                );
                // this.toastr.error('', this.translate.instant("GENERIC.ERRORS.GENERIC"));
                this.handleAPIError(
                  error,
                  this.dialog,
                  this.toastr,
                  this.translate.instant('GENERIC.ERRORS.GENERIC'),
                );
              },
            });
          }
          this.confirmDialogRef = null;
        },
      });
  }

  public onAddNewRoom(): void {
    //this.logger.info("RoomsComponent.onAddNewRoom() -> this.parentUUID: " + this.parentUUID);
    this.confirmDialogRef = this.dialog.open(CreateRoomComponent, {
      width: '440px',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: {
        parentType: this.parentType,
        parentUUID: this.parentUUID,
        isSubRoom: this.useDataCollection,
      },
    });

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          // //this.logger.info("RoomsComponent.onAddNewRoom() -> result:  " + JSON.stringify(result));
          this.router.navigateByUrl(ItemUrlFinder.getItemURL(result.data));
        }
        this.confirmDialogRef = null;
      },
    });
  }

  public onAttachRoom(): void {
    //this.logger.info("RoomsComponent.onAttachRoom() -> this.parentUUID: " + this.parentUUID);

    this.confirmDialogRef = this.dialog.open(AttachRoomComponent, {
      width: '440px',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: {
        // parentType: this.parentType,
        parentUUID: this.parentUUID,
        // isSubRoom: this.useDataCollection,
      },
    });

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          // //this.logger.info("RoomsComponent.onAttachRoom() -> result:  " + JSON.stringify(result));
          // this.router.navigateByUrl(
          //     ItemUrlFinder.getItemURL(result.data)
          // );
          // this.roomDataCollection.push(result);
          // this.dataSource.data = this.roomDataCollection;
          this.modelUpdatedEvent.next(result);
        }
        this.confirmDialogRef = null;
      },
    });
  }

  public onDetachRoom(room: any): void {
    //this.logger.info("AttachRoomComponent.onDetachRoom() -> room: " + JSON.stringify(room));
    this.onDetachSubRoom(room);
  }

  public onDetachSubRoom(subroom: any): void {
    this.loading = true;
    this.roomsService.detachSubRoom(this.parentUUID, subroom.uuid).subscribe({
      next: (response) => {
        //this.logger.info("AttachRoomComponent.onDetachSubRoom() -> SUCCESS: " + JSON.stringify(response));
        this.loading = false;
        // this.reloadData();
        this.modelUpdatedEvent.next(response.data);
      },
      error: (error) => {
        this.logger.error(
          'AttachRoomComponent.onDetachSubRoom() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public reloadData(): void {
    this.paginator?._changePageSize(this.paginator.pageSize);
  }

  public doPrint(url: string): void {
    this.print.printHTML(url).subscribe({
      next: (response: any) => {
        // //this.logger.info("RoomsComponent.doPrint() -> SUCCESS: " + JSON.stringify(response));
      },
      error: (error: any) => {
        this.logger.error(
          'RoomsComponent.doPrint() -> ERROR: ' + JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public doDownload(url: string): void {
    this.fileService.downloadFile(url).subscribe({
      next: (response: any) => {
        // //this.logger.info("RoomsComponent.doDownload() -> SUCCESS: " + JSON.stringify(response));
      },
      error: (error: any) => {
        this.logger.error(
          'RoomsComponent.doDownload() -> ERROR: ' + JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public onPrint(): void {
    const url: string = this.api.createUrl('/reports/rooms/html');
    // this.print.printHTML(url);
    this.doPrint(url);
  }

  public onOpenInNewTab(): void {
    window.open(this.router.url, '_blank');
  }

  public onDownloadPDF(): void {
    const url: string = this.api.createUrl('/reports/rooms/pdf');
    // const fileName: string = this.fileService.createFileName(null, "rooms", "pdf");
    // this.fileService.downloadFile(url, fileName);
    this.doDownload(url);
  }

  public onDownloadVCard(): void {
    // //this.logger.info("onDownloadVCard() ");
    // const url: string = this.api.createUrl('/reports/rooms/vcard');
    // window.location.href = url;
  }

  public onDownloadXLS(): void {
    // //this.logger.info("onDownloadVCard() ");

    const url: string = this.api.createUrl('/reports/rooms/xls');
    // const fileName: string = this.fileService.createFileName(null, "rooms", "xls");
    // this.fileService.downloadFile(url, fileName);
    this.doDownload(url);
  }
}
