<div style="max-height: 100%; overflow: auto">
  <div matDialogTitle *ngIf="mode === 'CREATE'" class="event-dialog-title">
    {{ "CREATE_TAKEOUT_REQUEST.TITLE" | translate }}
  </div>
  <div matDialogTitle *ngIf="mode === 'EDIT'" class="event-dialog-title">
    {{ "CREATE_TAKEOUT_REQUEST.EDIT_TITLE" | translate }}
  </div>

  <div fxLayout="column" fxLayoutAlign="center start">
    <div
      fxLayout="row"
      fxLayoutAlign="center start"
      [formGroup]="form"
      (keyup.enter)="
        form.valid &&
        !loading &&
        takeoutRequest.type &&
        takeoutRequest.wrapping &&
        takeoutRequest.content &&
        takeoutRequest.takeout_from_time &&
        takeoutRequest.takeout_from_time !== 'Invalid DateTime' &&
        takeoutRequest.takeout_to_time &&
        takeoutRequest.takeout_to_time !== 'Invalid DateTime'
          ? onCreateTakeoutRequest()
          : null
      "
      class="item-full-width"
    >
      <div fxFlex="100" fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field fxFlex="100%" class="item-full-width mb-4 mr-4">
          <mat-label>{{ "GENERIC.NAME" | translate }}</mat-label>
          <input
            matInput
            fxFlex="100%"
            autocomplete="nope"
            formControlName="name"
            type="text"
            [(ngModel)]="takeoutRequest.name"
            required
          />
          <mat-error *ngIf="form.controls['name'].invalid">{{
            "GENERIC.INPUT_LENGTH_INVALID" | translate
          }}</mat-error>
        </mat-form-field>
      </div>
    </div>

    <!-- CHECKBOXES -->

    <div
      fxLayout="row"
      fxLayoutAlign="start start"
      fxFlex="100"
      class="item-full-width mt-12 mb-8"
    >
      <div fxFlex="100" fxLayout="column" fxLayoutAlign="center start">
        <mat-checkbox
          labelPosition="after"
          color="accent"
          [(ngModel)]="takeoutRequest.show_on_purchase_order_production"
          [ngModelOptions]="{ standalone: true }"
          class=""
        >
          {{
            "CREATE_TAKEOUT_REQUEST.SHOW_ON_PURCHASE_ORDER_PRODUCTION"
              | translate
          }}
        </mat-checkbox>

        <mat-checkbox
          labelPosition="after"
          color="accent"
          [(ngModel)]="takeoutRequest.show_on_purchase_order_client_copy"
          [ngModelOptions]="{ standalone: true }"
        >
          {{
            "CREATE_TAKEOUT_REQUEST.SHOW_ON_PURCHASE_ORDER_CLIENT_COPY"
              | translate
          }}
        </mat-checkbox>

        <mat-checkbox
          labelPosition="after"
          color="accent"
          [(ngModel)]="takeoutRequest.show_on_client_proposal_and_order"
          [ngModelOptions]="{ standalone: true }"
        >
          {{
            "CREATE_TAKEOUT_REQUEST.SHOW_ON_CLIENT_PROPOSAL_AND_ORDER"
              | translate
          }}
        </mat-checkbox>

        <mat-checkbox
          labelPosition="after"
          color="accent"
          [(ngModel)]="takeoutRequest.show_on_purchase_order_kitchen"
          [ngModelOptions]="{ standalone: true }"
          class=""
        >
          {{
            "CREATE_TAKEOUT_REQUEST.SHOW_ON_PURCHASE_ORDER_KITCHEN" | translate
          }}
        </mat-checkbox>
        <mat-checkbox
          labelPosition="after"
          color="accent"
          [(ngModel)]="takeoutRequest.show_on_purchase_material"
          [ngModelOptions]="{ standalone: true }"
        >
          {{ "CREATE_TAKEOUT_REQUEST.SHOW_ON_PURCHASE_MATERIAL" | translate }}
        </mat-checkbox>
      </div>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="start start"
      fxFlex="100"
      class="item-full-width"
    >
      <div
        [formGroup]="form"
        fxFlex="50"
        fxLayout="column"
        fxLayoutAlign="center start"
      >
        <mat-form-field fxFlex="100%" class="item-full-width mr-8">
          <mat-label>{{ "GENERIC.DATE_START" | translate }}</mat-label>
          <input
            matInput
            [matDatepicker]="deliveryFromDate"
            [(ngModel)]="takeoutRequest.takeout_from_date"
            min="1900-01-01"
            autocomplete="nope"
            formControlName="takeout_from_date"
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="deliveryFromDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #deliveryFromDate></mat-datepicker>
          <mat-error *ngIf="form.controls['takeout_from_date'].invalid"
            >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="100%" class="item-full-width mr-8">
          <mat-label>{{ "GENERIC.DATE_END" | translate }}</mat-label>
          <input
            matInput
            [matDatepicker]="deliveryToDate"
            [(ngModel)]="takeoutRequest.takeout_to_date"
            min="1900-01-01"
            autocomplete="nope"
            formControlName="takeout_to_date"
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="deliveryToDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #deliveryToDate></mat-datepicker>

          <mat-error *ngIf="form.controls['takeout_to_date'].invalid"
            >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="50" fxLayout="column" fxLayoutAlign="center start">
        <div
          class="item-full-width ml-8 ngx-timepicker-field-from"
          fxFlex="100%"
        >
          <div class="timepicker-custom-label">
            {{ "GENERIC.TIME" | translate }}*
          </div>
          <ngx-timepicker-field
            [buttonAlign]="'left'"
            [format]="24"
            [(ngModel)]="takeoutRequest.takeout_from_time"
            class="ngx-material-timepicker-toggle"
          >
          </ngx-timepicker-field>
        </div>

        <div class="item-full-width ml-8 ngx-timepicker-field-to" fxFlex="100%">
          <div class="timepicker-custom-label">
            {{ "GENERIC.TIME" | translate }}*
          </div>
          <ngx-timepicker-field
            [buttonAlign]="'left'"
            [format]="24"
            [(ngModel)]="takeoutRequest.takeout_to_time"
            class="ngx-material-timepicker-toggle"
          >
          </ngx-timepicker-field>
        </div>
      </div>
    </div>

    <div
      *ngIf="initGt2Select"
      fxLayout="row"
      fxLayoutAlign="start start"
      fxFlex="100"
      [formGroup]="form"
      class="item-full-width"
    >
      <div fxFlex="33" fxLayout="row" fxLayoutAlign="start start">
        <app-gt2-select
          #wrapping
          [service]="wrappingService"
          [nullableUseNone]="false"
          [placeHolder]="'GENERIC.WRAPPING' | translate"
          [label]="'ORDERS.WRAPPING' | translate"
          [selectedModel]="takeoutRequest.wrapping"
          (changeEvent)="onWrappingSelected($event)"
          [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
          [isRequired]="true"
          fxFlex="100%"
          class="pr-4"
        ></app-gt2-select>
      </div>
      <div fxFlex="33" fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field class="pr-4" fxFlex="100%">
          <mat-label>{{ "GENERIC.TYPE" | translate }}</mat-label>
          <mat-select
            [(ngModel)]="takeoutRequest.type"
            [ngModelOptions]="{ standalone: true }"
            (selectionChange)="onTakeoutRequestTypeChange($event)"
            required
          >
            <mat-option value="TYPE_CLIENT_TAKEOUT">{{
              "CREATE_TAKEOUT_REQUEST.CLIENT_TAKEOUT" | translate
            }}</mat-option>
            <mat-option value="TYPE_CLIENT_RETURN">{{
              "CREATE_TAKEOUT_REQUEST.BACK" | translate
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="33" fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field class="" fxFlex="100%">
          <mat-label>{{ "GENERIC.CONTENT" | translate }}</mat-label>
          <mat-select
            [(ngModel)]="takeoutRequest.content"
            [ngModelOptions]="{ standalone: true }"
            (selectionChange)="onTakeoutRequestContentChange($event)"
            required
          >
            <mat-option [value]="'CONTENT_FOOD'">{{
              "CREATE_TAKEOUT_REQUEST.FOOD" | translate
            }}</mat-option>
            <mat-option [value]="'CONTENT_MATERIAL'">{{
              "CREATE_TAKEOUT_REQUEST.MATERIAL" | translate
            }}</mat-option>
            <mat-option [value]="'CONTENT_FOOD_MATERIAL'">{{
              "CREATE_TAKEOUT_REQUEST.FOOD_MATERIAL" | translate
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div fxFlex="100%" class="item-full-width mt-8 note-section">
      <p-editor
        #noteEditor
        [(ngModel)]="takeoutRequest.note"
        [style]="{
          height: '74px',
          width: '100%',
          'background-color': '#ffffff',
        }"
      >
        <ng-template pTemplate="header" class="">
          <span><b>Note | </b></span>
          <span class="ql-formats">
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
            <button class="ql-underline"></button>
            <button class="ql-strike"></button>
          </span>

          <span class="ql-formats">
            <select class="ql-color"></select>
            <select class="ql-background"></select>
          </span>

          <span class="ql-formats">
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
            <button class="ql-indent" value="-1"></button>
            <button class="ql-indent" value="+1"></button>
          </span>
        </ng-template>
      </p-editor>
      <mat-error
        *ngIf="noteInvalid"
        fxLayout="row"
        fxLayoutAlign="end center"
        class="p-4 pr-8"
      >
        {{ "GENERIC.NOTE_EDITOR_LENGTH_INVALID_2048" | translate }}
      </mat-error>
    </div>
  </div>

  <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center start">
    <mat-spinner [diameter]="40" class="mt-20"></mat-spinner>
  </div>

  <div
    mat-dialog-actions
    class="mt-16 gt2-buttons-theme"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button
      mat-raised-button
      [disabled]="
        !form.valid ||
        loading ||
        !takeoutRequest.type ||
        !takeoutRequest.wrapping ||
        !takeoutRequest.content ||
        !takeoutRequest.takeout_from_time ||
        takeoutRequest.takeout_from_time === 'Invalid DateTime' ||
        !takeoutRequest.takeout_to_time ||
        takeoutRequest.takeout_to_time === 'Invalid DateTime'
      "
      color="accent"
      class="mr-16"
      (click)="onCreateTakeoutRequest()"
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>

    <button
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(false)"
      class=""
    >
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
