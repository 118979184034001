import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DeliveryRequestContentService } from '../../../api/delivery-request-content.service';
import { DeliveryRequestTypeService } from '../../../api/delivery-request-type.service';
import { DeliveryRequestService } from '../../../api/delivery-request.service';
import { WrappingService } from '../../../api/wrapping.service';
import { ValidatorHelper } from '../../../validators/ValidatorHelper';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Editor } from 'primeng/editor';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import { Settings } from '../../../settings/settings';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { GT2DateAdapter } from '../../../utils/GT2DateAdapter';
import { DeliveryRequestTemplateService } from '../../../api/delivery-request-template.service';
//import * as _ from 'lodash';
import { ModulesService } from '../../../services/modules.service';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatButtonModule } from '@angular/material/button';
import { Gt2SelectComponent } from '../../gt2-select/gt2-select.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { EventsService } from '../../../api/events.service';
import { EventTypeService } from '../../../api/event-type.service';
import { EventComposeService } from '../../../api/event-compose.service';
import { EventAttachedFilesService } from '../../../api/event-attached-files.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { CalendarDateFormatter } from 'angular-calendar';
import { CustomDateFormatter } from '../../../content/calendar/CustomDateFormatter';

@Component({
  selector: 'app-create-delivery-request',
  templateUrl: './create-delivery-request.component.html',
  styleUrls: ['./create-delivery-request.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    Gt2SelectComponent,
    FormsModule,
    MatDatepickerModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    MatDialogModule,
    FlexLayoutModule,
    MatCheckboxModule,
    MatButtonModule,
    NgxMaterialTimepickerModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  providers: [
    EventsService,
    EventAttachedFilesService,
    EventTypeService,
    EventComposeService,
    { provide: DateAdapter, useClass: GT2DateAdapter },
    { provide: CalendarDateFormatter, useClass: CustomDateFormatter },
  ],
})
export class CreateDeliveryRequestComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  static CREATE: string = 'CREATE';
  static EDIT: string = 'EDIT';

  //
  mode: string = CreateDeliveryRequestComponent.CREATE;
  form: UntypedFormGroup;
  deliveryRequest: any;
  deliveryRequestTemplate: any;
  eventUUID!: string;
  event: any;
  loading: boolean = false;
  autoRedirect: boolean = false;
  noteInvalid: boolean = false;
  noteEditorSubscription?: Subscription;
  noteEditordebounceTimeValue: number = 1500;
  @ViewChild('noteEditor') noteEditor?: Editor;
  deliveryRequests?: any[];
  selectedDeliveryRequest: any;
  deliveryRequestToUpdate: any;
  deliveryRequestData: any;
  initGt2Select: boolean = false;
  templatesEmpty: boolean = false;

  constructor(
    public override dialogRef: MatDialogRef<CreateDeliveryRequestComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    public deliveryRequestTypeService: DeliveryRequestTypeService,
    public deliveryRequestContentService: DeliveryRequestContentService,
    public deliveryRequestTemplateService: DeliveryRequestTemplateService,
    public wrappingService: WrappingService,
    private deliveryRequestService: DeliveryRequestService,
    //private atp: any,
    public dialog: MatDialog,
    public router: Router,
    private adapter: DateAdapter<any>,
    private toastr: ToastrService,
    private logger: NGXLogger,
    public modulesService: ModulesService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    this.adapter.setLocale(this.translate.currentLang);

    this.form = this.formBuilder.group({
      // eventDate: ['', [ValidatorHelper.dateRangeDefault]],
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      delivery_from_date: ['', [ValidatorHelper.dateRangeDefault]],
      delivery_to_date: ['', [ValidatorHelper.dateRangeDefault]],
      // delivery_from_time: ['', [Validators.required]],
      // delivery_to_time: ['', [Validators.required]],
      /*contact_person_name: [
                "",
                [
                    Validators.minLength(Settings.inputMinChar),
                    Validators.maxLength(Settings.inputMaxCharMid),
                    // Validators.required,
                ],
            ],
            contact_person_phone: [
                "",
                [
                    Validators.minLength(Settings.inputMinChar),
                    Validators.maxLength(Settings.inputMaxCharMid),
                    // Validators.required,
                ],
            ],*/

      price: ['', [ValidatorHelper.isNumber0To1000000, Validators.required]],
    });

    // this.logger.info("CreateDeliveryRequestComponent.constructor() -> data: " + JSON.stringify(data));

    this.setDeliveryRequestDefault();

    //
    if (data && data.mode !== null) {
      this.mode = data.mode;
    }

    if (data && data.eventUUID !== null) {
      this.eventUUID = data.eventUUID;

      if (this.mode === CreateDeliveryRequestComponent.CREATE) {
        this.deliveryRequestService
          .getNewDeliveryRequestData(this.eventUUID)
          .subscribe({
            next: (response) => {
              // this.logger.info("CreateDeliveryRequestComponent.constructor() -> SUCCESS: " + JSON.stringify(response));
              this.deliveryRequestData = response.data;
              this.deliveryRequest = this.deliveryRequestData;
              this.initGt2Select = true;
              // this.deliveryRequest.price = this.deliveryRequestData.price;
              // this.deliveryRequest.name = this.deliveryRequestData.name;
            },
            error: (error) => {
              this.logger.error(
                'CreateDeliveryRequestComponent.constructor() -> ERROR: ' +
                  JSON.stringify(error),
              );
              this.handleAPIError(error, this.dialog, null, null);
            },
          });
      } else {
        this.initGt2Select = true;
      }
    }

    if (data && data.event !== null) {
      //this.event = _.cloneDeep(data.event); // data.event;

      this.deliveryRequest.delivery_from_date =
        data.event.informations.event_date;
      this.deliveryRequest.delivery_to_date =
        data.event.informations.event_date;
      this.deliveryRequest.delivery_from_time =
        data.event.informations.event_time;
      this.deliveryRequest.delivery_to_time =
        data.event.informations.event_time;
      // this.logger.info("CreateDeliveryRequestComponent.constructor() -> this.deliveryRequest: " + JSON.stringify(this.deliveryRequest));
      if (this.event?.spot?.delivery_by_sector_price) {
        this.deliveryRequest.price = this.event?.spot?.delivery_by_sector_price;
      }
    }

    if (data && data.autoRedirect !== null) {
      this.autoRedirect = data.autoRedirect;
    }

    if (data && data.deliveryRequestToUpdate) {
      //this.deliveryRequest = _.cloneDeep(data.deliveryRequestToUpdate);
      // this.logger.info("CreateDeliveryRequestComponent.constructor() -> deliveryRequestToUpdate TO data.deliveryRequestToUpdate: " + JSON.stringify(data.deliveryRequestToUpdate));
      // this.logger.info("CreateDeliveryRequestComponent.constructor() -> deliveryRequestToUpdate TO this.deliveryRequest: " + JSON.stringify(this.deliveryRequest));
    }
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.noteEditorSubscription = this.noteEditor?.onTextChange
      .pipe(debounceTime(this.noteEditordebounceTimeValue))
      .pipe(distinctUntilChanged())
      .subscribe({
        next: (data) => {
          if (data.htmlValue) {
            this.noteInvalid =
              data.htmlValue.length > Settings.editorMaxCharLong;
            // this.logger.info("CreateDeliveryRequestComponent.noteEditor.onTextChange() -> this.noteInvalid: " + this.noteInvalid);
            if (this.noteInvalid) {
              return;
            }
          }
        },
      });

    //this.logger.info("CreateDeliveryRequestComponent.onDeliveryRequestTemplateChange() -> deliveryRequest: " + JSON.stringify(this.deliveryRequest));
  }

  ngOnDestroy() {}

  public setDeliveryRequestDefault(): void {
    this.deliveryRequest = {
      name: '',
      note: '',
      contact_person_name: '',
      contact_person_phone: '',
      show_on_purchase_order_production: false,
      show_on_purchase_order_client_copy: false,
      show_on_client_proposal_and_order: false,
      show_on_purchase_order_kitchen: false,
      show_on_purchase_material: false,
      delivery_from_date: null,
      delivery_to_date: null,
      delivery_from_time: null,
      delivery_to_time: null,
      wrapping: null,
      content: null,
      type: null,
      price: null,
    };
  }

  public openStartTimePicker(): void {
    // const amazingTimePicker = this.atp.open({
    //   time: this.deliveryRequest.delivery_from_time,
    // });
    // amazingTimePicker.afterClose().subscribe((time: any) => {
    //   this.deliveryRequest.delivery_from_time = time;
    // });
  }
  public openEndTimePicker(): void {
    // const amazingTimePicker = this.atp.open({
    //   time: this.deliveryRequest.delivery_to_time,
    // });
    // amazingTimePicker.afterClose().subscribe((time: any) => {
    //   this.deliveryRequest.delivery_to_time = time;
    // });
  }

  public onDeliveryRequestTypeChange(event: any) {
    // this.logger.info("CreateDeliveryRequestComponent.onDeliveryRequestTypeChange() -> event: " + JSON.stringify(event));
    this.deliveryRequest.type = event;
    // this.logger.info("CreateDeliveryRequestComponent.onWrappingSelected() -> type: " + JSON.stringify(this.deliveryRequest.type));
  }

  public onDeliveryRequestContentChange(event: any) {
    // this.logger.info("CreateDeliveryRequestComponent.onDeliveryRequestContentChange() -> event: " + JSON.stringify(event));
    this.deliveryRequest.content = event;
    // this.logger.info("CreateDeliveryRequestComponent.onWrappingSelected() -> content: " + JSON.stringify(this.deliveryRequest.content));
  }

  public onWrappingSelected(event: any) {
    // this.logger.info("CreateDeliveryRequestComponent.onWrappingSelected() -> event: " + JSON.stringify(event));
    this.deliveryRequest.wrapping = event;
    // this.logger.info("CreateDeliveryRequestComponent.onWrappingSelected() -> wrapping: " + JSON.stringify(this.deliveryRequest.wrapping));
  }

  public onCreateDeliveryRequest() {
    this.loading = true;
    // this.logger.info("CreateDeliveryRequestComponent.onCreateFlow() -> flow: " + JSON.stringify(this.deliveryRequest));

    if (this.mode === CreateDeliveryRequestComponent.CREATE) {
      this.doCreateDeliveryRequest();
    } else if (this.mode === CreateDeliveryRequestComponent.EDIT) {
      this.updateDeliveryRequest();
    }
  }

  public doCreateDeliveryRequest() {
    // this.logger.info("CreateDeliveryRequestComponent.doCreateFlow() -> selectedFlow: " + JSON.stringify(this.selectedDeliveryRequest));
    this.deliveryRequestService
      .createDeliveryRequest(this.deliveryRequest, this.eventUUID)
      .subscribe({
        next: (response) => {
          // this.logger.info("CreateDeliveryRequestComponent.doCreateDeliveryRequest() -> SUCCESS: " + JSON.stringify(response));
          this.loading = false;
          this.dialogRef.close(response);
        },
        error: (error) => {
          this.logger.error(
            'CreateDeliveryRequestComponent.doCreateDeliveryRequest() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.GENERIC'),
          );
          // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
        },
      });
  }

  public updateDeliveryRequest() {
    // this.logger.info("CreateDeliveryRequestComponent.updateFlow() -> ***** UPDATE this.deliveryRequest: " + JSON.stringify(this.deliveryRequest));
    this.deliveryRequestService
      .updateDeliveryRequest(this.deliveryRequest, this.eventUUID)
      .subscribe({
        next: (response) => {
          // this.logger.info("CreateDeliveryRequestComponent.onCreateFlow() -> SUCCESS: " + JSON.stringify(response));
          this.loading = false;
          this.dialogRef.close(response);
        },
        error: (error) => {
          this.logger.error(
            'CreateDeliveryRequestComponent.onCreateFlow() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.GENERIC'),
          );
          // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
        },
      });
  }

  public onDeliveryRequestTemplateChange(model: any): void {
    //this.logger.info("CreateDeliveryRequestComponent.onDeliveryRequestTemplateChange() -> 0. this.deliveryRequest: " + JSON.stringify(this.deliveryRequest));
    //this.logger.info("CreateDeliveryRequestComponent.onDeliveryRequestTemplateChange() -> model:" + JSON.stringify(model));
    // this.logger.info("CreateDeliveryRequestComponent.onDeliveryRequestTemplateChange() -> 1. this.deliveryRequest: " + JSON.stringify(this.deliveryRequest));
    // this.deliveryRequest = model;
    for (const key in model) {
      if (model.hasOwnProperty(key) && key !== 'object' && key !== 'uuid') {
        this.deliveryRequest[key] = model[key];
      }
    }

    // if (this.deliveryRequest.price === 0)
    // {
    //     this.deliveryRequest.price = null;
    // }

    this.deliveryRequest.delivery_from_date =
      this.event.informations.event_date;
    this.deliveryRequest.delivery_to_date = this.event.informations.event_date;

    //this.logger.info("CreateDeliveryRequestComponent.onDeliveryRequestTemplateChange() -> 1. this.deliveryRequest: " + JSON.stringify(this.deliveryRequest));
  }

  public onDeliveryRequestTemplateModelSet(data: any): void {
    //this.logger.info("CreateDeliveryRequestComponent.onDeliveryRequestTemplateModelSet() -> " + JSON.stringify(data));
    this.templatesEmpty = data.isEmpty;
  }

  public onNavCreateTemplate(): void {
    //this.logger.info("CreateDeliveryRequestComponent.onNavCreateTemplate()");
    this.dialogRef.close();

    this.router.navigateByUrl('/preferences/model-demande-livraison');
  }
}
