export class ApiRoutes {
  static V1: string = '/v1/';

  static PING: string = '/v1/ping';

  // Auth
  // static LOGIN_URL = "/v1/oauth/token";
  static LOGIN_URL = '/v1/clients/web/app/login';
  static LOGOUT_URL = '/v1/logout';
  static REFRESH_TOKEN_URL = '/v1/clients/web/app/refresh';
  static REGISTRATION_URL = '/v1/register-single';
  static INVITE_URL = '/v1/invite-single'; // FOR TESTING PURPOSE IN GOD MENU ONLY
  static FINAL_REGISTRATION_URL = '/v1/register-wizard';
  static FINAL_INVITE_URL = '/v1/invite-wizard';
  static USER_URL = '/v1/employees/me'; // TODO:
  static FORGOT_PASSWORD = '/v1/password/forgot';
  static RESET_PASSWORD = '/v1/password/reset';
  static PAGE_AUTHORIZED = '/v1/page-access-authorized';

  // settings
  static SETTINGS = '/v1/settings';

  // DASHBOARD
  static DASHBOARD_LATEST = '/v1/users/dashboard/latest';
  static DASHBOARD_CLIENTS = '/v1/users/dashboard/lastclient';

  // App GT2 Locale
  static GT2_LOCALE = '/v1/locals';

  // get Users
  static USERS = '/v1/users';
  static USER = '/v1/users';

  // get Organization

  // CRUD Employee
  static EMPLOYEE_ME = '/v1/employees/me';
  static CREATE_EMPLOYEE = '/v1/employees';
  static GET_EMPLOYEES_LIST = '/v1/employees/list';
  static GET_CONSEILLER_LIST = '/v1/conseillers/list';
  static EMPLOYEES_USERS = '/v1/employees-users';
  static EMPLOYEES = '/v1/employees';
  static GET_EMPLOYEES_CALENDAR = '/v1/employees/calendar';
  static GET_EMPLOYEES_TYPES = '/v1/employee-types';
  static GET_EMPLOYEE = '/v1/employees/'; // + id
  static UPDATE_EMPLOYEE = '/v1/employees/'; // + id
  static DELETE_EMPLOYEE = '/v1/employees/'; // + id
  static RESEND_INVITATION = '/v1/users/resend-invite/'; // + id
  static CONVERT_TO_USER = '/v1/users/invite';
  static REMOVE_AS_USER = '/v1/users/';
  static CHANGE_ROLE = '/v1/role/switch'; // + id
  static EMPLOYEE_ASSIGNMENTS = '/v1/employees/${UUID}/job-assignments';

  static EMPLOYEE_ASSIGNMENTS_JOB_COMPOSER_GET =
    '/v1/reports/assignment/report-assignment-schedule-per-job-function/prefill/'; // '/v1/reports/client/report-client-statement-client/prefill/';
  static EMPLOYEE_ASSIGNMENTS_JOB_COMPOSER_SAVE =
    '/v1/reports/assignment/report-assignment-schedule-per-job-function/send/'; // '/v1/reports/client/report-client-statement-client/send/'
  static EMPLOYEE_ASSIGNMENTS_DATES_COMPOSER_GET =
    '/v1/reports/assignment/report-assignment-schedule-per-date/prefill/'; // '/v1/reports/client/report-client-statement-client/prefill/';
  static EMPLOYEE_ASSIGNMENTS_DATES_COMPOSER_SAVE =
    '/v1/reports/assignment/report-assignment-schedule-per-date/send/'; // '/v1/reports/client/report-client-statement-client/send/'

  static USER_LIST = '/v1/employees/users/list';
  //
  static ROLES = '/v1/roles';

  static REPORTS_2 = '/v1/reports/v2';

  // Clients
  static CLIENTS = '/v1/clients';
  static CLIENTS_LIST = '/v1/clients/list';
  static UPDATE_CLIENTS = '/v1/clients/'; // + uuid
  static GET_CLIENTS = '/v1/clients/';
  static CLIENTS_LIST_SEARCH = '/v1/searches/clients/';
  static ENTERPRISES_CLIENTS_LIST_SEARCH = '/v1/searches/enterprises/';
  static GET_CLIENTS_CALENDAR = '/v1/clients/calendar/';
  static CREATE_CLIENT = '/v1/clients';
  static CREATE_CLIENT_ENTERPRISE = '/v1/enterprises/and-client';
  static CREATE_CLIENT_ENTERPRISE_BRANCH =
    '/v1/enterprises/enterprise-child-and-client';
  static CREATE_CLIENT_FOR_ENTERPRISE = '/v1/clients/create-attach/'; // + uuid
  static DELETE_CLIENT = '/v1/clients/'; // + id
  static CLIENT_RECEIPT_COMPOSER_GET =
    '/v1/reports/client/report-client-statement-client/prefill/';
  static CLIENT_RECEIPT_COMPOSER_SAVE =
    '/v1/reports/client/report-client-statement-client/send/';
  static CLIENT_ORDER_CT_STACK_COMPOSER_GET =
    '/v1/reports/client/report-client-order-ct-stack/prefill/';
  static CLIENT_ORDER_CT_STACK_COMPOSER_SAVE =
    '/v1/reports/client/report-client-order-ct-stack/send/';

  // Enterprises
  static ENTERPRISES = '/v1/enterprises';
  static ENTERPRISES_LIST = '/v1/enterprises/list';
  static UPDATE_ENTERPRISE = '/v1/enterprises/'; // + uuid
  static GET_ENTERPRISES = '/v1/enterprises/';
  static COPY_ENTERPRISE_ADDRESS = '/v1/enterprises/copy-address-to-client/';
  static GET_ENTERPRISES_CALENDAR = '/v1/enterprises/calendar/';
  static CREATE_ENTERPRISE = '/v1/enterprises';
  static CREATE_ENTERPRISE_FOR_CLIENT =
    '/v1/enterprises/create-attach-to-client/';
  static CREATE_BRANCH_FOR_ENTERPRISE = '/v1/enterprises/create-attach/'; // + uuid
  static ENTERPRISE_RECEIPT_COMPOSER_GET =
    '/v1/reports/client/report-client-statement-enterprise/prefill/';
  static ENTERPRISE_RECEIPT_COMPOSER_SAVE =
    '/v1/reports/client/report-client-statement-enterprise/send/';
  static HISTORIC_ACCOUNT_COMPOSER_GET =
    '/v1/reports/client/report-historical-account-enterprise/prefill/';
  static HISTORIC_ACCOUNT__COMPOSER_SAVE =
    '/v1/reports/client/report-historical-account-enterprise/send/';

  // JOBS FUNCTION
  static JOBS_FUNCTION_ALL = '/v1/jobfunctions';
  static JOBS_FUNCTION_LIST = '/v1/jobfunctions/list';
  static JOBS_FUNCTION_AVAILABLE = '/v1/employees/jobfunctions/available/'; // + uuid
  static JOBS_FUNCTION_DISASSOCIATE = '/v1/employees/remove-job-function/'; // + employee id + / + job function uuid

  // CONTACT
  static UPDATE_CONTACT = '/v1/contacts/'; // + uuid

  // ADDRESS
  static UPDATE_ADDRESS = '/v1/addresses/'; // + uuid

  // COMMENTS
  static UPDATE_COMMENTS = '/v1/comments/'; // + uuid

  // CRUD Organization
  static ORGANIZATION = '/v1/organizations/';
  static ORGANIZATIONS = '/v1/organizations';
  static GET_ORGANIZATION_ME = '/v1/organizations/me'; // + id
  static UPDATE_ORGANIZATION = '/v1/organizations/'; // + id
  static UPDATE_ORGANIZATION_LOCALES = '/v1/locals/organizations/'; // + id
  static UPLOAD_LOGO = '/v1/organizations/me/upload/logo';
  static UPLOAD_HEADER = '/v1/organizations/me/upload/report/header';
  static ADD_ORGANIZATION_LOCALE =
    '/v1/organizations/${ORG_UUID}/locals/add/${LOCALE_UUID}'; // + id
  static DELETE_ORGANIZATION_LOCALE =
    '/v1/organizations/${ORG_UUID}/locals/remove/${LOCALE_UUID}'; // + id

  static GET_CATERER = '/v1/caterers/me'; // + id
  static CATERER = '/v1/caterers/';
  static UPDATE_CATERER = '/v1/caterers/'; // + id

  // GOD IMPERSONATION
  static IMPERSONATE = '/v1/users/impersonate/uuid/'; // + id
  static RESET_IMPERSONATE = '/v1/users/impersonate/reset';
  static RESET_IMPERSONATE_UUID = '/v1/users/impersonate/reset/'; // + id

  static SETTINGS_PREF = '/v1/preferences';
  static EXT_ENV = '/v1/env';

  static LEDGER_ACCOUNT = '/v1/ledger-accounts';
  static LEDGER_ACCOUNT_LIST = '/v1/ledger-accounts/list';

  static EVENTS_TYPE = '/v1/event-types';

  static TAGS = '/v1/tags/';
  static TAGS_AVAILABLE = '/v1/tags/available/';

  // CLIENTS CATEGORIES
  static CLIENTS_CATEGORIES = '/v1/clientcategories';
  static CLIENTS_CATEGORIES_LIST = '/v1/clientcategories/list';

  static CLIENT_EVENTS = '/v1/clients/events/';

  static ALLERGENS = '/v1/allergens';
  static ALLERGENS_AVAILABLE = '/v1/products/allergens/available/'; // + uuid
  static ALLERGENS_ATTACHED = '/v1/products/allergens/attached/'; // + uuid
  static ALLERGENS_ACTION = '/v1/products/allergens/'; // + uuid

  static EVENT_LOGISTIC_STATUS = '/v1/event-logistic-statuses';

  // SHOPS
  static SHOPS = '/v1/shops';
  static SHOP = '/v1/shops/';
  static SHOPS_LIST = '/v1/shops/list';
  static SHOP_TYPE_LIST = '/v1/shoptypes/list';
  static SHOP_PRODUCT_TYPE_LIST = '/v1/shopproducttypes/list';
  static SHOP_MESSAGE = '/v1/shop-messages/';
  static SHOP_MESSAGE_IMAGE = '/v1/shop-messages-images/';
  static SHOP_MESSAGE_IMAGE_CLEAR = '/v1/shop-messages-images/clear/';
  static SHOP_CLEAR_MESSAGE = '/v1/shop-messages/clear/';
  static SHOP_BUSINESS_HOURS = '/v1/shop-business-hours/';
  static SHOP_BUSINESS_DAYS = '/v1/shop-business-days/';
  static SHOP_BUSINESS_DAYS_BY_SHOP = '/v1/shop-business-days/by-shop/';
  static SHOP_PREFERENCE = '/v1/shop-preferences/';
  static SHOP_RTE_TEMPLATE = '/v1/shop-rte-schedule-template/';
  static SHOP_DELIVERY_RULES = '/v1/shop-delivery-rules/';
  static SHOP_RTE_SCHEDULE_STACK = '/v1/shop-rte-schedules/list/shop/';
  static SHOP_RTE_SCHEDULE = '/v1/shop-rte-schedules';
  static SHOP_RTE_SCHEDULE_UNIFIED = '/v1/shop-rte-schedules-unified';
  static SHOP_RTE_SCHEDULE_SHOP = '/v1/shop-rte-schedules/shop/';
  static SHOP_MONERIS_INFO = '/v1/moneris/';
  static SHOP_STRIPE_INFO = '/v1/stripes/';
  static SHOP_GLOBAL_PAYMENT_INFO = '/v1/global-payments/';
  static SHOP_MULTI_TAKEOUT_LIST = '/v1/takeout-locations/list/by-shop/';
  static SHOP_MULTI_TAKEOUT = '/v1/takeout-locations';
  // static SHOP_MESSAGE = '/v1/shop-messages/';

  // static SHOP_CATEGORIES_LIST = '/v1/shopproducttypes/list';
  // categories
  static WOOCOMMERCES = '/v1/woocommerces';
  static WOOCOMMERCE = '/v1/woocommerces/';

  static PICTURE_UPLOAD_BY_URL = '/v1/medias/images/upload-by-url/';
  static PICTURE_UPLOAD_BASE64 = '/v1/medias/images/upload-by-base64/';
  static PICTURE_UPLOAD = '/v1/medias/images/upload/';
  static PICTURE_DELETE = '/v1/medias/';
  static MEDIA = '/v1/medias/';
  static FILES = '/v1/files/';

  // PRODUCTS
  static PRODUCTS = '/v1/products';
  static PRODUCTS_LIST = '/v1/products/list';
  static PRODUCTS_SEARCH = '/v1/searches/products';
  static PRODUCT = '/v1/products/';
  static PRODUCT_WITHOUT_COMBO_LIST = '/v1/products/no-combo/list';
  static PRODUCT_WITHOUT_COMBO_SEARCH = '/v1/searches/products-no-combo';
  static PRODUCT_CLONE = '/v1/products/clone/'; // + uuid
  static PRODUCT_COMBO_CLONE = '/v1/products/clone-combo/'; // + uuid
  static PRODUCT_CHOICE_COMBO_FROM_CHOICE =
    '/v1/products/choice-combo-from-choice'; // + uuid
  // static PRODUCT_CLONE = "/v1/products/clone/"; // + uuid

  // upsale
  static UPSALE_QUESTIONS = '/v1/questions';
  static QUESTIONS = '/v1/questions/';
  static UPSALE_OPTIONS = '/v1/optiongroups';
  static UPSALE_AVAILABLE_QUESTIONS =
    '/v1/products/upsale-questions/available/';
  static UPSALE_QUESTIONS_ATTACHED = '/v1/products/upsale-questions/attached/'; // + uuid
  static UPSALE_QUESTIONS_ACTION = '/v1/products/upsale-questions/'; // + uuid
  static UPSALE_OPTIONS_ATTACHED = '/v1/optiongroups/upsale-options/attached/'; // + uuid

  //
  static PRODUCT_CATEGORIES = '/v1/productcategories';
  static PRODUCT_CATEGORIES_LIST = '/v1/productcategories/list';

  // PRODUCTS
  static MATERIALS = '/v1/materials';
  static MATERIALS_LIST = '/v1/materials/list';
  static MATERIALS_SEARCH = '/v1/searches/materials';
  static MATERIALS_WITHOUT_COMBO_LIST = '/v1/materials/no-combo/list';
  static MATERIALS_WITHOUT_COMBO_SEARCH = '/v1/searches/materials-no-combo';
  static MATERIAL = '/v1/materials/';
  static MATERIAL_CLONE = '/v1/materials/clone/'; // + uuid
  static MATERIAL_CLONE_COMBO = '/v1/materials/clone-combo/'; // + uuid

  //
  static MATERIAL_PROVIDER = '/v1/material-providers';
  static MATERIAL_PROVIDER_LIST = '/v1/material-providers/list';

  //
  static SOCIAL_CHARGE = '/v1/preferences';

  //
  static GENERAL_CONDITIONS = '/v1/generalconditions';
  static SPECIFIC_CONDITIONS = '/v1/specific-conditions';

  //
  static NOTIFICATION_MESSAGE = '/v1/notificationmessages';

  //
  static SERVICE_CHARGE = '/v1/service-charges';
  static SERVICE_CHARGE_LIST = '/v1/service-charges/list';
  static SERVICE_CHARGE_MULTIPLIER_LIST = '/v1/service-charge-multiplier/list';

  //
  static LABOUR_CHARGE_LIST = '/v1/jobfunctions/chargeable-list';

  //
  static SPECIFIC_CONDITION_LIST = '/v1/specific-conditions/list';

  // PRODUCTION ZONE
  static PRODUCTION_ZONE = '/v1/productionzones';
  static PRODUCTION_ZONE_LIST = '/v1/productionzones/list';
  static PRODUCTION_ZONE_AVAILABLE = '/v1/products/production-zones/available/'; // + uuid
  static PRODUCTION_ZONE_ATTACHED = '/v1/products/production-zones/attached/'; // + uuid
  static PRODUCTION_ZONE_ACTION = '/v1/products/production-zones/'; // + uuid

  // INVOICE CATEGORIES
  static INVOICE_CATEGORIES = '/v1/invoicecategories/products';
  static INVOICE_CATEGORY = '/v1/invoicecategories/';

  // MATERIALS CATEGORIES
  static MATERIALS_CATEGORIES = '/v1/materialcategories';
  static STAFF_CATEGORIES = '/v1/staff-providers';

  // WRAPPING
  static WRAPPING = '/v1/wrappings';
  static WRAPPING_LIST = '/v1/wrappings/list';

  // UNITS
  static UNITS = '/v1/units';
  static UNITS_LIST = '/v1/units/list';

  // UNITS
  static NUMBERING = '/v1/numbering-preferences';

  // ORDERS
  static ORDERS = '/v1/orders';
  static ORDER = '/v1/orders/';
  static ORDER_LIST = '/v1/orders/list';
  static ORDERS_BY_CLIENT = '/v1/orders/by-client/';
  static ORDERS_BY_DATE = '/v1/orders/by-date/';
  static ORDERS_BY_WEEK = '/v1/orders/by-week/';
  static ORDERS_BY_MONTH = '/v1/orders/by-month/';
  static CLONE_PROPOSAL = '/v1/proposals/clone/';
  static CLONE_PROPOSAL_INTO_EVENT = '/v1/orders/clone-to-new-event/';
  static CLONE_ORDER_TO_PROPOSAL_INTO_EVENT =
    '/v1/orders/clone-order-to-proposal-to-new-event/';
  static CLONE_PROPOSAL_INTO_EVENT_WITH_DETAILS =
    '/v1/orders/clone-to-new-event-with-details/';
  static CLONE_ORDER_TO_PROPOSAL_INTO_EVENT_WITH_DETAILS =
    '/v1/orders/clone-to-proposal-to-new-event-with-details/';
  static CLONE_ORDER_INTO_EVENT = '/v1/orders/clone-to-new-event/';
  static CLONE_ORDER_INTO_EVENT_WITH_DETAILS =
    '/v1/orders/clone-to-new-event-with-details/';

  static CLONE_EVENTS_INTO_PROJECT = '/v1/events/clone-to-new-project/';
  static MOVE_EVENTS_INTO_PROJECT = '/v1/events/move-to-new-project/';

  static PROPOSAL_COMPOSER_GET =
    '/v1/reports/client/report-client-proposal/prefill/';
  static PROPOSAL_COMPOSER_SAVE =
    '/v1/reports/client/report-client-proposal/send/';

  static PROPOSAL_COMPOSER_CT_GET =
    '/v1/reports/client/report-client-proposal-ct/prefill/';
  static PROPOSAL_COMPOSER_CT_SAVE =
    '/v1/reports/client/report-client-proposal-ct/send/';

  static ORDER_COMPOSER_GET = '/v1/reports/client/report-client-order/prefill/';
  static ORDER_COMPOSER_SEND_MANAGER_UBR =
    '/v1/reports/client/report-client-order/send-manager-ubr/';
  static ORDER_COMPOSER_SAVE = '/v1/reports/client/report-client-order/send/';
  static INVOICE_COMPOSER_GET =
    '/v1/reports/client/report-client-invoice/prefill/';
  static INVOICE_COMPOSER_SAVE =
    '/v1/reports/client/report-client-invoice/send/';

  static INVOICE_COMPOSER_CT_GET =
    '/v1/reports/client/report-client-invoice-ct/prefill/';
  static INVOICE_COMPOSER_CT_SAVE =
    '/v1/reports/client/report-client-invoice-ct/send/';

  static INVOICE_COMPOSER_GLOBAL_PAYMENT_GET =
    '/v1/reports/client/report-client-invoice/global-payment/prefill/';
  static INVOICE_COMPOSER_GLOBAL_PAYMENT_SAVE =
    '/v1/reports/client/report-client-invoice/global-payment/send/';

  // static ORDER_ATTACH_SERVICE_CHARGE = "/v1/orders/clone-to-new-event/";

  // EVENTS
  static EVENTS = '/v1/events';
  static EVENT = '/v1/events/';
  static EVENTS_LIST = '/v1/events/list';
  static EVENTS_BY_MONTH = '/v1/events/by-month/';
  static EVENTS_BY_MONTH_JUL = '/v1/events/by-month-detailed/';
  static PROD_ZONE_BY_MONTH_JUL = '/v1/events/by-month-prod-zone/';
  static PROD_ZONE_BY_DAY_JUL = '/v1/events/by-day-prod-zone/';
  static EVENTS_ADD_CLIENT = '/v1/events/client/';
  static EVENTS_PROPOSAL = '/v1/events/by-date/proposal';
  static EVENTS_ORDER = '/v1/events/by-date/order';
  static EVENTS_PROPOSAL_BY_CLIENT = '/v1/events/by-client/proposal/';
  static EVENTS_ORDER_BY_CLIENT = '/v1/events/by-client/order/';
  static EVENT_TYPE_LIST = '/v1/event-types/list';

  // static EVENT_FILE = "/v1/event/${UUID}/medias";
  static EVENT_FILE = '/v1/medias/files/upload';
  static EMPLOYEE_FILE = '/v1/medias/files/signature/upload';

  static RESET_SPOT = '/v1/events/spot/reset/';
  static ADD_SPORADIC_ADDRESS = '/v1/events/${UUID}/address';
  static EVENT_DELIVERY_REQUEST_DATA = '/v1/events/new-delivery-request-data/';
  static EVENT_TAKEOUT_REQUEST_DATA = '/v1/events/new-takeout-request-data/';
  static EVENT_COMPOSER_GET =
    '/v1/reports/client/report-client-event-receipt/prefill/';
  static EVENT_COMPOSER_SAVE =
    '/v1/reports/client/report-client-event-receipt/send/';
  static EVENT_ROOM = '/v1/events/by-room-month/';
  static EVENT_ROOM_UPDATE = '/v1/events/room-mngt/';
  static EVENT_ROOM_CREATE = '/v1/events/by-room-date/';
  static EVENT_ROOM_CREATE_FROM_RESERVATION = '/v1/events/by-room-reservation';
  static EVENT_ROOM_BLOCKED = '/v1/room-reservations';
  static UPDATE_EVENT_ROOM_BLOCKED = '/v1/room-reservations';
  static DELETE_EVENT_ROOM_BLOCKED = '/v1/room-reservations';
  static EVENTS_DETAIL_BY_DATE_RANGE = '/v1/events/details-by-date-range';
  static EVENTS_DETAIL_BY_DATE_RANGE_ALL =
    '/v1/events/details-by-date-range-all';
  static EVENTS_EXTRA_BY_DATE_RANGE = '/v1/events/extra-by-date-range';

  //
  static PAYMENT_DEADLINE_LIST = '/v1/payment-deadlines/list';

  // FLOW
  static FLOW = '/v1/flows';
  static FLOW_LIST = '/v1/flows/list';

  // PROGRAMS
  static CREATE_PROGRAM = '/v1/event-programs';
  static UPDATE_PROGRAM = '/v1/event-programs';
  static PROGRAM_LIST = '/v1/event-programs';

  // DELIVERY REQUEST TYPE
  static DELIVERY_REQUEST_TYPE_LIST = '/v1/delivery-request-types/list';

  static DELIVERY_REQUEST_TYPE_TEMPLATE = '/v1/delivery-request-templates';
  static DELIVERY_REQUEST_TYPE_TEMPLATE_LIST =
    '/v1/delivery-request-templates/list';

  static DELIVERY_TYPE_LIST = '/v1/deliverytypes/list';

  // DEPOSIT IN / CASHING LIST
  static DEPOSIT_IN_LIST = '/v1/delivery-request-types/list';

  // PAYMENT MODES LIST
  static PAYMENT_MODES_LIST = '/v1/payment-modes/list';

  // DEPOSIT REQUEST LIST
  static DEPOSIT_REQUEST_LIST = '/v1/events/deposit-requests/list/available/';

  // PLACES
  static PLACES_LIST = '/v1/places/list';
  static PLACES_ROOMS_LIST = '/v1/places/rooms/list';

  static ENTERPRISE_ROOMS_LIST = '/v1/enterprises/rooms/';

  // DELIVERY REQUEST CONTENT
  static DELIVERY_REQUEST_CONTENT_LIST = '/v1/delivery-request-contents/list';

  // GUESTS
  static GUEST_LIST = '/v1/guest-types/list';

  // PROJECTS
  static PROJECTS = '/v1/projects';
  static PROJECT = '/v1/projects/';
  static PROJECTS_CONGRESS = '/v1/projects/congress/';
  static PROJECTS_LIST = '/v1/projects/list';
  static CONGRES_PROJECTS = '/v1/projects/{{UUID}}/events';
  static PROJECT_EVENTS_PROPOSAL = '/v1/events/project/{{UUID}}/proposal';
  static PROJECT_EVENTS_ORDER = '/v1/events/project/{{UUID}}/order';

  // TAXES
  static TAXES = '/v1/taxes';

  // TAXES
  static TAXES_ITEMS = '/v1/taxes-items';
  static TAXES_ITEMS_PREF = '/v1/taxes-items-preference/'; //  +

  // PERSONNAL TITLES
  static PERSONNAL_TITLES = '/v1/personal-titles';

  // DATE_FORMAT
  static DATE_FORMAT = '/v1/settings/date-format';

  // Date Time Format
  static DATE_TIME_FORMAT = '/v1/settings/date-time-format';

  static FREQUENCY = '/v1/settings/frequency';

  static TIME_ZONE = '/v1/settings/timezone';

  // SECTORS
  static SECTORS = '/v1/sectors';

  static PLACES = '/v1/places';
  static GET_PLACE = '/v1/places/'; // + id
  static DELETE_PLACE = '/v1/places/'; // + id
  static UPDATE_PLACE = '/v1/places/'; // + id

  static ROOMS = '/v1/rooms';
  static ROOMS_SUB_LIST = '/v1/rooms/{uuid}/sub-list';
  static ATTACH_SUBROOM = '/v1/rooms/{uuid}/attach/{subroom_uuid}';
  static DETACH_SUBROOM = '/v1/rooms/{uuid}/detach/{subroom_uuid}';

  static GET_ROOM = '/v1/rooms/'; // + id
  static DELETE_ROOM = '/v1/rooms/'; // + id
  static UPDATE_ROOM = '/v1/rooms/'; // + id
  static GET_VENUE_ROOMS = '/v1/places/rooms/'; // + id
  static GET_ENTERPRISES_ROOMS = '/v1/enterprises/rooms/'; // + id
  static GET_ENTERPRISES_EVENTS = '/v1/enterprises/events/'; // + id
  static GET_ENTERPRISES_CLIENTS = '/v1/enterprises/clients/'; // + id
  static GET_ENTERPRISES_INVOICES = '/v1/enterprises/invoices/'; // + id
  static GET_ENTERPRISES_INVOICES_INFO =
    '/v1/enterprises/invoices/informations/'; // + id

  // static CREATE_ROOMS = "/v1/places/rooms/"; // + id

  static SEARCH: string = '/v1/searches';
  static SEARCH_TEST: string = '/v1/searches2';

  // ERRORS
  static ERROR_410: string = '/v1/error-code/410';
  static ERROR_401: string = '/v1/error-code/401';

  //
  static SAGE_AUTHORIZE: string = 'https://api.gtaws.xyz/sage/authorize';

  //
  // static KNOWLEDGE_BASE: string = "/v1/knowledge-base";
  static KNOWLEDGE_BASE: string = '/v1/knowledgebasecategories';

  static FAQ: string = '/v1/faqs';

  static SUPPORT_CONTACT: string = '/v1/users/support/contact-form';
  static SUPPORT_CONTACT_500: string = '/v1/users/support/contact-form/500';
  static SUPPORT_CONTACT_404: string = '/v1/users/support/contact-form/404';
  static SUPPORT_CONTACT_429: string = '/v1/users/support/contact-form/429';
  static SUPPORT_CONTACT_503: string = '/v1/users/support/contact-form/503';

  // REPORTS
  static REPORT_PRINT: string = '/reports/${SECTION}/html/${LOCAL}';
  static REPORT_PRINT_WITH_PAPER: string =
    '/reports/${SECTION}/html/${PAPER}/${LOCAL}';
  static REPORT_PDF: string = '/reports/${SECTION}/pdf/${LOCAL}';
  static REPORT_XLS: string = '/reports/${SECTION}/xls/${LOCAL}';
  static REPORT_CSV: string = '/reports/${SECTION}/csv/${LOCAL}';
  static REPORT_VCARD: string = '/reports/${SECTION}/vcard/${LOCAL}';

  // ASSIGNMENTS
  static ASSIGNMENTS: string = '/v1/events/${uuid_event}/assignments/';
  static GROUP_ASSIGNMENT: string =
    '/v1/events/${uuid_event}/group-assignments';
  static GROUP_ASSIGNMENT_ACTION: string = '/v1/group-assignments/';
  static JOB_ASSIGNMENT: string = '/v1/job-assignments';
  static PROPOSAL_STATUS: string = '/v1/job-proposals/${UUID}/status';
  static STAFF_PROVIDERS_LIST: string = '/v1/staff-providers/list';
  static JOB_EXPENSES: string = '/v1/job-expenses';
  static JOB_ASSIGNMENT_UNLINK: string = '/v1/job-assignments/unlink/';

  // CONGRESSES
  static CONGRESSES: string = '/v1/congresses';
  static CONGRESS_CANCEL: string = '/v1/congresses/{{UUID}}/cancel';
  static PROJECT_CANCEL: string = '/v1/projects/{{UUID}}/cancel';

  // DASHBOARD NOTES
  static DASHBOARD_NOTE = '/v1/dashboard-notes/';

  // ChangeLogs
  static GET_CHANGELOGS_LIST = '/v1/custom-changelogs';
  static CREATE_CHANGELOG = '/v1/custom-changelog';

  // WEBSSHOPS PRODUCTS
  static WEBSHOP_PRODUCT = '/v1/webshop-products/';

  // UQAM RECEIPT NOTICE REPORT
  static RECEIPT_NOTICE_GET =
    '/v1/reports/client/report-receipt-notice/prefill/';
  static RECEIPT_NOTICE_SAVE = '/v1/reports/client/report-receipt-notice/send/';
  static RECEIPT_NOTICE_SELL_ONLY_GET =
    '/v1/reports/client/report-receipt-notice-sell-only/prefill/';
  static RECEIPT_NOTICE_SELL_ONLY_SAVE =
    '/v1/reports/client/report-receipt-notice-sell-only/send/';
  static STORAGE_NOTICE_GET =
    '/v1/reports/client/report-storage-notice/prefill/';
  static STORAGE_NOTICE_SAVE = '/v1/reports/client/report-storage-notice/send/';
  static UBR_LIST = '/v1/ubrs-list';
  static EVENT_LOGISTIC_STATUS_LIST = '/v1/event-logistic-statuses-list';

  // static PROJECTS: string = "/v1/projects/";

  // static CONGRESSES: string = "/v1/congresses";
}
