<div class="profile-box info-box width-100-content">
  <header class="accent" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="title">{{ headerLabel }}</div>
    <div
      class="gt2-light-green"
      style="width: 60px; height: 56px"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <button
        type="button"
        matTooltip="{{ 'MATERIAL_CATEGORIES.CREATE_NEW_CATEGORY' | translate }}"
        mat-icon-button
        class=""
        (click)="onCreateNew()"
      >
        <mat-icon class="icon-mat-black" color="">add</mat-icon>
      </button>
    </div>
  </header>
  <div style="min-width: 370px" class="content" fxLayout="column">
    <div
      *ngIf="
        !isLoadingResults && dataSource.data && dataSource.data.length === 0
      "
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      {{ "GENERIC.NO_RESULTS" | translate }}
    </div>
    <mat-table
      #table
      class=""
      matSort
      matSortStart="desc"
      [@animateStagger]="{ value: '50' }"
      [dataSource]="dataSource"
    >
      <!-- Name Column -->
      <ng-container cdkColumnDef="name">
        <mat-header-cell fxFlex="100%" *cdkHeaderCellDef mat-sort-header>
          {{ "MATERIAL_CATEGORIES.CATEGORY_NAME" | translate }}
        </mat-header-cell>

        <mat-cell class="no-text-cursor" *cdkCellDef="let row">
          <div
            *ngIf="editSelected != row"
            fxLayout="column"
            fxFlex="100%"
            (click)="onSelect(row)"
          >
            <!-- <div *ngIf="row.ledger_account">{{row.name}} - {{row.ledger_account.name_label}}</div>
            <div *ngIf="!row.ledger_account">{{row.name}}</div> -->
            <div>{{ row.name }}</div>
          </div>
          <div
            *ngIf="editSelected == row"
            class=""
            fxLayout="column"
            fxFlex="100%"
          >
            <div [formGroup]="form" fxLayout="row" fxLayoutAlign="start center">
              <mat-form-field class="" fxFlex="100%">
                <mat-label>Français</mat-label>
                <input
                  matInput
                  type="text"
                  autocomplete="nope"
                  formControlName="name_fr_CA"
                  [(ngModel)]="editSelected.name_local.name_fr_CA"
                />
                <mat-error *ngIf="form.controls['name_fr_CA'].invalid">
                  {{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div [formGroup]="form" fxLayout="row" fxLayoutAlign="start center">
              <mat-form-field fxFlex="100%" class="">
                <mat-label>English</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="name_en_CA"
                  autocomplete="nope"
                  [(ngModel)]="editSelected.name_local.name_en_CA"
                />
                <mat-error *ngIf="form.controls['name_en_CA'].invalid">
                  {{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100%">
              <app-gt2-select
                #ledgerAccountSelect
                fxFlex="100%"
                [service]="ledgerAccountService"
                [placeHolder]="'MATERIAL_CATEGORIES.LEDGER_ACCOUNT' | translate"
                [label]="'MATERIAL_CATEGORIES.LEDGER_ACCOUNT' | translate"
                [labelKey]="'name_label'"
                [selectedModel]="editSelected.ledger_account"
                (changeEvent)="onLedgerAccountChange($event)"
                [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
                [nullableUseNone]="false"
                class=""
              ></app-gt2-select>
            </div>

            <div
              fxLayout="row"
              fxLayoutAlign="end center"
              class="gt2-buttons-theme"
            >
              <button
                mat-raised-button
                color="accent"
                class="mb-16"
                [disabled]="form.invalid"
                (click)="autosaveDataChange(editSelected)"
              >
                {{ "GENERIC.SAVE" | translate }}
              </button>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <!-- accounting_number Column -->
      <!--<ng-container cdkColumnDef="accounting_number">-->
      <!--<mat-header-cell fxFlex="220px" *cdkHeaderCellDef mat-sort-header>{{-->
      <!--'PRODUCTS_CATEGORIES.ACCOUNTING_NUMBER'-->
      <!--| translate }}-->
      <!--</mat-header-cell>-->
      <!--<mat-cell fxFlex="200px" *cdkCellDef="let row" style="max-height: 40px;">-->
      <!--<div *ngIf="editSelected != row" fxFlex="100%" (click)="onSelect(row)">{{row.ledger_account.name_label}}</div>-->

      <!--<div *ngIf="editSelected == row" class="ml-8" fxFlex="100%">-->

      <!--&lt;!&ndash;<div fxLayout="column" fxLayoutAlign="start start">&ndash;&gt;-->
      <!--<div fxLayout="column" fxLayoutAlign="start end">-->
      <!--&lt;!&ndash;<mat-form-field class="" fxFlex="150px">&ndash;&gt;-->
      <!--&lt;!&ndash;<input matInput&ndash;&gt;-->
      <!--&lt;!&ndash;[placeholder]="'PRODUCTS_CATEGORIES.ACCOUNTING_NUMBER' | translate"&ndash;&gt;-->
      <!--&lt;!&ndash;type="text"&ndash;&gt;-->
      <!--&lt;!&ndash;[(ngModel)]="editSelected.accounting_number">&ndash;&gt;-->
      <!--&lt;!&ndash;</mat-form-field>&ndash;&gt;-->

      <!--&lt;!&ndash;<mat-form-field class="" fxFlex="100%">&ndash;&gt;-->
      <!--&lt;!&ndash;<mat-select&ndash;&gt;-->
      <!--&lt;!&ndash;placeholder="{{'PRODUCTS_CATEGORIES.LEDGER_ACCOUNT' | translate }}"&ndash;&gt;-->
      <!--&lt;!&ndash;[(ngModel)]="editSelected.accounting_number">&ndash;&gt;-->
      <!--&lt;!&ndash;<mat-option *ngFor="let item of ledgerAccounts" [value]="item">{{item.name_label}}</mat-option>&ndash;&gt;-->
      <!--&lt;!&ndash;</mat-select>&ndash;&gt;-->
      <!--&lt;!&ndash;</mat-form-field>&ndash;&gt;-->

      <!--<app-gt2-select #ledgerAccountSelect-->
      <!--[service]="ledgerAccountService"-->
      <!--[placeHolder]=" 'PRODUCTS_CATEGORIES.LEDGER_ACCOUNT' | translate "-->
      <!--[label]="'PRODUCTS_CATEGORIES.LEDGER_ACCOUNT' | translate "-->
      <!--[labelKey]="'name_label'"-->
      <!--[selectedModel]="editSelected.ledger_account"-->
      <!--(changeEvent)="onLedgerAccountChange($event)"-->
      <!--[noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"-->
      <!--[nullableUseNone]="false"-->
      <!--class=""></app-gt2-select>-->

      <!--<button mat-icon-button-->
      <!--color="accent"-->
      <!--class=""-->
      <!--matTooltip="{{ 'GENERIC.SAVE' | translate }}"-->
      <!--(click)="onSaveLegdgerAccount(editSelected)">-->
      <!--<mat-icon class="mat-accent">save</mat-icon>-->
      <!--</button>-->
      <!--</div>-->
      <!--&lt;!&ndash;</div>&ndash;&gt;-->
      <!--</div>-->
      <!--</mat-cell>-->
      <!--</ng-container>-->

      <!-- EDIT Column -->
      <ng-container cdkColumnDef="edit">
        <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
        <mat-cell
          fxLayoutAlign="center center"
          fxFlex="50px"
          *cdkCellDef="let row"
        >
          <button
            mat-icon-button
            *ngIf="row.editable"
            matTooltip="{{
              editSelected != row
                ? translate.instant('GENERIC.EDIT')
                : translate.instant('GENERIC.EDIT_STOP')
            }}"
            (click)="onEdit(row)"
          >
            <mat-icon *ngIf="editSelected != row" class="s-16">{{
              getIcon("EDIT")
            }}</mat-icon>
            <mat-icon *ngIf="editSelected == row" class="s-16">{{
              getIcon("UNDO")
            }}</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <!-- Delete Column -->
      <ng-container cdkColumnDef="delete">
        <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
        <mat-cell
          fxLayoutAlign="center center"
          fxFlex="50px"
          *cdkCellDef="let row"
        >
          <button
            matSuffix
            mat-icon-button
            *ngIf="row.editable"
            matTooltip="{{ 'GENERIC.DELETE' | translate }}"
            (click)="onDelete(row)"
          >
            <mat-icon class="s-16">delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        class="mat-row-color"
        *cdkRowDef="let row; columns: displayedColumns"
        [@animate]="{ value: '*', params: { y: '100%' } }"
      >
      </mat-row>
    </mat-table>
    <!--<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>-->
    <mat-paginator
      #paginator
      [length]="resultsLength"
      showFirstLastButtons="true"
      (page)="datatableHelperService.onPaginator($event, 'materialCategories')"
      [pageIndex]="0"
      [pageSize]="'materialCategories' | tableSizePipe: 'large'"
      [pageSizeOptions]="datatableHelperService.pageSizeOptions"
    >
    </mat-paginator>
  </div>
</div>
