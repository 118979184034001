import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiRoutes } from './ApiRoutes';
import { Gt2ApiService } from './gt2-api.service';
import { ClientsModel, ClientsModelData } from './models/ClientsModel';

@Injectable()
export class ClientsService implements Resolve<any> {
  clients?: ClientsModelData[];
  onClientsChanged: BehaviorSubject<any> = new BehaviorSubject({});

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private api: Gt2ApiService,
  ) {}

  /**
   * Resolve
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getClients()]).then(() => {
        resolve();
      }, reject);
    });
  }

  public getClients(): Promise<any> {
    // const routeURL: string = 'api/test-employees';
    const routeURL: string = this.api.createUrl(ApiRoutes.CLIENTS);

    return new Promise((resolve, reject) => {
      this.http.get(routeURL).subscribe({
        next: (response: any) => {
          this.clients = response.data;
          // this.logger.info("ClientsService.getClients() -> this.clients: " + JSON.stringify(this.clients));
          this.onClientsChanged.next(this.clients);
          resolve(response.data);
        },
        error: reject,
      });
    });
  }

  public getClientsWithPagination(
    sort: string,
    direction: string,
    page: number,
    pageSize: number,
    filterValue: string,
  ): Promise<ClientsModel> {
    const routeURL: string = this.api.createUrl(ApiRoutes.CLIENTS);
    let requestUrl: string;

    if (sort === 'enterprise') {
      sort = 'enterprises|name';
    }

    if (!sort || direction === '') {
      requestUrl = `${routeURL}?page=${page + 1}&limit=${pageSize}`;
    } else {
      // if (direction !== 'asc' || direction !== 'desc')
      requestUrl = `${routeURL}?orderBy=${sort}&sortedBy=${direction}&page=${
        page + 1
      }&limit=${pageSize}`;
    }

    // Remove special caracters from the filter value for proper searching
    filterValue = filterValue.replace(/%/g, '');

    if (filterValue !== '') {
      requestUrl += '&search=' + filterValue;
    }

    return new Promise((resolve, reject) => {
      this.http.get(requestUrl).subscribe({
        next: (response: any) => {
          this.clients = response.data;
          resolve(response);
        },
        error: reject,
      });
    });
  }

  public getClient(clientID: string) {
    // this.logger.info("ClientsService.getClient() -> clientID: " + clientID);
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.GET_CLIENTS) + clientID,
    );
  }

  public getAllClients(keyword: string) {
    // this.logger.info("EnterprisesService.getAllClients()");
    // return this.http.get<any>(this.api.createUrl(ApiRoutes.CLIENTS_LIST));
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.CLIENTS_LIST_SEARCH),
      { key: keyword },
    );
  }
  public getAllClientsCC(keyword: string) {
    // this.logger.info("EnterprisesService.getAllClients()");
    // return this.http.get<any>(this.api.createUrl(ApiRoutes.CLIENTS_LIST));
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.CLIENTS) + '/search',
      { search: keyword },
    );
  }

  public getAllEnterprisesClients(keyword: string) {
    // this.logger.info("EnterprisesService.getAllEnterprisesClients()");
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.ENTERPRISES_CLIENTS_LIST_SEARCH),
      { key: keyword },
    );
  }

  public getClientCalendar(clientID: string) {
    // this.logger.info("ClientsService.ClientsService() -> clientID: " + clientID);
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.GET_CLIENTS_CALENDAR) + clientID,
    );
  }

  public updateClient(client: any, clientUUID: string) {
    // this.logger.info("ClientsService.updateClient() -> employeeUUID: " + clientUUID);
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.UPDATE_CLIENTS) + clientUUID,
      client,
    );
  }

  public deleteClient(clientUUID: String) {
    // this.logger.info("ClientsService.deleteClient() -> client: " + clientUUID);
    return this.http.delete<any>(
      this.api.createUrl(ApiRoutes.DELETE_CLIENT) + clientUUID,
    );
  }

  public attachClientToEnterprise(clientUUID: String, enterpriseUUID: string) {
    // this.logger.info("ClientsService.attachClientToEnterprise() -> clientUUID: " + clientUUID);
    // this.logger.info("ClientsService.attachClientToEnterprise() -> enterpriseUUID: " + enterpriseUUID);
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.CLIENTS) +
        '/attach/' +
        clientUUID +
        '/' +
        enterpriseUUID,
    );
  }

  public detachClientToEnterprise(clientUUID: String) {
    //this.logger.info("ClientsService.detachClientToEnterprise() -> clientUUID: " + clientUUID);
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.CLIENTS) +
        '/detach-enterprise/' +
        clientUUID,
    );
  }

  public getAllClientEvents(
    sort: string,
    direction: string,
    page: number,
    pageSize: number,
    clientUUID: any,
  ): Promise<any> {
    // this.logger.info("ClientsService.getAllClientEvents()");
    const routeURL: string =
      this.api.createUrl(ApiRoutes.CLIENT_EVENTS) + clientUUID;
    let requestUrl: string;
    if (!sort || direction === '') {
      requestUrl = `${routeURL}?page=${page + 1}&limit=${pageSize}`;
    } else {
      requestUrl = `${routeURL}?orderBy=${sort}&sortedBy=${direction}&page=${
        page + 1
      }&limit=${pageSize}`;
    }

    // .post(requestUrl, dateRange)
    return new Promise((resolve, reject) => {
      this.http.get(requestUrl).subscribe({
        next: (response: any) => {
          // this.clientsCategory = response.data;
          // this.logger.info("ClientsService.getAllClientEvents() -> this.response: " + JSON.stringify(response));
          resolve(response);
        },
        error: reject,
      });
    });
  }
}
