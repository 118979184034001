import { ServiceChargeMultiplierService } from './../../../api/service-charge-multiplier.service';
import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { GT2PageAbstract } from '../../abstract/GT2PageAbstract';
import { NGXLogger } from 'ngx-logger';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import {
  MatTable,
  MatTableDataSource,
  MatTableModule,
} from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { ClientsCategoryModel } from '../../../api/clients-categories.service';
import { CrudDatatableGenericService } from '../../../api/crud-datatable-generic.service';
import { ApiRoutes } from '../../../api/ApiRoutes';
import { Settings } from '../../../settings/settings';
import { DatatableHelperService } from '../../../../services/datatable-helper.service';
import { CrudDatatableGenericModel } from '../../crud/crud-datatable-generic/crud-datatable-generic.component';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { fuseAnimations } from '../../../../../@fuse/animations/index';
import { LedgerAccountService } from '../../../api/ledger-account.service';
import { ServiceChargeCreateComponent } from './service-charge-create/service-charge-create.component';
import { GenericAlertComponent } from '../../../components/generic-alert/generic-alert.component';
import { of, merge } from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { TaxesService } from '../../../api/taxes.service';
import { ValidatorHelper } from '../../../validators/ValidatorHelper';
import { CommonModule } from '@angular/common';
import { CdkTableModule } from '@angular/cdk/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { Gt2SelectComponent } from '../../../components/gt2-select/gt2-select.component';
import { TableSizePipeModule } from '../../../modules/table-size.module';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'app-service-charge',
  templateUrl: './service-charge.component.html',
  styleUrls: ['./service-charge.component.scss'],
  standalone: true,
  imports: [
    MatTableModule,
    TranslateModule,
    MatInputModule,
    FlexLayoutModule,
    TableSizePipeModule,
    MatTooltipModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    Gt2SelectComponent,
    MatPaginatorModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSortModule,
    CommonModule,
    CdkTableModule,
  ],
  providers: [CrudDatatableGenericService],
  animations: fuseAnimations,
})
export class ServiceChargeComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() headerLabel!: string;
  serviceURL: string;
  dataSource = new MatTableDataSource();
  displayedColumns = ['name', 'edit', 'delete'];
  selected!: ClientsCategoryModel;
  editSelected: any;
  // selectedServiceChargeMultiplier: any;
  form!: UntypedFormGroup;
  formPercent!: UntypedFormGroup;
  formPrice!: UntypedFormGroup;
  newCategory!: string;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('table') table!: MatTable<any>;
  @ViewChild(MatSort) sort!: MatSort;
  confirmDialogRef: any;
  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;
  currentLanguage?: string;
  ledgerAccounts: any;

  constructor(
    private logger: NGXLogger,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private toastr: ToastrService,
    public translate: TranslateService,
    public ledgerAccountService: LedgerAccountService,
    public taxesService: TaxesService,
    public datatableHelperService: DatatableHelperService,
    private crudDatatableGenericService: CrudDatatableGenericService,
    private translationLoader: FuseTranslationLoaderService,
    public serviceChargeMultiplierService: ServiceChargeMultiplierService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    this.serviceURL = ApiRoutes.SERVICE_CHARGE;

    this.ledgerAccountService.getLedgersAccount().subscribe({
      next: (response: any) => {
        // //this.logger.info("ServiceChargeComponent.getLedgersAccount() -> SUCCESS:" + JSON.stringify(response));
        this.ledgerAccounts = response.data;
        // //this.logger.info("ServiceChargeComponent.getLedgersAccount() -> SUCCESS:" + JSON.stringify(this.ledgerAccounts));
      },
      error: (error: any) => {
        this.logger.error(
          'ServiceChargeComponent.getLedgersAccount() -> ERROR:' +
            JSON.stringify(error),
        );
      },
    });
  }

  ngOnInit() {}

  ngOnDestroy() {}

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe({
      next: () => (this.paginator.pageIndex = 0),
    });

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(startWith(null))
      .pipe(
        switchMap(() => {
          setTimeout(() => {
            this.isLoadingResults = true;
          });

          return this.crudDatatableGenericService.getAll(
            this.serviceURL,
            this.sort?.active + '->' + this.translate.currentLang,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize,
          );
        }),
      )
      .pipe(
        map((data) => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.meta.pagination.total;
          return data.data;
        }),
      )
      .pipe(
        catchError(() => {
          setTimeout(() => {
            this.isLoadingResults = false;
            this.isRateLimitReached = true;
          });
          return of([]);
        }),
      )
      .subscribe({ next: (data) => (this.dataSource.data = data) });
  }

  public onSelect(selected: any) {
    // //this.logger.info("ServiceChargeComponent.onSelect() -> " + selected);
    if (selected.editable) {
      this.onEdit(selected);
    }
  }

  public reloadData(): void {
    this.paginator?._changePageSize(this.paginator.pageSize);
  }

  public onCreateNew() {
    // //this.logger.info("ServiceChargeComponent.onCreateNew() ");

    this.confirmDialogRef = this.dialog.open(ServiceChargeCreateComponent, {
      disableClose: false,
      width: '400px',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      data: {
        dialogTitle: this.translate.instant(
          'SERVICE_CHARGE.CREATE_NEW_CATEGORY',
        ),
        serviceURL: this.serviceURL,
        ledgerAccounts: this.ledgerAccounts,
        createPlaceholder: this.translate.instant(
          'SERVICE_CHARGE.NEW_CATEGORY_NAME',
        ),
        useEnglish: true,
      },
    });

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          // //this.logger.info("ServiceChargeComponent.onCreateNew() -> CREATION SUCCESS");
          this.reloadData();
        } else {
          // //this.logger.info("ServiceChargeComponent.onCreateNew() -> CREATION CANCELED");
        }
        this.confirmDialogRef = null;
      },
    });
  }

  public onEdit(selected: ClientsCategoryModel) {
    this.createForm();
    // //this.logger.info("ServiceChargeComponent.onEdit() -> " + JSON.stringify(selected));
    if (this.editSelected === selected) {
      this.editSelected = null;
    } else {
      this.editSelected = selected;
    }
  }

  public createForm(): void {
    //this.logger.info("ServiceChargeComponent.ngOnInit() -> " + JSON.stringify(this.editSelected));

    this.form = this.formBuilder.group({
      name_fr_CA: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      name_en_CA: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      ledger_account: '',
      // price: [
      //     "",
      //     [
      //         ValidatorHelper.isPositive,
      //         ValidatorHelper.isNumber0To1000000,
      //         Validators.required,
      //     ],
      // ],
    });
    //
    this.formPrice = this.formBuilder.group({
      price: [
        '',
        [
          ValidatorHelper.isPositive,
          ValidatorHelper.isNumber0To1000000,
          Validators.required,
        ],
      ],
    });

    //
    this.formPercent = this.formBuilder.group({
      percent: [
        '',
        [
          // ValidatorHelper.isNotZero,
          // ValidatorHelper.isNumberMinus100To100,
          Validators.required,
        ],
      ],
    });

    // if (
    //     this.editSelected.multiply_enum ===
    //     ServiceChargeMultiplyEnum.PERCENTAGE_BY_INVOICE_CATEGORIES
    // ) {
    //     this.form = this.formBuilder.group({
    //         name_fr_CA: [
    //             "",
    //             [
    //                 Validators.minLength(Settings.inputMinChar),
    //                 Validators.maxLength(Settings.inputMaxCharMid),
    //                 Validators.required,
    //             ],
    //         ],
    //         name_en_CA: [
    //             "",
    //             [
    //                 Validators.minLength(Settings.inputMinChar),
    //                 Validators.maxLength(Settings.inputMaxCharMid),
    //                 Validators.required,
    //             ],
    //         ],
    //         ledger_account: "",
    //         percent: [
    //             "",
    //             [
    //                 ValidatorHelper.isNotZero,
    //                 ValidatorHelper.isNumberMinus100To100,
    //                 Validators.required,
    //             ],
    //         ],
    //     });
    // } else {
    //     this.form = this.formBuilder.group({
    //         name_fr_CA: [
    //             "",
    //             [
    //                 Validators.minLength(Settings.inputMinChar),
    //                 Validators.maxLength(Settings.inputMaxCharMid),
    //                 Validators.required,
    //             ],
    //         ],
    //         name_en_CA: [
    //             "",
    //             [
    //                 Validators.minLength(Settings.inputMinChar),
    //                 Validators.maxLength(Settings.inputMaxCharMid),
    //                 Validators.required,
    //             ],
    //         ],
    //         price: [
    //             "",
    //             [
    //                 ValidatorHelper.isPositive,
    //                 ValidatorHelper.isNumber0To1000000,
    //                 Validators.required,
    //             ],
    //         ],
    //     });
    // }
  }

  public onDelete(selected: ClientsCategoryModel) {
    // //this.logger.info("ServiceChargeComponent.onDelete() -> " + selected);
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.crudDatatableGenericService
            .delete(ApiRoutes.SERVICE_CHARGE, selected)
            .subscribe({
              next: (response: any) => {
                // //this.logger.info("ServiceChargeComponent.onDelete() -> SUCCESS:" + JSON.stringify(response));
                this.toastr.success(
                  '',
                  this.translate.instant('GENERIC.DELETE_SUCCESS'),
                );
                this.reloadData();
              },
              error: (error: any) => {
                this.logger.error(
                  'ServiceChargeComponent.onDelete() -> ERROR' +
                    JSON.stringify(error),
                );
                // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
                this.handleAPIError(
                  error,
                  this.dialog,
                  this.toastr,
                  this.translate.instant('GENERIC.ERRORS.GENERIC'),
                );
              },
            });
        }
        this.confirmDialogRef = null;
      },
    });
  }

  public onEditCategoryFocusOut(
    selected: CrudDatatableGenericModel,
    name: string,
    local: string,
  ): void {
    // //this.logger.info("ServiceChargeComponent.onEditCategoryFocusOut() -> name: " + name);
    // //this.logger.info("ServiceChargeComponent.onEditCategoryFocusOut() -> local: " + local);
    this.autosaveDataChange(selected);
  }

  public onSaveLegdgerAccount(editSelected: any): void {
    // //this.logger.info("ServiceChargeComponent.onSaveLegdgerAccount() -> editSelected: " + JSON.stringify(editSelected));
    this.autosaveDataChange(editSelected);
  }

  public autosaveDataChange(data: any): void {
    // //this.logger.info("ServiceChargeComponent.autosaveDataChange() -> data to save: " + JSON.stringify(data));
    this.crudDatatableGenericService.edit(this.serviceURL, data).subscribe({
      next: (response: any) => {
        // //this.logger.info("ServiceChargeComponent.onEdit() -> SUCCESS:" + JSON.stringify(response));
        this.toastr.success(
          this.translate.instant('SERVICE_CHARGE.EDIT_SUCCESS'),
        );
        this.reloadData();
      },
      error: (error: any) => {
        // //this.logger.info("ServiceChargeComponent.onEdit() -> ERROR" + JSON.stringify(error));
        // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
        this.handleAPIError(
          error,
          this.dialog,
          this.toastr,
          this.translate.instant('GENERIC.ERRORS.GENERIC'),
        );
      },
    });
  }

  public onLanguageChange(lang: string): void {
    // //this.logger.info("ServiceChargeComponent.onLanguageChange() -> " + lang);
    this.reloadData();
  }

  public onLedgerAccountChange(event: any): void {
    // //this.logger.info("ServiceChargeComponent.onLedgerAccountChange() -> event: " + JSON.stringify(event));
    this.editSelected.ledger_account = event;
  }

  public onTaxChange(event: any): void {
    // //this.logger.info("ServiceChargeComponent.onTaxChange() -> event: " + JSON.stringify(event));
    this.editSelected.tax = event;
  }
  public onServiceChargeMultiplierChange(event: any): void {
    // //this.logger.info(
    //     "ServiceChargeCreateComponent.onServiceChargeMultiplierChange() -> event: " +
    //         JSON.stringify(event)
    // );
    this.editSelected.multiply_enum = event.value;
    // this.createForm();
  }
}
