export const locale = {
  lang: 'en-CA',
  data: {
    ORDERS: {
      ORDERS: 'Orders',
      ORDER: 'Order',
      PROPOSAL: 'Proposal',
      INVOICE: 'Invoice',
      GUESS_COUNT: 'Guess count',
      RESPONSIBLE_EMPLOYEE: 'Advisor',
      PROJECT_AND_EVENT: 'Project and event',
      PROGRESS: 'Progress',
      PROJECT: 'Project',
      DEPOSIT: 'Deposit',
      STAFF: 'Staff',
      SERVICE_CHARGE: 'Service charge',
      SERVICE_CHARGE_CT: 'Administration charge',
      SPECIFICS_CONDITIONS: 'Specific conditions',
      KITCHEN_NOTE: 'Production',
      ALLERGIES_NOTE: 'Allergens',
      MATERIAL_NOTE: 'Equipments',
      ADD_PAYMENT: 'Add a payment',
      WRAPPING_SELECT: 'Select a wrapping',
      WRAPPING: 'Wrapping for this order',
      PRODUCTS_NO_RESULTS: 'No foods & drinks for this order',
      SUB_TOTAL_PRODUCTS: 'Subtotal foods & drinks:',
      SUB_TOTAL_SERVICE_CHARGE: 'Subtotal service charges:',
      SUB_TOTAL_STAFFS: 'Subtotal staffs:',
      SUB_TOTAL_PER_PERSON: 'Price/person:',
      PRICE_SUBTOTAL: 'Subtotal price',
      ADD_PRODUCT: 'Add products',
      VISIT_EVENT: 'See event',
      VISIT_PROJECT: 'See project',
      ADD_MATERIAL: 'Add equipments',
      MATERIALS_NO_RESULTS: 'No equipment for this order',
      SUB_TOTAL_MATERIALS: 'Subtotal equipments:',
      DOES_NOT_NEED_DELIVERY: 'The client will pick up in store',
      NO_DELIVERY_REQUEST: 'No delivery request',
      CAPACITY: 'Capacity',
      ROYALTY: 'Royalty',
      SELECT_RESPONSIBLE_EMPLOYEE: 'Select an advisor',

      CLONE_INTO_EVENT: 'Clone into a new event',
      CLONE_ORDER_INTO_EVENT: 'Copy for another client',
      CLONE_ORDER_INTO_EVENT_KEEP_INFO: 'Copy this event to a new date',
      CLONE_PROPOSITION_INTO_EVENT: 'Copy for another client',
      CLONE_PROPOSITION_INTO_EVENT_KEEP_INFO: 'Copy this event to a new date',
      CLONE_PROPOSITION: 'Clone proposal',
      ACCEPT_PROPOSAL: 'Accept proposal',
      ACCEPT_PROPOSAL_SUCESS: 'Proposal accepted',
      REFUSE_PROPOSAL: 'Refuse proposal',
      REFUSE_PROPOSAL_SUCESS: 'Proposal refused',
      VALIDATE_DATE_MESSAGE: 'Please take some time to revised the dates.',
      CANCEL_ORDER: 'Cancel order',
      CANCEL_ORDER_SUCCESS: 'Order cancelled',
      CONFIRM_ORDER: 'Confirm order',
      CONFIRM_ORDER_SUCCESS: 'Order confirmed',
      NON_TAXABLE_TIP: 'Non taxable tip',
      NO_VENUES: 'No place',
      ADD_SERVICE_CHARGE: 'Add service charge',
      NO_SERVICE_CHARGE: 'No service charge for this order',
      ADD_SPECIFIC_CONDITION: 'Add specific condition',
      NO_SPECIFIC_CONDITION: 'No specific condition for this order',
      SEND_ORDER: 'Send order',
      SEND_ORDER_TOOLTIP: 'Send<br/>order',
      SEND_ORDER_PROPOSAL_TOOLTIP: 'Send<br/>order or proposal',
      PURCHASE_ORDER: 'Purchase order',
      PURCHASE_ORDER_KITCHEN: 'Purchase order - Kitchen',
      PURCHASE_ORDER_CLIENT: 'Purchase order - Client',
      PURCHASE_ORDER_MATERIAL: 'Equipment purchase order',
      PURCHASE_ORDER_STAFF: 'Staff purchase order',
      EDIT_PRODUCT: 'Edit food/drink',
      EDIT_MATERIAL: 'Edit equipment',
      NO_STAFF: 'No staff assigned for this order',
      ADD_STAFF: 'Add staff',
      COMPLETE_ORDER: 'Create invoice',
      CONFIRM_ORDER_ALERT_TITLE: 'Confirm order',
      CONFIRM_ORDER_ALERT_PRE_MESSAGE:
        'This order can only be confirm if it has: ',
      CONFIRM_ORDER_ALERT_NO_INVOICING_CLIENT: 'an invoicing client',
      CONFIRM_ORDER_ALERT_NO_ORDERING_CLIENT: 'an ordering client',
      CONFIRM_ORDER_ALERT_NO_SPOT: 'a place',
      CONFIRM_ORDER_ALERT_NO_DELIVERY_TYPE: 'a delivery type',
      CONFIRM_ORDER_ALERT_NO_EVENT_TIME: 'an event time',
      PROPOSAL_EXPIRED_AT: 'Expired at',
      INVOICED_AT: 'Invoiced at',
      INVOICE_SENT_TITLE: 'Invoice sent',
      INVOICE_SENT_MESSAGE:
        "You're modyfying an invoice that is already sent, don't forget to re-send the new modified invoice",
      COMPLETE_INVOICE_PAID: 'Completed and paid',
      COMPLETE_INVOICE_DIALOG_MESSAGE:
        'Since the balance is now at 0$ or negative, would you like to complete de invoice?',
      CANCEL_INVOICE: 'Cancel invoice',
      CLOSE_INVOICE: 'Close this invoice UBR',
      APPLY_CHILDREN: 'Apply to all children',
      WARNING_COMBO_1:
        'Warning: With this configuration, the reports will not display the item and therefore the price too item but it will be calculated.',
      WARNING_COMBO_2:
        'Warning: With this configuration, the reports will not display the price of the item but it will be calculated.',
      INTERNAL_VIEW_TRUE: 'Internal view only',
      INTERNAL_VIEW_FALSE: 'Internal view only',
      SHOW_UNIT_PRICE_TRUE: 'Show unit price',
      SHOW_UNIT_PRICE_FALSE: 'Show unit price',
      REFUSE_PROPOSAL_ALERT: 'Are you sure you want to refuse this proposal?',
      CANCEL_INVOICE_ALERT: 'Are you sure you want to cancel this invoice?',
      CLOSE_INVOICE_ALERT: 'Are you sure you want to close this invoice?',
      CANCEL_ORDER_ALERT: 'Are you sure you want to cancel this order?',
      VISIBILITY: 'Visibility',
      GROUP_ACTION_PRICE_MODAL_TITLE: 'Modify prices',
      GROUP_ACTION_QTY_MODAL_TITLE: 'Modify quantities',
      GROUP_ACTION_PRODUCT_CATEGORY_MODAL_TITLE: 'Edit product categories',
      GROUP_ACTION_MATERIAL_CATEGORY_MODAL_TITLE: 'Edit equipment categories',
      GROUP_ACTION_TOOLTIP: 'Group action. At least 1 item must be selected',
      GROUP_ACTION_SELECTED: 'selected',
      GROUP_ACTION_SELECTED_POSTFIX: '',
      GROUP_ACTION_MODIFY_QTY: 'Modify quantity',
      GROUP_ACTION_MODIFY_PRICE: 'Modify price',
      GROUP_ACTION_MODIFY_FREE: 'Free',
      GROUP_ACTION_CLIENT_VISIBLE: 'Visible to client',
      GROUP_ACTION_CLIENT_INVISIBLE: 'Invisible to client',
      GROUP_ACTION_SHOW_CLIENT_PRICE: 'Show price to client',
      GROUP_ACTION_SHOW_CLIENT_PRICE_NOT: 'Do not show price to client',
      LETTER_FORMAT: 'letter format',
      LEGAL_FORMAT: 'legal format',
      ASSIGNMENTS: 'Assignments',
      PRODUCTION_NOTE: 'Production note',
      SALE_NOTE: 'Note of the product sale',
      BUFFET_LABEL_5963: 'Tray and buffet label',
      BOX_LABEL_55164: 'Box label',
      REPORT_MNGT_ORDER_PURCHASE_ORDER_KITCHEN_STAFF:
        'Purchase order kitchen and staff - DAN',
      REPORT_DAN_ORDER_PURCHASE_ORDER_DELIVERY: 'Purchase order delivery - DAN',
      REPORT_DAN_ORDER_PURCHASE_ORDER_CLIENT: 'Purchase order client - DAN',
      REPORT_DAN_ORDER_PURCHASE_ORDER_STACK: 'Purchase order production - DAN',
      ORDER_GAB: "Bon d'événement | St-Gab",
      CONTRAT_GAB: 'Contrat',
      CONTRAT_BANQUET: 'Contrat Banquet',
      SC_FIX_PRICE: 'Fixed price',
      SC_PERCENT: 'Percentage',
      SC_NIGHT: 'Room',
      RP_CONTRACT_FULL: 'Contrat complet | RP',
      RP_CONTRACT_HOSTING: 'Contrat hébergement | RP',
      RP_CONTRACT_ROOMS: 'Contrat salle | RP',
      VISIT_DEPOSITS: 'Cashing and deposit',
      CT_ESTIMATION: 'Estimate',
      PURCHASE_ORDER_CHEF: 'Bon commande Chef - MC',
      PURCHASE_ORDER_HOT: 'Bon commande Chaud - MC',
      PURCHASE_ORDER_COLD: 'Bon commande Froid - MC',
      PURCHASE_ORDER_PASTRY: 'Bon commande Pâtisserie - MC',
      PURCHASE_ORDER_SERVICE: 'Bon commande service - MC',
      PURCHASE_ORDER_HOD_AND_COLD: 'Bon commande Chaud/Froid - MC',
      PRODUCT_DESCRIPTION_FR: 'Description (FR)',
      PRODUCT_DESCRIPTION_EN: 'Description (EN)',
      EXTERNAL_NAME_EN: 'External name (EN)',
      EXTERNAL_NAME_FR: 'External name (FR)',
      INTERNAL_NAME_EN: 'Internal name (EN)',
      INTERNAL_NAME_FR: 'Internal name (FR)',
      SYNC_STAFF: 'Staff',
      REPORT_CLIENT_RECEIPT_NOTICE_UQAM: 'Receipt notice',
      CHANGELOG: 'Notice of change',
      PRODUCTION_ZONE: 'Production zone',
      NOTE_LOGISTIC: 'Logistic equipment note',
      NOTE_SALE_MATERIAL: 'Sale equipment note',
      MATERIAL_PROVIDER: 'Name of supplier',
      NO_MATERIAL_PROVIDER_RESULTS: 'No supplier',
      INVOICE_UBR: 'Invoice UBR',
      INVOICE_GLOBAL_PAYMENTS: 'Invoice online payment (GP)',
      UBR_MANAGER: 'UBR manager',
    },
  },
};
