<div
  class="page-layout simple left-sidebar inner-sidebar inner-scroll"
  id="shop-web-internal"
>
  <ng-template #visitHintTemplate>
    <p>{{ "SHOPS.VISIT" | translate }}</p>
  </ng-template>

  <div class="gt2-theme">
    <div
      class="header accent pl-24 pr-24 h-80 mat-elevation-z1"
      fusePerfectScrollbar
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div fxLayout="row" fxLayoutAlign="start center">
        <button
          (click)="onPageBack(location, router, '/shops')"
          class="mr-0 mr-sm-16 white-fg"
          mat-icon-button
        >
          <mat-icon class="icon-mat">arrow_back</mat-icon>
        </button>
        <div fxLayout="column" fxLayoutAlign="center start">
          <div class="user-info" fxLayout="row" fxLayoutAlign="start start">
            <div
              *fuseIfOnDom
              [@animate]="{
                value: '*',
                params: { delay: '100ms', x: '-25px' },
              }"
              class="shop-title"
            >
              <div *ngIf="shop" class="">
                <span class="text-truncate"
                  >{{ "SHOPS.WEB_SHOP" | translate }} - {{ shop.name }}</span
                >
              </div>
            </div>
          </div>
          <div fxLayout="row">
            <div *ngIf="shop && shop.shop_type_label">
              {{ "SHOPS.INTEGRATION_TYPE" | translate }}:
              {{ shop.shop_type_label }}
            </div>
            <div *ngIf="shop && shop.shop_product_type_label">
              &nbsp;| {{ "SHOPS.SELL_TYPE" | translate }}:
              {{ shop.shop_product_type_label }}
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="end center">
        <button
          (click)="onOpenInNewTab()"
          class="mat-icon-button"
          mat-button
          matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
        >
          <mat-icon class="icon-mat s-30">{{ getIcon("NEW_TAB") }}</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <!-- / HEADER -->

  <!-- <div *ngIf="shop" class="primary mat-elevation-z1 py-8 gt2-sub-header" fxLayout="row"
        fxLayoutAlign="space-between center" style="height: 40px; min-height: 40px; max-height: 40px;">

        <div *ngIf="shop.shop_web" fxLayout="row" fxLayoutAlign="start center" style="margin-left:20px">
            <div class="info text-truncate">{{ 'SHOPS.GT_STORE_URL' | translate }}: {{shop.shop_web.gt_store_url}}</div>
            <mat-icon *ngIf="shop.shop_web.store_url" 
                matTooltip="visitHintTemplate" 
                content-type="template"
                style="margin-left: 10px; cursor: pointer;" (click)="openURL(shop.shop_web.gt_store_url)" color="accent">
                link</mat-icon>
        </div>
    </div> -->

  <!--HISTORY SIDEBAR-->
  <div *ngIf="!isSmallScreen" class="container-history-panel">
    <fuse-sidebar
      class="section-history-panel"
      name="shopHistoryPanel"
      position="right"
    >
      <app-section-history-panel
        panelName="shopHistoryPanel"
        section="shops"
        sectionUUID="{{ shopID }}"
      ></app-section-history-panel>
    </fuse-sidebar>
  </div>

  <div class="content">
    <!--HISTORY SIDEBAR BUTTON-->
    <div *ngIf="!isSmallScreen">
      <button
        (click)="toggleHistoricSidebarOpen('shopHistoryPanel')"
        *fuseIfOnDom
        [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }"
        class="warn mat-elevation-z2 historic-button"
        mat-icon-button
      >
        <mat-icon class="icon-mat">history</mat-icon>
      </button>
    </div>

    <!-- CENTER -->
    <div #mainContainer class="center p-24 pb-56 pr-sm-72" fusePerfectScrollbar>
      <!-- CONTENT -->
      <div class="shop-content">
        <div fxFlex="100" fxLayout="row" fxLayoutAlign="start start">
          <div
            *ngIf="shop"
            fxLayout="column"
            fxFlex="100%"
            style="margin-right: 16px"
          >
            <div class="profile-box info-box general">
              <header class="accent">
                <div class="title">{{ "SHOPS.CONFIGURATION" | translate }}</div>
              </header>

              <div class="content" fxLayout="column">
                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="start start"
                >
                  <div class="info-line" fxFlex="100%">
                    <div class="title">{{ "GENERIC.NAME" | translate }}</div>
                    <div class="info text-truncate">{{ shop.name }}</div>
                    <div *ngIf="!shop.name" class="info empty-value"></div>
                  </div>
                  <!-- <div *ngIf="editMode" class="info-line" fxFlex="100%">
                                        <div class="info-editable">
                                            <div [formGroup]="form" class="">
                                                <mat-form-field class="form-input-container" fxFlex="100%">
                                                    <input [(ngModel)]="shop.name" autocomplete="nope"
                                                        formControlName="name" matInput
                                                        placeholder="{{ 'GENERIC.INTERNAL_NAME' | translate }}" required
                                                        type="text">
                                                    <mat-error *ngIf="form.controls['name'].invalid">{{
                                                        'GENERIC.INPUT_LENGTH_INVALID' | translate }}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div> -->

                  <div>&nbsp;</div>
                </div>

                <!-- <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
                                    
                                    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                                        <div class="title">{{ 'SHOPS.STORE_URL' | translate }}</div>
                                        <div fxLayout="row" fxLayoutAlign="start center">
                                            <div class="text-truncate">{{shop.shop_web.store_url}}</div>
                                            <mat-icon *ngIf="shop.shop_web.store_url" 
                                                matTooltip="visitHintTemplate" 
                                                content-type="template"
                                                style="margin-left: 10px; cursor: pointer;"
                                                (click)="openURL(shop.shop_web.store_url)" color="accent">link
                                            </mat-icon>
                                        </div>

                                        <div *ngIf="!shop.shop_web.store_url" class="info empty-value"></div>
                                    </div>

                                    <div *ngIf="editMode" class="info-line" fxFlex="100%">
                                        <div [formGroup]="form" class="">
                                            <mat-form-field class="form-input-container" fxFlex="100%">
                                                <input [(ngModel)]="shop.shop_web.store_url" 
                                                    autocomplete="nope"
                                                    formControlName="store_url"
                                                    matInput
                                                    placeholder="{{ 'SHOPS.STORE_URL' | translate }}" 
                                                    type="text">
                                                <mat-icon matSuffix
                                                    *ngIf="shop.shop_web.store_url && shop.shop_web.store_url.length > 0"
                                                    style="cursor: pointer;" 
                                                    matTooltip="visitHintTemplate" 
                                                    content-type="template"
                                                    (click)="openURL(shop.shop_web.store_url)" color="accent">link
                                                </mat-icon>
                                                <mat-icon matSuffix style="opacity: 0.25;"
                                                    *ngIf="!shop.shop_web.store_url || shop.shop_web.store_url.length === 0"
                                                    matTooltip="Visiter" matTooltipPosition="right" color="disabled">
                                                    link</mat-icon>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div> -->

                <!-- <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
                                    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                                        <div class="title">{{ 'SHOPS.MIN_TO_TAKEOUT' | translate }}</div>
                                        <div fxLayout="row" fxLayoutAlign="start center">
                                            <div class="info text-truncate">{{shop.min_subtotal_takeout_label}}</div>
                                        </div>
                                        <div *ngIf="!shop.min_subtotal_takeout_label"
                                            class="info empty-value"></div>
                                    </div>

                                    <div *ngIf="editMode" class="info-line" fxFlex="100%">
                                        <div [formGroup]="form" class="">
                                            <mat-form-field class="form-input-container" fxFlex="100%">
                                                    <input [(ngModel)]="shop.min_subtotal_takeout" 
                                                        autocomplete="nope"
                                                        formControlName="min_subtotal_takeout"
                                                        matInput
                                                        pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
                                                        placeholder="{{ 'SHOPS.MIN_TO_TAKEOUT' | translate }}"
                                                        type="text">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div> -->

                <!-- APPLICATION FEE -->
                <!-- <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
                                    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                                        <div class="title">{{ 'SHOPS.APPLICATION_FEE' | translate }}</div>
                                        <div fxLayout="row" fxLayoutAlign="start center">
                                            <div *ngIf="shop.apply_application_fees" class="info">{{ 'GENERIC.YES' | translate}}</div>
                                            <div *ngIf="!shop.apply_application_fees" class="info">{{ 'GENERIC.NO' | translate}}</div>
                                        </div>
                                    </div>

                                    <ng-template #applicationFeeHintTemplate>
                                        <p>{{'SHOPS.APPLICATION_FEE_HINT' | translate }}</p>
                                    </ng-template>
                                      
                                    <div *ngIf="editMode" class="info-line" fxFlex="100%">
                                        <div [formGroup]="form" class="">
                                            
                                            <mat-checkbox labelPosition="after" color="accent"
                                                        [(ngModel)]="shop.apply_application_fees"
                                                        [ngModelOptions]="{standalone: true}"
                                                        class="">
                                                {{ 'SHOPS.APPLICATION_FEE' | translate }}
                                                <button class="mb-4" 
                                                    mat-icon-button 
                                                    matTooltip="applicationFeeHintTemplate" 
                                                    content-type="template"
                                                    type="button">
                                                <mat-icon class="s-20" color="">help</mat-icon>
                                            </button>
                                            </mat-checkbox>
                                        </div>
                                    </div>
                                </div> -->

                <!-- DESCRIPTION FR / EN -->
                <!-- <div class="info-line ">
                                    <div class="title-big mt-12">{{ 'SHOPS.DESCRIPTION' | translate }}
                                        
                                    </div>
                                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">

                                        <div class="info-line" fxFlex="100%">
                                            <div class="title">{{ 'GENERIC.fr-CA' | translate }}</div>
                                            <app-rich-text-locale 
                                                [(richText)]="shop.description_local"
                                                [localKey]="'description_fr_CA'" 
                                                [applyFocus]="true"
                                                [maxLength]="2048"
                                                [editMode]="editMode"
                                                [showClearButton]='false' [showSaveButton]='false'>
                                            </app-rich-text-locale>

                                            <div class="title mt-16">{{ 'GENERIC.en-CA' | translate }}</div>
                                            <app-rich-text-locale 
                                                [(richText)]="shop.description_local"
                                                [localKey]="'description_en_CA'" 
                                                [maxLength]="2048"
                                                [editMode]="editMode"
                                                [showClearButton]='false' [showSaveButton]='false'>
                                            </app-rich-text-locale>
                                        </div>

                                    </div>

                                </div> -->

                <!-- SAVE BUTTON -->
                <!-- <div *ngIf="editMode" fxLayout="row" fxLayoutAlign="end start">
                                    <button (click)="onSaveInfo()" color="accent" class="save-btn" [disabled]="false"
                                        mat-raised-button>
                                        {{ 'GENERIC.SAVE' | translate }}
                                    </button>
                                </div> -->
              </div>
            </div>

            <!-- ADDRESSE -->

            <div class="profile-box info-box general">
              <header class="accent">
                <div class="title">{{ "GENERIC.ADDRESS" | translate }}</div>
              </header>

              <div class="content-no-padding" fxLayout="column">
                <app-address
                  #addressComponent
                  [groupModel]="shop.address"
                  [isVisible]="true"
                  [editMode]="editMode"
                  [useAutosave]="false"
                  [hideSaveButton]="true"
                  (addressEvent)="onNewAdress($event)"
                  class=""
                ></app-address>

                <div
                  *ngIf="editMode"
                  fxLayout="row"
                  fxLayoutAlign="end start"
                  class="pb-16 pr-16"
                >
                  <button
                    (click)="onSaveAdress()"
                    color="accent"
                    class="save-btn"
                    [disabled]="!shop.address"
                    mat-raised-button
                  >
                    {{ "GENERIC.SAVE" | translate }}
                  </button>
                </div>
              </div>
            </div>

            <!-- PREFERENCE -->
            <div class="profile-box info-box general">
              <header class="accent">
                <div class="title">{{ "SHOPS.PREFERENCE" | translate }}</div>
              </header>

              <div class="content" fxLayout="column">
                <app-shop-preference
                  *ngIf="shopPreference"
                  [editMode]="editMode"
                  [shopPreference]="shopPreference"
                >
                </app-shop-preference>

                <div
                  *ngIf="editMode"
                  fxLayout="row"
                  fxLayoutAlign="end start"
                  class=""
                >
                  <button
                    (click)="onSavePreference()"
                    color="accent"
                    class="save-btn"
                    [disabled]="!shopPreference"
                    mat-raised-button
                  >
                    {{ "GENERIC.SAVE" | translate }}
                  </button>
                </div>
              </div>
            </div>

            <!-- CALENDAR -->
            <div class="profile-box info-box general" style="min-width: 1023px">
              <header class="accent">
                <div class="title">{{ "SHOPS.BUSINESS_DAYS" | translate }}</div>
              </header>

              <div class="content-no-padding" fxLayout="column">
                <app-business-days
                  #businessDaysComponent
                  [editMode]="editMode"
                  [(businessDays)]="businessDays"
                >
                </app-business-days>

                <div
                  *ngIf="editMode"
                  fxLayout="row"
                  fxLayoutAlign="end start"
                  class="mr-16 mb-16"
                >
                  <button
                    (click)="onSaveBusinessDay()"
                    color="accent"
                    class="save-btn"
                    [disabled]="false"
                    mat-raised-button
                  >
                    {{ "GENERIC.SAVE" | translate }}
                  </button>
                </div>
              </div>
            </div>

            <!-- BUSINESS HOURS -->
            <div class="profile-box info-box general" style="min-width: 1023px">
              <header class="accent">
                <div class="title">
                  {{ "SHOPS.BUSINESS_HOURS" | translate }}
                </div>
              </header>

              <div class="content" fxLayout="column">
                <div class="info-line">
                  <div class="title">
                    {{ "SHOPS.BUSINESS_HOURS" | translate }}
                  </div>
                </div>
                <app-hours-grid
                  *ngIf="businessHours"
                  [(hoursModel)]="businessHours"
                  [editMode]="editMode"
                >
                </app-hours-grid>

                <div
                  *ngIf="editMode"
                  fxLayout="row"
                  fxLayoutAlign="end start"
                  class="mt-16"
                >
                  <button
                    (click)="onSaveBusinessHours(businessHours)"
                    color="accent"
                    class="save-btn"
                    [disabled]="!shop.address"
                    mat-raised-button
                  >
                    {{ "GENERIC.SAVE" | translate }}
                  </button>
                </div>

                <div class="info-line mt-32">
                  <div class="title">
                    {{ "SHOPS.TAKEOUT_HOURS" | translate }}
                  </div>
                </div>
                <app-hours-grid
                  *ngIf="takeOutHours"
                  [(hoursModel)]="takeOutHours"
                  [editMode]="editMode"
                >
                </app-hours-grid>

                <div
                  *ngIf="editMode"
                  fxLayout="row"
                  fxLayoutAlign="end start"
                  class="mt-16"
                >
                  <button
                    (click)="onSaveBusinessHours(takeOutHours)"
                    color="accent"
                    class="save-btn"
                    [disabled]="!shop.address"
                    mat-raised-button
                  >
                    {{ "GENERIC.SAVE" | translate }}
                  </button>
                </div>
              </div>
            </div>

            <!-- TERMS & CONDITIONS -->
            <!-- <div class="profile-box info-box general">

                            <header class="accent">
                                <div class="title">{{ "SHOPS.TERMS_CONDITIONS" | translate}}</div>
                            </header>

                            <div class="content" fxLayout="column">
                                
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">

                                    <div class="info-line" fxFlex="100%">
                                        <div class="title">{{ 'GENERIC.fr-CA' | translate }}</div>
                                        <app-rich-text-locale
                                            #termsFR
                                            [(richText)]="shop.terms_local" 
                                            [localKey]="'terms_fr_CA'"
                                            [maxLength]="2048" 
                                            [showClearButton]='false' 
                                            [showSaveButton]='false'
                                            [invalid]="termsFRInvalid || termsENInvalid"
                                            [editMode]="editMode"
                                            (changeEvent)="termsFRInvalid = $event.invalid"
                                            (saveEvent)="onSaveTerms($event, 'fr-CA')"></app-rich-text-locale>

                                        <div class="title mt-16">{{ 'GENERIC.en-CA' | translate }}</div>
                                        <app-rich-text-locale 
                                            #termsEN
                                            [(richText)]="shop.terms_local" 
                                            [localKey]="'terms_en_CA'"
                                            [applyFocus]='false'
                                            [maxLength]="2048" 
                                            [editMode]="editMode"
                                            [showClearButton]='true'
                                            [invalid]="termsFRInvalid || termsENInvalid || !editMode"
                                            (changeEvent)="termsENInvalid = $event.invalid"
                                            (clearEvent)="onClearTerms()"
                                            (saveEvent)="onSaveTerms($event, 'en-CA')"></app-rich-text-locale>
                                    </div>


                                    
                                </div>
                            </div>
                        </div> -->

            <!-- <div *ngxPermissionsOnly="rolesService.RH_ROLES">
                            <div *ngIf="shop && editable" class="mt-8" fxFlex="100%" fxLayout="row"
                                fxLayoutAlign="center center">
                                <button (click)="deleteShop()" class="warn" mat-raised-button>
                                    {{ 'GENERIC.DELETE' | translate }}
                                </button>
                            </div>
                        </div> -->
          </div>
        </div>

        <!-- / CONTENT -->
      </div>

      <!-- / CENTER -->
    </div>
  </div>
</div>

<div *ngIf="shop && editable" fxLayout="row" fxLayoutAlign="end start">
  <div *ngxPermissionsOnly="rolesService.TEAM_LEAD_ROLES">
    <div class="fixed-button">
      <button
        (click)="onEdit($event)"
        *fuseIfOnDom
        [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }"
        [disabled]="loading"
        class="gt2-edit-button"
        id="edit-shop-button"
        mat-fab
      >
        <mat-icon class="icon-mat-black" *ngIf="!editMode">{{
          getIcon("EDIT_FAB")
        }}</mat-icon>
        <mat-icon class="icon-mat-black" *ngIf="editMode">{{
          getIcon("UNDO")
        }}</mat-icon>
      </button>
    </div>
  </div>
</div>

<!-- <ng-template #testConnectionTemplate let-item>
    <h1>{{ connectionTitleSuccess }}</h1>

    <div class="connection-message">{{ connectionMessageSuccess }}</div>
    <div fxLayout="row" fxLayoutAlign="center center">
        <button mat-raised-button
                color="primary"
                (click)="dialogRef.close(false)"
                style="margin-top: 25px"
                class="">{{ 'GENERIC.OK' | translate }}</button>
    </div>
</ng-template> -->
