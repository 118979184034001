<div style="max-height: 100%">
  <h1 matDialogTitle>{{ dialogTitle }}</h1>
  <div fxLayout="row" fxLayoutAlign="start end" class="p-8">
    <div
      [formGroup]="form"
      (keyup.enter)="form.valid && !loading ? onCreateNew() : null"
      fxFlex="100"
      class="mr-16"
      fxLayout="column"
    >
      <mat-form-field fxFlex="100" class="mr-4">
        <mat-label *ngIf="useEnglish"
          >{{ createPlaceholder }} (Français)</mat-label
        >
        <input
          *ngIf="useEnglish"
          matInput
          type="text"
          [(ngModel)]="newCategory_fr_CA"
          formControlName="name_fr_CA"
        />
        <mat-label *ngIf="!useEnglish">{{ createPlaceholder }}</mat-label>
        <input
          *ngIf="!useEnglish"
          matInput
          type="text"
          [(ngModel)]="newCategory_fr_CA"
          formControlName="name_fr_CA"
        />
      </mat-form-field>
      <mat-form-field *ngIf="useEnglish" fxFlex="100" class="">
        <mat-label>{{ createPlaceholder }} (English)</mat-label>
        <input
          matInput
          type="text"
          [(ngModel)]="newCategory_en_CA"
          formControlName="name_en_CA"
        />
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="center start" class="gt2-buttons-theme">
    <button
      mat-raised-button
      color="accent"
      (click)="onCreateNew()"
      [disabled]="!form.valid || loading"
      class="mr-16"
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(false)"
      class=""
    >
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
