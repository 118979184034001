import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { GT2FormPageAbstract } from '../../abstract/GT2FormPageAbstract';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NGXLogger } from 'ngx-logger';
import { ProductModel, ProductsService } from '../../../api/products.service';
import { FullcalendarPickerComponent } from '../../../components/fullcalendar-picker/fullcalendar-picker.component';
import * as moment from 'moment';
import { ShopsService } from '../../../api/shops.service';
import * as _ from 'lodash';
import { GenericAlertComponent } from '../../../components/generic-alert/generic-alert.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../../@fuse/pipes/pipes.module';
import { Gt2PrimengModule } from '../../../modules/gt2-primeng.module';
import { NgxMultipleDatesModule } from 'ngx-multiple-dates';

@Component({
  selector: 'app-product-shop-calendar-picker-dialog',
  templateUrl: './product-shop-calendar-picker-dialog.component.html',
  styleUrls: ['./product-shop-calendar-picker-dialog.component.scss'],
  standalone: true,
  imports: [
    MatProgressSpinnerModule,
    MatInputModule,
    TranslateModule,
    MatButtonModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    Gt2PrimengModule,
    NgxMultipleDatesModule,
    FullcalendarPickerComponent,
  ],
})
export class ProductShopCalendarPickerDialogComponent
  extends GT2FormPageAbstract
  implements OnInit, OnDestroy, AfterViewInit
{
  shop: any;
  product!: ProductModel;
  loading: boolean = false;
  modelDates!: any[];
  dateEvents: any[] = [];
  shop_cyclic_dates_clone!: any[];
  confirmDialogRef: any;
  //
  @ViewChild(FullcalendarPickerComponent)
  calendarPicker?: FullcalendarPickerComponent;

  constructor(
    public override dialogRef: MatDialogRef<ProductShopCalendarPickerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    public router: Router,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private logger: NGXLogger,
    public productsService: ProductsService,
    public shopService: ShopsService,
  ) {
    super();
    //
    if (data && data.shop) {
      this.shop = data.shop;
      // this.shop_cyclic_dates_clone = _.cloneDeep(this.shop.cyclic_dates);
      // this.modelDates = this.shop_cyclic_dates_clone;
    }
    if (data && data.product) {
      this.product = data.product;
    }
  }

  ngOnInit() {
    //this.logger.info("ProductShopCalendarPickerDialogComponent.ngOnInit() -> shop: " + JSON.stringify(this.shop));
    //this.logger.info("ProductShopCalendarPickerDialogComponent.ngOnInit() -> modelDates: " + JSON.stringify(this.modelDates));
    //
    this.loadData();
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  public loadData(): void {
    this.loading = true;
    this.productsService
      .getWebshopProductCyclicDates(
        this.product.uuid,
        this.shop.shop_webshop.uuid,
      )
      .subscribe({
        next: (response: any) => {
          //this.logger.info("ProductShopCalendarPickerDialogComponent.loadData() -> SUCCESS: " + JSON.stringify(response.cyclic_dates));
          this.loading = false;
          this.shop_cyclic_dates_clone = response.cyclic_dates; // _.cloneDeep(response.cyclic_dates);
          this.modelDates = this.shop_cyclic_dates_clone;
          // this.dialogRef.close(this.product);
        },
        error: (error: any) => {
          this.loading = false;
          // //this.logger.info("ProductComponent.autosaveDataChange() -> ERROR: " + JSON.stringify(error));
          this.handleAPIError(error, this.dialog, null, null);
          // this.dialogRef.close(false);
        },
      });
  }

  public onDatesEvent(event: any): void {
    //this.logger.info("ProductShopsComponent.onDatesEvent3 => " + JSON.stringify(event));
    for (let i = 0; i < event.length; i++) {
      let d = event[i];
      const mday = moment.utc(d).format('YYYY-MM-DD');
      if (this.modelDates?.includes(mday) == false) {
        this.modelDates.push(mday);
      } else {
        const index = this.modelDates.indexOf(mday, 0);
        if (index > -1) {
          this.modelDates?.splice(index, 1);
        }
      }
    }
    //
    setTimeout(() => {
      this.refreshCalendar();
    }, 50);
  }

  public onSaveDates(closeDialog: boolean = true): void {
    //this.logger.info("onSaveDates()");
    //
    this.shop.cyclic_dates = this.shop_cyclic_dates_clone;
    //
    this.loading = true;
    this.productsService
      .updateWebshopProduct(
        this.modelDates,
        this.product.uuid,
        this.shop.shop_webshop.uuid,
      )
      .subscribe({
        next: (response: any) => {
          //this.logger.info("ProductShopCalendarPickerDialogComponent.onSaveDates() -> SUCCESS: " + JSON.stringify(response));
          this.loading = false;
          this.product = response.data;
          if (closeDialog) {
            this.dialogRef.close(this.product);
          } else {
            setTimeout(() => {
              this.refreshCalendar();
            }, 50);
          }
        },
        error: (error: any) => {
          this.loading = false;
          // //this.logger.info("ProductComponent.autosaveDataChange() -> ERROR: " + JSON.stringify(error));
          this.handleAPIError(error, this.dialog, null, null);
          // this.dialogRef.close(false);
        },
      });
  }

  public onClearAllDates(): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });
    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.CLEAR_ALL_DATES_CONFIRM');

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.shop.cyclic_dates = [];
          this.shop_cyclic_dates_clone = [];
          this.modelDates = [];
          this.onSaveDates(false);
          // setTimeout(() => {
          //     this.refreshCalendar();
          // }, 50);
        }
      },
    });
  }

  public onCancelDates(): void {
    // this.shop.cyclic_dates = this.shop_cyclic_dates_clone;
    this.dialogRef.close(false);
  }

  public refreshCalendar(): void {
    if (this.calendarPicker) {
      this.calendarPicker.clearCalendarEvents();
      this.calendarPicker.refreshTimeline();
    }
  }
}
