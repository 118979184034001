import { GT2DateAdapter } from '../../../utils/GT2DateAdapter';
import { ValidatorHelper } from '../../../validators/ValidatorHelper';
import {
  CongressesService,
  CongressModel,
} from './../../../api/congresses.service';
import { Settings } from '../../../settings/settings';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  Inject,
} from '@angular/core';
import { Router } from '@angular/router';
import { locale as english } from '../i18n/en-CA';
import { locale as french } from '../i18n/fr-CA';
import { ItemUrlFinder } from '../../../utils/item-url-finder';
import { DateAdapter } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { ScpmPipeModule } from '../../../modules/spcm.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { CalendarDateFormatter } from 'angular-calendar';
import { CustomDateFormatter } from '../../calendar/CustomDateFormatter';

@Component({
  selector: 'app-create-congres-dialog',
  templateUrl: './create-congres-dialog.component.html',
  styleUrls: ['./create-congres-dialog.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    ScpmPipeModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatDialogModule,
    FlexLayoutModule,
    MatButtonModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    CommonModule,
  ],
  providers: [
    { provide: DateAdapter, useClass: GT2DateAdapter },
    { provide: CalendarDateFormatter, useClass: CustomDateFormatter },
  ],
})
export class CreateCongresDialogComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  //
  form: UntypedFormGroup;
  congress!: CongressModel | any;
  loading: boolean = false;
  autoRedirect: boolean = false;
  //
  constructor(
    public override dialogRef: MatDialogRef<CreateCongresDialogComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public router: Router,
    private toastr: ToastrService,
    private logger: NGXLogger,
    private congressesService: CongressesService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      congress_number: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      date_start: ['', [ValidatorHelper.dateRangeDefault, Validators.required]],
      date_end: ['', [ValidatorHelper.dateRangeDefault, Validators.required]],
    });

    this.congress = {
      uuid: null,
      object: null,
      slug: null,
      name: null,
      congress_number: null, // default value
      date_start: null,
      date_end: null,
      canceled_at: null,
    };

    if (data && data.autoRedirect !== null) {
      this.autoRedirect = data.autoRedirect;
    }
    //
    //this.logger.info("CreateCongresDialogComponent.contructor()");
  }

  ngOnInit() {
    //this.logger.info("CreateCongresDialogComponent.ngOnInit()");
  }

  ngAfterViewInit() {
    //this.logger.info("CreateCongresDialogComponent.ngAfterViewInit()");
  }

  ngOnDestroy() {
    //this.logger.info("CreateCongresDialogComponent.ngOnDestroy()");
  }

  public onCreateCongress(): void {
    this.loading = true;
    //this.logger.info("CreateCongresDialogComponent.onCreateCongress() : " + JSON.stringify(this.congress));
    this.congressesService.createCongress(this.congress).subscribe({
      next: (response) => {
        this.logger.log(
          'CreateCongresDialogComponent.createCongress() -> SUCCESS: ' +
            JSON.stringify(response),
        );
        //
        this.dialogRef.close(response.data);
        this.loading = false;
        if (this.autoRedirect) {
          this.router.navigateByUrl(ItemUrlFinder.getItemURL(response.data));
        }
      },
      error: (error) => {
        this.loading = false;
        this.logger.error(
          'CreateCongresDialogComponent.createCongress() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }
}
