import { ShopsService } from '../../../../api/shops.service';
import { NGXLogger } from 'ngx-logger';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { RichTextLocaleComponent } from '../../../../components/rich-text-locale/rich-text-locale.component';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-messages-settings',
  templateUrl: './messages-settings.component.html',
  styleUrls: ['./messages-settings.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    FlexLayoutModule,
    RichTextLocaleComponent,
    MatButtonModule,
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
  ],
})
export class MessagesSettingsComponent implements OnInit, AfterViewInit {
  // @Input() shop: any;
  @Input() shopMessage: any;
  @Input() editMode: boolean = false;
  // @Input() form: FormGroup;
  @Output() saveUnavailableMessage: EventEmitter<any> = new EventEmitter();
  @Output() clearUnavailableMessage: EventEmitter<any> = new EventEmitter();

  @Output() clearMessage: EventEmitter<any> = new EventEmitter();
  @Output() saveMessage: EventEmitter<any> = new EventEmitter();

  messageFRInvalid: boolean = true;
  messageENInvalid: boolean = true;
  messageUnavailableFRInvalid: boolean = true;
  messageUnavailableENInvalid: boolean = true;

  constructor(
    private logger: NGXLogger,
    private shopsService: ShopsService,
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    //this.logger.info("MessagesSettingsComponent.ngAfterViewInit() -> shopMessage: " + JSON.stringify(this.shopMessage));
  }

  public onSaveMessage(event: any): void {
    // //this.logger.info('MessagesSettingsComponent.onSaveMessage() -> shopMessage: ' + JSON.stringify(this.shopMessage));
    this.saveMessage.emit(this.shopMessage);
  }

  public onSaveUnavailableMessage(event: any): void {
    this.saveUnavailableMessage.emit(this.shopMessage);
  }

  public onClearMessage(): void {
    this.clearMessage.emit();
  }

  public onClearUnavailableMessage(): void {
    this.clearUnavailableMessage.emit();
  }
}
