import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ApiRoutes } from './ApiRoutes';
import { Gt2ApiService } from './gt2-api.service';
import { CommentsModel } from './models/CommentsModel';
import { HistoricModel } from './models/HistoricModel';
import { OrderModel } from '../content/orders/order.component';

@Injectable()
export class ProductsService {
  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private api: Gt2ApiService,
  ) {}

  public getProducts(keyword: string): any {
    // this.logger.info("ProductsService.getProducts()");
    // return this.http.get<any>(this.api.createUrl(ApiRoutes.PRODUCTS_LIST));
    return this.http.post<any>(this.api.createUrl(ApiRoutes.PRODUCTS_SEARCH), {
      key: keyword,
    });
  }

  public getProductsListWithoutCombo(keyword: string): any {
    // this.logger.info("ProductsService.getProductsListWithoutCombo()");
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.PRODUCT_WITHOUT_COMBO_SEARCH),
      { key: keyword },
    );
    // return this.http.get<any>(this.api.createUrl(ApiRoutes.PRODUCT_WITHOUT_COMBO_LIST));
  }

  public getProduct(uuid: any): any {
    // this.logger.info("ProductsService.getProduct() -> uuid: " + uuid);
    return this.http.get<any>(this.api.createUrl(ApiRoutes.PRODUCT) + uuid);
  }

  public updateProduct(data: any, productUUID: string): any {
    // this.logger.info("ProductsService.updateProduct() -> productUUID: " + productUUID);
    // this.logger.info("ProductsService.updateProduct() -> data: " + JSON.stringify(data));
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.PRODUCT) + productUUID,
      data,
    );
  }

  public updateWebshopProduct(
    data: any,
    productUUID: string,
    webshopUUID: string,
  ): any {
    //this.logger.info("ProductsService.updateWebshopProduct() -> productUUID: " + productUUID);
    //this.logger.info("ProductsService.updateWebshopProduct() -> webshopUUID: " + webshopUUID);
    //this.logger.info("ProductsService.updateWebshopProduct() -> data: " + JSON.stringify(data));
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.WEBSHOP_PRODUCT) +
        productUUID +
        '/webshop/' +
        webshopUUID,
      { cyclic_dates: data },
    );
  }

  public getWebshopProductCyclicDates(
    productUUID: string,
    webshopUUID: string,
  ): any {
    //this.logger.info("ProductsService.getWebshopProductCyclicDates() -> productUUID: " + productUUID);
    //this.logger.info("ProductsService.getWebshopProductCyclicDates() -> webshopUUID: " + webshopUUID);
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.WEBSHOP_PRODUCT) +
        productUUID +
        '/webshop/' +
        webshopUUID +
        '/cyclic-dates',
    );
  }

  public getProductsWithPagination(
    confirmedOnly: boolean,
    comboOnly: boolean,
    sort: string,
    direction: string,
    page: number,
    pageSize: number,
    filterValue: string,
  ): Promise<any> {
    // const confirmed: string = (confirmedOnly) ? "1" : "0";
    // const isCombo: string = (comboOnly) ? "1" : "0";
    const routeURL: string = this.api.createUrl(ApiRoutes.PRODUCTS) + '?';

    // this.logger.info("ProductsService.updateProduct() -> sort: " + sort);

    let requestUrl: string;
    if (!sort || direction === '') {
      requestUrl = `${routeURL}page=${page + 1}&limit=${pageSize}`;
    } else {
      requestUrl = `${routeURL}orderBy=${sort}&sortedBy=${direction}&page=${
        page + 1
      }&limit=${pageSize}`;
    }
    // Remove special caracters from the filter value for proper searching
    filterValue = filterValue.replace(/%/g, '');

    if (filterValue !== '') {
      requestUrl += '&search=' + filterValue;
    }

    // this.logger.info("getProductsWithPagination() -> routeURL: " + routeURL);
    // this.logger.info("getProductsWithPagination() -> requestUrl: " + requestUrl);
    return new Promise((resolve, reject) => {
      this.http.get(requestUrl).subscribe({
        next: (response: any) => {
          // this.logger.info("ProductsService.getProductsWithPagination() -> this.employees: " + JSON.stringify(response.data));
          resolve(response);
        },
        error: async (error) => {
          this.logger.error(
            'getProductsWithPagination() -> ERROR-ERROR: ' +
              JSON.stringify(error),
          );
          reject;
        },
      });
    });
  }

  public deleteProduct(uuid: string) {
    return this.http.delete<any>(this.api.createUrl(ApiRoutes.PRODUCT) + uuid);
  }

  public cloneProduct(uuid: string) {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.PRODUCT_CLONE) + uuid,
    );
  }

  public cloneCombo(uuid: string) {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.PRODUCT_COMBO_CLONE) + uuid,
    );
  }

  public createChoiceComboFromChoice(data: any, uuid: string) {
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.PRODUCT_CHOICE_COMBO_FROM_CHOICE),
      { combos: data, uuid: uuid },
    );
  }

  public createProduct(product: any) {
    return this.http.post<any>(this.api.createUrl(ApiRoutes.PRODUCTS), product);
  }

  public productChildMoveAfter(
    comboUUID: string,
    productUUID: string,
    afterUUID: string,
  ) {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.PRODUCT) +
        comboUUID +
        '/move-after/' +
        productUUID +
        '/' +
        afterUUID,
    );
  }

  public productChildMoveBefore(
    comboUUID: string,
    productUUID: string,
    beforeUUID: string,
  ) {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.PRODUCT) +
        comboUUID +
        '/move-before/' +
        productUUID +
        '/' +
        beforeUUID,
    );
  }

  public productComboAttach(
    comboUUID: string,
    productUUID: string,
    qty: number,
  ) {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.PRODUCT) +
        comboUUID +
        '/attach/' +
        productUUID +
        '/' +
        qty,
    );
  }

  public productComboDetach(
    comboUUID: string,
    productUUID: string,
    qty: number,
  ) {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.PRODUCT) +
        comboUUID +
        '/detach/' +
        productUUID,
    );
  }

  public productComboUpdate(
    comboUUID: string,
    productUUID: string,
    qty: number,
  ) {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.PRODUCT) +
        comboUUID +
        '/update/' +
        productUUID +
        '/' +
        qty,
    );
  }

  public productComboQtyUpdate(comboUUID: string, children: any[]): any {
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.PRODUCT) + comboUUID + '/combo/update',
      { children: children },
    );
  }

  public attachShop(productUUID: string, shopUUID: string): any {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.PRODUCT) +
        productUUID +
        '/shops/' +
        shopUUID +
        '/attach',
    );
  }

  public dettachShop(productUUID: string, shopUUID: string): any {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.PRODUCT) +
        productUUID +
        '/shops/' +
        shopUUID +
        '/detach',
    );
  }
}

export interface ProductsModel {
  data: ProductModel[];
}
export interface ProductModel {
  is_alcoholic_product: any;
  object: string;
  uuid: string;
  name: string;
  name_local: name_local;
  name_internal: string;
  name_internal_local: name_internal_local;
  slug: string;
  isArchive: boolean;
  order: OrderModel;
  ordered: boolean;
  description: string;
  description_internal: string;
  description_local: description_local;
  product_number: number;
  accountant_code: number;
  production_delay: number;
  serving_count: number;
  sku: number;
  price: number;
  price_label: string;
  cost: number;
  note: string;
  sale_note: string;
  prod_note: string;
  ingredient_note: string;
  active: boolean;
  confirmed: boolean;
  item_qty: number;
  item_unit: UnitItemModel;
  wrapping: WrappingItemModel;
  min_qty_order: number;
  tax: TaxItemModel;
  product_category: ProductCategoryItemModel;
  clone_id: number;
  recipe_id: number;
  organization_id: number;
  childs: any;
  parents: any;
  allergens: AllergenItemModel[];
  comments: CommentsModel;
  historic_model: HistoricModel;
  is_combo: boolean;
  shops: any[];
  combo_show_child_name: boolean;
  combo_show_child_qty: boolean;
  combo_show_child_price: boolean;
  combo: ProductModel[];
  invoice_category: any;
  medias: any;
  cost_label: string;
  production_zones: any[];
  is_cyclic: boolean;
  cyclic_dates: any[];
  recurrence: string;
  upsale_questions: any[];
  show_charged_price: boolean;
  multiply_combo_item_price: boolean;
  is_choice_product_combo: boolean;
}

// TODO: Put this somewhere else
// tslint:disable-next-line:class-name
export interface name_local {
  name_fr_CA: string;
  name_en_CA: string;
}
export interface name_internal_local {
  name_internal_fr_CA: string;
  name_internal_en_CA: string;
}

// tslint:disable-next-line:class-name
export interface description_local {
  description_fr_CA: string;
  description_en_CA: string;
}

export interface TaxItemModel {
  object: string;
  uuid: string;
  name: string;
  taxe_1: string;
  taxe_2: string;
  taxe_3: string;
  taxe_4: string;
  percent_total: number;
  percent_tax_1: number;
  percent_tax_2: number;
  percent_tax_3: number;
  percent_tax_4: number;
}

export interface WrappingItemModel {
  object: string;
  uuid: string;
  name: string;
  slug: string;
}

export interface UnitItemModel {
  object: string;
  uuid: string;
  name: string;
  slug: string;
}

export interface AllergenItemModel {
  object: string;
  uuid: string;
  name: string;
  // slug: string;
}

export interface ProductCategoryItemModel {
  object: string;
  uuid: string;
  name: string;
  slug: string;
  accounting_number: number;
}
