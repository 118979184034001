import { HttpClient } from '@angular/common/http';
import { Gt2ApiService } from './gt2-api.service';
import { ApiRoutes } from './ApiRoutes';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IGetDataService } from '../components/gt2-select/gt2-select.component';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class DateFormatService implements IGetDataService {
  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private api: Gt2ApiService,
  ) {}

  public getDateFormat() {
    // this.logger.info("DateFormatService.getDateFormat()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.DATE_FORMAT));
  }

  public getData(): Observable<any> {
    // this.logger.info("DateFormatService.getData()");
    return this.getDateFormat();
  }

  public updateDateFormat(format: any, uuid: string) {
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.SETTINGS_PREF) + '/' + uuid,
      format,
    );
  }
}
