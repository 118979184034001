import { RichTextsValidator } from './../../../utils/RichTextsValidator';
import { Location } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ItemUrlFinder } from '../../../utils/item-url-finder';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Editor } from 'primeng/editor';
import { Subscription } from 'rxjs';
import { fuseAnimations } from '../../../../../@fuse/animations/index';
import { FuseSidebarService } from '../../../../../@fuse/components/sidebar/sidebar.service';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { FileService } from '../../../../services/file.service';
import { PrintService } from '../../../../services/print.service';
import { Gt2ApiService } from '../../../api/gt2-api.service';
import { MaterialCategoryService } from '../../../api/material-category.service';
import { MaterialProviderService } from '../../../api/material-provider.service';
import {
  MaterialModel,
  MaterialsService,
} from '../../../api/materials.service';
import { TaxesService } from '../../../api/taxes.service';
import { CreateMaterialComboComponent } from '../../../components/create-material-combo/create-material-combo.component';
import { GenericAlertComponent } from '../../../components/generic-alert/generic-alert.component';
import { AppRoutes } from '../../../const/AppRoutes';
import { ICanDeactivateComponent } from '../../../gards/can-deactivate-guard.service';
import { RolesService } from '../../../services/roles.service';
import { Settings } from '../../../settings/settings';
import { GT2FormPageAbstract } from '../../abstract/GT2FormPageAbstract';
import { locale as english } from '../i18n/en-CA';
import { locale as french } from '../i18n/fr-CA';
import { Title } from '@angular/platform-browser';
import { ValidatorHelper } from '../../../validators/ValidatorHelper';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-material',
  templateUrl: './material.component.html',
  styleUrls: ['./material.component.scss'],
  animations: fuseAnimations,
})
export class MaterialComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy, ICanDeactivateComponent
{
  @ViewChild('descriptionFrEditor')
  descriptionFrEditor!: Editor;
  @ViewChild('descriptionEnEditor')
  descriptionEnEditor!: Editor;
  @ViewChild('noteEditor') noteEditor!: Editor;
  @ViewChild('noteSaleEditor') noteSaleEditor!: Editor;
  @ViewChild('noteLogisticEditor')
  noteLogisticEditor!: Editor;
  isSmallScreen?: boolean;

  material!: MaterialModel;
  materialID: any;
  loading?: boolean;
  isCloning: boolean = false;
  form: UntypedFormGroup;
  formEnTab: UntypedFormGroup;
  formEnTabValid = true;
  formEnTabValidSub?: Subscription;
  editMode: boolean = false;
  comboDialogRef: any;
  confirmDialogRef: any;
  editable: boolean = true;

  autosaveNameKeys: string[] = ['name_fr_CA', 'name_en_CA'];
  autosaveDescriptionKeys: string[] = [
    'description_fr_CA',
    'description_en_CA',
  ];

  descriptionFrEditorSubscription?: Subscription;
  descriptionEnEditorSubscription?: Subscription;
  noteEditorSubscription!: Subscription;
  noteSaleEditorSubscription!: Subscription;
  noteLogisticEditorSubscription!: Subscription;
  noteEditordebounceTimeValue: number = 1500;
  noteInvalid: any = {
    note: false,
    sale_note: false,
    logistic_notes: false,
  };
  componentWidth?: string;

  @ViewChild('mainContainer') mainContainer!: ElementRef;

  richTextsValidator!: RichTextsValidator;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private materialsService: MaterialsService,
    public materialProviderService: MaterialProviderService,
    public materialCategoryService: MaterialCategoryService,
    public translate: TranslateService,
    public taxesService: TaxesService,
    public location: Location,
    private toastr: ToastrService,
    private formBuilder: UntypedFormBuilder,
    private fileService: FileService,
    private dialog: MatDialog,
    public print: PrintService,
    private route: ActivatedRoute,
    private titleService: Title,
    private logger: NGXLogger,
    private api: Gt2ApiService,
    private _fuseSidebarService: FuseSidebarService,
    public rolesService: RolesService,
    public router: Router,
    private breakpointObserver: BreakpointObserver,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    this.form = this.formBuilder.group({
      name_fr_CA: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      // name_en_CA: [
      //     "",
      //     [
      //         Validators.minLength(Settings.inputMinChar),
      //         Validators.maxLength(Settings.inputMaxCharMid),
      //         Validators.required,
      //     ],
      // ],
      name_internal_fr_CA: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      description_fr_CA: [
        '',
        [
          // Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharLong),
        ],
      ],
      material_number: [
        '',
        [
          Validators.minLength(1),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      accountant_code: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
        ],
      ],
      price: ['', [ValidatorHelper.isNumber0To1000000, Validators.required]],
      sku: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
        ],
      ],
      cost: '',
      total_inventory_qty: [
        '',
        [
          // Validators.minLength(1),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      confirmed: '',
    });

    this.formEnTab = this.formBuilder.group({
      name_en_CA: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      name_internal_en_CA: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      description_en_CA: [
        '',
        [
          // Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharLong),
        ],
      ],
    });
  }

  ngOnInit() {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe({
      next: (result) => {
        this.isSmallScreen = result.matches;
      },
    });
    this.setComponentWidth();
    //this.titleService.setTitle(this.translate.instant("MATERIALS.MATERIAL"));
    this.route.params.subscribe({
      next: (params: any) => {
        this.materialID = params.id;
        this.loading = true;
        this.loadMaterial();
      },
    });

    this.richTextsValidator = new RichTextsValidator();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.setComponentWidth();
  }

  setComponentWidth(): void {
    const screenWidth = window.innerWidth;
    this.componentWidth = screenWidth < 500 ? `${screenWidth}px` : '100%';
  }

  ngAfterViewInit() {
    if (this.editMode) {
      this.setControlsChanges(this.form);
    }
    // window.scroll(0,0);
  }

  public onGeneralTabChange(index: any): void {
    // //this.logger.info("onGeneralTabChange() " + index);
    if (index === 1) {
      setTimeout(() => {
        // this.generalTabGenerated = true;
        this.formEnTabValidSub = this.formEnTab.statusChanges.subscribe({
          next: (status) => {
            // //this.logger.info("formEnTab.statusChanges() " + status);
            this.formEnTabValid = status === 'VALID';
            // //this.logger.info(
            //     "formEnTab.statusChanges() " + this.formEnTabValid
            // );
          },
        });
      }, 50);
    } else {
      this.formEnTabValidSub?.unsubscribe();
    }
  }

  public initRichText(): void {
    this.richTextsValidator.registerRichText(this.noteEditor, 'noteEditor');
    this.richTextsValidator.registerRichText(
      this.noteSaleEditor,
      'noteSaleEditor',
    );
    this.richTextsValidator.registerRichText(
      this.noteLogisticEditor,
      'noteLogisticEditor',
    );
    this.richTextsValidator.registerRichText(
      this.descriptionFrEditor,
      'descriptionFrEditor',
    );
    this.richTextsValidator.registerRichText(
      this.descriptionEnEditor,
      'descriptionEnEditor',
    );
  }

  ngOnDestroy() {
    // if (this.noteEditorSubscription)
    // {
    //     this.noteEditorSubscription.unsubscribe();
    // }

    if (this.descriptionFrEditorSubscription) {
      this.descriptionFrEditorSubscription.unsubscribe();
    }

    if (this.descriptionEnEditorSubscription) {
      this.descriptionEnEditorSubscription.unsubscribe();
    }

    if (this.richTextsValidator) {
      this.richTextsValidator.destroy();
    }
  }

  public canDeactivate(): boolean {
    return !this.form.dirty && !this.richTextsValidator.dirty();
  }

  public loadMaterial(): void {
    this.loading = true;
    this.materialsService.getMaterial(this.materialID).subscribe({
      next: (response) => {
        // //this.logger.info(
        //     "MaterialComponent.loadMaterial() -> SUCCESS: " +
        //         JSON.stringify(response)
        // );
        const isFirstLoad: boolean =
          this.material === null || this.material == undefined;
        this.material = response.data;
        this.loading = false;
        this.editable = !this.material?.isArchive;
        //this.titleService.setTitle(this.translate.instant("MATERIALS.MATERIAL") + " " + this.material.name);

        //
        if (isFirstLoad) {
          setTimeout(() => {
            this.initRichText();
          }, 500);
        }
      },
      error: (error) => {
        // this.logger.error("MaterialComponent.loadMaterial() -> ERROR: " + JSON.stringify(error));
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public deleteMaterial(): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });
    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.materialsService.deleteMaterial(this.materialID).subscribe({
            next: (response) => {
              ////this.logger.info("ProductComponent.deleteEnterprise() -> SUCCESS: " + JSON.stringify(response));
              this.toastr.success(
                '',
                this.translate.instant('GENERIC.DELETE_SUCCESS'),
              );
              this.router.navigateByUrl('/' + AppRoutes.MATERIALS);
            },
            error: (error) => {
              //this.logger.error("ProductComponent.deleteClient() -> ERROR: " + JSON.stringify(error));
              // this.toastr.error('', this.translate.instant("GENERIC.ERRORS.GENERIC"));
              this.loading = false;
              this.handleAPIError(
                error,
                this.dialog,
                this.toastr,
                this.translate.instant('GENERIC.ERRORS.GENERIC'),
              );
            },
          });
        }
      },
    });
  }

  public onEdit(event: any): void {
    this.editMode = !this.editMode;
    if (this.noteEditor) {
      this.noteEditor.readonly = !this.editMode;
    }

    if (this.noteSaleEditor) {
      this.noteSaleEditor.readonly = !this.editMode;
    }

    if (this.descriptionFrEditor) {
      this.descriptionFrEditor.readonly = !this.editMode;
    }

    if (this.descriptionEnEditor) {
      this.descriptionEnEditor.readonly = !this.editMode;
    }

    if (this.editMode) {
      // this.setControlsChanges(this.form);
    } else {
      // this.unsetControlsChanges();

      // HACK: p-editor add a space when deleting content
      if (this.material?.description_local.description_fr_CA === ' ') {
        this.material.description_local.description_fr_CA = '';
      }
      if (this.material?.description_local.description_en_CA === ' ') {
        this.material.description_local.description_en_CA = '';
      }

      this.loading = true;
      this.materialsService
        .updateMaterial(this.material, this.material.uuid)
        .subscribe({
          next: (response) => {
            // ////this.logger.info("MaterialComponent.autosaveDataChange() -> SUCCESS: " + JSON.stringify(response));
            ////this.logger.info("MaterialComponent.autosaveDataChange() -> SUCCESS!");
            this.loading = false;
            this.form.markAsPristine();
            this.form.markAsUntouched();
            this.loadMaterial();

            //
            if (this.richTextsValidator) {
              this.richTextsValidator.clean();
            }
          },
          error: (error) => {
            //this.logger.error("MaterialComponent.autosaveDataChange() -> ERROR: " + JSON.stringify(error));
            this.loading = false;
            this.handleAPIError(error, this.dialog, null, null);
          },
        });
    }
  }

  public onCancelEdit(event: any): void {
    this.editMode = false;

    if (this.noteEditor) {
      this.noteEditor.readonly = !this.editMode;
    }

    if (this.noteSaleEditor) {
      this.noteSaleEditor.readonly = !this.editMode;
    }

    if (this.descriptionFrEditor) {
      this.descriptionFrEditor.readonly = !this.editMode;
    }

    if (this.descriptionEnEditor) {
      this.descriptionEnEditor.readonly = !this.editMode;
    }

    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.loadMaterial();
  }

  public override autosaveDataChange(data: any): void {
    // if (AutosaveUtils.hasKey(data, this.autosaveNameKeys)) {
    //     ////this.logger.info("MaterialComponent.autosaveDataChange() -> IS NAME LOCAL");
    //     data = { name_local: this.material.name_local };
    //     // data = {name_local: {name_fr_CA: this.material.name_local.name_fr_CA, name_en_CA: this.material.name_local.name_en_CA}};
    // } else if (AutosaveUtils.hasKey(data, this.autosaveDescriptionKeys)) {
    //     ////this.logger.info("MaterialComponent.autosaveDataChange() -> IS DESCRIPTION LOCAL");
    //     data = { description_local: this.material.description_local };
    //     // data = {description_local: {description_fr_CA: this.material.description_local.description_fr_CA, description_en_CA: this.material.description_local.description_en_CA}};
    // } else {
    //     ////this.logger.info("MaterialComponent.autosaveDataChange() -> NOT LOCAL STRING");
    // }
    // ////this.logger.info("MaterialComponent.autosaveDataChange() -> data: " + JSON.stringify(data));
    // this.loading = true;
    // this.materialsService
    //     .updateMaterial(data, this.material.uuid)
    //     .subscribe(
    //         (response) => {
    //             // ////this.logger.info("MaterialComponent.autosaveDataChange() -> SUCCESS: " + JSON.stringify(response));
    //             ////this.logger.info("MaterialComponent.autosaveDataChange() -> SUCCESS!");
    //             this.loading = false;
    //             // this.form.markAsPristine();
    //             // this.form.markAsUntouched();
    //             if (Settings.refreshFormData) {
    //                 this.loadMaterial();
    //             }
    //         },
    //         (error) => {
    //             //this.logger.error("MaterialComponent.autosaveDataChange() -> ERROR: " + JSON.stringify(error));
    //             this.loading = false;
    //             this.handleAPIError(error, this.dialog, null, null);
    //         }
    //     );
  }

  public onMaterialProviderSelected(provider: any): void {
    // //this.logger.info("MaterialComponent.onMaterialProviderSelected() -> provider: " + JSON.stringify(provider));
    // this.autosaveDataChange({ material_provider: provider });
    this.material.material_provider = provider;
  }

  public onMaterialCategorySelected(category: any): void {
    // //this.logger.info("MaterialComponent.onMaterialCategorySelected() -> provider: " + JSON.stringify(category));
    // this.autosaveDataChange({ material_category: category });
    this.material.material_category = category;
  }

  public onEditCombo(): void {
    // //this.logger.info("MaterialComponent.onEditCombo()");
    this.comboDialogRef = this.dialog.open(CreateMaterialComboComponent, {
      width: '80%',
      minWidth: 360,
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: { material: this.material, materials: this.material?.childs },
    });

    this.comboDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.loadMaterial();
        }
        this.comboDialogRef = null;
      },
    });
  }

  public onNavigateToMaterial(item: any): void {
    //this.logger.info("onNavigateToMaterial()");
    this.router.navigateByUrl(ItemUrlFinder.getItemURL(item));
    this.mainContainer.nativeElement.scrollTop = 0;
  }

  public onNavigateToNewTabMaterial(item: any): void {
    window.open(ItemUrlFinder.getItemURL(item), '_blank');
  }

  public onNavigateToOrder(event: any): void {
    this.router.navigateByUrl(ItemUrlFinder.getItemURL(this.material?.order));
  }

  public onMaterialChildDrop(event: any) {
    // ////this.logger.info("ProductComponent.onProductChildDrop() -> " + JSON.stringify(event.value));
    ////this.logger.info("MaterialComponent.onMaterialChildDrop() -> " + JSON.stringify(this.material.childs));
    if (this.material?.childs.length < 2) {
      return;
    }

    let i: number = 0;
    const length: number = this.material?.childs.length;
    for (i; i < length; i++) {
      if (this.material?.childs[i].uuid === event.value.uuid) {
        // move before
        if (i === 0) {
          ////this.logger.info("Combo UUID: " + this.material.uuid);
          ////this.logger.info("productUUID: " + event.value.uuid);
          ////this.logger.info("beforeUUID: " + this.material.childs[i + 1].uuid);
          this.materialsService
            .productChildMoveBefore(
              this.material.uuid,
              event.value.uuid,
              this.material?.childs[i + 1].uuid,
            )
            .subscribe({
              next: (response) => {
                ////this.logger.info("MaterialComponent.onMaterialChildDrop() -> SUCCESS (productChildMoveBefore): " + JSON.stringify(response));
              },
              error: (error) => {
                //this.logger.error("MaterialComponent.onMaterialChildDrop() -> ERROR (productChildMoveBefore): " + JSON.stringify(error));
                this.handleAPIError(error, this.dialog, null, null);
              },
            });
        } // move after
        else {
          ////this.logger.info("Combo UUID: " + this.material.uuid);
          ////this.logger.info("productUUID: " + event.value.uuid);
          ////this.logger.info("afterUUID: " + this.material.childs[i - 1].uuid);
          this.materialsService
            .productChildMoveAfter(
              this.material.uuid,
              event.value.uuid,
              this.material?.childs[i - 1].uuid,
            )
            .subscribe({
              next: (response) => {
                ////this.logger.info("MaterialComponent.onMaterialChildDrop() -> SUCCESS (productChildMoveBefore): " + JSON.stringify(response));
              },
              error: (error) => {
                //this.logger.error("MaterialComponent.onMaterialChildDrop() -> ERROR (productChildMoveBefore): " + JSON.stringify(error));
                this.handleAPIError(error, this.dialog, null, null);
              },
            });
        }
        break;
      }
    }
  }

  // public onPageBack(): void
  // {
  //     this.location.back();
  // }

  // public setNoteAutoSave(
  //     note: Editor,
  //     subscription: Subscription,
  //     key: string
  // ): void {
  //     if (this.editMode) {
  //         subscription = note.onTextChange
  //             .debounceTime(this.noteEditordebounceTimeValue)
  //             .distinctUntilChanged()
  //             .subscribe((data) => {
  //                 let obj: any = {};

  //                 if (this.autosaveDescriptionKeys.includes(key)) {
  //                     obj = {
  //                         description_local: this.material.description_local,
  //                     };
  //                     obj.description_local[key] = data.htmlValue;
  //                 } else {
  //                     obj[key] = data.htmlValue;
  //                     if (obj[key]) {
  //                         this.noteInvalid[key] =
  //                             obj[key].length > Settings.editorMaxCharLong;
  //                     }
  //                 }

  //                 if (this.noteInvalid[key]) {
  //                     return;
  //                 }

  //                 // if (this.noteInvalid[key])
  //                 // {
  //                 //     return;
  //                 // }

  //                 this.loading = true;
  //                 this.materialsService
  //                     .updateMaterial(obj, this.materialID)
  //                     .subscribe(
  //                         (response) => {
  //                             ////this.logger.info("MaterialComponent.noteEditor.onTextChange() -> SUCCESS: " + JSON.stringify(response));
  //                             this.loading = false;
  //                         },
  //                         (error) => {
  //                             //this.logger.error("MaterialComponent.noteEditor.onTextChange() -> ERROR: " + JSON.stringify(error));
  //                             this.handleAPIError(
  //                                 error,
  //                                 this.dialog,
  //                                 null,
  //                                 null
  //                             );
  //                             this.loading = false;
  //                         }
  //                     );
  //             });
  //     } else if (subscription) {
  //         subscription.unsubscribe();
  //         subscription = null;
  //     }
  // }

  public onComboItemInternalViewChange(event: any, item: any): void {
    // ////this.logger.info("MaterialComponent.onComboItemInternalViewChange() -> " + event.checked);
    // ////this.logger.info("MaterialComponent.onComboItemInternalViewChange() -> " + event.checked);
    ////this.logger.info("MaterialComponent.onComboItemInternalViewChange() -> item.internal_view_only:" + item.internal_view_only);
    ////this.logger.info("MaterialComponent.onComboItemInternalViewChange() -> item.show_unit_price:" + item.show_unit_price);

    // ////this.logger.info("MaterialComponent.onComboItemInternalViewChange() -> " + JSON.stringify(this.material.childs));
    // this.autosaveDataChange( {childs: this.material.childs });

    this.loading = true;
    this.materialsService
      .productComboUpdateBoolean(
        this.materialID,
        item.uuid,
        item.qty,
        item.internal_view_only === false || item.internal_view_only === 0
          ? 0
          : 1,
        item.show_unit_price === false || item.show_unit_price === 0 ? 0 : 1,
      )
      .subscribe({
        next: (response) => {
          ////this.logger.info("MaterialComponent.onComboItemInternalViewChange() -> SUCCESS: " + JSON.stringify(response));
          this.loading = false;
        },
        error: (error) => {
          //this.logger.error("MaterialComponent.onComboItemInternalViewChange() -> ERROR: " + JSON.stringify(error));
          this.handleAPIError(error, this.dialog, null, null);
          this.loading = false;
        },
      });
  }

  public onTaxesSelected(taxes: any): void {
    ////this.logger.info("MaterialComponent.onTaxesSelected() -> taxes: " + JSON.stringify(taxes));
    // this.autosaveDataChange({ tax: taxes });
    this.material.tax = taxes;
  }

  public onOpenInNewTab(): void {
    window.open(this.router.url, '_blank');
  }

  public onCloneMaterial(): void {
    ////this.logger.info("onCloneMaterial() -> uuid: " + this.material.uuid);
    if (this.isCloning) {
      ////this.logger.info("onCloneMaterial() -> ALREADY CLONING. DENIED!!!");
      return;
    }

    this.isCloning = true;
    this.materialsService.cloneMaterial(this.material.uuid).subscribe({
      next: (response) => {
        ////this.logger.info("MaterialComponent.onCloneMaterial() -> SUCCESS: " + JSON.stringify(response));
        this.isCloning = false;
        if (response.data) {
          // this.router.navigateByUrl('/' + AppRoutes.MATERIALS + "/" + response.data.slug + '/' + response.data.uuid);
          this.router.navigateByUrl(ItemUrlFinder.getItemURL(response.data));
        }
      },
      error: (error) => {
        //this.logger.error("MaterialComponent.onCloneMaterial() -> ERROR: " + JSON.stringify(error));
        this.handleAPIError(
          error,
          this.dialog,
          this.toastr,
          this.translate.instant('GENERIC.ERRORS.GENERIC'),
        );
        this.isCloning = false;
        // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
      },
    });
  }

  public onCloneCombo(): void {
    ////this.logger.info("onCloneCombo() -> uuid: " + this.material.uuid);
    if (this.isCloning) {
      ////this.logger.info("onCloneCombo() -> ALREADY CLONING. DENIED!!!");
      return;
    }

    this.isCloning = true;
    this.materialsService.cloneMaterialCombo(this.material.uuid).subscribe({
      next: (response) => {
        ////this.logger.info("MaterialComponent.onCloneCombo() -> SUCCESS: " + JSON.stringify(response));
        this.isCloning = false;
        if (response.data) {
          // this.router.navigateByUrl('/' + AppRoutes.MATERIALS + "/" + response.data.slug + '/' + response.data.uuid);
          this.router.navigateByUrl(ItemUrlFinder.getItemURL(response.data));
        }
      },
      error: (error) => {
        //this.logger.error("MaterialComponent.onCloneCombo() -> ERROR: " + JSON.stringify(error));
        this.handleAPIError(
          error,
          this.dialog,
          this.toastr,
          this.translate.instant('GENERIC.ERRORS.GENERIC'),
        );
        this.isCloning = false;
        // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
      },
    });
  }

  public onCombo(): void {
    ////this.logger.info("MaterialComponent.onCombo()");
    this.comboDialogRef = this.dialog.open(CreateMaterialComboComponent, {
      width: '80%',
      minWidth: 350,
      disableClose: false,
      panelClass: 'custom-dialog-container',
      data: {
        material: this.material,
        materials: null,
        creationMode: true,
      },
    });

    this.comboDialogRef.componentInstance.confirmMessage = ''; // this.translate.instant("ROOMS.DELETE_CONFIRM");

    this.comboDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        this.loadMaterial();
        this.comboDialogRef = null;
      },
    });
  }

  public toggleSidebarOpen(key: string): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  public doPrint(url: string): void {
    this.print.printHTML(url).subscribe({
      next: (response: any) => {
        ////this.logger.info("MaterialComponent.doPrint() -> SUCCESS: " + JSON.stringify(response));
      },
      error: (error: any) => {
        //this.logger.error("MaterialComponent.doPrint() -> ERROR: " + JSON.stringify(error));
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public doDownload(url: string): void {
    this.fileService.downloadFile(url).subscribe({
      next: (response: any) => {
        ////this.logger.info("MaterialComponent.doDownload() -> SUCCESS: " + JSON.stringify(response));
      },
      error: (error: any) => {
        //this.logger.error("MaterialComponent.doDownload() -> ERROR: " + JSON.stringify(error));
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public onPrint(): void {
    // this.print.printHTML();
    const url: string = this.api.createUrl('/reports/materials/html');
    this.doPrint(url);
  }

  public onDownloadPDF(): void {
    const url: string = this.api.createUrl('/reports/materials/pdf');
    // const fileName: string = this.fileService.createFileName(null, "materials", "pdf");
    // this.fileService.downloadFile(url, fileName);
    this.doDownload(url);
  }

  public onMaterialActiveChange(event: any): void {
    ////this.logger.info("MaterialComponent.onMaterialActiveChange() -> " + event.checked);
    // this.autosaveDataChange({ active: event.checked ? 1 : 0 });
    // this.material.tax = taxes;
  }

  public onOpenObjectPage(item: any): void {
    ////this.logger.info("MaterialComponent.onOpenObjectPage() -> item: " + JSON.stringify(item));
    this.router.navigateByUrl(ItemUrlFinder.getItemURL(item));
  }
}
