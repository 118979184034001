<!-- <div id="reports" class="page-layout simple fullwidth" fxLayout="column" fusePerfectScrollbar> -->

<div
  id="reports"
  *ngIf="orgService.organizationMeObject"
  class="page-layout simple inner-sidebar inner-scroll page-container"
>
  <!-- HEADER -->
  <div class="gt2-theme header-container">
    <div
      class="header accent pl-24 pr-24 h-80 mat-elevation-z1"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <div fxLayout="column" fxLayoutAlign="center start">
        <div class="h1">{{ "REPORTS.TITLE" | translate }}</div>
      </div>
    </div>
  </div>
  <!-- / HEADER -->

  <!-- SUB HEADER -->
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="primary mat-elevation-z1 header-container content-subheader"
    fusePerfectScrollbar
  ></div>
  <!-- / SUB HEADER -->

  <div class="p-24 reports-container" fxLayout="column" fusePerfectScrollbar>
    <div
      fxFlex="100"
      fxLayout="row"
      fxLayoutAlign="start start"
      class="reports-content"
    >
      <div fxFlex="50" class="mr-8">
        <div class="profile-box info-box general" fxLayout="column">
          <div class="">
            <header class="accent">
              <div class="title p-16">
                {{ "REPORTS.SALE_STATISTIC" | translate }}
              </div>
            </header>
          </div>
          <div fxLayout="column" class="header-content">
            <div
              [formGroup]="form"
              fxLayout="row"
              fxLayoutAlign="space-between center"
            >
              <mat-form-field class="custom-date-picker">
                <mat-label>{{ "GENERIC.START" | translate }}</mat-label>
                <input
                  matInput
                  formControlName="date_from1"
                  [matDatepicker]="eventDateFrom1"
                  min="1900-01-01"
                  max="2099-01-01"
                  (ngModelChange)="onDateFromChange1($event)"
                  [(ngModel)]="dateRange1.date_from"
                  class=""
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="eventDateFrom1"
                ></mat-datepicker-toggle>
                <mat-datepicker #eventDateFrom1></mat-datepicker>

                <mat-error *ngIf="form.controls['date_from1'].invalid"
                  >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
                </mat-error>
              </mat-form-field>
              <div style="padding: 5px">&nbsp;</div>
              <mat-form-field class="custom-date-picker">
                <mat-label>{{ "GENERIC.END" | translate }}</mat-label>
                <input
                  matInput
                  formControlName="date_to1"
                  [matDatepicker]="eventDateTo1"
                  min="1900-01-01"
                  max="2099-01-01"
                  (ngModelChange)="onDateToChange1($event)"
                  [(ngModel)]="dateRange1.date_to"
                  class=""
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="eventDateTo1"
                ></mat-datepicker-toggle>
                <mat-datepicker #eventDateTo1></mat-datepicker>

                <mat-error *ngIf="form.controls['date_to1'].invalid"
                  >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
                </mat-error>
              </mat-form-field>

              <div>
                <button
                  mat-icon-button
                  class=""
                  [matMenuTriggerFor]="menuFrom1"
                >
                  <mat-icon class="">more</mat-icon>
                </button>
              </div>
              <mat-menu #menuFrom1="matMenu">
                <button mat-menu-item (click)="onSelectToday1()">
                  {{ "GENERIC.TODAY" | translate }}
                </button>
                <button mat-menu-item (click)="onSelectThisWeek1()">
                  {{ "GENERIC.WEEK" | translate }}
                </button>
                <button mat-menu-item (click)="onSelectThisMonth1()">
                  {{ "GENERIC.MONTH" | translate }}
                </button>
              </mat-menu>
            </div>
          </div>
          <div class="content" fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div>{{ "REPORTS.PRODUCT_CATEGORY" | translate }}</div>
              <div>
                <button
                  type="button"
                  *ngIf="fileService.excelEnabled()"
                  matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
                  mat-icon-button
                  class=""
                  (click)="onDownloadSalesStatistic()"
                >
                  <mat-icon
                    fontSet="fa"
                    fontIcon="{{ iconsService.getIcon('XLS') }}"
                    class="fa-lg"
                  >
                  </mat-icon>
                </button>
              </div>
            </div>

            <div
              *ngIf="
                modulesService.modulesAreSet && modulesService.hasJRReport()
              "
              fxLayout="row"
              fxLayoutAlign="space-between center"
            >
              <div>{{ "REPORTS.JR_PRODUCT_CATEGORY" | translate }}</div>
              <div>
                <button
                  type="button"
                  *ngIf="fileService.excelEnabled()"
                  matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
                  mat-icon-button
                  class=""
                  (click)="
                    menuReportsService.buildAndRunVo(
                      ReportsEnum.REPORT2_ADMIN_SALE_STATS_PRODUCT_BY_CATEGORY_JR_XLSX,
                      DistributionModeEnum.FILE,
                      ExportFormatEnum.XLSX,
                      ExportToEnum.DOWNLOAD,
                      LocaleEnum.FR_CA,
                      PaperEnum.LETTER,
                      dateRange1
                    )
                  "
                >
                  <mat-icon
                    fontSet="fa"
                    fontIcon="{{ iconsService.getIcon('XLS') }}"
                    class="fa-lg"
                  >
                  </mat-icon>
                </button>

                <!-- <button mat-menu-item (click)="menuReportsService.buildAndRunVo(
                                                            ReportsEnum.REPORT2_ADMIN_SALE_STATS_PRODUCT_BY_CATEGORY_JR_XLSX,
                                                            DistributionModeEnum.FILE,
                                                            ExportFormatEnum.PDF_STACK,
                                                            ExportToEnum.DOWNLOAD,
                                                            LocaleEnum.FR_CA,
                                                            PaperEnum.LETTER,
                                                            dateRange1)">
                                    {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                                </button> -->
              </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div>{{ "REPORTS.EVENT_TYPE" | translate }}</div>
              <div>
                <button
                  type="button"
                  *ngIf="fileService.excelEnabled()"
                  matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
                  mat-icon-button
                  class=""
                  (click)="onDownloadEventType()"
                >
                  <mat-icon
                    fontSet="fa"
                    fontIcon="{{ iconsService.getIcon('XLS') }}"
                    class="fa-lg"
                  >
                  </mat-icon>
                </button>
              </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div>{{ "GENERIC.CLIENTS" | translategt }}</div>
              <div>
                <button
                  type="button"
                  *ngIf="fileService.excelEnabled()"
                  matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
                  mat-icon-button
                  class=""
                  (click)="onDownloadClient()"
                >
                  <mat-icon
                    fontSet="fa"
                    fontIcon="{{ iconsService.getIcon('XLS') }}"
                    class="fa-lg"
                  >
                  </mat-icon>
                </button>
              </div>
            </div>

            <!-- Vente par entreprises (Préliminaire) -->
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div>
                {{ "GENERIC.ENTERPRISES" | translategt }}
                {{ "REPORTS.PROVISIONAL" | translate }}

                <button
                  type="button"
                  mat-icon-button
                  class=""
                  matTooltip="{{
                    'REPORTS_HELP.REPORT_ADMIN_SALE_STATS_BY_ENTERPRISE_PRELIMINARY'
                      | translate
                  }}"
                >
                  <mat-icon class="s-20 icon-mat" color="">help</mat-icon>
                </button>
              </div>
              <div>
                <button
                  type="button"
                  *ngIf="fileService.excelEnabled()"
                  matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
                  mat-icon-button
                  class=""
                  (click)="onDownloadEnterprisePreliminary()"
                >
                  <mat-icon
                    fontSet="fa"
                    fontIcon="{{ iconsService.getIcon('XLS') }}"
                    class="fa-lg"
                  >
                  </mat-icon>
                </button>
              </div>
            </div>

            <!-- Vente par entreprises -->
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div>
                {{ "GENERIC.ENTERPRISES" | translategt }}
                <button
                  type="button"
                  mat-icon-button
                  class=""
                  matTooltip="{{
                    'REPORTS_HELP.REPORT_ADMIN_SALE_STATS_BY_ENTERPRISE'
                      | translate
                  }}"
                >
                  <mat-icon class="s-20 icon-mat" color="">help</mat-icon>
                </button>
              </div>
              <div>
                <button
                  type="button"
                  *ngIf="fileService.excelEnabled()"
                  matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
                  mat-icon-button
                  class=""
                  (click)="onDownloadEnterprise()"
                >
                  <mat-icon
                    fontSet="fa"
                    fontIcon="{{ iconsService.getIcon('XLS') }}"
                    class="fa-lg"
                  >
                  </mat-icon>
                </button>
              </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div>{{ "REPORTS.SELLER" | translate }}</div>
              <div>
                <button
                  type="button"
                  *ngIf="fileService.excelEnabled()"
                  matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
                  mat-icon-button
                  class=""
                  (click)="onDownloadSeller()"
                >
                  <mat-icon
                    fontSet="fa"
                    fontIcon="{{ iconsService.getIcon('XLS') }}"
                    class="fa-lg"
                  >
                  </mat-icon>
                </button>
              </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div>
                {{ "REPORTS.DELIVERY_TYPE" | translate }}
                <button
                  type="button"
                  mat-icon-button
                  class=""
                  matTooltip="{{
                    'REPORTS_HELP.REPORT_ADMIN_SALE_STATS_BY_DELIVERY_TYPE'
                      | translate
                  }}"
                >
                  <mat-icon class="s-20 icon-mat" color="">help</mat-icon>
                </button>
              </div>
              <div>
                <button
                  type="button"
                  *ngIf="fileService.excelEnabled()"
                  matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
                  mat-icon-button
                  class=""
                  (click)="onDownloadDeliveryType()"
                >
                  <mat-icon
                    fontSet="fa"
                    fontIcon="{{ iconsService.getIcon('XLS') }}"
                    class="fa-lg"
                  >
                  </mat-icon>
                </button>
              </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div>{{ "REPORTS.PRODUCT_VELOCITY" | translate }}</div>
              <div>
                <button
                  type="button"
                  *ngIf="fileService.excelEnabled()"
                  matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
                  mat-icon-button
                  class=""
                  (click)="onDownloadProductVelocity()"
                >
                  <mat-icon
                    fontSet="fa"
                    fontIcon="{{ iconsService.getIcon('XLS') }}"
                    class="fa-lg"
                  >
                  </mat-icon>
                </button>
              </div>
            </div>
            <div
              fxLayout="row"
              fxLayoutAlign="space-between center"
              *ngIf="
                modulesService.modulesAreSet && modulesService.hasRPReport()
              "
            >
              <div>{{ "REPORTS.SUMMARIES_OF_EVENTS" | translate }}</div>
              <div>
                <button
                  type="button"
                  *ngIf="fileService.excelEnabled()"
                  matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
                  mat-icon-button
                  class=""
                  (click)="
                    menuReportsService.buildAndRunVo(
                      ReportsEnum.REPORT_ADMIN_SALE_STATS_SUMMARY_EVENT_XLSX,
                      DistributionModeEnum.FILE,
                      ExportFormatEnum.XLSX,
                      ExportToEnum.DOWNLOAD,
                      LocaleEnum.FR_CA,
                      PaperEnum.LETTER,
                      dateRange1
                    )
                  "
                >
                  <mat-icon
                    fontSet="fa"
                    fontIcon="{{ iconsService.getIcon('XLS') }}"
                    class="fa-lg"
                  >
                  </mat-icon>
                </button>
              </div>
            </div>
            <!-- Liste des produits vendus -->
            <div>
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div fxLayout="row" fxLayoutAlign="start center">
                  <div>{{ "REPORTS.PRODUCTS_SOLD_LIST" | translategt }}</div>
                  <button
                    type="button"
                    mat-icon-button
                    class=""
                    matTooltip="{{
                      'REPORTS.SOLD_PRODUCTS_LIST_HINT' | translate
                    }}"
                  >
                    <mat-icon color="" class="s-20 icon-mat">help</mat-icon>
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
                    mat-icon-button
                    class=""
                    (click)="
                      menuReportsService.buildAndRunVo(
                        ReportsEnum.REPORT2_ADMIN_PRODUCTS_SOLD_LIST_XLSX,
                        DistributionModeEnum.FILE,
                        ExportFormatEnum.XLSX,
                        ExportToEnum.DOWNLOAD,
                        LocaleEnum.FR_CA,
                        PaperEnum.LETTER,
                        dateRange1
                      )
                    "
                  >
                    <mat-icon
                      fontSet="fa"
                      fontIcon="{{ iconsService.getIcon('XLS') }}"
                      class="fa-lg"
                    >
                    </mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div fxFlex="50" class="ml-8">
        <div class="profile-box info-box general" fxLayout="column">
          <div class="">
            <header class="accent">
              <div class="title p-16">
                {{ "REPORTS.ACCOUNTING" | translate }}
              </div>
            </header>
          </div>
          <div fxLayout="column" class="header-content">
            <div
              [formGroup]="form"
              fxLayout="row"
              fxLayoutAlign="space-between center"
            >
              <mat-form-field class="custom-date-picker">
                <mat-label>{{ "GENERIC.START" | translate }}</mat-label>
                <input
                  matInput
                  formControlName="date_from2"
                  [matDatepicker]="eventDateFrom2"
                  min="1900-01-01"
                  max="2099-01-01"
                  (ngModelChange)="onDateFromChange2($event)"
                  [(ngModel)]="dateRange2.date_from"
                  class=""
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="eventDateFrom2"
                ></mat-datepicker-toggle>
                <mat-datepicker #eventDateFrom2></mat-datepicker>

                <mat-error *ngIf="form.controls['date_from2'].invalid"
                  >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
                </mat-error>
              </mat-form-field>
              <div style="padding: 5px">&nbsp;</div>
              <mat-form-field class="custom-date-picker">
                <mat-label>{{ "GENERIC.END" | translate }}</mat-label>
                <input
                  matInput
                  formControlName="date_to2"
                  [matDatepicker]="eventDateTo2"
                  min="1900-01-01"
                  max="2099-01-01"
                  (ngModelChange)="onDateToChange2($event)"
                  [(ngModel)]="dateRange2.date_to"
                  class=""
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="eventDateTo2"
                ></mat-datepicker-toggle>
                <mat-datepicker #eventDateTo2></mat-datepicker>

                <mat-error *ngIf="form.controls['date_to2'].invalid"
                  >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
                </mat-error>
              </mat-form-field>

              <div>
                <button
                  mat-icon-button
                  class=""
                  [matMenuTriggerFor]="menuFrom2"
                >
                  <mat-icon class="">more</mat-icon>
                </button>
              </div>
              <mat-menu #menuFrom2="matMenu">
                <button mat-menu-item (click)="onSelectToday2()">
                  {{ "GENERIC.TODAY" | translate }}
                </button>
                <button mat-menu-item (click)="onSelectThisWeek2()">
                  {{ "GENERIC.WEEK" | translate }}
                </button>
                <button mat-menu-item (click)="onSelectThisMonth2()">
                  {{ "GENERIC.MONTH" | translate }}
                </button>
              </mat-menu>
            </div>
          </div>
          <div class="content" fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div fxLayout="row" fxLayoutAlign="start center">
                <div>{{ "REPORTS.SALE_BY_LEDGER_ACCOUNT" | translate }}</div>
                <button
                  type="button"
                  mat-icon-button
                  class=""
                  matTooltip="{{ 'GENERIC.GL_TOOLTIP_HINT' | translate }}"
                >
                  <mat-icon
                    content-type="template"
                    color=""
                    class="s-20 icon-mat"
                  >
                    help
                  </mat-icon>
                </button>
              </div>
              <div>
                <button
                  type="button"
                  *ngIf="fileService.excelEnabled()"
                  matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
                  mat-icon-button
                  class=""
                  (click)="onDownloadSalesByLedgerAccount()"
                >
                  <mat-icon
                    fontSet="fa"
                    fontIcon="{{ iconsService.getIcon('XLS') }}"
                    class="fa-lg"
                  >
                  </mat-icon>
                </button>
              </div>
            </div>

            <div
              *ngxPermissionsOnly="['GOD']"
              fxLayout="row"
              fxLayoutAlign="space-between center"
            >
              <div fxLayout="row" fxLayoutAlign="start center">
                <div>{{ "REPORTS.SALE_BY_LEDGER_ACCOUNT_JR" | translate }}</div>
                <button
                  type="button"
                  mat-icon-button
                  class=""
                  matTooltip="{{ 'GENERIC.GL_TOOLTIP_HINT' | translate }}"
                >
                  <mat-icon
                    content-type="template"
                    color=""
                    class="s-20 icon-mat"
                  >
                    help
                  </mat-icon>
                </button>
              </div>
              <div>
                <button
                  type="button"
                  matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
                  mat-icon-button
                  class=""
                  (click)="
                    menuReportsService.buildAndRunVo(
                      ReportsEnum.REPORT2_ADMIN_ACCOUNTING_SALE_BY_LEDGER_ACCOUNT_XLSX_ALT,
                      DistributionModeEnum.FILE,
                      ExportFormatEnum.XLSX,
                      ExportToEnum.DOWNLOAD,
                      LocaleEnum.FR_CA,
                      PaperEnum.LETTER,
                      dateRange2
                    )
                  "
                >
                  <mat-icon
                    fontSet="fa"
                    fontIcon="{{ iconsService.getIcon('XLS') }}"
                    class="fa-lg"
                  >
                  </mat-icon>
                </button>
              </div>
            </div>

            <div
              *ngIf="
                moduleService.modulesAreSet && moduleService.hasUQAMReport()
              "
              fxLayout="row"
              fxLayoutAlign="space-between center"
            >
              <div fxLayout="row" fxLayoutAlign="start center">
                <div>
                  {{ "REPORTS.SALE_BY_LEDGER_ACCOUNT_UQAM" | translate }}
                </div>
              </div>
              <div>
                <button
                  type="button"
                  matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
                  mat-icon-button
                  class=""
                  (click)="
                    menuReportsService.buildAndRunVo(
                      ReportsEnum.REPORT2_ADMIN_ACCOUNTING_SALE_BY_LEDGER_ACCOUNT_UQAM_XLSX_ALT,
                      DistributionModeEnum.FILE,
                      ExportFormatEnum.XLSX,
                      ExportToEnum.DOWNLOAD,
                      LocaleEnum.FR_CA,
                      PaperEnum.LETTER,
                      dateRange2
                    )
                  "
                >
                  <mat-icon
                    fontSet="fa"
                    fontIcon="{{ iconsService.getIcon('XLS') }}"
                    class="fa-lg"
                  >
                  </mat-icon>
                </button>
              </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div fxLayout="row" fxLayoutAlign="start center">
                <div>
                  {{ "REPORTS.SALE_BY_LEDGER_ACCOUNT_PER_DAY" | translate }}
                </div>
                <button
                  type="button"
                  mat-icon-button
                  class=""
                  matTooltip="{{
                    'REPORTS.SALE_BY_LEDGER_ACCOUNT_PER_DAY_HINT' | translate
                  }}"
                >
                  <mat-icon
                    content-type="template"
                    color=""
                    class="s-20 icon-mat"
                  >
                    help
                  </mat-icon>
                </button>
              </div>
              <div>
                <button
                  type="button"
                  *ngIf="fileService.excelEnabled()"
                  matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
                  mat-icon-button
                  class=""
                  (click)="onDownloadSalesByLedgerAccountPerDay()"
                >
                  <mat-icon
                    fontSet="fa"
                    fontIcon="{{ iconsService.getIcon('XLS') }}"
                    class="fa-lg"
                  >
                  </mat-icon>
                </button>
              </div>
            </div>

            <!-- <div fxLayout="row" fxLayoutAlign="space-between center">
                            <div>{{ "REPORTS.CALCULATED_CLIENT_ENTERPRISE_BALANCE" | translate }}</div>
                            <div>
                                <button type="button" x matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
                                    mat-icon-button class="" (click)="onDownloadCalculatedClientAndEnterpriseBalance()">
                                    <mat-icon fontSet="fa" fontIcon="{{ iconsService.getIcon('XLS') }}" class="fa-lg">
                                    </mat-icon>
                                </button>
                            </div>
                        </div>-->

            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div>{{ "REPORTS.INVOICES_LIST" | translate }}</div>
              <div>
                <button
                  type="button"
                  *ngIf="fileService.excelEnabled()"
                  matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
                  mat-icon-button
                  class=""
                  (click)="onDownloadInvoicesList()"
                >
                  <mat-icon
                    fontSet="fa"
                    fontIcon="{{ iconsService.getIcon('XLS') }}"
                    class="fa-lg"
                  >
                  </mat-icon>
                </button>
              </div>
            </div>

            <div
              *ngIf="
                modulesService.modulesSet && modulesService.hasUQAMReport()
              "
              fxLayout="row"
              fxLayoutAlign="space-between center"
            >
              <div>{{ "REPORTS.INVOICES_LIST" | translate }} - UQAM</div>
              <div>
                <button
                  type="button"
                  matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
                  mat-icon-button
                  class=""
                  (click)="
                    menuReportsService.buildAndRunVo(
                      ReportsEnum.REPORT2_ADMIN_ACCOUNTING_LIST_OF_INVOICES_UQAM_XLSX,
                      DistributionModeEnum.FILE,
                      ExportFormatEnum.XLSX,
                      ExportToEnum.DOWNLOAD,
                      LocaleEnum.FR_CA,
                      PaperEnum.LETTER,
                      dateRange2
                    )
                  "
                >
                  <mat-icon
                    fontSet="fa"
                    fontIcon="{{ iconsService.getIcon('XLS') }}"
                    class="fa-lg"
                  >
                  </mat-icon>
                </button>
              </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div>{{ "REPORTS.RECEIPTS_JOURNAL" | translate }}</div>
              <div>
                <button
                  type="button"
                  *ngIf="fileService.excelEnabled()"
                  matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
                  mat-icon-button
                  class=""
                  (click)="onDownloadReceiptsJournal()"
                >
                  <mat-icon
                    fontSet="fa"
                    fontIcon="{{ iconsService.getIcon('XLS') }}"
                    class="fa-lg"
                  >
                  </mat-icon>
                  <!--<i class="fas {{ iconsService.getIcon('XLS') }}"></i>-->
                </button>
              </div>
            </div>

            <!-- Paiement interne UDEM -->
            <div
              *ngIf="
                moduleService.modulesAreSet && moduleService.hasUDEMReport()
              "
              fxLayout="row"
              fxLayoutAlign="space-between center"
            >
              <div>{{ "REPORTS.INTERNAL_PAYMENT" | translate }} - UDEM</div>
              <div>
                <button
                  type="button"
                  *ngIf="fileService.excelEnabled()"
                  matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
                  mat-icon-button
                  class=""
                  (click)="onDownloadInternalPayment()"
                >
                  <mat-icon
                    fontSet="fa"
                    fontIcon="{{ iconsService.getIcon('XLS') }}"
                    class="fa-lg"
                  >
                  </mat-icon>
                </button>
              </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div>{{ "REPORTS.ACCOUNTING_SUMMARY_PAYMENTS" | translate }}</div>
              <div>
                <button
                  type="button"
                  *ngIf="fileService.printEnabled()"
                  matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                  mat-icon-button
                  class=""
                  (click)="onDownloadPrintSummaryOfDeposits()"
                >
                  <mat-icon class="" color="">{{
                    iconsService.getIcon("PRINT")
                  }}</mat-icon>
                </button>
                <button
                  type="button"
                  *ngIf="fileService.pdfEnabled()"
                  matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                  mat-icon-button
                  class=""
                  (click)="onDownloadPdfSummaryOfDeposits()"
                >
                  <mat-icon class="" color="">{{
                    iconsService.getIcon("PDF")
                  }}</mat-icon>
                </button>
              </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div>
                {{
                  "REPORTS.REPORT_LIST_DEPOSITS_COLLECTED_FROM_BILLED_EVENTS"
                    | translate
                }}
              </div>
              <div>
                <button
                  type="button"
                  matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                  *ngIf="fileService.printEnabled()"
                  mat-icon-button
                  class=""
                  (click)="onDownloadPrintAdminCasheDepositeAfterInvoiceAt()"
                >
                  <mat-icon class="" color="">{{
                    iconsService.getIcon("PRINT")
                  }}</mat-icon>
                </button>
                <button
                  type="button"
                  matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                  *ngIf="fileService.pdfEnabled()"
                  mat-icon-button
                  class=""
                  (click)="onDownloadPdfAdminCasheDepositeAfterInvoiceAt()"
                >
                  <mat-icon class="" color="">{{
                    iconsService.getIcon("PDF")
                  }}</mat-icon>
                </button>
              </div>
            </div>

            <!--<div fxLayout="row" fxLayoutAlign="space-between center">
                            <div>{{ "REPORTS.ACCOUNTING_SYSTEM_CONTACTS" | translate }}</div>
                            <div>
                                <button type="button" matTooltip="{{ 'GENERIC.DOWNLOAD_CSV' | translate }}"
                                    mat-icon-button class="" (click)="onDownloadAccountingContacts()">
                                    <mat-icon fontSet="fa" fontIcon="{{ iconsService.getIcon('CSV') }}" class="fa-lg">
                                    </mat-icon>
                                </button>
                            </div>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <div>{{ "REPORTS.ACCOUNTING_SYSTEM_INVOICES" | translate }}</div>
                            <div>
                                <button type="button" matTooltip="{{ 'GENERIC.DOWNLOAD_CSV' | translate }}"
                                    mat-icon-button class="" (click)="onDownloadAccountingSystemInvoices()">
                                    <mat-icon fontSet="fa" fontIcon="{{ iconsService.getIcon('CSV') }}" class="fa-lg">
                                    </mat-icon>
                                </button>
                            </div>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <div>{{ "REPORTS.ACCOUNTING_SYSTEM_LOGS" | translate }}</div>
                            <div>
                                <button type="button" matTooltip="{{ 'GENERIC.DOWNLOAD_CSV' | translate }}"
                                    mat-icon-button class="" (click)="onDownloadAccountingSystemLogs()">
                                    <mat-icon fontSet="fa" fontIcon="{{ iconsService.getIcon('CSV') }}" class="fa-lg">
                                    </mat-icon>
                                </button>
                            </div>
                        </div>-->
          </div>
        </div>
      </div>
    </div>
    <div
      fxFlex="100"
      fxLayout="row"
      fxLayoutAlign="start start"
      class="management-content"
    >
      <div fxFlex="50" class="mr-8">
        <div class="profile-box info-box general" fxLayout="column">
          <div class="">
            <header class="accent">
              <div class="title p-16">
                {{ "REPORTS.ACCOUNTING_UNTIL" | translate }}
              </div>
            </header>
          </div>

          <div fxLayout="column" class="header-content">
            <div
              [formGroup]="form"
              fxLayout="row"
              fxLayoutAlign="space-between center"
            >
              <mat-form-field class="custom-date-picker">
                <mat-label>{{ "GENERIC.UNTIL" | translate }}</mat-label>
                <input
                  matInput
                  formControlName="date_to3"
                  [matDatepicker]="eventDateTo3"
                  min="1900-01-01"
                  max="2099-01-01"
                  (ngModelChange)="onDateToChange3($event)"
                  [(ngModel)]="dateRange3.date_to"
                  class=""
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="eventDateTo3"
                ></mat-datepicker-toggle>
                <mat-datepicker #eventDateTo3></mat-datepicker>

                <mat-error *ngIf="form.controls['date_to3'].invalid"
                  >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="content" fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div>{{ "REPORTS.AGE_OF_CLIENT_ACCOUNT" | translate }}</div>
              <div>
                <!--                             <mat-form-field class="custom-date-picker pr-12" fxLayout="row" fxLayoutAlign="start center">
                                                                 <input matInput placeholder="{{ 'GENERIC.END' | translate }}" formControlName="date_to"
                                                                        [matDatepicker]="eventDateTo" min="1900-01-01" max="2099-01-01"
                                                                        (ngModelChange)="onDateToChange($event)" [(ngModel)]="dateRange.date_to" class="">
                                                                 <mat-datepicker-toggle matSuffix [for]="eventDateTo"></mat-datepicker-toggle>
                                                                 <mat-datepicker #eventDateTo></mat-datepicker>

                                                                 <mat-error *ngIf="form.controls['date_to'].invalid">{{
                                                                     'GENERIC.DATE_DEFAULT_RANGE_INVALID' | translate }}
                                                                 </mat-error>
                                                             </mat-form-field>-->
                <button
                  type="button"
                  *ngIf="fileService.printEnabled()"
                  matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                  mat-icon-button
                  class=""
                  (click)="onDownloadPrintAgeOfClientAccount()"
                >
                  <mat-icon class="" color="">{{
                    iconsService.getIcon("PRINT")
                  }}</mat-icon>
                </button>
                <button
                  type="button"
                  *ngIf="fileService.pdfEnabled()"
                  matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                  mat-icon-button
                  class=""
                  (click)="onDownloadPDFAgeOfClientAccount()"
                >
                  <mat-icon class="" color="">{{
                    iconsService.getIcon("PDF")
                  }}</mat-icon>
                </button>
              </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div>{{ "REPORTS.AGE_OF_ENTERPRISE_ACCOUNT" | translate }}</div>
              <div>
                <button
                  type="button"
                  *ngIf="fileService.printEnabled()"
                  matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                  mat-icon-button
                  class=""
                  (click)="onDownloadPrintAgeOfEnterpriseAccount()"
                >
                  <mat-icon class="" color="">{{
                    iconsService.getIcon("PRINT")
                  }}</mat-icon>
                </button>
                <button
                  type="button"
                  *ngIf="fileService.pdfEnabled()"
                  matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                  mat-icon-button
                  class=""
                  (click)="onDownloadPDFAgeOfEnterpriseAccount()"
                >
                  <mat-icon class="" color="">{{
                    iconsService.getIcon("PDF")
                  }}</mat-icon>
                </button>
              </div>
            </div>
            <!-- report account aging clients not attached && enterprises-->
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div fxLayout="row" fxLayoutAlign="start center">
                <div>{{ "REPORTS.AGE_ACCOUNTS_REPORT" | translate }}</div>

                <button
                  type="button"
                  mat-icon-button
                  class=""
                  matTooltip="{{
                    'REPORTS_HELP.REPORT_AGE_ACCOUNTS' | translate
                  }}"
                >
                  <mat-icon class="s-20 icon-mat" color="">help</mat-icon>
                </button>
              </div>

              <div>
                <button
                  type="button"
                  *ngIf="fileService.printEnabled()"
                  matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                  mat-icon-button
                  class=""
                  (click)="onDownloadPrintAgeAccountsReport()"
                >
                  <mat-icon class="" color="">{{
                    iconsService.getIcon("PRINT")
                  }}</mat-icon>
                </button>
                <button
                  type="button"
                  *ngIf="fileService.pdfEnabled()"
                  matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                  mat-icon-button
                  class=""
                  (click)="onDownloadPDFAgeAccountsReport()"
                >
                  <mat-icon class="" color="">{{
                    iconsService.getIcon("PDF")
                  }}</mat-icon>
                </button>
              </div>
            </div>

            <!-- report aging account BTF -->
            <div *ngxPermissionsOnly="['GOD']">
              <div
                *ngIf="
                  moduleService.modulesAreSet &&
                  (moduleService.hasBTFReport() ||
                    moduleService.hasOdikaReport())
                "
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                <div fxLayout="row" fxLayoutAlign="start center">
                  <div>{{ "REPORTS.AGE_ACCOUNTS_REPORT_BTF" | translate }}</div>
                </div>

                <div>
                  <button
                    type="button"
                    matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                    mat-icon-button
                    class=""
                    (click)="
                      menuReportsService.buildAndRunVo(
                        ReportsEnum.REPORT_ADMIN_AGING_ACCOUNT_BTF,
                        DistributionModeEnum.FILE,
                        ExportFormatEnum.PDF,
                        ExportToEnum.PRINT,
                        LocaleEnum.FR_CA,
                        PaperEnum.LETTER,
                        dateRange3
                      )
                    "
                  >
                    <mat-icon class="" color="">{{
                      iconsService.getIcon("PRINT")
                    }}</mat-icon>
                  </button>

                  <button
                    type="button"
                    matTooltip="{{ 'GENERIC.DOWNLOAD' | translate }}"
                    mat-icon-button
                    class=""
                    (click)="
                      menuReportsService.buildAndRunVo(
                        ReportsEnum.REPORT_ADMIN_AGING_ACCOUNT_BTF,
                        DistributionModeEnum.FILE,
                        ExportFormatEnum.PDF,
                        ExportToEnum.DOWNLOAD,
                        LocaleEnum.FR_CA,
                        PaperEnum.LETTER,
                        dateRange3
                      )
                    "
                  >
                    <mat-icon class="" color="">{{
                      iconsService.getIcon("PDF")
                    }}</mat-icon>
                  </button>

                  <!-- <button type="button" *ngIf="fileService.printEnabled()" matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                                                                mat-icon-button class="" (click)="onDownloadPrintAgeAccountsReport()">
                                                                <mat-icon class="" color="">{{ iconsService.getIcon('PRINT') }}</mat-icon>
                                                            </button>
                                                            <button type="button" *ngIf="fileService.pdfEnabled()" matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                                                                mat-icon-button class="" (click)="onDownloadPDFAgeAccountsReport()">
                                                                <mat-icon class="" color="">{{ iconsService.getIcon('PDF') }}</mat-icon>
                                                            </button> -->
                </div>
              </div>
            </div>

            <!-- end report div -->
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div>
                {{ "REPORTS.CASH_DEPOSIT_BEFORE_INVOICE_AT" | translate }}
              </div>
              <div>
                <button
                  type="button"
                  *ngIf="fileService.excelEnabled()"
                  matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
                  mat-icon-button
                  class=""
                  (click)="onDownloadXlsAdminCasheDepositeBeforeInvoiceAt()"
                >
                  <mat-icon
                    fontSet="fa"
                    fontIcon="{{ iconsService.getIcon('XLS') }}"
                    class="fa-lg"
                  >
                  </mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div fxFlex="50" class="ml-8">
        <div class="profile-box info-box general" fxLayout="column">
          <div class="">
            <header class="accent">
              <div class="title p-16">
                {{ "REPORTS.ACCOUNTING_TODAY" | translate }}
              </div>
            </header>
          </div>
          <div fxLayout="column" class="header-content" style="padding: 22px">
            {{ "REPORTS.DATE_OF" | translate }}
            {{ this.dateNow.toLocaleDateString(translate.currentLang) }}
          </div>
          <div class="content" fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div style="margin-left: 8px">
                {{ "REPORTS.DEPOSIT_TRACKING" | translate }}
              </div>
              <div>
                <button
                  type="button"
                  *ngIf="fileService.printEnabled()"
                  matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                  mat-icon-button
                  class=""
                  (click)="onDownloadPrintDepositTracking()"
                >
                  <mat-icon class="" color="">{{
                    iconsService.getIcon("PRINT")
                  }}</mat-icon>
                </button>
                <button
                  type="button"
                  *ngIf="fileService.pdfEnabled()"
                  matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                  mat-icon-button
                  class=""
                  (click)="onDownloadPDFDepositTracking()"
                >
                  <mat-icon class="" color="">{{
                    iconsService.getIcon("PDF")
                  }}</mat-icon>
                </button>
              </div>
            </div>

            <div
              *ngIf="
                modulesService.modulesAreSet && modulesService.hasOkaReport()
              "
              fxLayout="row"
              fxLayoutAlign="space-between center"
            >
              <div style="margin-left: 8px">
                {{
                  "REPORTS.REPORT_ADMIN_ACCOUNTING_DEPOSITE_TRACKING_OKA"
                    | translate
                }}
              </div>
              <div>
                <button
                  type="button"
                  matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                  mat-icon-button
                  class=""
                  (click)="
                    menuReportsService.buildAndRunVo(
                      ReportsEnum.REPORT_ADMIN_ACCOUNTING_DEPOSITE_TRACKING_OKA,
                      DistributionModeEnum.FILE,
                      ExportFormatEnum.PDF,
                      ExportToEnum.PRINT,
                      LocaleEnum.FR_CA,
                      PaperEnum.LETTER,
                      dateRange3
                    )
                  "
                >
                  <mat-icon class="" color="">{{
                    iconsService.getIcon("PRINT")
                  }}</mat-icon>
                </button>

                <button
                  type="button"
                  matTooltip="{{ 'GENERIC.DOWNLOAD' | translate }}"
                  mat-icon-button
                  class=""
                  (click)="
                    menuReportsService.buildAndRunVo(
                      ReportsEnum.REPORT_ADMIN_ACCOUNTING_DEPOSITE_TRACKING_OKA,
                      DistributionModeEnum.FILE,
                      ExportFormatEnum.PDF,
                      ExportToEnum.DOWNLOAD,
                      LocaleEnum.FR_CA,
                      PaperEnum.LETTER,
                      dateRange3
                    )
                  "
                >
                  <mat-icon class="" color="">{{
                    iconsService.getIcon("PDF")
                  }}</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  -->
    <div
      *ngIf="
        moduleService.modulesAreSet &&
        moduleService.moduleExist('MODULE_STAFF_ASSIGNMENT')
      "
    >
      <!-- TEAM_LEAD_ROLES -->
      <!-- *ngxPermissionsOnly="rolesPermissions.GOD_ROLES" -->
      <div
        *ngxPermissionsOnly="rolesPermissions.RH_ROLES"
        fxFlex="100"
        fxLayout="row"
        fxLayoutAlign="start start"
        class="management-content"
      >
        <div fxFlex="50" class="mr-8">
          <div class="profile-box info-box general" fxLayout="column">
            <div class="">
              <header class="accent">
                <div class="title p-16">
                  {{ "REPORTS.EMPLOYEES" | translate }}
                </div>
              </header>
            </div>

            <div *ngIf="dateRange4" fxLayout="column" class="header-content">
              <div
                [formGroup]="form"
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                <mat-form-field class="custom-date-picker">
                  <mat-label>{{ "GENERIC.START" | translate }}</mat-label>
                  <input
                    matInput
                    formControlName="date_from4"
                    [matDatepicker]="eventDateFrom4"
                    min="1900-01-01"
                    max="2099-01-01"
                    (ngModelChange)="onDateFromChange4($event)"
                    [(ngModel)]="dateRange4.date_from"
                    class=""
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="eventDateFrom4"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #eventDateFrom4></mat-datepicker>

                  <mat-error *ngIf="form.controls['date_from4'].invalid"
                    >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
                  </mat-error>
                </mat-form-field>
                <div style="padding: 5px">&nbsp;</div>
                <mat-form-field class="custom-date-picker">
                  <mat-label>{{ "GENERIC.END" | translate }}</mat-label>
                  <input
                    matInput
                    formControlName="date_to4"
                    [matDatepicker]="eventDateTo4"
                    min="1900-01-01"
                    max="2099-01-01"
                    (ngModelChange)="onDateToChange4($event, 'patate')"
                    [(ngModel)]="dateRange4.date_to"
                    class=""
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="eventDateTo4"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #eventDateTo4></mat-datepicker>

                  <mat-error *ngIf="form.controls['date_to4'].invalid"
                    >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
                  </mat-error>
                </mat-form-field>

                <div>
                  <button
                    mat-icon-button
                    class=""
                    [matMenuTriggerFor]="menuFromEmployee"
                  >
                    <mat-icon class="">more</mat-icon>
                  </button>
                </div>
                <mat-menu #menuFromEmployee="matMenu">
                  <button mat-menu-item (click)="onSelectToday4()">
                    {{ "GENERIC.TODAY" | translate }}
                  </button>
                  <button mat-menu-item (click)="onSelectThisWeek4()">
                    {{ "GENERIC.WEEK" | translate }}
                  </button>
                  <button mat-menu-item (click)="onSelectThisMonth4()">
                    {{ "GENERIC.MONTH" | translate }}
                  </button>
                </mat-menu>
              </div>
            </div>

            <div class="content" fxLayout="column">
              <!-- Report Nethris Payment Data - JR  -->
              <div
                *ngIf="
                  moduleService.modulesAreSet && moduleService.hasJRReport()
                "
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                <div>{{ "REPORTS.PAYMENT_DATA_NETHRIS" | translate }}</div>
                <div>
                  <button
                    type="button"
                    *ngIf="true"
                    matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
                    mat-icon-button
                    (click)="onDownloadPaymentDataNethris()"
                    class=""
                  >
                    <mat-icon
                      fontSet="fa"
                      fontIcon="{{ iconsService.getIcon('XLS') }}"
                      class="fa-lg"
                    >
                    </mat-icon>
                  </button>
                </div>
              </div>

              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div fxLayout="row" fxLayoutAlign="start center">
                  <div>{{ "REPORTS.EMPLOYEES_ASSIGNMENT" | translate }}</div>

                  <button
                    type="button"
                    mat-icon-button
                    class=""
                    matTooltip="{{
                      'REPORTS.EMPLOYEE_ASSIGNMENTS_HINT2' | translate
                    }}"
                  >
                    <mat-icon content-type="template" color="" class="s-20">
                      help
                    </mat-icon>
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    [matMenuTriggerFor]="menuEmployeeAssignmentsPrint"
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                    mat-icon-button
                    class=""
                  >
                    <mat-icon class="" color="">{{
                      iconsService.getIcon("PRINT")
                    }}</mat-icon>
                  </button>

                  <mat-menu #menuEmployeeAssignmentsPrint="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        onPrintEmployeesAssigments(
                          'assignment/report-mngt-assignment-pay-staff',
                          'letter'
                        )
                      "
                    >
                      {{ "GENERIC.LETTER_FORMAT" | translate }}
                    </button>
                    <button
                      mat-menu-item
                      (click)="
                        onPrintEmployeesAssigments(
                          'assignment/report-mngt-assignment-pay-staff',
                          'legal'
                        )
                      "
                    >
                      {{ "GENERIC.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    type="button"
                    [matMenuTriggerFor]="menuEmployeeAssignmentsDownload"
                    *ngIf="fileService.pdfEnabled()"
                    matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                    mat-icon-button
                    class=""
                  >
                    <mat-icon class="" color="">{{
                      iconsService.getIcon("PDF")
                    }}</mat-icon>
                  </button>

                  <mat-menu #menuEmployeeAssignmentsDownload="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        onDownloadPDFEmployeesAssigments(
                          'assignment/report-mngt-assignment-pay-staff',
                          'letter'
                        )
                      "
                    >
                      {{ "GENERIC.LETTER_FORMAT" | translate }}
                    </button>
                    <button
                      mat-menu-item
                      (click)="
                        onDownloadPDFEmployeesAssigments(
                          'assignment/report-mngt-assignment-pay-staff',
                          'legal'
                        )
                      "
                    >
                      {{ "GENERIC.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                </div>
              </div>

              <!-- Assignments schedule per date stack -->
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <!-- <div>{{ "REPORTS.SCHEDULE_PER_DATE" | translate }}</div> -->
                <div fxLayout="row" fxLayoutAlign="start center">
                  <div>{{ "REPORTS.SCHEDULE_PER_DATE" | translate }}</div>

                  <button
                    type="button"
                    mat-icon-button
                    class=""
                    matTooltip="{{
                      'REPORTS.EMPLOYEE_ASSIGNMENTS_HINT' | translate
                    }}"
                  >
                    <mat-icon content-type="template" color="" class="s-20">
                      help
                    </mat-icon>
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    [matMenuTriggerFor]="menuEmployeeAssignmentsPerDatePrint"
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                    mat-icon-button
                    class=""
                  >
                    <mat-icon class="" color="">{{
                      iconsService.getIcon("PRINT")
                    }}</mat-icon>
                  </button>

                  <mat-menu #menuEmployeeAssignmentsPerDatePrint="matMenu">
                    <!-- <button mat-menu-item
                                            (click)="onPrintEmployeesAssigments('assignment/report-mngt-assignment-pay-staff', 'letter')">
                                            {{ "GENERIC.LETTER_FORMAT" | translate }}
                                        </button>
                                        <button mat-menu-item
                                            (click)="onPrintEmployeesAssigments('assignment/report-mngt-assignment-pay-staff', 'legal')">
                                            {{ "GENERIC.LEGAL_FORMAT" | translate }}
                                        </button> -->
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_ASSIGNMENT_SCHEDULE_PER_DATE_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRange4
                        )
                      "
                    >
                      {{ "GENERIC.LETTER_FORMAT" | translate }}
                    </button>
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_ASSIGNMENT_SCHEDULE_PER_DATE_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRange4
                        )
                      "
                    >
                      {{ "GENERIC.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    type="button"
                    [matMenuTriggerFor]="menuEmployeeAssignmentsPerDateDownload"
                    *ngIf="fileService.pdfEnabled()"
                    matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                    mat-icon-button
                    class=""
                  >
                    <mat-icon class="" color="">{{
                      iconsService.getIcon("PDF")
                    }}</mat-icon>
                  </button>

                  <mat-menu #menuEmployeeAssignmentsPerDateDownload="matMenu">
                    <!-- <button mat-menu-item
                                            (click)="onDownloadPDFEmployeesAssigments('assignment/report-mngt-assignment-pay-staff', 'letter')">
                                            {{ "GENERIC.LETTER_FORMAT" | translate }}
                                        </button>
                                        <button mat-menu-item
                                            (click)="onDownloadPDFEmployeesAssigments('assignment/report-mngt-assignment-pay-staff', 'legal')">
                                            {{ "GENERIC.LEGAL_FORMAT" | translate }}
                                        </button> -->
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_ASSIGNMENT_SCHEDULE_PER_DATE_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRange4
                        )
                      "
                    >
                      {{ "GENERIC.LETTER_FORMAT" | translate }}
                    </button>
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_ASSIGNMENT_SCHEDULE_PER_DATE_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRange4
                        )
                      "
                    >
                      {{ "GENERIC.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div fxFlex="50" class="ml-8">&nbsp;</div>
      </div>
    </div>
  </div>
</div>
