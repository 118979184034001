<div
  *ngIf="!data"
  fxFlex="100%"
  fxLayout="row"
  fxLayoutAlign="center center"
  class="p-8"
>
  <mat-spinner [diameter]="22" [color]="'primary'"></mat-spinner>
</div>
<div *ngIf="data && isInit" class="gt2-select" fxFlex="100%">
  <div
    *ngIf="data.length === 0 && noResultsLabel"
    class="mb-8 red-fg mat-caption"
  >
    {{ noResultsLabel }}
  </div>

  <mat-form-field
    *ngIf="data.length > 0 && isRequired && !isSimpleValue"
    class=""
    fxFlex="100%"
    [floatLabel]="useFloatValue"
  >
    <mat-label>{{ placeHolder }}</mat-label>
    <mat-select
      (selectionChange)="onSelectionChange($event)"
      [(ngModel)]="selected"
      [disabled]="disabled || data.length === 0"
      required
    >
      <mat-option *ngIf="nullableUseNone" [value]="null">{{
        "GENERIC.NONE" | translate
      }}</mat-option>
      <mat-option *ngFor="let item of data" [value]="item">{{
        item[labelKey]
      }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field
    *ngIf="data.length > 0 && !isRequired && !isSimpleValue"
    class=""
    fxFlex="100%"
    [floatLabel]="useFloatValue"
  >
    <mat-label>{{ placeHolder }}</mat-label>
    <mat-select
      (selectionChange)="onSelectionChange($event)"
      [(ngModel)]="selected"
      [disabled]="disabled || data.length === 0"
    >
      <mat-option *ngIf="nullableUseNone" [value]="null">{{
        "GENERIC.NONE" | translate
      }}</mat-option>
      <mat-option *ngFor="let item of data" [value]="item">{{
        item[labelKey]
      }}</mat-option>
    </mat-select>
  </mat-form-field>

  <!-- <mat-form-field *ngIf="data.length > 0 && isSimpleValue" class="" fxFlex="100%" floatLabel='{{ useFloatValue }}'>
    
        <mat-select placeholder="{{ placeHolder }}" (selectionChange)="onSelectionChange($event)" [(ngModel)]="selected"
            [disabled]="disabled || data.length === 0" >
            <mat-option *ngIf="nullableUseNone" [value]="null">{{ 'GENERIC.NONE' | translate }}</mat-option>
            <mat-option *ngFor="let item of data" [value]="item[labelKey]">{{item[labelKey]}}</mat-option>
        </mat-select>
    </mat-form-field> -->
</div>
