<div
  id="employees"
  class="page-layout simple left-sidebar inner-sidebar inner-scroll"
  style="overflow-x: scroll"
>
  <!-- HEADER -->
  <div class="gt2-theme">
    <div
      class="header accent pl-24 pr-24 h-80 mat-elevation-z1"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fusePerfectScrollbar
      style="min-width: 1023px"
    >
      <div fxLayout="column" fxLayoutAlign="center start">
        <div class="h1 mt-8">{{ "GENERIC.EMPLOYEES" | translate }}</div>
      </div>
      <div fxLayout="row" fxLayoutAlign="end start">
        <!-- <a href="/images/myw3schoolsimage.jpg" download><mat-icon>file_download</mat-icon></a> -->
        <div *ngxPermissionsOnly="rolesService.RH_ROLES">
          <button
            mat-button
            *ngIf="fileService.printEnabled()"
            matTooltip="{{ 'GENERIC.PRINT' | translate }}"
            (click)="onPrint()"
          >
            <mat-icon class="icon-mat s-30">print</mat-icon>
          </button>
        </div>
        <div *ngxPermissionsOnly="rolesService.RH_ROLES">
          <button
            mat-button
            *ngIf="fileService.pdfEnabled()"
            matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
            (click)="onDownloadPDF()"
          >
            <mat-icon class="icon-mat s-30">picture_as_pdf</mat-icon>
          </button>
        </div>
        <mat-spinner
          *ngIf="print.loadingPrint"
          [diameter]="20"
          class="mt-12"
        ></mat-spinner>

        <div *ngxPermissionsOnly="rolesService.RH_ROLES">
          <button
            mat-button
            *ngIf="fileService.excelEnabled()"
            matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
            (click)="onDownloadXLS()"
          >
            <!--<mat-icon class="s-30">collections</mat-icon>-->
            <mat-icon
              fontSet="fa"
              fontIcon="fa-table"
              class="icon-mat fa-2x"
            ></mat-icon>
          </button>
        </div>

        <button
          mat-button
          matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
          (click)="onOpenInNewTab()"
        >
          <mat-icon class="icon-mat s-30">{{ getIcon("NEW_TAB") }}</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <!-- / HEADER -->

  <div
    #mainContainer
    class="center p-24 pr-56"
    style="min-width: 1023px"
    fusePerfectScrollbar
  >
    <div fxLayout="column">
      <!--Data table-->
      <div class="mat-white-bg mat-elevation-z2">
        <div class="pl-8 pt-8 pr-8" fxLayout="row" fxLayoutAlign="end center">
          <!-- SEARCH -->
          <div
            class=""
            fxFlex="1 0 auto"
            fxLayout="row"
            fxLayoutAlign="start center"
          >
            <label for="search" class="mr-8">
              <mat-icon class="">search</mat-icon>
            </label>
            <mat-form-field floatLabel="auto" fxFlex="1 0 auto" class="">
              <mat-label>{{
                "GENERIC.SEARCH" | translate | titlecase
              }}</mat-label>
              <input id="search" [(ngModel)]="filterValue" matInput #filter />
              <button
                mat-button
                *ngIf="filterValue"
                matSuffix
                aria-label="Clear"
                (click)="clearSearch()"
              >
                <mat-icon class="">close</mat-icon>
              </button>
            </mat-form-field>
          </div>

          <!-- / SEARCH -->

          <div class="mat-body-2 p-8 ml-8">
            {{ resultsLength }}
            {{ "GENERIC.EMPLOYEES" | translate }}
          </div>
        </div>
        <div class="border-bottom">
          <div
            *ngIf="!isLoadingResults && resultsLength === 0"
            fxLayout="row"
            fxLayoutAlign="center start"
            class="pb-8 mat-body-2"
          >
            {{ "GENERIC.NO_RESULTS" | translate }}
          </div>

          <div *ngIf="isLoadingResults" class="">
            <mat-progress-bar
              mode="query"
              color="warn"
              style="height: 3px"
            ></mat-progress-bar>
          </div>
        </div>

        <div>
          <div class="border-bottom">
            <mat-paginator
              #paginator
              [length]="resultsLength"
              [pageIndex]="0"
              showFirstLastButtons="true"
              (page)="datatableHelperService.onPaginator($event, 'employees')"
              [pageSize]="'employees' | tableSizePipe: 'large'"
              [pageSizeOptions]="datatableHelperService.pageSizeOptions"
            >
            </mat-paginator>
          </div>
          <mat-table
            #table
            class=""
            matSort
            matSortStart="desc"
            [@animateStagger]="{ value: '50' }"
            [dataSource]="dataSource"
          >
            <!-- Avatar Column -->
            <ng-container cdkColumnDef="avatar">
              <mat-header-cell fxFlex="10" *cdkHeaderCellDef></mat-header-cell>
              <mat-cell
                class="no-text-cursor"
                fxFlex="10"
                *cdkCellDef="let employee"
              >
                <!-- <img class="avatar" *ngIf="employee.avatar_thumb" [alt]="employee.name"
                                            default="assets/images/avatar/default-no-avatar.png" 
                                             [src]="employee.avatar_thumb + '/test'" /> -->

                <img
                  class="avatar"
                  *ngIf="employee.avatar_thumb"
                  [alt]="employee.name"
                  onerror="if (this.src !== '/assets/images/avatars/default-no-avatar.png') this.src='/assets/images/avatars/default-no-avatar.png';"
                  [src]="employee.avatar_thumb"
                />
              </mat-cell>
            </ng-container>

            <!-- Name Column -->
            <ng-container cdkColumnDef="first_name">
              <mat-header-cell fxFlex="25" *cdkHeaderCellDef mat-sort-header
                >{{ "GENERIC.NAME" | translate }}
              </mat-header-cell>
              <mat-cell
                class="no-text-cursor"
                fxFlex="25"
                *cdkCellDef="let employee"
              >
                <p class="text-truncate center font-weight-600">
                  {{ employee.first_name }} {{ employee.last_name }}
                </p>
              </mat-cell>
            </ng-container>

            <!-- Employee # Column -->
            <ng-container cdkColumnDef="employee_number">
              <mat-header-cell fxFlex="15" *cdkHeaderCellDef mat-sort-header
                >{{ "EMPLOYEES.EMPLOYEE_NUMBER" | translate }}
              </mat-header-cell>
              <mat-cell
                class="no-text-cursor"
                fxFlex="15"
                *cdkCellDef="let employee"
              >
                <p class="position text-truncate">
                  {{ employee.employee_number }}
                </p>
              </mat-cell>
            </ng-container>

            <!-- Email Column -->
            <ng-container cdkColumnDef="email">
              <mat-header-cell fxFlex="20" *cdkHeaderCellDef
                >{{ "GENERIC.EMAIL" | translate }}
              </mat-header-cell>
              <mat-cell
                class="no-text-cursor"
                fxFlex="20"
                *cdkCellDef="let employee"
              >
                <p
                  *ngIf="
                    employee.contact && employee.contact.email_organisation
                  "
                  class="email text-truncate"
                >
                  {{ employee.contact.email_organisation }}
                </p>

                <p
                  *ngIf="
                    employee.contact && !employee.contact.email_organisation
                  "
                  class="email text-truncate"
                >
                  {{ employee.contact.email_personnal }}
                </p>
              </mat-cell>
            </ng-container>

            <!-- Active Column -->
            <ng-container cdkColumnDef="active">
              <mat-header-cell fxFlex="10" *cdkHeaderCellDef mat-sort-header
                >{{ "GENERIC.ACTIVE" | translate }}
              </mat-header-cell>
              <mat-cell
                class="no-text-cursor"
                fxFlex="10"
                *cdkCellDef="let employee"
              >
                <p *ngIf="employee.active === 1" class="text-truncate">
                  {{ "GENERIC.YES" | translate }}
                </p>
                <p *ngIf="!employee.active" class="text-truncate">
                  {{ "GENERIC.NO" | translate }}
                </p>
              </mat-cell>
            </ng-container>

            <!-- Phone Column -->
            <ng-container cdkColumnDef="phone">
              <mat-header-cell fxFlex="20" fxFlex="180px" *cdkHeaderCellDef
                >{{ "GENERIC.PHONE" | translate }}
              </mat-header-cell>
              <mat-cell
                class="no-text-cursor"
                fxFlex="20"
                *cdkCellDef="let employee"
              >
                <p *ngIf="employee.contact" class="phone text-truncate">
                  {{ employee.contact.telephone_home }}
                </p>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *cdkHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <mat-row
              *cdkRowDef="let employee; columns: displayedColumns"
              class="mat-row-color"
              matRipple
              [routerLink]="
                '/' +
                appRoutesService.employees +
                '/' +
                employee.slug +
                '/' +
                employee.uuid
              "
            >
            </mat-row>
          </mat-table>
          <mat-paginator
            #paginatorBottom
            [length]="paginator.length"
            (page)="syncPrimaryPaginator($event)"
            showFirstLastButtons="true"
            [pageIndex]="paginator.pageIndex"
            [pageSize]="paginator.pageSize"
            [pageSizeOptions]="paginator.pageSizeOptions"
          >
          </mat-paginator>
          <div
            *ngIf="isLoadingResults && !isFirstLoad"
            fxLayout="row"
            fxLayoutAlign="center start"
            class=""
          >
            <mat-progress-bar
              mode="query"
              color="warn"
              style="height: 3px"
            ></mat-progress-bar>
          </div>
        </div>
      </div>
    </div>

    <div class="spacer" style="height: 46px"></div>
  </div>
</div>
<div class="fixed-button">
  <button
    mat-fab
    *ngxPermissionsOnly="rolesService.RH_ROLES"
    class="gt2-create-button"
    id="add-employee-button"
    (click)="onCreateNewEmployee($event)"
    [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }"
  >
    <mat-icon class="icon-mat-black">add</mat-icon>
  </button>
</div>
