<div *ngIf="collectionData && dataSource">
  <mat-table
    #table
    class=""
    [@animateStagger]="{ value: '10' }"
    [dataSource]="dataSource"
  >
    <ng-container
      *ngFor="let col of displayedColumns; let i = index"
      cdkColumnDef="{{ displayedColumns[i] }}"
    >
      <ng-container [ngSwitch]="displayedColumns[i]">
        <ng-container *ngSwitchCase="'action'">
          <mat-header-cell
            *cdkHeaderCellDef
            mat-header
            fxFlex="98px"
            class="item-cell-header"
          >
            <button mat-button mat-icon-button (click)="onAddRow()">
              <mat-icon class="s-20">
                {{ getIcon("ADD") }}
              </mat-icon>
            </button>
          </mat-header-cell>

          <mat-cell
            *cdkCellDef="let row"
            class="item-cell"
            fxFlex="98px"
            fxLayout="row"
            fxLayoutAlign="start center"
          >
            <button mat-button mat-icon-button (click)="onSaveRow(row)">
              <mat-icon class="s-16">
                {{ getIcon("SAVE") }}
              </mat-icon>
            </button>

            <button mat-button mat-icon-button (click)="onDeleteRow(row)">
              <mat-icon class="s-16">
                {{ getIcon("DELETE") }}
              </mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <ng-container *ngSwitchCase="'start_at'">
          <mat-header-cell *cdkHeaderCellDef mat-header>
            {{ "SHOPS.RTE_SCHEDULE_TABLE." + displayedColumns[i] | translate }}
          </mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            fxLayout="column"
            fxLayoutAlign="start start"
          >
            <div fxLayout="row" fxLayoutAlign="start center">
              <div class="date-label">{{ "GENERIC.DATE" | translate }}:</div>
              <mat-form-field class="date-form">
                <input
                  matInput
                  autocomplete="nope"
                  [matDatepicker]="eventDate"
                  min="1900-01-01"
                  max="2099-01-01"
                  [(ngModel)]="row[displayedColumns[i]]"
                  (ngModelChange)="onDateChange(row, displayedColumns[i])"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="eventDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #eventDate></mat-datepicker>
              </mat-form-field>
            </div>
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              class="time-picker"
            >
              <div class="time-label">{{ "GENERIC.TIME" | translate }}:</div>
              <ngx-timepicker-field
                [buttonAlign]="'left'"
                [format]="24"
                [(ngModel)]="row.start_time"
                class="no-border ngx-material-timepicker-toggle"
              >
              </ngx-timepicker-field>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container *ngSwitchCase="'end_at'">
          <mat-header-cell *cdkHeaderCellDef mat-header>
            {{ "SHOPS.RTE_SCHEDULE_TABLE." + displayedColumns[i] | translate }}
          </mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            fxLayout="column"
            fxLayoutAlign="start start"
          >
            <div fxLayout="row" fxLayoutAlign="start center">
              <div class="date-label">{{ "GENERIC.DATE" | translate }}:</div>
              <mat-form-field class="date-form">
                <input
                  matInput
                  autocomplete="nope"
                  [matDatepicker]="eventDate"
                  min="1900-01-01"
                  max="2099-01-01"
                  [(ngModel)]="row[displayedColumns[i]]"
                  (ngModelChange)="onDateChange(row, displayedColumns[i])"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="eventDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #eventDate></mat-datepicker>
              </mat-form-field>
            </div>
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              class="time-picker"
            >
              <div class="time-label">{{ "GENERIC.TIME" | translate }}:</div>
              <ngx-timepicker-field
                [buttonAlign]="'left'"
                [format]="24"
                [(ngModel)]="row.end_time"
                class="no-border ngx-material-timepicker-toggle"
              >
              </ngx-timepicker-field>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container *ngSwitchCase="'takeout_start_at'">
          <mat-header-cell *cdkHeaderCellDef mat-header>
            {{ "SHOPS.RTE_SCHEDULE_TABLE." + displayedColumns[i] | translate }}
          </mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            fxLayout="column"
            fxLayoutAlign="start start"
          >
            <div fxLayout="row" fxLayoutAlign="start center">
              <div class="date-label">{{ "GENERIC.DATE" | translate }}:</div>
              <mat-form-field class="date-form">
                <input
                  matInput
                  autocomplete="nope"
                  [matDatepicker]="eventDate"
                  min="1900-01-01"
                  max="2099-01-01"
                  [(ngModel)]="row[displayedColumns[i]]"
                  (ngModelChange)="onDateChange(row, displayedColumns[i])"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="eventDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #eventDate></mat-datepicker>
              </mat-form-field>
            </div>
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              class="time-picker"
            >
              <div class="time-label">{{ "GENERIC.TIME" | translate }}:</div>
              <ngx-timepicker-field
                [buttonAlign]="'left'"
                [format]="24"
                [(ngModel)]="row.takeout_start_time"
                class="no-border ngx-material-timepicker-toggle"
              >
              </ngx-timepicker-field>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container *ngSwitchCase="'takeout_end_at'">
          <mat-header-cell *cdkHeaderCellDef mat-header>
            {{ "SHOPS.RTE_SCHEDULE_TABLE." + displayedColumns[i] | translate }}
          </mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            fxLayout="column"
            fxLayoutAlign="start start"
          >
            <div fxLayout="row" fxLayoutAlign="start center">
              <div class="date-label">{{ "GENERIC.DATE" | translate }}:</div>
              <mat-form-field class="date-form">
                <input
                  matInput
                  autocomplete="nope"
                  [matDatepicker]="eventDate"
                  min="1900-01-01"
                  max="2099-01-01"
                  [(ngModel)]="row[displayedColumns[i]]"
                  (ngModelChange)="onDateChange(row, displayedColumns[i])"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="eventDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #eventDate></mat-datepicker>
              </mat-form-field>
            </div>
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              class="time-picker"
            >
              <div class="time-label">{{ "GENERIC.TIME" | translate }}:</div>
              <ngx-timepicker-field
                [buttonAlign]="'left'"
                [format]="24"
                [(ngModel)]="row.takeout_end_time"
                class="no-border ngx-material-timepicker-toggle"
              >
              </ngx-timepicker-field>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container *ngSwitchCase="'delivery_start_at'">
          <mat-header-cell *cdkHeaderCellDef mat-header>
            {{ "SHOPS.RTE_SCHEDULE_TABLE." + displayedColumns[i] | translate }}
          </mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            fxLayout="column"
            fxLayoutAlign="start start"
          >
            <div fxLayout="row" fxLayoutAlign="start center">
              <div class="date-label">{{ "GENERIC.DATE" | translate }}:</div>
              <mat-form-field class="date-form">
                <input
                  matInput
                  autocomplete="nope"
                  [matDatepicker]="eventDate"
                  min="1900-01-01"
                  max="2099-01-01"
                  [(ngModel)]="row[displayedColumns[i]]"
                  (ngModelChange)="onDateChange(row, displayedColumns[i])"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="eventDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #eventDate></mat-datepicker>
              </mat-form-field>
            </div>
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              class="time-picker"
            >
              <div class="time-label">{{ "GENERIC.TIME" | translate }}:</div>
              <ngx-timepicker-field
                [buttonAlign]="'left'"
                [format]="24"
                [(ngModel)]="row.delivery_start_time"
                class="no-border ngx-material-timepicker-toggle"
              >
              </ngx-timepicker-field>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container *ngSwitchCase="'delivery_end_at'">
          <mat-header-cell *cdkHeaderCellDef mat-header>
            {{ "SHOPS.RTE_SCHEDULE_TABLE." + displayedColumns[i] | translate }}
          </mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            fxLayout="column"
            fxLayoutAlign="start start"
          >
            <div fxLayout="row" fxLayoutAlign="start center">
              <div class="date-label">{{ "GENERIC.DATE" | translate }}:</div>
              <mat-form-field class="date-form">
                <input
                  matInput
                  autocomplete="nope"
                  [matDatepicker]="eventDate"
                  min="1900-01-01"
                  max="2099-01-01"
                  [(ngModel)]="row[displayedColumns[i]]"
                  (ngModelChange)="onDateChange(row, displayedColumns[i])"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="eventDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #eventDate></mat-datepicker>
              </mat-form-field>
            </div>
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              class="time-picker"
            >
              <div class="time-label">{{ "GENERIC.TIME" | translate }}:</div>
              <ngx-timepicker-field
                [buttonAlign]="'left'"
                [format]="24"
                [(ngModel)]="row.delivery_end_time"
                class="no-border ngx-material-timepicker-toggle"
              >
              </ngx-timepicker-field>
            </div>
          </mat-cell>
        </ng-container>

        <!-- <ng-container *ngSwitchDefault>
                    <mat-header-cell *cdkHeaderCellDef mat-header>
                        {{ (('SHOPS.RTE_SCHEDULE_TABLE.' + displayedColumns[i]) | translate) }}
                    </mat-header-cell>
                    <mat-cell  *cdkCellDef="let row">
                        <div></div>
                    </mat-cell>
                </ng-container> -->
      </ng-container>
    </ng-container>

    <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
      *cdkRowDef="let row; columns: displayedColumns"
      (click)="onSelect(row)"
      [@animate]="{ value: '*', params: { y: '100%' } }"
    >
    </mat-row>
  </mat-table>
</div>
