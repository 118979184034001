<div matDialogTitle class="dialog-title text-bold" *ngIf="client">
  {{ "ATTACH_ENTERPRISE.TITLE" | translate }}
  {{ client.first_name + " " + client.last_name }}
</div>
<div class="px-16 pb-16" fxLayout="column">
  <!--<div class="" *ngIf="client">{{ 'ATTACH_ENTERPRISE.TITLE' | translate }}{{client.name}}</div>-->

  <mat-form-field floatLabel="auto" fxFlex="1 0 auto" class="">
    <mat-label>{{ "GENERIC.SEARCH" | translate | titlecase }}</mat-label>
    <input id="search" [(ngModel)]="filterValue" matInput #filter />
    <button
      mat-button
      *ngIf="filterValue"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="clearSearch()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <div
    #comboList
    class="container-border"
    style="height: 400px"
    fxLayout="column"
    fusePerfectScrollbar
  >
    <div
      *ngIf="isLoading"
      fxLayout="column"
      fxFlex="100%"
      fxLayoutAlign="center center"
    >
      <mat-spinner [diameter]="20" class=""></mat-spinner>
    </div>

    <div *ngIf="!isLoading">
      <div *ngFor="let item of enterprises" class="">
        <div
          class="client-child-item"
          *ngIf="
            filterValue === '' ||
            item.name.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1
          "
          (click)="onSelectEnterprise(item)"
          fxLayout="row"
          fxLayoutAlign="space-between center"
        >
          <div>{{ item.name }}</div>
          <!-- <mat-checkbox [checked]="selectedEnterprise === item" class=""> </mat-checkbox> -->
          <mat-radio-button
            [checked]="selectedEnterprise === item"
          ></mat-radio-button>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-8 selected-label text-bold" *ngIf="selectedEnterprise">
    {{ "ATTACH_ENTERPRISE.SELECTED_ENTERPRISE" | translate }}
    {{ selectedEnterprise.name }}
  </div>
</div>

<div
  mat-dialog-actions
  class="mt-16 gt2-buttons-theme"
  fxLayout="row"
  fxLayoutAlign="center start"
>
  <button
    mat-raised-button
    [disabled]="!selectedEnterprise"
    (click)="onAttachEnterprise()"
    class="accent mr-16"
  >
    {{ "ATTACH_ENTERPRISE.ATTACH" | translate }}
  </button>
  <button mat-raised-button (click)="dialogRef.close(false)" class="primary">
    {{ "GENERIC.CANCEL" | translate }}
  </button>
</div>
