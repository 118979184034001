import { CalendarEventAction } from 'angular-calendar';
import moment from 'moment';

export class CalendarEventModel {
  context: any;

  start: Date;
  end?: Date;
  title: string;
  color: {
    primary: string;
    secondary: string;
  };
  actions?: CalendarEventAction[];
  allDay?: boolean;
  cssClass?: string;
  resizable?: {
    beforeStart?: boolean;
    afterEnd?: boolean;
  };
  draggable?: boolean;
  meta?: {
    type: string;
    notes: string;
  };

  constructor(data: any) {
    data = data || {};
    this.start =
      new Date(data.start) || moment(new Date()).startOf('day').toDate();
    this.end = new Date(data.end) || moment(new Date()).endOf('day').toDate();
    this.title = data.title || '';
    this.color = {
      primary: (data.color && data.color.primary) || '#1e90ff',
      secondary: (data.color && data.color.secondary) || '#D1E8FF',
    };
    this.draggable = data.draggable || true;
    this.resizable = {
      beforeStart: (data.resizable && data.resizable.beforeStart) || true,
      afterEnd: (data.resizable && data.resizable.afterEnd) || true,
    };
    this.actions = data.actions || [];
    this.allDay = data.allDay || false;
    this.cssClass = data.cssClass || '';
    this.meta = {
      type: (data.meta && data.meta.type) || '',
      notes: (data.meta && data.meta.notes) || '',
    };

    this.context = data.context || null;
  }
}
