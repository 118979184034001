import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Gt2ApiService } from './gt2-api.service';
import { ApiRoutes } from './ApiRoutes';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IGetDataService } from '../components/gt2-select/gt2-select.component';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class EventsService implements IGetDataService {
  ubrList: string[] = [];
  uberData: any;
  constructor(
    private http: HttpClient,
    private router: Router,
    private logger: NGXLogger,
    private api: Gt2ApiService,
  ) {}

  public getEventsList(): any {
    // //this.logger.info("EventsService.getEventsList()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.EVENTS_LIST));
  }

  public getData(): Observable<any> {
    // //this.logger.info("EventsService.getData()");
    return this.getEventsList();
  }

  public getEvent(eventUUID: string): any {
    // //this.logger.info("OrdersService.getEvent() -> eventUUID: " + eventUUID );
    return this.http.get<any>(this.api.createUrl(ApiRoutes.EVENT) + eventUUID);
  }

  public updateEventInformation(eventUUID: string, data: any): any {
    // //this.logger.info("OrdersService.updateEventInformation() -> eventUUID: " + eventUUID );
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.EVENT) + 'informations/' + eventUUID,
      data,
    );
  }

  public updateEventReceiptNotice(eventUUID: string, data: any): any {
    ////this.logger.info("EventsService.updateEventReceiptNotice() -> eventUUID: " + eventUUID);
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.EVENT) + 'receipt-notice/' + eventUUID,
      data,
    );
  }

  public updateEventDeliveryType(eventUUID: string, data: any): any {
    // //this.logger.info("OrdersService.updateEventDeliveryType() -> eventUUID: " + eventUUID );
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.EVENT) + 'spot/delivery-type/' + eventUUID,
      data,
    );
  }

  public updateEventVenues(eventUUID: string, data: any): any {
    // //this.logger.info("OrdersService.updateEventVenues() -> eventUUID: " + eventUUID );
    // //this.logger.info("OrdersService.updateEventVenues() -> data: " + JSON.stringify(data) );
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.EVENT) + 'spot/' + eventUUID,
      data,
    );
  }

  public addClient(clientUUID: any, eventUUID: string, section: string): any {
    // //this.logger.info("OrdersService.addClient() -> eventUUID: " + eventUUID );
    // //this.logger.info("OrdersService.addClient() -> client: " + JSON.stringify(client) );
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.EVENTS_ADD_CLIENT) +
        section +
        '/' +
        eventUUID +
        '/' +
        clientUUID,
    );
  }

  // public createEvent(event: any)
  // {
  //     return this.http.post<any>(this.api.createUrl(ApiRoutes.EVENTS_ORDER), event);
  // }

  public createOrder(event: any): any {
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.EVENTS_ORDER),
      event,
    );
  }

  public createOrderByClient(event: any, clientUUID: string): any {
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.EVENTS_ORDER_BY_CLIENT) + clientUUID,
      event,
    );
  }

  public createProposal(event: any): any {
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.EVENTS_PROPOSAL),
      event,
    );
  }

  public createProposalByClient(event: any, clientUUID: string): any {
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.EVENTS_PROPOSAL_BY_CLIENT) + clientUUID,
      event,
    );
  }

  public cloneProposal(proposal: any): any {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.CLONE_PROPOSAL) + proposal.uuid,
    );
  }

  public cloneProposalIntoEvent(proposal: any, event: any): any {
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.CLONE_PROPOSAL_INTO_EVENT) + proposal.uuid,
      event,
    );
  }

  public cloneOrderToProposalIntoEvent(proposal: any, event: any): any {
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.CLONE_ORDER_TO_PROPOSAL_INTO_EVENT) +
        proposal.uuid,
      event,
    );
  }

  public cloneProposalIntoEventWithDetails(proposal: any, event: any): any {
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.CLONE_PROPOSAL_INTO_EVENT_WITH_DETAILS) +
        proposal.uuid,
      event,
    );
  }

  public cloneOrderToProposalIntoEventWithDetails(
    proposal: any,
    event: any,
  ): any {
    return this.http.post<any>(
      this.api.createUrl(
        ApiRoutes.CLONE_ORDER_TO_PROPOSAL_INTO_EVENT_WITH_DETAILS,
      ) + proposal.uuid,
      event,
    );
  }

  public cloneOrderIntoEvent(proposal: any, event: any): any {
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.CLONE_ORDER_INTO_EVENT) + proposal.uuid,
      event,
    );
  }

  public cloneOrderIntoEventWithDetails(proposal: any, event: any): any {
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.CLONE_ORDER_INTO_EVENT_WITH_DETAILS) +
        proposal.uuid,
      event,
    );
  }

  public cancelEvent(eventUUID: string): any {
    // //this.logger.info("EventsService.cancelEvent() -> eventUUID: " + eventUUID );
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.EVENT) + 'status/event-cancel/' + eventUUID,
    );
  }

  public abortEvent(eventUUID: string): any {
    // //this.logger.info("EventsService.abortEvent() -> eventUUID: " + eventUUID );
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.EVENT) + 'status/event-abort/' + eventUUID,
    );
  }

  public reopenEvent(eventUUID: string): any {
    // //this.logger.info("EventsService.abortEvent() -> eventUUID: " + eventUUID );
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.EVENT) +
        'status/event-in-invoice/' +
        eventUUID,
    );
  }
  public reCloseEvent(eventUUID: string): any {
    // //this.logger.info("EventsService.abortEvent() -> eventUUID: " + eventUUID );
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.EVENT) +
        'status/event-complete/' +
        eventUUID,
    );
  }

  public resetSpot(eventUUID: string): any {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.RESET_SPOT) + eventUUID,
    );
  }

  public addSporadicAddress(eventUUID: string): any {
    const url: string = ApiRoutes.ADD_SPORADIC_ADDRESS.replace(
      '${UUID}',
      eventUUID,
    );
    return this.http.post<any>(this.api.createUrl(url), {});
  }

  public deleteSporadicAddress(eventUUID: string): any {
    const url: string = ApiRoutes.ADD_SPORADIC_ADDRESS.replace(
      '${UUID}',
      eventUUID,
    );
    return this.http.delete<any>(this.api.createUrl(url));
  }

  public extraByDate(
    dateFrom: string,
    dateTo: string,
    includeCancelAbort: boolean,
  ): any {
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.EVENTS_EXTRA_BY_DATE_RANGE),
      {
        date_from: dateFrom,
        date_to: dateTo,
        include_cancel_abort: includeCancelAbort,
      },
    );
  }

  public detailsByDateRange(
    sort: string,
    direction: string,
    page: number,
    pageSize: number,
    dateFrom: string,
    dateTo: string,
    includeCancelAbort: boolean,
    filterValues: any,
  ): Promise<any> {
    // //this.logger.info("EventsService.detailsByDateRange()");
    const routeURL: string = this.api.createUrl(
      ApiRoutes.EVENTS_DETAIL_BY_DATE_RANGE,
    );
    let requestUrl: string;
    if (!sort || direction === '') {
      requestUrl = `${routeURL}?page=${page + 1}&limit=${pageSize}`;
    } else {
      requestUrl = `${routeURL}?orderBy=${sort}&sortedBy=${direction}&page=${
        page + 1
      }&limit=${pageSize}`;
    }

    // Filter Values
    let filterStr: any;
    Object.entries(filterValues).forEach(([key, value]) => {
      if (!filterStr || filterStr == undefined) {
        filterStr = '&search=';
      }
      const filterValue: string = value['value'];
      if (
        filterValue.trim().length >= 2 &&
        !(filterValue.trim() == undefined)
      ) {
        filterStr += key + ':' + filterValue + ';';
      }
    });

    if (
      filterStr !== '' &&
      filterStr !== '&search=' &&
      !(filterStr == undefined)
    ) {
      // multi filters AND search functionnality
      // if ((filterStr.match(/;/g) || []).length > 1)
      // {
      //     filterStr += "&searchJoin=and";
      // }
      requestUrl += '&search=' + filterStr;
    }

    // //this.logger.info("EventsService.detailsByDateRange() -> requestUrl: " + requestUrl);
    // //this.logger.info("EventsService.detailsByDateRange() -> dateFrom: " + dateFrom);
    // //this.logger.info("EventsService.detailsByDateRange() -> dateTo: " + dateTo);
    return new Promise((resolve, reject) => {
      this.http
        .post(requestUrl, {
          date_from: dateFrom,
          date_to: dateTo,
          include_cancel_abort: includeCancelAbort,
        })
        .subscribe({
          next: (response: any) => {
            // //this.logger.info("EventsService.detailsByDateRange() -> SUCCESS! meta: " + JSON.stringify(response.data.meta));
            //this.logger.info("EventsService.detailsByDateRange() -> this.response: " + JSON.stringify(response));
            // //this.logger.info("EventsService.detailsByDateRange() -> SUCCESS!");
            resolve(response);
          },
          error: reject,
        });
    });
  }

  public allByDateRange(
    dateFrom: string,
    dateTo: string,
    includeCancelAbort: boolean,
  ): Promise<any> {
    const routeURL: string = this.api.createUrl(
      ApiRoutes.EVENTS_DETAIL_BY_DATE_RANGE_ALL,
    );
    // //this.logger.info("EventsService.allByDateRange() -> routeURL: " + routeURL);
    return new Promise((resolve, reject) => {
      this.http
        .post(routeURL, {
          date_from: dateFrom,
          date_to: dateTo,
          include_cancel_abort: includeCancelAbort,
        })
        .subscribe({
          next: (response: any) => {
            // //this.logger.info("EventsService.allByDateRange() -> this.response: " + JSON.stringify(response));
            resolve(response);
          },
          error: reject,
        });
    });
  }
  public moveToProjectAction(projectUUID: string, uuids: string[]): any {
    const postData: any = {
      projectUUID: projectUUID,
      uuids: uuids,
    };
    const requestURL: string =
      this.api.createUrl(ApiRoutes.MOVE_EVENTS_INTO_PROJECT) + projectUUID;
    return this.http.post<any>(requestURL, postData);
  }
  public copyToProjectAction(
    projectUUID: string,
    congresUUID: string,
    uuids: string[],
  ): any {
    const postData: any = {
      projectUUID: projectUUID,
      congresUUID: congresUUID,
      uuids: uuids,
    };
    const requestURL: string =
      this.api.createUrl(ApiRoutes.CLONE_EVENTS_INTO_PROJECT) + projectUUID;
    return this.http.post<any>(requestURL, postData);
  }

  public getUbrList(ubr_string: string): Promise<any> {
    const finalURL: string = this.api.createUrl(ApiRoutes.UBR_LIST);
    return new Promise((resolve, reject) => {
      this.http.get(finalURL + '?search=' + ubr_string).subscribe({
        next: (res: any) => {
          this.uberData = res;
          this.ubrList = res.map((a: any) => a.ubr_code);
          resolve(this.ubrList);
        },
        error: (error) => {
          if (error.status == 401) {
            //this.showUnassigned401Error();
            //this.router.navigateByUrl(this.routeService.createURL('/connexion'));
          } else if (
            error.status === 422 ||
            error.status === 428 ||
            error.status === 412 ||
            error.status === 500 ||
            error.status === 404
          ) {
            //this.showUnassignedError();
          }
          throw new Error(error);
          //reject(error);
        },
      });
    });
  }

  public getEventLogisticStatusList(): Promise<any> {
    const finalURL: string = this.api.createUrl(
      ApiRoutes.EVENT_LOGISTIC_STATUS_LIST,
    );
    return new Promise((resolve, reject) => {
      this.http.get(finalURL).subscribe({
        next: (res: any) => {
          resolve(res);
        },
        error: (error) => {
          if (error.status == 401) {
            //this.showUnassigned401Error();
            //this.router.navigateByUrl(this.routeService.createURL('/connexion'));
          } else if (
            error.status === 422 ||
            error.status === 428 ||
            error.status === 412 ||
            error.status === 500 ||
            error.status === 404
          ) {
            //this.showUnassignedError();
          }
          throw new Error(error);
          //reject(error);
        },
      });
    });
  }
}
