import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FuseConfigService } from '../../../../../@fuse/services/config.service';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { AuthenticationService } from '../../../../gt2/api/auth-service.service';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { GenericAlertComponent } from '../../../../gt2/components/generic-alert/generic-alert.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GT2PageAbstract } from '../../../../gt2/content/abstract/GT2PageAbstract';
import { Router } from '@angular/router';
import { AppRoutes } from '../../../../gt2/const/AppRoutes';
import { IconsService } from '../../../../gt2/services/icons.service';
import { switchTheme } from '../../../../fuse-config';
import { AppRoutesService } from '../../../../gt2/services/app-routes.service';

@Component({
  selector: 'forgot-password-2',
  templateUrl: './forgot-password-2.component.html',
  styleUrls: ['./forgot-password-2.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class ForgotPassword2Component
  extends GT2PageAbstract
  implements OnInit
{
  declare dialogRef: any;
  forgotPasswordForm: UntypedFormGroup | any;
  forgotPasswordFormErrors: any;
  forgotPasswordRequestSuccess: boolean = false;
  currentLanguage: string = localStorage.getItem('local_lang') || 'fr-CA';
  buttonLabel: string =
    localStorage.getItem('local_lang') === 'en-CA' ? 'Français' : 'English';

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _formBuilder: UntypedFormBuilder,
    private translationLoader: FuseTranslationLoaderService,
    public dialog: MatDialog,
    public translate: TranslateService,
    public iconsService: IconsService,

    public appRoutesService: AppRoutesService,
    public router: Router,
    private authService: AuthenticationService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };

    this.forgotPasswordFormErrors = {
      email: {},
    };
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    const currentUrl = window.location.href;
    if (currentUrl.includes('gestion-traiteur.app')) {
      localStorage.setItem('local_lang', 'fr-CA');
    }
    if (currentUrl.includes('caterer-manager.app')) {
      localStorage.setItem('local_lang', 'en-CA');
    }
    // this.forgotPasswordForm = this._formBuilder.group({
    //     email: ['', [Validators.required, Validators.email]]
    // });
    this.forgotPasswordForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });

    this.forgotPasswordForm.valueChanges.subscribe({
      next: () => {
        this.onForgotPasswordFormValuesChanged();
      },
    });
  }

  onForgotPasswordFormValuesChanged() {
    for (const field in this.forgotPasswordFormErrors) {
      if (!this.forgotPasswordFormErrors.hasOwnProperty(field)) {
        continue;
      }

      // Clear previous errors
      this.forgotPasswordFormErrors[field] = {};

      // Get the control
      const control = this.forgotPasswordForm?.get(field);

      if (control && control.dirty && !control.valid) {
        this.forgotPasswordFormErrors[field] = control.errors;
      }
    }
  }
  toggleLanguage(): void {
    const currentUrl = window.location.href;
    const replacements = {
      'gestion-traiteur.app/password': 'caterer-manager.app/password',
      'gestion-traiteur.app/mot-de-passe': 'caterer-manager.app/password',
      'caterer-manager.app/mot-de-passe': 'gestion-traiteur.app/mot-de-passe',
      'caterer-manager.app/password': 'gestion-traiteur.app/mot-de-passe',
    };

    for (const [oldUrl, newUrl] of Object.entries(replacements)) {
      if (currentUrl.includes(oldUrl)) {
        window.location.href = currentUrl.replace(oldUrl, newUrl);
        return;
      }
    }
  }

  toggleTheme(): void {
    switchTheme();
  }

  public onForgotPassword(): void {
    const email = this.forgotPasswordForm?.get('email');
    // const reseturl: string = "auth/reset-password-2";
    this.authService.forgotPassword(email?.value).subscribe({
      next: (response) => {
        this.forgotPasswordRequestSuccess = true;

        this.dialogRef = this.dialog.open(GenericAlertComponent, {
          width: '45%',
          minWidth: 350,
          panelClass: 'scrollable-dialog',
          disableClose: false,
        });
        this.dialogRef.componentInstance.alertMessage = this.translate.instant(
          'FORGOT_PASSWORD.LINK_SENT_MESSAGE',
        );

        this.dialogRef.afterClosed().subscribe({
          next: (result: any) => {
            this.dialogRef = null;
            this.router.navigateByUrl(AppRoutes.LOGIN);
          },
        });
      },
      error: (error) => {
        this.forgotPasswordRequestSuccess = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }
}
