import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UserSettingsService } from '../../../api/user-settings.service';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { TaxesService } from '../../../api/taxes.service';
import { GT2PageAbstract } from '../../abstract/GT2PageAbstract';
import { locale as english } from '../i18n/en-CA';
import { locale as french } from '../i18n/fr-CA';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Gt2SelectComponent } from '../../../components/gt2-select/gt2-select.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-taxes-default',
  templateUrl: './taxes-default.component.html',
  styleUrls: ['./taxes-default.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    FormsModule,
    MatButtonModule,
    MatInputModule,
    FlexLayoutModule,
    Gt2SelectComponent,
    ReactiveFormsModule,
    TranslateModule,
  ],
})
export class TaxesDefaultComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() settingsModel: any;

  constructor(
    public router: Router,
    private logger: NGXLogger,
    private toastr: ToastrService,
    public translate: TranslateService,
    public taxesService: TaxesService,
    public settingsService: UserSettingsService,
    private translationLoader: FuseTranslationLoaderService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
  }

  ngOnInit() {}

  ngAfterViewInit() {
    // this.logger.info("TaxesDefaultComponent.ngAfterViewInit() -> settingsModel: " + JSON.stringify(this.settingsModel));
  }

  ngOnDestroy() {}

  //
  public onDeliveryTaxSelected(tax: any): void {
    // this.logger.info("TaxesDefaultComponent.onDeliveryTaxSelected() -> wrapping: " + JSON.stringify(tax));
    // this.autosaveDataChange({wrapping: wrapping});
    this.settingsModel.delivery_tax = tax;
  }

  public onProductTaxSelected(tax: any): void {
    // this.logger.info("TaxesDefaultComponent.onProductTaxSelected() -> wrapping: " + JSON.stringify(tax));
    // this.autosaveDataChange({wrapping: wrapping});
    this.settingsModel.product_tax = tax;
  }

  public onStaffChargeTaxSelected(tax: any): void {
    // this.logger.info("TaxesDefaultComponent.onStaffChargeTaxSelected() -> wrapping: " + JSON.stringify(tax));
    // this.autosaveDataChange({wrapping: wrapping});
    this.settingsModel.staff_charge_tax = tax;
  }

  public onMaterialTaxSelected(tax: any): void {
    // this.logger.info("TaxesDefaultComponent.onMaterialTaxSelected() -> wrapping: " + JSON.stringify(tax));
    // this.autosaveDataChange({wrapping: wrapping});
    this.settingsModel.material_tax = tax;
  }

  public onServiceChargeTaxSelected(tax: any): void {
    // this.logger.info("TaxesDefaultComponent.onServiceChargeTaxSelected() -> wrapping: " + JSON.stringify(tax));
    this.settingsModel.service_charge_tax = tax;
  }

  public onRoyaltyTaxSelected(tax: any): void {
    // this.logger.info("TaxesDefaultComponent.onRoyaltyTaxSelected() -> tax: " + JSON.stringify(tax));
    // this.autosaveDataChange({wrapping: wrapping});
    this.settingsModel.royalty_tax = tax;
  }

  public onSaveData(): void {
    // this.logger.info("TaxesDefaultComponent.onSaveData() -> this.settingsModel: " + JSON.stringify(this.settingsModel));
    this.settingsService
      .updateDefaultTaxes(this.settingsModel, this.settingsModel.uuid)
      .subscribe({
        next: (response: any) => {
          // this.logger.info("TaxesDefaultComponent.onSaveData() SUCCESS: " + JSON.stringify(response));
          this.toastr.success(
            '',
            this.translate.instant('GENERIC.API_CALL_SUCCESS.SAVE_SUCCESS'),
          );
        },
        error: (error: any) => {
          this.logger.error(
            'TaxesDefaultComponent.onSaveData() ERROR: ' +
              JSON.stringify(error),
          );
          this.toastr.error(
            '',
            this.translate.instant('GENERIC.ERRORS.GENERIC'),
          );
        },
      });
  }
}
