<h1 class="h1-title" matDialogTitle>
  {{ "CREATE_MATERIAL.TITLE" | translate }}
</h1>

<div
  fxLayout="column"
  fxLayoutAlign="center start"
  [formGroup]="form"
  (keyup.enter)="form.valid && !loading ? onCreateMaterial() : null"
  class="mt-8 create-client-width"
>
  <mat-form-field fxFlex="100%" class="item-full-width">
    <mat-label>{{ "GENERIC.EXTERNAL_NAME" | translate }}</mat-label>
    <input
      matInput
      fxFlex="100%"
      formControlName="name"
      autocomplete="nope"
      type="text"
      [(ngModel)]="material.name"
      required
    />
    <mat-error *ngIf="form.controls['name'].invalid">{{
      "GENERIC.INPUT_LENGTH_INVALID" | translate
    }}</mat-error>
  </mat-form-field>
</div>

<div *ngIf="loading" fxLayout="row" fxLayoutAlign="center start">
  <mat-spinner [diameter]="40" class="mt-20"></mat-spinner>
</div>

<div
  mat-dialog-actions
  class="mt-16 gt2-buttons-theme"
  fxLayout="row"
  fxLayoutAlign="center start"
>
  <button
    mat-raised-button
    [disabled]="!form.valid || loading"
    color="accent"
    class="mr-16"
    (click)="onCreateMaterial()"
  >
    {{ "GENERIC.SAVE" | translate }}
  </button>

  <button
    mat-raised-button
    color="primary"
    (click)="dialogRef.close(false)"
    class=""
  >
    {{ "GENERIC.CANCEL" | translate }}
  </button>
</div>
