import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaintenanceComponent } from './maintenance.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { GenericAlertComponent } from '../../../gt2/components/generic-alert/generic-alert.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';

const routes = [
  {
    path: 'maintenance',
    component: MaintenanceComponent,
  },
];

@NgModule({
  declarations: [MaintenanceComponent],
  imports: [
    GenericAlertComponent,
    MatButtonModule,
    MatInputModule,
    RouterModule.forChild(routes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    TranslateModule.forRoot(),
    MatFormFieldModule,
    MatCardModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [],
})
export class MaintenanceModule {}
