import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FuseConfigService } from '../../../../../@fuse/services/config.service';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AppRoutes } from '../../../../gt2/const/AppRoutes';
import { SettingsModel } from '../../../../gt2/settings/SettingsModel';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { SettingsService } from '../../../../gt2/settings/settings.service';
import { SupportContactService } from '../../../../gt2/content/contact/support-contact.service';
import { GT2FormPageAbstract } from '../../../../gt2/content/abstract/GT2FormPageAbstract';
import { Settings } from '../../../../gt2/settings/settings';
import { ResponsesStatus } from '../../../../gt2/api/ResponsesStatus';
import { AppRoutesService } from '../../../../gt2/services/app-routes.service';
import { GenericAlertComponent } from '../../../../gt2/components/generic-alert/generic-alert.component';
import { JwtInterceptor } from '../../../../gt2/interceptors/JwtInterceptor';
import { AuthenticationService } from '../../../../gt2/api/auth-service.service';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'error-500',
  templateUrl: './error-500.component.html',
  styleUrls: ['./error-500.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class Error500Component
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  form: UntypedFormGroup | any;
  subject?: string;
  message?: string;
  setting?: SettingsModel;
  last500URL?: string | any = null;
  readonly environment = environment;

  /*
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private router: Router,
    public location: Location,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    public dialog: MatDialog,
    private logger: NGXLogger,
    private auth: AuthenticationService,
    private formBuilder: UntypedFormBuilder,
    private settings: SettingsService,
    private titleService: Title,
    public appRoutesService: AppRoutesService,
    private supportContactService: SupportContactService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    if (JwtInterceptor.LAST_URL_500) {
      this.last500URL = JwtInterceptor.LAST_URL_500;
    }

    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };

    //
    this.form = this.formBuilder.group({
      subject: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      message: [
        '',
        [Validators.minLength(Settings.inputMinChar), Validators.required],
      ],
    });
  }

  ngOnInit() {
    //this.titleService.setTitle(this.translate.instant("GENERIC.ERROR"));
    //
    this.settings.loadSettings().subscribe({
      next: (response) => {
        // this.logger.info('Error500Component.constructor().settings() -> SUCCESS: ' + JSON.stringify(response));
        if (response.response_status === ResponsesStatus.OK) {
          this.setting = response.response;
        }
      },
      error: (error) => {
        this.logger.error(
          'Error500Component.constructor().settings() -> ERRORS: ' +
            JSON.stringify(error),
        );
      },
    });
  }

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public onSendContactForm(): void {
    // this.logger.info("Error500Component.onSendContactForm()");
    if (this.last500URL) {
      this.message +=
        ' - (url: ' +
        this.last500URL +
        '  version:' +
        environment.appVersion +
        ')';
      // this.logger.info("&&&&&&& -> " + this.message);
    }

    const email: any = {
      subject: '(Error 500 page) ' + this.subject,
      message: this.message,
    };
    this.supportContactService.postEmailError500(email).subscribe({
      next: (response) => {
        // this.logger.info("Error500Component.onSendContactForm() -> SUCCESS: " + JSON.stringify(response));
        this.showConfirmationDialog();
      },
      error: (error) => {
        this.logger.error(
          'Error500Component.onSendContactForm() -> ERROR: ' +
            JSON.stringify(error),
        );
      },
    });
  }

  public override onPageBack(): void {
    // this.logger.info(">>>>>>>>> ==> " + window.history.length);
    if (window.history.length > 1) {
      this.location.back();
    } else {
      this.router.navigateByUrl(AppRoutes.DASHBOARD);
    }
  }

  public onVisitGt2(): void {
    window.open('https://www.gestiontraiteur.com', '_blank');
  }

  public showConfirmationDialog(): void {
    this.subject = '';
    this.message = '';
    this.form.markAsUntouched();
    this.form.markAsPristine();

    this.dialogRef = this.dialog.open(GenericAlertComponent, {
      // width: '45%',
      disableClose: false,
    });
    this.dialogRef.componentInstance.alertTitle = '';
    this.dialogRef.componentInstance.alertMessage = this.translate.instant(
      'ERROR_500.SUCCESS_MESSAGE',
    );
    // this.dialogRef.componentInstance.showConfirmButton = false;

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        this.dialogRef = null;
      },
    });
  }

  public onLogout(): void {
    // JwtInterceptor.LAST_URL_500 = null;
    this.auth.logout(true).subscribe({
      next: (response) => {
        // this.logger.info("Error500Component.onLogout() -> SUCCESS: " + JSON.stringify(response));
      },
      error: (error) => {
        this.logger.error(
          'Error500Component.onLogout() -> ERROR: ' + JSON.stringify(error),
        );
      },
    });
  }
}
