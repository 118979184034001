export const locale = {
  lang: 'fr-CA',
  data: {
    VENUES: {
      VENUES: 'Lieux',
      VENUE: 'Lieu',
      DELETE_VENUES: 'Effacer cette place',
      DELETE_CONFIRM: 'Êtes vous sûr de vouloir effacer cette place?',
      DELETE_SUCCESS: 'Emplacement effacé avec succès',
      INFORMATION: 'Information',
      NAME: 'Nom',
      YES: 'Oui',
      ROOMS: 'Salles',
      EMAIL: 'Courriel',
      PHONE: 'Téléphone',
      NOTES: 'Notes',
      CONTACT_NAME: 'Nom du contact',
      CONTACT: 'Contact',
      GENERAL: 'Général',
      ADDRESS: 'Adresse',
      INTERNAL: 'Lieu interne',
      ORG_EMAIL: 'Courriel professionnel',
      MOBILE: 'Mobile',
      OFFICE_PHONE: 'Téléphone bureau',
      OFFICE_EXT: 'Ext',
      PHONE_OTHER: 'Téléphone autre',
      FAX: 'Fax',
      SKYPE: 'Skype',
      NO_CONTACT_INFO: 'Aucune information de contact.',
      EMAIL_CC: 'Email CC',
      SEARCH_ADDRESS: 'Rechercher une adresse',
      SEE_ON_GOOGLE_MAP: 'Voir sur Google Map',
      NO_GEO_LOCATION: 'Pas de permission pour le Geolocation.',
      ADDRESS_NUMBER: 'Numéro',
      STREET: 'Rue',
      APP_NUMBER: '# App',
      LINE_2: 'Adresse ligne 2',
      CITY: 'Ville',
      PROVINCE: 'Province/État',
      COUNTRY: 'Pays',
      POSTAL_CODE: 'Code Postal/Zip',
      SECTOR: 'Secteur',
      NO_ADDRESS_INFO: 'Aucune information pour cette adresse',
      ON_SITE_CONTACT: 'Livraison et personne à contacter sur le site',
      CREATED_ON: 'Créé le ',
      BY: 'par',
      LAST_MODIFICATION: 'Dernière modification le',
      DELETE: 'Effacer',
    },
  },
};
