<!-- PROGRESS BAR -->
<fuse-progress-bar></fuse-progress-bar>
<!-- / PROGRESS BAR -->

<!--<p-messages [(value)]="messages"></p-messages>-->

<div>
  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    name="reportSpinner"
    size="medium"
    color="#90ee90"
    [fullScreen]="true"
    type="ball-scale-multiple"
  >
  </ngx-spinner>
</div>
<!-- VERTICAL LAYOUT 1 -->
<ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-1'">
  <vertical-layout-1
    [topAlertModelUser]="topAlertModelUser!"
    [topAlertModelSettings]="topAlertModelSettings!"
    [topAlertModelCaterer]="topAlertModelCaterer!"
  ></vertical-layout-1>
  <!-- <vertical-layout-1  []="topAlertModel" [message]="topBarMessage" [messageColor]="topBarColor" [messageClosable]="topBarClosable"></vertical-layout-1> -->
</ng-container>
<!-- / VERTICAL LAYOUT 1 -->

<!-- VERTICAL LAYOUT 2 -->
<ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-2'">
  <vertical-layout-2></vertical-layout-2>
</ng-container>
<!-- / VERTICAL LAYOUT 2 -->

<!-- VERTICAL LAYOUT 3 -->
<ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-3'">
  <vertical-layout-3></vertical-layout-3>
</ng-container>
<!-- / VERTICAL LAYOUT 3 -->

<!-- HORIZONTAL LAYOUT 1 -->
<ng-container *ngIf="fuseConfig.layout.style === 'horizontal-layout-1'">
  <horizontal-layout-1></horizontal-layout-1>
</ng-container>
<!-- / HORIZONTAL LAYOUT 1 -->

<!-- THEME OPTIONS PANEL -->
<!--<button mat-icon-button class="warn mat-elevation-z2 theme-options-button"-->
<!--(click)="toggleSidebarOpen('themeOptionsPanel')">-->
<!--<mat-icon>settings</mat-icon>-->
<!--</button>-->

<app-firebase-process #firebaseProcessComponent></app-firebase-process>

<fuse-sidebar
  name="themeOptionsPanel"
  class="theme-options-sidebar"
  position="right"
  [invisibleOverlay]="true"
>
  <fuse-theme-options></fuse-theme-options>
</fuse-sidebar>
<!-- / THEME OPTIONS PANEL -->
