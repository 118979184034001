import { MenuReportsService } from '../../../reports/menu-reports.service';
import { ModulesService } from '../../../services/modules.service';
import { CreateTakeoutRequestComponent } from './../../../components/dialogs/create-takeout-request/create-takeout-request.component';
import { Location } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { FuseSidebarService } from '../../../../../@fuse/components/sidebar/sidebar.service';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { ApiRoutes } from '../../../api/ApiRoutes';
import { EventComposeService } from '../../../api/event-compose.service';
import { EventsService } from '../../../api/events.service';
import { Gt2ApiService } from '../../../api/gt2-api.service';
import { CreateDeliveryRequestComponent } from '../../../components/dialogs/create-delivery-request/create-delivery-request.component';
import { CreateDepositInComponent } from '../../../components/dialogs/create-deposit-in/create-deposit-in.component';
import { CreateDepositRequestComponent } from '../../../components/dialogs/create-deposit-request/create-deposit-request.component';
import { CreateFlowComponent } from '../../../components/dialogs/create-flow/create-flow.component';
import { CreateGuestComponent } from '../../../components/dialogs/create-guest/create-guest.component';
import { CreateProposalComponent } from '../../../components/dialogs/create-proposal/create-proposal.component';
import { GenericAlertComponent } from '../../../components/generic-alert/generic-alert.component';
import { ICanDeactivateComponent } from '../../../gards/can-deactivate-guard.service';
import { RolesService } from '../../../services/roles.service';
import { GT2DateAdapter } from '../../../utils/GT2DateAdapter';
import { ItemUrlFinder } from '../../../utils/item-url-finder';
import { FileService } from '../../../../services/file.service';
import { PrintService } from '../../../../services/print.service';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { GT2FormPageAbstract } from '../../abstract/GT2FormPageAbstract';
import { MailComposeDialogComponent } from '../../compose/compose.component';
import { locale as english } from '../i18n/en-CA';
import { locale as french } from '../i18n/fr-CA';
import { EventClientsComponent } from './event-clients/event-clients.component';
import { EventDeliveryRequestComponent } from './event-delivery-request/event-delivery-request.component';
import { EventDepositInComponent } from './event-deposit-in/event-deposit-in.component';
import { EventDepositRequestComponent } from './event-deposit-request/event-deposit-request.component';
import { EventDocumentsComponent } from './event-documents/event-documents.component';
import { EventFlowComponent } from './event-flow/event-flow.component';
import { EventGuestComponent } from './event-guest/event-guest.component';
import { EventInformationComponent } from './event-information/event-information.component';
import { EventInvoicesComponent } from './event-invoices/event-invoices.component';
import { EventStatsComponent } from './event-stats/event-stats.component';
import { EventVenuesComponent } from './event-venues/event-venues.component';
import { GT2ConstantsEnum } from '../../../const/GT2ConstantsEnum';
import { Title } from '@angular/platform-browser';
import { OrdersService } from '../../../api/orders.service';
import { EventTakoutRequestComponent } from './event-takout-request/event-takout-request.component';
import { CreateProgramComponent } from '../../../components/dialogs/create-program/create-program.component';
import { EventNoticeOfReceiptComponent } from './event-notice-of-receipt/event-notice-of-receipt.component';
import { ChangelogsDialogComponent } from '../../../components/dialogs/changelogs-dialog/changelogs-dialog.component';
import { TagsByModelService } from '../../../components/tags-by-model/tags.-by-model.service';
import { DepositRequestService } from '../../../api/deposit-request.service';
import { EventStorageNoticeComponent } from './event-storage-notice/event-storage-notice.component';
import { HistoryStack, PageHistory } from '../../../benching/page-history';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
  animations: fuseAnimations,
  providers: [{ provide: DateAdapter, useClass: GT2DateAdapter }],
})
export class EventComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy, ICanDeactivateComponent
{
  event: any;
  eventUUDID!: string;
  isSmallScreen?: boolean;
  loading: boolean = false;
  editMode: boolean = false;
  @Output() editModeChange = new EventEmitter<any>();
  declare dialogRef: any;
  confirmDialogRef: any;
  modelUpdatedSubject: Subject<any> = new Subject<any>();
  @Output() editableChange = new EventEmitter<any>();
  editable: boolean = true;
  @ViewChild(MatTabGroup) tabGroup?: MatTabGroup;
  @ViewChild(EventInformationComponent)
  eventInformationComponent?: EventInformationComponent;
  @ViewChild(EventNoticeOfReceiptComponent)
  eventNoticeOfReceiptComponent?: EventNoticeOfReceiptComponent;
  @ViewChild(EventStorageNoticeComponent)
  eventStorageNoticeComponent?: EventStorageNoticeComponent;
  @ViewChild(EventDocumentsComponent)
  eventDocumentsComponent?: EventDocumentsComponent;
  @ViewChild(EventVenuesComponent)
  eventVenuesComponent?: EventVenuesComponent;
  @ViewChild(EventFlowComponent)
  eventFlowComponent?: EventFlowComponent;
  @ViewChild(EventGuestComponent)
  eventGuestComponent?: EventGuestComponent;
  @ViewChild(EventDeliveryRequestComponent)
  eventDeliveryRequestComponent?: EventDeliveryRequestComponent;
  @ViewChild(EventTakoutRequestComponent)
  eventTakoutRequestComponent?: EventTakoutRequestComponent;
  @ViewChild(EventClientsComponent)
  eventClientsComponent?: EventClientsComponent;
  @ViewChild(EventStatsComponent)
  eventStatsComponent?: EventStatsComponent;
  @ViewChild(EventDepositRequestComponent)
  eventDepositRequestComponent?: EventDepositRequestComponent;
  @ViewChild(EventDepositInComponent)
  eventDepositInComponent?: EventDepositInComponent;
  @ViewChild(EventInvoicesComponent)
  eventInvoicesComponent?: EventInvoicesComponent;
  //
  deliveryIconReady: boolean = false;
  tabIndex: number = 0;

  get isEditable(): boolean {
    return this.editable && this.editMode;
  }

  set isEditable(value: boolean) {
    this.editable = value;
    this.editMode = value; // Adaptez cette logique selon votre besoin
  }
  constructor(
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public logger: NGXLogger,
    public location: Location,
    private dialog: MatDialog,
    public rolesService: RolesService,
    public print: PrintService,
    public fileService: FileService,
    private api: Gt2ApiService,
    private eventsService: EventsService,
    private _fuseSidebarService: FuseSidebarService,
    public ordersService: OrdersService,
    private adapter: DateAdapter<any>,
    private titleService: Title,
    public moduleService: ModulesService,
    public eventComposeService: EventComposeService,
    public router: Router,
    public modulesService: ModulesService,
    public menuReportsService: MenuReportsService,
    public tagsService: TagsByModelService,
    public depositRequestService: DepositRequestService,
    private breakpointObserver: BreakpointObserver,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    this.adapter.setLocale(this.translate.currentLang);
    // //this.logger.info("EventComponent.constructor() -> SYLVAIN!!!");
  }

  ngOnInit() {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe({
      next: (result) => {
        this.isSmallScreen = result.matches;
      },
    });
    //this.titleService.setTitle(this.translate.instant("EVENTS.EVENT"));

    this.route.params.subscribe({
      next: (params: any) => {
        this.eventUUDID = params.id;
        this.loadData();
      },
    });
  }

  ngAfterViewInit() {
    ////this.logger.info("EventComponent.ngAfterViewInit()");
    this.modelUpdatedSubject
      .pipe(
        debounceTime(1000),
        tap(() => {
          this.toastr.success(
            '',
            this.translate.instant('GENERIC.API_CALL_SUCCESS.SAVE_SUCCESS'),
          );
          this.loadData();
          // this.loading = false;
        }),
      )
      .subscribe();

    this.route.queryParams.subscribe({
      next: (params) => {
        let tab = params['tab'];
        if (
          tab &&
          tab !== null &&
          !(tab == undefined) &&
          tab === 'encaissement'
        ) {
          if (this.moduleService.hasEventTabsModule()) {
            this.tabIndex = 6;
          } else {
            this.tabIndex = 1;
          }
          setTimeout(() => {
            if (this.tabGroup) {
              if (this.moduleService.hasEventTabsModule()) {
                this.tabGroup.selectedIndex = 7;
              } else {
                this.tabGroup.selectedIndex = 2;
              }
            }
          }, 1000);
        } else if (
          tab &&
          tab !== null &&
          !(tab == undefined) &&
          tab === 'lieu'
        ) {
          // if (this.moduleService.hasEventTabsModule()){
          //     this.tabIndex = 3;
          // }else{
          //     this.tabIndex = 1;
          // }
          setTimeout(() => {
            if (this.tabGroup) {
              if (this.moduleService.hasEventTabsModule()) {
                this.tabGroup.selectedIndex = 2;
              } else {
                this.tabGroup.selectedIndex = 0;
              }
            }
          }, 1000);
        }
      },
    });
  }

  ngOnDestroy() {}

  public loadData(): void {
    this.eventsService.getEvent(this.eventUUDID).subscribe({
      next: (response: any) => {
        ////this.logger.info("EventComponent.loadData() -> SUCCESS");
        /* //this.logger.info(
                    "EventComponent.loadData() -> SUCCESS: " +
                        JSON.stringify(response)
                ); */
        this.event = response.data;

        const callBenching: HistoryStack = {
          url: this.router.url,
          icon: this.getIcon('EVENT'),
          uuid: this.event.uuid,
          label: this.event.informations.name + ' ' + this.event.event_number,
        };
        PageHistory.addCall(callBenching);

        // po_number
        if (
          this.event.informations.po_number !==
          response.data.informations.po_number
        ) {
          response.data.informations.po_number =
            this.event.informations.po_number;
        }

        // event_date
        if (
          this.event.informations.event_date !==
          response.data.informations.event_date
        ) {
          response.data.informations.event_date =
            this.event.informations.event_date;
          response.data.informations.event_date_label =
            this.event.informations.event_date_label;
        }

        // event_time
        if (
          this.event.informations.event_time !==
          response.data.informations.event_time
        ) {
          response.data.informations.event_time =
            this.event.informations.event_time;
          response.data.informations.event_time_label =
            this.event.informations.event_time_label;
        }

        // event_date_end
        if (
          this.event.informations.event_date_end !==
          response.data.informations.event_date_end
        ) {
          response.data.informations.event_date_end =
            this.event.informations.event_date_end;
          response.data.informations.event_date_end_label =
            this.event.informations.event_date_end_label;
        }

        // event_time_end
        if (
          this.event.informations.event_time_end !==
          response.data.informations.event_time_end
        ) {
          response.data.informations.event_time_end =
            this.event.informations.event_time_end;
          response.data.informations.event_time_end_label =
            this.event.informations.event_time_end_label;
        }

        // ordering_local
        if (this.event.ordering_local !== response.data.ordering_local) {
          response.data.ordering_local = this.event.ordering_local;
        }

        // invoicing_local
        if (this.event.invoicing_local !== response.data.invoicing_local) {
          response.data.invoicing_local = this.event.invoicing_local;
        }

        /* //this.logger.info(
                    "!!!EventComponent.loadData() -> SUCCESS: EVENT TYPE " +
                        JSON.stringify(response.data.informations.event_type)
                );
                //this.logger.info(response.data); */
        // event_type
        if (
          this.event.informations.event_type !==
          response.data.informations.event_type
        ) {
          response.data.informations.event_type =
            this.event.informations.event_type;
        }

        // organization_identity
        if (
          this.event.informations.organization_identity !==
          response.data.informations.organization_identity
        ) {
          response.data.informations.organization_identity =
            this.event.informations.organization_identity;
        }

        //
        this.event = response.data;
        //
        this.validateStatus();

        /* //this.logger.info(
                    "EventComponent.loadData() -> SUCCESS event.status: " +
                        this.event.status
                );
                //this.logger.info(
                    "EventComponent.loadData() -> SUCCESS this.editable: " +
                        this.editable
                ); */
        this.notifyComponentMdelLoaded();
        //this.titleService.setTitle(this.translate.instant("EVENTS.EVENT") + " " + this.event.informations.name);
        //
        this.deliveryIconReady = false;
        setTimeout(() => {
          this.deliveryIconReady = true;
        });
      },
      error: (error: any) => {
        this.logger.error(
          'EventComponent.loadData() -> ERROR: ' + JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public validateStatus(): void {
    this.editable =
      this.event.status !== GT2ConstantsEnum.EVENT_ABORT &&
      this.event.status !== GT2ConstantsEnum.EVENT_CANCEL &&
      this.event.status !== GT2ConstantsEnum.EVENT_COMPLETE;
    // this.editable = false;
  }

  public canDeactivate(): boolean {
    if (
      this.eventInformationComponent &&
      this.eventInformationComponent.form.dirty
    ) {
      return false;
    }
    return true;
  }

  public onEdit(): void {
    /* //this.logger.info(
            "EventComponent.onEdit() -> " +
                this.eventInformationComponent.form.valid
        ); */

    this.editMode = !this.editMode;
    // if (this.editMode) {
    //     return;
    // }
    if (this.eventInformationComponent) {
      this.eventInformationComponent.onToggleEdit();
      if (!this.editMode) {
        this.eventInformationComponent.onManualSave();
      }
    }

    if (this.eventNoticeOfReceiptComponent) {
      this.eventNoticeOfReceiptComponent.onToggleEdit();
      if (!this.editMode) {
        this.eventNoticeOfReceiptComponent.onManualSave();
      }
    }

    if (this.eventStorageNoticeComponent) {
      this.eventStorageNoticeComponent.onToggleEdit();
      if (!this.editMode) {
        this.eventStorageNoticeComponent.onManualSave();
      }
    }

    if (this.eventDocumentsComponent) {
      this.eventDocumentsComponent.onToggleEdit();
      if (!this.editMode) {
        this.eventDocumentsComponent.onManualSave();
      }
    }

    if (this.eventVenuesComponent) {
      this.eventVenuesComponent.onToggleEdit();
      if (!this.editMode) {
        this.eventVenuesComponent.onManualSave();
      }
    }

    if (this.eventFlowComponent) {
      this.eventFlowComponent.onToggleEdit();
      if (!this.editMode) {
        this.eventFlowComponent.onManualSave();
      }
    }

    if (this.eventGuestComponent) {
      this.eventGuestComponent.onToggleEdit();
      if (!this.editMode) {
        this.eventGuestComponent.onManualSave();
      }
    }

    if (this.eventDeliveryRequestComponent) {
      this.eventDeliveryRequestComponent.onToggleEdit();
      if (!this.editMode) {
        this.eventDeliveryRequestComponent.onManualSave();
      }
    }

    if (this.eventTakoutRequestComponent) {
      this.eventTakoutRequestComponent.onToggleEdit();
      if (!this.editMode) {
        this.eventTakoutRequestComponent.onManualSave();
      }
    }

    if (this.eventClientsComponent) {
      this.eventClientsComponent.onToggleEdit();
      if (!this.editMode) {
        this.eventClientsComponent.onManualSave();
      }
    }

    if (this.eventStatsComponent) {
      this.eventStatsComponent.onToggleEdit();
      if (!this.editMode) {
        this.eventStatsComponent.onManualSave();
      }
    }

    if (this.eventDepositRequestComponent) {
      this.eventDepositRequestComponent.onToggleEdit();
      if (!this.editMode) {
        this.eventDepositRequestComponent.onManualSave();
      }
    }

    if (this.eventDepositInComponent) {
      this.eventDepositInComponent.onToggleEdit();
      if (!this.editMode) {
        this.eventDepositInComponent.onManualSave();
      }
    }

    if (this.eventInvoicesComponent) {
      this.eventInvoicesComponent.onToggleEdit();
      if (!this.editMode) {
        // this.eventInvoicesComponent.onManualSave();
      }
    }

    // if (!this.editMode) {
    //     this.loading = true;
    // }
  }

  public onCancelEdit(event: any): void {
    //this.logger.info("onCancelEdit() -> event_time: " + this.event.informations.event_time);
    //this.logger.info("onCancelEdit() -> event_time: " + (this.event.informations.event_time === ""));
    this.editMode = false;
    if (this.eventInformationComponent) {
      this.eventInformationComponent.onToggleEdit();
    }

    if (this.eventNoticeOfReceiptComponent) {
      this.eventNoticeOfReceiptComponent.onToggleEdit();
    }

    if (this.eventStorageNoticeComponent) {
      this.eventStorageNoticeComponent.onToggleEdit();
    }

    if (this.eventDocumentsComponent) {
      this.eventDocumentsComponent.onToggleEdit();
    }

    if (this.eventVenuesComponent) {
      this.eventVenuesComponent.onToggleEdit();
    }

    if (this.eventFlowComponent) {
      this.eventFlowComponent.onToggleEdit();
    }

    if (this.eventGuestComponent) {
      this.eventGuestComponent.onToggleEdit();
    }

    if (this.eventDeliveryRequestComponent) {
      this.eventDeliveryRequestComponent.onToggleEdit();
    }

    if (this.eventTakoutRequestComponent) {
      this.eventTakoutRequestComponent.onToggleEdit();
    }

    if (this.eventClientsComponent) {
      this.eventClientsComponent.onToggleEdit();
    }

    if (this.eventStatsComponent) {
      this.eventStatsComponent.onToggleEdit();
    }

    if (this.eventDepositRequestComponent) {
      this.eventDepositRequestComponent.onToggleEdit();
    }

    if (this.eventDepositInComponent) {
      this.eventDepositInComponent.onToggleEdit();
    }

    if (this.eventInvoicesComponent) {
      this.eventInvoicesComponent.onToggleEdit();
    }

    // this.form.markAsPristine();
    // this.form.markAsUntouched();
    this.loadData();
  }

  public onSaveData(): void {
    // if (this.eventInformationComponent) {
    //     this.eventInformationComponent.onSaveData();
    // }
    // if (this.eventDocumentsComponent) {
    //     this.eventDocumentsComponent.onSaveData();
    // }
    // if (this.eventVenuesComponent) {
    //     this.eventVenuesComponent.onSaveData();
    // }
    // if (this.eventFlowComponent) {
    //     this.eventFlowComponent.onSaveData();
    // }
    // if (this.eventGuestComponent) {
    //     this.eventGuestComponent.onSaveData();
    // }
    // if (this.eventDeliveryRequestComponent) {
    //     this.eventDeliveryRequestComponent.onSaveData();
    // }
    // if (this.eventTakoutRequestComponent) {
    //     this.eventTakoutRequestComponent.onSaveData();
    // }
    // if (this.eventClientsComponent) {
    //     this.eventClientsComponent.onSaveData();
    // }
    // if (this.eventStatsComponent) {
    //     this.eventStatsComponent.onSaveData();
    // }
    // if (this.eventDepositRequestComponent) {
    //     this.eventDepositRequestComponent.onSaveData();
    // }
    // if (this.eventDepositInComponent) {
    //     this.eventDepositInComponent.onSaveData();
    // }
    // if (this.eventInvoicesComponent) {
    //     this.eventInvoicesComponent.onSaveData();
    // }
  }

  public notifyComponentMdelLoaded(): void {
    if (this.eventInformationComponent) {
      this.eventInformationComponent.onModelLoaded();
    }

    if (this.eventNoticeOfReceiptComponent) {
      this.eventNoticeOfReceiptComponent.onModelLoaded();
    }

    if (this.eventStorageNoticeComponent) {
      this.eventStorageNoticeComponent.onModelLoaded();
    }

    if (this.eventDocumentsComponent) {
      this.eventDocumentsComponent.onModelLoaded();
    }

    if (this.eventVenuesComponent) {
      this.eventVenuesComponent.onModelLoaded();
    }

    if (this.eventFlowComponent) {
      this.eventFlowComponent.onModelLoaded();
    }

    if (this.eventGuestComponent) {
      this.eventGuestComponent.onModelLoaded();
    }

    if (this.eventDeliveryRequestComponent) {
      this.eventDeliveryRequestComponent.onModelLoaded();
    }

    if (this.eventTakoutRequestComponent) {
      this.eventTakoutRequestComponent.onModelLoaded();
    }

    if (this.eventClientsComponent) {
      this.eventClientsComponent.onModelLoaded();
    }

    if (this.eventStatsComponent) {
      this.eventStatsComponent.onModelLoaded();
    }

    if (this.eventDepositRequestComponent) {
      this.eventDepositRequestComponent.onModelLoaded();
    }

    if (this.eventDepositInComponent) {
      this.eventDepositInComponent.onModelLoaded();
    }

    if (this.eventInvoicesComponent) {
      this.eventInvoicesComponent.onModelLoaded();
    }
  }

  public onComponentModelUpdated(): void {
    //this.logger.info("EventComponent.onComponentModelUpdated()");
    this.modelUpdatedSubject.next(undefined);
  }

  // public onComponentEventClientModelUpdated(result): void {
  //     //this.logger.info(
  //         "EventComponent.onComponentEventClientModelUpdated(): " +
  //             JSON.stringify(result)
  //     );
  //     this.modelUpdatedSubject.next();
  //     // this.event.c
  // }

  public onComponentNewModel(model: any): void {
    //this.logger.info("EventComponent.onComponentNewModel()");
    // this.modelUpdatedSubject.next();

    // here
    this.event = model;
  }

  public onVenuesComponentNewModel(model: any): void {
    //this.logger.info("EventComponent.onVenuesComponentNewModel() -> model: " + JSON.stringify(model));
    // this.event = model;
    this.event.clients = model.clients;
    this.event.spot = model.spot;
  }

  public onVisitOrder(): void {
    // //this.logger.info("EventComponent.onVisitOrder() -> " + ItemUrlFinder.getItemURL(this.event.documents.order));
    this.router.navigateByUrl(
      ItemUrlFinder.getItemURL(this.event.documents.order),
    );
  }

  public onVisitInvoice(): void {
    // //this.logger.info("EventComponent.onVisitOrder() -> " + ItemUrlFinder.getItemURL(this.event.documents.order));
    this.router.navigateByUrl(
      ItemUrlFinder.getItemURL(this.event.documents.invoice),
    );
  }

  public onAddNewProposal() {
    // //this.logger.info("EventComponent.onAddNewProposal()");
    this.dialogRef = this.dialog.open(CreateProposalComponent, {
      width: '340px',
      maxHeight: '96vh',
      disableClose: false,
      data: {
        autoRedirect: true,
        name: this.event.informations.name,
        eventUUID: this.event.uuid,
      },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          //this.logger.info("EventComponent.onAddNewProposal() -> dialog closed: result: " + JSON.stringify(result));
        }
        this.dialogRef = null;
      },
    });
  }

  public onAddNewFlow() {
    // //this.logger.info("EventComponent.onAddNewFlow()");
    this.dialogRef = this.dialog.open(CreateFlowComponent, {
      width: '440px',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      maxHeight: '96vh',
      disableClose: false,
      data: {
        autoRedirect: true,
        date: this.event.informations.event_date,
        eventUUID: this.event.uuid,
        mode: CreateFlowComponent.CREATE,
      },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          // //this.logger.info("EventComponent.onAddNewFlow() -> dialog closed: result: " + JSON.stringify(result));
          // this.eventFlowComponent.loading = true;
          // this.loadData();
          this.event.flows.push(result.data);
          this.loadData();
        }
        this.dialogRef = null;
      },
    });
  }

  public onAddNewProgram() {
    // //this.logger.info("EventComponent.onAddNewProgram()");
    this.dialogRef = this.dialog.open(CreateProgramComponent, {
      width: '440px',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      maxHeight: '96vh',
      disableClose: false,
      data: {
        autoRedirect: true,
        date: this.event.informations.event_date,
        eventUUID: this.event.uuid,
        isCreateForm: true,
        mode: CreateProgramComponent.CREATE,
      },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          // //this.logger.info("EventComponent.onAddNewProgram() -> dialog closed: result: " + JSON.stringify(result));
          // this.eventFlowComponent.loading = true;
          // this.loadData();
          // this.event.event_programs.push(result.data);
          this.loadData();
          //
        }
        this.dialogRef = null;
      },
    });
  }

  public onAddNewGuest(): void {
    // //this.logger.info("EventComponent.onAddNewGuest()");
    this.dialogRef = this.dialog.open(CreateGuestComponent, {
      width: '440px',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      maxHeight: '96vh',
      disableClose: false,
      data: {
        autoRedirect: true,
        eventUUID: this.event.uuid,
        mode: CreateGuestComponent.CREATE,
      },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          // //this.logger.info("EventComponent.onAddNewGuest() -> dialog closed: result: " + JSON.stringify(result));
          // this.eventGuestComponent.loading = true;
          //
          this.event.guests.push(result.data);
          this.loadData();
        }
        this.dialogRef = null;
      },
    });
  }

  public onAddNewDeliveryRequest(): void {
    // //this.logger.info("EventComponent.onAddNewDeliveryRequest()");

    // TODO:
    // event.spot.event_address;
    if (
      (!this.event.spot.enterprise &&
        !this.event.spot.private_client &&
        !this.event.spot.place &&
        !this.event.spot.room &&
        !this.event.spot.event_address) ||
      !this.event.delivery_type ||
      !this.event.delivery_type.need_delivery
    ) {
      this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
        disableClose: false,
      });

      this.confirmDialogRef.componentInstance.useCancel = false;
      this.confirmDialogRef.componentInstance.alertMessage =
        this.translate.instant('EVENTS.DELIVERY_REQUEST_SPOT_NOT_SELECTTED');
      this.confirmDialogRef.afterClosed().subscribe({
        next: (result: any) => {
          this.confirmDialogRef = null;
        },
      });
    } else {
      this.dialogRef = this.dialog.open(CreateDeliveryRequestComponent, {
        width: '600px',
        minWidth: 350,
        panelClass: 'scrollable-dialog',
        maxHeight: '96vh',
        disableClose: false,
        data: {
          autoRedirect: true,
          eventUUID: this.event.uuid,
          mode: CreateDeliveryRequestComponent.CREATE,
          event: this.event,
        },
      });

      this.dialogRef.afterClosed().subscribe({
        next: (result: any) => {
          if (result) {
            //this.logger.info("EventComponent.onAddNewDeliveryRequest() -> dialog closed: result: " + JSON.stringify(result));
            // this.eventDeliveryRequestComponent.loading = true;
            // this.loadData();
            this.event.delivery_requests.push(result.data);
          }
          this.dialogRef = null;
        },
      });
    }
  }

  public openChangelogDialog() {
    if (
      this.moduleService.hasChangelogsModule() &&
      this.event.documents.order &&
      this.event.documents.order.status === GT2ConstantsEnum.ORDER_CONFIRM
    ) {
      const dialogRef = this.dialog.open(ChangelogsDialogComponent, {
        width: '50vw',
        minWidth: 350,
        panelClass: 'scrollable-dialog',
        data: {
          changelog: this.event.changelog,
          event_uuid: this.event.uuid,
          order_uuid: this.event.documents.order.uuid,
        },
      });

      dialogRef.afterClosed().subscribe({
        next: (response) => {
          if (response) {
            //this.logger.info("changelog saved -> result : " + JSON.stringify(response));
            this.event.changelog = response.data;
          }
        },
      });

      this.dialogRef = null;
    }
  }

  public onAddNewTakeoutRequest(): void {
    //this.logger.info("EventComponent.onAddNewTakeoutRequest()");
    this.dialogRef = this.dialog.open(CreateTakeoutRequestComponent, {
      width: '600px',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      maxHeight: '96vh',
      disableClose: false,
      data: {
        autoRedirect: true,
        eventUUID: this.event.uuid,
        mode: CreateTakeoutRequestComponent.CREATE,
        event: this.event,
      },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          // //this.logger.info(
          //     "EventComponent.onAddNewDeliveryRequest() -> dialog closed: result: " +
          //         JSON.stringify(result)
          // );
          // //this.logger.info(
          //     "EventComponent.onAddNewDeliveryRequest() -> dialog closed: this.event.takeout_requests: " +
          //         JSON.stringify(this.event.takeout_requests)
          // );
          // this.eventTakoutRequestComponent.loading = true;
          // this.loadData();
          this.event.takeout_requests.push(result.data);
          // //this.logger.info(
          //     "EventComponent.onAddNewDeliveryRequest() -> dialog closed: this.event.takeout_requests 2: " +
          //         JSON.stringify(this.event.takeout_requests)
          // );
        }
        this.dialogRef = null;
      },
    });

    // if ((!this.event.spot.enterprise
    //     && !this.event.spot.private_client
    //     && !this.event.spot.place
    //     && !this.event.spot.room)
    //     ||
    //     (!this.event.delivery_type || !this.event.delivery_type.need_delivery))
    //     {
    //     this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
    //         disableClose: false
    //     });

    //     this.confirmDialogRef.componentInstance.useCancel = false;
    //     this.confirmDialogRef.componentInstance.alertMessage = this.translate.instant('EVENTS.DELIVERY_REQUEST_SPOT_NOT_SELECTTED');
    //     this.confirmDialogRef.afterClosed().subscribe(result => {
    //         this.confirmDialogRef = null;
    //     });
    // } else
    // {
    //     this.dialogRef = this.dialog.open(CreateTakeoutRequestComponent, {
    //         width: '600px',
    //         disableClose: false,
    //         data: {
    //             autoRedirect: true,
    //             eventUUID: this.event.uuid,
    //             mode: CreateTakeoutRequestComponent.CREATE,
    //             event: this.event,
    //         }
    //     });

    //     this.dialogRef.afterClosed().subscribe(result => {
    //         if (result)
    //         {
    //             // //this.logger.info("EventComponent.onAddNewDeliveryRequest() -> dialog closed: result: " + JSON.stringify(result));
    //             this.eventTakoutRequestComponent.loading = true;
    //             this.loadData();
    //         }
    //         this.dialogRef = null;
    //     });
    // }
  }

  public onAddNewDepositRequest(): void {
    // //this.logger.info("EventComponent.onAddNewDepositRequest()");

    if (this.event.invoice) {
      // this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      //   disableClose: false
      // });
      // this.confirmDialogRef.componentInstance.useCancel = false;
      // this.confirmDialogRef.componentInstance.alertMessage = this.translate.instant("EVENTS.DELIVERY_REQUEST_SPOT_NOT_SELECTTED");
      // this.confirmDialogRef.afterClosed().subscribe(result => {
      //     this.confirmDialogRef = null;
      // });
    } else {
      this.dialogRef = this.dialog.open(CreateDepositRequestComponent, {
        width: '400px',
        maxHeight: '96vh',
        minWidth: 350,
        panelClass: 'scrollable-dialog',
        disableClose: false,
        data: {
          autoRedirect: true,
          eventUUID: this.event.uuid,
          mode: CreateDeliveryRequestComponent.CREATE,
          event: this.event,
        },
      });

      this.dialogRef.afterClosed().subscribe({
        next: (result: any) => {
          if (result) {
            //this.logger.info("EventComponent.onAddNewDepositRequest() -> dialog closed: result: " + JSON.stringify(result));
            // this.eventDepositRequestComponent.loading = true;
            this.loadData();
            // this.event.deposit_requests.push(result.data);
          }
          this.dialogRef = null;
        },
      });
    }
  }

  public onAddNewOkaDepositRequest(): void {
    // //this.logger.info("EventComponent.onAddNewDepositRequest()");

    if (this.event.invoice) {
      // this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      //   disableClose: false
      // });
      // this.confirmDialogRef.componentInstance.useCancel = false;
      // this.confirmDialogRef.componentInstance.alertMessage = this.translate.instant("EVENTS.DELIVERY_REQUEST_SPOT_NOT_SELECTTED");
      // this.confirmDialogRef.afterClosed().subscribe(result => {
      //     this.confirmDialogRef = null;
      // });
    } else {
      this.loading = true;
      this.depositRequestService
        .creatOkaDepositRequest(this.event.uuid)
        .subscribe({
          next: (response) => {
            //this.logger.info("CreateDepositRequestComponent.onAddNewOkaDepositRequest() -> SUCCESS: " + JSON.stringify(response));
            this.loading = false;
            this.loadData();
            // this.dialogRef.close(response);
          },
          error: (error) => {
            this.logger.error(
              'CreateDepositRequestComponent.onAddNewOkaDepositRequest() -> ERROR: ' +
                JSON.stringify(error),
            );
            this.loading = false;
            this.handleAPIError(
              error,
              this.dialog,
              this.toastr,
              this.translate.instant('GENERIC.ERRORS.GENERIC'),
            );
            // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
          },
        });
    }
  }

  public onAddNewCashing(): void {
    // //this.logger.info("EventComponent.onAddNewDepositRequest()");
    this.dialogRef = this.dialog.open(CreateDepositInComponent, {
      width: '520px',
      minWidth: 350,
      maxHeight: '96vh',
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: {
        autoRedirect: true,
        eventUUID: this.event.uuid,
        mode: CreateDepositInComponent.CREATE,
        uiMode: CreateDepositInComponent.getDepositMode(this.event),
        event: this.event,
      },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          //this.logger.info("EventComponent.onAddNewDepositRequest() -> dialog closed: result: " + JSON.stringify(result));
          // //this.logger.info("EventComponent.onAddNewDepositRequest() -> this.event.status: " + this.event.status);
          // //this.logger.info("EventComponent.onAddNewDepositRequest() -> this.event.current_order_type: " + this.event.current_order_type);
          // //this.logger.info("EventComponent.onAddNewDepositRequest() -> result.data.balance <= 0: " + (result.data.balance <= 0));
          if (
            this.event.status === GT2ConstantsEnum.EVENT_IN_INVOICE &&
            this.event.current_order_type === GT2ConstantsEnum.INVOICE &&
            result.data.balance <= 0
          ) {
            this.openCompleteInvoice();
          } else {
            // this.eventDepositInComponent.loading = true;
            this.loadData();
            // this.event.cashings.push(result.data);
          }
        }
        this.dialogRef = null;
      },
    });
  }

  public openCompleteInvoice(): void {
    // //this.logger.info("EventComponent.openCompleteInvoice()");
    this.ordersService
      .completeAndPaidInvoice(this.event.documents.invoice.uuid)
      .subscribe({
        next: (response: any) => {
          //this.logger.info("EventComponent.openCompleteInvoiceDialog() -> SUCCESS: " + JSON.stringify(response));
          this.toastr.success(
            '',
            this.translate.instant('GENERIC.API_CALL_SUCCESS.SAVE_SUCCESS'),
          );
          this.loadData();
        },
        error: (error: any) => {
          this.logger.error(
            'EventComponent.openCompleteInvoiceDialog() -> ERROR: ' +
              JSON.stringify(error),
          );
          // this.toastr.error("", this.translate.instant("GENERIC.ERRORS.GENERIC"));
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.GENERIC'),
          );
        },
      });
  }

  public onCancelEvent(): void {
    // //this.logger.info("EventComponent.onCancelEvent()");
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('EVENTS.CANCEL_EVENT_DIALOG_MESSAGE');
    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        this.confirmDialogRef = null;
        if (result) {
          this.eventsService.cancelEvent(this.eventUUDID).subscribe({
            next: (response: any) => {
              // //this.logger.info("EventComponent.onCancelEvent() -> SUCCESS: " + JSON.stringify(response));
              this.toastr.success(
                '',
                this.translate.instant('EVENTS.CANCEL_EVENT_SUCESS'),
              );
              this.event = response.data;
              this.loadData();
            },
            error: (error: any) => {
              this.logger.error(
                'EventComponent.onCancelEvent() -> ERROR: ' +
                  JSON.stringify(error),
              );
              this.handleAPIError(error, this.dialog, null, null);
            },
          });
        }
      },
    });
  }

  public onAbortEvent(): void {
    // //this.logger.info("EventComponent.onAbortEvent()");
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('EVENTS.ABORT_EVENT_DIALOG_MESSAGE');
    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        this.confirmDialogRef = null;
        if (result) {
          this.eventsService.abortEvent(this.eventUUDID).subscribe({
            next: (response: any) => {
              // //this.logger.info("EventComponent.onAbortEvent() -> SUCCESS: " + JSON.stringify(response));
              this.toastr.success(
                '',
                this.translate.instant('EVENTS.ABORT_EVENT_SUCESS'),
              );
              this.event = response.data;
              this.loadData();
            },
            error: (error: any) => {
              this.logger.error(
                'EventComponent.onAbortEvent() -> ERROR: ' +
                  JSON.stringify(error),
              );
              this.handleAPIError(error, this.dialog, null, null);
            },
          });
        }
      },
    });
  }

  public onReopenEvent(): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('EVENTS.REOPEN_EVENT_DIALOG_MESSAGE');
    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        this.confirmDialogRef = null;
        if (result) {
          this.eventsService.reopenEvent(this.eventUUDID).subscribe({
            next: (response: any) => {
              // //this.logger.info("EventComponent.onReopenEvent() -> SUCCESS: " + JSON.stringify(response));

              // TODO:
              // this.toastr.success("", this.translate.instant("EVENTS.REOPEN_EVENT_SUCCESS"));
              this.event = response.data;
              this.loadData();
            },
            error: (error: any) => {
              this.logger.error(
                'EventComponent.onReopenEvent() -> ERROR: ' +
                  JSON.stringify(error),
              );
              this.handleAPIError(error, this.dialog, null, null);
            },
          });
        }
      },
    });
  }

  public onReClosepenEvent(): void {
    this.eventsService.reCloseEvent(this.eventUUDID).subscribe({
      next: (response: any) => {
        // //this.logger.info("EventComponent.onReClosepenEvent() -> SUCCESS: " + JSON.stringify(response));
        // TODO:
        // this.toastr.success("", this.translate.instant("EVENTS.REOPEN_EVENT_SUCCESS"));
        this.event = response.data;
        this.loadData();
      },
      error: (error: any) => {
        this.logger.error(
          'EventComponent.onReClosepenEvent() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public doPrint(url: string): void {
    this.print.printHTML(url).subscribe({
      next: (response: any) => {
        // //this.logger.info("EventComponent.doPrint() -> SUCCESS: " + JSON.stringify(response));
      },
      error: (error: any) => {
        this.logger.error(
          'EventComponent.doPrint() -> ERROR: ' + JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public doDownload(url: string): void {
    this.fileService.downloadFile(url).subscribe({
      next: (response: any) => {
        // //this.logger.info("EventComponent.doDownload() -> SUCCESS: " + JSON.stringify(response));
      },
      error: (error: any) => {
        this.logger.error(
          'EventComponent.doDownload() -> ERROR: ' + JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public onPrint(): void {
    const url: string =
      this.api.createUrl('/reports/events/html/') + this.event.uuid;
    this.doPrint(url);
  }

  public onDownloadPDF(): void {
    const url: string =
      this.api.createUrl('/reports/events/pdf/') + this.event.uuid;
    this.doDownload(url);
  }

  public onPrintReceipt(): void {
    // //this.logger.info("EventComponent.onPrintReceipt()");
    const url: string =
      this.api.createReportURL(
        ApiRoutes.REPORT_PRINT,
        'client/report-client-event-receipt',
        'fr-CA',
      ) +
      '/' +
      this.eventUUDID;
    this.doPrint(url);
  }

  // public onPrintDeposits(): void {
  //     // //this.logger.info("EventComponent.onPrintReceipt()");
  //     const url: string =
  //         this.api.createReportURL(
  //             ApiRoutes.REPORT_PRINT,
  //             "client/report-client-event-receipt",
  //             "fr-CA"
  //         ) +
  //         "/" +
  //         this.eventUUDID;
  //     this.doPrint(url);
  // }

  public onDownloadReceipt(): void {
    // //this.logger.info("EventComponent.onDownloadReceipt()");
    const url: string =
      this.api.createReportURL(
        ApiRoutes.REPORT_PDF,
        'client/report-client-event-receipt',
        'fr-CA',
      ) +
      '/' +
      this.eventUUDID;
    this.doDownload(url);
  }

  // public onDownloadDeposits(): void {
  //     // //this.logger.info("EventComponent.onDownloadReceipt()");
  //     const url: string =
  //         this.api.createReportURL(
  //             ApiRoutes.REPORT_PDF,
  //             "client/report-client-event-receipt",
  //             "fr-CA"
  //         ) +
  //         "/" +
  //         this.eventUUDID;
  //     this.doDownload(url);
  // }

  public onSendReceipt(): void {
    //this.logger.info("EventComponent.onSendReceipt()");

    // // IMPORTANT
    this.eventComposeService.parentUUID = this.eventUUDID;

    this.dialogRef = this.dialog.open(MailComposeDialogComponent, {
      panelClass: 'mail-compose-dialog',
      data: {
        composeService: this.eventComposeService,
      },
    });
    this.dialogRef.afterClosed().subscribe({
      next: (response: any) => {
        if (!response) {
          return;
        }
        this.dialogRef = null;
      },
    });
  }

  public onSendDeposits(): void {
    //this.logger.info("EventComponent.onSendReceipt()");

    // // IMPORTANT
    this.eventComposeService.parentUUID = this.eventUUDID;

    this.dialogRef = this.dialog.open(MailComposeDialogComponent, {
      panelClass: 'mail-compose-dialog',
      data: {
        composeService: this.eventComposeService,
      },
    });
    this.dialogRef.afterClosed().subscribe({
      next: (response: any) => {
        if (!response) {
          return;
        }
        this.dialogRef = null;
      },
    });
  }

  public onOpenInNewTab(): void {
    window.open(this.router.url, '_blank');
  }

  public toggleHistoricSidebarOpen(key: string): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  public onAffectations(): void {
    const url: string = ItemUrlFinder.getItemURL(this.event) + '/affectations';
    //this.logger.info("> url: " + url);
    this.router.navigateByUrl(url);
  }

  public onNavigateToOrder(): void {
    // this.router.navigateByUrl(
    //     ItemUrlFinder.getItemURL(this.event.order) + "?tab=encaissement"
    // );
  }
  //

  public printPost(url: string, dateRange: any): void {
    // //this.logger.info("ReportsComponent.printPost() -> url: " + url);
    this.print.postPrintHTML(url, dateRange).subscribe({
      next: (response: any) => {
        // //this.logger.info("ReportsComponent.printPost() -> SUCCESS: " + JSON.stringify(response));
      },
      error: (error: any) => {
        this.logger.error(
          'EventComponent.printPost() -> ERROR: ' + JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public downloadPost(url: string, dateRange: any = null): void {
    // //this.logger.info("EventComponent.downloadPost() -> url: " + url);
    this.fileService.postDownloadFile(url, dateRange).subscribe({
      next: (response) => {
        // //this.logger.info("EventComponent.downloadPost() -> SUCCESS: " + JSON.stringify(response));
      },
      error: (error) => {
        this.logger.error(
          'downloadPost.downloadPost() -> ERROR: ' + JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public onPrintEmployeesAssigments(
    section: string,
    paperFormat: string,
  ): void {
    //this.logger.info("EventComponent.onPrintEmployeesAssigments()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PRINT,
      section,
      paperFormat + '/fr-CA',
    );
    //this.logger.info("EventComponent.onPrintEmployeesAssigments() -> url: " + url);
    this.printPost(url, { event_uuid: this.event.uuid });
  }

  public onDownloadEmployeesAssigments(
    section: string,
    paperFormat: string,
  ): void {
    //this.logger.info("EventComponent.onDownloadEmployeesAssigments()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PDF,
      section,
      paperFormat + '/fr-CA',
    );
    //this.logger.info("EventComponent.onDownloadEmployeesAssigments() -> url: " + url);
    this.downloadPost(url, { event_uuid: this.event.uuid });
  }

  //

  // public onPrintEmployeesAssigmentsSale(section: string, paperFormat: string): void {
  //     //this.logger.info("EventComponent.onPrintEmployeesAssigmentsSale()");
  // }

  // public onPrintEmployeesAssigmentsCost(section: string, paperFormat: string): void {
  //     //this.logger.info("EventComponent.onPrintEmployeesAssigmentsCost()");
  // }

  // public onPrintEmployeesAssigmentsAttendance(section: string, paperFormat: string): void {
  //     //this.logger.info("EventComponent.onPrintEmployeesAssigmentsAttendance()");
  // }

  // public onDownloadEmployeesAssigmentsSale(section: string, paperFormat: string): void {
  //     //this.logger.info("EventComponent.onDownloadEmployeesAssigmentsSale()");
  // }

  // public onDownloadEmployeesAssigmentsCost(section: string, paperFormat: string): void {
  //     //this.logger.info("EventComponent.onDownloadEmployeesAssigmentsCost()");
  // }

  // public onDownloadEmployeesAssigmentsAttendance(section: string, paperFormat: string): void {
  //     //this.logger.info("EventComponent.onDownloadEmployeesAssigmentsAttendance()");
  // }

  public onNavigateToCongress(): void {
    this.router.navigateByUrl(
      ItemUrlFinder.getItemURL({
        object: 'Congress',
        uuid: this.event.congress_uuid,
        slug: this.event.congress_slug,
      }),
    );
  }

  public onNavigateToProject(): void {
    this.router.navigateByUrl(
      ItemUrlFinder.getItemURL({
        object: 'Project',
        uuid: this.event.project_uuid,
        slug: this.event.project_slug,
      }),
    );
  }
}
