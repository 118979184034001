import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { GT2PageAbstract } from './GT2PageAbstract';

export class GT2FormPageAbstract extends GT2PageAbstract {
  debounceTimeValue: number = 1500;
  pageForm?: UntypedFormGroup;
  pageFormErrors: any;
  controlsSubscription: Subscription[] = [];

  constructor() {
    super();
  }

  public setControlsChanges(form: UntypedFormGroup): void {
    for (const each of Object.keys(form.controls)) {
      const control = form.get(each);

      // const controlSubscription: Subscription = control.valueChanges
      //     .debounceTime(this.debounceTimeValue).distinctUntilChanged()
      //     .subscribe(data => {

      //     // if( control.parent )
      //     // if ( control.dirty && control.valid && !control.untouched )

      //     // TODO: find a solution for untouched
      //     if ( control.dirty && control.valid )
      //     {
      //         const updateData: Object = {};
      //         updateData[each] = data;
      //         this.autosaveDataChange(updateData);

      //         // if (data)
      //         // {
      //         //     this.autosaveDataChange(updateData);
      //         // }
      //     }
      // });

      // control.valueChanges

      const controlSubscription: Subscription = control.valueChanges
        .pipe(debounceTime(this.debounceTimeValue))
        .pipe(distinctUntilChanged())
        .subscribe({
          next: (data) => {
            // if( control.parent )
            // if ( control.dirty && control.valid && !control.untouched )

            // TODO: find a solution for untouched
            if (control?.dirty && control?.valid) {
              const updateData = {};
              updateData[each] = data;
              this.autosaveDataChange(updateData);

              // if (data)
              // {
              //     this.autosaveDataChange(updateData);
              // }
            }
          },
        });

      this.controlsSubscription.push(controlSubscription);
    }
  }

  public unsetControlsChanges(): void {
    // TODO: unset subscribe
    let i: number = 0;
    const length: number = this.controlsSubscription.length;
    for (i; i < length; ++i) {
      this.controlsSubscription[i].unsubscribe();
    }

    this.controlsSubscription = [];
  }

  public autosaveDataChange(data: any): void {}
}
