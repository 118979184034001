import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { GT2PageAbstract } from '../../abstract/GT2PageAbstract';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import {
  ClientsCategoriesService,
  ClientsCategoryModel,
} from '../../../api/clients-categories.service';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import {
  MatTable,
  MatTableDataSource,
  MatTableModule,
} from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DatatableHelperService } from '../../../../services/datatable-helper.service';
import { GenericCreateComponent } from '../../../components/dialogs/generic-create/generic-create.component';
import { ApiRoutes } from '../../../api/ApiRoutes';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { fuseAnimations } from '../../../../../@fuse/animations/index';
import { GenericAlertComponent } from '../../../components/generic-alert/generic-alert.component';
import { Settings } from '../../../settings/settings';
import { of, merge } from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CdkTableModule } from '@angular/cdk/table';
import { TableSizePipeModule } from '../../../modules/table-size.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-clients-categories-settings',
  templateUrl: './clients-categories-settings.component.html',
  styleUrls: ['./clients-categories-settings.component.scss'],
  standalone: true,
  imports: [
    MatTooltipModule,
    MatButtonModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    CdkTableModule,
    MatTableModule,
    MatInputModule,
    TableSizePipeModule,
    MatPaginatorModule,
    TranslateModule,
    CommonModule,
    MatIconModule,
    MatSortModule,
    FlexLayoutModule,
  ],
  animations: fuseAnimations,
})
export class ClientsCategoriesSettingsComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit
{
  @Input() headerLabel!: string;
  createLabel!: string;
  serviceURL: string;
  dataSource = new MatTableDataSource();
  displayedColumns = ['name', 'edit', 'delete'];
  selected!: ClientsCategoryModel;
  editSelected!: ClientsCategoryModel | any;
  form: UntypedFormGroup;
  newCategory!: string;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('table') table!: MatTable<any>;
  @ViewChild(MatSort) sort!: MatSort;
  confirmDialogRef: any;
  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;

  constructor(
    private logger: NGXLogger,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private toastr: ToastrService,
    public translate: TranslateService,
    public datatableHelperService: DatatableHelperService,
    private clientsCategoriesService: ClientsCategoriesService,
    private translationLoader: FuseTranslationLoaderService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
    });

    this.serviceURL = ApiRoutes.CLIENTS_CATEGORIES;
  }

  ngOnInit() {}

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe({
      next: () => (this.paginator.pageIndex = 0),
    });

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(startWith(null))
      .pipe(
        switchMap(() => {
          setTimeout(() => {
            this.isLoadingResults = true;
          });

          return this.clientsCategoriesService.getAll(
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize,
          );
        }),
      )
      .pipe(
        map((data) => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.meta.pagination.total;
          return data.data;
        }),
      )
      .pipe(
        catchError(() => {
          setTimeout(() => {
            this.isLoadingResults = false;
            this.isRateLimitReached = true;
          });
          return of([]);
        }),
      )
      .subscribe({ next: (data) => (this.dataSource.data = data) });
  }

  public onSelect(selected: ClientsCategoryModel) {
    // this.logger.info("ClientsCategoriesSettingsComponent.onSelect() -> " + selected);
    this.onEdit(selected);
  }

  public reloadData(): void {
    // this.dataSource = new ClientsCategoriesDataSource(this.clientsCategoriesService);
    this.paginator?._changePageSize(this.paginator.pageSize);
  }

  public onCreateNew() {
    // this.logger.info("ClientsCategoriesSettingsComponent.onCreateNew() ");

    this.confirmDialogRef = this.dialog.open(GenericCreateComponent, {
      disableClose: false,
      width: '400px',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      data: {
        dialogTitle: this.translate.instant(
          'CLIENTS_CATEGORIES.CREATE_NEW_CATEGORY',
        ),
        serviceURL: this.serviceURL,
        createPlaceholder: this.translate.instant(
          'CLIENTS_CATEGORIES.NEW_CATEGORY_NAME',
        ),
        useEnglish: false,
      },
    });

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          // this.logger.info("ClientsCategoriesSettingsComponent.onCreateNew() -> CREATION SUCCESS");
          this.reloadData();
        } else {
          // this.logger.info("ClientsCategoriesSettingsComponent.onCreateNew() -> CREATION CANCELED");
        }
        this.confirmDialogRef = null;
      },
    });
  }

  public onEdit(selected: ClientsCategoryModel) {
    // this.logger.info("ClientsCategoriesSettingsComponent.onEdit() -> " + JSON.stringify(selected));

    if (this.editSelected === selected) {
      this.editSelected = null;
      this.reloadData();
    } else {
      this.editSelected = selected;
    }
  }

  public onDelete(selected: ClientsCategoryModel) {
    // this.logger.info("ClientsCategoriesSettingsComponent.onDelete() -> " + selected);
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle = this.translate.instant(
      'GENERIC.DELETE',
      { value: selected.name },
    );
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM', {
        value: selected.name,
      });

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.clientsCategoriesService.deleteCategories(selected).subscribe({
            next: (response: any) => {
              // this.logger.info("ClientsCategoriesSettingsComponent.onDelete() -> SUCCESS:" + JSON.stringify(response));
              this.toastr.success(
                this.translate.instant('GENERIC.DELETE_SUCCESS'),
              );
              this.reloadData();
            },
            error: (error: any) => {
              this.logger.error(
                'ClientsCategoriesSettingsComponent.onDelete() -> ERROR' +
                  JSON.stringify(error),
              );
              // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
              this.handleAPIError(
                error,
                this.dialog,
                this.toastr,
                this.translate.instant('GENERIC.ERRORS.GENERIC'),
              );
            },
          });
        }
        this.confirmDialogRef = null;
      },
    });
  }

  public onEditCategoryFocusOut(selected: ClientsCategoryModel): any {
    // this.logger.info("ClientsCategoriesSettingsComponent.onEditCategoryFocusOut() -> job: " + JSON.stringify(selected));
    this.autosaveDataChange(selected);
  }

  public autosaveDataChange(data: ClientsCategoryModel): void {
    // this.logger.info("ClientsCategoriesSettingsComponent.autosaveDataChange() -> data to save: " + JSON.stringify(data));
    this.clientsCategoriesService.editCategory(data).subscribe({
      next: (response: any) => {
        // this.logger.info("ClientsCategoriesSettingsComponent.onEdit() -> SUCCESS:" + JSON.stringify(response));
        this.editSelected = null;
        this.toastr.success(
          this.translate.instant('CLIENTS_CATEGORIES.EDIT_SUCCESS'),
        );
        this.reloadData();
      },
      error: (error: any) => {
        this.logger.error(
          'ClientsCategoriesSettingsComponent.onEdit() -> ERROR' +
            JSON.stringify(error),
        );
        // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
        this.handleAPIError(
          error,
          this.dialog,
          this.toastr,
          this.translate.instant('GENERIC.ERRORS.GENERIC'),
        );
      },
    });
  }
}
